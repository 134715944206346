import { useCallback, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import {
    onePlatformReplaceHeaderColumns,
    tableOnePlatformReplaceFocusedRows,
    tableOnePlatformReplaceHoveredRows,
    tableOnePlatformReplaceSelectedRows,
    tableOnePlatformReplaceSort,
    tableOnePlatformUpdateWidthColumn
} from '../actions';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../constants';

const filterColumns = (headerColumns) => {
    return Object.values(headerColumns)
        .filter((v) => v.enabled && v.display)
        .sort((a, b) => a.order - b.order);
};

const selectTableState = createSelector(
    (state, reportKey) => state.tableOnePlatform[reportKey],
    (tableProperties) => {
        const { headerColumns = EMPTY_OBJECT, sort = EMPTY_ARRAY, selectedRows = EMPTY_OBJECT, focusedRows = EMPTY_ARRAY, isFetchingData = false, data = EMPTY_ARRAY, maxRowSize, pageSelect, hoveredRows = EMPTY_OBJECT } = tableProperties || {};
        const filteredColumns = filterColumns(headerColumns);
        return { headerColumns, sort, selectedRows, focusedRows, hoveredRows, isFetchingData, data, filteredColumns, maxRowSize, pageSelect };
    },
);

export const useOnePlatformTableState = (reportKey) => {
    const { data, selectedRows, ...rest } = useSelector(state => selectTableState(state, reportKey), shallowEqual);
    const selectedRowsWithData = useMemo(() => data.filter(data => selectedRows[data.___uKey]), [data, selectedRows]);
    return { ...rest, data, selectedRows, selectedRowsWithData };
};

export const useOnePlatformTableDispatch = (reportKey) => {
    const dispatch = useDispatch();
    return {
        replaceHeaderColumns: useCallback((columns) => {
            dispatch(onePlatformReplaceHeaderColumns(columns, reportKey, false));
        }, [dispatch, reportKey]),
        replaceSort: useCallback(sort => dispatch(tableOnePlatformReplaceSort(sort, reportKey)), [dispatch, reportKey]),
        replaceSelectedRows: (selectedRows) => () => dispatch(tableOnePlatformReplaceSelectedRows(selectedRows, reportKey)),
        replaceFocusedRows: useCallback((focusedRows) => () => dispatch(tableOnePlatformReplaceFocusedRows(focusedRows, reportKey)), [dispatch, reportKey]),
        // not used dispatches
        replaceHoveredRows: useCallback((hoveredRows) => dispatch(tableOnePlatformReplaceHoveredRows(hoveredRows, reportKey)), [dispatch, reportKey]),
        updateWidthColumn: (columnIndex, width) => () => dispatch(tableOnePlatformUpdateWidthColumn(columnIndex, width, reportKey))
    };
};
