import React from 'react';
import {Link} from 'react-router-dom';
import FlatButton from '@material-ui/core/Button';
import {generateComponentId} from "../../../../utils/ComponentUtils";

import MobileSubMenuContainer from './MobileSubMenuContainer';
import PropTypes from 'prop-types'

class MobileMenuItemContainer extends React.Component {

    static get propTypes(){
        return {
            clickMainMenu: PropTypes.func,
            index: PropTypes.string.isRequired,
            menuList: PropTypes.object.isRequired,
            toggle: PropTypes.func,
            active: PropTypes.bool
        }
    }

    constructor(props){
        super(props);
        this.toggleSubMenu = this.toggleSubMenu.bind(this);
    }

    /**
     * Open the subMenu for every item in the Menu list for Mobile
     */
    toggleSubMenu(){
        this.props.clickMainMenu(this.props.index);
    }

    //Main render.
    render(){

        const {active, menuList}=this.props;

        let classButtonLink = "button-link";
        if(active){
            classButtonLink += " active";
        }

        const childrenSize = menuList.children.length;
        const idSelector = menuList.name+"_selector";
        const button = (<FlatButton
                            className={classButtonLink}
                            ref={"button"}
                            id={generateComponentId(menuList.name.toLowerCase(), null, "button")}
                            label={menuList.name}
                            style={{textTransform:"none", justifyContent: 'initial'}}
                            onClick={this.toggleSubMenu}
                            variant="text"
        >{menuList.name}

        </FlatButton>);

        if(childrenSize === 0) {
            return (
                <div className={"link-container"}>
                    <Link to={"/" + menuList.routes} onClick={this.props.toggle} key={menuList.name} replace>
                        {button}
                    </Link>
                </div>
            );
        } else {
            return (
                <div className={"link-container"} >
                    <Link to={"/" + menuList.routes} onClick={e=> e.preventDefault()} key={menuList.name}>
                        {button}
                    </Link>
                    {(active)?
                    <MobileSubMenuContainer
                        {...this.props}
                        id={idSelector}
                        menuList={menuList.children}
                        subMenuActive={this.props.subMenuActive}
                        clickSubMenu={this.props.clickSubMenu}
                    />:""}
                </div>
            );
        }
    }

}

export default MobileMenuItemContainer;