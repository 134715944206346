import {
    DEVICES_ALLOWED_CONTEXT_MENU,
} from '../constants';
import { ItemType } from '../../../../../../constants';

export const MenuType = {
    EDIT: 'edit',
    DELETE: 'delete',
    DUPLICATE: 'duplicate',
    CONNECT: 'connect',
};

// const editCallback = (feature) => {
//     const data = feature.data.data;
//     feature.data.editAction(data, EDIT_FORM, feature.data.type);
// };
//
// const removeCallback = (feature) => {
//     console.log({ feature });
//     let data = feature.data.data;
//     data.siteId = feature.data.siteId;
//     data.type = feature.data.type;
//     feature.data.deleteAction(data);
// };
//
// const duplicateCallback = (feature) => {
//     let data = feature.data.data;
//     delete data.id;
//     delete data.name;
//     data.x = data.x + 10;
//     feature.data.editAction(data, ADD_FORM, feature.data.type);
// };

export const CONTEXT_MENU = {
    [MenuType.EDIT]: {
        text: 'Edit',
        menuType: MenuType.EDIT,
    },
    [MenuType.CONNECT]: {
        text: 'Connect',
        menuType: MenuType.CONNECT,
    },
    [MenuType.DELETE]: {
        text: 'Delete',
        menuType: MenuType.DELETE,
    },
    
    [MenuType.DUPLICATE]: {
        text: 'Duplicate',
        menuType: MenuType.DUPLICATE,
    },
};

const generateMenu = (elements, disabledDelete) => {
    return elements.map(item => {
        const menu = { ...CONTEXT_MENU[item] };
        if (item === MenuType.DELETE) {
            menu.classname = disabledDelete ? 'marker-disabled' : '';
        }
        return { ...menu };
    });
};

// TODO: Review devicesLocked
export const getContextMenuOptionsByItemType = (itemType, devicesLocked = []) => {
    let contextMenuItems = [];
    let disabledDelete = false;
    if (devicesLocked) {
        disabledDelete = devicesLocked.indexOf(itemType) > -1;
    }
    switch (itemType) {
        case ItemType.ZONE:
            contextMenuItems = generateMenu([ MenuType.EDIT ], disabledDelete);
            break;
        case ItemType.OBSTRUCTION:
            contextMenuItems = generateMenu([ MenuType.EDIT ], disabledDelete);
            break;
        case ItemType.DEVICE_ATR7000_CLAS:
            contextMenuItems = generateMenu([ MenuType.DELETE ], disabledDelete);
            break;
        case ItemType.DEVICE_DATUM:
            contextMenuItems = generateMenu([ MenuType.EDIT, MenuType.DELETE ], disabledDelete);
            break;
        case ItemType.ANTENNA:
            contextMenuItems = generateMenu([ MenuType.EDIT, MenuType.DELETE, MenuType.DUPLICATE ], disabledDelete);
            break;
        default:
            break;
    }
    return contextMenuItems;
};

export const getFeature = (event, mapRef) => {
    return mapRef.forEachFeatureAtPixel(event.pixel, feat => {
        if (DEVICES_ALLOWED_CONTEXT_MENU.includes(feat?.get('featureType'))) {
            return feat;
        }
    });
};
