import React from 'react';
import PropTypes from 'prop-types';
import StyledDialog, {CancelButton, SaveButton} from '../util/Dialog';

import {Field, Form} from '../util/DataEntryTabularForm';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actions from '../../actions';
import * as forms from '../../constants/DataEntryForms';
import {withRouter} from 'react-router-dom';
import {generateComponentId} from "../../utils/ComponentUtils";

class ChangePasswordDialog extends React.Component{
    constructor(){
        super();
        this.handleClose = this.handleClose.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }
    static get propTypes(){
        return {
            open: PropTypes.bool,
            close: PropTypes.func.isRequired,
            username: PropTypes.string.isRequired,
            showError: PropTypes.func.isRequired,
            changePassword: PropTypes.func.isRequired
        };
    }
    shouldComponentUpdate(nextProps){
        return (nextProps.open || this.props.open);
    }
    handleSave(){
        let password = this.refs.password.value,
            old_password = this.refs.old_password.value,
            confirm_password = this.refs.confirm_password.value;

        if(password !== confirm_password){
            this.props.showError({
                message:"Passwords must match."
            });
            return;
        }

        if(password.length === 0){
            this.props.showError({
                message: "Password cannot be empty."
            });
        }

        this.props.changePassword(
            this.props.username,
            old_password,
            password
        )
        .then((response)=>{
            this.props.close();
            this.props.showError({
                title:"Success",
                message: response.data,
                noconsole: true
            });            
        })
        .catch(err=>{
            this.props.showError({message:err});
        });

    }
    handleClose(){
        this.props.close();
    }

    render() {
        const {username} = this.props;
        const currentPassword= "Current Password";
        const newPassword= "New Password";
        const confirmPassword= "Confirm Password";
        return (
            <StyledDialog
                title={"Change Password"}
                modal={false}
                onRequestClose={this.handleClose}
                onClose={this.handleClose}
                contentStyle={{minWidth: 330}}
                style={{overflowY:'auto'}}
                {...this.props}
                actions={
                    <>
                        <SaveButton id={"change_save_btn"} onClick={this.handleSave} />
                        <CancelButton id={"change_cancel_btn"} style={{position: 'absolute', left: 8}} onClick={this.handleClose} />
                    </>
                }
                >
                    <Form>
                        <Field label={"Username"}>
                            {username}
                        </Field>
                        <Field label={currentPassword}>
                            <input style={{width: '330px'}}  id={generateComponentId(currentPassword, null, "text")} type={"password"} ref="old_password" />
                        </Field>
                        <Field label={newPassword} required>
                            <input  id={generateComponentId(newPassword, null, "text")} type={"password"} ref="password" />
                        </Field>
                        <Field label={confirmPassword} required>
                            <input  id={generateComponentId(confirmPassword, null, "text")} type={"password"} ref="confirm_password" />
                        </Field>
                    </Form>
            </StyledDialog>
        );
    }
}





const mapStateToProps = state => ({
    open: state.dataEntry.open && (state.dataEntry.activeForm === forms.CHANGE_PASSWORD),
    username: state.user.username || ""
});

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        close:actions.closeDataEntry,
        showError: actions.displayErrorDialog,
        changePassword: actions.changePassword
    },dispatch)
});



export default withRouter( connect(mapStateToProps,mapDispatchToProps)(ChangePasswordDialog) );
