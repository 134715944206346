import { Polygon } from 'ol/geom';
import { olModifyFactory, olSelectionFactory, olTranslateFactory } from '../commons';
import { getLastChildOfPolygonSet, getOlStoreKey, olExtender } from '../helpers';
import { olZoneColorFactory } from './OlZoneColorFactory';
import { olGreyMode } from '../commons/OlGreyMode';
import { olZoneGridFactory } from './OlZoneGridFactory';
import { olCopyPasteZonesFactory } from './OlCopyPasteZonesFactory';
import { olDrawZoneFactory } from './OlDrawZoneFactory';
import { olTranslateZoneFactory } from './OlTranslateZoneFactory';
import { ItemType } from '../../constants';
import { zoneItemToFeature } from './helpers';
import { autoZoneItemToFeature } from '../config-auto-zones/helpers';
import { MenuType } from '../../vss/app/views/sites/designer/utils';
import {olJoinNeighborZonesFactory} from "./OlJoinNeighborZonesFactory";


const storeZoneKey = getOlStoreKey(ItemType.ZONE);
const storeAutoZoneKey = getOlStoreKey(ItemType.AUTO_ZONE);

class OlConfigZones extends olExtender(
    // olCollisionDetectionFactory,
    olSelectionFactory(ItemType.ZONE),
    olSelectionFactory(ItemType.AUTO_ZONE),

    olJoinNeighborZonesFactory,
    olZoneColorFactory,
    olTranslateFactory(ItemType.ZONE),
    olModifyFactory(ItemType.ZONE),
    olCopyPasteZonesFactory,
    olGreyMode,
    olZoneGridFactory,
    olDrawZoneFactory,
    olTranslateZoneFactory,
) {
    
    showLabels;
    zones;
    autoZones
    obstructions;
    devices;
    visibleItemTypes;

    constructor(mapRef) {
        super(mapRef);
        if (!this[storeZoneKey]) {
            this[storeZoneKey] = {};
        }
        if (!this[storeAutoZoneKey]) {
            this[storeAutoZoneKey] = {};
        }

        this[storeZoneKey].enableMultiSelection();
        
        this.tiebreakerFeaturesForSingleSelection = (features) => {
            return getLastChildOfPolygonSet(features.filter((feature) => this.getFeatureItemType(feature)
                === ItemType.ZONE), {
                getPolygonKey: (feature) => feature.get('id'),
                getPolygonCoordinates: (feature) => feature.getGeometry().getCoordinates(),
            });
        };
        
        this.subscribe('measurementSystemUpdated', () => this.redrawFeatures());
        this.subscribe('onFinishTranslate', ({ event }) => this.finishTranslateZones(event));
        this.subscribe('onStartTranslate', (event) => this.startTranslateZones(event));
        this.subscribe('onTranslating', (event) => {
            this.translatingZones(event);
            // this.collisionDetectionEvent(this.featuresCollection?.getArray().filter(feature => this.getFeatureItemType(feature) === ItemType.ZONE));
        });
        this.subscribe('onFeatureChange', () => {
            // this.collisionDetectionEvent(this.featuresCollection?.getArray().filter(feature => this.getFeatureItemType(feature) === ItemType.ZONE));
        });
        this.subscribe('onFinishModify', ({ event, action: { featureType } }) => {
            if (featureType === ItemType.ZONE) {
                const zonesAfterChanges = this.featuresCollection.getArray()
                                        .filter(feature => this.getFeatureItemType(feature) === ItemType.ZONE)
                                        .map(this.getFeatureData.bind(this));
                this.observer?.executeEvent('onSaveZoneChanges', {
                    zonesBeforeChanges: this.zones,
                    zonesAfterChanges,
                    takeASnapshot: true,
                    event: 'finishModifyZones',
                });
            }
        });
        this.subscribe('onMenuItemClick', ({ event, menu, feature }) => {
            switch (menu?.menuType) {
                case MenuType.EDIT:
                    this.observer?.executeEvent('onEditZone', {
                        zone: {
                            ...this.getFeatureData(feature),
                            itemType: this.getFeatureItemType(feature),
                            itemId: this.getFeatureKey(feature),
                            itemLabel: this.getFeatureLabel(feature),
                        },
                        event: 'onEditZone',
                    });
                    break;
                case MenuType.DELETE:
                    this.observer?.executeEvent('onDeleteZone', {
                        zone: {
                            ...this.getFeatureData(feature),
                            itemType: this.getFeatureItemType(feature),
                            itemId: this.getFeatureKey(feature),
                            itemLabel: this.getFeatureLabel(feature),
                        },
                        event: 'onDeleteZone',
                    });
                    break;
                default:
            }
        });
        // this.subscribe(
        //     'layerUpdated',
        //     () => this.collisionDetectionEvent(this.featuresCollection?.getArray().filter(feature => this.getFeatureItemType(feature) === ItemType.ZONE)),
        // );
        this.showLabels = false;
        this.zones = [];
        this.DISTANCE_MINIMUM_GAP = 0.01;
    }
    
    getFeatureKey(feature) {
        return feature.get('id');
    }
    
    getFeatureLabel(feature) {
        return feature.get('name');
    }
    
    getFeatureItemType(feature) {
        return feature.get('itemType');
    }
    
    getFeatureData(feature) {
        return {
            id: feature.get('id'),
            itemType: ItemType.ZONE,
            isDraft: feature.get('isDraft'),
            notSaved: feature.get('notSaved'),
            childrenZones: feature.get('childrenZones'),
            color: feature.get('color'),
            name: feature.get('name'),
            parentId: feature.get('parentId'),
            type: feature.get('type'),
            coords: feature.getGeometry().getCoordinates()[0],
            isDeleted:feature.get('isDeleted')||false,
            isNew: feature.get('isNew'),
        };
    }
    
    getFeaturesDataArray() {
        return this.featuresCollection.getArray().map((feature) => this.getFeatureData(feature));
    }

    getFeaturesDataArrayByItemType(itemType) {
        return this.featuresCollection.getArray().filter(feature => this.getFeatureItemType(feature) === itemType).map((feature) => this.getFeatureData(feature));
    }
    
    getFeatureCoordinates(feature) {
        return feature.getGeometry().getCoordinates();
    }
    
    getItemKey(item) {
        return item.id;
    }
    
    getItemCoordinates(item) {
        return item.coords;
    }
    
    setFeatureCoordinates(feature, coordinates) {
        feature.setGeometry(new Polygon(coordinates));
    }
    
    redrawFeatures() {

        this.featuresCollection.clear();
        if(this.visibleItemTypes?.includes(ItemType.AUTO_ZONE)){
            this.redrawAutoZones();
        }
        if(this.visibleItemTypes?.includes(ItemType.ZONE)){
            this.redrawZones();
        }
    }

    redrawZones() {
        if (this.zones) {
            for (const zone of this.zones) {
                this.featuresCollection.push(zoneItemToFeature.bind(this)(zone, storeZoneKey));
            }
        }
        this[storeZoneKey].updateSelectedItemsFeatureCollection();
    }

    redrawAutoZones() {
        if (this.autoZones) {
            for (const autoZone of this.autoZones) {
                this.featuresCollection.push(autoZoneItemToFeature.bind(this)(autoZone, storeAutoZoneKey));
            }
        }
        this[storeAutoZoneKey].updateSelectedItemsFeatureCollection();
    }
    
    deleteZones(deleteChildren) {
        if (deleteChildren) {
            const selectedZones = this[storeZoneKey].selectedItemsFeatureCollection.getArray()
                .map(feature => this.getFeatureData(feature));
            this.observer?.executeEvent('onDeleteZones', {
                takeASnapshot: true,
                event: 'deleteZones',
                zonesToDelete: selectedZones,
                withCleanSelection: true,
            });
        } else {
            for (const feature of this[storeZoneKey].selectedItemsFeatureCollection.getArray()) {
                this.featuresCollection.remove(feature);
            }
            const zonesAfterChanges = this.featuresCollection.getArray()
                .filter(feature => this.getFeatureItemType(feature) === ItemType.ZONE)
                .map(this.getFeatureData.bind(this)); // filter only zones

            this.observer?.executeEvent('onSaveZoneChanges', {
                zonesBeforeChanges: this.zones,
                zonesAfterChanges,
                takeASnapshot: true,
                event: 'deleteZones',
            });
        }
    }
    
    getZoneData(feature) {
        const childrenZones = feature.values_?.childrenZones;
        const color = feature.values_?.color;
        const coords = feature.getGeometry().getCoordinates()[0];
        const extent = feature.getGeometry().getExtent();
        const id = feature.values_?.id;
        const mapId = feature.values_?.mapId;
        const name = feature.values_?.name;
        const parentId = feature.values_?.parentId;
        const type = feature.values_?.type;
        const isDraft = feature.values_?.isDraft || false;
        const notSaved = feature.values_?.notSaved || false;
        const isDeleted = feature.values_?.isDeleted || false;
        const isNew = feature.values_?.isNew || false;

        return {
            childrenZones,
            color,
            coords,
            extent,
            id,
            mapId,
            name,
            parentId,
            type,
            isDraft,
            notSaved,
            isDeleted,
            isNew
        };
    }
    
    setZones(zones) {
        if (zones !== this.zones) {
            this.zones = zones;
            this.redrawFeatures();
        }
    }

    setAutoZones(autoZones) {
        if (autoZones !== this.autoZones) {
            this.autoZones = autoZones;
            this.redrawFeatures();
        }
    }
    
    setShowLabels(showLabels) {
        if (this.showLabels !== showLabels) {
            this.showLabels = showLabels;
            this.updateLayer();
        }
    }

    setVisibleItemTypes(visibleItemTypes) { 
        if (visibleItemTypes !== this.visibleItemTypes) {
            this.visibleItemTypes = visibleItemTypes;
            this.redrawFeatures();
        }
    }
}

export default OlConfigZones;
