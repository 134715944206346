/* eslint-disable */
import _Object$assign from 'babel-runtime/core-js/object/assign';
import _extends from 'babel-runtime/helpers/extends';
import _Object$getPrototypeOf from 'babel-runtime/core-js/object/get-prototype-of';
import _classCallCheck from 'babel-runtime/helpers/classCallCheck';
import _createClass from 'babel-runtime/helpers/createClass';
import _possibleConstructorReturn from 'babel-runtime/helpers/possibleConstructorReturn';
import _inherits from 'babel-runtime/helpers/inherits';
import * as React from 'react';
import clsx from 'clsx';
import calculateSizeAndPositionDataAndUpdateScrollOffset
    from 'react-virtualized/dist/es/Grid/utils/calculateSizeAndPositionDataAndUpdateScrollOffset';
import ScalingCellSizeAndPositionManager from 'react-virtualized/dist/es/Grid/utils/ScalingCellSizeAndPositionManager';
import createCallbackMemoizer from 'react-virtualized/dist/es/utils/createCallbackMemoizer';
import defaultOverscanIndicesGetter, {
    SCROLL_DIRECTION_BACKWARD,
    SCROLL_DIRECTION_FORWARD
} from 'react-virtualized/dist/es/Grid/defaultOverscanIndicesGetter';
import updateScrollIndexHelper from 'react-virtualized/dist/es/Grid/utils/updateScrollIndexHelper';
import defaultCellRangeRenderer from 'react-virtualized/dist/es/Grid/defaultCellRangeRenderer';
import scrollbarSize from 'dom-helpers/scrollbarSize';
import {polyfill} from 'react-lifecycles-compat';
import {cancelAnimationTimeout, requestAnimationTimeout} from 'react-virtualized/dist/es/utils/requestAnimationTimeout';


import {
    bpfrpt_proptype_Alignment,
    bpfrpt_proptype_CellRangeRenderer,
    bpfrpt_proptype_CellRenderer,
    bpfrpt_proptype_CellSize,
    bpfrpt_proptype_NoContentRenderer,
    bpfrpt_proptype_OverscanIndicesGetter
} from 'react-virtualized/dist/es/Grid/types';
import PropTypes from 'prop-types';

/**
 * Specifies the number of milliseconds during which to disable pointer events while a scroll is in progress.
 * This improves performance and makes scrolling smoother.
 */
export var DEFAULT_SCROLLING_RESET_TIME_INTERVAL = 150;

/**
 * Controls whether the CustomGridVirtualized updates the DOM element's scrollLeft/scrollTop based on the current state or just observes it.
 * This prevents CustomGridVirtualized from interrupting mouse-wheel animations (see issue #2).
 */
var SCROLL_POSITION_CHANGE_REASONS = {
    OBSERVED: 'observed',
    REQUESTED: 'requested'
};

var renderNull = function renderNull() {
    return null;
};

/**
 * Renders tabular data with virtualization along the vertical and horizontal axes.
 * Row heights and column widths must be known ahead of time and specified as properties.
 */
var CustomGridVirtualized = function (_React$PureComponent) {
    _inherits(CustomGridVirtualized, _React$PureComponent);

    // Invokes onSectionRendered callback only when start/stop row or column indices change
    function CustomGridVirtualized(props) {
        _classCallCheck(this, CustomGridVirtualized);

        var _this = _possibleConstructorReturn(this, (CustomGridVirtualized.__proto__ || _Object$getPrototypeOf(CustomGridVirtualized)).call(this, props));

        _this._onGridRenderedMemoizer = createCallbackMemoizer();
        _this._onScrollMemoizer = createCallbackMemoizer(false);
        _this._deferredInvalidateColumnIndex = null;
        _this._deferredInvalidateRowIndex = null;
        _this._recomputeScrollLeftFlag = false;
        _this._recomputeScrollTopFlag = false;
        _this._horizontalScrollBarSize = 0;
        _this._verticalScrollBarSize = 0;
        _this._scrollbarPresenceChanged = false;
        _this._renderedColumnStartIndex = 0;
        _this._renderedColumnStopIndex = 0;
        _this._renderedRowStartIndex = 0;
        _this._renderedRowStopIndex = 0;
        _this._styleCache = {};
        _this._cellCache = {};

        _this._debounceScrollEndedCallback = function () {
            _this._disablePointerEventsTimeoutId = null;
            // isScrolling is used to determine if we reset styleCache
            _this.setState({
                isScrolling: false,
                needToResetStyleCache: false
            });
        };

        _this._invokeOnGridRenderedHelper = function () {
            var onSectionRendered = _this.props.onSectionRendered;


            _this._onGridRenderedMemoizer({
                callback: onSectionRendered,
                indices: {
                    columnOverscanStartIndex: _this._columnStartIndex,
                    columnOverscanStopIndex: _this._columnStopIndex,
                    columnStartIndex: _this._renderedColumnStartIndex,
                    columnStopIndex: _this._renderedColumnStopIndex,
                    rowOverscanStartIndex: _this._rowStartIndex,
                    rowOverscanStopIndex: _this._rowStopIndex,
                    rowStartIndex: _this._renderedRowStartIndex,
                    rowStopIndex: _this._renderedRowStopIndex
                }
            });
        };

        _this._setScrollingContainerRef = function (ref) {
            _this._scrollingContainer = ref;
        };

        _this._onScroll = function (event) {
            // In certain edge-cases React dispatches an onScroll event with an invalid target.scrollLeft / target.scrollTop.
            // This invalid event can be detected by comparing event.target to this component's scrollable DOM element.
            // See issue #404 for more information.
            if (event.target === _this._scrollingContainer) {
                _this.handleScrollEvent(event.target);
            }
        };

        var columnSizeAndPositionManager = new ScalingCellSizeAndPositionManager({
            cellCount: props.columnCount,
            cellSizeGetter: function cellSizeGetter(params) {
                return CustomGridVirtualized._wrapSizeGetter(props.columnWidth)(params);
            },
            estimatedCellSize: CustomGridVirtualized._getEstimatedColumnSize(props)
        });
        var rowSizeAndPositionManager = new ScalingCellSizeAndPositionManager({
            cellCount: props.rowCount,
            cellSizeGetter: function cellSizeGetter(params) {
                return CustomGridVirtualized._wrapSizeGetter(props.rowHeight)(params);
            },
            estimatedCellSize: CustomGridVirtualized._getEstimatedRowSize(props)
        });

        _this.state = {
            instanceProps: {
                columnSizeAndPositionManager: columnSizeAndPositionManager,
                rowSizeAndPositionManager: rowSizeAndPositionManager,

                prevColumnWidth: props.columnWidth,
                prevRowHeight: props.rowHeight,
                prevColumnCount: props.columnCount,
                prevRowCount: props.rowCount,
                prevIsScrolling: props.isScrolling === true,
                prevScrollToColumn: props.scrollToColumn,
                prevScrollToRow: props.scrollToRow,

                scrollbarSize: 0,
                scrollbarSizeMeasured: false
            },
            isScrolling: false,
            scrollDirectionHorizontal: SCROLL_DIRECTION_FORWARD,
            scrollDirectionVertical: SCROLL_DIRECTION_FORWARD,
            scrollLeft: 0,
            scrollTop: 0,
            scrollPositionChangeReason: null,

            needToResetStyleCache: false
        };

        if (props.scrollToRow > 0) {
            _this._initialScrollTop = _this._getCalculatedScrollTop(props, _this.state);
        }
        if (props.scrollToColumn > 0) {
            _this._initialScrollLeft = _this._getCalculatedScrollLeft(props, _this.state);
        }
        return _this;
    }

    /**
     * Gets offsets for a given cell and alignment.
     */


    _createClass(CustomGridVirtualized, [{
        key: 'getOffsetForCell',
        value: function getOffsetForCell() {
            var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
                _ref$alignment = _ref.alignment,
                alignment = _ref$alignment === undefined ? this.props.scrollToAlignment : _ref$alignment,
                _ref$columnIndex = _ref.columnIndex,
                columnIndex = _ref$columnIndex === undefined ? this.props.scrollToColumn : _ref$columnIndex,
                _ref$rowIndex = _ref.rowIndex,
                rowIndex = _ref$rowIndex === undefined ? this.props.scrollToRow : _ref$rowIndex;

            var offsetProps = _extends({}, this.props, {
                scrollToAlignment: alignment,
                scrollToColumn: columnIndex,
                scrollToRow: rowIndex
            });

            return {
                scrollLeft: this._getCalculatedScrollLeft(offsetProps),
                scrollTop: this._getCalculatedScrollTop(offsetProps)
            };
        }

        /**
         * Gets estimated total rows' height.
         */

    }, {
        key: 'getTotalRowsHeight',
        value: function getTotalRowsHeight() {
            return this.state.instanceProps.rowSizeAndPositionManager.getTotalSize();
        }

        /**
         * Gets estimated total columns' width.
         */

    }, {
        key: 'getTotalColumnsWidth',
        value: function getTotalColumnsWidth() {
            return this.state.instanceProps.columnSizeAndPositionManager.getTotalSize();
        }

        /**
         * This method handles a scroll event originating from an external scroll control.
         * It's an advanced method and should probably not be used unless you're implementing a custom scroll-bar solution.
         */

    }, {
        key: 'handleScrollEvent',
        value: function handleScrollEvent(_ref2) {
            var _ref2$scrollLeft = _ref2.scrollLeft,
                scrollLeftParam = _ref2$scrollLeft === undefined ? 0 : _ref2$scrollLeft,
                _ref2$scrollTop = _ref2.scrollTop,
                scrollTopParam = _ref2$scrollTop === undefined ? 0 : _ref2$scrollTop;

            // On iOS, we can arrive at negative offsets by swiping past the start.
            // To prevent flicker here, we make playing in the negative offset zone cause nothing to happen.
            if (scrollTopParam < 0) {
                return;
            }

            // Prevent pointer events from interrupting a smooth scroll
            this._debounceScrollEnded();

            var _props = this.props,
                autoHeight = _props.autoHeight,
                autoWidth = _props.autoWidth,
                height = _props.height,
                width = _props.width;
            var instanceProps = this.state.instanceProps;

            // When this component is shrunk drastically, React dispatches a series of back-to-back scroll events,
            // Gradually converging on a scrollTop that is within the bounds of the new, smaller height.
            // This causes a series of rapid renders that is slow for long lists.
            // We can avoid that by doing some simple bounds checking to ensure that scroll offsets never exceed their bounds.

            var scrollbarSize = instanceProps.scrollbarSize;
            var totalRowsHeight = instanceProps.rowSizeAndPositionManager.getTotalSize();
            var totalColumnsWidth = instanceProps.columnSizeAndPositionManager.getTotalSize();
            var scrollLeft = Math.min(Math.max(0, totalColumnsWidth - width + scrollbarSize), scrollLeftParam);
            var scrollTop = Math.min(Math.max(0, totalRowsHeight - height + scrollbarSize), scrollTopParam);

            // Certain devices (like Apple touchpad) rapid-fire duplicate events.
            // Don't force a re-render if this is the case.
            // The mouse may move faster then the animation frame does.
            // Use requestAnimationFrame to avoid over-updating.
            if (this.state.scrollLeft !== scrollLeft || this.state.scrollTop !== scrollTop) {
                // Track scrolling direction so we can more efficiently overscan rows to reduce empty space around the edges while scrolling.
                // Don't change direction for an axis unless scroll offset has changed.
                var _scrollDirectionHorizontal = scrollLeft !== this.state.scrollLeft ? scrollLeft > this.state.scrollLeft ? SCROLL_DIRECTION_FORWARD : SCROLL_DIRECTION_BACKWARD : this.state.scrollDirectionHorizontal;
                var _scrollDirectionVertical = scrollTop !== this.state.scrollTop ? scrollTop > this.state.scrollTop ? SCROLL_DIRECTION_FORWARD : SCROLL_DIRECTION_BACKWARD : this.state.scrollDirectionVertical;

                var newState = {
                    isScrolling: true,
                    scrollDirectionHorizontal: _scrollDirectionHorizontal,
                    scrollDirectionVertical: _scrollDirectionVertical,
                    scrollPositionChangeReason: SCROLL_POSITION_CHANGE_REASONS.OBSERVED
                };

                if (!autoHeight) {
                    newState.scrollTop = scrollTop;
                }

                if (!autoWidth) {
                    newState.scrollLeft = scrollLeft;
                }

                newState.needToResetStyleCache = false;
                this.setState(newState);
            }

            this._invokeOnScrollMemoizer({
                scrollLeft: scrollLeft,
                scrollTop: scrollTop,
                totalColumnsWidth: totalColumnsWidth,
                totalRowsHeight: totalRowsHeight
            });
        }

        /**
         * Invalidate CustomGridVirtualized size and recompute visible cells.
         * This is a deferred wrapper for recomputeGridSize().
         * It sets a flag to be evaluated on cDM/cDU to avoid unnecessary renders.
         * This method is intended for advanced use-cases like CellMeasurer.
         */
        // @TODO (bvaughn) Add automated test coverage for this.

    }, {
        key: 'invalidateCellSizeAfterRender',
        value: function invalidateCellSizeAfterRender(_ref3) {
            var columnIndex = _ref3.columnIndex,
                rowIndex = _ref3.rowIndex;

            this._deferredInvalidateColumnIndex = typeof this._deferredInvalidateColumnIndex === 'number' ? Math.min(this._deferredInvalidateColumnIndex, columnIndex) : columnIndex;
            this._deferredInvalidateRowIndex = typeof this._deferredInvalidateRowIndex === 'number' ? Math.min(this._deferredInvalidateRowIndex, rowIndex) : rowIndex;
        }

        /**
         * Pre-measure all columns and rows in a CustomGridVirtualized.
         * Typically cells are only measured as needed and estimated sizes are used for cells that have not yet been measured.
         * This method ensures that the next call to getTotalSize() returns an exact size (as opposed to just an estimated one).
         */

    }, {
        key: 'measureAllCells',
        value: function measureAllCells() {
            var _props2 = this.props,
                columnCount = _props2.columnCount,
                rowCount = _props2.rowCount;
            var instanceProps = this.state.instanceProps;

            instanceProps.columnSizeAndPositionManager.getSizeAndPositionOfCell(columnCount - 1);
            instanceProps.rowSizeAndPositionManager.getSizeAndPositionOfCell(rowCount - 1);
        }

        /**
         * Forced recompute of row heights and column widths.
         * This function should be called if dynamic column or row sizes have changed but nothing else has.
         * Since CustomGridVirtualized only receives :columnCount and :rowCount it has no way of detecting when the underlying data changes.
         */

    }, {
        key: 'recomputeGridSize',
        value: function recomputeGridSize() {
            var _ref4 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
                _ref4$columnIndex = _ref4.columnIndex,
                columnIndex = _ref4$columnIndex === undefined ? 0 : _ref4$columnIndex,
                _ref4$rowIndex = _ref4.rowIndex,
                rowIndex = _ref4$rowIndex === undefined ? 0 : _ref4$rowIndex;

            var _props3 = this.props,
                scrollToColumn = _props3.scrollToColumn,
                scrollToRow = _props3.scrollToRow;
            var instanceProps = this.state.instanceProps;


            instanceProps.columnSizeAndPositionManager.resetCell(columnIndex);
            instanceProps.rowSizeAndPositionManager.resetCell(rowIndex);

            // Cell sizes may be determined by a function property.
            // In this case the cDU handler can't know if they changed.
            // Store this flag to let the next cDU pass know it needs to recompute the scroll offset.
            this._recomputeScrollLeftFlag = scrollToColumn >= 0 && (this.state.scrollDirectionHorizontal === SCROLL_DIRECTION_FORWARD ? columnIndex <= scrollToColumn : columnIndex >= scrollToColumn);
            this._recomputeScrollTopFlag = scrollToRow >= 0 && (this.state.scrollDirectionVertical === SCROLL_DIRECTION_FORWARD ? rowIndex <= scrollToRow : rowIndex >= scrollToRow);

            // Clear cell cache in case we are scrolling;
            // Invalid row heights likely mean invalid cached content as well.
            this._styleCache = {};
            this._cellCache = {};

            this.forceUpdate();
        }

        /**
         * Ensure column and row are visible.
         */

    }, {
        key: 'scrollToCell',
        value: function scrollToCell(_ref5) {
            var columnIndex = _ref5.columnIndex,
                rowIndex = _ref5.rowIndex;
            var columnCount = this.props.columnCount;


            var props = this.props;

            // Don't adjust scroll offset for single-column grids (eg List, Table).
            // This can cause a funky scroll offset because of the vertical scrollbar width.
            if (columnCount > 1 && columnIndex !== undefined) {
                this._updateScrollLeftForScrollToColumn(_extends({}, props, {
                    scrollToColumn: columnIndex
                }));
            }

            if (rowIndex !== undefined) {
                this._updateScrollTopForScrollToRow(_extends({}, props, {
                    scrollToRow: rowIndex
                }));
            }
        }
    }, {
        key: 'componentDidMount',
        value: function componentDidMount() {
            var _props4 = this.props,
                getScrollbarSize = _props4.getScrollbarSize,
                height = _props4.height,
                scrollLeft = _props4.scrollLeft,
                scrollToColumn = _props4.scrollToColumn,
                scrollTop = _props4.scrollTop,
                scrollToRow = _props4.scrollToRow,
                width = _props4.width;
            var instanceProps = this.state.instanceProps;

            // Reset initial offsets to be ignored in browser

            this._initialScrollTop = 0;
            this._initialScrollLeft = 0;

            // If cell sizes have been invalidated (eg we are using CellMeasurer) then reset cached positions.
            // We must do this at the start of the method as we may calculate and update scroll position below.
            this._handleInvalidatedGridSize();

            // If this component was first rendered server-side, scrollbar size will be undefined.
            // In that event we need to remeasure.
            if (!instanceProps.scrollbarSizeMeasured) {
                this.setState(function (prevState) {
                    var stateUpdate = _extends({}, prevState, { needToResetStyleCache: false });
                    stateUpdate.instanceProps.scrollbarSize = getScrollbarSize();
                    stateUpdate.instanceProps.scrollbarSizeMeasured = true;
                    return stateUpdate;
                });
            }

            if (typeof scrollLeft === 'number' && scrollLeft >= 0 || typeof scrollTop === 'number' && scrollTop >= 0) {
                var stateUpdate = CustomGridVirtualized._getScrollToPositionStateUpdate({
                    prevState: this.state,
                    scrollLeft: scrollLeft,
                    scrollTop: scrollTop
                });
                if (stateUpdate) {
                    stateUpdate.needToResetStyleCache = false;
                    this.setState(stateUpdate);
                }
            }

            // refs don't work in `react-test-renderer`
            if (this._scrollingContainer) {
                // setting the ref's scrollLeft and scrollTop.
                // Somehow in MultiGrid the main grid doesn't trigger a update on mount.
                if (this._scrollingContainer.scrollLeft !== this.state.scrollLeft) {
                    this._scrollingContainer.scrollLeft = this.state.scrollLeft;
                }
                if (this._scrollingContainer.scrollTop !== this.state.scrollTop) {
                    this._scrollingContainer.scrollTop = this.state.scrollTop;
                }
            }

            // Don't update scroll offset if the size is 0; we don't render any cells in this case.
            // Setting a state may cause us to later thing we've updated the offce when we haven't.
            var sizeIsBiggerThanZero = height > 0 && width > 0;
            if (scrollToColumn >= 0 && sizeIsBiggerThanZero) {
                this._updateScrollLeftForScrollToColumn();
            }
            if (scrollToRow >= 0 && sizeIsBiggerThanZero) {
                this._updateScrollTopForScrollToRow();
            }

            // Update onRowsRendered callback
            this._invokeOnGridRenderedHelper();

            // Initialize onScroll callback
            this._invokeOnScrollMemoizer({
                scrollLeft: scrollLeft || 0,
                scrollTop: scrollTop || 0,
                totalColumnsWidth: instanceProps.columnSizeAndPositionManager.getTotalSize(),
                totalRowsHeight: instanceProps.rowSizeAndPositionManager.getTotalSize()
            });

            this._maybeCallOnScrollbarPresenceChange();
        }

        /**
         * @private
         * This method updates scrollLeft/scrollTop in state for the following conditions:
         * 1) New scroll-to-cell props have been set
         */

    }, {
        key: 'componentDidUpdate',
        value: function componentDidUpdate(prevProps, prevState) {
            var _this2 = this;

            var _props5 = this.props,
                autoHeight = _props5.autoHeight,
                autoWidth = _props5.autoWidth,
                columnCount = _props5.columnCount,
                height = _props5.height,
                rowCount = _props5.rowCount,
                scrollToAlignment = _props5.scrollToAlignment,
                scrollToColumn = _props5.scrollToColumn,
                scrollToRow = _props5.scrollToRow,
                width = _props5.width;
            var _state = this.state,
                scrollLeft = _state.scrollLeft,
                scrollPositionChangeReason = _state.scrollPositionChangeReason,
                scrollTop = _state.scrollTop,
                instanceProps = _state.instanceProps;
            // If cell sizes have been invalidated (eg we are using CellMeasurer) then reset cached positions.
            // We must do this at the start of the method as we may calculate and update scroll position below.

            this._handleInvalidatedGridSize();

            // Handle edge case where column or row count has only just increased over 0.
            // In this case we may have to restore a previously-specified scroll offset.
            // For more info see bvaughn/react-virtualized/issues/218
            var columnOrRowCountJustIncreasedFromZero = columnCount > 0 && prevProps.columnCount === 0 || rowCount > 0 && prevProps.rowCount === 0;

            // Make sure requested changes to :scrollLeft or :scrollTop get applied.
            // Assigning to scrollLeft/scrollTop tells the browser to interrupt any running scroll animations,
            // And to discard any pending async changes to the scroll position that may have happened in the meantime (e.g. on a separate scrolling thread).
            // So we only set these when we require an adjustment of the scroll position.
            // See issue #2 for more information.
            if (scrollPositionChangeReason === SCROLL_POSITION_CHANGE_REASONS.REQUESTED) {
                // @TRICKY :autoHeight and :autoWidth properties instructs CustomGridVirtualized to leave :scrollTop and :scrollLeft management to an external HOC (eg WindowScroller).
                // In this case we should avoid checking scrollingContainer.scrollTop and scrollingContainer.scrollLeft since it forces layout/flow.
                if (!autoWidth && scrollLeft >= 0 && (scrollLeft !== this._scrollingContainer.scrollLeft || columnOrRowCountJustIncreasedFromZero)) {
                    this._scrollingContainer.scrollLeft = scrollLeft;
                }
                if (!autoHeight && scrollTop >= 0 && (scrollTop !== this._scrollingContainer.scrollTop || columnOrRowCountJustIncreasedFromZero)) {
                    this._scrollingContainer.scrollTop = scrollTop;
                }
            }

            // Special case where the previous size was 0:
            // In this case we don't show any windowed cells at all.
            // So we should always recalculate offset afterwards.
            var sizeJustIncreasedFromZero = (prevProps.width === 0 || prevProps.height === 0) && height > 0 && width > 0;

            // Update scroll offsets if the current :scrollToColumn or :scrollToRow values requires it
            // @TODO Do we also need this check or can the one in componentWillUpdate() suffice?
            if (this._recomputeScrollLeftFlag) {
                this._recomputeScrollLeftFlag = false;
                this._updateScrollLeftForScrollToColumn(this.props);
            } else {
                updateScrollIndexHelper({
                    cellSizeAndPositionManager: instanceProps.columnSizeAndPositionManager,
                    previousCellsCount: prevProps.columnCount,
                    previousCellSize: prevProps.columnWidth,
                    previousScrollToAlignment: prevProps.scrollToAlignment,
                    previousScrollToIndex: prevProps.scrollToColumn,
                    previousSize: prevProps.width,
                    scrollOffset: scrollLeft,
                    scrollToAlignment: scrollToAlignment,
                    scrollToIndex: scrollToColumn,
                    size: width,
                    sizeJustIncreasedFromZero: sizeJustIncreasedFromZero,
                    updateScrollIndexCallback: function updateScrollIndexCallback() {
                        return _this2._updateScrollLeftForScrollToColumn(_this2.props);
                    }
                });
            }

            if (this._recomputeScrollTopFlag) {
                this._recomputeScrollTopFlag = false;
                this._updateScrollTopForScrollToRow(this.props);
            } else {
                updateScrollIndexHelper({
                    cellSizeAndPositionManager: instanceProps.rowSizeAndPositionManager,
                    previousCellsCount: prevProps.rowCount,
                    previousCellSize: prevProps.rowHeight,
                    previousScrollToAlignment: prevProps.scrollToAlignment,
                    previousScrollToIndex: prevProps.scrollToRow,
                    previousSize: prevProps.height,
                    scrollOffset: scrollTop,
                    scrollToAlignment: scrollToAlignment,
                    scrollToIndex: scrollToRow,
                    size: height,
                    sizeJustIncreasedFromZero: sizeJustIncreasedFromZero,
                    updateScrollIndexCallback: function updateScrollIndexCallback() {
                        return _this2._updateScrollTopForScrollToRow(_this2.props);
                    }
                });
            }

            // Update onRowsRendered callback if start/stop indices have changed
            this._invokeOnGridRenderedHelper();

            // Changes to :scrollLeft or :scrollTop should also notify :onScroll listeners
            if (scrollLeft !== prevState.scrollLeft || scrollTop !== prevState.scrollTop) {
                var totalRowsHeight = instanceProps.rowSizeAndPositionManager.getTotalSize();
                var totalColumnsWidth = instanceProps.columnSizeAndPositionManager.getTotalSize();

                this._invokeOnScrollMemoizer({
                    scrollLeft: scrollLeft,
                    scrollTop: scrollTop,
                    totalColumnsWidth: totalColumnsWidth,
                    totalRowsHeight: totalRowsHeight
                });
            }

            this._maybeCallOnScrollbarPresenceChange();
        }
    }, {
        key: 'componentWillUnmount',
        value: function componentWillUnmount() {
            if (this._disablePointerEventsTimeoutId) {
                cancelAnimationTimeout(this._disablePointerEventsTimeoutId);
            }
        }

        /**
         * This method updates scrollLeft/scrollTop in state for the following conditions:
         * 1) Empty content (0 rows or columns)
         * 2) New scroll props overriding the current state
         * 3) Cells-count or cells-size has changed, making previous scroll offsets invalid
         */

    }, {
        key: 'render',
        value: function render() {
            var _props6 = this.props,
                autoContainerWidth = _props6.autoContainerWidth,
                autoHeight = _props6.autoHeight,
                autoWidth = _props6.autoWidth,
                className = _props6.className,
                containerProps = _props6.containerProps,
                containerRole = _props6.containerRole,
                containerStyle = _props6.containerStyle,
                height = _props6.height,
                id = _props6.id,
                noContentRenderer = _props6.noContentRenderer,
                role = _props6.role,
                style = _props6.style,
                tabIndex = _props6.tabIndex,
                width = _props6.width,
                // Modified code added parameters.
                modernBrowser=_props6.modernBrowser,
                heightStaticRow=_props6.heightStaticRow;
                // --------
            var _state2 = this.state,
                instanceProps = _state2.instanceProps,
                needToResetStyleCache = _state2.needToResetStyleCache;


            var isScrolling = this._isScrolling();

            var gridStyle = {
                boxSizing: 'border-box',
                direction: 'ltr',
                height: autoHeight ? 'auto' : height+heightStaticRow,// Modified Code.
                position: 'relative',
                width: autoWidth ? 'auto' : width,
                WebkitOverflowScrolling: 'touch',
                willChange: 'transform'
            };

            if (needToResetStyleCache) {
                this._styleCache = {};
            }

            // calculate _styleCache here
            // if state.isScrolling (not from _isScrolling) then reset
            if (!this.state.isScrolling) {
                this._resetStyleCache();
            }

            // calculate children to render here
            this._calculateChildrenToRender(this.props, this.state);

            var totalColumnsWidth = instanceProps.columnSizeAndPositionManager.getTotalSize();
            var totalRowsHeight = instanceProps.rowSizeAndPositionManager.getTotalSize();

            // Force browser to hide scrollbars when we know they aren't necessary.
            // Otherwise once scrollbars appear they may not disappear again.
            // For more info see issue #116
            var verticalScrollBarSize = totalRowsHeight > height ? instanceProps.scrollbarSize : 0;
            var horizontalScrollBarSize = totalColumnsWidth > width ? instanceProps.scrollbarSize : 0;

            if (horizontalScrollBarSize !== this._horizontalScrollBarSize || verticalScrollBarSize !== this._verticalScrollBarSize) {
                this._horizontalScrollBarSize = horizontalScrollBarSize;
                this._verticalScrollBarSize = verticalScrollBarSize;
                this._scrollbarPresenceChanged = true;
            }

            // Also explicitly init styles to 'auto' if scrollbars are required.
            // This works around an obscure edge case where external CSS styles have not yet been loaded,
            // But an initial scroll index of offset is set as an external prop.
            // Without this style, CustomGridVirtualized would render the correct range of cells but would NOT update its internal offset.
            // This was originally reported via clauderic/react-infinite-calendar/issues/23
            gridStyle.overflowX = totalColumnsWidth + verticalScrollBarSize <= width ? 'hidden' : 'auto';
            gridStyle.overflowY = totalRowsHeight + horizontalScrollBarSize <= height ? 'hidden' : 'auto';

            var childrenToDisplay = this._childrenToDisplay;
            // Modified code
            var staticToDisplay = this._staticToDisplay;
            // --------
            var showNoContentRenderer = childrenToDisplay.length === 0 && height > 0 && width > 0;

            // Modified code
            var headerToDisplay = staticToDisplay.staticRow;
            var fixedColumnToDisplay = staticToDisplay.staticColumn;
            var fixedContentColumnToDisplay = staticToDisplay.staticContentColumn;
            var fixedRowColDisplay = staticToDisplay.staticRowCol;
            var fixedContentRowColDisplay = staticToDisplay.staticContentRowCol;
            var childrenToDisplayFiltered = [];
            // ------------

            return React.createElement(
                'div',
                _extends({
                    ref: this._setScrollingContainerRef
                }, containerProps, {
                    'aria-label': this.props['aria-label'],
                    'aria-readonly': this.props['aria-readonly'],
                    className: clsx('ReactVirtualized__Grid', className),
                    id: id,
                    onScroll: this._onScroll,
                    role: role,
                    style: _extends({}, gridStyle, style),
                    tabIndex: tabIndex }),
                // Modified code.
                React.createElement(
                    'div',
                    {
                        className: 'headerItem',
                    },
                    headerToDisplay
                ),
                React.createElement(
                    'div',
                    {
                        className: 'colRowHeaderItem',
                    },
                    fixedRowColDisplay
                ),
                React.createElement(
                    'div',
                    {
                        className: 'colRowHeaderItemOther',
                    },
                    fixedContentRowColDisplay
                ),
                React.createElement(
                    'div',
                    {
                        className: 'columnHeaderItem',
                        style: {marginTop:heightStaticRow+'px'},
                    },
                    fixedColumnToDisplay
                ),
                React.createElement(
                    'div',
                    {
                        className: 'columnHeaderItemOther',
                        style: {marginTop:heightStaticRow+'px'},
                    },
                    fixedContentColumnToDisplay
                ),
                React.createElement(
                    'div',
                    {
                        className: '',
                        style: {marginTop:heightStaticRow+'px'},
                    },
                    // ---------------
                childrenToDisplay.length > 0 && React.createElement(
                'div',
                {
                    className: 'ReactVirtualized__Grid__innerScrollContainer',
                    role: containerRole,
                    style: _extends({
                        width: autoContainerWidth ? 'auto' : totalColumnsWidth,
                        height: totalRowsHeight,
                        maxWidth: totalColumnsWidth,
                        maxHeight: totalRowsHeight,
                        overflow: 'hidden',
                        pointerEvents: isScrolling ? 'none' : '',
                        position: 'relative'
                    }, containerStyle) },
                childrenToDisplay
                ),
            ),// Modified code
                showNoContentRenderer && noContentRenderer()
            );
        }

        /* ---------------------------- Helper methods ---------------------------- */

    }, {
        key: '_calculateChildrenToRender',
        value: function _calculateChildrenToRender() {
            var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.props;
            var state = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.state;
            var cellRenderer = props.cellRenderer,
                cellRangeRenderer = props.cellRangeRenderer,
                // Modified code
                cellStaticRenderer = props.cellStaticRenderer,
                // ----
                columnCount = props.columnCount,
                deferredMeasurementCache = props.deferredMeasurementCache,
                height = props.height,
                overscanColumnCount = props.overscanColumnCount,
                overscanIndicesGetter = props.overscanIndicesGetter,
                overscanRowCount = props.overscanRowCount,
                rowCount = props.rowCount,
                width = props.width,
                isScrollingOptOut = props.isScrollingOptOut;
            var scrollDirectionHorizontal = state.scrollDirectionHorizontal,
                scrollDirectionVertical = state.scrollDirectionVertical,
                instanceProps = state.instanceProps;


            var scrollTop = this._initialScrollTop > 0 ? this._initialScrollTop : state.scrollTop;
            var scrollLeft = this._initialScrollLeft > 0 ? this._initialScrollLeft : state.scrollLeft;

            var isScrolling = this._isScrolling(props, state);

            this._childrenToDisplay = [];

            // Modified code
            this._staticToDisplay = [];
            //-----

            // Render only enough columns and rows to cover the visible area of the grid.
            if (height > 0 && width > 0) {
                var visibleColumnIndices = instanceProps.columnSizeAndPositionManager.getVisibleCellRange({
                    containerSize: width,
                    offset: scrollLeft
                });
                var visibleRowIndices = instanceProps.rowSizeAndPositionManager.getVisibleCellRange({
                    containerSize: height,
                    offset: scrollTop
                });

                var horizontalOffsetAdjustment = instanceProps.columnSizeAndPositionManager.getOffsetAdjustment({
                    containerSize: width,
                    offset: scrollLeft
                });
                var verticalOffsetAdjustment = instanceProps.rowSizeAndPositionManager.getOffsetAdjustment({
                    containerSize: height,
                    offset: scrollTop
                });

                // Store for _invokeOnGridRenderedHelper()
                this._renderedColumnStartIndex = visibleColumnIndices.start;
                this._renderedColumnStopIndex = visibleColumnIndices.stop;
                this._renderedRowStartIndex = visibleRowIndices.start;
                this._renderedRowStopIndex = visibleRowIndices.stop;

                var overscanColumnIndices = overscanIndicesGetter({
                    direction: 'horizontal',
                    cellCount: columnCount,
                    overscanCellsCount: overscanColumnCount,
                    scrollDirection: scrollDirectionHorizontal,
                    startIndex: typeof visibleColumnIndices.start === 'number' ? visibleColumnIndices.start : 0,
                    stopIndex: typeof visibleColumnIndices.stop === 'number' ? visibleColumnIndices.stop : -1
                });

                var overscanRowIndices = overscanIndicesGetter({
                    direction: 'vertical',
                    cellCount: rowCount,
                    overscanCellsCount: overscanRowCount,
                    scrollDirection: scrollDirectionVertical,
                    startIndex: typeof visibleRowIndices.start === 'number' ? visibleRowIndices.start : 0,
                    stopIndex: typeof visibleRowIndices.stop === 'number' ? visibleRowIndices.stop : -1
                });

                // Store for _invokeOnGridRenderedHelper()
                var columnStartIndex = overscanColumnIndices.overscanStartIndex;
                var columnStopIndex = overscanColumnIndices.overscanStopIndex;
                var rowStartIndex = overscanRowIndices.overscanStartIndex;
                var rowStopIndex = overscanRowIndices.overscanStopIndex;

                // Advanced use-cases (eg CellMeasurer) require batched measurements to determine accurate sizes.
                if (deferredMeasurementCache) {
                    // If rows have a dynamic height, scan the rows we are about to render.
                    // If any have not yet been measured, then we need to render all columns initially,
                    // Because the height of the row is equal to the tallest cell within that row,
                    // (And so we can't know the height without measuring all column-cells first).
                    if (!deferredMeasurementCache.hasFixedHeight()) {
                        for (var rowIndex = rowStartIndex; rowIndex <= rowStopIndex; rowIndex++) {
                            if (!deferredMeasurementCache.has(rowIndex, 0)) {
                                columnStartIndex = 0;
                                columnStopIndex = columnCount - 1;
                                break;
                            }
                        }
                    }

                    // If columns have a dynamic width, scan the columns we are about to render.
                    // If any have not yet been measured, then we need to render all rows initially,
                    // Because the width of the column is equal to the widest cell within that column,
                    // (And so we can't know the width without measuring all row-cells first).
                    if (!deferredMeasurementCache.hasFixedWidth()) {
                        for (var columnIndex = columnStartIndex; columnIndex <= columnStopIndex; columnIndex++) {
                            if (!deferredMeasurementCache.has(0, columnIndex)) {
                                rowStartIndex = 0;
                                rowStopIndex = rowCount - 1;
                                break;
                            }
                        }
                    }
                }

                this._childrenToDisplay = cellRangeRenderer({
                    cellCache: this._cellCache,
                    cellRenderer: cellRenderer,
                    columnSizeAndPositionManager: instanceProps.columnSizeAndPositionManager,
                    columnStartIndex: columnStartIndex,
                    columnStopIndex: columnStopIndex,
                    deferredMeasurementCache: deferredMeasurementCache,
                    horizontalOffsetAdjustment: horizontalOffsetAdjustment,
                    isScrolling: isScrolling,
                    isScrollingOptOut: isScrollingOptOut,
                    parent: this,
                    rowSizeAndPositionManager: instanceProps.rowSizeAndPositionManager,
                    rowStartIndex: rowStartIndex,
                    rowStopIndex: rowStopIndex,
                    scrollLeft: scrollLeft,
                    scrollTop: scrollTop,
                    styleCache: this._styleCache,
                    verticalOffsetAdjustment: verticalOffsetAdjustment,
                    visibleColumnIndices: visibleColumnIndices,
                    visibleRowIndices: visibleRowIndices
                });

                // Modified code
                this._staticToDisplay = cellStaticRenderer({
                    cellCache: this._cellCache,
                    cellRenderer: cellRenderer,
                    columnSizeAndPositionManager: instanceProps.columnSizeAndPositionManager,
                    columnStartIndex: columnStartIndex,
                    columnStopIndex: columnStopIndex,
                    deferredMeasurementCache: deferredMeasurementCache,
                    horizontalOffsetAdjustment: horizontalOffsetAdjustment,
                    isScrolling: isScrolling,
                    isScrollingOptOut: isScrollingOptOut,
                    parent: this,
                    rowSizeAndPositionManager: instanceProps.rowSizeAndPositionManager,
                    rowStartIndex: rowStartIndex,
                    rowStopIndex: rowStopIndex,
                    scrollLeft: scrollLeft,
                    scrollTop: scrollTop,
                    styleCache: this._styleCache,
                    verticalOffsetAdjustment: verticalOffsetAdjustment,
                    visibleColumnIndices: visibleColumnIndices,
                    visibleRowIndices: visibleRowIndices
                });
                //------

                // update the indices
                this._columnStartIndex = columnStartIndex;
                this._columnStopIndex = columnStopIndex;
                this._rowStartIndex = rowStartIndex;
                this._rowStopIndex = rowStopIndex;
            }
        }

        /**
         * Sets an :isScrolling flag for a small window of time.
         * This flag is used to disable pointer events on the scrollable portion of the CustomGridVirtualized.
         * This prevents jerky/stuttery mouse-wheel scrolling.
         */

    }, {
        key: '_debounceScrollEnded',
        value: function _debounceScrollEnded() {
            var scrollingResetTimeInterval = this.props.scrollingResetTimeInterval;


            if (this._disablePointerEventsTimeoutId) {
                cancelAnimationTimeout(this._disablePointerEventsTimeoutId);
            }

            this._disablePointerEventsTimeoutId = requestAnimationTimeout(this._debounceScrollEndedCallback, scrollingResetTimeInterval);
        }
    }, {
        key: '_handleInvalidatedGridSize',


        /**
         * Check for batched CellMeasurer size invalidations.
         * This will occur the first time one or more previously unmeasured cells are rendered.
         */
        value: function _handleInvalidatedGridSize() {
            if (typeof this._deferredInvalidateColumnIndex === 'number' && typeof this._deferredInvalidateRowIndex === 'number') {
                var columnIndex = this._deferredInvalidateColumnIndex;
                var rowIndex = this._deferredInvalidateRowIndex;

                this._deferredInvalidateColumnIndex = null;
                this._deferredInvalidateRowIndex = null;

                this.recomputeGridSize({ columnIndex: columnIndex, rowIndex: rowIndex });
            }
        }
    }, {
        key: '_invokeOnScrollMemoizer',
        value: function _invokeOnScrollMemoizer(_ref6) {
            var _this3 = this;

            var scrollLeft = _ref6.scrollLeft,
                scrollTop = _ref6.scrollTop,
                totalColumnsWidth = _ref6.totalColumnsWidth,
                totalRowsHeight = _ref6.totalRowsHeight;

            this._onScrollMemoizer({
                callback: function callback(_ref7) {
                    var scrollLeft = _ref7.scrollLeft,
                        scrollTop = _ref7.scrollTop;
                    var _props7 = _this3.props,
                        height = _props7.height,
                        onScroll = _props7.onScroll,
                        width = _props7.width;


                    onScroll({
                        clientHeight: height,
                        clientWidth: width,
                        scrollHeight: totalRowsHeight,
                        scrollLeft: scrollLeft,
                        scrollTop: scrollTop,
                        scrollWidth: totalColumnsWidth
                    });
                },
                indices: {
                    scrollLeft: scrollLeft,
                    scrollTop: scrollTop
                }
            });
        }
    }, {
        key: '_isScrolling',
        value: function _isScrolling() {
            var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.props;
            var state = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.state;

            // If isScrolling is defined in props, use it to override the value in state
            // This is a performance optimization for WindowScroller + CustomGridVirtualized
            return Object.hasOwnProperty.call(props, 'isScrolling') ? Boolean(props.isScrolling) : Boolean(state.isScrolling);
        }
    }, {
        key: '_maybeCallOnScrollbarPresenceChange',
        value: function _maybeCallOnScrollbarPresenceChange() {
            if (this._scrollbarPresenceChanged) {
                var _onScrollbarPresenceChange = this.props.onScrollbarPresenceChange;


                this._scrollbarPresenceChanged = false;

                _onScrollbarPresenceChange({
                    horizontal: this._horizontalScrollBarSize > 0,
                    size: this.state.instanceProps.scrollbarSize,
                    vertical: this._verticalScrollBarSize > 0
                });
            }
        }
    }, {
        key: 'scrollToPosition',


        /**
         * Scroll to the specified offset(s).
         * Useful for animating position changes.
         */
        value: function scrollToPosition(_ref8) {
            var scrollLeft = _ref8.scrollLeft,
                scrollTop = _ref8.scrollTop;

            var stateUpdate = CustomGridVirtualized._getScrollToPositionStateUpdate({
                prevState: this.state,
                scrollLeft: scrollLeft,
                scrollTop: scrollTop
            });

            if (stateUpdate) {
                stateUpdate.needToResetStyleCache = false;
                this.setState(stateUpdate);
            }
        }
    }, {
        key: '_getCalculatedScrollLeft',
        value: function _getCalculatedScrollLeft() {
            var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.props;
            var state = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.state;

            return CustomGridVirtualized._getCalculatedScrollLeft(props, state);
        }
    }, {
        key: '_updateScrollLeftForScrollToColumn',
        value: function _updateScrollLeftForScrollToColumn() {
            var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.props;
            var state = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.state;

            var stateUpdate = CustomGridVirtualized._getScrollLeftForScrollToColumnStateUpdate(props, state);
            if (stateUpdate) {
                stateUpdate.needToResetStyleCache = false;
                this.setState(stateUpdate);
            }
        }
    }, {
        key: '_getCalculatedScrollTop',
        value: function _getCalculatedScrollTop() {
            var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.props;
            var state = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.state;

            return CustomGridVirtualized._getCalculatedScrollTop(props, state);
        }
    }, {
        key: '_resetStyleCache',
        value: function _resetStyleCache() {
            var styleCache = this._styleCache;
            var cellCache = this._cellCache;
            var isScrollingOptOut = this.props.isScrollingOptOut;

            // Reset cell and style caches once scrolling stops.
            // This makes CustomGridVirtualized simpler to use (since cells commonly change).
            // And it keeps the caches from growing too large.
            // Performance is most sensitive when a user is scrolling.
            // Don't clear visible cells from cellCache if isScrollingOptOut is specified.
            // This keeps the cellCache to a resonable size.

            this._cellCache = {};
            this._styleCache = {};

            // Copy over the visible cell styles so avoid unnecessary re-render.
            for (var rowIndex = this._rowStartIndex; rowIndex <= this._rowStopIndex; rowIndex++) {
                for (var columnIndex = this._columnStartIndex; columnIndex <= this._columnStopIndex; columnIndex++) {
                    var key = rowIndex + '-' + columnIndex;
                    this._styleCache[key] = styleCache[key];

                    if (isScrollingOptOut) {
                        this._cellCache[key] = cellCache[key];
                    }
                }
            }
        }
    }, {
        key: '_updateScrollTopForScrollToRow',
        value: function _updateScrollTopForScrollToRow() {
            var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.props;
            var state = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.state;

            var stateUpdate = CustomGridVirtualized._getScrollTopForScrollToRowStateUpdate(props, state);
            if (stateUpdate) {
                stateUpdate.needToResetStyleCache = false;
                this.setState(stateUpdate);
            }
        }
    }], [{
        key: 'getDerivedStateFromProps',
        value: function getDerivedStateFromProps(nextProps, prevState) {
            var newState = {};

            if (nextProps.columnCount === 0 && prevState.scrollLeft !== 0 || nextProps.rowCount === 0 && prevState.scrollTop !== 0) {
                newState.scrollLeft = 0;
                newState.scrollTop = 0;

                // only use scroll{Left,Top} from props if scrollTo{Column,Row} isn't specified
                // scrollTo{Column,Row} should override scroll{Left,Top}
            } else if (nextProps.scrollLeft !== prevState.scrollLeft && nextProps.scrollToColumn < 0 || nextProps.scrollTop !== prevState.scrollTop && nextProps.scrollToRow < 0) {
                _Object$assign(newState, CustomGridVirtualized._getScrollToPositionStateUpdate({
                    prevState: prevState,
                    scrollLeft: nextProps.scrollLeft,
                    scrollTop: nextProps.scrollTop
                }));
            }

            var instanceProps = prevState.instanceProps;

            // Initially we should not clearStyleCache

            newState.needToResetStyleCache = false;
            if (nextProps.columnWidth !== instanceProps.prevColumnWidth || nextProps.rowHeight !== instanceProps.prevRowHeight) {
                // Reset cache. set it to {} in render
                newState.needToResetStyleCache = true;
            }

            instanceProps.columnSizeAndPositionManager.configure({
                cellCount: nextProps.columnCount,
                estimatedCellSize: CustomGridVirtualized._getEstimatedColumnSize(nextProps),
                cellSizeGetter: CustomGridVirtualized._wrapSizeGetter(nextProps.columnWidth)
            });

            instanceProps.rowSizeAndPositionManager.configure({
                cellCount: nextProps.rowCount,
                estimatedCellSize: CustomGridVirtualized._getEstimatedRowSize(nextProps),
                cellSizeGetter: CustomGridVirtualized._wrapSizeGetter(nextProps.rowHeight)
            });

            if (instanceProps.prevColumnCount === 0 || instanceProps.prevRowCount === 0) {
                instanceProps.prevColumnCount = 0;
                instanceProps.prevRowCount = 0;
            }

            // If scrolling is controlled outside this component, clear cache when scrolling stops
            if (nextProps.autoHeight && nextProps.isScrolling === false && instanceProps.prevIsScrolling === true) {
                _Object$assign(newState, {
                    isScrolling: false
                });
            }

            var maybeStateA = void 0;
            var maybeStateB = void 0;

            calculateSizeAndPositionDataAndUpdateScrollOffset({
                cellCount: instanceProps.prevColumnCount,
                cellSize: typeof instanceProps.prevColumnWidth === 'number' ? instanceProps.prevColumnWidth : null,
                computeMetadataCallback: function computeMetadataCallback() {
                    return instanceProps.columnSizeAndPositionManager.resetCell(0);
                },
                computeMetadataCallbackProps: nextProps,
                nextCellsCount: nextProps.columnCount,
                nextCellSize: typeof nextProps.columnWidth === 'number' ? nextProps.columnWidth : null,
                nextScrollToIndex: nextProps.scrollToColumn,
                scrollToIndex: instanceProps.prevScrollToColumn,
                updateScrollOffsetForScrollToIndex: function updateScrollOffsetForScrollToIndex() {
                    maybeStateA = CustomGridVirtualized._getScrollLeftForScrollToColumnStateUpdate(nextProps, prevState);
                }
            });
            calculateSizeAndPositionDataAndUpdateScrollOffset({
                cellCount: instanceProps.prevRowCount,
                cellSize: typeof instanceProps.prevRowHeight === 'number' ? instanceProps.prevRowHeight : null,
                computeMetadataCallback: function computeMetadataCallback() {
                    return instanceProps.rowSizeAndPositionManager.resetCell(0);
                },
                computeMetadataCallbackProps: nextProps,
                nextCellsCount: nextProps.rowCount,
                nextCellSize: typeof nextProps.rowHeight === 'number' ? nextProps.rowHeight : null,
                nextScrollToIndex: nextProps.scrollToRow,
                scrollToIndex: instanceProps.prevScrollToRow,
                updateScrollOffsetForScrollToIndex: function updateScrollOffsetForScrollToIndex() {
                    maybeStateB = CustomGridVirtualized._getScrollTopForScrollToRowStateUpdate(nextProps, prevState);
                }
            });

            instanceProps.prevColumnCount = nextProps.columnCount;
            instanceProps.prevColumnWidth = nextProps.columnWidth;
            instanceProps.prevIsScrolling = nextProps.isScrolling === true;
            instanceProps.prevRowCount = nextProps.rowCount;
            instanceProps.prevRowHeight = nextProps.rowHeight;
            instanceProps.prevScrollToColumn = nextProps.scrollToColumn;
            instanceProps.prevScrollToRow = nextProps.scrollToRow;

            // getting scrollBarSize (moved from componentWillMount)
            instanceProps.scrollbarSize = nextProps.getScrollbarSize();
            if (instanceProps.scrollbarSize === undefined) {
                instanceProps.scrollbarSizeMeasured = false;
                instanceProps.scrollbarSize = 0;
            } else {
                instanceProps.scrollbarSizeMeasured = true;
            }

            newState.instanceProps = instanceProps;

            return _extends({}, newState, maybeStateA, maybeStateB);
        }
    }, {
        key: '_getEstimatedColumnSize',
        value: function _getEstimatedColumnSize(props) {
            return typeof props.columnWidth === 'number' ? props.columnWidth : props.estimatedColumnSize;
        }
    }, {
        key: '_getEstimatedRowSize',
        value: function _getEstimatedRowSize(props) {
            return typeof props.rowHeight === 'number' ? props.rowHeight : props.estimatedRowSize;
        }
    }, {
        key: '_getScrollToPositionStateUpdate',


        /**
         * Get the updated state after scrolling to
         * scrollLeft and scrollTop
         */
        value: function _getScrollToPositionStateUpdate(_ref9) {
            var prevState = _ref9.prevState,
                scrollLeft = _ref9.scrollLeft,
                scrollTop = _ref9.scrollTop;

            var newState = {
                scrollPositionChangeReason: SCROLL_POSITION_CHANGE_REASONS.REQUESTED
            };

            if (typeof scrollLeft === 'number' && scrollLeft >= 0) {
                newState.scrollDirectionHorizontal = scrollLeft > prevState.scrollLeft ? SCROLL_DIRECTION_FORWARD : SCROLL_DIRECTION_BACKWARD;
                newState.scrollLeft = scrollLeft;
            }

            if (typeof scrollTop === 'number' && scrollTop >= 0) {
                newState.scrollDirectionVertical = scrollTop > prevState.scrollTop ? SCROLL_DIRECTION_FORWARD : SCROLL_DIRECTION_BACKWARD;
                newState.scrollTop = scrollTop;
            }

            if (typeof scrollLeft === 'number' && scrollLeft >= 0 && scrollLeft !== prevState.scrollLeft || typeof scrollTop === 'number' && scrollTop >= 0 && scrollTop !== prevState.scrollTop) {
                return newState;
            }
            return null;
        }
    }, {
        key: '_wrapSizeGetter',
        value: function _wrapSizeGetter(value) {
            return typeof value === 'function' ? value : function () {
                return value;
            };
        }
    }, {
        key: '_getCalculatedScrollLeft',
        value: function _getCalculatedScrollLeft(nextProps, prevState) {
            var columnCount = nextProps.columnCount,
                height = nextProps.height,
                scrollToAlignment = nextProps.scrollToAlignment,
                scrollToColumn = nextProps.scrollToColumn,
                width = nextProps.width;
            var scrollLeft = prevState.scrollLeft,
                instanceProps = prevState.instanceProps;


            if (columnCount > 0) {
                var finalColumn = columnCount - 1;
                var targetIndex = scrollToColumn < 0 ? finalColumn : Math.min(finalColumn, scrollToColumn);
                var totalRowsHeight = instanceProps.rowSizeAndPositionManager.getTotalSize();
                var scrollBarSize = instanceProps.scrollbarSizeMeasured && totalRowsHeight > height ? instanceProps.scrollbarSize : 0;

                return instanceProps.columnSizeAndPositionManager.getUpdatedOffsetForIndex({
                    align: scrollToAlignment,
                    containerSize: width - scrollBarSize,
                    currentOffset: scrollLeft,
                    targetIndex: targetIndex
                });
            }
            return 0;
        }
    }, {
        key: '_getScrollLeftForScrollToColumnStateUpdate',
        value: function _getScrollLeftForScrollToColumnStateUpdate(nextProps, prevState) {
            var scrollLeft = prevState.scrollLeft;

            var calculatedScrollLeft = CustomGridVirtualized._getCalculatedScrollLeft(nextProps, prevState);

            if (typeof calculatedScrollLeft === 'number' && calculatedScrollLeft >= 0 && scrollLeft !== calculatedScrollLeft) {
                return CustomGridVirtualized._getScrollToPositionStateUpdate({
                    prevState: prevState,
                    scrollLeft: calculatedScrollLeft,
                    scrollTop: -1
                });
            }
            return null;
        }
    }, {
        key: '_getCalculatedScrollTop',
        value: function _getCalculatedScrollTop(nextProps, prevState) {
            var height = nextProps.height,
                rowCount = nextProps.rowCount,
                scrollToAlignment = nextProps.scrollToAlignment,
                scrollToRow = nextProps.scrollToRow,
                width = nextProps.width;
            var scrollTop = prevState.scrollTop,
                instanceProps = prevState.instanceProps;


            if (rowCount > 0) {
                var finalRow = rowCount - 1;
                var targetIndex = scrollToRow < 0 ? finalRow : Math.min(finalRow, scrollToRow);
                var totalColumnsWidth = instanceProps.columnSizeAndPositionManager.getTotalSize();
                var scrollBarSize = instanceProps.scrollbarSizeMeasured && totalColumnsWidth > width ? instanceProps.scrollbarSize : 0;

                return instanceProps.rowSizeAndPositionManager.getUpdatedOffsetForIndex({
                    align: scrollToAlignment,
                    containerSize: height - scrollBarSize,
                    currentOffset: scrollTop,
                    targetIndex: targetIndex
                });
            }
            return 0;
        }
    }, {
        key: '_getScrollTopForScrollToRowStateUpdate',
        value: function _getScrollTopForScrollToRowStateUpdate(nextProps, prevState) {
            var scrollTop = prevState.scrollTop;

            var calculatedScrollTop = CustomGridVirtualized._getCalculatedScrollTop(nextProps, prevState);

            if (typeof calculatedScrollTop === 'number' && calculatedScrollTop >= 0 && scrollTop !== calculatedScrollTop) {
                return CustomGridVirtualized._getScrollToPositionStateUpdate({
                    prevState: prevState,
                    scrollLeft: -1,
                    scrollTop: calculatedScrollTop
                });
            }
            return null;
        }
    }]);

    return CustomGridVirtualized;
}(React.PureComponent);

CustomGridVirtualized.defaultProps = {
    'aria-label': 'grid',
    'aria-readonly': true,
    autoContainerWidth: false,
    autoHeight: false,
    autoWidth: false,
    cellRangeRenderer: defaultCellRangeRenderer,
    containerRole: 'rowgroup',
    containerStyle: {},
    estimatedColumnSize: 100,
    estimatedRowSize: 30,
    getScrollbarSize: scrollbarSize,
    noContentRenderer: renderNull,
    onScroll: function onScroll() {},
    onScrollbarPresenceChange: function onScrollbarPresenceChange() {},
    onSectionRendered: function onSectionRendered() {},
    overscanColumnCount: 0,
    overscanIndicesGetter: defaultOverscanIndicesGetter,
    overscanRowCount: 10,
    role: 'grid',
    scrollingResetTimeInterval: DEFAULT_SCROLLING_RESET_TIME_INTERVAL,
    scrollToAlignment: 'auto',
    scrollToColumn: -1,
    scrollToRow: -1,
    style: {},
    tabIndex: 0,
    isScrollingOptOut: false
};
CustomGridVirtualized.propTypes = process.env.NODE_ENV === 'production' ? null : {
    "aria-label": PropTypes.string.isRequired,
    "aria-readonly": PropTypes.bool,


    /**
     * Set the width of the inner scrollable container to 'auto'.
     * This is useful for single-column Grids to ensure that the column doesn't extend below a vertical scrollbar.
     */
    autoContainerWidth: PropTypes.bool.isRequired,


    /**
     * Removes fixed height from the scrollingContainer so that the total height of rows can stretch the window.
     * Intended for use with WindowScroller
     */
    autoHeight: PropTypes.bool.isRequired,


    /**
     * Removes fixed width from the scrollingContainer so that the total width of rows can stretch the window.
     * Intended for use with WindowScroller
     */
    autoWidth: PropTypes.bool.isRequired,


    /** Responsible for rendering a cell given an row and column index.  */
    cellRenderer: function cellRenderer() {
        return (typeof bpfrpt_proptype_CellRenderer === 'function' ? bpfrpt_proptype_CellRenderer.isRequired ? bpfrpt_proptype_CellRenderer.isRequired : bpfrpt_proptype_CellRenderer : PropTypes.shape(bpfrpt_proptype_CellRenderer).isRequired).apply(this, arguments);
    },


    /** Responsible for rendering a group of cells given their index ranges.  */
    cellRangeRenderer: function cellRangeRenderer() {
        return (typeof bpfrpt_proptype_CellRangeRenderer === 'function' ? bpfrpt_proptype_CellRangeRenderer.isRequired ? bpfrpt_proptype_CellRangeRenderer.isRequired : bpfrpt_proptype_CellRangeRenderer : PropTypes.shape(bpfrpt_proptype_CellRangeRenderer).isRequired).apply(this, arguments);
    },


    /** Optional custom CSS class name to attach to root CustomGridVirtualized element.  */
    className: PropTypes.string,


    /** Number of columns in grid.  */
    columnCount: PropTypes.number.isRequired,


    /** Either a fixed column width (number) or a function that returns the width of a column given its index.  */
    columnWidth: function columnWidth() {
        return (typeof bpfrpt_proptype_CellSize === 'function' ? bpfrpt_proptype_CellSize.isRequired ? bpfrpt_proptype_CellSize.isRequired : bpfrpt_proptype_CellSize : PropTypes.shape(bpfrpt_proptype_CellSize).isRequired).apply(this, arguments);
    },


    /** Unfiltered props for the CustomGridVirtualized container. */
    containerProps: PropTypes.object,


    /** ARIA role for the cell-container.  */
    containerRole: PropTypes.string.isRequired,


    /** Optional inline style applied to inner cell-container */
    containerStyle: PropTypes.object.isRequired,


    /**
     * If CellMeasurer is used to measure this CustomGridVirtualized's children, this should be a pointer to its CellMeasurerCache.
     * A shared CellMeasurerCache reference enables CustomGridVirtualized and CellMeasurer to share measurement data.
     */
    deferredMeasurementCache: PropTypes.object,


    /**
     * Used to estimate the total width of a CustomGridVirtualized before all of its columns have actually been measured.
     * The estimated total width is adjusted as columns are rendered.
     */
    estimatedColumnSize: PropTypes.number.isRequired,


    /**
     * Used to estimate the total height of a CustomGridVirtualized before all of its rows have actually been measured.
     * The estimated total height is adjusted as rows are rendered.
     */
    estimatedRowSize: PropTypes.number.isRequired,


    /** Exposed for testing purposes only.  */
    getScrollbarSize: PropTypes.func.isRequired,


    /** Height of CustomGridVirtualized; this property determines the number of visible (vs virtualized) rows.  */
    height: PropTypes.number.isRequired,


    /** Optional custom id to attach to root CustomGridVirtualized element.  */
    id: PropTypes.string,


    /**
     * Override internal is-scrolling state tracking.
     * This property is primarily intended for use with the WindowScroller component.
     */
    isScrolling: PropTypes.bool,


    /**
     * Opt-out of isScrolling param passed to cellRangeRenderer.
     * To avoid the extra render when scroll stops.
     */
    isScrollingOptOut: PropTypes.bool.isRequired,


    /** Optional renderer to be used in place of rows when either :rowCount or :columnCount is 0.  */
    noContentRenderer: function noContentRenderer() {
        return (typeof bpfrpt_proptype_NoContentRenderer === 'function' ? bpfrpt_proptype_NoContentRenderer.isRequired ? bpfrpt_proptype_NoContentRenderer.isRequired : bpfrpt_proptype_NoContentRenderer : PropTypes.shape(bpfrpt_proptype_NoContentRenderer).isRequired).apply(this, arguments);
    },


    /**
     * Callback invoked whenever the scroll offset changes within the inner scrollable region.
     * This callback can be used to sync scrolling between lists, tables, or grids.
     */
    onScroll: PropTypes.func.isRequired,


    /**
     * Called whenever a horizontal or vertical scrollbar is added or removed.
     * This prop is not intended for end-user use;
     * It is used by MultiGrid to support fixed-row/fixed-column scroll syncing.
     */
    onScrollbarPresenceChange: PropTypes.func.isRequired,


    /** Callback invoked with information about the section of the CustomGridVirtualized that was just rendered.  */
    onSectionRendered: PropTypes.func.isRequired,


    /**
     * Number of columns to render before/after the visible section of the grid.
     * These columns can help for smoother scrolling on touch devices or browsers that send scroll events infrequently.
     */
    overscanColumnCount: PropTypes.number.isRequired,


    /**
     * Calculates the number of cells to overscan before and after a specified range.
     * This function ensures that overscanning doesn't exceed the available cells.
     */
    overscanIndicesGetter: function overscanIndicesGetter() {
        return (typeof bpfrpt_proptype_OverscanIndicesGetter === 'function' ? bpfrpt_proptype_OverscanIndicesGetter.isRequired ? bpfrpt_proptype_OverscanIndicesGetter.isRequired : bpfrpt_proptype_OverscanIndicesGetter : PropTypes.shape(bpfrpt_proptype_OverscanIndicesGetter).isRequired).apply(this, arguments);
    },


    /**
     * Number of rows to render above/below the visible section of the grid.
     * These rows can help for smoother scrolling on touch devices or browsers that send scroll events infrequently.
     */
    overscanRowCount: PropTypes.number.isRequired,


    /** ARIA role for the grid element.  */
    role: PropTypes.string.isRequired,


    /**
     * Either a fixed row height (number) or a function that returns the height of a row given its index.
     * Should implement the following interface: ({ index: number }): number
     */
    rowHeight: function rowHeight() {
        return (typeof bpfrpt_proptype_CellSize === 'function' ? bpfrpt_proptype_CellSize.isRequired ? bpfrpt_proptype_CellSize.isRequired : bpfrpt_proptype_CellSize : PropTypes.shape(bpfrpt_proptype_CellSize).isRequired).apply(this, arguments);
    },


    /** Number of rows in grid.  */
    rowCount: PropTypes.number.isRequired,


    /** Wait this amount of time after the last scroll event before resetting CustomGridVirtualized `pointer-events`. */
    scrollingResetTimeInterval: PropTypes.number.isRequired,


    /** Horizontal offset. */
    scrollLeft: PropTypes.number,


    /**
     * Controls scroll-to-cell behavior of the CustomGridVirtualized.
     * The default ("auto") scrolls the least amount possible to ensure that the specified cell is fully visible.
     * Use "start" to align cells to the top/left of the CustomGridVirtualized and "end" to align bottom/right.
     */
    scrollToAlignment: function scrollToAlignment() {
        return (typeof bpfrpt_proptype_Alignment === 'function' ? bpfrpt_proptype_Alignment.isRequired ? bpfrpt_proptype_Alignment.isRequired : bpfrpt_proptype_Alignment : PropTypes.shape(bpfrpt_proptype_Alignment).isRequired).apply(this, arguments);
    },


    /** Column index to ensure visible (by forcefully scrolling if necessary) */
    scrollToColumn: PropTypes.number.isRequired,


    /** Vertical offset. */
    scrollTop: PropTypes.number,


    /** Row index to ensure visible (by forcefully scrolling if necessary) */
    scrollToRow: PropTypes.number.isRequired,


    /** Optional inline style */
    style: PropTypes.object.isRequired,


    /** Tab index for focus */
    tabIndex: PropTypes.number,


    /** Width of CustomGridVirtualized; this property determines the number of visible (vs virtualized) columns.  */
    width: PropTypes.number.isRequired
};


polyfill(CustomGridVirtualized);
export default CustomGridVirtualized;