import {
    AlertService,
    DirectionService,
    displayErrorFromAxios,
    ICService,
    LocationConfigService,
    ReportService,
    ResourceService,
    SiteViewService
} from './util';
import { arrayToObject } from '../utils/Misc';
import {
    FILTERS_ONE_PLATFORM_PATCH_FILTER,
    FILTERS_ONE_PLATFORM_REPLACE_ALL_FILTERS,
    FILTERS_ONE_PLATFORM_RECOVER_ALL_FILTERS_ALL_REPORTS,
    FILTERS_ONE_PLATFORM_REPLACE_FILTER,
} from '../constants/ActionTypes';
import {ERROR, LOADING, REPORTS_WITH_CUSTOM_ATTRIBUTES, REPORTS_WITH_FIELD_NAMES, SUCCESS} from '../constants';
import {
    onePlatformReplaceHeaderColumns, tableOnePlatformReplaceConfigHeaderColumnsSortFilters,
} from './tableOnePlatform';
import { getReportKeys } from '../hooks';
import { getDataOfPath, getDefaultColumnValues, getValueOfPath } from '../utils';
import {FILTER_TYPE, PERIOD} from '../views/util/report/filter/util';
import {TYPE_DATA} from "./resource-types";
import {IdBsonType} from "../views/resourceTypes/toolbar/accordionItemsResourceType/ResourceTypeAttributes";
import {TYPE_CUSTOM_ATTRIBUTE_FIELD_NAME, TYPE_SECTION} from "../utils/CustomCellByConfiguration";
import {patchReportProperties} from "./reports";
import {TYPE_RESET_FILTER} from "../views/onePlatform/LayoutContent/FilterLoader/Filters/TimeFilter";

export const getData = async (service, urlFilter, params, urlMethod, dispatch) => {
    let dataService = ResourceService;
    switch (service) {
        case 'ReportService':
        case 'reportsvc':
            dataService = ReportService;
            break;
        case 'ICService':
            dataService = ICService;
            break;
        case 'LocationConfigService':
            dataService = LocationConfigService;
            break;
        case 'SiteViewService':
            dataService = SiteViewService;
            break;
        case 'AlertService':
            dataService = AlertService;
            break;
        case 'DirectionService':
            dataService = DirectionService;
            break;
        default:
    }
    
    return await dataService.instance().request(
        {
            url: urlFilter,
            params: params,
            method: urlMethod,
        },
    ).catch(displayErrorFromAxios.bind(null, dispatch));
};

const sortByName = (options,sort) =>{
    if(sort){
        const compare  = (a,b) => {
            if(sort==='asc'){
                return (a.name).toLowerCase().localeCompare((b.name).toLowerCase())
            }else{
                return (b.name).toLowerCase().localeCompare((a.name).toLowerCase())
            }
        };
        options.sort(compare);
    }
    return options;
}
export const loadFilter = (reportKey, filterKey, params) => {
    return async (dispatch, getState) => {
        const filters = getState().filtersOnePlatform;
        try {
            const filter = filters[reportKey][filterKey];
            dispatch(filtersOnePlatformPatchFilter({ fetchingOptions: LOADING, options: [] }, filterKey, reportKey));
            const { url, method, service, optionsPath, optionNamePath, optionValuePath,sort } = filter;
            let urlFilter = url;
            if (filter.valuesQueryParameters !== undefined) {
                let queryParameters = '';
                filter.valuesQueryParameters.forEach(parameter => {
                    if (queryParameters !== '') {
                        queryParameters = queryParameters + '&';
                    }
                    let parameterFilter = undefined;
                    for (let filterKey in filters[reportKey]) {
                        if (filters[reportKey][filterKey].key === parameter.dependencyFilterValue) {
                            parameterFilter = filters[reportKey][filterKey];
                        }
                    }
                    queryParameters = queryParameters + parameter.field + '=' + parameterFilter.value;
                });
                if (urlFilter.indexOf('?') >= 0) {
                    urlFilter = urlFilter + '&' + queryParameters;
                } else {
                    urlFilter = urlFilter + '?' + queryParameters;
                }
            }
            let newFilter = {};
            if (urlFilter && method) {
                let valuesData = await getData(service, urlFilter, params, method, dispatch);
                const options = getDataOfPath(valuesData.data, optionsPath);
                newFilter.options = Object.values(options).map((option) => ({
                    name: '' + getValueOfPath(option, optionNamePath || optionValuePath),
                    //name: '' + getValueOfPath(option, optionNamePath ),
                    value: '' + getValueOfPath(option, optionValuePath),
                })).filter( (field) => field.name !== '' && field.value !== '');
                newFilter.fetchingOptions = SUCCESS;
                //Added filter to remove duplicate values - should not affect the original filter
                newFilter.options = newFilter.options.filter((v, i, a) => a.findIndex(t => (t.value === v.value && t.name === v.name)) === i);

                //MWE-6826 add local sort functionality in UI filters
                if(sort){
                    newFilter.options = sortByName(newFilter.options,sort);
                }
                dispatch(filtersOnePlatformPatchFilter(newFilter, filterKey, reportKey));
            } else if (filter.options) {
                newFilter.fetchingOptions = SUCCESS;
                if (filter.type === FILTER_TYPE.INEQUALITY) {
                    newFilter.options = filter.options;
                } else {
                    if (filter.options[0].value && filter.options[0].name) { // options: [{ name: string, value: string }]
                        newFilter.options = filter.options.map(option => ({ name: option.name, value: option.value }));
                    } else {
                        newFilter.options = filter.options.map(option => ({ name: option, value: option }));
                    }

                    //MWE-6826 add local sort functionality in UI filters
                    if(sort){
                        newFilter.options = sortByName(newFilter.options,sort);
                    }
                }
                dispatch(filtersOnePlatformPatchFilter(newFilter, filterKey, reportKey));
            } else {
                newFilter.fetchingOptions = ERROR;
                dispatch(filtersOnePlatformPatchFilter(newFilter, filterKey, reportKey));
            }
        } catch (e) {
            if (filters && filters[reportKey] && filters[reportKey][filterKey]) {
                dispatch(filtersOnePlatformReplaceFilter({ ...filters[reportKey][filterKey], fetchingOptions: ERROR }, filterKey, reportKey));
            }
        }
    };
};

// TODO: replace with loadFilter & Check getState().tableOnePlatform.resourceTypes
export const loadResourceFilters = (typeFilter, typeValue) => {
    return async (dispatch, getState) => {
        
        let filtersMain = getState().filtersOnePlatform;
        let urlFilter;
        const { reportKey } = getReportKeys(getState());

        const filterKey = typeValue;
        const filter = filtersMain[reportKey][filterKey];
        dispatch(filtersOnePlatformPatchFilter({ fetchingOptions: LOADING, options: [] }, filterKey, reportKey));
        const { url, defaultValues } = JSON.parse(JSON.stringify(filter));

        urlFilter = url;
        const size = getState().tableOnePlatform[reportKey].maxRowSize;
        const page = getState().tableOnePlatform[reportKey].pageSelect;
        
        urlFilter = urlFilter.replace('{page}', page);
        urlFilter = urlFilter.replace('{size}', size);
        urlFilter = urlFilter.replace('{filters}', '');
        urlFilter = urlFilter.replace('{sort}', '');
        try {
            let valuesData = await ResourceService.instance().get(urlFilter);
            let filterValues = { ...filtersMain[typeFilter][typeValue] };
            if(defaultValues){
                Object.keys(valuesData.data.content).map(name => defaultValues.push(valuesData.data.content[name].name));
                filterValues.options = defaultValues;
            }else{
                filterValues.options = Object.keys(valuesData.data.content).map(name => valuesData.data.content[name].name);
            }
            dispatch(filtersOnePlatformReplaceFilter(filterValues, typeValue, typeFilter));
        } catch (e) {
            console.log(e);
        }
    };
};

const getChildrenResourceType = async ({reportKey, filterKey, value,reportId,getURL,filtersMain,headerColumns,dispatch,OldChildrenValues={}}) =>{
    const childrenAttribute = [];
    const defaultColumns = Object.filter(headerColumns, item => !item.isExtra);
    const defaultExtraColumns = Object.filter(headerColumns, item => item.isExtra);
    let newColumns = {};

    const filterTime = Object.values(filtersMain[reportKey]).filter( filter  => filter.type === FILTER_TYPE.TIME);
    const {rangeMode} = filterTime && Array.isArray(filterTime) && filterTime.length > 0 ? filterTime[0]:{};

    if ( value && value !== 'All' && value !== '%' && value.length > 0) {
        let resourceTypeUrl = getURL.url;
        resourceTypeUrl = resourceTypeUrl.replace('{type}', value[0].value);

        const valuesData = await ResourceService.instance().request(
            {
                url: resourceTypeUrl,
                method: getURL.method,
            },
        ).catch(error=>{
            console.log({error})
            //displayErrorFromAxios(dispatch,error);
        });

        const customProperties = valuesData?.data&&valuesData?.data?.customProperties?[...valuesData?.data?.customProperties]:[];
        customProperties.forEach(item => {
                const { dataType } = item;
                if(dataType !== TYPE_DATA.LIST){
                    //Add configuration of the lookup fields
                    const obj = {};
                    obj.id = item.propertyName;
                    obj.name = item.propertyName;
                    obj.displayName = item.propertyDisplayName ? item.propertyDisplayName : item.propertyName;
                    obj.type = 'TEXT';
                    obj.parent = value;
                    obj.searchable = item.searchable;
                    obj.value = OldChildrenValues[item.propertyName]?.value||'';
                    obj.options = item.lookups;
                    obj.dataType = item[IdBsonType];
                    obj.sortable = rangeMode===PERIOD.NOW?true:false;
                    childrenAttribute.push(obj);
                }else{
                    //Add configuration of the custom fields type array
                    const { fieldNames, keyFieldName, propertyName, searchable } = item;
                    if( REPORTS_WITH_FIELD_NAMES.includes(reportId)){
                        //Configuration of each value of the array is generated to generate multiple columns and generate the split of the rows
                        fieldNames.forEach(fieldName=>{
                            const obj2 = {};
                            obj2.id = propertyName;
                            obj2.name = fieldName;
                            obj2.displayName = fieldName;
                            obj2.type = TYPE_CUSTOM_ATTRIBUTE_FIELD_NAME;
                            obj2.parent = value;
                            obj2.searchable = searchable && fieldName === keyFieldName;
                            obj2.sortable = false;
                            obj2.value = OldChildrenValues[item.propertyName]?.value||'';
                            obj2.listValues = OldChildrenValues[item.propertyName]?.listValues||[];
                            obj2.options = undefined;
                            obj2.dataType = item[IdBsonType];
                            obj2.keySort = propertyName;
                            childrenAttribute.push(obj2);
                        });
                    }else{
                        const {  keyFieldName } = item;
                        const obj3 = {};
                        obj3.id = item.propertyName;
                        obj3.name =  item.propertyName;
                        obj3.keyFieldName =  item.keyFieldName;
                        obj3.displayName = keyFieldName;
                        obj3.type = TYPE_CUSTOM_ATTRIBUTE_FIELD_NAME;
                        obj3.parent = value;
                        obj3.searchable = item.searchable;

                        obj3.value = OldChildrenValues[item.propertyName]?.value||'';
                        obj3.listValues = OldChildrenValues[item.propertyName]?.listValues||[];

                        obj3.options = undefined;
                        obj3.typeSection = TYPE_SECTION.REPORT;
                        obj3.dataType = item[IdBsonType];

                        obj3.sortable = false;
                        obj3.filters = false;
                        obj3.clickable = false;
                        obj3.displayOnDetail = true;
                        obj3.displayNameColumn = item.propertyDisplayName ? item.propertyDisplayName : item.propertyName;
                        obj3.nameColumn = item.propertyDisplayName ? item.propertyDisplayName : item.propertyName;
                        childrenAttribute.push(obj3);
                    }
                }
            },
        );
    }
    let currentFilter = {...filtersMain[reportKey][filterKey]};
    const childrenFilter = childrenAttribute.length > 0 ? childrenAttribute.filter((value) => value.searchable === true) : {};
    currentFilter.children = Object.keys(childrenFilter).length > 0 ? arrayToObject(childrenFilter, 'id') : {};
    currentFilter.value = value;

    //Extra Columns
    if (REPORTS_WITH_CUSTOM_ATTRIBUTES.includes(reportId)) {
        const columnResAttr = generateExtraColumns(childrenAttribute,defaultExtraColumns, Object.keys(defaultColumns).length);
        newColumns = {...defaultColumns, ...columnResAttr};
    }
    return {newColumns, currentFilter}
}

export const selectedResourceType = (reportKey, filterKey, value,OldChildrenValues={}) => {
    return async (dispatch, getState) => {
        const { reportId } = getReportKeys(getState());
        const getURL = getState().resourceTypes.resourceTypesURL.getType;
        let filtersMain = getState().filtersOnePlatform;
        const headerColumns = getState().tableOnePlatform[reportKey]?.headerColumns;
        const {newColumns, currentFilter} =  await getChildrenResourceType({reportKey, filterKey, value,reportId,getURL,filtersMain,headerColumns,dispatch,OldChildrenValues});
        dispatch(filtersOnePlatformReplaceFilter(currentFilter, filterKey, reportKey));
        //Extra Columns
        if (REPORTS_WITH_CUSTOM_ATTRIBUTES.includes(reportId)) {
            if (JSON.stringify(newColumns) !== JSON.stringify(headerColumns)) { // prevent infinite render loop
                dispatch(onePlatformReplaceHeaderColumns(newColumns, reportKey, false));
            }

        }
    }
};

export const loadDefaultColumns = (reportKey) => {
    return (dispatch, getState) => {
        const headerColumns = getState().tableOnePlatform[reportKey]?.headerColumns;
        const defaultColumns = {};
        for (const headerColumnKey in headerColumns) {
            if (!headerColumns[headerColumnKey].isExtra) {
                defaultColumns[headerColumnKey] = headerColumns[headerColumnKey];
            }
        }
        dispatch(onePlatformReplaceHeaderColumns(defaultColumns, reportKey));
    };
};

export const childSelectedValue = (typeFilter, filterId, childrenId, values) => {
    return (dispatch, getState) => {
        let filters = getState().filtersOnePlatform;
        let currentFilter = { ...filters[typeFilter][filterId] };
        currentFilter.children[childrenId].value = values;
        dispatch(filtersOnePlatformReplaceFilter(currentFilter, filterId, typeFilter));
    };
};

//
// function orderList(listResource) {
//     let keysArray = Object.keys(listResource);
//     let orderedFilter = [];
//     for (let i = 0; i < keysArray.length; i++) {
//         orderedFilter.push(listResource[keysArray[i]]);
//     }
//     orderedFilter.sort((a, b) => a.order - b.order);
//     return orderedFilter;
// }

export const filtersOnePlatformReplaceAllFilters = (filters, key) => {
    return {
        type: FILTERS_ONE_PLATFORM_REPLACE_ALL_FILTERS,
        key,
        filters,
    };
};

export const filtersOnePlatformRecoverAllFiltersAllReports = (filters) => {
    return {
        type: FILTERS_ONE_PLATFORM_RECOVER_ALL_FILTERS_ALL_REPORTS,
        filters,
    };
};

export const filtersOnePlatformPatchFilter = (filter, filterKey, key) => {
    return {
        type: FILTERS_ONE_PLATFORM_PATCH_FILTER,
        filterKey,
        key,
        filter,
    };
};

export const filtersOnePlatformReplaceFilter = (filter, filterKey, key) => {
    return {
        type: FILTERS_ONE_PLATFORM_REPLACE_FILTER,
        filterKey,
        key,
        filter,
    };
};

const generateExtraColumns = (children, defaultExtraColumns,initOrder) => {
    const extraColumns = {};
    (Array.isArray(children) ? children : []).forEach((keyAttr, index) => {
        const propertyName = 'customProperties';

        const {searchable,sortable,keySort,type,columnKey,displayNameColumn} = keyAttr;
        const columnNewKey = columnKey?columnKey: propertyName + '.' + keyAttr.name;
        const keyAttrName = displayNameColumn?displayNameColumn:keyAttr.displayName;
        extraColumns[columnNewKey] = {
            ...getDefaultColumnValues(columnNewKey, keyAttrName),
            order: initOrder + index,
            width: 100,
            isExtra: true,
            ...(defaultExtraColumns[columnNewKey]||{}),
            value:keyAttrName,
            searchable:searchable,
            sortable:sortable!==undefined?sortable:true,
            keySort:keySort?propertyName + '.' +keySort:undefined,
            path:`$['${propertyName}']['${keyAttr.name}']`,
            type:type
        };
    });
    return extraColumns;
};

export const loadCustomFieldNames =  (reportKey, resourceType,customAttributeName,fieldNameSelected,filter) => {
    return async (dispatch, getState) => {
        const getCustomPropertiesURL = getState().resources.resourcesURL.getCustomProperties;
        const getResourceTypeURL = getState().resourceTypes.resourceTypesURL.getType;
        const filterKey = filter.key;
        const newFilter = {...filter};
        dispatch(filtersOnePlatformPatchFilter({ fetchingOptions: LOADING, options: [] }, filterKey, reportKey));
        try{

            if ( resourceType && getCustomPropertiesURL) {
                let resourceUrl = getCustomPropertiesURL.url;
                resourceUrl = resourceUrl.replace('{resourceType}', resourceType);

                const resourceData = await ResourceService.instance().request(
                    {
                        url: resourceUrl,
                        method: getCustomPropertiesURL.method,
                    },
                ).catch(error=>{
                    console.log({error})
                });

                let resourceTypeUrl = getResourceTypeURL.url;
                resourceTypeUrl = resourceTypeUrl.replace('{type}', resourceType);

                const resourceTypeData = await ResourceService.instance().request(
                    {
                        url: resourceTypeUrl,
                        method: getResourceTypeURL.method,
                    },
                ).catch(error=>{
                    console.log({error})
                });

                let keyFieldName = null, keyFieldType=null;
                if(resourceTypeData && resourceTypeData.data && resourceTypeData.data.customProperties){
                    const {customProperties} = resourceTypeData.data;
                    const attribute = (customProperties||[]).find(item => item.propertyName === customAttributeName);
                    if(attribute && attribute.keyFieldName === fieldNameSelected && attribute.dataType === TYPE_DATA.LIST ){
                        keyFieldName = attribute.keyFieldName;
                        keyFieldType = attribute.keyFieldType;
                    }
                }
                if(!keyFieldName && !keyFieldName && resourceTypeData && resourceTypeData.data && resourceTypeData.data.customProperties){
                    const {customProperties} = resourceTypeData.data;
                    const attribute = (customProperties||[]).find(item => item.dataType ===TYPE_DATA.LIST);
                    if(attribute){
                        keyFieldName = attribute.keyFieldName;
                        keyFieldType = attribute.keyFieldType;
                    }
                }
                const dataFieldName = {};
                if( keyFieldName && keyFieldType ) {
                    const content = resourceData?.data?.content||[];
                    content.forEach(resource=>{
                        const {customProperties} = resource;
                        const fieldNamesData = customProperties[customAttributeName]||[];

                        if(fieldNamesData!=null){
                            fieldNamesData.forEach(field=>{
                                if(field[keyFieldName]!=null){
                                    dataFieldName[field[keyFieldName]] = field[keyFieldName];
                                }
                            })
                        }
                    });
                }

                if(dataFieldName && Object.keys(dataFieldName).length > 0){
                    newFilter.fetchingOptions = SUCCESS;
                    const values = Object.values(dataFieldName).sort();
                    newFilter.children[customAttributeName].listValues = values;
                    dispatch(filtersOnePlatformPatchFilter(newFilter, filterKey, reportKey));
                }else{
                    newFilter.fetchingOptions = ERROR;
                    dispatch(filtersOnePlatformPatchFilter(newFilter, filterKey, reportKey));
                }
            }
        }catch (e) {
            newFilter.fetchingOptions = ERROR;
            dispatch(filtersOnePlatformPatchFilter(newFilter, filterKey, reportKey));
        }
    }
}

export const patchTimeFilter = (reportKey, filter, filterKey) =>{
    return async (dispatch, getState) => {
        const filters = getState().filtersOnePlatform;
        const sort = getState().tableOnePlatform[reportKey]?.sort;
        const headerColumns = getState().tableOnePlatform[reportKey]?.headerColumns;
        const columnsConfig = getState().tableOnePlatform[reportKey]?.columnsConfig;
        try {
            const filtersReport = {...filters[reportKey]};
            const newFilter = { ...filtersReport[filterKey] };
            for (const propertyKey of Object.keys(filter)) {
                newFilter[propertyKey] = filter[propertyKey];
            }

            const {enableFilterConfiguration, enabledFilters, rangeMode,
                enableSortConfiguration, sortConfiguration, enabledColumns, action, updateSort} = newFilter;
            newFilter.action = TYPE_RESET_FILTER.SUCCESS;

            //Columns Configuration
            const {nowSort,customSort} = sortConfiguration;
            const newSortConfiguration = {
                nowSort:[],
                customSort:[]
            };
            const newHeaderColumns = {};
            if(enableSortConfiguration){
                const defaultSortKey = Object.keys(enabledColumns).find(key=>enabledColumns[key].defaultSort);
                const defaultSort = [{ key: headerColumns[defaultSortKey].key, keySort: headerColumns[defaultSortKey].keySort || headerColumns[defaultSortKey].key, direction: enabledColumns[defaultSortKey].direction }]
                if(rangeMode === PERIOD.NOW) {
                    newSortConfiguration.nowSort = nowSort;
                    newSortConfiguration.customSort = sort && sort.length>0?sort:customSort;

                    Object.keys(columnsConfig).forEach(key=>{
                        if(key && headerColumns.hasOwnProperty(key)){
                            newHeaderColumns[key] = {...headerColumns[key]};
                            if(columnsConfig[key].hasOwnProperty('sortable')){
                                newHeaderColumns[key].sortable = columnsConfig[key].sortable;
                            }else{
                                newHeaderColumns[key].sortable = true;
                            }
                        }
                    })

                    Object.values(headerColumns).forEach(headerColumn=>{
                        const { key } = headerColumn;
                        if(key && key.includes('customProperties')) {
                            newHeaderColumns[key] = {...headerColumn};
                            if(newHeaderColumns[key].type === 'TEXT'){
                                newHeaderColumns[key].sortable = rangeMode===PERIOD.NOW?true:false;
                            }
                        }
                    })
                }else{
                    newSortConfiguration.nowSort = sort && sort.length>0?sort:nowSort;
                    newSortConfiguration.customSort = customSort.length>0?customSort:defaultSort;
                    Object.keys(headerColumns).forEach(key=>{
                        if(enabledColumns.hasOwnProperty(key)){
                            newHeaderColumns[key] = {...headerColumns[key],sortable:true}
                        }else{
                            newHeaderColumns[key] = {...headerColumns[key],sortable:false}
                        }
                    });
                }
                newFilter.sortConfiguration = newSortConfiguration;
                newFilter.updateSort = false;
            }

            filtersReport[filterKey] = newFilter;
            if(enableFilterConfiguration){
                //Filter Configuration
                const keys = Object.keys(enabledFilters);
                const values = Object.values(enabledFilters);
                let disabledFiltersTimeFilter = [];
                let enabledFiltersTimeFilter = [];
                let keyFilters = [];
                if(rangeMode === PERIOD.NOW) {
                    const enableFiltersAll = {};
                    Object.keys(enabledFilters).forEach(keyFilter=>{
                        enableFiltersAll[enabledFilters[keyFilter]] = keyFilter;
                    });
                    disabledFiltersTimeFilter = values;
                    enabledFiltersTimeFilter = keys;
                    keyFilters = enableFiltersAll;
                }else {
                    disabledFiltersTimeFilter = keys;
                    enabledFiltersTimeFilter = values;
                    keyFilters = enabledFilters;
                }
                const sortReports = {};
                disabledFiltersTimeFilter.forEach(keyFilter=>{
                    const {order} = filtersReport[keyFilter];
                    filtersReport[keyFilter].display = false;
                    sortReports[keyFilters[keyFilter]] = order;
                    filtersReport[keyFilter].value = [];
                });
                enabledFiltersTimeFilter.forEach(keyFilter=>{
                    filtersReport[keyFilter].display = true;
                    filtersReport[keyFilter].order = sortReports[keyFilter];
                    filtersReport[keyFilter].value = [];
                });
            }

            const newSort = rangeMode === PERIOD.NOW ? newSortConfiguration.nowSort:newSortConfiguration.customSort;
            const newUpdateSort =  JSON.stringify(newSort) !== JSON.stringify(sort)&&action!==TYPE_RESET_FILTER.RESET_FILTER && updateSort&&action !== TYPE_RESET_FILTER.LOAD_REPORT;
            dispatch(tableOnePlatformReplaceConfigHeaderColumnsSortFilters({reportKey, headerColumns:newHeaderColumns,sort:newSort,updateSort:newUpdateSort,updateFilters:true,filters:filtersReport}));

        }catch (e) {
            dispatch(patchReportProperties(reportKey, { configurationsStatus: ERROR }));
            console.log({e})
        }
    }
}