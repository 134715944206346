import { PATCH_FORM, REPLACE_FORM } from '../../action-types';
import { clearEmptyObjects, jsonPathReverseSetValue } from '../../../vss/app/actions/util/transform-data';

export const patchForm = (scope, key, value) => ({ type: PATCH_FORM, scope, key, value });

export const replaceForm = (scope, form) => ({ type: REPLACE_FORM, scope, form });

export const updateFieldsForm = (scope, paths, clearEmptyObj) => {
    return (dispatch, getState) => {
        const formData = getState().siteManager[scope]?.form;
        const field = 'store';
        let result = { [field]: { ...formData } };
        for (const [ path, value, emptyValues = [ null, undefined ] ] of paths) {
            result = jsonPathReverseSetValue(result, field, path, value);
            if (clearEmptyObj && emptyValues.some(v => JSON.stringify(v) === JSON.stringify(value))) {
                result = clearEmptyObjects(result, field, path, emptyValues);
            }
        }
        dispatch(replaceForm(scope, result[field]));
    };
};
