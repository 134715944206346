import Point from '../../../utils/ol/Point';

export const isValidPolygon = (shape) => {
    let isValid = true;
    for (let i = 0; i < shape.length - 1; i++) {
        for (let j = i + 1; j < shape.length - 1; j++) {
            const a = new Point();
            const interStrict = a.intersectsStrict(
                new Point(shape[i][0], shape[i][1]),
                new Point(shape[i + 1][0], shape[i + 1][1]),
                new Point(shape[j][0], shape[j][1]),
                new Point(shape[j + 1][0], shape[j + 1][1]),
            );
            if (interStrict) {
                isValid = false;
            }
        }
    }
    return isValid;
};
