import { ROUTES } from '../utils/NConstant';

export const DEFAULT_ROUTE_MIGRATION = '/' + ROUTES.LANDING;
export const ROUTES_MIGRATIONS = [
    {
        oldPath: "/reports/29F110EE-836D-434B-965E-7AA08FAFBBCF",
        newPath: DEFAULT_ROUTE_MIGRATION
    },
    {
        oldPath: "/reports/C0FB7CAD-8C25-11D5-9066-00105A6AE011",
        newPath: "/reports/resources"
    },
    {
        oldPath: "/reports/2D50E6C5-F1C7-4544-A91F-B1DA38045411",
        newPath: "/reports/tags"
    },
    {
        oldPath: "/reports/6611E48B-D5D6-11D3-9019-00105A6AE011",
        newPath: DEFAULT_ROUTE_MIGRATION
    },
    {
        oldPath: "/reports/826DEEF9-31FA-4CA0-BFB7-D697827574E7",
        newPath: "/reports/call/history"
    },
    {
        oldPath: "/reports/881F1A4F-64E3-4BFA-B80E-FE78DB7D8578",
        newPath: DEFAULT_ROUTE_MIGRATION
    },
    {
        oldPath: "/reports/A9E735CA-C416-4BD2-8011-814ED94C6D21",
        newPath: DEFAULT_ROUTE_MIGRATION
    },
    {
        oldPath: "/reports/2B4525D6-0189-4CD6-AD50-4C419383B170",
        newPath: DEFAULT_ROUTE_MIGRATION
    },
    {
        oldPath: "/reports/B9AF8F31-453D-4847-B48C-99523CC36776",
        newPath: "/reports/resource-event/history"
    },
    {
        oldPath: "/reports/6BAD2F8F-A0EC-42CF-94C5-3BC0BC5B71FA",
        newPath: "/reports/resource-zone-correlation/history"
    },
    {
        oldPath: "/reports/C94812E1-C727-4A71-B32E-8D5986E972EE",
        newPath: "/reports/system-alert/history"
    },
    {
        oldPath: "/reports/9756F150-B733-11D4-9045-00105A6AE011",
        newPath: "/reports/tag-blink/history"
    },
    {
        oldPath: "/reports/59395545-6D3C-49C5-A6F0-218B2197AE92",
        newPath: DEFAULT_ROUTE_MIGRATION
    },
    {
        oldPath: "/reports/F1C7422C-D5D0-11D3-9019-00105A6AE011",
        newPath: "/reports/alerts/notification-configurations"
    },
    {
        oldPath: "/reports/CF2768DA-D459-11D3-9019-00105A6AE011",
        newPath: "/reports/alerts/open"
    },
    {
        oldPath: "/reports/56147513-FAE2-4ACD-BB55-0659DE3D0BB4",
        newPath: DEFAULT_ROUTE_MIGRATION
    },
    {
        oldPath: "/reports/3AFD3EDD-9BEA-48C8-9555-DB6098723B9A",
        newPath: "/reports/infrastructure/hardware-devices"
    },
    {
        oldPath: "/reports/F9DBB6D7-49D8-4F2C-A67A-4EFF422C94B7",
        newPath: "/reports/infrastructure/whereports"
    },
    {
        oldPath: "/reports/6A6BDA1F-F91D-11D3-901E-00105A6AE011",
        newPath: DEFAULT_ROUTE_MIGRATION
    },
    {
        oldPath: "/reports/BD94C84A-CD63-4C99-950E-4947E0CDF643",
        newPath: "/reports/contacts/config"
    },
    {
        oldPath: "/reports/0E6B6EDA-CEE9-4D2C-8A9C-C92D99A87037",
        newPath: DEFAULT_ROUTE_MIGRATION
    },
    {
        oldPath: "/reports/784EA60E-A13C-11D3-9309-006008965FF8",
        newPath: DEFAULT_ROUTE_MIGRATION
    },
    {
        oldPath: "/reports/4D191515-A23D-493C-B164-036C75ADE157",
        newPath: DEFAULT_ROUTE_MIGRATION
    },
    {
        oldPath: "/reports/1DE2960D-4948-4042-BACE-66B2E6ABC81B",
        newPath: "/reports/zone-expiration"
    },
    {
        oldPath: "/reports/81BF9753-F9FB-11D3-901E-00105A6AE011",
        newPath: DEFAULT_ROUTE_MIGRATION
    },
    {
        oldPath: "/reports/1200AE63-C16E-4B01-865E-0BDB3711DCD2",
        newPath: DEFAULT_ROUTE_MIGRATION
    },
    {
        oldPath: "/reports/7F0B1E75-D394-4BF7-B77F-708BF1E6573F",
        newPath: DEFAULT_ROUTE_MIGRATION
    },
    {
        oldPath: "/reports/784EA612-A13C-11D3-9309-006008965FF8",
        newPath: "/reports/resource-types"
    },
    {
        oldPath: "/reports/3589E831-3265-43FE-B47B-BE03A3DACEAB",
        newPath: "/reports/system-alert"
    },
    {
        oldPath: "/reports/F35CC0D9-E3FE-11D3-901B-00105A6AE011",
        newPath: DEFAULT_ROUTE_MIGRATION
    },
    {
        oldPath: "/reports/E1FCA24F-B0F4-41A8-AD55-927A4FE7B2F5",
        newPath: DEFAULT_ROUTE_MIGRATION
    },
    {
        oldPath: "/reports/01F59EC9-9F99-4317-93D2-5DE114F6C259",
        newPath: DEFAULT_ROUTE_MIGRATION
    },
    {
        oldPath: "/reports/B9D2691C-F552-11D3-901E-00105A6AE011",
        newPath: DEFAULT_ROUTE_MIGRATION
    },
    {
        oldPath: "/reports/1C7E518C-A269-46F5-AEBC-D6AE8DD73885",
        newPath: DEFAULT_ROUTE_MIGRATION
    },
    {
        oldPath: "/reports/8D833563-9F51-4730-8A2E-A5DA8ADDC3AC",
        newPath: DEFAULT_ROUTE_MIGRATION
    },
    {
        oldPath: "/reports/03D4FCF5-F505-4454-BDD6-64B410F3C1B8",
        newPath: DEFAULT_ROUTE_MIGRATION
    },
    {
        oldPath: "/reports/744AA3C9-ED43-422B-BCF8-EEECA1D883D6",
        newPath: "/reports/zone-groups"
    },
    {
        oldPath: "/reports/D48C0A98-943F-4052-B277-07AD0CDE9639",
        newPath: DEFAULT_ROUTE_MIGRATION
    },
    {
        oldPath: "/reports/784EA611-A13C-11D3-9309-006008965FF8",
        newPath: "/reports/zones"
    },
];