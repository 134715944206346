import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    // checkDevicesMap,
    getAdjustedBounds,
    refStore,
    saveMapConfiguration,
    saveWidthHeight,
    saveLonLatConfiguration,
    setErrorWidthHeight
} from "../../../actions";
import AlertMessage from '../../deviceManager/common/AlertMessage';
import SetLatLongComponent from './SetLatLongComponent';
import {Fill, Stroke, Style, Text} from "ol/style";
// import Snap from "ol/interaction/Snap";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import CircleStyle from "ol/style/Circle";
// import Modify from "ol/interaction/Modify";
import SettingsIcon from '@material-ui/icons/Settings';
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import ZoomInIcon from '@material-ui/icons/AddBox';
import CenterIcon from '@material-ui/icons/CenterFocusStrong';
import ZoomOutIcon from '@material-ui/icons/IndeterminateCheckBox';
import {centerMap, zoomInMap, zoomOutMap, zoomToMap} from "./configureCoordinatesOl";
import {CustomDrag} from "./MoveFeatures";
import {LOWER_LEFT_POINT, UPPER_RIGHT_POINT} from "../../../constants/SiteManager";
import NotificationInfo from "./NotificationInfo";
import {Dialog, DialogActions, DialogContent} from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import Paper from "@material-ui/core/Paper/Paper";
import Button from "@material-ui/core/Button";
import WarningIcon from '@material-ui/icons/Warning';

import { ToolbarTool } from '../../util/toolbar';

import CoordinatesIcon from './common/images/CoordinatesIcon';
import MapSizeIcon from './common/images/MapSizeIcon';
import PinDrop from '@material-ui/icons/PinDrop';
// Ol variables.
let draw;
let featuresDrawn=[];
const source = new VectorSource();
let dragInteraction=null;
const vector = new VectorLayer({
    source: source,
});

const ConfigureMapCoordinates=()=>{

    const CONFIGURE_COORDINATES="CONFIGURE_COORDINATES";
    const CONFIGURE_WIDTH_OR_HEIGHT="CONFIGURE_WIDTH_OR_HEIGHT";

    const [upperLeftX,setUpperLeftX]=useState(0);
    const [upperLeftY,setUpperLeftY]=useState(0);
    const [lowerRightX,setLowerRightX]=useState(0);
    const [lowerRightY,setLowerRightY]=useState(0);
    const [endRightX,setEndRightX]=useState(0);
    const [endRightY,setEndRightY]=useState(0);
    const [startLeftX,setStartLeftX]=useState(0);
    const [startLeftY,setStartLeftY]=useState(0);
    const [startWidth,setStartWidth]=useState(null);
    const [startHeight,setStartHeight]=useState(null);
    const [step,setStep]=useState(null);
    const [showWarningDialog,setShowWarningDialog]=useState(null);
    const [configWH, setConfigWH] = useState(false);
    const [configLonLat, setConfigLonLat] = useState(false);
    const [openAlertValue, setOpenAlertValue] = useState(false);
    const [messageAlertValue, setMessageAlertValue] = useState("");
    const [openOptions, setOpenOptions] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openWarningMessage, setOpenWarningMessage] = useState(false);
    const [warningMessage, setWarningMessage] = useState("");
    const [errorCoordinates, setErrorCoordinates] = useState({});
    // const [positionUserDrawnPoints, setPositionUserDrawnPoints] = useState({});
    const MAX_COORDINATE_ERROR_RATIO=0.01;

    const map=useRef();
    const mapsConfiguration=useSelector(state=>state.reportMap.mapConfiguration);
    const selectedMapId=useSelector(state=>state.reportMap.mapId);
    const errorWH = useSelector(state=>state.reportMap.errorWH);
    const errorWHMessage = useSelector(state=>state.reportMap.errorWHmessage);
    const whSuccessful = useSelector(state=>state.reportMap.whSuccessful);
    const canEdit = useSelector(state => state.user.permissions['edit-infrastructure-site manager']);
    const dispatch = useDispatch();


    useEffect(()=>{
        setStep(null);
        setConfigWH(false);
        setConfigLonLat(false);
        if(map.current!=null) {
            removePoints();
        }

        const currentConfiguration=mapsConfiguration;
        if(currentConfiguration!=null){
            setUpperLeftX(currentConfiguration.mapCorner1X || (currentConfiguration.extent && currentConfiguration.extent[0]));
            setUpperLeftY(currentConfiguration.mapCorner1Y || (currentConfiguration.extent && currentConfiguration.extent[3]));
            setLowerRightX(currentConfiguration.mapCorner2X || (currentConfiguration.extent && currentConfiguration.extent[2]));
            setLowerRightY(currentConfiguration.mapCorner2Y || (currentConfiguration.extent && currentConfiguration.extent[1]));
            setEndRightX(currentConfiguration.mapPoint2X);
            setEndRightY(currentConfiguration.mapPoint2Y);
            setStartLeftX(currentConfiguration.mapPoint1X);
            setStartLeftY(currentConfiguration.mapPoint1Y);
            setStartWidth(currentConfiguration.mapCorner2X);
            setStartHeight(currentConfiguration.mapCorner1Y);
        }
        else{
            // const bounds2=map.current.getView().calculateExtent(map.current.getSize());
        }

    },[mapsConfiguration,selectedMapId]);

    const handleSetConfigWH = () => {
        if(whSuccessful) {
            setConfigWH(!whSuccessful);
        }
    };

    useEffect(handleSetConfigWH, [whSuccessful]);

    // useEffect(()=>{
    //     console.log("positionUserDrawnPoints",positionUserDrawnPoints);
    // }, [positionUserDrawnPoints]);

    // const memoizedCallback = useCallback(
    //     () => {
    //
    //     },
    //     [positionUserDrawnPoints.upperRightPoint, positionUserDrawnPoints.lowerLeftPoint,calculateErrorRatio],
    // );

    // useEffect(()=>{
    //     console.log("positionUserDrawnPoints",positionUserDrawnPoints);
    //     if(positionUserDrawnPoints.upperRightPoint&&positionUserDrawnPoints.lowerLeftPoint) {
    //         memoizedCallback();
    //     }
    // }, [positionUserDrawnPoints,memoizedCallback]);



    function renderFistStep(){
        return <div>
            <div className={'message-for-user'}>
                <b>First Step:</b> Define points
                <div className={'cancel-map-coordinates'}>
                    <button onClick={() => {setStep(null)}}>Cancel</button>
                </div>
                <div className={'save-map-coordinates'}>
                    {(step === 1) && <button onClick={secondStep} disabled={!validSize()}>Next Step</button>}
                </div>
            </div>

            <div className={'first-step-map-coordinates'}>

                <div className={'form-size-map'}>
                    <div className={'user-defined-size'}>
                        <label>Specify Lower Left Point</label>
                        <br/>
                        <label>X</label>:<input size={8} value={startLeftX} onChange={(e) => setStartLeftX( e.target.value)}/>
                        <label>Y</label>:<input size={8} value={startLeftY} onChange={(e) => setStartLeftY( e.target.value)}/>
                    </div>

                    <div className={'user-defined-size'}>
                        <label>Specify Upper Right Point</label>
                        <br/>
                        <label>X</label>:<input size={8} value={endRightX} onChange={(e) => setEndRightX(e.target.value)}/>
                        <label>Y</label>:<input size={8} value={endRightY} onChange={(e) => setEndRightY(e.target.value)}/>
                    </div>



                </div>
            </div>
        </div>;
    }

    function renderSecondStep(){

        return  <div>
                    <div className={'message-for-user'}><b>Second Step:</b> Position Icons on Map</div>
                    <div className={'message-info-container'}>
                        <span className={'message-info-accuracy'}>For better accuracy zoom in and position icons on map </span>
                         {renderMessageRatio()}
                    </div>


                    <div className={'second-step-map-coordinates'}>
                        <div className={'save-map-coordinates'}>
                            <button onClick={saveConfiguration}>SAVE</button>
                        </div>
                    </div>
                </div>;
    }

    function currentStep(){
        if(step===1)
            return renderFistStep();
        else if (step===2)
            return renderSecondStep();
        else
            return null;
    };

    function configWidthHeight(){
        if(configWH){
            return renderConfigWidthHeight();
        }
    }

    function handleSaveWidthOrHeight(){
        if(!isNaN(startWidth) ||  !isNaN(startHeight)){
            if(startWidth != null && startWidth !== "" && startWidth < 10) {
                setMessageAlertValue("The map width specified is far too small to be accurate!!")
                setOpenAlertValue(true);
            } else if(startHeight != null && startHeight !== "" && startHeight < 10) {
                setMessageAlertValue("The map height specified is far too small to be accurate!!");
                setOpenAlertValue(true);
            } else {
                dispatch(saveWidthHeight(selectedMapId, startWidth, startHeight, mapsConfiguration.coordinates));
                setStartWidth(null);
                setStartHeight(null);
            }
        }
    }

    function renderConfigWidthHeight(){
        return <div>
            <div className={'message-for-user'}>
                <b>Configure Width or Height</b>
                <div className={'cancel-map-coordinates'} style={{right:'110px'}}>
                    <button onClick={() => {setConfigWH(null)}}>Cancel</button>
                </div>
                <div className={'save-map-coordinates'}>
                    {(configWH) && <button onClick={handleSaveWidthOrHeight} disabled={!validateSave()} >Save</button>}
                </div>
            </div>

            <div className={'config-map-width-height'}>
                <div className={'form-config-map'}>
                    <div className={'label-defined-size'}>
                        <label>Specify values for:</label>
                    </div>
                    <div className={'config-defined-size'}>
                        <label>Width:</label><input size={8} value={startWidth  == null ? "" : startWidth} onChange={(e) => setStartWidth( e.target.value)}/>
                    </div>
                    <div className={'label-defined-size'}>
                        <label>Or</label>
                    </div>
                    <div className={'config-defined-size'}>
                        <label>Height:</label><input size={8} value={startHeight == null ? "" : startHeight} onChange={(e) => setStartHeight(e.target.value)}/>
                    </div>
                    {/*<div className={'save-map-coordinates'}>
                        {(configWH) && <button onClick={handleSaveWidthOrHeight} >Save</button>}
                    </div>*/}
                </div>
            </div>
        </div>;
    }

    function renderMessageRatio(explanation){
        let messageRatio=null;

        if(errorCoordinates!=null&&errorCoordinates.percentage!=null) {
             //messageRatio = <span className={(errorCoordinates.errorRatio > MAX_COORDINATE_ERROR_RATIO) ? 'coordinates-accuracy-warning' : 'coordinates-accuracy-ok'}>Coord system X/Y ratio accurate within {errorCoordinates.percentage.toFixed(4)} % {(errorCoordinates.errorRatio > MAX_COORDINATE_ERROR_RATIO)?'The error will spread in the four sides of map automatically, for prevent this please obtain a windows metafile with correct aspect ratio':null}</span>
            messageRatio = <span className={(errorCoordinates.errorRatio > MAX_COORDINATE_ERROR_RATIO) ? 'coordinates-accuracy-warning' : 'coordinates-accuracy-ok'}>Coord system X/Y ratio accurate within {errorCoordinates.percentage.toFixed(4)} % </span>
        }
        return messageRatio;
    }

    const validateSave = () =>{
        const validate = (startWidth != null && startWidth !== "") || (startHeight != null && startHeight !== "");

        return (validate);
    };

    const handleCloseAlertValueModal = () => {
        setOpenAlertValue(!openAlertValue);
    };
    const handleCloseWarningMessage = () => {
        setOpenWarningMessage(!openWarningMessage);
    };

    const handleSaveLonLat = (lonLatCoordinates) => {
        const mapCoordinates = {
            ...mapsConfiguration.coordinates,
            ...lonLatCoordinates
        };
        dispatch(saveLonLatConfiguration(selectedMapId, mapCoordinates))
        setConfigLonLat(!configLonLat)
    };

    const handleCloseLonLat = () => {
        setConfigLonLat(false);
    };

    const openOptionsMenu = (event) => {
        setOpenOptions(!openOptions);
        setAnchorEl(anchorEl ? null : event.currentTarget)
    };

    const handleClickAway = () => {
        setOpenOptions(false);
        setAnchorEl(null)
    }

    return (
        <div>
            {showWarningDialog&&<Dialog
                open={showWarningDialog}
                fullWidth
                onClose={closeAction}
            >
                <DialogContent >
                    <div style={{padding: '0.5em', textAlign: 'center', fontSize: '1.1em',lineHeight:'37px'}}><WarningIcon style={{fill:'#cc0000',fontSize:'1em',paddingRight:'5px'}}/>Recalibration Warning</div>
                    <div style={{padding: '0.5em', textAlign: 'left', fontSize: '1em'}}>Recalibrating this map will cause existing devices and zones associated with this map to shift its position on the map</div>
                </DialogContent>
                <DialogActions>
                    <Button style={{background: '#007CB0',color: '#fff', padding: '0.5em 0.8em'}} onClick={closeAction} component="span">
                        Cancel
                    </Button>
                    <Button style={{background: '#007CB0',color: '#fff', padding: '0.5em 0.8em'}} onClick={continueAction} component="span">
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>}

            {errorWH&&<Dialog
                open={errorWH}
                fullWidth
                onClose={handleCloseDialogWH}
            >
                <DialogContent >
                    <div style={{padding: '0.5em 0.5em 0.5em 0', textAlign: 'center', fontSize: '1.1em',lineHeight:'37px'}}><WarningIcon style={{fill:'#cc0000',fontSize:'1em',paddingRight:'5px', position:'relative', top:'2.5px'}}/>{errorWHMessage}</div>
                </DialogContent>
                <DialogActions>
                    <Button style={{background: '#007CB0',color: '#fff', padding: '0.5em 0.8em'}} onClick={handleCloseDialogWH} component="span">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>}

            {(step===2&&mapsConfiguration&&mapsConfiguration.distanceVariation&&mapsConfiguration.distanceVariation.variation>0)&&<NotificationInfo variation={mapsConfiguration.distanceVariation.variation}/>}
            {(selectedMapId)&&<div className={'configure-map-coordinates'}>

                <div className="toolbar">
                    <ToolbarTool
                        title="Zoom In"
                        onClick={zoomIn}
                        icon={<ZoomInIcon />}
                        isVertical
                    />

                    <ToolbarTool
                        title="Zoom Out"
                        onClick={zoomOut}
                        icon={<ZoomOutIcon />}
                        isVertical
                    />

                    <ToolbarTool
                        title="Center Map"
                        onClick={center}
                        icon={<CenterIcon />}
                        isVertical
                    />

                    {canEdit && <ToolbarTool
                        title="Configure Map"
                        onClick={openOptionsMenu}
                        icon={<SettingsIcon />}
                        isVertical
                    />}
                </div>

                <Popover
                    anchorEl={anchorEl}
                    open={openOptions}
                    // placement={"right-start"}
                    anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                    className={"configuration-dropdown-container"}
                    onClose={handleClickAway}
                >
                    <Paper>
                        <div className={"configuration-dropdown-item"} onClick={checkDevices}>
                            {/*<IconButton onClick={checkDevices} style={{marginLeft:'1px'}} className={'toolbar-button'} disableRipple data-tip={"Configure coordinates"} data-for={"centerMap"}>*/}
                            <CoordinatesIcon width={16} height={16}/>
                            &nbsp;
                            {/*</IconButton>*/}
                            {/*<ReactToolTip className={"tooltip-toolbar-class"} place={"left"} id={"centerMap"} effect={"solid"}/>*/}
                            <div>Give Coordinates Of Two Points</div>
                        </div>

                        <div className={"configuration-dropdown-item"} onClick={setConfigWidthHeight}>
                            {/*<IconButton onClick={setConfigWidthHeight} style={{marginLeft:'1px'}} className={'toolbar-button'} disableRipple data-tip={"Configure width or height"} data-for={"widthHeight"}>*/}
                            <MapSizeIcon width={16} height={16}/>
                            &nbsp;
                            {/*</IconButton>*/}
                            {/*<ReactToolTip className={"tooltip-toolbar-class"} place={"left"} id={"widthHeight"} effect={"solid"}/>*/}
                            <div>Specify Map Width Or Height</div>
                        </div>

                        <div className={!mapsConfiguration.coordinates || Object.keys(mapsConfiguration.coordinates).length === 0 ? "configuration-dropdown-item configuration-dropdown-item-disabled" : "configuration-dropdown-item"} onClick={handleSetConfigLonLat}>
                            {/*<IconButton disabled={!mapsConfiguration.coordinates || Object.keys(mapsConfiguration.coordinates).length === 0}
                                        onClick={handleSetConfigLonLat} className={'toolbar-button'} disableRipple data-tip={"Configure Long and Lat"} data-for={"centerMap"}>*/}
                            <PinDrop style={{ width: "18px", height: "18px", color: !mapsConfiguration.coordinates || Object.keys(mapsConfiguration.coordinates).length === 0 ? '#bdbdbd' : '#000000'}}/>
                            &nbsp;
                            {/*</IconButton>*/}
                            {/*<ReactToolTip className={"tooltip-toolbar-class"} place={"left"} id={"centerMap"} effect={"solid"}/>*/}
                            <div>Specify Site Latitude and Longitude</div>
                        </div>
                    </Paper>
                </Popover>

            </div>}

            <div>{currentStep()}
           </div>
            <div>{configWidthHeight()}</div>
            <div>
                For better accuracy zoom in and position icons on map
            </div>
            {configLonLat && <div><SetLatLongComponent configLonLat={configLonLat} saveLonLat={handleSaveLonLat} closeLonLat={handleCloseLonLat} mapsConfiguration={mapsConfiguration} /></div>}

            {openAlertValue && <AlertMessage openModal={openAlertValue} onClose={handleCloseAlertValueModal} message={messageAlertValue}/>}
            {openWarningMessage && <AlertMessage openModal={openWarningMessage} onClose={handleCloseWarningMessage} message={warningMessage} isWarning={true} maxWidth={'sm'}/>}

        </div>
    );

    function closeAction(){
        setShowWarningDialog(null);
    }
    function continueAction(){
        const currentAction=showWarningDialog;

        setShowWarningDialog(null);

        if(currentAction===CONFIGURE_COORDINATES) {
            firstStep();
        }else if(currentAction===CONFIGURE_WIDTH_OR_HEIGHT){
            setConfigWH(!configWH);
        }
    }

    function handleCloseDialogWH(){
        dispatch(setErrorWidthHeight(false, null));
    };

    async function checkDevices(){
        setOpenOptions(!openOptions);
        setAnchorEl(null);
        setShowWarningDialog(null);

        // const enableCoordinates = await dispatch(checkDevicesMap(selectedMapId));
        const showWarning = (mapsConfiguration!=null&&mapsConfiguration.mapCorner1X!=null);

        if (showWarning === true) {
                setShowWarningDialog(CONFIGURE_COORDINATES);
            }else{
                firstStep();
            }

    }

    async function firstStep() {

        setUpperLeftX(mapsConfiguration.mapCorner1X || (mapsConfiguration.extent && mapsConfiguration.extent[0]));
        setUpperLeftY(mapsConfiguration.mapCorner1Y || (mapsConfiguration.extent && mapsConfiguration.extent[3]));
        setLowerRightX(mapsConfiguration.mapCorner2X || (mapsConfiguration.extent && mapsConfiguration.extent[2]));
        setLowerRightY(mapsConfiguration.mapCorner2Y || (mapsConfiguration.extent && mapsConfiguration.extent[1]));
        setEndRightX(mapsConfiguration.mapPoint2X);
        setEndRightY(mapsConfiguration.mapPoint2Y);
        setStartLeftX(mapsConfiguration.mapPoint1X);
        setStartLeftY(mapsConfiguration.mapPoint1Y);
        if (step != null) {
            setStep(null);
        } else {
            setStep(1);
        }
        // map.current=window.MY_MAP;

        setConfigWH(false);
        setConfigLonLat(false);
        map.current = refStore.olMap;
        removePoints();
    }

    function secondStep(){

        drawConfigurationPoints();

        addDefaultPointsPosition([upperLeftX,lowerRightY],[lowerRightX,upperLeftY]);
        const currentBounds=map.current.getView().calculateExtent(map.current.getSize());

        setUpperLeftX(currentBounds[0]);
        setUpperLeftY(currentBounds[1]);
        setLowerRightX(currentBounds[2]);
        setLowerRightY(currentBounds[3]);
        setStep(2);

        center();
        zoomToMap(refStore.olMap,0.75);
        const newErrorCoordinates = calculateErrorRatio([lowerRightX,upperLeftY],[upperLeftX,lowerRightY]);
        setErrorCoordinates(newErrorCoordinates);

    }

    async function setConfigWidthHeight() {
        setOpenOptions(!openOptions);
        setAnchorEl(null);
        setStep(null);
        setConfigLonLat(false);

        setShowWarningDialog(null);
        // const enableCoordinates = await dispatch(checkDevicesMap(selectedMapId));
        const showWarning = (mapsConfiguration != null && mapsConfiguration.mapCorner1X != null);

        if (showWarning === true) {
            setShowWarningDialog(CONFIGURE_WIDTH_OR_HEIGHT);
        } else {
            setConfigWH(!configWH);
        }
    }

    function handleSetConfigLonLat() {
        setOpenOptions(!openOptions);
        setAnchorEl(null);
        setConfigLonLat(!configLonLat);
        setConfigWH(false);
        setStep(null);
    }

    function addDefaultPointsPosition(lowerLeft, upperRight) {

        const style = {
            fill: new Fill({
                color: 'rgba(255, 255, 255, 0.2)'
            }),
            stroke: new Stroke({
                color: '#00ff33',
                width: 2
            }),
            image: new CircleStyle({
                radius: 7,
                fill: new Fill({
                    color: '#ffcc33'
                })
            })
        };

        const lowerLeftPoint = new Feature({
            geometry: new Point(lowerLeft),

        });
        lowerLeftPoint.set('name',LOWER_LEFT_POINT)

        lowerLeftPoint.setStyle(new Style({
            ...style, text: new Text({
                font: '11px Calibri,sans-serif',
                fill: new Fill({color: '#3c3c3c'}),
                text: 'Lower Left',
                offsetY: -10,
                stroke: new Stroke({
                    color: '#fff', width: 2
                })
            })
        }));
        const upperRightPoint = new Feature({
            geometry: new Point(upperRight),
        });
        upperRightPoint.set('name',UPPER_RIGHT_POINT)

        upperRightPoint.setStyle(new Style({
            ...style, text: new Text({
                font: '11px Calibri,sans-serif',
                fill: new Fill({color: '#3c3c3c'}),
                text: 'Upper Right',
                offsetY: -10,
                stroke: new Stroke({
                    color: '#fff', width: 2
                })
            })
        }));

        source.addFeature(lowerLeftPoint);
        featuresDrawn.push(lowerLeftPoint);
        source.addFeature(upperRightPoint);
        featuresDrawn.push(upperRightPoint);
        dragInteraction.lowerLeftFeature=featuresDrawn[0];
        dragInteraction.upperRightFeature=featuresDrawn[1];

    }

    function saveConfiguration() {

        const newExtent=calculateNewExtent();

        let coordinates = {
            // "name": "Map A",
            mapPoint1X: newExtent.mapPoint1X,// map size
            mapPoint1Y: newExtent.mapPoint1Y,
            mapPoint2X: newExtent.mapPoint2X,
            mapPoint2Y: newExtent.mapPoint2Y,
            mapCorner1X: newExtent.mapCorner1X, // lower and upper points
            mapCorner1Y: newExtent.mapCorner1Y,
            mapCorner2X: newExtent.mapCorner2X,
            mapCorner2Y: newExtent.mapCorner2Y,
            extent: newExtent.extent,
            distanceVariation: newExtent.distanceVariation,
        };

        dispatch(saveMapConfiguration(selectedMapId,coordinates));

        removePoints();

        setStep(null);

    }

    function drawConfigurationPoints(){

        const bounds=map.current.getView().calculateExtent(map.current.getSize());

        removePoints();

        addPointsInteraction(map.current,bounds);
        //drawExtent([startLeftX,startLeftY,endRightX,endRightY]);
    }

    function onDrag(lowerLeftPoint,upperRightPoint){
        const newErrorCoordinates = calculateErrorRatio(upperRightPoint, lowerLeftPoint);
        setErrorCoordinates(newErrorCoordinates);
        // setPositionUserDrawnPoints({lowerLeftPoint:lowerLeftPoint,upperRightPoint:upperRightPoint});
    }

    function addPointsInteraction(map,bounds){

        if(draw==null) {

            map.addLayer(vector);
            dragInteraction=new CustomDrag();
            map.addInteraction(dragInteraction);
            dragInteraction.subscribeDrag(onDrag);

        }
    }

    function removePoints(){
        const olMap=map.current;

        featuresDrawn=[];
        source.clear();
        olMap.removeInteraction(dragInteraction);
        olMap.removeLayer(vector);
        draw=null;

    }

    function calculateNewExtent(){
        // const bounds=map.current.getView().calculateExtent(map.current.getSize());
        const lowerLeft = featuresDrawn[0].getGeometry().getFlatCoordinates();
        const upperRight = featuresDrawn[1].getGeometry().getFlatCoordinates();

        return calculateExtent(lowerLeft, upperRight);
    }

    function calculateExtent(lowerLeft,upperRight){


        // Compute the ratio error with the current drawn points.
        const newErrorCoordinates=calculateErrorRatio(lowerLeft,upperRight);

        // Show error message when the ratio error is bigger than 0.01.
        if (newErrorCoordinates.errorRatio > MAX_COORDINATE_ERROR_RATIO) {
            setOpenWarningMessage(true);
            //messageRatio = <span className={(errorCoordinates.errorRatio > MAX_COORDINATE_ERROR_RATIO) ? 'coordinates-accuracy-warning' : 'coordinates-accuracy-ok'}>Coord system X/Y ratio accurate within {errorCoordinates.percentage.toFixed(4)} % {(errorCoordinates.errorRatio > MAX_COORDINATE_ERROR_RATIO)?'The error will spread in the four sides of map automatically, for prevent this please obtain a windows metafile with correct aspect ratio':null}</span>
            setWarningMessage("Coord system X/Y ratio accurate within "+newErrorCoordinates.percentage.toFixed(4)+ "%. The error will spread in the four sides of map automatically, for prevent this adjust the coordinates or obtain a windows metafile with correct aspect ratio");
        }


        // Current bounds.
        // const adjustedBounds=getAdjustedBounds([mapsConfiguration.mapCorner1X,mapsConfiguration.mapCorner2Y,mapsConfiguration.mapCorner2X,mapsConfiguration.mapCorner1Y]);
        const adjustedBounds=getAdjustedBounds(mapsConfiguration.extent);

        let newAdjustedLowerLeftX=parseFloat(startLeftX);
        let newAdjustedLowerLeftY=parseFloat(startLeftY);
        let newAdjustedUpperRightX=parseFloat(endRightX);
        let newAdjustedUpperRightY=parseFloat(endRightY);

        // Distance between drawn points.
        const currentDistanceX=upperRight[0]-lowerLeft[0];
        const currentDistanceY=upperRight[1]-lowerLeft[1];

        // Distance outside the drawn points (box).
        const remainingLeftX=lowerLeft[0]-adjustedBounds[0];
        const remainingRightX=adjustedBounds[2]-upperRight[0];
        const remainingUpY=adjustedBounds[3]-upperRight[1];
        const remainingDownY=lowerLeft[1]-adjustedBounds[1];

        // Distance between points filled in form.
        const newRequiredDistanceX=newAdjustedUpperRightX-newAdjustedLowerLeftX;
        const newRequiredDistanceY=newAdjustedUpperRightY-newAdjustedLowerLeftY;

        // Ratio of distances.
        const ratioX=newRequiredDistanceX/currentDistanceX;
        const ratioY=newRequiredDistanceY/currentDistanceY;

        // const totalRequiredDistanceX=(remainingLeftX*ratioX)+(currentDistanceX*ratioX)+(remainingRightX*ratioX);
        // const totalRequiredDistanceY=(remainingUpY*ratioY)+(currentDistanceY*ratioY)+(remainingDownY*ratioY);


        // New required distances.
        const requiredLeftX=newAdjustedLowerLeftX-(remainingLeftX*ratioX);
        const requiredRightX=newRequiredDistanceX+newAdjustedLowerLeftX+(remainingRightX*ratioX);
        const requiredLowerY=newAdjustedLowerLeftY-(remainingDownY*ratioY);
        const requiredUpperY=newRequiredDistanceY+newAdjustedLowerLeftY+(remainingUpY*ratioY);

        // setNewBounds([requiredLeftX,requiredLowerY,requiredRightX,requiredUpperY]);



        // New extent points.
        let newRequiredLeftX;
        let newRequiredRightX;
        let newRequiredLowerY;
        let newRequiredUpperY;


        // When the defined square in forms does not match with the drawn square we distribute the difference in x and y coordinates.
        const dY = requiredUpperY-requiredLowerY;
        const dX = requiredRightX-requiredLeftX;
        let errorDistance;
        let errorDistanceBySite;
        let direction;

        if(dY > dX){
            direction='yx';
            errorDistance=(dY - dX);
            errorDistanceBySite =  errorDistance/ 4;

            newRequiredLeftX=requiredLeftX-errorDistanceBySite;
            newAdjustedLowerLeftX-=errorDistanceBySite;
            newRequiredRightX=requiredRightX+errorDistanceBySite;
            newAdjustedUpperRightX+=errorDistanceBySite;

            newRequiredLowerY=requiredLowerY+errorDistanceBySite;
            newAdjustedLowerLeftY+=errorDistanceBySite;
            newRequiredUpperY=requiredUpperY-errorDistanceBySite;
            newAdjustedUpperRightY-=errorDistanceBySite;

        }else{
            direction='xy';
            errorDistance=(dX - dY);
            errorDistanceBySite = errorDistance / 4;

            newRequiredLowerY=requiredLowerY-errorDistanceBySite;
            newAdjustedLowerLeftY-=errorDistanceBySite;
            newRequiredUpperY=requiredUpperY+errorDistanceBySite;
            newAdjustedUpperRightY+=errorDistanceBySite;

            newRequiredLeftX=requiredLeftX+errorDistanceBySite;
            newAdjustedLowerLeftX+=errorDistanceBySite;
            newRequiredRightX=requiredRightX-errorDistanceBySite;
            newAdjustedUpperRightX-=errorDistanceBySite;

        }

        newRequiredLeftX=parseFloat(newRequiredLeftX);
        newRequiredUpperY=parseFloat(newRequiredUpperY);
        newRequiredRightX=parseFloat(newRequiredRightX);
        newRequiredLowerY=parseFloat(newRequiredLowerY);

        // Update the state of new points.
        setUpperLeftX(newRequiredLeftX);
        setUpperLeftY(newRequiredUpperY);
        setLowerRightX(newRequiredRightX);
        setLowerRightY(newRequiredLowerY);

        // setAdjustedLowerLeftX(newAdjustedLowerLeftX);
        // setAdjustedLowerLeftY(newAdjustedLowerLeftY);
        // setAdjustedUpperRightX(newAdjustedUpperRightX);
        // setAdjustedUpperRightY(newAdjustedUpperRightY);

        return {
            mapPoint1X: parseFloat(startLeftX),
            mapPoint1Y: parseFloat(startLeftY),
            mapPoint2X: parseFloat(endRightX),
            mapPoint2Y: parseFloat(endRightY),
            mapCorner1X:newAdjustedLowerLeftX,
            mapCorner1Y:newAdjustedUpperRightY,
            mapCorner2X:newAdjustedUpperRightX,
            mapCorner2Y:newAdjustedLowerLeftY,
            extent:[newRequiredLeftX,newRequiredLowerY,newRequiredRightX,newRequiredUpperY],
            distanceVariation:{variation:errorDistance,direction:direction}
        }

    }

    function validSize(){
        const minWidth=1;
        const minHeight=1;

        if (startLeftX != null
            && startLeftY != null
            && endRightX != null
            && endRightY != null) {
            return (((endRightX - startLeftX) >= minWidth) && ((endRightY-startLeftY) >= minHeight));
        }
        return false;

    }

    function calculateErrorRatio(upperRight,lowerLeft){

        const mapAspectRatio =(endRightY-startLeftY) / (endRightX-startLeftX);

        const coordinateAspectRatio = (upperRight[1]-lowerLeft[1]) / (upperRight[0]-lowerLeft[0]);

        const ratio = Math.abs(mapAspectRatio / coordinateAspectRatio);

        let errorRatio = Math.abs(ratio - 1);

        const inUnit= ((errorRatio * (endRightY - startLeftY)) + (errorRatio * (endRightX - startLeftX)));

        const newErrorCoordinates = {
            mapAspectRatio: mapAspectRatio,
            coordinateAspectRatio: coordinateAspectRatio,
            errorRatio: errorRatio,
            ratio: ratio,
            percentage: errorRatio * 100,
            inUnit: inUnit,
            // upperRight:upperRight,
            // lowerLeft:lowerLeft
        };

        // setErrorCoordinates(newErrorCoordinates);

        return newErrorCoordinates;
    }

    function zoomIn(){
        zoomInMap(refStore.olMap);
    }
    function zoomOut(){
        zoomOutMap(refStore.olMap);
    }
    function center(){
        // const bounds=[mapsConfiguration.mapCorner1X,mapsConfiguration.mapCorner1Y,mapsConfiguration.mapCorner2X,mapsConfiguration.mapCorner2Y];
        const bounds=mapsConfiguration.extent;
        centerMap(refStore.olMap,bounds);
    }
}
export default ConfigureMapCoordinates;
