import React, { useState, useEffect } from 'react';
import StyledDialog, { SaveButton } from "../../util/Dialog";
import { Form, Field } from '../../util/DataEntryTabularForm';
import { useDispatch } from 'react-redux';
import {changeZoneGroup} from '../../../actions';

const GroupsDialog = ({ open, mapId, groupName: prevGroupName, onClose }) => {
  const dispatch = useDispatch();
  const [zoneGroupName, setZoneGroupName] = useState("");

  useEffect(() => {
    if (prevGroupName) {
      setZoneGroupName(prevGroupName);
    }
  }, [prevGroupName]);
  
  const onChange = (e) => {
    setZoneGroupName(e.target.value);
  }

  const onSaveClick = async () => {
    // dispatch(editZoneGroupName(prevGroupName, zoneGroupName, mapId));
    dispatch(changeZoneGroup(prevGroupName,zoneGroupName));
    handleClose();
  }

  const handleClose = () => {
    onClose();
    setTimeout(() => setZoneGroupName(""), 250); // cause dialog transition
  }

  return (
    <StyledDialog
      open={open}
      title="Edit a Zone Group"
      onClose={handleClose}
      maxWidth={"xs"}
      actions={
        <SaveButton 
          disabled={!!!zoneGroupName} 
          onClick={onSaveClick} 
          label={prevGroupName ? "Save" : "Create"} 
        />
      }
    >
      <Form fullWidth>
        <Field label={"Zone Group Name"} required>
          <input 
            type="text" 
            autoComplete="off" 
            onChange={onChange} 
            value={zoneGroupName} 
          />
        </Field>
      </Form>
    </StyledDialog>
  );
}

export default GroupsDialog;