import React, {useEffect, useState} from "react";
import { REPORT_KEY_DEFAULT } from "../../../../constants";
import {SelectInputToolbar} from "./SelectInputToolbar";
import BackIcon from '@material-ui/icons/ArrowBackIos';
import ForwardIcon from '@material-ui/icons/ArrowForwardIos';
import {useReportDispatch, useReportKey, useReportState} from "../../../../hooks";
import {getValueOfPath} from "../../../../utils";
import {FILTER_TYPE, PERIOD} from "../../../util/report/filter/util";
import {useSelector} from "react-redux";

const Pagination = ({filtersValues,sort}) =>{
    const { reportKey } = useReportKey();
    const { paginationInfo,counterRefresh,isFetchingData } = useReportState(reportKey);
    // const { maxResultsByPage } = useReportState(REPORT_KEY_DEFAULT);
    const { patchReportProperties } = useReportDispatch(reportKey);
    const [displayError, setDisplayError] = useState(false);
    const [errors, setErrors] = useState({});
    const {pageListConfiguration,page,totalPages} = paginationInfo;

    const filters = useSelector(state => state.filtersOnePlatform[reportKey]);
    const timeFilter = Object.values(filters).find( filter  => filter.type === FILTER_TYPE.TIME);
    const { rangeMode } = timeFilter||{};

    const enableButton = !(isFetchingData && isFetchingData !== 0);
    const defaultOption = {value:'1',name:'1'};
    const field = {key:'page', label:''};

    useEffect(()=>{
        patchReportProperties({ paginationInfo:{...paginationInfo,pageListConfiguration:{},page:1}});
        // eslint-disable-next-line
    },[filtersValues,sort]);
    useEffect(()=>{
        if(!page){
            patchReportProperties({ paginationInfo:{...paginationInfo,page:1}});
        }
        // eslint-disable-next-line
    },[page])
    const getPageListConfiguration = () => {
        const pages = [];
        const count = Object.keys(pageListConfiguration).length-1;
        Object.keys(pageListConfiguration).forEach((value,index)=>{
            const pageSelect = +value;
            if(index !== count){
                pages.push({value:''+(pageSelect+1),name:''+(pageSelect+1)})
            }
        });
        return pages;
    }

    const pages = rangeMode === PERIOD.CUSTOM ? getPageListConfiguration() : Array.from({length: totalPages}, (_, index) => ({value:''+(index+1),name:''+(index+1)}));

    // useEffect(()=>{
    //     if(maxResultsByPage  && maxResultsByPage !== paginationInfo.maxResultsByPage){
    //         const newPagination  = {...paginationInfo};
    //         newPagination.maxResultsByPage = maxResultsByPage;
    //         newPagination.page = 1;
    //         newPagination.enableSelectPage = true;
    //         newPagination.pageListConfiguration = {}
    //         patchReportProperties({ paginationInfo:newPagination,counterRefresh:counterRefresh+1});
    //     }
    //     // eslint-disable-next-line
    // },[maxResultsByPage,paginationInfo,paginationInfo.maxResultsByPage,counterRefresh]);

    useEffect(() => {
        setDisplayError(false);
    }, [errors]);

    const changePage = (nextPage, prevPage) => {
        //let offset = paginationInfo.offset;
        let page = paginationInfo.page;
        if(nextPage){
            //offset = offset + paginationInfo.maxResultsByPage;
            page = page + 1;
        }
        if(prevPage){
            //offset = offset - paginationInfo.maxResultsByPage;
            page = page - 1;
        }
        const newPagination  = {...paginationInfo, page/*, offset*/,enableSelectPage:true};
        patchReportProperties({paginationInfo:newPagination,counterRefresh:counterRefresh+1});
    };
    const handleChangePage = (nextPage, prevPage) => {
        if(nextPage && !paginationInfo.nextPageAllow){
            return;
        }
        if(prevPage && !paginationInfo.prevPageAllow){
            return;
        }
        changePage(nextPage, prevPage);
    }
    const changePageInput  = (page) =>{
        const newPage = +page;
        if(  newPage !== paginationInfo.page){
            const newPagination  = {...paginationInfo, page:newPage,enableSelectPage:true};
            patchReportProperties({paginationInfo:newPagination,counterRefresh:counterRefresh+1});
        }
    }

    return (
        <div style={{display:'flex', height: '100%', lineHeight:'45px', /*margin: '0 20px'*/}} className={'toolbar-main-container-pagination'}>
            {paginationInfo.totalRecords > 0 && /*enableButton &&*/ <div style={{margin: '0 5px', /*minWidth:'85px',*/lineHeight:'50px', }}>{((paginationInfo.totalRecords > 0 ?
                (typeof paginationInfo.offset==='number'?paginationInfo.offset+1:paginationInfo.offset) : paginationInfo.offset) + " - " + paginationInfo.totalPerPage + " of " + paginationInfo.totalRecords)}</div> }
            <div style={{display:'flex',margin:'6px'}}>
                <div style={{cursor:'pointer', /*lineHeight: '62px', */decoration:'underline', color: !paginationInfo.prevPageAllow || !enableButton? '#cecece': 'inherit'}}
                     onClick={() => enableButton&&handleChangePage(false, true)}><BackIcon/></div>
                {
                    <SelectInputToolbar
                        key={'pagination'}
                        value={ ''+page }
                        onChange={(value) => changePageInput( value)}
                        filterKey={'pagination'}
                        multiselect={false}
                        isAllAllowed={false}
                        disabled={false}
                        defaultOption={defaultOption ? { value: defaultOption.value, name: defaultOption.name } : null}
                        properties={{
                            name: field.key,
                            label: field.label,
                            validations: field.validations || [],
                            inputDisabled:rangeMode === PERIOD.CUSTOM
                        }}
                        field={field}
                        filter={pages}

                        forceDisplayError={displayError}
                        onError={(error) => {
                            setErrors(prevState => ({ ...prevState, error }));
                        }}
                        options={pages}
                    />
                }
                <div style={{cursor:'pointer',/* lineHeight: '62px', */ decoration:'underline', color: !paginationInfo.nextPageAllow || !enableButton? '#cecece': 'inherit',}}
                     onClick={() => enableButton&&handleChangePage(true, false)}><ForwardIcon/></div>
            </div>
        </div>
    );
}
//export default Pagination;
export default React.memo(Pagination, ((prevProps, nextProps) => {
    return (prevProps.filtersValues === nextProps.filtersValues
        && prevProps.sort === nextProps.sort
    );
}));


export const getPaginationParameters  = (result,reportKey,scopes) =>{
    const scopesReport = scopes?.[reportKey];
    const scopesDefault = scopes?.[REPORT_KEY_DEFAULT];
    const keys = ["totalElements","numberOfElements","totalPages","pageNumber","offset"];
    const parameters = {};
    keys.forEach(key=>{
        const value =  (scopesReport?.[key] && getValueOfPath(result,scopesReport?.[key]))|| (scopesDefault?.[key] &&getValueOfPath(result,scopesDefault?.[key]));
        parameters[key] = +value;
    });
    return parameters;
}


export const getNewPaginationInfo = (result,{totalElementsResult,numberOfElementsResult,pageNumberPageable,offsetPageable,totalPagesResult,paginationInfo,pagePrev,enableSelectPage,
                                    page,lastValueResult,pageListConfiguration,filters,nextPageAllow})=>{

    const {count,offset:offsetStatus}  = result.status||{};
    const totalElements = count||count===0?count:totalElementsResult;
    const numberOfElements = count||count===0?count:numberOfElementsResult;
    const pageNumber = pageNumberPageable||pageNumberPageable===0?pageNumberPageable:0;
    const offset = offsetPageable||offsetPageable===0?offsetPageable:offsetStatus;
    const totalPages = totalPagesResult?totalPagesResult:1;

    let newPagination = {...paginationInfo,totalRecords:totalElements,page:pagePrev,totalPages,offset:offset};
    newPagination.totalPerPage = offset + numberOfElements;
    newPagination.nextPageAllow = offset + numberOfElements < totalElements;
    newPagination.prevPageAllow = offset > 0;
    newPagination.enableSelectPage = false;

    const timeFilter = Object.values(filters).find( filter  => filter.type === FILTER_TYPE.TIME);
    const { rangeMode } = timeFilter||{};

    if(!enableSelectPage && page>=pageNumber ){
        newPagination.page = 1;
    }

    if(Object.keys(pageListConfiguration).length === 0){
        pageListConfiguration[0] = null;
    }
    if(lastValueResult){
        pageListConfiguration[pagePrev]=lastValueResult;
    }
    newPagination.pageListConfiguration = pageListConfiguration;
    if( rangeMode === PERIOD.CUSTOM ){
        newPagination.nextPageAllow = nextPageAllow;
    }
    return newPagination;
}
export const getConfigurationPagination = (paginationInfo,filters,columnConfigurationForPagination) =>{
    const timeFilter = Object.values(filters).find( filter  => filter.type === FILTER_TYPE.TIME);
    const { rangeMode } = timeFilter||{};
    const {maxResultsByPage:size,page:pagePrev,enableSelectPage,pageListConfiguration} = paginationInfo||{};
    let page = pagePrev > 0 && enableSelectPage?pagePrev-1:0;
    let extraParams = {};
    if( rangeMode === PERIOD.CUSTOM && columnConfigurationForPagination ){
        extraParams[columnConfigurationForPagination.nameParams] = pageListConfiguration[page]?pageListConfiguration[page]:null;
    }
    return {size, page, extraParams}
}