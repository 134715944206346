import {
    ADD_INTERACTION_ON_MAP,
    BACK_SNAPSHOT,
    CLEAN_SNAPSHOTS,
    FORWARD_SNAPSHOT,
    REMOVE_INTERACTION_ON_MAP,
    REPLACE_ITEMS,
    TAKE_SNAPSHOT,
} from '../../action-types';

export const replaceItems = (scope, itemType, items,zoneProperties) => ({ type: REPLACE_ITEMS, scope, itemType, items,zoneProperties});

export const addInteractionOnMap = (scope, interaction) => ({ type: ADD_INTERACTION_ON_MAP, scope, interaction });

export const removeInteractionOnMap = (scope, interaction) => ({ type: REMOVE_INTERACTION_ON_MAP, scope, interaction });

export const takeSnapshot = (scope, itemType, snapshot) => ({ type: TAKE_SNAPSHOT, scope, itemType, snapshot });

export const backSnapshot = (scope, itemType) => ({ type: BACK_SNAPSHOT, scope, itemType });

export const forwardSnapshot = (scope, itemType) => ({ type: FORWARD_SNAPSHOT, scope, itemType });

export const cleanSnapshots = (scope, itemType) => ({ type: CLEAN_SNAPSHOTS, scope, itemType });

const changeValues = (snapshotsArray, oldKey, oldValue, newKey, newValue) => {
    for (const snapshots of snapshotsArray) {
        for (const zone of snapshots) {
            if (zone[oldKey] === oldValue) {
                zone[newKey] = newValue;
            }
            // if (zone.parentId === oldValue) {
            //     zone.parentId = newValue;
            // }
        }
    }
};

export const changePropOnSnapshots = (scope, itemType, oldKey, oldValue, newKey, newValue) => {
    return (dispatch, getState) => {
        const backSnapshots = getState().siteManager[scope]?.backSnapshots?.[itemType] || [];
        const forwardSnapshots = getState().siteManager[scope]?.forwardSnapshots?.[itemType] || [];
        changeValues(backSnapshots, oldKey, oldValue, newKey, newValue);
        changeValues(forwardSnapshots, oldKey, oldValue, newKey, newValue);
    };
};
