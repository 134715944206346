import React from 'react';
import PropTypes from 'prop-types';
//openlayers
//import {Coordinate} from 'ol/coordinate';
//import {Extent} from 'ol/extent';
//import Projection from 'ol/proj/Projection';
//import MapLoader from 'ol/MapLoader';
import OLView from 'ol/View';

/**
 * Handles how the map is viewed / projected / etc.
 */
class View extends React.Component{

    /**
     * @private
     */
    static get propTypes(){
        return {
            map: PropTypes.any,
            center: PropTypes.any,
            zoom: PropTypes.any,
            resolution: PropTypes.any,
            maxResolution: PropTypes.any,
            minResolution: PropTypes.any,
            extent: PropTypes.any,
            projection: PropTypes.any,
            sphericalMercator: PropTypes.any
        };
    }


    /**
     * @private
     */
    constructor(props){
        super(props);

        /**
         * @type {Object}
         * @property {Coordinate} center - OpenLayers An XY coordinate representing the center of the map.
         * @property {int} zoom - Zoom level.
         * @property {double} resolution - Current resolution of the map.
         * @property {double} minResolution - Minimum resolution of the map.
         * @property {double} maxResolution - Maximum resolution of the map.
         * @property {Extent} extent - OpenLayers An array representing the box which is allowed to be the center point.
         * @property {Projection} projection - An OpenLayers Projection for the map.
         * @property {boolean} sphericalMercator - Whether or not the spherical mercator is used for the View.  Ignored if projection is set.
         * @property {Map} map - OpenLayers map. Passed by <Map />. **DO NOT SET.**
         */
        this.props = props;
    }

    /**
     * @private
     * Runs after mounting.
     */
    componentDidMount(){
        this.props.map.setView(
            new OLView({
                center: this.props.center,
                zoom: this.props.zoom,
                resolution: this.props.resolution,
                maxResolution: this.props.maxResolution,
                minResolution: this.props.minResolution,
                extent: this.props.extent,
                projection: this.props.projection || (this.props.sphericalMercator ? "EPSG:3857" : "EPSG:900913")
            })
        );
    }

    /**
     * @private
     * Renders the UI to the Virtual DOM (which updates the actual DOM)
     */
    render(){
        return (
            <div />
        );
    }
}


export default View;
