import React from 'react';
import PropTypes from 'prop-types';

export default class CordialErrorScreen extends React.Component{
    static get propTypes(){
        return {
            children: PropTypes.any
        };
    }
    render(){
        return (
            <div style={{background:'#ddd',color:'#777',height:'100%'}}>
                <div style={{position:'relative',top:'50%',transform:'translateY(-50%)',textAlign:'center',padding:'1em'}}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}
