const {
    MOTIONWORKS_BLUE,STANDARD_ORANGE
} = require('../../constants/Colors');

const GOOD_COLOR = MOTIONWORKS_BLUE;
const BAD_COLOR = STANDARD_ORANGE;

const innerData = {
    type: 'horizontalBar',
    data: {
        labelAttribute:"devicetype",
        datasets: [{
            label: 'Has Alert',
            stack:"0",
            dataAttribute:"alert",
            backgroundColor: BAD_COLOR,
            borderColor: BAD_COLOR,
            borderWidth: 1
        },{
            label: 'No Alerts',
            stack:"0",
            dataAttribute:"noalert",
            backgroundColor: GOOD_COLOR,
            borderColor: GOOD_COLOR,
            borderWidth: 1
        }]
    },
    options: {
        scales: {
            xAxes:[{
                stacked:true,
                ticks: {
                    beginAtZero:true,
                    stepSize:1
                }
            }],
            yAxes: [{
                stacked: true,

            }]
        },
        legend:{
            display:true,
            position:'bottom'
        }
    }
};

module.exports = {
    type:"GRAPH",
    data:innerData,
    title:'Devices',
    dataProvider:{
        service:'report',
        url:'/dashboards/widgets/7/data'
    },
    reportLink:"/reports/CF2768DA-D459-11D3-9019-00105A6AE011" // system alerts report
};
