import {
    TABLE_ONE_PLATFORM_ALL_REPLACE_COLUMNS,
    TABLE_ONE_PLATFORM_REPLACE_COLUMNS,
    TABLE_ONE_PLATFORM_REPLACE_CONFIG_COLUMNS,
    TABLE_ONE_PLATFORM_REPLACE_FOCUSED_ROWS,
    TABLE_ONE_PLATFORM_REPLACE_HEADERS_COLUMNS_SORT_FILTERS_FILTER,
    TABLE_ONE_PLATFORM_REPLACE_HOVERED_ROWS,
    TABLE_ONE_PLATFORM_REPLACE_SELECTED_ROWS,
    TABLE_ONE_PLATFORM_REPLACE_SORT,
    TABLE_ONE_PLATFORM_UPDATE_WIDTH_COLUMN,
    TABLE_UPDATE_DATA,
} from '../constants/ActionTypes';
import { patchReportProperties } from './reports';
import {getReportKeys} from "../hooks";
import {FILTER_TYPE, PERIOD} from "../views/util/report/filter/util";

export const tableOnePlatformReplaceColumns = (headerColumns, page) => {
    return {
        type: TABLE_ONE_PLATFORM_REPLACE_COLUMNS,
        headerColumns,
        page,
    };
};

export const onePlatformReplaceHeaderColumns = (headerColumns, reportKey, setConfigColumns) => {
    return dispatch => {
        dispatch(tableOnePlatformReplaceColumns(headerColumns, reportKey));
        if (setConfigColumns) {
            const headerColumnsArr = Object.values(headerColumns);
            const mapLabelColumn = headerColumnsArr.find(column => column.mapLabel) || {};
            const mapXColumn = headerColumnsArr.find(column => column.mapX) || {};
            const mapYColumn = headerColumnsArr.find(column => column.mapY) || {};
            const mapIdColumn = headerColumnsArr.find(column => column.mapId) || {};
            const mapIdColumnColumn = headerColumnsArr.find(column => column.mapIdColumn) || {};
            dispatch(patchReportProperties(reportKey, {
                uKeyFields: headerColumnsArr.filter(column => column.___uKey).map(column => column.path),
                mapLabelField: { key: mapLabelColumn.key, path: mapLabelColumn.path },
                mapXField: { key: mapXColumn.key, path: mapXColumn.path },
                mapYField: { key: mapYColumn.key, path: mapYColumn.path },
                mapIdField: { key: mapIdColumn.key, path: mapIdColumn.path },
                mapIdColumnField: { key: mapIdColumnColumn.key, label: mapIdColumnColumn.value, path: mapIdColumnColumn.path },
            }));
        }
    };
};

export const tableOnePlatformReplaceAllColumns = (tableOnePlatform) => {
    return {
        type: TABLE_ONE_PLATFORM_ALL_REPLACE_COLUMNS,
        tableOnePlatform,
    };
};

export const tableOnePlatformReplaceSort = (sort, page) => {
    return {
        type: TABLE_ONE_PLATFORM_REPLACE_SORT,
        sort,
        page,
    };
};

export const tableOnePlatformReplaceSelectedRows = (selectedRows, page) => {
    return {
        type: TABLE_ONE_PLATFORM_REPLACE_SELECTED_ROWS,
        selectedRows,
        page,
    };
};

export const tableOnePlatformReplaceFocusedRows = (focusedRows, page) => {
    return {
        type: TABLE_ONE_PLATFORM_REPLACE_FOCUSED_ROWS,
        focusedRows,
        page,
    };
};

export const tableOnePlatformReplaceHoveredRows = (hoveredRows, page) => {
    return {
        type: TABLE_ONE_PLATFORM_REPLACE_HOVERED_ROWS,
        hoveredRows,
        page,
    };
};

export const tableOnePlatformUpdateWidthColumn = (columnIndex, width, page) => {
    return {
        type: TABLE_ONE_PLATFORM_UPDATE_WIDTH_COLUMN,
        columnIndex,
        width,
        page,
    };
};

export const tableOnePlatformUpdateData = (reportKey, data, uKeyFields) => {
    return {
        type: TABLE_UPDATE_DATA,
        reportKey,
        data,
        uKeyFields,
    };
};

export const tableOnePlatformReplaceConfigColumns = (reportKey, columnsConfig) => {
    return {
        type: TABLE_ONE_PLATFORM_REPLACE_CONFIG_COLUMNS,
        reportKey,
        columnsConfig,
    };
};
export const tableOnePlatformReplaceConfigHeaderColumnsSortFilters = ({reportKey, headerColumns,sort,updateSort,updateFilters, filters,filter}) => {
    return {
        type: TABLE_ONE_PLATFORM_REPLACE_HEADERS_COLUMNS_SORT_FILTERS_FILTER,
        reportKey,
        headerColumns,
        sort,
        updateSort,
        updateFilters,
        filters,
        filter
    };
};
export const filtersOnePlatformReplaceValues = (reportKey,keyTimeFilter,isSavedReport=false,filters,restoreSort,restoreSortObject) => {
    return async (dispatch, getState) => {
        const headerColumns = getState().tableOnePlatform[reportKey]?.headerColumns;

        let filtersMain = getState().filtersOnePlatform;
        const filter = filtersMain[reportKey][keyTimeFilter];
        const {enabledColumns,sortConfiguration} = filter;
        const {nowSort,customSort} = sortConfiguration;
        const sort = getState().tableOnePlatform[reportKey]?.sort;
        const defaultSortKey = Object.keys(enabledColumns).find(key=>enabledColumns[key].defaultSort);
        const defaultSort = [{ key: headerColumns[defaultSortKey].key, keySort: headerColumns[defaultSortKey].keySort || headerColumns[defaultSortKey].key, direction: enabledColumns[defaultSortKey].direction }]
        //TimeFilter=CUSTOM
        const newSortConfiguration={
            nowSort: sort && sort.length>0?sort:nowSort,
            customSort:customSort.length>0?customSort:defaultSort
        }

        const newHeaderColumns = {};
        Object.keys(headerColumns).forEach(key=>{
            if(enabledColumns.hasOwnProperty(key)){
                newHeaderColumns[key] = {...headerColumns[key],sortable:true}
            }else{
                newHeaderColumns[key] = {...headerColumns[key],sortable:false}
            }
        })
        const newSort = restoreSort?restoreSortObject:newSortConfiguration.customSort;
        const newUpdateSort = restoreSort?restoreSort:true;
        dispatch(tableOnePlatformReplaceConfigHeaderColumnsSortFilters({reportKey, headerColumns:newHeaderColumns,sort:newSort,updateSort:newUpdateSort,updateFilters:true,filters,filter:{sortConfiguration:newSortConfiguration}}));
    }
};
export const filtersOnePlatformReloadValues = (reportKey,keyTimeFilter,isSavedReport=false,filters,restoreSort,restoreSortObject) => {
    return async (dispatch, getState) => {
        const headerColumns = getState().tableOnePlatform[reportKey]?.headerColumns;
        const columnsConfig = getState().tableOnePlatform[reportKey]?.columnsConfig;
        const sort = getState().tableOnePlatform[reportKey]?.sort;
        const { reportId } = getReportKeys(getState());

        let filtersMain = getState().filtersOnePlatform;
        const filter = filtersMain[reportKey][keyTimeFilter];

        const filterTime = Object.values(filtersMain[reportKey]).filter( filter  => filter.type === FILTER_TYPE.TIME);
        const timeFilter= filterTime && Array.isArray(filterTime) && filterTime.length > 0 ? filterTime[0]:{};
        const {rangeMode} = timeFilter;
        const { sortConfiguration} = filter;
        const {nowSort,customSort} = sortConfiguration;
        //TimeFilter: NOW
        const newSortConfiguration={
            nowSort:nowSort,
            customSort:sort && sort.length>0?sort:customSort
        }
        dispatch(restoreColumnsConfiguration(reportId,reportKey,headerColumns,newSortConfiguration,rangeMode,isSavedReport,columnsConfig,sort,{sortConfiguration:newSortConfiguration},filters,restoreSort,restoreSortObject));

    }
};
const restoreColumnsConfiguration = (reportId,reportKey,headerColumns,newSortConfiguration=null,rangeMode,isSavedReport,columnsConfig,sort,filter,filters,restoreSort,restoreSortObject) =>{
    return (dispatch) =>{
        const newHeaderColumns = {};
        Object.keys(columnsConfig).forEach(key=>{
            if(key && headerColumns.hasOwnProperty(key)){
                newHeaderColumns[key] = {...headerColumns[key]};
                if(columnsConfig[key].hasOwnProperty('sortable')){
                    newHeaderColumns[key].sortable = columnsConfig[key].sortable;
                }else{
                    newHeaderColumns[key].sortable = true;
                }
            }
        })

        Object.values(headerColumns).forEach(headerColumn=>{
            const { key } = headerColumn;
            if(key.includes('customProperties')) {
                newHeaderColumns[key] = {...headerColumn};
                if(newHeaderColumns[key].type === 'TEXT'){
                    newHeaderColumns[key].sortable = rangeMode===PERIOD.NOW?true:false;
                }
            }
        })

        const newSort =  restoreSort ? restoreSortObject: rangeMode === PERIOD.NOW ? newSortConfiguration.nowSort:newSortConfiguration.customSort;
        const newUpdateSort = restoreSort?restoreSort:true;
        dispatch(tableOnePlatformReplaceConfigHeaderColumnsSortFilters({reportKey, headerColumns:newHeaderColumns,sort:newSort,updateSort:newUpdateSort,updateFilters:true,filters,filter}));
    }
}