import { useEffect } from "react";
import { MousePosition } from "ol/control";
import { toStringXY } from "ol/coordinate";

export default function PointerCoordinates({ map }) {
  useEffect(() => {
    const mousePositionControl = new MousePosition({
      coordinateFormat: (coordinate) => {
        return "(" + toStringXY(coordinate, 2) + ")";
      },
      className: 'ol-mouse-position-xy',
      target: document.getElementById('ol-mouse-position'),
      undefinedHTML: '&nbsp;',
    });

    map.addControl(mousePositionControl);

    return () => {
      map && map.getControls().forEach(control => {
        if (control instanceof MousePosition) {
          map.removeControl(control);
        }
      });
    }

  }, [map]);


  return (
      <div id={'ol-mouse-position'} className={'ol-mouse-position'} />
  )
}