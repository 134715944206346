// Experimenting with file organization...
//
// this can be moved.

export const ZONE_BUILDER_PROPERTY_TYPES = {
    DROPDOWN: "DROPDOWN",
    TEXT: "TEXT",
    COLOR: "COLOR",
    FIXED: "FIXED",
    AUTOSUGGEST: "AUTOSUGGEST",
    DIALOG: "DIALOG"
};

const { DROPDOWN, FIXED, COLOR, TEXT, AUTOSUGGEST, DIALOG } = ZONE_BUILDER_PROPERTY_TYPES;

export const ZONE_BUILDER_VALIDATION_TYPES = {
    INTEGER: "INTEGER",
    ANGLE: "ANGLE"
};

const {
    INTEGER,
    ANGLE } = ZONE_BUILDER_VALIDATION_TYPES;


export const ZONE_NAME = "name";
export const SHAPE_NAME = "shape";
export const EXIT_ZONE_ID_NAME = "exitZoneId";
export const ZONE_GROUP_NAME = "groupName";
export const ZONE_ORIENTATION = "orientationAngle";
const ZONE_GROUP_INFORMATION = "You can create a new zone group by typing in this field";
export const ZONE_STANDARD_FIELDS = (zoneGroups) => [
    {
        name:"_id",
        type:FIXED
    },
    {
        name:"color",
        type:COLOR
    },
    {
        name:"mapId",
        type:FIXED
    },
    {
        name:ZONE_GROUP_NAME,
        type:AUTOSUGGEST,
        values:zoneGroups,
        required: true,
        information:{
            title:ZONE_GROUP_INFORMATION
        }
    },
    {
        name: ZONE_NAME,
        type: TEXT
    },
    {
        name:"description",
        type:TEXT
    },
    {
        name: "action",
        type: DROPDOWN,
        values: ["", "dissociate"]
    },
    {
        name:"type",
        type:DROPDOWN,
        values:["standard","inclusion","exclusion"]
    },
    {
        name: SHAPE_NAME,
        type: DIALOG
    },
    {
        name:"priority",
        type:TEXT,
        validation:INTEGER
    },
    {
        name: EXIT_ZONE_ID_NAME,
        type: DIALOG,
    },
    {
        name: ZONE_ORIENTATION,
        type: TEXT,
        validation:ANGLE
    },
    /*{
        name:"whereportInclusion",
        type:TEXT
    },
    {
        name:"resourceOrientation",
        type:TEXT
    }*/
];

export const MULTIPLE_ZONE_STANDARD_FIELDS = (zoneGroups) => [
    // {
    //     name:"_id",
    //     type:FIXED
    // },
    {
        name:"color",
        type:COLOR
    },
    // {
    //     name:"mapId",
    //     type:FIXED
    // },
    {
        name:ZONE_GROUP_NAME,
        type:AUTOSUGGEST,
        values:zoneGroups,
        required: true,
        information:{
            title:ZONE_GROUP_INFORMATION
        }
    },
    // {
    //     name: ZONE_NAME,
    //     type: TEXT
    // },
    {
        name:"description",
        type:TEXT
    },
    // {
    //     name: "action",
    //     type: DROPDOWN,
    //     values: ["", "dissociate"]
    // },
    {
        name:"type",
        type:DROPDOWN,
        values:["standard","inclusion","exclusion"]
    },
    // {
    //     name: SHAPE_NAME,
    //     type: DIALOG
    // },
    {
        name:"priority",
        type:TEXT,
        validation:INTEGER
    },
    {
        name: EXIT_ZONE_ID_NAME,
        type: DIALOG,
    },
    {
        name: ZONE_ORIENTATION,
        type: TEXT,
        validation:ANGLE
    },
    /*{
        name:"whereportInclusion",
        type:TEXT
    },
    {
        name:"resourceOrientation",
        type:TEXT
    }*/
];

export const INTERACTIONS = {
    GRID: "mapGrid",
    DRAW: "drawingZone",
    MODIFY: "modifyingZone",
    MOVE: "movingZones",
    BOX_SELECT: "boxSelect",
    DRAFT_MOVE: 'movingDraftZones',
    SELECT_OFF: 'selectOff', // To deactivate the selection of zones
}

export const MULTI_INTERACTIONS = {
    AUTOZONES: "autoZones",
    GREY_MODE: "greyMode",
    LABELS: "labels"
}

export const DEFAULT_GRID_STATE = {
    anchorEl: null,
    matrix: [3, 3],
    open: false,
    features: [],
    autoname: ""
}