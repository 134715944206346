import { displayAllErrorFromAxios, ReportService, reportServiceInstance } from './util';
import * as types from '../constants/ActionTypes';

import { CUSTOM_PAGES, FULL_MENU } from '../constants/Menu';

/*
██    ██ ████████ ██ ██      ███████
██    ██    ██    ██ ██      ██
██    ██    ██    ██ ██      ███████
██    ██    ██    ██ ██           ██
 ██████     ██    ██ ███████ ███████
*/

// useful when binded.
function sortByAttr(attr, a, b) {
    if (a[attr] > b[attr]) return 1;
    else return -1;
}

// =============================================================================

const DIRECTORIES = {
    'Saved': 'saved',
};

function getChildrenFromPermissionSet(permSet,username) {
    return permSet.map(function (entity) {
        // For now, all reports will need to be rendered by the reports page.
        if (entity.type === 'report') {
            if (entity.name === 'Import') {
                return { ...entity };
            } else {
                const {owner} = entity;
                const isShared = owner !== username;
                if (entity.path !== undefined) {
                    return {
                        ...entity,
                        name: entity.label,
                        routes: '/' + entity.path,
                        isShared
                    };
                } else {
                    return {
                        ...entity,
                        name: entity.label,
                        routes: '/' + (DIRECTORIES[entity.category] || 'reports') + '/' + entity.id,
                        isShared
                    };
                }
            }
        } else if (entity.type === 'dashboard') {
            return {
                ...entity,
                name: entity.label,
                routes: '/' + (DIRECTORIES[entity.category] || 'dashboard') + '/' + entity.id,
            };
        }
        
        // views usually suggest that they have custom pages developed.
        if (entity.type === 'view') {
            if (entity.category === 'Users') return null; // this is already accessible via the main tab.
            if (entity.category === 'Dashboard') return null; // workaround.
            
            return {
                ...entity,
                name: entity.label,
                ...CUSTOM_PAGES[entity.category][entity.label],
            };
        }
        
        // unknown type -- don't include.
        return null;
        
    })
        .filter(ch => !!ch) // don't include nulls
        .sort(sortByAttr.bind(null, 'name')); // sort it alphabetically
}

// =============================================================================

function populateAndFilter(permissions,username) {
    
    let userMenu = [], len = FULL_MENU.length, i;
    
    // for each potential tab...
    for (i = 0; i < len; i++) {
        
        let menuItem = FULL_MENU[i];
        // Try to get permission set for category.
        // if no set, don't include it.
        var permSet = menuItem.apiCategory && permissions[menuItem.apiCategory];
        //add import to report
        if (permSet && menuItem.name === 'Reports') {
            // const reportImport = {
            //     canEdit: true,
            //     category: 'Reports',
            //     label: 'Import',
            //     name: 'Import',
            //     routes: '/import',
            //     type: 'report'
            // };
            // permSet.push(reportImport);
        }
        //add devices and templates in infraestructure
        if (permSet && menuItem.name === 'Infrastructure') {
            const extraItems = [
                // {
                //     canEdit: true,
                //     category: 'Infrastructure',
                //     label: 'Devices',
                //     name: 'Devices',
                //     routes: '/device-manager/devices',
                //     type: 'view'
                // }
                /*{
                    canEdit: true,
                    category: 'Infrastructure',
                    label: 'Device Templates',
                    name: 'Templates',
                    routes: '/device-manager/templates',
                    type: 'view'
                }*/
            ];
            permSet = [...permSet, ...extraItems];
        }
        
        if (!permSet) continue;
        // at this point, the menu will include the tab in some format.
        userMenu.push({
            ...menuItem,
            children: getChildrenFromPermissionSet(permSet,username),
        });
    }
    return userMenu;
}

// =============================================================================

function indexByAttribute(arr, attr) {
    return arr.reduce((o, item) => {
        var i = item[attr];
        o[i] = o[i] || [];
        o[i].push(item);
        return o;
    }, {});
}

// =============================================================================

function parseMenu(permissions,username) {
    
    var permissionsByCategory = indexByAttribute(permissions, 'category');
    return {
        raw: permissions,
        menu: populateAndFilter(permissionsByCategory,username),
        permissions: constructLookup(permissions),
    };
    
}

function constructLookup(permissions) {
    let lookup = {};
    
    permissions.forEach(p => {
        let key;
        switch (p.type) {
            case 'view':
                key = p.label ? `edit-${p.category.toLowerCase()}-${p.label.toLowerCase()}` : `edit-${p.category.toLowerCase()}`;
                lookup[key] = p.canEdit;
                break;
            case 'report':
                lookup[`edit-report-${p.id}`] = p.canEdit;
                break;
            case 'dashboard':
                break; // do nothing. (we expect this type but don't utilize it)
            case 'search':
                break; // do nothing.
            default:
                console.log('unknown type', p); // eslint-disable-line
        }
    });
    
    return lookup;
}

/*
 █████   ██████ ████████ ██  ██████  ███    ██ ███████
██   ██ ██         ██    ██ ██    ██ ████   ██ ██
███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
██   ██  ██████    ██    ██  ██████  ██   ████ ███████
*/

export const loadMenuSavedReports = () => {
    return (dispatch, getState) => {
        return ReportService.instance().get(`/saved-reports`)
            .then(({ data }) => {
                const { menu, permissions } = getState().user;
                const newMenu = [...menu];
                
                const children = [];
                data.forEach(saved => {
                    if (saved.baseReportId && saved.name) {
                        children.push({
                            type: 'report',
                            category: 'Saved',
                            id: saved.id,
                            label: saved.name,
                            canEdit: true,
                            name: saved.name,
                            routes: '/saved/' + saved.id,
                            path: 'saved/' + saved.id,
                            baseReportId: saved.baseReportId,
                            runReportId: saved.runReportId,
                            enabled: true,
                        });
                    }
                });
                const saved = newMenu.find(menu => menu.routes === 'saved');
                if (children.length) {
                    if (saved) {
                        saved.children = children;
                    } else {
                        newMenu.push({
                            name: 'Saved',
                            routes: 'saved',
                            placement: 'left',
                            apiCategory: 'Saved',
                            children,
                        });
                    }
                }
                dispatch(loadMenu(newMenu, permissions));
            })
            .catch(errors => dispatch(displayAllErrorFromAxios(errors)));
    };
};

export const loadMenu = (menu, permissions) => ({ type: types.LOAD_MENU, menu, permissions });
export const RESOURCE_ALERT_SETTING = 'Resource Alert Setting';
export const requestMenu = (username, token) => {
    return (dispatch) => {
        const reportService = reportServiceInstance(username, token);
        return reportService.get('/menu')
            .then(response => {
                var perm = response.data.results;
                
                perm.push({ type: 'search', category: 'Search', id: '0', label: 'Item0', text: 'Item0Tet', canEdit: true });
                // temp
                if (perm.find(p => p.category === 'Dashboard')) {
                    perm.push({ type: 'dashboard', category: 'Dashboard', id: '0', label: 'System Health', text: 'System Health', canEdit: true });
                    perm.push({ type: 'dashboard', category: 'Dashboard', id: '1', label: 'Resources', text: 'Resources', canEdit: true });
                    perm.push({ type: 'dashboard', category: 'Dashboard', id: '2', label: 'ZHorzMenuGrafana', text: 'Grafana - System Health', canEdit: true });
                    perm.push({ type: 'dashboard', category: 'Dashboard', id: '3', label: 'ZHorzMenuPrometheus', text: 'Prometheus', canEdit: true });
                    perm.push({ type: 'dashboard', category: 'Dashboard', id: '4', label: 'ZHorzMenuAlertManager', text: 'Alert Manager', canEdit: true });
                    perm.push({ type: 'dashboard', category: 'Dashboard', id: '5', label: 'ZHorzMenuKibanaEvents', text: 'Kibana - Application History Data', canEdit: true });
                    perm.push({ type: 'dashboard', category: 'Dashboard', id: '6', label: 'ZHorzMenuKibanaLogs', text: 'Kibana - Application Logs', canEdit: true });
                }
                const infrastructure = perm.find(p => p.category === 'Infrastructure');
                const configuration = perm.find(p => p.category === 'Configuration');

                if (infrastructure) {
                     perm.push({ type: 'view',disabled:true, category: 'Infrastructure', label: 'Firmware',path: "device-manager/firmware", routes: '/device-manager/firmware', canEdit: true,canAdd:true });
                }
                if (configuration) {
                    perm.push({ type: 'report', category: 'Configuration', label: RESOURCE_ALERT_SETTING, canEdit: true });
                }
                
                const reports = perm.find(p => p.category === 'Reports');
                if (reports) {
                    perm.push({ type: 'report', category: 'Reports', label: 'Import', name: 'Import', routes: '/import', canEdit: true });
                    //perm.push({ type: 'report', category: 'Reports', label: 'Resource Grouping', name: 'Resource Grouping', path: 'reports/resource-grouping',routes: '/resource-grouping', canEdit: true });
                }

                // // TODO temp code for mark only active menus
                // const enabledPaths = ['reports/resource-types'
                //     , 'reports/resources'
                //     , 'reports/tags'
                //     , 'reports/resource-event/history'
                //     , 'reports/call/history'
                //     , 'reports/tag-blink/history'
                //     , 'reports/resource-zone-correlation/history'
                //     , 'alerts/alert-history'
                //     , 'alerts/notification-configurations'
                //     , 'reports/system-alert/history'
                //     , 'reports/contacts/config'
                //     , 'reports/zone-groups'
                //     , 'reports/zones'
                //     , 'reports/infrastructure/hardware-devices'
                //     , 'reports/infrastructure/whereports'
                //     , 'reports/zone-expiration'
                //     , 'reports/direction-configuration'
                //     //,"reports/resource-grouping"
                // ];
                // const enableLabels = ['Appliances', 'Devices', 'Site Manager', 'Resources', 'System Health', 'Open System Alerts', 'System Alert Settings', 'System Alert History', 'Import',
                //     'Zone Settings', 'Zone Group Settings', 'Alert Notification Configurations'];
                //
                // perm.forEach(menuItem => {
                //     if (enabledPaths.indexOf(menuItem.path) >= 0 || enableLabels.indexOf(menuItem.label) >= 0 || menuItem.category === 'Saved') {
                //         menuItem.enabled = true;
                //     }
                // });
                
                // console.log("perm",perm);
                var parsed = parseMenu(perm,username);
                dispatch(loadMenu(parsed.menu, parsed.permissions));
                
                // dispatch(loadMenuSavedReports());
            });
    };
};
