import { shallowEqual, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { createSelector } from 'reselect';

const selectWindowState = createSelector(
    (state) => state.resize,
    (sitesState) => {
        const { windowWidth: width, windowHeight: height } = sitesState || {};
        return { width, height };
    }
);

export const useWindowState = () => {
    return useSelector(state => selectWindowState(state), shallowEqual);
};

export const useWindowOnFocus = () => {
    const [focus, setFocus] = useState(true);

    useEffect(() => {
        const handleActivityFalse = () => {
            setFocus(false);
        };

        const handleActivityTrue = () => {
            setFocus(true);
        };

        window.addEventListener('focus', handleActivityTrue);
        window.addEventListener('blur', handleActivityFalse);
        return () => {
            window.removeEventListener('focus', handleActivityTrue);
            window.removeEventListener('blur', handleActivityFalse);
        };
    }, []);
    return focus;
};
/*
export const useWindowDispatch = () => {
    const dispatch = useDispatch();
    return {
    };
};
 */
