import React from 'react';
import PropTypes from 'prop-types';
import ZPage from './../../../../common/app/views/ZPage';
import Paper from "@material-ui/core/Paper";

import ReportsToolbar from './toolbars/ReportsToolbar';
import ColumnsDropDown from './toolbars/ColumnsDropDown';

import LinearProgress from '@material-ui/core/LinearProgress';
import TableFilters from './sidebar/TableFilters';
import {renderFilter} from './../util/report/filter/util';


import DynamicDataEntryDialog from './dataentry/DynamicDataEntryDialog';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import createTheme from "@material-ui/core/es/styles/createTheme";
import { withStyles } from '@material-ui/core/styles';
import ReportView from '../util/report/ReportView';

import * as FORMS from '../../constants/DataEntryForms';

import {getSidebarSizes} from '../../constants/Responsive';

import Promise from 'promise';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../../actions';
import DetailsSidebar from "./sidebar/DetailsSidebar";
import * as views from "../../constants/ViewTypes";
//delete actions.default;

//import Snackbar from "@material-ui/core/Snackbar/Snackbar";

const outerTheme = createTheme({
    palette: {
        primary: {
            main: '#00779F',
        },
    },
    shadows: Array(25).fill('none')
});

const ColorLinearProgress = withStyles({
    barColorPrimary: {
        backgroundColor: '#00779f'
    }
})(LinearProgress);

class FetchScreen extends React.Component{
    render(){
        return (
            <div style={{position:'absolute',top:0,right:0,left:0,bottom:0,background:'rgba(0,0,0,0.7)',textAlign:'center', zIndex: '100000'}}>
                <div style={{position:'absolute',top:'50%',transform:'translateY(-50%)',left:0,right:0}}>
                    <Paper style={{display:'inline-block',padding:'2em'}}>
                        Getting Data From the Server...
                        <ThemeProvider theme={outerTheme}>
                        <ColorLinearProgress style={{background:'rgba(0,0,0,0.25)',marginTop:'1em'}}/>
                        </ThemeProvider>
                    </Paper>
                </div>

            </div>
        );
    }
}


/**
 * Home Page for Nexus component
 */
class Home extends React.Component{
    /**
     *
     * @param props
     * @param context
     */
    constructor(props,context){
        super(props,context);

        const defaultWidths = this.__getDefaultWidths(props);

        this.state = {
            sidebarCollapsed: this.props.isMobile,
            firstToggled: false,
            leftSidebarResizing: false,
            rightSidebarResizing: false,
            leftSidebarWidth: defaultWidths.leftSidebarWidth,
            rightSidebarWidth: defaultWidths.rightSidebarWidth,
            animationEnabled: false,
        };

        this.handleToggleClick = this.handleToggleClick.bind(this);
        this.changeSelectedItem=this.changeSelectedItem.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.handleTransitionEnd = this.handleTransitionEnd.bind(this);
        this.handleDetailsSidebarClose = this.handleDetailsSidebarClose.bind(this);
        this.handleResetMap = this.handleResetMap.bind(this);

        this.autoRefreshID = 0;
    }

    __getDefaultWidths(props) {
        const widths = getSidebarSizes();
        return {
            leftSidebarWidth: widths.defaultWidth,
            rightSidebarWidth: widths.defaultWidth,
        };
    }

    componentDidMount(){
        this.props.actions.requestSites();
        this.props.actions.requestMaps();
        this.props.actions.requestRefreshRate();
        this.props.actions.getRfidPrintingData();
        this.setupAutoRefresh();
    }

    setupAutoRefresh(){
        const setupAutoRefreshID = ++this.autoRefreshID;
        this.cancelAutoRefresh = false;
        this.props.actions.getRefreshRate()
        .then((r)=>{
            if(this.autoRefreshID !== setupAutoRefreshID) {
                // eslint-disable-next-line
                throw "Old interval"; // a later auto refresh is setting up.
            }
            return r;
        })
        .then(
            this.setupAutoRefreshWithRate.bind(this)
        ).catch((e)=>{
            this.cancelAutoRefresh = true;
        });
    }

    setupAutoRefreshWithRate(rateSec){

        return Promise.resolve(rateSec)
        .then((rateSec)=>{
            rateSec = (rateSec > 2) ? rateSec : 2; // has to be at least 2 seconds.

            if(!this.cancelAutoRefresh)
                console.log(`Auto-refresh set to ${rateSec} seconds.`)

            const interval = ()=>{
                if(this.cancelAutoRefresh) return;
                this.autoRefreshTimeout = setTimeout(()=>{
                    Promise.resolve(this.props.actions.reloadReport({silent:true}))
                    .then(interval)
                    .catch(interval);
                },rateSec*1000);
            };

            interval();

            this.props.actions.addCleanUpCallback(()=>{
                this.clearAutoRefresh();
            });
        })
        .catch(()=>{
            console.log(`Auto-refresh is disabled.`); // eslint-disable-line
        });
    }

    clearAutoRefresh(){
        clearTimeout(this.autoRefreshTimeout);
        this.cancelAutoRefresh = true;
    }

    componentWillUnmount(){
        this.clearAutoRefresh();
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        let sidebarCollapsed = this.state.sidebarCollapsed;
        if (!this.state.firstToggled) {
            sidebarCollapsed = nextProps.isMobile;
        }
        const changeset = {};
        if (nextProps.showRightSidebar !== this.props.showRightSidebar) {
            changeset.animationEnabled = true;
        }

        this.setState({
            sidebarCollapsed,
            ...changeset
        });

        if(nextProps.refreshRate !== this.props.refreshRate){
            this.restartAutoRefresh();
        }

        // Removed for avoid request the same report twice.
        // if(this.hasSiteFilterChanged(nextProps)){
            //this.props.actions.reloadReport({silent:true});
        // }
    }

    // hasSiteFilterChanged(nextProps){
    //     const {mapFormat,columnMeta,filters} = nextProps;
    //     if(!mapFormat || !mapFormat.siteAttribute) return false;
    //
    //     const siteColumn = columnMeta[mapFormat.siteAttribute];
    //     const siteFilterId = siteColumn && siteColumn.filter;
    //
    //     if(!siteFilterId) return false;
    //
    //     const oldValue = (this.props.filters[siteFilterId] && this.props.filters[siteFilterId].value);
    //     const newValue = (filters[siteFilterId] && filters[siteFilterId].value);
    //
    //     return (oldValue !== newValue);
    // }

    restartAutoRefresh(){
        this.clearAutoRefresh();
        this.setupAutoRefresh();
    }

    getSidebarFilteredColumns(columns) {
        return columns.filter(col => col.filter != null);
    }

    handleToggleClick() {
        const defaultWidths = this.__getDefaultWidths(this.props);
        this.setState({
            sidebarCollapsed: !this.state.sidebarCollapsed,
            firstToggled: true,
            animationEnabled: true,
            leftSidebarWidth: defaultWidths.leftSidebarWidth
        });
    }

    handleDetailsSidebarClose() {
        const defaultWidths = this.__getDefaultWidths(this.props);
        this.setState({
            rightSidebarWidth: defaultWidths.rightSidebarWidth
        });
    }

    handleResize(e) {
        const sidebarWidth = `${e.identifier}Width`;
        const sidebarResizing = `${e.identifier}Resizing`;
        if (e.event === 'mousedown') {
            this.setState({
                animationEnabled: false,
                [sidebarResizing]: true,
                [sidebarWidth]: e.width || this.state[sidebarWidth],
            });
        } else if (this.state[sidebarResizing]) {
            this.setState({
                animationEnabled: false,
                [sidebarResizing]: e.resizing,
                [sidebarWidth]: e.width || this.state[sidebarWidth],
            });
        }
    }

    handleTransitionEnd() {
        this.setState({
            animationEnabled: false
        });
    }

    /**
     * Reset current map to world map.
     */
    handleResetMap(){
        this.props.actions.changeLocalMap(this.props.reportId,null);
    }

    changeSelectedItem(newItemSelected){
        this.props.actions.changeSelectedItem(newItemSelected);
    }

    getSiteId(sites, siteSelected){
        if(siteSelected === '%') return null;

        let site = sites.filter(s => s.name === siteSelected);
        return !!site && site[0] !== undefined ? site[0]._id : 'none';
    }
    handleSubmit(){
        this.props.notificationMessage({
            type: "alert",
            name: "nombre",
            message: "esto es un mensaje",
            numSuccess: 0,
            numFails:0
        });
    }


    /**
     *
     * @returns {ReactElement} markup
     */
    render (){
        const {
            actions,
            view,
            dataEntryOpen,
            data,
            filters,
            reportMeta,
            reportHoverIndex,
            reportHoverUIDs,
            reportId,
            dataEntryForms,
            dataEntryActiveForm,
            initialFormData,
            informationDataDeleted,
            fields,
            sites,
            maps,
            mapFormat,
            reportName,
            reportCategory,
            mapEnabled,
            columnOrder,
            columnMeta,
            sidebarColumnOrder,
            sidebarColumnMeta,
            sortColumn,
            sortDirection,
            isFetching,
            additionalReportActions,
            feature,
            clickedItem,
            selected,
            isMobile,
            isMobileDevice,
            isPortrait,
            showRightSidebar,
            detailsItem,
            selectedTag,
            currentLocalMap,
            realTime,
            connectedRealTime,
            siteSelected,
            loadingValues,
            printerDevices,
            rfidData,
            windowHeight,
            daysQty
        } = this.props;

        const siteId = this.getSiteId(sites, siteSelected);
        const columns = columnOrder.map(col=>({...col,...columnMeta[col.name]}));
        const sidebarColumns = sidebarColumnOrder.map(col => ({...col, ...sidebarColumnMeta[col.name]}));

        const filteredColumns = columns.filter((v)=>v.enabled);

        const sortIndex = filteredColumns.findIndex(c=>(c.name===sortColumn));

        const selectedRowsCount = selected != null ? Object.keys(selected).length : 0;

        const sidebarFilteredColumns = this.getSidebarFilteredColumns(sidebarColumns);
        let gridContainerClassName = 'grid-container';
        const sidebarSizeStyle = {
            left: '0px',
        };
        let totalWidthOffset = 0;
        let sidebarFilters = '';

        const sidebarFilterStatus = {
            leftSidebarEnabled: false,
            leftSidebarCollapsed: false,
            rightSidebarEnabled: false,
            rightSidebarCollapsed: false,
        };

        if (showRightSidebar) {
            totalWidthOffset += this.state.rightSidebarWidth;
            sidebarFilterStatus.rightSidebarCollapsed = true;
            sidebarFilterStatus.rightSidebarEnabled = true;
        }

        if (sidebarFilteredColumns.length !== 0) {
            sidebarFilterStatus.leftSidebarEnabled = true;
            sidebarFilterStatus.leftSidebarCollapsed = this.state.sidebarCollapsed;

            if (this.state.animationEnabled) {
                gridContainerClassName += ' animation';
            }

            if (!this.state.sidebarCollapsed) {
                sidebarSizeStyle.left = `${this.state.leftSidebarWidth}px`;
                totalWidthOffset += this.state.leftSidebarWidth;
            }
            sidebarFilters = <TableFilters
                                reportName={reportName || reportMeta.name}
                                reportId={reportId}
                                sidebarColumnOrder={sidebarColumnOrder}
                                columns={sidebarColumns}
                                filters={filters}
                                actions={actions}
                                loadingValues={loadingValues}
                                filterRenderer={renderFilter}
                                collapsed={this.state.sidebarCollapsed}
                                onToggleClicked={this.handleToggleClick}
                                handleResize={this.handleResize}
                                resizing={this.state.leftSidebarResizing}
                                width={this.state.leftSidebarWidth}
                                identifier="leftSidebar"
                                animationEnabled={this.state.animationEnabled}
                                onTransitionEnd={this.handleTransitionEnd}
                                resetMap={this.handleResetMap}
                                isMobile={isMobile}
                                isMobileDevice={isMobileDevice}
                                daysQty={daysQty}
                            />
        } else {
            gridContainerClassName += ' normal';
        }
        sidebarSizeStyle.width = `calc(100% - ${totalWidthOffset}px)`;


        // Get last local map selected.
        const localMapInfo=currentLocalMap[reportId];

        // Report or report&map.
        let currentView="";

        // If user select a view.
        if(view.hasOwnProperty(reportId))
            currentView=view[reportId];
        // Set default view.
        else if(mapEnabled===true)
        {
            if(isMobileDevice && isMobile)
                currentView=views.REPORT_VIEW;
            else{
                currentView=views.MAP_REPORT_VERTICAL_SPLIT;
                if(isPortrait)
                    currentView=views.MAP_REPORT_HORIZONTAL_SPLIT;
            }
        }
        else
            currentView=views.REPORT_VIEW;

        return (
            <ZPage zBreadCrumbs={["Home"]}

                zSubHeaderHeight={100}
                zShowSubHeader={false} >

                <div>

                    <ReportsToolbar

                        actions={actions}
                        forms={dataEntryForms}
                        {...this.props}
                        reportName={reportName || reportMeta.name}
                        reportCategory={reportCategory || reportMeta.reportCategory}
                        reportId={reportId}
                        mapEnabled={mapEnabled}
                        selectedRowsCount={selectedRowsCount}
                        numberOfResults={data.length}
                        columnManager={(
                            <ColumnsDropDown
                                columns={columns}
                                moveColumn={actions.moveColumn}
                                toggleColumn={actions.toggleColumn}
                                toggleAllColumns={actions.toggleAllColumns}
                            />
                        )}
                        additionalReportActions={additionalReportActions}
                        isMobile={isMobile}
                        showRightSidebar={showRightSidebar}
                        sideBarFiltersLength={sidebarFilteredColumns.length}
                        view={currentView}
                        printerDevices={printerDevices}
                        rfidData={rfidData}
                    />

                    <div className={"home-page"}>


                        <Paper style={{ boxShadow: "0", margin:0,minHeight:'5em', top: "48px"}}>
                            <div className="home-container">
                                <DetailsSidebar
                                    identifier="rightSidebar"
                                    resizing={this.state.rightSidebarResizing}
                                    handleResize={this.handleResize}
                                    width={this.state.rightSidebarWidth}
                                    animationEnabled={this.state.animationEnabled}
                                    onTransitionEnd={this.handleTransitionEnd}
                                    onClose={this.handleDetailsSidebarClose}
                                />
                                {sidebarFilters}
                                <div className={gridContainerClassName} style={sidebarSizeStyle}>
                                    <ReportView
                                        view={currentView}
                                        data={data}
                                        columns={filteredColumns}
                                        sidebarFilteredColumns={sidebarFilteredColumns}
                                        columnMeta={columnMeta}
                                        filters={filters}
                                        reportMeta={reportMeta}
                                        hoverIndex={reportHoverIndex}
                                        hoverUIDs={reportHoverUIDs}
                                        onTouchTapRow={actions.clickRow}
                                        onTouchTapCheckbox={actions.clickRow}
                                        onHoverRow={actions.hoverRow}
                                        actions={actions}
                                        sites={sites}
                                        clickedItem={clickedItem}
                                        maps={maps}
                                        mapFormat={mapFormat}
                                        mapEnabled={mapEnabled}
                                        sortDirection={sortDirection}
                                        sortIndex={sortIndex}
                                        columnOrder={columnOrder}
                                        requestZones={actions.requestZones}
                                        feature={feature}
                                        sidebarFilterStatus={sidebarFilterStatus}
                                        animationEnabled={this.state.animationEnabled}
                                        resizing={this.state.leftSidebarResizing || this.state.rightSidebarResizing}
                                        disableFillTable
                                        disableFilters={true}
                                        isMobile={isMobile}
                                        isMobileDevice={isMobileDevice}
                                        isPortrait={isPortrait}
                                        detailsItem={detailsItem}
                                        showRightSidebar={showRightSidebar}
                                        selected={selected}
                                        selectedTag={selectedTag}
                                        reportId={reportId}
                                        localMapInfo={localMapInfo}
                                        realTime={realTime}
                                        connectedRealTime={connectedRealTime}
                                        siteId={siteId}
                                        currentLocalMap={currentLocalMap}
                                    />
                                </div>
                            </div>
                            {isFetching ? <FetchScreen /> : null}
                        </Paper>
                    </div>

                </div>
                <DynamicDataEntryDialog open={dataEntryOpen && dataEntryActiveForm === FORMS.DYNAMIC_DATA_ENTRY} actions={actions} data={{...initialFormData,fields}} informationDataDeleted={informationDataDeleted} isMobile={isMobile} windowHeight={windowHeight} />

            </ZPage>

        );
    }

    static get propTypes(){
        return {
            actions: PropTypes.object,
            view: PropTypes.object,
            dataEntryOpen: PropTypes.bool,
            initialFormData: PropTypes.object,
            data: PropTypes.array.isRequired,
            columnOrder: PropTypes.array,
            sidebarColumnOrder: PropTypes.array,
            columnMeta: PropTypes.object,
            sidebarColumnMeta: PropTypes.object,
            filters: PropTypes.object,
            reportName: PropTypes.string,
            reportCategory: PropTypes.string,
            reportMeta: PropTypes.object,
            reportHoverIndex: PropTypes.number,
            reportHoverUIDs: PropTypes.arrayOf(PropTypes.string),
            dataEntryForms: PropTypes.array,
            dataEntryActiveForm: PropTypes.any,
            fields: PropTypes.any,
            sites: PropTypes.array,
            mapFormat: PropTypes.object,
            maps: PropTypes.array,
            mapEnabled: PropTypes.bool,
            sortColumn: PropTypes.string,
            sortDirection: PropTypes.number,
            additionalReportActions:PropTypes.array,
            isFetching: PropTypes.bool,
            refreshRate: PropTypes.number,
            clickedItem: PropTypes.object,
            detailsItem: PropTypes.string,
            selectedTag: PropTypes.bool,
            loadingValues: PropTypes.bool,
            siteSelected: PropTypes.string,
            isMobile: PropTypes.bool,
            printerDevices: PropTypes.array,
            rfidData: PropTypes.array,
        };
    }

    static get defaultProps(){
        return {
            dataEntryOpen: false,
            columnOrder: [],
            sidebarColumnOrder: [],
            additionalReportActions:[],
            isFetching: false
        };
    }

}

/*const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions,dispatch),

});*/

const mapDispatchToProps = dispatch => {
    return ({
        actions: bindActionCreators(actions,dispatch),

        ...bindActionCreators({
            notificationMessage: actions.displayNotification,
        }, dispatch)
    });
};

const mapStateToProps = state =>({
    view: state.view,
    dataEntryOpen: state.dataEntry.open,
    dataEntryActiveForm: state.dataEntry.activeForm,
    initialFormData: state.dataEntry.initialFormData,
    informationDataDeleted: state.dataEntry.informationDataDeleted,
    columnMeta: (state.report.columnMeta && state.report.columnMeta[state.report.reportId]) || {},
    sidebarColumnMeta: (state.report.sidebarColumnMeta && state.report.sidebarColumnMeta[state.report.reportId]) || {},
    data: state.report.data,
    clickedItem: state.report.clickedItem,
    isFetching: state.report.fetching,
    filters: state.filters,
    reportMeta: state.report.meta,
    reportHoverIndex: state.report.hoverIndex,
    reportHoverUIDs: state.report.hoverUIDs,
    reportId: state.report.reportId,
    dataEntryForms: state.user.permissions[`edit-report-${state.report.reportId}`] ? state.report.dataEntryForms : [],
    fields: state.dataEntry.dynamicFormFields, // ... ok, we may want to make the dynamic form "smart" at some point.
    sites: state.sites.sites,
    mapFormat: state.reportMap.mapFormat,
    maps: state.sites.maps,
    mapEnabled: state.reportMap.enabled,
    sortColumn: state.report.sortColumn,
    sortDirection:state.report.sortDirection,
    refreshRate: state.user.autoRefreshInterval,
    selected:state.report.selected,
    isMobile:state.resize.isMobile,
    isMobileDevice: state.resize.isMobileDevice,
    isPortrait:state.resize.isPortrait,
    showRightSidebar:state.report.showRightSidebar,
    detailsItem:state.report.detailsItem,
    selectedTag:state.report.selectedTag,
    currentLocalMap:state.reportMap.currentLocalMap,
    loadingValues: state.filters.loadingValues,
    realTime: state.report.realTime,
    connectedRealTime: state.report.connectedRealTime,
    siteSelected: state.report.siteSelected,
    printerDevices: state.report.printerDevices,
    userName: state.user.username,
    rfidData: state.resourceTypePrint.rfidData,
    windowHeight: state.resize.windowHeight,
    daysQty: state.filters.daysQty
});

export default connect(mapStateToProps,mapDispatchToProps)(Home);
