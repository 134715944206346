import React from 'react';
import PropTypes from 'prop-types';
import {ABSOLUTE_FIT} from '../utils';

import CordialErrorScreen from '../../util/map/misc/CordialErrorScreen';
import ColorInput from './ColorInput';

import PropertiesSchemaDialog,{opensPropSchemaDialog} from '../dialog/PropertiesSchemaDialog';

import {
    ZONE_BUILDER_PROPERTY_TYPES,
    ZONE_STANDARD_FIELDS,
    ZONE_GROUP_NAME,
    SHAPE_NAME,
    EXIT_ZONE_ID_NAME, ZONE_BUILDER_VALIDATION_TYPES
} from '../Constants';
import ZBAutosuggest from './ZBAutosuggest';
import ShapesDialog from '../dialog/ShapesDialog';
import ExitZoneDialog from '../dialog/ExitZoneDialog';
import ExpandLessIcon from '@material-ui/icons/ExpandLessRounded';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreRounded'
import { IconButton } from '@material-ui/core';
import { MultiplePropertiesGridTable } from './MultiplePropertiesGrid';
import Information from "./Information";


/*
██████  ██████   ██████  ██████  ███████ ██████  ████████ ██ ███████ ███████
██   ██ ██   ██ ██    ██ ██   ██ ██      ██   ██    ██    ██ ██      ██
██████  ██████  ██    ██ ██████  █████   ██████     ██    ██ █████   ███████
██      ██   ██ ██    ██ ██      ██      ██   ██    ██    ██ ██           ██
██      ██   ██  ██████  ██      ███████ ██   ██    ██    ██ ███████ ███████

████████  █████  ██████  ██      ███████
   ██    ██   ██ ██   ██ ██      ██
   ██    ███████ ██████  ██      █████
   ██    ██   ██ ██   ██ ██      ██
   ██    ██   ██ ██████  ███████ ███████
*/


export default class PropertiesManager extends React.Component{

    constructor(props) {
        super(props);
        this.handleOnExpand = this.handleOnExpand.bind(this);
    }

    static get propTypes(){
        return {
            mapId: PropTypes.number,
            //properties: PropTypes.object,
            properties: PropTypes.array,
            errorText: PropTypes.string,
            schema: PropTypes.object,
            onPropertyChange: PropTypes.func
        };
    }

    static get defaultProps(){
        return {
            onPropertyChange: ()=>{}
        };
    }

    state = {
        expanded: true
    }

    handleOnExpand() {
        this.setState({ expanded: !this.state.expanded });
    }

    render(){
        const { properties } = this.props;
        const commonProperties = {};
        for (const property of properties) {
            for (const key in property) {
                if (!commonProperties[key]) {
                    commonProperties[key] = property[key];
                }
                if (commonProperties[key] !== property[key]) {
                    commonProperties[key] = typeof property[key] === 'string' ? '' : typeof property[key] === 'number' ? 0 : commonProperties[key];
                }
            }
        }
        const grid = (properties.length === 1) ? (
            <PropertiesGridTable
                mapId={this.props.mapId}
                schema={this.props.schema} 
                properties={properties[0]}
                onPropertyChange={this.props.onPropertyChange}
            />
        ) : properties.length > 1 ? (
            <MultiplePropertiesGridTable
                mapId={this.props.mapId}
                schema={this.props.schema}
                properties={commonProperties}
                onPropertyChange={this.props.onPropertyChange}
            />
        ) : (
            <CordialErrorScreen>No zone selected.</CordialErrorScreen>
        );

        return (
            <div className="zones-properties" style={ this.state.expanded ? { flex: 1 } : {}}>
                <div className="zp-head">
                    <span>Zone Properties</span>
                    <IconButton 
                        size="small" 
                        style={{ color: "#fff" }}
                        onClick={this.handleOnExpand}
                    >
                        {this.state.expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                </div>
                <div 
                    style={{ 
                        ...ABSOLUTE_FIT, 
                        bottom: '0.25em', 
                        top: '2.5em', 
                        display: this.state.expanded ? "initial" : "none",
                        overflowY: 'auto', overflowX: 'hidden', border: 'solid 1px' 
                    }}
                >
                    {grid}
                </div>
            </div>
        );
    }
}


class SimplePropertiesGridTable extends React.Component{
    static get propTypes(){
        return {
            properties: PropTypes.object,
            schema: PropTypes.object,
            zones: PropTypes.object,
            onPropertyChange: PropTypes.func,
            openPropSchemaDialog: PropTypes.func
        };
    }

    constructor(){
        super();
        this.getCurrentZoneGroups = this.getCurrentZoneGroups.bind(this);
        this.handleSchemaTap = this.handleSchemaTap.bind(this);
        this.handleShapeTableSave = this.handleShapeTableSave.bind(this);
        this.handleBusinessPropertyChange = this.handleBusinessPropertyChange.bind(this);
    }

    state = {
        zoneShape: null,
        openExitZone: false
    }

    componentDidMount() {
        // const { siteId, getSiteZoneGroups } = this.props;
        // getSiteZoneGroups(siteId); // get zone groups for the properties dropdown
    }

    render(){
        const { properties, schema, zoneGroups, zones,
            // mapId,
            siteId } = this.props;
        const currentZoneGroups = this.getCurrentZoneGroups();
        const publishedZoneGroups = zoneGroups.reduce((acc, item) => item?.siteId === siteId?[...acc, item?.groupName]: acc, []);
        const allZoneGroups = [...currentZoneGroups, ...publishedZoneGroups].filter((value, index, self) => self.indexOf(value) === index);

        const standardPropertyRows = ZONE_STANDARD_FIELDS(allZoneGroups).map((f) => (
            <PropertyRow
                key={`${properties._key}-${f.name}`}
                {...f}
                value={properties[f.name]}
                business={false}
                zones={zones}
                siteId={siteId}
                onPropertyChange={this.props.onPropertyChange}
                onZoneShapeSelect={zoneShape => this.setState({ ...this.state, zoneShape })}
                openExitZoneDialog={zoneId => this.setState({ ...this.state, openExitZone: zoneId })}
            />
        ));
        const businessPropertyKeys = (schema && Object.entries(schema).sort()) || [];

        const businessPropertyRows = businessPropertyKeys.map(([key, { name, type, values = [] }]) => (
            <PropertyRow
                key={`${properties._key}-${key}`}
                name={name}
                type={type}
                values={[""].concat(values)} // add empty value as first value
                value={( properties.properties || {} )[key]}
                business={true}
                onPropertyChange={this.handleBusinessPropertyChange}
            />
        ));

        return (
            <>
                <ShapesDialog
                    zoneId={properties._id || properties._key}
                    open={!!this.state.zoneShape}
                    onSave={this.handleShapeTableSave}
                    onClose={() => this.setState({ ...this.state, zoneShape: null })}
                />

                <ExitZoneDialog 
                    open={this.state.openExitZone !== false}
                    zoneId={this.state.openExitZone}
                    mapId={this.props.mapId}
                    onPropertyChange={this.props.onPropertyChange}
                    onClose={() => this.setState({ ...this.state, openExitZone: false })}
                />

                <table style={{ width: '100%' }}>
                    <tbody>
                        {standardPropertyRows}
                        <tr>
                            <td colSpan={2} style={{ "background": "#aaa", padding: '0.25em 0.5em' }}>
                                <div>
                                    <span style={{ "fontSize": "0.8em", "fontStyle": "italic", "fontWeight": "bold", "letterSpacing": "1px" }}>
                                        {"Custom Properties"}
                                    </span>
                                    <div style={{ float: 'right' }}>
                                        <span style={{ textDecoration: 'underline', fontSize: '0.8em', cursor: 'pointer' }} onClick={this.handleSchemaTap}>
                                            edit
                                    </span>
                                        <PropertiesSchemaDialog />
                                    </div>
                                </div>
                            </td>
                        </tr>
                        {businessPropertyRows}
                    </tbody>
                </table>
            </>
        );
    }

    getCurrentZoneGroups(){
        const {zones, mapId} = this.props;
        let zoneGroups = [];
        Object.entries(zones).forEach(([key, zone]) => {
          if(zone.data?.mapId === mapId && zoneGroups.indexOf(zone.data?.groupName) === -1){
            zoneGroups.push(zone.data.groupName);
          }
        });
        return zoneGroups;
    }

    handleSchemaTap(){
        this.props.openPropSchemaDialog();
    }

    handleBusinessPropertyChange(key,value){
            this.props.onPropertyChange(
                "properties",
                {
                    ...this.props.properties.properties,
                    [key]: value
                }
            );
    }

    handleShapeTableSave(newCoords) {
        console.log(newCoords);
    }
}

const PropertiesGridTable = opensPropSchemaDialog(SimplePropertiesGridTable);

const getDialogFieldInput = (key, value, zones = {}) => {
    if (key === SHAPE_NAME) {
        return "coordinates";
    } else if (key === EXIT_ZONE_ID_NAME) {
        if (value) {
            return zones[value]?.data ? zones[value].data.name : value;
        }
        return value;
    }
    return value;
}

export class PropertyRow extends React.Component{

    static get propTypes(){
        return {
            onPropertyChange: PropTypes.func,
            onZoneShapeSelect: PropTypes.func,
            openExitZoneDialog: PropTypes.func,
            name: PropTypes.string,
            business: PropTypes.bool,
            zones: PropTypes.object
        };
    }

    constructor(props) {
        super(props);
        this.state = {value: props.value};
        this.handleOnDoubleClick = this.handleOnDoubleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    render(){
        return this.renderPropertyRow(this.props);
    }

    renderPropertyRow(props){

        const { name, value, business, required,information } = props;

        let input = this.renderPropertyInput(props);


        return this.renderPropertyRow2(name, value, input, required, { business },information);
    }

    renderPropertyRow2(propertyKey, value, input, required, { business },information) {
        const styleInformation = information?{paddingRight:'0.1em'}:{};
        const propertyKeyStyle={
            "background":business ? "#aaa" : "#ddd",
            ...PropertiesGridTable.styles.propertyKey,
            ...styleInformation
        };

        const fieldKeyStyle = {
            ...PropertiesGridTable.styles.cell,
            overflow: propertyKey === ZONE_GROUP_NAME ? "inherit" : "hidden"
        }

        return (
            <tr key={propertyKey} style={PropertiesGridTable.styles.row}>
                <td style={{...PropertiesGridTable.styles.cell,...{background:business ? "#aaa" : "#ddd"}}}>
                    <div style={propertyKeyStyle}>
                        {propertyKey}
                        {required && <span style={{ color: "#c1121f" }}>*</span>}
                        {information && <Information title={information.title}/>}
                    </div>
                </td>
                <td style={fieldKeyStyle}>
                    {input}
                </td>
            </tr>
        );
    }

    renderPropertyInput({ name, type, value, values, zones }) {
        const { onPropertyChange, siteId } = this.props;
        const { COLOR, TEXT, FIXED, DROPDOWN, AUTOSUGGEST, DIALOG } = ZONE_BUILDER_PROPERTY_TYPES;

        const dialogValue = getDialogFieldInput(name, value, zones);

        switch(type){
            case COLOR:
                return (
                    <ColorInput
                        value={value}
                        onChange={onPropertyChange.bind(null,name)}
                    />
                );

            case DROPDOWN:
                return (
                    <select style={{width:'100%'}} value={value || ""} onChange={this.handleFieldOnBlur.bind(this,name)}>
                        {values.map(v=>(
                            <option key={v} value={v}>
                                {v}
                            </option>
                        ))}
                    </select>
                );
            case AUTOSUGGEST:
                return (
                    <ZBAutosuggest 
                        value={value}
                        values={values}
                        name={name}
                        onChange={onPropertyChange}
                        siteId={siteId}
                        // handleAutosuggestChange={e => this.handleFieldOnBlur(name, e)} 
                    />
                )
            case FIXED:
                return (
                    <input
                        style={{width:'100%'}}
                        value={value}
                        disabled
                    />
                );            
            case DIALOG:
                return (
                    <div
                        style={{ width: "100%",height:"100%"}}
                        onDoubleClick={this.handleOnDoubleClick.bind(null, name, value)}>
                        <span>&nbsp;{dialogValue}</span>
                    </div>
                )

            case TEXT:
            default:
                return (
                    <input
                        style={{width:'100%'}}
                        value={this.state.value}
                        // defaultValue={value}
                        onChange={this.handleChange}
                        onBlur={this.handleFieldOnBlur.bind(this,name)}
                        onKeyDown={e => e.key === "Enter" && this.handleFieldOnBlur(name, e)}
                    />
                );
        }
    }


    cleanValue(validationType, value) {
        let newValue=value;
        if (validationType != null) {
            switch (validationType){
                case ZONE_BUILDER_VALIDATION_TYPES.ANGLE:
                    if(value!=='') {
                        const valueNumber = parseInt(value,10);
                        if (!isNaN(valueNumber) && valueNumber <= 360 && valueNumber >= 0) {
                            newValue = valueNumber;
                        }else{
                            newValue="";
                        }
                    }
                    break;
                case ZONE_BUILDER_VALIDATION_TYPES.INTEGER:
                        const valueInteger = parseInt(value,10);
                        if (!isNaN(valueInteger)) {
                            newValue = valueInteger;
                        }else{
                            newValue="";
                        }

                    break;
                default:
                    newValue=value;

            }
            if(value!==newValue) {
                this.setState({value: newValue});
            }
        }

        return newValue;
    }

    validateValue(validationType, value) {
        let validValue=true;
        if (validationType != null) {
            switch (validationType){
                case ZONE_BUILDER_VALIDATION_TYPES.ANGLE:
                    const valueAngle=Number(value);
                    if(!Number.isInteger(valueAngle)||valueAngle>360||valueAngle<0|| (value != null && value.indexOf('.') >= 0)){
                        validValue=false;
                    }
                    break;
                case ZONE_BUILDER_VALIDATION_TYPES.INTEGER:
                    if (value !== '-') {
                        const valueInteger = Number(value);
                        if (!Number.isInteger(valueInteger) || (value != null && value.indexOf('.') >= 0)) {
                            validValue = false;
                        }
                    }
                    break;
                default:
                    validValue=true;
            }
        }

        return validValue;
    }

    handleChange(event) {

        const value=event.target.value;
        const validValue=this.validateValue(this.props.validation,value);

        if(validValue) {
            this.setState({value: value});
        }
    }

    handleFieldOnBlur(key, e) {
        let value = e.currentTarget.value;
        value=this.cleanValue(this.props.validation,value);
        if (value !== this.props.value) {
            this.props.onPropertyChange(key, value);
        }
    }

    handleOnDoubleClick(key, value) {
        if (key === SHAPE_NAME) {
            this.props.onZoneShapeSelect(value.geometry?.coordinates);
        } else if (key === EXIT_ZONE_ID_NAME) {
            this.props.openExitZoneDialog(value);
        }
    }
}

PropertiesGridTable.styles = {
    row:{
        height:'1.2em'
    },
    cell:{
        border:'solid 1px',
        borderCollapse:'collapse',
        height:'1.3em',
        maxWidth: 0,
        overflow:'hidden',
        textOverflow:'ellipsis',
        whiteSpace:'nowrap',
        width:'50%',
        position:'relative'
    },
    propertyKey:{
        "padding":"0.1em 0.5em 0.1em 0.1em",
        "textAlign":"right",
        "fontSize":"0.9em",
        "fontWeight":"bold",
        "fontStyle":"italic",
        "color":"black",
        "letterSpacing":"1px"
    },
    zgContainer: {
        position: "absolute", 
        top: 0, 
        left: 0,
    },
    actionSpan: {
        textDecoration: "underline",
        fontSize: 13,
        fontWeight: 400,
        cursor: "pointer",
        marginRight: 8
    }
};
