import { ItemType } from '../../../../../constants';

export const FeatureType = {
    ZONE: 'ZONE',
    OBSTRUCTION: 'OBSTRUCTION',
    DEVICE_DATUM: 'DEVICE_DATUM',
    DEVICE_ATR7000_CLAS: 'DEVICE_ATR7000_CLAS',
    // TODO: Remove the next interactions:
    TOGGLE_GRID_VIEW: 'TOGGLE_GRID_VIEW',
    LOCK_FEATURES: 'LOCK_FEATURES',
    DISTANCE_METER: 'DISTANCE_METER',
    CLEAR: 'CLEAR',
    OBSTRUCTION_BOX_MODE: 'OBSTRUCTION_BOX_MODE',
    MODIFY_OBSTRUCTION: 'MODIFY_OBSTRUCTION',
    MOVE_OBSTRUCTION: 'MOVE_OBSTRUCTION',
    REMOVE_OBSTRUCTION: 'REMOVE_OBSTRUCTION',
    MODIFY_ZONE: 'MODIFY_ZONE',
    REMOVE_ZONE: 'REMOVE_ZONE',
    FILL_ZONE_COLOR: 'FILL_ZONE_COLOR',
    COPY_ZONE: 'COPY_ZONE',
    PASTE_ZONE: 'PASTE_ZONE',
    GREY_MODE: 'GREY_MODE',
    SHOW_HIDE_LABELS: 'SHOW_HIDE_LABELS',
    DRAW_ZONE_GRID: 'DRAW_ZONE_GRID',
};

export const DeviceType = {
    DEVICE: 'DEVICE',
    ANTENNA: 'ANTENNA',
    SNAP: 'SNAP',
    TRANSITION_READER: 'TRANSITION_READER',
    POST_READER: 'POST_READER',
    STAR_READER: 'STAR_READER',
    CAMERA: 'CAMERA',
    STANDALONE_WIFI_AP: 'STANDALONE_WIFI_AP',
};

export const MapPhase = "MAP_PHASE"

// Devices allowed deleting on press 'Delete' key on keyboard
export const DevicesAllowedToDelete = [DeviceType.ANTENNA, FeatureType.DEVICE_DATUM, FeatureType.DEVICE_ATR7000_CLAS]

// Devices allowed to be selected
export const DevicesAllowedToSelected = [DeviceType.ANTENNA, FeatureType.DEVICE_DATUM, FeatureType.DEVICE_ATR7000_CLAS];

// Devices allowed to have context menu
export const DEVICES_ALLOWED_CONTEXT_MENU = [ItemType.ANTENNA, ItemType.DEVICE_DATUM, ItemType.ZONE, ItemType.DEVICE_ATR7000_CLAS, ItemType.OBSTRUCTION];

export const DISABLED_DEVICES_FOR_LOCKED_ICON = [DeviceType.ANTENNA, DeviceType.CAMERA, DeviceType.POST_READER, DeviceType.SNAP, DeviceType.STANDALONE_WIFI_AP, DeviceType.STAR_READER, DeviceType.TRANSITION_READER, FeatureType.DEVICE_DATUM, FeatureType.DEVICE_ATR7000_CLAS];
export const DISABLED_DEVICES_FOR_UNLOCKED_ICON = [];

export const ADD_FORM = 'add_form';
export const EDIT_FORM = 'edit_form';

export const ADD_FORM_ZONE_BUILDER = 'add_form_zone_builder';
export const EDIT_FORM_ZONE_BUILDER = 'edit_form_zone_builder';

export const ADD_FORM_ZONE_GRID = 'add_form_zone_grid';

export const DEFAULT_GRID_MATRIX_VALUE = [3,3]

export const payloadObstruction = [
    {
        name: "obstruction1",
        id: 12,
        coords: [
            [1,1],[1,20],[20,20],[20,1],[1,1],
        ],
        type: "obstruction",
    },
    {
        name: "zone1",
        id: 12,
        coords: [
            [100,100],[100,200],[200,200],[200,100],[100,100],
        ],
        type: "zone",
    },
]

export const SiteDesignerElements = {
    DEVICE_TYPES: 'DEVICE_TYPES',
    COMPONENT_TYPES: 'COMPONENT_TYPES',
    MEASURE_SYSTEM: 'MEASURE_SYSTEM',
}
