import React from "react";
import IconButton from "@material-ui/core/IconButton";
import ActionInfo from '@material-ui/icons/Info';

const NotificationInfo=({variation})=>{
    return (
        <div className={"auto-adjustment-notification"}>
            <div className={""}>
                <div >
                    <IconButton className={""} disableTouchRipple>
                        <ActionInfo/>
                    </IconButton>
                    <div className={"notification-text"}>
                        <span> The Lower Left and Upper Right points was auto adjusted (variation: {variation.toFixed(2)})</span>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default NotificationInfo;