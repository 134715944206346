import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const AutoZoneIcon = (props) => (
    <SvgIcon {...props}>
        <rect x="0" y="0" width="24" height="24" stroke="gray"/>
        <text x="50%" y="50%" fontSize="12" dominantBaseline="middle" textAnchor="middle">AZ</text>
    </SvgIcon>
);

export default AutoZoneIcon;
