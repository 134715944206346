import moment from 'moment';
import {
    TIME_TYPES
} from '../constants/Search';

export const parseTimeSelectorToDate = (data) => {
    if (!data) {
        return null;
    }
    let parsed = new Date();
    if (data.type === TIME_TYPES.ABSOLUTE) {
        parsed = data.value;
    }
    if (data.type === TIME_TYPES.RELATIVE) {
        parsed = moment();
        if (data.unit.startsWith('-')) {
            parsed = moment().subtract(data.value, data.unit.substr(1)).toDate();
        } else {
            parsed = moment().add(data.value, data.unit.substr(1)).toDate();
        }
    }
    return parsed;
};

export const checkTimeSelectorOrder = (from, to) => {
    let result = true;
    if (from && to) {
        const fromParsed = parseTimeSelectorToDate(from);
        const toParsed = parseTimeSelectorToDate(to);
        result = fromParsed < toParsed;
    }
    return result;
};