export const DIRECTION_ENDPOINTS = {
    zoneExpiration: {
        add: {
            method: 'POST',
            url: '/zoneexp'
        },
        saveConfig: {
            method: 'GET',
            url: '/zoneexp/saveConfig'
        },
        getAll: {
            method: 'GET',
            url: '/zoneexp?{query}'
        },
        getOne: {
            method: 'GET',
            url: '/zoneexp?{query}'
        },
        update: {
            method: 'UPDATE',
            url: '/zoneexp/{id}'
        },
        patch: {
            method: 'PATCH',
            url: '/zoneexp/{id}'
        },
        delete: {
            method: 'DELETE',
            url: '/zoneexp/{id}'
        },

    },
    direction: {

    }
}

// VALIDATION FIELDS

export const VALIDATION_TYPE_REQUIRED = "VALIDATION_TYPE_REQUIRED";
export const VALIDATION_MESSAGE_REQUIRED = "Mandatory field";
