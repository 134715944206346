export const SITE_MANAGER_SCOPE = 'site-manager-scope';
export const SITE_MANAGER_CONFIGURATION_ZONES_SCOPE = 'config-zones';
export const SITE_MANAGER_CONFIGURATION_OBSTRUCTIONS_SCOPE = 'config-obstruction';
export const SITE_MANAGER_CONFIGURATION_DEVICES_SCOPE = 'config-devices';

export const InteractionType = {
    SAVE: 'SAVE',
    DRAW: 'DRAW',
    DRAW_BOX: 'DRAW_BOX',
    MODIFY: 'MODIFY',
    REMOVE: 'REMOVE',
    AREA_SELECTOR: 'AREA_SELECTOR',
    TRANSLATE: 'TRANSLATE',
    SNAP_TO_ADJACENT_ON_TRANSLATE: 'SNAP_TO_ADJACENT_ON_TRANSLATE',
    JOIN_NEIGHBOR: 'JOIN_NEIGHBOR',
    GREY_MODE: 'GREY_MODE',
    FILL_COLOR: 'FILL_COLOR',
    SHOW_HIDE_LABELS: 'SHOW_HIDE_LABELS',
    COPY: 'COPY',
    PASTE: 'PASTE',
    UNDO: 'UNDO',
    REDO: 'REDO',
    DRAW_ZONE_GRID: 'DRAW_ZONE_GRID',
    DISTANCE_METER: 'DISTANCE_METER',
    LOCK: 'LOCK',
    TOGGLE_VISIBILITY_CLUSTER_CLAS: 'TOGGLE_VISIBILITY_CLUSTER_CLAS',
    TOGGLE_VISIBILITY_AUTO_ZONES: 'TOGGLE_VISIBILITY_AUTO_ZONES',
    DRAW_RANDOM_ZONES: 'DRAW_RANDOM_ZONES',
};

export const ItemType = {
    ZONE: 'ZONE',
    AUTO_ZONE: 'AUTO_ZONE',
    OBSTRUCTION: 'OBSTRUCTION',
    DEVICE_DATUM: 'DEVICE_DATUM',
    DEVICE_ATR7000_CLAS: 'DEVICE_ATR7000_CLAS',
    CLUSTER_CLAS: 'CLUSTER_CLAS',
    ANTENNA: 'ANTENNA',
};

export const VisibleItemTypes = [ItemType.ZONE];
export const ITEM_TYPE_NAME = {
    [ItemType.DEVICE_ATR7000_CLAS]: 'ATR7000-CLAS',
};

export const MAX_BACK_SNAPSHOT_LENGTH = 10;

export const DialogType = {
    ERROR: 'ERROR',
    MESSAGE: 'MESSAGE',
    ADD_ZONE: 'ADD_ZONE',
    EDIT_ZONE: 'EDIT_ZONE',
    DELETE_ZONE: 'DELETE_ZONE',
    ADD_OBSTRUCTION: 'ADD_OBSTRUCTION',
    EDIT_OBSTRUCTION: 'EDIT_OBSTRUCTION',
    ADD_DEVICE: 'ADD_DEVICE',
    EDIT_DEVICE: 'EDIT_DEVICE',
    DELETE_DEVICE: 'DELETE_DEVICE',
};
