import { Translate } from 'ol/interaction';
import { getOlStoreKey } from '../helpers';

// Requires OlBase class
// Requires OlSelectionFactory class

export function olTranslateFactory(featureType) {
    return function (Base) {
        
        class OlTranslate extends Base {
            constructor(mapRef) {
                super(mapRef);
                
                const storeKey = getOlStoreKey(featureType);
                if (!this[storeKey]) {
                    this[storeKey] = {};
                }
                const store = this[storeKey];
                
                store.translateInteraction = null;
                
                store.isEnabledTranslate = () => !!store.translateInteraction;
                
                store.finalizeTranslate = () => {
                    if (store.translateInteraction) {
                        this.mapRef.removeInteraction(store.translateInteraction);
                    }
                    store.translateInteraction = null;
                };
                
                store.initializeTranslate = () => {
                    store.finalizeTranslate();
                    store.translateInteraction = new Translate({ features: this[storeKey].selectedItemsFeatureCollection });
                    
                    this.mapRef.addInteraction(store.translateInteraction);
                    
                    store.translateInteraction.on('translatestart', (event) => {
                        this.observer?.executeEvent('onStartTranslate', event);
                    });
                    
                    store.translateInteraction.on('translateend', (event) => {
                        this.observer?.executeEvent('onFinishTranslate', { event, action: { type: 'onFinishTranslate', featureType } });
                    });
                    
                    store.translateInteraction.on(
                        'translating',
                        (event) => this.observer?.executeEvent('onTranslating', event),
                    );
                };
            }
        }
        
        return OlTranslate;
    };
}
