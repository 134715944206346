export const SELECT_ZONE = "SELECT_ZONE";
export const SELECT_ZONES = "SELECT_ZONES";
export const SELECT_ZONE_GROUP = "SELECT_ZONE_GROUP";
export const CLICK_ZONE_PAGE_SECTION = "CLICK_ZONE_PAGE_SECTION";
export const ADD_ZONE = "ADD_ZONE";
export const ADD_MULTI_ZONES = "ADD_MULTI_ZONES";
export const UPDATE_ZONE = "UPDATE_ZONE";
export const DELETE_ZONE = "DELETE_ZONE";

export const LOAD_ZONES = "LOAD_ZONES";

export const CHANGE_ZONE_PROPERTY = "CHANGE_ZONE_PROPERTY";

export const COPY_ZONE = "COPY_ZONE";
export const PASTE_ZONE = "PASTE_ZONE";

export const SAVE_ZONES = "SAVE_ZONES";
export const CLEAR_DIRTY = "CLEAR_DIRTY";
export const PUBLISH_ZONES = "PUBLISH_ZONES";

export const UPDATE_ZONE_PROPERTY_SCHEMA = "UPDATE_ZONE_PROPERTY_SCHEMA";

export const LOAD_PUBLISHED_ZONES_FOR_MAP = "LOAD_PUBLISHED_ZONES_FOR_MAP";
export const LOAD_STAGING_ZONES_FOR_MAP = "LOAD_STAGING_ZONES_FOR_MAP";
export const LOAD_ZONES_FOR_MAP = "LOAD_ZONES_FOR_MAP";
export const CHANGE_FILTER = "CHANGE_FILTER";

export const UNDO = "UNDO";
export const REDO = "REDO";

export const UPDATE_ZONE_GROUPS = "UPDATE_ZONE_GROUPS";
export const TOGGLE_MAP_INTERACTION = "TOGGLE_MAP_INTERACTION";
export const TOGGLE_MULTI_INTERACTION = "TOGGLE_MULTI_INTERACTION";

export const CHANGE_ACTIVE_TAB = "CHANGE_ACTIVE_TAB";
export const PATCH_REPLICATE_ZONES_DATA = 'PATCH_REPLICATE_ZONES_DATA';
export const REPLACE_ZONES = 'REPLACE_ZONES';
export const UPDATE_ZONES = 'UPDATE_ZONES';

// New zone builder constants
export const LOAD_ZONES_ON_CONFIG = "LOAD_ZONES_ON_CONFIG";
export const PATCH_ZONES_ON_CONFIG = "PATCH_ZONES_ON_CONFIG";
export const OPEN_FORM_ZONE_BUILDER = "OPEN_FORM_ZONE_BUILDER";
export const ZONE_BUILDER_PATCH_PROPERTIES = "ZONE_BUILDER_PATCH_PROPERTIES";
export const LOAD_ZONE_TYPES = "LOAD_ZONE_TYPES";

export const OPEN_FORM_ZONE_GRID = "OPEN_FORM_ZONE_GRID";
export const UPDATE_SELECTED_GRID_IDS = "UPDATE_SELECTED_GRID_IDS";

export const UPDATE_CONFIRM_CHILDREN_ZONES = "UPDATE_CONFIRM_CHILDREN_ZONES";
