import React from 'react';
import IconButton from '@material-ui/core/IconButton';

const ClassedIconButton = (props) =>{
    return (
        <IconButton style={{backgroundColor: 'transparent'}} id={props.id} className="icon-button" {...props} />
    );
};

export default ClassedIconButton;
