import {
    ADD_RESOURCE_TYPES_ATTRIBUTES_FORM,
    ADD_RESOURCE_TYPES_ATTRIBUTES_LOOK_UP_VALUE_FORM,
    CLEAN_RESOURCE_TYPES_FORM, CLEAR_RESOURCE_TYPES_ATTRIBUTES,
    DELETE_RESOURCE_TYPES_ATTRIBUTES_FORM,
    LOAD_CUSTOM_RESOURCE_TYPES_DATA, LOAD_DEFAULT_RESOURCE_TYPE_FILTERS,
    LOAD_RESOURCE_TYPES_FILTERS,
    REMOVE_RESOURCE_TYPES_ATTRIBUTES_LOOK_UP_VALUE_FORM,
    RESOURCE_TYPE_FILTER_SELECTION,
    RESOURCE_TYPE_SELECTION,
    SET_DEFAULT_FILTER_VALUES,
    SET_SHOW_ATTRIBUTES,
    UPDATE_OPTION_LOOKUP,
    UPDATE_RESOURCE_TYPES_ALL_FIELDS,
    UPDATE_RESOURCE_TYPES_ATTRIBUTES_FORM,
    UPDATE_RESOURCE_TYPES_ATTRIBUTES_LOOK_UP_VALUE_FORM, UPDATE_RESOURCE_TYPES_ATTRIBUTES_LOOK_UP_VALUE_OPTION_FORM,
    UPDATE_RESOURCE_TYPES_DATA_FORM,
    UPDATE_RESOURCE_TYPES_FIELD_ENABLED,
    UPDATE_RESOURCE_TYPES_FILTERS_FORM,
    UPDATE_STATIC_RESOURCE_TYPE,
    UPDATE_VALIDATED_FIELDS_RESOURCE_TYPES
} from '../constants/ActionTypes';
import { displayAllErrorFromAxios, displayErrorFromAxios, ResourceService } from './util';
import {
    VALIDATION_MESSAGE_REQUIRED,
    VALIDATION_MESSAGE_REGULAR_CAHRACTERS_REQUIRED,
    VALIDATION_TYPE_REQUIRED,
    VALIDATION_TYPE_REGEXP,
    VALIDATION_LOOKUP_MESSAGE_REQUIRED,
    VALIDATION_MESSAGE_REGULAR_CHARACTERS_ATTRIBUTE_NUMBER_REQUIRED,
    VALIDATION_MESSAGE_REGULAR_CHARACTERS_ATTRIBUTE_REQUIRED,
    GROUP_PURGE_FIELD,
    PURGE_FIELD_VALUES,
    PURGE_IN_DAYS_FIELD,
    PURGE_FIELD
} from '../constants/ResourceTypes';
import { CLEAN_DIALOG_ICON_RESOURCE_TYPE, MODE_DIALOG_ICON_RESOURCE_TYPE, MODE_DIALOG_RESOURCE_TYPE, MODE_DIALOG_RESOURCE_TYPE_EDIT } from '../views/resourceTypes/constants/ResourceTypes';
import {EMPTY_OBJECT, ENDPOINTS, LOADING, RANGE_NUMBER, REPORT_ID_RESOURCE_TYPES, SUCCESS} from '../constants';
import {getReportKeys} from "../hooks";
import {getDataWithFilter, patchReportProperties} from './reports';
import { getLookup } from '../utils';
import { tableOnePlatformReplaceFocusedRows, tableOnePlatformReplaceSelectedRows } from './tableOnePlatform';
import {v4 as uuidv4} from "uuid";
import {
    IdBsonType,
    IdFieldNames
} from "../views/resourceTypes/toolbar/accordionItemsResourceType/ResourceTypeAttributes";

export const getListResourceTypesNames = () => {
    return async (dispatch, getState) => {
        const { reportKey } = getReportKeys(getState());
        const listURL = getState().resourceTypes.resourceTypesURL.list;
        const size = getState().tableOnePlatform[reportKey]?.maxRowSize;
        const page = getState().tableOnePlatform[reportKey]?.pageSelect;
        
        let resourceTypeUrl = listURL.url;
        resourceTypeUrl = resourceTypeUrl.replace('{page}', page);
        resourceTypeUrl = resourceTypeUrl.replace('{size}', size);
        resourceTypeUrl = resourceTypeUrl.replace('{sort}', 'displayName');
        resourceTypeUrl = resourceTypeUrl.replace('{filters}', '');
        resourceTypeUrl = resourceTypeUrl + '&category=user&fields=id,displayName,name,description,customProperties,category';

        await ResourceService.instance().request(
            {
                url: resourceTypeUrl,
                method: listURL.method
            }
        ).then(result => {
            dispatch({ type: LOAD_CUSTOM_RESOURCE_TYPES_DATA, listCustomResourceTypes: result.data.content});
        }).catch(displayErrorFromAxios.bind(null, dispatch));
    };
};
/*
export const getListResourceTypes = (background) => {
    return async (dispatch, getState) => {
        genericRequest(background,dispatch, getState, (reportFilters) => {
            const resourceTypeAPI = getState().resourceTypes.resourceTypesURL.list;
            const filters = processFiltersValues(reportFilters);
            let otherValue = '';
            if (filters && Object.keys(filters).length > 0) {
                Object.keys(filters).forEach((key) => {
                    otherValue += '&' + key + '=' + parseFilter(parseBoolean(filters[key]));
                });
            }
            return {
                url: resourceTypeAPI.url,
                filters: otherValue,
                method: resourceTypeAPI.method
            };
        }, ResourceService);
    }
};
*/
export const changeRTFilterValue = (index, value) => {
    return (dispatch, getState) => {
        let filters = { ...getState().resourceTypes.filters };
        const sortIndex = getState().resourceTypes.sortIndex;
        if (value === '%' || value === 'All') {
            // filters = null
            delete filters[index];
        } else {
            filters[index] = value.toUpperCase() === 'YES' ? true : (value.toUpperCase() === 'NO' ? false : value);
        }
        dispatch({ type: LOAD_RESOURCE_TYPES_FILTERS, filters: filters });
        if (sortIndex)
            // TODO: Check these requests
            dispatch(getDataWithFilter(ENDPOINTS[REPORT_ID_RESOURCE_TYPES].GET_ALL, filters))
            // dispatch(getListResourceTypes(filters, [{ name: sortIndex, direction: 1 }]));
    };
};

export const updateStaticValues = () => {
    return async (dispatch, getState) => {
        const { reportKey } = getReportKeys(getState());
        const modeDialogResourceType = getState().resourceTypes.modeDialogResourceType;
        const selectedRow = getState().tableOnePlatform[reportKey].selectedRows;
        let resourceTypes=[];
        try {
            resourceTypes = await ResourceService.instance().get('/v2/resourcetypes?fields=name,displayName&sort=displayName&category=user');

            resourceTypes=resourceTypes.data.content;
            if (resourceTypes != null) {
                resourceTypes = resourceTypes.map(item => {
                    return {
                        name: item.displayName ? item.displayName : item.name,
                        value: item.name,
                        category: item.category
                    };
                });
            } else {
                resourceTypes = [];
            }
        } catch (e) {
            console.log(e);
        }

        if(modeDialogResourceType === MODE_DIALOG_RESOURCE_TYPE_EDIT){
            resourceTypes = resourceTypes.filter((value) => value.value!==Object.keys(selectedRow)[0])
        }

        dispatch({ type: UPDATE_STATIC_RESOURCE_TYPE, fieldId: 'parentTypeId', data: resourceTypes });
        
        // const listURL = getState().resourceTypes.resourceProfilesURL.list;
        // const result = await ResourceService.instance().request(
        //     {
        //         url:listURL.url,
        //         method:listURL.method
        //     }
        // ).catch(displayErrorFromAxios.bind(null,dispatch));
        // dispatch({type:UPDATE_STATIC_RESOURCE_TYPE, fieldId:"resourceProfile",data:(result.data.map(item=>{return {name:item.name,value:item.id}}))});
        
        /*
        const listTagURL = getState().resourceTypes.tagsURL.list;
        const resultTag = await ResourceService.instance().request(
            {
                url:listTagURL.url,
                method:listTagURL.method
            }
        ).catch(displayErrorFromAxios.bind(null,dispatch));
        dispatch({type:UPDATE_STATIC_RESOURCE_TYPE, fieldId:"tagProfile",data:(resultTag.data.content.map(item=>{return {name:item.label,value:item.id}}))});
        */
        
        // const listFilterProfilesURL = getState().resourceTypes.filterprofilesURL.list;
        // const resultFilterProfiles = await ResourceService.instance().request(
        //     {
        //         url:listFilterProfilesURL.url,
        //         method:listFilterProfilesURL.method
        //     }
        // ).catch(displayErrorFromAxios.bind(null,dispatch));
        // dispatch({type:UPDATE_STATIC_RESOURCE_TYPE, fieldId:"tagProfile",data:(resultFilterProfiles.data.map(item=>{return {name:item.name,value:item.id}}))});
        
        const listIconsURL = getState().resourceTypes.iconsURL.list;
        const resultIcons = await ResourceService.instance().request(
            {
                url: listIconsURL.url,
                method: listIconsURL.method
            }
        ).catch(displayErrorFromAxios.bind(null, dispatch));
        dispatch({
            type: UPDATE_STATIC_RESOURCE_TYPE, fieldId: 'iconId', data: (resultIcons.data.content.map(item => {
                return { name: item.iconName, value: item.iconId };
            }))
        });
        
        const listIconsMapsURL = getState().resourceTypes.iconmapsURL.list;
        const resultIconsMap = await ResourceService.instance().request(
            {
                url: listIconsMapsURL.url,
                method: listIconsMapsURL.method
            }
        ).catch(displayErrorFromAxios.bind(null, dispatch));
        dispatch({
            type: UPDATE_STATIC_RESOURCE_TYPE, fieldId: 'iconMapId', data: (resultIconsMap.data.map(item => {
                return { name: item.description, value: item.id };
            }))
        });
        
        if (getState().resourceTypes.modeDialogResourceType !== MODE_DIALOG_RESOURCE_TYPE_EDIT) {
            const resultDefaultFilters=getState().resourceTypes.defaultFilters;
            let resourceTypeFilterDefaultValues = {};
            resultDefaultFilters?.forEach((item, index) => {
                getResourceTypeFilter(item, resourceTypeFilterDefaultValues);
            });
            dispatch({
                type: SET_DEFAULT_FILTER_VALUES,
                resourceTypesFormFiltersEdit: resourceTypeFilterDefaultValues
            });
            dispatch({ type: UPDATE_RESOURCE_TYPES_DATA_FORM, IdField: 'autoDissociation', newValue: false });
            dispatch({ type: UPDATE_RESOURCE_TYPES_DATA_FORM, IdField: 'autoDissociationByZone', newValue: false });
            dispatch({ type: UPDATE_RESOURCE_TYPES_DATA_FORM, IdField: 'deadTimeS', newValue: '' });
            dispatch({ type: UPDATE_RESOURCE_TYPES_DATA_FORM, IdField: 'rfidPrintingEnabled', newValue: false });
            dispatch({ type: UPDATE_RESOURCE_TYPES_DATA_FORM, IdField: 'groupUpdateMode', newValue: 'None' });
        }
        
        if(getState().resourceTypes.modeDialogResourceType === MODE_DIALOG_RESOURCE_TYPE_EDIT){
            // dispatch({ type: UPDATE_RESOURCE_TYPES_FIELD_ENABLED, IdSection: 'ParentChildren', IdField: 'parentTypeId', newValue: getState().resourceTypes.resourceTypesForm.parentTypeId? true:false });
            dispatch({ type: UPDATE_RESOURCE_TYPES_FIELD_ENABLED, IdSection: 'ParentChildren', IdField: 'groupUpdateMode', newValue: getState().resourceTypes.resourceTypesForm.parentTypeId? false:true });
            dispatch({ type: UPDATE_RESOURCE_TYPES_FIELD_ENABLED, IdSection: 'Identity', IdField: PURGE_IN_DAYS_FIELD, newValue: getState().resourceTypes.resourceTypesForm.purge === PURGE_FIELD_VALUES.NONE? true:false });
        }
        else {
            dispatch({ type: UPDATE_RESOURCE_TYPES_FIELD_ENABLED, IdSection: 'ParentChildren', IdField: 'groupUpdateMode', newValue: true });
            dispatch({ type: UPDATE_RESOURCE_TYPES_FIELD_ENABLED, IdSection: 'Identity', IdField: PURGE_IN_DAYS_FIELD, newValue: true });
        }

    };
};

export const loadIconList = () => {
    return async (dispatch, getState) => {
        const listIconsURL = getState().resourceTypes.iconsURL.list;
        const resultIcons = await ResourceService.instance().request(
            {
                url: listIconsURL.url,
                method: listIconsURL.method
            }
        ).catch(displayErrorFromAxios.bind(null, dispatch));
        dispatch({
            type: UPDATE_STATIC_RESOURCE_TYPE, fieldId: 'iconId', data: (resultIcons.data.content.map(item => {
                return { name: item.iconName, value: item.iconId };
            }))
        });
    };
};

export const loadIcon = (iconId) => {
    return async (dispatch, getState) => {
        
        let listIconURL = getState().resourceTypes.iconsURL.getIcon;
        const iconUrl = listIconURL.url.replace('{id}', iconId);
        const result = await ResourceService.instance().request(
            {
                url: iconUrl,
                method: listIconURL.method
            }
        ).catch(displayErrorFromAxios.bind(null, dispatch));
        // return result.data;
        dispatch({ type: UPDATE_RESOURCE_TYPES_DATA_FORM, IdField: 'iconContent', newValue: result.data.iconContent });
        dispatch({ type: UPDATE_RESOURCE_TYPES_DATA_FORM, IdField: 'iconColor', newValue: result.data.iconColor });
    };
};

export const clearFormResourceType = () => {
    return (dispatch) => {
        dispatch({ type: CLEAN_RESOURCE_TYPES_FORM });
        dispatch(initValidatedFieldsResourceTypes());
    };
};
// export const getResourceType = (IdResourceType) =>{
export const getResourceType = (Name) => {
    return async (dispatch, getState) => {
        const listURL = getState().resourceTypes.resourceTypesURL.getOne;
        let resourceTypeUrl = listURL.url;
        resourceTypeUrl = resourceTypeUrl.replace('{name}', Name);
        const result = await ResourceService.instance().request(
            {
                url: resourceTypeUrl,
                method: listURL.method
            }
        ).catch(displayErrorFromAxios.bind(null, dispatch));
        return result.data;
    };
};
export const showDialogResourceType = (status, modeDialog) => ({ type: MODE_DIALOG_RESOURCE_TYPE, statusModalResourceTypes: status, modeDialog: modeDialog, resourceTypeSelected: {} });
export const showDialogIconResourceType = (status, IdSection) => ({ type: MODE_DIALOG_ICON_RESOURCE_TYPE, statusModalIcon: status, selectSectionModal: IdSection });

export const getDefaultFilters = () => {
    return async (dispatch,getState) => {
        const url = getState().resourceTypes.resourceTypesURL.defaultFilters;
        const result = await ResourceService.instance().request(
            {
                url: url.url,
                method: url.method
            }
        ).catch(displayErrorFromAxios.bind(null, dispatch));
        dispatch({ type: LOAD_DEFAULT_RESOURCE_TYPE_FILTERS, filters: result.data});
    };
}

export const modeUpdateResourceType = (resourceTypeParam, modeDialogResourceType) => {
    return async (dispatch, getState) => {
        dispatch(getDefaultFilters());
        dispatch(showDialogResourceType(true, modeDialogResourceType));
        let resourceTypeEdit = {};
        let resourceTypeAttributeEdit = [];
        let resourceTypeFilterEdit = {};
        if (modeDialogResourceType === MODE_DIALOG_RESOURCE_TYPE_EDIT) {
            const resourceTypesFormField = getState().resourceTypes.resourceTypesFormField;
            resourceTypeEdit.id = resourceTypeParam.id;
            // const resourceType = await dispatch(getResourceType(resourceTypeParam.id));
            const resourceType = (await dispatch(getResourceType(resourceTypeParam.name))).content[0];

            Object.keys(resourceTypesFormField).forEach(section => {
                if (section === 'Filters') {
                    //console.log('getRSTFilters');
                } else {
                    resourceTypesFormField[section].fields.forEach((item, index) => {
                        // resourceTypeEdit[item.key] = resourceType[item.key]!==null?resourceType[item.key]:"";
                        if(item && item.key === GROUP_PURGE_FIELD){
                            const fields = item.fields||[];
                            fields.forEach(groupPurgeField =>{
                                resourceTypeEdit[groupPurgeField.key] = resourceType[groupPurgeField.key] !== null ? resourceType[groupPurgeField.key] : '';
                            })
                        }
                        else{
                            resourceTypeEdit[item.key] = resourceType[item.key] !== null ? resourceType[item.key] : '';
                        }
                    });
                }
            });
            
            if (resourceType['customProperties'] && resourceType['customProperties'] !== null) {
                resourceType.customProperties.forEach((item, index) => {
                    resourceTypeAttributeEdit.push(getResourceTypeAttribute(item));
                });
            }

            /*
              Note:  Load Default Filters then
             */
            const resultDefaultFilters=getState().resourceTypes.defaultFilters?getState().resourceTypes.defaultFilters:[];
                resultDefaultFilters.forEach((item, index) => {
                getResourceTypeFilter(item, resourceTypeFilterEdit);
            });
            /*
                if the resourceType selected has filters then the default will be replaced
             */
            if (resourceType['filters'] && resourceType['filters'] !== null) {
                resourceType.filters.forEach((item, index) => {
                    getResourceTypeFilter(item, resourceTypeFilterEdit);
                });
                resultDefaultFilters.map(itm=>itm.type).filter(value => resourceType.filters.map(itm2=>itm2.type).indexOf(value)===-1).map( rmv=> rmv.includes('moveMedian')? delete resourceTypeFilterEdit['moveMedianEnabled']:delete resourceTypeFilterEdit[rmv])
            }
            /* */
            if (resourceType['icon'] && resourceType['icon'] !== null) {
                // resourceTypeEdit.iconId = resourceType['icon'].iconId;
                // resourceTypeEdit.svgData=resourceType['icon'].iconContent;
                // resourceTypeEdit.iconColor=resourceType['icon'].iconColor;

                Object.keys(resourceType.icon).forEach((item, index) => {
                    resourceTypeEdit[item] = resourceType.icon[item] !== null ? resourceType.icon[item] : '';
                })
            }
            if (resourceType['parentType'] && resourceType['parentType'] !== null) {
                resourceTypeEdit['parentTypeId'] = resourceType['parentType'] !== null ? resourceType['parentType'].parentName  : '';
            }

        }
        resourceTypeEdit.IconConfiguration = false;

        dispatch({
            type: UPDATE_RESOURCE_TYPES_ALL_FIELDS,
            resourceTypeEdit: resourceTypeEdit,
            resourceTypesFormAttributes: resourceTypeAttributeEdit,
            modeDialogResourceType: modeDialogResourceType,/*successfulValidatedFieldsResourceTypes:false,*/
            resourceTypesFormFiltersEdit: resourceTypeFilterEdit
        });
    };
};
const getResourceTypeAttribute = (item) => {
    const lookUpValues = item.lookups ? item.lookups.map((item) => {
        return {
            value: item,
            validation: {
                error: false,
                type: VALIDATION_TYPE_REQUIRED,
                message: VALIDATION_MESSAGE_REQUIRED
            },
            isNewValue:false,
            isSelect:false,
            keyLookupOption:uuidv4()
        };
    }) : [];
    const keyFieldName = item?.keyFieldName;
    const keyFieldType = item?.keyFieldType||TYPE_DATA.STRING;
    const fieldNames = item.fieldNames? item.fieldNames.map((fieldName) => {
        const isSelect = fieldName === keyFieldName;
        return {
            value: fieldName,
            validation: {
                error: false,
                type: VALIDATION_TYPE_REQUIRED,
                message: VALIDATION_MESSAGE_REQUIRED
            },
            isNewValue:false,
            isSelect:isSelect,
            isIndex:isSelect,
            keyFieldType:isSelect?keyFieldType:TYPE_DATA.STRING,
            keyLookupOption:uuidv4()
        };
    }) : [];


    return {
        nameTypeAttribute: item.propertyName,
        displayNameAttribute: item.propertyDisplayName,
        descriptionAttribute: item.propertyDescription,
        [IdBsonType]: item[IdBsonType],
        // required: item.required,
        searchable: item.searchable,
        indexed: item.indexed,
        unique: item.unique,
        descending: item.descending,
        pattern: item.pattern,
        lookUpValue: lookUpValues,
        enableLookUp:lookUpValues.length>0,
        [IdFieldNames]:fieldNames,
        keyFieldName:keyFieldName,
        keyFieldType:keyFieldType,
        validation: {
            nameTypeAttribute: {
                error: false,
                type: VALIDATION_TYPE_REGEXP,
                regexp: /^\w+$/,
                message: VALIDATION_MESSAGE_REGULAR_CAHRACTERS_REQUIRED,
                validateForms:[]
            },
            dataType: {
                error: false,
                type: VALIDATION_TYPE_REQUIRED,
                message: VALIDATION_MESSAGE_REQUIRED,
                validateForms: []
            },
            lookUpValue: {
                error: false,
                type: VALIDATION_TYPE_REQUIRED,
                message: VALIDATION_MESSAGE_REQUIRED,
                validateForms: []
            }
        }
    };
};

const getResourceTypeFilter = (item, resourceTypeFilterEdit) => {
    if (item.type === 'rate') {
        Object.keys(item).forEach(val => {
            if (item[val] === 'rate') {
                resourceTypeFilterEdit[item[val]] = item.enabled;
            } else {
                if (val === 'radius') {
                    resourceTypeFilterEdit['radius2'] = item[val];
                } else {
                    resourceTypeFilterEdit[val] = item[val];
                }
            }
        });
    } else {
        Object.keys(item).forEach(val => {
            if (val === 'type') {
                if (item[val] === 'moveMedian') {
                    resourceTypeFilterEdit[item[val] + 'Enabled'] = item.enabled;
                } else {
                    resourceTypeFilterEdit[item[val]] = item.enabled;
                }
            }
            resourceTypeFilterEdit[val] = item[val];
        });
    }
    
    return {
        resourceTypeFilterEdit
    };
};

export const deleteResourceTypes = (type) => {
    return async (dispatch, getState) => {
        const deleteResourceTypeURL = getState().resourceTypes.resourceTypesURL.delete;
        let resourceTypeUrl = deleteResourceTypeURL.url;
        resourceTypeUrl = resourceTypeUrl.replace('{type}', type);
        await ResourceService.instance().request(
            {
                url: resourceTypeUrl,
                method: deleteResourceTypeURL.method
            }
        ).catch(displayErrorFromAxios.bind(null, dispatch));
        dispatch(getDataWithFilter(ENDPOINTS[REPORT_ID_RESOURCE_TYPES].GET_ALL, false))
    };
};

export const initDialogAddIcon = () => {
    return (dispatch, getState) => {
        const resourceTypesFormField = getState().resourceTypes.resourceTypesFormField;
        const selectSectionModal = getState().resourceTypes.selectSectionModal;
        const fieldIconConfiguration = resourceTypesFormField[selectSectionModal].fields;
        const listFieldClean = fieldIconConfiguration ? fieldIconConfiguration.map(item => {
            return item.key==='iconColor'? [item.key, '#000000']:[item.key, ''];
        }) : [];
        dispatch({ type: CLEAN_DIALOG_ICON_RESOURCE_TYPE, fields: Object.fromEntries(listFieldClean) });
    };
};

export const saveIcon = () => {
    return async (dispatch, getState) => {
        const addIconIconsURL = getState().resourceTypes.iconsURL.add;
        const resourceType = getState().resourceTypes.resourceTypesForm;
        let iconUrl = addIconIconsURL.url;
        var data = new FormData();
        data.append('description', resourceType.iconDescription);
        data.append('file', resourceType.file);
        data.append('name', resourceType.iconName);
        data.append('color', resourceType.iconColor);
        
        await ResourceService.instance().request(
            {
                url: iconUrl,
                method: addIconIconsURL.method,
                data: data
            }
        ).then((result) => {
            dispatch(updateResourceType("",'iconId', result.data));
            dispatch(loadIconList());
            dispatch(showDialogIconResourceType(false));
        }).catch(error => {
            dispatch(displayAllErrorFromAxios(error));
        });
    };
};

export const deleteResourceTypeAttribute = (IndexGroup) => {
    return (dispatch) => {
        dispatch({ type: DELETE_RESOURCE_TYPES_ATTRIBUTES_FORM, IndexDelete: IndexGroup });
    };
};
export const updateResourceType = (IdSection, IdField, newValue) => {
    return (dispatch,getState) => {
        dispatch({ type: UPDATE_RESOURCE_TYPES_DATA_FORM, IdField: IdField, newValue: newValue });
        if (IdField === 'name') {
            const requiredFields = getState().resourceTypes.needsValidationFieldsResourceType;
            if(requiredFields[IdSection][IdField] && requiredFields[IdSection][IdField].hasOwnProperty('error')){
                requiredFields[IdSection][IdField].error = false;
            }
            IdField = 'displayName';
            dispatch({ type: UPDATE_RESOURCE_TYPES_DATA_FORM, IdField: IdField, newValue: newValue });
        } else if (IdField === 'iconId') {
            dispatch(loadIcon(newValue));
        } else if (IdField === 'parentTypeId') {
            // dispatch(enableField(IdSection,IdField,true));
            dispatch({ type: UPDATE_RESOURCE_TYPES_FIELD_ENABLED, IdSection: IdSection, IdField: 'groupUpdateMode', newValue: false });
        } else if (IdField === PURGE_FIELD ){
            const disabled = newValue === PURGE_FIELD_VALUES.NONE;
            dispatch({ type: UPDATE_RESOURCE_TYPES_FIELD_ENABLED, IdSection: IdSection, IdField: PURGE_IN_DAYS_FIELD, newValue: disabled });
            if(disabled){
                dispatch({ type: UPDATE_RESOURCE_TYPES_DATA_FORM, IdField: PURGE_IN_DAYS_FIELD, newValue: '' });
            }
        }
    };
};
export const enableField = (IndexGroup, stateIconUpload) => {
    return (dispatch) => {
        // dispatch({type:UPDATE_RESOURCE_TYPES_DATA_FORM, IdField:IndexGroup,newValue:stateIconUpload});
    };
};

export const addResourceTypeAttribute = () => {
    return (dispatch) => {
        dispatch({ type: ADD_RESOURCE_TYPES_ATTRIBUTES_FORM });
    };
};

export const updateResourceTypeAttribute = (Index, IdField, newValue,validateLookUpOptions) => {
    return (dispatch,getState) => {
        dispatch({ type: UPDATE_RESOURCE_TYPES_ATTRIBUTES_FORM, Index: Index, IdField: IdField, newValue: newValue });
        const resourceTypesFormAttributes = getState().resourceTypes.resourceTypesFormAttributes;
        if(resourceTypesFormAttributes[Index]?.validation[IdField] && resourceTypesFormAttributes[Index]?.validation[IdField].hasOwnProperty('error')){
            resourceTypesFormAttributes[Index].validation[IdField].error = false;
        }
        if (IdField === 'nameTypeAttribute'){
            dispatch({ type: UPDATE_RESOURCE_TYPES_ATTRIBUTES_FORM, Index: Index, IdField: 'displayNameAttribute', newValue: newValue });
        }
        validateLookUpOptions?.();
    };
};

export const updateResourceTypeFilter = (IdField, newValue) => {
    return (dispatch) => {
        dispatch({ type: UPDATE_RESOURCE_TYPES_FILTERS_FORM, IdField: IdField, newValue: newValue });
    };
};

export const addResourceTypeAttributeLookUpValue = (IndexGroup, IdField,value) => {
    return (dispatch) => {
        dispatch({ type: ADD_RESOURCE_TYPES_ATTRIBUTES_LOOK_UP_VALUE_FORM, IndexGroup: IndexGroup, IdField: IdField,value:value });
    };
};

export const clearResourceTypeAttributeLookUpValue = (IndexGroup, IdField) => {
    return (dispatch) => {
        dispatch({ type: CLEAR_RESOURCE_TYPES_ATTRIBUTES, IndexGroup: IndexGroup, IdField: IdField});
    };
};
export const updateResourceTypeAttributeSelectOption = (IndexGroup, IdField,keyOptionSelect,updateIndexGroup,keyFieldType) => {
    return (dispatch) => {
        //console.log({IndexGroup,IdField,keyOptionSelect});
        dispatch({ type: UPDATE_RESOURCE_TYPES_ATTRIBUTES_LOOK_UP_VALUE_OPTION_FORM, IndexGroup: IndexGroup, IdField: IdField,keyOptionSelect:keyOptionSelect,updateIndexGroup:updateIndexGroup,keyFieldType:keyFieldType });
    };
};

export const deleteResourceTypeAttributeLookUpValue = (IndexGroup, keyLookupOption, IdField) => {
    return (dispatch) => {
        dispatch({ type: REMOVE_RESOURCE_TYPES_ATTRIBUTES_LOOK_UP_VALUE_FORM, IndexGroup: IndexGroup, keyLookupOption: keyLookupOption, IdField: IdField });
    };
};

export const updateResourceTypeAttributeLookUpValue = (IndexGroup, Index, IdField, newValue) => {
    return (dispatch,getState) => {
        const resourceTypesFormAttributes = getState().resourceTypes.resourceTypesFormAttributes;
        if(resourceTypesFormAttributes[IndexGroup][IdField][Index]?.validation && resourceTypesFormAttributes[IndexGroup][IdField][Index]?.validation.hasOwnProperty('error')){
            resourceTypesFormAttributes[IndexGroup][IdField][Index].validation.error = false;
        }
        dispatch({ type: UPDATE_RESOURCE_TYPES_ATTRIBUTES_LOOK_UP_VALUE_FORM, IndexGroup: IndexGroup, Index: Index, IdField: IdField, newValue: newValue });
    };
};
export const updateEnableLookUp = (IndexGroup, stateLookup) => {
    return (dispatch) => {
        dispatch({ type: UPDATE_OPTION_LOOKUP, IndexGroup: IndexGroup, stateLookup: stateLookup });
    };
};

export const updateEnableIconUpload = (IndexGroup, stateIconUpload) => {
    return (dispatch) => {
        dispatch({ type: UPDATE_RESOURCE_TYPES_DATA_FORM, IdField: IndexGroup, newValue: stateIconUpload });
    };
};

export const saveResourceType = () => {
    return async (dispatch, getState) => {
        const { reportKey } = getReportKeys(getState());
        const { uKeyFields } = getState().report.scopes?.[reportKey] || {};
        const resourceTypesForm = getState().resourceTypes.resourceTypesForm;

        const disableSubModalButton = (open) =>{
            dispatch(patchReportProperties(reportKey, {dialog:{subModal:{disableButton:open}}}))
        }
        dispatch(patchReportProperties(reportKey, { savingFormData: LOADING,background:true }));
        //dispatch(updateProcessing(true, reportKey, true));
        disableSubModalButton(true);
        const resourceTypesFormFilters = getState().resourceTypes.resourceTypesFormFilters;
        const resourceTypesFormAttributes = getState().resourceTypes.resourceTypesFormAttributes;
        const modeDialogResourceType = getState().resourceTypes.modeDialogResourceType;
        let modeSaveURL;
        let savePayloadResourceType = getPayloadResourceType(resourceTypesForm, resourceTypesFormAttributes,modeDialogResourceType);
        savePayloadResourceType = getPayloadResourceTypeFilter(savePayloadResourceType, resourceTypesFormFilters, modeDialogResourceType !== MODE_DIALOG_RESOURCE_TYPE_EDIT);
        // savePayloadResourceType = saveIcon(resourceTypesForm,savePayloadResourceType,getState().resourceTypes.iconsURL.add);
        // if(!resourceTypesForm.iconId && typeof !resourceTypesForm.file==='undefined'){
        let existsError = false;
        // if(typeof resourceTypesForm.file !=='undefined' && resourceTypesForm.IconConfiguration){
        // ///SAVE ICON//////////////////////////////////////
        //      const result  = await dispatch(saveIcon(resourceTypesForm,savePayloadResourceType));
        //      if(result){
        //          existsError = true;
        //      }
        // }
        //////////////////////////////////////////////////
        if (modeDialogResourceType !== MODE_DIALOG_RESOURCE_TYPE_EDIT)  {
            modeSaveURL = getState().resourceTypes.resourceTypesURL.add;
            await ResourceService.instance().request(
                {
                    url: modeSaveURL.url,
                    method: modeSaveURL.method,
                    data: savePayloadResourceType
                }
            ).then(
                () => {
                    dispatch(initValidatedFieldsResourceTypes());
                    const { selectedRows = EMPTY_OBJECT } = getState().tableOnePlatform[reportKey];
                    const ___uKey = getLookup(savePayloadResourceType, uKeyFields);
                    dispatch(tableOnePlatformReplaceSelectedRows({ ...selectedRows, [___uKey]: { ___uKey } }, reportKey));
                    dispatch(tableOnePlatformReplaceFocusedRows({ [___uKey]: { ___uKey } }, reportKey));
                    dispatch(getDataWithFilter(ENDPOINTS[REPORT_ID_RESOURCE_TYPES].GET_ALL, false));
                }
                //).catch(displayErrorFromAxios.bind(null,dispatch));
            ).catch(error => {
                existsError = true;
                dispatch(displayAllErrorFromAxios(error));
                disableSubModalButton(false);
            });
        }
        
        if (modeDialogResourceType === MODE_DIALOG_RESOURCE_TYPE_EDIT) {
            savePayloadResourceType.id = resourceTypesForm.id;
            const patch = getState().resourceTypes.resourceTypesURL.patch;
            const resourceTypeUrlPatch = patch.url.replace('{type}', savePayloadResourceType.name);

            const resourceTypesFormPrev=getState().resourceTypes.resourceTypesFormPrev;
            const resourceTypesFormAttributesPrev=getState().resourceTypes.resourceTypesFormAttributesPrev;
            const resourceTypesFormFiltersPrev=getState().resourceTypes.resourceTypesFormFiltersPrev;

            /**Setting value enableLookUp into previous resourceTypeFormAttributesPrev to get the lookups set up
             */
            resourceTypesFormAttributesPrev.map(function (value) {
                    if (value.lookUpValue && value.lookUpValue.length > 0) {
                        value["enableLookUp"] = true;
                    }
                    return value;
                }
            )
            let previusPayloadResourceType = getPayloadResourceType(resourceTypesFormPrev, resourceTypesFormAttributesPrev,modeDialogResourceType);
            previusPayloadResourceType = getPayloadResourceTypeFilter(previusPayloadResourceType, resourceTypesFormFiltersPrev, modeDialogResourceType !== MODE_DIALOG_RESOURCE_TYPE_EDIT);

            /** Removing from payload objects which values didn't changed in a separated object copied from original
             */
            const savePayLoad=JSON.parse(JSON.stringify(savePayloadResourceType))
            for (const key in savePayLoad) {
                if (JSON.stringify(savePayLoad[key]) === JSON.stringify(previusPayloadResourceType[key])) {
                    delete savePayLoad[key];
                }
            }

            await ResourceService.instance().request(
                {
                    url: resourceTypeUrlPatch,
                    method: patch.method,
                    data: savePayLoad
                }
            ).then(
                () => {
                    dispatch(initValidatedFieldsResourceTypes());
                    dispatch(getDataWithFilter(ENDPOINTS[REPORT_ID_RESOURCE_TYPES].GET_ALL, false));
                    disableSubModalButton(false);
                }
                //).catch(displayErrorFromAxios.bind(null,dispatch));
            ).catch(error => {
                existsError = true;
                dispatch(displayAllErrorFromAxios(error));
                disableSubModalButton(false);
            });
            
        }
        //dispatch(updateProcessing(false, reportKey));
        dispatch(patchReportProperties(reportKey, { savingFormData: SUCCESS,background:true }));
        if (existsError === false) {
            disableSubModalButton(false);
            dispatch(showDialogResourceType(false, ''));
        }
    };
};

export const TYPE_DATA = {
    INTEGER:'Integer',
    DECIMAL:'Decimal',
    BOOLEAN:'Boolean',
    STRING:'String',
    DATE:'Date',
    LIST:'Array',
    DEFAULT:'Default',
};
export const DATA_TYPE_OPTIONS = {
    LOOKUP:'Lookup',
    FLEXIBLE:'Flexible'

}
export const isValidDataType = (value,type) => {
    const valueNumber = typeof value!=='boolean'&&value!==''&&value!==null? +value:Number.NaN;
    const valueBoolean = (value === 'true'||value === 'false'||value===true||value===false)? (value==='true'||value===true):null;
    //console.log({value,type,valueNumber,valueBoolean,valueString})
    switch (type) {
        case TYPE_DATA.INTEGER:
            return {result:!Number.isNaN(valueNumber) && Number.isInteger(valueNumber),message:`Value is not ${type}`,value:valueNumber};
        case TYPE_DATA.DECIMAL:
            return {result:!Number.isNaN(valueNumber),message:`Value is not ${type}`,value:valueNumber};
        case TYPE_DATA.BOOLEAN:
            return {result:typeof valueBoolean === 'boolean',message:`Value is not ${type}`,value:valueBoolean};
        case TYPE_DATA.STRING:
        case TYPE_DATA.LIST:
            const valueString = ''+value;
            return {result:typeof valueString === 'string',message:`Value is not ${type}`,value:valueString};
        case TYPE_DATA.DATE:
            const dateResult = new Date(value);
            return {result:dateResult instanceof Date && !isNaN(dateResult.valueOf()),message:`Value is not ${type}`,value:dateResult};
        case TYPE_DATA.DEFAULT:
            const isValidate = value !==null && value !=='';
            return {result: isValidate,message: '',value:isValidate?value:null};
        default:
            return {result:false,message:`Value is not ${type}`,value:null};
    }
}
export const getJsonArray = (value) =>{
    let jsonValue = undefined;
    let isValidateJsonArray = false;
    if(typeof value === 'string')
    {
        try {
            jsonValue = JSON.parse(value);
            if (jsonValue && typeof jsonValue === "object") {
                isValidateJsonArray = true;
            }
            else{
                isValidateJsonArray = false;
            }

        } catch (e) {
            console.log({e})
            isValidateJsonArray = false;
        }
    }
    return {result:isValidateJsonArray,value:jsonValue};
}
const getPayloadResourceType = (resourceType, resourceTypesFormAttributes,modeDialogResourceType) => {
    resourceType.customProperties = [];
    const resourceTypeAttributeFormat = resourceTypesFormAttributes && resourceTypesFormAttributes.map((item, index) => {
        const {enableLookUp,dataType} = item;
        if( dataType !== TYPE_DATA.LIST ) {
            return enableLookUp ?
                {
                    propertyName: item.nameTypeAttribute,
                    propertyDisplayName: item.displayNameAttribute,
                    propertyDescription: item.descriptionAttribute,
                    [IdBsonType]: item[IdBsonType],
                    /*lookups: item.lookUpValue.map(item => {
                        return item.value;
                    }),*/
                    lookups: item.lookUpValue.map(option => {
                        //const {value} = isValidDataType(option.value,item.bsonType);
                        //return value;
                        return option.value
                    }),
                    // required: item.required,
                    searchable: item.searchable
                } : {
                    propertyName: item.nameTypeAttribute,
                    propertyDisplayName: item.displayNameAttribute,
                    propertyDescription: item.descriptionAttribute,
                    [IdBsonType]: item[IdBsonType],
                    // required: item.required,
                    searchable: item.searchable,
                };
        }else{

            const fieldNames = item[IdFieldNames]||[];
            const fieldName = fieldNames.find(optionName=>optionName.isIndex);
            return {
                propertyName: item.nameTypeAttribute,
                propertyDisplayName: item.displayNameAttribute,
                propertyDescription: item.descriptionAttribute,
                [IdBsonType]: item[IdBsonType],
                fieldNames:fieldNames.map(option => {
                    const {value} = option;
                    return value;
                }),
                searchable: fieldName?true:false,
                keyFieldName:fieldName?.value,
                keyFieldType: fieldName?.value? fieldName.keyFieldType:undefined
            };
        }
    });
    
    if (resourceTypeAttributeFormat.length > 0) {
        resourceType.customProperties = resourceTypeAttributeFormat;
    }
    //delete resourceType.reserved;
    /*
    resourceType.displayNameMap = {
        additionalProp1:resourceType.displayNameMap,
    }*/
    if(resourceType.hasOwnProperty('iconContent'))
        delete resourceType.iconContent;

    if(resourceType.hasOwnProperty('IconConfiguration'))
        delete resourceType.IconConfiguration;

    if(resourceType.hasOwnProperty('iconColor'))
        delete resourceType.iconColor;


    if(resourceType.hasOwnProperty('file'))
        delete resourceType.file;

    if(resourceType.hasOwnProperty('iconDescription'))
        delete resourceType.iconDescription;

    if(resourceType.hasOwnProperty('iconEnable'))
        delete resourceType.iconEnable;

    if(resourceType.hasOwnProperty('iconName'))
        delete resourceType.iconName;

    if(resourceType.hasOwnProperty('deadTimeS')){
        if(resourceType.deadTimeS===null || resourceType.deadTimeS===undefined||resourceType.deadTimeS===''){
            if(resourceType.deadTimeS === '' && modeDialogResourceType === MODE_DIALOG_RESOURCE_TYPE_EDIT){
                resourceType.deadTimeS = 0;//null;
            }else{
                delete resourceType.deadTimeS;
            }
        }
    }

    if(resourceType.hasOwnProperty('updateMode')){
        const valueUpdateMode = resourceType.updateMode;
        delete resourceType.updateMode;
        resourceType.groupUpdateMode  = valueUpdateMode;
    }

    if(resourceType.hasOwnProperty(PURGE_FIELD)){
        if(resourceType[PURGE_FIELD] === PURGE_FIELD_VALUES.NONE && resourceType.hasOwnProperty(PURGE_IN_DAYS_FIELD)){
            delete resourceType[PURGE_IN_DAYS_FIELD];
        }
    }

    return resourceType;
};

const getPayloadResourceTypeFilter = (resourceType, resourceTypesFormFilters, addMode) => {
    let filters = [];
    
    if ((addMode && resourceTypesFormFilters.tagOffset) || (!addMode && typeof resourceTypesFormFilters.tagOffset !== 'undefined')) {
        filters.push(
            {
                type: 'tagOffset',
                enabled: resourceTypesFormFilters.tagOffset,
                x: resourceTypesFormFilters.x,
                y: resourceTypesFormFilters.y,
                z: resourceTypesFormFilters.z
            }
        );
    }
    
    if ((addMode && resourceTypesFormFilters.moveMedianEnabled) || (!addMode && typeof resourceTypesFormFilters.moveMedianEnabled !== 'undefined')) {
        filters.push(
            {
                type: 'moveMedian',
                enabled: resourceTypesFormFilters.moveMedianEnabled,
                radius: resourceTypesFormFilters.radius,
                moveCount: resourceTypesFormFilters.moveCount,
                moveMedian: resourceTypesFormFilters.moveMedian,
                medianCount: resourceTypesFormFilters.medianCount,
                medianCountZ: resourceTypesFormFilters.medianCountZ
            }
        );
    }
    
    // if ((addMode && resourceTypesFormFilters.stationZone) || (!addMode && typeof resourceTypesFormFilters.stationZone !== 'undefined')) {
    //     filters.push(
    //         {
    //             // typeStationZone: getField('typeStationZone',"TYPE_FIELD_TEXT_X",'typeStationZone','stationZone'),
    //             type: 'stationZone',
    //             enabled: resourceTypesFormFilters.stationZone,
    //             lockMode: resourceTypesFormFilters.lockMode,
    //             zoneUnlockDistance: resourceTypesFormFilters.zoneUnlockDistance
    //         });
    // }
    
    if ((addMode && resourceTypesFormFilters.rate) || (!addMode && typeof resourceTypesFormFilters.rate !== 'undefined')) {
        filters.push(
            {
                type: 'rate',
                enabled: resourceTypesFormFilters.rate,
                radius: resourceTypesFormFilters.radius2,
                maxTime: resourceTypesFormFilters.maxTime,
                minTime: resourceTypesFormFilters.minTime
            });
    }
    
    if ((addMode && resourceTypesFormFilters.zoneLockdown) || (!addMode && typeof resourceTypesFormFilters.zoneLockdown !== 'undefined')) {
        filters.push(
            {
                type: 'zoneLockdown',
                enabled: resourceTypesFormFilters.zoneLockdown,
                count: resourceTypesFormFilters.count
            }
        );
    }
    
    if ((addMode && resourceTypesFormFilters.zoneChange) || (!addMode && typeof resourceTypesFormFilters.zoneChange !== 'undefined')) {
        filters.push(
            {
                type: 'zoneChange',
                enabled: resourceTypesFormFilters.zoneChange,
                numBlinks: resourceTypesFormFilters.numBlinks
            });
    }

    resourceType.filters = filters;

    return resourceType;
};

/*
export const updateAttributeSectionLookUp = (IndexGroup,field,enabled) =>{
    return (dispatch) => {
        //console.log("IndexGroup: ",IndexGroup,", field: ",field," enabled: ",enabled);
        //dispatch({type: SET_SHOW_ATTRIBUTES, showAttributes: showAttributes});
    }
}*/
export const showAttributes = (showAttributes) => {
    return (dispatch) => {
        dispatch({ type: SET_SHOW_ATTRIBUTES, showAttributes: showAttributes });
    };
};

export const selectResourceTypeRow = (rowIndex) => { // Function not used
    return (dispatch, getState) => {
        const { reportKey } = getReportKeys(getState());
        const currentSelectedRows = getState().resourceTypes.selectedRows;
        const resourceType = getState().tableOnePlatform[reportKey].data[rowIndex];
        const newState = Object.assign({}, currentSelectedRows);
        newState[resourceType.id] = resourceType;
        dispatch({ type: RESOURCE_TYPE_SELECTION, selectedRows: newState, selectedResourceTypeId: getState().resourceTypes.selectedResourceTypeId || '' });
    };
};

export const viewResourceTypeDetails = (rowIndex) => {
    return (dispatch, getState) => {
        const { reportKey } = getReportKeys(getState());
        const currentSelectedRows = getState().resourceTypes.selectedRows;
        const resourceType = getState().tableOnePlatform[reportKey].data[rowIndex];
        const newState = Object.assign({}, currentSelectedRows);
        if (resourceType && resourceType.hasOwnProperty('id')) {
            newState[resourceType.id] = resourceType;
        }
        dispatch({ type: RESOURCE_TYPE_SELECTION, selectedRows: newState, selectedResourceTypeId: resourceType.id });
    };
};

export const viewResourceTypeFilters = (rowIndex) => { // Function not used
    return (dispatch, getState) => {
        const { reportKey } = getReportKeys(getState());
        const currentSelectedRows = getState().resourceTypes.selectedRows;
        const resourceType = getState().tableOnePlatform[reportKey].data[rowIndex];
        const newState = Object.assign({}, currentSelectedRows);
        if (resourceType && resourceType.hasOwnProperty('id')) {
            newState[resourceType.id] = resourceType;
            dispatch({ type: RESOURCE_TYPE_FILTER_SELECTION, selectedRow: newState });
        }
    };
};

export const unSelectResourceTypeRow = (rowIndex) => { // Function not used
    return (dispatch, getState) => {
        const { reportKey } = getReportKeys(getState());
        const resourceType = getState().tableOnePlatform[reportKey].data[rowIndex];
        const currentSelectedRows = getState().resourceTypes.selectedRows;
        const newState = Object.assign({}, currentSelectedRows);
        delete newState[resourceType.id];
        dispatch({ type: RESOURCE_TYPE_SELECTION, selectedRows: newState });
    };
};

export const resourceTypeSelectAll = (allRows) => ({ type: RESOURCE_TYPE_SELECTION, selectedRows: allRows });

export const validateFieldsResourceTypes = (validateLookUpOptions=()=>{}) => {
    return async (dispatch, getState) => {
        const validateResults = await dispatch(validateFormResourceTypes(validateLookUpOptions));
        const successful = !validateResults.error;
        dispatch({ type: UPDATE_VALIDATED_FIELDS_RESOURCE_TYPES, /*successfulValidatedFieldsResourceTypes:successful,*/needsValidationFieldsResourceType: validateResults.needsValidationFieldsResourceType });
        if (successful === true) {
            dispatch(saveResourceType());
        }
        return successful;
    };
};

export const validateIconFieldsResourceTypes = () => {
    return async (dispatch, getState) => {
        const validateResults = await dispatch(validateIconFormResourceTypes());
        const successful = !validateResults.error;
        dispatch({ type: UPDATE_VALIDATED_FIELDS_RESOURCE_TYPES, /*successfulValidatedFieldsResourceTypes:successful,*/needsValidationFieldsResourceType: validateResults.needsValidationFieldsResourceType });
        if (successful === true) {
            dispatch(saveIcon());
        }
        return successful;
    };
};

export const validateIconFormResourceTypes = () => {
    return async (dispatch, getState) => {
        const resourceTypesForm = getState().resourceTypes.resourceTypesForm;
        
        const needsValidationFieldsResourceType = getState().resourceTypes.needsValidationFieldsResourceType;
        let error = false;
        // Object.keys(needsValidationFieldsResourceType).forEach(sectionKey=>{
        const section = needsValidationFieldsResourceType['IconConfiguration'];
        Object.keys(section).forEach(field => {
            const validationInfo = section[field];
            if (validationInfo.validateForms != null && field !== 'resourceTypesFormAttributes') {
                if (validationInfo.type === VALIDATION_TYPE_REQUIRED) {
                    validationInfo.error = validateRequired(resourceTypesForm[field]);
                }
                if (validationInfo.error === true) {
                    error = true;
                }
            }
            // });
        });
        
        return { error, needsValidationFieldsResourceType };
    };
};

export const validateFormResourceTypes = (validateLookUpOptions) => {
    return async (dispatch, getState) => {
        const resourceTypesForm = getState().resourceTypes.resourceTypesForm;
        const resourceTypesFormAttributes = getState().resourceTypes.resourceTypesFormAttributes;
        
        const needsValidationFieldsResourceType = getState().resourceTypes.needsValidationFieldsResourceType;
        let error = false;
        Object.keys(needsValidationFieldsResourceType).forEach(sectionKey => {
            if (sectionKey !== 'IconConfiguration') {
                const section = needsValidationFieldsResourceType[sectionKey];
                Object.keys(section).forEach(field => {
                    const validationInfo = section[field];
                    if (validationInfo.validateForms !== null && field !== 'resourceTypesFormAttributes') {
                        if (validationInfo.type === VALIDATION_TYPE_REQUIRED) {
                            validationInfo.error = validateRequired(resourceTypesForm[field]);
                        }

                        if (validationInfo.type === VALIDATION_TYPE_REGEXP) {
                            validationInfo.error = validateFieldRegex(resourceTypesForm[field], validationInfo.regexp);
                        }

                        if(validationInfo.type === RANGE_NUMBER){
                            if(field === PURGE_IN_DAYS_FIELD){
                                const purge = resourceTypesForm.hasOwnProperty(PURGE_FIELD)?resourceTypesForm.purge:PURGE_FIELD_VALUES.NONE;
                                if (purge === PURGE_FIELD_VALUES.NONE){
                                    validationInfo.error = false;
                                } else{
                                    validationInfo.error = validateFieldRange(resourceTypesForm[field], validationInfo.range);
                                }
                            }else{
                                validationInfo.error = validateFieldRange(resourceTypesForm[field], validationInfo.range);
                            }
                        }
                        if (validationInfo.error === true) {
                            error = true;
                        }
                    }
                });
            }
        });

        // VALIDATION IN ATTRIBUTES fields
        if (resourceTypesFormAttributes.length > 0) {
            resourceTypesFormAttributes.forEach((element, index) => {
                Object.keys(element.validation).forEach(IdAttributeField => {

                    if (element.validation[IdAttributeField]?.type === VALIDATION_TYPE_REQUIRED) {
                        element.validation[IdAttributeField].error = validateRequired(resourceTypesFormAttributes[index][IdAttributeField]);
                    }
                    if (element.validation[IdAttributeField]?.type === VALIDATION_TYPE_REGEXP) {
                        element.validation[IdAttributeField].error = validateFieldRegex(resourceTypesFormAttributes[index][IdAttributeField], element.validation[IdAttributeField]?.regexp);
                        element.validation[IdAttributeField].message = VALIDATION_MESSAGE_REGULAR_CHARACTERS_ATTRIBUTE_REQUIRED;
                        if (!element.validation[IdAttributeField].error && element.validation[IdAttributeField].regFirstCharacter?.test(resourceTypesFormAttributes[index][IdAttributeField].charAt(0))) {
                            element.validation[IdAttributeField].error = true;
                            element.validation[IdAttributeField].message = VALIDATION_MESSAGE_REGULAR_CHARACTERS_ATTRIBUTE_NUMBER_REQUIRED;
                        }
                    }

                    if(IdAttributeField==='lookUpValue' && resourceTypesFormAttributes[index].enableLookUp && resourceTypesFormAttributes[index][IdAttributeField].length>0){
                        if(Object.keys(resourceTypesFormAttributes[index][IdAttributeField]).length===1 && resourceTypesFormAttributes[index][IdAttributeField][0].value==='' )
                        {
                            element[IdAttributeField][0].validation.error=true
                            element[IdAttributeField][0].validation.message=VALIDATION_LOOKUP_MESSAGE_REQUIRED
                            error=true
                        }
                        else{
                            /*Object.keys(resourceTypesFormAttributes[index][IdAttributeField]).map(itm=>
                                resourceTypesFormAttributes[index][IdAttributeField][itm].value? element[IdAttributeField][itm].validation.error=false:
                                    (element[IdAttributeField][itm].validation.error=true,element[IdAttributeField][itm].validation.message=VALIDATION_MESSAGE_REQUIRED, error=true)
                            )*/
                            Object.keys(resourceTypesFormAttributes[index][IdAttributeField]).forEach(itm=>{
                                if( resourceTypesFormAttributes[index][IdAttributeField][itm].value ||
                                    (typeof resourceTypesFormAttributes[index][IdAttributeField][itm].value === 'boolean' && resourceTypesFormAttributes[index][IdAttributeField][itm].value===false) ||
                                    (typeof resourceTypesFormAttributes[index][IdAttributeField][itm].value === 'number' && !Number.isNaN(resourceTypesFormAttributes[index][IdAttributeField][itm].value))
                                ){
                                    element[IdAttributeField][itm].validation.error = false
                                }else{

                                    element[IdAttributeField][itm].validation.error = true;
                                    element[IdAttributeField][itm].validation.message = VALIDATION_MESSAGE_REQUIRED;
                                    error = true;
                                }
                            })
                        }
                    }

                    if (element.validation[IdAttributeField]?.error === true) {
                        error = true;
                    }                    
                });
            });
        }
        if(validateLookUpOptions()){
            error=true;
        }

        return { error, needsValidationFieldsResourceType,resourceTypesFormAttributes };
    };
};
const validateRequired = (fieldValue, enableLookUp) => {
    let error = false;
    if (fieldValue === undefined || fieldValue == null || fieldValue === '') {// || (typeof fieldValue === 'object' && fieldValue.length === 0 && enableLookUp)) {
        error = true;
    }
    return error;
};

const validateFieldRegex = (fieldValue, regexExp) => {
    let error = false;
    if (fieldValue === undefined || fieldValue == null || fieldValue === '' || !regexExp.test(fieldValue)) {
        error = true;
    }
    return error;
};

const validateFieldRange = (fieldValue, range) => {
    let error = false;
    if(! (!isNaN(fieldValue) &&
        (range.hasOwnProperty('min') ? parseInt(fieldValue)>=range.min:true))){
        error = true;
    }
    if(! (!isNaN(fieldValue) &&
        (range.hasOwnProperty('max') ? parseInt(fieldValue)<=range.max:true))){
        error = true;
    }
    return error;
};
export const initValidatedFieldsResourceTypes = () => {
    return (dispatch, getState) => {
        const needsValidationFieldsResourceType = Object.assign({}, getState().resourceTypes.needsValidationFieldsResourceType);
        Object.keys(needsValidationFieldsResourceType).forEach(sectionKey => {
            const section = needsValidationFieldsResourceType[sectionKey];
            section && Object.keys(section).forEach(field => {
                const validationInfo = section[field];
                validationInfo.error = false;
            });
        });
        dispatch({ type: UPDATE_VALIDATED_FIELDS_RESOURCE_TYPES, /*successfulValidatedFieldsResourceTypes:false,*/needsValidationFieldsResourceType: needsValidationFieldsResourceType });
    };
};
