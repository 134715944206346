import React, { useState, useMemo, useEffect } from 'react';
import BaseFilter from '../../onePlatform/LayoutContent/FilterLoader/Filters/BaseFilter/BaseFilter';
import {getSiteZoneGroups} from '../../../actions';
import { useDispatch } from 'react-redux';

const ZBAutosuggest = ({value, values, name, onChange, siteId}) => {
  const dispatch = useDispatch();
  const [optionsField, setOptionsField] = useState([]);
  useEffect(() => {
    setOptionsField(values.map(itemValue => ({name: itemValue, value: itemValue})));
  }, [values]);

  const isAllAllowed = false;
  const multiselect = false;
  const isValueAll = () => {};
  const selectedValue = useMemo(() => ([{ name: value, value }]), [value]);
  const recentSearches = [];
  const fetchingOptions = 'NONE';
  const enableAutoSuggest = true;

  return(
    <BaseFilter
      properties={{
          title: '',
          withAll: isAllAllowed,
          multiSelect: multiselect,
          separatorCharacter: ',',
          noAllowedCharacters: '',
          isValueAll,
          enableSelectOnLabel: false,
          extraClassInputContainer: 'input-full-container',
          extraClassInputBox: 'input-full-box'
      }}
      selectedValues={selectedValue || []}
      recentSearches={recentSearches}
      suggestions={optionsField}
      fetchingOptions={fetchingOptions}
      onInit={() => {
          dispatch(getSiteZoneGroups(siteId));
      }}
      onInputChange={({ newSuggest, opening }) => {
          if (!opening) {
              const query = newSuggest || '';
              if(enableAutoSuggest){
                  const newValues = values.filter(v => v?.toLowerCase().indexOf(query.toLowerCase())>-1);
                  setOptionsField(newValues.map(itemValue => ({name: itemValue, value: itemValue})))
              }
          }
      }}
      onChangeSelectValues={(selectedValues, recentSearches) => {
          if(selectedValues.length > 0 && selectedValues[0].value !== value){
            onChange(name, selectedValues[0].value)
          }
      }}
      enableTitle={false}
      enabledFilter={true}
      onOpenOptions={() => {
        dispatch(getSiteZoneGroups(siteId));
          // setOptionsField(values.map(itemValue => ({name: itemValue, value: itemValue})));
      }}
    />
  );
}  
export default ZBAutosuggest;
