/* jshint esversion: 6 */

import React from 'react';
import PropTypes from 'prop-types';
import assign from 'object-assign';

const timePickerHeight= 150;
let listDropUp = true;
const styles={
    container:{
        position:'relative',
        display:'inline-block',
        overflow:'visible'
    },

    input:{
        width:'6em',
        padding:'0.25em 0.5em'
    },

    dropdown:{
        position:'absolute',
        zIndex:'10',
        background:'white',
        top:'2em',
        left:'0',
        height: timePickerHeight + 'px',
        overflow:'auto',
        border:'1px solid',
        width:'7em',
        cursor:'pointer'
    },

    dropdownList:{
        listStyleType:'none',
        margin:0,
        padding:0
    }
};

function withLeadingZeros(n,nDigits){
    let s = String(n);
    return Array(1+nDigits-s.length).join("0")+s;
}

const itemStyles={
    hover:{
        backgroundColor:"#ddd",
        padding:'0 0.5em',
        cursor:'pointer'
    },
    normal:{
        padding:'0 0.5em',
        cursor:'pointer'
    }
};

class TimePickerItem extends React.Component{
    constructor(props,context){
        super(props,context);

        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseOut = this.handleMouseOut.bind(this);

        this.state={
            hover:false
        };
    }

    static get propTypes(){
        return {
            children: PropTypes.any
        };
    }

    handleMouseEnter(){
        this.setState({
            hover:true
        });
    }

    handleMouseOut(){
        this.setState({
            hover:false
        });
    }

    render(){
        let style = this.state.hover ? itemStyles.hover : itemStyles.normal;

        return (
            <li style={style} onMouseEnter={this.handleMouseEnter} onMouseOut={this.handleMouseOut} {...this.props}>
                {this.props.children}
            </li>
        );
    }
}
const HOURS = [12,1,2,3,4,5,6,7,8,9,10,11];
function TimeFromNMinutes(N){
    let o = {};
    o.hours = Math.floor(N / 60);
    o.minutes = N % 60;
    o.pm = (N >= 720);
    o.string = [
        HOURS[o.hours % 12],
        ":",
        withLeadingZeros(o.minutes,2),
        " ",
        (o.pm) ? "PM" : "AM"
    ].join("");
    return o;
}

export default class TimePicker extends React.Component{
    constructor(props,context){
        super(props,context);
        this.timeOptions = React.createRef();
        this.state={
            time:props.defaultValue.string || "",
            ddvis: false
        };

        this.handleInputClick = this.handleInputClick.bind(this);
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
        this.pageClick = this.pageClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    static get propTypes(){
        return {
            defaultValue: PropTypes.shape({
                string: PropTypes.string
            }),
            onChange: PropTypes.func,
            style: PropTypes.object,
            disabled: PropTypes.bool
        };
    }

    handleChange(e){

        let time = e.target.value;

        this.setState({
            time:time
        });

        if(this.props.onChange){
            this.props.onChange(time);
        }
    }

    handleSelect(time){
        this.setState({
            time:time.string,
            ddvis:false
        });

        if(this.props.onChange){
            this.props.onChange(time);
        }
    }

    handleInputClick(){
        let position = this.timeOptions.current.getBoundingClientRect();
        let remainingHeight=window.innerHeight - position.top;
        let minHeightRequired= timePickerHeight+50;
        if(remainingHeight< minHeightRequired){
            listDropUp=true;
        }else {
            listDropUp=false;
        }
        this.setState({
            ddvis:true
        });
    }

    componentDidMount(){
        window.addEventListener("mousedown",this.pageClick,false);
    }
    componentWillUnmount(){
        window.removeEventListener("mousedown",this.pageClick);
    }

    UNSAFE_componentWillReceiveProps(np){
        let dV = np.defaultValue;
        if(dV === "") dV = {string:""};

        this.setState({
            time: dV.string
        });
    }

    pageClick(){
        if(this.mouseIsDownOnPicker){ return; }
        this.setState({
            ddvis:false
        });
    }

    handleMouseDown(){
        this.mouseIsDownOnPicker = true;
    }
    handleMouseUp(){
        this.mouseIsDownOnPicker = false;
    }

    render(){


        let t_min = 0,t_max = 1440,t_inc= 30;
        let t;
        let items = [];//,h,m,ap;
        for(t = t_min; t <= t_max; t+=t_inc){
            items.push(TimeFromNMinutes(t));
        }

        let elmsItems = items.map((v,i)=>{
            return (
                <TimePickerItem key={i} onClick={
                    this.handleSelect.bind(this,v)
                }>{v.string}</TimePickerItem>
            );
        });

        let ddStyles={...styles.dropdown} ;
        if(this.state.ddvis) {
            if (listDropUp) {
                ddStyles.top=undefined;
                ddStyles.bottom='2em';
            }
        }
        else{
            ddStyles.display='none';
        }

        let contStyles = assign(styles.container,this.props.style);

        return(
            <div style={contStyles} onMouseUp={this.handleMouseUp} onMouseDown={this.handleMouseDown} ref={this.timeOptions}>
                <input style={styles.input} value={this.state.time} onClick={this.handleInputClick} onChange={this.handleChange} disabled={this.props.disabled} readOnly/>
                <div style={ddStyles}>
                    <ul style={{...styles.dropdownList, ...this.props.timePickerStyle}}>
                        {elmsItems}
                    </ul>
                </div>
            </div>
        );
    }
}
