// created to have a standarized input field for all screens
// TODO: Find a way to use this method in DataEntryTabularForm (cause: {this.props.children})
export default function Input({ label, required, ...inputProps }) {
  return (
    <div className="basic-input">
      <div className={"label"}>
        {label}
        {
          required ? (
            <span style={{ fontSize: '0.9em', color: '#f55', marginLeft: '0.5em', fontStyle: 'italic', letterSpacing: '0.5px' }}>
              (required)
            </span>
          ) : null
        }
      </div>
      <div className={"field"}>
        <input {...inputProps} />
      </div>
    </div>
  );
}