import moment from 'moment/moment';

import {
    CHANGE_LOCAL_MAP,
    CLEAN_LOCAL_MAP,
    CLEAN_PLAYBACK_DATE,
    LOAD_LOCAL_MAP,
    LOAD_MAP_CONFIG,
    LOAD_PLAYBACK_DATE,
    LOAD_PLAYBACK_HISTORY,
    LOAD_PLAYBACK_VIEW,
    LOAD_REPORT,
    MAP_WIDTH_HEIGHT_ERROR,
    MODIFY_PLAYBACK_ITEMS,
    REPORT_MAP_PATCH_PROPERTIES_MAP,
    REPORT_MAP_REPLACE_ALL_SITE_LOCAL_MAP,
    REPORT_MAP_UPDATE_MAP_DIALOG,
    REQUEST_PLAYBACK,
    SAVE_CURRENT_VIEW,
    SELECT_MAP,
    SET_CURRENT_PLAYBACK_SITE_NAME,
    SET_DEFAULT_PLAYBACK_MAP_ID,
    SET_PLAYBACK_DATE,
    SITE_MANAGER_SITE_SELECTED,
    UPDATE_CLUSTER_INDEX,
    UPDATE_MAP_SIZE
} from '../constants/ActionTypes';
import { WORLD_MAP } from '../utils/ol/olConstants';

const initialState = {
    enabled: false,
    mapFormat:{
        xAttribute:"",
        yAttribute:"",
        siteAttribute:"siteName", // please fix...
        selectedAttribute:"___selected", // may be a constant.  should check.
        mapAttribute:"",
        idAttribute:"",
        renderAsPoints: false
    },
    localSite:{
        _id:null
    },
    localMap:{
        _id:null,
        boundingBox:[null,null,null,null] // <-- [minX,minY,maxX,maxY]
    },
    mapConfiguration:{},
    currentLocalMap:{},
    requested: null,
    clusterInfo:{clusterIndex:null,mapId:null},
    currentPlaybackInfo:{playbackId:null,playbackSiteName:null},
    dateRange: {},
    playback:{},
    mapType:WORLD_MAP,
    mapId:null,
    updateMapSize:[],
    siteManagerSiteSelected:null //
    // itemsPlayback:{},
    // eventQueryPlayback:null,
};

function adaptMapFormat(reportDefinition){
    const mf = reportDefinition.map;

    return {
        ...initialState.mapFormat,
        xAttribute: mf.xColumn || initialState.mapFormat.xAttribute,
        yAttribute: mf.yColumn || initialState.mapFormat.yAttribute,
        mapAttribute: mf.mapColumn || initialState.mapFormat.mapAttribute,
        idAttribute: mf.idColumn || initialState.mapFormat.idAttribute,
        renderPoints: mf.renderAsPoints || initialState.mapFormat.renderPoints
    };
}

export function reportMap(state = initialState, action){
    switch(action.type){
        case LOAD_REPORT:
            return {
                ...state,
                enabled: action.reportDefinition.map.enabled,
                mapFormat:adaptMapFormat(action.reportDefinition)
            };

        case LOAD_MAP_CONFIG:

            return{ ...state,
                mapConfiguration:action.mapConfiguration,
                validConfiguration:action.validConfiguration
            };
        case CHANGE_LOCAL_MAP:
            let currentLocalMap={...state.currentLocalMap};
            let site=action.site;
            if(action.reportId)
            {
                const mapId=(action.map)?action.map._id:null;

                if(currentLocalMap[action.reportId])
                    currentLocalMap[action.reportId].localMapId=mapId;
                else
                    currentLocalMap[action.reportId]={localMapId:mapId};

                if(site||site===null)
                    currentLocalMap[action.reportId].site=site;
            }
            return{ ...state,
                currentLocalMap: currentLocalMap
            };
        case LOAD_LOCAL_MAP:
            return {
                ...state,
                currentLocalMap: action.currentLocalMap,
            };
        case CLEAN_LOCAL_MAP:
            return {
                ...state,
                currentLocalMap: {}
            };
        case LOAD_PLAYBACK_VIEW:

            return{
                ...state,
                currentMapPlayback: action.currentMap
            };
        case LOAD_PLAYBACK_HISTORY:
            return {
                ...state,
                length: action.length,
                tagsData: action.tagsData
            };
        case MODIFY_PLAYBACK_ITEMS:

            let playbackByReport={};
            playbackByReport[action.reportId]={};
            if(state.playback.hasOwnProperty(action.reportId)){
                playbackByReport[action.reportId]=Object.assign({},state.playback[action.reportId]);
            }
            else{
                playbackByReport[action.reportId].items={};
                playbackByReport[action.reportId].eventQuery=null;
            }

            playbackByReport[action.reportId].items=action.items;
            if (action.eventQuery != null) {
                playbackByReport[action.reportId].eventQuery = action.eventQuery;
            }

            const playback=Object.assign({},state.playback,playbackByReport);

            return{
                ...state,
                playback: playback,
            };
        case SET_DEFAULT_PLAYBACK_MAP_ID:
            return {
                ...state,
                currentPlaybackMapId: action.playbackMapId
            };
        case SET_CURRENT_PLAYBACK_SITE_NAME:
            return {
                ...state,
                currentPlaybackInfo: {playbackId:action.playbackId, playbackSiteName: action.playbackSiteName}
            };
        case REQUEST_PLAYBACK:
            return {
                ...state,
                requested: action.requested
            };
        case SET_PLAYBACK_DATE:
            return {
                ...state,
                dateRange: {
                    ...state.dateRange,
                    [action.reportId]: {
                        fromDateRange: action.fromDateRange,
                        toDateRange: action.toDateRange,
                    }
                },
            };
        case LOAD_PLAYBACK_DATE:
            // console.log("Action.DateRange: ", action.dateRange);
            let dateRangeAux = {};
            const keysArray = Object.keys(action.dateRange);
            keysArray.forEach(key => {
                dateRangeAux[key] = {
                    fromDateRange: moment(action.dateRange[key].fromDateRange).toDate(),
                    toDateRange: moment(action.dateRange[key].toDateRange).toDate()
                }
            });

            return {
                ...state,
                dateRange: {
                    ...dateRangeAux
                }
            };
        case CLEAN_PLAYBACK_DATE:
            return{
                ...state,
                dateRange: {}
            }
        case SAVE_CURRENT_VIEW:
            return {
                ...state,
                previousView: action.currentView,
            };
        case UPDATE_CLUSTER_INDEX:
            return {
                ...state,
                clusterInfo: {clusterIndex: action.clusterIndex, mapId: action.mapId},
            };
        case UPDATE_MAP_SIZE:
            const updateMapSize=[];
            return {
                ...state,
                updateMapSize: updateMapSize,
            };
        case SELECT_MAP:
            return {
                ...state,
                mapId:action.mapId,
                mapType:action.mapType,
                mapConfiguration:action.mapConfiguration,
                validConfiguration:action.validConfiguration,
                bounds:action.bounds,
                maxZoom:action.maxZoom,
                currentMap: action.currentMap
            };
        case SITE_MANAGER_SITE_SELECTED:
            return {
                ...state,
                siteManagerSiteSelected:action.siteManagerSiteSelected
            };
        case MAP_WIDTH_HEIGHT_ERROR:
            return {
                ...state,
                errorWH: action.errorWH,
                errorWHmessage: action.errorWHmessage,
                whSuccessful: action.whSuccessful
            }
        case REPORT_MAP_PATCH_PROPERTIES_MAP:
            const scopes = { ...state.scopes };
            //const scopes = JSON.parse(JSON.stringify(state.scopes || {}));
            if (!scopes[action.reportKey]) {
                scopes[action.reportKey] = {};
            }
            scopes[action.reportKey] = { ...scopes[action.reportKey] };
            const scope = {};
            for (const propertyKey of Object.keys(action.properties)) {
                if (Object.prototype.toString.call(action.properties[propertyKey]) === '[object Object]') {
                    // scopes[action.reportKey][propertyKey] = {
                    //     ...scopes[action.reportKey][propertyKey],
                    //     ...JSON.parse(JSON.stringify(action.properties[propertyKey]))
                    // }
                    scope[propertyKey] = {
                        ...scopes[action.reportKey][propertyKey],
                        ...JSON.parse(JSON.stringify(action.properties[propertyKey]))
                    }
                } else {
                    // scopes[action.reportKey][propertyKey] = JSON.parse(JSON.stringify(action.properties[propertyKey]));
                    scope[propertyKey] = JSON.parse(JSON.stringify(action.properties[propertyKey]));
                }
            }
            scopes[action.reportKey] = {
                ...scopes[action.reportKey],
                ...scope
            };
            return {
                ...state,
                scopes
            }
        case REPORT_MAP_REPLACE_ALL_SITE_LOCAL_MAP:
            return {
                ...state,
                scopes: action.scopes
            }
        case REPORT_MAP_UPDATE_MAP_DIALOG:
            return {
                ...state,
                dialog: {
                    open: action.open,
                    message: action.message,
                    loader: action.loader
                }
            }
        default:
            return state;
    }
}
