import { 
    GET_SYSTEM_ALERT_SETTINGS_DATA, 
    GET_SYSTEM_ALERT_DATA, 
    SET_SELECTED_ALERT_DEFINITION, 
    GET_NOTIFICATION_BY_ALERT_NAME, 
    CHANGE_ALERT_MESSAGE_MODAL_STATUS, 
    GET_ALERT_RECIPIENTS, 
    UPDATE_EMAIL_NOTIFICATION_MODAL_STATUS, 
    UPDATE_EMAIL_NOTIFICATION_DATA,
    SET_CONTACT,
    OPEN_DIALOG_CONTACT,
    CONTACT_DIALOG_MODE,
    SITE_NOTIFICATION,
    RECIPIENTS_LIST,
    LOADING_SITE_NOTIFICATION
} from '../constants/ActionTypes';

const initialState = {
    settings: [],
    data: [],
    sortDirection: 'asc',
    sortField: 'timestamp',
    page: 0,
    limit: 1000,
    alertNotifications: [],
    selectedAlertDefinition: {},
    changeAlertMessageModalStatus: false,
    updateEmailNotificationModalStatus: false,
    updateEmailNotificationData: {},
    recipientsList: [],
    recipients: [],
    contact: null,
    openDialogContact: false,
    contactDialogMode: '',
    siteNotification: null,
    loadingSiteNotification: false
}

const alertsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SYSTEM_ALERT_SETTINGS_DATA:
            return { ...state, settings: action.data };
        case GET_SYSTEM_ALERT_DATA:
            return { ...state, data: action.data };
        case SET_SELECTED_ALERT_DEFINITION:
            return { ...state, selectedAlertDefinition: action.alertDefinition };
        case GET_NOTIFICATION_BY_ALERT_NAME:
            return { ...state, alertNotifications: action.alertNotifications };                
        case CHANGE_ALERT_MESSAGE_MODAL_STATUS:
            return { ...state, changeAlertMessageModalStatus: action.changeAlertMessageModalStatus };
        case UPDATE_EMAIL_NOTIFICATION_MODAL_STATUS:
            return { ...state, updateEmailNotificationModalStatus: action.updateEmailNotificationModalStatus };
        case UPDATE_EMAIL_NOTIFICATION_DATA:
            return { ...state, updateEmailNotificationData: action.updateEmailNotificationData };
        case GET_ALERT_RECIPIENTS:
            return { ...state, recipients: action.recipients };
        case SET_CONTACT:
            return { ...state, contact: action.contact };
        case OPEN_DIALOG_CONTACT:
            return { ...state, openDialogContact: action.openDialogContact };
        case CONTACT_DIALOG_MODE:
            return { ...state, contactDialogMode: action.contactDialogMode };
        case SITE_NOTIFICATION:
            return { ...state, siteNotification: action.siteNotification }
        case RECIPIENTS_LIST:
            return { ...state, recipientsList: action.recipientsList }
        case LOADING_SITE_NOTIFICATION:
            return { ...state, loadingSiteNotification: action.loadingSiteNotification }            
        default:
            return state;
    }
}

export default alertsReducer;