import React from 'react';

const InputBox = ({
                      openSuggestion,
                      selectedValues,
                      inputOrder,
                      updateInputValue,
                      setInputOrder,
                      inputValue,
                      updateSelectedValues,
                      inputRef,
                      varRef,
                      properties,
                      setIndexFocus,
                      indexFocus,
                      recentSearches,
                      suggestions,
                      onRecentSearchSelected,
                      onSuggestionSelected,
                      onAllSelected,
                      endSearch,
                      allOption,
                  }) => {
    const {
        multiSelect,
        separatorCharacter,
        withAll,
        noAllowedCharacters,
        hasValueWildcard,
        getName,
        isValidState,
        inputDisabled,
    } = properties;
    
    const handleClickLabel = (selectedValue, index) => {
        updateInputValue(getName(selectedValue));
        setInputOrder(index);
        const newSelectedValues = [...selectedValues];
        let diff = 0;
        if (inputValue) {
            newSelectedValues.splice(inputOrder, 0, { name: inputValue, value: inputValue });
            if (index >= inputOrder) {
                setInputOrder(prevState => prevState + 1);
                diff = 1;
            }
        }
        newSelectedValues.splice(index + diff, 1);
        updateSelectedValues(newSelectedValues);
    };
    
    const handleClickDelete = (selectedValue, index) => {
        const newSelectedValues = [...selectedValues];
        newSelectedValues.splice(index, 1);
        if (index < inputOrder) {
            setInputOrder(prevState => prevState - 1);
        }
        updateSelectedValues(newSelectedValues);
    };
    
    const validOnChange = (newValue) => {
        const values = newValue
            .split(separatorCharacter)
            .map(value => value.split('').filter(c => !noAllowedCharacters.includes(c)).join(''))
            .filter(value => !!value)
            .map(value => ({ name: value, value }));
        let newInputValue = '';
        if (newValue.trim().slice(-1) === separatorCharacter) {
            newInputValue = '';
            const newSelectedValues = [...selectedValues];
            newSelectedValues.splice(inputOrder, 0, ...values);
            return isValidState(newSelectedValues, recentSearches, newInputValue);
        } else {
            if (values.length) {
                const newSelectedValues = [...selectedValues];
                newInputValue = getName(values[values.length - 1]) || '';
                if (values.length > 1) {
                    newSelectedValues.splice(inputOrder, 0, ...values.slice(0, -1));
                }
                return isValidState(newSelectedValues, recentSearches, newInputValue)
            } else {
                newInputValue = '';
                return isValidState(selectedValues, recentSearches, newInputValue);
            }
        }
    }
    
    const onChange = (event) => {
        varRef.current.downUp = false;
        const newValue = event.target.value;
        const isValid = validOnChange(newValue);
        if (isValid) {
            const values = newValue
                .split(separatorCharacter)
                .map(value => value.split('').filter(c => !noAllowedCharacters.includes(c)).join(''))
                .filter(value => !!value)
                .map(value => ({ name: value, value }));
            if (newValue.trim().slice(-1) === separatorCharacter) {
                updateInputValue('');
                const newSelectedValues = [...selectedValues];
                newSelectedValues.splice(inputOrder, 0, ...values);
                updateSelectedValues(newSelectedValues);
                setInputOrder(inputOrder + values.length);
            } else {
                if (values.length) {
                    updateInputValue(getName(values[values.length - 1]) || '');
                    if (values.length > 1) {
                        const newSelectedValues = [...selectedValues];
                        newSelectedValues.splice(inputOrder, 0, ...values.slice(0, -1));
                        updateSelectedValues(newSelectedValues);
                        setInputOrder(inputOrder + values.length - 1);
                    }
                } else {
                    updateInputValue('');
                }
            }
        }
    };
    
    const handleKeyDown = (e) => {
        let newIndexFocus;
        let suggestion;
        const { keyCode } = e;
        switch (keyCode) {
            case 8: // delete
                if (!inputValue && inputOrder) {
                    const newSelectedValues = [...selectedValues];
                    const deletedValue = newSelectedValues[inputOrder - 1];
                    newSelectedValues.splice(inputOrder - 1, 1);
                    updateSelectedValues(newSelectedValues);
                    setInputOrder(prevState => prevState - 1);
                    updateInputValue(getName(deletedValue));
                    e.preventDefault();
                    // TODO: Find a better way to select the text on the next render
                    setTimeout(() => {
                        inputRef.current?.focus();
                        // inputRef.current?.select();
                    }, 200);
                    
                }
                break;
            case  46: // supr
                if (!inputValue && inputOrder < selectedValues.length) {
                    const newSelectedValues = [...selectedValues];
                    const deletedValue = newSelectedValues[inputOrder];
                    newSelectedValues.splice(inputOrder, 1);
                    updateSelectedValues(newSelectedValues, false);
                    updateInputValue(getName(deletedValue));
                    // TODO: Find a better way to select the text on the next render
                    setTimeout(() => {
                        inputRef.current?.focus();
                        // inputRef.current?.select();
                    }, 200);
                }
                break;
            case  27: // escape
                endSearch();
                break;
            case 37: // left
                if (!inputValue) {
                    setInputOrder(prevState => Math.max(prevState - 1, 0));
                }
                break;
            case 39: // right
                if (!inputValue) {
                    setInputOrder(prevState => Math.min(prevState + 1, selectedValues.length));
                }
                break;
            case 40: // Down
                varRef.current.downUp = true;
                newIndexFocus = (indexFocus + 1) % (recentSearches.length + suggestions.length);
                setIndexFocus(newIndexFocus);
                if (!multiSelect) {
                    if (newIndexFocus < recentSearches.length) {
                        suggestion = recentSearches[newIndexFocus];
                        updateInputValue(suggestion.map(recentSearch => getName(recentSearch)).join(separatorCharacter));
                    } else {
                        suggestion = suggestions[newIndexFocus - recentSearches.length];
                        updateInputValue(getName(suggestion));
                    }
                }
                break;
            case 38: // Up
                varRef.current.downUp = true;
                newIndexFocus = (indexFocus - 1 < 0) ? suggestions.length + recentSearches.length - 1 : indexFocus - 1;
                setIndexFocus(newIndexFocus);
                if (!multiSelect) {
                    if (newIndexFocus < recentSearches.length) {
                        suggestion = recentSearches[newIndexFocus];
                        updateInputValue(suggestion.map(recentSearch => getName(recentSearch)).join(separatorCharacter));
                    } else {
                        suggestion = suggestions[newIndexFocus - recentSearches.length];
                        updateInputValue(getName(suggestion));
                    }
                }
                break;
            case 13: // Enter
                if (varRef.current.downUp) {
                    varRef.current.downUp = false;
                    if (indexFocus < recentSearches.length) {
                        onRecentSearchSelected(recentSearches[indexFocus]);
                    } else {
                        if (withAll && indexFocus === recentSearches.length) { // All selected
                            onAllSelected();
                        } else {
                            onSuggestionSelected(suggestions[indexFocus - recentSearches.length]);
                        }
                    }
                } else {
                    endSearch();
                }
                break;
            default:
        }
    };
    
    const renderInput = () => (
        <label className={'input-label' + ((!openSuggestion && !inputValue) ? ' empty-label' : '')} key="input-label-multiselect">
            <input
                value={inputValue}
                onChange={onChange}
                onKeyDown={handleKeyDown}
                ref={inputRef}
                disabled={inputDisabled}
            />
            {inputValue}
        </label>
    );

    return (
        <div className="selected-values-container">
            {selectedValues.map((initialSelectedValue, index) => {
                const className = 'selected-value-item' + (hasValueWildcard(initialSelectedValue) && selectedValues.length > 1 ? ' warning-wildcard' : '') + ' item-' + index;
                const toRender = [];
                if (index === inputOrder && openSuggestion && inputValue !== null) {
                    toRender.push(renderInput());
                }
                toRender.push(
                    <div className={className} key={JSON.stringify(initialSelectedValue) + '__' + index}>
                        <label onClick={() => handleClickLabel(initialSelectedValue, index)}>{getName(initialSelectedValue)}</label>
                        {multiSelect && (
                            <span className="delete-box" onClick={() => handleClickDelete(initialSelectedValue, index)}> x </span>
                        )}
                    </div>,
                );
                return toRender;
            })}
            {!selectedValues.length && !openSuggestion && (
                <div className="selected-value-item default-item">
                    <label>{getName(allOption)}</label>
                </div>
            )}
            {inputOrder >= selectedValues.length && openSuggestion && inputValue !== null && renderInput()}
        </div>
    );
};

export default InputBox;
