Object.filter = function (obj = {}, predicate = () => true) {
    const result = {};
    
    for (const key in obj) {
        if (obj.hasOwnProperty(key) && predicate(obj[key])) {
            result[key] = obj[key];
        }
    }
    
    return result;
};

Object.map = function (obj = {}, predicate = val => val) {
    const result = {};
    
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            result[key] = predicate(obj[key]);
        }
    }
    
    return result;
};
const ONE_THOUSAND = 1000;
export const bytesToLiteral = (bytes) => {
    let newBytes = bytes;
    let unit = 'B';
    if (newBytes >= ONE_THOUSAND) {
        newBytes /= ONE_THOUSAND;
        unit = 'KB';
    }
    if (newBytes >= ONE_THOUSAND) {
        newBytes /= ONE_THOUSAND;
        unit = 'MB';
    }
    if (newBytes >= ONE_THOUSAND) {
        newBytes /= ONE_THOUSAND;
        unit = 'GB';
    }
    return `${newBytes.toFixed(2)} ${unit}`;
};
export * from './filters';
export * from './reports';
export * from './search';