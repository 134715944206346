import {
    ADD_INTERACTION_ON_MAP,
    BACK_SNAPSHOT,
    CLEAN_SNAPSHOTS,
    CLOSE_DIALOG,
    FORWARD_SNAPSHOT,
    OPEN_DIALOG,
    PATCH_FORM,
    REMOVE_INTERACTION_ON_MAP,
    REPLACE_FORM,
    REPLACE_ITEMS,
    TAKE_SNAPSHOT, UPDATE_ZONES_MAP,
} from '../action-types';
import { MAX_BACK_SNAPSHOT_LENGTH } from '../../constants';
import {UPDATE_ZONE} from "../../vss/app/constants/ActionTypes";

const initialState = {
    // 'config-zones': {
    //     backSnapshots: {
    //         ZONE: [
    //             []
    //         ]
    //     },
    //     forwardSnapshots: {
    //         ZONE: []
    //     },
    //     items: {
    //         DEVICE_ATR7000_CLAS: [],
    //         ZONE: [],
    //         OBSTRUCTION: [],
    //         DEVICE_DATUM: []
    //     }
    // }
};
export function siteManager(state = initialState, action) {
    
    const currentState = state[action.scope] || {};
    let newInteractions = [];
    const backSnapshots = currentState.backSnapshots || {};
    const forwardSnapshots = currentState.forwardSnapshots || {};
    const items = currentState.items || {};
    const form = currentState.form || {};
    
    switch (action.type) {
        case OPEN_DIALOG: {
            return {
                ...state,
                [action.scope]: {
                    ...currentState,
                    dialog: { key: action.key, title: action.title, content: action.content, event: action.event },
                },
            };
        }
        case CLOSE_DIALOG: {
            return {
                ...state,
                [action.scope]: {
                    ...currentState,
                    dialog: {},
                },
            };
        }
        case PATCH_FORM:
            return {
                ...state,
                [action.scope]: {
                    ...currentState,
                    form: {
                        ...form,
                        [action.key]: action.value,
                    },
                },
            };
        case REPLACE_FORM:
            return {
                ...state,
                [action.scope]: {
                    ...currentState,
                    form: action.form,
                },
            };
        case REPLACE_ITEMS:
            return {
                ...state,
                [action.scope]: {
                    ...currentState,
                    items: {
                        ...items,
                        [action.itemType]: action.items,
                    },
                },
            };
        case UPDATE_ZONE:
        case UPDATE_ZONES_MAP:
            return {
                ...state,
                [action.scope]: {
                    ...currentState,
                    items: {
                        ...items,
                        [action.itemType]: action.items,
                    },
                },
            };
        case ADD_INTERACTION_ON_MAP:
            newInteractions = [ ...(currentState.mapInteractions || []) ];
            if (!newInteractions.includes(action.interaction)) {
                newInteractions.push(action.interaction);
            }
            return {
                ...state,
                [action.scope]: {
                    ...currentState,
                    mapInteractions: newInteractions,
                },
            };
        case REMOVE_INTERACTION_ON_MAP:
            newInteractions = [ ...(currentState.mapInteractions || []) ];
            if (newInteractions.includes(action.interaction)) {
                newInteractions = newInteractions.filter(interaction => interaction !== action.interaction);
            }
            return {
                ...state,
                [action.scope]: {
                    ...currentState,
                    mapInteractions: newInteractions,
                },
            };
        case TAKE_SNAPSHOT:
            return {
                ...state,
                [action.scope]: {
                    ...currentState,
                    backSnapshots: {
                        ...backSnapshots,
                        [action.itemType]: [
                            ...(backSnapshots[action.itemType] || []),
                            action.snapshot,
                        ].slice(-MAX_BACK_SNAPSHOT_LENGTH),
                    },
                    forwardSnapshots: {
                        ...forwardSnapshots,
                        [action.itemType]: [],
                    },
                },
            };
        case BACK_SNAPSHOT:
            if (backSnapshots[action.itemType]?.length) {
                const lastSnapshot = backSnapshots[action.itemType][backSnapshots[action.itemType].length - 1];
                return {
                    ...state,
                    [action.scope]: {
                        ...currentState,
                        backSnapshots: {
                            ...backSnapshots,
                            [action.itemType]: backSnapshots[action.itemType].slice(0, -1),
                        },
                        forwardSnapshots: {
                            ...forwardSnapshots,
                            [action.itemType]: [ lastSnapshot, ...(forwardSnapshots?.[action.itemType] || []) ],
                        },
                    },
                };
            }
            return state;
        case FORWARD_SNAPSHOT:
            if (forwardSnapshots[action.itemType]?.length) {
                return {
                    ...state,
                    [action.scope]: {
                        ...currentState,
                        backSnapshots: {
                            ...backSnapshots,
                            [action.itemType]: [ ...(backSnapshots[action.itemType] || []), forwardSnapshots[action.itemType][0] ],
                        },
                        forwardSnapshots: {
                            ...forwardSnapshots,
                            [action.itemType]: forwardSnapshots[action.itemType].slice(1),
                        },
                    },
                };
            }
            return state;
        case CLEAN_SNAPSHOTS:
            return {
                ...state,
                [action.scope]: {
                    ...currentState,
                    backSnapshots: {
                        ...backSnapshots,
                        [action.itemType]: [],
                    },
                    forwardSnapshots: {
                        ...forwardSnapshots,
                        [action.itemType]: [],
                    },
                },
            };
        default:
            return state;
    }
}
