/* jshint esversion:6 */

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import DatePicker from 'react-datepicker';

import TimePicker from './TimePicker';
import { Grid } from '@material-ui/core';


const HOURS = [12,1,2,3,4,5,6,7,8,9,10,11];
function TimeFromNMinutes(N){
    let o = {};
    o.hours = Math.floor(N / 60);
    o.minutes = N % 60;
    o.pm = (N >= 720);
    o.string = [
        HOURS[o.hours % 12],
        ":",
        withLeadingZeros(o.minutes,2),
        " ",
        (o.pm) ? "PM" : "AM"
    ].join("");
    return o;
}
function withLeadingZeros(n,nDigits){
    let s = String(n);
    return Array(1+nDigits-s.length).join("0")+s;
}

function getStateFromProps(props,key){
    if(props.hasOwnProperty(key)){
        if(props[key] === ""){
            return {
                date:null,
                time:""
            };
        }

        let d = new Date(props[key]);  // works with JSON and Date objects.

        let obj = {
            date:moment(d.toJSON()),
            time:TimeFromNMinutes(d.getHours()*60+d.getMinutes())
        };

        return obj;
    }else{
        return {
            date:null,
            time:""
        };
    }
}

class DateTimePickerFilter extends React.Component{
    constructor(props,context){
        super(props,context);
        this.state = getStateFromProps(props,'defaultValue');
        this.minDate = getStateFromProps(props,'minDate');
        this.maxDate = getStateFromProps(props,'maxDate');
        this.nextPrevButtons = props.nextPrevButtons;
    }

    static get propTypes(){
        return {
            onChange: PropTypes.func,
            disabled: PropTypes.bool,
            label: PropTypes.string,
            style: PropTypes.object,
            defaultValue: PropTypes.any.isRequired
        };
    }

    UNSAFE_componentWillReceiveProps(np){
        this.setState(getStateFromProps(np,'defaultValue'));
        this.minDate = getStateFromProps(np,'minDate');
        this.maxDate = getStateFromProps(np,'maxDate');
    }

    handleChange(date){
        this.setState({
            date: date
        });

        this.notifyChange(date,this.state.time);
    }

    handleTimeChange(time){
        this.setState({
            time:time
        });
        this.notifyChange(this.state.date,time);
    }

    notifyChange(date,time){
        if(!(time instanceof Object)) time = {};

        let d = new Date(date.toJSON());

        d.setHours(time.hours || 0);
        d.setMinutes(time.minutes || 0);

        if(this.props.onChange){
            this.props.onChange(d);
        }
    }

    shouldComponentUpdate(np,ns){
        return ns.date !== this.state.date || this.state.time !== ns.time || np.disabled !== this.props.disabled;
    }
    render(){
        const selected = moment(this.state.date).toDate();
        return (
            <div style={this.props.style}>
                <div><span>{this.props.label}</span></div>
                <Grid container spacing={2}>
                    <Grid item xs={this.props.grid?this.props.grid:8}>
                        <DatePicker selected={selected} className={this.props.className} onChange={this.handleChange.bind(this)} disabled={this.props.disabled} minDate={this.nextPrevButtons?moment(this.minDate.date).toDate():null} maxDate={this.nextPrevButtons?moment(this.maxDate.date).toDate():null} />
                    </Grid>
                    <Grid item xs={this.props.grid?this.props.grid:3}>
                        <TimePicker timePickerStyle={this.props.timePickerStyle} defaultValue={this.state.time} onChange={this.handleTimeChange.bind(this)} disabled={this.props.disabled} />
                    </Grid>
                </Grid>
            </div>
        );
    }

}

export default  DateTimePickerFilter;
