import {userGroups as MOCK_USERGROUPS_COLUMNS, users as MOCK_USERS_COLUMNS} from '../utils/column-definitions';

import {
    LOAD_RESOURCE_TYPES,
    LOAD_SITES_USERMGMT,
    LOAD_USER_GROUPS,
    LOAD_USERS,
    USERS_CLICK_TAB
} from '../constants/ActionTypes';

const initialState = {
    users: [],
    userColumns: MOCK_USERS_COLUMNS,
    groups: [],
    groupColumns: MOCK_USERGROUPS_COLUMNS,
    tab: 0,

    resourceTypes: [], // maybe not best location.
    sites:[]
};

function parseGroup(group){
    return {
        ...group,
        role:"View" // (temp)
    };
}

export function userMgmt(state = initialState, action){

    switch(action.type){

        case LOAD_RESOURCE_TYPES:
            return {
                ...state,
                resourceTypes:action.resourceTypes
            };

        case LOAD_USER_GROUPS:
            return {
                ...state,
                groups:action.groups.map(parseGroup)
            };

        case LOAD_USERS:
            return {
                ...state,
                users: action.users
            };

        case USERS_CLICK_TAB:
            return {
                ...state,
                tab: action.report
            };

        case LOAD_SITES_USERMGMT:
            return {
                ...state,
                sites: action.sites
            };
        default:
            return state;
    }
}
