import {
    DETECT_SAVE_CHANGES,
    SET_FETCHING,
    // SET_FETCHING_DEVICE,
    CHANGE_STEP,
    CHANGE_STEP_BATCH,
    UPDATE_LIST_TYPE_DEVICES,
    INIT_ADD_DEVICES,
    UPDATE_BATCH_IMPORT_FILES,
    DELETE_BATCH_IMPORT_FILE,
    CLEAN_IMPORTED_FILES,
    CREATE_DEVICES_BATCH,
    CLEAN_DEVICES_BATCH,
    FILTER_RESULTS,
    LIST_SITES,
    RECENT_MAPS,
    LOAD_SUGGESTIONS,
    MAP_SELECTED,
    CHANGE_CURRENT_MAP,
    SHOW_DETAILS,
    SHOW_MAPS,
    LOAD_TEMPLATE_LIST,
    APPLY_TEMPLATE,
    SELECT_TEMPLATE,
    LOAD_FORM_TEMPLATE,
    CLEAN_FORM_TEMPLATE,
    UPDATE_FORM_TEMPLATE,
    UPDATE_FORM_TEMPLATE_MAIN,
    UPDATE_FORM_TEMPLATE_ANTENNA,
    ADD_FORM_TEMPLATE_ANTENNA,
    ADD_FORM_TEMPLATE_ANTENNA_BY_PORT,
    DELETE_FORM_TEMPLATE_ANTENNA,
    LIST_SITES_GROUPS,
    SET_SELECTED_GROUP,
    LOAD_DEVICE_LIST,
    LOAD_SITE_MAP,
    UPDATE_DEVICE_OPERATIONS,
    CHECK_DEVICE_OPERATION,
    ACTION_DEVICE,
    DEVICE_MANAGER_UPDATE_SELECTED,
    UPDATE_SELECTED_FILTERS,
    LOAD_FILTERS,
    LOAD_FORM_DEVICE,
    LOAD_MAP_ZONES,
    UPDATE_MAP_ZONES,
    LOAD_ZONES_ANTENNAS,
    LOAD_DEVICES_SITES,
    LOAD_SCREEN,
    ADD_ANTENNA_BATCH,
    DELETE_ANTENNA_BATCH,
    LOAD_EDITED_DEVICE,
    LOAD_DEVICE_DETAILS_DATA,
    LOAD_TEMPLATE_DETAILS_DATA,
    LOAD_LINKED_DEVICES,
    CLEAN_LINKED_DEVICES,
    UPDATE_LINKED_SELECTED_ROWS,
    CHANGE_MAP_VIEW,
    LOAD_DEVICE_MANAGER_SCREEN,
    SET_FETCHING_DETAILS,
    SHOW_MODAL_DETAILS,
    SHOW_MODAL_EDIT_DEVICE,
    DEVICE_MOVE_COLUMN,
    DEVICE_TOGGLE_COLUMN,
    DEVICE_TOGGLE_ALL_COLUMNS,
    OPEN_ALERT,
    SORT_COLUMN,
    LOAD_DIALOG_NOTIFICATION,
    LOAD_FIRMWARE_VERSIONS_DEVICE,
    LOAD_DEVICE_OPTIONS,
    CHANGE_DEVICE_OPTIONS,
    UNLOAD_DEVICE_OPTIONS,
    UPDATE_DEVICE_OPTIONS,
    LOAD_MESSAGES_DEVICES_BATCH,
    CLEAN_MESSAGES_DEVICES_BATCH,
    UPDATE_COLUMN_WIDTH,
    CONFIGURATION_VERSION_HISTORY,
    UPDATE_DEVICE_OPERATIONS_IN_PROGRESS,
    UPDATE_TEMPLATE_COLUMN_WIDTH,
    SET_FIRMWARE_ERROR,
    UPDATE_VALIDATED_FIELDS,
    ALLOW_UPDATE_TEMPLATE,
    SET_TEMPLATE_ERROR,
    SET_ADDED_TEMPLATE,
    CONFIGURATION_CLOUD,
    CLEAN_NOTIFICATION,
    LOAD_SITE_SNAPSHOT_INFORMATION,
    GETTING_SITE_INFO,
    UPDATE_MAP_SELECTED,
    LOAD_STATUS_HISTORY,
    LOAD_DEVICE_STATUS,
    CHANGE_HISTORY_DATE_FILTER,
    LOAD_FETCH_HISTORY_STATUS,
    LOAD_FETCH_DEVICE_STATUS,
    DATA_TO_DOWNLOAD,
    CHANGE_LOG_HISTORY_DATE_FILTER,
    LOAD_LOG_HISTORY_DATA,
    SET_LOG_HISTORY_TYPES,
    SET_LOG_DOWNLOAD_TYPES,
    SET_DEVICES_SELECTED,
    SET_DOWNLOAD_ERROR,
    SET_LOG_CONFIGURATION_ERROR,
    SET_LOGS_CONFIGURATION_SUCCESSFULLY,
    SET_FETCHING_LOGS_SAVE,
    SET_FETCHING_DOWNLOAD_LOGS,
    SET_FETCHING_LOGS, CHANGE_LOG_LEVEL,
    SET_DOWNLOADED_HISTORY_LOGS,
    CLEAR_DOWNLOADED_LOGS,
    SET_DEVICE_LOG_CONFIG,
    SET_ERROR_DEVICES_LIST,
    UPDATE_DETAILS_DATA_OPERATION,
    LOADING_DEVICES_LIST,
    SET_PAGINATION_INFO,
    SET_DEVICES_ID,
    CLEAN_DEVICE_MANAGER, UPDATE_PUBLISH_SWITCH,
    LOGS_DELETED_STATUS,
    LOAD_USER_DEFINED_FILE,
    UNLOAD_USER_DEFINED_FILE, DETECT_SITE_CHANGES, IS_LOADING_FIRMWARE_VERSIONS_DEVICE
} from '../constants/ActionTypes';

import {
    // EDIT_DEVICE_CONFIG,
    EDIT_DEVICE_SAVED,
    EDIT_DEVICE_ERROR,
    UPGRADE_FIRMWARE,
    VIEW_DETAILS,
    EDIT,
    PUBLISH,
    CANCEL,
    // STOP,
    DELETE,
    // INITIALIZE,
    // ADD_TEMPLATE,
    ALERT_SUCCESS,
    ALERT_ERROR,
    ALERT_RECEIVER,
    ALERT_WARNING,
    LANDING_PAGE_SCREEN,
    EDIT_DEVICES_SCREEN,
    LANDING_DEVICE_TABLE,
    LANDING_TEMPLATE_TABLE,
    OPE_STATUS_NA,
    OPE_STATUS_FAILURE,
    OPE_STATUS_PUBLISHING,
    // OPE_STATUS_RUNNING, OPE_STATUS_STOPPED,
    // OPE_STATUS_TESTING, OPE_STATUS_REBOOTING,
    // OPE_STATUS_DISABLED, OPE_STATUS_INITIALIZED,
    // OPE_STATUS_UPDATING_FIRMWARE, OPE_STATUS_FIRMWARE_UPDATED,
    TEMPLATE_FIELDS,
    EMPTY_REQ_PARAMS,
    OPERATIONS_AVAILABLE_MAP,
    LIST_KNOWN_STATUS,
    NEW_AUTO_ZONE_TEMPLATE,
    ADD_TEMPLATE_SCREEN,
    EDIT_TEMPLATE_SCREEN,
    EDIT_TEMPLATE_ERROR_SCREEN,
    LOCAL_MAP_VIEW_DEVICES,
    OPE_STATUS_COMPLETED,
    OPE_STATUS_PENDING,
    OPE_TIMEOUT,
    EDIT_DEVICE_MANUALLY_ADDED
} from '../constants/DeviceManager';

import { LOADING, SUCCESS, ERROR, NONE } from '../constants/Filters';
import {
    displayErrorFromAxios,
    SiteViewService,
    getToken,
    // ReportService,
    ICService,
    DeviceManagementService,
    LCSMapService,
} from "./util";
import {displayNotification} from "./notification";
import {displayErrorDialog} from "./general";
import {MAP_VIEW_URL, SITE_VIEW_HOST} from "../constants/Misc";
import {getMapCoordinates} from "./util/maps";
import axios from 'axios';

// import {manageRfidTasks} from "./util/rfid-operations";

// import {data as devicesFromJson} from "../views/deviceManager/landing/dataResource2";

import {validate as uuidValidate} from 'uuid';
import {validateDevice} from "./util/deviceValidation";
import {downloadDeviceFileLogs} from '../views/deviceManager/common/accordionItems/utils/utils';

import { Feature } from 'ol';
import Polygon from 'ol/geom/Polygon';
import GeoJSON from 'ol/format/GeoJSON';
import Zone from '../utils/ZoneModel';


export const pathDeviceManager = '/device-manager/devices';

const {LIGHT_BLACK} = require('../constants/Colors');
const LIGHT_BLACK_COLOR = LIGHT_BLACK;

export const updateListTypeDevices = (listDeviceTypes) => ({type:UPDATE_LIST_TYPE_DEVICES, listDeviceTypes: listDeviceTypes});
export const updateStepAddDevices = (step) => ({type:CHANGE_STEP, step: step});
export const updateStepAddDevicesBatch = (step) => ({type:CHANGE_STEP_BATCH, step: step});

export const createDevicesBatch = (listDevices) => ({type:CREATE_DEVICES_BATCH, listDevices: listDevices});
export const createBatchImportFiles = (newFile) =>({type:UPDATE_BATCH_IMPORT_FILES, batchImportFiles: newFile});
export const deleteBatchImportFile = file => ({type: DELETE_BATCH_IMPORT_FILE, file: file});
export const cleanImportedFiles = () => ({type: CLEAN_IMPORTED_FILES});

export const cleanDevicesBatch = () => ({type: CLEAN_DEVICES_BATCH});
export const initAddDevices = () => ({type: INIT_ADD_DEVICES});
export const cleanMessagesDevicesBatch = () => ({type: CLEAN_MESSAGES_DEVICES_BATCH});
export const editNewDevices = (history=null) => {
    return async (dispatch, getState) => {
        const devicesBatch = getState().deviceManager.devicesBatch;
        dispatch(displayNotification(true, ALERT_RECEIVER, `Saving devices: `, 'in process', '', true, 5000));

        let devicesFailed = [];
        for(var i = 0; i < devicesBatch.length; i++){
            const device = devicesBatch[i];
            let {_id, deviceIdStr, template, status, operation_status, ...deviceObject} = device;
            try {
                await DeviceManagementService.instance().patch(`/devices/${_id}`, deviceObject);
            } catch (error) {
                devicesFailed.push(device);
            }
        }

        if(devicesFailed.length === 0){
            //all the devices are SAVED
            dispatch(displayNotification(true, ALERT_SUCCESS,`DEVICES SAVED:`, 'All the devices were saved successfully', '',true, 6000));
            if(history){
                dispatch(updateStepAddDevices(0));
                dispatch(initAddDevices());
                dispatch(cleanDevicesBatch());
                history.push(pathDeviceManager);
            }
        }else{
            //some devices arent SAVED
            let titleMessage = 'Invalid Devices';
            let bodyMessage = "Some devices cannot be saved";
            let detailsMessage = `${devicesFailed.map(device => device.title).join(', ')} ${devicesFailed.length > 1? "have an IP address already defined": "has an IP address already defined"}`;
            let updateDevicesBatch = devicesFailed.map((item, index) => ({...item, _id:index}));
            dispatch(createDevicesBatch(updateDevicesBatch));
            dispatch(displayNotification(true, ALERT_ERROR,`${titleMessage}:`, bodyMessage, detailsMessage, true));
        }
    }
}

export const addNewDevices = (listDevices, isBatchImport = false) => {
    return async (dispatch, getState) => {
        const devicesBatchCurrent = getState().deviceManager.devicesBatch;
        const mapSelected = getState().deviceManager.mapSelected;
        let devicesBatch = [];
        let errors = [];
        dispatch(displayNotification(true, ALERT_RECEIVER, `New devices: `, 'saving...', '', true, null));
        let groupName = null;
        if(isBatchImport && mapSelected && mapSelected.siteId && mapSelected.id){
            groupName = await getZoneGroupAutozone(mapSelected.siteId, mapSelected.id);
        }

        for(var i = 0; i < listDevices.length; i++){
            const device = listDevices[i];
            let {_id, template, configuration_state, status, autoZone, ...deviceObject} = device;
            deviceObject.siteName = mapSelected?.siteName || "";
            deviceObject.location = {...deviceObject.location, mapName: mapSelected?.name || ""};

            let now = new Date();
            const currentDate = new Date(now).toISOString();
            device.dateInstalled = currentDate;
            try {
                let responsePost = await DeviceManagementService.instance().post(`/devices`, deviceObject);
                // create autozones
                if(isBatchImport && mapSelected && mapSelected.siteId && mapSelected.id && autoZone && autoZone.trim() === 'yes'){
                    const antennas = [...device.rfidAntennas];
                    const bodyAutozones = [...antennas].map((antenna, j) => {
                        let antennaPosition = [antenna.location.x, antenna.location.y];
                        const shape = generateRectangle([antennaPosition]);
                        const newZone = new Zone({
                            ...NEW_AUTO_ZONE_TEMPLATE,
                            shape,
                            groupName,
                            name: `${device.ipAddress.length <= 85 ? device.ipAddress : device.ipAddress.substr(0, 35)}-${j+1}-az`,
                            color: LIGHT_BLACK_COLOR,
                            siteId: mapSelected.siteId,
                            mapId: Number(mapSelected.id),
                            mapName: mapSelected.name,
                            siteName: mapSelected.siteName,
                        });

                        return newZone.toJSON();
                    });

                    try {
                        let responseZones = await LCSMapService.instance().post(`/zones`, bodyAutozones);
                        let arrayResponses = responseZones.data.created;
                        const updateAntennas = [...antennas].map((item, index) => ({...item, autoZone: arrayResponses[index]._id}));
                        await DeviceManagementService.instance().patch(`/devices/${responsePost.data._id}`, {rfidAntennas: updateAntennas});
                    } catch (error) {
                        displayErrorFromAxios.bind(null, dispatch);
                    }
                }

                let objectMessage = {status: 'SUCCESS', text: ''};
                dispatch({type: LOAD_MESSAGES_DEVICES_BATCH, position: _id, message: objectMessage});
                let getResponse = await DeviceManagementService.instance().get(`/devices/${responsePost.data._id}`);
                devicesBatch.push(getResponse.data);
            } catch (error) {
                if (error.response.data.errors) {
                    let errorMessage = `${error.response.data.errors[0]?.title}: ${error.response.data.errors[0]?.detail}`;
                    errorMessage = errorMessage.replace('ipAddress', 'Hostname');
                    let objectMessage = {status: 'FAILED', text: errorMessage};
                    dispatch({type: LOAD_MESSAGES_DEVICES_BATCH, position: _id, message: objectMessage});
                    errors.push(`${device.title}`);
                } else {
                    let errorMessage = error.response.data?.message;
                    let objectMessage = {status: 'FAILED', text: errorMessage};
                    dispatch({type: LOAD_MESSAGES_DEVICES_BATCH, position: _id, message: objectMessage});
                    errors.push(`${device.title}`);
                }
            }
        }

        if(errors.length > 0){
            let detailsErrorMessage = `${errors.join(', ') } have errors`;
            dispatch(displayCustomErrorNotification(`Issues for import:`, `${errors.length} devices could not be saved`, detailsErrorMessage));
        }else{
            dispatch(displayNotification(true, ALERT_SUCCESS, `New devices: `, `${listDevices.length} were saved successfully`, '', true, 5000));
        }
        dispatch(createDevicesBatch([...devicesBatchCurrent, ...devicesBatch]));
    }
}

export const addDevices = (listDevices) => {
    return async (dispatch) => {
        let devicesFailed = [];
        await listDevices.reduce((previusPromise, element) => {
            let {_id, deviceId, template, configuration_state, status, ...device} = element;
            return previusPromise.then(() => {
                let now = new Date();
                const currentDate = new Date(now).toISOString();
                device.dateInstalled = currentDate;
                return DeviceManagementService.instance().post(`/devices`, device);
            }).catch(e => {
                devicesFailed.push(element);
                return Promise.resolve();
            })
        }, Promise.resolve());

        if(devicesFailed.length === 0){
            //all the devices are SAVED
            dispatch(displayNotification(true, ALERT_SUCCESS,`NEW DEVICES ARE SAVED:`, 'All the devices were saved successfully', '',true, 6000));
            return true;
        }else{
            //some devices arent SAVED
            let titleMessage = 'Invalid Devices';
            let bodyMessage = "Some devices cannot be saved";
            let detailsMessage = `${devicesFailed.map(device => device.title).join(', ')} ${devicesFailed.length > 1? "have an IP address already defined": "has an IP address already defined"}`;
            let updateDevicesBatch = devicesFailed.map((item, index) => ({...item, _id:index}));
            dispatch(createDevicesBatch(updateDevicesBatch));
            dispatch(displayNotification(true, ALERT_ERROR,`${titleMessage}:`, bodyMessage, detailsMessage, true));
            return false;
        }
    }
}

const getZoneGroupAutozone = async (siteId, mapId) => {
    const groupName = siteId + '-' + mapId + '-autozoneGroup';
    const groupsResponse = await getZoneGroups(siteId);
    const groups = groupsResponse.data;
    for (let index = 0; index < groups.length; index++) {
        const group = groups[index];
        if (group?.groupName === groupName) {
            return group.groupName;
        }
    }
    return groupName;
};

export const loadCoordinatesOfZonesOnMap = (siteId, mapId, deviceIds = null) => {
    return async (dispatch, getState) => {
        const selectedRows = getState().deviceManager.selectedRows || {};
        const listDeviceIds = deviceIds? deviceIds: Object.keys(selectedRows);
        const devicesAndAntennas = await getAntennasOnMap(siteId, mapId, true);
        const zonesWithAntennas = getZonesWithAntennas(devicesAndAntennas, listDeviceIds);
        dispatch(loadOtherAntennasInZones(zonesWithAntennas));
    }
}

export const loadOtherAntennasInZones = (zonesWithAntennas) => ({type: LOAD_ZONES_ANTENNAS, zonesWithAntennas: zonesWithAntennas})

export const loadEditDevice = (deviceId, history) => {
    return async (dispatch) => {
        dispatch({type:SET_FETCHING, isFetching: true});
        try {
            const response = await DeviceManagementService.instance().get(`/devices/${deviceId}`);
            if(response.data && response.data !== ""){
                let device = response.data;
                dispatch(createDevicesBatch([device]));
                dispatch(loadDeviceManagerScreen(EDIT_DEVICES_SCREEN));
                if(device.siteId !== ""){
                    const mapIdSelected = device.location.mapID !== ""? device.location.mapID: null;
                    dispatch(loadSiteAndMap(device.siteId, mapIdSelected));
                }
            }else{
                const err = { message: `Device ${deviceId} not found`};
                displayErrorFromAxios(dispatch, err , {ignore403:true});
                history.replace('/device-manager/devices');
            }
        } catch (error) {
            displayErrorFromAxios.bind(null, dispatch);
        }
        dispatch({type:SET_FETCHING, isFetching: false});
    }
}

export const loadEditMultipleDevices = () => {
    return async (dispatch, getState) => {
        dispatch({type:SET_FETCHING, isFetching: true});
        const currentSelectedRows = getState().deviceManager.selectedRows;
        const deviceIds = Object.keys(currentSelectedRows);
        const query = `?_id=${deviceIds.join('&_id=')}`;
        try {
            const response = await DeviceManagementService.instance().get(`/devices${query}`);
            dispatch(createDevicesBatch(response.data));
        } catch (error) {
            dispatch({type:SET_FETCHING, isFetching: false});
        }

        dispatch(loadDeviceManagerScreen(EDIT_DEVICES_SCREEN));
        dispatch({type:SET_FETCHING, isFetching: false});
    }
}



export const getZonesWithAntennas = (devicesAndAntennas, devicesIds = []) => {
    let zonesAntennaCoordinates = {};
    const devicesList = devicesIds.length === 0?devicesAndAntennas:devicesAndAntennas.filter(item => devicesIds.indexOf(item._id) === -1);
    for(var i = 0; i < devicesList.length; i++){
        const antennas = devicesList[i].rfidAntennas;
        for(var j = 0; j < antennas.length; j++){
            const antenna = antennas[j];
            if(antenna.autoZone){
                if(zonesAntennaCoordinates[antenna.autoZone]){
                    zonesAntennaCoordinates[antenna.autoZone] = [...zonesAntennaCoordinates[antenna.autoZone], [antenna.location.x, antenna.location.y]];
                }else{
                    zonesAntennaCoordinates[antenna.autoZone] = [[antenna.location.x, antenna.location.y]];
                }
            }
        }
    }

    return zonesAntennaCoordinates;
}

export const loadModalEditDevice = (deviceId) => {
    return async (dispatch, getState) => {
        const deviceManagerScreen = getState().deviceManager.deviceManagerScreen;
        const currentDevice = getState().deviceManager.devicesBatch.find(item => item._id === deviceId);

        try{
            const responseDevice = await DeviceManagementService.instance().get(`/devices/${deviceId}`);
            const templateId = responseDevice.data.templateId;
            let template = "";
            let templateHeader = {};

            if(templateId && templateId !== ""){
                const includeFields = 'title,deviceType,description,createDate,createBy';
                const responseTemplate = await DeviceManagementService.instance().get(`/devices/templates/${templateId}?includeFields=${includeFields}`);
                template = responseTemplate.data.title;
                templateHeader = responseTemplate.data;
            }

            //building the device
            let device = {...responseDevice.data, siteId:currentDevice.siteId, siteName: currentDevice.siteName, location: currentDevice.location, rfidAntennas: currentDevice.rfidAntennas, template: template};

            if(deviceManagerScreen === EDIT_DEVICES_SCREEN){
                dispatch(loadFormDevice(device, templateHeader, EDIT_DEVICE_SAVED));
            }else{
                dispatch(loadFormDevice(device, templateHeader, EDIT_DEVICE_MANUALLY_ADDED));
            }

            dispatch(changeStatusModalEditDevices(true));

        } catch (error) {
            displayErrorFromAxios.bind(null, dispatch);
        }
    }
};

export const editDevice = (deviceId) => {
    return async (dispatch, getState) => {
        dispatch({type:SET_FETCHING, isFetching: true});
        try {
            const response = await DeviceManagementService.instance().get(`/devices/${deviceId}`);
            if(typeof(response.data) === 'object'){
                let templateId = response.data.templateId;
                let template = "";
                let templateHeader = {};
    
                //getting template title
                if(templateId && templateId !== ""){
                    try {
                        const includeFields = 'title,deviceType,description,createDate,createBy';
                        const response1 = await DeviceManagementService.instance().get(`/devices/templates/${templateId}?includeFields=${includeFields}`);
                        template = response1.data.title;
                        templateHeader = response1.data;
                    } catch (error) {
                        displayErrorFromAxios.bind(null, dispatch);
                    }
                }
    
                //building the device
                let device = {...response.data, template: template};
                dispatch(loadFormDevice(device, templateHeader, EDIT_DEVICE_SAVED));
    
                //loading zones
                const siteId = device.siteId;
                const zones = getState().deviceManager.zones[siteId];
                if(zones.length === 0){
                    getZones(siteId);
                }
            }else{
                const err = { message: `Device ${deviceId} not found`};
                //EDIT_DEVICE_ERROR
                dispatch(loadFormDevice({}, {}, EDIT_DEVICE_ERROR));
                displayErrorFromAxios(dispatch, err , {ignore403:true});
            }
        } catch (error) {
            displayErrorFromAxios.bind(null, dispatch);
        }
        dispatch({type:SET_FETCHING, isFetching: false});
    }
}

export const deleteDevice = (device) => {
    return async (dispatch, getState) => {
        const selectedFilters = getState().deviceManager.selectedFilters.devices;
        const deviceList = getState().deviceManager.deviceList;
        try {
            await DeviceManagementService.instance().delete(`/devices/${device._id}`);
            if(device.siteId !== "" && device.location.mapID !== ""){
                const siteId = device.siteId;
                const mapId = device.location.mapID;
                await deleteZonesBySiteAndMap(siteId, mapId);
            }
            let newSelectedFilters = cleanFilters(device, selectedFilters, deviceList)

            dispatch({type:UPDATE_SELECTED_FILTERS, table:"devices", filters: newSelectedFilters });

            dispatch(displayNotification(true, ALERT_SUCCESS, `${device.title}:`, 'Device has been deleted', '', true, 2000));
            dispatch(loadDeviceList());
            dispatch(reloadSitesMaps(null, null,true));
        } catch(e) {
            // the arror message appears more than 90 seconds
            dispatch(displayNotification(true, ALERT_ERROR,`${device.title}:`, 'Device cannot be deleted', 'Error Details   ', true));
        };
    }
}

export const cleanFilters = (device, selectedFilters, loadList) => {
    let keys = [...new Set(selectedFilters.map(item => Object.keys(item)[0]))];
    let objectFilter = {};
    for(let index = 0; index < keys.length; index++){
        objectFilter[keys[index]] = [];
        selectedFilters.forEach(item => {
            if(item[keys[index]]) {
                objectFilter[keys[index]].push(item[keys[index]])
            }
        })
    }

    const objRemove = {};
    for(let key in objectFilter){
        let index = objectFilter[key].indexOf(device[key]);
        if(index > -1){
            objRemove[key] = {value: device[key], index: index}
        }
    }


    for(let key in objRemove){
        let count = 0;
        for(let indx = 0; indx < loadList.length; indx++) {
            if (loadList[indx]._id !== device._id && objRemove[key].value === loadList[indx][key]){
                count++;
            }
        }

        if(count > 0){
            delete objRemove[key];
        }
    }

    for(let key in objRemove){
        objectFilter[key].splice(objRemove[key].index, 1);
    }

    let newSelectedFilters = [];

    for(let key in objectFilter){
        objectFilter[key].forEach(item => {
            newSelectedFilters.push({[key]:item})
        })
    }

    return newSelectedFilters;
};

export const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

//---------------------------Select Maps screen--------------------------------

export const loadDeviceManagerScreen = (deviceManagerScreen) => ({type: LOAD_DEVICE_MANAGER_SCREEN, deviceManagerScreen})

export const loadSuggestions = () => {
    return dispatch => {
        let suggestions = [];


        SiteViewService.instance().get(MAP_VIEW_URL + "/sites/", {
            params: {},
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(responseSites => {
                for(let i=0; i < responseSites.data.length; i++){
                    suggestions.push({name: responseSites.data[i].name, value: responseSites.data[i]._id, isSite: true});
                }

            }).catch(displayErrorFromAxios.bind(null, dispatch));

        SiteViewService.instance().get(MAP_VIEW_URL+"/maps")
            .then(responseMaps => {
                for(let i=0; i < responseMaps.data.length; i++){
                    suggestions.push({name: responseMaps.data[i].description, value: responseMaps.data[i].siteId, isSite: false});
                }

                dispatch({type: LOAD_SUGGESTIONS, suggestions: suggestions});
            }).catch(displayErrorFromAxios.bind(null, dispatch));

    }
};

export const unloadSuggestions = () => ({type: LOAD_SUGGESTIONS, suggestions: []});

export const searchData = (value) => {

    return (dispatch, getState) => {
        const state = getState();

        let suggestions = state.deviceManager.suggestions || [];

        const inputValue = value.toLowerCase();
        const inputLength = inputValue.length;

        const results = inputLength === 0 ? [] : suggestions.filter(suggestion =>
            suggestion.name.toLowerCase().slice(0, inputLength) === inputValue
        );

        dispatch({type: FILTER_RESULTS, results:results});

    };
};

const loadSiteMaps = (sites, maps,countDevices) => ({type: LIST_SITES, sites: sites, maps: maps,countDevices:countDevices});

const getDevicesByMap = async () => {
    // const response1 = await DeviceManagementService.instance().get(`/devices?includeFields=siteId,location`);
    let countDevicesByMap={};
    try {
        const devicesResponse = await DeviceManagementService.instance().get('/devices?includeFields=siteId,location');
        if(devicesResponse!=null&&devicesResponse.data!=null){
            devicesResponse.data.forEach(device=>{
                try{
                    const siteId=device.siteId;
                    const mapId=device.location.mapID;

                    if(!countDevicesByMap.hasOwnProperty(siteId))
                        countDevicesByMap[siteId]={};

                    if(!countDevicesByMap[siteId].hasOwnProperty(mapId))
                        countDevicesByMap[siteId][mapId]=0;

                    countDevicesByMap[siteId][mapId]++;
                    }catch (error) {
                }
            });
        }
    }
    catch (e) {

    }
    return countDevicesByMap;
}

export const getDevicesBySites = async () => {
    let devicesBySites={};
    try {
        const devicesResponse = await DeviceManagementService.instance().get('/devices?includeFields=siteId,location');
        if(devicesResponse!=null&&devicesResponse.data!=null){
            devicesResponse.data.forEach(device=>{
                try{
                    const siteId = device.siteId;
                    const mapId = device.location.mapID;
                    const id = device._id;
                    //devicesBySites[id]={id,siteId,mapId};
                    devicesBySites[id]={siteId,mapId};
                }catch (error) { }
            });
        }
    }
    catch (e) { }
    return devicesBySites;
}
export const getDevicesSelect = async (deviceId) => {
    let deviceSelect={};
    try {
        const fields=["operation_status","status","firmwareVersion","rfidAntennas","alert","health","ipAddress","configuration_state","siteId","location.mapID","title","macAddress","currentMode","siteName","location.mapName","protocol"];
        const devicesResponse = await DeviceManagementService.instance().get(`/devices/${deviceId}/?fields=${fields.join(",")}`);
        if(devicesResponse!=null&&devicesResponse.data!=null){
            const device = devicesResponse.data;
            const siteId = device.siteId;
            const mapId = device.location.mapID;
            const id = device._id;
            deviceSelect = {id,mapId,siteId};
        }
    }
    catch (e) {
        console.log({e})
    }
    return deviceSelect;
}
const compareStrings = (mainStr, comparer) => {
    const compSize = comparer.length;
    return mainStr.substring(0, compSize).toLowerCase() === comparer.toLowerCase();
}

const shouldNotFilterSiteAndMaps = (search, map, sites = []) => {
    if (search) {
        if (sites.length) {
            // if the site is filtered, all inner maps must be shown
            return sites.some(site => site.id === map.siteId);
        } else {
            return compareStrings(map.description, search);
        }
    }
    return true;
}

export const reloadSitesMaps = (groupId, selectedData,loadListSuggestions, searchFilter) => {
    return async (dispatch, getState) => {
        const recentMaps = getState().deviceManager.recentMaps;
        const mapSelected = getState().deviceManager.mapSelected;

        let suggestions = [];
        const timestamp = (new Date()).getTime();

        dispatch({type:SET_FETCHING, isFetching: true});
        dispatch({type: SET_SELECTED_GROUP, groupId: groupId});
        let sites = [];
        let maps = [];
        try {
            const responseSites = await SiteViewService.instance().get(MAP_VIEW_URL+"/sites/", EMPTY_REQ_PARAMS);
            if(responseSites.data) {
                sites = responseSites.data;
                if (loadListSuggestions) {
                    for (let i = 0; i < responseSites.data.length; i++) {
                        suggestions.push({
                            name: responseSites.data[i].name,
                            value: responseSites.data[i]._id,
                            isSite: true
                        });
                    }
                }

                const responseMaps = await SiteViewService.instance().get(MAP_VIEW_URL + "/maps");

                if(responseMaps.data) {

                    maps = responseMaps.data;
                    const responseAppliances = await ICService.instance().get("/appliances/status", EMPTY_REQ_PARAMS);
                    const appliances = responseAppliances.data;

                    if (loadListSuggestions) {
                        for (let i = 0; i < responseMaps.data.length; i++) {
                            suggestions.push({
                                name: responseMaps.data[i].description,
                                value: responseMaps.data[i].siteId,
                                isSite: false
                            });
                        }
                    }

                    let sitesArray = [];
                    let sitesObj = {};
                    for (let i = 0; i < responseSites.data.length; i++) {
                        if (!selectedData) {
                            if (!groupId || groupId === responseSites.data[i].groupMembership[0]) {
                                const applianceObj = appliances.filter(a => a.siteId === responseSites.data[i]._id)[0];
                                sitesArray.push({
                                    id: responseSites.data[i]._id,
                                    name: responseSites.data[i].name,
                                    applianceId: applianceObj && applianceObj._id
                                });
                                sitesObj[responseSites.data[i]._id] = responseSites.data[i].name
                            }
                        } else {
                            if (responseSites.data[i]._id === selectedData.value) {
                                sitesArray.push({
                                    id: responseSites.data[i]._id,
                                    name: responseSites.data[i].name
                                });
                                sitesObj[responseSites.data[i]._id] = responseSites.data[i].name;
                                break;
                            }
                        }
                    }

                    let countDevicesAllMaps = 0;
                    getDevicesByMap().then(countDevicesByMap => {
                        const mapsArray = [];
                        const filteredSites = searchFilter && sitesArray.filter(site => compareStrings(site.name, searchFilter));

                        responseMaps.data.forEach(map => {

                            // cancel the loop if filter search doesn't match
                            const shouldNotFilter = shouldNotFilterSiteAndMaps(searchFilter, map, filteredSites);
                            if (!shouldNotFilter) {
                                return;
                            }

                            let countDevices = 0;
                            if (
                                countDevicesByMap.hasOwnProperty(map.siteId) &&
                                countDevicesByMap[map.siteId].hasOwnProperty(map._id)
                            ) {
                                countDevices = countDevicesByMap[map.siteId][map._id];
                            }

                            recentMaps && recentMaps.forEach(item => {
                                if (item.id === map._id) {
                                    item.countDevices = countDevices;
                                    if (mapSelected && mapSelected.id === map._id) {
                                        mapSelected.countDevices = countDevices;
                                        dispatch({type: UPDATE_MAP_SELECTED, mapSelected: mapSelected})
                                    }
                                }
                                // return item
                            });

                            countDevicesAllMaps += countDevices;

                            const newMaps = {
                                name: map.description,
                                id: map._id,
                                src: SITE_VIEW_HOST + MAP_VIEW_URL + "/maps/" + map._id + "/tiles/0_0_0?timestamp=" + timestamp + "&jwt=" + getToken(),
                                siteId: map.siteId,
                                siteName: sitesObj[map.siteId],
                                countDevices: countDevices,
                                // lastEdited: "2020-02-06",
                                showSiteName: false,
                                selected: false,
                                isConfiguredCoordinates: map.hasOwnProperty('coordinates')
                                // isConfiguredCoordinates: true
                            }

                            mapsArray.push(newMaps);
                        });

                        let mapsObj = {};
                        for (let i = 0; i < sitesArray.length; i++) {
                            let maps = mapsArray.filter(map => map.siteId === sitesArray[i].id);
                            if (maps.length > 0) {
                                if (selectedData && !selectedData.isSite) {
                                    if (selectedData.value === sitesArray[i].id) {
                                        mapsObj[sitesArray[i].id] = maps.filter(map => selectedData.name === map.name);
                                        break;
                                    }
                                } else {
                                    mapsObj[sitesArray[i].id] = maps;
                                }
                            }
                        }
                        if (loadListSuggestions) {
                            dispatch({type: LOAD_SUGGESTIONS, suggestions: suggestions});
                        }
                        dispatch(loadSiteMaps(sitesArray, mapsObj, countDevicesAllMaps));

                        dispatch(recentMapsAction());
                    }).finally(() => {
                        dispatch({type: SET_FETCHING, isFetching: false});
                    });
                }
            }

        } catch (e) {
            displayErrorFromAxios.bind(null,dispatch)
        } finally {
            dispatch({type:SET_FETCHING, isFetching: false});
            dispatch(verifyChangesOnSiteMap(sites, maps));
        }
    }
};

export const unloadSiteMaps = () =>{
    return dispatch => {
        dispatch({type: LOAD_SUGGESTIONS, suggestions: []});
        dispatch({type: LIST_SITES, sites: [], maps: {}});
    }
};

export const recentMapsAction = () => {
    return (dispatch, getState) => {
        const recentMaps = getState().deviceManager.recentMaps;
        const maps = getState().deviceManager.maps;
        if(maps){
            let keysArray = Object.keys(maps);
            let recentMapsUpdated=[];
    
            for(let i=0; i < recentMaps.length;i++){
                for(let j=0; j<keysArray.length; j++){
                    if(recentMaps[i].siteId===keysArray[j]){
                        let item = maps[keysArray[j]];
                        for(let k=0; k<item.length; k++){
                            if(recentMaps[i].id===item[k].id){
                                recentMapsUpdated.push(recentMaps[i]);
                            }
                        }
                    }
                }
            }
    
            dispatch({type: RECENT_MAPS, recentMapUpdated:recentMapsUpdated});
        }
    }
}

export const selectDevicesMap = (mapSelected) => {
    return dispatch => {
        dispatch({type:MAP_SELECTED, mapSelected: mapSelected});
        dispatch({type:RECENT_MAPS, mapSelected: mapSelected});
        //reloading the device list
        dispatch(resetPaginationInfo(true))
    }
};

export const selectDevicesSite = (mapSelected) => {
    return dispatch => {
        dispatch({type:MAP_SELECTED, mapSelected: mapSelected});
        dispatch(resetPaginationInfo(true))
    }
};

export const changeStatusDetails = (status) => ({type:SHOW_DETAILS, showDetails: status});
export const changeStateShowMaps = (showMaps) => ({type:SHOW_MAPS, showMaps: showMaps});

export const listGroups = () => {
    return async (dispatch) => {
        try {
            const siteGroupsResponse = await ICService.instance().get("/sitegroups/", {
                params: {},
                headers: {
                    'Content-Type': 'application/json',
                    'Pragma': 'no-cache'
                }
            });
            if(siteGroupsResponse.data){
                dispatch({type: LIST_SITES_GROUPS, data: siteGroupsResponse.data});
            }
        } catch (error) {
            displayErrorFromAxios.bind(null, dispatch);
        }
    }
};

export const unloadGroups = () => ({type: LIST_SITES_GROUPS, data: []});

export const changeMapView = (mapView) => ({type: CHANGE_MAP_VIEW, mapView});

//-----------------------End Select Maps screen--------------------------------
export const cleanFormTemplate = () => ({type:CLEAN_FORM_TEMPLATE});

export const initValidatedFields = () => {
    return (dispatch, getState)=>{
        const needsValidationFields = Object.assign({},getState().deviceManager.needsValidationFields);
        Object.keys(needsValidationFields).forEach(sectionKey=>{
            const section=needsValidationFields[sectionKey];
            Object.keys(section).forEach(field=>{
                const validationInfo=section[field];
                validationInfo.error=false;
            });
        });
        dispatch({type: UPDATE_VALIDATED_FIELDS, successfulValidatedFields: true, needsValidationFields:needsValidationFields, focusedSection:null});
    }
}

export const detectSiteChanges = (isSiteChanging) => ({type: DETECT_SITE_CHANGES, isSiteChanging: isSiteChanging});

export const validateFields = () => {
    return async (dispatch, getState)=>{
        const templateForm = getState().deviceManager.templateForm.template;
        const templateFormLoaded = getState().deviceManager.templateFormLoaded;
        const needsValidationFields = getState().deviceManager.needsValidationFields;
        const validateResults=validateDevice(templateForm,templateFormLoaded,needsValidationFields);
        const isSiteChanging = await dispatch(getIsSiteChanging(templateForm));
        dispatch({type: UPDATE_VALIDATED_FIELDS, successfulValidatedFields: !validateResults.error, needsValidationFields:validateResults.needsValidationFields, focusedSection:validateResults.focusedSection,isSiteChanging});
        dispatch(detectSiteChanges(isSiteChanging));
        return !validateResults.error;
    }
}

export const validateFieldsDevice = () => {
    return async (dispatch, getState) => {
        dispatch({type: CLEAN_NOTIFICATION});
        const successful = await dispatch(validateFields());
        if (successful === false) {
            dispatch(displayCustomErrorNotification("The device cannot be saved.", 'Please make sure fix all errors first', null));
        }
        return successful;
    }
}

export const validateFieldsTemplate = () => {
    return async (dispatch, getState) => {
        dispatch({type: CLEAN_NOTIFICATION});
        const successful = await dispatch(validateFields());
        if (successful === false) {
            dispatch(displayCustomErrorNotification("The template cannot be saved.", 'Please make sure fix all errors first', null));
        }
        return successful;
    }
}

const getIsSiteChanging = (templateForm) =>{
    return async () => {
        const {siteId,_id:deviceId} = templateForm;
        if(siteId){
            return await getDevicesSelect(deviceId).then(deviceSelect =>{
                return siteId !== deviceSelect.siteId;
            }).catch(error =>{
                return false;
            });
        }else{
            return false;
        }
    }
}

export const applySelectedTemplate = (templateObject) => {
    return async (dispatch, getState) => {
        const deviceId = getState().deviceManager.templateForm.template._id;

        if(templateObject){
            const oldTemplateId = getState().deviceManager.templateForm.template.templateId;
            const showModalEditDevice = getState().deviceManager.showModalEditDevice;
            let rfidAntennasDev = [];

            try {
                const response = await DeviceManagementService.instance().get(`/devices/${deviceId}`);

                //Added a routine to change the antennas disabled value according the values of template but keeping the name and coordinates values
    
                if(templateObject.template.rfidAntennas.length <= response.data.rfidAntennas.length) {
                    rfidAntennasDev = templateObject.template.rfidAntennas.map((m, index) => {
                        const idAntenna = response.data.rfidAntennas[index].id+"";
                        return {...response.data.rfidAntennas[index], id: idAntenna, disabled: m.disabled}
                    });
                } else {
                    rfidAntennasDev = templateObject.template.rfidAntennas.map((m, index) => {
                        if(response.data.rfidAntennas[index]) {
                            const idAntenna = response.data.rfidAntennas[index].id+"";
                            return {...response.data.rfidAntennas[index], id: idAntenna, disabled: templateObject.template.rfidAntennas[index].disabled};
                        } else {
                            const idAntenna = m.id + "";
                            return {...m, id: idAntenna};
                        }
                    });
                }

                dispatch({type:SET_ADDED_TEMPLATE, addedTemplate: (oldTemplateId !== templateObject._id)})
                dispatch({type:APPLY_TEMPLATE, template:templateObject, rfidAntennas: rfidAntennasDev});
                //showing notification
                if(showModalEditDevice){
                    dispatch(displayDialogNotification(ALERT_WARNING, templateObject.title, 'has been applied successfully to your device'));
                }else{
                    dispatch(displayNotification(true, ALERT_WARNING, templateObject.title, 'has been applied successfully to your device','',false,5000));
                }

            } catch (error) {
                displayErrorFromAxios.bind(null, dispatch);
            }
        }else{
            const response = await DeviceManagementService.instance().get(`/devices/${deviceId}`);
            let template = "";
            let templateId = "";
            let templateHeader = {};
            //building the device
            let device = {...response.data, template: template, templateId: templateId};
            dispatch(loadFormDevice(device, templateHeader, EDIT_DEVICE_SAVED));
        }
    }
};

export const loadFormTemplate = (nameForm, templateId = null) =>{
    return async (dispatch) => {
        if(templateId){
            dispatch({type:SET_FETCHING, isFetching: true});
            try {
                const response = await DeviceManagementService.instance().get(`/devices/templates/${templateId}`);

                if(typeof(response.data) === 'object'){
                    dispatch(loadDeviceManagerScreen(EDIT_TEMPLATE_SCREEN));
                    dispatch({type:LOAD_FORM_TEMPLATE, template: response.data, nameForm: nameForm});
                    //getting linked devices for a template 
                    dispatch(loadLinkedDevices(templateId));
                }else{
                    const err = { message: `Template ${templateId} not found`};
                    displayErrorFromAxios(dispatch, err , {ignore403:true});

                    dispatch(loadDeviceManagerScreen(EDIT_TEMPLATE_ERROR_SCREEN));
                    dispatch({type:LOAD_FORM_TEMPLATE, template: null, nameForm: nameForm});
                }
            } catch (error) {
                displayErrorFromAxios.bind(null, dispatch);
            }
            dispatch({type:SET_FETCHING, isFetching: false});
        }else{
            dispatch(loadDeviceManagerScreen(ADD_TEMPLATE_SCREEN));
            dispatch({type:LOAD_FORM_TEMPLATE, template: null, nameForm: nameForm});
        }
    }
}

export const loadLinkedDevices = (templateId) => {
    return async dispatch => {
        let includeFields = 'deviceType,rfidType,title,ipAddress,rfidAntennas,configuration_state,status';
        let response = await DeviceManagementService.instance().get(`/devices?templateId=${templateId}&includeFields=${includeFields}`);
        const linkedDevices = response.data;
        //loading selected linked devices
        let selectedItems = linkedDevices.reduce((acc, item, index) => {
            acc[index] = item._id;
            return acc;
        }, {});

        dispatch({type: LOAD_LINKED_DEVICES, linkedDevices: linkedDevices});
        dispatch({type: UPDATE_LINKED_SELECTED_ROWS, linkedSelectedRows: selectedItems});
    }
}

export const cleanLinkedDevices = () => ({type: CLEAN_LINKED_DEVICES});
export const selectAllLinkedDevices = () => {
    return (dispatch, getState) => {
        const linkedSelectedRows = getState().deviceManager.linkedSelectedRows;
        const linkedDevices = getState().deviceManager.linkedDevices;

        if(Object.keys(linkedSelectedRows).length === 0){
            //loading selected linked devices
            let selectedItems = linkedDevices.reduce((acc, item, index) => {
                acc[index] = item._id;
                return acc;
            }, {});
            dispatch({type: UPDATE_LINKED_SELECTED_ROWS, linkedSelectedRows: selectedItems});
        }else{
            dispatch({type: UPDATE_LINKED_SELECTED_ROWS, linkedSelectedRows: {}});
        }
    }
}

export const clickRowLinkedDevice = (rowIndex) => {
    return (dispatch, getState) => {
        const currentlinkedDevice = getState().deviceManager.linkedDevices[rowIndex];
        const linkedSelectedRows = getState().deviceManager.linkedSelectedRows;

        const linkedRowsUpdated = Object.assign({}, linkedSelectedRows);
        if(linkedRowsUpdated.hasOwnProperty(rowIndex)){
            delete linkedRowsUpdated[rowIndex];
        }
        else {
            linkedRowsUpdated[rowIndex] = currentlinkedDevice._id;
        }
        dispatch({type: UPDATE_LINKED_SELECTED_ROWS, linkedSelectedRows: linkedRowsUpdated});
    }
}

export const getSpecificTemplate = (templateId) => {
    return async dispatch => {
        dispatch({type:SET_FETCHING_DETAILS, isFetchingDetails: true});

        try {
            const response = await DeviceManagementService.instance().get(`/devices/templates/${templateId}`);
            dispatch({type: LOAD_TEMPLATE_DETAILS_DATA, detailsTemplate: response.data})
        } catch (error) {
            displayErrorFromAxios.bind(null, dispatch);
        }
        
        dispatch({type:SET_FETCHING_DETAILS, isFetchingDetails: false});
    }
}

export const loadFormDevice = (device, templateHeader, nameForm) => ({type:LOAD_FORM_DEVICE, device: device, templateHeader: templateHeader, nameForm: nameForm});

export const updateMultipleDevices = (history) => {
    return async (dispatch, getState) => {
        const devicesBatch = getState().deviceManager.devicesBatch;
        const zones = getState().deviceManager.zones;

        dispatch({type:SET_FETCHING, isFetching: true});

        // SAVING AUTOZONES
        let {objZones, objDevices} = buildAutozonesMultipleObjects(devicesBatch, zones);
        let responseAutoZones = await saveAutozonesV2(objZones, Object.keys(objDevices), dispatch);

        if(responseAutoZones){
            // SAVING DEVICES
            for(var i = 0; i < devicesBatch.length; i++){
                const {_id, siteId, siteName, location, rfidAntennas} = devicesBatch[i];
                const antennas = rfidAntennas.map((antenna, index) => {
                    if(!uuidValidate(antenna.autoZone)){
                        return {...antenna};
                    }

                    let antennaZone = null;
                    let autoZoneUID = objDevices[_id][index];
                    if(autoZoneUID){
                        antennaZone = responseAutoZones[autoZoneUID];
                    }
                    return {...antenna, autoZone: antennaZone}
                });

                try {
                    await DeviceManagementService.instance().patch(`/devices/${_id}`, {siteId: siteId, siteName: siteName, location: location, rfidAntennas: antennas});
                } catch (error) {
                    let titleMessage = error.response ? error.response.statusText : "Error";
                    let bodyMessage = "Error";
                    let detailsMessage = "Error saving the device";
                    if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
                        bodyMessage = "Error";
                        detailsMessage = error.response.data.errors[0].detail;
                    }
                    if (error.response && error.response.config && error.response.config.url && error.response.config.url.indexOf('zone-data') >= 0) {
                        bodyMessage = "Error creating auto zone";
                        detailsMessage = "An error occurred with auto zone creation.";
                    }
                    dispatch(displayNotification(true, ALERT_ERROR,`${titleMessage}:`, bodyMessage, detailsMessage, true));
                }
            }
        }

        await deleteZonesWithoutAntennas(objZones);
        dispatch(selectAll([]));
        dispatch({type:SET_FETCHING, isFetching: false});
        history.push(pathDeviceManager);
    }
}

export const buildAutozonesMultipleObjects = (devicesBatch, zones) => {
    const NO_ZONE = 'No Zone';
    let objectZones = {};
    let deviceAutoZones = {};
    for(var i = 0; i < devicesBatch.length; i++){
        const device = devicesBatch[i];
        let deviceId = device._id;
        let siteId = device.siteId;
        let mapId = device.location.mapID;
        let siteName = device.siteName;
        let mapName = device.location.mapName;

        if(!Boolean(siteId) || siteId==="" || !Boolean(mapId) || mapId===""){
            continue;
        }

        //site level
        if(!objectZones[siteId]){
            objectZones[siteId] = {}
        }

        //map level
        if(!objectZones[siteId][mapId]){
            objectZones = {...objectZones, [siteId]: {...objectZones[siteId], [mapId]:{}}};
        }

        deviceAutoZones[deviceId] ={};

        for(var j = 0; j < device.rfidAntennas.length; j++){
            const antenna = device.rfidAntennas[j];
            if(antenna.autoZone && antenna.autoZone !== NO_ZONE){
                let antennaPosition = [antenna.location.x, antenna.location.y];

                // saving temporaly auto zones in devices
                deviceAutoZones[deviceId] = {...deviceAutoZones[deviceId], [`${j}`]: antenna.autoZone};

                // building zones object
                if(objectZones[siteId][mapId][antenna.autoZone]){
                    objectZones[siteId][mapId][antenna.autoZone].antennas = [...objectZones[siteId][mapId][antenna.autoZone].antennas, antennaPosition];
                }else{
                    const zoneSelected = zones[mapId]?.find(zone => zone._id === antenna.autoZone);
                    let objectZone = {};
                    objectZone.title = zoneSelected ? zoneSelected.name: antenna.autoZone;
                    objectZone.antennas = [antennaPosition];
                    objectZone.siteName = siteName;
                    objectZone.mapName = mapName;
                    objectZones[siteId][mapId][antenna.autoZone] = objectZone;
                }
            }
        }
    }
    
    return {objZones: objectZones, objDevices: deviceAutoZones};
}


export const deleteZonesWithoutAntennas =  async (objZones) => {
    for(const siteId in objZones){
        for(const mapId in objZones[siteId]){
            await deleteZonesBySiteAndMap(siteId, mapId);
        }
    }
}

export const getZonesByMap = async (mapId) => {
    try {
        const responseZonesOnMap = await LCSMapService.instance().get(`/zones?mapId=${Number(mapId)}`);
        return responseZonesOnMap.data;
    }catch(error){
        console.log('Error getZonesByMap: ', error.response);
        return [];
    }
}

export const getZonesBySite = async (siteId) => {
    try {
        const responseZonesOnSite = await LCSMapService.instance().get(`/zones?siteId=${siteId}`);
        return responseZonesOnSite.data;
    }catch(error){
        console.log('Error getZonesBySite: ', error.response);
        return [];
    }
}

export const deleteZonesBySiteAndMap = async (siteId, mapId) => {
    const devicesAndAntennas = await getAntennasOnMap(siteId, mapId);
    const zonesWithAntennas = getZonesWithAntennas(devicesAndAntennas);
    const responseZonesOnMap = await getZonesByMap(mapId);
    const zonesOnMap = responseZonesOnMap.reduce((acc, item) => item.priority === -1?[...acc, item._id]:acc, []);
    const zoneIdsWithoutAntennas = zonesOnMap.filter(zoneId => Object.keys(zonesWithAntennas).indexOf(zoneId) === -1);

    if(zoneIdsWithoutAntennas.length > 0){
        try {
            // deleting zones
            await LCSMapService.instance().put(`/zones?batch=true&mapId=${Number(mapId)}`, {zonesDeleted: zoneIdsWithoutAntennas});
        } catch (error) {
            console.log('error DELETE: ', error.response);
        }
    }
}


export const saveAutozonesV2 = async (objZones, deviceIds, dispatch) => {
    let objectTitles = {};
    let zonesAdded = [];

    for(const siteId in objZones){
        const responseZonesOnSite = await getZonesBySite(siteId);
        for(const mapId in objZones[siteId]){
            const groupName = await getZoneGroupAutozone(siteId, mapId);
            // for getting antennas of other devices on map
            const devicesOnMap = await getAntennasOnMap(siteId, mapId);
            const otherDevices = devicesOnMap.filter(d => deviceIds.indexOf(d._id) === -1);
            let zonesUpdated = [];

            for(const keyZone in objZones[siteId][mapId]){
                const zone = objZones[siteId][mapId][keyZone];
                //checking if exists the zone name
                const existZone = responseZonesOnSite.find(z => z.mapId === Number(mapId) && z.name === zone.title);
                // if(uuidValidate(keyZone) && !existZone){
                if(!existZone){
                    // create new zone
                    const shape = generateRectangle(zone.antennas);
                    const zonePayload = new Zone({
                        ...NEW_AUTO_ZONE_TEMPLATE,
                        shape,
                        siteId,
                        groupName,
                        name: zone.title,
                        mapId: Number(mapId),
                        color: LIGHT_BLACK_COLOR,
                        mapName: zone.mapName,
                        siteName: zone.siteName
                    });

                    zonesAdded.push(zonePayload.toJSON());
                    objectTitles[zone.title] = keyZone;
                }else{
                    // update zone
                    const otherAntennasCoordinates = getOtherAntennasCoordinates(otherDevices, zone);
                    const zoneCoordinates = [...zone.antennas, ...otherAntennasCoordinates];
                    const shape = generateRectangle(zoneCoordinates);
                    const zonePayload = new Zone({
                        ...existZone,
                        shape,
                        siteId,
                        groupName,
                        name: zone.title,
                        mapId: Number(mapId),
                        mapName: zone.mapName,
                        siteName: zone.siteName
                    });

                    zonesUpdated.push(zonePayload.toJSON());
                }
            }

            if(zonesUpdated.length > 0){
                try {
                    // updating zones
                    await LCSMapService.instance().put(`/zones?batch=true&mapId=${Number(mapId)}`, {zonesUpdated: zonesUpdated});
                } catch (error) {
                    displayErrorFromAxios(dispatch, error, {ignore403:true});
                    continue;
                }
            }
        }
    }

    if(zonesAdded.length > 0){
        try {
            // creating new zones
            let responseZones = await LCSMapService.instance().post(`/zones`, zonesAdded);
            let arrayResponses = responseZones.data.created;
            return arrayResponses.reduce((acc, item) => ({...acc, [objectTitles[item.name]]: item._id}), {});
        } catch (error) {
            displayErrorFromAxios(dispatch, error, {ignore403:true});
            return {};
        }
    }else{
        return {};
    }
}


const getAntennasOnMap = async (siteId, mapID, extraFields = false) => {
    const fields = extraFields?'title,ipAddress,location,rfidAntennas,siteId,status,operation_status':'rfidAntennas';
    const response = await DeviceManagementService.instance().get(`/devices?siteId=${siteId}&location.mapID=${parseInt(mapID)}&fields=${fields}`);
    return response.data;
}

const getOtherAntennasCoordinates = (otherDevices, zoneName) => {
    let antennasCoordinates = [];
    for(var i = 0; i < otherDevices.length; i++){
        const rfidAntennas = otherDevices[i].rfidAntennas || [];
        for(var j = 0; j < rfidAntennas.length; j++){
            const antenna = rfidAntennas[j];
            if(antenna.autoZone === zoneName){
                antennasCoordinates.push([antenna.location.x, antenna.location.y]);
            }
        }
    }
    return antennasCoordinates;
}

const generateRectangle = coordinates => {
    //getting the min and max (x, y) coordinates
    let minX, maxX, minY, maxY;
    for(var i = 0; i < coordinates.length; i++){
        const coor = coordinates[i];
        minX = (coor[0] < minX || minX == null) ? coor[0] : minX;
        maxX = (coor[0] > maxX || maxX == null) ? coor[0] : maxX;
        minY = (coor[1] < minY || minY == null) ? coor[1] : minY;
        maxY = (coor[1] > maxY || maxY == null) ? coor[1] : maxY;
    }

    const coordinatesRectangle = [
        [minX - 5, minY - 5],
        [maxX + 5, minY - 5],
        [maxX + 5, maxY + 5],
        [minX - 5, maxY + 5],
        [minX - 5, minY - 5]
    ];

    // let rectangle = '' + coordinatesRectangle.length + ';';
    // for (let index = 0; index < coordinatesRectangle.length; index++) {
    //     const coordinate = coordinatesRectangle[index];
    //     rectangle = rectangle + coordinate[0] + ';' + coordinate[1] + ';';
    // }

    const feature  = new Feature({ geometry: new Polygon([coordinatesRectangle]) });
    return (new GeoJSON()).writeFeatureObject(feature, {});
}

export const buildAutozonesObject = (devicesBatch, zones) => {
    const NO_ZONE = 'No Zone';
    let objectZones = {};
    for(var i = 0; i < devicesBatch.length; i++){
        const antennas = devicesBatch[i].rfidAntennas;
        let deviceId = devicesBatch[i]._id;
        for(var j = 0; j < antennas.length; j++){
            const antenna = antennas[j];
            if(antenna.autoZone && antenna.autoZone !== NO_ZONE){
                let antennaPosition = [antenna.location.x, antenna.location.y];
                if(antenna.autoZone in objectZones){
                    objectZones[antenna.autoZone].antennas = [...objectZones[antenna.autoZone].antennas, antennaPosition];
                    if(objectZones[antenna.autoZone].deviceIds.indexOf(deviceId) === -1){
                        objectZones[antenna.autoZone].deviceIds.push(deviceId);
                    }
                }else{
                    const zoneSelected = zones.find(zone => zone.locationGuid === antenna.autoZone);
                    let objectZone = {};
                    objectZone.title = zoneSelected ? zoneSelected.name: antenna.autoZone;
                    objectZone.antennas = [antennaPosition];
                    objectZone.deviceIds = [deviceId];
                    objectZones[antenna.autoZone] = objectZone;
                }
            }
        }
    }

    return objectZones;
}

export const updateOnlyDevice = () => {
    return async (dispatch, getState) => {
        const actualTemplate = getState().deviceManager.templateForm.template;
        const updateTemplate = getState().deviceManager.updateTemplate;
        dispatch(cleanMessagesDevicesBatch());
        let {_id, template, configuration_state, operation_status, status, deviceIdStr, ...device} = actualTemplate;
        if(updateTemplate){
            device.templateId = '';
        }

        if(device.advancedJson && typeof(device.advancedJson) === 'string'){
            device.advancedJson = JSON.parse(device.advancedJson);
        }

        if(device.readerConfigurationJson && typeof(device.readerConfigurationJson) === 'string'){
            device.readerConfigurationJson = JSON.parse(device.readerConfigurationJson);
        }

        device.title = device.title.trim();
        device.description = device.description.trim();
        device.ipAddress = device.ipAddress.trim();

        try {
            const response = await DeviceManagementService.instance().patch(`/devices/${_id}`, device);
            if(response.data){
                dispatch(editDevice(_id));
                dispatch(detectSaveChanges(false));
                //show notification
                dispatch(displayNotification(true, ALERT_SUCCESS,`${device.title}:`, 'local configuration saved successfully', '',true, 6000));
            }
        } catch (e) {
            let titleMessage = e.response ? e.response.statusText : "Error";
            let bodyMessage = "Error";
            let detailsMessage = "Error saving the device";
            if (e.response && e.response.data && e.response.data.errors && e.response.data.errors.length > 0) {
                titleMessage = e.response.data.errors[0].title;
                bodyMessage = null;
                detailsMessage = e.response.data.errors[0].detail;
                detailsMessage = detailsMessage.replace('ipAddress', 'Hostname');
            }
            if (e.response && e.response.config && e.response.config.url && e.response.config.url.indexOf('zone-data') >= 0) {
                bodyMessage = "Error creating auto zone";
                detailsMessage = "An error occurred with auto zone creation.";
            }

            // show notification
            dispatch(displayNotification(true, ALERT_ERROR,`${titleMessage}:`, bodyMessage, detailsMessage, true));
            dispatch({type: LOAD_MESSAGES_DEVICES_BATCH, position: _id, message: detailsMessage});
        }
    }
}

export const updateDeviceAndTemplate = () => {
    return async (dispatch, getState) => {
        // const showModalEditDevice = getState().deviceManager.showModalEditDevice;
        const actualTemplate = getState().deviceManager.templateForm.template;
        const idsLinkedDevices = getState().deviceManager.linkedDevices.map(item => item._id);
        const linkedSelectedRows = getState().deviceManager.linkedSelectedRows;
        const idsLinkedSelectedRows = Object.keys(linkedSelectedRows).map(key => linkedSelectedRows[key]);
        const idsUnlinkedSelectedRows = idsLinkedDevices.filter(id => idsLinkedSelectedRows.indexOf(id) === -1);        
        let {_id, template, configuration_state, operation_status, status, deviceIdStr, ...device} = actualTemplate;

        if(device.advancedJson && typeof(device.advancedJson) === 'string'){
            device.advancedJson = JSON.parse(device.advancedJson);
        }

        if(device.readerConfigurationJson && typeof(device.readerConfigurationJson) === 'string'){
            device.readerConfigurationJson = JSON.parse(device.readerConfigurationJson);
        }

        const templateId = actualTemplate.templateId;
        const data = {"template": device};

        let rfidAntennasDev = [];
        dispatch(cleanMessagesDevicesBatch());

        try {
            const responseDev = await DeviceManagementService.instance().get(`/devices/${_id}`);
            if(actualTemplate.rfidAntennas.length <= responseDev.data.rfidAntennas.length) {
                rfidAntennasDev = actualTemplate.rfidAntennas.map((m, index) => {
                    let idAntenna = responseDev.data.rfidAntennas[index].id+"";
                    return {...responseDev.data.rfidAntennas[index], id: idAntenna, title: m.title, location: {...m.location}, disabled: m.disabled}
                });
            } else {
                rfidAntennasDev = actualTemplate.rfidAntennas.map((m, index) => {
                    if(responseDev.data.rfidAntennas[index]) {
                        let idAntenna = responseDev.data.rfidAntennas[index].id+"";
                        return {...responseDev.data.rfidAntennas[index], id: idAntenna, title: m.title, location: {...m.location}, disabled: m.disabled};
                    } else {
                        let idAntenna = m.id + "";
                        return {...m, id: idAntenna};
                    }
                });
            }
            dispatch(detectSaveChanges(false));
        } catch (error) {
            displayErrorFromAxios.bind(null, dispatch);
        }

        //disassociating devices to a template
        for(var i = 0; i < idsUnlinkedSelectedRows.length; i++){
            const deviceId = idsUnlinkedSelectedRows[i];
            try {
                await DeviceManagementService.instance().patch(`/devices/${deviceId}`, {templateId: ''});
            } catch (error) {
                displayErrorFromAxios.bind(null, dispatch);
            }
        }

        // updating the template and antennas
        try {
            const resultUpdateTemplate = await DeviceManagementService.instance().patch(`/devices/templates/${templateId}`, data);
            if(resultUpdateTemplate.status === 200){
                //updating other fieds of current device
                // const responseDevice = await DeviceManagementService.instance().put(`devices/${_id}`, deviceFieldsUpdate);
                const responseDevice = await DeviceManagementService.instance().patch(`/devices/${_id}`, {...device, 'rfidAntennas': [...rfidAntennasDev]});

                if(responseDevice.status === 200){
                    dispatch(editDevice(_id));
                    //show notification
                    dispatch(displayNotification(true, ALERT_SUCCESS,`${device.title}:`, 'local configuration saved successfully', '',true, 6000));
                    dispatch({type:SET_ADDED_TEMPLATE, addedTemplate: false});
                }
            }            
        } catch (e) {
            // show notification
            dispatch(displayErrorNotification(e));
            let detailsMessage = "Error saving the device";
            if (e.response && e.response.data && e.response.data.errors && e.response.data.errors.length > 0) {
                detailsMessage = e.response.data.errors[0].detail;
                detailsMessage = detailsMessage.replace('ipAddress', 'Hostname');
            }
            dispatch({type: LOAD_MESSAGES_DEVICES_BATCH, position: _id, message: detailsMessage});
        }
    }   
}

export const verifyUpdateTemplate = () => {
    return async (dispatch, getState) => {
        const actualTemplate = getState().deviceManager.templateForm.template;
        const addedTemplate = getState().deviceManager.addedTemplate;
        try {
            const response = await DeviceManagementService.instance().get(`/devices/templates/${actualTemplate.templateId}`);
            const updateTemplate = compareDevicesAndTemplateValues(actualTemplate, response.data.template, addedTemplate);
            dispatch({type:ALLOW_UPDATE_TEMPLATE, updateTemplate: updateTemplate});
        } catch (error) {
            displayErrorFromAxios.bind(null, dispatch);
        }
    }
}


const compareDevicesAndTemplateValues = (device, template, addedTemplate) => {
    let updateTemplate = false;
    if(addedTemplate){
        return true;
    }

    for(var i = 0; i < TEMPLATE_FIELDS.length; i++){
        const field = TEMPLATE_FIELDS[i];
        switch(field){
            case 'rfidAntennas':
                if(device[field].length !== template[field].length){
                    updateTemplate = true;
                }else{
                    for(let index=0; index<device[field].length; index++){
                        if(device[field][index].disabled !== template[field][index].disabled){
                            updateTemplate = true;
                        }
                    }
                }
            break;
            default:
                if(JSON.stringify(device[field]) !== JSON.stringify(template[field])){
                    updateTemplate = true;
                }
            break;
        }
    }

    return updateTemplate;
};

export const createOperationForDevice = async (deviceId, operation, args, password) => {
    let now = new Date();
    const deviceOperationObject = {
        "timeToExecute": new Date(now).toISOString(),
        // "current_status": (operation===TEST) ? OPE_STATUS_COMPLETED:OPE_STATUS_PENDING,
        "operation": operation,
        "args": args,
        "password": password
    };

    let response = null;
    try {
         response = await DeviceManagementService.instance().post(`/devices/${deviceId}/operations`, deviceOperationObject).catch(error=>{
             if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length>0) {
                 return {status:error.response.data.errors[0].status,
                     title:error.response.data.errors[0].title,
                     detail:error.response.data.errors[0].detail
                 };
             }
         });
    } catch (e) {
        response = null;
    }
    return response;
};

export const createMultipleOperations = async (selectedRows, operation, password) => {
    const deviceOperationObject = {
        "deviceId": selectedRows,
        "current_status": OPE_STATUS_PENDING,
        "operation": operation,
        "args": {},
        "password": password
    };
    
    let response = null;
    try {
         response = await DeviceManagementService.instance().post(`/devices/operations`, deviceOperationObject);
    } catch (e) {
         response= null;
    }
    return response;
};

export const changeOperationCheck = (screen, value) => ({type: CHECK_DEVICE_OPERATION, screen:screen,  operationCheck: value});

export const checkOperationPending = (deviceId) => {
    return async (dispatch, getState) => {
        const operationCheck = getState().deviceManager.operationCheck[EDIT_DEVICES_SCREEN];
        if(operationCheck){
            let response = null;
            try{
                response = await DeviceManagementService.instance().get(`/devices/${deviceId}?includeFields=operation_status`);
                if(response.data.operation_status.current_status === OPE_STATUS_COMPLETED){
                    //STATUS COMPLETED
                    //dispatch({type: DELETE_DEVICE_OPERATION, deviceId: deviceId});
                    dispatch(changeOperationCheck(EDIT_DEVICES_SCREEN, false));
                    //load edit device screen
                    dispatch(updateStateFormTemplate('operation_status', null, response.data.operation_status));
                }else{
                    setTimeout(()=>dispatch(checkOperationPending(deviceId)), OPE_TIMEOUT);
                }
            }catch{
                response = null;
            }
        }
    }
}

// Global var for operation polling.
let operationStatusInterval=null;
export const startPollingOperationStatus=(deviceId)=>{
    return async (dispatch, getState) => {
        if (operationStatusInterval != null) {
            clearInterval(operationStatusInterval);
            operationStatusInterval=null;
        }
        if(operationStatusInterval==null){
            operationStatusInterval=setInterval(()=>dispatch(checkOperationsStatus(deviceId)),5000);
        }
    }

}

export const stopPollingOperationStatus=()=>{
    return async (dispatch, getState) => {
        if (operationStatusInterval != null) {
            clearInterval(operationStatusInterval);
            operationStatusInterval=null;
        }
    }
}


export const checkOperationsStatus = (deviceId) => {
    return async (dispatch, getState) => {
        let operations = null;

        const inProgress = getState().deviceManager.deviceOperationUpdateInProgress;

        if (inProgress === false) {
            dispatch({type:UPDATE_DEVICE_OPERATIONS_IN_PROGRESS, inProgress: true});
            try {
                const fields=["operation_status","status","firmwareVersion","rfidAntennas","alert","health","ipAddress","configuration_state","siteId","location.mapID","title","macAddress","currentMode","siteName","location.mapName","protocol"];
                dispatch({type:LOADING_DEVICES_LIST, loadingDevices: true});
                if (deviceId == null) {
                    const mapSelected=getState().deviceManager.mapSelected;
                    const selectedFilters=getState().deviceManager.selectedFilters;
                    const devicesIdArray = getState().deviceManager.devicesId;

                    const requestParams = {
                        'deviceId': devicesIdArray
                    };
                    const queryString=buildQueryString(mapSelected,selectedFilters);
                    operations = await DeviceManagementService.instance().put(`/devices/${queryString}&fields=${fields.join(",")}`, requestParams);
                } else {
                    operations = await DeviceManagementService.instance().get(`/devices/${deviceId}/?fields=${fields.join(",")}`);
                }

                if (operations.data != null) {
                    //dispatch(checkChangeOperationStatus(operations.data));
                    const dataList = deviceId?[operations.data]:operations.data;
                    dispatch(checkChangeDeviceStatus(dataList, Boolean(deviceId)));
                }

            } catch {
                operations = null;
            } finally {
                dispatch({type:UPDATE_DEVICE_OPERATIONS_IN_PROGRESS, inProgress: false});
            }
        }
    }
}

export const checkChangeOperationStatus = (deviceList) => {
    return (dispatch, getState) => {
        const currentStatuses = getState().deviceManager.deviceOperationStatuses;
        let deviceOperationStatuses = {};
        let operationStatusChange = false;

        // Check operations change of state.
        deviceList.forEach(device => {

            const deviceOperationStatus = device.operation_status;
            const deviceId = deviceOperationStatus.deviceId;
            if (currentStatuses.hasOwnProperty(deviceId)) {
                if (currentStatuses[deviceId].current_status !== deviceOperationStatus.current_status) {
                    operationStatusChange = true;
                }
            }else{
                operationStatusChange = true;
            }

            deviceOperationStatuses[deviceId] = {
                current_status: deviceOperationStatus.current_status,
                operation: deviceOperationStatus.operation
            };
        });

        // If a change in operation found update the list of operation statuses.
        if (operationStatusChange === true&&Object.keys(deviceOperationStatuses).length > 0) {
            dispatch({type: UPDATE_DEVICE_OPERATIONS, deviceOperationStatuses: deviceOperationStatuses});
        }

    }
}

export const checkChangeDeviceStatus = (dataList, hasDeviceId) => {
    return (dispatch, getState) => {
        const deviceList = getState().deviceManager.deviceList.slice();
        const templateForm = {...getState().deviceManager.templateForm};

        const deviceListState = hasDeviceId?[templateForm.template]:deviceList;
        const deviceListStateObject = hasDeviceId? {[templateForm.template._id]: 0} :getState().deviceManager.deviceListObject;
        let deviceStatusChange = false;
        // Check device change of state.
        dataList.forEach(device => {
            const deviceId = device._id;
            if (deviceListStateObject.hasOwnProperty(deviceId)) {
                const currentDevice=deviceListState[deviceListStateObject[deviceId]];

                if (currentDevice.status?.title !== device.status?.title
                    ||currentDevice.firmwareVersion!==device.firmwareVersion
                    ||currentDevice.ipAddress!==device.ipAddress
                    ||currentDevice.configuration_state!==device.configuration_state
                    ||currentDevice.siteId!==device.siteId
                    ||currentDevice.location?.mapID!==device.location?.mapID
                    ||currentDevice.title!==device.title
                    ||currentDevice.macAddress!==device.macAddress
                    ||currentDevice.siteName!==device.siteName
                    ||currentDevice.location?.mapName!==device.location.mapName
                ) {
                    deviceStatusChange = true;
                }
                else if((device.rfidAntennas!=null&&currentDevice.rfidAntennas!=null&&device.rfidAntennas.length!==currentDevice.rfidAntennas.length)
                        ||(device.rfidAntennas!=null&&currentDevice.rfidAntennas==null)
                        ||(device.rfidAntennas==null&&currentDevice.rfidAntennas!=null)
                ){
                    deviceStatusChange = true;
                }
                // Chek alerts changes.
                else if(currentDevice.health!==device.health
                    ||(typeof currentDevice.alert!== typeof device.alert)
                    ||(currentDevice.alert!=null&&device.alert!=null&&(currentDevice.alert.length!==device.alert.length))
                    ||(currentDevice.alert!=null&&device.alert!=null&&(device.alert.find((alert,index)=>alert.value!==currentDevice.alert[index].value)))
                ){
                    deviceStatusChange = true;
                }
                else if(currentDevice.operation_status?.current_status!==device.operation_status?.current_status){
                    deviceStatusChange = true;
                }
                else if(device.rfidAntennas!=null&&currentDevice.rfidAntennas!=null&&device.rfidAntennas.length===currentDevice.rfidAntennas.length) {
                    device.rfidAntennas.find((antenna,index)=>{
                        if(antenna.status!==currentDevice.rfidAntennas[index].status
                            ||antenna.disabled!==currentDevice.rfidAntennas[index].disabled
                            ||antenna.transmitPower_curr!==currentDevice.rfidAntennas[index].transmitPower_curr
                        ){
                            deviceStatusChange=true;
                            return true;
                        }
                        return false;
                    });
                }

                currentDevice.status=device.status;
                currentDevice.operation_status = device.operation_status;
                currentDevice.health = device.health;
                currentDevice.alert = device.alert;
                if(currentDevice.rfidAntennas!=null) {
                    currentDevice.rfidAntennas.forEach((antenna,index)=>{
                        antenna.transmitPower_curr=device.rfidAntennas[index].transmitPower_curr;
                    });
                }

                if(!hasDeviceId) {
                    currentDevice.ipAddress = device.ipAddress;
                    currentDevice.configuration_state = device.configuration_state;
                    currentDevice.siteId = device.siteId;
                    currentDevice.title = device.title;
                    currentDevice.macAddress = device.macAddress;
                    currentDevice.siteName = device.siteName
                    if (currentDevice.location)
                        currentDevice.location.mapId = device.location?.mapId;
                        currentDevice.location.mapName = device.location?.mapName;
                }
                // if(device.logLevel){
                //     currentDevice.logLevel = device.logLevel;
                // }
                if(device.firmwareVersion!=null) {
                    currentDevice.firmwareVersion = device.firmwareVersion;
                }
                if(device.rfidAntennas!=null&&!hasDeviceId) {
                    currentDevice.rfidAntennas = device.rfidAntennas;
                }
            }
        });
        // If a change in operation found update the list of operation statuses.
        if (deviceStatusChange === true&&Object.keys(deviceListState).length > 0) {
            if(hasDeviceId){
                const {template, ...templateHeader} = templateForm;
                dispatch(loadFormDevice(deviceListState[0], templateHeader, EDIT_DEVICE_SAVED));
            }else{
                dispatch({type: LOAD_DEVICE_LIST, deviceList: deviceListState});
            }
        }

    }
}

export const displayPendingProcess = (operationStatus) => {
    return (dispatch, getState) => {
        const title = getState().deviceManager.templateForm.template.title;
        dispatch(displayNotification(true, ALERT_RECEIVER, `${title}:`, `the device has ${operationStatus.operation.toUpperCase()} operation pending`, '', true));
    }
}

export const operateDevice = (device, action, password) => {
    return async (dispatch, getState) => {

        // If the action is UI to RFID direct task.
        // if(RFID_TASKS.indexOf(action)>=0){
        //     return await manageRfidTasks(device._id,action);
        // }

        const deviceManagerScreen = getState().deviceManager.deviceManagerScreen;
        const templateFormLoaded = getState().deviceManager.templateFormLoaded;

        const deviceId = device._id;

        // Display Notification
        if(templateFormLoaded){
            dispatch(displayDialogNotification(ALERT_RECEIVER, `${device.title}`, `Device began ${action.toUpperCase()} operation`));
        }else{
            //dispatch(displayNotification(true, ALERT_RECEIVER, `${device.title}:`, `device began ${action.toUpperCase()} operation`, '', true, 5000));
        }
        let operationCreated;
        try {
            //creating a device operation
            operationCreated = await createOperationForDevice(deviceId, action, undefined, password);
            //loading Landing Page
            if(deviceManagerScreen === LANDING_PAGE_SCREEN){
                dispatch(loadDeviceList());
            }
            //loading Edit Form
            if(deviceManagerScreen === EDIT_DEVICES_SCREEN && templateFormLoaded){
                dispatch(editDevice(deviceId));
            }

            //loading Edit Map screen
            if(deviceManagerScreen === EDIT_DEVICES_SCREEN && !templateFormLoaded){
                const deviceResponse = await DeviceManagementService.instance().get(`/devices/${deviceId}`);
                if(deviceResponse.status === 200 && deviceResponse.data){
                    dispatch(saveDeviceBatch(deviceId, deviceResponse.data));
                }
            }

            // Display notification of status or issue

            if("ops" in operationCreated.data){
                // let current_status = operationCreated.data.ops[0].current_status;

                // Display notification of status
                // if(templateFormLoaded){
                //     dispatch(displayDialogNotification(ALERT_SUCCESS, `${device.title}`, `Device is making ${action.toUpperCase()} operation, the current status is ${current_status}`));
                // }else{
                //     //dispatch(displayNotification(true, ALERT_SUCCESS, `${device.title}:`, `device is making ${action.toUpperCase()} operation, the current status is ${current_status}`, true));
                //     //dispatch(displayNotification(true, ALERT_SUCCESS, `${device.title}:`, `Device is being ${action.toUpperCase()}`, true));
                // }
            }else{
                let error = operationCreated.data.errors[0];
                if(templateFormLoaded){
                    dispatch(displayDialogNotification(ALERT_SUCCESS, `${device.title}`, `Cannot perform  ${action.toUpperCase()} operation, ${error}`));
                }else{
                    dispatch(displayNotification(true, ALERT_ERROR, `${device.title}:`, ` cannot perform  ${action.toUpperCase()} operation`, `${error}`, true));
                }
            }

        } catch (error) {
            let errorDetails = "";
            if(operationCreated && operationCreated.detail){
                errorDetails = operationCreated.detail;
            }
            // Display notification
            if(templateFormLoaded){
                dispatch(displayDialogNotification(ALERT_ERROR, `${device.title}`, `Cannot perform ${action.toUpperCase()} operation, ${errorDetails}`));
            }else{
                dispatch(displayNotification(true, ALERT_ERROR, `${device.title}:`, ` cannot perform ${action.toUpperCase()} operation`, errorDetails, true));
            }
        }
    }
}

export const testDevice = (deviceId, deviceTitle, action, args={}) => {
    return async (dispatch, getState) => {
        const deviceManagerScreen = getState().deviceManager.deviceManagerScreen;
        const templateFormLoaded = getState().deviceManager.templateFormLoaded;

        try {
            // Creating a device operation
            await createOperationForDevice(deviceId, action, args);

            //loading the change
            if(deviceManagerScreen === LANDING_PAGE_SCREEN && args.action === 'done'){
                dispatch(loadDeviceList());
            }

            if(deviceManagerScreen === EDIT_DEVICES_SCREEN && args.action === 'done'){
                dispatch(editDevice(deviceId));
            }

            // Display notification
            if(templateFormLoaded){
                dispatch(displayDialogNotification(ALERT_SUCCESS, `${deviceTitle}`, `Device finished TEST operation`));
            }else{
                dispatch(displayNotification(true, ALERT_SUCCESS, `${deviceTitle}:`, `device finished was tested`, '', true, 5000));
            }

        } catch (error) {

            dispatch(displayNotification(true, ALERT_ERROR, `${deviceTitle}:`, 'Device could not be tested', 'Error Details ', true));
        }
    }
}


export const operateMultipleDevices = (action, password) => {
    return async (dispatch, getState) => {
        const currentSelectedRows = getState().deviceManager.selectedRows;
        const deviceList = getState().deviceManager.deviceList;
        const devicesWithAntennas = Object.keys(currentSelectedRows).reduce((acc, idDevice) => {
            const device = deviceList?.find(item => item._id === idDevice) || {};
            return device.rfidAntennas?.length > 0? [...acc, idDevice]: acc;
        }, []);
        
        const devicesToOperate = (action === PUBLISH) ? devicesWithAntennas: Object.keys(currentSelectedRows);

        let operationCreated;
        try {
            operationCreated = await createMultipleOperations(devicesToOperate, action, password);
            // await createMultipleOperations(devicesToOperate, action);
            dispatch(loadDeviceList());
            // dispatch(displayNotification(true, ALERT_SUCCESS, `Multiple devices: `, `Devices is making ${action.toUpperCase()} operation`,'',5000));

        } catch (error) {
            let errorDetails = "";
            if(operationCreated && operationCreated.detail){
                errorDetails = operationCreated.detail;
            }
            dispatch(displayNotification(true, ALERT_ERROR, `Multiple Devices:`, ` Devices cannot perform ${action.toUpperCase()} operation`, errorDetails, true));
        }
    }
}

export const updateStateFormTemplate = (sectionKey, key, value, initState=false) => {
    return (dispatch, getState) => {
        if(!initState){
            const isDeviceChanging = getState().deviceManager.isDeviceChanging;
            if(!isDeviceChanging){
                dispatch(detectSaveChanges(true));
            }
        }
        dispatch({type:UPDATE_FORM_TEMPLATE, sectionKey: sectionKey, key:key, value:value});
    }
}

export const detectSaveChanges = (isDeviceChanging) => ({type: DETECT_SAVE_CHANGES, isDeviceChanging: isDeviceChanging});
export const updateStateKeyFormTemplate = (key, value) => {
    return (dispatch, getState) => {
        const isDeviceChanging = getState().deviceManager.isDeviceChanging;
        if(!isDeviceChanging){
            dispatch(detectSaveChanges(true));
        }
        dispatch({type:UPDATE_FORM_TEMPLATE_MAIN, key:key, value:value});
    }
};
export const addFormAntenna = () => {
    return (dispatch, getState) => {
        const isDeviceChanging = getState().deviceManager.isDeviceChanging;
        if(!isDeviceChanging){
            dispatch(detectSaveChanges(true));
        }
        dispatch({type:ADD_FORM_TEMPLATE_ANTENNA})
    }
};

export const addFormAntennaByPort = (port) => {
    return (dispatch, getState) => {
        const isDeviceChanging = getState().deviceManager.isDeviceChanging;
        if(!isDeviceChanging){
            dispatch(detectSaveChanges(true));
        }
        dispatch({type:ADD_FORM_TEMPLATE_ANTENNA_BY_PORT, port: Number(port)})
    }
}

export const deleteFormAntenna = (index) => {
    return (dispatch, getState) => {
        const isDeviceChanging = getState().deviceManager.isDeviceChanging;
        if(!isDeviceChanging){
            dispatch(detectSaveChanges(true));
        }
        dispatch({type:DELETE_FORM_TEMPLATE_ANTENNA, index: index})
    }
};

export const deleteFormAntennaByPort = (port) => {
    return (dispatch, getState) => {
        const antennas = getState().deviceManager.templateForm.template.rfidAntennas
        const indexAntenna = antennas.findIndex(antenna => antenna.id === port);
        if(indexAntenna >-1){
            dispatch(deleteFormAntenna(indexAntenna));
        }
    }
};

export const updateFormAntenna = (index, key, value) => {
    return (dispatch, getState) => {
        const rfidAntennas = getState().deviceManager.templateForm.template.rfidAntennas || [];
        const isDeviceChanging = getState().deviceManager.isDeviceChanging;
        if(!isDeviceChanging){
            dispatch(detectSaveChanges(true));
        }

        let rfidAntennasUpdated = rfidAntennas.map((antenna, i) => {
            if(index === i){
                return ({...antenna, [key]: value});
            }else{
                return ({...antenna});
            }
        });
        dispatch({type: UPDATE_FORM_TEMPLATE_ANTENNA, rfidAntennas: rfidAntennasUpdated});
    }
}

export const addTemplateSaveDevice = (indexDevice = null,setSave=null) => {
    return async (dispatch, getState) => {
        const templateForm = getState().deviceManager.templateForm;
        const showModalEditDevice = getState().deviceManager.showModalEditDevice;

        const {_id, ...rest} = templateForm;
        const deviceId = rest.template._id;
        const templateName = rest.title;

        if(rest.template.advancedJson && typeof(rest.template.advancedJson) === 'string'){
            rest.template.advancedJson = JSON.parse(rest.template.advancedJson);
        }

        if(rest.template.readerConfigurationJson && typeof(rest.template.readerConfigurationJson) === 'string'){
            rest.template.readerConfigurationJson = JSON.parse(rest.template.readerConfigurationJson);
        }
        try {
            //adding new template
            const responseTemplate = await DeviceManagementService.instance().post(`/devices/templates`, rest);
            const templateId = responseTemplate.data._id;

            //edit current device
            await DeviceManagementService.instance().patch(`/devices/${deviceId}`, {templateId: templateId});

            //loading current device
            dispatch(editDevice(deviceId));

            //showing notification
            if(showModalEditDevice){
                dispatch(displayDialogNotification(ALERT_SUCCESS, rest.title, `was created and associated to ${rest.template.title}`));
                //updating for Edit by Map screen
                dispatch({type: LOAD_EDITED_DEVICE, deviceModified: {...rest.template, templateId: templateId, template: templateName}, indexDevice: indexDevice});
            }else{
                dispatch(displayNotification(true, ALERT_SUCCESS, rest.title, `was created and associated to ${rest.template.title}`,'', true, 5000));
            }

            if(setSave!=null){
                setSave(null);
            }
        } catch (error) {
            //displayErrorFromAxios.bind(null,dispatch);
            dispatch(displayErrorNotification(error));
        }
    }
}

export const cleanSiteAndMapDevice = (deviceId) => {
    return (dispatch, getState) => {
        const indexDevice = getState().deviceManager.devicesBatch.findIndex(item => item._id === deviceId);
        let currentDevice = {...getState().deviceManager.devicesBatch[indexDevice]};
        const isDeviceChanging = getState().deviceManager.isDeviceChanging;
        if(!isDeviceChanging){
            dispatch(detectSaveChanges(true));
        }

        const initLocation = {
            "locationType": "",
            "surveyPoint": "",
            "mapID": "",
            "mapName": ""
        }
        currentDevice.siteId = "";
        currentDevice.siteName = "";
        currentDevice.location = initLocation;
        currentDevice.rfidAntennas = currentDevice.rfidAntennas.map(antenna => ({...antenna, location: initLocation, autoZone: null}));
        dispatch({type: LOAD_EDITED_DEVICE, deviceModified: currentDevice, indexDevice: indexDevice});
    }
}

export const addTemplate = (history = null) => {
    return async (dispatch, getState) => {
        const templateForm = getState().deviceManager.templateForm;
        const deviceType = getState().deviceManager.deviceType;

        let {_id, ...template} = templateForm;
        if(template.deviceType === ''){
            template.deviceType = deviceType;
        }

        if(template.template.advancedJson && typeof(template.template.advancedJson) === 'string'){
            template.template.advancedJson = JSON.parse(template.template.advancedJson);
        }

        if(template.template.readerConfigurationJson && typeof(template.template.readerConfigurationJson) === 'string'){
            template.template.readerConfigurationJson = JSON.parse(template.template.readerConfigurationJson);
        }
        try {
            await DeviceManagementService.instance().post(`/devices/templates`, template);
            dispatch(detectSaveChanges(false));
            if(history){
                const pathTemplateList = '/device-manager/templates';
                dispatch(cleanFormTemplate());
                dispatch({type: SET_TEMPLATE_ERROR, templateMsgError: null});
                history.push(pathTemplateList);
            }
        } catch (error) {
            // show notification
            dispatch(displayErrorNotification(error));
        }
    }
}

export const updateTemplate = (history = null) => {
    return async (dispatch, getState) => {
        const templateForm = getState().deviceManager.templateForm;
        const idsLinkedDevices = getState().deviceManager.linkedDevices.map(item => item._id);
        const linkedSelectedRows = getState().deviceManager.linkedSelectedRows;
        const idsLinkedSelectedRows = Object.keys(linkedSelectedRows).map(key => linkedSelectedRows[key]);
        const idsUnlinkedSelectedRows = idsLinkedDevices.filter(id => idsLinkedSelectedRows.indexOf(id) === -1);
        const {_id, ...template} = templateForm;

        if(template.template.advancedJson && typeof(template.template.advancedJson) === 'string'){
            template.template.advancedJson = JSON.parse(template.template.advancedJson);
        }
        if(template.template.readerConfigurationJson && typeof(template.template.readerConfigurationJson) === 'string'){
            template.template.readerConfigurationJson = JSON.parse(template.template.readerConfigurationJson);
        }

        template.title = template.title.trim();
        template.description = template.description.trim();

        //disassociating devices to a template
        if(idsUnlinkedSelectedRows.length > 0){
            for(var i = 0; i < idsUnlinkedSelectedRows.length; i++){
                const deviceId = idsUnlinkedSelectedRows[i];
                try {
                    await DeviceManagementService.instance().patch(`/devices/${deviceId}`, {templateId: ''});
                } catch (error) {
                    displayErrorFromAxios.bind(null,dispatch);
                }
            }
        }

        try {
            await DeviceManagementService.instance().patch(`/devices/templates/${_id}`, template);
            dispatch(detectSaveChanges(false));
            if(history){
                const pathTemplateList = '/device-manager/templates';
                dispatch(cleanFormTemplate());
                history.push(pathTemplateList);
            }
        }catch (error) {
            dispatch(displayErrorNotification(error));
            displayErrorFromAxios.bind(null, dispatch);
        }
    }
};

function displayErrorNotification(error){
    return (dispatch) => {
        if (error != null && error.response != null && error.response.data != null 
            && error.response.data.errors != null && error.response.data.errors.length > 0) {
            const firstError = error.response.data.errors[0];
            dispatch(displayNotification(true, ALERT_ERROR, firstError.title, '', firstError.detail, true, null));
        }
    }
}

function displayCustomErrorNotification(errorTitle,errorSubtitle,errorDetail){
    return (dispatch) => {
            dispatch(displayNotification(true, ALERT_ERROR, errorTitle, errorSubtitle, errorDetail, true, null));
    }
}

export const displayDialogNotification = (typeNotification, title, message) => {
    return dispatch => {
        // let dialogNotification = {typeNotification: typeNotification, title: title, message: message};
        // dispatch({type: LOAD_DIALOG_NOTIFICATION, dialogNotification: dialogNotification});
        let duration=5000;
        if(typeNotification===ALERT_ERROR){
            duration=null;
        }
        dispatch(displayNotification(true, typeNotification, title, null,message, true, duration));
    }
};

export const cleanDialogNotification = () => ({type: LOAD_DIALOG_NOTIFICATION, dialogNotification: null});

export const selectTemplate = (position) => ({type:SELECT_TEMPLATE, position: position});

export const deleteTemplate = (templateId) => {
    return async dispatch => {
        try {
            await DeviceManagementService.instance().delete(`/devices/templates/${templateId}`);
            dispatch(loadTemplateList());
        } catch (error) {
            displayErrorFromAxios(dispatch, error , {ignore403:true});
        }
    }
};

export const loadTemplatesBatch = () => {
    return async (dispatch, getState) => {
        const batchImportFiles = getState().deviceManager.batchImportFiles;
        let titleTemplates = [];
        Object.keys(batchImportFiles).forEach(file => {
            batchImportFiles[file].forEach(item => {
                if(titleTemplates.indexOf(item.deviceTemplate) === -1){
                    titleTemplates.push(item.deviceTemplate);
                }
            })
        })
        
        try {
            const query = titleTemplates.join('&title=')
            const response = await DeviceManagementService.instance().get(`/devices/templates?title=${query}`);
            dispatch({type: LOAD_TEMPLATE_LIST, templates: response.data});
        } catch (error) {
            displayErrorFromAxios.bind(null,dispatch);
        }
    }
}

export const loadTemplateList = () => {
    return (dispatch, getState) => {
        dispatch({type:SET_FETCHING, isFetching: true});
        const currentSelectedFilters = getState().deviceManager.selectedFilters['templates'];
        let parameters = getFilterValues(currentSelectedFilters);
        const query = (parameters !== '')? `?${parameters}`: '';
        return DeviceManagementService.instance().get(`/devices/templates${query}`)
        .then(response => {
            dispatch({type: LOAD_TEMPLATE_LIST, templates: response.data});
            dispatch({type:SET_FETCHING, isFetching: false});
        })
        .catch(displayErrorFromAxios.bind(null,dispatch));
    }
}

export const unloadTemplateList = () => ({type: LOAD_TEMPLATE_LIST, templates:[]});

export const setOpenAlert = (openAlert) => ({type: OPEN_ALERT, openAlert: openAlert});
export const loadDeviceList = (params) => {
    return async (dispatch, getState) => {
        const {requestForCloud} = params || {};
        dispatch({type:SET_FETCHING, isFetching: true});
        // dispatch(reloadSitesMaps(null, null,true));
        let paginationInfo = getState().deviceManager.paginationInfo;

        const mapSelected=getState().deviceManager.mapSelected;
        const selectedFilters=getState().deviceManager.selectedFilters;
        const queryString=buildQueryString(mapSelected,selectedFilters);

        let querySort = '';
        if(paginationInfo.sort != null){
            const {direction, index} =  paginationInfo.sort;
            let orderSort = direction === -1 ? '-' : '';
             querySort = '&sort_by=' + orderSort + index;
        }
        try {
            if(requestForCloud){
                dispatch(updateConfigurationOption());
            }
            const response = await DeviceManagementService.instance().get(`/devices${queryString}&limit=${paginationInfo.limit}&offset=${paginationInfo.offset}${querySort}&include_metadata`);
            if (response && response.status === 200) {
                const results = response.data.results;
                const status = response.data.status;
                paginationInfo = changePaginationInfo(status, paginationInfo);
                const deviceList = results.map(item => ({...item, template: ""}));
                // loading data in table
                dispatch({type: LOAD_DEVICE_LIST, deviceList: deviceList});
            }
        } catch (e) {
            displayErrorFromAxios.bind(null, dispatch);
            dispatch({type: SET_ERROR_DEVICES_LIST, errorLoadDevicesList: {error: true, message: 'Could not connect with the service.\nPlease try to reload again on several minutes.'}});
        } finally {
            dispatch(setPaginationInfo(paginationInfo))
        }
        dispatch({type: SET_FETCHING, isFetching: false});

    }
};

export const setErrorMessage = (error, message) => ({type: SET_ERROR_DEVICES_LIST, errorLoadDevicesList: {error: error, message: message}});
// getting values of a specific column of devices or templates
export const getColumnListValues = (type, field) => {
    // type caould be 'devices' or 'templates'
    return async (dispatch,getState) => {
        const sites = getState().deviceManager.sites;
        const maps = getState().deviceManager.maps;
        const paginationInfo = getState().deviceManager.paginationInfo;

        type = ( type === "devices" )? type :  "devices/" + type;
        let pagination = ( type === "devices" )?`&offset=${paginationInfo.limit}&limit=${paginationInfo.limit}`:'';
        let response = await DeviceManagementService.instance().get(`/${type}/distinct?column=${field}${pagination}`);
        let listValues = [];
        switch (field) {
            case 'rfidAntennas': listValues = filterAntennaColors(response.data); break;
            case 'siteId':  listValues = filterSite(response.data,sites); break;
            case 'location': listValues = filterSiteMap(response.data,maps); break;
            case 'status': listValues = filterStatus(response.data); break;
            default: listValues = filter(response.data); break;
        }

        for (let i = 0; i < listValues.length; i++) {
            if(listValues[i].key===""||listValues[i].value==="") {
                listValues.splice(i,1);
                break;
            }
        }

        dispatch({type: LOAD_FILTERS, column: field, values: listValues});
    } 
}

export const getReloadDeviceVersion = (deviceId) => {
    return async (dispatch) => {
        dispatch({type:SET_FETCHING, isFetching: true});
        return DeviceManagementService.instance().get(`/devices/snapshots?deviceIds=${deviceId}`).then(
            response =>{
                let version={};
                if(response && response.data && response.data.length > 0){
                    version.configuration=[];
                    response.data.forEach( item =>{
                       let configuration = {};
                       configuration.name = item.name;
                       configuration.dateCreated = item.dateCreated;
                       configuration.version = item.version;
                       const rfidR2CReaders = item.configuration.rfidR2CReaders;
                       configuration.rfidR2CReaders=[];
                       rfidR2CReaders.forEach(reader =>{
                          if(reader._id === deviceId){
                              configuration.rfidR2CReaders = reader;
                              configuration.version = reader.version;
                              //configuration.rfidR2CReaders.versionSnapshot = item.version;
                              //configuration.rfidR2CReaders.push(reader);
                          }
                       });
                       version.configuration.push(configuration);
                    });
                }
                dispatch({type: CONFIGURATION_VERSION_HISTORY, versionHistory: version});
                dispatch({type:SET_FETCHING, isFetching: false});
        }).catch(displayErrorFromAxios.bind(null,dispatch));
    }
}
export const unloadDeviceVersion = () => {
    return dispatch => {
        dispatch({type: CONFIGURATION_VERSION_HISTORY, versionHistory: {}});
    }
}
export const saveDeviceConfigurationVersion =  (deviceId, device) => {
    return async (dispatch, getState) => {
        const configuration = device.rfidR2CReaders;
        const actualTemplate = getState().deviceManager.templateForm.template;
        const templateId = configuration.templateId;
        let template = "";
        let templateHeader = {};
        if(templateId !== ""){
            const includeFields = 'title,deviceType,description,createDate,createBy';
            const response1 = await DeviceManagementService.instance().get(`/devices/templates/${templateId}?includeFields=${includeFields}`);
            if(response1.data !== ""){
                template = response1.data.title;
                templateHeader = response1.data;
            }
        }
        const deviceFieldsUpdate = {
            _id:  configuration._id,
            deviceType: configuration.deviceType,
            siteId: configuration.siteId,
            title: configuration?.title,
            ipAddress: configuration.ipAddress,
            serialNumber:configuration.serialNumber,
            description: configuration.description,
            disabled: configuration.disabled,
            version: configuration.version,
            versionSnapshot: configuration?.versionSnapshot,
            orderNumber: configuration.orderNumber,
            itemNumber: configuration.itemNumber,
            typedInSerialNumber: configuration.typedInSerialNumber,
            dateInstalled:  configuration.dateInstalled,
            product:  configuration.product,
            location: configuration.location,
            rfidType: configuration.rfidType,
            configuration_state: configuration.configuration_state,
            templateId: configuration.templateId,
            rfidAntennas:  configuration.rfidAntennas,
            operationMode: configuration.operationMode,
            tagFilter: configuration.tagFilter,
            inventoryInterval: configuration.inventoryInterval,
            portalSettings: configuration.portalSettings,
            deviceIdStr:configuration.deviceIdStr,
            //status: configuration.status,
            macAddress: configuration.macAddress,
            operation_status:  configuration.operation_status,
            firmwareVersion: configuration?.firmwareVersion,
            hostname: configuration?.hostname,
            current_status:configuration?.current_status,
            template: template
        }; 
        let dataTemplate = {...actualTemplate,...deviceFieldsUpdate};
        let deviceSave = {...dataTemplate, template: template};
        dispatch(loadFormDevice(deviceSave, templateHeader, EDIT_DEVICE_SAVED));

    };
}
const filter = (data) =>{
    //returning the new format
    return data.map(item =>  {return {key:item,value:item}});
};

const filterAntennaColors = (data) =>{
    //returning the antenna colors
    return data.map(item => item.color).filter((value, index, self) => self.indexOf(value) === index);
};
const filterSite = (data,sites) =>{
    //returning the site name
    let sitesAll = [];
    sites.forEach( item =>{
        sitesAll[item.id] = item.name;
    });
    return data.map(item =>  {return {key:item,value:sitesAll[item]}});
};
const filterSiteMap = (data,maps) =>{
    //returning the map name
    let mapAll = [];
    Object.keys(maps).forEach(key => maps[key].forEach(item =>{
        mapAll[item.id] = item.name;
    }));
    let mapsResponse = [];
    data.forEach( item =>{
        mapsResponse[item.mapID] = {key:item.mapID,value:mapAll[item.mapID]};
    });
    return mapsResponse.filter(item => item!==null && item.key!=null);
};
/*
const filterStatus = async (dispatch) => {
    let listFilter = []
    try{
        const result = await DeviceManagementService.instance().get('/devices/statuses/distinct?column=title');
        listFilter = result.data.map((item, index) => {
            return {key: item, value: item}
        })
    } catch(error){
        displayErrorFromAxios.bind(null, dispatch);
    }
    return listFilter.filter(item => item!==null && item.key!=null);
};*/
const filterStatus = (data) =>{
    return data.map(item => item.title).filter((value, index, self) => self.indexOf(value) === index).map(item=>{
        return {key:item,value:item};
    });
}

export const unloadDeviceList = () => ({type: LOAD_DEVICE_LIST, deviceList:[]});
export const updateDeviceList = (number) => ({type: "UPDATE_DEVICE_LIST", text:number});

export const selectRow  = (rowIndex) => {
    return(dispatch, getState) => {
        const currentSelectedRows = getState().deviceManager.selectedRows;
        const device = getState().deviceManager.deviceList[rowIndex];
        const sitesForDevices = getState().deviceManager.sitesForDevices;
        const siteId = getState().deviceManager.siteId;
        const mapId = getState().deviceManager.mapId;

        const newState = Object.assign({},currentSelectedRows);
        newState[device._id] = device._id;
        // newState[device._id] = device.title;
        dispatch({type: DEVICE_MANAGER_UPDATE_SELECTED, selectedRows: newState});

        const siteIdDevice = device.siteId;
        const mapIdDevice = device.location.mapID;

        dispatch(changeMapView(LOCAL_MAP_VIEW_DEVICES));

        //checking if the mapselected is different
        if (!(mapId === mapIdDevice && siteId === siteIdDevice)){
            if(!sitesForDevices){dispatch(loadDevicesSites());}
            dispatch(loadSiteAndMap(siteIdDevice, mapIdDevice));
        }
    }
};

export const unSelectRow  = (rowIndex) => {
    return(dispatch, getState) => {
        const state = getState();
        const device = state.deviceManager.deviceList[rowIndex];
        const currentSelectedRows = state.deviceManager.selectedRows;
        const newState = Object.assign({},currentSelectedRows);
        delete newState[device._id];
        dispatch({type: DEVICE_MANAGER_UPDATE_SELECTED, selectedRows: newState});
    }
};

export const clickFeatureRow = (deviceId, deviceObject) => {
    return (dispatch, getState) => {
        const currentSelectedRows = getState().deviceManager.selectedRows;
        if(Object.keys(currentSelectedRows).indexOf(deviceId) > -1){
            //delete device
            const newState = Object.assign({},currentSelectedRows);
            delete newState[deviceId];
            dispatch({type: DEVICE_MANAGER_UPDATE_SELECTED, selectedRows: newState});
        }else{
            //add a new device
            dispatch({type: DEVICE_MANAGER_UPDATE_SELECTED, selectedRows: {...currentSelectedRows, [deviceId]: deviceObject}});
        }
    }
}

export const selectAll = (allRows) => ({type: DEVICE_MANAGER_UPDATE_SELECTED, selectedRows: allRows});

export const deviceActionForm = (action) => {
    return (dispatch, getState) => {
        const device = getState().deviceManager.templateForm.template;
        
        if(action === PUBLISH){
            // const numberAntennas = device.rfidAntennas.length;
            // if(numberAntennas === 0){
            //     let errorMessage = 'The device must have at least one defined antenna';
            //     dispatch(displayDialogNotification(ALERT_ERROR, `${device.title}`, errorMessage));
            // }else{
                dispatch(operateDevice(device, action));
            // }
        }else{
            dispatch(operateDevice(device, action));
        }
    }
}

export const deviceAction = (action, rowIndex, password) => {
    return (dispatch, getState) => {
        const state = getState();
        const device = state.deviceManager.deviceList[rowIndex];
        // const numberAntennas = device.rfidAntennas.length;
        // let errorMessage = 'The device must have at least one defined antenna';

        // if(onCloud===true&&action===REBOOT) {
        //     dispatch(displayNotification(true, ALERT_WARNING, 'Warning', '', 'Because of security restrictions, please use the Local R2C app to initialize the reader after a reboot.', true, null, null));
        // }

        dispatch({type: ACTION_DEVICE, actionDevice: action, actionRow:device})
        switch(action){
            case VIEW_DETAILS:
                dispatch(selectRow(rowIndex));
                dispatch(changeStatusDetails(true));
            break;
            case DELETE:
                dispatch(deleteDevice(device));
            break;
            case PUBLISH:
                // if(numberAntennas === 0){
                //     dispatch(displayNotification(true, ALERT_ERROR,`${device.title}:`, 'Publication failed', errorMessage, true));
                // }else{
                    dispatch(operateDevice(device, action));
                // }
            break;
            case CANCEL:
                dispatch(operateDevice(device, action));
            break;
            case EDIT:
            break;
            default:
                dispatch(operateDevice(device, action, password));
            break;
        }
    }
};
//-------------------------Adding manually devices--------------------------------

export const loadDevicesSites = () => {
    return (dispatch) => {
        return SiteViewService.instance().get(MAP_VIEW_URL + '/sites/',{
            params: {},
            headers:{
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            dispatch({ type: LOAD_DEVICES_SITES, data: response.data })
        }).catch(displayErrorFromAxios.bind(null, dispatch));
    }
};

export const loadSiteAndMap = (siteId, mapId) => {
    return async (dispatch) => {
        if(!siteId){
            try{
                const responseSites = await SiteViewService.instance().get(MAP_VIEW_URL + "/sites/", {params: {}, headers: {'Content-Type': 'application/json'}});
                if(responseSites.data.length > 0){
                    const siteObj = responseSites.data[0];
                    const responseMaps = await SiteViewService.instance().get(MAP_VIEW_URL + "/maps");
                    let mapsArray = responseMaps.data.filter(map => map.siteId === siteObj._id);
                    if(mapsArray.length > 0){
                        let mapobj = mapsArray[0];
                        // TODO: refactor this part it should be all async await functions.
                        Promise.all([getMapCoordinates(mapobj._id)]).then(results=>{
                            if(results!=null&&results.length>0) {
                                dispatch({
                                    type: LOAD_SITE_MAP,
                                    siteDevice: siteObj,
                                    mapDevice: mapobj,
                                    mapBounds: results[0].extent
                                })
                            }
                        });
                    }
                }
            }catch(error){
                displayErrorFromAxios.bind(null, dispatch);
            }
        }else{
            try {
                const responseMaps = await SiteViewService.instance().get(MAP_VIEW_URL + "/maps");
                let mapsArray = responseMaps.data.filter(map => map.siteId === siteId);
                if (mapsArray.length > 0){
                    let indexMap = mapsArray.findIndex(item => Boolean(item.coordinates));
                    indexMap = indexMap >-1? indexMap: 0;
                    let mapObjId = Number(mapId) || mapsArray[indexMap]._id;
                    let mapSelected = mapsArray.filter(map => map._id === mapObjId)[0];
                    dispatch({type: CHANGE_CURRENT_MAP, siteId: siteId, mapId: mapObjId});

                    // SiteViewService.instance().get(MAP_VIEW_URL + "/maps/" + mapObjId + "/configuration")
                    // TODO: refactor this part it should be all async await functions.
                    Promise.all([getMapCoordinates(mapObjId)]).then(results=>{
                        let mapBounds=null;
                        if(results[0]!=null&&results[0].extent){
                            mapBounds=results[0].extent;
                        }

                        dispatch({
                            type: LOAD_SITE_MAP,
                            mapDevices: mapsArray,
                            mapBounds: mapBounds,
                            mapZoom: mapSelected?mapSelected.maxZoom:0
                        })
                    }).catch(e=>{
                        dispatch({
                            type: LOAD_SITE_MAP,
                            mapDevices: mapsArray,
                            mapBounds: null,
                            mapZoom: mapSelected?mapSelected.maxZoom:8
                        })
                    });
                }
            } catch (error) {
                displayErrorFromAxios.bind(null, dispatch);
            }
        }
    }
};

export const selectSite =(siteId) => {
    return dispatch => {
        const timestamp = (new Date()).getTime();
        SiteViewService.instance().get(MAP_VIEW_URL+"/sites/",{
            params:{

            },
            headers:{
                'Content-Type':'application/json'
            }
        })
        .then(responseSites => {
            let siteSelected = responseSites.data.filter(site => site._id === siteId);
            SiteViewService.instance().get(MAP_VIEW_URL + "/maps")
                .then(responseMaps => {
                    let mapsArray = responseMaps.data.filter(map => map.siteId === siteId);

                    if(mapsArray.length > 0){
                        let mapobj = {
                            name: mapsArray[0].description,
                            id: mapsArray[0]._id,
                            src: SITE_VIEW_HOST + MAP_VIEW_URL + "/maps/" + mapsArray[0]._id + "/tiles/0_0_0?timestamp=" + timestamp + "&jwt=" + getToken(),
                            siteId: siteId,
                            siteName: siteSelected[0].name,
                            countDevices: 25,
                            lastEdited: "2020-02-06",
                            showSiteName: false,
                            selected: false,
                            isConfiguredCoordinates: mapsArray[0].hasOwnProperty('coordinates') && mapsArray[0].coordinates.hasOwnProperty('extent'),
                        };    
                        dispatch({type: MAP_SELECTED, mapSelected: mapobj});
                    }
                    
                })
                .catch(displayErrorFromAxios.bind(null, dispatch))
        })
        .catch(displayErrorFromAxios.bind(null, dispatch))
    }
};

export const editAddedDevice = (index) => {
    return (dispatch, getState) => {
        const deviceObject = getState().deviceManager.devicesBatch[index];
        dispatch(loadFormDevice(deviceObject, {}, EDIT_DEVICE_MANUALLY_ADDED));
        dispatch(loadEditScreen(true, index));
    }
}

export const loadEditScreen = (editScreen, indexDevice) => {
    return dispatch => {
        dispatch({type: LOAD_SCREEN, editScreen, indexDevice})
    }
};

export const saveDeviceBatch = (idDevice, device = null) => {
    return (dispatch, getState) => {
        const currentDevice = device ? device : getState().deviceManager.templateForm.template;
        let indexDevice = getState().deviceManager.devicesBatch.findIndex(item => item._id === idDevice);
        dispatch({type: LOAD_EDITED_DEVICE, deviceModified: currentDevice, indexDevice: indexDevice});
    }
}

export const saveNewDevice = (indexDevice, onlyDevice = false) => {
   return async (dispatch, getState) => {
        const currentDevice = getState().deviceManager.templateForm.template;
        const deviceOptions = {...getState().deviceManager.deviceOptions};
        let {_id, deviceId, deviceIdStr, template, configuration_state, status, operation_status, ...device} = currentDevice;

        dispatch(detectSaveChanges(false));
        if(onlyDevice){
            device.templateId = '';
            device.template = '';
        }

        if(!isNaN(_id)){
            let now = new Date();
            const currentDate = new Date(now).toISOString();
            device.dateInstalled = currentDate;
        }

        try {
            // saving the changes of new device
            const responseDevice = !isNaN(_id) ? await DeviceManagementService.instance().post(`/devices`, device):
                                    await DeviceManagementService.instance().patch(`/devices/${_id}`, device);

            // loading the new changes
            const responseCurrentDevice = await DeviceManagementService.instance().get(`/devices/${responseDevice.data._id}`);
            const updatedDevice = {...responseCurrentDevice.data, template: onlyDevice?'':currentDevice.template};

            dispatch({type: LOAD_EDITED_DEVICE, deviceModified: updatedDevice, indexDevice: indexDevice});
            dispatch(loadFormDevice(updatedDevice, {}, EDIT_DEVICE_MANUALLY_ADDED));
            const deviceId = responseCurrentDevice.data._id;
            if(!deviceOptions[deviceId]){
                const options = {...deviceOptions[indexDevice+""]};
                delete deviceOptions[indexDevice+""];
                deviceOptions[deviceId] = {...options};
                dispatch({type: UPDATE_DEVICE_OPTIONS, deviceOptions: deviceOptions});
            }

            dispatch(displayDialogNotification(ALERT_SUCCESS, `Device saved: `, `${device.title} was saved successfully`));
        } catch (error) {
            let detailsMessage = `Error: Error saving the device`;
            if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
                detailsMessage = error.response.data.errors[0].detail;
            }
            if (error.response && error.response.config && error.response.config.url && error.response.config.url.indexOf('zone-data') >= 0) {
                detailsMessage = "Error creating auto zone: An error occurred with auto zone creation.";
            }
            dispatch(displayDialogNotification(ALERT_ERROR, `${device.title}: `, detailsMessage));
        }    
    }
}

export const addAntennaBatch = (indexDevice) => ({type: ADD_ANTENNA_BATCH, indexDevice: indexDevice});
export const deleteAntennaBatch = (indexDevice, indexAntenna) => ({type: DELETE_ANTENNA_BATCH, indexDevice: indexDevice, indexAntenna: indexAntenna});

export const updateDeviceCoordinates = (idDevice, coordinates, dragAllow) => {
    return (dispatch, getState) => {
        const devicesBatch = getState().deviceManager.devicesBatch;
        const isDeviceChanging = getState().deviceManager.isDeviceChanging;
        const siteId = getState().deviceManager.siteId;
        const mapId = getState().deviceManager.mapId;

        const maps = getState().deviceManager.maps;

        if(!isDeviceChanging){
            dispatch(detectSaveChanges(true));
        }

        let indexDevice = getState().deviceManager.devicesBatch.findIndex(item => item._id === idDevice);
        let currentDevice = devicesBatch[indexDevice];


        //checking site and map
        if(!currentDevice.siteId || currentDevice.siteId === "" || !currentDevice.location.mapID || currentDevice.location.mapID === ""){

            // getting site and map name
            const mapObjectSelected = maps[siteId] && maps[siteId].find(item => item.id === mapId);

            // assigning siteId, mapId, siteName and mapName
            currentDevice.siteId = siteId;
            currentDevice.location.mapID = mapId.toString();
            currentDevice.siteName = mapObjectSelected?.siteName || '';
            currentDevice.location.mapName = mapObjectSelected?.name || '';
        }

        let rfidAntennas = currentDevice.rfidAntennas || [];

        rfidAntennas = currentDevice.rfidAntennas.map(antenna => {
            let coordinatesDevice = !dragAllow? coordinates: {};
            return {...antenna, location: {...antenna.location, ...coordinatesDevice, mapID: mapId.toString()}};
        });

        let deviceModified = {...currentDevice, location: {...currentDevice.location, ...coordinates}, rfidAntennas: rfidAntennas };
        dispatch({type: LOAD_EDITED_DEVICE, deviceModified, indexDevice});
    }
};

export const updateAntennaCoordinates = (idDevice, indexAntenna, coordinates) => {
    return (dispatch, getState) => {
        const devicesBatch = getState().deviceManager.devicesBatch;
        const isDeviceChanging = getState().deviceManager.isDeviceChanging;
        const mapId = getState().deviceManager.mapId;

        if(!isDeviceChanging){
            dispatch(detectSaveChanges(true));
        }

        let indexDevice = getState().deviceManager.devicesBatch.findIndex(item => item._id === idDevice);
        let currentDevice = devicesBatch[indexDevice];
        let currentAntennas = getState().deviceManager.devicesBatch[indexDevice].rfidAntennas.map((antenna, index) => {
            if(indexAntenna === index){
                return {...antenna, location: {...antenna.location, ...coordinates, mapID: mapId.toString()}};
            }else{
                return {...antenna, location: {...antenna.location, mapID: mapId.toString()}};
            }
        });

        const checkedAll = currentAntennas.reduce((previousValue, currentAntenna) => currentAntenna.autoZone && previousValue, true);

        let deviceModified = {...currentDevice, rfidAntennas: currentAntennas, checkedAll: checkedAll};

        dispatch({type: LOAD_EDITED_DEVICE, deviceModified, indexDevice});
    }
};

export const updateAntennaAutozone = (idDevice, indexAntenna, autoZone) => {
    return (dispatch, getState) => {
        const devicesBatch = getState().deviceManager.devicesBatch;
        let indexDevice = devicesBatch.findIndex(item => item._id === idDevice);
        let currentDevice = devicesBatch[indexDevice];
        let currentAntennas = devicesBatch[indexDevice].rfidAntennas.map((antenna, index) => indexAntenna === index ? {...antenna, autoZone: autoZone}: antenna);
        const checkedAll = currentAntennas.reduce((previousValue, currentAntenna) => currentAntenna.autoZone && previousValue, true);
        let deviceModified = {...currentDevice, rfidAntennas: currentAntennas, checkedAll: checkedAll};        
        dispatch({type: LOAD_EDITED_DEVICE, deviceModified, indexDevice});
    }
};

export const unloadSiteAnMap = () => ({type: LOAD_SITE_MAP, siteDevice: {}, mapDevice: {}});

export const getZones = (mapId) => {
    return async dispatch => {
        if (mapId === undefined) return;
        try{
            const responseZones = await LCSMapService.instance().get(`/zones?mapId=${mapId}`);
            dispatch({type: LOAD_MAP_ZONES, mapId: mapId, zones: interpretZonesFromDefinition(responseZones.data)});
        }catch(e){
            displayErrorFromAxios.bind(null,dispatch);
        }
    }
};

export const interpretZonesFromDefinition = (zones) => {
    return zones.map(zone => ({
        _id: zone._id,
        name: zone.name,
        color: zone.color && hexColorToRGBArray(zone.color),
        shape: zone.shape && zone.shape.geometry?.coordinates[0],
        mapId: zone.mapId,
        type: zone.type || null,
        priority: zone.priority || 16000,
        raw: zone
    }));
};

export const updateZoneName = (zoneId, zoneName) => {
    return (dispatch, getState) => {
        const mapId = getState().deviceManager.mapId;
        const zones = getState().deviceManager.zones[mapId];

        if(zones.find(z => z._id === zoneId)){
            const updatedZones = [...zones].map(zone => zone._id === zoneId ? {...zone, name: zoneName}: zone);
            dispatch({type: UPDATE_MAP_ZONES, mapId: mapId, updatedZones: updatedZones});
        }else{
            //add new zone
            const newZone = {_id: zoneId, name: zoneName, mapId: mapId, priority: -1, color: hexColorToRGBArray(LIGHT_BLACK_COLOR)};
            const updatedZones = [...zones, newZone];
            dispatch({type: UPDATE_MAP_ZONES, mapId: mapId, updatedZones: updatedZones});
        }
    }
}

export const deleteEmptyNewZones = () => {
    return (dispatch, getState) => {
        const mapId = getState().deviceManager.mapId;
        const devicesBatch = getState().deviceManager.devicesBatch;
        const zonesWithAntennas = getState().deviceManager.zonesWithAntennas;
        const zones = getState().deviceManager.zones[mapId];
        let listZoneIds = [];

        for(var i = 0; i < devicesBatch.length; i++){
            const rfidAntennas = devicesBatch[i].rfidAntennas;
            for(var j = 0; j < rfidAntennas.length; j++){
                const antenna = rfidAntennas[j];
                if(antenna.autoZone && listZoneIds.indexOf(antenna.autoZone) === -1){
                    listZoneIds.push(antenna.autoZone);
                }
            }
        }

        const updatedZones = zones.filter(z => (Object.keys(zonesWithAntennas).indexOf(z._id) > -1 || listZoneIds.indexOf(z._id) > -1) || z.priority !== -1);
        dispatch({type: UPDATE_MAP_ZONES, mapId: mapId, updatedZones: updatedZones});
    }
}

export const getZoneGroups = (siteId) => {
    return LCSMapService.instance().get(`/zonegroups?siteId=${siteId}`);
};

const hexColorToRGBArray = (hex) => {
    return [1,3,5].map(i=>parseInt(hex.slice(i,i+2),16));
};

// const interpretShapeString = (shapeString) => {
//     let delimited = shapeString.split(';');
//     let n = parseInt(delimited[0]);
//     let shape = [];
//     for(let i = 1; i <= 2*n; i+=2){
//         shape.push([ parseFloat(delimited[i]), parseFloat(delimited[i+1]) ]);
//     }
//     shape.push([].concat(shape[0]));
//     return shape;
// };


//-------------------------End Adding manually devices----------------------------

//------------------------- Update selected filters----------------------------------
export const selectFilter  = (type, filters) => {
    return dispatch =>{
        if(type === 'devices'){
            dispatch(resetPaginationInfo())
        }
        dispatch({type: UPDATE_SELECTED_FILTERS, table: type, filters: filters});
         //loading devices or templates list
        dispatch(type === 'devices' ? loadDeviceList(): loadTemplateList());
    }
};

export const resetAllFilters = (type = null) => {
    // type can be devices or templates
    return dispatch =>{
        dispatch ({type: UPDATE_SELECTED_FILTERS, table: type, filters: []});
        //loading devices or templates list, after reset filters 
        if(type){
            dispatch(type === 'devices' ?loadDeviceList(): loadTemplateList());
        }
    }
};

export const unSelectFilter = (type, itemKey, filter) => {
    return (dispatch, getState) => {
        const state = getState();
        const currentSelectedFilter = state.deviceManager.selectedFilters[type].slice(0);

        let removeIndex = currentSelectedFilter.map((item) => item[itemKey[0]]).indexOf(filter[0]);
        currentSelectedFilter.splice(removeIndex,1);

        dispatch({type: UPDATE_SELECTED_FILTERS, table: type, filters: currentSelectedFilter});
        dispatch(type === 'devices' ? loadDeviceList():loadTemplateList());
    }
};


function buildQueryString(mapSelected,selectedFilters){

    // getting applied filters
    const currentSelectedFilters = selectedFilters['devices'];
    let parameters = getFilterValues(currentSelectedFilters);
    //making query for select device and siteId
    const querySiteId = (mapSelected && mapSelected.siteId)?`&siteId=${mapSelected.siteId}`:'';
    const queryMapId = (mapSelected && mapSelected.id)?`&location.mapID=${mapSelected.id}`:'';

    const queryParams = (parameters!=='') ? `&${parameters}`:'';
    let query = '?' + querySiteId + queryMapId + queryParams;
    return query;
}

const getFilterValues = selectedFilters => {
    let parameters ='';
    //create a string with the parameters that are sent to it
    if (selectedFilters.length > 0) {
        selectedFilters.forEach(function(item) {
            const currentFilter = item;
            Object.keys(currentFilter).forEach(function (subKey) {
                let subKeyCast;
                switch (subKey) {
                    case 'location':
                        subKeyCast = 'location.mapID';
                        break;
                    case 'status':
                        subKeyCast = 'status.title';
                        break;
                    default:
                        subKeyCast = subKey;
                        break;
                }
                if (parameters === ''){
                    parameters = subKeyCast + "=" + currentFilter[subKey];
                }
                else {
                    parameters = parameters + "&" + subKeyCast + "=" + currentFilter[subKey];
                }
            })
        });
    }
    return parameters;
}

let detailsDataUpdateInterval = null;
export const startPoolingDetails=(deviceId)=>{
    return async (dispatch) => {
        if (detailsDataUpdateInterval != null) {
            clearInterval(detailsDataUpdateInterval);
            detailsDataUpdateInterval=null;
        }
        if(detailsDataUpdateInterval==null){
            detailsDataUpdateInterval=setInterval(()=>dispatch(checkDeviceDetailsData(deviceId)),5000);
        }
    }

};

export const stopPoolingDetails=()=>{
    return async () => {
        if (detailsDataUpdateInterval != null) {
            clearInterval(detailsDataUpdateInterval);
            detailsDataUpdateInterval=null;
        }
    }
};
export const checkDeviceDetailsData = (deviceId) => {
    return async (dispatch, getState) => {
        let updateOperation = null;
        let inProgress = getState().deviceManager.detailsDataUpdateInProgress;
        if(!inProgress){
            dispatch({type: UPDATE_DETAILS_DATA_OPERATION, detailsDataUpdateInProgress: true});
            try{
                updateOperation = await DeviceManagementService.instance().get( `/devices/${deviceId}`)
                if(updateOperation.status === 200 && updateOperation.data && updateOperation.data !== ""){
                    let templateId = updateOperation.data.templateId;
                    let additionalFields = {};
                    //getting template title
                    if(templateId && templateId !== ""){

                        const responseTemplate = await DeviceManagementService.instance().get(`/devices/templates/${templateId}?includeFields=title,createBy,createDate`);

                        if(responseTemplate.data !== ""){
                            additionalFields.templateTitle = responseTemplate.data.title;
                            additionalFields.templateCreateBy = responseTemplate.data.createBy;
                            additionalFields.templateCreateDate = responseTemplate.data.createDate;
                        }
                    }
                    //building the device
                    let device = {...updateOperation.data, ...additionalFields};
                    dispatch({type: LOAD_DEVICE_DETAILS_DATA, detailsDevice: device});
                }
            } catch (e) {
                updateOperation = null;
            } finally {
                dispatch({type: UPDATE_DETAILS_DATA_OPERATION, detailsDataUpdateInProgress: false})
            }
        }
    }
};

export const loadDeviceDetailsData = (deviceID) => {
    return async (dispatch) => {
        dispatch({type:SET_FETCHING_DETAILS, isFetchingDetails: true});
        // Make call to service for get data about devices.
        try {
            const response = await DeviceManagementService.instance().get( `/devices/${deviceID}`)
            if(response.data !== ""){
                let templateId = response.data.templateId;
                let aditionalFields = {};
                //getting template title
                if(templateId && templateId !== ""){

                    const responseTemplate = await DeviceManagementService.instance().get(`/devices/templates/${templateId}?includeFields=title,createBy,createDate`);
    
                    if(responseTemplate.data !== ""){
                        aditionalFields.templateTitle = responseTemplate.data.title;
                        aditionalFields.templateCreateBy = responseTemplate.data.createBy;
                        aditionalFields.templateCreateDate = responseTemplate.data.createDate;
                    }
                }
                //building the device
                let device = {...response.data, ...aditionalFields};
                dispatch({type: LOAD_DEVICE_DETAILS_DATA, detailsDevice: device});
            }else{
                dispatch({type: LOAD_DEVICE_DETAILS_DATA, detailsDevice: null});
            }   
        } catch (error) {
            dispatch(displayErrorFromAxios.bind(null,dispatch));
        }
        dispatch({type: SET_FETCHING_DETAILS, isFetchingDetails: false});
    }
};

// Load data for firmware history.
export const loadFirmwareHistory = (deviceId) => {
    return async (dispatch) => {
        dispatch({type:SET_FIRMWARE_ERROR, errorMsg: null, error: false})
        dispatch({type:IS_LOADING_FIRMWARE_VERSIONS_DEVICE, isLoadingFirmwareList: true})
        const response = await DeviceManagementService.instance().get('/devices/' + deviceId + '/firmware/')
            .catch((e) => {
                const response = e.response;
                const data = response && response.data;
                const message = data && ( data.errors && (data.errors[0].title+ " - " + data.errors[0].detail));
                dispatch({type:SET_FIRMWARE_ERROR, errorMsg: message, error: true})
                dispatch({type:IS_LOADING_FIRMWARE_VERSIONS_DEVICE, isLoadingFirmwareList: false})
            });
        if (response && response.data) {
            const firmwareList = response.data;
            // firmwareList.unshift("Select a version");
            dispatch({type: LOAD_FIRMWARE_VERSIONS_DEVICE, deviceId: deviceId, firmwareList: firmwareList});
            dispatch({type:IS_LOADING_FIRMWARE_VERSIONS_DEVICE, isLoadingFirmwareList: false})
        }
    }
};

export const upgradeFirmware = (objectVersions) => {
    return async (dispatch) => {
        let data = {
            operation: UPGRADE_FIRMWARE,
            //args : {version: objectVersions.version}
            args : {fileId: objectVersions.fileId,version:objectVersions.version}
        };

        let parameters = 'deviceId=' + objectVersions.ids.join('&deviceId=');
        try {
            await DeviceManagementService.instance().post(`/devices/operations?${parameters}`, data);
        } catch (error) {
            dispatch(displayErrorFromAxios.bind(null,dispatch));
        }
    }
}

export const changeStatusModalDetails = (status) => ({type:SHOW_MODAL_DETAILS, showModalDetails: status});
export const changeStatusModalEditDevices = (status) => ({type: SHOW_MODAL_EDIT_DEVICE, showModalEditDevice: status});

//operations with columns

export const deviceMoveColumn = (key, dragIndex,hoverIndex) => ({type:DEVICE_MOVE_COLUMN, key, dragIndex, hoverIndex});

export const deviceToggleColumn = (key, index,enabled) => ({type: DEVICE_TOGGLE_COLUMN, key, index, enabled});

export const deviceToggleAllColumns = (key, enabled) => ({type: DEVICE_TOGGLE_ALL_COLUMNS, key, enabled});

export const getOperationOptions = (device, isOnCloud=null) => {
    let options = {};
    if(device.operation_status && device.operation_status.current_status === OPE_STATUS_PENDING){
        // when there is a PENDING OPERATION
        options = Object.keys(OPERATIONS_AVAILABLE_MAP).reduce((acc, op) => ({...acc, [op]: OPERATIONS_AVAILABLE_MAP[op].indexOf(OPE_STATUS_PENDING) > -1}),{});
    }else{
        // when there is not a PENDING OPERATION
        try {
            const statusTitle = device.status.title;
            if(LIST_KNOWN_STATUS.indexOf(statusTitle)>-1){
                // status title declared
                options = Object.keys(OPERATIONS_AVAILABLE_MAP).reduce((acc, op) => ({...acc, [op]: OPERATIONS_AVAILABLE_MAP[op].indexOf(statusTitle) > -1}),{});
            }else{
                // status title undeclared
                options = Object.keys(OPERATIONS_AVAILABLE_MAP).reduce((acc, op) => ({...acc, [op]: OPERATIONS_AVAILABLE_MAP[op].indexOf(OPE_STATUS_PUBLISHING) > -1}),{});
            }
        } catch (error) {
            // status undefined
            options = Object.keys(OPERATIONS_AVAILABLE_MAP).reduce((acc, op) => ({...acc, [op]: OPERATIONS_AVAILABLE_MAP[op].indexOf(OPE_STATUS_NA) > -1}),{});
        }
    }
    // Exceptions for isOncloud option
    if(isOnCloud){
        options.initialize = false;
        options.reset = false;
    }
    return options;
}

export const getSubMenuOptions = (device) => {
    let subMenuOptionsDefault = {
        configureLogs: true,
        downloadLogs: true,
        historical: true
    };

    if(device.status.title===OPE_STATUS_NA||device.status.title===''||device.status.title==null){
        subMenuOptionsDefault.configureLogs = false;
        subMenuOptionsDefault.downloadLogs = false;
    } else if(device.status.title===OPE_STATUS_FAILURE){
        subMenuOptionsDefault.configureLogs = false;
        subMenuOptionsDefault.downloadLogs = false;
    }

    return subMenuOptionsDefault;
}

export const updateColumnWidth = (itemKey, columWidth,tableScreen) => {
    return (dispatch, getState) => {
        switch (tableScreen) {
            case LANDING_TEMPLATE_TABLE:
                dispatch({type:UPDATE_TEMPLATE_COLUMN_WIDTH, data: {itemKey:itemKey,newSize:columWidth}});
                break;

            case LANDING_DEVICE_TABLE:
            default:
                dispatch({type:UPDATE_COLUMN_WIDTH, data: {itemKey:itemKey,newSize:columWidth}});
                break;
        }
    }
};

export const sortingByColumns = (sortIndex, direction) => {
    return (dispatch, getState) => {
        dispatch({type:SORT_COLUMN, sortIndex: sortIndex});
        dispatch({type:SET_FETCHING, isFetching: true});
        //Get Pagination Info
        let paginationInfo = getState().deviceManager.paginationInfo;

        // Make call to service for get data about devices.
        const mapSelected = getState().deviceManager.mapSelected;

        // getting applied filters
        const currentSelectedFilters = getState().deviceManager.selectedFilters['devices'];
        let parameters = getFilterValues(currentSelectedFilters);

        //const deviceType = getState().deviceManager.deviceType;

        //making query for select device and siteId
        //const queryDeviceType = `deviceType=${deviceType}`;
        const querySiteId = (mapSelected && mapSelected.siteId)?`&siteId=${mapSelected.siteId}`:'';
        const queryMapId = (mapSelected && mapSelected.id)?`&location.mapID=${mapSelected.id}`:'';

        let queryParams = (parameters!=='') ? `&${parameters}`:'';
        // const sort = direction === -1 ? 'desc' : 'asc';
        // queryParams += '&sort='+direction;
        let indexColumn = sortIndex;
        if(indexColumn === 'status'){
            indexColumn += '.title'
        }

        if(indexColumn === 'location'){
            indexColumn += '.mapName'
        }

        let orderSort = direction === -1 ? '-' : '';
        let querySort = '&sort_by=' + orderSort + indexColumn;

        const query = '?' + querySiteId + queryMapId + queryParams + querySort;
        const sort = {index: indexColumn, direction: direction};

        return DeviceManagementService.instance().get(`/devices${query}&offset=${paginationInfo.offset}&limit=${paginationInfo.limit}&include_metadata`)
            .then(response => {
                const deviceList = response.data.results.map(item=>({...item, template:""}));
                // loading data in table
                dispatch({type: LOAD_DEVICE_LIST, deviceList: deviceList});
                dispatch({type:SET_FETCHING, isFetching: false});
                paginationInfo.sort = sort;
                const status = response.data.status;
                paginationInfo = changePaginationInfo(status, paginationInfo);
                // dispatch({type:SET_PAGINATION_INFO, paginationInfo: {...paginationInfo, sort: sort}});

            }).catch(displayErrorFromAxios.bind(null,dispatch))
            .finally(()=>{
                dispatch(setPaginationInfo(paginationInfo));
                dispatch({type:SET_FETCHING, isFetching: false});
            });
    }
};

export const unloadDevicesOptions = () => ({type: UNLOAD_DEVICE_OPTIONS, devicesBatch: {}});

export const loadDevicesOptions = devicesBatch => {
    return (dispatch, getState) => {
        const numberDeviceOptions = Object.keys(getState().deviceManager.deviceOptions).length;
        if(numberDeviceOptions === 0){
            let newDeviceOptions = {};
            devicesBatch.forEach(device => {
                let conditionEnableDrag = true;

                device.rfidAntennas.forEach(antenna => {
                    if(!antenna.disabled && (device.location?.x !== antenna.location?.x || device.location?.y !== antenna.location?.y)){
                        conditionEnableDrag = false;
                    }
                });
                newDeviceOptions[device._id] = {"enableDrag": conditionEnableDrag};
            });
            dispatch({type: LOAD_DEVICE_OPTIONS, deviceOptions: newDeviceOptions});
        }
    }
}

export const changeDeviceOptions = (enableDrag, deviceId) => {
    return (dispatch, getState) => {
        const deviceOption = getState().deviceManager.deviceOptions[deviceId];
        const devicesBatch = getState().deviceManager.devicesBatch;
        let indexDevice = devicesBatch.findIndex(item => item._id === deviceId);
        const currentDevice = {...devicesBatch[indexDevice]};
        if(enableDrag){
            let locationDeviceCoordinates = {x: currentDevice.location.x, y: currentDevice.location.y, z: currentDevice.location.z};
            dispatch(updateDeviceCoordinates(deviceId, locationDeviceCoordinates, !enableDrag));
            dispatch({type: CHANGE_DEVICE_OPTIONS, deviceId: deviceId, deviceOption: {'enableDrag': enableDrag}});
        }else{
            dispatch({type: CHANGE_DEVICE_OPTIONS, deviceId: deviceId, deviceOption: {...deviceOption, 'enableDrag': enableDrag}});
        }
    }
}

export const updateConfigurationOption = ( ) => {
    return async (dispatch) => {
        return DeviceManagementService.instance().get(`/devices/cloud`)
            .then(response => {
                //let MWE_HTTPS = response && response.data.MWE_HTTPS;
                //let isOnCloud = MWE_HTTPS && MWE_HTTPS === "false";
                let MWE_LOCAL_INCOMING_HTTPS = response && response.data.MWE_LOCAL_INCOMING_HTTPS;
                let isOnCloud = !(MWE_LOCAL_INCOMING_HTTPS && MWE_LOCAL_INCOMING_HTTPS === "true");
                dispatch({type: CONFIGURATION_CLOUD, isOnCloud: isOnCloud});
            })
            .catch( (error)=>{
                dispatch({type: CONFIGURATION_CLOUD, isOnCloud: true});
            });
    }
}

export const requestSnapshotInfoBySite = (siteId) => {
    return async (dispatch) => {
        let errorMsg = null;
        const response = await DeviceManagementService.instance().get(`/devices/snapshots?siteId=${siteId}`)
            .catch(error => {
                console.log("Error: ", error.toJSON());

                errorMsg = error.name + " - " + error.message;
            });
        let siteInformation = {};
        if(response && response.data){
            const dataLength = response.data.length;
            siteInformation = dataLength > 0 ? response.data[dataLength-1] : {};
        }
        dispatch({type: LOAD_SITE_SNAPSHOT_INFORMATION, siteInformation: siteInformation, errorMsg: errorMsg});
        dispatch({type: GETTING_SITE_INFO,loadingSiteInfo:false});
    }
};



async function checkDevicesDMS(mapId) {
    const message = {
        devicesOnMap:null,
        error:null
    };
    try {
         const devicesDMS=await DeviceManagementService.instance().get(`/devices?location.mapID=${mapId}&fields=_id&limit=1`);
        //const devicesDMS = await DeviceManagementService.instance().get(`/devices?location.mapID=${mapId}&fields=_id`);

        if (devicesDMS != null && devicesDMS.data && Array.isArray(devicesDMS.data)) {
            message.devicesOnMap = devicesDMS.data.length>0;
        }

    } catch (e) {
        message.error = e;
        console.log(e);
    }

    return message;
}

async function checkDevicesAppliances(mapId) {
    const message = {
        devicesOnMap:null,
        error:null
    };
    try {
        const deviceCollections = ['rfidReaders', 'wherePorts', 'bleBeacons', 'locationSensors', 'healthReferenceTags', 'timeReferenceTags'];

        const devicesAppliances = await ICService.instance().get(`/appliances?type=ALL`);

        if (devicesAppliances != null && devicesAppliances.data && Array.isArray(devicesAppliances.data)) {

            const foundDevicesOnMap = devicesAppliances.data.find(appliance => {
                if (appliance != null && appliance.configuration != null) {
                    const foundDevices = deviceCollections.find(deviceName => {

                        if (appliance.configuration[deviceName] != null && Array.isArray(appliance.configuration[deviceName])) {

                            const devicesInMap = appliance.configuration[deviceName].find(device => {
                                if (device != null
                                    && device.location != null
                                    && device.location.mapID === mapId
                                ) {
                                    return true;
                                }
                                return false;
                            });
                            if (devicesInMap != null) {
                                return true;
                            }

                        }
                        return false;
                    });

                    if (foundDevices != null) {
                        return true;
                    }
                    return false;
                }
                return false;
            });

            if (foundDevicesOnMap != null) {
                message.devicesOnMap = true;
            } else {
                message.devicesOnMap = false;

            }
        }

    } catch (e) {
        message.error = e;
        console.log(e);
    }
    return message;
}


export const checkDevicesMap= (mapId) => {
    return async (dispatch) => {

        // Get number of devices from DMS(mongo devices collection).
        const devicesDMS = await checkDevicesDMS(mapId);
        // Get number of devices from ICS(mongo appliances collection).
        const devicesAppliances = await checkDevicesAppliances(mapId);

        const result = {error:null,enableCoordinates:null}
        if( devicesDMS.error !==null || devicesAppliances.error!==null ){
            result.error = devicesDMS.error ||devicesAppliances.error;
            //dispatch(displayAllErrorFromAxios(result.error));
            displayErrorFromAxios(dispatch,result.error);
        }else{
            if (devicesDMS.devicesOnMap === false && devicesAppliances.devicesOnMap === false) {
                result.enableCoordinates = true;
            }else{
                result.enableCoordinates = false;
            }
        }
        return result;
    }
}

export const getDeviceStatusHistory = (deviceId, startTime, stopTime, fromId, toId) => {
    return async (dispatch, getState) => {
        dispatch({type:LOAD_FETCH_HISTORY_STATUS, fetchingHistory: true});
        let query = '';
        const filters = getState().deviceManager.filtersHistory;
        if(startTime && startTime !== ''){
            query = query + '&startTime='+startTime;
        } else if(fromId) {
            query = query + '&startTime='+filters[fromId].value;
        }
        if(stopTime && stopTime !== ''){
            query = query + '&stopTime='+stopTime;
        } else if(toId) {
            query = query + '&stopTime='+filters[toId].value;
        }
        const deviceStatus=await DeviceManagementService.instance().get(`/devices/${deviceId}/detailed_status/?sort_by=-time${query}`);
        dispatch({type: LOAD_STATUS_HISTORY, statusHistory: deviceStatus.data});
        dispatch({type:LOAD_FETCH_HISTORY_STATUS, fetchingHistory: false});
    }
};

export const getDeviceStatus = (deviceId) => {
    return async dispatch => {
        dispatch({type: LOAD_FETCH_DEVICE_STATUS, fetchDeviceStatus: true});
        // const deviceStatus=await DeviceManagementService.instance().get(`/devices/${deviceId}/detailed_status/latest`);
        const deviceStatus=await DeviceManagementService.instance().get(`/devices/${deviceId}/detailed_status/?sort_by=-time&limit=1`);
        const data = deviceStatus.data.length > 0 ? deviceStatus.data[0] : null;
        if(deviceStatus) {
            dispatch({type:LOAD_DEVICE_STATUS, deviceStatus: data})
        }
        dispatch({type: LOAD_FETCH_DEVICE_STATUS, fetchDeviceStatus: false});
    }
};

export const changeDateFilterStatusHistory = (fromId, newDateTimeFrom,toId, newDateTimeTo, deviceId) => {
    return (dispatch, getState) => {
        let filtersHistory = {...getState().deviceManager.filtersHistory};
        if(newDateTimeFrom && newDateTimeFrom != null) {
            filtersHistory[fromId].value = newDateTimeFrom;
        }
        if(newDateTimeTo && newDateTimeTo != null) {
            filtersHistory[toId].value = newDateTimeTo;
        }

        dispatch({type: CHANGE_HISTORY_DATE_FILTER, filtersHistory: filtersHistory});
        // dispatch(getDeviceStatusHistory(deviceId, newDateTimeFrom, newDateTimeTo));
    }
};

export const changeRangeFilterStatusHistory = (filterKey,filterValue) => {
    return (dispatch, getState) => {
        let filtersHistory = {...getState().deviceManager.filtersHistory};
        filtersHistory["datetimeFilters"]["statusHistory"][filterKey].value= filterValue;
        dispatch({type: CHANGE_HISTORY_DATE_FILTER, filtersHistory: filtersHistory})
    }
};

export const changeDateValueStatusHistory = (key, newDateTime) => {
    return (dispatch, getState) => {
        let filtersHistory = {...getState().deviceManager.filtersHistory};
        filtersHistory[key].value = newDateTime;

        dispatch({type: CHANGE_HISTORY_DATE_FILTER, filtersHistory: filtersHistory})
    }
}

export const setConfigurationDevices = (devices) => {
    return async (dispatch, getState) => {
        let fetchingLogsSave = {...getState().deviceManager.fetchingLogsSave, [devices[0]]: true};

            try {
                dispatch({type: SET_FETCHING_LOGS_SAVE, fetchingLogsSave: fetchingLogsSave});
                if(devices.length > 1){
                    dispatch({type: SET_FETCHING_LOGS, fetchingLogs: true});
                }
                // let deviceIds = devices.toString();
                // let query = `?deviceId=${deviceIds}`;
                let query = `?`;
                for(let i =0; i<devices.length;i++){
                    query += `deviceId=${devices[i]}`
                    if(i < devices.length - 1){
                        query+='&'
                    }
                }

                const logLevelParams = getState().deviceManager.logLevel;
                await DeviceManagementService.instance().put(`/devices/logs${query}`, logLevelParams);
                dispatch({type: SET_LOGS_CONFIGURATION_SUCCESSFULLY, logsSavedSuccessfully: true});
                dispatch(loadDeviceList());
                delete fetchingLogsSave[devices[0]];
                dispatch({type: SET_FETCHING_LOGS_SAVE, fetchingLogsSave: fetchingLogsSave});
                dispatch({type: SET_FETCHING_LOGS, fetchingLogs: false});
                dispatch({type: SET_LOGS_CONFIGURATION_SUCCESSFULLY, logsSavedSuccessfully: false});
            } catch (error) {
                dispatch(setLogConfigurationError(true, "Cannot publish the configuration"));
                delete fetchingLogsSave[devices[0]];
                dispatch({type: SET_FETCHING_LOGS_SAVE, fetchingLogsSave: fetchingLogsSave});
                dispatch({type: SET_FETCHING_LOGS, fetchingLogs: false});
            }
    }
};

const CancelToken = axios.CancelToken;
let cancelRequest = null;
const CANCELLATION_MESSAGE = "Cancelled";

export const downloadDeviceLogs = (deviceId, typeLogs, name) => {
    return async (dispatch) => {
        try {
            dispatch({type: SET_FETCHING_DOWNLOAD_LOGS, fetchingDownloadLogs:{[deviceId]: {name: name, loading: true}}});
            let index = typeLogs.indexOf('syslog');
            if(index !== -1) {
                const path1 = `/devices/${deviceId}/logs/syslog`;
                const res = await DeviceManagementService.instance().get(path1, {cancelToken: new CancelToken(c=>{cancelRequest = c;})});
                if (res) {
                    const path2 = `/devices/${deviceId}/logs/syslog/history?sort_by=-time&limit=1`;
                    const resultDownload = await DeviceManagementService.instance().get(path2, {cancelToken: new CancelToken(c=>{cancelRequest = c;})});
                    const downloadLog = resultDownload.data[0];
                    if (downloadLog && !downloadLog.logData?.code ) {
                        downloadDeviceFileLogs(downloadLog.logData);
                    } else {
                        dispatch(setDownloadError(true, downloadLog.logData.message));
                    }
                }
            }

            let indexRadio = typeLogs.indexOf('radioPacketLog');
            if(indexRadio !== -1) {
                const path1 = `/devices/${deviceId}/logs/radioPacketLog`;
                const res = await DeviceManagementService.instance().get(path1, {cancelToken: new CancelToken(c=>{cancelRequest = c;})});
                if (res) {
                    const path2 = `/devices/${deviceId}/logs/radioPacketLog/history?sort_by=-time&limit=1`;                    
                    const resultDownload = await DeviceManagementService.instance().get(path2, {cancelToken: new CancelToken(c=>{cancelRequest = c;})});
                    const downloadLog = resultDownload.data[0];
                    if (downloadLog && !downloadLog.logData?.code) {
                        downloadDeviceFileLogs(downloadLog.logData);
                    } else {
                        dispatch(setDownloadError(true, downloadLog.logData.message));
                    }
                }
            }

        }catch(e) {
            if(e.message === CANCELLATION_MESSAGE){
                dispatch(displayErrorDialog({message: 'The operation was cancelled'}));
            }else{
                dispatch(displayErrorFromAxios.bind(null,dispatch));
            }
        }finally{
            dispatch({type: SET_FETCHING_DOWNLOAD_LOGS, fetchingDownloadLogs:{}});
        }
    }
};


export const deleteLogs = (deviceId, type) => {
    return async (dispatch) => {
        try{
            const path = `/devices/${deviceId}/logs/${type}`;
            const result = await DeviceManagementService.instance().delete(path);
            if(result.status === 200){
                dispatch({type: LOGS_DELETED_STATUS, logsDeleted: true});
            }
        } catch(e) {
            if(e.message === CANCELLATION_MESSAGE){
                dispatch(displayErrorDialog({message: 'The operation was cancelled'}));
            }else{
                dispatch(displayErrorFromAxios.bind(null,dispatch));
            }
        }finally{
            dispatch({type: SET_FETCHING_DOWNLOAD_LOGS, fetchingDownloadLogs:{}});
        }
    }
}

export const cleanLogsDelete = () => ({type: LOGS_DELETED_STATUS, logsDeleted: false});

export const cancelDownloadLogs = () => {
    return dispatch => {
        if(cancelRequest){
            cancelRequest(CANCELLATION_MESSAGE);
        }
    }
}

export const cleanDownloadLogs = () => ({type: DATA_TO_DOWNLOAD, downloadLogs: null});
export const cleanDownloadTypes = () => ({type: SET_LOG_DOWNLOAD_TYPES, logsDownloadTypes: []});
export const cleanDownloadHistoryTypes = () => ({type: SET_LOG_HISTORY_TYPES, logsHistoryTypes: ['syslog']});

export const getLogsDevicesByDate = (deviceId, startTime, stopTime, fromId, toId) => {
    return async (dispatch, getState) => {
        const syslog = 'syslog';
        const radioPacketLog = 'radioPacketLog';

        dispatch({type:LOAD_FETCH_HISTORY_STATUS, fetchingHistory: true});
        let query = '';
        const filters = getState().deviceManager.filtersLogsHistory;
        const logHistoryTypes = getState().deviceManager.logsHistoryTypes;
        if(startTime && startTime !== ''){
            query = query + '&startTime='+startTime;
        } else if(fromId) {
            query = query + '&startTime='+filters[fromId].value;
        }
        if(stopTime && stopTime !== ''){
            query = query + '&stopTime='+stopTime;
        } else if(toId) {
            query = query + '&stopTime='+filters[toId].value;
        }
        let resultArray = [];

        if(logHistoryTypes.indexOf(syslog) !== -1) {
            const result = await DeviceManagementService.instance().get(`/devices/${deviceId}/logs/${syslog}/history?includeFields=time,logData.filename,logType,deviceId,logData.code,logData.message&sort_by=-time${query}`);
            if (result) {

                resultArray = resultArray.concat(result.data);
            }
        }

        if(logHistoryTypes.indexOf(radioPacketLog) !== -1) {
            const result = await DeviceManagementService.instance().get(`/devices/${deviceId}/logs/${radioPacketLog}/history?includeFields=time,logData.filename,logType,deviceId,logData.code,logData.message&sort_by=-time${query}`);
            if (result) {
                resultArray = resultArray.concat(result.data);
            }
        }

        dispatch({type: LOAD_LOG_HISTORY_DATA, logsHistoryData: resultArray});
        dispatch({type:LOAD_FETCH_HISTORY_STATUS, fetchingHistory: false});
    }
};


export const changeDateFilterDownloadsHistory = (fromId, newDateTimeFrom,toId, newDateTimeTo, deviceId) => {
    return (dispatch, getState) => {
        let filtersLogsHistory = {...getState().deviceManager.filtersLogsHistory};
        if(newDateTimeFrom && newDateTimeFrom != null) {
            filtersLogsHistory[fromId].value = newDateTimeFrom;
        }
        if(newDateTimeTo && newDateTimeTo != null) {
            filtersLogsHistory[toId].value = newDateTimeTo;
        }

        dispatch({type: CHANGE_LOG_HISTORY_DATE_FILTER, filtersLogsHistory: filtersLogsHistory});
        // dispatch(getDeviceStatusHistory(deviceId, newDateTimeFrom, newDateTimeTo));
    }
};

export const changeRangeFilterDownloadsHistory = (filterKey,filterValue) => {
    return (dispatch, getState) => {
        let filtersLogsHistory = {...getState().deviceManager.filtersLogsHistory};
        filtersLogsHistory["datetimeFilters"]["statusHistory"][filterKey].value= filterValue;
        dispatch({type: CHANGE_LOG_HISTORY_DATE_FILTER, filtersLogsHistory: filtersLogsHistory})
    }
};

export const changeDateValueDownloadsHistory = (key, newDateTime) => {
    return (dispatch, getState) => {
        let filtersLogsHistory = {...getState().deviceManager.filtersLogsHistory};
        filtersLogsHistory[key].value = newDateTime;

        dispatch({type: CHANGE_LOG_HISTORY_DATE_FILTER, filtersLogsHistory: filtersLogsHistory})
    }
};

export const changeLogType = (logTypeArray) => ({type: SET_LOG_HISTORY_TYPES, logsHistoryTypes: logTypeArray});
export const changeLogDownloadType = (logTypeArray) => ({type: SET_LOG_DOWNLOAD_TYPES, logsDownloadTypes: logTypeArray});

export const downloadSpecificLog = (deviceId, logType, logId) => {
    return async dispatch => {
        try {
            dispatch({type:LOAD_FETCH_HISTORY_STATUS, fetchingHistory: true});
            const result = await DeviceManagementService.instance().get(`/devices/${deviceId}/logs/${logType}/history?_id=${logId}`);
            if (result) {
                // dispatch({type: SET_LOG_TO_DOWNLOAD, logToDownload: result.data[0]});
            const downloadLog = result.data[0];
            if(downloadLog) {
                downloadDeviceFileLogs(downloadLog.logData);}
                dispatch({type: SET_DOWNLOADED_HISTORY_LOGS, downloadedLogs: {[logId]:true}})
            }
        } catch (error) {
            dispatch(displayErrorFromAxios.bind(null,dispatch));
        } finally{
            dispatch({type:LOAD_FETCH_HISTORY_STATUS, fetchingHistory: false});
        }
    }
};

export const clearDownloadedLogs = () => ({type: CLEAR_DOWNLOADED_LOGS})

export const updateFetchHistoryStatus = fetchingHistory => ({type: LOAD_FETCH_HISTORY_STATUS, fetchingHistory: fetchingHistory});
export const setDevicesSelected = (devicesSelected) => ({type: SET_DEVICES_SELECTED, devicesSelected});

export const setDownloadError = (error, message) => ({type: SET_DOWNLOAD_ERROR, errorDownload: {error, message}});

export const setLogConfigurationError = (error, message) => ({type: SET_LOG_CONFIGURATION_ERROR, errorLogConfiguration: {error, message}});

export const changeLogLevel = (key, value) => {
    return (dispatch, getState) => {
        let logLevel = {...getState().deviceManager.logLevel};
            switch(key) {
                case 'radioPacketLog':
                    logLevel[key] = value;
                    break;
                case 'components.radio_control':
                    const keysArr = key.split('.');
                    const arrayLogsRadio = logLevel[keysArr[0]] || [];
                    for(let i=0;i < arrayLogsRadio.length;i++){
                        if(arrayLogsRadio[i].componentName === keysArr[1]){
                            arrayLogsRadio.splice(i,1);
                            i--;
                        }
                    }
                    arrayLogsRadio.push(Object({componentName: "radio_control",level:value}));
                    logLevel[keysArr[0]] = arrayLogsRadio;
                    break;
                case 'components.cloud_agent':
                    const keys = key.split('.');
                    const arrayLogs = logLevel[keys[0]] || [];
                    for(let i=0;i < arrayLogs.length;i++){
                        if(arrayLogs[i].componentName === keys[1]){
                            arrayLogs.splice(i,1);
                            i--;
                        }
                    }
                    arrayLogs.push(Object({componentName: "cloud_agent",level:value}));
                    logLevel[keys[0]] = arrayLogs;
                    break;
                default:
                    break;
            }
        dispatch({type: CHANGE_LOG_LEVEL, logLevel})
    }
};

export const loadLogLevel = (logLevel) => ({type: CHANGE_LOG_LEVEL, logLevel});
export const getDeviceLogConfiguration = (deviceId) => {
    return async dispatch => {
        try {
            const logSettingsResponse = await DeviceManagementService.instance().get(`/devices/${deviceId}/logs`);
            if(logSettingsResponse){
                dispatch({type: SET_DEVICE_LOG_CONFIG, deviceLogConfig: logSettingsResponse.data || {}})
            }
        } catch (e) {
            dispatch(setLogConfigurationError(true, "Cannot load the log configuration for this device"))
        }
    }
};

export const cleanDeviceLogConfiguration = () => ({type: SET_DEVICE_LOG_CONFIG, deviceLogConfig: {}});

export const unsetLogLevel = () => ({type: CHANGE_LOG_LEVEL, logLevel: {}});

export const devicesDeleteMultipleRows = (devicesToDelete) => {
    return async dispatch => {
        try {
            const deviceObject = {
                data: {
                    'deviceId': devicesToDelete
                }
            };
            await DeviceManagementService.instance().delete(`/devices`,deviceObject);
        } catch (error) {
            dispatch(displayErrorFromAxios.bind(null,dispatch));
        } finally{
            dispatch(loadDeviceList());
            dispatch(reloadSitesMaps(null, null,true));
            // dispatch(setPaginationInfo());
            dispatch(resetPaginationInfo())
            dispatch({type:DEVICE_MANAGER_UPDATE_SELECTED, selectedRows:{}});
        }
    }
}

/*export const setPaginationInfo = (isMapSelected) => {
    return async (dispatch, getState) => {

        let paginationInfo = getState().deviceManager.paginationInfo;
        let nextPageAllow = paginationInfo.nextPageAllow;
        const mapSelected=getState().deviceManager.mapSelected;
        const selectedFilters=getState().deviceManager.selectedFilters;
        const queryString=buildQueryString(mapSelected,selectedFilters);
        try {
            const response = await DeviceManagementService.instance().get(`/devices${queryString}&includeFields=title`)
            if (response && response.status === 200) {
                const deviceList = response.data.map(item => ({...item, template: ""}));
                const totalPages = Math.ceil(deviceList.length / paginationInfo.limit);
                const totalRecords = deviceList.length;
                let quantityRanges = [];
                let limitAux = paginationInfo.limit;
                for(let i=0; i < totalPages;i++){
                    quantityRanges.push(limitAux > totalRecords ? totalRecords : limitAux);
                    limitAux += paginationInfo.limit;
                }
                const totalPerPage = quantityRanges[0] || 0;
                nextPageAllow = totalPages > 1;
                let offset = 0;
                let page = 1;
                let prevPageAllow = false;
                paginationInfo= {...paginationInfo, offset, page, totalPages, totalRecords, nextPageAllow, totalPerPage,quantityRanges, prevPageAllow};

            }
        } catch (e) {
            displayErrorFromAxios.bind(null, dispatch);
        } finally {
            dispatch({type:SET_PAGINATION_INFO, paginationInfo});
            if(isMapSelected) {
                dispatch(loadDeviceList());
            }
        }
    }
};*/

export const changePage = (nextPage, prevPage) => {
    return (dispatch, getState) => {
        let paginationInfo = getState().deviceManager.paginationInfo;
        let offset = paginationInfo.offset;
        let page = paginationInfo.page;
        // let quantityRanges = paginationInfo.quantityRanges;
        if(nextPage){
            offset = offset + paginationInfo.limit;
            page = page + 1;
        }

        if(prevPage){
            offset = offset - paginationInfo.limit;
            page = page - 1;
        }

        // let totalPerPage = quantityRanges[page-1];
        /*let nextPageAllow = (page < paginationInfo.totalPages);
        let prevPageAllow = (page > 1);*/

        paginationInfo = {...paginationInfo, page, offset};

        dispatch({type: SET_PAGINATION_INFO, paginationInfo});

    }
};

export const changePaginationInfo = (status, pagination) => {
    let newPagination = {...pagination};
    if(pagination.totalRecords !== status.totalCount){
        newPagination.totalRecords = status.totalCount;
    }
    newPagination.totalPerPage = newPagination.offset + status.count;
    newPagination.nextPageAllow = newPagination.offset + status.count < status.totalCount;
    newPagination.prevPageAllow = newPagination.offset > 0;

    return newPagination;
}

export const setPaginationInfo = (paginationInfo) => ({type: SET_PAGINATION_INFO, paginationInfo});
export const resetPaginationInfo = (reload) => {
    return (dispatch, getState) => {
        let paginationInfo = {...getState().deviceManager.paginationInfo};
        paginationInfo.offset = 0;
        paginationInfo.page = 1;
        dispatch(setPaginationInfo(paginationInfo));
        if(reload) {
            dispatch(loadDeviceList())
        }
    }
};

export const setDevicesIds = (devicesId) => ({type:SET_DEVICES_ID, devicesId});

export const cleanDeviceManager = () => ({type: CLEAN_DEVICE_MANAGER});

export const unloadDeviceDetails = () => ({type: LOAD_DEVICE_DETAILS_DATA, detailsDevice: null});


export const verifyChangesOnSiteMap = (sites, maps) => {
    return async dispatch => {

        try {

            let objSites = {};
            sites.forEach(site => {
                objSites[site._id] = {
                    "siteId": site._id,
                    "siteName": site.name,
                    "maps": []
                }
            });

            maps.forEach(mapItem => {
                objSites[mapItem.siteId].maps.push({mapID: mapItem._id+"", mapName: mapItem.description})
            });

            const devicesResult = await DeviceManagementService.instance().get(`/devices?includeFields=siteId,siteName,location`);

            let objSitesChange = {};

            devicesResult.data.forEach((device, index) => {
                if (device.siteId && !objSitesChange[device.siteId]) {
                    let site = objSites[device.siteId];
                    if (!device.siteName || site.siteName !== device.siteName) {
                        objSitesChange[device.siteId] = site;
                    } else {
                        let boolChange = false;
                        site.maps.forEach(map => {
                            if(device.location){
                                if(!device.location.mapName || (device.location.mapID  === map.mapID && device.location.mapName !== map.mapName)){
                                    boolChange = true
                                }
                            }
                        });

                        if (boolChange) {
                            objSitesChange[device.siteId] = site;
                        }
                    }
                }
            });

            if (Object.keys(objSitesChange).length > 0) {
                await DeviceManagementService.instance().post('/devices/sitemap', objSitesChange);
            }
        } catch (e) {
            displayErrorFromAxios.bind(null, dispatch);
        }
    }
};

export const switchIsPublished = (isPublished) => ({type: UPDATE_PUBLISH_SWITCH, isPublished});

/*
export const loadUserDefinedSampleFile = () => {
    return async (dispatch) => {
        dispatch({type: LOAD_USER_DEFINED_FILE, userDefined: {status: LOADING}});
        axios.get(`/assets/examples/edit-device.json`).then(response => {
            dispatch({type: LOAD_USER_DEFINED_FILE, userDefined: {status: SUCCESS, jsonFile: response.data}});
            //dispatch(updateStateFormTemplate('advancedJson', null, response.data));
        }).catch(e => {
            console.log('loadUserDefinedSampleFile', e)
            dispatch({type: LOAD_USER_DEFINED_FILE, userDefined: {status: ERROR}});
        })
    }
}*/
export const loadUserDefinedSampleFile = () => {
    return async (dispatch) => {
        dispatch(getDataExample({url:`/assets/examples/edit-device.json`,key:'jsonFile'}))
    }
}
export const loadReaderConfigurationSampleFile = () => {
    return async (dispatch) => {
        dispatch(getDataExample({url:`/assets/examples/reader-configuration.json`,key:'jsonFile'}))
    }
}
export const getReaderConfiguration = () => {
    return async (dispatch, getState) => {
        const _id = getState().deviceManager.templateForm.template._id;
        dispatch({type: LOAD_USER_DEFINED_FILE, userDefined: {status: LOADING}});
        return await DeviceManagementService.instance().get(`/devices/${_id}/get_config`)
            .then(response=>{
                dispatch({type: LOAD_USER_DEFINED_FILE, userDefined: {status: SUCCESS, 'jsonFile': response.data}});
            })
            .catch(error=>{
                dispatch({type: LOAD_USER_DEFINED_FILE, userDefined: {status: NONE}});
                displayErrorFromAxios(dispatch,error)
            });
    }
}

const getDataExample  = ({url,key}) =>{
    return async  (dispatch)=> {
        dispatch({type: LOAD_USER_DEFINED_FILE, userDefined: {status: LOADING}});
        axios.get(url).then(response => {
            dispatch({type: LOAD_USER_DEFINED_FILE, userDefined: {status: SUCCESS, [key]: response.data}});
        }).catch(e => {
            dispatch({type: LOAD_USER_DEFINED_FILE, userDefined: {status: ERROR}});
        })
    }
}

export const unloadUserDefinedSampleFile = () => ({type: UNLOAD_USER_DEFINED_FILE});

export const isATRTypeDevice = (rfidType) =>{
    //return rfidType.substring(0,3)==="ATR";
    return rfidType?.indexOf('ATR') >= 0;
}