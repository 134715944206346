const MAP_NORMAL = 0;
const CHART_ZLAS = 6;
const CHART_DEVICES = 7;
const CHART_TAG_BLINKBATTERY = 8;
const OTHER_ALERTS_TABLE = 9;

const LAYOUTS_SYSTEM_HEALTH = {
    META:{
        title:"System Health",
        height:4,
        width:6
    },

    LAYOUT:[
        {
            x:0,
            y:0,
            width:2,
            height:4,
            classDashboard:"map-normal-class",
            componentId:MAP_NORMAL
        },
        {
            x:2,
            y:0,
            width:2,
            height:2,
            classDashboard:"chart-zlas-class",
            componentId:CHART_ZLAS
        },
        {
            x:4,
            y:0,
            width:2,
            height:2,
            classDashboard:"chart-tag-blinkbattery-class",
            componentId:CHART_TAG_BLINKBATTERY
        },
        {
            x:2,
            y:2,
            width:2,
            height:2,
            classDashboard:"chart-devices",
            componentId:CHART_DEVICES
        },
        {
            x:4,
            y:2,
            width:2,
            height:2,
            classDashboard:"other-alerts-class",
            componentId:OTHER_ALERTS_TABLE
        }
    ]
};

export default LAYOUTS_SYSTEM_HEALTH;