import * as types from '../constants/ActionTypes';
import { LOAD_SITE_LOCAL_MAP_ZONES, LOAD_SITE_SITE_ZONES, SITE_MANAGER_SITE_HOVER, TOGGLE_MY_SITES, UPDATE_MAP_SIZE } from '../constants/ActionTypes';

import {displayErrorFromAxios, LCSMapService, LocationConfigService, SiteViewService} from './util';
import { MAP_VIEW_URL } from '../constants/Misc';
import {updateFiltersWithSiteName} from "./reports";

// pure ui actions -- sites page
export const openSiteForm = (form, data) => ({ type: types.OPEN_SITE_FORM, form, data });
export const closeSiteForm = () => ({ type: types.CLOSE_SITE_FORM });

export const selectSite = (id, checked) => ({ type: types.TOGGLE_SITE_SELECTION, id, checked });
export const selectSiteGroup = (id, checked) => ({ type: types.TOGGLE_SITEGROUP_SELECTION, id, checked });
export const selectAllSites = (checked) => ({ type: types.TOGGLE_ALLSITES, checked });

export const selectFloorMap = (map) => ({ type: types.SELECT_FLOOR_MAP, map });
export const updateMapSize = () => ({ type: UPDATE_MAP_SIZE });

export const toggleSitePanels = panelKey => {
    return (dispatch) => {
        dispatch({ type: TOGGLE_MY_SITES, panelKey });
        dispatch(updateMapSize());
    };
};

/*
███    ███  ██████  ██    ██ ███████
████  ████ ██    ██ ██    ██ ██
██ ████ ██ ██    ██ ██    ██ █████
██  ██  ██ ██    ██  ██  ██  ██
██      ██  ██████    ████   ███████
*/

export const moveSiteToSiteGroup = (id, groupId) => {
    return (dispatch,getState) => {
        dispatch({ type: types.MOVE_SITE_TO_SITEGROUP, id, groupId: (groupId === 'null' ? null : groupId) });
        
        // let data = {
        //     groupMembership: (!groupId || groupId === 'null') ? [] : [ groupId ]
        // };
        //
        // LocationConfigService.instance().put('/sites/' + id, data)
        //     .then(() => {
        //         dispatch(requestSites());
        //     })

        //     .catch(displayErrorFromAxios.bind(null, dispatch));

        const sites=getState().sites?.sites||[];
        const siteInfo=sites.find(site=>site.id===id);

        if (siteInfo != null) {
            let data = {
                id: siteInfo.id,
                name: siteInfo.name,
                location: siteInfo.location,
                lat: siteInfo.lat,
                long: siteInfo.long,
                groupMembership: (!groupId || groupId === 'null') ? [] : [groupId]

            }
            dispatch(updateSite(data));
        }else{
            console.log("Site ",id," not found in ", JSON.stringify(sites));
        }
    };
};

/*
██████  ███████  ██████  ██    ██ ███████ ███████ ████████         ██     ██       ██████   █████  ██████
██   ██ ██      ██    ██ ██    ██ ██      ██         ██           ██      ██      ██    ██ ██   ██ ██   ██
██████  █████   ██    ██ ██    ██ █████   ███████    ██          ██       ██      ██    ██ ███████ ██   ██
██   ██ ██      ██ ▄▄ ██ ██    ██ ██           ██    ██         ██        ██      ██    ██ ██   ██ ██   ██
██   ██ ███████  ██████   ██████  ███████ ███████    ██        ██         ███████  ██████  ██   ██ ██████
                    ▀▀
*/

export const loadSites = (data) => ({ type: types.LOAD_SITES, data: data });

export const requestSites = () => {
    return (dispatch) => {
        //upgradeReact
        // Actually not convinced we need to dispatch an event... UI doesn't really do much with it.
        //dispatch({type:types.REQUEST_SITES});
        
        return SiteViewService.instance().get(MAP_VIEW_URL + '/sites/', {
            params: {},
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                const UNASIGNED_SITE_ID = "111111111111111111111111";
                const sites = response.data.filter(site => site._id !== UNASIGNED_SITE_ID);
                dispatch(loadSites(sites));
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch({ type: types.SET_SESSION, isSessionActive: false });
                }
                displayErrorFromAxios.bind(null, dispatch);
            });
    };
};

// request + load site groups
export const loadSiteGroups = (data) => ({ type: types.LOAD_SITE_GROUPS, data: data });

export const requestSiteGroups = () => {
    return (dispatch) => {
        
        LocationConfigService.instance().get('/sitegroups/', {
            params: {},
            headers: {
                'Content-Type': 'application/json',
                'Pragma': 'no-cache'
            }
        })
            .then(response => {
                dispatch(loadSiteGroups(response.data));
            })
            .catch(displayErrorFromAxios.bind(null, dispatch));
    };
};

/*
 █████  ██████  ██████
██   ██ ██   ██ ██   ██
███████ ██   ██ ██   ██
██   ██ ██   ██ ██   ██
██   ██ ██████  ██████
*/

// site (create,update,delete)
export const addSite = ({ name, location, lat, long, siteGroupId }) => {
    return (dispatch) => {
        //dispatch(({type: types.ADD_SITE, name, location, lat, long, siteGroupId}));
        
        let data = {
            name,
            location,
            lat,
            long,
            groupMembership: (siteGroupId ? [ siteGroupId ] : [])
        };
        
        return LocationConfigService.instance().post('/sites', data)
            .then(() => {
                dispatch(requestSites());
            })
            .catch(displayErrorFromAxios.bind(null, dispatch));
    };
};
export const addSiteGroup = (name) => {
    return (dispatch) => {
        //dispatch({type: types.ADD_SITE_GROUP, name});
        
        let data = { name };
        
        return LocationConfigService.instance().post('/sitegroups', data)
            .catch(displayErrorFromAxios.bind(null, dispatch))
            .then(() => {
                dispatch(requestSiteGroups());
            });
    };
};

/*
██    ██ ██████  ██████   █████  ████████ ███████
██    ██ ██   ██ ██   ██ ██   ██    ██    ██
██    ██ ██████  ██   ██ ███████    ██    █████
██    ██ ██      ██   ██ ██   ██    ██    ██
 ██████  ██      ██████  ██   ██    ██    ███████
*/

export const updateSite = ({ id, name, location, lat, long,groupMembership }) => {
    return (dispatch) => {
        //dispatch(({type: types.UPDATE_SITE, id, name, location, lat, long}));
        
        let data = {
            name,
            location,
            lat,
            long,
            groupMembership
        };

        return LocationConfigService.instance().put('/sites/' + id, data)
            .catch(displayErrorFromAxios.bind(null, dispatch))
            .then(() => {
                //dispatch(requestSites());
                //When we change the name of the site, we will update the properties of sites in redux and the filters
                dispatch(updateFiltersWithSiteName());
            })/*.finally(()=>{
                dispatch(updateFiltersWithSiteName());
            })*/;
    };
};
export const updateSiteGroup = (id, name) => {
    return (dispatch) => {
        //dispatch({type:types.UPDATE_SITE_GROUP, id, name});
        
        let data = { name };
        
        return LocationConfigService.instance().put('/sitegroups/' + id, data)
            .catch(displayErrorFromAxios.bind(null, dispatch))
            .then(() => {
                dispatch(requestSiteGroups());
            });
    };
};

/*
██████  ███████ ██      ███████ ████████ ███████
██   ██ ██      ██      ██         ██    ██
██   ██ █████   ██      █████      ██    █████
██   ██ ██      ██      ██         ██    ██
██████  ███████ ███████ ███████    ██    ███████
*/

export const deleteSite = (id) => {
    return (dispatch) => {
        //dispatch({type: types.DELETE_SITE, id});
        
        LocationConfigService.instance().delete('/sites/' + id)
            .then(() => {
                dispatch(requestSites());
            })
            .catch(displayErrorFromAxios.bind(null, dispatch));
    };
};
export const deleteSiteGroup = (id) => {
    return (dispatch) => {
        
        LocationConfigService.instance().delete('/sitegroups/' + id)
            .then(() => {
                dispatch({ type: types.DELETE_SITE_GROUP, id });
                dispatch(requestSiteGroups());
            })
            .catch(displayErrorFromAxios.bind(null, dispatch));
    };
};

export const changeSiteManagerSiteHover = (siteUID, hovered) => ({ type: SITE_MANAGER_SITE_HOVER, hoverUID: hovered ? siteUID : null });

export const loadSiteZones = (siteId) => {
    return async dispatch => {
        if (siteId) {
            try {
                //const responseZones = await ReportService.instance().get(`/zone-data/zones?site=${siteId}`);
                //const responseZones = await ReportService.instance().get(`/v2/zones?mapId=${siteId}`);
                const responseZones = { data: dummyZonesResponse };
                const zones = interpretZonesFromDefinition(responseZones.data);
                //console.log({ responseZones, zones });
                dispatch({ type: LOAD_SITE_SITE_ZONES, siteId: siteId, zones });
            } catch (e) {
                displayErrorFromAxios.bind(null, dispatch);
            }
        }
    };
};

const hexColorToRGBArray = (hex) => {
    return [1,3,5].map(i=>parseInt(hex.slice(i,i+2),16));
};

const formatZones = (zones) => {
    return zones.map(zone=>({
        name: zone.name,
        color: zone.color && hexColorToRGBArray(zone.color),
        shape: (zone.shape?.geometry?.coordinates || []).flat(),
        siteId: zone.siteId,
        map: zone.mapId,
        locationGuid: zone.locationGuid,
        locationTypeId: zone.locationTypeId,
        abbr: zone.abbr,
        raw: zone
    }));
}
export const loadMapZones = (mapId) => {
    return async dispatch => {
        if (mapId !== null) {
            try {
                const responseZones = await LCSMapService.instance().get(`/zones?mapId=${mapId}&type=standard`);
                const zones = formatZones(responseZones.data);
                dispatch({ type: LOAD_SITE_LOCAL_MAP_ZONES, mapId: mapId, zones });
            } catch (e) {
                displayErrorFromAxios.bind(null, dispatch);
            }
        }
    };
};


export const interpretZonesFromDefinition =(zones) => {
    return zones.map(zone=>({
        _id: zone._id,
        name: zone.name,
        color: zone.color && hexColorToRGBArray(zone.color),
        shape: zone.shape.geometry.coordinates[0],
        mapId: zone.mapId,
        type: zone.type,
        priority: zone.priority,
        raw: zone
    }));
};

const dummyZonesResponse = [ {
    'id': 1,
    'name': 'On-Site',
    'groupId': 1,
    'activationDate': '2020-02-18T20:50:38.417Z',
    'deactivationDate': null,
    'locationGuid': '1',
    'locationGroupGuid': '1',
    'wpExitLocId': null,
    'maxCapacity': 1,
    'orderSequence': null,
    'layerPriority': 0,
    'locationTypeId': 0,
    'color': null,
    'shapeTrue': '0;',
    'shapeOffset': '0;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': 'Between locations',
    'abbr': 'ONS',
    'mapId': null,
    'siteId': null,
    'locationGroupName': 'On-Site'
}, {
    'id': 4,
    'name': 'Off-Site',
    'groupId': 4,
    'activationDate': '2020-02-18T20:50:38.430Z',
    'deactivationDate': null,
    'locationGuid': '4',
    'locationGroupGuid': '4',
    'wpExitLocId': null,
    'maxCapacity': 1,
    'orderSequence': null,
    'layerPriority': 0,
    'locationTypeId': 0,
    'color': null,
    'shapeTrue': '0;',
    'shapeOffset': '0;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': 'OFS',
    'mapId': null,
    'siteId': null,
    'locationGroupName': 'Off-Site'
}, {
    'id': 11,
    'name': '1_1',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.657Z',
    'deactivationDate': null,
    'locationGuid': '{D2929F29-1980-4F51-A27F-FA15364F88F7}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;8360.57;7097.34;8364.63;6680.99;7798.86;6675.47;7794.80;7091.82;',
    'shapeOffset': '4;8360.57;7097.34;8364.63;6680.99;7798.86;6675.47;7794.80;7091.82;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '0',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 12,
    'name': '1_10',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.670Z',
    'deactivationDate': null,
    'locationGuid': '{30368995-D9DD-4B5A-8876-CCDE28CF3C4D}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;8397.13;3350.19;8401.19;2933.84;7835.41;2928.32;7831.35;3344.67;',
    'shapeOffset': '4;8397.13;3350.19;8401.19;2933.84;7835.41;2928.32;7831.35;3344.67;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '9',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 13,
    'name': '1_2',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.670Z',
    'deactivationDate': null,
    'locationGuid': '{6D65CDD9-F1FF-4864-9949-1E7B19C216CB}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;8364.63;6680.99;8368.69;6264.64;7802.92;6259.12;7798.86;6675.47;',
    'shapeOffset': '4;8364.63;6680.99;8368.69;6264.64;7802.92;6259.12;7798.86;6675.47;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '1',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 15,
    'name': '1_4',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.673Z',
    'deactivationDate': null,
    'locationGuid': '{269C8AEF-EE1A-4E8D-93E8-11D741B7623D}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;8372.76;5848.29;8376.82;5431.94;7811.04;5426.42;7806.98;5842.77;',
    'shapeOffset': '4;8372.76;5848.29;8376.82;5431.94;7811.04;5426.42;7806.98;5842.77;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '3',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 16,
    'name': '1_5',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.673Z',
    'deactivationDate': null,
    'locationGuid': '{F1FCB21E-58DB-4FF8-8C33-C7563EAD1315}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;8376.82;5431.94;8380.88;5015.59;7815.11;5010.07;7811.04;5426.42;',
    'shapeOffset': '4;8376.82;5431.94;8380.88;5015.59;7815.11;5010.07;7811.04;5426.42;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '4',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 17,
    'name': '1_6',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.673Z',
    'deactivationDate': null,
    'locationGuid': '{B36FAD1D-3A26-4B52-A7A3-982E67FBD8E3}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;8380.88;5015.59;8384.94;4599.24;7819.17;4593.72;7815.11;5010.07;',
    'shapeOffset': '4;8380.88;5015.59;8384.94;4599.24;7819.17;4593.72;7815.11;5010.07;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '5',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 18,
    'name': '1_7',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.673Z',
    'deactivationDate': null,
    'locationGuid': '{9FB364D6-B09E-4CAA-B293-3F1B19498DF6}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;8384.94;4599.24;8389.00;4182.89;7823.23;4177.37;7819.17;4593.72;',
    'shapeOffset': '4;8384.94;4599.24;8389.00;4182.89;7823.23;4177.37;7819.17;4593.72;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '6',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 19,
    'name': '1_8',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.673Z',
    'deactivationDate': null,
    'locationGuid': '{0AB44DF2-583E-4D76-BE71-18FDC621F8D3}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;8389.00;4182.89;8393.06;3766.54;7827.29;3761.02;7823.23;4177.37;',
    'shapeOffset': '4;8389.00;4182.89;8393.06;3766.54;7827.29;3761.02;7823.23;4177.37;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '7',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 20,
    'name': '1_9',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.673Z',
    'deactivationDate': null,
    'locationGuid': '{DE4D5201-59B1-44A0-BE8F-6E452676B4D6}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;8393.06;3766.54;8397.13;3350.19;7831.35;3344.67;7827.29;3761.02;',
    'shapeOffset': '4;8393.06;3766.54;8397.13;3350.19;7831.35;3344.67;7827.29;3761.02;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '8',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 21,
    'name': '10_1',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.673Z',
    'deactivationDate': null,
    'locationGuid': '{4C51CF83-8B11-489E-8297-49276057C4EC}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;3268.61;7047.66;3272.68;6631.31;2706.90;6625.79;2702.84;7042.14;',
    'shapeOffset': '4;3268.61;7047.66;3272.68;6631.31;2706.90;6625.79;2702.84;7042.14;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '90',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 22,
    'name': '10_10',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.673Z',
    'deactivationDate': null,
    'locationGuid': '{6206EC16-6FA0-4985-B99F-8C662509DC53}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;3305.17;3300.51;3309.23;2884.16;2743.46;2878.64;2739.40;3294.99;',
    'shapeOffset': '4;3305.17;3300.51;3309.23;2884.16;2743.46;2878.64;2739.40;3294.99;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '99',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 23,
    'name': '10_2',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.673Z',
    'deactivationDate': null,
    'locationGuid': '{F560FA8C-59D7-40E3-8301-53D95B876E5E}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;3272.68;6631.31;3276.74;6214.96;2710.96;6209.44;2706.90;6625.79;',
    'shapeOffset': '4;3272.68;6631.31;3276.74;6214.96;2710.96;6209.44;2706.90;6625.79;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '91',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 24,
    'name': '10_3',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.673Z',
    'deactivationDate': null,
    'locationGuid': '{87743AD0-8D25-4D94-8ABB-FF15485C26BF}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;3276.74;6214.96;3280.80;5798.61;2715.03;5793.09;2710.96;6209.44;',
    'shapeOffset': '4;3276.74;6214.96;3280.80;5798.61;2715.03;5793.09;2710.96;6209.44;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '92',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 25,
    'name': '10_4',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.673Z',
    'deactivationDate': null,
    'locationGuid': '{A5ABC6E6-01E7-4F7D-959B-4C8603B974E3}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;3280.80;5798.61;3284.86;5382.26;2719.09;5376.74;2715.03;5793.09;',
    'shapeOffset': '4;3280.80;5798.61;3284.86;5382.26;2719.09;5376.74;2715.03;5793.09;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '93',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 26,
    'name': '10_5',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.673Z',
    'deactivationDate': null,
    'locationGuid': '{54369068-A377-4D0E-B807-6D0757E5C640}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;3284.86;5382.26;3288.92;4965.91;2723.15;4960.39;2719.09;5376.74;',
    'shapeOffset': '4;3284.86;5382.26;3288.92;4965.91;2723.15;4960.39;2719.09;5376.74;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '94',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 27,
    'name': '10_6',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.673Z',
    'deactivationDate': null,
    'locationGuid': '{B33D6501-C3D0-48BA-B0B6-11381F059FBC}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;3288.92;4965.91;3292.99;4549.56;2727.21;4544.04;2723.15;4960.39;',
    'shapeOffset': '4;3288.92;4965.91;3292.99;4549.56;2727.21;4544.04;2723.15;4960.39;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '95',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 28,
    'name': '10_7',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.673Z',
    'deactivationDate': null,
    'locationGuid': '{32700A38-8848-4FED-BA47-308E2234EBA7}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;3292.99;4549.56;3297.05;4133.21;2731.27;4127.69;2727.21;4544.04;',
    'shapeOffset': '4;3292.99;4549.56;3297.05;4133.21;2731.27;4127.69;2727.21;4544.04;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '96',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 29,
    'name': '10_8',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.673Z',
    'deactivationDate': null,
    'locationGuid': '{C9B81980-20F4-4177-8891-AB55F4B70B91}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;3297.05;4133.21;3301.11;3716.86;2735.34;3711.34;2731.27;4127.69;',
    'shapeOffset': '4;3297.05;4133.21;3301.11;3716.86;2735.34;3711.34;2731.27;4127.69;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '97',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 30,
    'name': '10_9',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.673Z',
    'deactivationDate': null,
    'locationGuid': '{FC2EEEDA-4A83-4393-9587-79897BD08AFE}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;3301.11;3716.86;3305.17;3300.51;2739.40;3294.99;2735.34;3711.34;',
    'shapeOffset': '4;3301.11;3716.86;3305.17;3300.51;2739.40;3294.99;2735.34;3711.34;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '98',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 31,
    'name': '2_1',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.673Z',
    'deactivationDate': null,
    'locationGuid': '{64F5D685-C269-43D2-9E7C-CA69EA97038D}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;7794.80;7091.82;7798.86;6675.47;7233.09;6669.95;7229.03;7086.30;',
    'shapeOffset': '4;7794.80;7091.82;7798.86;6675.47;7233.09;6669.95;7229.03;7086.30;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '10',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 32,
    'name': '2_10',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.673Z',
    'deactivationDate': null,
    'locationGuid': '{76CC73D5-0FDF-42F8-AC9D-13D2AA616E16}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;7831.36;3344.67;7835.42;2928.32;7269.65;2922.80;7265.58;3339.15;',
    'shapeOffset': '4;7831.36;3344.67;7835.42;2928.32;7269.65;2922.80;7265.58;3339.15;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '19',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 33,
    'name': '2_2',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.673Z',
    'deactivationDate': null,
    'locationGuid': '{D43B8033-96FD-4B3E-8DB8-33E9DF8A6EE0}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;7798.86;6675.47;7802.92;6259.12;7237.15;6253.60;7233.09;6669.95;',
    'shapeOffset': '4;7798.86;6675.47;7802.92;6259.12;7237.15;6253.60;7233.09;6669.95;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '11',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 34,
    'name': '2_3',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.673Z',
    'deactivationDate': null,
    'locationGuid': '{66FFA265-0D2C-449E-9D2E-20310C0FA705}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;7802.92;6259.12;7806.98;5842.77;7241.21;5837.25;7237.15;6253.60;',
    'shapeOffset': '4;7802.92;6259.12;7806.98;5842.77;7241.21;5837.25;7237.15;6253.60;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '12',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 35,
    'name': '2_4',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.673Z',
    'deactivationDate': null,
    'locationGuid': '{7A2AF0D8-94BD-4A85-BE33-8A8443FF811D}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;7806.98;5842.77;7811.05;5426.42;7245.27;5420.90;7241.21;5837.25;',
    'shapeOffset': '4;7806.98;5842.77;7811.05;5426.42;7245.27;5420.90;7241.21;5837.25;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '13',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 36,
    'name': '2_5',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.673Z',
    'deactivationDate': null,
    'locationGuid': '{BEB41935-48FA-49BD-AEBF-2A49EBB869A1}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;7811.05;5426.42;7815.11;5010.07;7249.34;5004.55;7245.27;5420.90;',
    'shapeOffset': '4;7811.05;5426.42;7815.11;5010.07;7249.34;5004.55;7245.27;5420.90;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '14',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 37,
    'name': '2_6',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.673Z',
    'deactivationDate': null,
    'locationGuid': '{52BFB353-52C0-48BB-9178-861DF1C44164}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;7815.11;5010.07;7819.17;4593.72;7253.40;4588.20;7249.34;5004.55;',
    'shapeOffset': '4;7815.11;5010.07;7819.17;4593.72;7253.40;4588.20;7249.34;5004.55;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '15',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 38,
    'name': '2_7',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.673Z',
    'deactivationDate': null,
    'locationGuid': '{F385E264-7684-4E97-BD0B-DB6ED97F76DF}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;7819.17;4593.72;7823.23;4177.37;7257.46;4171.85;7253.40;4588.20;',
    'shapeOffset': '4;7819.17;4593.72;7823.23;4177.37;7257.46;4171.85;7253.40;4588.20;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '16',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 39,
    'name': '2_8',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.677Z',
    'deactivationDate': null,
    'locationGuid': '{35C8CFA8-10E9-4630-9287-8FCA7545F21E}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;7823.23;4177.37;7827.29;3761.02;7261.52;3755.50;7257.46;4171.85;',
    'shapeOffset': '4;7823.23;4177.37;7827.29;3761.02;7261.52;3755.50;7257.46;4171.85;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '17',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 40,
    'name': '2_9',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.677Z',
    'deactivationDate': null,
    'locationGuid': '{2B53B36D-AC1F-4852-88F5-1CC0CD95BFA9}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;7827.29;3761.02;7831.36;3344.67;7265.58;3339.15;7261.52;3755.50;',
    'shapeOffset': '4;7827.29;3761.02;7831.36;3344.67;7265.58;3339.15;7261.52;3755.50;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '18',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 41,
    'name': '3_1',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.677Z',
    'deactivationDate': null,
    'locationGuid': '{43F2C63E-D924-429A-8006-E8D4BFCA5FD8}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;7229.02;7086.30;7233.09;6669.95;6667.31;6664.43;6663.25;7080.78;',
    'shapeOffset': '4;7229.02;7086.30;7233.09;6669.95;6667.31;6664.43;6663.25;7080.78;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '20',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 42,
    'name': '3_10',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.677Z',
    'deactivationDate': null,
    'locationGuid': '{1371C71B-10D1-4CE3-BF14-7173EF95B26E}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;7265.58;3339.15;7269.65;2922.80;6703.87;2917.28;6699.81;3333.63;',
    'shapeOffset': '4;7265.58;3339.15;7269.65;2922.80;6703.87;2917.28;6699.81;3333.63;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '29',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 43,
    'name': '3_2',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.677Z',
    'deactivationDate': null,
    'locationGuid': '{406F79A8-05A6-4805-9F32-9A5E957173AC}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;7233.09;6669.95;7237.15;6253.60;6671.38;6248.08;6667.31;6664.43;',
    'shapeOffset': '4;7233.09;6669.95;7237.15;6253.60;6671.38;6248.08;6667.31;6664.43;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '21',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 44,
    'name': '3_3',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.677Z',
    'deactivationDate': null,
    'locationGuid': '{24CEAE80-D8CA-4EB5-8379-EACD1889380E}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;7237.15;6253.60;7241.21;5837.25;6675.44;5831.73;6671.38;6248.08;',
    'shapeOffset': '4;7237.15;6253.60;7241.21;5837.25;6675.44;5831.73;6671.38;6248.08;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '22',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 45,
    'name': '3_4',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.677Z',
    'deactivationDate': null,
    'locationGuid': '{A072E531-4C17-4207-AC3B-05FAC3369147}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;7241.21;5837.25;7245.27;5420.90;6679.50;5415.38;6675.44;5831.73;',
    'shapeOffset': '4;7241.21;5837.25;7245.27;5420.90;6679.50;5415.38;6675.44;5831.73;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '23',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 46,
    'name': '3_5',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.677Z',
    'deactivationDate': null,
    'locationGuid': '{C1300DFE-5500-4DDA-B9FA-81507D7BD1E4}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;7245.27;5420.90;7249.33;5004.55;6683.56;4999.03;6679.50;5415.38;',
    'shapeOffset': '4;7245.27;5420.90;7249.33;5004.55;6683.56;4999.03;6679.50;5415.38;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '24',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 47,
    'name': '3_6',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.677Z',
    'deactivationDate': null,
    'locationGuid': '{0709E0DF-32C6-4CC1-8AA1-D15A5E228BF8}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;7249.33;5004.55;7253.40;4588.20;6687.62;4582.68;6683.56;4999.03;',
    'shapeOffset': '4;7249.33;5004.55;7253.40;4588.20;6687.62;4582.68;6683.56;4999.03;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '25',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 48,
    'name': '3_7',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.677Z',
    'deactivationDate': null,
    'locationGuid': '{9CF363E7-0888-4032-803A-E7B54D6D564C}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;7253.40;4588.20;7257.46;4171.85;6691.69;4166.33;6687.62;4582.68;',
    'shapeOffset': '4;7253.40;4588.20;7257.46;4171.85;6691.69;4166.33;6687.62;4582.68;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '26',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 49,
    'name': '3_8',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.677Z',
    'deactivationDate': null,
    'locationGuid': '{73A31829-1FD2-4AEC-97F3-E9DB0A1F0CC2}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;7257.46;4171.85;7261.52;3755.50;6695.75;3749.98;6691.69;4166.33;',
    'shapeOffset': '4;7257.46;4171.85;7261.52;3755.50;6695.75;3749.98;6691.69;4166.33;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '27',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 50,
    'name': '3_9',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.677Z',
    'deactivationDate': null,
    'locationGuid': '{9861629A-9C1B-4BE1-88F5-86C44743E897}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;7261.52;3755.50;7265.58;3339.15;6699.81;3333.63;6695.75;3749.98;',
    'shapeOffset': '4;7261.52;3755.50;7265.58;3339.15;6699.81;3333.63;6695.75;3749.98;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '28',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 51,
    'name': '4_1',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.677Z',
    'deactivationDate': null,
    'locationGuid': '{DF1B9BD8-AAD0-45C8-8D5C-B8E01B98BD35}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;6663.25;7080.78;6667.31;6664.43;6101.54;6658.91;6097.48;7075.26;',
    'shapeOffset': '4;6663.25;7080.78;6667.31;6664.43;6101.54;6658.91;6097.48;7075.26;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '30',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 52,
    'name': '4_10',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.677Z',
    'deactivationDate': null,
    'locationGuid': '{FF8DA5D2-9B21-45C0-B639-408807E73BF2}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;6699.81;3333.63;6703.87;2917.28;6138.10;2911.76;6134.04;3328.11;',
    'shapeOffset': '4;6699.81;3333.63;6703.87;2917.28;6138.10;2911.76;6134.04;3328.11;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '39',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 53,
    'name': '4_2',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.677Z',
    'deactivationDate': null,
    'locationGuid': '{B25D596F-2414-4D43-A57E-14E87AD29AA9}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;6667.31;6664.43;6671.38;6248.08;6105.60;6242.56;6101.54;6658.91;',
    'shapeOffset': '4;6667.31;6664.43;6671.38;6248.08;6105.60;6242.56;6101.54;6658.91;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '31',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 54,
    'name': '4_3',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.677Z',
    'deactivationDate': null,
    'locationGuid': '{543CF267-EBE7-4D85-9571-5F8DB341D8E8}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;6671.38;6248.08;6675.44;5831.73;6109.67;5826.21;6105.60;6242.56;',
    'shapeOffset': '4;6671.38;6248.08;6675.44;5831.73;6109.67;5826.21;6105.60;6242.56;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '32',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 55,
    'name': '4_4',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.677Z',
    'deactivationDate': null,
    'locationGuid': '{4B5D5566-9CE8-4381-BE8F-153CD18C82C1}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;6675.44;5831.73;6679.50;5415.38;6113.73;5409.86;6109.67;5826.21;',
    'shapeOffset': '4;6675.44;5831.73;6679.50;5415.38;6113.73;5409.86;6109.67;5826.21;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '33',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 56,
    'name': '4_5',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.677Z',
    'deactivationDate': null,
    'locationGuid': '{46A00EBB-21BC-48F8-A3F2-4906D46ED786}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;6679.50;5415.38;6683.56;4999.03;6117.79;4993.51;6113.73;5409.86;',
    'shapeOffset': '4;6679.50;5415.38;6683.56;4999.03;6117.79;4993.51;6113.73;5409.86;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '34',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 57,
    'name': '4_6',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.677Z',
    'deactivationDate': null,
    'locationGuid': '{1A3CFB63-72B9-482B-BC6A-B99FE41B1EA3}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;6683.56;4999.03;6687.62;4582.68;6121.85;4577.16;6117.79;4993.51;',
    'shapeOffset': '4;6683.56;4999.03;6687.62;4582.68;6121.85;4577.16;6117.79;4993.51;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '35',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 58,
    'name': '4_7',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.677Z',
    'deactivationDate': null,
    'locationGuid': '{D47A1280-E996-4AC4-A23C-485B02995ADB}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;6687.62;4582.68;6691.69;4166.33;6125.91;4160.81;6121.85;4577.16;',
    'shapeOffset': '4;6687.62;4582.68;6691.69;4166.33;6125.91;4160.81;6121.85;4577.16;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '36',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 59,
    'name': '4_8',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.677Z',
    'deactivationDate': null,
    'locationGuid': '{54DF040A-7DDE-4D6C-B978-78256F2E014D}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;6691.69;4166.33;6695.75;3749.98;6129.98;3744.46;6125.91;4160.81;',
    'shapeOffset': '4;6691.69;4166.33;6695.75;3749.98;6129.98;3744.46;6125.91;4160.81;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '37',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 60,
    'name': '4_9',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.677Z',
    'deactivationDate': null,
    'locationGuid': '{3F4B3173-B204-4629-A5C5-535D0B9777CE}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;6695.75;3749.98;6699.81;3333.63;6134.04;3328.11;6129.98;3744.46;',
    'shapeOffset': '4;6695.75;3749.98;6699.81;3333.63;6134.04;3328.11;6129.98;3744.46;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '38',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 61,
    'name': '5_1',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.677Z',
    'deactivationDate': null,
    'locationGuid': '{F7F93502-E805-4D31-BB7E-DDC47C883220}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;6097.48;7075.26;6101.54;6658.91;5535.77;6653.39;5531.71;7069.74;',
    'shapeOffset': '4;6097.48;7075.26;6101.54;6658.91;5535.77;6653.39;5531.71;7069.74;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '40',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 62,
    'name': '5_10',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.677Z',
    'deactivationDate': null,
    'locationGuid': '{0B6646DF-EF9E-4C0D-B215-8717C88FE00F}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;6134.04;3328.11;6138.10;2911.76;5572.33;2906.24;5568.26;3322.59;',
    'shapeOffset': '4;6134.04;3328.11;6138.10;2911.76;5572.33;2906.24;5568.26;3322.59;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '49',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 63,
    'name': '5_2',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.677Z',
    'deactivationDate': null,
    'locationGuid': '{F27DE9FA-C488-4AE1-99D8-ED4FA57685A3}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;6101.54;6658.91;6105.60;6242.56;5539.83;6237.04;5535.77;6653.39;',
    'shapeOffset': '4;6101.54;6658.91;6105.60;6242.56;5539.83;6237.04;5535.77;6653.39;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '41',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 64,
    'name': '5_3',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.677Z',
    'deactivationDate': null,
    'locationGuid': '{21EDDCBF-1302-4939-92D6-AA8E948F9511}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;6105.60;6242.56;6109.67;5826.21;5543.89;5820.69;5539.83;6237.04;',
    'shapeOffset': '4;6105.60;6242.56;6109.67;5826.21;5543.89;5820.69;5539.83;6237.04;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '42',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 65,
    'name': '5_4',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.677Z',
    'deactivationDate': null,
    'locationGuid': '{5F3BD7C5-435A-4E51-80A3-EFE5ACEC559D}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;6109.67;5826.21;6113.73;5409.86;5547.95;5404.34;5543.89;5820.69;',
    'shapeOffset': '4;6109.67;5826.21;6113.73;5409.86;5547.95;5404.34;5543.89;5820.69;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '43',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 66,
    'name': '5_5',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.680Z',
    'deactivationDate': null,
    'locationGuid': '{2B3D037D-D384-4BC8-916D-27DC8EC1C011}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;6113.73;5409.86;6117.79;4993.51;5552.02;4987.99;5547.95;5404.34;',
    'shapeOffset': '4;6113.73;5409.86;6117.79;4993.51;5552.02;4987.99;5547.95;5404.34;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '44',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 67,
    'name': '5_6',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.680Z',
    'deactivationDate': null,
    'locationGuid': '{CB7CCDF1-4891-44D0-B652-AFF50D37200E}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;6117.79;4993.51;6121.85;4577.16;5556.08;4571.64;5552.02;4987.99;',
    'shapeOffset': '4;6117.79;4993.51;6121.85;4577.16;5556.08;4571.64;5552.02;4987.99;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '45',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 68,
    'name': '5_7',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.680Z',
    'deactivationDate': null,
    'locationGuid': '{EAF8BC84-8E66-4E83-A14D-7A6715D0927A}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;6121.85;4577.16;6125.91;4160.81;5560.14;4155.29;5556.08;4571.64;',
    'shapeOffset': '4;6121.85;4577.16;6125.91;4160.81;5560.14;4155.29;5556.08;4571.64;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '46',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 69,
    'name': '5_8',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.680Z',
    'deactivationDate': null,
    'locationGuid': '{27981859-276E-4AFA-944D-E032DEDABB10}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;6125.91;4160.81;6129.98;3744.46;5564.20;3738.94;5560.14;4155.29;',
    'shapeOffset': '4;6125.91;4160.81;6129.98;3744.46;5564.20;3738.94;5560.14;4155.29;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '47',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 70,
    'name': '5_9',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.680Z',
    'deactivationDate': null,
    'locationGuid': '{1885355A-DF1F-4478-84A8-7D1CDC14057D}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;6129.98;3744.46;6134.04;3328.11;5568.26;3322.59;5564.20;3738.94;',
    'shapeOffset': '4;6129.98;3744.46;6134.04;3328.11;5568.26;3322.59;5564.20;3738.94;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '48',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 71,
    'name': '6_1',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.680Z',
    'deactivationDate': null,
    'locationGuid': '{4DEF42FA-395D-48DD-A423-13EC30D149F9}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;5531.71;7069.74;5535.77;6653.39;4970.00;6647.87;4965.93;7064.22;',
    'shapeOffset': '4;5531.71;7069.74;5535.77;6653.39;4970.00;6647.87;4965.93;7064.22;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '50',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 72,
    'name': '6_10',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.680Z',
    'deactivationDate': null,
    'locationGuid': '{8BB6EA41-4680-4880-AB59-B9962BADC19D}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;5568.26;3322.59;5572.33;2906.24;5006.55;2900.72;5002.49;3317.07;',
    'shapeOffset': '4;5568.26;3322.59;5572.33;2906.24;5006.55;2900.72;5002.49;3317.07;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '59',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 73,
    'name': '6_2',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.680Z',
    'deactivationDate': null,
    'locationGuid': '{5DEC8112-C7FD-4D65-B479-79E9E2F0125E}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;5535.77;6653.39;5539.83;6237.04;4974.06;6231.52;4970.00;6647.87;',
    'shapeOffset': '4;5535.77;6653.39;5539.83;6237.04;4974.06;6231.52;4970.00;6647.87;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '51',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 74,
    'name': '6_3',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.680Z',
    'deactivationDate': null,
    'locationGuid': '{2FEC5501-B08F-4089-844A-0DB138BAA6D8}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;5539.83;6237.04;5543.89;5820.69;4978.12;5815.17;4974.06;6231.52;',
    'shapeOffset': '4;5539.83;6237.04;5543.89;5820.69;4978.12;5815.17;4974.06;6231.52;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '52',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 75,
    'name': '6_4',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.680Z',
    'deactivationDate': null,
    'locationGuid': '{32536D69-EBD5-4818-9E5B-1753403039C7}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;5543.89;5820.69;5547.95;5404.34;4982.18;5398.82;4978.12;5815.17;',
    'shapeOffset': '4;5543.89;5820.69;5547.95;5404.34;4982.18;5398.82;4978.12;5815.17;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '53',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 76,
    'name': '6_5',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.680Z',
    'deactivationDate': null,
    'locationGuid': '{2E32F5C9-DCE9-4D9C-8BF4-D21757268821}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;5547.95;5404.34;5552.02;4987.99;4986.24;4982.47;4982.18;5398.82;',
    'shapeOffset': '4;5547.95;5404.34;5552.02;4987.99;4986.24;4982.47;4982.18;5398.82;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '54',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 77,
    'name': '6_6',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.680Z',
    'deactivationDate': null,
    'locationGuid': '{8529BB4B-87AC-49D3-AFD1-96E4E9CB7D04}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;5552.02;4987.99;5556.08;4571.64;4990.31;4566.12;4986.24;4982.47;',
    'shapeOffset': '4;5552.02;4987.99;5556.08;4571.64;4990.31;4566.12;4986.24;4982.47;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '55',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 78,
    'name': '6_7',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.680Z',
    'deactivationDate': null,
    'locationGuid': '{9E7D4B1E-5007-4812-B679-08965EE1E56D}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;5556.08;4571.64;5560.14;4155.29;4994.37;4149.77;4990.31;4566.12;',
    'shapeOffset': '4;5556.08;4571.64;5560.14;4155.29;4994.37;4149.77;4990.31;4566.12;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '56',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 79,
    'name': '6_8',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.680Z',
    'deactivationDate': null,
    'locationGuid': '{385FDCDD-2F9E-4A6A-8B74-F00F9A6ABDC9}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;5560.14;4155.29;5564.20;3738.94;4998.43;3733.42;4994.37;4149.77;',
    'shapeOffset': '4;5560.14;4155.29;5564.20;3738.94;4998.43;3733.42;4994.37;4149.77;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '57',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 80,
    'name': '6_9',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.680Z',
    'deactivationDate': null,
    'locationGuid': '{1B1A8B51-D9A9-414D-B8B9-4E0F646748BA}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;5564.20;3738.94;5568.26;3322.59;5002.49;3317.07;4998.43;3733.42;',
    'shapeOffset': '4;5564.20;3738.94;5568.26;3322.59;5002.49;3317.07;4998.43;3733.42;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '58',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 81,
    'name': '7_1',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.680Z',
    'deactivationDate': null,
    'locationGuid': '{B49D214D-9D62-49D2-A8BD-402E71C60ACF}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;4965.93;7064.22;4969.99;6647.87;4404.22;6642.35;4400.16;7058.70;',
    'shapeOffset': '4;4965.93;7064.22;4969.99;6647.87;4404.22;6642.35;4400.16;7058.70;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '60',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 82,
    'name': '7_10',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.680Z',
    'deactivationDate': null,
    'locationGuid': '{506CD4B5-61E5-4A00-B525-5225DF678564}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;5002.49;3317.07;5006.55;2900.72;4440.78;2895.20;4436.72;3311.55;',
    'shapeOffset': '4;5002.49;3317.07;5006.55;2900.72;4440.78;2895.20;4436.72;3311.55;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '69',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 83,
    'name': '7_2',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.680Z',
    'deactivationDate': null,
    'locationGuid': '{D4BC548C-288A-4120-A0AA-1A417E9B754F}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;4969.99;6647.87;4974.06;6231.52;4408.28;6226.00;4404.22;6642.35;',
    'shapeOffset': '4;4969.99;6647.87;4974.06;6231.52;4408.28;6226.00;4404.22;6642.35;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '61',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 84,
    'name': '7_3',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.680Z',
    'deactivationDate': null,
    'locationGuid': '{74FB45F9-DD36-436B-A2F4-A546D8030DF7}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;4974.06;6231.52;4978.12;5815.17;4412.35;5809.65;4408.28;6226.00;',
    'shapeOffset': '4;4974.06;6231.52;4978.12;5815.17;4412.35;5809.65;4408.28;6226.00;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '62',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 85,
    'name': '7_4',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.680Z',
    'deactivationDate': null,
    'locationGuid': '{1EFE3106-9FF2-49DD-AA96-A084A6CA0C86}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;4978.12;5815.17;4982.18;5398.82;4416.41;5393.30;4412.35;5809.65;',
    'shapeOffset': '4;4978.12;5815.17;4982.18;5398.82;4416.41;5393.30;4412.35;5809.65;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '63',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 86,
    'name': '7_5',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.680Z',
    'deactivationDate': null,
    'locationGuid': '{CC81E9CB-2A35-46E6-9FEB-399022D0BBEA}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;4982.18;5398.82;4986.24;4982.47;4420.47;4976.95;4416.41;5393.30;',
    'shapeOffset': '4;4982.18;5398.82;4986.24;4982.47;4420.47;4976.95;4416.41;5393.30;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '64',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 87,
    'name': '7_6',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.680Z',
    'deactivationDate': null,
    'locationGuid': '{82FC3023-B751-456B-916E-4CAFA579FBA5}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;4986.24;4982.47;4990.30;4566.12;4424.53;4560.60;4420.47;4976.95;',
    'shapeOffset': '4;4986.24;4982.47;4990.30;4566.12;4424.53;4560.60;4420.47;4976.95;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '65',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 88,
    'name': '7_7',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.680Z',
    'deactivationDate': null,
    'locationGuid': '{4159C063-6BE7-4DB5-A8C0-539A578E133C}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;4990.30;4566.12;4994.37;4149.77;4428.59;4144.25;4424.53;4560.60;',
    'shapeOffset': '4;4990.30;4566.12;4994.37;4149.77;4428.59;4144.25;4424.53;4560.60;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '66',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 89,
    'name': '7_8',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.680Z',
    'deactivationDate': null,
    'locationGuid': '{AF925C8D-3415-4998-A6B9-CAE4255E3AC0}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;4994.37;4149.77;4998.43;3733.42;4432.66;3727.90;4428.59;4144.25;',
    'shapeOffset': '4;4994.37;4149.77;4998.43;3733.42;4432.66;3727.90;4428.59;4144.25;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '67',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 90,
    'name': '7_9',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.680Z',
    'deactivationDate': null,
    'locationGuid': '{7A710E66-29E2-4B32-89B8-BCC80FF486AD}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;4998.43;3733.42;5002.49;3317.07;4436.72;3311.55;4432.66;3727.90;',
    'shapeOffset': '4;4998.43;3733.42;5002.49;3317.07;4436.72;3311.55;4432.66;3727.90;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '68',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 91,
    'name': '8_1',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.680Z',
    'deactivationDate': null,
    'locationGuid': '{404D6CB6-B201-4CCB-A545-F48F249E7A66}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;4400.16;7058.70;4404.22;6642.35;3838.45;6636.83;3834.39;7053.18;',
    'shapeOffset': '4;4400.16;7058.70;4404.22;6642.35;3838.45;6636.83;3834.39;7053.18;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '70',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 92,
    'name': '8_10',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.680Z',
    'deactivationDate': null,
    'locationGuid': '{91938EB6-7D48-41F6-9FC0-3748A24F003A}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;4436.72;3311.55;4440.78;2895.20;3875.01;2889.68;3870.94;3306.03;',
    'shapeOffset': '4;4436.72;3311.55;4440.78;2895.20;3875.01;2889.68;3870.94;3306.03;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '79',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 93,
    'name': '8_2',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.680Z',
    'deactivationDate': null,
    'locationGuid': '{D528B813-5A23-4D44-830A-8BB73B3483CF}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;4404.22;6642.35;4408.28;6226.00;3842.51;6220.48;3838.45;6636.83;',
    'shapeOffset': '4;4404.22;6642.35;4408.28;6226.00;3842.51;6220.48;3838.45;6636.83;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '71',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 94,
    'name': '8_3',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.680Z',
    'deactivationDate': null,
    'locationGuid': '{A7EDCD58-488C-4B02-AB46-2B86FA7AA023}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;4408.28;6226.00;4412.35;5809.65;3846.57;5804.13;3842.51;6220.48;',
    'shapeOffset': '4;4408.28;6226.00;4412.35;5809.65;3846.57;5804.13;3842.51;6220.48;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '72',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 95,
    'name': '8_4',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.680Z',
    'deactivationDate': null,
    'locationGuid': '{5C02ABEB-16EA-44C6-B791-8CF84B24A845}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;4412.35;5809.65;4416.41;5393.30;3850.63;5387.78;3846.57;5804.13;',
    'shapeOffset': '4;4412.35;5809.65;4416.41;5393.30;3850.63;5387.78;3846.57;5804.13;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '73',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 96,
    'name': '8_5',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.680Z',
    'deactivationDate': null,
    'locationGuid': '{57F6CAA9-7CD1-4C3F-8DF2-3070164B4E48}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;4416.41;5393.30;4420.47;4976.95;3854.70;4971.43;3850.63;5387.78;',
    'shapeOffset': '4;4416.41;5393.30;4420.47;4976.95;3854.70;4971.43;3850.63;5387.78;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '74',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 97,
    'name': '8_6',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.680Z',
    'deactivationDate': null,
    'locationGuid': '{2856403D-D1AE-4B22-AE7D-B2298531995E}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;4420.47;4976.95;4424.53;4560.60;3858.76;4555.08;3854.70;4971.43;',
    'shapeOffset': '4;4420.47;4976.95;4424.53;4560.60;3858.76;4555.08;3854.70;4971.43;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '75',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 98,
    'name': '8_7',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.680Z',
    'deactivationDate': null,
    'locationGuid': '{64D1D700-2238-48A7-95E4-6D3EEE1E72E7}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;4424.53;4560.60;4428.59;4144.25;3862.82;4138.73;3858.76;4555.08;',
    'shapeOffset': '4;4424.53;4560.60;4428.59;4144.25;3862.82;4138.73;3858.76;4555.08;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '76',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 99,
    'name': '8_8',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.683Z',
    'deactivationDate': null,
    'locationGuid': '{E9BE429F-F540-4F88-84EE-FE6D7AC28B71}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;4428.59;4144.25;4432.66;3727.90;3866.88;3722.38;3862.82;4138.73;',
    'shapeOffset': '4;4428.59;4144.25;4432.66;3727.90;3866.88;3722.38;3862.82;4138.73;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '77',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 100,
    'name': '8_9',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.683Z',
    'deactivationDate': null,
    'locationGuid': '{441F0533-F352-45EA-9DEB-AF3380BC1215}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;4432.66;3727.90;4436.72;3311.55;3870.94;3306.03;3866.88;3722.38;',
    'shapeOffset': '4;4432.66;3727.90;4436.72;3311.55;3870.94;3306.03;3866.88;3722.38;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '78',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 101,
    'name': '9_1',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.683Z',
    'deactivationDate': null,
    'locationGuid': '{D78CEE70-1EAE-4880-A7E4-744763E4533B}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;3834.39;7053.18;3838.45;6636.83;3272.68;6631.31;3268.61;7047.66;',
    'shapeOffset': '4;3834.39;7053.18;3838.45;6636.83;3272.68;6631.31;3268.61;7047.66;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '80',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 102,
    'name': '9_10',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.683Z',
    'deactivationDate': null,
    'locationGuid': '{E62FF57D-B0B4-4AD1-9AD4-E2A7D56679C0}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;3870.94;3306.03;3875.01;2889.68;3309.23;2884.16;3305.17;3300.51;',
    'shapeOffset': '4;3870.94;3306.03;3875.01;2889.68;3309.23;2884.16;3305.17;3300.51;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '89',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 103,
    'name': '9_2',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.683Z',
    'deactivationDate': null,
    'locationGuid': '{F899EFCE-F15F-4515-8E3C-F3213D22F828}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;3838.45;6636.83;3842.51;6220.48;3276.74;6214.96;3272.68;6631.31;',
    'shapeOffset': '4;3838.45;6636.83;3842.51;6220.48;3276.74;6214.96;3272.68;6631.31;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '81',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 104,
    'name': '9_3',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.683Z',
    'deactivationDate': null,
    'locationGuid': '{C56A54FE-880F-4DF0-BBDF-8CBA5169CE37}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;3842.51;6220.48;3846.57;5804.13;3280.80;5798.61;3276.74;6214.96;',
    'shapeOffset': '4;3842.51;6220.48;3846.57;5804.13;3280.80;5798.61;3276.74;6214.96;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '82',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 105,
    'name': '9_4',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.683Z',
    'deactivationDate': null,
    'locationGuid': '{459F3E8F-6DE3-4E53-81EF-DCBD303BEC14}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;3846.57;5804.13;3850.63;5387.78;3284.86;5382.26;3280.80;5798.61;',
    'shapeOffset': '4;3846.57;5804.13;3850.63;5387.78;3284.86;5382.26;3280.80;5798.61;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '83',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 106,
    'name': '9_5',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.683Z',
    'deactivationDate': null,
    'locationGuid': '{6EE24CCA-9087-4EB0-BB0F-141941318504}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;3850.63;5387.78;3854.70;4971.43;3288.92;4965.91;3284.86;5382.26;',
    'shapeOffset': '4;3850.63;5387.78;3854.70;4971.43;3288.92;4965.91;3284.86;5382.26;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '84',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 107,
    'name': '9_6',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.683Z',
    'deactivationDate': null,
    'locationGuid': '{E926436E-D15F-4D17-B937-FE52D19DD1BF}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;3854.70;4971.43;3858.76;4555.08;3292.99;4549.56;3288.92;4965.91;',
    'shapeOffset': '4;3854.70;4971.43;3858.76;4555.08;3292.99;4549.56;3288.92;4965.91;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '85',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 108,
    'name': '9_7',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.683Z',
    'deactivationDate': null,
    'locationGuid': '{437F9A19-7123-4BAF-B3D8-3794525CF888}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;3858.76;4555.08;3862.82;4138.73;3297.05;4133.21;3292.99;4549.56;',
    'shapeOffset': '4;3858.76;4555.08;3862.82;4138.73;3297.05;4133.21;3292.99;4549.56;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '86',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 109,
    'name': '9_8',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.683Z',
    'deactivationDate': null,
    'locationGuid': '{89229EC4-7902-4854-9A8D-02E9DEC32561}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '9170A7',
    'shapeTrue': '4;3862.82;4138.73;3866.88;3722.38;3301.11;3716.86;3297.05;4133.21;',
    'shapeOffset': '4;3862.82;4138.73;3866.88;3722.38;3301.11;3716.86;3297.05;4133.21;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '87',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 110,
    'name': '9_9',
    'groupId': 11,
    'activationDate': '2020-11-09T15:03:05.683Z',
    'deactivationDate': null,
    'locationGuid': '{64D3AC6C-3BC1-4019-B50B-A48A07785190}',
    'locationGroupGuid': '{DA003355-320A-4CF7-8E53-22F82A7B75DE}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;3866.88;3722.38;3870.94;3306.03;3305.17;3300.51;3301.11;3716.86;',
    'shapeOffset': '4;3866.88;3722.38;3870.94;3306.03;3305.17;3300.51;3301.11;3716.86;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '88',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 3'
}, {
    'id': 282,
    'name': 'NEW ZONE 104',
    'groupId': 17,
    'activationDate': '2021-03-25T20:07:56.380Z',
    'deactivationDate': null,
    'locationGuid': '{65C79D8E-CFCC-4835-BF56-B9D312D3CCD1}',
    'locationGroupGuid': '{93A2C534-CDE5-4385-876D-D64F88BABF40}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 1,
    'color': '0080C0',
    'shapeTrue': '3;56.75;75.69;65.52;75.79;61.54;67.35;',
    'shapeOffset': '3;56.75;75.69;65.52;75.79;61.54;67.35;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': 'a102',
    'mapId': 13,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'New Group Cris'
}, {
    'id': 283,
    'name': 'NEW ZONE 106',
    'groupId': 17,
    'activationDate': '2021-03-25T20:07:56.380Z',
    'deactivationDate': null,
    'locationGuid': '{9A63F788-766C-4505-BF04-73A34E124D71}',
    'locationGroupGuid': '{93A2C534-CDE5-4385-876D-D64F88BABF40}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 3,
    'color': '0080FF',
    'shapeTrue': '3;56.11;75.42;43.79;75.42;60.90;67.24;',
    'shapeOffset': '3;56.11;75.42;43.79;75.42;60.90;67.24;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '104',
    'mapId': 13,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'New Group Cris'
}, {
    'id': 275,
    'name': 'NEW ZONE 103',
    'groupId': 17,
    'activationDate': '2021-03-25T14:27:21.703Z',
    'deactivationDate': null,
    'locationGuid': '{F5FABB90-886E-43B9-A400-9406891A4E48}',
    'locationGroupGuid': '{93A2C534-CDE5-4385-876D-D64F88BABF40}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': '808000',
    'shapeTrue': '7;68.52;66.34;70.86;66.36;70.95;62.19;68.57;63.39;65.68;62.03;65.34;66.46;68.50;64.99;',
    'shapeOffset': '7;68.52;66.34;70.86;66.36;70.95;62.19;68.57;63.39;65.68;62.03;65.34;66.46;68.50;64.99;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '102',
    'mapId': 13,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'New Group Cris'
}, {
    'id': 287,
    'name': 'NEW ZONE1',
    'groupId': 20,
    'activationDate': '2021-03-30T20:45:53.033Z',
    'deactivationDate': null,
    'locationGuid': '{392DA2A1-D6C4-4D59-A349-D9C3FA6B400E}',
    'locationGroupGuid': '{7D446883-37B4-4137-9E6E-3F266C5CB10D}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;477.00;521.00;519.00;521.00;519.00;479.00;477.00;479.00;',
    'shapeOffset': '4;477.00;521.00;519.00;521.00;519.00;479.00;477.00;479.00;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '105',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP1'
}, {
    'id': 288,
    'name': 'NEW ZONE1',
    'groupId': 21,
    'activationDate': '2021-03-30T20:45:53.033Z',
    'deactivationDate': null,
    'locationGuid': '{208FA0C5-0671-4949-B4AA-340D2CC3ED3F}',
    'locationGroupGuid': '{975BE7D9-9CE6-4542-A81D-8252916D54B9}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': 16000,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;47.00;52.00;51.00;52.00;51.00;48.00;47.00;48.00;',
    'shapeOffset': '4;47.00;52.00;51.00;52.00;51.00;48.00;47.00;48.00;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '106',
    'mapId': 13,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': 'NEW GROUP 8'
}, {
    'id': 392,
    'name': '2043-1-az',
    'groupId': 23,
    'activationDate': '2021-04-06T16:29:10.593Z',
    'deactivationDate': null,
    'locationGuid': '{d9f51a91-5292-411c-9a1e-9e75d3a83b0e}',
    'locationGroupGuid': '{7bfe7a02-19d4-4a28-bc77-935b03ad8cc7}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': -1,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;28.97;37.81;51.85;37.81;51.85;64.59;28.97;64.59;',
    'shapeOffset': '4;28.97;37.81;51.85;37.81;51.85;64.59;28.97;64.59;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '2',
    'mapId': 13,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': '5fa957510f8c750cab23fdb2-13-autozoneGroup'
}, {
    'id': 393,
    'name': '2043-2-az',
    'groupId': 23,
    'activationDate': '2021-04-06T16:29:10.593Z',
    'deactivationDate': null,
    'locationGuid': '{606c7b66-d865-419c-8353-acff6b5a0be5}',
    'locationGroupGuid': '{7bfe7a02-19d4-4a28-bc77-935b03ad8cc7}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': -1,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;52.23;45.93;63.03;45.93;63.03;57.54;52.23;57.54;',
    'shapeOffset': '4;52.23;45.93;63.03;45.93;63.03;57.54;52.23;57.54;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '2',
    'mapId': 13,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': '5fa957510f8c750cab23fdb2-13-autozoneGroup'
}, {
    'id': 394,
    'name': '2050-1-az',
    'groupId': 24,
    'activationDate': '2021-04-09T21:24:26.917Z',
    'deactivationDate': null,
    'locationGuid': '{8a3c75cd-bf26-4a40-8068-9f0027991274}',
    'locationGroupGuid': '{f13ff145-9a90-4065-ac96-dce3d02b8d3e}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': -1,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;510.94000000000005;575.48;520.94;575.48;520.94;585.48;510.94000000000005;585.48;',
    'shapeOffset': '4;510.94000000000005;575.48;520.94;575.48;520.94;585.48;510.94000000000005;585.48;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '2',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': '5fa957510f8c750cab23fdb2-3-autozoneGroup'
}, {
    'id': 395,
    'name': '2050-2-az',
    'groupId': 24,
    'activationDate': '2021-04-09T21:24:26.980Z',
    'deactivationDate': null,
    'locationGuid': '{80edb97f-8712-4101-bae4-e525eefe0774}',
    'locationGroupGuid': '{f13ff145-9a90-4065-ac96-dce3d02b8d3e}',
    'wpExitLocId': null,
    'maxCapacity': 0,
    'orderSequence': null,
    'layerPriority': -1,
    'locationTypeId': 0,
    'color': 'E1E1E1',
    'shapeTrue': '4;571.71;575.09;581.71;575.09;581.71;585.09;571.71;585.09;',
    'shapeOffset': '4;571.71;575.09;581.71;575.09;581.71;585.09;571.71;585.09;',
    'resourceOrientation': null,
    'wpInclusion': null,
    'custom1': null,
    'custom2': null,
    'custom3': null,
    'custom4': null,
    'custom5': null,
    'description': '',
    'abbr': '2',
    'mapId': 3,
    'siteId': '5fa957510f8c750cab23fdb2',
    'locationGroupName': '5fa957510f8c750cab23fdb2-3-autozoneGroup'
} ];
