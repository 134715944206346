import { getParentKeysToPolygonSet } from '../ol/helpers';
import { getDiffArray } from './';
import { ItemType } from '../constants';

export const zonesArrayMapper = (zones) => {
    const items = [];
    const autoZones = [];
    zones.forEach(zone=>{
        const zoneItem=getZoneItem(zone);
        if(zoneItem.itemType===ItemType.ZONE) {
            items.push(zoneItem);
        }else if(zoneItem.itemType===ItemType.AUTO_ZONE){
            autoZones.push(zoneItem);
        }
    })
    return {zoneProperties:zones,items,autoZones};

};

export const zonesStagingArrayMapper = (response) => {
    const items = [];
    const autoZones = [];
    const zoneProperties=[];
    const zones={};

    if (response.zones != null) {
        for (const zoneId in response.zones) {
            const zone = response.zones[zoneId];
            const zoneItem = getZoneItem(zone);

            if (zoneItem.itemType === ItemType.ZONE) {
                items.push(zoneItem);
            } else if (zoneItem.itemType === ItemType.AUTO_ZONE) {
                autoZones.push(zoneItem);
            }
            zoneProperties.push(zone);
            zones[zoneId]=zone;
        }
    }
    if (response.zonesWithoutId != null) {
        for (const zone of response.zonesWithoutId) {
            const zoneItem = getZoneItem(zone);
            const zoneItemZone={...zoneItem};
            zoneItem.id=zoneItem.name;
            zoneItemZone._id=zoneItem.id;
            items.push({...zoneItem,isNew:true});
            zones[zoneItemZone._id]=zoneItemZone;
            zones[zoneItemZone._id].groupName=zone.groupName||null;

        }
    }
    return {zones:zones,zoneProperties:zoneProperties,items,autoZones};

};

const getZoneItem = (zone) => {
    let zoneItem = null;
    if (zone != null) {
        const shape = zone.shape?.geometry?.coordinates[0] || [];
        const geometry = {type: 'Polygon', coordinates: shape?.map(item => [item[0], item[1]]) || []};
        zoneItem = {
            id: zone._id || undefined,
            itemType: (zone.priority !== -1) ? ItemType.ZONE : ItemType.AUTO_ZONE,
            parentId: zone.parentId || null,
            name: zone.name,
            color: zone.color,
            type: zone.type || 'standard',
            coords: geometry.coordinates,
            shape: zone.shape,
            childrenZones: zone.childrenZones || [],
            mapId: zone.mapId,
            isDraft: zone.isDraft || false,
            notSaved: zone.notSaved || false,
            isNew: zone.isNew || false,
            isDeleted: zone.isDeleted || false,
        };
    }
    return zoneItem;
}

export const toPayloadZone = (siteId, mapId) => (zone) => {
    const shape = zone.coords.map((coord, index) => ({ sequence: index + 1, x: coord[0], y: coord[1] }));
    const payload = {
        parentId: zone.parentId,
        mapId: mapId,
        name: zone.name,
        color: zone.color,
        type: zone.type,
        childrenZones: [],
        shape,
    };
    if (zone.id) {
        payload.id = zone.id;
    }
    return payload;
};

export const fixUpZoneParentIds = ({ zonesToCreate, zonesToUpdate, zonesAfterChange }) => {
    const getPolygonKey = (zone) => zone.id;
    const getPolygonCoordinates = (polygon) => [ polygon.coords ];
    const parentKeys = getParentKeysToPolygonSet(zonesAfterChange, zonesAfterChange, {
        getPolygonKey,
        getPolygonCoordinates,
    });
    const newZones = zonesAfterChange.map((zone) => ({
        ...zone,
        parentId: parentKeys[zone.id],
    }));
    const newDiffZones = getDiffArray(zonesAfterChange, newZones, [ 'parentId' ], { getItemKey: getPolygonKey });
    const newZonesToUpdate = (zonesToUpdate || []).map((zone) => ({
        ...zone,
        parentId: parentKeys[zone.id],
    }));
    const newZonesToCreate = (zonesToCreate || []).map((zone) => ({
        ...zone,
        parentId: parentKeys[zone.id],
    }));
    for (const diffZone of newDiffZones) {
        if (!newZonesToUpdate.find(zone => zone.id === diffZone.id)) {
            newZonesToUpdate.push(diffZone);
        }
    }
    
    const newZonesAfterChange = zonesAfterChange.map((zone) => ({
        ...zone,
        parentId: parentKeys[zone.id],
    }));
    
    return {
        newZonesToCreate,
        newZonesToUpdate,
        newZonesAfterChange,
    };
};

export const getDiffZones = (zonesBeforeChanges, zonesAfterChanges) => {
    const zonesToCreate = [];
    const zonesToUpdate = [];
    const zonesToDelete = [];
    for (const zoneChanged of zonesAfterChanges) {
        const zoneOld = zonesBeforeChanges.find((zone) => (zone.id === zoneChanged.id));
        if (zoneChanged.isDraft || !zoneOld) {
            zonesToCreate.push(zoneChanged);
        } else {
            for (const key of [ 'parentId', 'coords', 'color', 'name', 'type' ]) {
                if (zoneChanged.notSaved || JSON.stringify(zoneOld[key]) !== JSON.stringify(zoneChanged[key])) {
                    zonesToUpdate.push(zoneChanged);
                    break;
                }
            }
        }
    }
    for (const zoneOld of zonesBeforeChanges) {
        const zoneToDelete = zonesAfterChanges.find((zone) => (zone.id === zoneOld.id));
        if (!zoneToDelete) {
            zonesToDelete.push(zoneOld);
        }
    }
    return { zonesToCreate, zonesToUpdate, zonesToDelete };
};
