import React, {useState, useEffect} from 'react';
import PropertiesManager from './panel/PropertiesTable';
import { setZonesWithShiftKey, getZoneGroupsInSelectedZones} from './utils';
import { useDispatch, useSelector } from 'react-redux';
import {selectZones, changeZoneProperty, clickItemSection, refStore} from '../../actions';
import GroupsDialog from './dialog/GroupsDialog';
import ZBPanelZones from './utils/ZBPanelZones';
import { IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/SearchRounded';
import './styles.scss';
import {ItemType, SITE_MANAGER_CONFIGURATION_ZONES_SCOPE} from "../../../../constants";
import {getOlStoreKey} from "../../../../ol/helpers";
import ZoneBuilderModel from "../../utils/ZoneBuilderModel";

const SimpleZonePanel = props => {
    const {mapId} = props;
    const dispatch = useDispatch();
    const propertiesSchema = useSelector(state => state.zoneBuilder.propertiesSchema);
    const selectedZoneIds = useSelector(state => state.zoneBuilder.selectedZoneIds);
    const allZones = useSelector(state => state.zoneBuilder.zones);


    const selectedZones = selectedZoneIds.map(id => allZones[id]);
    // const zones = filterFromProperties(
    //     Object.keys(allZones).map(zid => allZones[zid]).filter(z => filterZones(z, mapId)),
    //     propertiesSchema,
    //     activeFilter);


    const [editGroup, setEditGroup] = useState(null);
    const [search, setSearch] = useState("");
    const [openSearch, setOpenSearch] = useState(false);
    const zonesState = useSelector(state => state.siteManager[SITE_MANAGER_CONFIGURATION_ZONES_SCOPE]?.items?.[ItemType.ZONE]
        || []);
    const zonesArray = zonesState.filter(zone=>zone.isDeleted!==true).map(zone => new ZoneBuilderModel({ ...zone }));
    useEffect(() => {
        dispatch(selectZones([]));
    }, [mapId, dispatch]);

    const handleZonePropertyChange = (key, value) => {
        dispatch(changeZoneProperty(key,value));
        dispatch(clickItemSection("properties"));
    }

    const searchZonesByName = (zone) => {
        const { name } = zone.data;
        return name.toLowerCase().indexOf(search.toLowerCase()) > -1;
    }

    const onZoneGroupEdit = groupName => {
        setEditGroup(groupName);
    }
    const onSearchChange = ({ target }) => {
        setSearch(target.value);
    };

    const onZonesClicked = (zoneId) => {
        const onSelectZones = (selectedZoneIds) => {
            refStore.configZonesMap?.[getOlStoreKey(ItemType.ZONE)]?.clearSelectedItems();
            refStore.configZonesMap?.[getOlStoreKey(ItemType.ZONE)]?.addSelectedItems(selectedZoneIds, { forceMultiSelection: true });
        };

        setZonesWithShiftKey(zoneId, selectedZoneIds, onSelectZones);
    };
    const renderZonesContent = () => {
        let searchedZones = openSearch ? zonesArray.filter(searchZonesByName) : zonesArray;
        // let myArray = ['January', 'February', 'March'];
        //

        searchedZones=searchedZones.map(zone=>{
            const zoneProperties = allZones[zone.data.id];
            zone.data.groupName=(zoneProperties!=null)?zoneProperties.data.groupName:"";
            return zone;});
        const selectedZoneGroups = getZoneGroupsInSelectedZones(selectedZones);

        const stylesPanel = { padding: '0.5em', width: '100%', height: 'calc(100% - 38px)' };
        return (<>
            <div className="zones-list-panel" style={stylesPanel}>
                <span className="zlp-header" style={{height: '30px'}}>
                    {openSearch ? <input placeholder={'Zone Name...'} onChange={onSearchChange} />
                        : <h3>{'Zones'} ({zonesArray.length})</h3>}
                    <IconButton size="small" style={{padding: '3px', width:'30px', height:'30px'}} onClick={() => setOpenSearch(!openSearch)}>
                        <SearchIcon />
                    </IconButton>
                </span>
                <ZBPanelZones
                    zones={searchedZones}
                    onZonesClicked={onZonesClicked}
                    onZoneGroupEdit={onZoneGroupEdit}
                    isSelectedMultipleZoneGroups={selectedZoneGroups.length>1}
                />
            </div>

            <GroupsDialog
                open={Boolean(editGroup)}
                mapId={mapId}
                groupName={editGroup}
                onClose={() => setEditGroup(null)}
            />
        </>);
    };

    const selectedZonesProperties = (selectedZones || []).filter(zone => !!zone).map(({data}) => data);
    return (
        <div className="simple-zones-panel">
            <div className="zones-panel">
                {renderZonesContent()}
            </div>            
            <PropertiesManager
                mapId={mapId}
                // properties={(canEditProperties && selectedZones[0] && selectedZones[0].data) || undefined}
                // errorText={(tooManyZonesSelected && `${selectedZones.length} zones selected.`) || null}
                properties={selectedZonesProperties}
                errorText={null}
                onPropertyChange={handleZonePropertyChange}
                schema={propertiesSchema}
            />
        </div>
    );
}

export default SimpleZonePanel;