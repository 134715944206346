

/**
 * Common Utility class. Shared different method that can be used in applicaiton.
 */
class ZUtils {

    /**
     *
     * @param {string} eventType - Event type ex : "zLogin"
     * @param {object} args - Passed as object ex : { username : "zebra", "isRemember":true|false}
     * @returns {CustomEvent}
     *
     * @example
     * import ZUtil from "<Package path>"
     *
     * new ZUtil.createCustomEvent("zLoggedIn",{username: "Vipul", isRemember : true|false});
     *
     *
     *
     *
     */
    createCustomEvent  (eventType,args) {

        if(!eventType && !args){
            console.error("Missing Arguments for creating custom event through ZUtil");
        }else{

            var event = document.createEvent("CustomEvent");

            event.initCustomEvent(eventType,true,true,{
                message : args
            });

            return event;
        }
    }


    /**
     * Helper method to create cookie for application.
     * Note : Again day(s) is optional value if it's no define then it will not expire.
     *
     * @param {string} name - Name of the cookie
     * @param {string} value - Value of the cookie
     * @param {string} days - Number of the Days after cookie will expires.
     *
     * @example
     * import ZUtil from "<Package path>"
     *
     * new ZUtil.createCookie("username","John",3);
     *
     *
     *
     */
    createCookie (name,value,days) {
        var expire = "";
        if(days){
            var date = new Date();
            date.setTime(date.getTime()+(days*24*60*60*1000));
            expire = ";expires="+date.toISOString();
            console.log("Created Cookie Will Expire in "+expire);
        }else{
            expire = "";
        }
        document.cookie = name + "="+value+expire+";path=/";
    }

    /**
     * @example
     * import ZUtil from "<Package path>";
     * var username = new ZUtil.readCookie("username");
     * // this will return "John"
     *
     *
     *
     * Helper method to read cookie in application. Provide name of the cookie you want to read.
     * @param {string} name - Provide name of the cookie you want to read.
     * @returns {*} - return cookie value
     */

    readCookie  (name) {
        var cookies = document.cookie.split(";"),
            value = null;
        for(var x=0; x<cookies.length; x++){
            if (cookies[x].indexOf(name) !== -1) {
                value = cookies[x].substring(cookies[x].indexOf("=") + 1, cookies[x].length);
                break;
            }else{
                console.error("We didn't find cookies you looking for...")
            }
        }
        return value;
    }

    /**
     * Use this util to provide username on right side of application bar. This will happen automatically. Call This method from your application app file.
     *
     *
     * @example
     * import ZUtil from "<package>"
     * setTimeOut(()=>new ZUtil.userLoggedIn("<username>",200))
     *
     * @param {string} name=Username passed in Login - pass username you want to set
     * @param args {object} - another argument parameters as object.
     */

    userLoggedIn (name,args) {

        var id = document.body.children[0].id;
        if(!id){
            console.error("Please provide id to body tag first child")
        }
        var zUserLoggedInEvent = this.createCustomEvent("zUserLoggedIn",{username:name, args : args});
        document.getElementById(id).dispatchEvent(zUserLoggedInEvent);
    }

    static sendToastNotification (arg){
        if(!arg){
            console.log('Argument Missing ', 'color:red');
        }
        var event = new CustomEvent("toast",{
            detail : {
                message : arg
            },
            bubbles : true,
            cancelable:true
        });
        document.getElementById('toastId').dispatchEvent(event);
    }

}
export default ZUtils;
