export const CALL_HISTORY_ID='826DEEF9-31FA-4CA0-BFB7-D697827574E7';
export const RESOURCE_EVENT_HISTORY_ID='B9AF8F31-453D-4847-B48C-99523CC36776';
export const RESOURCE_ZONE_CORRELATION_EVENT_HISTORY_ID='6BAD2F8F-A0EC-42CF-94C5-3BC0BC5B71FA';
export const TAG_HISTORY_REPORT = '9756F150-B733-11D4-9045-00105A6AE011';

export const historyReports = [
    RESOURCE_EVENT_HISTORY_ID,
    TAG_HISTORY_REPORT,
    CALL_HISTORY_ID
];

export const OBJECT_ID = 'objectId';
export const TAG_ID = 'tagId';
export const RESOURCE_ID = 'resourceId';