import React, {useEffect, useRef, useState} from 'react';
import { Tooltip} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import {REQUIRED} from "../../../../../../constants";
import TooltipInfoMessage from "../../../../../deviceManager/common/accordionItems/common/TooltipInfoMessage";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import {useHandleError} from "../../useHandleError";

export const InputType = {
    DECIMAL:"DECIMAL",
    INTEGER:"INTEGER",
}

const DEFAULT_VALUE = {
    [InputType.INTEGER]: null,
    [InputType.DECIMAL]: null,
}
const MAX_SAFE_INTEGER = Number.MAX_SAFE_INTEGER;
const MIN_SAFE_INTEGER = Number.MIN_SAFE_INTEGER;
const DECIMAL_MIN_VALUE = -Number.MAX_VALUE-1;
const DECIMAL_MAX_VALUE = Number.MAX_VALUE;

const InputNumberValidated = ({
                                  typeInput, disabled=false,
                                  id,
                                  name,
                                  value,
                                  onChange,
                                  validations=[],
                                  label,
                                  trim = false,
                                  positiveOnly=null,
                                  negativeOnly=null,
                                  defaultValue=DEFAULT_VALUE[typeInput],
                                  max=null,
                                  min=null,
                                  className=null,
                                  classNameLabel =null,
                                  classNameInput=null,
                                  placeholder,
                                  fixedDecimals = null,
                                  size = null,
                                  errorPlacement = 'right',
                                  errorEl = 'label',
                                  onError,
                                  forceDisplayError,
                                  tooltipPositionInHeader = true,
                                  showErrorOnBlur = false,
                                  informationTooltip = {},
                                  forceMessageError,
                                  tooltipDisablePortal = true,
                              }) => {


    const specialCharacters = ['-','+'];
    const inputRef = useRef(null);
    const tooltipRef = useRef(null);

    const {
        errorMessage: _errorMessage,
        displayError,
        handleOnBlur: handleOnBlurError,
    } = useHandleError({ validations, value, onError, forceDisplayError, showErrorOnBlur, defaultValue: DEFAULT_VALUE });
    const errorMessage = forceMessageError || _errorMessage;
    const [maxSizeToolTip, setMaxSizeToolTip] = useState('none');

    const inputWidth = inputRef?.current?.offsetWidth;
    const tooltipWidth = tooltipRef?.current?.offsetWidth;

    useEffect(() => {
        const maxSizeToolTipCalculate = inputWidth - tooltipWidth - 28;
        if (maxSizeToolTipCalculate && maxSizeToolTipCalculate > 0) {
            setMaxSizeToolTip(maxSizeToolTipCalculate);
        } else if (maxSizeToolTipCalculate < 0) {
            setMaxSizeToolTip('none');
        }
    }, [inputWidth, tooltipWidth, tooltipPositionInHeader]);
    const useStyles = makeStyles({
        tooltip: {
            fontSize: (errorMessage.length < 24) || !tooltipDisablePortal ? '14px' : '12px',
            maxWidth: maxSizeToolTip && false ? maxSizeToolTip : 'none',
            top: errorMessage.length < 24 ? '0px' : '-6px',
            color: '#000000',
            backgroundColor: 'var(--highlighted-error-color)',
            lineHeight: '15px',
            // fontSize: 14px;
        },
        arrow: {
            color: 'var(--highlighted-error-color)',
        },
        infoTooltip: {
            color: '#FFFFFF',
            backgroundColor: '#000000',
            opacity: '0.95',
            fontSize: '14px',
        },
        tooltipPlacementBottom: {
            margin: '2px',
        },
        tooltipPlacementTop: {
            margin: '2px',
        },

    });

    const classes = useStyles();

    function getIsNumberAndZero (changedValue){
        /*return (changedValue||changedValue===0||changedValue==='0')*/
        return (!isNaN(changedValue) && changedValue !=='' && changedValue!==null && changedValue!==undefined)||changedValue===0||changedValue==='0';
    }
    const getValueTypeInput = (newValue,forceParse=false)=>{
        let changedValue;
        switch (typeInput) {
            case InputType.INTEGER:
                const newValueInteger = getIsNumberAndZero(newValue)? +newValue:'';
                if( (Number.isInteger(newValueInteger) && isIntegerRange(newValueInteger))|| newValueInteger ==='' ) {
                    changedValue = newValueInteger;
                }else{
                    changedValue = getIsNumberAndZero(value)?+value:'';
                }

                break;
            case InputType.DECIMAL:
                changedValue = getIsNumberAndZero(newValue)?newValue:'';
                if( typeof  changedValue !== 'number' ) {
                    changedValue = changedValue?changedValue.replace(/^-0+/, '-0'):changedValue;
                    changedValue = changedValue?changedValue.replace(/^0+/, '0'):changedValue;
                    changedValue = forceParse?(getIsNumberAndZero(changedValue)? Number(changedValue):changedValue):changedValue;
                }
                break;
            default:
                changedValue = defaultValue;
        }
        return changedValue;
    }

    const isIntegerRange = (value) =>{
        return (max?value<=max:value<MAX_SAFE_INTEGER ) && (min?value>=min:value>MIN_SAFE_INTEGER)
    }

    const isDecimalRange = (value) =>{
        return (max?value<=max:value<DECIMAL_MAX_VALUE ) && (min?value>=min:value>DECIMAL_MIN_VALUE)
    }

    const validateValue = (newValue) =>{
        if(size != null){
            const value = String(newValue).replace("-", "");
            if(value.length > size){
                return;
            }
        }
        if( (newValue && !specialCharacters.includes(newValue) && !isNaN(newValue)) || newValue === null) {
            let changedValue = getValueTypeInput(newValue);
            if(changedValue){
                let change = false;
                let changePositiveOnly = false;
                let changeNegativeOnly = false;
                if(positiveOnly && (negativeOnly === null || negativeOnly === false) && changedValue>=0 ){
                    change = true;
                    changePositiveOnly = true;
                }
                if((positiveOnly ===null || positiveOnly===false )&& negativeOnly && changedValue<=0 ){
                    change = true;
                    changeNegativeOnly = true;
                }

                if( typeInput === InputType.INTEGER && isIntegerRange(changedValue)){
                    change = true;
                }
                if( typeInput === InputType.DECIMAL && isDecimalRange(+changedValue)){
                    change = true;
                }
                if(change && ((changeNegativeOnly||changePositiveOnly)||(positiveOnly === null && negativeOnly === null )) ){
                    //const error = getError(changedValue);
                    //if(!error){
                    onChange(changedValue, name);
                    /*}else{
                        console.log({error})
                    }*/
                }
            }else{
                onChange(changedValue, name);
            }
        }else if(specialCharacters.includes(newValue)){//-0
            if( !(positiveOnly && (negativeOnly === null || negativeOnly === false) )){
                onChange(newValue, name);
            }else{
                onChange(defaultValue, name);
            }
        }
    }

    const getValueInput = () =>{
        if( !isNaN(value) || specialCharacters.includes(value)){
            let val =  value===null?'':value;
            let valueStr = val.toString();
            if(fixedDecimals != null && valueStr.indexOf('.') > -1){
                let arrAux = valueStr.split('.');
                let strDec = arrAux[1].slice(0,fixedDecimals);
                arrAux[1] = strDec;
                valueStr = arrAux.join('.');
            }
            return valueStr;
        }else{
            return defaultValue||''
        }
    }
    
    const getInputNumber = () => {
        return <input ref={inputRef}
                      disabled = {disabled}
                      className={classNameInput || ''}
                      placeholder={placeholder}
                      id = {id}
                      autoComplete = "off"
                      value = {getValueInput()}
                      name={name}
                      onChange = {({target}) => {
                          const newValue = target.value.trim() !== '' ? target.value : null;
                          validateValue(newValue)
                      }}
                      onBlur = {(event)=>{
                          if(specialCharacters.includes(value)) {
                              onChange(defaultValue, name);
                          }else{
                              const valueChange = getIsNumberAndZero(value)?getValueTypeInput(value,true):defaultValue;
                              onChange(valueChange,name)
                          }
                          handleOnBlurError();
                      }}
        />
    }

    const styleIcon = {
        cursor: !disabled ? 'pointer' : 'default',
        color: !disabled ? '#007CB0' : '#98CADF',
        margin: '0 5px',
    };

    const textInputLabel = (
        <div className={"form-text-input-label " + classNameLabel} style={{ display: 'flex', alignItems: 'center' }}>
            {errorEl === 'label' ? (
                    <Tooltip
                        open={!!displayError && !!errorMessage}
                        arrow={true}
                        title={errorMessage}
                        placement={errorPlacement}
                        PopperProps={{ disablePortal: tooltipDisablePortal }}
                        classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
                        ref={tooltipRef}
                    >
                        <span>{label} {(!!validations?.find(validate => validate.type === REQUIRED)) && <span>*</span>}</span>
                    </Tooltip>
                )
                : <span>{label} {(!!validations?.find(validate => validate.type === REQUIRED)) && <span>*</span>}</span>}
            {!!informationTooltip?.message && (
                <Tooltip
                    interactive
                    placement="top"
                    title={<TooltipInfoMessage tooltipContent={informationTooltip?.message} />}
                    classes={{
                        tooltip: classes.infoTooltip,
                        tooltipPlacementBottom: classes.tooltipPlacementBottom,
                        tooltipPlacementTop: classes.tooltipPlacementTop,
                    }}
                >
                    <InfoIcon style={styleIcon} fontSize={'small'} />
                </Tooltip>
            )}
        </div>
    );
    const getInput = () =>{
        switch (typeInput) {
            case InputType.INTEGER:
            case InputType.DECIMAL:
                return getInputNumber()
            default:
                return null
        }
    }

    const renderInput = () => {
        return (
            <div
                className={'form-text-input-container' + (className ? ' ' + className : '') + (disabled ? ' disabled' : '') + (displayError && errorMessage ? ' display-error' : ' no-display-error')}
            >
                {textInputLabel}
                <div className="form-text-input">
                    <div className="base-filter">
                        <div className="box-container">
                            <div className="input-container">
                                {getInput()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    return errorEl === 'container' ? (
        <Tooltip
            open={!!displayError && !!errorMessage}
            arrow={true}
            title={errorMessage}
            placement={errorPlacement}
            PopperProps={{ disablePortal: true }}
            classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
            ref={tooltipRef}
        >{renderInput()}</Tooltip>)
        : renderInput();
}


export default InputNumberValidated;