/**
 * This method helps to generate an Id to the html components
 * @param param - The main name that will have the id of the component
 * @param prefix - The section to belongs the component  (optional)
 * @param type - The type of the component
 * @returns {string}
 */
function generateComponentId(param, prefix, type){
    // converts to lowerCase all the characters
    let componentId = param.toLowerCase().replace(/ /g, "_");
    // add the prefix if it is necessary
    if(prefix != null){
        componentId = prefix.toLowerCase() + "_" + componentId;
    }
    let suffix;
    // set the suffix accord the type of the component
    switch (type){
        case "button": suffix = "_btn";
                        break;
        case "text": suffix = "_txt";
                        break;
        case "dropdown": suffix = "_dwn";
                            break;
        case "checkbox": suffix = "_chk";
                            break;
        default: suffix = "";
    }
    componentId += suffix;
    return componentId;
}

export {generateComponentId}