import { TAG_VIEW_MODAL_OPTION, UPDATE_TAG_FIELD_VALUE } from '../constants/ActionTypes';
import { TAGS_TYPE_FIELD, VALIDATE_TAGS_FIELD } from '../constants';
import { MODE_DIALOG_TAG } from '../views/tagView/constants/TagsConstants';

const initialState = {
    statusModalTagAddEdit: false,
    tagsFormField: TAGS_TYPE_FIELD,
    needsValidationFieldsTags: VALIDATE_TAGS_FIELD,
    tagsURL: {
        add: {
            method: 'POST',
            url: '/v2/tags'
        },
        list: {
            method: 'GET',
            url: '/v2/tags?{filters}&page={page}&size={size}&sort={sort}'
        },
        edit: {
            method: 'PATCH',
            url: '/v2/tags/{source}/{id}'
        },
        deleteOneTag: {
            method: 'DELETE',
            url: '/v2/tags/{source}/{id}',
            urlForceDelete: '/v2/tags/{source}/{id}/forceDelete'
        },
        deleteMultipleTag: {
            method: 'DELETE',
            url: '/v2/tags',
            urlForceDelete: '/v2/tags/forceDelete'
        }
    },
    resourceURL: {
        list: {
            method: 'GET',
            url: '/v2/tags?page={page}&size={size}&sort={sort}'
        }
    },
    staticValues: {
        resourceId: [
            {
                name: 'resourceId01',
                value: 'resourceId01'
            }
        ],
        siteId: [
            {
                name: 'siteId1',
                value: 'siteId1'
            }
        ],
        source: [
            {
                name: 'DART',
                value: 'DART'
            },
            {
                name: 'EPC',
                value: 'EPC'
            },
            {
                name: 'MPACT',
                value: 'MPACT'
            },
            {
                name: 'WHERENET',
                value: 'WHERENET'
            },
            {
                name: 'UNKNOWN',
                value: 'UNKNOWN'
            },
            // {
            //     name: 'MSE',
            //     value: 'MSE'
            // }
            /*
            {
                name:'BLE',
                value:'BLE'
            },
            {
                name:'GPS',
                value:'GPS'
            },
            */
            /**/
        ],
        mode: [
            {
                name: 'mode1',
                value: 'mode1'
            }
        ]
    },
    tagList: [],
    selectedTagRows: {},
    tagsSortIndex: ''
};

export function tags(state = initialState, action) {
    switch (action.type) {
        case TAG_VIEW_MODAL_OPTION:
            return {
                ...state,
                statusModalTagAddEdit: action.statusModalTagAddEdit
            };
        case UPDATE_TAG_FIELD_VALUE:
            let tagsFormField = [...state.tagsFormField];
            tagsFormField[action.index].value = action.value;
            return {
                ...state,
                tagsFormField: tagsFormField
            };
        case MODE_DIALOG_TAG:
            return {
                ...state,
                statusModalTagAddEdit: action.statusModalTagAddEdit,
                modeDialog: action.modeDialog,
                selectTag: action.selectTag ? action.selectTag : null
                // needsValidationFieldsResource:VALIDATE_RESOURCE_FIELD,
            };
        
        default:
            return state;
    }
}
