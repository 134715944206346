import React from 'react';
import PropTypes from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
// import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import SaveIcon from '@material-ui/icons/Save';
import ToolbarButton from '../../icons/ToolbarButton';
import StyledDialog from "../../util/Dialog";

import AddIcon from '@material-ui/icons/Add';
import UpdateIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ExportIcon from '@material-ui/icons/CloudDownload';
import InfoIcon from '@material-ui/icons/Info';
import ImportIcon from '@material-ui/icons/CloudUpload';
import PrintIcon from '@material-ui/icons/Print';

import * as FORMS from '../../../constants/DataEntryForms';

import * as views from '../../../constants/ViewTypes';
import {PLAYBACK_VIEW} from '../../../constants/ViewTypes';
import {IMPORT_ENABLED_REPORTS} from '../../../constants/Misc'
import ViewsDropDown from './ViewsDropDown';
import ClassedIconButton from './ClassedIconButton';
import ModalImport from '../../import/toolbars/ModalImport';
import ModalPrint from '../../util/print/ModalPrint';
import ModalResourcePrint from './modals/ModalResourcePrint';

import DataEntryDropDown from './DataEntryDropDown';
import Button from '@material-ui/core/Button';
import {generateComponentId} from "../../../utils/ComponentUtils";
import connect from "react-redux/es/connect/connect";

// const styles={
//     iconButton:{
//         margin:'0 0.5em',
//         minWidth:'auto',
//         padding:'0 0.5em'
//     },
//
//     iconButtonText:{
//         verticalAlign:'middle',
//         display:'inline-block',
//         fontSize:'0.95em',
//         lineHeight:'24px',
//         marginLeft:'0.1em',
//         marginRight:'0.1em'
//     }
// };
const UPDATE_RESOURCE_RFID_PRINTING = "Update Resource By RFID Label Printing";

class DataEntryButtons extends React.Component{

    static get propTypes(){
        return {
            actions: PropTypes.object.isRequired,
            forms: PropTypes.array,
            selectedRowsCount: PropTypes.number
        };
    }

    static get defaultProps(){
        return {
            forms: []
        };
    }

    // constructor(props){
    //     super(props);
    // }

    openForm(form,metadata){
        this.props.actions.openDynamicReportDataEntry(form,{
            meta:metadata
        });
    }

    getIcon(type){
        switch(type){
            case "IMPORT":
                return ImportIcon; // change to import icon
            case "ADD":
                return AddIcon;
            case "UPDATE":
                return UpdateIcon;
            case "DELETE":
                return DeleteIcon;
            case "EXPORT":
                return ExportIcon;
            case "INFO":
                return InfoIcon;
            case "PRINT":
                return PrintIcon;
            default:
                return UpdateIcon;
        }
    }

    renderCategory(type,isMobile){

        const {enabled}=this.props
        const updateItems = this.props.forms.filter(f => f.type === type).map((f) => {
            let action = null;

            if (f.action != null) {
                action = f.action;
            } else if ((type === "UPDATE" || type === "DELETE") && this.props.selectedRowsCount === 0) {
                action = () => {
                    this.props.actions.displayErrorDialog({
                        title: "Error",
                        message: this.props.selectedRowsCount === 0 ? "Please select a row first." : "Please select a single row.",
                        noconsole: true,
                    });
                };
            } else {
                action = this.openForm.bind(this, FORMS.DYNAMIC_DATA_ENTRY, f);
            }
            return {
                text: f.name,
                action: action
            };
        });
        if(!updateItems.length) return null;

        let label = type[0].toUpperCase() + type.substr(1).toLowerCase();

        let Icon = this.getIcon(type);
        let idButton = generateComponentId(type, null, "button");
        
        return (
            <DataEntryDropDown key={type} type={type} label={label} icon={<Icon />} idButton={idButton} items={updateItems} isMobile={isMobile} enabled={enabled}/>
        );
    }

    render(){

        return (
            <div style={{display:"flex",justifyContent:"flex-start", height: '100%'}}>
                {
                    ["ADD","UPDATE","DELETE","EXPORT","IMPORT","INFO", "PRINT"].map(type=>{
                        return this.renderCategory(type,this.props.isMobile);
                    })
                }
            </div>
        );
    }
}

export {DataEntryButtons};
/*

color: #00779f;
    font-weight: bold;
     letter-spacing: 0.5px;
 */


class Breadcrumbs extends React.Component{

    static get propTypes(){
        return {
            category: PropTypes.string,
            name: PropTypes.string
        };
    }

    static get defaultProps(){
        return {
            category: "Reports",
            name: "Resources"
        };
    }

    render(){
        const {category,name, /*sideBarFiltersLength*/} = this.props;
        const title = category + " / " + name;
        let titleWidth = title.length;
        if(category !== "Dashboards"){
            titleWidth = title.length / 3.2;
            if(titleWidth < 8){
                titleWidth += 1;
            }
        }
        /*let paddingLeft = 3;
        if(sideBarFiltersLength === 0 || sideBarFiltersLength === undefined){
            paddingLeft = 1;
        }*/
        return (
            <div style={{minWidth: `${titleWidth}em`, /*paddingLeft: `${paddingLeft}em`, */}} className={"toolbar-breadcrumbs-container"}>
                <span>{category}</span>
                <span> / </span>
                <span className="toolbar-breadcrumbs-report-child" >{name}</span>
            </div>
        );
    }
}

export {Breadcrumbs};

export class StandardToolbar extends React.Component{
    static get propTypes(){
        return {
            children: PropTypes.node
        };
    }

    render(){
        return (
            <Toolbar className="save-share-toolbar" style={{height:'48px', justifyContent: "normal"}}>
                {this.props.children}
            </Toolbar>
        );
    }
}

class ReportsToolbar extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            importModal: false,
            printModal: false,
            updateModal: false,
        };
        this.handleEmailClick = this.handleEmailClick.bind(this);
        this.handleScheduledEmailClick = this.handleScheduledEmailClick.bind(this);
        this.handlePrintClick = this.handlePrintClick.bind(this);
        this.handleStarClick = this.handleStarClick.bind(this);
        this.handleFullScreenClick = this.handleFullScreenClick.bind(this);
        this.handleImport = this.handleImport.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);
        this.handleRunReport = this.handleRunReport.bind(this);
        this.handleExportCSV = this.handleExportCSV.bind(this);
        this.handleExportHTML = this.handleExportHTML.bind(this);
        this.handleRightSidebar = this.handleRightSidebar.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleOpenPrintModal = this.handleOpenPrintModal.bind(this);
        this.handleClosePrintScreen = this.handleClosePrintScreen.bind(this);
        this.handleSelectSite = this.handleSelectSite.bind(this);
        this.handleUpdateModal = this.handleUpdateModal.bind(this);
        this.handleCloseUpdateModal = this.handleCloseUpdateModal.bind(this);
    }

    static get propTypes(){
        return {
            actions:PropTypes.object,
            additionalReportActions: PropTypes.array,
            forms: PropTypes.array,
            reportName: PropTypes.string,
            reportCategory: PropTypes.string,
            mapEnabled: PropTypes.bool,
            numberOfResults: PropTypes.number,
            columnManager: PropTypes.any,
            reportId:PropTypes.string,
            selectedRowsCount: PropTypes.number,
            isMobile:PropTypes.bool,
            showRightSidebar:PropTypes.bool,
            printerDevices: PropTypes.array,
            canEdit: PropTypes.object,
        };
    }

    shouldComponentUpdate(nextProps){
        return(nextProps.columnManager !== this.props.columnManager) || (this.props.forms !== nextProps.forms);
    }

    handleSaveClick(){ this.props.actions.openSaveReportForm(); }
    handleEmailClick(){ this.props.actions.displayMessage("Email Button clicked.");}
    handleScheduledEmailClick(){ this.props.actions.displayMessage("Scheduled Email Button clicked."); }
    handlePrintClick(){ this.props.actions.displayMessage("Print Button clicked."); }
    handleStarClick(){ this.props.actions.displayMessage("Star Button clicked"); }
    handleFullScreenClick(){ this.props.actions.displayMessage("Full Screen clicked"); }
    handleImport() {
        this.setState({
            importModal: true
        });
        this.forceUpdate();
    }
    handleClose() {
        this.setState({
            importModal: false
        });
        this.forceUpdate();
    }
    handleSomething(){ this.props.actions.displayMessage("Something");}
    handlePlaybackView(){
        this.props.actions.changeView(views.PLAYBACK_VIEW);
    }

    handleRunReport(){
        this.props.actions.reloadReport();
    }

    handleOpenPrintModal(){
        if(this.props.selectedRowsCount>0) {
            this.setState({
                printModal: true,
            });
            this.forceUpdate();
        } else
            this.props.actions.displayErrorDialog({
                title: "Error",
                message: "Please select a row first.",
                noconsole: true,
            });
    }
    handleSelectSite(siteName){
        this.props.actions.getPrinterDevices(siteName);
    }

    handleClosePrintScreen(){
        this.setState({
            printModal: false,
        });
        this.forceUpdate();
    }
    handleUpdateModal(){
        if(this.props.selectedRowsCount>0) {
            this.setState({
                updateModal: true
            });
            this.forceUpdate();
        } else {
            this.props.actions.displayErrorDialog(
                {
                    title: "Error",
                    message: "Please select a row first.",
                    noconsole: true,
                });
        }
    }

    handleCloseUpdateModal(){
        this.setState({
            updateModal: false
        });
        this.forceUpdate();
    }

    // Toggle right details sidebar.
    handleRightSidebar(){
        if(this.props.selectedRowsCount>0) {
            if(!this.props.showRightSidebar)
                this.props.actions.toggleRightSidebar();
            else
                return;
        }
        else
            this.props.actions.displayErrorDialog({
            title: "Error",
            message: "Please select a row first.",
            noconsole: true,
        });

    }

    handleExportCSV(){
        this.props.actions.exportReport(1,'csv');
    }
    handleExportHTML(){
        this.props.actions.exportReport(2,'html');
    }
     render(){
         const {
             actions,
             forms,
             reportName,
             reportCategory,
             mapEnabled,
             numberOfResults,
             columnManager,
             additionalReportActions,
             reportId,
             selectedRowsCount,
             isMobile,
             sideBarFiltersLength,
             view,
             printerDevices,
             //rfidData,
             windowWidth,
             canEdit

         } = this.props;
         const  {importModal, printModal, updateModal}  = this.state;
         //const contentStyle = {};

         /*
         <ClassedIconButton onClick={this.handleStarClick}><StarIcon /></ClassedIconButton>
         <ClassedIconButton onClick={this.handleEmailClick}><EmailIcon /></ClassedIconButton>
         <ClassedIconButton onClick={this.handleScheduledEmailClick}><ClockIcon /></ClassedIconButton>
         <ClassedIconButton onClick={this.handlePrintClick}><PrintIcon /></ClassedIconButton>
         <ClassedIconButton onClick={this.handleSomething.bind(this)}><DownloadIcon /></ClassedIconButton>
         <ClassedIconButton onClick={this.handleSomething.bind(this)}><UploadIcon /></ClassedIconButton>
         <ClassedIconButton onClick={this.handlePlaybackView.bind(this)}><HistoryIcon /></ClassedIconButton>
          */

         let enabledReportToolbar=true;
         if(view===PLAYBACK_VIEW)
             enabledReportToolbar=false;
         const modalImportButtons = (
            <div>
                <Button label="Confirm" onClick={this.handleClose} style={{visibility:'hidden',color:'#00779f'}}>Confirm</Button>
                <Button label="Close" style={{color:'#00779f'}} onClick={this.handleClose}>Close</Button>
            </div>
        );

         let showDialogImport = (IMPORT_ENABLED_REPORTS.indexOf(reportName) !== -1)? canEdit[`edit-report-${reportId}`]: false;
         let showPrintUpdate = ['Resource Type Print Setup'].indexOf(reportName) !== -1 && canEdit[`edit-report-${reportId}`];

         // Define fixed buttons for export.
         const exportButtons=[
             {id: reportId, name: "Export CSV", type: "EXPORT",action:this.handleExportCSV},
             {id: reportId, name: "Export HTML", type: "EXPORT",action:this.handleExportHTML}
         ];

         const formsToolbar = forms.map(f => {
             if(f.name === UPDATE_RESOURCE_RFID_PRINTING){
                 f.action = this.handleOpenPrintModal;
             }
             return f;
         });

         // For Resource Type Printing
         const formPrintUpdateToolbar =[
             {id: reportId, name: "Resource Type RFID Printing Setup", type: "UPDATE", action: this.handleUpdateModal}
         ];
         const message = numberOfResults > 0 ? `${numberOfResults} Results` : '';

         const isReportCategory = (reportName === 'Resources' || reportName === 'Tags');
         
         return (
             <div className={"toolbar-main-container save-share-toolbar"} style={{paddingBottom:'0px'}}>
                 <div className={"toolbar-left-container"}>
                     <Breadcrumbs name={reportName} category={reportCategory} sideBarFiltersLength={sideBarFiltersLength}/>
                 </div>
                 <div className={"toolbar-buttons-container"}>

                    <ToolbarButton id={generateComponentId("run report", null, "button")} iconType={"RUN"} isMobile={isMobile} click={this.handleRunReport} enabled={enabledReportToolbar}/>
                    {!showPrintUpdate ? <DataEntryButtons actions={actions} forms={formsToolbar} selectedRowsCount={selectedRowsCount} isMobile={isMobile} enabled={enabledReportToolbar}/> : null}
                    {showPrintUpdate ? <DataEntryButtons actions={actions} forms={formPrintUpdateToolbar} selectedRowsCount={selectedRowsCount} isMobile={isMobile} enabled={enabledReportToolbar}/> : null}
                    {updateModal && <ModalResourcePrint
                            {...this.props}
                            openModal={updateModal}
                            rfidData={this.props.rfidData}
                            selectedData={this.props.selected}
                            onClose={this.handleCloseUpdateModal}
                        />}
                    <DataEntryButtons actions={actions} forms={exportButtons} isMobile={isMobile} enabled={enabledReportToolbar}/>
                    {showDialogImport && <ToolbarButton id={generateComponentId("import", null, "button")} iconType={"IMPORT"} isMobile={isMobile} click={this.handleImport} enabled={enabledReportToolbar}/>}
                     {importModal && <StyledDialog
                         maxWidth={false}
                         open={importModal}
                         onClose={this.handleClose}
                         actions={modalImportButtons}
                     >
                        <ModalImport
                            style={{height:'auto'}}
                            reportId={reportId}
                            windowWidth={windowWidth}
                            reportName={reportName}
                            isMobile={isMobile}
                            actions={actions}
                        />
                     </StyledDialog>

                    }
                     {printModal && <ModalPrint printerDevices={printerDevices}
                                        {...this.props}
                                        onClose={this.handleClosePrintScreen}
                                        openModal={printModal}
                                        onChange={this.handleSelectSite}
                                        />}
                    <ToolbarButton id={generateComponentId("detail", null, "button")} iconType={"DETAIL"} isMobile={isMobile} click={this.handleRightSidebar} enabled={enabledReportToolbar}/>

                  </div>
                 <div className={"toolbar-right-container"}>
                     <div ref={"NumResults"}>{message}</div>
                     {columnManager}
                     <div className="report-separator">&nbsp;</div>
                     <ClassedIconButton id={generateComponentId("save", null, "button")} onClick={this.handleSaveClick}>
                         <SaveIcon />
                     </ClassedIconButton>
                     {additionalReportActions}
                     <div className="report-separator">&nbsp;</div>
                     <ViewsDropDown isReportCategory={isReportCategory} actions={actions} mapEnabled={mapEnabled} reportId={reportId}/>

                 </div>

             </div>
         );
     }
 }
const mapStateToProps = state => ({
    canEdit: state.user.permissions
});
export default connect(mapStateToProps)(ReportsToolbar);
export {ClassedIconButton as ReportActionButton};
