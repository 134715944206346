export const RFID_MODELS={
    FX7500:{id:"FX7500",label:"FX7500",number:0},
    FX9600:{id:"FX9600",label:"FX9600",number:0},
    ATR7000:{id:"ATR7000",label:"ATR7000",number:0}
};

export const CONF_LLRP = "LLRP";
export const CONF_R2C = "R2C";

export const HIGHLIGHT_STYLE = "0px 0px 4px #4195fc";
export const RFID_READER='RFID Reader';
//Screens of Form of Devices
export const ADD_TEMPLATE = "ADD_TEMPLATE";
export const EDIT_TEMPLATE = "EDIT_TEMPLATE";
export const EDIT_DEVICE_CONFIG = "EDIT_DEVICE_CONFIG";
export const EDIT_DEVICE_SAVED = "EDIT_DEVICE_SAVED";
export const EDIT_DEVICE_MANUALLY_ADDED = "EDIT_DEVICE_MANUALLY_ADDED";
export const EDIT_DEVICE_ERROR = "EDIT_DEVICE_ERROR";
export const DETAILS_TEMPLATE = "DETAILS_TEMPLATE";
//Device actions
export const UPGRADE_FIRMWARE = "update_firmware";
export const VIEW_DETAILS = "viewDetails";
export const EDIT = "edit";
export const PUBLISH = "publish";
export const TEST = "test";
export const START = "start";
export const STOP = "stop";
export const RESTART = "restart";
export const REBOOT = "reboot";
export const ENABLE = "enable";
export const DISABLE = "disable";
export const DELETE = "delete";
export const EDIT_DEV = "edit-dev";
export const CANCEL = "cancel";
export const LOGS = "logs";
export const CONFIGURE_LOGS = "configureLogs";
export const DOWNLOAD_LOGS = "downloadLogs";
export const HISTORICAL = "historical";
export const RESYNC_READER = "resync";
// Device Status


//PATHS OF DEVICE MANAGER
export const PATH_ADD_DEVICES_MANUALLY = "add-devices";
export const PATH_ADD_BATCH_DEVICES = "add-batch-devices";
export const PATH_ADD_SYNC_VIA_ZEBRA_DATA_SERVICE = "add-sync-via-zebra-data-service";
export const PATH_EDIT_DEVICE = "edit-device";
export const PATH_EDIT_DEV = "edit-dev";
export const PATH_ADD_TEMPLATE = "add-template";
export const PATH_EDIT_TEMPLATE = "edit-template";
export const PATH_TEMPLATES = "templates";
export const PATH_FIRMWARES = "firmware";
//Add Devices Steps

export const STEPS_ADD_DEVICE = {
    "add-devices": ['Select types and models', 'Provide device details', 'Place new devices on map'],
    "add-batch-devices": ['Add files to import', 'Confirm imported devices', 'Place new devices on map'],
    "add-sync-via-zebra-data-service": ['Get and review Devices to Import', 'Place new devices on map']
}

//enabled field depends of device state
export const ACTION_OPTIONS = [
    {"item":"viewDetails","label":"View Details","enabled":true},
    {"item":"edit","label":"Edit","enabled":true},
    {"item":"publish","label":"Publish","enabled":true},
    {"item":"test","label":"Test","enabled":true},
    {"item":"start","label":"Start","enabled":true},
    {"item":"stop","label":"Stop","enabled":true},
    {"item":"reboot","label":"Reboot","enabled":true},
    {"item":"enable","label":"Enable","enabled":true},
    {"item":"disable","label":"Disable","enabled":true},
    {"item":"delete","label":"Delete","enabled":true}
];

export const ACTION_HEADER_OPTIONS = [
    {"item":"update_firmware","label":"Upgrade Firmware","enabled":false},
    {"item":"initialize","label":"Initialize","enabled":true},
    {"item":"reset","label":"Reset","enabled":true},
    {"item":"resync","label":"Resync Reader Status","enabled":true},
    {"item":"edit","label":"Edit on Map","enabled":true},
    {"item":"logs","label":"Logs","enabled":false},
    {"item":"publish","label":"Publish","enabled":true},
    {"item":"start","label":"Start","enabled":true},
    {"item":"stop","label":"Stop","enabled":true},
    {"item":"reboot","label":"Reboot","enabled":true},
    {"item":"enable","label":"Enable","enabled":true},
    {"item":"disable","label":"Disable","enabled":true},
    {"item":"delete","label":"Delete","enabled":true},
];

//Map View
export const LOCAL_MAP_VIEW_DEVICES = "LOCAL_MAP_VIEW_DEVICES";
export const LOCAL_MAP_VIEW = "LOCAL_MAP_VIEW";
export const WORLD_MAP_VIEW = "WORLD_MAP_VIEW";

export const FAILED = "failed";
export const SUCESSFUL = "successful";

//Notifications
export const ALERT_SHOW = "alert-show";
export const ALERT_HIDDEN ="alert-hidden";
export const ALERT_SUCCESS = "alertSuccess";
export const ALERT_ERROR = "alertError";
export const ALERT_LOADING = "alertLoading";
export const ALERT_DETAILS = "alertDetails";
export const ALERT_WARNING = "alertWarning";
export const ALERT_RECEIVER = "alertReceiver";
export const ALERT_TESTING_RESULT = "alertTestingResult";

// Columns in device list.
export const DEVICE_MANAGER_COLUMNS = {
    "deviceType": {key:"deviceType",value:"Device Type",width:155,clickable:false,sortable:true,enable:true,order:1,filterable:true,search:true},
    "rfidType": {key:"rfidType",value:"Device Model",width:160,clickable:false,sortable:true,enable:true,order:2,filterable:true,search:true},
    "title": {key:"title",value:"Name",width:160,clickable:false,sortable:true,enable:false,order:3,filterable:true,search:true},
    "firmwareVersion": {key:"firmwareVersion",value:"Firmware Version",width:110,clickable:false,sortable:true,enable:true,order:4,filterable:true,search:false},
    // "logLevel": {key:"logLevel",value:"Log Level",width:150,clickable:false,sortable:false,enable:false,order:5,search:false},
    "ipAddress": {key:"ipAddress",value:"Hostname",width:150,clickable:false,sortable:true,enable:true,order:6,filterable:true,search:true},
    "status": {key:"status",value:"Status",width:130,clickable:false,sortable:true,enable:true,order:7,filterable:true,search:true},
    "alert":{key:"alert",value:"Hardware Health",width: 170,clickable:false,sortable:false,enable:true,order:8,filterable:false,search:false},
    "rfidAntennas": {key:"rfidAntennas",value:"Antenna Ports",width:140,clickable:false,sortable:false,enable:true,order:9,filterable:false,search:false},
    "configuration_state":{key:"configuration_state",value:"Config. State",width:155,clickable:false,sortable:true,enable:true,order:10,filterable:true,search:true},
    // "siteId":{key:"siteId",value:"Site Name",width: 160,clickable:false,sortable:false,enable:true,order:11,filterable:true,search:true},
    "siteId":{key:"siteName",value:"Site Name",width: 160,clickable:false,sortable:true,enable:true,order:11,filterable:true,search:true},
    "location":{key:"location",value:"Map Name",width: 160,clickable:false,sortable:true,enable:true,order:12,filterable:true,search:true},
    "macAddress":{key:"macAddress",value:"Mac Address",width: 160,clickable:false,sortable:true,enable:false,order:13,filterable:true,search:true},
    "actions":{key:"actions",value:"",width: 80,clickable:false,sortable:true,enable:true,order:50,filterable:false,search:false }
};
export const EXTRA_COLUMN = "extraColumn";

export const TEMPLATE_MANAGER_COLUMNS = {
    "title": {key:"title",value:"Name",clickable:true,width: 250,enable:true,order:1},
    "deviceType": {key:"deviceType",value:"Device Type",clickable:true,width: 195,enable:true,order:2},
    "createBy": {key:"createBy",value:"Created by",clickable:true,width: 125,enable:true,order:3},
    "createDate": {key:"createDate",value:"Last Updated",clickable:true,width: 125,enable:true,order:4},
    "devices": {key:"devices",value:"Devices",clickable:true,width: 120,enable:true,order:5},
    "actions": {key:"actions",value:"",clickable:false,width: 75,enable:true,order:6}
};

export const CONSTANT_OF_FORMS = {
    "prefix": {label:"Prefix",value:"prefix"},
    "suffix": {label:"Suffix",value:"suffix"},
    "regex": {label:"Regex",value:"regex"},
    "include": {label:"Include",value:"include"},
    "exclude": {label:"Exclude",value:"exclude"},
    "high": {label:"High",value:"HIGH"},
    "low": {label:"Low",value:"LOW"}
};


export const LOG_DIALOG_COLUMNS = {
    "title": {key:"title",value:"Name",width:200,clickable:false,sortable:false,enable:false,order:1},
    "deviceType": {key:"deviceType",value:"Device Type",width:140,clickable:false,sortable:false,enable:true,order:2},
    "rfidType": {key:"rfidType",value:"Device Model",width:140,clickable:false,sortable:false,enable:true,order:3},
    "ipAddress": {key:"ipAddress",value:"Hostname",width:120,clickable:false,sortable:false,enable:true,order:4}
};

export const LOG_MULTISELECT_DOWNLOAD_COLUMNS = {
    "title": {key:"title",value:"Name",width:120,clickable:false,sortable:false,enable:false,order:1},
    "deviceType": {key:"deviceType",value:"Device Type",width:50,clickable:false,sortable:false,enable:true,order:2},
    "ipAddress": {key:"ipAddress",value:"Hostname",width:300,clickable:false,sortable:false,enable:true,order:3},
    "log.download": {key:"log.download",value:"",width:50,clickable:false,sortable:false,enable:true,order:4}
};

export const LOG_HISTORY_COLUMNS = {
    "time": {key:"time",value:"Timestamp",width:120,clickable:false,sortable:false,enable:false,order:1},
    "logType": {key:"logType",value:"Log Type",width:50,clickable:false,sortable:false,enable:false,order:2},
    "logData.filename": {key:"logData.filename",value:"File Name",width:300,clickable:false,sortable:false,enable:false,order:3},
    "logData.binary": {key:"logData.binary",value:"File",width:50,clickable:false,sortable:false,enable:false,order:4},
};

//DeviceManagerScreen

export const ADD_DEVICES_SCREEN = "ADD_DEVICES_SCREEN";
export const EDIT_DEVICES_SCREEN = "EDIT_DEVICES_SCREEN";
export const LANDING_PAGE_SCREEN = "LANDING_PAGE_SCREEN";
export const ADD_TEMPLATE_SCREEN = "ADD_TEMPLATE_SCREEN";
export const EDIT_TEMPLATE_SCREEN = "EDIT_TEMPLATE_SCREEN";
export const EDIT_TEMPLATE_ERROR_SCREEN = "EDIT_TEMPLATE_ERROR_SCREEN";
export const LOG_HISTORY_DIALOG = "LOG_HISTORY_DIALOG";


//Devices - Configuration states
export const CONF_STATE_SAVED = "Saved";
export const CONF_STATE_PUBLISHED = "Published";
export const CONF_STATE_TESTED = "Tested";

//Devices - Operational Status
export const OPE_STATUS_NA = "n/a";
export const OPE_STATUS_RUNNING = "Running";
export const OPE_STATUS_STOPPED = "Stopped";
export const OPE_STATUS_DISABLED = "Disabled";
export const OPE_STATUS_REBOOTING = "Rebooting";
export const OPE_STATUS_TESTING = "Testing";
export const OPE_STATUS_FAILURE = "Failure";
export const OPE_STATUS_INITIALIZED = "Initialized";
export const OPE_STATUS_PUBLISHING = "Publishing";
export const OPE_STATUS_UPDATING_FIRMWARE = "Updating Firmware";
export const OPE_STATUS_FIRMWARE_UPDATED = "Firmware Updated";

// UI to RFID tasks
export const INITIALIZE='initialize';
export const RESET = 'reset';
export const RFID_TASKS = [
    INITIALIZE
];

//Device Operations constants
export const OPE_STATUS_COMPLETED = "complete";
export const OPE_STATUS_PENDING = "pending";
export const OPE_TIMEOUT = 5000;
export const LIST_KNOWN_STATUS = [OPE_STATUS_NA, OPE_STATUS_INITIALIZED, OPE_STATUS_PUBLISHING, OPE_STATUS_RUNNING, OPE_STATUS_FAILURE, OPE_STATUS_PENDING, OPE_STATUS_STOPPED, OPE_STATUS_DISABLED, OPE_STATUS_REBOOTING, OPE_STATUS_TESTING, OPE_STATUS_UPDATING_FIRMWARE, OPE_STATUS_FIRMWARE_UPDATED];

// get device operations options
export const OPERATIONS_AVAILABLE_MAP = {
    [UPGRADE_FIRMWARE]: [OPE_STATUS_INITIALIZED, OPE_STATUS_RUNNING, OPE_STATUS_FAILURE, OPE_STATUS_STOPPED, OPE_STATUS_TESTING, OPE_STATUS_FIRMWARE_UPDATED],
    [INITIALIZE]: [OPE_STATUS_NA, OPE_STATUS_INITIALIZED, OPE_STATUS_RUNNING, OPE_STATUS_FAILURE, OPE_STATUS_STOPPED, OPE_STATUS_FIRMWARE_UPDATED],
    // [RESET]: [OPE_STATUS_NA, OPE_STATUS_INITIALIZED, OPE_STATUS_RUNNING, OPE_STATUS_FAILURE, OPE_STATUS_STOPPED, OPE_STATUS_FIRMWARE_UPDATED],
    [RESYNC_READER]: [OPE_STATUS_INITIALIZED, OPE_STATUS_RUNNING, OPE_STATUS_FAILURE, OPE_STATUS_STOPPED, OPE_STATUS_FIRMWARE_UPDATED],
    [VIEW_DETAILS]: [OPE_STATUS_NA, OPE_STATUS_INITIALIZED, OPE_STATUS_PUBLISHING, OPE_STATUS_RUNNING, OPE_STATUS_FAILURE, OPE_STATUS_PENDING, OPE_STATUS_STOPPED, OPE_STATUS_DISABLED, OPE_STATUS_REBOOTING, OPE_STATUS_TESTING, OPE_STATUS_UPDATING_FIRMWARE, OPE_STATUS_FIRMWARE_UPDATED],
    [EDIT]: [OPE_STATUS_NA, OPE_STATUS_INITIALIZED, OPE_STATUS_PUBLISHING, OPE_STATUS_RUNNING, OPE_STATUS_FAILURE, OPE_STATUS_PENDING, OPE_STATUS_STOPPED, OPE_STATUS_DISABLED, OPE_STATUS_REBOOTING, OPE_STATUS_TESTING, OPE_STATUS_UPDATING_FIRMWARE, OPE_STATUS_FIRMWARE_UPDATED],
    [LOGS]: [OPE_STATUS_NA, OPE_STATUS_INITIALIZED, OPE_STATUS_PUBLISHING, OPE_STATUS_RUNNING, OPE_STATUS_FAILURE, OPE_STATUS_PENDING, OPE_STATUS_STOPPED, OPE_STATUS_DISABLED, OPE_STATUS_REBOOTING, OPE_STATUS_TESTING, OPE_STATUS_UPDATING_FIRMWARE, OPE_STATUS_FIRMWARE_UPDATED],
    [CONFIGURE_LOGS]: [OPE_STATUS_INITIALIZED, OPE_STATUS_PUBLISHING, OPE_STATUS_RUNNING, OPE_STATUS_FAILURE, OPE_STATUS_PENDING, OPE_STATUS_STOPPED, OPE_STATUS_DISABLED, OPE_STATUS_TESTING, OPE_STATUS_FIRMWARE_UPDATED],
    [DOWNLOAD_LOGS]: [OPE_STATUS_INITIALIZED, OPE_STATUS_PUBLISHING, OPE_STATUS_RUNNING, OPE_STATUS_FAILURE, OPE_STATUS_PENDING, OPE_STATUS_STOPPED, OPE_STATUS_DISABLED, OPE_STATUS_TESTING, OPE_STATUS_FIRMWARE_UPDATED],
    [HISTORICAL]: [OPE_STATUS_NA, OPE_STATUS_INITIALIZED, OPE_STATUS_PUBLISHING, OPE_STATUS_RUNNING, OPE_STATUS_FAILURE, OPE_STATUS_PENDING, OPE_STATUS_STOPPED, OPE_STATUS_DISABLED, OPE_STATUS_REBOOTING, OPE_STATUS_TESTING, OPE_STATUS_UPDATING_FIRMWARE, OPE_STATUS_FIRMWARE_UPDATED],
    [PUBLISH]: [OPE_STATUS_INITIALIZED, OPE_STATUS_RUNNING, OPE_STATUS_FAILURE, OPE_STATUS_STOPPED, OPE_STATUS_FIRMWARE_UPDATED],
    [START]: [OPE_STATUS_INITIALIZED, OPE_STATUS_FAILURE, OPE_STATUS_STOPPED, OPE_STATUS_FIRMWARE_UPDATED],
    [STOP]: [OPE_STATUS_INITIALIZED, OPE_STATUS_RUNNING, OPE_STATUS_FIRMWARE_UPDATED],
    [RESTART]: [OPE_STATUS_INITIALIZED, OPE_STATUS_RUNNING, OPE_STATUS_FAILURE, OPE_STATUS_STOPPED, OPE_STATUS_FIRMWARE_UPDATED],
    [REBOOT]: [OPE_STATUS_INITIALIZED, OPE_STATUS_RUNNING, OPE_STATUS_FAILURE, OPE_STATUS_STOPPED, OPE_STATUS_TESTING, OPE_STATUS_FIRMWARE_UPDATED],
    [ENABLE]: [OPE_STATUS_DISABLED],
    [DISABLE]: [OPE_STATUS_INITIALIZED, OPE_STATUS_RUNNING, OPE_STATUS_FAILURE, OPE_STATUS_STOPPED, OPE_STATUS_FIRMWARE_UPDATED],
    [DELETE]: [OPE_STATUS_NA, OPE_STATUS_INITIALIZED, OPE_STATUS_PUBLISHING, OPE_STATUS_RUNNING, OPE_STATUS_FAILURE, OPE_STATUS_PENDING, OPE_STATUS_STOPPED, OPE_STATUS_REBOOTING, OPE_STATUS_TESTING, OPE_STATUS_UPDATING_FIRMWARE, OPE_STATUS_FIRMWARE_UPDATED],
    [CANCEL]: [OPE_STATUS_PENDING]
}

export const TOOLTIPS_OPERATIONS = {
    update_firmware: {},
    initialize: {'https': 'Already initialized via Zebra Data Service', 'mqtt': 'Due to security restriction, please use local R2C app to perform this action'},
    reset: {'all': 'Helps to switch the reader to via Zebra Data Service when the device is configured with Direct to Reader','https': 'Already initialized via Zebra Data Service', 'mqtt': 'Due to security restriction, please use local R2C app to perform this action'},
    resync: {},
    viewDetails: {},
    edit: {},
    logs: {},
    configureLogs: {},
    downloadLogs: {},
    historical: {},
    publish: {},
    start: {},
    stop: {},
    restart: {},
    reboot: {},
    enable: {},
    disable: {},
    delete: {},
    cancel: {}
}

export const TOOLTIP_OPERATION_DISABLED = {'https': 'Already initialized via Zebra Data Service', 'mqtt': 'Due to security restriction, please use local R2C app to perform this action'};
//Limit of characters on Browsers
export const IE_MAX_CHARACTERS = 2083;
// export const MODERN_BROWSER_MAX_CHARACTERS = 2097152;

//Max Quantity of selected items in filters
// export const MAX_ITEMS_SELECTED = 5;

// Alert Message
export const ALERT_MESSAGE = "Is not possible select more items.";
export const ALERT_MESSAGE_ON_CLOUD = "Due to security restriction, please use local R2C app to perform this action";
export const ALERT_MESSAGE_ZEBRA_DATA_SERVICE = "Already initialized via Zebra Data Service";

// All Maps Icon
export const  ALL_MAPS = "assets/images/devices/all-maps.svg";



//Screen tables

export const LANDING_DEVICE_TABLE = "LANDING_DEVICE_TABLE";
export const LANDING_TEMPLATE_TABLE = "LANDING_TEMPLATE_TABLE";

// Validation fields
export const VALIDATION_TYPE_CUSTOM = "VALIDATION_TYPE_CUSTOM";
export const VALIDATION_TYPE_CUSTOM_PATH = "VALIDATION_TYPE_CUSTOM_PATH";
export const VALIDATION_TYPE_REGEXP = "VALIDATION_TYPE_REGEXP";
export const VALIDATION_MESSAGE_NEGATIVE_VALUE = "You should provide negative value";
export const VALIDATION_TYPE_REQUIRED = "VALIDATION_TYPE_REGEXP";
export const VALIDATION_MESSAGE_REQUIRED = "Mandatory field";

// TagFilter fields
export const VALIDATION_TAGFILTER_VALUE = "TagId field should have a value";
export const VALIDATION_TAGFILTER_MATCH = "Match field should have a value";
export const VALIDATION_TAGFILTER_OPERATION = "Operation field should have a value";
export const VALIDATION_TAGFILTER_LENGTH = "TagId field number of digits must be even";

// Inventory fields
export const VALIDATION_INVENTORY_VALUE = "Interval field value should be more than zero";
export const VALIDATION_INVENTORY_UNIT = "Unit field should have a value";

// Portal Settings fields
export const VALIDATION_PORTAL_PORT = "Port field should have a value";
export const VALIDATION_PORTAL_SIGNAL = "Signal field should have a value";

//Advanced Operation
export const VALIDATION_JSON_FORMAT = "VALIDATION_JSON_FORMAT";
export const VALIDATION_JSON_MESSAGE = "Advanced Operation dont have a JSON valid";
export const VALIDATION_JSON_READER_CONFIGURATION_MESSAGE = "Reader Configuration dont have a JSON valid";

//Rfid antennas fields
export const VALIDATION_ANTENNAS_REQUIRED = "VALIDATION_ANTENNAS_REQUIRED";
export const VALIDATION_ANTENNAS_MESSAGE = "The antenna name must be unique and cannot be empty";
export const VALIDATION_ANTENNAS_TRANSMIT_POWER_MESSAGE = "Min value is 10";

// Template Fields
export const TEMPLATE_FIELDS = ["inventoryInterval","operationMode","rfidAntennas","portalSettings","tagFilter","protocol"];

export const LIST_OF_OPERATIONS_TO_EXCLUDE = {
    initialize:true,
    // update_firmware:true,
    //reboot:true
};

// Template select rows
export const TEMPLATES_DETAILS_ALERT = "You must select an item to show it details";

//Limit of characters on Template Form
export const TEMPLATE_MAX_FIELD_NAME = 40;
export const TEMPLATE_MAX_FIELD_DESCRIPTION = 500;

export const EMPTY_REQ_PARAMS = {
    params: {},
    headers: {
        'Content-Type': 'application/json'
    }
};

//options for number of antennas per reader
export const NUMBER_ANTENNAS = {
    FX7500:[2,4,8],
    FX9600:[2,4,8],
    //ATR7000:[2,4,8,14]
    ATR7000:[14]
}
export const getMaxNumberAntennasByDevice = ()=>{
    const result = {};
    Object.keys(NUMBER_ANTENNAS).forEach(rfidType=>{
        result[rfidType] = NUMBER_ANTENNAS[rfidType].slice(-1)[0]
    });
    return result;
};
//max size device title
export const  MAX_LENGTH_DEVICE_TITLE = 300;

// Pagination configuration
export const PAGINATION_LIMIT = 100;

// zone template
export const NEW_AUTO_ZONE_TEMPLATE = {
    "name":"ZoneTestV",
    "groupName":"Zone Group 4_2",
    "siteId": null,
    "mapId":10,
    "description":"Auto zone group",
    "type":"standard",
    "color":"#ddeb14",
    "priority":-1,
    "exitZone":"false",
    "deleteTimestamp":null,
    "updateTimestamp":"2021-03-16T23:16:56.261Z",
    "whereportInclusion":"",
    "resourceOrientation":"0",
    "shape":{
        "type":"Feature",
        "geometry":{
            "type":"Polygon",
            "coordinates":[]
        },
        "properties":null
    }
};

// Antennas transmit power marks

export const ANTENNAS_POWER_SLIDER_MARKS = [
    // {
    //     value: 1,
    //     label: '1',
    // },
    // {
    //     value: 5,
    //     label: '5',
    // },
    {
        value: 10,
        label: '10',
    },
    {
        value: 15,
        label: '15',
    },
    {
        value: 20,
        label: '20',
    },
    {
        value: 25,
        label: '25',
    },
    {
        value: 30,
        label: '30',
    },
    /*{
        value: 35,
        label: '35',
    },
    {
        value: 40,
        label: '40',
    },
    {
        value: 45,
        label: '45',
    },*/
];


export const CONFIGURATION_VALIDATION_OBJECT = {
    GPIOLED_VALIDATED_PATHS: ['$.{gpioled_condition}[0].pin','$.{gpioled_condition}[0].state','$.{gpioled_condition}[0].postActionState',
        '$.{gpioled_condition}[0].led','$.{gpioled_condition}[0].color','$.{gpioled_condition}[0].postActionColor','$.{gpioled_condition}[0].blink.ON',
        '$.{gpioled_condition}[0].blink.OFF','$.{gpioled_condition}[0].blink.DURATION','$.{gpioled_condition}[0].conditions[0]'],
    VALIDATED_PATHS:['$.xml', '$.maxEventRetentionTimeInMin',
        '$.maxNumEvents','$.throttle','$.maxPayloadSizePerReport','$.rssiFilter.threshold'],
    GPIOLED_FIELDS: ["GPI_1_H","GPI_1_L","GPI_2_H","GPI_2_L","CLOUD_DISCONNECT","CLOUD_CONNECT","TAG_READ","RADIO_START","RADIO_STOP"],
    ANTENNAS_FIELDS : ['$.antennaStopCondition', '$.linkProfile', '$.query', '$.transmitPower']
}
