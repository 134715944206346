import React from 'react';
import PropTypes from 'prop-types';

export default class LayerGroup extends React.Component{

    static get propTypes(){
        return {
            map: PropTypes.any,
            zIndexKey: PropTypes.any,
            zIndexFn: PropTypes.func,
            children: PropTypes.any
        };
    }

    render(){

        const {map,zIndexKey,zIndexFn} = this.props;

        // Standardize the children.
        const children = (this.props.children.length) ? this.props.children.filter((v)=>!!v) : [this.props.children];

        // Give each child a 'map' and a 'zIndex' prop.
        const childrenWithProps = React.Children.map( React.Children.toArray(children),
            (child,i) => React.cloneElement(child, {
                map: map,
                zIndexKey:`${zIndexKey}-${i}`,
                zIndexFn
            })
        );

        return (
            <div>
                {childrenWithProps}
            </div>
        );
    }
}
