export const SampleFields = {
	":pCallModeName": [""],
	"pAttributeName": ["Resource Custom1","Resource Custom10","Resource Custom11","Resource Custom12","Resource Custom2","Resource Custom3","Resource Custom4","Resource Custom5","Resource Custom6","Resource Custom7","Resource Custom8","Resource Custom9"],
	"pAttributeValue": ["A custom value", "A value with numbers 0123", "A value with \"\"quotes\"\"", "A value with special \"\"characters\"\", Aa#_-!123."],
	"pAttributeValue1": ["A custom value 1", "A value with numbers 0123", "A value with \"\"quotes\"\"", "A value with special \"\"characters\"\", Aa#_-!123."],
	"pAttributeValue10": ["A custom value 10", "A value with special \"\"characters\"\", Aa#_-!123.", "A value with \"\"quotes\"\"", "A value with numbers 0123"],
	"pAttributeValue11": ["A custom value 11", "A value with numbers 0123", "A value with \"\"quotes\"\"", "A value with special \"\"characters\"\", Aa#_-!123."],
	"pAttributeValue12": ["A custom value 12", "A value with \"\"quotes\"\"", "A value with special \"\"characters\"\", Aa#_-!123.", "A value with numbers 0123"],
	"pAttributeValue2": ["A custom value 2", "A value with \"\"quotes\"\"", "A value with special \"\"characters\"\", Aa#_-!123.", "A value with numbers 0123"],
	"pAttributeValue3": ["A custom value 3", "A value with \"\"quotes\"\"", "A value with numbers 0123", "A value with special \"\"characters\"\", Aa#_-!123."],
	"pAttributeValue4": ["A custom value 4", "A value with special \"\"characters\"\", Aa#_-!123.", "A value with numbers 0123", "A value with \"\"quotes\"\""],
	"pAttributeValue5": ["A custom value 5", "A value with special \"\"characters\"\", Aa#_-!123.", "A value with \"\"quotes\"\"", "A value with numbers 0123"],
	"pAttributeValue6": ["A custom value 6", "A value with numbers 0123", "A value with \"\"quotes\"\"", "A value with special \"\"characters\"\", Aa#_-!123."],
	"pAttributeValue7": ["A custom value 7", "A value with \"\"quotes\"\"", "A value with special \"\"characters\"\", Aa#_-!123.", "A value with numbers 0123"],
	"pAttributeValue8": ["A custom value 8", "A value with \"\"quotes\"\"", "A value with numbers 0123", "A value with special \"\"characters\"\", Aa#_-!123."],
	"pAttributeValue9": ["A custom value 9", "A value with special \"\"characters\"\", Aa#_-!123.", "A value with numbers 0123", "A value with \"\"quotes\"\""],
	"pBlinksPerHistory": ["1", "3", "10", "6", "5"],
	"pButton_proces": ["Yes","No"],
	"pCallMode": ["Disabled","Button Press","Switcher"],
	"pComment" : ["This is a comment, any character can be \"\"here\"\", inclusive special characters. Aa#_-!123."],
	"pDead_time": ["1200", "3000", "10", "5600", "1234"],
	"pEvent_log": ["Yes","No"],
	"pExtTagID": ["15000", "12345", "00001", "000000001", "1", "15001"],
	"pFilterEnable": ["Enabled","Disabled"],
	"pflexibleField1": ["A custom value fl_1", "A value with numbers 0123", "A value with \"\"quotes\"\"", "A value with special \"\"characters\"\", Aa#_-!123."],
	"pflexibleField2": ["A custom value fl_2", "A value with \"\"quotes\"\"", "A value with special \"\"characters\"\", Aa#_-!123.", "A value with numbers 0123"],
	"pflexibleField3": ["A custom value fl_3", "A value with \"\"quotes\"\"", "A value with numbers 0123", "A value with special \"\"characters\"\", Aa#_-!123."],
	"pflexibleField4": ["A custom value fl_4", "A value with special \"\"characters\"\", Aa#_-!123.", "A value with numbers 0123", "A value with \"\"quotes\"\""],
	"pflexibleField5": ["A custom value fl_5", "A value with special \"\"characters\"\", Aa#_-!123.", "A value with \"\"quotes\"\"", "A value with numbers 0123"],
	"pIcon": ["icon_1", "icon_2", "icon_3"],
	"pIconColor": ["red", "blue", "green"],	
	"pIconID": ["45E", "1", "001"],
	"pLocation_proc": ["Do Not Keep History","On Location Change","On Location Group Change"],
	"pLockdnCnt": ["2", "1", "7", "3"],
	"pLockdnEnable": [""],
	"pLockdown": ["Yes","No"],
	"pMedianCnt": ["3", "6", "5", "10"],
	"pMedianCntz": ["1", "4", "5", "2"],
	"pMedianCntZ": ["4", "5", "2", "3"],
	"pMode": [""],
	"pModule": ["Base MotionWorks Enterprise"],
	"pMoveCnt": ["3", "1", "6", "5"],
	"pMoveCount": ["3", "1", "6", "5"],
	"pMoveRadius": ["20", "30", "15", "10"],
	"pNewAttributeValue": ["A new value \"\"Aa,_-!12\"\"", "A value with numbers 0123", "A value with \"\"quotes\"\"", "A value with special \"\"characters\"\", Aa#_-!123."],
	"pNewExtTagID": ["111111110", "2", "MyTagID002", "15003", "54321", "10000"],
	"pNoBlinkThreshold": ["6", "4", "2", "1"],
	
	"pOffsetValues": [""],
	"pOldAttributeValue": ["An existing value related to the attribute. \"\"Aa,_-!12\"\""],
	"pOldExtTagID": ["15000", "12345", "00001", "000000001", "1", "15001"],
	"pPurgeOffsetDays": ["1", "3", "5", "2"],
	"pPurgeScheduleId": ["None","Offset from last update","Offset from dissociation"],
	"pRateFilterEnabled": ["Enabled","Disabled"],
	"pRateFilterMoveThreshold": ["10", "3", "5", "9"],
	"pRateFilterTimeMax": ["3", "1", "6", "5"],
	"pRateFilterTimeMin": ["5", "10", "15", "92"],
	"pReserved": ["User Defined"],
	
	"pSourceTagTypeName": ["WHERENET","DART","EPC","UNKNOWN","MPACT"],
	"pSuspicious": ["Yes","No"],
	"pTagSource": ["WHERENET","DART","EPC","UNKNOWN","MPACT","MSE"],
	"pTagTypeName": ["WHERENET","DART","EPC","UNKNOWN","MPACT"],
	
	"pResource": ["RCK20_15000", "000101", "MyIDForResource", "A resource id with spaces", "R1710000000", "Box0001"],
	"pResourceType": ["ResourceTypeName", "User_Defined", "resourceType001", "rt01", "RT_01"],
	"pType":         ["ResourceTypeName", "User_Defined", "resourceType001", "rt01", "RT_01"],// resource type id
	"pName": ["Resource Type Name", "User Defined", "resource type 001", "rt01", "RT_01"],//Display Name in resource type
	"pMapId": ["1", "4", "5", "23", "2", "618"],
	"pSiteId": ["6196a8a483b9424182eae095","3456a8a483b9424182ea1234","6196a8a483bae13182eae0ba","3489a8a483b942418256f095","6196a8a483b9424182eae095"],
	"pZoneId": ["61a63d5183b9423640eb8ee0", "87123d5183b9423640ebfa12", "61fe895183b9423678348e12","61a63d5183b9423640eb7456","61a63d5183b9423640eb8ee0"],
	"pZoneName": ["Zone 1", "z", "Zone_3", "Zone - 4", "zone_5", "Zone 1"],
	"pZoneGroup": ["Zone Group 1", "zg 2", "Zg_3", "zonegroup - 4", "ZG.5", "Zone Group 1"],
	"pTagAndSource":  [
		"WHERENET:1,EPC:F,EPC:ABCDEF",
		"WHERENET:294967295",
		"EPC:AbCd321,DART:FEDCBA019",
		"WHERENET:321,EPC:A",
		"WHERENET:543210,WHERENET:234567890",
		"EPC:CBA0123,EPC:73B54,DART:0a1B2c3D4e5F6"
	],
	"pSource": ["WHERENET","DART","EPC","UNKNOWN","MPACT","MSE"],
	"pTag": ["ABCD", "012", "max4", "_123"],
	"pDescription": ["Empty value allowed", "Letters and numbers Allowed","ABCD", "ABCD 123"],//Description of resource type
	
	"pExpiration": ["1", "5", "1200", "60"],
	"pAutoDissociation": ["Yes","No"],
	"pRFID": ["Yes","No"],
	"pTagOffset": ["","{x:1,y:2,z:3}"],
	"pMoveMedian": ["{radius:20,moveCount:3,moveMedian:1,medianCount:3,medianCountZ:3}"],
	"pStationZone": ["{lockMode:noLock,zoneUnlockDistance:1}","{lockMode:xyLock,zoneUnlockDistance:1}","{lockMode:zoneLock,zoneUnlockDistance:0}"],
	"pRate": ["{radius:2,maxTime:1,minTime:2}"],
	"pZoneLockdown": ["{count:3}"],
	"pZoneChange": ["{numBlinks:3}"],
	"pParentType": ["", "ResourceTypeName"],//Resource Type Parent of a resource type
	"pUpdateMode": ["None", "Parent", "Children"],
	"pCustomProperties": ["value1","value9","value10","value2","value5"],
	"pCustomProperties2": ["value5","value6","value1","value2","value3"],
	"pCustomProperties3": ["value8","value1","value2","value9","value10"],
	"pCustomProperty": ["customProperty1","customProperty2","customProperty3"],
	"pCustomPropertyValue": ["value1","value2","value3"],
	
	
	/*
	From resourcesvc
	"WHERENET tag's id has to be integer from 1 to 4294967295"
	"DART" and "EPC" tag's id has to be hex value, each letter should be one of [0-9,a-f,A-F]
	*/
	"tags": [
		{"source": "WHERENET", "id":          1},
		{"source": "EPC", "id": "F"},
		{"source": "WHERENET", "id": 4294967295},
		{"source": "EPC", "id": "AbCd321"},
		{"source": "DART", "id": "FEDCBA019"},
		{"source": "WHERENET", "id":        321},
		{"source": "EPC", "id": "A"},
		{"source": "WHERENET", "id":     543210},
		{"source": "WHERENET", "id": 1234567890},
		{"source": "EPC", "id": "ABCDEF"},
		{"source": "EPC", "id": "CBA0123"},
		{"source": "EPC", "id": "73B54"},
		{"source": "DART", "id": "0a1B2c3D4e5F6"}
	]
}
export default SampleFields;

