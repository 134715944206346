import Vector from './Vector';

const EPS = 1e-8;

class Point extends Vector {
    constructor(x = 0, y = 0) {
        super(x, y);
    }

    less(p) {
        return this.x < p.x - EPS || (Math.abs(this.x - p.x) < EPS && this.y < p.y - EPS);
    }

    min(p) {
        if (this.less(p)) {
            return this;
        }
        return p;
    }

    max(p) {
        if (this.less(p)) {
            return p;
        }
        return this;
    }

    betweenSegment(A, B) {
        return this.x + EPS >= Math.min(A.x, B.x) && this.x <= Math.max(A.x, B.x) + EPS &&
            this.y + EPS >= Math.min(A.y, B.y) && this.y <= Math.max(A.y, B.y) + EPS;
    }

    betweenStrictSegment(A, B) {
        return this.x > Math.min(A.x, B.x) && this.x < Math.max(A.x, B.x) &&
            this.y > Math.min(A.y, B.y) && this.y < Math.max(A.y, B.y);
    }

    area(A, B, C) {
        return (B.sub(A)).prodCross(C.sub(A));
    }

    onSegment(A, B) {
        return Math.abs(this.area(A, B, this)) < EPS && this.betweenSegment(A, B);
    }

    onStrictSegment(A, B) {
        return Math.abs(this.area(A, B, this)) < EPS && this.betweenStrictSegment(A, B);
    }

    // http://www.sunshine2k.de/coding/java/PointOnLine/PointOnLine.html
    getProjectedPointOnLine(A, B) {
        const e1 = new Vector(B.x - A.x, B.y - A.y);
        const e2 = new Vector(this.x - A.x, this.y - A.y);
        const valDp = e1.prodPoint(e2);
        // get squared length of e1
        if (e1.mod2() === 0) { // A and B are the same point
            return new Point(...A.coordinates());
        }
        const len2 = e1.x * e1.x + e1.y * e1.y;
        return new Point((A.x + (valDp * e1.x) / len2), (A.y + (valDp * e1.y) / len2));
    }

    getProjectedPointOnSegment(A, B) {
        const projectedPoint = this.getProjectedPointOnLine(A, B);
        if (projectedPoint.betweenSegment(A, B)) {
            return projectedPoint;
        }
        const a = this.sub(A).mod2();
        const b = this.sub(B).mod2();
        if (a < b) {
            return new Point(...A.coordinates());
        }
        return new Point(...B.coordinates());
    }

    intersects(P1, P2, P3, P4) {
        const A1 = this.area(P3, P4, P1);
        const A2 = this.area(P3, P4, P2);
        const A3 = this.area(P1, P2, P3);
        const A4 = this.area(P1, P2, P4);
        if (((A1 > 0 && A2 < 0) || (A1 < 0 && A2 > 0)) &&
            ((A3 > 0 && A4 < 0) || (A3 < 0 && A4 > 0))) {
            return true;
        } else if (A1 === 0 && P1.onSegment(P3, P4)) {
            return true;
        } else if (A2 === 0 && P2.onSegment(P3, P4)) {
            return true;
        } else if (A3 === 0 && P3.onSegment(P1, P2)) {
            return true;
        } else if (A4 === 0 && P4.onSegment(P1, P2)) {
            return true;
        }
        return false;
    }

    intersectsStrict(P1, P2, P3, P4) {
        const A1 = this.area(P3, P4, P1);
        const A2 = this.area(P3, P4, P2);
        const A3 = this.area(P1, P2, P3);
        const A4 = this.area(P1, P2, P4);

        if (((A1 > 0 && A2 < 0) || (A1 < 0 && A2 > 0)) &&
            ((A3 > 0 && A4 < 0) || (A3 < 0 && A4 > 0))) {
            return true;
        } else if (A1 === 0 && P1.onStrictSegment(P3, P4)) {
            return true;
        } else if (A2 === 0 && P2.onStrictSegment(P3, P4)) {
            return true;
        } else if (A3 === 0 && P3.onStrictSegment(P1, P2)) {
            return true;
        } else if (A4 === 0 && P4.onStrictSegment(P1, P2)) {
            return true;
        }
        return false;
    }

    middlePoint(P2) {
        return new Point((this.x + P2.x) / 2, (this.y + P2.y) / 2);
    }
}

export default Point;
