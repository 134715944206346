import React from 'react';
import PropTypes from 'prop-types';

// External State (with default) to reduce the number of jarring transitions.
let maximizerHeight = window.innerHeight - 128;

class Maximizer extends React.Component{

    constructor(){
        super();
        this.state={
            height:maximizerHeight
        };

        this.handleResize = this.handleResize.bind(this);
    }

    static get propTypes(){
        return {
            hasTitle: PropTypes.bool,
            hasActions: PropTypes.bool,
            style: PropTypes.object,
            children:PropTypes.any
        };
    }


    getMaxHeight(){

        const {hasTitle, hasActions} = this.props;

        const clientHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        const dialogContent = this.refs.wrapper.parentNode;

        let maxDialogContentHeight = clientHeight - 2 * 64;

        if (hasTitle) maxDialogContentHeight -= dialogContent.previousSibling;

        if (hasActions) {
            maxDialogContentHeight -= dialogContent.nextSibling;
        }

        return parseInt(maxDialogContentHeight) - parseInt(dialogContent.style.paddingTop) - parseInt(dialogContent.style.paddingBottom) + 'px';
    }

    componentDidMount(){
        this.resize();
    }

    resize(){
        maximizerHeight = this.getMaxHeight.call(this);
        this.setState({
            height:maximizerHeight
        });
    }

    render(){
        const {
            hasTitle,   //eslint-disable-line
            hasActions, //eslint-disable-line
            style,
            children,
            ...other
        } = this.props;

        return (
            <div ref="wrapper" {...other} style={{position:'relative',...style, height:this.state.height}}>
                {children}
            </div>
        );
    }

    handleResize(){
        console.log("handleResize");
        this.resize();
    }
}

export default Maximizer;
