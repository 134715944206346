import Promise from 'promise';
import {displayErrorFromAxios, ICService, ReportService, SiteViewService, ResourceService, LCSMapService, AlertService} from './util';
import WidgetStore from '../utils/widget-definitions/WidgetStore';
import {compileChart} from '../utils/widget-definitions/compile-chart';
import SystemDashboard from '../utils/dashboard-definitions/SystemDashboard';
import TagsDashboard from '../utils/dashboard-definitions/TagsDashboard';
import {LOAD_DATA_WIDGET, LOAD_OPTIONS_WIDGET, SET_PARAMS_WIDGET} from '../constants/ActionTypes';
import {getTimes, PERIOD} from '../views/util/report/filter/util';
import {resourceByCustomAttributeValues} from "../views/dashboard/widgets/util/DashboardConstants";

export const requestDashboard = (dashboardId) => {
    return dispatch =>{
        return Promise.resolve(getLocalDashboard(dashboardId))
         .catch(displayErrorFromAxios.bind(null,dispatch));
    };
};
/*
function getServerDashboard(id){
    return ReportService.instance().get(`/dashboards/${id}`)
     .then(response => response.data);
}*/

export function getLocalDashboard(id){
    if(parseInt(id) === 1){
        return TagsDashboard;
    }else{
        return SystemDashboard;
    }
}



export const requestDashboardWidget = (widgetId,queryParameters) => {
    return dispatch =>{
        return Promise.resolve( getLocalWidget(widgetId,queryParameters) )
        .catch(displayErrorFromAxios.bind(null,dispatch));
    };
};

export const requestDashboardWidgetDefinition = (widgetId)=>{
    return (/*dispatch*/) =>{
        return Promise.resolve(WidgetStore.get(widgetId));
    };
};

export const requestDashboardWidgetData = (definition)=>{
    return (/*dispatch*/) => {
        return Promise.resolve(getLocalWidgetFromDefinition(definition,{}));
    };
};

/*
function getServerWidget(id){
    return ReportService.instance().get(`/dashboards/widgets/${id}`)
    .then(response=>response.data);
}*/

function getLocalWidget(id,queryParameters){
    const def = WidgetStore.get(id);

    return getLocalWidgetFromDefinition(def,queryParameters);
}

function getLocalWidgetFromDefinition(def,queryParameters){
    if(!def.dataProvider) return def;

    let requestData = () => requestDashboardData(def.dataProvider,queryParameters);
    if(!queryHasAllRequiredParameters(queryParameters,def.parameters)){
        requestData = ()=>Promise.resolve([]);
    }

    switch(def.type){
        case "GRAPH":
            return requestData()
            .then(data => compileChart(def.data,data))
            .then(x=>({...def, ...x}));
        case "MAP":
        case "TABLE":
        default:
            return requestData()
            .then(data=>({...def, data}));
    }
}

function queryHasAllRequiredParameters(query,parameters){

    if(!parameters) return true;

    let i, l = parameters.length;
    for(i=0;i<l;i++){
        let par = parameters[i];
        if(par.required && (query && query[par.name]) === undefined) return false;
    }
    return true;
}

export const requestDashboardFilterData = (dataProvider,qp={}) => {
    return (/*dispatch*/) =>{
        return requestDashboardData(dataProvider,qp);
    };
};

function requestDashboardData(dataProvider,queryParameters){
    const dataProviderFn = getDataProviderFn(dataProvider);
    return Promise.resolve(dataProviderFn(queryParameters));
}


function getDataProviderFn({service,url}){
    switch(service){

        case "report":
            return (params)=>{
                return ReportService.instance().get(url,{params})
                .then(response=>response.data);
            };

        case "ics":
            return (params)=>{
                return ICService.instance().get(url,{params})
                .then(response=>response.data);
            };

        case "ics-appliance-status":
            return ()=>{
                return ICService.instance().get(url)
                .then(response=>response.data)
                .then(getTallyByStatus)
                .then(formatStatusTally);
            };

            case "report-appliance-status":
            return ()=>{
                return SiteViewService.instance().get(url)
                .then(response=>response.data)
                .then(getTallyByStatus)
                .then(formatStatusTally);
            };
        default:
            return null;
    }
}

const getTallyByStatus = rawData => rawData.reduce(
    (tally,record)=>{
        if(!(record.serviceStatus in tally)){
            return tally;
        }
        return {
            ...tally,
            [record.serviceStatus]: tally[record.serviceStatus] + 1
        };
    },{
        "offline":0,
        "active":0,
        "inactive":0,
        "failed":0,
        "activating":0
    }
);

const formatStatusTally = tallies => {
    return [
        {
            "status": "Running",
            "count": tallies["active"]
        },{
            "status": "Activating",
            "count": tallies["activating"]
        },{
            "status": "Stopped",
            "count": tallies["inactive"]
        },{
            "status": "Offline",
            "count": tallies["offline"],
            "color": "red"
        },{
            "status": "Failed",
            "count": tallies["failed"],
            "color": "red"
        },{
            "status": "Total",
            "count": Object.keys(tallies).reduce((cnt,tK)=>cnt+tallies[tK],0)
        }
    ];
};

export const getReportWidgetData = (widgetData, params) => {
    return (dispatch) => {
        ReportService.instance().get(widgetData.url, {params})
            .then(res => {
                dispatch({type: LOAD_DATA_WIDGET, id: widgetData.id ,data: res.data})
            })
    }
};

export const getTagHealthData = (widgetData, params) => {
    return async dispatch => {
        try {
            const response = await ResourceService.instance().get(widgetData.url, {params});
            dispatch({type: LOAD_DATA_WIDGET, id: widgetData.id, data: response.data})
        } catch (error) {
            displayErrorFromAxios.bind(null,dispatch);
        }
    }
};

export const getReportZlaStatusData = (widgetData, params) => {
    return (dispatch) => {
         SiteViewService.instance().get(widgetData.url)
            .then(response=>response.data)
            .then(getTallyByStatus)
            .then(formatStatusTally).then(res =>{
             dispatch({type: LOAD_DATA_WIDGET, id: widgetData.id, data: res})
         });
    }
};

// const capitalizeFirstLetter = (string) => {
//     return string.charAt(0).toUpperCase() + string.slice(1);
// }

const selectMaxSeverity = (obj) => {
    return Object.keys(obj).reduce((a, b) => { return obj[a] > obj[b] ? a : b });
}

export const getSiteAlertData = (widgetData, params) => {
    return async dispatch => {
        const resSites = await LCSMapService.instance().get('/sites');
        const resAlertsByDevices = await AlertService.instance().get(widgetData.url, params);
        const alertData = resAlertsByDevices.data.content;
        let objectSites = null;
        if(resSites && resSites.data){
            objectSites = resSites.data.reduce((acc, siteItem) => {acc[siteItem.name] = siteItem._id; return acc},{});
        }

        const alertsBySiteObject = alertData.reduce((acc, alertItem) => {
            const siteObject = {
                "site_name": "",
                "site_id": "",
                "high": 0,
                "medium": 0,
                "low": 0,
                "severity": "high"
            };

            if(alertItem.siteName){
                if(acc[alertItem.siteName]){
                    acc[alertItem.siteName][alertItem.severity.toLowerCase()] = acc[alertItem.siteName][alertItem.severity.toLowerCase()] + 1;

                    const severityValues = {high: acc[alertItem.siteName].high, medium: acc[alertItem.siteName].medium, low: acc[alertItem.siteName].low};
                    acc[alertItem.siteName].severity = selectMaxSeverity(severityValues);
                    // acc[alertItem.siteName].severity = capitalizeFirstLetter(selectMaxSeverity(severityValues));
                }else{
                    acc[alertItem.siteName] = {...siteObject};
                    acc[alertItem.siteName][alertItem.severity.toLowerCase()] = 1;
                    acc[alertItem.siteName].severity = alertItem.severity.toLowerCase();
                    // acc[alertItem.siteName].severity = capitalizeFirstLetter(alertItem.severity);
                    acc[alertItem.siteName].site_name = alertItem.siteName;
                    if(objectSites){
                        acc[alertItem.siteName].site_id = objectSites[alertItem.siteName] || 'NA';
                    }
                }
            }

            return acc;
        }, {});

        const alertsBySiteArray = Object.keys(alertsBySiteObject).map(key => alertsBySiteObject[key]);
        dispatch({type: LOAD_DATA_WIDGET, id: widgetData.id, data: alertsBySiteArray})
    }
}

export const getOtherOpenAlerts = (widgetData, params) => {
    return async dispatch => {
        try {
            const resAlertsByDevices = await AlertService.instance().get(widgetData.url, params);
            const OPEN_STATE = 'Open';
            const alertData = resAlertsByDevices.data.content;
            const openAlertsObject = alertData.reduce((acc, alertItem) => {
                if(alertItem.state === OPEN_STATE){
                    acc[alertItem.name] = (acc[alertItem.name] || 0) + 1;
                }
                return acc;
            }, {});

            const openAlertArray = Object.keys(openAlertsObject).map(key => ({name: key, cnt: openAlertsObject[key]}));
            dispatch({type: LOAD_DATA_WIDGET, id: widgetData.id, data: openAlertArray})
        } catch (error) {
            displayErrorFromAxios.bind(null,dispatch)
        }
    }
}

export const getAlertsByDevices = (widgetData, params) => {
    return async dispatch => {
        try {
            const resAlertsByDevices = await AlertService.instance().get(widgetData.url, params);
            dispatch({type: LOAD_DATA_WIDGET, id: widgetData.id, data: resAlertsByDevices.data});   
        } catch (error) {
            displayErrorFromAxios.bind(null,dispatch)
        }
    }
}

export const getWidgetData = (widgetData, params) => {
    return async (dispatch) => {
        try {
            const testParams = {...widgetData.defaultParams, ...params};
            const resDashboard = await ResourceService.instance().post(widgetData.url, testParams);
            switch(widgetData.id){
                case 10:
                    // dataExample = [
                    //     {
                    //       "queryTimeRange": {
                    //         "type": "Today",
                    //         "startDate": "2021-06-02T19:46:36.553Z",
                    //         "endDate": "2021-06-02T19:46:36.553Z"
                    //       },
                    //       "resourceType": "RFID reader",
                    //       "previousCount": 4,
                    //       "currentCount": 7,
                    //       "difference": 3
                    //     }
                    // ];
                    dispatch({type: LOAD_DATA_WIDGET, id: widgetData.id, data: resDashboard.data});
                break;
                case 13:
                    // dataExample = {
                    //     "request": {
                    //       "siteName": "string",
                    //       "zineGroup": "string"
                    //     },
                    //     "zoneCounts": [
                    //       {
                    //         "zone": "Zone 1",
                    //         "resourceType": "RFID reader",
                    //         "count": 2
                    //       }
                    //     ]
                    //   };
                    dispatch({type: LOAD_DATA_WIDGET, id: widgetData.id, data: resDashboard.data.zoneCounts});
                break;
                case 11: 
                    // dataExample = {
                    //     "request": {
                    //     "resourceType": "string",
                    //     "siteName": "string",
                    //     "customAttrName": "string"
                    //     },
                    //     "attrCounts": [
                    //     {
                    //         "value": "string",
                    //         "count": 8
                    //     }
                    //     ]
                    // };
                    let dataValues= resDashboard.data?.attrCounts;
                    // show only first resourceByCustomAttributeValues values
                    if( dataValues!=null){
                        dataValues=dataValues.slice(0,resourceByCustomAttributeValues);
                    }
                    dispatch({type: LOAD_DATA_WIDGET, id: widgetData.id, data: dataValues});
                break;
                default:
                break;
            }
            //dispatch({type: LOAD_DATA_WIDGET, id: widgetData.id, data: resDashboard.data});
        } catch (error) {
            displayErrorFromAxios.bind(null,dispatch);
        }
    }
}

export const updateResourceByZoneWidget = (configWidget, paramsWidget) => {
    return async (dispatch, getState) => {
        const id = configWidget.id;
        const currentParams = getState().dashboards.params[id];
        const options = getState().dashboards.options[id];

        // refreshing zone groups options
        if(currentParams && paramsWidget.siteName !== currentParams.siteName){
            let optionSelected = options.siteName.find(item => item.value === paramsWidget.siteName);
            let siteIdSelected = optionSelected.id;
            const siteMaps = getState().sites.maps;
            const selectedMaps = siteMaps.filter(m => m.siteId === siteIdSelected);
            try {
                const zonegroupResponse = await LCSMapService.instance().get(`/zonegroups?siteId=${siteIdSelected}&includeOnSites=true`);
                const zonegroupNames = zonegroupResponse.data.reduce((acc, item) => (acc.indexOf(item.groupName) === -1)?[...acc, item.groupName]:acc,[]);
                const zoneGroupOptions = zonegroupNames.map(zg => ({label: zg, value: zg, siteId: siteIdSelected}));

                let zoneSelected = zonegroupNames[0]||null;
                let mapIdSelected = selectedMaps[0]?._id||null;
    
                dispatch({type: LOAD_OPTIONS_WIDGET, id: id, options: {...options, zoneGroup: zoneGroupOptions}});
                dispatch(updateWidgetParams(id, {...paramsWidget, mapId: mapIdSelected, zoneGroup: zoneSelected}));
                dispatch(getWidgetData(configWidget, {...paramsWidget, mapId: mapIdSelected, zoneGroup: zoneSelected}));   
            } catch (error) {
                displayErrorFromAxios.bind(null,dispatch);
            }            
        }else{
            dispatch(updateWidgetParams(id, paramsWidget));
            dispatch(getWidgetData(configWidget, paramsWidget));
        }
    }
}

export const updateResourceByCustomAttribute = (configWidget, paramsWidget) => {
    return async (dispatch, getState) => {
        const id = configWidget.id;
        const currentParams = getState().dashboards.params[id];
        const options = getState().dashboards.options[id];

        // refreshing customAttributes
        if(currentParams && paramsWidget.resourceType !== currentParams.resourceType){
            try {
                const resourcetypes = await ResourceService.instance().get(`/v2/resourcetypes/${paramsWidget.resourceType}?fields=customProperties`);

                let customAttrList = resourcetypes.data.customProperties?resourcetypes.data.customProperties.map(ca => ({label: ca.propertyDisplayName, value: ca.propertyName,lookups:(ca.lookups!=null)?true:false})):[];
                customAttrList = customAttrList.filter(attr=>attr.lookups===true);

                let customAttrNameSelected = customAttrList[0]?.value||null;

                dispatch({type: LOAD_OPTIONS_WIDGET, id: id, options: {...options, customAttrName: customAttrList}});
                dispatch(updateWidgetParams(id, {...paramsWidget, customAttrName: customAttrNameSelected}));
                dispatch(getWidgetData(configWidget, {...paramsWidget, customAttrName: customAttrNameSelected}));
            } catch (error) {
                displayErrorFromAxios.bind(null,dispatch);
            }
        }else{
            dispatch(updateWidgetParams(id, paramsWidget));
            dispatch(getWidgetData(configWidget, paramsWidget));
        }
    }
}

export const updateWidgetParams = (id, paramsWidget) => ({type: SET_PARAMS_WIDGET, id: id, params: paramsWidget});

export const loadDashboardParameters = (widgetConfig) => {
    return async (dispatch) => {
        const {id, parameters} = widgetConfig;
        let optionsWidget = {};
        let paramsWidget = {};
        let siteNames = [];
        let mapIds = [];
        let siteIdSelected = null;
        let resourceTypeSelected = null;

        for (var i = 0; i < parameters.length; i++){
            const param = parameters[i];
            switch(param.name){
                case 'siteName':
                    try {
                        const sites = await ResourceService.instance().get('/v2/sites');
                        siteNames = sites.data.map(site => ({label: site.name, value: site.name, id: site._id}));
                        optionsWidget[param.name] = siteNames;
                        paramsWidget[param.name] = siteNames[0]?.value || '';
                        siteIdSelected = siteNames[0]?.id || '';
                    }catch (error) {
                        displayErrorFromAxios.bind(null,dispatch);
                    }
                break;
                case 'resourceType':
                    try {
                        const resourcetypes = await ResourceService.instance().get('/v2/resourcetypes?fields=name,displayName&sort=displayName');
                        const resourcetypesNames = resourcetypes.data.content.map(rt => ({label: rt.displayName, value: rt.name}));
                        optionsWidget[param.name] = resourcetypesNames;
                        paramsWidget[param.name] = resourcetypesNames[0].value;
                        resourceTypeSelected = resourcetypesNames[0].value;
                    } catch (error) {
                        displayErrorFromAxios.bind(null,dispatch);
                    }
                break;
                case 'customAttrName':
                    try {
                        //it is neccesary type
                        const resourcetypes = await ResourceService.instance().get(`/v2/resourcetypes/${resourceTypeSelected}?fields=customProperties`);
                        let customAttr = resourcetypes.data.customProperties.map(ca => ({label: ca.propertyName, value: ca.propertyName}));
                        optionsWidget[param.name] = customAttr;
                        paramsWidget[param.name] = customAttr[0].value;
                    } catch (error) {
                        displayErrorFromAxios.bind(null,dispatch);
                    }
                break;
                case 'zoneGroup':
                    try {
                        //it is neccesary site
                        const zonegroupResponse = await LCSMapService.instance().get(`/zonegroups?siteId=${siteIdSelected}&includeOnSites=true`);
                        const zonegroupNames = zonegroupResponse.data.reduce((acc, item) => (acc.indexOf(item.groupName) === -1)?[...acc, item.groupName]:acc,[]);
                        // eslint-disable-next-line no-loop-func
                        const zoneGroupOptions = zonegroupNames.map(zg => ({label: zg, value: zg, siteId: siteIdSelected}));
                        
                        //loading in options only the zonegroups of a specific site
                        optionsWidget[param.name] = zoneGroupOptions;
                        paramsWidget[param.name] = zoneGroupOptions[0]?.value || null;

                        // mapId that is needed for first init, and not should be in config of params
                        const maps = await ResourceService.instance().get(`/v2/maps?siteId=${siteIdSelected}`);
                        mapIds = maps.data.map(map => ({siteId: map.siteId, maxZoom: map.maxZoom, id: map._id}));
                        optionsWidget["mapId"] = mapIds;
                        paramsWidget["mapId"] = mapIds[0]?.id || '';
                    } catch (error) {
                        displayErrorFromAxios.bind(null,dispatch);
                    }
                break;
                case 'startDate':
                    let times = getTimes(PERIOD.TODAY);
                    paramsWidget[param.fromName] = times.from.toJSON();
                    paramsWidget[param.toName] = times.to.toJSON();
                break;
                default:
                break;
            }
        }

        dispatch({type: LOAD_OPTIONS_WIDGET, id: id, options: optionsWidget});
        dispatch({type: SET_PARAMS_WIDGET, id: id, params: paramsWidget});
        dispatch(getWidgetData(widgetConfig, paramsWidget));
    } 
}