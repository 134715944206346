import {
    FILTERS_ONE_PLATFORM_PATCH_FILTER,
    FILTERS_ONE_PLATFORM_RECOVER_ALL_FILTERS_ALL_REPORTS,
    FILTERS_ONE_PLATFORM_REPLACE_ALL_FILTERS,
    FILTERS_ONE_PLATFORM_REPLACE_FILTER, SIGN_OUT, TABLE_ONE_PLATFORM_REPLACE_HEADERS_COLUMNS_SORT_FILTERS_FILTER
} from '../constants/ActionTypes';
import { REPLACE_FILTER_TEMPLATES } from '../constants/ActionTypes/report';

const initialState = {};

export function filtersOnePlatform(state = initialState, action) {
    switch (action.type) {
        case REPLACE_FILTER_TEMPLATES:
            return {
                ...state,
                ___filterTemplates: action.data,
                ___diffFilterTemplates: action.diff,
            };
        case FILTERS_ONE_PLATFORM_REPLACE_FILTER:
            return {
                ...state,
                [action.key]: {
                    ...state[action.key],
                    [action.filterKey]: action.filter,
                },
            };
        case FILTERS_ONE_PLATFORM_PATCH_FILTER:
            if (!state[action.key]) {
                state[action.key] = {};
            }
            if (!state[action.key][action.filterKey]) {
                state[action.key][action.filterKey] = {};
            }
            const newFilter = { ...state[action.key][action.filterKey] };
            for (const propertyKey of Object.keys(action.filter)) {
                newFilter[propertyKey] = action.filter[propertyKey];
            }
            
            return {
                ...state,
                [action.key]: {
                    ...state[action.key],
                    [action.filterKey]: newFilter,
                },
            };
        case FILTERS_ONE_PLATFORM_REPLACE_ALL_FILTERS:
            return {
                ...state,
                [action.key]: {
                    ...action.filters,
                },
            };
        case FILTERS_ONE_PLATFORM_RECOVER_ALL_FILTERS_ALL_REPORTS:
            const ___stateRecovered = {
                ...state.___stateRecovered,
                ...action.filters,
            };
            delete ___stateRecovered.___stateRecovered;
            return {
                ...state,
                ___stateRecovered,
            };
        case SIGN_OUT:
            return initialState;
        case TABLE_ONE_PLATFORM_REPLACE_HEADERS_COLUMNS_SORT_FILTERS_FILTER:
            if(action.updateFilters){
                return {
                    ...state,
                    [action.reportKey]: {
                        ...action.filters,
                    },
                };
            }else{
                if (!state[action.reportKey]) {
                    state[action.reportKey] = {};
                }
                if (!state[action.reportKey][action.filterKey]) {
                    state[action.reportKey][action.filterKey] = {};
                }
                const newFilter = { ...state[action.reportKey][action.filterKey] };
                for (const propertyKey of Object.keys(action.filter)) {
                    newFilter[propertyKey] = action.filter[propertyKey];
                }

                return {
                    ...state,
                    [action.reportKey]: {
                        ...state[action.reportKey],
                        [action.filterKey]: newFilter,
                    },
                };
            }
        default:
            return state;
    }
}
