import {
    LOAD_ANTENNAS_DATA,
    LOAD_ANTENNAS_MODEL_DATA,
    OPEN_FORM_ADD_ANTENNAS,
    OPEN_FORM_EDIT_DEVICE,
    UPDATE_ANTENNA_DIALOG_STATE,
    SITE_DESIGNER_PATCH_PROPERTIES,
    UNLOAD_DEVICE_FORM,
    UPDATE_SITE_DESIGNER_ERROR_FORM,
    UNLOAD_FORM_ERRORS,
    UPDATE_FORM_SUCCESS,
    LOAD_DATUM_DATA,
    REPLACE_DEVICES,
    REPLACE_DEVICE_FORM, UPDATE_CURRENT_SITE_OBJECT,
    UPDATE_CURRENT_SELECTED_MAP,
    UPDATE_PREVENT_DELETE,
    REPLACE_ZONES,
    OPEN_FORM_ADD_ZONE,
    UNLOAD_ZONE_FORM,
    OPEN_FORM_EDIT_ZONE,
    UPDATE_ERROR_DIALOG_STATE,
    UPDATE_STATUS_FEATURE,
    LOAD_MAP_PHASES_TYPES,
    LOAD_MAP_PHASES,
    LOAD_SITE_DESIGNER_DATA,
    UPDATE_LEVEL_SELECTED,
    UPDATE_SITE_SELECTED,
    UPDATE_MEASURE_UNIT
} from '../constants/ActionTypes';
import {DeviceType, FeatureType} from "../views/sites/designer/constants";

const initialState = {
    antennas: [],
    antennaModels: [],
    antennaForm: {
        "name": "",
        "status": "enabled",
        "x": 0,
        "y": 0,
        "z": 0,
        "az": 0,
        "antenna_model_id": null,
        "reader_port_index": 0
    },
    openFormDialog: false,
    formType: null,
    formFieldErrors: {},
    successSave: false,
    datums: [],
    datumForm: {
        "mapId": 0,
        "name": "",
        "x": 0,
        "y": 0
    },
    deviceForm: {},
    zoneForm: {},
    selectedMapSD: {},
    errorDialog: {},
    successDialog: {},
    mapInteractions: {},
    deviceTypesData: [],
    componentTypesData: [],
    measureUnits: [],
    deviceConfigForm: {},
    siteSelected: {},
    levelSelected: {},
    measureUnitSelected: {},
}

export function siteDesigner(state = initialState, action) {
    const newState = { ...state };
    let form = {};

    switch (action.type){
        case REPLACE_DEVICES:
            return {
                ...state,
                devices: {
                    ...state.devices,
                    [action.deviceType]: action.devices,
                }
            };
        case LOAD_MAP_PHASES:
            return {
                ...state,
                mapPhases: action.mapPhases
            };
        case LOAD_MAP_PHASES_TYPES:
            return {
                ...state,
                mapPhasesTypes: action.mapPhasesTypes
            };
        case LOAD_ANTENNAS_DATA:
            return {
                ...state,
                antennas: action.antennas
            }
        case LOAD_ANTENNAS_MODEL_DATA:
            return {
                ...state,
                antennaModels: action.antennaModels
            }
        case OPEN_FORM_ADD_ZONE:
            switch(action.zoneType){
                case FeatureType.OBSTRUCTION:
                    form = {...state.zoneForm,
                        mapId: action.payload.mapId,
                        coords: action.payload.obstruction.coords,
                        name: action.payload.obstruction.name,
                        others: action.payload.obstruction.others
                    };
                    break;
                default:
                    form = {...state.zoneForm};
                    break;
            }
            return {
                ...state,
                zoneForm: {...form},
                openFormDialog: action.openDialog,
                formType: action.formType,
                zoneType: action.zoneType
            };
        case OPEN_FORM_EDIT_ZONE:
            return {
                ...state,
                zoneForm: {...action.payload},
                openFormDialog: action.openDialog,
                formType: action.formType,
                zoneType: action.zoneType,
            }
        case UNLOAD_ZONE_FORM:
            return {
                ...state,
                zoneForm: initialState.zoneForm
            }
        case OPEN_FORM_ADD_ANTENNAS:
            switch (action.deviceType) {
                case DeviceType.ANTENNA:
                    form = {...state.antennaForm, x: action.payload.x, y: action.payload.y};
                    break;
                case FeatureType.DEVICE_DATUM:
                    form = {...state.datumForm, x: action.payload.x, y: action.payload.y, mapId: action.payload.mapId}
                    break;
                default:
                    form = {...state.deviceForm}
                    break;
            }
            return {
                ...state,
                deviceForm: {...form},
                openFormDialog: action.openDialog,
                formType: action.formType,
                deviceType: action.deviceType,
            };
        case OPEN_FORM_EDIT_DEVICE:
            return {
                ...state,
                deviceForm: {...action.payload},
                openFormDialog: action.openDialog,
                formType: action.formType,
                deviceType: action.deviceType,
            }
        case UPDATE_ANTENNA_DIALOG_STATE:
            return {
                ...state,
                openFormDialog: action.openDialog
            }
        case UPDATE_ERROR_DIALOG_STATE:
            return {
                ...state,
                errorDialog: {...action.payload}
            }
        case SITE_DESIGNER_PATCH_PROPERTIES:
            for(const key in action.payload){
                newState[key] = {
                    // ...(newState[key] || {}),
                    ...action.payload[key]
                }
            }
            return newState
        case UNLOAD_DEVICE_FORM:
            return {
                ...state,
                deviceForm: initialState.deviceForm
            }
        case UPDATE_SITE_DESIGNER_ERROR_FORM:
            const newFormFieldErrors = {...state.formFieldErrors}
            for(let key in action.payload){
                newFormFieldErrors[key] = action.payload[key];
            }
            return {
                ...state,
                formFieldErrors: {
                    ...newFormFieldErrors
                }
            }
        case UNLOAD_FORM_ERRORS:
            return {
                ...state,
                formFieldErrors: initialState.formFieldErrors
            }
        case UPDATE_FORM_SUCCESS:
            return {
                ...state,
                successSave: action.success
            }
        case LOAD_DATUM_DATA:
            return {
                ...state,
                datums: action.datums
            }
        case REPLACE_DEVICE_FORM:
            return {
                ...state,
                deviceForm: action.payload
            }
        case UPDATE_CURRENT_SITE_OBJECT:
            return {
                ...state,
                currentSite: action.site
            }
        case UPDATE_CURRENT_SELECTED_MAP:
            return {
                ...state,
                selectedMapSD: action.map
            }
        case UPDATE_PREVENT_DELETE:
            return {
                ...state,
                openDialogPrevent: action.openConfirmDeleteDialog,
                deviceToDelete: action.deviceToDelete
            }
        case REPLACE_ZONES:
            return {
                ...state,
                zones: {
                    ...state.zones,
                    [action.zoneType]: action.zones,
                }
            };
        case UPDATE_STATUS_FEATURE:
            return {
                ...state,
                statusFeature: action.feature
            };
        case LOAD_SITE_DESIGNER_DATA:
            return {
                ...state,
                [action.dataType]: action.data
            }
        case UPDATE_SITE_SELECTED:
            return {
                ...state,
                siteSelected: action.siteSelected
            }
        case UPDATE_LEVEL_SELECTED:
            return {
                ...state,
                levelSelected: action.levelSelected
            }
        case UPDATE_MEASURE_UNIT:
            return {
                ...state,
                measureUnitSelected: action.measureUnitSelected
            }
        default:
            return state;
    }
}
