import React from 'react';

class TimerPlusIcon extends React.Component{

    render() {
        return (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                 width="25px" height="25px" className={"timer-svg"} viewBox="0 0 50 50">
                <g>
                    <path d="M5,4.1v13.6l0,0l0,0l9.1,9.1L5,36l0,0l0,0v13.7h27.3V36l0,0l0,0l-9.1-9.1l9.1-9.1l0,0l0,0V4.1H5 M27.8,37.1v8H9.6v-8
		            l9.1-9.1L27.8,37.1 M18.7,25.8l-9.1-9.1v-8h18.2v8L18.7,25.8z"/>
                    <g>
                        <path d="M40.8,4.9H45V8h-4.2v4.8h-3.2V8h-4.2V4.9h4.2V0.4h3.2V4.9z"/>
                    </g>
                </g>
            </svg>
        );
    }
}

export default TimerPlusIcon;
