import * as types from '../constants/ActionTypes';
import Promise from 'promise';

import {AccessControlService, displayErrorFromAxios, LocationConfigService, ResourceService} from './util';
import {patchReportProperties} from "./reports";
import {REPORT_KEY_DEFAULT} from "../constants";
import {getReportKeys} from "../hooks";
import {getReportData} from "../utils";

function handleACLErrorMessages(err){
    var message = (err.response && err.response.data) || err.message;
    //err.response.data = message.status.error;
    err.response.data = message;
    return Promise.reject(err);
}

// users page
export const clickUsersTab = (report) => ({type:types.USERS_CLICK_TAB, report});

export const loadUserGroups = (groups) => ({type:types.LOAD_USER_GROUPS, groups});

export const requestUserGroups = () => {
    return dispatch => {
        AccessControlService.instance().get("/groups")
        .then(d=>{
            dispatch(loadUserGroups(d.data.results));
        })
        .catch(handleACLErrorMessages)
        .catch(displayErrorFromAxios.bind(null,dispatch));
    };
};

export const addUserGroup = (group) => {
    return dispatch => {
        return AccessControlService.instance().post("/groups",group)
        .then(()=>dispatch(requestUserGroups()))
        .catch(handleACLErrorMessages)
        .catch(displayErrorFromAxios.bind(null,dispatch));
    };
};

export const deleteUserGroup = (guid) => {
    return dispatch => {
        return AccessControlService.instance().delete("/groups/" + guid)
        .then(()=>dispatch(requestUserGroups()))
        .catch(handleACLErrorMessages)
        .catch(err=>{
            displayErrorFromAxios(dispatch,err,{ignore403:true})
            .catch(()=>null); // allow it to go through. (don't keep form open)
        });
    };
};

export const updateUserGroup = (guid,group) => {
    return dispatch => {
        return AccessControlService.instance().put("/groups/" + guid,group)
        .then(()=>dispatch(requestUserGroups()))
        .catch(handleACLErrorMessages)
        .catch(err=>displayErrorFromAxios(dispatch,err,{ignore403:true}));
    };
};

export const zoomFeature = (feature) => {
    return (dispatch,getState)=>{

    const username=getState().user.username;
    window.localStorage.setItem("feature/"+username,JSON.stringify(feature));

        dispatch({
            type: types.ZOOM_FEATURE,
            feature
        })
    }
};

export const getZoomFeature = () => {
    return (dispatch,getState)=>{
    const username=getState().user.username;
    let feature = window.localStorage.getItem("feature/"+username);
    if(feature){
        feature = JSON.parse(feature)
    }else{
        feature = {"zoomObject":true,"zoomReport":true}
    }



        dispatch({
            type: types.ZOOM_FEATURE,
            feature
        })
    }
};

export const saveMaxResultsByPage = (maxResultsByPage) => {
    return (dispatch,getState)=>{

        const { reportKey} = getReportKeys(getState());
        const { paginationInfo,counterRefresh } = getReportData(getState().report.scopes?.[reportKey], getState().report.scopes?.[REPORT_KEY_DEFAULT]);

        if(reportKey.startsWith("reports:")) {
            const newPagination = {...paginationInfo};
            newPagination.maxResultsByPage = maxResultsByPage;
            newPagination.page = 1;
            newPagination.enableSelectPage = true;
            newPagination.pageListConfiguration = {};
            dispatch(patchReportProperties(REPORT_KEY_DEFAULT, {maxResultsByPage: +maxResultsByPage}));
            dispatch(patchReportProperties(reportKey,{paginationInfo:newPagination,counterRefresh:counterRefresh+1}));
        }
        else{
            dispatch(patchReportProperties(REPORT_KEY_DEFAULT, {maxResultsByPage: +maxResultsByPage,counterRefresh:counterRefresh+1}));
        }

    }
};

// export const loadMaxResultsByPage = () => {
//     return (dispatch,getState)=>{
//         const username=getState().user.username;
//         let feature = window.localStorage.getItem("feature/"+username);
//         if(feature){
//             feature = JSON.parse(feature)
//         }else{
//             feature = {}
//         }
//
//
//
//         dispatch({
//             type: types.ZOOM_FEATURE,
//             feature
//         })
//     }
// };

export const loadUsers = (users) => ({type:types.LOAD_USERS, users});

export const requestUsers = () => {
    return dispatch => {
        AccessControlService.instance().get("/users")
        .then(d=>{
            dispatch(loadUsers(d.data.results));
        })
        .catch(handleACLErrorMessages)
        .catch(displayErrorFromAxios.bind(null,dispatch));
    };
};

export const deleteUser = (uuid) => {
    return dispatch => {
        return AccessControlService.instance().delete("/users/" + uuid)
        .then(()=>dispatch(requestUsers()))
        .catch(handleACLErrorMessages)
        .catch(err=>{
            displayErrorFromAxios(dispatch,err,{ignore403:true})
            .catch(()=>null); // allow it to go through. (don't keep form open)
        });
    };
};

export const addUser = (user) => {
    return dispatch => {
        return AccessControlService.instance().post("/users",user)
        .then(()=>dispatch(requestUsers()))
        .catch(handleACLErrorMessages)
        .catch(displayErrorFromAxios.bind(null,dispatch));
    };
};

export const updateUser = (uuid,user) => {
    return dispatch => {
        return AccessControlService.instance().put("/users/" + uuid,user)
        .then(()=>dispatch(requestUsers()))
        .catch(handleACLErrorMessages)
        .catch(err=>displayErrorFromAxios(dispatch,err,{ignore403:true}));
    };
};

export const resetUserPassword = (uuid, password) => {
    return dispatch => {
        return AccessControlService.instance().put(`/users/${uuid}/password`, {password: password})
        .then(()=>dispatch(requestUsers()))
        .catch(handleACLErrorMessages)
        .catch(err=>displayErrorFromAxios(dispatch,err,{ignore403:true}));
    }
}

export const loadResourceTypes = (resourceTypes) => ({type:types.LOAD_RESOURCE_TYPES, resourceTypes});

export const requestResourceTypes = () => {
    return dispatch => {
        ResourceService.instance().get('/v2/resourcetypes')
        .then(d=>{
            const resourceTypes = d.data.content.map(resourceType => resourceType.name);
            dispatch(loadResourceTypes(resourceTypes));
        })
        .catch(displayErrorFromAxios.bind(null,dispatch));
    };
};

export const loadSitesForUserMgmt = (sites) => ({type:types.LOAD_SITES_USERMGMT, sites});

export const requestSitesForUserMgmt = () => {
    return dispatch => {
        LocationConfigService.instance().get('/sites')
        .then(d=>{
            const sites = d.data.map(site => site.name);
            dispatch(loadSitesForUserMgmt(sites));
        })
        .catch(displayErrorFromAxios.bind(null,dispatch));
    };
};


export const sortUsersColumns = (sortKey, sortDirection, index) => {
    return (dispatch, getState) => {
            dispatch({type:types.SORT_USER_TABLE_COLUMNS, sortIndex: index, sortDirection:sortDirection});
        // const userSelectedRows = getState().user.selectedUserRows;
        // const userSelectedFilters = getState().userMgmt.userSelectedFilters;
        // const newSelectedRowsData = {};
        let indexKey = sortKey;

        if(indexKey === 'username'){
            indexKey = 'user_name';
        } else if(indexKey === 'firstName'){
            indexKey = 'first_name'
        } else if(indexKey === 'lastName'){
            indexKey = 'last_name'
        } else if(indexKey === 'daysInactive'){
            indexKey = 'inactivedays'
        } else if(indexKey === 'groups'){
            indexKey = 'group_name'
        }
        let orderSort = sortDirection === -1 ? '-' : '';
        let querySort = `&sort_by=${orderSort}${indexKey}`;
        /*let parameters = getFilterValues(userSelectedFilters);
        let queryParams = (parameters!=='') ? `&${parameters}`:'';
        const query = `?${queryParams}${querySort}`;*/
        const query = `?${querySort}`;
        AccessControlService.instance().get(`/users${query}`)
            .then(d=>{
                console.log("Users: ", d.data.results)
                dispatch(loadUsers(d.data.results));
                // dispatch({type: types.UPDATE_SELECTED_ROWS_USER, selectedUserRows: newSelectedRowsData})
            })
            .catch(handleACLErrorMessages)
            .catch(displayErrorFromAxios.bind(null,dispatch));

    }
};