import * as types from '../constants/ActionTypes';

import {closeDataEntry} from './general';

import {requestAppliances} from './appliance-appliances';

import {displayErrorFromAxios, ICService} from './util';

/*
██       ██████   █████  ██████
██      ██    ██ ██   ██ ██   ██
██      ██    ██ ███████ ██   ██
██      ██    ██ ██   ██ ██   ██
███████  ██████  ██   ██ ██████
*/


export const loadTasks = (data, applianceId, commandName) => ({type:types.LOAD_TASKS, data, applianceId, commandName});

export const requestTasks = (applianceId,commandName) => {
    return (dispatch) => {
        // Actually not convinced we need to dispatch an event... UI doesn't really do much with it.
        dispatch({type:types.REQUEST_TASKS});
        dispatch(requestAppliances()); // keep in sync

        ICService.instance().get("/tasks",{
            params:{
                "command.name":commandName,
                applianceId
            },
            headers:{
                'Content-Type':'application/json',
                'Cache-Control':'no-cache, no-store, must-revalidate',
                'Pragma':'no-cache',
                'Expires': 0,
            }
        })
        .then(response => {
            dispatch(loadTasks(response.data,applianceId,commandName));
        })
        .catch(displayErrorFromAxios.bind(null,dispatch));
    };
};

export const requestTasksConfiguration = (applianceId,commandName) => {
    return (dispatch) => {
        // Actually not convinced we need to dispatch an event... UI doesn't really do much with it.
        dispatch({type:types.REQUEST_TASKS});
        dispatch(requestAppliances()); // keep in sync

        ICService.instance().get("/tasks",{
            params:{
                "command.name":commandName,
                applianceId
            },
            headers:{
                'Content-Type':'application/json',
                'Cache-Control':'no-cache, no-store, must-revalidate',
                'Pragma':'no-cache',
                'Expires': 0,
            }
        })
            .then(response => {
                let siteConfiguration = {};
                let timeCreated = null;
                if(response.data && response.data.length > 0) {
                    siteConfiguration = response.data[0].command.args.configuration;
                    timeCreated = response.data[0].timeCreated
                }
                dispatch({type:types.LOAD_SITE_CONFIGURATION, siteConfiguration: siteConfiguration, timeCreated: timeCreated});
            })
            .catch(displayErrorFromAxios.bind(null,dispatch));
    };
};

/*
 ██████ ██████  ███████  █████  ████████ ███████
██      ██   ██ ██      ██   ██    ██    ██
██      ██████  █████   ███████    ██    █████
██      ██   ██ ██      ██   ██    ██    ██
 ██████ ██   ██ ███████ ██   ██    ██    ███████
*/

export const createTask = (applianceId,commandName,commandArgs, timeToExecute, callback) => {
    return (dispatch) => {
        let data = {
            applianceId,
            command:{
                name:commandName,
                args:commandArgs
            }
        };

        if(timeToExecute) data.timeToExecute = timeToExecute;

        return ICService.instance().post("/tasks",data)
        .then((response) => {
            if(callback){
                callback(response);
            }else{
                dispatch(closeDataEntry());
                dispatch(requestAppliances());
            }
        })
        .catch(err=>{
            displayErrorFromAxios.bind(null,dispatch)
        });
    };
};

/*
 ██████  █████  ███    ██  ██████ ███████ ██
██      ██   ██ ████   ██ ██      ██      ██
██      ███████ ██ ██  ██ ██      █████   ██
██      ██   ██ ██  ██ ██ ██      ██      ██
 ██████ ██   ██ ██   ████  ██████ ███████ ███████
*/

export const cancelTask = (taskId,callback) => {
    return (dispatch) => {

        ICService.instance().put("/tasks/" + taskId,{
            status:"cancelled",
            timeToExecute: (new Date()).getTime() // so we can relatively ignore them in task history...
        })
        .then( callback )
        .catch(displayErrorFromAxios.bind(null,dispatch));

    };
};


/*
██████  ███████ ██      ███████ ████████ ███████
██   ██ ██      ██      ██         ██    ██
██   ██ █████   ██      █████      ██    █████
██   ██ ██      ██      ██         ██    ██
██████  ███████ ███████ ███████    ██    ███████
*/

export const deleteTask = (taskId,callback) => {
    return (dispatch) => {

        ICService.instance().delete("/tasks/" + taskId)
        .then( callback )
        .catch(displayErrorFromAxios.bind(null,dispatch));

    };
};
