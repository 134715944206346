import {
    CLOSE_ERROR_DIALOG_ON_BACKGROUND, DISPLAY_ERROR_DIALOG_ON_BACKGROUND
} from '../constants/ActionTypes';

export const displayErrorOnBackgroundProcess = (title, message, details) => {
    return async dispatch => {
        dispatch({type: DISPLAY_ERROR_DIALOG_ON_BACKGROUND, title: title, message: message, details: details});
    }
}

export const closeErrorOnBackgroundProcess = () => {
    return async dispatch => {
        dispatch({type: CLOSE_ERROR_DIALOG_ON_BACKGROUND});
    }
}
