import { useCallback, useEffect, useState } from 'react';

export const useHandleError = ({ getError, value, onError, forceDisplayError, showErrorOnBlur }) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [displayError, setDisplayError] = useState(false);
    useEffect(() => {
        const error = getError(value);
        if (!error) {
            setDisplayError(false);
        }
        setErrorMessage(error);
    }, [getError, value]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => onError?.(errorMessage), [errorMessage/*, onError*/]);
    useEffect(() => forceDisplayError && setDisplayError(!!forceDisplayError), [forceDisplayError]);
    const handleOnBlur = useCallback(() => {
        if (showErrorOnBlur && errorMessage) {
            setDisplayError(true);
        }
    }, [errorMessage, showErrorOnBlur]);
    
    return {
        errorMessage: errorMessage || '',
        displayError,
        setDisplayError: useCallback((value) => setDisplayError(value || !!forceDisplayError), [forceDisplayError]),
        handleOnBlur,
    };
};