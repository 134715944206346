import {
    CHANGE_RESOURCE_FILTER_VALUE,
    CLEAN_FIELDS_RESOURCE,
    LOAD_SITES_RESOURCES,
    RESOURCE_SELECTION,
    UPDATE_VALIDATED_FIELDS_RESOURCE
} from '../constants/ActionTypes';
import {
    MODE_DIALOG_RESOURCE,
    UPDATE_RESOURCE_FIELD, UPDATE_RESOURCE_URL_PARAMS
} from '../views/resourceTypes/constants/ResourceTypes';
import { VALIDATE_RESOURCE_FIELD } from '../utils/resource/ResourceField';

const initialState = {
    statusModalResourceAddEdit:false,
    modeDialog:"",
    sitesData: [],
    needsValidationFieldsResource:VALIDATE_RESOURCE_FIELD,
    resourceFilters: {
        resourceType: {displayName: 'Resource Type',value: "", options: []}
    },
    resourcesURL:{
        add:{
            method: 'POST',
            url: '/v2/resources/{resourceType}'
        },
        update:{
            method: 'PATCH',
            url: '/v2/resources/{resourceType}/{resourceId}'
        },
        list:{
            method: 'GET',
            //url: '/v2/resources?fields=location,id,resourceId,resourceType,lastUpdatedTime,localPosition,globalPosition,mapId,site,zone,properties,children,associatedTags,multiTagSetting,icon,&{filters}&page={page}&size={size}&siteId={siteId}&zone={zone}&sort={sort}'
            url: '/v2/resources?{filters}&page={page}&size={size}&siteId={siteId}&sort={sort}'
        },
        listSites:{
            method: 'GET',
            url: '/v2/sites'
        },
        /*list:{
            method: 'GET',
            url: '/v2/report/resources?{filters}&page={page}&size={size}'
        },*/
        oneResource:{
            method: 'GET',
            url: '/v2/resources/{type}/{id}'
        },
        deleteOneResource:{
            method: 'DELETE',
            url: '/v2/resources/{resourceType}/{resourceId}'
        },
        deleteMultipleResource:{
            method: 'DELETE',
            url: '/v2/resources'
        },
        getCustomProperties:{
            method: 'GET',
            url: '/v2/resources?fields=customProperties&type={resourceType}'
        }
    },
    selectedRows: {},
    selectedResourceId:"",
    selectResource:null
};

export function resources(state = initialState, action) {
    switch (action.type) {
        case CHANGE_RESOURCE_FILTER_VALUE:
            return {
                ...state,
                resourceFilters: {...state.resourceFilters, [action.filterId]: action.filterObject}
            }
        case LOAD_SITES_RESOURCES:
            return {
                ...state,
                sitesData: action.sitesData
            }
        case RESOURCE_SELECTION:
            return {
                ...state,
                selectedRows: {...action.selectedRows},
                selectedResourceId: action.selectedResourceId
            };
        case UPDATE_RESOURCE_FIELD:
            return {
                ...state,
                currentResourceId: action.currentResourceId,
                currentResourceType: action.currentResourceType,
                statusModalResourceAddEdit: action.statusModalResourceAddEdit,
                selectResource: action.selectResource,
                selectResourcePrev: JSON.parse(JSON.stringify(action.selectResource)),
                modeDialog:action.modeDialog
            };
        case UPDATE_RESOURCE_URL_PARAMS:
            return {
                ...state,
                urlParams: action.urlParams,
            };
        case MODE_DIALOG_RESOURCE:
            return {
                ...state,
                statusModalResourceAddEdit: action.statusModalResourceAddEdit,
                modeDialog:action.modeDialog,
                selectResource:action.selectResource?action.selectResource:null,
                needsValidationFieldsResource:VALIDATE_RESOURCE_FIELD,
            };
        case UPDATE_VALIDATED_FIELDS_RESOURCE:
            return {
                ...state,
                needsValidationFieldsResource:action.needsValidationFieldsResource,
                //selectResource:action.selectResource,
                //resourceTypesForm:{...state.resourceTypesForm},
                //statusModalResourceAddEdit:action.statusModalResourceAddEdit
            };
        case CLEAN_FIELDS_RESOURCE:
            return {
                ...state,
                needsValidationFieldsResource:action.needsValidationFieldsResource,
                selectResource:{}
        }
        default:
            return state;
    }
};
