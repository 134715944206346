const users = [
    {
        caption:"Username",
        name:"username",
        sort:0,
        filter:true,
        enabled:true
    },{
        caption:"First Name",
        name:"firstName",
        sort:0,
        filter:true,
        enabled:true
    },{
        caption:"Last Name",
        name:"lastName",
        sort:0,
        filter:true,
        enabled:true
    },{
        caption:"User Description",
        name:"description",
        sort:0,
        filter:true,
        enabled:true
    },{
        caption:"Groups",
        name:"groups",
        sort:0,
        filter:true,
        enabled:true
    },{
        caption:"Days Inactive",
        name:"daysInactive",
        sort:0,
        filter:true,
        enabled:true
    }
];

export default users;