import {CLEAN_NOTIFICATION, DISPLAY_NOTIFICATION, UPDATE_NOTIFICATION} from "../constants/ActionTypes";

const initialState = {
    openNotification: false,
    typeNotification:"",
    nameNotification: "",
    subTitleNotification: "",
    messageNotification:"",
    messageDetailNotification: "",
    autoHideDurationNotification: "",
    idSetTimeOut:0
};

export function notification (state = initialState, action){
    switch(action.type){
        case DISPLAY_NOTIFICATION:
            return{
                ...state,
                openNotification: action.openNotification,
                typeNotification: action.typeNotification,
                nameNotification: action.nameNotification,
                subTitleNotification: action.subTitleNotification,
                messageNotification: action.messageNotification,
                showIcon: action.showIcon,
                autoHideDurationNotification: action.autoHideDurationNotification,
                idSetTimeOut: action.idSetTimeOut,
            };
        case CLEAN_NOTIFICATION:
            return{
                ...state,
                ...initialState,
            };
        case UPDATE_NOTIFICATION:
            return{
                ...state,
                idSetTimeOut: action.idSetTimeOut,
            };
        default:
            return state;
    }
}