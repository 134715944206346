

export default class ZoneBuilderModel {

    constructor(zoneObj) {
        this.data = {
            id: zoneObj.id || undefined,
            parentId: zoneObj.parentId || null,
            mapId: zoneObj.mapId,
            name: zoneObj.name,
            color: zoneObj.color,
            type: zoneObj.type || "standard",
            shape: zoneObj.shape,
            childrenZones: zoneObj.childrenZones || [],
            isDraft: zoneObj.isDraft || false,
            notSaved: zoneObj.notSaved || false,
            isDeleted: zoneObj.isDeleted || false,
            isNew:zoneObj.isNew || false,

        }
    }

    isOnMap(mapId){
        return this.data.mapId === mapId;
    }

    toJSON(){
        const d = this.data;
        const jsonObj = {
            parentId: d.parentId,
            mapId: d.mapId,
            name: d.name,
            color: d.color,
            type: d.type,
            shape: d.shape,
            childrenZones: d.childrenZones,
            isDraft: d.isDraft || false,
            isDeleted: d.isDeleted || false,
            isNew:d.isNew || false,
        }

        if(d.id){
            jsonObj.id = d.id;
        }

        return jsonObj;
    }
}
