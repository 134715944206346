import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
//import "core-js/es/array/fill";
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import './vss/sass/style.scss';
import './vss/sass/app/mainApp.scss';
import { Routes } from './vss/app/routes';
import store, { history } from './vss/app/store';

ReactDOM.render((
    <Provider store={store}>
        <ConnectedRouter history={history}>
            {Routes}
        </ConnectedRouter>
    </Provider>
), document.getElementById('mainAppId'));
