import React from 'react';
import PropTypes from 'prop-types';
import Handle from '@material-ui/icons/Dehaze';

import {getSidebarSizes} from '../../../constants/Responsive';

// min210, max700

function getBounds() {
    const widths = getSidebarSizes();
    return {
        MAX_WIDTH: widths.maxWidth,
        MIN_WIDTH: widths.minWidth,
    };
}

class Resizer extends React.Component {
    constructor(props) {
        super(props);
        this.handleMouseUp = this.handleMouseUp.bind(this);
        this.handleTouchEnd = this.handleTouchEnd.bind(this);

        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleTouchStart = this.handleTouchStart.bind(this);

        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.handleTouchMove = this.handleTouchMove.bind(this);
        this.handlePointerMove = this.handlePointerMove.bind(this);

        this.handleTransitionEnd = this.handleTransitionEnd.bind(this);

        this.state = {
            position: undefined,
            transitionFinished: true,
        };
    }
    componentDidMount() {
        document.addEventListener('mousemove', this.handleMouseMove)
        document.addEventListener('touchmove', this.handleTouchMove)
        document.addEventListener('mouseup', this.handleMouseUp);
        document.addEventListener('touchend', this.handleTouchEnd);
    }
    componentWillUnmount() {
        document.removeEventListener('mousemove', this.handleMouseMove)
        document.removeEventListener('touchmove', this.handleTouchMove)
        document.removeEventListener('mouseup', this.handleMouseUp);
        document.removeEventListener('touchend', this.handleTouchEnd);
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        const changeset = {};
        /*
        //old solution for show bar resize
        if (nextProps.collapsed !== this.props.collapsed) {
            changeset.transitionFinished = false;
        }
        */
       
        if (nextProps.collapsed) {
            changeset.transitionFinished = false;
        }else{
            changeset.transitionFinished = true;
        }
                
        this.setState({
            ...changeset
        });
    }
    handlePointerMove(e) {
        const direction = this.props.separatorPosition === 'right' ? 1 : -1;
        const { MIN_WIDTH, MAX_WIDTH } = getBounds();
        const current = e.clientX;

        const width = this.props.width + direction * (current - this.state.position);
        if (this.props.resizing && width >= MIN_WIDTH && width <= MAX_WIDTH) {
            this.props.handleResize({event: 'mousemove', resizing: true, width: width, identifier: this.props.identifier})
            this.setState({
                position: current,
            });
        }
    }
    handleMouseMove(e) {
        this.handlePointerMove({ clientX: e.clientX });
    }
    handleTouchMove(e) {
        this.handlePointerMove({ clientX: e.touches[0].clientX });
    }
    handleMouseDown(e) {
        this.props.handleResize({event: 'mousedown', resizing: true, width: null, identifier: this.props.identifier})
        this.setState({
            position: e.clientX,
        });
        if (e.stopPropagation) { e.stopPropagation(); }
        if (e.preventDefault) { e.preventDefault(); }
        if (e.persist) { e.persist(); }
        e.cancelBubble = true;
        e.returnValue = false;
        return false;
    }
    handleTouchStart(e) {
        this.props.handleResize({event: 'mousedown', resizing: true, width: null, identifier: this.props.identifier})
        e.persist();
        this.setState({
            position: e.touches[0].clientX,
        });
    }
    handleMouseUp(e) {
        if (this.props.resizing) {
            this.props.handleResize({event: 'mouseup', resizing: false, width: null, identifier: this.props.identifier})
        }
    }
    handleTouchEnd(e) {
        if (this.props.resizing) {
            this.props.handleResize({event: 'mouseup', resizing: false, width: null, identifier: this.props.identifier});
        }
    }
    handleTransitionEnd() {
        if (this.props.onTransitionEnd) {
            this.props.onTransitionEnd();
        }
        this.setState({
            transitionFinished: true,
        })
    }
    getSeparator() {
        if (this.props.collapsed || !this.state.transitionFinished) {
            return '';
        }
        return <div
            id={`${this.props.identifier}-resizer`}
            className={`resize-separator ${this.props.separatorPosition}-aligned`}
            onTouchStart={this.handleTouchStart}
            onMouseDown={this.handleMouseDown}
        >
            <div className="resize-handle">
                <Handle />
            </div>
        </div>;
    }
    render() {
        const { className, width, children } = this.props;

        return <div className={className} style={{width: `${width}px`}} onTransitionEnd={this.handleTransitionEnd}>
            {this.getSeparator()}
            {children}
        </div>;
    }
}

Resizer.propTypes = {
    handleResize: PropTypes.func,
    resizing: PropTypes.bool,
    width: PropTypes.number,
}

export default Resizer;
