import { OPTION_ALL_WILDCARD } from "../../../../../constants/Misc";

export const PREFIX_SERVICE = '/mwe/v2';
export const SITE_URL_DEFAULT = '/sites';
export const MAP_URL_DEFAULT = '/maps';
export const ZONEGROUP_URL_DEFAULT = '/zonegroups';
export const ZONE_URL_DEFAULT = '/zones';

export const TREE_NAMES = {
  ALL: "%",
  SITES: "treeSiteName",
  MAPS: "treeMapName",
  ZONE_GROUPS: "treeZoneGroup",
  ZONES: "treeZone"
}

export const TREE_STRUCTURE_ID = "treePos";
export const IS_LOADING = 'loading';
export const SIMPLE_TREE_SELECT = {
  onlyPickOne: true,
  mode: "radioSelect",
  keepOpenOnSelect: true
};

export const SIMPLE_TREE_SELECT_ADD_RESOURCE = {
  onlyPickOne: false,
  mode: "radioSelect",
  keepOpenOnSelect: true
};

export const ZONES_OBJ = {
  treeSiteName: [],
  treeMapName: [],
  treeZoneGroup: [],
  treeZone: []
}; // TREE STRUCTURE FOR ZONES
/*
export const SHOULD_LOAD_DATA = [{
  value: IS_LOADING,
  label: "Loading...",
  disabled: true
}]; // structure for childrens that don't have data yet
*/
export const DEFAULT_TREE_STATE = {
  label: 'All',
  value: OPTION_ALL_WILDCARD,
  //children: SHOULD_LOAD_DATA,
  //children:{}
}; // default value for tree filter without data