import {
    PROCESSING_SEARCH_QUERY,
    SET_SEARCH_QUERY_RESPONSE,
    LOADING_AUTOCOMPLETION_FIELDS,
    SET_AUTOCOMPLETION_FIELDS,
    LOADING_SUGGESTIONS_FOR_VALUE,
    SET_SUGGESTIONS_FOR_VALUE,
    SAVE_INPUT_QUERY,
    SORT_BY_COLUMN
} from '../constants/ActionTypes';
const initialState = {
    processingSearchQuery: false,
    loadingAutocompletionFields: false,
    loadingSuggestionsForValue: false,
    fieldsList: [],
    suggestionsForValue: [],
    inputSearchQuery: '',
    from: null,
    to: null,
    data: [],
    sortIndex: null
};
export function search(state = initialState, action){
    switch(action.type){
        case SET_SEARCH_QUERY_RESPONSE:
            return {
                ...state,
                data: action.data
            };
        case PROCESSING_SEARCH_QUERY:
            return {
                ...state,
                processingSearchQuery: action.processingSearchQuery
            };
        case LOADING_AUTOCOMPLETION_FIELDS:
            return {
                ...state,
                loadingAutocompletionFields: action.loadingAutocompletionFields
            };
        case SET_AUTOCOMPLETION_FIELDS:
            return {
                ...state,
                fieldsList: action.fieldsList
            };
        case LOADING_SUGGESTIONS_FOR_VALUE:
            return {
                ...state,
                loadingSuggestionsForValue: action.loadingSuggestionsForValue
            };
        case SET_SUGGESTIONS_FOR_VALUE:
            return {
                ...state,
                suggestionsForValue: action.suggestionsForValue
            };
        case SAVE_INPUT_QUERY: 
            return {
                ...state,
                inputSearchQuery: action.inputSearchQuery,
                from: action.from,
                to: action.to
            };
        case SORT_BY_COLUMN:
            return {
                ...state,
                sortIndex: action.sortIndex
            };
        default:
            return state;
    }
}