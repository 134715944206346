import React, { useEffect, useRef, useState } from 'react';
import {ALPHABET, FORM_FIELD_REQUIRED, RANGE_NUMBER, REQUIRED} from '../../../../../../constants';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHandleError } from '../../useHandleError';
import '../../../FilterLoader/Filters/BaseFilter/BaseFilter.scss';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import TooltipInfoMessage from "../../../../../deviceManager/common/accordionItems/common/TooltipInfoMessage";
import {I18n} from "react-redux-i18n";

/*validations: [
 { "type": "required", "errorMessage": "Field Required" },
 { "type": "allowedRegex", "regex": "^[a-zA-Z0-9_-]*$", "errorMessage": "character not valid" },
 { "type": "noAllowedRegex", "regex": "^[a-zA-Z0-9_-]*$", "errorMessage": "character not valid" }
 ]*/

const DEFAULT_VALUE = '';
const DEFAULT_MAX_LENGTH = 524288;
const translate = (key) => I18n.t('common.' + key);

export const TextInput = ({
                              properties: {
                                  name,
                                  label,
                                  placeholder = '',
                                  type = 'text',
                                  className,
                                  idInput = name,
                                  validations,
                                  errorPlacement = 'right',
                                  errorEl = 'label',
                                  trim = false,
                                  noAllowedCharacters: initialNoAllowedCharacters,
                                  allowedCharacters: initialAllowedCharacters,
                                  showErrorOnBlur = false,
                                  maxLength,
                              },
                              onError,
                              value = DEFAULT_VALUE,
                              onChange,
                              disabled,
                              forceDisplayError,
                              forceMessageError,
                              tooltipPositionInHeader = true,
                              additionalStyleChildren = {},
                              informationTooltip = {},
                              classNameInput,
                              onKeyPress
                          }) => {
    const noAllowedCharacters = (initialNoAllowedCharacters || (ALPHABET).split('').filter(c => !(initialAllowedCharacters || ALPHABET).includes(c)).join('')).split('');
    const allowedCharacters = (initialAllowedCharacters || (ALPHABET).split('').filter(c => !noAllowedCharacters.includes(c)).join('')).split('');
    const inputRef = useRef(null);
    const tooltipRef = useRef(null);
    const {
        errorMessage: _errorMessage,
        displayError,
        handleOnBlur: handleOnBlurError,
    } = useHandleError({ validations, value, onError, forceDisplayError, showErrorOnBlur, defaultValue: DEFAULT_VALUE });
    const errorMessage = forceMessageError || _errorMessage;
    const [maxSizeToolTip, setMaxSizeToolTip] = useState('none');
    const handleChange = (newValue) => {
        let fixedValue = newValue.split('').filter(c => allowedCharacters.includes(c)).join('');
        if (trim && fixedValue.trim().length === 0) {
            fixedValue = '';
        }
        if (fixedValue !== value) {
            onChange(fixedValue);
        }
    };
    
    const handleOnBlur = () => {
        let fixedValue = value;
        if (trim) {
            fixedValue = value.trim();
            if (fixedValue !== value) {
                onChange(fixedValue);
            }
        }
        handleOnBlurError();
    };
    
    const inputWidth = inputRef?.current?.offsetWidth;
    const tooltipWidth = tooltipRef?.current?.offsetWidth;
    
    useEffect(() => {
        const maxSizeToolTipCalculate = inputWidth - tooltipWidth - 28;
        if (maxSizeToolTipCalculate && maxSizeToolTipCalculate > 0) {
            setMaxSizeToolTip(maxSizeToolTipCalculate);
        } else if (maxSizeToolTipCalculate < 0) {
            setMaxSizeToolTip('none');
        }
    }, [inputWidth, tooltipWidth, tooltipPositionInHeader]);
    
    const useStyles = makeStyles({
        tooltip: {
            fontSize: (errorMessage.length < 24) ? '14px' : '12px',
            maxWidth: maxSizeToolTip && false ? maxSizeToolTip : 'none',
            top: errorMessage.length < 24 ? '0px' : '-6px',
            color: '#000000',
            backgroundColor: 'var(--highlighted-error-color)',
            lineHeight: '15px',
            // fontSize: 14px;
        },
        arrow: {
            color: 'var(--highlighted-error-color)',
        },
        infoTooltip: {
            color: '#FFFFFF',
            backgroundColor: '#000000',
            opacity: '0.95',
            fontSize: '14px',
        },
        tooltipPlacementBottom: {
            margin: '2px',
        },
        tooltipPlacementTop: {
            margin: '2px',
        },
        
    });
    
    const classes = useStyles();
    const rangeNumber = validations?.find(validate => validate.type === RANGE_NUMBER);
    //const extraProperties = !!rangeNumber?{min:rangeNumber?.range?.min||undefined,max:rangeNumber?.range?.max||undefined }:{}
    const extraProperties = !!rangeNumber ? {
        min: (rangeNumber.hasOwnProperty('range') && rangeNumber.range.hasOwnProperty('min')) ? rangeNumber.range.min : undefined,
        max: (rangeNumber.hasOwnProperty('range') && rangeNumber.range.hasOwnProperty('max')) ? rangeNumber.range.max : undefined,
    } : {};
    
    const styleIcon = {
        cursor: !disabled ? 'pointer' : 'default',
        color: !disabled ? '#007CB0' : '#98CADF',
        margin: '0 5px',
    };
    
    const textInputLabel = (
        <div className="form-text-input-label" style={{ display: 'flex', alignItems: 'center' }}>
            {errorEl === 'label' ? (
                    <Tooltip
                        open={!!displayError && !!errorMessage}
                        arrow={true}
                        title={errorMessage}
                        placement={errorPlacement}
                        PopperProps={{ disablePortal: true }}
                        classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
                        ref={tooltipRef}
                    >
                        <span>{label} {(!!validations?.find(validate => validate.type === REQUIRED || validate.type === FORM_FIELD_REQUIRED)) && <span>*</span>}</span>
                    </Tooltip>
                )
                : <span>{label} {(!!validations?.find(validate => validate.type === REQUIRED)) && <span>*</span>}</span>}
            {!!informationTooltip?.message && (
                <Tooltip
                    interactive
                    placement="top"
                    title={<TooltipInfoMessage tooltipContent={informationTooltip?.message} />}
                    classes={{
                        tooltip: classes.infoTooltip,
                        tooltipPlacementBottom: classes.tooltipPlacementBottom,
                        tooltipPlacementTop: classes.tooltipPlacementTop,
                    }}
                >
                    <InfoIcon style={styleIcon} fontSize={'small'} />
                </Tooltip>
            )}
        </div>
    );
    
    const textInput = (
        <div
            className={'form-text-input-container' + (className ? ' ' + className : '') + (disabled ? ' disabled' : '') + (displayError && errorMessage ? ' display-error' : ' no-display-error')}
            style={additionalStyleChildren}
        >
            {textInputLabel}
            <div className="form-text-input">
                <div className="base-filter">
                    <div className="box-container">
                        <div className="input-container">
                            <input
                                className={classNameInput || ''}
                                ref={inputRef}
                                id={idInput}
                                type={type}
                                placeholder={placeholder}
                                name={name}
                                value={value}
                                disabled={disabled}
                                onChange={e => handleChange(e.target.value)}
                                onBlur={handleOnBlur}
                                autoComplete="off"
                                maxLength={maxLength || DEFAULT_MAX_LENGTH}
                                onKeyPress={onKeyPress}
                                {...extraProperties}
                            />
                            {(maxLength && type !== 'number') &&
                                <span className='character-counter'>{`${maxLength - (value? value.length : 0)} characters left.`}</span>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    
    return errorEl === 'container' ? (
        <Tooltip
            open={!!displayError && !!errorMessage}
            arrow={true}
            title={translate(errorMessage)}
            placement={errorPlacement}
            PopperProps={{ disablePortal: true }}
            classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
            ref={tooltipRef}
        >
            {textInput}
        </Tooltip>
    ) : textInput;
};
