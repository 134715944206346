import React from 'react';
import PropTypes from 'prop-types';
import {Feature, VectorLayer} from '../../../../../../common/app/views/map/ZMap';
import PinIcon from '../../../icons/PinIcon';
import PinShadowIcon from '../../../icons/PinShadowIcon';
import * as ReactDOMServer from "react-dom/server";
//openlayers
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import Point from 'ol/geom/Point';


function getImageSourceFromReactIcon(icon){
    icon = React.cloneElement(icon,{xmlns:"http://www.w3.org/2000/svg"});
    let svg_string = ReactDOMServer.renderToStaticMarkup(icon);

    return "data:image/svg+xml;charset=utf8,"+ encodeURIComponent(svg_string);
}

let PIN_IMAGE = getImageSourceFromReactIcon(<PinIcon height={512} width={512} color={"#269bc5"} borderColor={"#2084a8"} style={{height:'auto',width:'auto'}}/>);
let PIN_SHADOW = getImageSourceFromReactIcon(<PinShadowIcon height={512} width={720} color="rgba(0,0,0,0.6)" style={{height:'auto',width:'auto'}}/>);

/**
 * Displays tags on a map.
 */
class PinLayer extends React.Component{

    /**
     * @private
     */
    constructor(props){
        super(props);
        this.props = props;
    }

    /**
     * @private
     */
    static get propTypes(){
        return {
            pins: PropTypes.array.isRequired
        };
    }

    /**
     * @private
     */
    render(){

        let iconStyle = new Style({
            image: new Icon({
                anchor:[0.5,1],
                src:PIN_IMAGE,
                imgSize:[512,512],
                scale:(25.0 / 512)
            })
        });

        let largeIconStyle = new Style({
            image: new Icon({
                anchor:[0.5,1],
                src:PIN_IMAGE,
                imgSize:[512,512],
                scale:(35.0 / 512)
            })
        });

        let haloStyle = new Style({
            image: new Icon({
                anchor:[0.5,(30/40)],
                src:PIN_SHADOW,
                imgSize:[720,512],
                scale:(35.0 / 512)
            })
        });


        let _pins =  this.props.pins.map((v,i)=>{

            let styles = (v.selected) ? [haloStyle,largeIconStyle] : [iconStyle];

            return (
                <Feature
                    key={"tag"+i}
                    geometry={ new Point([v.x,v.y]) }
                    data={v}
                    style={styles}
                />
            );
        });


        return (
            <VectorLayer {...this.props}>
                {_pins}
            </VectorLayer>
        );
    }
}

export default PinLayer;
