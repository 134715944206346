import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const ExportIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"/>
    </SvgIcon>
);

export default ExportIcon;
