import React from 'react';

import Button from '@material-ui/core/Button';
import ResourcePrintElement from "./ResourcePrintElement";
import StyledDialog from "../../../util/Dialog";

class ModalResourcePrint extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            typesArrayObject: [],
            index: 0,
            anchorEl: null
        };
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleChangeIndex = this.handleChangeIndex.bind(this);
        this.handleChangeElementValue = this.handleChangeElementValue.bind(this);
        this.associateAnchor = this.associateAnchor.bind(this);
    }

    associateAnchor(ref){
        this.setState({
            anchorEl: ref
        });
    }

    UNSAFE_componentWillMount(){
        let typesArrayObject = Object.keys(this.props.selectedData).map(key => {
            const selectedObj = this.props.selectedData[key];
            return {
                pObjSeqMaxLen: selectedObj.objectidMaxLength,
                pObjIdPrefix: selectedObj.objectidPrefix,
                pObjectSeq: selectedObj.objectidSequence,
                pPrintFlag: selectedObj.rfidPrinting,
                pRangeCode: selectedObj.tagSeqRangeCode,
                pTagIDBase: selectedObj.tagidBase,
                pType: selectedObj.type,
                pTypeId: selectedObj.typeId
            };
        });

        this.setState({
            typesArrayObject: typesArrayObject
        });

    }

    handleChangeIndex(){
        this.setState({
            index: this.state.index + 1
        })
    }

    handleUpdate(){
        const args = this.state.typesArrayObject[0];
        let print = true;
        const limit_int = 2147483647;
        if(Number(args.pObjSeqMaxLen)> limit_int){
            this.props.actions.displayErrorDialog({
                title: "Error",
                message: `The max number permitted for the "ResourceId Seq Max Length" field is up to ${limit_int}`,
                noconsole: true,
            });
            print = false;
        }
        if(args.pObjIdPrefix!==null && (args.pObjIdPrefix.length > 10 || /\s/.test(args.pObjIdPrefix))){
            this.props.actions.displayErrorDialog({
                title: "Error",
                message: 'Write only 10 characters as max in "Resource Id Prefix for AutoGen" field without spaces',
                noconsole: true,
            });
            print = false;
        }

        if(print) {
            this.props.actions.updateResourceTypePrint(args);
            this.props.onClose();
        }
    }

    handleClose(){
        this.props.onClose();
    }

    handleChangeElementValue(index, elementName, value){
        let typeDataArray = [...this.state.typesArrayObject];

        typeDataArray[index][elementName] = value;

        this.setState({
            typesArrayObject: typeDataArray
        });
    }

    render(){
        const {index, typesArrayObject} = this.state;
        const dataTypePrint = typesArrayObject[index];
        let anchor = this.state.anchorEl;

        const modalResourcePrintButtons = (
            <>
                <Button style={{position: 'absolute', left: 8, bottom: 8}} onClick={this.props.onClose} variant="text">Cancel</Button>
                <Button style={{color:'#00779f'}} onClick={this.handleUpdate} variant="text">Save</Button>
            </>
        );

        let mobileHeight = `${this.props.windowHeight - 240}px`;        
        if(anchor && (anchor.offsetHeight < this.props.windowHeight -240)){
            mobileHeight = `${anchor.offsetHeight}px`;
        }

        return (<StyledDialog
             title={'Resource Type RFID Printing Setup'}
             open={this.props.openModal}
             onClose={this.props.onClose}
             style={{height: 'auto'}}
             actions={modalResourcePrintButtons}
             contentStyle={{overflowY:'auto',minHeight: (this.props.isMobile)?mobileHeight:'inherit',height:'100%'}}
             >
             <form ref={this.associateAnchor} style={{width: 360}}>
                <ResourcePrintElement 
                    rfidData={this.props.rfidData} 
                    index={index} 
                    dataTypePrint={dataTypePrint} 
                    onChangeElement={this.handleChangeElementValue}/>
             </form>
        </StyledDialog>)
    }

}

export default ModalResourcePrint;