import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const ZoneIcon = (props) => (
    <SvgIcon {...props}>
            <path d="m 3.0636401,21.099709 17.3853979,0.1733 -3.896463,-17.4612002 -10.9224966,0.047 z" />
            <rect height="5" style={{fill:'white',stroke:'black'}} width="19.732" x="1.428" y="9.178" />
    </SvgIcon>
);

export default ZoneIcon;
