import { ItemType } from '../../constants';
// import { DeviceRegistryService, LocationConfigService } from '../../vss/app/actions/util';
import {zonesArrayMapper, zonesStagingArrayMapper} from '../../helpers';
import {LocationConfigService} from "../../vss/app/actions/util";

export const SITE_MANAGER_ENDPOINTS = {
    [ItemType.ZONE]: {
        GET_ALL: {
            service: LocationConfigService,
            method: 'GET',
            urlTemplate: ({ mapId }) => {
                return `/zones?mapId=${mapId}`;
            },
            getResultData: (response) => zonesArrayMapper(response.data),
            fetchingMessage: 'Getting zones',
        },
        GET_AUTO_ZONES: {
            service: LocationConfigService,
            method: 'GET',
            urlTemplate: ({ mapId }) => {
                return `/zones?mapId=${mapId}&priority=-1`;
            },
            getResultData: (response) => zonesArrayMapper(response.data),
            fetchingMessage: 'Getting zones',
        },
        GET_ALL_STAGING: {
            service: LocationConfigService,
            method: 'GET',
            urlTemplate: ({ mapId }) => {
                return `/staging/${mapId}`;
            },
            getResultData: (response) => zonesStagingArrayMapper(response.data),
            fetchingMessage: 'Getting zones',
        },
        GET: { // Not used
            service: LocationConfigService,
            method: 'GET',
            urlTemplate: ({ itemId }) => `/zones/${itemId}`,
            getResultData: (result) => result.data?.results || {},
            fetchingMessage: 'Getting zone',
        },
        CREATE: {
            service: LocationConfigService,
            method: 'POST',
            urlTemplate: () => `/zones`,
            fetchingMessage: 'Adding zone',
        },
        BULK_CREATE: {
            service: LocationConfigService,
            method: 'POST',
            urlTemplate: () => `/zones`,
            fetchingMessage: 'Adding zone',
        },
        UPDATE: {
            service: LocationConfigService,
            method: 'PUT',
            urlTemplate: () => `/zones`,
            fetchingMessage: 'Updating zone',
        },
        DELETE: {
            service: LocationConfigService,
            method: 'DELETE',
            urlTemplate: ({ itemId }) => `/zones/${itemId}`,
            fetchingMessage: 'Deleting zone',
        },
        BULK_DELETE: {
            service: LocationConfigService,
            method: 'DELETE',
            urlTemplate: ({ itemIds, props }) => `/zones/${itemIds.join(',')}?deleteChildren=${props.deleteChildren}`,
            fetchingMessage: 'Deleting zone',
        },
        GET_ZONE_TYPES: {
            service: LocationConfigService,
            method: 'GET',
            urlTemplate: () => {
                return `/zones/types`;
            },
            getResultData: (response) => response.data?.results || [],
            fetchingMessage: 'Getting zones',
        },
    },
};
