import React from 'react';
import PropTypes from 'prop-types';

import SimpleZonePanel from './SimpleZonePanel';

import {bindActionCreators} from 'redux';
import * as actions from '../../actions';
import {connect} from 'react-redux';
import {ConfigurationZones} from "../../../../views/site-manager/configuration-zones/ConfigurationZones";
import {Split} from "../../../../components/Split";
import '../../../../views/site-manager/styles.scss';



// =============================================================================

class Autosaver extends React.Component{

    static get propTypes(){
        return {
            save: PropTypes.func,
            arguments:PropTypes.array
        };
    }

    componentDidMount(){
        this.saveInterval = setInterval(()=>{
            this.save();
        },5000);
    }

    componentWillUnmount(){
        clearInterval(this.saveInterval);
        this.save();
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        if(this.props.arguments !== nextProps.arguments){
            this.save();
        }
    }

    save(){
        this.props.save.apply(null,this.props.arguments);
    }

    render(){
        return (
            <div style={{display:'none'}} />
        );
    }
}

/*
███    ███  █████  ███    ██  █████   ██████  ███████ ██████
████  ████ ██   ██ ████   ██ ██   ██ ██       ██      ██   ██
██ ████ ██ ███████ ██ ██  ██ ███████ ██   ███ █████   ██████
██  ██  ██ ██   ██ ██  ██ ██ ██   ██ ██    ██ ██      ██   ██
██      ██ ██   ██ ██   ████ ██   ██  ██████  ███████ ██   ██
*/

class DumbZoneBuilderManager extends React.Component{

    static get propTypes(){
        return {
            onMount: PropTypes.func,
            map: PropTypes.any,
            saveToStaging: PropTypes.func.isRequired,
            requestZonesForMap: PropTypes.func.isRequired,
            requestPublishedZonesForMap: PropTypes.func.isRequired,
            autosave: PropTypes.any,
            fitMapToContainer: PropTypes.func
        };
    }

    componentDidMount(){
        this.props.onMount();
        // this.props.requestZonesForMap(this.props.map);
        // this.props.requestPublishedZonesForMap(this.props.map);
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        if(nextProps.map !== this.props.map){
            // this.props.loadZonesByZoneByMap(nextProps.map);
            // this.props.requestPublishedZonesForMap(nextProps.map);
        }
    }


    render() {
        const { map, autosave, zonesPanel, fitMapToContainer } = this.props;

        const autosaver = autosave ? (
            <Autosaver arguments={[map]} save={this.props.saveToStaging} />
        ) : null;
        return (
            <div style={{ height: "100%" }}>
                {zonesPanel ? (
                  <Split
                    initialPrimarySize="calc(100% - 320px)"
                    minPrimarySize="calc(100% - 480px)"
                    minSecondarySize="160px"
                    onSplitChanged={fitMapToContainer}
                    splitterSize="10px"
                  >
                    <ConfigurationZones mapId={map} />
                    <SimpleZonePanel mapId={map} />
                  </Split>
                ): (
                  <ConfigurationZones mapId={map} />
                )}
                {autosaver}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    zonesPanel: state.sites.layout.zonesPanel
});

const ZoneBuilderManager = connect(
    mapStateToProps,
    dispatch => ({
        ...bindActionCreators({
            onMount: actions.initialize,
            saveToStaging: actions.saveToStaging,
            fitMapToContainer: actions.updateMapSize,
            requestZonesForMap: actions.requestZonesForMap,
            requestPublishedZonesForMap: actions.requestCurrentlyPublishedZonesForMap
        },dispatch)
    })
)(DumbZoneBuilderManager);

export default ZoneBuilderManager;
