import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import {DialogActions, DialogTitle, List, ListItem} from '@material-ui/core';
import {DialogContent} from '@material-ui/core/es';
import FlatButton from '@material-ui/core/Fab';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import ActionSearch from '@material-ui/icons/Search';
import Paper from "@material-ui/core/Paper";
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import {OPTION_ALL_WILDCARD} from "../../../../constants/Misc";
import {isAllWrite} from "./util";


class FilterMobile extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            searchText:''
        };
        super(props);
        this.handleClose = this.handleClose.bind(this);
        this.handleChangeValue = this.handleChangeValue.bind(this);
        this.searchValue = this.searchValue.bind(this);       
    }

    static get propTypes(){
        return {
            open: PropTypes.bool.isRequired,
            onRequestClose: PropTypes.func.isRequired,
            onChangeValue: PropTypes.func.isRequired
        };
    }

    handleClose(){
        this.props.onRequestClose();
    }

    searchValue(){
        const {searchText} = this.state;
        //const value=(searchText===OPTION_ALL_TEXT)?OPTION_ALL_WILDCARD:searchText;
        const value=(isAllWrite(searchText))?OPTION_ALL_WILDCARD:searchText;
        this.props.onChangeValue(value);
        this.handleClose();
    }

    handleChangeValue(event, value){
        this.props.onChangeValue(value);
        this.handleClose();
    }

    renderCloseButton(){
        return (
            <div style={{position:'absolute',top:'1em',right:'1em',color:'#777'}}>
                <IconButton
                    onClick={this.handleClose}
                    style={{width:'20px',height:'20px',padding:'0px'}}
                    iconStyle={{color:'inherit',width:'20px',height:'20px'}}
                    >
                    <CloseIcon />
                </IconButton>
            </div>
        );
    }


    render(){
        const {values, title, value, open} = this.props;
        const styles = {
            radioButton: {
              paddingTop: 10,
              paddingBottom: 10,
              paddingLeft:10,
              width:'calc(100% + 12px)',
              borderBottom:'0.5px solid',
            },
            dialogcontent:{
                width:'94%',       
            },
            actions:{
                textAlign:'left',
                borderTop:'0.36px solid',
                boxShadow: '0 -3px 3px -3px #76868c',
            },title:{
                padding:'0.5em',
                paddingBottom: '0.2em',
                borderBottom:'0.36px solid',
                boxShadow: '0 3px 3px -3px #76868c',
            },body:{
                paddingRight:'0.5em',
                paddingLeft:'0em',
                paddingTop:'0em',
                paddingBottom:'0em',
                overflowX:'hidden'
            }
        };

        // const actions = [
        //     <FlatButton
        //       label="Cancel"
        //       secondary={true}
        //       onClick={this.handleClose}
        //       variant="text"
        //     />
        // ];

        const height_list = window.innerHeight/2;

          return (
              <Dialog
                className={"lookup-dialog"}
                open={open}
                onRequestClose={this.handleClose}
                onClose={this.handleClose}
                autoScrollBodyContent={true}
                style={styles.body}
                autoDetectWindowHeight={false}
              >
                  <DialogTitle style={styles.title}>{title}</DialogTitle>
                  <DialogContent style={styles.dialogcontent}>

                {this.renderCloseButton()}
                <div style={{width:'calc(100% - 8px)',marginLeft:'10px',display:'flex'}}>
                    <TextField
                    hintText="Search" style={{width:'calc(100% - 40px)'}}
                    onChange = {(event,newValue) => this.setState({searchText : newValue})}
                    />
                    <FlatButton
                    icon={<ActionSearch color={"#fff"}/>}
                    backgroundColor="#00779f"
                    hoverColor="#5296b2"
                    onClick={this.searchValue}
                    style={{marginTop:'6px',marginLeft:'6px',padding:'0',minWidth:'40px'}}
                    autoFocus
                    ><ActionSearch color={"#fff"}/></FlatButton>
                </div>             
                    <Paper style={{maxHeight: height_list,overflowY:'auto',boxShadow: "none"}}>
                        <CustomSelectableList values={values} defaultValue={value}  handleChangeValue={this.handleChangeValue} />
                    </Paper>
                  </DialogContent>
                  <DialogActions style={styles.actions}>actions}</DialogActions>
              </Dialog>
          );
    }
    
}

export default FilterMobile;



class CustomSelectableList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            existResults: false
        }
    }

    static get propTypes(){
        return {
            defaultValue: PropTypes.string.isRequired,
            values: PropTypes.array.isRequired
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        if(nextProps.values !== this.props.values){
            this.setState({existResults:true});
        }
    }

    render() {
        const styles={
            item:{
                marginLeft:10,
                borderBottom:'0.3px solid',
            },
            itemfirst:{
                marginLeft:10,
                borderBottom:'0.3px solid',
                borderTop:'0.3px solid',
            }
        };
        const {values,defaultValue} = this.props;
        const {existResults} = this.state;
        const items = values.map((value, index) => 
                <ListItem  key={index} primaryText={value} value={value} style={styles.item}
                rightIcon={(defaultValue === value) ? <RadioButtonChecked style={{fill:'#5296b2'}} />: <RadioButtonUnchecked />}/> 
        );

        //const SelectableList = makeSelectable(List);
        // removed unavailable function
        const SelectableList = List;
        return (
                <div>
                    {existResults?
                        <SelectableList onChange={this.props.handleChangeValue}>            
                        <ListItem primaryText="All" value={'%'} style={styles.itemfirst}
                            rightIcon={(defaultValue === '%') ? <RadioButtonChecked style={{fill:'#5296b2'}} />: <RadioButtonUnchecked />}/> 
                            {items}                
                    </SelectableList>
                    :<div style={{textAlign:'center'}}><CircularProgress color={"#00779f"} thickness={4} size={40}/></div>
                    }
                </div>
                     
            );
    }
}

