import React from 'react';
import PropTypes from 'prop-types';

import {getToken} from '../../../../actions/util';

import * as ZMap from './../../../../../../common/app/views/map/ZMap';
//openlayers
import XYZ from 'ol/source/XYZ';

const {TileLayer} = ZMap;



export default class LocalMapLayer extends React.Component{

    static get propTypes(){
        return {
            tilePrefix: PropTypes.string.isRequired,
            maxZoom: PropTypes.number,
            projection: PropTypes.any,
            onLoadError: PropTypes.func,
            siteId: PropTypes.any,
            mapId: PropTypes.any,
        };
    }

    static get defaultProps(){
        return {
            maxZoom: 1,
            onLoadError:()=>{}
        };
    }

    constructor(props){
        super(props);

        this.state = {
            tileSource: this.refreshTileSource(props)
        };
    }

    refreshTileSource(props={}){
        const {tilePrefix,maxZoom,projection} = props;

        const tileUrlFunction = (coord)=>{
            let _z = coord[0];
            let _x = coord[1];
            // let _y = -coord[2] -1;
            let _y = coord[2];

            var url = tilePrefix;
            url += (_z) + "_";
            url += (_x) + "_";
            url += (_y);
            url += `?jwt=${getToken()}`;
            return url;
        };

        const tileSource = new XYZ({
            tileUrlFunction: tileUrlFunction,
            tileSize:[256,256],
            wrapX:false,
            maxZoom:maxZoom,
            projection: projection,
            crossOrigin:'anonymous',
        });

        tileSource.on('tileloaderror',this.props.onLoadError);


        return tileSource;
    }

    shouldComponentUpdate(nextProps){
        return (
            this.props.tilePrefix !== nextProps.tilePrefix
            || this.props.maxZoom !== nextProps.maxZoom
            || this.props.projection !== nextProps.projection
            || this.props.map !== nextProps.map // eslint-disable-line
            || this.props.zIndex !== nextProps.zIndex // eslint-disable-line
        );
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        if(this.shouldComponentUpdate(nextProps)){
            this.setState({
                tileSource: this.refreshTileSource(nextProps)
            });
        }
    }

    render(){
        const {projection,mapId,siteId,...other} = this.props;
        const {tileSource} = this.state;

        return (
            <TileLayer
                {...other}
                source={tileSource}
                projection={projection}
                siteId={siteId}
                mapId={mapId}
                />
        );
    }
}
