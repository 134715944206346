import { loadTranslations, setLocale } from 'react-redux-i18n';
import { DEFAULT_LOCALE, SUPPORTED_LOCALES } from '../constants/i18n';
import { getUserLocale } from 'get-user-locale';
import axios from "axios";
import {displayAllErrorFromAxios} from "./util";

export const initLocaleWithFallback = () => {
  const userLocale = getUserLocale().split('-')[0];
  //console.log(userLocale);
  const finalLocale = Object.keys(SUPPORTED_LOCALES).includes(userLocale) ? userLocale : DEFAULT_LOCALE;
  return (dispatch, getState) => {
    if (!getState().i18n?.translations?.en) {
      dispatch(loadLocale(finalLocale));
    }
    dispatch(setLocale(finalLocale));
  };
};

export const setLocaleWithFallback = (newLocale) => {
  const finalLocale = Object.keys(SUPPORTED_LOCALES).includes(newLocale) ? newLocale : DEFAULT_LOCALE;
  return dispatch => dispatch(setLocale(finalLocale));
};
/*
export const loadLocale = (newLocale) => {
  return async (dispatch, getState) => {
    const response = await fetch(`http://localhost:1234/${newLocale}`).then(response => response.json());
    dispatch(loadTranslations({
      ...getState().i18n.translations,
      [newLocale]: {
        ...response
      }
    }));
  };
};*/

export const loadLocale = (newLocale) => {
  return async (dispatch,getState) => {
      axios.get(`/lang/translationI18n.json`)
          .then( result=> {
            const info = result.data[newLocale];
            dispatch(loadTranslations({
              ...getState().i18n.translations,
              [newLocale]: {
                ...info
              }
            }));
          }).catch(errors => {
            dispatch(displayAllErrorFromAxios(errors));
          })
    }
};

export const loadTranslate  = (newLocale) =>{
    const finalLocale = Object.keys(SUPPORTED_LOCALES).includes(newLocale) ? newLocale : DEFAULT_LOCALE;
    return (dispatch, getState) => {
        if (!getState().i18n?.translations?.en) {
            dispatch(loadLocale(finalLocale));
        }
        dispatch(setLocale(finalLocale));
    };
}