let WidgetStore = {};

const WidgetDefinitions = {};

WidgetStore.get = (widgetId) => {
    return WidgetDefinitions[widgetId];
};

WidgetStore.register = (widgetId,widgetDef) => {
    WidgetDefinitions[widgetId] = widgetDef;
};

WidgetStore.register(6,require('./ZLAStatusChart'));
WidgetStore.register(7,require('./DeviceAlertChart'));
WidgetStore.register(8,require('./TagBlinkBatteryChart'));
WidgetStore.register(10,require('./ResourcesByTypeChart'));
WidgetStore.register(11,require('./ResourcesByCustomAttributeValue'));
WidgetStore.register(12,require('./ResourcesBySite'));
WidgetStore.register(13,require('./ResourcesByZone'));

WidgetStore.register(0,{
    type:'MAP',
    title:"Site Alerts",
    pinType:'alert',
    data:null,
    dataProvider:{
        service:"report",
        url:"/dashboards/widgets/0/data"
    },
    reportLink:"/reports/CF2768DA-D459-11D3-9019-00105A6AE011" // system alerts report
});


WidgetStore.register(9,{
    type:'TABLE',
    dataProvider:{
        service:"report",
        url:"/dashboards/widgets/9/data"
    },
    data:null,
    columns:[{
        caption:"Alert Type",
        key:"name"
    },{
        caption:"# of Alerts",
        key:"cnt"
    }],
    title:"Other Open Alerts",
    reportLink:"/reports/CF2768DA-D459-11D3-9019-00105A6AE011"
});

module.exports = WidgetStore;
