import {getCenter} from "ol/extent";

export const zoomToMap=(map,zoom,withDelay=true)=>{
    map.getView().setZoom(zoom);
}
export const zoomOutMap=(map,withDelay=true)=>{
    const currentZoom=map.getView().getZoom();
    const newZoom=(currentZoom>0)?currentZoom-1:0;
    zoomToMap(map,newZoom);
}
export const zoomInMap=(map,withDelay=true)=>{
    const currentZoom=map.getView().getZoom();
    const newZoom=(currentZoom<=100)?currentZoom+1:100;
    zoomToMap(map,newZoom);
}
export const centerMap=(map,bounds)=>{
    map.getView().setCenter(getCenter(bounds));
    zoomToMap(map,1)
}