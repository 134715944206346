import { ZONES_ENDPOINTS } from '../constants/Zones';
import { getDataWithFilter, updateProcessing } from './';
import { LCSMapService, displayErrorFromAxios } from './util';
import { ENDPOINTS, REPORT_ID_ZONES } from '../constants';

export const updateZoneSettings = (zone) => {
    return async dispatch => {
        const zonePayload = {zonesUpdated: [zone]};
        let endpoint = ZONES_ENDPOINTS.zones.update.url;
        endpoint = endpoint.replace('{mapId}', zone.mapId);

        dispatch(updateProcessing(true));
        await LCSMapService.instance().request(
            {
                url: endpoint,
                method: ZONES_ENDPOINTS.zones.update.method,
                data: zonePayload
            }
        ).then((result) => {
            dispatch(updateProcessing(false));
            dispatch(getDataWithFilter(ENDPOINTS[REPORT_ID_ZONES].GET_ALL, false));
        }).catch(
            displayErrorFromAxios.bind(null, dispatch)
        );
    }
}
