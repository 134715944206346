import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';
import PropTypes from 'prop-types';

const PinShadowIcon = (props) => {

    const {color,...other} = props;


    return (
        <SvgIcon {...other}>
            <g transform="translate(10,0) skewX(-15) scale(0.15)" style={{overflow:'visible',boxShadow:'0 0 5px'}}>
                <path
                    d="m 99.498376,52.719861 c 0,-26.489798 -21.269663,-47.963099 -47.510063,-47.963099 -26.238532,0 -47.5100649,21.473301 -47.5100649,47.963099 0,11.64676 4.1162875,22.325641 10.9565339,30.632173 L 52.029401,134.73062 89.159097,82.57451 c 1.333498,-1.684793 2.554937,-3.460503 3.656847,-5.327128 l 0.375397,-0.543604 -0.06163,-0.30368 c 4.04345,-7.059274 6.368668,-14.939958 6.368668,-23.680237"
                    style={{
                        fill:color
                    }}
                />
            </g>
        </SvgIcon>
    );

};

PinShadowIcon.propTypes = function(){
    return {
        color: PropTypes.string
    };
};




export default PinShadowIcon;
