import {getDataOfPath, getValueOfPath} from "../../../utils";
const sexp = require('sexp');

const parseDecimalToHexadecimal = number =>{
    if(typeof number === 'number'){
        return '#'+number.toString(16);
    }else if(typeof number === 'string'){
        const parseNumber = parseInt(number);
        if(!isNaN(parseNumber)){
            return '#'+  parseNumber.toString(16);
        }else {
            return null;
        }
    }else{
        return null;
    }
}

const parseGEO_JSON = data =>{
    const geoJSON = {};
    geoJSON.type = 'Feature';
    geoJSON.properties = null;
    const coordinates = [];
    data?.forEach(point => {
        coordinates.push([+getValueOfPath(point, '$.x') , +getValueOfPath(point, '$.y')]);
    });

    if(coordinates.length>1){
        coordinates.push(coordinates[0]);
    }

    geoJSON.geometry = {
        type: 'Polygon',
        coordinates: [coordinates]
    }
    return geoJSON;
}
const TYPE_VALUE = {
    COLOR:"color",
    GEO_JSON:"geoJson",
    GROUP_TYPE:"groupType"
}

const convertValue = (type,value) =>{
    switch (type) {
        case TYPE_VALUE.COLOR:
            return parseDecimalToHexadecimal(value);
        case TYPE_VALUE.GEO_JSON:
            return parseGEO_JSON(value);
        case TYPE_VALUE.GROUP_TYPE:
            return groupTypeValues[value]
        default:
            return value;
    }
}
const groupTypeValues = {
    'Standard Zone':'standard',
    'Inclusion Zone':'inclusion',
    'Exclusion Zone':'exclusion',
    'Invisible Zone':'invisible'
}

const renamePropertyNames = {
    groupType:'type',
    offsetPoly:'shape',
    viewColor:'color',
    WPInclusion:'whereportInclusion',
    exitZone_dbIndex:'exitZoneId',
    exitZone_guid:'exitZoneId'
};

const convertValueToType = {
    viewColor:TYPE_VALUE.COLOR,
    offsetPoly:TYPE_VALUE.GEO_JSON,
    groupType:TYPE_VALUE.GROUP_TYPE
};

const pathData = {
    offsetPoly:'$.offsetPoly.pts',
}

const groupKeys = {
    custom1:'properties',
    custom2:'properties',
    custom3:'properties',
    custom4:'properties',
    custom5:'properties'
}

const convertToZoneJSON = contentJSON =>{
    const { Zones } = contentJSON;
    return Zones?.map(zone=>{
        const keyListZone = Object.keys(zone);
        keyListZone.forEach(keyZone=>{

            const value = getDataOfPath(zone, pathData[keyZone] || keyZone);
            const newValue = convertValue(convertValueToType[keyZone],value);
            const newKey = renamePropertyNames[keyZone];

            if (newKey && keyZone !== newKey ) {
                zone[newKey] = newValue;
                delete zone[keyZone];
            }else{
                if(groupKeys[keyZone]){
                    zone[groupKeys[keyZone]] = {...zone[groupKeys[keyZone]],[keyZone]:zone[keyZone]}
                    delete zone[keyZone];
                }else{
                    zone[keyZone] = newValue;
                }
            }
        });
        return zone;
    });
}

const TYPE_PROPERTY = {
    KEY:"key",
    VALUE:"value",
    ARRAY:"array"
}

const option = {
    translateSymbol:(sym)=>{
            if(sym === 'true' || sym === 'false') {
                return {type:TYPE_PROPERTY.VALUE,value:sym==='true'}
            }else{
                return {type:TYPE_PROPERTY.KEY,KEY:sym,value:null}
            }
        },
    translateString:(value)=>{return {type:TYPE_PROPERTY.VALUE,value}},
    translateNumber:(value)=>{return {type:TYPE_PROPERTY.VALUE,value:parseFloat(value)}},
}
export const handleImportZone = contentFile =>{
    const splitContent = contentFile.split("\n").filter(item => item.charAt(0) !== '#' && item && item !== 'END');
    const content = splitContent.toString().replaceAll(",", " ");
    try{
        const parseExpression = sexp("("+content+")",option);
        return convertToZoneJSON(convertToJSON(parseExpression));
    }catch (e) {
        console.log({e})
    }
}
const convertToJSON = parseExpression =>{
    const result = fixTypeParseArrays(parseExpression);
    const joinKeysResult = joinValues (result,0,1,{});
    return fixConvertToJSON(joinKeysResult);
}

const convertArrayToObjectCoordinate = item =>{
    return {
        nPoints: getValueOfPath(item,"$[0].nPoints") || 0,
        pts: getDataOfPath(item,"$[0].pts[0].Point") || []
    }
}
const fixConvertToJSON = result =>{
    const zoneGroupType = getDataOfPath(result,"$.GroupTypes[0].ZoneGroupType")||[];
    const zoneGroup =  getDataOfPath(result,"$.Groups[0].ZoneGroup")||[];
    const zoneType =  getDataOfPath(result,"$.ZoneTypes[0].ZoneType")||[];
    const zone =  getDataOfPath(result,"$.Zones[0].Zone")||[];

    result['GroupTypes'] = zoneGroupType;
    result['Groups'] = zoneGroup;
    result['ZoneTypes'] = zoneType;
    result['Zones'] = zone.map(item=>{
        return {...item,'shapePoly':convertArrayToObjectCoordinate(item['shapePoly']),'offsetPoly':convertArrayToObjectCoordinate(item['offsetPoly'])}
    });
    return result;
}
const joinValues = (result,positionKey,positionKeyReview,parseJSON) =>{
    if(positionKeyReview<result.length){
        if(result[positionKey].type === TYPE_PROPERTY.KEY){
            if( result[positionKeyReview].type === TYPE_PROPERTY.VALUE ){
                parseJSON[result[positionKey].KEY] = result[positionKeyReview].value
            }else if( result[positionKeyReview].type === TYPE_PROPERTY.ARRAY ){
                const groupArray  = joinValues(result[positionKeyReview].value,0,1,{});
                if(!parseJSON.hasOwnProperty(result[positionKey].KEY)){
                    parseJSON[result[positionKey].KEY] = [];
                }
                parseJSON[result[positionKey].KEY].push(groupArray)
            }
            else if(result[positionKey+1].type === TYPE_PROPERTY.KEY){
                return joinValues(result,positionKey,positionKeyReview+1,parseJSON);
            }
            return joinValues(result,positionKeyReview+1,positionKeyReview+2,parseJSON);
        }
    }
    return parseJSON;
}
const fixTypeParseArrays = result =>{
    return result.map(item =>{
       if(Array.isArray(item)){
           return {type:TYPE_PROPERTY.ARRAY,value:fixTypeParseArrays(item)};
       }else{
           return item;
       }
    });
}
/*
export const getListCustomProperties = (Zones,schema) =>{
    const propertiesSchema = {...schema};
    Zones.forEach( zone=>{
        const { properties } = zone;
        const keysProperties = properties?Object.keys(properties):[];
        keysProperties.forEach( keyProperty =>{
            if( !propertiesSchema.hasOwnProperty(keyProperty) ) {
                propertiesSchema[keyProperty] = {"name":keyProperty,"type":"TEXT"};
            }
        })
    });
    return propertiesSchema;
}*/