import { LOADING_AUTOCOMPLETION_FIELDS, LOADING_SUGGESTIONS_FOR_VALUE, SAVE_INPUT_QUERY, SET_AUTOCOMPLETION_FIELDS, SET_SUGGESTIONS_FOR_VALUE } from '../constants/ActionTypes';
import { getDateFromTimestamp } from '../utils/LocaleTimestamp';
import { SEARCH_SORT_FIELDS } from '../constants';

import { parseTimeSelectorToDate } from '../utils/search';
import { displayErrorFromAxios, SearchService } from './util';
import { tableOnePlatformUpdateData, updateProcessing } from './';
import { getReportKeys } from '../hooks';

function processResponse(data, allSites, allMaps) {
    return data.map((record, idx) => {
        const header = record._source.header;
        const resource = record._source.resource;
        const { eventType, eventTrigger, eventTime } = header;
        const resourceId = (resource && resource.id) ? resource.id : '';
        const tagId = (resource && resource.tags) ? resource.tags.map(tag => (tag.id)).join(', ') : '';
        const deviceId = '';
        const siteName = (resource && resource.zones) ? resource.zones.map(zone => (zone.siteName)).join(', ') : '';
        const siteId = (resource && resource.zones && resource?.zones[0]?.siteId);
        const location = resource?.location;
        const mapId = location?.properties?.mapId || (resource && resource.zones && resource?.zones[0]?.mapId);
        const mapName = allMaps.find(map => +(map._id) === +mapId)?.description;
        const zoneName = (resource && resource.zones) ? resource.zones.map(zone => (zone.name)).join(', ') : '';
        // const coordinates = location?.geometry?.coordinates;
        // const [x, y, z] = coordinates;
        const id = '_' + idx + tagId + resourceId;
        //EventType | EventTrigger | Timestamp| ResourceId | TagId | DeviceId | Site
        const ret = {
            id, eventType, eventTrigger, eventTime: getDateFromTimestamp(eventTime), resourceId, tagId, deviceId, siteName, location,
            'site': { siteId }, mapName, zoneName
        };
        if (mapId && !(location?.properties?.mapId)) {
            ret.location.properties = { mapId };//It's needed to display the point on the map
        }
        return ret;
    });
}

export const sendSearchQuery = (queryStr, sortIndex = null, sortDirection = -1,background) => {
    return async (dispatch, getState) => {
        const { reportKey } = getReportKeys(getState());
        dispatch(updateProcessing(true, reportKey,background));
        try {
            const sort = sortIndex ? ((sortDirection < 0 ? '-' : '') + SEARCH_SORT_FIELDS[sortIndex]) : null;
            const from = getState().search.from;
            const to = getState().search.to;
            const response = await SearchService.instance().get('/search?query=' + queryStr
                + (sort ? '&sort=' + sort : '')
                + (from ? '&fromTimestamp=' + parseTimeSelectorToDate(from).toJSON() : '')
                + (to ? '&toTimestamp=' + parseTimeSelectorToDate(to).toJSON() : ''));
            const hits = processResponse(response.data, getState().sites.sites, getState().sites.maps);
            const { uKeyFields } = getState().report.scopes?.[reportKey] || {};
            dispatch(tableOnePlatformUpdateData(reportKey, hits, uKeyFields));
            // dispatch({type:SET_SEARCH_QUERY_RESPONSE, data: hits});
        } catch (error) {
            displayErrorFromAxios.bind(null, dispatch);
        }
        dispatch(updateProcessing(false, reportKey));
    };
};

export const loadAutocompletionFields = () => {
    return async (dispatch, getState) => {
        dispatch({ type: LOADING_AUTOCOMPLETION_FIELDS, loadingAutocompletionFields: true });
        try {
            const response = await SearchService.instance().get('/suggestions/fields');
            const fieldsList = response.data.map(field => field.name);
            dispatch({ type: SET_AUTOCOMPLETION_FIELDS, fieldsList: fieldsList });
        } catch (error) {
            displayErrorFromAxios.bind(null, dispatch);
        }
        dispatch({ type: LOADING_AUTOCOMPLETION_FIELDS, loadingAutocompletionFields: false });
    };
};

export const loadSuggestionsForValue = (fieldName, queryStr) => {
    return async (dispatch, getState) => {
        dispatch({ type: LOADING_SUGGESTIONS_FOR_VALUE, loadingSuggestionsForValue: true });
        try {
            const response = await SearchService.instance().get('/suggestions/values?field=' + fieldName + '.keyword');
            const suggestions = response.data ? response.data.map(element => ({ 'value': element })) : [];
            const currentInputStr = getState().search.inputSearchQuery;
            if (queryStr === currentInputStr) {
                dispatch({ type: SET_SUGGESTIONS_FOR_VALUE, suggestionsForValue: suggestions });
            }
        } catch (error) {
            displayErrorFromAxios.bind(null, dispatch);
        }
        dispatch({ type: LOADING_SUGGESTIONS_FOR_VALUE, loadingSuggestionsForValue: false });
    };
};

export const saveInputSearchQuery = (newValue, newFrom, newTo) => {
    return async (dispatch, getState) => {
        dispatch({ type: SAVE_INPUT_QUERY, inputSearchQuery: newValue, from: newFrom, to: newTo });
    };
};

export const sortByColumn = (background) => {
    return async (dispatch, getState) => {
        const { reportKey } = getReportKeys(getState());
        const { keySort: sortIndex, direction } = getState().tableOnePlatform[reportKey].sort?.[0] || {};
        const queryStr = getState().search.inputSearchQuery;
        dispatch(sendSearchQuery(queryStr, sortIndex, direction,background));
    };
};
