import {detect} from "detect-browser";
export function detectBrowser() {
    let modernBrowser=false;
    const browserInfo = detect();

    if (browserInfo) {
        const version = browserInfo.version.split('.');
        const versionTail = Array.prototype.slice.call(version, 1).join('') || '0';
        const versionBrowser = parseFloat(`${version[0]}.${versionTail}`);

        if(browserInfo.name=== 'chrome'&&versionBrowser>=60)
            modernBrowser=true;
        else if(browserInfo.name==='firefox'&&versionBrowser>=32)
            modernBrowser=true;
        else if(browserInfo.name==='safari'&&versionBrowser>=7.1)
            modernBrowser=true;
        else if(browserInfo.name==='opera'&&versionBrowser>=46)
            modernBrowser=true;
        else if(browserInfo.name==='ios'&&versionBrowser>=8)
            modernBrowser=true;
        else if(browserInfo.name==='android'&&versionBrowser>=60)
            modernBrowser=true;
        else if(browserInfo.name==='edge-chromium'&&versionBrowser>=90)
            modernBrowser=true;
        else if(browserInfo.name==='crios'&&versionBrowser>=60)// chrome ios
            modernBrowser=true;
    }
    return modernBrowser;
} 