import { DEFAULT_ZONE_COLOR } from '../../vss/app/utils/ol/olConstants';
import { getOlStoreKey } from '../helpers';
import { FeatureType } from '../../vss/app/views/sites/designer/constants';
import { ItemType } from '../../constants';

export function olZoneColorFactory(Base) {
    class OlZoneColor extends Base {
        
        color;
        
        constructor(mapRef, observer) {
            super(mapRef, observer);
            this.color = DEFAULT_ZONE_COLOR;
        }
        
        fillColorZones() {
            this[getOlStoreKey(FeatureType.ZONE)].selectedItemsFeatureCollection.getArray().forEach(feature => {
                feature.set('color', this.color);
            });
            this.observer?.executeEvent('onSaveZoneChanges', {
                zonesBeforeChanges: this.zones,
                zonesAfterChanges: this.getFeaturesDataArrayByItemType(ItemType.ZONE),
                takeASnapshot: true,
                event: 'fillColorZones',
            });
        }
        
        setColor(color) {
            if (this.color !== color) {
                this.color = color;
            }
        }
    }
    
    return OlZoneColor;
}
