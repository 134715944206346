// MapLoader Format Utilities
//
// Uses the map_format from a report definition to specify how to display the data
// set on a map.

const SITE_UID_ATTRIBUTE = "name";

const RETAIN_SITES_WITHOUT_ASSETS = false;

// Returns appropriate site information to be used for global maps.
export const compileSiteInfo = function(mapFormat,sites,assets){

    // Determine which attribute stores site information.
    let colSite = mapFormat.siteAttribute;

    // Determine which attribute stores "selected" information.
    let colSelected = mapFormat.selectedAttribute;

    // Index sites by ID / Name (choose).
    let indexedSites = {};
    sites.forEach((site)=>{
        indexedSites[ site[SITE_UID_ATTRIBUTE] ] = site;
    });

    // MapLoader and reduce which sites are shown and which are selected. (based off assets)
    // Return result.
    let _sites = assets.reduce(function(includedSites,asset){

        // Determine which site the asset is on.
        let siteIndex;
        if(asset) {
            siteIndex = asset[colSite];
        }

        // Skip if no site included.
        if(siteIndex === null || siteIndex === undefined) return includedSites;

        // Determine if asset is selected.
        let selected = asset[colSelected];

        // Merge site info and return.
        if(includedSites[siteIndex] && includedSites[siteIndex].selected) return includedSites; // no change necessary.

        return {
            ...includedSites,
            [siteIndex]:{
                ...indexedSites[siteIndex],
                selected: selected
            }
        };

    },(RETAIN_SITES_WITHOUT_ASSETS ? indexedSites : {}));

    // return as array.
    return Object.keys(_sites).map(i=>_sites[i]);

};

// Returns appropriate asset information to render on a local map.
export const compileAssetInfo = function(mapFormat,assets,hoverUID){

    if(!mapFormat.renderPoints) return []; // no points / tags / resources to be rendered.

    let colX, colY, colMap, colId, colSelected, colRecent;

    // Determine which attribute stores 'x'.
    colX = mapFormat.xAttribute;

    // Determine which attribute stores 'y'.
    colY = mapFormat.yAttribute;

    // Determine which attribute stores 'map id'.
    colMap = mapFormat.mapAttribute;

    // Determine which attribute stores 'id' (for label)
    colId = mapFormat.idAttribute;

    // Determine which attribute stores 'selected'.
    colSelected = "___selected";

    colRecent = "recent";

    return assets.map((asset) =>({
        x: asset[colX],
        y: asset[colY],
        map: asset[colMap],
        // selected: asset[colSelected] || (asset.___uid === hoverUID),
        selected: asset[colSelected],
        label: asset[colId],
        recent: asset[colRecent],
        // legacy inclusion (figure out why and break this dependency)
        //tagId: asset["tagId"],
        //name:asset["objectId"],
        raw:asset
    }));
};
