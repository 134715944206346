import React from 'react';

import PlayIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import ResetIcon from '@material-ui/icons/Replay';
import NextIcon from '@material-ui/icons/SkipNext';
import PreviousIcon from '@material-ui/icons/SkipPrevious';
import DateIcon from '@material-ui/icons/Event';
import TimeIcon from '@material-ui/icons/Schedule';

import DayPicker, {DateUtils} from 'react-day-picker';
import CustomIconButton from '../../button/IconButton';
import moment from "moment/moment";
import TimePickerComponent from './TimePickerComponent';
// import {Popover} from '@material-ui/core';
// import {List, ListItem} from 'material-ui/List';
import {ClickAwayListener, Popper} from '@material-ui/core';

import 'react-day-picker/lib/style.css';
import 'rc-time-picker/assets/index.css';
import TimerMinusIcon from './../../../../views/icons/TimerMinusIcon';
import TimerPlusIcon from './../../../../views/icons/TimerPlusIcon';


import {getDateFromTimestamp} from "../../../../utils/LocaleTimestamp";
import _ from "lodash";
import Paper from "@material-ui/core/Paper/Paper";

const currentYear = new Date().getFullYear();
const fromMonth = new Date(currentYear - 10, 0);
const toMonth = new Date(currentYear + 20, 11);


function YearMonthForm({date, localeUtils, onChange}) {
    const months = localeUtils.getMonths();

    const years = [];
    for (let i = fromMonth.getFullYear(); i <=  toMonth.getFullYear(); i += 1){
        years.push(i);
    }

    const handleChange = function handleChange(e) {
        const {year, month} = e.target.form;
        onChange(new Date(year.value, month.value));
    };

    return(
      <form className={"DayPicker-Caption"}>
          <select name={"month"} onChange={handleChange} value={date.getMonth()}>
              {months.map((month, i) => (
                  <option key={month} value={i}>
                      {month}
                  </option>
              ))}
          </select>
          <select name={"year"} onChange={handleChange} value={date.getFullYear()}>
              {years.map(year => (
                  <option key={year} value={year}>
                      {year}
                  </option>
              ))}
          </select>
      </form>
    );
}

const INDICATOR="indicator";
const START_TIME="start_time";
const END_TIME="end_time";
const SLIDER_REF="slider";

const TIME_CONTROL_WIDTH=10;
const INITIAL_REPEAT_COUNT=0;

class TimeLineControl extends React.Component{

    constructor(props){
        super(props);

        const defaultStartDate = new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate(), 0, 0, 0);
        const defaultEndDate = new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate(), 23, 59, 59);
        this.state = {
            ...this.state,
            playing: false,
            externalPause:null,
            constantRate:1000,
            updateRate:1000,
            speed: 1,
            maxSpeed:10,
            startDate: defaultStartDate,
            endDate: defaultEndDate,
            from: null,
            to: null,
            enteredTo: null,
            month: (new Date()),
            play:0,
            drag: false,
            positionIndicator:props.positionStart,
            positionRepeatCount:INITIAL_REPEAT_COUNT,
            positionStartTime:props.positionStart,
            positionEndTime:props.positionEnd,
            timeTooltipOpen: false,
            dateTooltipOpen: false,
            open: false,
            timeDiff:null, // time between the dates selected.
            minTimeDiff:60*1000, //(1 minute) min time for enable zoomIn timeline controls.
            zoomInTimelineEnabled:true,
            zoomOutTimelineEnabled:true,
            drawLayers:false,
        };

        this.distance=0;
        this.limitDatesByPosition = props.limitDatesByPosition;
        
        this.handleEndIndexInterval = this.handleEndIndexInterval.bind(this);
        this.handlePlayPauseTap = this.handlePlayPauseTap.bind(this);
        this.handleStopTap = this.handleStopTap.bind(this);
        this.handleRequestTagHistoryByTime = this.handleRequestTagHistoryByTime.bind(this);

        this.handleDayClick = this.handleDayClick.bind(this);
        this.handleDayMouseEnter = this.handleDayMouseEnter.bind(this);
        this.handleResetClick = this.handleResetClick.bind(this);
        this.handleYearMonthChange = this.handleYearMonthChange.bind(this);

        this.handleInteractionStart =  this.handleInteractionStart.bind(this);
        this.handleMove = this.handleMove.bind(this);
        this.handleInteractionEnd = this.handleInteractionEnd.bind(this);
        this.updateSliderValue = this.updateSliderValue.bind(this);
        this.handleChangeParent = _.debounce(this.handleChangeParent.bind(this),100);
        this.calculateDistance = this.calculateDistance.bind(this);
        this.handleZoomTimeline = this.handleZoomTimeline.bind(this);
        this.handleSetDate = this.handleSetDate.bind(this);

        this.handleOpenDateTooltip = this.handleOpenDateTooltip.bind(this);
        this.handlePrevTimeline = this.handlePrevTimeline.bind(this);
        this.handleOnClickOutsideDateTooltip = this.handleOnClickOutsideDateTooltip.bind(this);
        this.handleOnClickOutsideTimeTooltip = this.handleOnClickOutsideTimeTooltip.bind(this);
        this.updateTimer = this.updateTimer.bind(this);
        this.calculateUpdateRate = this.calculateUpdateRate.bind(this);

        this.handleSpeedReduce = this.handleSpeedReduce.bind(this);
        this.handleSpeedAdd = this.handleSpeedAdd.bind(this);
        this.handleOpenSpeedDropdown = this.handleOpenSpeedDropdown.bind(this);
        this.handleCloseSpeedDropdown = this.handleCloseSpeedDropdown.bind(this);
        this.zoomTimeLineFlags = this.zoomTimeLineFlags.bind(this);
    }

    UNSAFE_componentWillMount(){
        if(this.props.dateRange && this.props.dateRange[this.props.reportId]){
            const dateRangeObj = this.props.dateRange[this.props.reportId];

            this.setState({
                from: dateRangeObj.fromDateRange,
                to: dateRangeObj.toDateRange,
                enteredTo: dateRangeObj.toDateRange,
                startDate: dateRangeObj.fromDateRange,
                endDate: dateRangeObj.toDateRange
            });

            this.props.getTagHistory(moment(dateRangeObj.fromDateRange), moment(dateRangeObj.toDateRange));
        }
    }

    componentDidMount(){
        this.updateTimer(this.state.updateRate);

        const {length}=this.props;
        //const refName=SLIDER_REF;
        //let maxSize = this.getSliderInfo(refName).length;
        this.distance=this.calculateDistance(length);

        let date = new Date();
        let dateString = date.toLocaleTimeString();
        if(dateString.match(/am|pm/i) || date.toString().match(/am|pm/i)){
            this.setState({
                use12Hours: true,
            });
        } else {
            this.setState({
                use12Hours: false,
            });
        }

        if(this.props.dateRange && this.props.dateRange[this.props.reportId]) {
            const dateRangeObj = this.props.dateRange[this.props.reportId];
            this.handleYearMonthChange(dateRangeObj.fromDateRange);
        }

    }

    componentDidUpdate(prevProps){

        this.distance=this.calculateDistance(this.props.length);
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        this.limitDatesByPosition = nextProps.limitDatesByPosition;
        let {positionIndicator,positionStartTime,positionEndTime}=this.state;
        let newPositionIndicator=nextProps.pos;
        let stateObject={};

        if(positionIndicator<positionStartTime)
            newPositionIndicator=positionStartTime;
        else if(positionIndicator>positionEndTime)
            newPositionIndicator=positionEndTime;

        if(nextProps.windowWidth!==this.props.windowWidth||this.props.length!==nextProps.length)
            this.distance=this.calculateDistance(nextProps.length);

        if(this.state.externalPause===true&&nextProps.playTimeLine!==this.props.playTimeLine)
            stateObject.playing=nextProps.playTimeLine;

        // if(this.props.historyDates!==nextProps.historyDates){
            const zoomTimeLineFlags=this.zoomTimeLineFlags(positionStartTime,positionEndTime);
            stateObject={...stateObject,...zoomTimeLineFlags};
        // }
        if (nextProps.dateRange && this.props.dateRange) {
            const currentDates = this.props.dateRange[this.props.reportId];
            const nextDates = nextProps.dateRange[this.props.reportId];
            if (currentDates.fromDateRange !== nextDates.fromDateRange || 
                currentDates.toDateRange !== nextDates.toDateRange) {
                    stateObject={...stateObject,
                        from: nextDates.fromDateRange,
                        to: nextDates.toDateRange,
                        enteredTo: nextDates.toDateRange,
                        startDate: nextDates.fromDateRange,
                        endDate: nextDates.toDateRange
                    };
                }
        }
        this.setState({
            positionIndicator:newPositionIndicator,
            positionStartTime:nextProps.positionStart,
            positionEndTime:nextProps.positionEnd,
            ...stateObject
        });
    }

    componentWillUnmount(){
        window.clearInterval(this.timer);
    }


    updateTimer(updateRate){
        clearInterval(this.timer);
        this.timer = window.setInterval(this.handleEndIndexInterval, updateRate);
    }

    calculateUpdateRate(speed){
        const {constantRate, maxSpeed,updateRate} = this.state;
        let newUpdateRate=updateRate;
        if(speed>0&&speed<=maxSpeed) {
            //newUpdateRate = constantRate - ((constantRate / maxSpeed) * (speed - 1));
            newUpdateRate = (constantRate-speed*100)+100;
        }
        return newUpdateRate;

    }

    calculateDistance(length){

        const refName=SLIDER_REF;
        let maxSize = this.getSliderInfo(refName).length;

        const distance=maxSize/length;

        return distance;
    }

    zoomTimeLineFlags(positionStartTime,positionEndTime){

        let response={};

        const {length,historyDates}=this.props;
        const { limitDatesByPosition } = this;
        if (limitDatesByPosition != null && limitDatesByPosition[positionStartTime] && limitDatesByPosition[positionEndTime]) {

            const {minTimeDiff} = this.state;
            const startLimit = limitDatesByPosition[positionStartTime].minDate;
            const endLimit = limitDatesByPosition[positionEndTime].maxDate;

            const timeDiff = endLimit - startLimit;
            const positionDiff=positionEndTime-positionStartTime;

            response.timeDiff = timeDiff;

            // min diff for enable zoom button.
            if (timeDiff < minTimeDiff&&positionDiff>=length)
                response.zoomInTimelineEnabled = false;
            else
                response.zoomInTimelineEnabled = true;

            // No zoom because is in max timeline.
            if (positionStartTime === 0 && positionEndTime === length)
                response.zoomInTimelineEnabled = false;

            // Enable zoom out if exist al least one element in history.
            if (historyDates != null && historyDates.length > 0)
                response.zoomOutTimelineEnabled = true;
            else
                response.zoomOutTimelineEnabled = false;
        } else {
            response.zoomOutTimelineEnabled = false;
            response.zoomInTimelineEnabled = false;
        }

        return response;
    }

    isSelectingFirstDay(from, to, day){
        const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
        const isRangeSelected = from && to;
        return !from || isBeforeFirstDay || isRangeSelected;
    }

    calculatePositionIndicator(relativePosition,length){

        const {positionIndicator, positionStartTime, positionEndTime} = this.state;

        let newPositionIndicator=null;
        if(relativePosition>=positionStartTime&&relativePosition<=positionEndTime) {
            newPositionIndicator = this.calculatePositionByRelative(INDICATOR, relativePosition, positionStartTime, positionEndTime,length);
            return newPositionIndicator;
        }
        else
            return positionIndicator;


    }

    calculatePosition(dragName, position,length) {

        if (length > 0) {

            const {positionStartTime, positionEndTime} = this.state;

            let newRelativePosition = Math.round(position / this.distance);

            return this.calculatePositionByRelative(dragName,newRelativePosition,positionStartTime,positionEndTime,length);
        }

        return null;

    }

    calculatePositionByRelative(dragName,newRelativePosition,relativePositionStartTime,relativePositionEndTime,length){

        if (dragName === START_TIME) {
            newRelativePosition = (newRelativePosition < 0) ? 0 : newRelativePosition;
            newRelativePosition = (newRelativePosition >= relativePositionEndTime) ? relativePositionEndTime - 1 : newRelativePosition;
        }
        else if (dragName === END_TIME) {

            newRelativePosition = (newRelativePosition > length) ? length : newRelativePosition;
            newRelativePosition = (newRelativePosition <= relativePositionStartTime) ? relativePositionStartTime + 1 : newRelativePosition;
        }
        else if (dragName === INDICATOR) {

            newRelativePosition = (newRelativePosition <= relativePositionStartTime) ? relativePositionStartTime : newRelativePosition;
            newRelativePosition = (newRelativePosition >= relativePositionEndTime) ? relativePositionEndTime : newRelativePosition;

        }

        return newRelativePosition;
    }

    getSliderInfo(ref) {
        const sl = this.refs[ref];
        const sliderInfo = {
            bounds: sl.getBoundingClientRect(),
            length: sl.clientWidth,
            height: sl.clientHeight,
        };
        return sliderInfo;
    }

    updateSliderValue(e, eventType) {

        const {dragName,positionIndicator,positionStartTime,positionEndTime}=this.state;
        const {length}=this.props;

        const xCoords = (eventType !== 'touch' ? e.pageX : e.touches[0].pageX) - window.pageXOffset;
        //const yCoords = (eventType !== 'touch' ? e.pageY : e.touches[0].pageY) - window.pageYOffset;

        const refName=SLIDER_REF;

        let newAbsolutePosition = xCoords - this.getSliderInfo(refName).bounds.left;

        let prevPosition=null;
        if (dragName === INDICATOR)
            prevPosition = positionIndicator;
        else if (dragName === START_TIME)
            prevPosition = positionStartTime;
        else if (dragName === END_TIME)
            prevPosition = positionEndTime;

        const newPosition=this.calculatePosition(dragName,newAbsolutePosition,length);

        if(newPosition!=null&&newPosition!==prevPosition) {

            let updateObject = {};

            if (dragName === INDICATOR)
                updateObject = {positionIndicator: newPosition};
            else if (dragName === START_TIME)
            {
                updateObject = {positionStartTime: newPosition};
                if(positionIndicator< newPosition)
                    updateObject.positionIndicator=newPosition;

                const zoomTimeLineFlags=this.zoomTimeLineFlags(newPosition,positionEndTime);
                updateObject={...updateObject,...zoomTimeLineFlags};
            }
            else if (dragName === END_TIME)
            {
                updateObject = {positionEndTime: newPosition};
                if(positionIndicator> newPosition)
                    updateObject.positionIndicator=newPosition;

                const zoomTimeLineFlags=this.zoomTimeLineFlags(positionStartTime,newPosition);
                updateObject={...updateObject,...zoomTimeLineFlags};
            }

            this.setState({
                ...updateObject
            }, this.handleChangeParent.bind(this,dragName));
        }
    }

    formatDateTimeline(timestamp){

        if(isFinite(timestamp))
            return getDateFromTimestamp(timestamp);
        else
            return "";
    }


    addEvents(type) {
        switch (type) {
            case 'mouse': {
                document.addEventListener('mousemove', this.handleMove);
                document.addEventListener('mouseup', this.handleInteractionEnd);
                break;
            }
            case 'touch': {
                document.addEventListener('touchmove', this.handleMove);
                document.addEventListener('touchend', this.handleInteractionEnd);
                break;
            }
            default:
        }
    }

    removeEvents() {
        document.removeEventListener('mousemove', this.handleMove);
        document.removeEventListener('mouseup', this.handleInteractionEnd);
        document.removeEventListener('touchmove', this.handleMove);
        document.removeEventListener('touchend', this.handleInteractionEnd);
    }

    handlePlayPauseTap(){
        if(this.state.positionIndicator <= this.state.positionEndTime) {
            this.setState({
                playing: !this.state.playing,
                externalPause:(!this.state.playing===true)?true:null,
            })
        }
    }

    handleEndIndexInterval(){

        if(!this.state.playing) return;

        const {blinksPerFrame}=this.props;
        const {positionRepeatCount,positionIndicator,positionEndTime}=this.state;

        let repeatCount = positionRepeatCount + 1;

        // First draw.
        if (this.state.drawLayers === false) {
            this.handleClickPosition(positionIndicator,repeatCount, true, {positionRepeatCount: repeatCount,drawLayers: true});
        }
        // While the current position is less than end position.
        else if (positionIndicator <= positionEndTime) {

            let positionRepeat=0

            // Gets the max repeat for the current position.
            if(blinksPerFrame.hasOwnProperty(positionIndicator)){
                positionRepeat=blinksPerFrame[positionIndicator];
            }

            // While the current repeat is less or equal to max repeat in current position.
            if (repeatCount <= positionRepeat) {
                this.handleClickPosition(positionIndicator, repeatCount,true, {positionRepeatCount: repeatCount});
            }
            // Max repeat in current position was exceeded and we need to move to next position.
            else {

                let newPositionIndicator = positionIndicator + 1;

                // Reset the repeat count and move to next position.
                if (newPositionIndicator <= positionEndTime) {
                    repeatCount = INITIAL_REPEAT_COUNT+1;
                    this.handleClickPosition(newPositionIndicator, repeatCount, true, {positionRepeatCount: repeatCount});
                }
                // Max position was exceeded we need to stop the playback.
                else {
                    repeatCount = positionRepeatCount;
                    newPositionIndicator = positionIndicator;
                    this.handleClickPosition(newPositionIndicator, repeatCount,true, {positionRepeatCount: repeatCount, playing: false,externalPause:null});

                }

            }
        }
    }

    handleNext(){
        const {positionIndicator} = this.state;
        if(positionIndicator < this.state.positionEndTime) {
            this.moveToPosition(positionIndicator + 1);
        }
    }

    handlePrevious(){
        const {positionIndicator,positionStartTime} = this.state;
        if(positionIndicator > positionStartTime) {
            this.moveToPosition(positionIndicator - 1);
        }
    }

    handleStopTap(){
        const {positionStartTime}=this.state;

            this.handleClickPosition(positionStartTime,INITIAL_REPEAT_COUNT,false,{playing: false,externalPause:null,drawLayers:false,positionRepeatCount:INITIAL_REPEAT_COUNT});

    }

    handleClickPosition(position,repeatCount,drawLayers,stateObject){

        const positionIndicator=this.calculatePositionIndicator(position,this.props.length);
        this.props.onChangePosition(positionIndicator,repeatCount,drawLayers, this.state.playing);

        this.setState({
            positionIndicator:positionIndicator,
            ...stateObject
        })
    }

    moveToPosition(positionIndicator){

        const {drawLayers}=this.state;
        const {blinksPerFrame}=this.props;
        let repeatCount=0;

        if(blinksPerFrame.hasOwnProperty(positionIndicator)){
            repeatCount=blinksPerFrame[positionIndicator];
        }
        this.handleClickPosition(positionIndicator,repeatCount,drawLayers,{});
    }

    handleDayClick(day, modifiers, e){
        e.stopPropagation();
        const { from, to } = this.state;
        if(from && to && day >= from && day <= to){
            this.handleResetClick();
            return;
        }
        if(this.isSelectingFirstDay(from, to, day)){
            this.setState({
                from: day,
                to: null,
                enteredTo: null,
            });
        } else {
            this.setState({
                to: day,
                enteredTo: day,
            });
            this.handleRequestTagHistoryByDate(day);
            this.handleCloseDateTooltip(e);
        }
    }

    handleRequestTagHistoryByDate(to){
        const {from} = this.state;

        let dateFrom = new Date(
            from.getFullYear(),
            from.getMonth(),
            from.getDate(),
            this.state.startDate.getHours(),
            this.state.startDate.getMinutes(),
            this.state.startDate.getSeconds());

        let dateTo = new Date(
            to.getFullYear(),
            to.getMonth(),
            to.getDate(),
            this.state.endDate.getHours(),
            this.state.endDate.getMinutes(),
            this.state.endDate.getSeconds());

        this.setState({
            startDate: dateFrom,
            endDate: dateTo
        });

        // if the user did not set any date/time before clear default value (last 24 hours) to  00:00:00-23:59:59
        if(this.props.userDefinedDateRange==null){
            dateFrom.setHours(0);
            dateFrom.setMinutes(0);
            dateFrom.setSeconds(0);
            dateTo.setHours(23);
            dateTo.setMinutes(59);
            dateTo.setSeconds(59);

        }

        this.props.setPlaybackDateRange(dateFrom, dateTo);
        this.props.getTagHistory(moment(dateFrom), moment(dateTo));
    }

    handleRequestTagHistoryByTime(init, end, e){

        let dateFrom = new Date(
            this.state.startDate.getFullYear(),
            this.state.startDate.getMonth(),
            this.state.startDate.getDate(),
            init.hour,
            init.min,
            init.sec);

        let dateTo = new Date(
            this.state.endDate.getFullYear(),
            this.state.endDate.getMonth(),
            this.state.endDate.getDate(),
            end.hour,
            end.min,
            end.sec
        );

        this.setState({
            startDate: dateFrom,
            endDate: dateTo
        });

        this.props.setPlaybackDateRange(dateFrom, dateTo);
        this.props.getTagHistory(moment(dateFrom), moment(dateTo));
        this.handleCloseTimeTooltip(e);
    }

    handleSetDate(startDate, endDate){
        this.props.setRangeDate(startDate, endDate);
    }

    handleDayMouseEnter(day){
        const {from, to} = this.state;
        if(!this.isSelectingFirstDay(from, to, day)){
            this.setState({
                enteredTo: day,
            });
        }
    }

    handleResetClick(){
        this.setState({
            from: null,
            to: null,
            enteredTo: null,
            externalPause:null,
            positionRepeatCount:INITIAL_REPEAT_COUNT
        })
    }

    handleYearMonthChange(month){
        this.setState({month});
    }

    handleChangeParent(dragName) {

        const {positionIndicator,positionStartTime,positionEndTime}=this.state;

        if(dragName===INDICATOR) {
            const {blinksPerFrame}=this.props;
            let repeatCount=0;

            if(blinksPerFrame.hasOwnProperty(positionIndicator)){
                repeatCount=blinksPerFrame[positionIndicator];
            }

            this.props.onChangePosition(positionIndicator, repeatCount, true, this.state.playing);
        }
        else if(dragName===START_TIME)
            this.props.onPositionStartChange(positionStartTime,positionIndicator);
        else if(dragName===END_TIME)
            this.props.onPositionEndChange(positionEndTime,positionIndicator);
    }

    handleMove(e) {
        const eventType = (e.touches !== undefined ? 'touch' : 'mouse');
        if (!this.state.drag) return;
        this.updateSliderValue(e, eventType);
        e.stopPropagation();
    }

    handleInteractionEnd() {
        this.setState({
            drag: false,
        });
        // end event
        this.removeEvents();
    }

    handleInteractionStart(name,e){
        this.props.onInteractionStart(name, e);
        const eventType = (e.touches !== undefined ? 'touch' : 'mouse');
        const leftMouseButton = 0;
        if ((eventType === 'mouse') && (e.button !== leftMouseButton)) return;

        this.setState({ drag: true,dragName:name,displayLabel: true });
        this.addEvents(eventType);
        e.preventDefault();
    }

    handleZoomTimeline() {

        const {positionStartTime, positionEndTime} = this.state;

        this.props.onChangeTimeLine(positionStartTime,positionEndTime);
    }

    handlePrevTimeline(){
        this.props.onPrevTimeLine();
    }

    handleOpenDateTooltip(){
        this.refs.dateTooltip.style.display = 'block';
        this.refs.timeTooltip.style.display = 'none';
        this.setState({
            dateTooltipOpen: !this.state.dateTooltipOpen,
            timeTooltipOpen: false
        });
        document.addEventListener('mousedown', this.handleOnClickOutsideDateTooltip);
    }

    handleCloseDateTooltip(e){
        e.stopPropagation();
        this.refs.dateTooltip.style.display = 'none';
        this.setState({
            dateTooltipOpen: !this.state.dateTooltipOpen
        });
        document.removeEventListener('mousedown', this.handleOnClickOutsideDateTooltip);
    }

    handleOnClickDateIconTooltip(e){
        const {dateTooltipOpen} = this.state;
        if(!dateTooltipOpen){
            this.handleOpenDateTooltip();
        } else {
            this.handleCloseDateTooltip(e)
        }
    }

    handleOnClickOutsideDateTooltip(e){
        if(this.refs.dateTooltipContainer && !this.refs.dateTooltipContainer.contains(e.target))
            this.handleCloseDateTooltip(e);
    }

    handleOpenTimeTooltip(){
        this.refs.timeTooltip.style.display = 'block';
        this.refs.dateTooltip.style.display = 'none';

        this.setState({
            timeTooltipOpen: !this.state.timeTooltipOpen,
            dateTooltipOpen: false
        });
    }

    handleCloseTimeTooltip(e){
        e.stopPropagation();
        this.refs.timeTooltip.style.display = 'none';

        this.setState({
            timeTooltipOpen: !this.state.timeTooltipOpen
        });
    }

    handleOnClickTimeIconTooltip(e){
        const {timeTooltipOpen} = this.state;
        if(!timeTooltipOpen){
            this.handleOpenTimeTooltip();
        } else {
            this.handleCloseTimeTooltip(e);
        }
    }

    handleOnClickOutsideTimeTooltip(e){
        if(this.refs.timeTooltipContainer && !this.refs.timeTooltipContainer.contains(e.target))
            this.handleCloseTimeTooltip(e);
    }

    handleSpeedReduce(){
        const newSpeed=this.state.speed-1;
        if(newSpeed > 0) {
            const newUpdateRate = this.calculateUpdateRate(newSpeed);
            this.updateTimer(newUpdateRate);
            this.setState({speed: newSpeed, updateRate: newUpdateRate});
        }
    }

    handleSpeedAdd(){
        const newSpeed=this.state.speed+1;
        if(newSpeed <= this.state.maxSpeed) {
            const newUpdateRate = this.calculateUpdateRate(newSpeed);
            this.updateTimer(newUpdateRate);
            this.setState({speed: newSpeed, updateRate: newUpdateRate});
        }
    }

    handleOpenSpeedDropdown(event){
        event.preventDefault();

        this.setState({
            open: !this.state.open,
            anchorEl: event.currentTarget,
        })
    }

    handleCloseSpeedDropdown(){
        this.setState({
            open:false,
        });
    }

    handleChangeTimelineSpeed(newSpeed){
        const newUpdateRate = this.calculateUpdateRate(newSpeed);
        this.updateTimer(newUpdateRate);
        this.setState({speed: newSpeed, updateRate: newUpdateRate});
    }


    render(){

        const {playing, from, enteredTo,positionStartTime,positionEndTime,positionIndicator,zoomInTimelineEnabled,zoomOutTimelineEnabled,use12Hours,drawLayers} = this.state;
        const {length,limitDatesByPosition,startSelectedDate, endSelectedDate, dataRequested, showPlaybackAdvice, dateRange, reportId} = this.props;

        const modifiers = { start: from, end: enteredTo };
        const disabledDays = { before: this.state.from };
        const selectedDays = [from, { from, to: enteredTo }];
        const timePickerRender = (<TimePickerComponent timeInit={dateRange && dateRange[reportId] && dateRange[reportId].fromDateRange} timeEnd={dateRange && dateRange[reportId] && dateRange[reportId].toDateRange} requestTagHistory={this.handleRequestTagHistoryByTime} use12Hours={use12Hours}/>);
        let left = 0;
        let divTick = [];
        if(length > 0) {
            for (let i = 0; i <= length; i++) {
                left = this.distance * i;
                divTick.push(<div key={"tick_slider_" + i} onClick={() => this.moveToPosition(i)} className={"tick-slider"} style={{
                    height: i === 0 || i === length ? "24px" : "10px",
                    left: left + "px"
                }}><div/></div>);
            }
        }

        const absolutePositionStartTime=(positionStartTime*this.distance)-TIME_CONTROL_WIDTH;
        const absolutePositionEndTime=(positionEndTime*this.distance)-TIME_CONTROL_WIDTH;
        const absolutePositionIndicator=(positionIndicator*this.distance);

        const selectedStart=(positionStartTime*this.distance);
        const selectedEnd=(positionEndTime*this.distance);
        const selectedEndWidth=selectedEnd-selectedStart;

        const absoluteStartTime=0;
        const absoluteEndTime=this.distance*length;

        //const showZoomTimeline=(positionStartTime>0)||positionEndTime<length;

        let startUserDate="";
        let endUserDate="";
        let startLimiterDate="";
        let endLimiterDate="";
        let startIndicatorDate="";
        let endIndicatorDate="";

        if(limitDatesByPosition) {
            startUserDate = (limitDatesByPosition[0])?this.formatDateTimeline(limitDatesByPosition[0].minDate):"";
            endUserDate = (limitDatesByPosition[length])?this.formatDateTimeline(limitDatesByPosition[length].maxDate):"";
            startLimiterDate = (limitDatesByPosition[positionStartTime])?this.formatDateTimeline(limitDatesByPosition[positionStartTime].minDate):"";
            endLimiterDate = (limitDatesByPosition[positionEndTime])?this.formatDateTimeline(limitDatesByPosition[positionEndTime].maxDate):"";
            startIndicatorDate = (limitDatesByPosition[positionIndicator])?this.formatDateTimeline(limitDatesByPosition[positionIndicator].minDate):"";
            endIndicatorDate = (limitDatesByPosition[positionIndicator])?this.formatDateTimeline(limitDatesByPosition[positionIndicator].maxDate):"";
        }

        let itemsDropdown = [];
        for(let i=1; i <= this.state.maxSpeed; i++ ){
            itemsDropdown = itemsDropdown.concat(<div key={"list-item-"+i} className={"speed-dropown-item"} onClick={() => {this.handleChangeTimelineSpeed(i); this.handleCloseSpeedDropdown()}}>{(this.state.constantRate-i*100)+100 + ""}</div>);
        }

        let classNameButtonGt = this.state.speed < this.state.maxSpeed ? "speed-button-gt" : "speed-button-gt speed-button-gt-disabled" ;
        let classNameButtonLt = this.state.speed > 1 ? "speed-button-lt" : "speed-button-lt speed-button-lt-disabled";

        let selectedStartAux = selectedStart - 6;
        let selectedEndAux = selectedEnd < (selectedStart+57)  ? (selectedEnd+38) : selectedEnd;

        let showTimeLineControls=true;
        if(limitDatesByPosition==null||Object.keys(limitDatesByPosition).length===0) {
            if(dataRequested) {
                startUserDate = this.formatDateTimeline(startSelectedDate);
                endUserDate = this.formatDateTimeline(endSelectedDate);
            }
            showTimeLineControls = false;
        }
        // else if(selectedTagIds==null||selectedTagIds.length==0)
        //     showTimeLineControls= false;



        return (
            <div className={"timeline-container no-select"}>
                <div className={"slider-container"} >
                    <div className={"slider-element"} ref={SLIDER_REF}>


                        <div className={"slider-current-timeline"} style={{left:selectedStart+"px",width:selectedEndWidth+"px"}}></div>
                        <div className={"slider-user-start-date"} style={{left:absoluteStartTime+"px"}}>{startUserDate}</div>
                        <div className={"slider-user-end-date"} style={{left:absoluteEndTime+"px"}}>{endUserDate}</div>
                        <div className={"slider-selected-start-date"} style={{left:selectedStartAux+"px"}}>{startLimiterDate}</div>
                        <div className={"slider-selected-end-date"} style={{left:selectedEndAux+"px"}}>{endLimiterDate}</div>
                        <div className={"slider-pointer-range"} style={{left:absolutePositionIndicator+"px"}}>{startIndicatorDate}<br/>{endIndicatorDate}</div>

                        {(showTimeLineControls)?<div
                            style={{left: absolutePositionStartTime+ "px"}}
                            className={"slider-point-icon-start"}
                            onMouseDown={this.handleInteractionStart.bind(this,START_TIME)}
                            onTouchStart={this.handleInteractionStart.bind(this,START_TIME)}
                        />:""}
                        {(showTimeLineControls)?<div
                            style={{left: absolutePositionEndTime + "px"}}
                            className={"slider-point-icon-end"}
                            onMouseDown={this.handleInteractionStart.bind(this,END_TIME)}
                            onTouchStart={this.handleInteractionStart.bind(this,END_TIME)}
                        />:""}
                        {(showTimeLineControls&&drawLayers===true)?<div
                            style={{left: absolutePositionIndicator+ "px"}}
                            className={"slider-icon" + (playing ? ' animate-flicker' : '')}
                            onMouseDown={this.handleInteractionStart.bind(this,INDICATOR)}
                            onTouchStart={this.handleInteractionStart.bind(this,INDICATOR)}
                        />:""}
                        {divTick}

                    </div>
                </div>

                <div className={"playback-container"}>
                    <div className={"datetime-container"}>
                        <div className={"icon-datetime"}>
                            <div className={"time-title"}>Time Range</div>
                            <div className={"time-icon-container"} ref={"timeTooltipContainer"} onClick={(e) => this.handleOnClickTimeIconTooltip(e)}>
                                <div className={"time-tooltip-aux"} ref={"timeTooltip"} onClick={(e) => {e.stopPropagation()}}>
                                    <div className={"time-tooltip-closer"} onClick={(e) => this.handleCloseTimeTooltip(e)}/>
                                    {timePickerRender}
                                </div>
                                <TimeIcon/>
                            </div>
                        </div>
                        <ClickAwayListener onClickAway={(e) => this.handleCloseDateTooltip(e)}>
                        <div  className={"icon-datetime"}>
                            <div className={"date-title"}>Date Range</div>
                            <div className={"date-icon-container"} ref={"dateTooltipContainer"} onClick={(e) => this.handleOnClickDateIconTooltip(e)}>
                                <div ref={"dateTooltip"} className={"date-tooltip-aux"} onClick={(e) => {e.stopPropagation()}}>
                                    <div className={"date-tooltip-closer"} onClick={(e) => this.handleCloseDateTooltip(e)}/>
                                    <DayPicker
                                        className={"Range"}
                                        fromMonth={fromMonth}
                                        toMonth={toMonth}
                                        month={this.state.month}
                                        selectedDays={selectedDays}
                                        disabledDays={disabledDays}
                                        modifiers={modifiers}
                                        onDayClick={this.handleDayClick}
                                        onDayMouseEnter={this.handleDayMouseEnter}
                                        captionElement={({date, localeUtils}) =>{
                                            return(
                                                <YearMonthForm
                                                    date={date}
                                                    localeUtils={localeUtils}
                                                    onChange={this.handleYearMonthChange}
                                                />
                                            )}}
                                    />
                                </div>
                                <DateIcon className={"date-svg"}/>
                            </div>
                        </div>
                        </ClickAwayListener>
                        
                        <div className={"speed-elements-container"}>

                            <div className={"speed-title"}>Speed (ms)</div>

                            <div className={classNameButtonGt} onClick={this.handleSpeedAdd}/>
                            <div className={"speed-label-value"}
                                    onClick={this.handleOpenSpeedDropdown}>{(this.state.constantRate-this.state.speed*100)+100 + ""}</div>
                                <Popper
                                    className={"speed-dropdown-container"}
                                    open={this.state.open}
                                    anchorEl={this.state.anchorEl}
                                    placement={"top"}
                                    // onRequestClose={this.handleCloseSpeedDropdown}
                                >
                                    <ClickAwayListener onClickAway={this.handleCloseSpeedDropdown}>
                                        <Paper>
                                            {itemsDropdown}
                                        </Paper>
                                    </ClickAwayListener>
                                </Popper>
                            <div className={classNameButtonLt} onClick={this.handleSpeedReduce}/>
                        </div>
                        
                        <div className={"slider-zoom-out"+((!zoomOutTimelineEnabled)?" slider-zoom-out-disabled":" ")} onClick={(zoomOutTimelineEnabled)?this.handlePrevTimeline:null}>
                            <div className={"slider-zoom-title"}>ZoomOut</div>
                            <TimerMinusIcon/>
                        </div>
                        <div className={"slider-zoom-in"+((!zoomInTimelineEnabled)?" slider-zoom-in-disabled":" ")} onClick={(zoomInTimelineEnabled)?this.handleZoomTimeline:null}>
                            <div className={"slider-zoom-title"}>ZoomIn</div>
                            <TimerPlusIcon/>
                        </div>
                    </div>

                    <div className={"playcontrols-container"}>
                        <CustomIconButton className={"control-button-container"} onClick={()=>this.handlePrevious()} icon={<PreviousIcon/>} description={"Prev"}/>
                        <CustomIconButton className={"control-button-container"} onClick={()=>this.handleNext()} icon={<NextIcon/>} description={"Next"}/>
                        <CustomIconButton className={"control-button-container"} onClick={this.handlePlayPauseTap} icon={playing ? <PauseIcon/> : <PlayIcon/>} description={playing ? "Pause" : "Play"} showPlaybackAdvice={showPlaybackAdvice}/>
                        <CustomIconButton className={"control-button-container"} onClick={this.handleStopTap} icon={<ResetIcon/>} description={"Reset"}/>
                    </div>

                </div>
            </div>
        );
    }
}

export default TimeLineControl;
