import React from 'react';
import PropTypes from 'prop-types';
import Promise from 'promise';

import StyledDialog, {SaveButton, CancelButton} from '../../util/Dialog';

import {Form,Field} from '../../util/DataEntryTabularForm';

import {ZONE_BUILDER_PROPERTY_TYPES} from '../Constants';
import Chip from '@material-ui/core/Chip';
export default class SinglePropertySchemaDialog extends React.Component {


    static get propTypes(){
        return {
            open: PropTypes.bool,
            onSave: PropTypes.func.isRequired,
            onClose: PropTypes.func.isRequired,
            data: PropTypes.object,
            title: PropTypes.string
        };
    }

    static get defaultProps(){
        return {
            open: false
        };
    }

    constructor(props){
        super(props);

        this.state = {
            type: (props.data && props.data.type) || ZONE_BUILDER_PROPERTY_TYPES.DROPDOWN,
            editPropertyIndex: null,
            propertyName: ""
        };

        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);

        this.handleSave = this.handleSave.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        this.setState({
            type: (nextProps.data && nextProps.data.type) || ZONE_BUILDER_PROPERTY_TYPES.DROPDOWN
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data && this.props.data) {
            this.setState({ ...this.state, propertyName: this.props.data.name || "" });
        }
    }

    render() {

        const {
            // actions, // eslint-disable-line
            title,
            ...other
        } = this.props;

        const actions = (
            <>
                <CancelButton onClick={this.handleClose} />
                <SaveButton onClick={this.handleSave} disabled={!!!this.state.propertyName} />
            </>
        );

        return (
            <StyledDialog
                title={title || "Edit Zone Property"}
                open={this.props.open}
                onClose={this.handleClose}
                maxWidth="xs"
                headerStyles={{ textAlign:'center' }}
                actions={actions}
                {...other}
                >
                {this.renderForm()}
            </StyledDialog>
        );
    }

    handleSave(){
        const { propertyName: name, type } = this.state;

        const property = {
            name,
            type
        };

        if(this.valuesInput){
            property.values = this.valuesInput.value;
        }

        Promise.resolve(this.props.onSave(property))
        .then(()=>{
            return this.props.onClose();
        });
    }

    handleClose ()  {
        this.props.onClose();
    }

    renderForm(){

        const defaultValues = this.props.data || {};

        const {DROPDOWN,TEXT} = ZONE_BUILDER_PROPERTY_TYPES;

        return (
            <Form fullWidth>
                <Field label={"Property Name"}>
                    <input 
                        type="text" 
                        value={this.state.propertyName} 
                        onChange={this.handleInputChange}
                    />
                </Field>

                <Field label={"Property Type"}>
                    <select value={this.state.type} onChange={this.handleTypeChange}>
                        <option value={DROPDOWN}>
                            Dropdown
                        </option>
                        <option value={TEXT}>
                            Text
                        </option>
                    </select>
                </Field>

                {this.state.type === DROPDOWN ? (
                    <Field label={"Acceptable Values"}>
                        <ChipInput defaultValue={defaultValues.values || []} ref={(ref)=>{this.valuesInput = ref;}}/>
                    </Field>
                ) : null}

            </Form>
        );
    }

    handleInputChange(evt) {
        this.setState({ 
            ...this.state, 
            propertyName: evt.target.value 
        })
    }

    handleTypeChange(evt){
        this.setState({
            type: evt.currentTarget.value.trim()
        });
    }
}

class ChipInput extends React.Component {

    static get propTypes(){
        return {
            defaultValue: PropTypes.array
        };
    }

    constructor(){
        super();

        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleRequestDelete = this.handleRequestDelete.bind(this);

        this.state = {
            chips:[],
            lastKey:0
        };
    }

    componentDidMount(){
        this.updateChipsFromDefaultValue(this.props.defaultValue);
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        this.updateChipsFromDefaultValue(nextProps.defaultValue);
    }

    updateChipsFromDefaultValue(defaultValue){
        const values = defaultValue || [];

        this.value = values;

        this.setState({
            chips: values.map((v,i)=>({key:i,label:v})),
            lastKey: values.length
        });
    }

    render(){
        return (
            <div>
                <input type="text" onKeyDown={this.handleKeyDown}/>
                <span style={{fontSize:'0.8em'}}> Hit 'enter' to separate.</span>
                <br />
                {this.state.chips.map(this.renderChip, this)}
            </div>
        );
    }

    renderChip(data) {
        return (
            <Chip
                key={data.key}
                onDelete={() => this.handleRequestDelete(data.key)}
                label={data.label}
                style={{
                    margin:'4px 4px 4px 0',
                    float:'left'
                }}
            />
        );
    }

    handleRequestDelete(key){
        const chipToDelete = this.state.chips.findIndex((chip) => chip.key === key);

        let chipData = this.state.chips;
        chipData.splice(chipToDelete, 1);

        this.setState({
            chips: chipData
        });
    }

    handleKeyDown(evt){
        if(evt.which === 13 && evt.currentTarget.value){
            this.addChip(evt.currentTarget.value);
            evt.currentTarget.value = "";
            return;
        }
    }

    addChip(text){
        let chips = this.state.chips.concat([{key:this.state.lastKey,label:text.trim()}]);
        this.setState({
            chips,
            lastKey: this.state.lastKey + 1
        });

        this.value = chips.map(chip=>chip.label); // to use with 'ref' (as if a normal <input>)
    }
}
