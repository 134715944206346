import Collection from 'ol/Collection';
import VectorSource from 'ol/source/Vector';
import VectorImageLayer from 'ol/layer/VectorImage';
import { ItemType } from '../../constants';

// const storeZoneKey = getOlStoreKey(ItemType.ZONE);

export function olTranslateZoneFactory(Base) {
    class OlTranslateZoneFactory extends Base {
        #helperTranslateSource = null;
        #helperTranslateLayer = null;
        #helperTranslateFeaturesCollection;
        
        startTranslateZones() {
            const extent = this.mapRef.getView().calculateExtent(this.mapRef.getSize());
            const minEdgeLength = Math.min(extent[2] - extent[0], extent[3] - extent[1]);
            this.MIN_DISTANCE = minEdgeLength * 0.02;
            
            if (this.#helperTranslateSource) {
                this.#helperTranslateSource.clear(true);
            }
            if (this.#helperTranslateLayer) {
                this.mapRef.removeLayer(this.#helperTranslateLayer);
            }
            this.#helperTranslateFeaturesCollection = new Collection();
            this.#helperTranslateSource = new VectorSource({
                useSpatialIndex: false,
                features: this.#helperTranslateFeaturesCollection,
            });
            this.#helperTranslateLayer = new VectorImageLayer({ source: this.#helperTranslateSource });
            
            this.mapRef.addLayer(this.#helperTranslateLayer);
        }
        
        translatingZones(event) {

            this.#helperTranslateFeaturesCollection.clear();

        }
        
        finishTranslateZones(event) {
            // console.time("finishTranslateZones")
            // pass the coordinates of the zones that were adjusted in the translation
            for (const feature of this.featuresCollection.getArray()) {
                const affectedFeature = this.#helperTranslateFeaturesCollection.getArray()
                    .find(f => this.getFeatureKey(f) === this.getFeatureKey(feature));
                if (affectedFeature) {
                    feature.setGeometry(affectedFeature.getGeometry());
                }
            }
            
            // cleaning
            for (const feature of event.features.getArray()) {
                feature.set('hidden', false);
            }
            this.#helperTranslateFeaturesCollection.clear();
            // console.time("features");
            const features=this.getFeaturesDataArrayByItemType(ItemType.ZONE);
            // console.timeEnd("features");
            this.observer?.executeEvent('onSaveZoneChanges', {
                zonesBeforeChanges: this.zones,
                zonesAfterChanges: features,
                takeASnapshot: true,
                event: 'finishTranslateZones',
                noSaveOnService:true

            });
            // console.timeEnd("finishTranslateZones")
        }
    }
    
    return OlTranslateZoneFactory;
}
