import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';

const getSuggestionValue = suggestion => suggestion;

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
  <span>
    {suggestion}
</span>
);

class ComboBox extends React.Component {
  constructor() {
    super();
    this.state = {
      value: '',
      values:[],
      suggestions: []
    };

    this.onChange = this.onChange.bind(this);
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
  }

  static get propTypes(){
      return {
          field: PropTypes.object,
          defaultValue: PropTypes.any,
          actions: PropTypes.object,
          onError: PropTypes.func.isRequired,
          otherValues: PropTypes.object,
          onChange: PropTypes.func
      };
  }

  requestValues(props){
      if(!props) return;
      props.actions.requestFieldValues(props.field.id,props.otherValues)
      .then(res=>{
          this.setState({
              values: res,
              value: this.getDefaultValue({props,list:res})
          });

          if(res.indexOf(this.value) === -1){
              this.value = res[0];
              props.onChange(props.field.name,res[0]);
          }
      })
      .catch(props.onError);

      this.hasRequestedValues = true;
  }

  getDefaultValue({props,list}){
      const {field,defaultValue} = props;

      switch(field.defaultSource){
          case "TOP_OF_LIST":
            return list[0];
          default:
              return defaultValue;
      }
  }

  componentDidMount(){
      this.requestValues(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps){
      for(var key in nextProps.otherValues){
          if(nextProps.otherValues[key] !== this.props.otherValues[key]){
              this.requestValues(nextProps);
              break;
          }
      }
  }

  onChange(event, { newValue }){
    this.setState({
      value: newValue
    });
    this.value = newValue;
  }

  onSuggestionsFetchRequested({value}){
    if(!this.hasRequestedValues){
        this.requestValues(this.props);
    }else{
        this.setState({
            suggestions: this.state.values.filter(v=>v.match(new RegExp(value,"i")))
        });
    }
  }

  onSuggestionsClearRequested(){
    //this.setState({
    //  suggestions: []
    //});
  }

  render() {
    const { value, suggestions } = this.state;

    const inputProps = {
      value: String(value || ""),
      onChange: this.onChange
    };

    //console.log(inputProps);

    // Finally, render it!
    return (
      <Autosuggest
        id={this.props.id}
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        shouldRenderSuggestions={()=>true}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
      />
    );
  }
}

export default ComboBox;
