import React from "react";
import {LOCAL_MAP} from "./olConstants";
import {useSelector} from "react-redux";
import WorldMapContainer from "./WorldMapContainer";
import LocalMapContainer from "./LocalMapContainer";

const OlMapContainer=()=>{

    const mapType=useSelector(state=>state.reportMap.mapType);


    // useEffect(()=>{
    //     // console.log("map configuration change",mapType);
    // },mapType);

    // Render self hosted world map.
    function renderWorldMap(){
        return <WorldMapContainer />
    }

    // TODO GET max zoom dynamically
    // Render self hosted (previous tiled) local map.
    function renderLocalMap(){
        return <LocalMapContainer/>
    }

    return <div style={{height:'100%'}}>
        {mapType===LOCAL_MAP?renderLocalMap():renderWorldMap()}
    </div>;
}

export default React.memo(OlMapContainer,((prevProps,nextProps) => {
    return (prevProps.coordinates===nextProps.coordinates);
}));

// export default WorldLocalMap;