import React from 'react';
import PropTypes from 'prop-types';
import Promise from 'promise';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';
import * as actions from '../../../actions';
import * as forms from '../../../constants/DataEntryForms';
import StyledDialog, {
    SaveButton
} from '../../util/Dialog';

import SinglePropertySchemaDialog from './SinglePropertySchemaDialog';

import {ZONE_BUILDER_PROPERTY_TYPES} from '../Constants';

class ClassicLink extends React.Component{

    static get propTypes(){
        return {
            children:PropTypes.node,
            styles:PropTypes.object
        };
    }

    render(){
        const {styles,children,...other} = this.props;

        return (
            <a style={{fontSize:'0.8em',textDecoration:'underline',cursor:'pointer',...styles}} {...other}>
                {children}
            </a>
        );
    }
}

class PropertiesSchemaDialog extends React.Component {


    static get propTypes(){
        return {
            open: PropTypes.bool,
            onSave: PropTypes.func.isRequired,
            onClose: PropTypes.func.isRequired,
            data: PropTypes.object,
            properties: PropTypes.object
        };
    }

    static get defaultProps(){
        return {
            open: false,
            properties: {}
        };
    }

    constructor(props){
        super(props);

        this.state = {
            properties:Object.keys(props.properties).sort().map(k=>props.properties[k]),
            editPropertyIndex: null
        };

        this.handleClose = this.handleClose.bind(this);

        this.handleAddPropertyTap = this.handleAddPropertyTap.bind(this);
        this.handleEditPropertyTap = this.handleEditPropertyTap.bind(this);
        this.handleRemovePropertyTap = this.handleRemovePropertyTap.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        const props = nextProps;
        this.setState({
            properties: Object.keys(props.properties).sort().map(k=>props.properties[k])
        });
    }

    render() {

        const {
            actions, // eslint-disable-line
            ...other
        } = this.props;

        return (
            <StyledDialog
                title="Zone Properties Schema"
                modal={false}
                open={this.props.open}
                onRequestClose={this.handleClose}
                style={{
                    textAlign:'center'
                }}
                contentStyle={{display:'inline-block',textAlign:'left',minWidth:'400px'}}
                bodyStyle={{overflowY:'auto'}}
                actions={
                    <SaveButton key="psc-save-action" onClick={this.handleClose} label="OK"/>
                }
                {...other}
                >
                {this.renderForm()}
                {this.renderSinglePropertyForm()}
            </StyledDialog>
        );
    }

    handleClose(){

        const schema = this.state.properties.reduce((schema,property)=>{
            schema[property.name] = property;
            return schema;
        },{});

        Promise.resolve(this.props.onSave(schema))
        .then(()=>{
            this.props.onClose(schema);
        })
        .catch(err=>{
            console.log(err); // eslint-disable-line no-console
            this.props.onClose(schema);
        });
    }

    renderSinglePropertyForm(){

        const {editPropertyIndex, properties} = this.state;

        const isAdding = editPropertyIndex === properties.length;

        return (
            <SinglePropertySchemaDialog
                title={isAdding ? "Add Property" : "Edit Property"}
                open={this.state.editPropertyIndex !== null}
                onSave={(property={})=>{
                    if(isAdding){
                        this.setState({
                            properties: properties.concat(property)
                        });
                    }else{
                        this.setState({
                            properties: properties.slice(0,editPropertyIndex).concat([property]).concat(properties.slice(editPropertyIndex+1))
                        });
                    }
                }}
                onClose={()=>{
                    this.setState({
                        editPropertyIndex: null
                    });
                }}
                data={isAdding ? {} : properties[editPropertyIndex] }
            />
        );
    }

    renderForm(){

        const {properties} = this.state;

        return (
            <ZonePropertyList
                properties={properties}
                onAddProperty={this.handleAddPropertyTap}
                onEditProperty={this.handleEditPropertyTap}
                onRemoveProperty={this.handleRemovePropertyTap}
            />
        );

    }

    handleAddPropertyTap(){
        this.setState({
            editPropertyIndex: this.state.properties.length
        });
    }



    handleEditPropertyTap(index){
        this.setState({
            editPropertyIndex: index
        });
    }

    handleRemovePropertyTap(index){
        this.setState({
            properties: this.state.properties.slice(0,index).concat(this.state.properties.slice(index+1))
        });
    }

}

class ZonePropertyList extends React.Component{
    static get propTypes(){
        return {
            properties: PropTypes.array,
            onAddProperty: PropTypes.func.isRequired,
            onEditProperty: PropTypes.func.isRequired,
            onRemoveProperty: PropTypes.func.isRequired
        };
    }

    render(){
        const {properties,onAddProperty} = this.props;

        return (

                <table style={{width:'100%'}}>
                    <tbody>
                        {properties.map((p,i)=>(
                            this.renderPropertyRow(p,i)
                        ))}
                        <tr>
                            <td colSpan="2" style={{textAlign:'right'}}>
                                <ClassicLink onClick={onAddProperty}>
                                    add property
                                </ClassicLink>
                            </td>
                        </tr>
                    </tbody>
                </table>
        );
    }

    renderPropertyRow(p,i,permanent=false){

        const {DROPDOWN,COLOR,FIXED} = ZONE_BUILDER_PROPERTY_TYPES;

        const {onEditProperty,onRemoveProperty} = this.props;

        const acceptableValuesItem = p.type === DROPDOWN ? (
            <li> <span style={{fontWeight:'bold'}}>Values</span>: {(p.values || []).join(", ")}</li>
        ) : null;

        let type = "Text Input";

        switch(p.type){
            case DROPDOWN:
                type = "Drop Down";
                break;
            case COLOR:
                type = "Color";
                break;
            case FIXED:
                type = "Fixed";
                break;
            default:
                type = "Text Input";
        }

        const actionsCell = permanent ? (<td></td>) : (
            <td style={{verticalAlign:'top',textAlign:'right'}}>
                <ClassicLink onClick={onEditProperty.bind(null,i)}>
                    edit
                </ClassicLink>
                <span> </span>
                <ClassicLink onClick={onRemoveProperty.bind(null,i)}>
                    remove
                </ClassicLink>
            </td>
        );

        return (
            <tr key={i}>
                <td>
                    <div>{p.name || "Unnamed Custom Property"}</div>
                    <ul style={{"fontSize":"0.9em","margin":"0em 0px 1em","padding":"0.5em 1em","listStyle":"none","borderLeft":"solid 0.25em #ddd"}}>
                        <li>
                            <span style={{fontWeight:'bold'}}>
                                {type}
                            </span>
                        </li>
                        {acceptableValuesItem}
                    </ul>

                </td>
                {actionsCell}
            </tr>
        );
    }
}


export default connect(
    state => ({
        open: state.dataEntry.open && state.dataEntry.activeForm === forms.ZONE_BUILDER_PROPERTIES_SCHEMA,
        properties: state.zoneBuilder.propertiesSchema
    }),

    dispatch => ({
        ...bindActionCreators({
            onSave: actions.updateZonePropertiesSchema,
            onClose: actions.closeDataEntry
        },dispatch)
    }),

    null,
    {
        forwardRef: true
    }

)(PropertiesSchemaDialog);

// -----------------------------------------------------------------------------

// Including the following function since I don't want to have to include the
// connect() function & form name in whatever uses it.  (reducing the dependencies somewhat)
//
// I figure it makes more sense for the module to include a way to open it...
// Unfortunately, it seems like it needs to be a Higher Order Component (to pass the
// function as a prop).
//
// Putting a pin in this.  May be worth using refs in the component using this dialog,
// so that one could call dialogRef.open() or something instead.
//
// What's a trickier concept: refs or HOC's ?

const mapStateToProps = state => ({
    siteId: state.reportMap.mapConfiguration?.siteId,
    zoneGroups: state.zoneBuilder.zoneGroups,
    zones: state.zoneBuilder.zones
});

export const opensPropSchemaDialog = (WrappedComponent) => connect(
    mapStateToProps,
    dispatch => {

        const boundActions = bindActionCreators({
            openDataEntry: actions.openDataEntry
        },dispatch);

        return {
            openPropSchemaDialog: boundActions.openDataEntry.bind(null,forms.ZONE_BUILDER_PROPERTIES_SCHEMA,{}),
            getSiteZoneGroups: (siteId) => dispatch(actions.getSiteZoneGroups(siteId))
        };
    }
)(WrappedComponent);
