import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const CarotIcon = (props) => (
    <SvgIcon {...props}>
            <path d="M7 10 l 5 5 5 -5 z" />
    </SvgIcon>
);

export default CarotIcon;
