export const SEARCH_SORT_FIELDS = {
    eventType: 'header.eventType.keyword',
    eventTrigger: 'header.eventTrigger.keyword',
    eventTime: 'header.eventTime',
    resourceId: 'resource.id.keyword',
    tagId: 'resource.tags.id.keyword',
    deviceId: 'header.eventTime.keyword',
    siteId: 'resource.zones.siteId.keyword',
    mapId: 'resource.location.properties.mapId'
};

export const RELATIVE_TIMES = [
    { value: '-seconds', label: 'Seconds ago' },
    { value: '-minutes', label: 'Minutes ago' },
    { value: '-hours', label: 'Hours ago' },
    { value: '-days', label: 'Days ago' },
    { value: '-weeks', label: 'Weeks ago' },
    { value: '-months', label: 'Months ago' },
    { value: '-years', label: 'Years ago' },
    { value: '+seconds', label: 'Seconds from now' },
    { value: '+minutes', label: 'Minutes from now' },
    { value: '+hours', label: 'Hours from now' },
    { value: '+days', label: 'Days from now' },
    { value: '+weeks', label: 'Weeks from now' },
    { value: '+months', label: 'Months from now' },
    { value: '+years', label: 'Years from now' }
];

export const TIME_TYPES = {
    ABSOLUTE: 0,
    RELATIVE: 1
};

export const NOW = 'now';
