/* jshint esversion: 6 */

import React from 'react';
import PropTypes from 'prop-types';
//openlayers
import OLFeature from 'ol/Feature';
//import Style from 'ol/style/Style';
//import Geometry from 'ol/geom/Geometry';
//import Source from 'ol/source/Source';


/**
 * A feature to be rendered in a <VectorLayer />
 */
class Feature extends React.Component{
    /**
     * @private
     */
    constructor(props){
        super(props);

        /**
         * @type {Object}
         * @property {Geometry} OpenLayers Geometry for the feature.
         * @property {Style} OpenLayers Style for the feature.
         * @property {any} data "Catch all".  Useful for passing information about the feature not directly needed by OpenLayers.
         * @property {Source} layerSource OpenLayers layer source passed by <VectorLayer />. **DO NOT SET**
         */
        this.props = props;

        /**
         * @private
         * Internal OpenLayers feature.
         */
        this.feature = null;

        if(this.props.layerSource){
            this.feature = new OLFeature({
                geometry: this.props.geometry,
                data: this.props.data  // kind of a "catch all"
            });

            this.feature.setStyle(this.props.style);
        }
    }

    static get propTypes(){
        return {
            style: PropTypes.any,
            data: PropTypes.any,
            layerSource: PropTypes.any,
            geometry: PropTypes.any
        };
    }

    componentDidUpdate(pp){
        if(pp.style !== this.props.style){
            this.feature.setStyle(this.props.style);
        }
        if(pp.geometry !== this.props.geometry){
            this.feature.setGeometry(this.props.geometry);
        }
        if(pp.data !== this.props.data){
            this.feature.set('data',this.props.data);
        }
    }

    /**
     * @private
     * Runs after mounting.
     */
    componentDidMount(){
        this.props.layerSource.addFeature(
            this.feature
        );
    }

    /**
     * @private
     * Runs before unmounting.
     */
    componentWillUnmount(){
        this.props.layerSource.removeFeature(this.feature);
    }

    /**
     * @private
     * Renders the UI to the Virtual DOM (which updates the actual DOM)
     */
    render(){
        return (
            <div />
        );
    }
}

export default Feature;
