import React from "react";

class LegendPlayback extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            coordinates: {},
            dragLegend: false,
            leftLegend: 0,
            bottomLegend: 0
        };

        this.handleLegendMove = this.handleLegendMove.bind(this);
        this.handleLegendInteractionEnd = this.handleLegendInteractionEnd.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps){

        if(this.props.showLegend!==nextProps.showLegend&&nextProps.showLegend===true) {

            const boundsToolbars=nextProps.getBounds();

            const left=50;
            const bottom=boundsToolbars.timeLineControlBounds.height;

            this.setState({
                leftLegend: left,
                bottomLegend: bottom,
            });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        let updateView = false;
        if (this.props.selectedTagIds!==nextProps.selectedTagIds
            ||this.props.currentPosition!==nextProps.currentPosition
            ||this.props.highlightTags!==nextProps.highlightTags
            ||this.props.tagBlinksByPosition!==nextProps.tagBlinksByPosition
            ||this.props.highlightColor!==nextProps.highlightColor
            ||this.props.blinkColor!==nextProps.blinkColor
            ||this.props.colorByTag!==nextProps.colorByTag
            ||this.props.handleSelectTag!==nextProps.handleSelectTag
            ||this.props.showLegend!==nextProps.showLegend
            ||this.state.leftLegend !== nextState.leftLegend
            ||this.state.bottomLegend !== nextState.bottomLegend
        ) {
            updateView = true;
        }

        return updateView;
    }

    handleMoveStart(e){

        const {miniMaps,getBounds}=this.props;
        const eventType = e.touches !== undefined ? 'touch' : 'mouse';

        const leftButton = 0;
        if (eventType === 'mouse' && e.button !== leftButton) return;

        const xCoords = (eventType !== 'touch' ? e.pageX : e.touches[0].pageX) - window.pageXOffset;
        const yCoords = (eventType !== 'touch' ? e.pageY : e.touches[0].pageY) - window.pageYOffset;

        const boundsLegend = this.getLegendInfo("playbackMapLegend").bounds;
        const boundsParent = this.getLegendInfo("playbackMapLegend").boundsParent;
        const boundsToolbars=getBounds();

        const miniMapsWidth=(miniMaps===true)?107:0;
        const maxLeft=boundsParent.width-boundsLegend.width-miniMapsWidth;
        const maxBottom=window.innerHeight-boundsLegend.height-boundsParent.top;

        this.setState({
            dragLegend: true,
            difLeft: xCoords - boundsLegend.left,
            difBottom: boundsLegend.bottom-yCoords,
            minLeft: 50,
            maxLeft:maxLeft,
            minBottom: boundsToolbars.timeLineControlBounds.height,
            maxBottom:maxBottom,
            parentTop:boundsParent.top,
            parentLeft:boundsParent.left,
        });

        this.addLegendEvents(eventType);
        e.preventDefault();
    }

    handleLegendMove(e){
        const eventType = e.touches !== undefined ? 'touch' : 'mouse';

        if(!this.state.dragLegend) return;

        this.updatePositionForLegend(e, eventType);
        e.stopPropagation();
    }

    handleLegendInteractionEnd(){

        //Remove Listener Events
        this.removeLegendEvents();

        this.setState({
            drag: false
        });


    }

    updatePositionForLegend(e, eventType){


        const {
            difBottom,
            difLeft,
            minLeft,
            maxLeft,
            minBottom,
            maxBottom,
            parentLeft,
        } = this.state;

        const xCoords = (eventType !== 'touch' ? e.pageX : e.touches[0].pageX) - window.pageXOffset;
        const yCoords = (eventType !== 'touch' ? e.pageY : e.touches[0].pageY) - window.pageYOffset;

        let bottom = window.innerHeight-yCoords -difBottom;
        let left = xCoords - parentLeft - difLeft;

        if(bottom<minBottom) {
            bottom = minBottom;
        } else if(bottom>maxBottom){
             bottom=maxBottom;
        }

        if(left<minLeft) {
            left = minLeft;
        } else if(left>maxLeft){
            left=maxLeft;
        }

        this.setState({
            leftLegend: left,
            bottomLegend: bottom
        });
    }

    getLegendInfo(ref){
        const legendRef = this.refs[ref];
        const legendParentRef = this.refs[ref].parentElement;

        const legendInfo = {
            bounds: legendRef.getBoundingClientRect(),
            boundsParent: legendParentRef.getBoundingClientRect(),
            width: legendRef.clientWidth,
            height: legendRef.clientHeight,
        };

        return legendInfo;
    }

    addLegendEvents(type){
        switch (type){
            case 'mouse':
                document.addEventListener('mousemove', this.handleLegendMove);
                document.addEventListener('mouseup', this.handleLegendInteractionEnd);
                break;

            case 'touch':

                document.addEventListener('touchmove', this.handleLegendMove);
                document.addEventListener('touchend', this.handleLegendInteractionEnd);
                break;

            default: break;
        }
    }

    removeLegendEvents(){
        document.removeEventListener('mousemove', this.handleLegendMove);
        document.removeEventListener('mouseup', this.handleLegendInteractionEnd);
        document.removeEventListener('touchmove', this.handleLegendMove);
        document.removeEventListener('touchend', this.handleLegendInteractionEnd);
    }

    renderLegend(){
        const {selectedTagIds,currentPosition,highlightTags,tagBlinksByPosition,highlightColor,blinkColor,colorByTag,handleSelectTag,elementIdLabel}=this.props;
        const {leftLegend, bottomLegend} = this.state;

        let legend=null;

        if(tagBlinksByPosition!=null) {
            const tagBlinksCurrentPosition = tagBlinksByPosition[currentPosition];

            const tagIds = selectedTagIds.map((tagId, index) => {

                const highlight = (highlightTags.indexOf(tagId) >= 0);

                let blinking = false;
                if (tagBlinksCurrentPosition != null && tagBlinksCurrentPosition.length > 0) {
                    blinking=(tagBlinksCurrentPosition.indexOf(tagId)>=0);
                }
                const tagColor = colorByTag[tagId];

                const newHighlightColor = (tagColor!=null&&highlight === true) ? highlightColor : "transparent";


                return <div key={index} onClick={() => handleSelectTag(tagId)} className={'playback-legend-item-container'}>

                    <div className={'playback-legend-blinking'}>

                        <div className={'playback-legend-tag-blinking'}>
                            {(blinking === true) ? <div>
                                <div className="playback-legend-tag-blinking-ring"style={{background: blinkColor}}></div>
                                <div className="playback-legend-tag-blinking-ring-centre"></div>
                            </div> : ""}
                        </div>
                    </div>
                    <div className={'playback-legend-tagId'}>{tagId}</div>
                    <div className={'playback-legend-tag-color'}>
                        <div className={'playback-legend-tag-highlight'} style={{background: newHighlightColor}}>
                            <div className={'playback-legend-tag-color-path'} style={{background: tagColor}}></div>
                        </div>
                    </div>

                </div>;
            });


            legend = <div ref={"playbackMapLegend"}
                style={{left: leftLegend+"px", bottom: bottomLegend+"px"}}
                className={'playback-map-legend no-select'}
                onMouseDown={this.handleMoveStart.bind(this)}
                onTouchStart={this.handleMoveStart.bind(this)}
            >
                <div className={'playback-legend-item-container'}>
                    <div className={'playback-legend-blinking'}></div>
                    <div className={'playback-legend-tagId'}><b>{elementIdLabel}</b></div>
                    <div className={'playback-legend-tag-color'}><b>Color</b></div>
                </div>
                {tagIds}
                <div className={'playback-legend-bar-container'}>
                    <div className={'playback-legend-bar-text'}><b>Blinking:</b></div>
                    <div className={'playback-legend-bar-color'}>
                        <div className="playback-legend-tag-blinking-ring"style={{background: blinkColor}}></div>
                        <div className="playback-legend-tag-blinking-ring-centre"></div>
                    </div>
                </div>
                <div className={'playback-legend-bar-container'}>
                    <div className={'playback-legend-bar-text'}><b>Highlight:</b></div>
                    <div className={'playback-legend-bar-highlight'}>
                        <div className="playback-legend-tag-blinking-square"style={{background: highlightColor}}></div>
                        <div className="playback-legend-tag-blinking-square-centre"></div>
                    </div>
                </div>
            </div>;
        }

        return legend;

    }

    render(){

        const {showLegend}=this.props;

        let legend=<div></div>;
        if(showLegend===true) {
            legend=this.renderLegend();
        }

        return legend;
    }

}
export default LegendPlayback;