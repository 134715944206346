import {
    // LOAD_ANTENNAS_MODEL_DATA,
    LOAD_MAP_PHASES,
    LOAD_MAP_PHASES_TYPES,
    LOAD_SITE_DESIGNER_DATA,
    OPEN_FORM_ADD_ANTENNAS,
    OPEN_FORM_ADD_ZONE,
    OPEN_FORM_EDIT_DEVICE,
    OPEN_FORM_EDIT_ZONE,
    SITE_DESIGNER_PATCH_PROPERTIES,
    UNLOAD_DEVICE_FORM,
    UNLOAD_FORM_ERRORS,
    UNLOAD_ZONE_FORM,
    UPDATE_ANTENNA_DIALOG_STATE,
    UPDATE_CURRENT_SELECTED_MAP,
    UPDATE_CURRENT_SITE_OBJECT,
    UPDATE_ERROR_DIALOG_STATE,
    UPDATE_FORM_SUCCESS,
    UPDATE_PREVENT_DELETE,
    UPDATE_SITE_DESIGNER_ERROR_FORM,
    UPDATE_STATUS_FEATURE,
    UPDATE_CONFIRM_CHILDREN_ZONES,
    UPDATE_LEVEL_SELECTED,
    UPDATE_SITE_SELECTED, UPDATE_MEASURE_UNIT,
} from '../constants/ActionTypes';
import {displayErrorFromAxios, LCSMapService, LocationConfigService} from './util';
import { validateFieldsPath } from './util/siteManagerValidations';
import { updateProcessing } from './reports';
import { getReportKeys } from '../hooks';
import { MapPhase, SiteDesignerElements } from '../views/sites/designer/constants';
import { getUrl } from '../utils';
import { clearEmptyObjects, jsonPathReverseSetValue } from './util/transform-data';

// export const loadAntennaModelsData = () => {
//     return async (dispatch) => {
//         try {
//             const response = await LocationConfigService.instance().get(`/antennaModels`);
//             if (response.status === 200) {
//                 dispatch({ type: LOAD_ANTENNAS_MODEL_DATA, antennaModels: response.data.results });
//             }
//         } catch (e) {
//             displayErrorFromAxios.bind(null, dispatch);
//         }
//     };
// };

export const SITE_DESIGNER_ENDPOINTS = {
    [MapPhase]: {
        GET_TYPES: {
            service: LCSMapService,
            method: 'GET',
            urlTemplate: () => '/mapPhases/types',
            getResultData: (result) => result.data?.results || [],
            fetchingMessage: 'Getting map phases types'
        },
        GET: {
            service: LCSMapService,
            method: 'GET',
            urlTemplate: ({ mapId }) => `/mapPhases?mapId=${mapId}`,
            getResultData: (result) => result.data?.results || [],
            fetchingMessage: 'Getting map phases'
        },
        SAVE: {
            service: LCSMapService,
            method: 'POST',
            urlTemplate: () => '/mapPhases',
            fetchingMessage: 'Adding map phase'
        }
    },
    // [SiteDesignerElements.DEVICE_TYPES]: {
    //     GET_ALL: {
    //         service: DeviceRegistryService,
    //         method: 'GET',
    //         urlTemplate: `/devices/specifications/device-type`,
    //         getResultData: (result) => result.data || [],
    //         dataType: 'deviceTypesData'
    //     }
    // },
    // [SiteDesignerElements.COMPONENT_TYPES]: {
    //     GET_ALL: {
    //         service: DeviceRegistryService,
    //         method: 'GET',
    //         urlTemplate: `/devices/specifications/component-type`,
    //         getResultData: (result) => result.data || [],
    //         dataType: 'componentTypesData'
    //     }
    // },
    [SiteDesignerElements.MEASURE_SYSTEM]: {
        GET_ALL: {
            service: LCSMapService,
            method: 'GET',
            urlTemplate: `/measureUnits`,
            getResultData: (result) => result.data?.results || [],
            dataType: 'measureUnits'
        }
    }
};

// MAP PHASES

export const postMapPhase = (mapId, mapPhaseData) => {
    return async (dispatch, getState) => {
        const { reportKey } = getReportKeys(getState());
        const { urlTemplate, service, method, fetchingMessage } = SITE_DESIGNER_ENDPOINTS[MapPhase].SAVE;
        dispatch(updateProcessing(true, reportKey, false, fetchingMessage));
        const url = getUrl({ urlTemplate: typeof urlTemplate === 'function' ? urlTemplate() : urlTemplate });
        try {
            await service.instance().request({ url, method, data: mapPhaseData });
        } catch (error) {
            displayErrorFromAxios(dispatch, error);
        }
        await dispatch(loadMapPhases(mapId));
        dispatch(updateProcessing(false, reportKey, false));
    };
};

export const loadMapPhases = (mapId) => {
    return async (dispatch, getState) => {
        const { reportKey } = getReportKeys(getState());
        const { urlTemplate, service, method, getResultData, fetchingMessage } = SITE_DESIGNER_ENDPOINTS[MapPhase].GET;
        dispatch(updateProcessing(true, reportKey, false, fetchingMessage));
        const url = getUrl({ urlTemplate: typeof urlTemplate === 'function' ? urlTemplate({ mapId: mapId }) : urlTemplate });
        try {
            const result = await service.instance().request({ url, method });
            dispatch({ type: LOAD_MAP_PHASES, mapPhases: getResultData(result) });
        } catch (error) {
            displayErrorFromAxios(dispatch, error);
        }
        dispatch(updateProcessing(false, reportKey));
    };
};

export const loadMapPhasesTypes = () => {
    return async (dispatch, getState) => {
        const { reportKey } = getReportKeys(getState());
        const { urlTemplate, service, method, getResultData, fetchingMessage } = SITE_DESIGNER_ENDPOINTS[MapPhase].GET_TYPES;
        dispatch(updateProcessing(true, reportKey, false, fetchingMessage));
        const url = getUrl({ urlTemplate: typeof urlTemplate === 'function' ? urlTemplate() : urlTemplate });
        try {
            const result = await service.instance().request({ url, method });
            dispatch({ type: LOAD_MAP_PHASES_TYPES, mapPhasesTypes: getResultData(result) });
        } catch (error) {
            displayErrorFromAxios(dispatch, error);
        }
        dispatch(updateProcessing(false, reportKey));
    };
};

export const openAntennaFormDialog = (formType, payload, deviceType) => ({
    type: OPEN_FORM_ADD_ANTENNAS,
    payload,
    formType,
    openDialog: true,
    deviceType
});

export const openEditFormDialog = (payload, formType, deviceType) => ({
    type: OPEN_FORM_EDIT_DEVICE,
    payload,
    formType,
    openDialog: true,
    deviceType
});

export const closeAntennaDialog = () => ({ type: UPDATE_ANTENNA_DIALOG_STATE, openDialog: false });

export const openZoneFormDialog = (payload, formType, zoneType) => ({
    type: OPEN_FORM_ADD_ZONE,
    payload,
    formType,
    openDialog: true,
    zoneType
});

export const openEditFormZoneDialog = (payload, formType, zoneType) => ({
    type: OPEN_FORM_EDIT_ZONE,
    payload: { ...payload[payload.zoneType.toLocaleLowerCase()], mapId: payload.mapId },
    formType,
    openDialog: true,
    zoneType
});

export const updateFieldsForms = (fieldForm, fields) => {
    return (dispatch, getState) => {
        // const form = getState().siteDesigner[fieldForm];
        dispatch(dispatchSiteDesignerProperties({ [fieldForm]: { ...fields } }));
    };
};

export const updateFieldsFormsNoData = (formField, paths, clearEmptyObj = true, detectChanges = true) => {
    return (dispatch, getState) => {
        const data = getState().siteDesigner[formField];
        let result = { [formField]: { ...data } };
        for (const [path, value, emptyValues = [null, undefined]] of paths) {
            result = jsonPathReverseSetValue(result, formField, path, value);
            if (clearEmptyObj && emptyValues.some(v => JSON.stringify(v) === JSON.stringify(value))) {
                result = clearEmptyObjects(result, formField, path, emptyValues);
            }
        }
        dispatch(updateFieldsForms(formField, { ...result[formField] }));
    };
};

export const openSiteErrorDialog = payload => ({ type: UPDATE_ERROR_DIALOG_STATE, payload });
export const closeSiteErrorDialog = () => ({ type: UPDATE_ERROR_DIALOG_STATE, payload: {} });
export const unloadDeviceForm = () => ({ type: UNLOAD_DEVICE_FORM });
export const unloadZoneForm = () => ({ type: UNLOAD_ZONE_FORM });
export const dispatchSiteDesignerProperties = (payload) => ({
    type: SITE_DESIGNER_PATCH_PROPERTIES,
    payload
});

export const validateFormSiteManager = (form, itemType, formKey, specificValuesValidation) => {
    return async (dispatch, getState) => {
        let formFieldErrors = { ...getState().siteDesigner.formFieldErrors } || {};
        let validateErrors = await validateFieldsPath(form, formFieldErrors, itemType, formKey, specificValuesValidation);
        let errorsArray = Object.keys(validateErrors).filter(error => validateErrors[error] !== '');
        let successSave = errorsArray.length === 0;
        dispatch(updateFormErrors(validateErrors));
        dispatch(updateFormSuccess(successSave));
        return { success: successSave };
    };
};

export const updateFormErrors = (payload) => ({
    type: UPDATE_SITE_DESIGNER_ERROR_FORM,
    payload
});

export const updateFormSuccess = (success) => ({ type: UPDATE_FORM_SUCCESS, success });

export const unloadFormErrors = () => ({ type: UNLOAD_FORM_ERRORS });
export const unloadSuccessFul = () => ({ type: UPDATE_FORM_SUCCESS, success: false });

//TODO this function is temporarily to get the siteId, currently is returning the complete Object
export const getSiteDesignerId = (mapId) => {
    return async (dispatch, getState) => {
        const maps = getState().sites.maps;
        const sites = getState().sites.sites;
        const map = maps.filter(itm => itm._id === mapId);
        const site = sites.filter(itm => itm._id === map[0].siteId);
        const siteDesigner = await getSiteFromSiteDesigner(site[0].name);
        dispatch(updateCurrentSite(siteDesigner));
    };
};

export const getSiteFromSiteDesigner = async (siteName) => {
    const sites = await LocationConfigService.instance().get('/sites');
    return sites.data.results.find(itm => itm.name === siteName) || [];
};

export const selectMapSD = (mapId) => {
    return async (dispatch) => {
        try {
            const response = await LocationConfigService.instance().get(`/maps/${mapId}`);
            if (response.status === 200) {
                dispatch({ type: UPDATE_CURRENT_SELECTED_MAP, map: response.data });
            }
        } catch (error) {
            displayErrorFromAxios(dispatch, error);
        }
    };
};

export const updateCurrentSite = (siteDesigner) => ({ type: UPDATE_CURRENT_SITE_OBJECT, site: siteDesigner });

export const closeConfirmDeleteDialog = () => ({
    type: UPDATE_PREVENT_DELETE,
    openConfirmDeleteDialog: false,
    deviceToDelete: {}
});

export const openConfirmDeleteDialog = (device) => ({
    type: UPDATE_PREVENT_DELETE,
    openConfirmDeleteDialog: true,
    deviceToDelete: device
});

export const openConfirmDeleteChildrenZones = (openDeleteChildrenZones, zonesToDelete, hasChildren) => ({type: UPDATE_CONFIRM_CHILDREN_ZONES, openDeleteChildrenZones, zonesToDelete, hasChildren});

export const updateStatusFeature = (feature) => {
    return async (dispatch, getState) => {
        dispatch(updateFeature(feature));
    };
};

export const updateFeature = (feature) => ({
    type: UPDATE_STATUS_FEATURE, feature
});

export const loadItemsByType = (type) => {
    return async (dispatch) => {
        const { urlTemplate, service, method, getResultData, dataType } = SITE_DESIGNER_ENDPOINTS[type].GET_ALL;
        const url = getUrl({ urlTemplate: typeof urlTemplate === 'function' ? urlTemplate() : urlTemplate });
        try {

            const result = await service.instance().request({ url, method });
            if (result.status === 200 && result.data) {
                dispatch(updateSiteDesignerElements(dataType, getResultData(result)));
            }
        } catch (error) {
            displayErrorFromAxios(dispatch, error);
        }
    };
};

export const updateSiteDesignerElements = (dataType, data) => ({
    type: LOAD_SITE_DESIGNER_DATA,
    dataType,
    data
});

export const loadLevelAndSiteSelected = (mapId) => {
    return async (dispatch) => {
        try{
            const response = await LocationConfigService.instance().get(`/maps/${mapId}`);
            if(response.status === 200 && response.data) {
                dispatch({type: UPDATE_LEVEL_SELECTED, levelSelected: response.data})
                const responseSites = await LocationConfigService.instance().get(`/sites/${response.data.siteId}`);
                if(responseSites.status === 200 && responseSites.data) {
                    dispatch({type: UPDATE_SITE_SELECTED, siteSelected: responseSites.data})
                }
            }
        } catch (error) {
            displayErrorFromAxios(dispatch, error);
        }
    }
}

export const getMeasureUnit = (measureId) => {
    return async (dispatch) => {
        try {
            const response = await LocationConfigService.instance().get(`/measureUnits/${measureId}`);
            if (response.status === 200 && response.data) {
                dispatch({type: UPDATE_MEASURE_UNIT, measureUnitSelected: response.data})
            }
        } catch (error) {
            displayErrorFromAxios(dispatch, error);
        }
    }
}
