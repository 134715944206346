import {CLOSE_ERROR_DIALOG, DISPLAY_ERROR_DIALOG, ERROR_DIALOG} from '../constants/ActionTypes';

const initialState = {
    open:false,
    message:"",
    details: "",
    title:""
};

export function errorDialog(state = initialState, action){
    switch(action.type){
        case DISPLAY_ERROR_DIALOG:
            return {
                open:true,
                title: action.title || "Error",
                message: action.message || "Unspecified error.",
                details: action.details,
            };
        case CLOSE_ERROR_DIALOG:
            return {
                open:false,
                message:""
            };
        case ERROR_DIALOG:
            return {
                open:true,
                message:action.message,
                details: action.details,
                title: action.title
            }
        default:
            return state;
    }


}
