import React, { useCallback } from 'react';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHandleError } from '../../useHandleError';
import {FORM_FIELD_REQUIRED, REQUIRED} from '../../../../../../constants';
import {I18n} from "react-redux-i18n";
const translate = (key) => I18n.t('common.' + key);
export const SelectInput = ({
                                properties: {
                                    name,
                                    label,
                                    idSelect = name,
                                    className,
                                    validations = [],
                                    errorPlacement = 'right',
                                    showErrorOnBlur = false,
                                    errorEl = 'label',
                                },
                                onError,
                                options: initialOptions,
                                defaultOption,
                                value,
                                onChange,
                                disabled,
                                forceDisplayError,
                                forceMessageError,
                                selectClassName,
                            }) => {
    let options = [];
    if (Array.isArray(initialOptions)) {
        options = initialOptions.map(option => ({
            value: option?.value !== undefined ? option.value : option,
            name: option?.name !== undefined ? option?.name : option,
            disabled: option?.disabled || false,
        }));
    }
    const optionSelected = options.find(option => (JSON.stringify(option?.value) === JSON.stringify(value))) || defaultOption || { value, name: value };
    const getError = useCallback((value) => {
        for (const validate of validations || []) {
            switch (validate.type) {
                case REQUIRED:
                    if (JSON.stringify(value) === JSON.stringify(defaultOption?.value)) {
                        return validate.errorMessage;
                    }
                    break;
                default:
            }
        }
        return '';
    }, [defaultOption?.value, validations]);
    
    const {
        errorMessage: _errorMessage,
        displayError,
        handleOnBlur,
    } = useHandleError({ getError, value, onError, forceDisplayError, showErrorOnBlur, validations, defaultValue: defaultOption?.value });
    const errorMessage = forceMessageError || _errorMessage;
    const handleChange = (newValue) => {
        let value = newValue;
        if (typeof options?.[0].value === 'number') {
            value = +newValue;
        }
        const option = options.find(option => JSON.stringify(option.value) === JSON.stringify(value)) || defaultOption;
        if (JSON.stringify(option?.value) !== JSON.stringify(optionSelected.value)) {
            onChange(option);
        }
    };
    
    if (!options.find(option => option.value === optionSelected.value) && optionSelected.value !== defaultOption?.value) {
        options.push(optionSelected);
    }
    const isRequired = !!validations?.find(validate => validate.type === REQUIRED || validate.type === FORM_FIELD_REQUIRED);
    
    const selectInput = (
        <div className={'form-select-input-container' + (className ? ' ' + className : '') + (disabled ? ' disabled' : '') + (displayError && errorMessage ? ' display-error' : ' no-display-error')}>
            <div className="form-text-input-label">
                {errorEl === 'label' ? (
                    <Tooltip
                        open={!!errorMessage && !!displayError}
                        arrow={true}
                        title={errorMessage}
                        placement={errorPlacement}
                        PopperProps={{ disablePortal: true }}
                    >
                        <span>{label} {isRequired && <span>*</span>}</span>
                    </Tooltip>
                ) : (
                    <span>{label} {isRequired && <span>*</span>}</span>
                )}
            </div>
            <div className="form-select-input">
                <div className="base-filter">
                    <div className="box-container">
                        <div className="input-container">
                            {errorEl === 'input' ? (
                                <Tooltip
                                    open={!!errorMessage && !!displayError}
                                    arrow={true}
                                    title={errorMessage}
                                    placement={errorPlacement}
                                    PopperProps={{ disablePortal: true }}
                                >
                                    <select
                                        className={selectClassName || ''}
                                        name={name}
                                        value={optionSelected.value}
                                        disabled={disabled}
                                        onChange={e => handleChange(e.target.value)}
                                        onBlur={handleOnBlur}
                                        id={idSelect}
                                    >
                                        {!!defaultOption && <option value={defaultOption.value} disabled={isRequired}>{defaultOption.name}</option>}
                                        {options.map((option, index) => (
                                            <option value={option.value} key={option.value + '___' + index} disabled={option.disabled}>{option.name}</option>
                                        ))}
                                    </select>
                                </Tooltip>
                            ) : (
                                <select
                                    name={name}
                                    value={optionSelected.value}
                                    disabled={disabled}
                                    onChange={e => handleChange(e.target.value)}
                                    onBlur={handleOnBlur}
                                    id={idSelect}
                                    className={selectClassName}
                                >
                                    {!!defaultOption && <option value={defaultOption.value} disabled={isRequired}>{defaultOption.name}</option>}
                                    {options.map((option, index) => (
                                        <option value={option.value} key={option.value + '___' + index}>{option.name}</option>
                                    ))}
                                </select>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    
    const useStyles = makeStyles({
        tooltip: {
            fontSize: (errorMessage.length < 24) ? '14px' : '12px',
            top: errorMessage.length < 24 ? '0px' : '-6px',
            color: '#000000',
            backgroundColor: 'var(--highlighted-error-color)',
            lineHeight: '15px',
        },
        arrow: {
            color: 'var(--highlighted-error-color)',
        },
    });
    
    const classes = useStyles();
    
    return errorEl === 'container' ? (
        <Tooltip
            open={!!errorMessage && !!displayError}
            arrow={true}
            title={translate(errorMessage)}
            placement={errorPlacement}
            PopperProps={{ disablePortal: true }}
            classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
        >
            {selectInput}
        </Tooltip>
    ) : selectInput;
};
