import React from 'react';
import PropTypes from 'prop-types';
//openlayers
import Point from 'ol/geom/Point';
import Feature from 'ol/Feature';

/**
 * A feature to be used in animation.
 */
class AnimatedFeature extends React.Component{
    /**
     * @private
     */
    static get propTypes(){
        return {
            vectorContext: PropTypes.any,
            elapsedTime: PropTypes.any,
            style: PropTypes.any,
            coordFn: PropTypes.any,
        };
    }


    /**
     * @private
     */
    constructor(props){
        super(props);

        /**
         * @type {Object}
         * @property {function} coordFn Should accept elapsed time, in milliseconds.
         * @property {VectorContext} vectorContext Set by <Animation />.  **DO NOT SET**
         * @property {number} elapsedTime In milliseconds. Set by <Animation />.  **DO NOT SET**
         */
        this.props = props;

        this.renderFeature = this.renderFeature.bind(this);
    }

    /**
     * @private
     * Runs after mounting.
     */
    componentDidMount(){
        this.renderFeature();
    }

    /**
     * @private
     * Runs after rendering.
     */
    componentDidUpdate(){
        this.renderFeature();
    }

    /**
     * @private
     *
     */
    renderFeature(){
        var vectorContext = this.props.vectorContext;
        var elapsedTime = this.props.elapsedTime;

        if(!vectorContext) return;

        var coord = (this.props.coordFn) ? this.props.coordFn(elapsedTime) : [0,0];

        var currentPoint = new Point(coord);

        var feature = new Feature(currentPoint);
        vectorContext.drawFeature(feature,this.props.style);
    }

    /**
     * @private
     * Renders the UI to the Virtual DOM (which updates the actual DOM)
     */
    render(){
        return (
            <div />
        );
    }
}

//exports.AnimatedFeature = AnimatedFeature;


/**
 * An animation environment.
 *
 *
 * Children of the <Animation /> component will be passed in the following properties:
 * - `vectorContext`: A vector context in which the feature can be rendered.
 * - `elapsedTime`: Current "elapsed time" of the animation. Use to determine feature position.
 */
class Animation extends React.Component{
    /**
     * @private
     */
    static get propTypes(){
        return {
            map: PropTypes.any,
            fps: PropTypes.any,
            t0: PropTypes.any,
            rate: PropTypes.any,
            children: PropTypes.any,
            initialTime: PropTypes.any
        };
    }

    /**
     * @private
     */
    constructor(props){
        super(props);

        /**
         * @type {Object}
         * @property {number} rate Rate of the animation's time compared to real time.
         * @property {number} fps Frames-per-second of the animation (how often the animation should update)
         * @property {number} initialTime Initial elapsed time of the animation. In milliseconds.
         * @property {number} t0 Initial elapsed time of the animation. In milliseconds.
         * @property {Map} map Internal OpenLayers.  Passed in by <Map />.  **DO NOT SET**
         */
        this.props = props;

        /**
         * @private
         * Internal OpenLayers map.
         */
        this.map = this.props.map;

        /**
         * @private
         */
        this.fps = this.props.fps || 30;

        let last_time = this.props.initialTime || this.props.t0 || 0;

        /**
         * @private
         */
        this.state = {
            elapsedTime: last_time,
            rate: this.props.rate || 1
        };

        // Methods
        this.startAnimation = this.startAnimation.bind(this);
        this.stopAnimation = this.stopAnimation.bind(this);
        this.moveFeature = this.moveFeature.bind(this);
    }

    /**
     * @private
     * Runs after mounting.
     */
    componentDidMount(){
        this.startAnimation();
    }

    /**
     * @private
     * Runs before unmounting.
     */
    componentWillUnmount(){
        this.stopAnimation();
    }

    /**
     * @private
     * Runs after props are changed / passed in.
     */
    UNSAFE_componentWillReceiveProps(np){
        if(np.rate !== this.props.rate){
            this.resetRealTime();
        }

        this.setState({
            rate: (np.rate === undefined) ? 1 : np.rate
        });
    }

    /**
     * @private
     */
    resetRealTime(){
        /**
         * @private
         * Offset to determine calculated elapsed time.
         */
        this.lastTime = this.state.elapsedTime;

        /**
         * @private
         * Reference point to determine real elapsed time.
         */
        this.now = new Date().getTime();
    }

    /**
     * @private
     */
    startAnimation(){
        this.resetRealTime();

        //speed = 2; // arbitrary
        this.map.on('postcompose',this.moveFeature);
        var x = ()=>{
            this.map.render();
        };
        clearInterval(this.animationInterval); // only one animation at a time.

        /**
         * @private
         * Internal interval handler.
         */
        this.animationInterval = setInterval(x,1000/this.fps);
        x();
    }

    /**
     * @private
     */
    stopAnimation(){
        this.map.un('postcompose',this.moveFeature);
    }

    /**
     * @private
     */
    moveFeature(event){
        let vectorContext = event.vectorContext;
        let frameState = event.frameState;
        var elapsedTime  = this.lastTime + (frameState.time - this.now)*this.state.rate;

        this.setState({
            vectorContext:vectorContext,
            elapsedTime:elapsedTime
        });

    }

    /**
     * @private
     * Renders the UI to the Virtual DOM (which updates the actual DOM)
     */
    render(){
        var children = React.Children.toArray(this.props.children);
        children = React.Children.map(children,(child) => React.cloneElement(child, {
            vectorContext: this.state.vectorContext,
            elapsedTime: this.state.elapsedTime
        }));
        return (
            <div>
                {children}
            </div>
        );
    }
}

export default Animation;
export {AnimatedFeature};
