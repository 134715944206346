import { Feature } from 'ol';
import { ItemType } from '../../constants';
import { Polygon } from 'ol/geom';
import { zoneStyle } from './styles';

export function zoneItemToFeature(item, store) {
    
    const feature = new Feature({
        id: item.id,
        itemType: ItemType.ZONE,
        label: item.name,
        geometry: new Polygon([ item.coords ]),
        // others
        isDraft: item.isDraft,
        notSaved: item.notSaved,
        childrenZones: item.childrenZones,
        color: item.color,
        name: item.name,
        parentId: item.parentId,
        type: item.type,
        isNew: item.isNew,
        isDeleted:item.isDeleted
    });
    feature.setId(item.id);
    const style = (feature) => {
        const isDeleted = feature.get('isDeleted');
        if (feature.get('hidden')||!!isDeleted) {
            return null;
        }
        const selected = this[store].isSelectedItem(this.getFeatureKey(feature));
        return zoneStyle(
            feature,
            undefined,
            selected,
            this.showLabels,
            this.measurementSystem,
        );
    };
    feature.setStyle(style);
    feature.set('featureStyle', style);
    
    return feature;
}
