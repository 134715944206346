import { useCallback, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import {
    cleanReportData,
    deleteSavedReportData,
    initialLoad,
    loadColumnsAndFilters,
    loadData, loadFilterColumnsSavedReportConfiguration,
    loadForm,
    loadReport,
    loadSavedReportConfiguration,
    patchReportProperties,
    redirectPageByUserPermission,
    saveSavedReportData,
    showErrorMessage,
    updateSavedReportData,
    updateSelectedSites,
    validateUserPermissions,
} from '../actions';
import {
    REPORT_ID_CALL_HISTORY,
    REPORT_ID_HARDWARE_DEVICES,
    REPORT_ID_RESOURCE_EVENT_HISTORY,
    REPORT_ID_RESOURCES,
    REPORT_ID_SEARCH,
    REPORT_ID_TAG_BLINK_HISTORY,
    REPORT_ID_TAGS,
    REPORT_ID_WHEREPORTS,
    REPORT_ID_ZONES,
    REPORT_KEY_DEFAULT,
} from '../constants';
import { useLocation } from 'react-router';
import { useUserState } from './user';
import { getPermissionsOfRoute,getReportData } from '../utils';
import { useHistory } from 'react-router-dom';

const selectReportState = createSelector(
    (state, reportKey) => state.report.scopes?.[reportKey],
    (state) => state.report.scopes?.[REPORT_KEY_DEFAULT],
    (reportProperties, reportGeneric = {}) => {
        return getReportData(reportProperties, reportGeneric);
    },
);

export const useReportState = () => {
    /*const { reportKey } = useReportKey();
    return useSelector(state => selectReportState(state, reportKey), shallowEqual);*/
    const { reportKey } = useReportKey();
    const { pathname } = useLocation();
    const { menu } = useUserState();
    const user = useSelector(state => selectReportState(state, reportKey), shallowEqual);

    return useMemo(() => {
        return {
            ...user,
            permissions: getPermissionsOfRoute(menu, pathname),
        };
    }, [user, menu, pathname]);

};

export const useReportDispatch = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { reportKey, isSavedReport, reportPath } = useReportKey();
    return {
        loadReport: (reportKey) => () => dispatch(loadReport(reportKey)),
        loadColumnsAndFilters: useCallback(() => {
            dispatch(loadColumnsAndFilters());
        }, [dispatch]),
        loadSavedReportConfiguration: useCallback(() => {
            dispatch(loadSavedReportConfiguration(reportKey));
        }, [dispatch, reportKey]),
        loadFilterColumnsSavedReportConfiguration: useCallback((restoreFilters,restoreColumns,restoreSort,isSavedReport,actionFilter) => {dispatch(loadFilterColumnsSavedReportConfiguration(reportKey,restoreFilters,restoreColumns,restoreSort,isSavedReport,actionFilter));}, [dispatch, reportKey]),
        patchReportProperties: useCallback((properties) => dispatch(patchReportProperties(reportKey, properties)), [dispatch, reportKey]),
        loadData: useCallback((background) => {
            dispatch(loadData(reportKey, background));
        }, [dispatch, reportKey]),
        initialLoad: useCallback(() => dispatch(initialLoad()), [dispatch]),
        saveSavedReport: useCallback((reportData, handleSaveClose) => dispatch(saveSavedReportData(reportData, history,handleSaveClose)), [dispatch, history]),
        updateSavedReport: useCallback((reportData, handleSaveClose) => dispatch(updateSavedReportData(reportData,handleSaveClose)), [dispatch]),
        deleteSavedReport: useCallback((reportId, redirect,changeReport,handleDeleteClose,forceDelete,openForceDeleteDialog) => dispatch(deleteSavedReportData(reportId, redirect, history,changeReport,handleDeleteClose,forceDelete,openForceDeleteDialog)), [dispatch, history]),
        cleanReportData: useCallback(() => dispatch(cleanReportData(reportKey, isSavedReport)), [dispatch, isSavedReport, reportKey]),
        loadForm: useCallback((requestData, refresh, focus) => dispatch(loadForm(reportKey, requestData, refresh, focus)), [dispatch, reportKey]),
        validateUserPermissions: useCallback(() => dispatch(validateUserPermissions(reportPath)), [dispatch, reportPath]),
        showErrorMessage: useCallback((message) => dispatch(showErrorMessage({ reportKey, message })), [dispatch, reportKey]),
        redirectPageByUserPermission: useCallback((redirect) => dispatch(redirectPageByUserPermission(reportKey, redirect, history)), [dispatch, history, reportKey]),
        updateSelectedSites: useCallback((selectedRows) => dispatch(updateSelectedSites(selectedRows)), [dispatch]),
    };
};

const toLabel = (text) => {
    return text.split('-').join(' ').replace(/\b\w/g, c => c.toUpperCase());
};

const genReportKey = ({ menu, param0, param1, param2 }) => {
    const paths = [param0];
    let reportLabel = toLabel(param0);
    if (param1) {
        paths.push(param1);
        reportLabel += ' / ' + toLabel(param1);
        if (param2) {
            paths.push(param2);
            reportLabel = toLabel(param1) + ' / ' + toLabel(param2);
        }
    }
    let reportKey = paths.join(':');
    let runReportId = ''; // OLD REPORTS
    let savedReportId = '';
    let reportId = paths.slice(1).join('.');
    const isSavedReport = param0 === 'saved';
    let isShared = false;
    switch (param1) {
        case 'alerts':
            if (param2 && param2 === 'notification-configurations') {
                // reportLabel = 'Alerts / Alert Notification Configuration';
                break;
            } else {
                // reportKey = REPORT_KEY_SYSTEM_ALERT_DATA;
                reportLabel = 'Alerts / Open System Alert';
                break;
            }
        case 'zones':
            reportLabel = 'Configuration / Zone Settings';
            break;
        case 'zone-groups':
            reportLabel = 'Configuration / Zone Group Settings';
            break;
        case 'system-alert':
            if (param2 && param2 === 'history') {
                // reportKey = REPORT_KEY_SYSTEM_ALERT_HISTORY;
                reportLabel = 'History / System Alert History';
                break;
            } else {
                // reportKey = REPORT_KEY_SYSTEM_ALERT_SETTINGS;
                reportLabel = 'Configuration / System Alert Settings';
                break;
            }
        case 'contacts':
            // reportKey = REPORT_KEY_CONTACTS;
            reportLabel = 'Configuration / Contacts';
            break;
        /*case 'infrastructure':
            if (param2 === 'hardware-devices') {
                reportKey = REPORT_KEY_HARDWARE_DEVICES;
                reportLabel = 'Infrastructure / Hardware Devices';
            } else if (param2 === 'whereports') {
                reportKey = REPORT_KEY_WHEREPORTS;
                reportLabel = 'Infrastructure / WherePorts';
            }
            break;*/
        case 'firmware':
            reportLabel = 'Infrastructure / FX Reader Firmware';
            break;
        default:
            let reportPath = param0 + '/' + param1;
            if (param2) {
                reportPath = reportPath + '/' + param2;
            }
            menu.forEach(menuItem => {
                menuItem.children.forEach(menuChild => {
                    if (menuChild.path === reportPath) {
                        runReportId = menuChild.id;
                        reportLabel = ((menuChild.category)?menuChild.category:param0) + ' / ' + menuChild.label;
                        isShared = menuChild.isShared && menuChild.category === 'Saved';
                        if (isSavedReport) {
                            reportId = menuChild.baseReportId;
                            runReportId = menuChild.runReportId;
                            savedReportId = param1;
                        }
                    }
                });
            });
    }
    
    let reportCanEdit = false;
    let reportPath = param0 + '/' + param1;
    if (param2) {
        reportPath = reportPath + '/' + param2;
    }
    menu.forEach(menuItem => {
        menuItem.children.forEach(menuChild => {
            if (menuChild.path === reportPath) {
                reportCanEdit = menuChild.canEdit;
            }
        });
    });
    
    const REPORTS_WITH_MAP = [REPORT_ID_RESOURCES, REPORT_ID_TAGS, REPORT_ID_CALL_HISTORY, REPORT_ID_RESOURCE_EVENT_HISTORY,
        REPORT_ID_TAG_BLINK_HISTORY, REPORT_ID_HARDWARE_DEVICES, REPORT_ID_WHEREPORTS, REPORT_ID_ZONES, REPORT_ID_SEARCH];
    
    const REPORTS_WITH_PLAYBACK = [REPORT_ID_RESOURCES, REPORT_ID_TAGS, REPORT_ID_RESOURCE_EVENT_HISTORY, REPORT_ID_TAG_BLINK_HISTORY];
    
    const reportWithMap = REPORTS_WITH_MAP.indexOf(reportId) > -1;
    const reportWithPlayback = REPORTS_WITH_PLAYBACK.indexOf(reportId) > -1;
    
    return { reportKey, reportId, reportLabel, isShared, reportWithMap, reportWithPlayback, runReportId/*OLD REPORTS*/, isSavedReport, savedReportId, reportCanEdit, reportPath };
};

export const getReportKeys = (state) => {
    const paths = state?.router?.location?.hash.split('/').slice(1);
    const menu = state?.user?.menu;
    const { reportKey, reportId, reportLabel, isShared, reportWithMap, reportWithPlayback, runReportId, isSavedReport, savedReportId, reportCanEdit, reportPath } = genReportKey({ menu, param0: paths[0], param1: paths[1], param2: paths[2] });
    return { reportKey, reportId, reportLabel, isShared, reportWithMap, reportWithPlayback, runReportId, isSavedReport, savedReportId, reportCanEdit, reportPath };
};

export const useReportKey = () => {
    const { pathname } = useLocation();
    const { menu } = useUserState(); // OLD REPORTS
    return useMemo(() => {
        const paths = pathname.split('/').slice(1);
        const { reportKey, reportLabel, isShared, reportId, reportWithMap, reportWithPlayback, runReportId, isSavedReport, savedReportId, reportCanEdit, reportPath } = genReportKey({ menu, param0: paths[0], param1: paths[1], param2: paths[2] });
        return { reportKey, reportId, reportLabel, isShared, reportWithMap, reportWithPlayback, runReportId, isSavedReport, savedReportId, reportCanEdit, reportPath };
    }, [menu, pathname]);
};
