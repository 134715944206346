import {
    ADD_RESOURCE_TYPES_ATTRIBUTES_FORM,
    ADD_RESOURCE_TYPES_ATTRIBUTES_LOOK_UP_VALUE_FORM,
    CLEAN_RESOURCE_TYPES_FORM,
    UPDATE_RESOURCE_TYPES_ATTRIBUTES_FORM,
    UPDATE_RESOURCE_TYPES_ATTRIBUTES_LOOK_UP_VALUE_FORM,
    UPDATE_RESOURCE_TYPES_DATA_FORM,
    UPDATE_STATIC_RESOURCE_TYPE,
    RESOURCE_TYPE_SELECTION,
    SET_SHOW_ATTRIBUTES,
    UPDATE_VALIDATED_FIELDS_RESOURCE_TYPES,
    DELETE_RESOURCE_TYPES_ATTRIBUTES_FORM,
    UPDATE_RESOURCE_TYPES_ALL_FIELDS,
    SORT_COLUMN,
    LOAD_RESOURCE_TYPES_FILTERS,
    SET_RESOURCE_TYPES_FILTERS,
    UPDATE_OPTION_LOOKUP,
    REMOVE_RESOURCE_TYPES_ATTRIBUTES_LOOK_UP_VALUE_FORM,
    SET_DEFAULT_FILTER_VALUES,
    UPDATE_RESOURCE_TYPES_FILTERS_FORM,
    RESOURCE_TYPE_FILTER_SELECTION,
    LOAD_CUSTOM_RESOURCE_TYPES_DATA,
    UPDATE_RESOURCE_TYPES_FIELD_ENABLED,
    LOAD_DEFAULT_RESOURCE_TYPE_FILTERS,
    UPDATE_RESOURCE_TYPES_ATTRIBUTES_LOOK_UP_VALUE_OPTION_FORM, CLEAR_RESOURCE_TYPES_ATTRIBUTES,
} from '../constants/ActionTypes';
import { RESOURCE_TYPE_FIELD, VALIDATE_RESOURCE_TYPE_FIELD } from '../utils/resource-type/ResourceTypeField';
import {
    VALIDATION_MESSAGE_REQUIRED,
    VALIDATION_TYPE_REQUIRED,
    VALIDATION_TYPE_REGEXP,
    VALIDATION_LOOKUP_MESSAGE_REQUIRED,
    VALIDATION_MESSAGE_REGULAR_CHARACTERS_ATTRIBUTE_REQUIRED,
    PURGE_IN_DAYS_FIELD,
    GROUP_PURGE_FIELD
} from '../constants/ResourceTypes';
import resourceTypesFilters from '../views/resourceTypes/filters/resourceTypeFilter';
import {
    CLEAN_DIALOG_ICON_RESOURCE_TYPE,
    MODE_DIALOG_ICON_RESOURCE_TYPE,
    MODE_DIALOG_RESOURCE_TYPE
} from "../views/resourceTypes/constants/ResourceTypes";
import {v4 as uuidv4} from 'uuid';
import {
    IdBsonType,
    IdFieldNames
} from "../views/resourceTypes/toolbar/accordionItemsResourceType/ResourceTypeAttributes";
import {TYPE_DATA} from "../actions";
const initialState = {
    //resourceTypesData: [],
    listCustomResourceTypes:[],
    resourceTypesFormField:RESOURCE_TYPE_FIELD,
    resourceTypesForm:{},
    resourceTypesFormAttributes:[],
    resourceTypesFormFilters:[],
    modeDialogResourceType:null,
    needsValidationFieldsResourceType:VALIDATE_RESOURCE_TYPE_FIELD,
    //successfulValidatedFieldsResourceTypes:false,
    statusModalResourceTypes:false,
    statusModalIcon:false,
    selectSectionModal:null,
    resourceTypesURL:{
        add:{
            method:'POST',
            url:'/v2/resourcetypes/'
        },
        update:{
            method:'PUT',
            url:'/v2/resourcetypes/'
        },
        patch:{
            method:'PATCH',
            url:'/v2/resourcetypes/{type}'
        },
        delete:{
            method:'DELETE',
            url:'/v2/resourcetypes/{type}'
        },
        getOne:{
            method:'GET',
            // url:'/v2/resourcetypes/id/{id}'
            url:'/v2/resourcetypes?name={name}'
        },
        getType:{
            method:'GET',
            url:'/v2/resourcetypes/{type}?fields=id,name,customProperties'
        },
        list:{
            method:'GET',
            //url:'/v2/resourcetypes?{filters}&page={page}&size={size}'
            url:'/v2/resourcetypes?page={page}&size={size}&sort={sort}{filters}'
        },
        defaultFilters:{
            method:'GET',
            url:'/v2/resourcetypes/defaultFilters'
        }

    },
    // resourceProfilesURL:{
    //     list:{
    //         method:'GET',
    //         url:'/v2/filterprofiles'
    //     }
    // },
    tagsURL:{
        list:{
            method:'GET',
            url:'/v2/tags?page=0&size=100&sort=label:1'
        }
    },
    iconsURL:{
        list:{
            method:'GET',
            url:'/v2/icons'
        },
        add:{
            method:'POST',
            url:'/v2/icons'
        },
        getIcon:{
            method:'GET',
            url:'/v2/icons/{id}'
        }
    },
    iconmapsURL:{
        list:{
            method:'GET',
            url:'/v2/iconmaps'
        }
    },
    // filterprofilesURL:{
    //     list:{
    //         method:'GET',
    //         url:'/v2/filterprofiles'
    //     }
    // },
    staticValues:{
        reserved:[
            {
                name:'Yes',
                value:true
            },
            {
                name:'No',
                value:false
            }
        ],
        category:[
            /*{
                name:'User Defined',
                value:'userDefined'
            },*/
            {
                name:'System',
                value:'system'
            }
        ],
        enabled:[
            {
                name:'Yes',
                value:true
            },
            {
                name:'No',
                value:false
            }
        ],
        tagProfile:[
            {
                name:'tag01',
                value:1
            },
        ],
        suspiciousBlink:[
            {
                name:'Yes',
                value:true
            },
            {
                name:'No',
                value:false
            }
        ],
        autoDissociation:[
            {
                name:'No',
                value:false
            },
            {
                name:'Yes',
                value:true
            }
        ],
        rfidPrintingEnabled:[
            {
                name:'Yes',
                value:true
            },
            {
                name:'No',
                value:false
            }
        ],
        groupUpdateMode:[
            {
                name:'None',
                value:'None'
            },
            {
                name:'Based on Parent',
                value:'Parent',
            },
            {
                name:'Based on Child',
                value:'Children',
            }
        ],
        required:[
            {
                name:'Yes',
                value:true
            },
            {
                name:'No',
                value:false
            }
        ],
        callType:[
            {
                name:'Disabled',
                value:'Disabled'
            },
            {
                name:'Button Press',
                value:'ButtonPress'
            },
            {
                name:'Switcher',
                value:'Switcher'
            }
        ],
        deact:[
            {
                name:'None',
                value:'None',
            },
            {
                name:'From Last Update',
                value:'FromLastUpdate',
            },
            {
                name:'From Dissociation',
                value:'FromDissociation',
            }
        ],
        buttonProcess:[
            {
                name:'Yes',
                value:true
            },
            {
                name:'No',
                value:false
            }
        ],
        resourceProfile:[
            {
                name:'1',
                value:'1'
            }
        ],
        displayNameMap:[
            {
                name:'NameMap0001',
                value:'NameMap0001'
            },
            {
                name:'NameMap0002',
                value:'NameMap0002'
            },
        ],
        parentTypeId:[
            {
                name:'None',
                value:'none'
            },
        ],
        lockMode:[
            {
                name:'noLock',
                value:'noLock'
            },
            {
                name:'xyLock',
                value:'xyLock'
            },
            {
                name:'zoneLock',
                value:'zoneLock'
            }
        ],
        [IdBsonType]:[
            {
                name:'String',
                value:'String'
            },
            {
                name:'Boolean',
                value:'Boolean'
            },
            {
                name:'Integer',
                value:'Integer'
            },
            {
                name:'Decimal',
                value:'Decimal'
            },
            /*{
                name:'Date',
                value:'Date'
            }*/
            {
                name:'Array',
                value:'Array'
            },
        ],
        keyFieldType:[
            {name:'Integer',value:'Integer'},
            {name:'String',value:'String'},
            {name:'Boolean',value:'Boolean'}
        ],
        searchable:[
            {
                name:'Yes',
                value:'true'
            },
            {
                name:'No',
                value:'false'
            }
        ],
        iconId:[
            {
                name:'Icon001',
                value:'Icon001'
            },
            {
                name:'Icon002',
                value:'Icon002'
            },
        ],
        iconMapId:[
            {
                name:'IconMap001',
                value:'IconMap001'
            },
            {
                name:'IconMap002',
                value:'IconMap002'
            },
        ],
        purge:[
            {
                name:'Never Purge',
                value:'None'
            },
            {
                name:'From Last Update',
                value:'FromLastUpdate'
            },
            {
                name:'From Dissociation',
                value:'FromDissociation'
            },
            {
                name:'Never Updated',
                value:'FromCreation'
            }
        ]
    },
    enableIcon:false,
    showAttributes: false,
    resourceTypeSelected : {},
    selectedRows: {},
    selectedResourceTypeId:"",
    filters: {},
    resourceTypesFilters: {...resourceTypesFilters.resourceTypes}
};
export function resourceTypes(state=initialState, action) {
    switch (action.type) {
        case MODE_DIALOG_RESOURCE_TYPE:{
            return {
                ...state,
                statusModalResourceTypes: action.statusModalResourceTypes,
                modeDialog: action.modeDialog,
                resourceTypeSelected: action.resourceTypeSelected,
            };
        }
        case MODE_DIALOG_ICON_RESOURCE_TYPE:{
            return {
                ...state,
                statusModalIcon: action.statusModalIcon,
                selectSectionModal:action.selectSectionModal
            };
        }
        case CLEAN_DIALOG_ICON_RESOURCE_TYPE:{
            return {
                ...state,
                resourceTypesForm:{...state.resourceTypesForm,...action.fields}
            };
        }
        case LOAD_CUSTOM_RESOURCE_TYPES_DATA:
            return {
                ...state,
                listCustomResourceTypes: action.listCustomResourceTypes
            };
        case ADD_RESOURCE_TYPES_ATTRIBUTES_FORM:{
            return {
                ...state,
                resourceTypesFormAttributes:[...state.resourceTypesFormAttributes,getNewResourceTypeAttribute()]
            };
        }
        case CLEAN_RESOURCE_TYPES_FORM:
            return {
                ...state,
                resourceTypesForm:{},
                resourceTypesFormAttributes:[],
                //needsValidationFieldsResourceType:VALIDATE_RESOURCE_TYPE_FIELD,
            };
        case DELETE_RESOURCE_TYPES_ATTRIBUTES_FORM:{
            let resourceTypesFormAttributesDelete = [...state.resourceTypesFormAttributes];
            resourceTypesFormAttributesDelete.splice(action.IndexDelete,1);
            return {
                ...state,
                resourceTypesFormAttributes:resourceTypesFormAttributesDelete
            };
        }
        case UPDATE_RESOURCE_TYPES_ALL_FIELDS:
            return {
                ...state,
                resourceTypesForm:action.resourceTypeEdit,
                resourceTypesFormPrev:action.resourceTypeEdit,
                modeDialogResourceType:action.modeDialogResourceType,
                resourceTypesFormAttributes:action.resourceTypesFormAttributes,
                resourceTypesFormAttributesPrev:JSON.parse(JSON.stringify(action.resourceTypesFormAttributes)),
                // resourceTypesFormAttributesPrev:action.resourceTypesFormAttributes,
                //successfulValidatedFieldsResourceTypes:action.successfulValidatedFieldsResourceTypes,
                resourceTypesFormFilters:action.resourceTypesFormFiltersEdit,
                resourceTypesFormFiltersPrev:action.resourceTypesFormFiltersEdit
            };
        case SET_DEFAULT_FILTER_VALUES:
            return {
                ...state,
                resourceTypesFormFilters:action.resourceTypesFormFiltersEdit
            };
        case UPDATE_RESOURCE_TYPES_DATA_FORM:
            return {
                ...state,
                resourceTypesForm:{...state.resourceTypesForm,[action.IdField]:action.newValue}
            };
        case UPDATE_RESOURCE_TYPES_ATTRIBUTES_FORM:
            let resourceTypesFormAttributesUpdate = [...state.resourceTypesFormAttributes];
            resourceTypesFormAttributesUpdate[action.Index][action.IdField] = action.newValue;
            return {
                ...state,
                resourceTypesFormAttributes:resourceTypesFormAttributesUpdate
            };

        case UPDATE_RESOURCE_TYPES_FIELD_ENABLED:
            let newResourceTypesFormField = {...state.resourceTypesFormField};
            if(action.IdField !== PURGE_IN_DAYS_FIELD){
                const index = newResourceTypesFormField[action.IdSection].fields.map(function(e) { return e.key; }).indexOf(action.IdField);
                if (index>=0)
                    newResourceTypesFormField[action.IdSection].fields[index].disabled = action.newValue;
            }else{
                const indexParent = newResourceTypesFormField[action.IdSection].fields.map(function(e) { return e.key; }).indexOf(GROUP_PURGE_FIELD);
                const indexChild = indexParent >= 0 && newResourceTypesFormField[action.IdSection].fields[indexParent].fields.map(function(e) { return e.key; }).indexOf(action.IdField);
                if(indexParent >=0 && indexChild >= 0){
                    newResourceTypesFormField[action.IdSection].fields[indexParent].fields[indexChild].disabled = action.newValue;
                }
            }
            return {
                ...state,
                resourceTypesFormField:newResourceTypesFormField
            };

        // case "UPDATE_RESOURCE_TYPES_ATTRIBUTES_FORM":
            //     let resourceTypesFormFiltersUpdate = [...state.resourceTypesFormFilters];
        //     resourceTypesFormFiltersUpdate[action.Index][action.IdField] = action.newValue;
        //     return {
        //         ...state,
        //         resourceTypesFormFilters:resourceTypesFormFiltersUpdate
        //     };
        case UPDATE_RESOURCE_TYPES_FILTERS_FORM:
            return {
                ...state,
                resourceTypesFormFilters:{...state.resourceTypesFormFilters,[action.IdField]:action.newValue}
            };
        case ADD_RESOURCE_TYPES_ATTRIBUTES_LOOK_UP_VALUE_FORM:
            let resourceTypesFormAttributesUpdateAdd = [...state.resourceTypesFormAttributes];
            const newLookUp  = addLookUpValue(true,action.value,true);
            resourceTypesFormAttributesUpdateAdd[action.IndexGroup][action.IdField].forEach(item=>{
                item.isSelect=false;
            })
            resourceTypesFormAttributesUpdateAdd[action.IndexGroup][action.IdField].push(newLookUp);
            return {
                ...state,
                resourceTypesFormAttributes:resourceTypesFormAttributesUpdateAdd
            };
        case CLEAR_RESOURCE_TYPES_ATTRIBUTES:{
            let resourceTypesFormAttributesUpdateClear = [...state.resourceTypesFormAttributes];
            resourceTypesFormAttributesUpdateClear[action.IndexGroup][IdFieldNames] = [];
            resourceTypesFormAttributesUpdateClear[action.IndexGroup].lookUpValue = [];
            return {
                ...state,
                resourceTypesFormAttributes:resourceTypesFormAttributesUpdateClear
            };
        }
        case UPDATE_RESOURCE_TYPES_ATTRIBUTES_LOOK_UP_VALUE_OPTION_FORM:
            let resourceTypesFormAttributesUpdateOption = [...state.resourceTypesFormAttributes];
            const updateIndexGroup = action.updateIndexGroup;
            resourceTypesFormAttributesUpdateOption[action.IndexGroup][action.IdField].forEach(item=>{
                item.isSelect = item.keyLookupOption === action.keyOptionSelect;
                item.isIndex = item.keyLookupOption === action.keyOptionSelect && updateIndexGroup;
                item.keyFieldType = action.keyFieldType?action.keyFieldType:TYPE_DATA.STRING;
            })
            return {
                ...state,
                resourceTypesFormAttributes:resourceTypesFormAttributesUpdateOption
            };

        case REMOVE_RESOURCE_TYPES_ATTRIBUTES_LOOK_UP_VALUE_FORM:
            let resourceTypesFormAttributes = [...state.resourceTypesFormAttributes];
            const keyLookupOption = action.keyLookupOption;
            //resourceTypesFormAttributesDelete[action.IndexGroup][action.IdField].splice(action.Index,1);
            const lookup = resourceTypesFormAttributes[action.IndexGroup][action.IdField].filter(item=>item?.keyLookupOption!==keyLookupOption);
            resourceTypesFormAttributes[action.IndexGroup][action.IdField]=[...lookup];
            return {
                ...state,
                resourceTypesFormAttributes:resourceTypesFormAttributes
            };
        case UPDATE_RESOURCE_TYPES_ATTRIBUTES_LOOK_UP_VALUE_FORM:
            let resourceTypesFormAttributesUpdateLoopUpValue = [...state.resourceTypesFormAttributes];
            resourceTypesFormAttributesUpdateLoopUpValue[action.IndexGroup][action.IdField][action.Index].value = action.newValue;
            return {
                ...state,
                resourceTypesFormAttributes:resourceTypesFormAttributesUpdateLoopUpValue
            };
        case UPDATE_STATIC_RESOURCE_TYPE:
            return {
                ...state,
                staticValues:{...state.staticValues,[action.fieldId]:action.data}
            };

        case RESOURCE_TYPE_SELECTION:
            return {
                ...state,
                selectedRows: {...action.selectedRows},
                selectedResourceTypeId: action.selectedResourceTypeId
            };
        case RESOURCE_TYPE_FILTER_SELECTION:
            return {
                ...state,
                selectedFilterRow: action.selectedRow
            };

        case SET_SHOW_ATTRIBUTES:
            return {
                ...state,
                showAttributes: action.showAttributes
            };
        case UPDATE_VALIDATED_FIELDS_RESOURCE_TYPES:
            return {
                ...state,
                needsValidationFieldsResourceType:action.needsValidationFieldsResourceType,
                resourceTypesForm:{...state.resourceTypesForm},
                //successfulValidatedFieldsResourceTypes:action.successfulValidatedFieldsResourceTypes
            };

        case SORT_COLUMN:
            return {
                ...state,
                sortIndex: action.sortIndex
            };
        case LOAD_RESOURCE_TYPES_FILTERS:
            return {
                ...state,
                filters: {...action.filters}
            };
        case SET_RESOURCE_TYPES_FILTERS:
            return {
                ...state,
                resourceTypesFilters:{...action.resourceTypesFilters}
            };
        case UPDATE_OPTION_LOOKUP:
            let resourceTypesFormAttribute = [...state.resourceTypesFormAttributes];
            resourceTypesFormAttribute[action.IndexGroup].enableLookUp =  action.stateLookup;
            return {
                ...state,
                resourceTypesFormAttributes:resourceTypesFormAttribute
            };
        case LOAD_DEFAULT_RESOURCE_TYPE_FILTERS:
            return {
                ...state,
                defaultFilters:action.filters
            };
        // case UPDATE_OPTION_ICON_UPLOAD:
        //     return {
        //         ...state,
        //         stateIconUpload: action.stateIconUpload
        //     };
        default:
            return state;
    }
}
const getNewResourceTypeAttribute = () =>{
    return {
        //lookUpValue:[addLookUpValue()],
        lookUpValue:[],
        [IdFieldNames]:[],
        enableLookUp:true,
        [IdBsonType]:TYPE_DATA.STRING,
        // required:false,
        searchable:false,
        newAttribute: true,
        validation:{
            nameTypeAttribute:{
                error: false,
                type: VALIDATION_TYPE_REGEXP,
                regexp: /^\w+$/,
                regFirstCharacter: /^[0-9]$/,
                message: VALIDATION_MESSAGE_REGULAR_CHARACTERS_ATTRIBUTE_REQUIRED,
                validateForms:[]
            },
            [IdBsonType]:{
                error: false,
                type: VALIDATION_TYPE_REQUIRED,
                message: VALIDATION_MESSAGE_REQUIRED,
                validateForms:[]
            },
            lookUpValue:{
                error: false,
                type: VALIDATION_TYPE_REQUIRED,
                message: VALIDATION_MESSAGE_REQUIRED,
                validateForms:[]
            }
        }
    };
}
const addLookUpValue = (isNewValue=true,value="",isSelect=false,isIndex=false) =>{
    const lookUp = {
        value:value,
        validation:{
            error: false,
            type: VALIDATION_TYPE_REQUIRED,
            message: VALIDATION_LOOKUP_MESSAGE_REQUIRED,
        },
        isNewValue:isNewValue,
        isSelect:isSelect,
        isIndex:isIndex,
        keyFieldType:TYPE_DATA.STRING//Default value
    };

    //if(isNewValue){
        //lookUp["keyLookupOption"] = uuidv4();
    lookUp["keyLookupOption"] = uuidv4();
    
    //}
    return lookUp;
}
