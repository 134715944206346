import React from 'react';

const MultiSelectIcon = ({width=512, height=512}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 64 64" width={width}     height={height}>
            <polyline fill="none" stroke="#010101" strokeMiterlimit="10" strokeWidth="3" points="1 41 5 45 15 35"/>
            <polyline fill="none" stroke="#010101" strokeMiterlimit="10" strokeWidth="3" points="1 17 5 21 15 11"/>
            <line x1="24" x2="64" y1="17" y2="17" fill="none" stroke="#010101" strokeMiterlimit="10" strokeWidth="4"/>
            <line x1="24" x2="64" y1="29" y2="29" fill="none" stroke="#010101" strokeMiterlimit="10" strokeWidth="4"/>
            <line x1="24" x2="64" y1="41" y2="41" fill="none" stroke="#010101" strokeMiterlimit="10" strokeWidth="4"/>
            <line x1="24" x2="64" y1="53" y2="53" fill="none" stroke="#010101" strokeMiterlimit="10" strokeWidth="4"/>
        </svg>
    )
};

export default MultiSelectIcon;