import { useSelector } from "react-redux";
import { MULTI_INTERACTIONS } from "../Constants";
import { getArea } from "ol/sphere";

export const ABSOLUTE_FIT = {
    position: 'absolute',
    top:0,
    bottom:0,
    left:0,
    right:0
};

export const POLYGON_VERTICES = 4;

export const filterFromProperties = (zones,schema,filter) => {
    if(!filter){
        return zones;
    }

    return zones.filter(zone=>{

        for(let prop in schema){

            if(!filter[prop]) continue;

            const zoneProp = zone.data.properties && zone.data.properties[prop];

            if(schema[prop].type === "DROPDOWN"){
                if(zoneProp !== filter[prop]){
                    return false;
                }
                continue;
            }

            if(schema[prop].type === "TEXT"){
                if(zoneProp.toUpperCase().indexOf(filter[prop].toUpperCase()) === -1){
                    return false;
                }
            }
        }

        return true;
    });
};

// Add zones to queue if the Shift Key on the keyboard is clicked
export const setZonesWithShiftKey = (zoneId, selectedZoneIds, onSelectZones) => {
    const isShiftPressed = window.event.shiftKey;
    const selectedZones = isShiftPressed ? [...selectedZoneIds] : [];
    if (!selectedZones.includes(zoneId)) {
        selectedZones.push(zoneId);
        onSelectZones(selectedZones);
    }
}

export const useZoneBuilderState = mapId => {
    const zones = useSelector(state => state.zoneBuilder.zones) || {};
    const multinteractions = useSelector(state => state.zoneBuilder.multinteractions);
    const activeFilter = useSelector(state => state.zoneBuilder.activeFilter);
    const propertiesSchema = useSelector(state => state.zoneBuilder.propertiesSchema);

    const zonesArr = Object.keys(zones).map(zid => zones[zid]);
    if (mapId && zonesArr.length) {
        const showAutoZones = multinteractions[MULTI_INTERACTIONS.AUTOZONES];
        return filterFromProperties(
            zonesArr.filter(z => filterZones(z, mapId, showAutoZones)),
            propertiesSchema,
            activeFilter
        );
    }
    return zonesArr;
}

/**
 * Should be displayed or not. Used with filter method commonly
 * @param {Zone} zone defined zone with it's properties, check ZoneModel.js file
 * @param {number | string} map mapId for comparision with the zone object
 * @param {boolean} showAutoZones should return autozones too
 * @returns {boolean}
 */
export const filterZones = (zone, map, showAutoZones) => {
    if (!zone.data.shape) {
        return false;
    }
    const mapZones = zone.isOnMap(map); // boolean
    return showAutoZones ? mapZones : mapZones && zone.data.priority !== -1;
}

/**
 * Groups the zones in an object with zone group names as keys.
 * @param {Zone[]} zones defined zone with it's properties, check ZoneModel.js file
 * @param {number | string} effectiveMapId mapId for comparision with the zone object
 */
export const getZonesInZoneGroups = (zones, effectiveMapId) => {
    const zoneGroups = {};
    zones.filter(z => z.isOnMap(effectiveMapId)).forEach(zone => {
        const { groupName } = zone.data;
        const arr = zoneGroups[groupName] || [];
        arr.push(zone);
        zoneGroups[groupName] = arr;
    });
    return zoneGroups;
}

export const getZoneGroupsInSelectedZones = (selectedZones) => {
    let zoneGroups = [];
    for(let zone of selectedZones){
        if(!zone){
            continue;
        }
        if(zoneGroups.indexOf(zone.data?.groupName) === -1){
            zoneGroups.push(zone.data.groupName);
        }
    }
    return zoneGroups;
}

/**
 * Checks if all zones of a group are selected.
 * @param {Array<Zone>} zones array of zone with it's properties, check ZoneModel.js file
 * @param {Array<string>} zoneIds array of selected zoneIds
 * @returns {boolean}
 */
 export const checkIfGroupIsSelected = (zones, zoneIds) => {
    // return zones.length === zoneIds.length && zones.every(({ data }) => zoneIds?.includes(data._key));
    const selectedObject = {
        allZoneGroup: zones.length === zoneIds.length && zones.every(({ data }) => zoneIds?.includes(data._key)),
        someZones: zones.some(zone => zoneIds?.includes(zone.data?._key))        
    };
    return selectedObject;
}

// When a zone is created, this function verifies which should be its zone group name
export const getNewZoneGroupName = ({ mapId, zones, selectedZoneIds }) => {
    const groupedZones = getZonesInZoneGroups(zones, mapId);
    let groupName = "";
    
    for(let [zoneGroup, zones] of Object.entries(groupedZones)){
        if(zones.some(zone => selectedZoneIds?.includes(zone.data?._key))){
            groupName = zoneGroup;
            return groupName;
        }
    }
    
    /*
    Object.entries(groupedZones).some(([zoneGroup, zones]) => {
        if (checkIfGroupIsSelected(zones, selectedZoneIds).allZoneGroup) {
            groupName = zoneGroup;
            return true;
        }
        return false;
    });
    */

    return groupName;
}

export const getPolygonArea = polygon => {
    const area = getArea(polygon);
    return Math.round(area);
}

/**
 * Checks if the grid is drawn from rtl or ltr
 * @param {Array<number[]>} geometry coordinates from the area drawn
 * @param {Array<number>} gridMatrix matrix dimensions [rows, cols]
 * @returns {Array<number>} reverse the matrix on rtl drawing
 */
export const getGridMatrixOrdered = (geometry, gridMatrix) => {
    const [p1, p2] = geometry;
    if (p1[0] > p2[0]) {
        const [numRows, numCols] = gridMatrix;
        return [numCols, numRows];
    }
    return gridMatrix;
}

/* Math Methods to Draw Rectangle on Certain Angle */
export const getDotProduct = (p1, p2) => p1[0] * p2[0] + p1[1] * p2[1];
export const getCrossProduct = (p1, p2) => p1[0] * p2[1] - p1[1] * p2[0];
export const getVectorDistance = ([dx, dy]) => Math.sqrt(dx * dx + dy * dy);
export const getDiffBetweenTwoPoints = (p1, p2) => [p2[0] - p1[0], p2[1] - p1[1]];

export const getOrientation = (p1, p2, p3) => {
    const long = getDiffBetweenTwoPoints(p1, p2);
    const lang = getDiffBetweenTwoPoints(p1, p3);
    return Math.sign(getCrossProduct(long, lang));
}

export const getPointsAngle = (p1, p2) => {
    const [deltaX, deltaY] = [p2[0] - p1[0], p2[1] - p1[1]];
    const angle = Math.atan2(deltaY, deltaX);
    const degrees = angle * (180 / Math.PI);
    return {
        angle,
        degrees: degrees < 0 ? 360 + degrees : degrees,
    };
}
