import {
    SET_ZONE_EXPIRATION,
    OPEN_DIALOG_ZONE_EXPIRATION,
    ZONE_EXPIRATION_DIALOG_MODE
} from "../constants/ActionTypes";

import {VALIDATION_TYPE_REQUIRED, VALIDATION_MESSAGE_REQUIRED} from '../constants/Direction';

const initialState = {
    zoneExpiration: null,
    openDialogZoneExpiration: false,
    zoneExpirationDialogMode: '',
    needValidationFields: {
        'resourceType': {
            error: false,
            type: VALIDATION_TYPE_REQUIRED,
            message: VALIDATION_MESSAGE_REQUIRED,
            label: 'Title'
        },
        'expiringSeconds': {
            error: false,
            type: VALIDATION_TYPE_REQUIRED,
            message: VALIDATION_MESSAGE_REQUIRED,
            label: 'Description'
        }
    }
};

const directionReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ZONE_EXPIRATION:
            return { ...state, zoneExpiration: action.zoneExpiration };
        case OPEN_DIALOG_ZONE_EXPIRATION:
            return { ...state, openDialogZoneExpiration: action.openDialogZoneExpiration };
        case ZONE_EXPIRATION_DIALOG_MODE:
            return { ...state, zoneExpirationDialogMode: action.zoneExpirationDialogMode };
        default:
            return state;
    }
}

export default directionReducer;