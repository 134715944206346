import React from 'react';

import { useI18n, useOnePlatformFiltersDispatch } from '../../../../../hooks';
import BaseFilter from './BaseFilter/BaseFilter';

const SEG_ON_DAY = 60 * 60 * 24;
const SEG_ON_HOUR = 60 * 60;
const SEG_ON_MINUTE = 60;

export const convertSegTo___D__H__M__S = (seg = '0') => {
    if (isNaN(+seg)) {
        return '000d 00h 00m 00s';
    }
    const days = Math.floor(+seg / (SEG_ON_DAY));
    let remaining = +seg % SEG_ON_DAY;
    const hours = Math.floor(remaining / (SEG_ON_HOUR));
    remaining = remaining % SEG_ON_HOUR;
    const minutes = Math.floor(remaining / SEG_ON_MINUTE);
    const seconds = remaining % SEG_ON_MINUTE;
    return `${(days + '').padStart(3, '0')}d ${(hours + '').padStart(2, '0')}h ${(minutes + '').padStart(2, '0')}m ${(seconds + '').padStart(2, '0')}s`;
};

const convert___D__H__M__SToSeg = (pattern) => {
    if (typeof pattern !== 'string') {
        return '';
    }
    const days = +pattern.substr(0, 3);
    const hours = +pattern.substr(5, 2);
    const minutes = +pattern.substr(9, 2);
    const seconds = +pattern.substr(13, 2);
    if (isNaN(days) || isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
        return '';
    }
    const value = (days * SEG_ON_DAY + hours * SEG_ON_HOUR + minutes * SEG_ON_MINUTE + seconds) + '';
    if (value === '0') {
        return '';
    }
    return value;
};

const InequalityFilter = ({ reportKey, filter }) => {
    
    const { updateFilter } = useOnePlatformFiltersDispatch(reportKey);
    const { translate } = useI18n('common.filterTable');
    
    const isOptionAll = ({ value }) => !value || value === '%' || value === '' || value === 'All' || +value === 0 || value === '000d 00h 00m 00s';
    const isValueAll = value => !value || value === '%' || value === '' || value === 'All' || +value === 0 || value === '000d 00h 00m 00s';
    
    return (
        <div className="filter inequality-filter" key={filter.key}>
            <BaseFilter
                properties={{
                    title: translate(filter.displayName),
                    withAll: true,
                    multiSelect: false,
                    separatorCharacter: filter.separatorCharacter || ',',
                    allowedCharacters: filter.allowedCharacters || '0123456789dhms ',
                    isValueAll: isOptionAll,
                    inputCaption: filter.inputCaption,
                }}
                selectedValues={isValueAll(filter.value) ? [] : [{ name: convertSegTo___D__H__M__S(filter.value), value: convertSegTo___D__H__M__S(filter.value) }]}
                recentSearches={(filter.recentSearches || []).map(value => isValueAll(value) ? [] : [{ name: convertSegTo___D__H__M__S(value), value: convertSegTo___D__H__M__S(value) }])}
                suggestions={(filter.options || []).map(seg => ({ name: convertSegTo___D__H__M__S(seg), value: convertSegTo___D__H__M__S(seg) }))}
                onChangeSelectValues={(values, recentSearches) => {
                    const value = values[0]?.value || '';
                    updateFilter({
                        ...filter,
                        value: convert___D__H__M__SToSeg(value) || '',
                        recentSearches: [...new Set(recentSearches.map(recentSearch => recentSearch.map(option => convert___D__H__M__SToSeg(option.value)).join(',') || ''))],
                    }, filter.key)();
                }}
            />
        </div>
    );
};

export default InequalityFilter;
