import React, {useRef} from 'react';
import StyledDialog from "../../util/Dialog";
import Button from "@material-ui/core/Button";
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';

const AlertMessage = props => {
    const {openModal, message, onClose, isError, maxWidth} = props; //isWarning by deafault true
    const associateAnchor = useRef();
    const actionButtons = (
        <div className='dialog-buttons'>
            <Button className={'alert-close-button'} onClick={onClose} style={{left: 8}}>Close</Button>
        </div>
    );

    const styleAlertMessage = {paddingLeft:'0.5em', paddingTop: '2px', textAlign: 'justify', lineHeight: '18px', textJustify:'inter-word', whiteSpace: 'pre-line'};
    return (
        <StyledDialog
            modal={true}
            open={openModal}
            onClose={onClose}
            maxWidth={maxWidth||false}
            contentStyle={{height: 'auto', minHeight:'30px'}}
            actions={actionButtons}
            bodyStyle={{overflowY:'auto', minHeight:'inherit', height:'100%'}}
            title={isError ? "Error": "Warning"}
            // title={isError ? "Error" : (isWarning ? "Warning" : "")}
        >
            <form ref={associateAnchor} style={{display: 'flex'}}>
                <div>
                    {isError ? <ErrorIcon color='error' />: <WarningIcon htmlColor='#f7b32f' />}
                </div>                    
                <div style={styleAlertMessage}>{message}</div>
            </form>

        </StyledDialog>
    )
};

export default AlertMessage;