import TableView from '../../../../../common/app/views/table';
import React from 'react';
import PropTypes from 'prop-types';

import {renderFilter} from './filter/util';

import {NoFilterTheme} from '../../../../../common/app/views/table/themes/NormalTheme';
import {detect} from "detect-browser";

export {NoFilterTheme};


const DEFAULT_COLUMN_SIZE = 200;
const DEFAULT_AUTOSIZE_OFFSET = 1;

function __getString(data) {
    if (Array.isArray(data)) {
        return data.join(',');
    }
    return data.toString();
}


/**
 * Useful for shared handling. (Not to be extended to 'common' functionality)
 */
class WrappedTable extends React.Component{
    constructor(props){
        super(props);

        //region Browser version detection.
        let modernBrowser=false;
        const browserInfo = detect();

        if (browserInfo) {
            const version = browserInfo.version.split('.');
            const versionTail = Array.prototype.slice.call(version, 1).join('') || '0';
            const versionBrowser=parseFloat(`${version[0]}.${versionTail}`);

            if(browserInfo.name=== 'chrome'&&versionBrowser>=60)
                modernBrowser=true;
            else if(browserInfo.name==='firefox'&&versionBrowser>=32)
                modernBrowser=true;
            else if(browserInfo.name==='safari'&&versionBrowser>=7.1)
                modernBrowser=true;
            else if(browserInfo.name==='opera'&&versionBrowser>=46)
                modernBrowser=true;
            else if(browserInfo.name==='ios'&&versionBrowser>=8)
                modernBrowser=true;
            else if(browserInfo.name==='android'&&versionBrowser>=60)
                modernBrowser=true;
            else if(browserInfo.name==='crios'&&versionBrowser>=60)// chrome ios
                modernBrowser=true;
        }
        //endregion
        
        let columnWidths = {}, adjusted = false;
        if (props.data && props.data.length > 0) {
            columnWidths = this.getColumnWidths();
            adjusted = true;
        }

        this.state = {
            columnWidths,
            adjusted,
            modernBrowser:modernBrowser,
        }

        this.jumpToRow = this.jumpToRow.bind(this);
        this.renderFilter = this.renderFilter.bind(this);
        this.handleSelectAll = this.handleSelectAll.bind(this);
        this.handleResizeColumn = this.handleResizeColumn.bind(this);
        this.handleAutoSizeColumns = this.handleAutoSizeColumns.bind(this);
        this.getBaseColumnWidth = this.getBaseColumnWidth.bind(this);
    }

    static get propTypes(){
        return {
            onTouchTapRow: PropTypes.func,
            onHoverRow: PropTypes.func,
            hoverIndex: PropTypes.number,
            actions: PropTypes.object.isRequired,
            columns: PropTypes.array,
            filters: PropTypes.object,
            disableFilters: PropTypes.bool,
            modernBrowser: PropTypes.bool,
            adjusted: PropTypes.bool,
            initiaColumnSizeOffset: PropTypes.number,
            maxInitialColumnSize: PropTypes.number
        };
    }

    static get defaultProps () {
        return {
            adjusted : false,
            initiaColumnSizeOffset : 50,
            maxInitialColumnSize: 200,
        };
    }

    jumpToRow(i){
        this.refs.table.jumpToRow(i);
    }

    renderFilter({index}) {
        return renderFilter({index, ...this.props});
    }

    isSelected(index,datum){
        if(datum!=null)
            return !!datum.___selected;
        else
            return false;
    }

    // True if the item is selected by details sidebar, else in other case.
    isSelectedDetails(uid,datum,showRightSidebar){
        return (uid!=null&&datum!=null&&uid===datum['___uid']&&showRightSidebar);
    }

    handleSelectAll(event,selected){
        this.props.actions.selectAllRows(selected);
    }

    handleResizeColumn(index,width) {
        const {columns, columnOrder} = this.props;
        if (index !== columns.length) {
           let filteredColumns = columnOrder ? columnOrder.filter(c=>c.enabled) : columns;
    
            this.setState({
                columnWidths:{
                    ...this.state.columnWidths,
                    [filteredColumns[index].name] : width
                }
            });
        }
    }

    getAdjustedColumnWidth(index, autosize, offset) {
        const { columns, columnOrder, columnMeta, data, maxInitialColumnSize} = this.props;

        let filteredColumns = columnOrder ? columnOrder.filter(c=>c.enabled) : columns;

        const key = filteredColumns[index].name;

        let nCharacters = 40;

        if((columnMeta&&columnMeta[key]&&columnMeta[key].caption)||(filteredColumns&&filteredColumns[index]&&filteredColumns[index].caption))
            nCharacters = columnMeta ? columnMeta[key].caption.length : filteredColumns[index].caption.length;

        data.forEach(d=>{
            let len = d[key] && __getString(d[key]).length;
            if(len && len > nCharacters) nCharacters = len;
        });

        let width = nCharacters * 8 + (offset || DEFAULT_AUTOSIZE_OFFSET);
        width = (width > 50) ? width : 50;
        if (!autosize && width > maxInitialColumnSize) {
            width = maxInitialColumnSize;
        }

        return {
            key,
            width
        };
    }

    handleAutoSizeColumns(index){
        const { key, width } = this.getAdjustedColumnWidth(index, true, DEFAULT_AUTOSIZE_OFFSET);

        this.setState({
            columnWidths:{
                ...this.state.columnWidths,
                [key] : width
            }
        });
    }

    getColumnWidths() {
        const { columns, initiaColumnSizeOffset } = this.props;
        const columnWidths = {};
        for (let i = 0; i < columns.length; ++i) {
            const { key, width } = this.getAdjustedColumnWidth(i, false, initiaColumnSizeOffset);
            columnWidths[key] = width;
        }
        return columnWidths;
    }

    componentDidUpdate() {
        const { columns, data, adjustColumns } = this.props;
        if (adjustColumns && columns.length > 0 && data.length > 0 && !this.state.adjusted) {
            const columnWidths = this.getColumnWidths();
            this.setState({
                adjusted: true,
                columnWidths,
            });
        }
    }

    getBaseColumnWidth({index}) {
        const {columns, columnOrder} = this.props;
        const {columnWidths} = this.state;

        let filteredColumns = columnOrder ? columnOrder.filter(c=>c.enabled) : columns;
        if (filteredColumns.length > 0){
            return columnWidths[filteredColumns[index].name] || DEFAULT_COLUMN_SIZE;
        }
    }

    render() {
        const {
            actions,
            adjustColumns,
            animationEnabled,
            bodyCellContentRenderer,
            columnMeta,
            columnOrder,
            columns,
            data,
            detailsItem,
            disableFillTable,
            disableFilters,
            disableSort,
            filters,
            getBaseColumnWidth,
            getRowHeight,
            initiaColumnSizeOffset,
            isHovered,
            isMobile,
            isSelected,
            maxInitialColumnSize,
            onHoverRow,
            onRequestAutoSize,
            onResizeColumn,
            onSelectAll,
            onTouchTapCheckbox,
            onTouchTapRow,
            reportId,
            reportName,
            requestZones,
            resizing,
            selected,
            showRightSidebar,
            sidebarFilterStatus,
            sortDirection,
            sortIndex,
            view,
            backgroundRow,
            maps,
            sites,
        } = this.props;
        const {modernBrowser}=this.state;
        var filterRenderer = (
            (!this.props.disableFilters || undefined)
            && this.renderFilter
        );

        let reportsFixed = ['Resources','Tags','Event History'];        
        let nFixedColumnsDefault = 1;
        let nFixedColumnsReportsFixed = 2;

        //nFC is the number of columns will be statics in wrapped Table in a specific report
        let nFC;

        if (reportsFixed.indexOf(reportName) >= 0){
            nFC = nFixedColumnsReportsFixed;
        }else{
            nFC = nFixedColumnsDefault;
        }

        return (
            <TableView
                ref="table"
                filterRenderer={filterRenderer}
                isSelectedDetails={this.isSelectedDetails}
                nFixedColumns={nFC}
                headerHeight={filterRenderer ? 30 : 40}
                filterHeight={40}
                onSortChange={this.props.actions.changeSort}
                modernBrowser={modernBrowser}
                columns={columns}
                data={data}
                isHovered={isHovered}
                onTouchTapCheckbox={onTouchTapCheckbox}
                onTouchTapRow={onTouchTapRow}
                actions={actions}
                filters={filters}
                animationEnabled={animationEnabled}
                columnMeta={columnMeta}
                columnOrder={columnOrder}
                detailsItem={detailsItem}
                disableFillTable={disableFillTable}
                disableFilters={disableFilters}
                isMobile={isMobile}
                onHoverRow={onHoverRow}
                reportId={reportId}
                requestZones={requestZones}
                resizing={resizing}
                selected={selected}
                showRightSidebar={showRightSidebar}
                sidebarFilterStatus={sidebarFilterStatus}
                sortDirection={sortDirection}
                sortIndex={sortIndex}

                adjustColumns={adjustColumns}
                bodyCellContentRenderer={bodyCellContentRenderer}
                disableSort={disableSort}
                getBaseColumnWidth={getBaseColumnWidth || this.getBaseColumnWidth}
                getRowHeight={getRowHeight}
                initiaColumnSizeOffset={initiaColumnSizeOffset}
                isSelected={isSelected || this.isSelected}
                maxInitialColumnSize={maxInitialColumnSize}
                onRequestAutoSize={onRequestAutoSize || this.handleAutoSizeColumns}
                onResizeColumn={onResizeColumn || this.handleResizeColumn}
                onSelectAll={onSelectAll || this.handleSelectAll}
                view={view}
                backgroundRow={backgroundRow}
                maps={maps}
                sites={sites}
            />
        );
    }
}
// || this.getBaseColumnWidth
export default WrappedTable;
