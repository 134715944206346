import React from 'react';
import PropTypes from 'prop-types';
import { MULTIPLE_ZONE_STANDARD_FIELDS } from '../Constants';
import ExitZoneDialog from '../dialog/ExitZoneDialog';
import { opensPropSchemaDialog } from '../dialog/PropertiesSchemaDialog';
import { PropertyRow } from './PropertiesTable';

class SimplePropertiesGridTable extends React.Component{
    static get propTypes(){
        return {
            properties: PropTypes.object,
            schema: PropTypes.object,
            zones: PropTypes.object,
            onPropertyChange: PropTypes.func,
            openPropSchemaDialog: PropTypes.func
        };
    }
    
    constructor(){
        super();
        this.getCurrentZoneGroups = this.getCurrentZoneGroups.bind(this);
        this.handleSchemaTap = this.handleSchemaTap.bind(this);
        this.handleShapeTableSave = this.handleShapeTableSave.bind(this);
        this.handleBusinessPropertyChange = this.handleBusinessPropertyChange.bind(this);
    }
    
    state = {
        zoneShape: null,
        openExitZone: false
    }
    
    componentDidMount() {
        // const { siteId, getSiteZoneGroups } = this.props;
        // getSiteZoneGroups(siteId); // get zone groups for the properties dropdown
    }
    
    render(){
        const { properties, zoneGroups, zones,
            //mapId,
            siteId } = this.props;
        const currentZoneGroups = this.getCurrentZoneGroups();
        const publishedZoneGroups = zoneGroups.reduce((acc, item) => item?.siteId === siteId?[...acc, item?.groupName]: acc, []);
        const allZoneGroups = [...currentZoneGroups, ...publishedZoneGroups].filter((value, index, self) => self.indexOf(value) === index);

        const standardPropertyRows = MULTIPLE_ZONE_STANDARD_FIELDS(allZoneGroups).map((f) => (
            <PropertyRow
                key={`${properties._key}-${f.name}`}
                {...f}
                value={properties[f.name]}
                business={false}
                zones={zones}
                siteId={siteId}
                onPropertyChange={this.props.onPropertyChange}
                onZoneShapeSelect={zoneShape => this.setState({ ...this.state, zoneShape })}
                openExitZoneDialog={zoneId => this.setState({ ...this.state, openExitZone: zoneId })}
            />
        ));
        
        return (
            <>
                <ExitZoneDialog
                    open={this.state.openExitZone !== false}
                    zoneId={this.state.openExitZone}
                    mapId={this.props.mapId}
                    onPropertyChange={this.props.onPropertyChange}
                    onClose={() => this.setState({ ...this.state, openExitZone: false })}
                />
                
                <table style={{ width: '100%' }}>
                    <tbody>
                    {standardPropertyRows}
                    </tbody>
                </table>
            </>
        );
    }
    
    getCurrentZoneGroups(){
        const {zones, mapId} = this.props;
        let zoneGroups = [];
        Object.entries(zones).forEach(([key, zone]) => {
          if(zone.data?.mapId === mapId && zoneGroups.indexOf(zone.data?.groupName) === -1){
            zoneGroups.push(zone.data.groupName);
          }
        });
        return zoneGroups;
    }

    handleSchemaTap(){
        this.props.openPropSchemaDialog();
    }
    
    handleBusinessPropertyChange(key,value){
        this.props.onPropertyChange(
            "properties",
            {
                ...this.props.properties.properties,
                [key]:value
            }
        );
    }
    
    handleShapeTableSave(newCoords) {
        console.log(newCoords);
    }
}

export const MultiplePropertiesGridTable = opensPropSchemaDialog(SimplePropertiesGridTable);