import {appliances as APPLIANCE_COLUMNS} from '../utils/column-definitions';

import {
    LOAD_APPLIANCES,
    LOAD_FIRMWWARE_VERSIONS,
    LOAD_PACKAGES,
    LOAD_SITES,
    LOAD_TASKS,
    TOGGLE_SELECT_ALL_APPLIANCES,
    TOGGLE_SELECT_APPLIANCE,
    UPDATE_SERVER_LOGS_DOWNLOAD_STATUS,
    LOAD_SITE_CONFIGURATION
} from '../constants/ActionTypes';

import {arrayToObject} from '../utils/Misc';


const initialState = {
    columns: APPLIANCE_COLUMNS,
    data: [],
    selected:{}, // indexed by appliance id
    sites:{}, // indexed by site id
    tasks:{}, // indexed by appliance id, then command name
    packages:{}, // indexed by appliance id
    firmwareVersions:[],
    serverLogsDownloadStatus:null,
};

function synthesizeAppliances(appliances){

    return appliances.map((app)=>({
        ...app,
        uuid: app._id
    }));
}

function tasks(state,action){

    switch(action.type){
        case LOAD_TASKS:
            return {
                ...state,
                [action.applianceId]:{
                    ...state[action.applianceId],
                    [action.commandName] : action.data
                }
            };
        default:
            break;
    }

    return state;
}

export function appliances(state = initialState, action){

    let isAllSelected;

    switch(action.type){

        case LOAD_FIRMWWARE_VERSIONS:
            return {
                ...state,
                firmwareVersions: action.data.reverse()
            };

        case LOAD_PACKAGES:
            return {
                ...state,
                packages:{
                    ...state.packages,
                    [action.applianceId]:action.data
                }
            };

        case LOAD_TASKS:
            return {
                ...state,
                tasks: tasks(state.tasks,action)
            };

        case LOAD_SITES:
            return {
                ...state,
                sites:arrayToObject(action.data,"_id")
            };

        case LOAD_APPLIANCES:
            let data = action.data.sort((a, b) => {
                if(a.name.toUpperCase() < b.name.toUpperCase()){
                    return -1;
                }
                if(a.name.toUpperCase() > b.name.toUpperCase()){
                    return 1;
                }
                return 0;
            });
            return {
                ...state,
                data: synthesizeAppliances(data)
            };

        case TOGGLE_SELECT_APPLIANCE:
            return {
                ...state,
                selected:{
                    ...state.selected,
                    [action.uuid] : !state.selected[action.uuid]
                }
            };

        case TOGGLE_SELECT_ALL_APPLIANCES:
            isAllSelected = Object.keys(state.selected).filter(v=>state.selected[v]).length === state.data.length;

            return {
                ...state,
                selected: !isAllSelected ? state.data.reduce((obj,app)=>{
                    obj[app._id] = true;
                    return obj;
                },{}) : {}
            };

        case UPDATE_SERVER_LOGS_DOWNLOAD_STATUS:
            return {
                ...state,
                serverLogsDownloadStatus:action.serverLogsDownloadStatus
            };

        case LOAD_SITE_CONFIGURATION:
            return {
                ...state,
                siteConfiguration: action.siteConfiguration,
                timeCreated: action.timeCreated
            };

        default:
            break;
    }

    return state;
}
