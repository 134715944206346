import React from 'react';

import Paper from "@material-ui/core/Paper";
import ZoneToggleButton from '../misc/ZoneToggleButton';
import IconButton from '@material-ui/core/IconButton';
import ZoomInIcon from '@material-ui/icons/AddBox';
import CenterIcon from '@material-ui/icons/CenterFocusStrong';
import ZoomOutIcon from '@material-ui/icons/IndeterminateCheckBox';
import MapsIcon from '@material-ui/icons/Collections';
import TagLabelIcon from '@material-ui/icons/Crop75';
import TagListIcon from '@material-ui/icons/LocalOffer';
import BackwardIcon from '@material-ui/icons/Reply';
import * as views from '../../../../constants/ViewTypes';
import LegendIcon from "../../../icons/LegendIcon";
import ReactToolTip from 'react-tooltip';

class HistoryPlaybackToolbar extends React.Component{
    changeView(view, reportId){
        this.props.toggleBackView(view, reportId);
    }
    render(){

        const {
            zoneVisibility,
            showMapDrawer,
            showTagLabels,
            zoomIn,
            zoomOut,
            centerMap,
            toggleZoneVisibility,
            toggleShowTagLabels,
            toggleMapDrawer,
            toggleTagList,
            toggleShowLegend,
            reportId,
            previousView,
        } = this.props;

        //const DISABLED_COLOR = "#aaa";

        const styles = HistoryPlaybackToolbar.styles;

        return (
            <Paper className="paper-lmvt" ref={"leftToolBar"} style={{position:'absolute',top:'0',bottom: 0,left:0, transition:0}} elevation={2}>


                <IconButton style={styles.zoomInButton} onClick={zoomIn} disableTouchRipple data-tip={"Zoom In"} data-for={"zoomIn"} >
                    <ZoomInIcon />
                </IconButton>
                <ReactToolTip className={"tooltip-class"} place={"right"} id={"zoomIn"} effect={"solid"} />

                <IconButton style={styles.zoomOutButton} onClick={zoomOut} disableTouchRipple data-tip={"Zoom Out"} data-for={"zoomOut"} >
                    <ZoomOutIcon />
                </IconButton>
                <ReactToolTip className={"tooltip-class"} place={"right"} id={"zoomOut"} effect={"solid"} />

                <IconButton onClick={centerMap} style={styles.enabledButton} disableTouchRipple data-tip={"Center Map"} data-for={"centerMap"} >
                    <CenterIcon />
                </IconButton>
                <ReactToolTip className={"tooltip-class"} place={"right"} id={"centerMap"} effect={"solid"} />

                <ZoneToggleButton style={styles.enabledButton} onClick={toggleZoneVisibility} mode={zoneVisibility} data-tip={"Show/Hide Zones"} data-for={"zones"} />
                <ReactToolTip className={"tooltip-class"} place={"right"} id={"zones"} effect={"solid"} />

                <IconButton style={showTagLabels?styles.enabledButton:styles.standardButton} disableTouchRipple onClick={toggleShowTagLabels}
                    data-tip={"Show/Hide Tag Labels"} data-for={"showTagLabel"}
                >
                    <TagLabelIcon />
                </IconButton>
                <ReactToolTip className={"tooltip-class"} place={"right"} id={"showTagLabel"} effect={"solid"} />

                <div style={styles.spacer}/>

                <IconButton style={showMapDrawer?styles.enabledButton:styles.standardButton} onClick={toggleMapDrawer} disableTouchRipple
                    data-tip={"Show/Hide Map Thumbnails"} data-for={"showMapThumbnails"}
                >
                    <MapsIcon />
                </IconButton>
                <ReactToolTip className={"tooltip-class"} place={"right"} id={"showMapThumbnails"} effect={"solid"} />

                <div style={styles.spacer}/>

                <IconButton style={styles.enabledButton} onClick={toggleTagList} disableTouchRipple data-tip={"Show/Hide Tag List"} data-for={"showTagList"} >
                    <TagListIcon />
                </IconButton>
                <ReactToolTip className={"tooltip-class"} place={"right"} id={"showTagList"} effect={"solid"} />

                <div style={styles.spacer} />

                {views.PLAYBACK_VIEW !== previousView && previousView !== undefined ?
                        <IconButton style={styles.enabledButton} onClick={() => this.changeView(previousView, reportId)}
                                    disableTouchRipple data-tip={"Back To Report"} data-for={"backToReport"} >
                            <BackwardIcon/>
                            <ReactToolTip className={"tooltip-class"} place={"right"} id={"backToReport"} effect={"solid"} />
                        </IconButton>
                    :
                    null
                }


                <div style={styles.spacer} />

                <IconButton style={styles.enabledButton} onClick={toggleShowLegend} disableTouchRipple data-tip={"Show/Hide Tag Legend"} data-for={"tagLegend"} >
                    <LegendIcon />
                </IconButton>
                <ReactToolTip className={"tooltip-class"} place={"right"} id={"tagLegend"} effect={"solid"} />
            </Paper>
        );
    }
}

HistoryPlaybackToolbar.styles = {
    zoomInButton:{
        display:'block',
        height:'32px',
        padding:'8px 12px 0px',
        backgroundColor: 'transparent',
        color: '#000'
    },
    zoomOutButton:{
        display:'block',
        height:'32px',
        padding:'0px 12px 8px',
        backgroundColor: 'transparent',
        color: '#000'
    },
    standardButton:{
        display:'block',
        height:'35px',
        padding:'10px 12px',
        backgroundColor: 'transparent',
        color: '#aaa'
    },
    enabledButton: {
        display:'block',
        height:'35px',
        padding:'10px 12px',
        backgroundColor: 'transparent',
        color: '#000'
    },
    spacer:{
        height:'1em',
        width:'48px'
    },
};

export default HistoryPlaybackToolbar;
