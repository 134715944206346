import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Color from 'color';
import ColorPopover from './ColorPopover';

const getBoxStyles = value => ({
  background: value,
  color: (Color(value).light() ? '#000' : '#fff'),
  padding: '0.15em 0.5em',
  lineHeight: '1.0em',
  position: 'relative'
});

const ColorInput = ({ value, onChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleBoxTap = (e) => {
    setAnchorEl(e.currentTarget);
  }

  const boxStyles = getBoxStyles(value);

  return (
    <>
      <div style={boxStyles} onClick={handleBoxTap}>
        {value}
      </div>

      <ColorPopover 
        color={value}
        onChange={onChange}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      />
    </>
  );
}

ColorInput.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func
}

export default ColorInput;
