const RESOURCES_BY_TYPE = 10;
// const RESOURCES_BY_SITE = 12;
const RESOURCES_BY_CUSTOMATTRIBUTE = 11;
const RESOURCES_BY_ZONE = 13; // replace this!

const LAYOUTS_RESOURCES = {
    META:{
        title:"Resources",
        height:6,
        width:12
    },

    LAYOUT:[
        {
            x:0,
            y:0,    //0
            width:3,       //3
            height:3,
            classDashboard: "resource-by-type-class",
            componentId:RESOURCES_BY_TYPE
        },
        {
            x:6,
            y:0,
            width:6,
            height:6,
            classDashboard: "resource-by-zone",
            componentId:RESOURCES_BY_ZONE
        },
        // {
        //     x:3,
        //     y:0,
        //     width:3,
        //     height:3,
        //     classDashboard: "resource-by-site-class",
        //     componentId:RESOURCES_BY_SITE
        // },
        {
            x:0,
            y:3,
            width:6,
            height:3,
            classDashboard: "resource-by-custom-attr-class",
            componentId:RESOURCES_BY_CUSTOMATTRIBUTE
        }

    ]
};

export default LAYOUTS_RESOURCES;