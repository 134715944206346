import React from 'react';
import moment from 'moment';
import {DatetimeFilter, Filter, InequalityFilter, LookupFilter} from './';
import {getLocalTimestamp} from "../../../../utils/LocaleTimestamp";
import {OPTION_ALL_TEXT, OPTION_ALL_WILDCARD, OPTION_ALL_WILDCARD_OLD} from "../../../../constants/Misc";
import {
    CALL_HISTORY_ID,
    RESOURCE_EVENT_HISTORY_ID,
    RESOURCE_ZONE_CORRELATION_EVENT_HISTORY_ID,
    TAG_HISTORY_REPORT
} from "../../../../constants/History";


export const PERIOD = {
    TODAY: "TODAY",
    YESTERDAY: "YESTERDAY",
    LAST_WEEK: "LAST_WEEK",
    LAST_MONTH: "LAST_MONTH",
    CUSTOM: "CUSTOM",
    NOW: "NOW",
};

export const DEFAULT_FILTER_VALUE = {
    'normal': OPTION_ALL_WILDCARD,
    'lookup': OPTION_ALL_WILDCARD,
    'inequality': OPTION_ALL_WILDCARD,
    'datetime': getDefaultDateTimeValue('from'),
    'datetimeFrom': getDefaultDateTimeValue('from'),
    'datetimeTo': getDefaultDateTimeValue('to'),
}

export const FILTER_TYPE = {
    DATETIME: "datetime",
    DATETIME_FROM: "datetime.from",
    DATETIME_TO: "datetime.to",
    DATETIME_RANGE_MODE: "datetime.rangeMode",
    TREE: "tree",
    RESOURCE_TYPE: "resourceType",
    RESOURCE_TYPE_WITH_DISPLAY_NAME:"resourceTypeWithDisplayName",
    INEQUALITY: "inequality",
    LOOKUP: "lookup",
    NORMAL: "normal",
    MULTISELECT: "multiselect",
    MULTISELECT_AUTO_SUGGEST: "multiselectAutoSuggest",
    RESOURCE_TYPE_ATTRIBUTES:"attributes",
    SELECT: "select",
    TIME: "time"
};


function setToMidnight(date){
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
}

function getDefaultDateTimeValue(type) {
    if (type === 'to') {
        return moment().add(1, 'day').startOf('day').toDate().toISOString();
    }
    return moment().startOf('day').toDate().toISOString();
}

export function isDefaultValue(value,defaultValue, filterType='normal') {

    let isDefault=undefined;

    if(defaultValue!==undefined&&filterType!=='datetime'&&filterType!=='datetimeFrom'&&filterType!=='datetimeTo') {

        if (defaultValue === value    // The current value is the same that the default value.
            || value === undefined)   // No filter was selected.
            isDefault = true;
        // For old wildcard option (*)
        else if (value === OPTION_ALL_WILDCARD_OLD && defaultValue === OPTION_ALL_WILDCARD)
            isDefault = true;
        else
            isDefault = false;
    }
    else
        isDefault=(value === undefined || value === DEFAULT_FILTER_VALUE[filterType]);

    return isDefault;
}


export function isAllOption(value) {
    return (value===OPTION_ALL_WILDCARD || value===OPTION_ALL_WILDCARD_OLD||(value!=null&&value.toLowerCase()==='all'));
}

export function isAllOptionLookup(value) {
    return (value[0]===OPTION_ALL_WILDCARD || value[0]===OPTION_ALL_WILDCARD_OLD||(value!=null&&value[0]&&value[0].toLowerCase()==='all'));
}

export function isAllWrite(value) {
    if (value === undefined || value === null || typeof  value ==='object' || typeof value === 'number' || typeof  value === 'boolean') {
        return false;
    }else{
        return value.toString().toLowerCase()===OPTION_ALL_TEXT.toLowerCase() || value.trim()==='';
    }
}

// || value.toString().toLowerCase()===OPTION_ALL_TEXT.toLowerCase()

/**
 * Return the selected value if is defined, or the default value if is not.
 * @param filter
 * @returns {string}
 */
export function getFilterValue(filter){
    
    let value = undefined;
    if (filter&&(filter.type==='normal'||filter.type==='lookup'||filter.type==='inequality'||filter.type==='multiselect')) {
        if (filter.value!==undefined) {
            if(filter.type ==='multiselect'){
                value = filter.value;
                if(value !== OPTION_ALL_WILDCARD && value !== '' && isJsonString(value)){
                    value = JSON.parse(filter.value).values ? JSON.parse(filter.value).values.toString() : filter.value;
                }
            } else {
                value = isJsonString(filter.value) && filter.value !== OPTION_ALL_WILDCARD && JSON.parse(filter.value).values ? JSON.parse(filter.value).values.toString() : filter.value;
            }

            // value = isJsonString(value) ? (JSON.parse(filter.value).values ? JSON.parse(filter.value).values.toString() : "")  : filter.value;

        }
        else if (filter && filter.defaultValue!==undefined)
            value = filter.defaultValue;
        else if (filter.isAllAllowed === true)
            value = OPTION_ALL_WILDCARD;
    }
    // Default value from service is not important in datetimes.
    else if(filter&&(filter.type==='datetime'||filter.type==='datetimeFrom'||filter.type==='datetimeTo'))
        value= value === undefined || value === DEFAULT_FILTER_VALUE[filter.type];
    return value;
}

function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function getDefaultValue(filter){

    let value = undefined;
    if (filter&&(filter.type==='normal'||filter.type==='lookup'||filter.type==='inequality'||filter.type==='multiselect')) {

        if (filter && filter.defaultValue!==undefined)
            value = filter.defaultValue;
        else if (filter.isAllAllowed === true)
            /*if(filter.type === 'lookup'){
                value = {"values":[OPTION_ALL_WILDCARD]}
            } else {*/
                value = OPTION_ALL_WILDCARD;
            // }
    }
    // Default value from service is not important in datetimes.
    else if(filter&&(filter.type==='datetime'||filter.type==='datetimeFrom'||filter.type==='datetimeTo'))
        value= DEFAULT_FILTER_VALUE[filter.type];

    return value;
}

function getFilterAtIndex(index, columns, filters) {
    if(!columns[index].filter) return null; // no filter for column
    if(!filters) return null; // no loaded filters at all.

    const filterName = columns[index].filter;

    return filters[ filterName ];
}

export function renderFilter({index, columns, filters, actions, loadingValues, isMobile, isMobileDevice, reportName,reportId}){
    const filter = getFilterAtIndex(index, columns, filters);
    if(!filter) return;
    switch(filter.type){
        case FILTER_TYPE.DATETIME:
            return (
                <DatetimeFilter
                    reportId={reportId}
                    name={columns[index].name}
                    filters={filters}
                    actions={actions}
                    isMobile={isMobile}
                    isMobileDevice={isMobileDevice}
                    nextPrevButtons={enableNextPrevButtons(reportId)}
                />
            );
        case FILTER_TYPE.INEQUALITY:
            return (
                <InequalityFilter
                    filter={filter}
                    actions={actions}
                    loadingValues={loadingValues}
                    isMobile={isMobile}
                    isMobileDevice={isMobileDevice}
                />
            );

        case FILTER_TYPE.LOOKUP:
        case FILTER_TYPE.MULTISELECT:
            return (
                <LookupFilter
                    reportName={reportName}
                    filter={filter}
                    actions={actions}
                    loadingValues={loadingValues}
                    isMobile={isMobile}
                    isMobileDevice={isMobileDevice}
                    isMultiselectAllowed={filter.type === FILTER_TYPE.MULTISELECT}
                />
            );

        case FILTER_TYPE.NORMAL:
        default:
            return (
                <Filter
                    reportName={reportName}
                    filter={filter}
                    actions={actions}
                    isMobile={isMobile}
                    isMobileDevice={isMobileDevice}
                />
            );
    }
}
export const enableNextPrevButtons = (reportId) =>{
    const allowedReports = [CALL_HISTORY_ID, RESOURCE_EVENT_HISTORY_ID, TAG_HISTORY_REPORT, RESOURCE_ZONE_CORRELATION_EVENT_HISTORY_ID];
    return allowedReports.indexOf(reportId) > - 1;
}

export function getTimes(mode){
    var _f, _t;
    switch(mode){
        case PERIOD.LAST_MONTH:
            _f = new Date();
            setToMidnight(_f);
            _f.setMonth(_f.getMonth()-1);
            _t = new Date();
            setToMidnight(_t);
            _t.setDate(_t.getDate()+1);
            return {
                from:_f,
                to:_t
            };

        case PERIOD.LAST_WEEK:
            _f = new Date();
            setToMidnight(_f);
            _f.setDate(_f.getDate()-6);
            _t = new Date();
            setToMidnight(_t);
            _t.setDate(_t.getDate()+1);
            return {
                from:_f,
                to:_t
            };

        case PERIOD.YESTERDAY:
            _f = new Date();
            setToMidnight(_f);
            _f.setDate(_f.getDate()-1);
            _t = new Date();
            setToMidnight(_t);
            return {
                from:_f,
                to:_t
            };

        case PERIOD.TODAY:
            _f = new Date();
            setToMidnight(_f);
            _t = new Date();
            setToMidnight(_t);
            _t.setDate(_t.getDate()+1);
            return {
                from:_f,
                to:_t
            };
        case PERIOD.CUSTOM:
        default:
            _f = new Date();
            setToMidnight(_f);
            _t = new Date();
            setToMidnight(_t);
            return {
                from:_f,
                to:_t
            };
    }
}

export function getRangeMode(fromTime,toTime){

    let rangeMode = PERIOD.CUSTOM;

    [PERIOD.TODAY,PERIOD.YESTERDAY,PERIOD.LAST_WEEK,PERIOD.LAST_MONTH].forEach(period=>{
        let times = getTimes(period);

        if(times.from.getTime() === fromTime.getTime() && times.to.getTime() === toTime.getTime()){
            rangeMode = period;
        }
    });

    return rangeMode;
}

export function getLabelForFilter({rangeMode,fromTime,toTime,nextPrevButtons=false}){
    switch(rangeMode){
        // case PERIOD.YESTERDAY:
        //     return "Yesterday";

        case PERIOD.LAST_WEEK:
            //return "Last 7 days";
            return (nextPrevButtons?`7 days (${getLabelFromTimes(fromTime,toTime)})`:`Last 7 days`)

        case PERIOD.LAST_MONTH:
            //return "Last 30 days";
            return (nextPrevButtons?`30 days (${getLabelFromTimes(fromTime,toTime)})`:`Last 30 days`)

        case PERIOD.CUSTOM:
            return `Custom (${getLabelFromTimes(fromTime,toTime)})`;

        case PERIOD.TODAY:
        default:
            return (nextPrevButtons?`One Day (${getLabelFromTimes(fromTime,toTime)})`:`Today`)
    }
}

function getLabelFromTimes(from,to){
    return getLocalTimestamp(from)+" - "+getLocalTimestamp(to);
}
