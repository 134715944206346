import React from 'react';
import PropTypes from 'prop-types';
import {ButtonBase, ClickAwayListener} from '@material-ui/core';
import Autosuggest from 'react-autosuggest';
import {getFilterValue, isAllOption, isAllWrite} from "./util";
import {OPTION_ALL_TEXT, OPTION_ALL_WILDCARD} from "../../../../constants/Misc";
import LookupDialog from "./LookupDialog";

const getSuggestionValue = suggestion => suggestion;

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => {
    return (
        <span>
            {suggestion}
        </span>
    );
};



export class LookupComboBox extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            inputValue: null,
            values:(props.filter&&props.filter.isAllAllowed===true)?[OPTION_ALL_TEXT]:[],
            suggestions: [],
            focused: false,
            anchorEl: null,
            anchorElPopover: null,
            searches: []
        };

        this.onInputChangeValue = this.onInputChangeValue.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
        this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
        this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
        this.openLookUp =this.openLookUp.bind(this);
        this.closeLookUp=this.closeLookUp.bind(this);
        this.associateAnchor = this.associateAnchor.bind(this);
        this.associateAnchorPopover = this.associateAnchorPopover.bind(this);
        this.renderInputComponent = this.renderInputComponent.bind(this);
        this.renderSuggestionsContainer = this.renderSuggestionsContainer.bind(this);
        this.handleRequestLookup = this.handleRequestLookup.bind(this);
        this.getSectionSuggestions = this.getSectionSuggestions.bind(this);
        this.renderSectionTitle = this.renderSectionTitle.bind(this);
        this.setAllFilter = this.setAllFilter.bind(this);
        this.handleValueToArray = this.handleValueToArray.bind(this);
    }


    static get propTypes(){
        return {
            field: PropTypes.object,
            value: PropTypes.any,
            onError: PropTypes.func.isRequired,
            values: PropTypes.array,
            onChangeValue: PropTypes.func.isRequired,
            onFocus: PropTypes.func,
            onRequestLookup: PropTypes.func.isRequired,
            isAllAllowed: PropTypes.bool,
        };
    }

    static get defaultProps(){
        return {
            onBlur: ()=>{}
        };
    }


    componentDidMount(){
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        this.value = getFilterValue(nextProps);
        this.setState({
            value:this.value,
            values:nextProps.values,
            searches:nextProps.searches || this.state.searches,
        });
    }

    onFocus(){
        this.setState({
            focused: true,
            searches: this.props.searches
        });
        this.props.onFocus && this.props.onFocus();
    }


    onBlur(event){
        if (this.state.inputValue !== null && event && event.target)
            this.changeValue(event.target.value);

        this.setState({inputValue: null});
    }

    onInputChangeValue(event, { newValue }){
        this.setState({
            inputValue: newValue
        });
    }

    onKeyDown(event){
        if(event.key === 'Enter')
        {
            this.changeValue(event.target.value);
            this.setState({
                inputValue:null,
            })
        }
    }

    onSuggestionsFetchRequested({value}){
    }

    getSuggestionsFromValues(){
        return [];
    }

    getSectionSuggestions(section){
        return '';
    }

    renderSectionTitle(section) {
        return '';
    }
    
    onSuggestionsClearRequested(){}

    onSuggestionSelected(event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }){
        //this hack. Better way to do it to pass this in props itself;
        if(method === "enter"){
            this.setState({inputValue:null});
        }
        this.changeValue(suggestionValue);
    }

    changeValue(newValue){
        const value=(isAllWrite(newValue))?OPTION_ALL_WILDCARD:newValue.split('\\').join('').split('"').join("\\\"").replace(/'/g, "");
        const valueToSend  = this.props.isMultiselectAllowed && value !== OPTION_ALL_WILDCARD ? this.handleValueToArray(value) : value;
        this.props.onChangeValue(valueToSend);
        this.setState({
            focused: false
        });
    }
    openLookUp(){
    this.setState({
        focused:true
    });
    }
    closeLookUp(){
        this.setState({
            focused:false
        });
    }

    render() {
        const { inputValue} = this.state;
        const { value } = this.props;

        const inputProps = {
            value: (inputValue === null) ? value : inputValue,
            onChange: this.onInputChangeValue,
            onBlur: this.onBlur,
            onKeyDown: this.onKeyDown,
            onFocus: this.onFocus
        };

        // Finally, render it!
        //let searchesfixed = searches.map(function(item) { return item == '%' ? 'All' : item; });

        return (
          <Autosuggest
            suggestions={[]}
            focusInputOnSuggestionClick={false}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            shouldRenderSuggestions={()=>true}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            renderInputComponent={this.renderInputComponent}
            renderSuggestionsContainer={this.renderSuggestionsContainer}
            onSuggestionSelected={this.onSuggestionSelected}
            inputProps={inputProps}
            renderSectionTitle={this.renderSectionTitle}
            getSectionSuggestions={this.getSectionSuggestions}
          />
        );
    }

    renderInputComponent(inputProps){

        const {isAllAllowed} = this.props;
        let value=(isAllOption(inputProps.value))?OPTION_ALL_TEXT:inputProps.value;
        if (value === OPTION_ALL_TEXT && !isAllAllowed) {
            value = '';
        }
        return (
            <ClickAwayListener onClickAway={this.closeLookUp}>
                <input {...inputProps} value={value} onClick={this.openLookUp} ref={this.associateAnchor} placeholder="Type something" tabIndex="-1"/>
            </ClickAwayListener>
        );
    }

    associateAnchor(ref){
        this.setState({
            anchorEl: ref
        });
    }

    associateAnchorPopover(ref){
        this.setState({
            anchorElPopover: ref
        });
    }

    handleRequestLookup(){

        let {value} = this.state;
        this.props.onRequestLookup(value);
        this.setState({
            focused: false
        });
    }

    setAllFilter(){
        this.changeValue(OPTION_ALL_TEXT);
        this.setState({
            focused: false
        });
    }
    handleClick = (newValue) => {
        const value=(isAllWrite(newValue))?OPTION_ALL_WILDCARD:newValue.split('\\').join('').split('"').join("\\\"").replace(/'/g, "");
        let valueToSend = this.props.isMultiselectAllowed ? this.handleValueToArray(value) : value;

        this.props.onChangeValue(valueToSend);
        this.setState({
            focused: false
        });
    };

    handleValueToArray (value) {
        let valueToSend = [];
        if(!Array.isArray(value)){
            if(value === OPTION_ALL_WILDCARD){
                valueToSend.push(value)
            } else {
                valueToSend = value.split(",");
            }
        } else {
            valueToSend = value;
        }

        return valueToSend;
    }

    renderSuggestionsContainer({containerProps}){

        const listSearch = (this.state.searches)? this.state.searches.map((search) =>{
            // let key = Object.keys(search);
            return search !== '' && search !== '%'?
                <li key={search}  value={search} className="recent-search" onClick={()=>this.handleClick(search)}>{search}</li>: null
        }): null;
       return (

          <div {...containerProps} style={{display: this.state.focused ? 'block':'none'}} className={"lookup-container"} ref={this.associateAnchorPopover}>
                  <ButtonBase className="lookup-filter-button" onClick={this.handleRequestLookup}>
                      LOOKUP VALUES
                  </ButtonBase>
                  {this.state.searches && this.state.searches.length>0?
                      <div>
                          <b style={{fontSize: '10px',paddingLeft:'3px'}}>Recent Search</b>
                          <ul style={{padding:0, margin:0}}>
                              {listSearch}
                          </ul>
                      </div>:null}
                  {this.props.isAllAllowed&&
                  <div>
                      <b style={{fontSize: '10px',paddingLeft:'3px'}}>Options</b>
                      <input type={"Button"}  value={OPTION_ALL_TEXT} className="all-option" onClick={this.setAllFilter} readOnly/>
                  </div>
                  }
          </div>
        );
    }
}

// -----------------------------------------------------------------------------
/*
███████ ███    ███  █████  ██████  ████████
██      ████  ████ ██   ██ ██   ██    ██
███████ ██ ████ ██ ███████ ██████     ██
     ██ ██  ██  ██ ██   ██ ██   ██    ██
███████ ██      ██ ██   ██ ██   ██    ██

███████ ██ ██   ████████ ███████ ██████
██      ██ ██      ██    ██      ██   ██
█████   ██ ██      ██    █████   ██████
██      ██ ██      ██    ██      ██   ██
██      ██ ███████ ██    ███████ ██   ██
*/



class LookupFilter extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            options: [],
            loadingValues: false,
        };

        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.onRequestLookup = this.onRequestLookup.bind(this);
    }

    static get propTypes(){
        return {
            filter: PropTypes.object,
            actions: PropTypes.shape({
                requestFilterValues: PropTypes.func.isRequired,
                changeFilterValue: PropTypes.func.isRequired
            }).isRequired,
            isMultiselectAllowed: PropTypes.bool
        };
    }

    static get defaultProps(){
        return {
            filter:{}
        };
    }

    shouldComponentUpdate(nextProps,nextState){
        return (this.props.filter !== nextProps.filter || this.state !== nextState);
    }

/*     shouldComponentUpdate(nextProps,nextState){
        return (this.props.filter !== nextProps.filter || this.state.value != nextState.value || this.state.options != nextState.options);
    } */

    requestFilterValues(value) {
        const {filter} = this.props;
        if(!filter.hasOwnProperty('searches')){
            filter['searches'] = [];
        }
        return this.props.actions.requestFilterValues(filter.id, value);
    }

    handleFilterChange(value){
        
        //const {filter} = this.props;
        if(value.length !== 0){
            this.props.actions.changeFilterValue(this.props.filter.id,value);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(nextProps.loadingValues !== this.state.loadingValues){
            this.setState({
                loadingValues: nextProps.loadingValues
            })
        }
    }

    onRequestLookup(val){

        this.setState({lookupOpen:true});
    }
    handleClose(){
        this.setState({open:false});
    }

    render(){
        const {loadingValues} = this.state;
        const {filter} = this.props;
        const value=getFilterValue(filter);
        return (
          <div style={{paddingTop:'3px',paddingBottom:'3px',paddingLeft:'0px', paddingRight:'8px'}}>
              <LookupComboBox
                  value={value}
                  onError={()=>{}}
                  onChangeValue={this.handleFilterChange}
                  values={[]}
                  onRequestLookup={this.onRequestLookup}
                  searches={this.props.filter.searches}
                  isAllAllowed={this.props.filter.isAllAllowed}
                  isMultiselectAllowed={this.props.isMultiselectAllowed}
              />
              <LookupDialog
                  open={!!this.state.lookupOpen}
                  onRequestClose={()=>{this.setState({lookupOpen:false});}}
                  queryForValues={(value)=>{
                      return this.requestFilterValues(value);
                  }}
                  onSave={(value)=>{this.handleFilterChange(value);}}
                  value={value}
                  loadingValues={loadingValues}
                  onClose={()=>{this.setState({lookupOpen:false});}}
                  isMultiselectAllowed={this.props.isMultiselectAllowed}
              />
          </div>
        );
    }
}

export default LookupFilter;
