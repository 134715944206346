// Functions for transform data before sends to redux store, that prevents transform in each render

// gets boolean or text true,false (non case sensitive) and return the string Yes or No
export const booleanToYesNo = (value) => {
    const yes = "Yes";
    const no = "No";
    let result = null;
    if (value != null) {
        if (typeof value === "boolean") {
            result = (value === true) ? yes : no;
        } else if (typeof value === "string") {
            if (value.toLowerCase() === "true") {
                result = yes;
            } else if (value.toLowerCase() === "false") {
                result = no;
            }

        }
    }
    return result;
}