import { Circle, Fill, Stroke, Style, Text } from 'ol/style';
import { colorHexToRgb } from '../../vss/app/utils/ol/utils';

import CircleStyle from "ol/style/Circle";
import MultiPoint from "ol/geom/MultiPoint";
import Polygon from "ol/geom/Polygon";

// const styleLabelCoords = colorHexToRgb('#000000', 1);
//
// const styleLabelBorder = new Stroke({
//     color: colorHexToRgb('#FFFFFF', 0.8),
//     width: 1,
// });
//
// const styleLabelBorderCoords = new Stroke({
//     color: colorHexToRgb('#FFFFFF', 0.8),
//     width: 3,
// });

export const selectedZoneStyle = (feature, color, measurementSystem) => {
    // const textLabel = feature.values_?.name || '';
    const featureGeometry = feature.getGeometry();
    const featureType = featureGeometry.getType();
    const styles = [];
    if (featureType === 'Polygon') {
        // const pointGeometry = featureGeometry.getInteriorPoint();
        // styles.push(
        //     new Style({
        //         featureGeometry: pointGeometry,
        //         text: new Text({
        //             text: textLabel,
        //             font: '12px Calibri,sans-serif',
        //             fill: new Fill({
        //                 color: colorHexToRgb('#000000', 1),
        //             }),
        //             backgroundFill: new Fill({
        //                 color: colorHexToRgb('#FFFFFF', 0.8),
        //             }),
        //             overflow: true,
        //         }),
        //     }),
        // );
        styles.push(
            new Style({
                image: new CircleStyle({
                    radius: 5,
                    fill: new Fill({
                        color: 'blue',
                    }),
                }),
                geometry: function (feature) {
                    // return the coordinates of the first ring of the polygon
                    const coordinates = feature.getGeometry().getCoordinates()[0];
                    return new MultiPoint(coordinates);
                },
                fill: new Fill({
                    color: colorHexToRgb(color, 0.8),
                }),
            }),
        );
    }
    return styles;
};

export const zoneStyle = (
    feature,
    color,
    isSelected,
    showLabels,
    measurementSystem,
) => {
    let zoneColor = feature.values_?.color ? feature.values_.color : color;
    const isDraft = feature.values_?.isDraft || false;
    const featureGeometry = feature.getGeometry();
    const featureType = featureGeometry.getType();
    
    const styles = [];
    if (featureType === 'Polygon' || featureType === 'Point') {
        zoneColor = feature.get('isCollided') ? '#FF0000' : zoneColor;
        styles.push(
            new Style({
                image: new Circle({
                    fill: new Fill({
                        color: colorHexToRgb(zoneColor, 1),
                    }),
                    stroke: new Stroke({
                        color: colorHexToRgb(zoneColor, 1),
                        width: 1.25,
                    }),
                    radius: 2,
                }),
                fill: new Fill({
                    color: colorHexToRgb(zoneColor, isSelected?0.7:(isDraft ? 0.1 : 0.3)),
                }),
                stroke: new Stroke({
                    color: colorHexToRgb(zoneColor, 1),
                    width: 1.25,
                    lineDash: isDraft ? [ 4, 8 ] : null,
                }),
            }),
        );
    }
    
    if (isSelected) {
        styles.push(...selectedZoneStyle(feature, zoneColor, measurementSystem));
    }

    if (showLabels && feature.getGeometry() instanceof Polygon) {

        const pointGeometry = feature.getGeometry().getInteriorPoint();
        const textLabel = feature.values_?.name || '';
        
        styles.push(
            new Style({
                featureGeometry: pointGeometry,
                text: new Text({
                    text: textLabel,
                    font: '12px Calibri,sans-serif',
                    fill: new Fill({ color: '#000000' }),
                    backgroundFill: new Fill({ color: '#FFFFFF' }),
                    backgroundStroke: new Stroke({ color: '#000000', width: 1 }),
                    overflow: true,
                    padding: [ 1.5, 1.5, 1.5, 1.5 ],
                }),
            }),
        );
    }
    
    return styles;
};
