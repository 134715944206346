import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../../../../actions/index';

const DISABLED = "Disabled";
const MANUAL_INPUT_RESOURCE = "Manual Input Resource Id";
const AUTO_GENERATE_RESOURCE = "Auto Generate Resource Id";

class ResourcePrintElement extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            rfidData: [],
            resourcePrintSeq: props.resourcePrintSeq,
            tagIdBase: props.tagIdBase,
            rangeCode: props.rangeCode,
            dataTypePrint: {
                module: "",
                objectidMaxLength: "",
                objectidPrefix: "",
                objectidSequence: "",
                rfidPrinting: "",
                tagSeqRangeCode: "",
                tagidBase: "",
                type: "",
                typeId: ""
            },
            disabledFormComponents: false,
            disabledInputFields: false
        };

        this.handleChangeRfidPrinting = this.handleChangeRfidPrinting.bind(this);
        this.handleChangeValue = this.handleChangeValue.bind(this);
        this.handleChangeTagIdBase = this.handleChangeTagIdBase.bind(this);
        this.handleAllowOnlyNumericValue = this.handleAllowOnlyNumericValue.bind(this);
    }

    static get propTypes(){
        return {
            onChangeElement: PropTypes.func,
            index: PropTypes.number.isRequired,
            dataTypePrint: PropTypes.object.isRequired,
        }
    }

    UNSAFE_componentWillMount(){
        this.setState({
            dataTypePrint: {
                objectidMaxLength: this.props.dataTypePrint.pObjSeqMaxLen,
                objectidPrefix: this.props.dataTypePrint.pObjIdPrefix,
                objectidSequence: this.props.dataTypePrint.pObjectSequence,
                rfidPrinting: this.props.dataTypePrint.pPrintFlag,
                tagSeqRangeCode: this.props.dataTypePrint.pRangeCode,
                tagidBase: this.props.dataTypePrint.pTagIDBase,
                type: this.props.dataTypePrint.pType,
                typeId: this.props.dataTypePrint.pTypeId
            },
            disabledFormComponents: this.props.dataTypePrint.pPrintFlag === DISABLED,
            disabledInputFields: this.props.dataTypePrint.pPrintFlag === MANUAL_INPUT_RESOURCE,
            rfidData: this.props.rfidData,
        });
        this.props.getResourceIdPrintSeq(this.props.dataTypePrint.pPrintFlag);
        this.props.getTagIdBase(this.props.dataTypePrint.pPrintFlag);
        this.props.getRangeCode(this.props.dataTypePrint.pTagIDBase);
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        if(nextProps.dataTypePrint !== this.props.dataTypePrint || nextProps.pObjSeqMaxLen !== this.props.pObjSeqMaxLen){
            this.setState({
                dataTypePrint: {
                    objectidMaxLength: nextProps.dataTypePrint.pObjSeqMaxLen,
                    objectidPrefix: nextProps.dataTypePrint.pObjIdPrefix,
                    objectidSequence: nextProps.dataTypePrint.pObjectSequence,
                    rfidPrinting: nextProps.dataTypePrint.pPrintFlag,
                    tagSeqRangeCode: nextProps.dataTypePrint.pRangeCode,
                    tagidBase: nextProps.dataTypePrint.pTagIDBase,
                    type: nextProps.dataTypePrint.pType,
                    typeId: nextProps.dataTypePrint.pTypeId
                },
                disabledFormComponents: nextProps.dataTypePrint.pPrintFlag === DISABLED,
                disabledInputFields: nextProps.dataTypePrint.pPrintFlag === MANUAL_INPUT_RESOURCE,
                rfidData: this.props.rfidData,
            })
        }

        if(nextProps.resourcePrintSeq !== this.props.resourcePrintSeq){
            this.setState({
                resourcePrintSeq: nextProps.resourcePrintSeq
            });
            this.props.onChangeElement(this.props.index, "pObjectSeq", nextProps.resourcePrintSeq[0]);
        }

        if(nextProps.tagIdBase !== this.props.tagIdBase){
            this.setState({
                tagIdBase: nextProps.tagIdBase
            });
            this.props.onChangeElement(this.props.index, "pTagIDBase", nextProps.tagIdBase[0]);
        }

        if(nextProps.rangeCode !== this.props.rangeCode){
            this.setState({
                rangeCode: nextProps.rangeCode
            });
            this.props.onChangeElement(this.props.index, "pRangeCode", nextProps.rangeCode[0]);
        }
    }

    handleChangeRfidPrinting(e){
        let dataTypePrint = this.state.dataTypePrint;
        dataTypePrint.rfidPrinting = e.target.value;
        if(e.target.value === AUTO_GENERATE_RESOURCE){
            this.props.onChangeElement(this.props.index, "pObjSeqMaxLen", "6");
            dataTypePrint.objectidMaxLength = "6";
            this.refs.pObjSeqMaxLen.value = "6";
        } else if(e.target.value === DISABLED || e.target.value === MANUAL_INPUT_RESOURCE){
            this.props.onChangeElement(this.props.index, "pObjSeqMaxLen", "");
            this.props.onChangeElement(this.props.index, "pObjIdPrefix", "");
            dataTypePrint.objectidMaxLength = "";
            dataTypePrint.objectidPrefix = "";
            this.refs.pObjSeqMaxLen.value = "";
            this.refs.pObjIdPrefix.value = "";
        }

        this.setState({
            dataTypePrint: dataTypePrint,
            disabledFormComponents: e.target.value === DISABLED,
            disabledInputFields: e.target.value === MANUAL_INPUT_RESOURCE,
        });
        this.handleChangeValue(e);
        this.props.getResourceIdPrintSeq(e.target.value);
        this.props.getTagIdBase(e.target.value);
    }

    handleChangeTagIdBase(e){
        this.handleChangeValue(e);
        this.props.getRangeCode(e.target.value);
    }

    handleAllowOnlyNumericValue(e){
        if (e.which !== 8 && e.which !== 0 && (e.which < 48 || e.which > 57)){
            e.preventDefault();
        }
    }

    handleChangeValue(e){
        this.props.onChangeElement(this.props.index, e.target.name, e.target.value);
    }

    render () {
        const {dataTypePrint, disabledFormComponents, disabledInputFields, rfidData, resourcePrintSeq, tagIdBase, rangeCode} = this.state;

        const optionRfidData = rfidData.map((item, index) => <option key={index} value={item}>{item}</option>);
        const optionResourcePrintSeq = resourcePrintSeq.map((item, index) => <option key={index} value={item}>{item}</option> );
        const optionTagIdBase = tagIdBase.map((item, index) => <option key={index} value={item}>{item}</option> );
        const optionRangeCode = rangeCode.map((item, index) => <option key={index} value={item}>{item}</option> );
        let fullWidth = true;
        return (
            <table className="data-entry-form" style={{width:(fullWidth ? '100%' : 'auto'),tableLayout:'fixed'}}>
            <thead />
                <tbody>
                    <tr>
                        <td className="basic-input">
                            <div className={"label"}>
                                Type
                            </div>
                            <div className={"field"}>
                                {dataTypePrint.type}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="basic-input">
                            <div className={"label"}>
                                RFID Printing?
                            </div>
                            <div className={"field"}>
                                <select name={"pPrintFlag"} onChange={this.handleChangeRfidPrinting} value={dataTypePrint.rfidPrinting}>
                                    {optionRfidData}
                                </select>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="basic-input">
                            <div className={"label"}>
                                ResourceId Sequence
                            </div>
                            <div className={"field"}>
                                <select name={"pObjectSeq"} onChange={this.handleChangeValue} disabled={disabledFormComponents}>
                                    {optionResourcePrintSeq}
                                </select>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="basic-input">
                            <div className={"label"}>
                                ResourceId Seq Max Length
                            </div>
                            <div className={"field"}>
                                <input name={"pObjSeqMaxLen"} ref={"pObjSeqMaxLen"} onChange={this.handleChangeValue} disabled={disabledFormComponents || disabledInputFields}
                                        onKeyPress={this.handleAllowOnlyNumericValue}
                                        defaultValue={dataTypePrint.objectidMaxLength}/>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="basic-input">
                            <div className={"label"}>
                                Resource Id Prefix for AutoGen
                            </div>
                            <div className={"field"}>
                                <input name={"pObjIdPrefix"} ref={"pObjIdPrefix"} onChange={this.handleChangeValue} disabled={disabledFormComponents || disabledInputFields}
                                    defaultValue={dataTypePrint.objectidPrefix}/>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="basic-input">
                            <div className={"label"}>
                                Tag Id Base
                            </div>
                            <div className={"field"}>
                                <select name={"pTagIDBase"} onChange={this.handleChangeTagIdBase} disabled={disabledFormComponents}>
                                    {optionTagIdBase}
                                </select>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="basic-input">
                            <div className={"label"}>
                                Range Code
                            </div>
                            <div className={"field"}>
                                <select name={"pRangeCode"} onChange={this.handleChangeValue} disabled={disabledFormComponents}>
                                    {optionRangeCode}
                                </select>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        getResourceIdPrintSeq: actions.getResourceIdPrintSeq,
        getTagIdBase: actions.getTagIdBase,
        getRangeCode: actions.getRangeCode,
        //changeObjSeqMaxLength: actions.changeObjSeqMaxLength,
    },dispatch)
});


const mapStateToProps = state => ({
    resourcePrintSeq: state.resourceTypePrint.resourcePrintSeq,
    tagIdBase: state.resourceTypePrint.tagIdBase,
    rangeCode: state.resourceTypePrint.rangeCode,
    pObjSeqMaxLen: state.resourceTypePrint.pObjSeqMaxLen
});

export default connect(mapStateToProps, mapDispatchToProps)(ResourcePrintElement);