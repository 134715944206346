import {CHANGE_VIEW} from '../constants/ActionTypes';

import {MAP_REPORT_DEFAULT} from '../constants/ViewTypes';

const initialState = {
    default:MAP_REPORT_DEFAULT
};

export function view(state=initialState,action){
    switch(action.type){
        case CHANGE_VIEW:
            let reportView={};
            if(action.reportId&&action.reportId!==''){
                reportView[action.reportId]=action.view;                                
            }                
            return {...state,...reportView};
        default:
            return state;
    }
}
