// Requires OlBase class

const CANVAS_BRIGHTEN = (img) => {
    const MINIMUM_BRIGHTNESS = 150;

    const convert = (value) => Math.floor(((255-MINIMUM_BRIGHTNESS)*value)/255)+MINIMUM_BRIGHTNESS;

    let i,l=img.data.length;
    for(i=0;i<l;i+=4){
        img.data[i] = convert(img.data[i]);
        img.data[i+1] = convert(img.data[i+1]);
        img.data[i+2] = convert(img.data[i+2]);
    }

    return img;
};

const CANVAS_COPY = (img,original)=>{
    let i,l=img.data.length;
    for(i=0;i<l;i++){
        img.data[i] = original.data[i];
    }

    return img;
};

const CANVAS_GRAY = (img) => {
    let i,l=img.data.length;

    for(i=0;i<l;i+=4){
        let r = img.data[i],
            g = img.data[i+1],
            b = img.data[i+2];

        let grey = Math.round(0.2126*r + 0.7152*g + 0.0722*b);//Math.round((r+g+b)/3);

        img.data[i] = grey;
        img.data[i+1] = grey;
        img.data[i+2] = grey;
    }
    return img;
};


export function olGreyMode(Base) {
  class OlGreyMode extends Base {
    
    #greyMode;
    
    constructor(mapRef) {
      super(mapRef);
      
      this.#greyMode = false;

    }
    
    get greyMode(){
        return this.#greyMode;
    }

    set greyMode(greyMode){
        if(this.#greyMode !== greyMode){
            this.#greyMode = greyMode;
            this.mapRef.render();

            if(this.#greyMode){
                this.mapRef.getLayers().getArray()[0].on('postrender', this.applyGreyMode);
            }else{
                this.mapRef.getLayers().getArray()[0].un('postrender', this.applyGreyMode);
            }
        }
    }

    applyGreyMode(evt){
        if (evt.context) {
            const cxt = evt.context;
            const canvas = cxt.canvas;
            const height = canvas.height;
            const width = canvas.width;

            try {
                let img_data = cxt.getImageData(0,0,width,height);
                let copy = cxt.createImageData(width,height);
                copy = CANVAS_COPY(copy,img_data);
                img_data = CANVAS_BRIGHTEN(CANVAS_GRAY(copy));
                cxt.putImageData(copy,0,0);
            } catch (error) {
                console.warn("grey filter failed");
            }
        }
    }
  }
  
  return OlGreyMode;
}