import Draw from 'ol/interaction/Draw';
import VectorSource from 'ol/source/Vector';
import VectorImageLayer from 'ol/layer/VectorImage';
import { zoneStyle } from './styles';
import { getRandomInt } from '../../helpers';
import { ItemType } from '../../constants';
import { Feature } from 'ol';
import { Polygon } from 'ol/geom';

export function olDrawZoneFactory(Base) {
    class OlDrawZoneFactory extends Base {
        draw = null;
        addSource = null;
        addLayer = null;
        
        isEnabledDraw() {
            return !!this.draw;
        }
        
        initializeDraw() {
            this.removeDraw();
            this.addSource = new VectorSource();
            this.addLayer = new VectorImageLayer({
                source: this.addSource,
                style: (feature) =>
                    zoneStyle(
                        feature,
                        this.color,
                        false,
                        this.showLabels,
                        this.measurementSystem,
                    ),
            });
            this.mapRef.addLayer(this.addLayer);
            
            this.draw = new Draw({
                source: this.addSource,
                type: 'Polygon',
                style: (feature) =>
                    zoneStyle(
                        feature,
                        this.color,
                        true,
                        this.showLabels,
                        this.measurementSystem,
                    ),
            });
            this.draw.set('addedInteraction', true);
            this.draw.set('addedInteractionType', 'draw');
            this.mapRef.addInteraction(this.draw);
            this.draw.on('drawstart', (event) => {
                const feature = event.feature;
                feature.setId(getRandomInt());
                feature.on('change', () => { return null;//this.collisionDetectionEvent([ feature, ...this.featuresCollection?.getArray().filter(feature => this.getFeatureItemType(feature) === ItemType.ZONE) ])
                     });
            });
            this.draw.on('drawend', (event) => {
                const feature = event.feature;
                feature.set('color', this.color);
                //const groupName = getNewZoneGroupName({ mapId, zones, selectedZoneIds });
                //const name = getNewZoneName({ gridName, zoneName, namesList, ind, mapId });
                const zone = this.getZoneData(feature);

                this.observer?.executeEvent('onFinishDrawZone', {
                    zone,
                    zones: [ ...this.getFeaturesDataArrayByItemType(ItemType.ZONE) ],
                });
                //this.collisionDetectionEvent(this.featuresCollection?.getArray().filter(feature => this.getFeatureItemType(feature) === ItemType.ZONE));
            });
            this.draw.on('drawabort', () => {
                //this.collisionDetectionEvent(this.featuresCollection?.getArray().filter(feature => this.getFeatureItemType(feature) === ItemType.ZONE));
            });
        }
        
        removeDraw() {
            if (this.addSource) {
                this.addSource.clear(true);
            }
            if (this.addLayer) {
                this.mapRef.removeLayer(this.addLayer);
            }
            this.addSource = null;
            this.addLayer = null;
            this.draw = null;
            const interactions = this.mapRef?.getInteractions()
                .getArray()
                .filter(interaction => interaction.get('addedInteractionType') === 'draw');
            for (const interaction of interactions) {
                this.mapRef?.removeInteraction(interaction);
            }
        }

        drawRandomZones(numZones) {
            const n = Math.round(Math.ceil(Math.sqrt(numZones)));
            // change this options
            const longitudeSide = 70; // length of the sides of a square
            const originSpaceBetween = 140; // length between the center point of the squares 

            const dx = [1 * longitudeSide / 2, -1 * longitudeSide / 2, -1 * longitudeSide / 2, 1 * longitudeSide / 2]; //directions
            const dy = [1 * longitudeSide / 2, 1 * longitudeSide / 2, -1 * longitudeSide / 2, -1 * longitudeSide / 2]; //directions
            const newZones = [];
            for (let x = 0; x < n; ++x) {
                const posX = x * originSpaceBetween;
                for (let y = 0; y < n; ++y) {
                    const posY = y * originSpaceBetween;
                    const coords = [];
                    for (let i = 0; i < 4; i++) {
                        coords.push([posX + dx[i], posY + dy[i]]);
                        if (i === 3) {
                            coords.push(coords[0]);
                        }
                    }
                    const featureId = 1000000 + y + (n * x);
                    const feature = new Feature({
                        color: '#' + ('000000' + Math.floor(Math.random() * 16777215).toString(16)).slice(-6),
                        geometry: new Polygon([coords]),
                        coords,
                        id: featureId,
                        name: 'zone' + featureId,
                        type: 'standard',
                        parentId: null,
                        childrenZones: [],
                        isFictional: true,
                    });
                    newZones.push(this.getZoneData(feature));
                }
            }

            const zones = newZones.map((zone) => ({
                ...zone,
                shape: {
                    geometry: {
                        coordinates: zone.coords,
                        type: 'Polygon'
                    },
                    type: zone.type
                }
            }));

            this.observer?.executeEvent('onDrawRandomZones', {
              zones,
            });
        }
    }
    
    return OlDrawZoneFactory;
}
