import React from 'react';
import PropTypes from 'prop-types';

import {ZoneVisibility} from '../LocalMap';
//import IconButton from 'material-ui/IconButton';
import IconButton from '@material-ui/core/IconButton';

import ZoneIcon from '../../../icons/ZoneIcon';
import ZoneAndLabelIcon from '../../../icons/ZoneAndLabelIcon';

// import AutoZoneAndLabelIcon from '@material-ui/icons/RemoveFromQueue';
// import AutoZoneIcon from '@material-ui/icons/Tv';
import AutoZoneIcon from '../../../icons/AutoZoneIcon';
import AutoZoneAndLabelIcon from '../../../icons/AutoZoneAndLabelIcon';
export default class ZoneToggleButton extends React.Component{
    static get propTypes(){
        return {
            mode: PropTypes.number,
        };
    }

    shouldComponentUpdate(nextProps){

        // this is the only thing that should change often enough
        // to warrant updating immediately.
        return (
            this.props.mode !== nextProps.mode
            || this.props.onClick !== nextProps.onClick
        );
    }

    render(){
        const {mode, style, ...other} = this.props;
        const dataFor = other['data-for'];
        const AUTOZONES = 'autoZones'
        let iconStyle = null, icon = null;
        switch(mode){
            case ZoneVisibility.ZONES_AND_LABELS:
                icon = dataFor === AUTOZONES ? <AutoZoneAndLabelIcon/> : <ZoneAndLabelIcon />;
                iconStyle = {
                    color: 'rgba(255,0,0,0.5)',
                    stroke:'black'
                };
                break;
            case ZoneVisibility.ZONES_ONLY:
                icon = dataFor === AUTOZONES ? <AutoZoneIcon/> : <ZoneIcon key={"zones"}/>;
                iconStyle = {
                    color: 'rgba(255,0,0,0.5)',
                    stroke:'black'
                };
                break;

            case ZoneVisibility.NO_ZONES:
            default:
                icon = dataFor === AUTOZONES ? <AutoZoneIcon/> : <ZoneIcon key={"no-zones"}/>;
                iconStyle = {
                    color: 'transparent',
                    stroke:'rgba(0,0,0,0.5)',
                    strokeDasharray: dataFor === AUTOZONES ? '0': '2,2'
                };
        }

        return (
            <IconButton {...other} disableRipple style={{...style, ...iconStyle}} >
                {icon}
            </IconButton>
        );
    }
}
