import {
    TYPE_FIELD_CHECK_BOX, TYPE_FIELD_COLOR_PICKER,
    TYPE_FIELD_COMBOBOX, TYPE_FIELD_GROUP_PURGE, TYPE_FIELD_INPUT_FILE, TYPE_FIELD_RADIO_GROUP,
    TYPE_FIELD_TEXT
} from "../../views/resourceTypes/constants/ResourceTypes";
import {
    VALIDATION_MESSAGE_REQUIRED,
    VALIDATION_TYPE_REQUIRED,
    VALIDATION_TYPE_REGEXP,
    VALIDATION_MESSAGE_REGULAR_CAHRACTERS_REQUIRED,
    TAG_OFFSET_FILTER_INFO,
    MOVE_MEDIAN_FILTER_INFO,
    RATE_FILTER_INFO,
    ZONE_LOCK_DOWN_FILTER_INFO,
    ZONE_CHANGE_FILTER_INFO, GROUP_PURGE_FIELD, PURGE_FIELD, PURGE_IN_DAYS_FIELD
} from "../../constants/ResourceTypes";
import {RANGE_NUMBER} from "../../constants";

const getField = (key,type,caption,placeholder,disabled, description,required=false, regex,fields=[],forceDisabled=false,range) =>{
    return {
        key:key,
        type:type,
        caption:caption,
        placeholder:placeholder,
        disabled: disabled!==undefined?disabled:false,
        description: description,
        required,
        regex,
        fields,
        forceDisabled,
        range
    }
}
export const RESOURCE_TYPE_FIELD = {
    'Identity':{
        name:'Identity',
        displayName: "Identity",
        fields:[
            getField('name',TYPE_FIELD_TEXT,'Name','Name',true,'',true, /^\w+$/),
            getField('displayName',TYPE_FIELD_TEXT,'Display Name','Display Name',false,'',true),
            getField('description',TYPE_FIELD_TEXT,'Description','Description'),
            getField('deadTimeS',TYPE_FIELD_TEXT,'Expiration Time in seconds','Expiration Time seconds'),
            // getField('callType',TYPE_FIELD_COMBOBOX,'Call Type','Call Type'),
            getField('autoDissociation',TYPE_FIELD_COMBOBOX,'Auto Dissociation','Auto Dissociation'),
            getField('autoDissociationByZone', TYPE_FIELD_CHECK_BOX,'Enable auto dissociation in dissociation zone', '', false),
            getField(GROUP_PURGE_FIELD, TYPE_FIELD_GROUP_PURGE,'groupPurge', '', false,null,null,null,[
                getField(PURGE_FIELD, TYPE_FIELD_COMBOBOX,'Select Purge Mode', '', false,null,null,null,null),
                getField(PURGE_IN_DAYS_FIELD, TYPE_FIELD_TEXT,'Purge In Days', '', true,null,null,null,null,true,{
                    min: 1,
                    errorMessageMinValue: "Minimum Value 1"
                })
            ]),

            // getField('rfidPrintingEnabled',TYPE_FIELD_COMBOBOX,'RFID Printing Enable','RFID Printing Enabled'),
        ]
    },
    'Filters':{
        name:'Filters',
        displayName: "Filters",
        fields:{
            filters :[
                {
                    type: getField('type', TYPE_FIELD_CHECK_BOX, 'TagOffset', 'tagOffset', false, TAG_OFFSET_FILTER_INFO),
                    x: getField('x', TYPE_FIELD_TEXT, 'x', '0'),
                    y: getField('y', TYPE_FIELD_TEXT, 'y', '0'),
                    z: getField('z', TYPE_FIELD_TEXT, 'z', '0',true)
                },
                {
                    type: getField('type', TYPE_FIELD_CHECK_BOX, 'Move/Median', 'moveMedianEnabled', false, MOVE_MEDIAN_FILTER_INFO),
                    radius: getField('radius', TYPE_FIELD_TEXT, 'Radius', '0'),
                    moveCount: getField('moveCount', TYPE_FIELD_TEXT, 'Move Count', '0'),
                    moveMedian: getField('moveMedian', TYPE_FIELD_TEXT, 'Move Median', '0'),
                    medianCount: getField('medianCount', TYPE_FIELD_TEXT, 'Median Count', '0'),
                    medianCountZ: getField('medianCountZ', TYPE_FIELD_TEXT, 'Median Count Z', '0'),
                },
                // {
                //     type: getField('type', TYPE_FIELD_CHECK_BOX, 'StationZone', 'stationZone', false, STATION_ZONE_FILTER_INFO),
                //     lockMode: getField('lockMode', TYPE_FIELD_COMBOBOX, 'LockMode', 'LockMode'),
                //     zoneUnlockDistance: getField('zoneUnlockDistance', TYPE_FIELD_TEXT, 'Zone Unlock Distance', '0'),
                // },
                {
                    type: getField('type', TYPE_FIELD_CHECK_BOX, 'Rate', 'rate', false, RATE_FILTER_INFO),
                    radius: getField('radius2', TYPE_FIELD_TEXT, 'Radius', '0'),
                    maxTime: getField('maxTime', TYPE_FIELD_TEXT, 'Max Time Threshold(sec)', '0'),
                    minTime: getField('minTime', TYPE_FIELD_TEXT, 'Min Time Threshold(sec)', '0')
                },
                {
                    type: getField('type', TYPE_FIELD_CHECK_BOX, 'ZoneLockDown', 'zoneLockdown', false, ZONE_LOCK_DOWN_FILTER_INFO),
                    count: getField('count', TYPE_FIELD_TEXT, 'Count', '0')
                },
                {
                    type: getField('type', TYPE_FIELD_CHECK_BOX, 'ZoneChange', 'zoneChange', false, ZONE_CHANGE_FILTER_INFO),
                    numBlinks: getField('numBlinks', TYPE_FIELD_TEXT, 'Num Blinks', '0')
                }
            ]
        }
    },


    'Attribute':{
        name:'Attribute',
        displayName: "Attribute",
        fields:[
            //getField('nameAttribute',TYPE_FIELD_TEXT,'Name','Name')
        ]
    },
    'IconConfiguration':{
        name:'Icon Configuration',
        displayName: "Icon Configuration",
        fields:[
            getField('iconEnable',TYPE_FIELD_RADIO_GROUP,'IconEnable','IconEnable',false),
            getField('iconId',TYPE_FIELD_COMBOBOX,'Select Icon','iconId'),
            getField('iconContent',TYPE_FIELD_INPUT_FILE,'File *','File'),
            getField('iconName',TYPE_FIELD_TEXT,'Name','Name',false,'',true),
            getField('iconDescription',TYPE_FIELD_TEXT,'Description','Description',false,'',true),
            getField('iconColor',TYPE_FIELD_COLOR_PICKER,'Color','Color'),
        ]
    },
    'ParentChildren':{
        name:'Parent Children',
        displayName: "Parent Children",
        fields:[
            getField('parentTypeId',TYPE_FIELD_COMBOBOX,'Parent Type','Parent Type',false),
            getField('groupUpdateMode',TYPE_FIELD_COMBOBOX,'Group Update Mode','Group Update Mode',true),
        ]
    },
};

export const VALIDATE_RESOURCE_TYPE_FIELD = {
    Identity: {
        name: {
            error: false,
            type: VALIDATION_TYPE_REGEXP,
            regexp: /^\w+$/,
            message: VALIDATION_MESSAGE_REGULAR_CAHRACTERS_REQUIRED,
            validateForms:[]
        },
        displayName: {
            error: false,
            type: VALIDATION_TYPE_REQUIRED,
            message: VALIDATION_MESSAGE_REQUIRED,
            validateForms:[]
        // },
        // tagProfile:{
        //     error: false,
        //     type: VALIDATION_TYPE_REQUIRED,
        //     message: VALIDATION_MESSAGE_REQUIRED,
        //     validateForms:[]
        // },
        // resourceProfile:{
        //     error: false,
        //     type: VALIDATION_TYPE_REQUIRED,
        //     message: VALIDATION_MESSAGE_REQUIRED,
        //     validateForms:[]
        },
        /*purge: {
            error: false,
            type: VALIDATION_TYPE_REGEXP,
            regexp: /^\w+$/,
            message: VALIDATION_MESSAGE_REGULAR_CAHRACTERS_REQUIRED,
            validateForms:[]
        },*/
        purgeInDays: {
            error: false,
            type: RANGE_NUMBER,
            range:{
                min: 1,
                errorMessageMinValue: "Minimum Value 1"
            },
            validateForms:[]
        },
    },
    Filters:{},
    Attribute: {},
    IconConfiguration: {
        iconName: {
            error: false,
            type: VALIDATION_TYPE_REQUIRED,
            message: VALIDATION_MESSAGE_REQUIRED,
            validateForms:[]
        },
        iconDescription: {
            error: false,
            type: VALIDATION_TYPE_REQUIRED,
            message: VALIDATION_MESSAGE_REQUIRED,
            validateForms:[]
        },
        iconContent:{
            error: false,
            type: VALIDATION_TYPE_REQUIRED,
            message: VALIDATION_MESSAGE_REQUIRED,
            validateForms:[]
        }
    },
    ParentChildren: {},
    ResourcesTypeAttribute:{
        resourceTypesFormAttributes:{
            error: false,
            type: "",//VALIDATION_TYPE_REQUIRED,
            message: VALIDATION_MESSAGE_REQUIRED,
            validateForms:[]
        },
    }
}
