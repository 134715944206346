export const getRandomInt = (min = 1, max = 4294967296) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
}

export const getDiffArray = (arrayOld, arrayNew, keysToCompare, options) => {
    const { getItemKey = (item) => item.key } = options || {};
    const diff = [];
    for (const itemOld of arrayOld) {
        const item = arrayNew.find((item) => (getItemKey(item) === getItemKey(itemOld)));
        if (item) {
            for (const key of keysToCompare) {
                if (JSON.stringify(item[key]) !== JSON.stringify(itemOld[key])) {
                    diff.push(item);
                    break;
                }
            }
        }
    }
    return diff;
};

export * from './form';
export * from './permissions';
export * from './zones';
