import React from 'react';
import { useDispatch } from 'react-redux';
import {viewDetailsJumpToSection} from '../../actions';
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

const NewValue = ({ rowIndex,value , typeSection}) => {

    const dispatch = useDispatch();
    const handleShowAttributes = () => {
        if (rowIndex !== undefined) {
            dispatch(viewDetailsJumpToSection(rowIndex));
        }
    };

    if (typeof value !== 'object') {
        return '';
    } else if (value != null&&typeof value === 'object' && !Array.isArray(value)) {
        if (typeSection === 'REPORT') {
            return (
                <div className={'actionContainer'}>
                    <div style={{textAlign: "center", paddingTop: "0.3em"}}>
                        <MoreHorizIcon onClick={handleShowAttributes} style={{fill: '#819dc9', cursor: "pointer"}}/>
                    </div>
                </div>
            );
        } else if ('DETAILS') {
            const htmlValues=[];
            for (const [key, objectValue] of Object.entries(value)) {
                if (objectValue != null && Array.isArray(objectValue) && objectValue.length > 0) {
                    htmlValues.push(<span>{key}: </span>);
                    htmlValues.push(<br/>);
                    objectValue.forEach(arrayValue => {
                        if (arrayValue != null && typeof arrayValue === 'object' && !Array.isArray(arrayValue)) {
                            for (const [keyArrayAttr, objectValueArrayAttr] of Object.entries(arrayValue)) {
                                htmlValues.push(<span style={{paddingLeft: '8px'}}>{keyArrayAttr}: {objectValueArrayAttr + ''}</span>);
                                htmlValues.push(<br/>);
                            }
                        }
                    });
                } else {
                    htmlValues.push(<span>{key}: {objectValue + ''}</span>);
                    htmlValues.push(<br/>);
                }
            }
            return htmlValues;
        }
    }else {return null;}
};

export default NewValue;
