import { Popover } from "@material-ui/core";
import { SketchPicker } from "react-color";

const DEFAULT_ANCHOR_ORIGIN = { horizontal: 'right', vertical: 'top' };
const DEFAULT_TRANSFORM_ORIGIN = { horizontal: 'right', vertical: 'bottom' };

const ColorPopover = ({ 
  color, 
  onChange, 
  anchorEl, 
  setAnchorEl, 
  anchorOrigin = DEFAULT_ANCHOR_ORIGIN,
  transformOrigin = DEFAULT_TRANSFORM_ORIGIN
}) => {
  
  const handleClose = () => {
    setAnchorEl(null);
  }

  const handleColorChange = color => {
    onChange(color.hex);
  }

  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
    >
      <SketchPicker
        color={color}
        onChangeComplete={handleColorChange}
        disableAlpha
      />
    </Popover>
  );
}

export default ColorPopover;