import {FILTER_TYPE, getLabelForFilter} from '../views/util/report/filter/util';
import { ICService, LocationConfigService, ReportService, ResourceService, SiteViewService } from '../actions/util';
import {getTreePathPayload, getValidTreePathPayload} from '../views/util/report/filter/utils/treeUtils';
import {getValueOfPath} from "./reports";
import {convertSegTo___D__H__M__S} from "../views/onePlatform/LayoutContent/FilterLoader/Filters/InequalityFilter";

// TODO: Check the function
export const isValueAll = (value) => !value || value === '%' || value === '' || value === 'All';

export const processFiltersValues = (filters) => {
    const filterObject = {};
    for (const filterKey in filters) {
        const filter = filters[filterKey];
        let requestKey = filter.requestKey || filterKey;
        let values = isValueAll(filter.value) ? '' : filter.value;
        if (filter.type === FILTER_TYPE.TREE && filter.hasOwnProperty('modeRequest')) {
            const { treeSiteName } = values || {};
            const { requestKey, path } = filter.locationPaths || {};
            
            // only validating site name, because if it exists then the tree exists
            if (path && treeSiteName && treeSiteName.length) {
                filterObject[requestKey] = getTreePathPayload(values, path);
            }
        } else {
            if (filter.type === FILTER_TYPE.MULTISELECT_AUTO_SUGGEST || filter.type === FILTER_TYPE.SELECT) {
                if (Array.isArray(values)) {
                    values = values.map(value => value.value).join(filter.separatorCharacter || ',');
                    // values = isValueAll(values) ? '' : values;
                } else {
                    values = '';
                }
            }
            if (filter.hasOwnProperty('JSONFormatValue') && filter.JSONFormatValue && values !== '') {
                filterObject[requestKey] = { values: values.split(',') };
            }else if (filter.hasOwnProperty('JSONFormatValueSimple') && filter.JSONFormatValueSimple && values !== '' && (Array.isArray(values) && values.length>0)) {
                const valuesName = values.map(option=>getValueOfPath(option, filter.optionValuePath));
                filterObject[requestKey] = { values: valuesName };
            }else if(filter.type === FILTER_TYPE.TIME){
                //filterObject[requestKey] = filter.rangeMode === PERIOD.NOW?null:values;
                filterObject[requestKey] = values;
            }
            else {
                if (!(filter.hasOwnProperty('extraFilter') && filter.extraFilter)) {
                    filterObject[requestKey] = values;
                }
            }
        }
        // filterObject[requestKey] = (filter.value && !isValueAll(filter.value)) ? filter.value : filters[filterKey].defaultValue;
    }

    const {attributes} = filters||{};
    const {resourceTypeParent} = attributes || "";
    let resourceTypeKey = null;
    if(filters && resourceTypeParent){
        resourceTypeKey = filters.hasOwnProperty(resourceTypeParent)?resourceTypeParent:null;
    }
    if (filters && resourceTypeKey) {
        //if (filters && filters.type===FILTER_TYPE.RESOURCE_TYPE) {
        for (const filterKey in filters[resourceTypeKey]?.children) {
            filterObject[filterKey] = filters[resourceTypeKey]?.children[filterKey]?.value;
        }
    }
    
    return filterObject;
};

export const makeFilterObjectQuery = (filterObject) => { // Object returned of processFiltersValues
    const filterData = {};
    for (const filterKey in filterObject) {
        if (filterObject[filterKey]) {
            filterData[filterKey] = filterObject[filterKey];
        }
    }
    return filterData;
};

export const processFilters = (filters) => {
    const processedFilters = {};
    if (!filters) {
        return processFilters;
    }
    let index = 1;
    Object.values(filters)
        .sort((a, b) => a.order - b.order)
        .forEach(filter => {
            if (filter.type === FILTER_TYPE.DATETIME_FROM || filter.type === FILTER_TYPE.DATETIME_TO || filter.type === FILTER_TYPE.DATETIME_RANGE_MODE) {
                const name = filter.name;
                if (!processedFilters[name]) {
                    processedFilters[name] = {
                        key: name,
                        filter: {
                            order: index++,
                            key: name,
                            name,
                            enabled: false,
                            display: false,
                            type: FILTER_TYPE.DATETIME,
                        },
                    };
                }
                if (!processedFilters[name].displayName && filter.displayName) {
                    processedFilters[name].filter.displayName = filter.displayName;
                }
                if (filter.type === FILTER_TYPE.DATETIME_TO) {
                    processedFilters[name].filter.to = filter;
                }
                if (filter.type === FILTER_TYPE.DATETIME_RANGE_MODE) {
                    processedFilters[name].filter.rangeMode = filter;
                }
                if (filter.type === FILTER_TYPE.DATETIME_FROM) {
                    processedFilters[name].filter.from = filter;
                }
                processedFilters[name].filter.enabled = processedFilters[name].filter.enabled || filter.enabled;
                processedFilters[name].filter.display = processedFilters[name].filter.display || filter.display;
            } else if (filter.type === FILTER_TYPE.TREE) {
                const name = filter.parentTree || filter.key;
                if (!processedFilters[name]) {
                    processedFilters[name] = {
                        key: name,
                        filter: {
                            order: index++,
                            name: filter.name || filter.key,
                            ...filter,
                            urls: {
                                site: {
                                    service: null,
                                    url: null,
                                },
                                map: {
                                    service: null,
                                    url: null,
                                },
                                zonegroup: {
                                    service: null,
                                    url: null,
                                },
                                zone: {
                                    service: null,
                                    url: null,
                                },
                            },
                        },
                    };
                }
                let service = ReportService;
                switch (filter.service) {
                    case 'ResourceService':
                        service = ResourceService;
                        break;
                    case 'ICService':
                        service = ICService;
                        break;
                    case 'LocationConfigService':
                        service = LocationConfigService;
                        break;
                    case 'SiteViewService':
                        service = SiteViewService;
                        break;
                    default:
                }
                switch (filter.key) {
                    case 'siteName':
                        processedFilters[name].filter.urls.site.service = service;
                        processedFilters[name].filter.urls.site.url = filter.valuesPath;
                        break;
                    case 'locationGroup':
                        processedFilters[name].filter.urls.zonegroup.service = service;
                        processedFilters[name].filter.urls.zonegroup.url = filter.valuesPath;
                        break;
                    case 'location':
                        processedFilters[name].filter.urls.zone.service = service;
                        processedFilters[name].filter.urls.zone.url = filter.valuesPath;
                        break;
                    default:
                }
            } else {
                processedFilters[filter.key] = {
                    key: filter.key,
                    filter: {
                        ...filter,
                        order: index++,
                        name: filter.name || filter.key,
                    },
                };
            }
        });
    Object.values(processedFilters)
        .forEach(filter => {
            if (filter.filter.type === FILTER_TYPE.DATETIME) {
                filter.getLabel = () => {
                    return getLabelForFilter({ rangeMode: filter.filter.rangeMode?.value, fromTime: new Date(filter.filter.from.value), toTime: new Date(filter.filter.to.value), nextPrevButtons: true });
                };
            } else if(filter.filter.type === FILTER_TYPE.TREE) {
                filter.getLabel = () => {
                    const showFullPath = filter.filter.showFullPath;
                    const valueTree = getValidTreePathPayload(filter.filter?.value);
                    if(valueTree ===''){
                        return 'All';
                    }
                    const DELIMITER = "\f";

                    if(showFullPath){
                        const values = valueTree.replaceAll(DELIMITER,"/");
                        const listValues = values.split(",");
                        return listValues.length>0?listValues.join(', '):"";
                    }else{
                        const listValues = valueTree.split(",");
                        const lastValues = [];
                        listValues.forEach(value=>{
                            const valueFilterSite = value.split(DELIMITER);
                            const lastValue = valueFilterSite.length>0?valueFilterSite[valueFilterSite.length-1]:null;
                            if(value){
                                lastValues.push(lastValue);
                            }
                        })
                        return lastValues.length>0?lastValues.join(', '):"";
                    }
                }
            }else {
                filter.getLabel = () => {
                    let value = '';
                    if (filter.filter.value != null && filter.filter.value!=="") {
                        if (Array.isArray(filter.filter.value)) {
                            if (filter.filter.value.length > 0){
                                if(filter.filter.type === FILTER_TYPE.RESOURCE_TYPE_WITH_DISPLAY_NAME){
                                    value = filter.filter.value.map(opt => opt.name).join(', ');
                                }else{
                                    value = filter.filter.value.map(opt => opt.value).join(', ');
                                }
                            }
                            else
                                value = 'All';
                        }
                        else if (filter.filter.value === "%") {
                            value = 'All';
                        }
                        else {
                            value = filter.filter.value === "" ? 'All' : filter.filter.value;
                        }
                    }
                    else{
                        value='All';
                    }
                    if(filter.filter.type  === FILTER_TYPE.INEQUALITY){
                        return convertSegTo___D__H__M__S(value);
                    }else{
                        return value;
                    }
                };
            }
        });
    
    return processedFilters;
};

export const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
};

export const getObjectFromPattern = (object, pattern) => {
    const paths = pattern.split('.');
    let result = object;
    for (const path of paths) {
        if (result[path]) {
            result = result[path];
        } else {
            console.warn('getObjectFromPattern', { result, path });
            return result;
        }
    }
    return result;
};

export const transformDateToISOformat = date => {
    return (date.toString().indexOf('GMT')>-1)?date.toISOString(): date;
}