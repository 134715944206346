import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import {generateComponentId} from "../../../utils/ComponentUtils";
import {ListItemIcon} from "@material-ui/core";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import MoveIcon from '@material-ui/icons/Reorder';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import {useI18n} from "../../../hooks/i18n";

const itemType = "item";

const SortableItem = ({ id, index, value, checked, onChange, onClick, moveItem }) => {
    const ref = useRef(null);
    const [, drop] = useDrop({
        accept: itemType,
        hover(item, monitor) {
            if(!ref.current) {
                return
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current.getBoundingClientRect()
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            // Determine mouse position
            const clientOffset = monitor.getClientOffset()
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }
            // Time to actually perform the action
            moveItem(dragIndex, hoverIndex)
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
    });

    const [{isDragging}, drag, preview] = useDrag({
        item: { type: itemType, id, index },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return(
        <div className="drag-handle-checklist" ref={preview} onClick={onClick} style={{zIndex: 1501, listStyleType: 'none'}}>
            <ListItem
                key={index}
                button
                dense
                style={{maxHeight: 40, paddingLeft: 10, backgroundColor: isDragging ? '#eeeeee' : null }}>
                <ListItemIcon className={"drag-handle-checklist-icon"}>
                    <Checkbox edge="start"
                        id={generateComponentId(value, null, "checkbox")}
                        className={"drag-handle-checklist-checkbox"}
                        checked={checked}
                        onChange={onChange}
                        style={{backgroundColor:'transparent', color: checked?'#43a047':'black'}}
                    />
                </ListItemIcon>
                <ListItemText primary={value} className={"drag-handle-checklist-text"}/>
                <ListItemSecondaryAction>
                    <div ref={ref} className={"drag-handle"}><MoveIcon /></div>
                </ListItemSecondaryAction>
            </ListItem>
        </div>
    );

};

const SortedChecklist = props => {
    const { translate } = useI18n('common.filterTable');
    const moveItem = (dragIndex, hoverIndex) => {
        props.onMoveItem(dragIndex, hoverIndex);
    }

    const checkItem = (index,checked) => {
        props.onCheckItem(index,!checked);
    }

    let {
        data,
        selectAllText,
        listItemClassName,
        keyAttribute,
        labelAttribute,
        checkedAttribute,
        onCheckSelectAll,
    } = props;
    
    let selectAllChecked = !data.some((v)=>!v.enabled);
    let items = data.map((v,i)=>{
        return (
            <SortableItem
                id={v.id}
                checked={v[checkedAttribute]}
                onClick={() => checkItem(i,v[checkedAttribute])}
                onChange={() => checkItem(i,v[checkedAttribute])}
                key={v[keyAttribute]}
                index={i}
                value={v[labelAttribute]? translate(v[labelAttribute]):translate(v.displayName)}
                moveItem={moveItem}
            />
        );
    });

    return(
        <div>
            <List  style={{paddingTop:8, paddingBottom:8, width:290}}>
                <ListItem
                    key={generateComponentId(listItemClassName, null, "checkbox")}
                    dense
                    role={undefined}
                    button
                    onClick={(event) => onCheckSelectAll(event, !selectAllChecked)}
                    style={{height:30, paddingLeft:10}}
                    >
                    <ListItemIcon style={{height:30,minWidth:'35px'}}>
                        <Checkbox id={generateComponentId(listItemClassName, null, "checkbox")}
                                    edge="start"
                                    checked={selectAllChecked}
                                    onChange={onCheckSelectAll}
                                    style={{backgroundColor:'transparent', color: selectAllChecked?'#43a047':'black'}}
                        />
                    </ListItemIcon>
                <ListItemText primary={translate(selectAllText)}/>
                </ListItem>
                <Divider />
                <DndProvider backend={Backend}>
                    {items}
                </DndProvider>
            </List>
        </div>
    );
}

SortedChecklist.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.object
    ).isRequired,
    onMoveItem: PropTypes.func,
    onCheckItem: PropTypes.func,
    keyAttribute: PropTypes.string,
    selectAllText: PropTypes.string,
    listItemClassName: PropTypes.string,
    labelAttribute: PropTypes.string,
    checkedAttribute: PropTypes.string,
    onCheckSelectAll: PropTypes.func,
    onChange: PropTypes.func,
};

SortedChecklist.defaultProps = {
    keyAttribute: 'id',
    labelAttribute: 'name',
    checkedAttribute: 'checked',
    listItemClassName: 'list-item'
}

export default SortedChecklist;
