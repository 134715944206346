export const NONE = 'NONE';
export const LOADING = 'LOADING';
export const SUCCESS = 'SUCCESS';
export const ERROR = 'ERROR';
export const MESSAGE_WILDCARD_NOT_ALLOWED = 'Wildcard not allowed with multiselect search.';
export const ALL = 'All';

export const ALPHABET = (() => {
    let alphabet = '';
    for (let i = 32; i < 127; ++i) {
        alphabet += (String.fromCharCode(i));
    }
    return alphabet;
})();

export const ON_OPEN = 'ON_OPEN';
export const ON_INPUT_CHANGE = 'ON_INPUT_CHANGE';
export const ON_INIT = 'ON_INIT';
export const ON_OPEN_DEPENDANT = 'ON_OPEN_DEPENDANT';