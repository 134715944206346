import React from 'react';
import {Link} from 'react-router-dom';
import FlatButton from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import {SITE_VIEW_HOST} from "../../../../constants/Misc";
import {RESOURCE_ALERT_SETTING} from "../../../../actions";
import Divider from "@material-ui/core/Divider";

class MobileSubMenuContainer extends React.Component {

    static get propTypes() {
        return {
            id: PropTypes.string,
            toggle: PropTypes.func,
            setSubMenuActive: PropTypes.func
        }
    }

    /**
     * Calls the toggle method and set the SubMenu active sendig the subMenuKey as parameter
     * @param subMenuKey
     */
    handleOnClick(subMenuKey) {
        this.props.toggle();
        this.props.clickSubMenu(subMenuKey);
    }

    /**
     * Generates the elements for every subMenu for the Menu list on Mobile
     * @param props
     * @returns {*}
     */
    createSubMenu(props) {
        const baseUrl = SITE_VIEW_HOST;
        // const styles = {
        //     color: 'black',
        //     paddingLeft: '5px',
        //     fontSize: '12px',
        //     fontWeight: 'bold',
        //     backgroundColor:'#d9dee0',
        //     marginRight:'0em'
        // };
        return props.menuList.map(function (childrenList) {
            let classButtonSubmenu = "button-submenu";
            if (props.subMenuActive !== null) {
                if (props.subMenuActive === childrenList.routes) {
                    classButtonSubmenu += " active-submenu";
                }
            } else {
                const pathArray = window.location.hash;
                const routeAux = "#" + childrenList.routes;
                if (pathArray === routeAux) {
                    classButtonSubmenu += " active-submenu";
                }
            }
            if (childrenList.category === 'Configuration' && childrenList.label === RESOURCE_ALERT_SETTING) {
                return (

                    <Link to={"#"} key={childrenList.name}
                          onClick={() => window.open("https:" + baseUrl + "/business-rules/businessRulesManager")}
                          replace>
                        <FlatButton
                            className={classButtonSubmenu}
                            ref={"button"}
                            label={childrenList.name}
                            style={{textTransform: "capitalize", justifyContent: 'initial'}}
                            variant="text"
                        >{childrenList.label}</FlatButton>
                    </Link>);

            }
            if (childrenList.type === 'dashboard') {
                switch (childrenList.id) {
                    case "2":
                        return (

                            <Link to={"#"} key={childrenList.name}
                                  onClick={() => window.open(baseUrl + "/app-grafana")} replace>
                                <FlatButton
                                    className={classButtonSubmenu}
                                    ref={"button"}
                                    label={childrenList.name}
                                    style={{textTransform: "capitalize", justifyContent: 'initial'}}
                                    variant="text"
                                >{childrenList.text}</FlatButton>
                            </Link>);
                    case "3":
                        return (
                            <Link to={"#"} key={childrenList.name}
                                  onClick={() => window.open(baseUrl + "/app-prometheus")} replace>
                                <FlatButton
                                    className={classButtonSubmenu}
                                    ref={"button"}
                                    label={childrenList.name}
                                    style={{textTransform: "capitalize", justifyContent: 'initial'}}
                                    variant="text"
                                >{childrenList.text}</FlatButton>
                            </Link>);
                    case "4":
                        return (
                            <div key={"AlertManager"}>
                                <Divider  />
                                <Link  to={"#"} key={childrenList.name}
                                      onClick={() => window.open(baseUrl + "/app-alertmanager")} replace>
                                    <FlatButton
                                        className={classButtonSubmenu}
                                        ref={"button"}
                                        label={childrenList.name}
                                        style={{textTransform: "capitalize", justifyContent: 'initial'}}
                                        variant="text"
                                    >{childrenList.text}</FlatButton>
                                </Link>
                            </div>);
                    case "5":
                        return (
                            <Link  to={"#"} key={childrenList.name}
                                  onClick={() => window.open(baseUrl + "/app-kibana/app/kibana#/home")} replace>
                                <FlatButton
                                    className={classButtonSubmenu}
                                    ref={"button"}
                                    label={childrenList.name}
                                    style={{textTransform: "capitalize", justifyContent: 'initial'}}
                                    variant="text"
                                >{childrenList.text}</FlatButton>
                            </Link>);

                    case "6":
                        return (
                            <Link to={"#"} key={childrenList.name}
                                  onClick={() => window.open(baseUrl + "/app-kibana/s/logs/app/kibana#/home")} replace>
                                <FlatButton
                                    className={classButtonSubmenu}
                                    ref={"button"}
                                    label={childrenList.name}
                                    style={{textTransform: "capitalize", justifyContent: 'initial'}}
                                    variant="text"
                                >{childrenList.text}</FlatButton>
                            </Link>);
                    default:
                        return (<Link to={childrenList.routes} key={childrenList.name}
                                      onClick={this.handleOnClick.bind(this, childrenList.routes)} replace>
                            <FlatButton
                                className={classButtonSubmenu}
                                ref={"button"}
                                label={childrenList.name}
                                style={{textTransform: "capitalize", justifyContent: 'initial'}}
                                variant="text"
                            >{childrenList.text}</FlatButton>
                        </Link>);
                }
            } else if(childrenList.externalUrl){
                return (
                    <Link to={"#"} key={childrenList.name}
                          onClick={() => window.open(childrenList.path)}
                          replace>
                        <FlatButton
                            className={classButtonSubmenu}
                            ref={"button"}
                            label={childrenList.name}
                            style={{textTransform: "capitalize", justifyContent: 'initial'}}
                            variant="text"
                        >{childrenList.label}</FlatButton>
                    </Link>);
            }else{
                return (
                    <Link to={childrenList.routes} key={childrenList.name}
                          onClick={this.handleOnClick.bind(this, childrenList.routes)} replace>
                        <FlatButton
                            className={classButtonSubmenu}
                            ref={"button"}
                            label={childrenList.name}
                            style={{textTransform: "capitalize", justifyContent: 'initial'}}
                            variant="text"
                        >{childrenList.name} {childrenList.isShared && childrenList.category === 'Saved'?<i style={{textTransform:'none',paddingLeft:'2px'}}>(shared)</i>:null}</FlatButton>
                    </Link>
                );
            }

        }, this);
    }

    // Main render.
    render() {
        const {id} = this.props;
        return (
            <div id={id} className={'child-links'}>
                {this.createSubMenu(this.props)}
            </div>);
    }
}

export default MobileSubMenuContainer;