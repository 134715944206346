const appliances = [{
    caption:"Site",
    name:"siteId",
    sort:0,
    filter:true,
    enabled:true
},{
    caption:"Appliance",
    name:"name",
    sort:0,
    filter:true,
    enabled:true
},{
    caption:"Status",
    name:"serviceStatus",
    sort:0,
    filter:true,
    enabled:true
},{
    caption:"Firmware Version",
    name:"softwareVersion",
    sort:0,
    filter:true,
    enabled:true
},{
    caption:"Last Firmware Update",
    name:"softwareUpgradeStatus",
    sort:0,
    filter:true,
    enabled:true
},{
    caption:"Last Config Update",
    name:"configStatus",
    sort:0,
    filter:true,
    enabled:true
},{
    caption:"Message Filters",
    name:"messageFiltersProfileName",
    sort:0,
    filter:false,
    enabled:true
},{
    caption:"Seconds Since Last Blink",
    name:"secSinceLastBlink",
    sort:0,
    filter:false,
    enabled:true
},{
    caption:"Seconds Since Last Heartbeat",
    name:"secSinceLastHeartbeat",
    sort:0,
    filter:false,
    enabled:true
}];

export default appliances;