import {CLOUD_IMPORT_UPDATE_SELECTED_DEVICES, LOAD_DEVICES_VIA_ZEBRA_DATA_SERVICE, SET_CLOUD_DEVICES_DATA, INIT_STATUS_CLOUD_DEVICES} from "../constants/ActionTypes";

const initialState={
    cloudDevices:[],
    deviceModelAndType:{},
    selectedRows:{},
    statusCloudDevices: {}
}

export function zebraDataService(state = initialState, action) {

    switch (action.type) {

        case LOAD_DEVICES_VIA_ZEBRA_DATA_SERVICE:
            return {
                ...state,
                cloudDevices: action.cloudDevices,
                deviceModelAndType:action.deviceModelAndType
            }
        case CLOUD_IMPORT_UPDATE_SELECTED_DEVICES:
            return {
                ...state,
                selectedRows: action.selectedRows,
            };
        case SET_CLOUD_DEVICES_DATA:
            return {
                ...state,
                statusCloudDevices: {...state.statusCloudDevices, [action.deviceId]: action.stateSaved}
            };
        case INIT_STATUS_CLOUD_DEVICES:
            return {
                ...state,
                statusCloudDevices: {}
            }
        default:
            return state;
    }
}