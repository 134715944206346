import {makeStyles, Tooltip} from "@material-ui/core";
import React, {useState} from "react";
import InfoIcon from '@material-ui/icons/Info';
const useStyles = makeStyles({
    tooltip: {
        fontSize: '14px',
        color: "#fff",
        background: "#222",
        padding: 8,
        transition: "background .2s"
    },
    arrow: {
        color: '#222'
    }, popper: {
        zIndex: 0
    }
});

const Information = ({title,placement="top",styleInformation={}}) => {
    const [isHover, setIsHover] = useState(false);
    const classes = useStyles();
    //const styleIcon = {padding:'-1em -1em -1em -1em',backgroundColor:'red',margin:'0px'};
    const styleIcon = styleInformation?styleInformation:{padding:'0.2em'};
    return (
        <Tooltip title={title} placement={placement} open={isHover} arrow={true}
                 PopperProps={{disablePortal: false}}
                 classes={{tooltip: classes.tooltip, arrow: classes.arrow, popper: classes.popper}}>
            <span style={styleIcon}
                onMouseOver={() => {
                    setIsHover(true)
                }}
                onMouseLeave={() => {
                    setIsHover(false)
                }}>
                <InfoIcon fontSize={"small"}/>
            </span>
        </Tooltip>
    );
}
export default Information;