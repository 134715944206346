export const CONFIGURATION = {
    "documentTitle": "MotionWorks Enterprise UI Configuration File",

    "ics":{
        "baseUrl":"//localhost/mwe/infrastructure/"
    },

    "reportService":{
        "baseUrl":"//localhost/mwe"
        //"baseUrl":"//localhost:8181/report-service"
    },

    "accessControlService":{
        "baseUrl":"//localhost/mwe/usermgmt"
    },

    "authService":{
        "baseUrl":"//localhost/mwe"
    },

    "streamService":{
        "baseUrl":"//localhost",
        "path" : "/mwe/stream/socket.io"
    },

    "importService":{
        "baseUrl":"//localhost/mwe/import"
    },

    "locationConfigurationService":{
        "baseUrl":"//localhost/mwe/v2"
    },
    "osmSource": "//localhost/tileserver",

    "zoneBuilder":{
        "enabled": true
    },

    "siteViewService":{
        "baseUrl":"//localhost"
    },

    "deviceManagementService":{
        "baseUrl":"//localhost/mwe"
    },
    "resourceService":{
        "baseUrl":"//localhost/mwe"
    },

    "searchService": {
        "baseUrl":"//localhost/mwe/search"
    },
    "alertService": {
        "baseUrl": "//localhost/mwe/v2/alert"
    },
    "directionService": {
        "baseUrl": "//localhost/mwe/v2"
    },
    "fileService": {
        "baseUrl":"//localhost/mwe/v2"
    },
};