// Validation fields
export const VALIDATION_TYPE_CUSTOM = "VALIDATION_TYPE_CUSTOM";
export const VALIDATION_TYPE_REGEXP = "VALIDATION_TYPE_REGEXP";
export const VALIDATION_MESSAGE_NEGATIVE_VALUE = "You should provide negative value";
export const VALIDATION_TYPE_REQUIRED = "VALIDATION_TYPE_REQUIRED";
export const VALIDATION_MESSAGE_REQUIRED = "Mandatory field";
export const VALIDATION_LOOKUP_MESSAGE_REQUIRED = "Need at least one value";
export const HIGHLIGHT_STYLE = "0px 0px 4px #4195fc";
export const VALIDATION_MESSAGE_REGULAR_CAHRACTERS_REQUIRED = "Mandatory field can not contain spaces or other special characters";
//export const VALIDATION_MESSAGE_REGULAR_CHARACTERS_ATTRIBUTE_REQUIRED = "Attribute field name cannot contain spaces or other special characters";
export const VALIDATION_MESSAGE_REGULAR_CHARACTERS_ATTRIBUTE_REQUIRED = "Cannot contain spaces or other special characters";
//export const VALIDATION_MESSAGE_REGULAR_CHARACTERS_ATTRIBUTE_NUMBER_REQUIRED = "Attribute field name cannot start with a number";
export const VALIDATION_MESSAGE_REGULAR_CHARACTERS_ATTRIBUTE_NUMBER_REQUIRED = "Cannot start with a number";
export const VALIDATION_DUPLICATED_VALUES_IN_ARRAY = "VALIDATION_DUPLICATED_VALUES_IN_ARRAY";
export const VALIDATION_MESSAGE_DUPLICATED_TAGID = "Declared duplicate Tag ID";
export const VALIDATION_TYPE_JSON = "Invalid JSON";

export const TAG_OFFSET_FILTER_INFO= ["Tag location reported by sensors/readers is shifted by MWE by specified x,y,z values. This is useful when tags sit close to two zone boundaries and it is desirable to report tags in the center of a zone."];
export const MOVE_MEDIAN_FILTER_INFO=["Median Count must be an odd number (max allowed value is 7). If tag blinks a (Move Count) number of consecutive blinks a distance (Radius, in feet) from the last median value, the median filter will be reset and the new location will be reported."];
export const STATION_ZONE_FILTER_INFO=["Valid lockMode are noLock, xyLock, zoneLock. If in zoneLock mode, location's x, y are checked against station's zone. If x, y are inside the zone, no adjustment is needed. Otherwise, distance to the zone are calculated. If it is smaller than zoneUnlockDistance, snap to the edge of the zone. Otherwise no adjustment is needed, and zone outside of station's zone is reported. If in xyLock mode, when blink with stationId, defined location of station is used for blink location"];
export const ZONE_LOCK_DOWN_FILTER_INFO=["After a (Count) number of blinks in a new zone, a tag will be reported at fixed x,y (the location of blink # (Count)) until the tag blinks outside the zone."];
export const ZONE_CHANGE_FILTER_INFO=["MWE will report a tag in a new zone and generate a zoneChange event only after the tag has blinked a (Num Blinks) number of consecutive times inside that zone."];
export const RATE_FILTER_INFO=["Time based filter: Min Time Threshold specifies minimum time interval to allow repeated tag blinks. A value of 0 disables it.",
    "Distance based filter: A tag blink must happen a distance (Radius) away from previously reported tag blink to be allowed through by the filter. But if the tag is static for a long time, the filter can let a blink through every (Max Time Threshold) seconds even if the tag has not moved, to let know users that the tag is present and alive."];


export const BACKGROUND_TOOLTIP = '#e6ee31';

export const GROUP_PURGE_FIELD = 'groupPurge';
export const PURGE_FIELD = 'purge';
export const PURGE_IN_DAYS_FIELD = 'purgeInDays';
export const PURGE_FIELD_VALUES = {
    NONE:'None'
}