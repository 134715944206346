import React from 'react';
import Paper from "@material-ui/core/Paper";

export default class AlertScreen extends React.Component{
    render(){
        const {message} = this.props;
        return (
            <div className={"alert-container"}>
                <div className={"alert-content"}>
                    <Paper style={{display:'inline-block',padding:'2em'}}>{message}</Paper>
                </div>
            </div>
        );
    }
}