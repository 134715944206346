import moment from 'moment/moment.js';
import {getLabelForFilter, getRangeMode} from './../../views/util/report/filter/util';
import ColumnTable from './../../views/import/toolbars/Column.json';
import {downloadImport} from './downloadReport';

export function getImportFilters(filters, columns){
    let importFilters = [];
    Object.keys(columns).forEach(key =>{
        if(columns[key].type === 'datetime'){
            Object.keys(filters).forEach(f=>{
                if(String(filters[f].name).toUpperCase() === String('from' + columns[key].name).toUpperCase()){
                    importFilters.push(filters[f]);
                }
                if(String(filters[f].name).toUpperCase() === String('to' + columns[key].name).toUpperCase()){
                    importFilters.push(filters[f]);
                }
            });
        }else{
            importFilters.push(filters[columns[key].filter]);
        }
    })
    return importFilters;
}

export function parseFiltersToParams(filters) {
    let obj = {}
    filters.forEach(filter =>{
        if(filter.type === 'datetime'){
            obj[filter.filterSelector] = moment(filter.value).toDate();
        }else{
            obj[filter.filterSelector] = filter.value
        }
    })
    return obj;
}


export function parseFilterConstraints(filters, filter) {
    if (filter.name === 'action'){
        const reportSelected = filters.find(item => item.name === "report")['value'];
        if(reportSelected !== '%'){
            return {'reportName' : reportSelected};
        }else{
            return {};
        }
    }else{
        return {};
    }
}


function getFiltersSummary(filters) {
    return filters.map(filter => {
        return `${filter.displayName} = ${filter.value==='%'?'All': filter.value}`;
    });
}

function getDateFilterSummary(filters) {
    const fromValue = filters.find(object => String(object.name).indexOf('from')).value;
    const toValue = filters.find(object => String(object.name).indexOf('to')).value;
    const fromTime = new Date(fromValue);
    const toTime = new Date(toValue); 
    const rangeMode = getRangeMode(fromTime,toTime);
    const labelFilter = getLabelForFilter({rangeMode, fromTime, toTime});
    return [`Imported Date = ${labelFilter}`];
}

export function exportImportReportResults(format, filters, data) {
    let filtersSummary = getFiltersSummary(filters.filter(object => object.type === 'normal')).map(item => [item]);
    const filtersDateSummary = getDateFilterSummary(filters.filter(object => object.type !== 'normal'));
    filtersSummary.push(filtersDateSummary);
    const headerData = ColumnTable.map(value => value.caption);
    const columnNames = ColumnTable.map(value => value.name);
    const bodyDataOrdered = data.map(row => {
        return columnNames.map(key => row[key]);
    });
    downloadImport(filtersSummary, headerData, bodyDataOrdered, format);
}