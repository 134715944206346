import { useEffect, useRef, useState } from 'react';
import {useSelector} from 'react-redux';

export const useRecalculateHeightWidth = (elementRef, ...props) => {
    const [height, setHeight] = useState(null);
    const [width, setWidth] = useState(null);
    const [bottom, setBottom] = useState(null);
    const windowHeight = useSelector(state => state.resize.windowHeight);

    useEffect(() => {
        if (elementRef && elementRef.current) {
            const { height: currentHeight, width: currentWidth, bottom: currentBottom} = elementRef.current.getBoundingClientRect();

            if (height !== currentHeight) {
                setHeight(currentHeight);
            }
            if (width !== currentWidth) {
                setWidth(currentWidth);
            }
            if (bottom !== currentBottom) {
                setBottom(currentBottom);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elementRef, ...props]); // force setHeight with ...props
    
    return { height, width , heightContainer: windowHeight - bottom };
};

// https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
export const useOutsideClick = (onOutsideClick, ...refs) => {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            let clickOutside = true;
            for (const ref of refs) {
                if (!(ref.current && !ref.current.contains(event.target))) {
                    clickOutside = false;
                }
            }
            if (clickOutside) {
                onOutsideClick?.();
            }
        }
        
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...refs, onOutsideClick]);
};

export const useClickMovement = (onMovement, onMovementEnd) => {
    const [mouseDown, setMouseDown] = useState(false);
    useEffect(() => {
        const handleMouseMove = (e) => {
            onMovement(e.movementX, e.movementY);
        };
        
        if (mouseDown) {
            window.addEventListener('mousemove', handleMouseMove);
        }
        
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, [mouseDown, onMovement]);
    
    useEffect(() => {
        const handleMouseUp = () => {
            setMouseDown(false);
            onMovementEnd?.();
        };
        
        window.addEventListener('mouseup', handleMouseUp);
        
        return () => {
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [onMovementEnd]);
    return [setMouseDown];
};

export const useRefHeightWidth = (elementRef, onResize, ...props) => {
    const size = useRef({ height: 0, width: 0 });
    useEffect(() => {
        if (elementRef && elementRef.current) {
            const { height, width } = elementRef.current.getBoundingClientRect();
            if (height !== size.current.height || width !== size.current.width) {
                size.current = { height, width };
                onResize(height, width);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elementRef, ...props]); // force setHeight with ...props
};
