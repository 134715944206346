import { easeOut } from 'ol/easing';
import { getCenter } from 'ol/extent';

export const zoomMap = (mapRef, bounds, center, newZoom, duration = 0) => {
    
    const view = mapRef?.getView();
    if (!view) {
        console.error('Map does not have view.  Cannot zoom', { mapRef });
        return;
    }
    
    if (!bounds) {
        console.error('Map does not have bounds.  Cannot zoom', { bounds });
        return;
    }
    
    const currentResolution = view.getResolution();
    if (!currentResolution) {
        console.error('No current resolution', { view });
    }
    
    if (center) {
        view.setCenter(center);
    }
    
    if (duration > 0) {
        if (view.getAnimating()) {
            view.cancelAnimations();
        }
        view.animate({
            //resolution: currentResolution - delta,
            zoom: newZoom,
            duration: duration,
            easing: easeOut,
        });
    } else {
        view.setZoom(newZoom);
    }
};

export const zoomMapByDeltaWithDuration = (mapRef, bounds, delta) => {
    zoomMap(mapRef, bounds, null, mapRef?.getView()?.getZoom?.() + delta, 250);
};

export const centerMap = (mapRef, bounds) => {
    zoomMap(mapRef, bounds, getCenter(bounds), 1, 250);
};

export const fitMap = (mapRef, bounds, minZoom = 5, padding = [30, 30, 30, 30]) => {
    const minResolution = mapRef.getView().getResolutionForZoom(minZoom);
    const boundsResolution = mapRef.getView().getResolutionForExtent(bounds);
    const boundsZoom = mapRef.getView().getZoomForResolution(boundsResolution);
    if(boundsZoom > minZoom){
        mapRef.getView().fit(bounds, {size: mapRef.getSize(), padding: padding, minResolution });
    }else{
        mapRef.getView().fit(bounds, {size: mapRef.getSize(), padding: padding});
    }
}

export function detectTypeFeatures(mapRef, coords, itemType) {
    let result = false;
    const pixel = mapRef.getPixelFromCoordinate(coords);
    const features = mapRef.getFeaturesAtPixel(pixel);
    if (features?.length >= 1) {
        result = features.some(item => item.get('itemType') === itemType);
    }
    return result;
}

export function onRectangle(point, bounds) {console.log({point, bounds})
    return (bounds[0] <= point.x && point.x <= bounds[2]) && (bounds[1] <= point.y && point.y <= bounds[3]);
}

export function onZoneRectangle(coords, bounds){
    for(let coord of coords){
        if(!onRectangle({ x: coord[0], y: coord[1] }, bounds)){
            return false;
        }
    }
    return true;
}


export function mapDetectCoordinates(mapConfiguration){
    const {mapPoint1X, mapPoint1Y, mapPoint2X, mapPoint2Y, id} = mapConfiguration;
    const {mapCorner1X, mapCorner1Y, mapCorner2X, mapCorner2Y} = mapConfiguration;
    let mapCoordinates = id ? [mapPoint1X, mapPoint1Y, mapPoint2X, mapPoint2Y] : null;
    const mapCorner = id ? [mapCorner1X, mapCorner2Y, mapCorner2X, mapCorner1Y] : null;

    //if the mapCoordinates contains 0 as coordinates it supposed been configured with Width or Height
    if (JSON.stringify(mapCoordinates) === JSON.stringify([0, 0, 0, 0])) {
        mapCoordinates = mapCorner;
    }
    return mapCoordinates;
}

export function getIsPointInsidePolygon(point = [], vertices = []){
    const [x,y] = point;

    let inside = false;
    for (let i = 0, j = vertices.length - 1; i < vertices.length; j = i++) {
      const xi = vertices[i][0], yi = vertices[i][1]
      const xj = vertices[j][0], yj = vertices[j][1]

      const intersect = ((yi > y) !== (yj > y)) && x < (((xj - xi) * (y - yi)) / (yj - yi) + xi);
      if (intersect) inside = !inside
    }

    return inside;
}
