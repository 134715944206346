export const VERSION = {
  "release-version":"2.0.4"
};VERSION['authsvc'] = '2.0.3-cfd0af4';
VERSION['ics'] = '2.0.4-5edfe3c';
VERSION['mwe-rescorrelationsvc'] = '2.0.3-c82833a';
VERSION['locationsvc'] = '2.0.4-3b41e4a';
VERSION['reportsvc'] = '2.0.4-42d56e0';
VERSION['importsvc'] = '2.0.4-76a7362';
VERSION['streamsvc'] = '2.0.3-09c6f00';
VERSION['dmsvc'] = '2.0.4-0c4f0b5';
VERSION['zonesvc'] = '2.0.4-135d96b';
VERSION['printsvc'] = '2.0.3-4b5a2f1';
VERSION['tagdevicehealthsvc'] = '2.0.4-b5acc12';
VERSION['noderedsvc'] = '2.0.3-44d9c9d';
VERSION['noderededgesvc'] = '2.0.3-44d9c9d';
VERSION['kong'] = '2.8.1-d29bad5';
VERSION['konginit'] = '2.8.1-d29bad5';
VERSION['tileserver'] = 'v4.1.1-73596ab';
VERSION['camel'] = '2.0.3-acf98f1';
VERSION['kelasticsvc'] = '5.3.0-1a06aa1';
VERSION['wso2sp-dashboard'] = '4.4.0-a6621cd';
VERSION['vault'] = '0.10.3-b765011';
VERSION['consul'] = '1.6.3-699882c';
VERSION['mwe-autofamily-svc'] = '2.6.6-9ef263e';
VERSION['directionsvc'] = '2.0.3-935d6b6';
VERSION['kibana'] = '7.10.2-a2f1300';
VERSION['ksql'] = '5.4.8-f158d39';
VERSION['fastgatesvc'] = '2.0.3-22a189c';
VERSION['dns'] = '2.0.3-fc0806a';
VERSION['mqttsvc'] = '4.3.5-8225b5e';
VERSION['resourcesvc'] = '2.0.4-ec53d5b';
VERSION['alertsvc'] = '2.0.3-3c0f844';
VERSION['pgsqlusrmgmt'] = '14.3-alpine3.16-1dcccf7';
VERSION['uisvc'] = '2.0.4-853f42d';
