const Promise = require('promise');

const assign = require('object-assign');

export function compileChart(chartDef,data){

    // This is slightly giving up on the pure JSON format
    // of widget specification.  At some point with that,
    // it felt like "programming in JSON", where I am simultaneously
    // "designing the JSON-based language".
    if(typeof chartDef === "function"){
        return Promise.resolve(chartDef(data))
        .then(data=>({
            type:"GRAPH",
            data
        }));
    }

    let comp = Widget.fromObject({
        type:"GRAPH",
        data: chartDef
    });
    comp.setDataTransformFunction(
        compileChartObject.bind(null,chartDef)
    );

    return Promise.resolve(data)
    .then(data=>{
        comp.modifyWithData(data);
        return comp.toObject();
    });
};

function compileChartObject(definition, data){

    data = data || [];

    if(!definition || !definition.data ) return definition;

    const labelAttribute = definition.data.labelAttribute;
    const datasets = definition.data.datasets || [];

    return assign({},definition,{
        data: assign({},definition.data,{
            labels: data.map(d=>d[labelAttribute]),
            datasets:datasets.map((ds)=>assign(
                {},
                ds,
                {
                    data: data.map(d=>d[ds.dataAttribute])
                }
            ))
        })
    });
}

/*
██     ██ ██ ██████   ██████  ███████ ████████      ██████ ██       █████  ███████ ███████
██     ██ ██ ██   ██ ██       ██         ██        ██      ██      ██   ██ ██      ██
██  █  ██ ██ ██   ██ ██   ███ █████      ██        ██      ██      ███████ ███████ ███████
██ ███ ██ ██ ██   ██ ██    ██ ██         ██        ██      ██      ██   ██      ██      ██
 ███ ███  ██ ██████   ██████  ███████    ██         ██████ ███████ ██   ██ ███████ ███████
*/


class Widget{
    constructor(){
        this.type = "";
        this.data = {};

        this.transformFunction = (oldData,newData)=>{return oldData;}; // eslint-disable-line
    }

    load(obj){
        this.type = obj.type;
        this.data = assign({},obj.data);
    }

    static fromObject(obj){
        let comp = new Widget();
        comp.load(obj);
        return comp;
    }

    static fromWidget(_comp){
        let comp = new Widget();
        comp.load(_comp.toObject());
        return comp;
    }

    toObject(){
        return {
            type: this.type,
            data: this.data
        };
    }

    setDataTransformFunction(fn){
        this.transformFunction = fn;
    }

    modifyWithData(data){
        this.data = this.transformFunction(data);
    }
}
