function getSpecialLoginAuthType(authSettings) {
  for (let authType in authSettings) {
      if (authSettings[authType].specialLogin) {
          return authSettings[authType];
      }
  }
  return null;
}

export {
    getSpecialLoginAuthType
};
