import React, { useEffect, useRef } from 'react';
import { getExtensionBase64 } from '../../utils/ol/utils';

const ImageDisplayAsIcon = ({ svgColor, svgBase64, alignIcon }) => {
    const refContainer = useRef(null);
    const type = getExtensionBase64(svgBase64);
    useEffect(() => {
        const container = refContainer.current;
        const dataURL = `data:${type};base64,${svgBase64}`;
        if (container) {
            container.innerHTML = '';
            if (type === 'image/svg+xml') {
                const xhr = new XMLHttpRequest();
                xhr.open('GET', dataURL);
                xhr.addEventListener('load', function (ev) {
                    const xml = ev.target.response;
                    const dom = new DOMParser();
                    const image = dom.parseFromString(xml, type);
                    try {
                        if (container) {
                            container.appendChild(image.rootElement);
                        }
                    } catch (e) {
                        container.innerHTML = '';
                        console.log('Image not valid', e);
                    }
                });
                xhr.send(null);
            } else if (type === 'image/png' || type === 'image/jpeg') {
                container.innerHTML = '';
                const img = document.createElement('img');
                img.setAttribute('src', dataURL);
                container.appendChild(img);
            } else {
                container.innerHTML = '';
            }
        }
    }, [svgBase64, type]);
    
    return (
        <div
            id="divImgSvgId2"
            ref={refContainer}
            className="image-icon"
            style={{ fill: svgColor, textAlign: alignIcon }}
        />
    );
};

export default ImageDisplayAsIcon;