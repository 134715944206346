const MWE_BUILD_HASH = "MWE_BUILD_HASH";
//
// function hashString(str) {
//     const len = str.length;
//     let hash = 0;
//     if (len === 0) return hash;
//     let i;
//     for (i = 0; i < len; i++) {
//         hash = ((hash << 5) - hash) + str.charCodeAt(i);
//         hash |= 0; // Convert to 32bit integer
//     }
//     return hash;
// }

function reloadApp(e) {
    window.location.reload(true);
    if (e){
        e.preventDefault();
    }
}

function downloadServerHash() {
    return fetch('/', {method: 'HEAD'})
    .then(response => {
        if (response.status !== 200) {
            throw new Error('Offline');
        }
        return {status: "OK", hash: response.headers.get("etag")};
    })
    .catch((e) => {
        return {status: "ERROR", error: e};
    });
}

function checkMWEBuildUpdates(e) {
    downloadServerHash()
        .then(data => {
            if (data.status === "OK") {
                const hash = data.hash;
                const previousHash = localStorage.getItem(MWE_BUILD_HASH);
                if (!previousHash) {
                    localStorage.setItem(MWE_BUILD_HASH, hash);
                } else {
                    if (previousHash !== hash) {
                        localStorage.setItem(MWE_BUILD_HASH, hash);
                        if(process.env.NODE_ENV==="production") {
                            reloadApp(e);
                        }
                    }
                }
            }
        })
        .catch(e => {});
}

export {checkMWEBuildUpdates};