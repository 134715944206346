import {closeDataEntry} from './general';

import {createTask} from './appliance-tasks';

export const updateApplianceServices = (uuid,servicesRunning)=> {
    return (dispatch) => {

        // Actually not convinced we need to dispatch an event... UI doesn't really do much with it.
        //dispatch({type:types.UPDATE_APPLIANCE, name, description, uuid, publicKey, site});

        // Send the actual task...
        dispatch( createTask(
            uuid,
            (servicesRunning) ? "StartServices" : "StopServices" ,
            {},
            undefined,
             function(){}   // nop
         ));

        let data = {
            servicesRunning
        };
        if(!data.publicKey) delete data.publicKey; // don't include if it shouldn't change.

        dispatch(closeDataEntry());
    };
};
