module.exports = {
    RESOURCE_TYPE:{
        service:"report",
        url:"/dashboards/widgets/15/data",
        key:"resourceType"
    },
    SITE_NAMES:{
        service:"report",
        url:"/dashboards/widgets/17/data",
        key:"site_name"
    }/*{
        service:"ics",
        url:"/sites",
        key:"name",
    }*/,
    CUSTOM_ATTRIBUTES:{
        service:"report",
        url:"/dashboards/widgets/13/data",
        key:"attribute"
    },
    ZONE_GROUPS:{
        service:"report",
        url:"/dashboards/widgets/18/data",
        key:"locationGroup"
    }

};
