import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import Feature from 'ol/Feature';
import { LineString, MultiLineString, Point } from 'ol/geom';
import { Fill, Stroke, Style, Text } from 'ol/style';
import { getLength } from 'ol/sphere';
import { IMPERIAL_MEASURE_SYSTEM, METRIC_MEASURE_SYSTEM } from '../../vss/app/constants/SiteDesigner';

const BORDER_TYPE = 'BORDER_TYPE';
const BORDER_RULE_TYPE = 'BORDER_RULE_TYPE';
const LENGTH_VERTICAL_LINE = 1.5;
const SCALE_FACTOR = 0.2;
const INIT_OFFSET_XCORNER = 22;
const INIT_OFFSET_YCORNER = 14;
const INIT_OFFSET_YRULE = 14;

const ruleDividers = (measurementSystem) => {
    let measure = '';
    switch (measurementSystem) {
        case METRIC_MEASURE_SYSTEM:
            measure = 'M';
            break;
        case IMPERIAL_MEASURE_SYSTEM:
            measure = 'FT';
            break;
        default:
            break;
    }
    
    return [
        { value: 0, label: '0' + measure },
        { value: 10, label: '10' + measure },
        { value: 20, label: '' },
        { value: 30, label: '' },
        { value: 40, label: '' },
        { value: 50, label: '50' + measure },
        { value: 100, label: '100' + measure },
        { value: 150, label: '150' + measure },
    ];
};

const style = {
    multilines: new Style({
        stroke: new Stroke({ color: '#000000', width: 2 }),
    }),
    line: new Style({
        stroke: new Stroke({ color: '#800080', width: 2 }),
    }),
    corner: new Style({
        text: new Text({
            font: 'bold 12px Calibri,sans-serif',
            fill: new Fill({
                color: '#000000',
            }),
            padding: [ 3, 3, 3, 3 ],
            textBaseline: 'bottom',
            textAlign: 'end',
            offsetY: 14,
            offsetX: 22,
        }),
    }),
    segment: new Style({
        text: new Text({
            font: '12px Calibri,sans-serif',
            fill: new Fill({
                color: '#800080',
            }),
            padding: [ 2, 2, 2, 2 ],
            textBaseline: 'bottom',
            offsetY: 14,
        }),
    }),
};

const customStyles = (feature, resolution, config, measurementSystem) => {
    const typeFeature = feature.get('type');
    const scale = SCALE_FACTOR / resolution;
    const offsetXCorner = INIT_OFFSET_XCORNER * scale;
    const offsetYCorner = INIT_OFFSET_YCORNER * scale;
    const offsetYRule = INIT_OFFSET_YRULE * scale;
    switch (typeFeature) {
        case BORDER_TYPE: {
            // configuring the text labels color and the line color
            style.multilines.getStroke().setColor(config.color);
            // creating vector of styles
            const styles = [ style.multilines.clone() ];
            const coords = feature.getGeometry().getFirstCoordinate();
            const point = new Point(coords);
            const labelCoords = `X: ${(config.mapCoordinates[0] || 0).toFixed(0)} Y: ${(config.mapCoordinates[1] || 0).toFixed(0)}`;
            const labelCorner = style.corner.clone();
            labelCorner.setGeometry(point);
            labelCorner.getText().setText(labelCoords);
            labelCorner.getText().setOffsetX(offsetXCorner);
            labelCorner.getText().setOffsetY(offsetYCorner);
            labelCorner.getText().setScale(scale);
            labelCorner.getText().setScale(scale);
            
            styles.push(labelCorner);
            return styles;
        }
        case BORDER_RULE_TYPE:
        default: {
            const styles = [];
            
            // 1. Calculating the measure of the rule
            const initialPoint = feature.getGeometry().getFirstCoordinate();
            const finalPoint = feature.getGeometry().getLastCoordinate();
            const auxLine = new LineString([ initialPoint, finalPoint ]);
            const widthMapFT = (getLength(auxLine)).toFixed(2);
            const ruleMap = ruleDividers(measurementSystem).filter(divider => Number(widthMapFT) >= divider.value);
            
            // 2. Drawing horizontal line of the rule
            const lastPointRule = ruleMap[ruleMap.length - 1].value;
            const ruleLine = new LineString([ initialPoint, feature.getGeometry().getCoordinateAt(lastPointRule / widthMapFT) ]);
            const lineRule = style.line.clone();
            lineRule.setGeometry(ruleLine);
            styles.push(lineRule);
            
            // 3. Drawing vertical dividers and labels
            
            for (let divider of ruleMap) {
                const point = feature.getGeometry().getCoordinateAt(divider.value / Number(widthMapFT));
                const [ coorX, coorY ] = point;
                const verticalLineCoords = [ [ coorX, coorY ], [ coorX, coorY + LENGTH_VERTICAL_LINE ] ];
                const verticalLine = new LineString(verticalLineCoords);
                const lineSegment = style.line.clone();
                lineSegment.setGeometry(verticalLine);
                styles.push(lineSegment);
                
                const segmentPoint = new Point(point);
                const labelSegment = style.segment.clone();
                labelSegment.setGeometry(segmentPoint);
                labelSegment.getText().setText(divider.label);
                labelSegment.getText().setOffsetY(offsetYRule);
                labelSegment.getText().setScale(scale);
                //labelSegment.getText().setFont(fontSize + 'px Calibri,sans-serif');
                styles.push(labelSegment);
            }
            
            return styles;
        }
    }
};

export function OlMapLimitsFactory(Base) {
    
    class OlMapLimits extends Base {
        mapLimitsSource;
        mapLimitsLayer;
        
        //update properties
        #coordinates;
        #coordinatesRule;
        #resolutionMap;
        #zoomMap;
        #colorlimitMap;
        #colorMeasure;
        
        constructor(mapRef) {
            super(mapRef);
            
            this.#colorlimitMap = '#000000';
            this.#colorMeasure = '#800080';
            
            this.mapLimitsSource = null;
            this.mapLimitsLayer = null;
        }
        
        generateRuleCoordinates(coor) {
            const [ minX, minY, maxX ] = coor;
            const offsetY = 4;
            const offsetX = 1;
            return [ [ minX + offsetX, minY - offsetY ], [ maxX - offsetX, minY - offsetY ] ];
        }
        
        generateLinesCoordinates(coor) {
            const [ minX, minY, maxX, maxY ] = coor;
            const extraLine = 5;
            
            return [
                [ [ minX - extraLine, minY ], [ maxX + extraLine, minY ] ],
                [ [ maxX, minY - extraLine ], [ maxX, maxY + extraLine ] ],
                [ [ maxX + extraLine, maxY ], [ minX - extraLine, maxY ] ],
                [ [ minX, maxY + extraLine ], [ minX, minY - extraLine ] ],
            ];
        }
        
        cleanMapLimits() {
            if (this.mapLimitsLayer) {
                this.mapRef.removeLayer(this.mapLimitsLayer);
            }
        };
        
        redrawMapLimits() {
            this.cleanMapLimits();
            this.drawMapLimits(this.mapCoordinates, this.measurementSystem);
        }
        
        drawMapLimits(mapCoordinates, measurementSystem) {
            // 1. Cleaning layers
            if (this.mapLimitsLayer) {
                this.mapRef.removeLayer(this.mapLimitsLayer);
            }
            
            if (mapCoordinates) {
                // 2. Creating layers
                
                // Calculates the limits of current visible area of map.
                // const extentMap = this.#mapRef.getView().calculateExtent((this.#mapRef.getSize()));
                this.#resolutionMap = this.mapRef.getView().getResolution();
                this.#zoomMap = this.mapRef.getView().getZoom();
                this.#coordinates = this.generateLinesCoordinates(mapCoordinates);
                this.#coordinatesRule = this.generateRuleCoordinates(mapCoordinates);
                
                const configStyle = {
                    colorLimit: this.#colorlimitMap,
                    colorMeasure: this.#colorMeasure,
                    mapCoordinates,
                };
                
                this.mapLimitsSource = new VectorSource({ updateWhileInteracting: true });
                this.mapLimitsLayer = new VectorLayer({
                    source: this.mapLimitsSource,
                    style: (feature, resolution) => customStyles(feature, resolution, configStyle, measurementSystem),
                    updateWhileAnimating: true,
                    updateWhileInteracting: true,
                });
                this.mapLimitsLayer.set('addedLayer', true);
                this.mapRef.addLayer(this.mapLimitsLayer);
                
                // 3. Creating features
                const multiLinesFeature = new Feature({ geometry: new MultiLineString(this.#coordinates), type: BORDER_TYPE });
                const lineFeature = new Feature({ geometry: new LineString(this.#coordinatesRule), type: BORDER_RULE_TYPE });
                
                this.mapLimitsSource.addFeatures([ multiLinesFeature, lineFeature ]);
            }
        };
    }
    
    return OlMapLimits;
}
