import {SiteViewService} from "./index";
import {MAP_VIEW_URL} from "../../constants/Misc";
import {getAdjustedBounds} from "../site-maps";

// Gets the the map object except the wmf field.
const getMapObject = async (mapId) => {

    let mapConfiguration = null;

    try {
        const response = await SiteViewService.instance().get(MAP_VIEW_URL + "/maps/" + mapId);

        if (response != null && response.data != null) {
            mapConfiguration = response.data;
        }
    } catch (e) {
        console.log(e);
    }

    return mapConfiguration;

}

// const getApplianceMapConfiguration = async (mapId) => {
//
//     let mapConfiguration = null;
//
//     try {
//         const response = await SiteViewService.instance().get(MAP_VIEW_URL + "/maps/" + mapId+"/configuration");
//
//         if (response != null && response.data != null) {
//             mapConfiguration = response.data;
//         }
//     } catch (e) {
//         console.log(e);
//     }
//
//     return mapConfiguration;
//
// }

// Gets the map coordinates from map collection.
export const getMapCoordinates = async (mapId) => {

    let mapCoordinates = null;

    try {

        // const mapObject = await getMapObject(mapId);

        // const applianceMapConfiguration = await getApplianceMapConfiguration(mapId);
        //
        //
        // if (applianceMapConfiguration != null && applianceMapConfiguration.currentState!=='Unspecified') {
        //     let extent=[applianceMapConfiguration.mapCorner1X,applianceMapConfiguration.mapCorner2Y,applianceMapConfiguration.mapCorner2X,applianceMapConfiguration.mapCorner1Y];
        //     extent=getAdjustedBounds(extent);
        //     mapCoordinates={...applianceMapConfiguration,maxZoom:mapObject.maxZoom,siteId:mapObject.siteId,extent:extent};
        //
        // } else {

            const mapObject = await getMapObject(mapId);

            if (mapObject != null) {
                if(mapObject.coordinates&&mapObject.coordinates.extent==null){
                    mapObject.extent=getAdjustedBounds([mapObject.coordinates.mapCorner1X,mapObject.coordinates.mapCorner2Y,mapObject.coordinates.mapCorner2X,mapObject.coordinates.mapCorner1Y]);
                }
                mapCoordinates = {...mapObject,...mapObject.coordinates};
            }
        // }

    } catch (e) {
        console.log(e);
    }
    return mapCoordinates;

}


// function getAdjustedBounds(bounds){
//
//     const dY = bounds[3] - bounds[1];
//     const dX = bounds[2] - bounds[0];
//     let d, _bounds = [];
//
//     if(dY > dX){
//         d = (dY - dX) / 2;
//         // console.log("d",d);
//         _bounds[0] = bounds[0] - d;
//         _bounds[1] = bounds[1];
//         _bounds[2] = bounds[2] + d;
//         _bounds[3] = bounds[3];
//     }else{
//         d = (dX - dY) / 2;
//         // console.log("d",d);
//         _bounds[0] = bounds[0];
//         _bounds[1] = bounds[1] - d;
//         _bounds[2] = bounds[2];
//         _bounds[3] = bounds[3] + d;
//     }
//     return _bounds;
// }