import React from 'react';
import { useDispatch } from 'react-redux';

import { viewResourceTypeDetails } from '../../actions';
import {
    useOnePlatformTableDispatch,
    useOnePlatformTableState,
    useReportDispatch, useReportKey
} from '../../hooks';
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

const ResourceTypesViewAttributes = ({ rowIndex }) => {

    const dispatch = useDispatch();
    // const { translate } = useI18n('common.filterTable');
    // const handleShowAttributes = () => {
    //     if (rowIndex !== undefined) {
    //         dispatch(viewResourceTypeDetails(rowIndex));
    //         dispatch(showAttributes(true));
    //     }
    // };
    // const reportKey="reports:resource-types";
    const { reportKey } = useReportKey();
    // const { showDetails } = useReportState(reportKey);
    const { patchReportProperties } = useReportDispatch(reportKey);
    const { selectedRows,  data: dataTable } = useOnePlatformTableState(reportKey);
    const { replaceSelectedRows } = useOnePlatformTableDispatch(reportKey);
    const handleShowAttributes = () => {
        if (rowIndex !== undefined) {
            dispatch(viewResourceTypeDetails(rowIndex));
            // dispatch(viewResourceTypeFilters(rowIndex));
            // setModalProfile(true);
            // dispatch(showAttributes(true));

            const row = dataTable[rowIndex];
            const newSelectedRows = { ...selectedRows };
            if (newSelectedRows.hasOwnProperty(row.___uKey)) {
                delete newSelectedRows[row.___uKey];
            } else {
                newSelectedRows[row.___uKey] = row;
            }
            replaceSelectedRows(newSelectedRows)();

            if (Object.keys(newSelectedRows).length>0)
                patchReportProperties({ showDetails: true, customDetails:['customProperties','filters'] });
            else
                patchReportProperties({ showDetails: false, customDetails:['customProperties','filters'] });

        }
    };

    return (
        <div className={'actionContainer'}>
            <div style={{textAlign:"center",paddingTop: "0.3em"}}>
                {/*<button className={'actionButtonContainer'} onClick={handleShowAttributes}>{translate('Attributes')}</button>*/}
                    <MoreHorizIcon onClick={handleShowAttributes} style={{fill:'#819dc9',cursor: "pointer"}} />
                    {/*<MoreHorizIcon onClick={handleShowAttributes} style={{fill:'orange',cursor: "pointer"}} />*/}
            </div>
        </div>
    );
};

export default ResourceTypesViewAttributes;
