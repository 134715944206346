import WorldMap from '../map/MapView';
import HistoryPlayback from '../playback/HistoryPlayback';
import React from 'react';
import PropTypes from 'prop-types';
import {localeTimestamp} from "../../../../../common/app/views/table/TableBody";
import {NoFilterTheme} from '../../../../../common/app/views/table/themes/NormalTheme';
import {compileAssetInfo, compileSiteInfo} from '../../../utils/MapFormat';

import WrappedTable from './WrappedTable';
import {OPTION_ALL_WILDCARD} from "../../../constants/Misc";
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actions from '../../../actions';

export {NoFilterTheme};
export {WrappedTable as TableView};

class MapView extends React.Component{
    constructor(props){
        super(props);

        let sites=[];
        if(props.mapFormat&&props.sites&&props.data)
            sites= compileSiteInfo(
            props.mapFormat,
            props.sites,
            props.data
            );

        this.state={
            sites: sites,
            selectedTags: props.data.filter(t=>t.___selected),
            refinedTags: this.getRefinedTags(props,{})
        }; // DIFF

        this.handleHoverSite = this.handleHoverSite.bind(this);
        this.handleSelectSite = this.handleSelectSite.bind(this);
        this.handleHoverFeature = this.handleHoverFeature.bind(this);
        // this.handleSelectFeature = this.handleSelectFeature.bind(this);

        this.isSiteSelected = this.isSiteSelected.bind(this);
    }

    static get propTypes(){
        return {
            hoverIndex: PropTypes.number,
            hoverUIDs: PropTypes.arrayOf(PropTypes.string),
            data: PropTypes.array.isRequired,
            columns: PropTypes.array.isRequired,
            actions: PropTypes.object.isRequired,
            playback: PropTypes.bool,
            sites: PropTypes.array,
            mapFormat: PropTypes.shape({
                siteAttribute:PropTypes.string.isRequired,
                selectedAttribute: PropTypes.string.isRequired
            }),
            maps: PropTypes.array,
            filters: PropTypes.object,
            selected: PropTypes.object,
        };
    }

    static get defaultProps(){
        return {
            sites:[],
            hoverUIDs:[]
        };
    }

    shouldComponentUpdate(nextProps,nextState){
        return this.props !== nextProps || nextState !== this.state;
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        let shouldSetState = false, changeset = {}, showMapSelectionRefresh = false;

        if(this.props.data !== nextProps.data){
            changeset = {
                ...changeset,
                selectedTags: nextProps.data.filter(t=> { 
                    if(t && t.___selected) {
                        return t.___selected;
                    } else {
                        return undefined;
                    }
                })
            };
            showMapSelectionRefresh = true;
            shouldSetState = true;
        }

        if(this.props.data !== nextProps.data || this.props.mapFormat !== nextProps.mapFormat || this.props.hoverUIDs !== nextProps.hoverUIDs){
            changeset = {
                ...changeset,
                refinedTags: this.getRefinedTags(nextProps)
            };
            shouldSetState = true;
        }

        if(this.props.data !== nextProps.data || this.props.sites !== nextProps.sites || this.props.mapFormat !== nextProps.mapFormat){
            shouldSetState = true;
            changeset = {
                ...changeset,
                sites: compileSiteInfo(
                  nextProps.mapFormat,
                  nextProps.sites,
                  nextProps.data
                )
            };
        }

        if(this.hasSiteFilterChanged(nextProps)){
            const filterId = this.getSiteFilterId(nextProps);
            const selectedSite = nextProps.sites.find(s=>s.name === nextProps.filters[filterId].value);
            changeset = {
                ...changeset,
                selectedSite
            };
            shouldSetState = true;
        }

        if(shouldSetState){
            this.setState(changeset,() => {
                const { selectedTags, selectedSite } = this.state;
                const { feature, reportMeta } = this.props;
                if(reportMeta.map){
                    const { xColumn, yColumn } = reportMeta.map;
                    if(feature && feature.zoomObject && selectedTags.length > 0 && selectedSite && showMapSelectionRefresh){
                        const { name } = selectedSite;
                        let tagsXY = [];
                        selectedTags.forEach((tagObj) => {
                            const x = tagObj[xColumn];
                            const y = tagObj[yColumn];
                            if(x && y && typeof x === "number" && typeof y === "number" && name === tagObj.siteName){
                                tagsXY.push({x,y});
                            }
                        });

                        // if(tagsXY && tagsXY.length){
                        //     const minX = tagsXY.sort((a,b) => a["x"] - b["x"])[0]["x"];
                        //     const minY = tagsXY.sort((a,b) => a["y"] - b["y"])[0]["y"];
                        //     const maxX = tagsXY.sort((a,b) => b["x"] - a["x"])[0]["x"];
                        //     const maxy = tagsXY.sort((a,b) => b["y"] - a["y"])[0]["y"];
                            //const corrds = [minX, minY, maxX, maxy];
                            // window.MY_MAP.getView().fit(corrds,{size:window.MY_MAP.getSize(),maxZoom:5})
                        // }

                    }
                    // Removed for avoid un-zoom bug when run report or auto-refresh happens.
                    // else if(feature && feature.zoomObject && selectedTags.length === 0 && selectedSite && showMapSelectionRefresh === true){
                    //     // window.MY_MAP.getView().setCenter(ol.extent.getCenter(window._origninalBound));
                    //     // window.MY_MAP.getView().setZoom(1);
                    // }
                }
            });
        }
    }

    hasSiteFilterChanged(nextProps){
        const filterId = this.getSiteFilterId(nextProps);
        const tf = this.props.filters, nf = nextProps.filters;
        if(nf && nf[filterId] && (tf && tf[filterId] && tf[filterId].value) !== nf[filterId].value){
            return true;
        } else if(nf && nf[filterId] && tf && tf[filterId] && (this.props.reportId !== nextProps.reportId)){
            return true;
        }
        return false;
    }

    getSiteFilterId(props){
        return this.getFilterIdForColumn(props.columns, props.mapFormat.siteAttribute);
    }

    getFilterIdForColumn(columns,columnName){
        const col = columns.find(c=>c.name===columnName);
        return col && col.filter;
    }

    componentDidUpdate(prevProps){


        if(!this.state.selectedSite || this.props.hoverUIDs === prevProps.hoverUIDs) return; // don't change if hoverUID hasn't changed.

        const validIndex = (i) =>{
            return !!( (i && i > 0) || i === 0);
        };

        const data = this.getRefinedData(this.props,this.state.selectedSite);

        if(!validIndex(this.props.hoverIndex) && validIndex(this.props.hoverUIDs[0])){
            const hoverIndex = data.findIndex((v)=>(v.___uid === this.props.hoverUIDs[0]));
            this.props.onHoverIndexUpdate && this.props.onHoverIndexUpdate(hoverIndex);
        }

    }

    handleHoverSite(site){
        if(this.state.hoverSite === site) return; // don't update...

        const {sites,hoverSite} = this.state;

        let _sites = [].concat(sites);

        if(hoverSite){
            _sites.find(s=>s._id===hoverSite._id).hovered = false;
        }

        if(site){
            _sites.find(s=>s._id===site._id).hovered = true;
        }

        this.setState({
            hoverSite:site,
            sites:_sites
        });
    }

    handleSelectSite(site){
        this.setState({
            selectedSite:site,
            refinedTags: this.getRefinedTags(this.props,{selectedSite:site})
        });

        this.setSiteFilter(site);
    }

    setSiteFilter(site){
        const {columns,mapFormat} = this.props;
        const siteColumn = columns.find(c=>c.name===mapFormat.siteAttribute);
        if(!siteColumn||!siteColumn.filter) return; // no filter to set
        this.props.actions.changeFilterValue(
          siteColumn.filter,
          (site ? site.name : OPTION_ALL_WILDCARD) // % is wildcard for ALL
          //OPTION_ALL_WILDCARD
        );
    }

    handleHoverFeature(features){
        // this.props.actions.hoverFeatures(features.map(feature=>feature && feature.raw));
    }

    // handleSelectFeature(features){
    //
    //     const {data}=this.props;
    //
    //     let uniqueIds=[];
    //     const featureLength=features.length;
    //     let lastObjectSelected=null;
    //
    //     features.forEach((feature,index)=>{
    //
    //         // Non clustered objects.
    //         if(feature && feature.cluster !== true && feature.raw) {
    //
    //             // Jump and highlight the last item in the list.
    //             //this.props.onItemSelected(items[items.length - 1].properties);
    //
    //             // Select or unselect objects.
    //             //this.props.selectCluster(features);
    //             uniqueIds.push(feature.raw.___uid);
    //
    //             // Keep last item in the list.
    //             if(index===featureLength-1){
    //                 lastObjectSelected=feature.raw;
    //             }
    //         }
    //         // Clustered objects.
    //         else if(feature && feature.cluster === true){
    //
    //             uniqueIds=feature.items.map(item=>item.___uid);
    //
    //             // Toggle action clusters.
    //             //this.props.selectManyRows(uniqueIds, true);
    //
    //             // Keep last item in the list.
    //             if(index===featureLength-1&&uniqueIds.length>0){
    //                 lastObjectSelected=data.find(item=>item.___uid===uniqueIds[uniqueIds.length-1]);
    //             }
    //         }
    //     });
    //
    //     // Jump and highlight element in table.
    //     this.props.onItemSelected(lastObjectSelected);
    //
    //     // Select and unselect clusters/tags.
    //     this.props.selectCluster(uniqueIds,lastObjectSelected);
    // }

    getRefinedData({data,mapFormat},selectedSite){
        return data.filter(r=> {
              return !selectedSite || (r && r[mapFormat.siteAttribute] === selectedSite["name"])
          }
        );
    }

    isSiteSelected(point){
        const {hoverIndex, data, mapFormat} = this.props;

        const siteHoveredInReport = (hoverIndex >= 0) && point.name === data[hoverIndex][mapFormat.siteAttribute];
        const siteHoveredInMap = point.selected || point.hovered;
        return siteHoveredInMap || siteHoveredInReport;
    }

    render(){
        return <div>
            {this.renderSplitComponent()}
        </div>;
    }

    renderSplitComponent(){
        const {maps,data,columns, clickedItem, feature, selectedTag,playback, resizeTrigger,isMobile,reportId,localMapInfo, actions, realTime, currentView,connectedRealTime,currentLocalMap} = this.props;

        const {refinedTags} = this.state;
        if(playback){
            return (
              <HistoryPlayback
                resizeTrigger={resizeTrigger}
              />
            );
        }

        const {sites, selectedSite} = this.state;

        let infoDiv = this.renderInfoDiv({clickedItem,columns});
        return (
          <WorldMap
            resizeTrigger={resizeTrigger}
            isSelected={this.isSiteSelected}
            onHoverSite={this.handleHoverSite}
            onSelectSite={this.handleSelectSite}
            onHoverFeature={this.handleHoverFeature}
            //onSelectFeature={this.handleSelectFeature}
            infoBoxContent={infoDiv}
            tags={ refinedTags }
            data={data}
            actions={actions}
            sites={ sites }
            maps={ maps }
            siteId={selectedSite && selectedSite._id}
            site={selectedSite}
            onItemSelected={this.props.onItemSelected}
            selectedTag={selectedTag}
            feature={feature}
            isMobile={isMobile}
            reportId={reportId}
            localMapInfo={localMapInfo}
            realTime={realTime}
            currentView={currentView}
            connectedRealTime={connectedRealTime}
            currentLocalMap={currentLocalMap}
          />
        );
    }

    getRefinedTags(props,state){
        const {mapFormat,hoverUIDs} = props;
        state = state || this.state;
        const {selectedSite} = state;
        return compileAssetInfo(
          mapFormat,
          this.getRefinedData(props,selectedSite),
          hoverUIDs && hoverUIDs[0]
        );
    }

    renderInfoText(columnObj, selectedTag){
        const { type, name } = columnObj;
        if(selectedTag[0][name]){
            if(type === "DATETIME"){
                return localeTimestamp(new Date(selectedTag[0][name]))
            }else{
                return selectedTag[0][name];
            }
        }else{
            return <span style={{color:'#aaa',fontStyle:'italic'}}>{"NULL"}</span>
        }
    }
    renderInfoDiv({columns,clickedItem}){
        clickedItem = clickedItem ? [clickedItem] : [];
        switch(clickedItem.length){
            case 0:
                return (<div>{"No Tags Selected."}</div>);
            case 1:
                return (
                  <div style={{position:'absolute',top:'3em',bottom:0,left:0,right:0,overflow:'auto'}}>
                      <div style={{fontSize:'0.9em',padding:'0 0.5em'}}>
                          {columns.map((c,i)=>(
                            <div key={i} style={{margin:'0.5em 0'}}>
                                <div style={{fontWeight:'bold'}}>{c.displayName}</div>

                                <div>{this.renderInfoText(c,clickedItem)}</div>
                            </div>
                          ))}
                      </div>

                  </div>
                );
            default:
                return (<div>{`${clickedItem.length} tags selected.`}</div>);
        }
    }
}


// eslint-disable-next-line
const mapStateToProps = state =>({
    clusterIndex: state.reportMap.clusterInfo.clusterIndex,
});

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        selectManyRows:actions.selectManyRows,
        selectCluster:actions.selectCluster
    },dispatch)
});

export default connect(mapStateToProps,mapDispatchToProps)(MapView);