import { Modify } from 'ol/interaction';
import { getOlStoreKey } from '../helpers';

// Requires OlBase class
// Requires OlSelectionFactory class

export function olModifyFactory(featureType) {
    return function (Base) {
        
        class OlModify extends Base {
            constructor(mapRef) {
                super(mapRef);
                
                const storeKey = getOlStoreKey(featureType);
                if (!this[storeKey]) {
                    this[storeKey] = {};
                }
                const store = this[storeKey];
                
                store.modifyInteraction = null;
                
                store.isEnabledModify = () => !!store.modifyInteraction;
                
                store.finalizeModify = () => {
                    if (store.modifyInteraction) {
                        this.mapRef.removeInteraction(store.modifyInteraction);
                    }
                    store.modifyInteraction = null;
                };
                
                store.initializeModify = () => {
                    store.finalizeModify();
                    
                    store.modifyInteraction = new Modify({
                        features: this[getOlStoreKey(featureType)].selectedItemsFeatureCollection,
                    });
                    
                    this.mapRef.addInteraction(store.modifyInteraction);
                    
                    store.modifyInteraction.on('modifystart', (event) => {
                        const feature = event.features.getArray()[0];
                        feature.on('change', (event) => this.observer?.executeEvent('onFeatureChange', {
                            event,
                            action: { type: 'onFeatureChange', featureType },
                        }));
                        this.observer?.executeEvent('onStartModify', {
                            event,
                            action: { type: 'onStartModify', featureType },
                        });
                    });
                    
                    store.modifyInteraction.on('modifyend', (event) => {
                        this.observer?.executeEvent('onFinishModify', {
                            event,
                            action: { type: 'onFinishModify', featureType },
                        });
                    });
                };
            }
        }
        
        return OlModify;
    };
}
