import { Circle, Fill, Stroke, Style, Text } from 'ol/style';
import { colorHexToRgb } from '../../vss/app/utils/ol/utils';


export const autoZoneStyle = (
    feature,
    color,
    isSelected,
    showLabels,
) => {
    let zoneColor = feature.values_?.color ? feature.values_.color : color;
    const isDraft = feature.values_?.isDraft || false;
    const featureGeometry = feature.getGeometry();
    const featureType = featureGeometry.getType();

    const styles = [];
    if (featureType === 'Polygon' || featureType === 'Point') {
        zoneColor = feature.get('isCollided') ? '#FF0000' : zoneColor;
        styles.push(
            new Style({
                image: new Circle({
                    fill: new Fill({
                        color: colorHexToRgb(zoneColor, 1),
                    }),
                    stroke: new Stroke({
                        color: colorHexToRgb(zoneColor, 1),
                        width: 1.25,
                    }),
                    radius: 2,
                }),
                fill: new Fill({
                    color: colorHexToRgb(zoneColor, isDraft ? 0.1 : 0.3),
                }),
                stroke: new Stroke({
                    color: colorHexToRgb(zoneColor, 1),
                    width: 1.25,
                    lineDash: isDraft ? [ 4, 8 ] : null,
                }),
            }),
        );
    }

    if (showLabels) {
        const pointGeometry = feature.getGeometry().getInteriorPoint();
        const textLabel = feature.values_?.name || '';

        styles.push(
            new Style({
                featureGeometry: pointGeometry,
                text: new Text({
                    text: textLabel,
                    font: '12px Calibri,sans-serif',
                    fill: new Fill({ color: '#000000' }),
                    backgroundFill: new Fill({ color: '#FFFFFF' }),
                    backgroundStroke: new Stroke({ color: '#000000', width: 1 }),
                    overflow: true,
                    padding: [ 1.5, 1.5, 1.5, 1.5 ],
                }),
            }),
        );
    }

    return styles;
};
