import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const ZebraIcon = (props) => (
    <SvgIcon viewBox={"100 0 1 130"} {...props}>
        <g>
            <polygon points="82.5,65.6 74.2,57 74.2,81 82.5,81 	"/>
            <path d="M48.6,39.8h20.7L61,31.5H50c7-0.7,12.8-6.6,12.8-13.9c0-7.7-6.2-14.1-14.1-14.1v27.9L25.3,8.4
		c-1.3,0.7-2.4,1.3-3.5,2c-1.3,0.9-2.4,1.8-3.7,2.6l22,22v48.2L57,100.1V88l-8.6-8.6L48.6,39.8z"/>
            <polygon points="57,39.8 57,81 65.6,81 65.6,48.4 	"/>
            <polygon points="86.3,57 77.7,48.4 65.6,48.4 74.2,57 	"/>
            <path d="M31.5,75V38.5L11.9,18.9c-1.8,2.2-3.5,4.6-4.8,7.3L22.9,42v12.1L3.8,35c-0.9,3.3-1.3,6.8-1.5,10.6
		C2.4,45.5,30.6,74.1,31.5,75"/>
            <polygon  points="2.7,57.6 2.7,69.7 57,124.1 57,112.2 	"/>
            <path d="M99.7,70.4h-8.6v10.8h-8.6c0,7.7,6.4,14.1,14.1,14.1c7.7,0,14.1-6.4,14.1-14.1L99.7,70.4z"/>
        </g>
    </SvgIcon>
);

export default ZebraIcon;
