// import moment from 'moment';
// require('moment/locale/en-gb');

// moment.locale(window.navigator.language);

export const monthDay = (date) => {
    return date.toLocaleDateString(window.navigator.language,{
        month:"numeric",
        day:"numeric",
    });
};

const getLocalTimestamp= (date) => {
    return date.toLocaleDateString(window.navigator.language,{
        year:"2-digit",
        month:"numeric",
        day:"numeric",
        hour:"numeric",
        minute:"numeric",
        second:"numeric"
    });
};

const getShortDateFromTimestamp= (timestamp) => {
    return new Date(timestamp).toLocaleDateString(window.navigator.language,{
        year:"2-digit",
        month:"numeric",
        day:"numeric",
        hour:"numeric",
        minute:"numeric",
    });
};

const getDateFromTimestamp= (timestamp) => {
    return new Date(timestamp).toLocaleDateString(window.navigator.language,{
        year:"2-digit",
        month:"numeric",
        day:"numeric",
        hour:"numeric",
        minute:"numeric",
        second:"numeric"
    });
};

export {getLocalTimestamp,getShortDateFromTimestamp,getDateFromTimestamp};
