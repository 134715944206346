// https://bitbucket.org/OscarGauss/algorithms/src/master/Computational_Geometry/points.cpp
class Vector {
    x;
    y;

    constructor(x = 0, y = 0) {
        this.x = x;
        this.y = y;
    }

    add(B) {
        return new Vector(this.x + B.x, this.y + B.y);
    }

    sub(B) {
        return new Vector(this.x - B.x, this.y - B.y);
    }

    prod(k) {
        return new Vector(this.x * k, this.y * k);
    }

    prodPoint(B) {
        return this.x * B.x + this.y * B.y;
    }

    prodCross(B) {
        return this.x * B.y - this.y * B.x;
    }

    div(k) {
        return new Vector(this.x / k, this.y / k);
    }

    mod2() {
        return this.prodPoint(this);
    }

    mod() {
        return Math.sqrt(this.mod2());
    }

    unit() {
        return this.div(this.mod());
    }

    ort() {
        return new Vector(-this.y, this.x);
    }

    coordinates() {
        return [this.x, this.y];
    }

    getCoordinates() {
        return [this.x, this.y];
    }

    angle(B) {
        return Math.acos(this.prodPoint(B) / (this.mod() * B.mod()));
    }

    rotate(angle) { // angle on radians
        const s = Math.sin(angle);
        const c = Math.cos(angle);
        return new Vector(this.x * c - this.y * s, this.x * s + this.y * c);
    }
}

export default Vector;
