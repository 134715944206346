import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { useCallback } from 'react';
import {
    addInteractionOnConfigZonesMap,
    changePropOnSnapshots,
    cleanSnapshots,
    patchForm, removeAllInteractionsOnConfigZonesMap,
    removeInteractionOnConfigZonesMap,
    replaceForm,
    replaceItems as replaceItemsAction,
    takeSnapshot as takeSnapshotAction,
    updateFieldsForm,
} from '../store/actions';
import {
    ItemType,
    SITE_MANAGER_CONFIGURATION_ZONES_SCOPE,
    SITE_MANAGER_SCOPE
} from '../constants';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../vss/app/constants';
import { displayErrorFromAxios } from '../vss/app/actions/util';
import {
    bulkCreateItemByItemType,
    bulkDeleteItemsByItemIdsItemType,
    bulkUpdateByItemType,
    createItemByItemType,
    deleteItemByItemIdItemType, getAutoZonesByMapId,
    getItemsBySiteIdMapIdDeviceType, getStagingItemsByMapId,
    updateItemByItemIdItemType,
} from '../services';
import { useLoaderDispatch } from '../hooks';
import { closeDialog, openDialog } from '../store/actions/site-manager/dialog';
import {loadPublishedZonesForMap, loadStagingZonesForMap} from "../vss/app/actions";

const selectSiteManagerState = createSelector(
    (state, scope) => state.siteManager[scope],
    (siteManagerState) => {
        const {
            backSnapshots = EMPTY_ARRAY,
            forwardSnapshots = EMPTY_ARRAY,
            mapInteractions = EMPTY_ARRAY,
            items = EMPTY_OBJECT,
            form = EMPTY_OBJECT,
            dialog = EMPTY_OBJECT,
        } = siteManagerState || {};
        return { backSnapshots, forwardSnapshots, mapInteractions, items, form, dialog };
    },
);

export const useSiteManager = (scope) => {
    return useSelector(state => selectSiteManagerState(state, scope), shallowEqual);
};

export const useSiteManagerDispatch = (scope) => {
    const { addLoaderCounter, subLoaderCounter } = useLoaderDispatch(SITE_MANAGER_SCOPE);
    const dispatch = useDispatch();
    const replaceItems = useCallback((itemType, items,zoneProperties) => dispatch(replaceItemsAction(scope, itemType, items,zoneProperties)), [
        dispatch,
        scope,
    ]);
    const takeSnapshot = useCallback((itemType, snapshot) => dispatch(takeSnapshotAction(scope, itemType, snapshot)), [
        dispatch,
        scope,
    ]);
    
    const loadItemsBySiteIdMapIdItemType = useCallback(async ({
        siteId,
        mapId,
        itemType,
        takeSnapshotAfterChanges = false,
    }) => {
        let success;
        addLoaderCounter({ message: 'Loading' });
        try {
            try {
                const {items,zoneProperties,zones} = await getStagingItemsByMapId(siteId, mapId, itemType);
                const {autoZones} = await getAutoZonesByMapId(siteId, mapId, itemType);
                replaceItems(itemType, items,{mapId,siteId});
                replaceItems(ItemType.AUTO_ZONE, autoZones,{mapId,siteId});
                dispatch( loadStagingZonesForMap(mapId,zones,zoneProperties,true,false));

                if (takeSnapshotAfterChanges) {
                    takeSnapshot(itemType, items);
                }
            }
            catch (e){
                // console.log(e);
                const {items,zoneProperties,autoZones} = await getItemsBySiteIdMapIdDeviceType(siteId, mapId, itemType);
                replaceItems(itemType, items,{mapId,siteId});
                replaceItems(ItemType.AUTO_ZONE, autoZones,{mapId,siteId});
                dispatch( loadPublishedZonesForMap(mapId,zoneProperties,true));
                if (takeSnapshotAfterChanges) {
                    takeSnapshot(itemType, items);
                }
            }
            success = true;
        } catch (error) {console.log(error)
            displayErrorFromAxios(dispatch, error);
            success = false;
        }
        subLoaderCounter();
        return { success };
    }, [ dispatch, addLoaderCounter, subLoaderCounter, replaceItems, takeSnapshot ]);
    
    return {
        patchForm: useCallback(({ key, value }) => dispatch(patchForm(scope, key, value)), [ dispatch, scope ]),
        updateFieldsForm: useCallback(({ paths, clearEmptyObj = true }) => dispatch(updateFieldsForm(scope, paths, clearEmptyObj)), [ dispatch, scope ]),
        replaceForm: useCallback(({ form }) => dispatch(replaceForm(scope, form)), [ dispatch, scope ]),
        openDialog: useCallback(({ key, title = '', content = '', event = '' }) => dispatch(openDialog(scope, key, title, content, event)), [ dispatch, scope ]),
        closeDialog: useCallback(() => dispatch(closeDialog(scope)), [ dispatch, scope ]),
        replaceItems,
        loadItemsBySiteIdLevelIdItemType: loadItemsBySiteIdMapIdItemType,
        bulkCreateItemByItemType: useCallback(async ({ siteId, mapId, itemType, payload, takeSnapshotAfterChanges, withoutReloadingItems = false }) => {
            let success;
            addLoaderCounter({ message: 'Creating' });
            try {
                await bulkCreateItemByItemType(itemType, payload);
                if (!withoutReloadingItems) {
                    await loadItemsBySiteIdMapIdItemType({ siteId, mapId: mapId, itemType, takeSnapshotAfterChanges });
                }
                success = true;
            } catch (error) {
                displayErrorFromAxios(dispatch, error);
                success = false;
            }
            subLoaderCounter();
            return { success };
        }, [ addLoaderCounter, subLoaderCounter, dispatch, loadItemsBySiteIdMapIdItemType ]),
        createItemByItemType: useCallback(async ({ siteId, mapId, itemType, payload, takeSnapshotAfterChanges, withoutReloadingItems = false }) => {
            let success;
            addLoaderCounter({ message: 'Creating' });
            try {
                await createItemByItemType(itemType, payload);
                if (!withoutReloadingItems) {
                    await loadItemsBySiteIdMapIdItemType({ siteId, mapId: mapId, itemType, takeSnapshotAfterChanges });
                }
                success = true;
            } catch (error) {
                displayErrorFromAxios(dispatch, error);
                success = false;
            }
            subLoaderCounter();
            return { success };
        }, [ addLoaderCounter, subLoaderCounter, dispatch, loadItemsBySiteIdMapIdItemType ]),
        deleteItemByItemTypeItemId: useCallback(async ({ siteId, mapId, itemType, itemId, takeSnapshotAfterChanges, withoutReloadingItems = false }) => {
            let success;
            addLoaderCounter({ message: 'Loading' });
            try {
                await deleteItemByItemIdItemType(itemType, itemId);
                if (!withoutReloadingItems) {
                    await loadItemsBySiteIdMapIdItemType({ siteId, mapId: mapId, itemType, takeSnapshotAfterChanges });
                }
                success = true;
            } catch (error) {
                displayErrorFromAxios(dispatch, error);
                success = false;
            }
            subLoaderCounter();
            return { success };
        }, [ addLoaderCounter, subLoaderCounter, dispatch, loadItemsBySiteIdMapIdItemType ]),
        bulkDeleteItemsByItemTypeItemIds: useCallback(async ({
            siteId,
            mapId,
            itemType,
            itemIds,
            props,
            takeSnapshotAfterChanges,
            withoutReloadingItems,
        }) => {
            let success;
            addLoaderCounter({ message: 'Loading' });
            try {
                await bulkDeleteItemsByItemIdsItemType(itemType, itemIds, props);
                if (!withoutReloadingItems) {
                    await loadItemsBySiteIdMapIdItemType({ siteId, mapId: mapId, itemType, takeSnapshotAfterChanges });
                }
                success = true;
            } catch (error) {
                displayErrorFromAxios(dispatch, error);
                success = false;
            }
            subLoaderCounter();
            return { success };
        }, [ addLoaderCounter, subLoaderCounter, dispatch, loadItemsBySiteIdMapIdItemType ]),
        updateItemByItemTypeItemId: useCallback(async ({ siteId, mapId, itemType, itemId, payload, takeSnapshotAfterChanges, withoutReloadingItems = false }) => {
            let success;
            addLoaderCounter({ message: 'Updating' });
            try {
                await updateItemByItemIdItemType(itemType, itemId, payload);
                if (!withoutReloadingItems) {
                    void loadItemsBySiteIdMapIdItemType({ siteId, mapId: mapId, itemType, takeSnapshotAfterChanges });
                }
                success = true;
            } catch (error) {
                displayErrorFromAxios(dispatch, error);
                success = false;
            }
            subLoaderCounter();
            return { success };
        }, [ addLoaderCounter, dispatch, loadItemsBySiteIdMapIdItemType, subLoaderCounter ]),
        bulkUpdateByItemType: useCallback(async ({ siteId, mapId, itemType, payload, takeSnapshotAfterChanges, withoutReloadingItems }) => {
            let success;
            addLoaderCounter({ message: 'Updating' });
            try {
                await bulkUpdateByItemType(itemType, payload);
                if (!withoutReloadingItems) {
                    void loadItemsBySiteIdMapIdItemType({ siteId, mapId: mapId, itemType, takeSnapshotAfterChanges });
                }
                success = true;
            } catch (error) {
                displayErrorFromAxios(dispatch, error);
                success = false;
            }
            subLoaderCounter();
            return { success };
        }, [ addLoaderCounter, dispatch, loadItemsBySiteIdMapIdItemType, subLoaderCounter ]),
        takeSnapshot,
        changePropsOnSnapshots: useCallback((itemType, oldKey, oldValue, newKey, newValue) => dispatch(
            changePropOnSnapshots(scope, itemType, oldKey, oldValue, newKey, newValue)), [
            dispatch,
            scope,
        ]),
        addInteractionOnMap: useCallback((interaction) => {
            switch (scope) {
                case SITE_MANAGER_CONFIGURATION_ZONES_SCOPE:
                    dispatch(addInteractionOnConfigZonesMap(interaction));
                    break;
                // case SITE_MANAGER_CONFIGURATION_OBSTRUCTIONS_SCOPE:
                //     dispatch(addInteractionOnConfigObstructionsMap(interaction));
                //     break;
                // case SITE_MANAGER_CONFIGURATION_DEVICES_SCOPE:
                //     dispatch(addInteractionOnConfigDevicesMap(interaction));
                //     break;
                default:
            }
        }, [ dispatch, scope ]),
        removeInteractionOnMap: useCallback((interaction) => {
            switch (scope) {
                case SITE_MANAGER_CONFIGURATION_ZONES_SCOPE:
                    dispatch(removeInteractionOnConfigZonesMap(interaction));
                    break;
                // case SITE_MANAGER_CONFIGURATION_OBSTRUCTIONS_SCOPE:
                //     dispatch(removeInteractionOnConfigObstructionsMap(interaction));
                //     break;
                // case SITE_MANAGER_CONFIGURATION_DEVICES_SCOPE:
                //     dispatch(removeInteractionOnConfigDevicesMap(interaction));
                //     break;
                default:
            }
        }, [ dispatch, scope ]),
        cleanSnapshots: useCallback((itemType) => dispatch(cleanSnapshots(scope, itemType)), [ dispatch, scope ]),
        cleanAllInteractionsOnMap: useCallback(() => {
            switch (scope) {
                case SITE_MANAGER_CONFIGURATION_ZONES_SCOPE:
                    dispatch(removeAllInteractionsOnConfigZonesMap());
                    break;
                // case SITE_MANAGER_CONFIGURATION_OBSTRUCTIONS_SCOPE:
                //     dispatch(removeAllInteractionsOnConfigObstructionsMap());
                //     break;
                // case SITE_MANAGER_CONFIGURATION_DEVICES_SCOPE:
                //     dispatch(removeAllInteractionsOnConfigDevicesMap());
                //     break;
                default:
            }
        }, [ dispatch, scope ]),
    };
};
