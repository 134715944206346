// import {MOTIONWORKS_BLUE} from '../../../../constants/Colors';

export const SITE_ALERTS = {
    id: 0,
    url: '/data',
    dashboardType:'MAP',
    title:"Site Alerts",
    pinType:'alert',
    //data:null,
    dataProvider:{
        service:"report",
        url:"/dashboards/widgets/0/data"
    },
    reportLink:"/reports/alerts/open" // system alerts report
}

export const OTHER_OPEN_ALERTS = {
    id: 9,
    url: '/data',
    dashboardType:'TABLE',
    dataProvider:{
        service:"report",
        url:"/dashboards/widgets/9/data"
    },
    //data:null,
    columns:[{
        caption:"Alert Type",
        key:"name",
        align:"left"
    },{
        caption:"# of Alerts",
        key:"cnt",
        align:"center"
    }],
    title:"Other Open Alerts",
    reportLink:"/reports/alerts/open"
}

export const TAG_BLINK_HEALTH = {
    id: 8,
    url:"/v2/dashboard/tagHealth",
    reportLink:"/reports/tags",
    title:"Tag Health: All Sites"
};

export const ZLA_STATUS ={
    id:6,
    url:"/appliances/status",
    title: "ZLA Status: All Sites",
    reportLink:"/appliances",
};

export const DEVICE_ALERT = {
    id: 7,
    url: "/deviceCounts",
    dashboardType:'TABLE',
    title:"No. Devices: All Sites",
    reportLink:"/reports/alerts/open",
    columns:[{
        caption:"Devices",
        key:"devicetype",
        align:"left"
    },{
        caption:"On Alert",
        key:"alert",
        align:"center",
        color: 'red'
    }]
};

export const RESOURCES_BY_TYPE = {
    id: 10,
    url: "/v2/dashboard/resourceCount",
    reportLink:"/reports/resources",
    defaultParams: {
        "type": "Custom",
        "startDate": "",
        "endDate": ""
    },
    dashboardType:"TABLE",
    title:'Resource Count by Type',
    dataProvider:{
        service:"report",
        url:"/dashboards/widgets/16/data"
    },
    columns:[
        {
            key:"resourceTypeDisplayName",
            caption:"Resource Type",
            align:"left"
        },
        {
            key:"previousCount",
            caption:"Previous",
            align:'center'
        },
        {
            key:"currentCount",
            caption:"Current",
            align:"center"
        },
        {
            key:"difference",
            caption:"+ / -",
            align:"center"
        }
    ],
    parameters:[
        {
            name:"startDate", // in order to have the 'required' attribute work, it will check if 'fromTime' is included in query
            type:"from-to",
            label:"Time Range",
            fromName:"startDate",
            toName:"endDate",
            required:true
        }
    ]
};

export const RESOURCE_BY_SITE = {
    id: 12,
    dashboardType:'TABLE',
    title:"Resource Movement by Site",
    pinType:'count',
    reportLink:"/reports/resources", // event history report
    dataProvider:{
        service:"report",
        url:"/dashboards/widgets/12/data"
    },
    parameters:[
        {
            type:"enum",
            name:"type",
            label:"Resource Type",
            //async:ParameterAsyncConfigurations.RESOURCE_TYPE
        },
        {
            name:"fromTime",
            type:"from-to",
            label:"Time Range",
            fromName:"fromTime",
            toName:"toTime",
            required:true
        },
    ],
    columns:[
        {
            key:"site_name",
            caption:"Site",
            align:'left'
        },{
            key:"incnt",
            caption:"In",
            align:'center'
        },{
            key:"outcnt",
            caption:"Out",
            align:'center'
        }
    ]
};

export const resourceByCustomAttributeValues=20;

export const RESOURCES_BY_CUSTOMATTRIBUTE = {
    id: 11,
    url: '/v2/dashboard/resourceAttrCount',
    reportLink:"/reports/resources",
    defaultParams:{
        "resourceType": "",
        "siteName": "",
        "customAttrName": ""
    },
    dashboardType:"GRAPH",
    title:'Resources by Custom Attribute Value',
    dataProvider:{
        service:"report",
        url:"/dashboards/widgets/11/data"
    },
    parameters:[
        {
            type:'enum',
            name:"resourceType",
            label:"Resource Type",
            // async:ParameterAsyncConfigurations.RESOURCE_TYPE,
            required: true
        },
        {
            type:'multi-enum',
            name:"siteName",
            label:"Site",
            // async:ParameterAsyncConfigurations.SITE_NAMES,
            required: true
        },{
            type:'enum',
            name:"customAttrName",
            label:"Lookup Attribute",
            depends: "resourceType",
            // async:ParameterAsyncConfigurations.CUSTOM_ATTRIBUTES,
            required: true,
            infoText:`Showing up to ${resourceByCustomAttributeValues} lookup attributes and their usage count`
        }
    ]
};

export const RESOURCES_BY_ZONE = {
    id: 13,
    url: '/v2/dashboard/resourceByZoneGroup',
    reportLink:"/reports/resources",
    defaultParams:{
        "siteName": "",
        "zoneGroup": "",
        "mapId": ""
    },
    dashboardType:'FLOOR-MAP',
    dataProvider:{
        service:"report",
        url:"/dashboards/widgets/19/data"
    },
    title: "Resources by Zone",
    parameters:[
        {
            name:"siteName",
            label:"Site",
        },
        {
            type:"enum",
            name:"zoneGroup",
            label:"Zone Group",
            depends: "siteName",
            // providers:["siteName"],
            includeFrontOptions:[
                {label:'All',value:'%'}
            ]
        }
    ],
    showHeader:false,
    columns:[
        {caption:"Zone",key:"zone",align:'left'},
        {caption:"Resource Type",key:"resourceTypeDisplayName",align:"left"},
        {caption:"Count",key:"count",align:'center'}
    ]
};
