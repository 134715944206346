import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { useSiteDesignerState, useZoneBuilderDispatch, useZoneBuilderState } from '../../../vss/app/hooks';
import { MEASURE_DEFAULT_MEASURE_SYSTEM } from '../../../vss/app/constants/SiteDesigner';
import { formatAsGeoJSON, getZonesByGeoJson, refStore } from '../../../vss/app/actions';
import { mapDetectCoordinates } from '../../../vss/app/utils/ol/OlUtils';
import { DialogZoneDetails } from './DialogZoneDetails';
import { ConfigZonesMap } from './ConfigurationZonesMap';
import { ConfigZonesToolbar } from './ConfigurationZonesToolbar';
import GridDialog from '../../../ol/utils/GridDialog';
import DialogError from '../../../vss/app/views/sites/designer/forms/DialogError';
import { useConfigurationZones, useSiteManager, useSiteManagerDispatch } from '../../../hooks';
import { DialogType, ItemType, SITE_MANAGER_CONFIGURATION_ZONES_SCOPE, VisibleItemTypes } from '../../../constants';
import { DialogDeleteChildrenZones } from './DialogDeleteChildrenZones';
import {PositionToolbar} from "../../../components/maps";
import SaveZonesDialog from "../../../vss/app/views/zone-builder/dialog/SaveZonesDialog";
import PublishZonesDialog from "../../../vss/app/views/zone-builder/dialog/PublishZonesDialog";
import DialogMessage from "../../../vss/app/views/sites/designer/forms/DialogMessage";
export const ConfigurationZones = ({ mapId }) => {
    const bounds = useSelector(state => state.reportMap.bounds);
    const maxZoom = useSelector(state => state.reportMap.maxZoom);
    const validConfiguration = useSelector(state => state.reportMap.validConfiguration);
    const updateMapSize = useSelector(state => state.reportMap.updateMapSize);
    const mapConfiguration = useSelector(state => state.reportMap.mapConfiguration) || {};
    const mapCoordinates = mapDetectCoordinates(mapConfiguration);
    const { openDialogZoneGrid } = useZoneBuilderDispatch();
    const { grid } = useZoneBuilderState();
    const { selectedMapSD } = useSiteDesignerState();
    const siteId = selectedMapSD.siteId;
    const { loadZoneTypes } = useConfigurationZones(siteId, mapId);
    const { loadItemsBySiteIdLevelIdItemType, replaceItems, closeDialog, cleanAllInteractionsOnMap, cleanSnapshots } = useSiteManagerDispatch(
        SITE_MANAGER_CONFIGURATION_ZONES_SCOPE);
    const { items, dialog } = useSiteManager(SITE_MANAGER_CONFIGURATION_ZONES_SCOPE);
    //Getting the measure from siteConfigured
    const sites = useSelector(state => state.sites.sites) || [];
    const measureUnitSite = sites.filter(itm => itm.id === mapConfiguration.siteId);
    const measurementSystem = measureUnitSite[0]?.measureSystem ? measureUnitSite[0].measureSystem : MEASURE_DEFAULT_MEASURE_SYSTEM;
    
    useEffect(() => {
        if(mapId != null && siteId != null){
            refStore.configZonesMap?.setVisibleItemTypes(VisibleItemTypes);
            void loadItemsBySiteIdLevelIdItemType({ siteId, mapId: mapId, itemType: ItemType.ZONE, takeSnapshotAfterChanges: true });

        }
        cleanAllInteractionsOnMap();
        cleanSnapshots(ItemType.ZONE);
        return () => {
            replaceItems(ItemType.ZONE, []);
        };
    }, [ siteId, mapId, loadItemsBySiteIdLevelIdItemType, loadZoneTypes, replaceItems, cleanAllInteractionsOnMap, cleanSnapshots ]);
    
    const handleChangeGridMatrix = () => {
        if (grid != null && (grid.matrix[0]
            !== refStore.configZonesMap?.gridMatrix[0]
            || grid.matrix[1]
            !== refStore.configZonesMap?.gridMatrix[1])) {
            refStore.configZonesMap?.setGridMatrix(grid.matrix);
        }
    };
    useEffect(handleChangeGridMatrix, [ grid ]);

    const zones = useMemo(() => (items[ItemType.ZONE] || []), [ items ]);
    const autoZones = useMemo(() => (items[ItemType.AUTO_ZONE] || []), [ items ]);

    const handleCloseGridDialog = () => {
        refStore.configZonesMap.removeDrawGrid();
        openDialogZoneGrid({ open: false, autoname: '', features: [] });
        refStore.configZonesMap.initializeDrawZoneGrid();
    };
    
    const handleFinishZoneGrid = () => {
        const { features, autoname } = grid;
        const zoneProps = {
            zoneName: autoname,
            namesList: features.map(zone => zone.name),
        };
        const newFeatures = features.map(feature => formatAsGeoJSON(feature));
        const zones = getZonesByGeoJson(newFeatures, zoneProps, mapId)
            .map((zone) => ({
                ...zone,
                coords: zone.shape.map((shape) => ([ shape.x, shape.y ])),
                id: zone.name,
                isDraft: true,
            }));
        refStore.configZonesMap?.finishDrawGrid(zones);
    };
    
    const handleSetGridState = (payload) => openDialogZoneGrid(payload);
    
    const onCloseDialog = () => {
        closeDialog();
    };

    return (
        <div className="configuration-container">
            <PositionToolbar configRef={refStore.configZonesMap} />
            <div className="configuration-map-container">
                <div className="configuration-map-box">
                    <ConfigZonesToolbar mapId={mapId} />
                    <ConfigZonesMap
                        siteId={siteId}
                        mapId={mapId}
                        bounds={(validConfiguration===true)?bounds:null}
                        maxZoom={maxZoom}
                        validConfiguration={validConfiguration}
                        updateMapSize={updateMapSize}
                        zones={zones}
                        autoZones={autoZones}
                        devices={[]}
                        obstructions={[]}
                        mapCoordinates={mapCoordinates}
                        mapExtent={mapConfiguration.extent}
                        measurementSystem={measurementSystem}
                    />
                </div>
            </div>
            <div
                id="ol-mouse-position-config-zones"
                className={'ol-mouse-position' + (!validConfiguration ? ' ol-hide' : '')}
            />
            {(dialog.key === DialogType.ADD_ZONE || dialog.key === DialogType.EDIT_ZONE) && (
                <DialogZoneDetails onClose={closeDialog} />
            )}
            {dialog.key === DialogType.ERROR && (
                <DialogError
                    open={true}
                    onClose={onCloseDialog}
                    title={dialog.title}
                    content={dialog.content}
                    actions={[ <Button onClick={onCloseDialog} style= {{color: 'rgba(0, 0, 0, 0.87)'}} key="OK">OK</Button> ]}
                />
            )}
            {dialog.key === DialogType.MESSAGE && (
                <DialogMessage
                    open={true}
                    onClose={onCloseDialog}
                    title={dialog.title}
                    content={dialog.content}
                    actions={[ <Button onClick={onCloseDialog} style= {{color: 'rgba(0, 0, 0, 0.87)'}} key="OK">OK</Button> ]}
                />
            )}
            {grid.open && (
                <GridDialog
                    grid={grid}
                    onClose={handleCloseGridDialog}
                    setGridState={handleSetGridState}
                    onFinish={handleFinishZoneGrid}
                />
            )}
            {dialog.key === DialogType.DELETE_ZONE && (
                <DialogDeleteChildrenZones
                    onClose={onCloseDialog}
                />
            )}
            <SaveZonesDialog />
            <PublishZonesDialog />
        </div>
    );
};
