import React from 'react';
import PropTypes from 'prop-types';

import StyledDialog from './Dialog';
// import FlatButton from 'material-ui/FlatButton';
import Button from '@material-ui/core/Button';

class ErrorDialog extends React.Component{

    constructor(){
        super();
        this.state = {
            detailsOpen: false
        };
        this.openDetails = this.openDetails.bind(this);
    }

    static get propTypes(){
        return {
            open: PropTypes.bool,
            title: PropTypes.string,
            message: PropTypes.any,
            details: PropTypes.any,
            onRequestClose: PropTypes.func
        };
    }

    static get defaultProps(){
        const nop = function(){};
        return {
            open: false,
            title: "Error",
            onRequestClose: nop
        };
    }

    openDetails() {
        this.setState({
            detailsOpen: !this.state.detailsOpen
        })
    }

    renderDetails() {
        let details = this.props.details;
        if(typeof this.props.details === "object"){
            details = this.props.details.originalError && this.props.details.originalError.info ? this.props.details.originalError.info.message:"Unexpected error";
        }
        return (
            <div>
                <br />
                <Button onClick={this.openDetails}>
                    {this.state.detailsOpen ? 'Hide ' : 'Show '}
                    details
                </Button>
                {this.state.detailsOpen ? (<div><br />{JSON.stringify(details)}<br /></div>) : null}
                <br />
            </div>
        );
    }

    render() {

        const {
            title,
            message,
            details,
            onRequestClose
        } = this.props;

        const mainButtons = [
            <Button key={'ok-button'} label="OK" onClick={onRequestClose}>OK</Button>
        ];
        
        return (
            <StyledDialog
                key={'styled-dialog'}
                onClose={onRequestClose}
                contentStyle={{maxWidth: 360}}
                title={title}
                actions={mainButtons}
                {...this.props}
            >
                <div style={{width: '100vw', maxWidth: '100%'}}>
                    {message}
                    {details ? this.renderDetails() : null}
                </div>
            </StyledDialog>
        );
    }
}

export default ErrorDialog;
