/* miscellaneous constants */

import { CONFIGURATION } from '../../../settings';
let configuration = {};

window.CONFIGURATION = CONFIGURATION;

configuration.ics = CONFIGURATION.ics || {};
configuration.reportService = CONFIGURATION.reportService || {};
configuration.accessControlService = CONFIGURATION.accessControlService || {};
configuration.authService = CONFIGURATION.authService || {};
//configuration.zoneService = CONFIGURATION.zoneService || {};
configuration.zoneBuilder = CONFIGURATION.zoneBuilder || {};
configuration.streamService = CONFIGURATION.streamService || {};
configuration.importService = CONFIGURATION.importService || {};
configuration.siteViewService = CONFIGURATION.siteViewService || {};
configuration.deviceManagementService = CONFIGURATION.deviceManagementService || {};
configuration.locationConfigurationService = CONFIGURATION.locationConfigurationService || {};
configuration.resourceService = CONFIGURATION.resourceService || {};
configuration.searchService = CONFIGURATION.searchService || {};
configuration.alertService = CONFIGURATION.alertService || {};
configuration.directionService = CONFIGURATION.directionService || {};
configuration.fileService = CONFIGURATION.fileService || {};

function adjustURLForLocalhost(url){
    return url.replace("localhost",window.location.host);
}

const ICS_HOST = adjustURLForLocalhost( configuration.ics.baseUrl || "//localhost/mwe/infrastructure/" );
const REPORT_SERVICE_HOST = adjustURLForLocalhost( configuration.reportService.baseUrl || "//localhost/mwe" );
const ACL_HOST = adjustURLForLocalhost( configuration.accessControlService.baseUrl || "//localhost/mwe/usermgmt" );
const ACCESS_HOST = adjustURLForLocalhost( configuration.authService.baseUrl || "//localhost/mwe" );
const OSM_SOURCE = adjustURLForLocalhost(CONFIGURATION.osmSource || "//localhost/tileserver");
const LOC_CONFIG_HOST = adjustURLForLocalhost(configuration.locationConfigurationService.baseUrl || "//localhost/mwe/v2");
const STREAM_SERVICE_PATH = configuration.streamService.path || "/mwe/stream/socket.io";
const STREAM_SERVICE_HOST = adjustURLForLocalhost(configuration.streamService.baseUrl || "//localhost");
const IMPORT_SERVICE_HOST = adjustURLForLocalhost(configuration.importService.baseUrl || "//localhost/mwe/import");
//const MAP_VIEW_URL = "/map-view";  // Change to /infrastructure for getting the old.
const MAP_VIEW_URL = "/mwe/v2";

const ZONE_BUILDER_ENABLED = configuration.zoneBuilder.enabled || true;
const SITE_VIEW_HOST = adjustURLForLocalhost(configuration.siteViewService.baseUrl || "//localhost");
const DEVICE_MANAGEMENT_SERVICE_HOST = adjustURLForLocalhost(configuration.deviceManagementService.baseUrl || "//localhost/mwe");
const RESOURCE_SERVICE_HOST = adjustURLForLocalhost(configuration.resourceService.baseUrl || "//localhost/mwe");
const SEARCH_SERVICE_HOST = adjustURLForLocalhost(configuration.searchService.baseUrl || "//localhost/mwe/search");
const ALERT_SERVICE_HOST = adjustURLForLocalhost(configuration.alertService.baseUrl || "//localhost/mwe/v2/alert");
const DIRECTION_SERVICE_HOST = adjustURLForLocalhost(configuration.directionService.baseUrl || "//localhost/mwe/v2");
const FILE_SERVICE_HOST = adjustURLForLocalhost(configuration.fileService.baseUrl || "//localhost/mwe/v2");
export {
    ICS_HOST,
    REPORT_SERVICE_HOST,
    ACL_HOST,
    ACCESS_HOST,
    OSM_SOURCE,
    LOC_CONFIG_HOST,
    ZONE_BUILDER_ENABLED,
    MAP_VIEW_URL,
    STREAM_SERVICE_PATH,
    STREAM_SERVICE_HOST,
    IMPORT_SERVICE_HOST,
    SITE_VIEW_HOST,
    DEVICE_MANAGEMENT_SERVICE_HOST,
    RESOURCE_SERVICE_HOST,
    SEARCH_SERVICE_HOST,
    ALERT_SERVICE_HOST,
    DIRECTION_SERVICE_HOST,
    FILE_SERVICE_HOST
};

export const FIELD = {
    DISPLAY_TYPES: {
        NO_DISPLAY: 0,
        DISPLAY: 1,
        EDIT: 2,
        COMBOBOX: 3,
        COMBOBOX_NOEDIT: 4,
        COLORBOX: 5,
        DATEBOX: 6,
        MEMOBOX: 7,
        MEMOBOX_NOEDIT: 8,
        PASSWORD: 9,
        EDIT_WITHFOLDER: 10,
        COMBOBOX_WITHFOLDER: 11,
        ICON_WITHFOLDER: 12,
        ICON_WITHREPOSITORY: 13,
        COLORBOX_READONLY: 14
    },
    DEFAULT_SOURCE_TYPES: {
        VALUE: 0,
        REPORT_ROW: 1,
        TOP_OF_LIST: 2,
        LOGIN_NAME: 3,
        COMPUTER_NAME: 4
    },
    VALUE_SOURCE_TYPES:{
        NONE: 0,
        LIST: 1,
        SELECT: 2,
        REPORT_COLUMN: 3,
        REPORT_FILTER: 4
    }
};

export const WILDCARD_ALL = "*";
export const OPTION_ALL_WILDCARD = "%";
export const OPTION_ALL_WILDCARD_OLD = "*";
export const OPTION_ALL_TEXT = "All";

export const DEFAULT_ID_ATTRIBUTE = 'tagId';

// Name of layer of tags in maps.
export const MAPS_CLUSTERED_TAG_LAYER_NAME = 'clusteredTagLayer';
export const IMPORT_ENABLED_REPORTS = ['Resources','Tags','Resource Custom Attribute Value Settings','Resource Type Settings'];

//Endpoint to get direcions sending lat and lon
export const OPEN_STREET_MAP_ENDPOINT ='https://nominatim.openstreetmap.org'

/* Site Manager Error Messages */
export const SITE_MANAGER_ERRORS = {
    DEVICES: {
        title: "There are devices associated with this map.",
        message: "You must remove all devices associated with this map before deleting the map."
    },
    ZONES: {
        title: "There are zones associated with this map.",
        message: "You must remove all zones associated with this map before deleting the map."
    }
}