import {
    ADD_SITE,
    ADD_SITE_GROUP,
    CLOSE_SITE_FORM,
    DELETE_SITE,
    DELETE_SITE_GROUP,
    LOAD_MAPS,
    LOAD_SITE_GROUPS, LOAD_SITE_LOCAL_MAP_ZONES, LOAD_SITE_SITE_ZONES,
    LOAD_SITES,
    MOVE_SITE_TO_SITEGROUP,
    OPEN_SITE_FORM,
    SELECT_FLOOR_MAP, SITE_MANAGER_SITE_HOVER,
    TOGGLE_ALLSITES, TOGGLE_MY_SITES,
    TOGGLE_SITE_SELECTION,
    TOGGLE_SITEGROUP_SELECTION,
    UPDATE_SITE,
    UPDATE_SITE_GROUP,
    UPLOAD_MAP_STATUS
} from '../constants/ActionTypes';


const DEFAULT_SITE_GROUP = {
    id:null,
    name:"Default"
};


const initialState = {
    sites: [],
    siteGroups: [DEFAULT_SITE_GROUP] ,
    activeForm: -1,
    initialFormData:{},
    maps:[],
    selectedFloorMap: null,
    upload_status: {
        siteId: null,
        progress: false,
        message: ""
    },
    layout: {
        sitesPanel: true, // By default the left panel of site manager is open.
        zonesPanel: true // By default the zones panel of zone builder is open.
    },
    siteManagerSiteHover:null, //By Ticket MWE-4705
};


function _toggleSite(site,checked){
    return {
        ...site,
        checked
    };
}

function __site(state,action){
    if(state.id !== action.id) return state;

    switch(action.type){
        case MOVE_SITE_TO_SITEGROUP:
            return {
                ...state,
                groupId: action.groupId
            };
        case UPDATE_SITE:
            return {
                ...state,
                name: action.name,
                location: action.location,
                lat: action.lat,
                long: action.long
            };
        default:
            return state;
    }
}

function __sites(state,action){

    let site = null, sites = null;

    switch(action.type){

        case LOAD_SITES:
            sites = action.data.map(v => ({
                ...v,
                id: v._id,
                checked:false,
                groupId: (v.groupMembership && v.groupMembership[0]) || null,
                name: v.name.trim()
            }));
            return sites;

        case ADD_SITE:
            sites = [].concat(state);
            sites.push({
                id: "PLACEHOLDER",
                name:action.name,
                location: action.location,
                lat: action.lat,
                long: action.long,
                groupId:action.siteGroupId
            });
            return sites;

        case TOGGLE_SITE_SELECTION:
            site = state.findIndex(v=>(v.id === action.id));
            if(site >= 0){
                let ns = [].concat(state);
                ns.splice(site,1,_toggleSite(state[site],action.checked));
                return ns;
            }
            break;

        case TOGGLE_SITEGROUP_SELECTION:
            return state.map(v=>((v.groupId === action.id)? _toggleSite(v,action.checked) : v));

        case TOGGLE_ALLSITES:
            return state.map(v=>_toggleSite(v,action.checked));

        case DELETE_SITE:
            site = state.findIndex(v=>(v.id === action.id));
            if(site >= 0){
                let ns = [].concat(state);
                ns.splice(site,1);
                return ns;
            }
            break;

        case DELETE_SITE_GROUP:
            return state.map(v=>({
                ...v,
                groupId: (v.groupId === action.id) ? null : v.groupId
            }));

        case MOVE_SITE_TO_SITEGROUP:
        case UPDATE_SITE:
            site = state.findIndex(v=>(v.id === action.id));
            if(site >= 0){
                let ns = [].concat(state);
                ns.splice(site,1, __site( state[site], action ));
                return ns;
            }
            break;
        default:
            return state;

    }
    return state;
}
function siteGroups(state,action){
    let groups;

    switch(action.type){

        case LOAD_SITE_GROUPS:
            groups = action.data.map( v => ({
                ...v,
                id: v._id
            }));
            return [DEFAULT_SITE_GROUP].concat(groups);

        case ADD_SITE_GROUP:
            groups = [].concat(state);
            groups.push({
                id: null,
                name:action.name
            });
            return groups;

        case DELETE_SITE_GROUP:
            return state.filter(v=>(v.id !== action.id));

        case UPDATE_SITE_GROUP:
            return state.map(v=>((v.id === action.id) ? (
                {
                    ...v,
                    name: action.name
                }
            ) : v));
        default:
            return state;
    }
}

export function sites(state = initialState, action){

    switch(action.type){

        case LOAD_SITES:
        case TOGGLE_SITE_SELECTION:
        case TOGGLE_SITEGROUP_SELECTION:
        case TOGGLE_ALLSITES:
        case MOVE_SITE_TO_SITEGROUP:
        case DELETE_SITE:
        case UPDATE_SITE:
        case ADD_SITE:
            return {...state,sites:__sites(state.sites,action)};


        case LOAD_SITE_GROUPS:
        case ADD_SITE_GROUP:
        case UPDATE_SITE_GROUP:
            return {
                ...state,
                siteGroups:siteGroups(state.siteGroups,action)
            };
        case DELETE_SITE_GROUP:
            return {
                ...state,
                sites:__sites(state.sites,action),
                siteGroups:siteGroups(state.siteGroups,action)
            };

        case OPEN_SITE_FORM:
            return {
                ...state,
                activeForm: action.form,
                initialFormData: action.data || {}
            };
        case CLOSE_SITE_FORM:
            return {
                ...state,
                activeForm:-1,
                initialFormData: {}
            };

        case LOAD_MAPS:
            return {
                ...state,
                maps: action.data
            };

        case UPLOAD_MAP_STATUS:
            return {
                ...state,
                upload_status: action.data
            };

        case SELECT_FLOOR_MAP:
            return {
                ...state,
                selectedFloorMap: action.map
            };
        case TOGGLE_MY_SITES:
            return {
                ...state,
                layout: {
                    ...state.layout,
                    [action.panelKey]: !state.layout[action.panelKey]
                }
            };
        case SITE_MANAGER_SITE_HOVER:
            return{
                ...state,
                siteManagerSiteHover:action.hoverUID
            }
        case LOAD_SITE_SITE_ZONES:
            return{
                ...state,
                zones: {
                    ...state.zones,
                    sites: {
                        ...state.zones?.sites,
                        [action.siteId]: action.zones
                    }
                }
            };
        case LOAD_SITE_LOCAL_MAP_ZONES:
            return{
                ...state,
                zones: {
                    ...state.zones,
                    maps: {
                        ...state.zones?.maps,
                        [action.mapId]: action.zones
                    }
                }
            };
        default:
            return state;
    }
}
