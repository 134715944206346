import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import {
    filtersOnePlatformReplaceAllFilters,
    filtersOnePlatformReplaceFilter,
    filtersOnePlatformPatchFilter,
    loadDefaultColumns,
    loadFilter,
    loadCustomFieldNames, /*changeConfiguration,*/ patchTimeFilter
} from '../actions/filtersOnePlatform';
import { processFiltersValues } from '../utils';

const selectFilterValues = createSelector(
    (state, reportKey) => state.filtersOnePlatform[reportKey],
    (filters) => {
        return processFiltersValues(filters);
    },
);

export const useOnePlatformFilterValuesState = (reportKey) => {
    return useSelector(state => selectFilterValues(state, reportKey), (prevState, nextState) => {
        return JSON.stringify(prevState) === JSON.stringify(nextState);
    });
};

export const useOnePlatformFiltersDispatch = (reportKey) => {
    const dispatch = useDispatch();
    return {
        updateFilter: (filter, filterKey) => () => dispatch(filtersOnePlatformReplaceFilter(filter, filterKey, reportKey)),
        patchFilter: (filter, filterKey) => dispatch(filtersOnePlatformPatchFilter(filter, filterKey, reportKey)),
        updateAllFilters: useCallback((filters) => dispatch(filtersOnePlatformReplaceAllFilters(filters, reportKey)), [dispatch, reportKey]),
        loadFilter: (filterKey, params) => () => dispatch(loadFilter(reportKey, filterKey, params)),
        loadCustomFieldNames: (resourceType,customAttributeName,fieldNameSelected,filter) => () => dispatch(loadCustomFieldNames(reportKey, resourceType,customAttributeName,fieldNameSelected,filter)),
        loadDefaultColumns: useCallback(() => dispatch(loadDefaultColumns(reportKey)), [dispatch, reportKey]),
        patchTimeFilter: (filter, filterKey) => dispatch(patchTimeFilter(reportKey, filter, filterKey))
    };
};
