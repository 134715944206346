export const getCoordinatesFields = (coordinatesLength, coordinateFormat) => {
    
    let coordinatesFields = [];
    for (let index = 0; index < coordinatesLength; index++) {
        const coordinateFixed = { ...coordinateFormat };
        let { label, required, fields } = coordinateFixed;
        let { x, y } = fields;
        label = `${coordinateFixed.label} ${index + 1}`;
        
        if (index === coordinatesLength - 1) {
            // for the last coordinate
            coordinatesFields.push({
                label,
                required,
                fields: {
                    x: { ...x, path: `$.coordinates.${index}.0`, disabled: true },
                    y: { ...y, path: `$.coordinates.${index}.1`, disabled: true },
                },
            });
        } else {
            coordinatesFields.push({
                label,
                required,
                fields: { x: { ...x, path: `$.coordinates.${index}.0` }, y: { ...y, path: `$.coordinates.${index}.1` } },
            });
        }
    }
    
    return coordinatesFields;
};
