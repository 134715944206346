import {CLOSE_SNACKBAR, DISPLAY_SNACKBAR_MESSAGE} from '../constants/ActionTypes';

const initialState = {
    open:false,
    message:""
};

export function snackbar(state = initialState, action){
    switch(action.type){
        case DISPLAY_SNACKBAR_MESSAGE:
            return {open:true,message:action.text};
        case CLOSE_SNACKBAR:
            return {open:false,message:""};
        default:
            return state;
    }
}
