import { getSafeValueOfPath } from '../../utils';

export const generateBody = (structure, data) => {
    if (structure === '*' || structure === 'text' || structure === 'number' || structure === 'boolean' || structure === 'null') {
        if (structure === '*'
            || (structure === 'text' && typeof data === 'string')
            || (structure === 'number' && typeof data === 'number')
            || (structure === 'boolean' && typeof data === 'boolean')
            || (structure === 'null' && data === null)) {
            return { result: data, success: true };
        }
        console.warn('Structure dont\' match with data', { structure, data });
        return { success: false };
    }
    if (Array.isArray(structure)) { // $type = array
        if (Array.isArray(data)) {
            const configuration = structure[0] || '*';
            const result = [];
            data.forEach((data) => {
                const { result: myResult, success } = generateBody(configuration, data);
                if (success) {
                    result.push(myResult);
                }
            });
            return { result, success: true };
        }
        console.warn('data isn\'t an array and structure is an array', { structure, data });
        return false;
    }
    if (typeof structure === 'object') {
        const { value, success } = getSafeValueOfPath(data, structure.$valuePath);
        if (structure.$bodyOr || structure.$body) {
            if (success) {
                const bodies = structure.$bodyOr || [structure.$body];
                for (const myBody of bodies) {
                    const { result: myResult, success } = generateBody(myBody, value);
                    if (success) {
                        return { result: myResult, success: true };
                    }
                }
            }
            console.warn('body or bodyOr structure not valid', { structure, data });
            return { success: false };
        }
        const body = {};
        for (const key in structure) {
            let { value, success } = structure[key].$valuePath ? getSafeValueOfPath(data, structure[key].$valuePath)
                : (data?.hasOwnProperty(key) ? { value: data[key], success: true } : { success: false });
            
            if (success && (structure[key].$bodyOr || structure[key].$body)) {
                const bodies = structure[key].$bodyOr || [structure[key].$body];
                let success = false;
                for (const myBody of bodies) {
                    const { result: myResult, success: isSuccess } = generateBody(myBody, value);
                    if (isSuccess) {
                        body[key] = myResult;
                        success = true;
                        break;
                    }
                }
                if (!success) {
                    console.warn('body or bodyOr of structure[key] not valid', { structure, data, key });
                }
            } else if (success) {
                const { success, result } = generateBody(structure[key], value);
                if (success) {
                    body[key] = result;
                } else {
                    console.warn('data or structure not valid', { structure: structure[key], data: value });
                }
            } else {
                console.warn('data or structure not valid', { structure, data });
            }
        }
        return { result: body, success: true };
    }
    console.warn('No body structure valid', { structure, data });
    return { success: false };
};

const getArrayParams = (params) => {
    const newParams = [];
    params.forEach(param => {
        if (param.charAt(param.length - 1) === ']' && param.length >= 2) {
            newParams.push('[' + param);
        }
    });
    return newParams;
};

const getParams = (path) => {
    return path.split('.').map(param => {
        const params = param.split('[');
        if (params[0] === param) {
            return param;
        }
        const arrayParams = getArrayParams(params.slice(1));
        return [params[0], ...arrayParams];
    }).flat();
};

const getIndex = (param) => {
    const substr = param.match(/\d+/)?.pop();
    if (`[${substr}]` === param) { // is index of an array
        const index = +substr;
        if (!Number.isNaN(index) && index >= 0) {
            return index;
        }
    }
    return -1;
};

export const jsonPathReverseSetValue = (data, field, path, value) => {
    const obj = JSON.parse(JSON.stringify(data || {}));
    const params = getParams(path);
    let parentObject = obj;
    let node = field;
    for (let i = 1; i < params.length; i++) {
        if (getIndex(params[i]) !== -1) { // is index of an array
            const index = getIndex(params[i]);
            if (!Array.isArray(parentObject[node])) {
                parentObject[node] = new Array(index + 1);
            }
            parentObject = parentObject[node];
            node = index;
        } else {
            if (typeof parentObject[node] !== 'object' || !parentObject[node]) {
                parentObject[node] = {};
            }
            parentObject = parentObject[node];
            node = params[i];
        }
    }
    if (value === undefined) {
        delete parentObject[node];
    } else {
        parentObject[node] = value;
    }
    return obj;
};

export const clearEmptyObjects = (data, field, path, emptyValues = [null, undefined]) => {
    const obj = JSON.parse(JSON.stringify(data || {}));
    const params = getParams(path);
    let parentObject = obj;
    let node = field;
    const parentHistory = [];
    const nodeHistory = [];
    parentHistory.push(parentObject);
    nodeHistory.push(node);
    for (let i = 1; i < params.length; i++) {
        if (getIndex(params[i]) !== -1) { // is index of an array
            const index = getIndex(params[i]);
            if (!Array.isArray(parentObject[node])) {
                parentObject[node] = new Array(index + 1);
            }
            parentObject = parentObject[node];
            node = index;
        } else {
            if (typeof parentObject[node] !== 'object' || !parentObject[node]) {
                parentObject[node] = {};
            }
            parentObject = parentObject[node];
            node = params[i];
        }
        parentHistory.push(parentObject);
        nodeHistory.push(node);
    }
    for (let i = parentHistory.length - 1; i > 0; i--) {
        const value = parentHistory[i][nodeHistory[i]];
        if (Array.isArray(value)) {
            const cleanValue = value.filter(v => emptyValues.every(val => JSON.stringify(val) !== JSON.stringify(v)));
            if (cleanValue.length > 0) {
                break;
            }
        } else if (value !== null && typeof value === 'object') {
            const cleanValue = Object.values(value)
                .filter(v => emptyValues.every(val => JSON.stringify(val) !== JSON.stringify(v)));
            if (cleanValue.length > 0) {
                break;
            }
        } else if (emptyValues.every(val => JSON.stringify(val) !== JSON.stringify(value))) {
            break;
        }
        delete parentHistory[i][nodeHistory[i]];
    }
    return obj;
};

//will return new object with the correct values removing null fields if is required

export const formatToStrictTypeField = (data, removeNullEmptyFields = false, removeSpecificFields = []) => {
    let newObject = {};
    newObject = data;
    // eslint-disable-next-line
    Object.keys(data).map(key => {
        newObject[key] = parseType(data[key], typeof data[key]);
    });
    
    //this flag will remove the newObject values equals to null if is set as true
    if (removeNullEmptyFields) {
        Object.keys(newObject).forEach(key => {
            if (newObject[key] === null) {
                delete newObject[key];
            }
        });
    } else if (!newObject['siteId'] && !newObject['mapId']) {
        delete newObject['siteId'];
        delete newObject['mapId'];
    }
    
    //This section will remove an specific fields from an array of strings
    if (removeSpecificFields.length > 0) {
        removeFields(newObject, removeSpecificFields);
    }
    
    return newObject;
};

function parseType(value, fieldType) {
    switch (fieldType) {
        case 'object': //verify if all fields from object are null if are null then the object will be set as null
            return value ? (verifyEmptyFieldObject(value) ? value : null) : null;
        default:
            return value;
    }
}

function verifyEmptyFieldObject(objectValues) {
    Object.keys(objectValues).forEach(key => {
        if (objectValues[key] === null) {
            delete objectValues[key];
        }
    });
    return Object.keys(objectValues).length > 0;
}

function removeFields(data, removeSpecificFields) {
    // eslint-disable-next-line
    removeSpecificFields.map(field => {
            delete data[field];
        }
    );
    return data;
}
