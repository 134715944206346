import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import ToolbarTool from '../../../vss/app/views/util/toolbar/ToolbarTool';
import {
    // CheckBox,
    // CheckBoxOutlineBlank,
    CropDin,
    Dashboard,
    Delete,
    FileCopy,
    FilterBAndW,
    FlipToBack,
    GridOn,
    InsertDriveFile,
    Label,
    LinearScale,
    Palette,
    PanTool,
    Redo,
    Undo,
    BorderOuterRounded
    // Add,
} from '@material-ui/icons';

import FillColorIcon from '../../../vss/app/views/icons/FillColorIcon';
import DrawColorIcon from '../../../vss/app/views/icons/DrawColorIcon';
import {openDataEntry, refStore} from '../../../vss/app/actions';
import ColorPopover from '../../../vss/app/views/zone-builder/panel/ColorPopover';
import { DEFAULT_ZONE_COLOR } from '../../../vss/app/utils/ol/olConstants';
// import { AddButton } from '../../../vss/app/views/sites/toolbar/AddButton';

import { useSiteManager, useSiteManagerDispatch } from '../../../hooks';
import { InteractionType, ItemType, SITE_MANAGER_CONFIGURATION_ZONES_SCOPE } from '../../../constants';
import { useZoneBuilderState } from '../../../vss/app/hooks';
import DialogReplicateZones from '../../../vss/app/views/sites/designer/forms/DialogReplicateZones';
import Toolbar from "@material-ui/core/Toolbar";
import SaveIcon from '@material-ui/icons/Save';
import PublishIcon from '@material-ui/icons/Publish';
import * as forms from "../../../vss/app/constants/DataEntryForms";
import ToolbarGroup from "../../../vss/app/views/util/toolbar/ToolbarGroup";
import ImportExportIcon from '@material-ui/icons/ImportExport';
import ToolbarToolImport from "../../../vss/app/views/util/toolbar/ToolbarToolImport";
export const ZoneSnapLabel = () => {
    return null;
    // const { addInteractionOnMap, removeInteractionOnMap } = useSiteManagerDispatch(
    //     SITE_MANAGER_CONFIGURATION_ZONES_SCOPE);
    // const { mapInteractions } = useSiteManager(SITE_MANAGER_CONFIGURATION_ZONES_SCOPE);
    // const { selectedZoneIds } = useZoneBuilderState();
    
    // const isActive = (interaction) => mapInteractions.includes(interaction);
    // const toggleInteraction = (interaction) => () => isActive(interaction) ? removeInteractionOnMap(interaction) : addInteractionOnMap(
    //     interaction);
    
    // return (isActive(InteractionType.TRANSLATE) && (
    //     <div
    //         style={{
    //             position: 'absolute', top: 65, left: 55,
    //             color: selectedZoneIds.length > 1 ? 'gray' : 'black',
    //             display: 'flex', alignItems: 'center',
    //             fontSize: '14px',
    //             zIndex: 1,
    //         }}
    //     >
    //         <div
    //             onClick={toggleInteraction(InteractionType.SNAP_TO_ADJACENT_ON_TRANSLATE)}
    //             style={{
    //                 cursor: selectedZoneIds.length <= 1 ? 'pointer' : undefined,
    //                 display: 'flex',
    //                 alignItems: 'center',
    //             }}
    //         >
    //             {isActive(InteractionType.SNAP_TO_ADJACENT_ON_TRANSLATE) && selectedZoneIds.length <= 1
    //                 ? <CheckBox />
    //                 : <CheckBoxOutlineBlank />}
    //         </div>
    //         &nbsp;<T>zone snap to adjacent zone</T>
    //     </div>
    // ));
};

const styleEnabled = { width: '24px', height: '24px', color: 'white' };
const styleDisabled = { width: '24px', height: '24px', color: 'grey' };
const styleActive = { width: '24px', height: '24px', color: '#4FB7E3' };

export const ConfigZonesToolbar = ({ mapId }) => {
    
    const { addInteractionOnMap, removeInteractionOnMap } = useSiteManagerDispatch(
        SITE_MANAGER_CONFIGURATION_ZONES_SCOPE);
    const { mapInteractions, backSnapshots, forwardSnapshots } = useSiteManager(SITE_MANAGER_CONFIGURATION_ZONES_SCOPE);
    const { selectedZoneIds } = useZoneBuilderState();
    const [ drawingColor, setDrawingColor ] = useState(DEFAULT_ZONE_COLOR);
    const [ colorPickerAnchorEl, setColorPickerAnchorEl ] = useState(null);
    const [ replicateZone, setReplicateZone ] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        setReplicateZone(false);
        setDrawingColor(DEFAULT_ZONE_COLOR);
    }, [ mapId ]);
    
    const onColorPickerToggle = (e) => setColorPickerAnchorEl(e.currentTarget);
    
    const drawingColorHandler = (e) => {
        setDrawingColor(e);
        if (refStore.configZonesMap) {
            refStore.configZonesMap.color = e;
        }
    };
    
    const isActive = (interaction) => mapInteractions.includes(interaction);
    
    const getStyleActive = (interaction) => isActive(interaction) ? styleActive : styleEnabled;
    
    const toggleInteraction = (interaction) => () => isActive(interaction) ? removeInteractionOnMap(interaction) : addInteractionOnMap(
        interaction);
    const saveZones = type => dispatch(openDataEntry(type, {}));
    const disabled = false;//!!!(mapConfig && mapConfig._id);

    const SAVE_OPTIONS = [
        {
            icon: <SaveIcon />,
            title: "Save to Staging",
            actions: () => saveZones(forms.ZONE_BUILDER_SAVE_ZONES)
        },
        {
            icon: <PublishIcon />,
            title: "Publish Zones",
            actions: () => saveZones(forms.ZONE_BUILDER_PUBLISH_ZONES)
        },
    ];

    return (
        <div className="configuration-toolbar MuiPaper-elevation1">
            <Toolbar style={{ width: 'fit-content',minHeight:'50px' }}>
                <div className="actions-toolbar-smartlens-main" style={{ width: 'auto' }}>
                    <div className="actions-toolbar-tools-container">
                        <ToolbarGroup
                            icon={<SaveIcon style={getStyleActive(InteractionType.SAVE)}  />}
                            title="Save/Publish Zones"
                            disabled={disabled}
                            items={SAVE_OPTIONS}
                        />
                        <ToolbarToolImport
                            title="Import"
                            icon={<ImportExportIcon style={getStyleActive(InteractionType.SAVE)}  />}
                            disabled={disabled}
                        />
                        <ToolbarTool
                            onClick={toggleInteraction(InteractionType.DRAW)}
                            icon={<DrawColorIcon fillColor={drawingColor} style={getStyleActive(InteractionType.DRAW)} />}
                            title="Draw Zone"
                        />
                        <ToolbarTool
                            onClick={toggleInteraction(InteractionType.DRAW_ZONE_GRID)}
                            icon={<GridOn style={getStyleActive(InteractionType.DRAW_ZONE_GRID)} />}
                            title="Draw Zone Grid"
                        />
                        <ToolbarTool
                            onClick={toggleInteraction(InteractionType.MODIFY)}
                            icon={
                                <LinearScale style={getStyleActive(InteractionType.MODIFY)} />
                            }
                            title="Modify Zone Shape"
                        />
                        <ToolbarTool
                            onClick={() => addInteractionOnMap(InteractionType.REMOVE)}
                            icon={<Delete style={selectedZoneIds?.length ? styleEnabled : styleDisabled} />}
                            title="Delete Zone"
                        />
                        <ToolbarTool
                            onClick={() => addInteractionOnMap(InteractionType.COPY)}
                            icon={<FileCopy style={selectedZoneIds?.length ? styleEnabled : styleDisabled} />}
                            title="Copy Zone"
                        />
                        <ToolbarTool
                            onClick={toggleInteraction(InteractionType.PASTE)}
                            icon={
                                <InsertDriveFile style={isActive(InteractionType.COPY) ? styleEnabled : styleDisabled} />
                            }
                            title="Paste Zone"
                        />
                        <ToolbarTool
                            onClick={toggleInteraction(InteractionType.UNDO)}
                            icon={
                                <Undo
                                    style={(backSnapshots?.[ItemType.ZONE] || []).length >= 2
                                        ? styleEnabled
                                        : styleDisabled}
                                />
                            }
                            title="Undo Zone Changes"
                        />
                        <ToolbarTool
                            onClick={toggleInteraction(InteractionType.REDO)}
                            icon={
                                <Redo
                                    style={(forwardSnapshots?.[ItemType.ZONE] || []).length
                                        ? styleEnabled
                                        : styleDisabled}
                                />
                            }
                            title="Redo Zone Changes"
                        />
                        <ToolbarTool
                            onClick={toggleInteraction(InteractionType.AREA_SELECTOR)}
                            icon={<CropDin style={getStyleActive(InteractionType.AREA_SELECTOR)} />}
                            title="Area Selector"
                        />
                        <ToolbarTool
                            onClick={toggleInteraction(InteractionType.JOIN_NEIGHBOR)}
                            icon={<Dashboard style={getStyleActive(InteractionType.JOIN_NEIGHBOR)} />}
                            title="Join Neighbor Zones"
                        />
                        <ToolbarTool
                            onClick={toggleInteraction(InteractionType.TRANSLATE)}
                            icon={<PanTool style={getStyleActive(InteractionType.TRANSLATE)} />}
                            title="Move Zone"
                        />
                        <ToolbarTool
                            onClick={(e) => onColorPickerToggle(e)}
                            icon={<Palette style={styleEnabled} />}
                            title="Pick Zone Color"
                        />
                        <ToolbarTool
                            onClick={toggleInteraction(InteractionType.FILL_COLOR)}
                            icon={<FillColorIcon fillColor={drawingColor} style={styleEnabled} />}
                            title="Fill Zone Color"
                        />
                        <ToolbarTool
                            onClick={toggleInteraction(InteractionType.SHOW_HIDE_LABELS)}
                            icon={<Label style={getStyleActive(InteractionType.SHOW_HIDE_LABELS)} />}
                            title="Show/Hide Labels"
                        />
                        <ToolbarTool
                            onClick={toggleInteraction(InteractionType.GREY_MODE)}
                            icon={<FilterBAndW style={getStyleActive(InteractionType.GREY_MODE)} />}
                            title="Grey Mode"
                        />
                        <ToolbarTool
                            onClick={() => setReplicateZone(true)}
                            icon={<FlipToBack style={selectedZoneIds?.length ? styleEnabled : styleDisabled} />}
                            disabled={!selectedZoneIds?.length}
                            title="Replicate a Zone"
                        />

                        <ToolbarTool
                            onClick={toggleInteraction(InteractionType.TOGGLE_VISIBILITY_AUTO_ZONES)}
                            icon={<BorderOuterRounded style={getStyleActive(InteractionType.TOGGLE_VISIBILITY_AUTO_ZONES)} />}
                            title="Show/Hide Auto-Zones"
                        />
                        {/*<ToolbarTool*/}
                        {/*    onClick={toggleInteraction(InteractionType.DRAW_RANDOM_ZONES)}*/}
                        {/*    icon={<Add style={styleEnabled} />}*/}
                        {/*    title="Generate Random Zones"*/}
                        {/*/>*/}
                        <ColorPopover
                            color={drawingColor}
                            anchorEl={colorPickerAnchorEl}
                            onChange={(color) => drawingColorHandler(color)}
                            setAnchorEl={(anchorEl) => setColorPickerAnchorEl(anchorEl)}
                        />
                        {replicateZone && (
                            <DialogReplicateZones
                                open={replicateZone}
                                onClose={() => setReplicateZone(false)}
                            />
                        )}
                    </div>
                </div>
            </Toolbar>
        </div>
    );
};
