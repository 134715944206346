import React, {useEffect} from 'react';
import '../../../sass/app/deviceManager.scss'
import '../../../sass/app/resourceTypes.scss';
import CancelIcon from '@material-ui/icons/Cancel';
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import LeakAddRoundedIcon from '@material-ui/icons/LeakAddRounded';
import WarningIcon from '@material-ui/icons/Warning';
import {displayNotification, updateIdSetNotification} from "../../actions";
import {useDispatch, useSelector} from "react-redux";
import {
    ALERT_SUCCESS, ALERT_ERROR, ALERT_LOADING,
    ALERT_WARNING, ALERT_RECEIVER
} from "../../constants/DeviceManager";


const Notification  = (props) => {
    const dispatch = useDispatch();
    const {type,name,subTitle,message,iconAlert,autoHideDuration, open} =  props;
    const notificationIdSetTimeOut = useSelector(state => state.deviceManager.notification?.idSetTimeOut);
    const SIZE_ALERT_ICON = 30;

    const showNotification = () => {
        return (<React.Fragment>
            {open && <div className={'notification-container'}>
                <div className={'notification-container-main'}>
                    {iconAlert? renderIcon(type): null}
                    <div className={'notification-container-title'}>
                        <b>{name}</b>&nbsp;
                        <span>{subTitle}</span>
                    </div>
                    <CancelIcon  onClick={closeButton} />
                </div>
                {message && <div className={'notification-container-details'}>{message}</div>}
            </div>}
        </React.Fragment>)
    }

    const renderIcon = type => {
        if (type === ALERT_SUCCESS)
            return <CheckCircleRoundedIcon className={'image'} width={SIZE_ALERT_ICON} height={SIZE_ALERT_ICON} style={{color:'green'}}/>;
        else if (type === ALERT_LOADING)
            return <CircularProgress className={'image'} width={SIZE_ALERT_ICON} height={SIZE_ALERT_ICON} />;
        else if(type ===ALERT_RECEIVER)
             return<LeakAddRoundedIcon className={'image'} width={SIZE_ALERT_ICON} height={SIZE_ALERT_ICON} />;
        else if (type === ALERT_ERROR)
            return <CancelIcon className={'image'} width={SIZE_ALERT_ICON} height={SIZE_ALERT_ICON}  style={{color:'red'}}/>;
        else if (type === "close")
            return <CancelIcon className={'image'} width={SIZE_ALERT_ICON} height={SIZE_ALERT_ICON}  style={{color:'red'}}/>;
        else if (type === ALERT_WARNING)
            return <WarningIcon className={'image'}/>;
        else return null
    };

    const autoHidden = () =>{
        if(notificationIdSetTimeOut && notificationIdSetTimeOut !== 0){
            clearTimeout(notificationIdSetTimeOut);
        }
        if(autoHideDuration && autoHideDuration>0){
            let idSetTimeOut = setTimeout(() => {closeButton();}, autoHideDuration);
            dispatch(updateIdSetNotification(idSetTimeOut));
        }
    }
    useEffect( autoHidden,[type,name]);

    const closeButton = () =>{
        dispatch(displayNotification(false,'','', '','', '','',0));
    };

    switch (type) {
        case ALERT_ERROR :
        case ALERT_WARNING :
        case ALERT_SUCCESS  :
        case ALERT_RECEIVER :
            return showNotification();
        default:
            return null;
    }
};
export default Notification;