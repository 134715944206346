import React from 'react';

import {VectorLayer} from "../../../../../../common/app/views/map/Layer";
import Feature from "../../../../../../common/app/views/map/Feature";
//openlayers
import Style from 'ol/style/Style';
import Text from 'ol/style/Text';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import CircleStyle from 'ol/style/Circle';
import Point from 'ol/geom/Point';
import LineString from 'ol/geom/LineString';

class SingleTagPathLayer extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            tags: {}
        };

        this.renderTagPoints = this.renderTagPoints.bind(this);
        this.renderTagPaths = this.renderTagPaths.bind(this);
        this.hoverTagStyle = this.hoverTagStyle.bind(this);
    }



    UNSAFE_componentWillReceiveProps(nextProps){
        this.setState({
            tags: nextProps.tags
        });
    }

    hoverTagStyle(item){
        let label = item;
        let labelStyle = {};
        let radius = 5;
        let fillColor = 'yellow';
        let strokeColor = 'red';

        if(item.selected === true){
            fillColor = 'red';
        }

        if(this.props.showTagLabels===true){
            labelStyle = {text: new Text({
                    font: '12px Calibri,sans-serif',
                    fontWeight:'600',
                    fill: new Fill({ color: '#000' }),
                    stroke: new Stroke({
                        color: '#fff', width: 2
                    }),
                    text: label.toString(),
                    offsetY:-11,
                })};
        }

        return new Style({
            image: new CircleStyle({
                radius: radius,
                fill: new Fill({color: fillColor}),
                stroke: new Stroke({color: strokeColor, width: 1})
            }),
            ...labelStyle
        });
    }

    renderTagPoints(item){
        const itemKey = Object.keys(item);
        if(itemKey.length > 0 && itemKey !== null) {
            const styles = this.hoverTagStyle(itemKey[0]);

            return item[itemKey[0]].map((v, i) => {

                    return (
                        <Feature
                            id={"tag" + itemKey[0] + "_" + i}
                            key={"tag" + itemKey[0] + "_" + i}
                            geometry={new Point([v.x, v.y])}
                            style={styles}
                            data={item[itemKey[0]]}
                        />
                    );
                }
            );
        } else {
            return null;
        }
    }

    renderTagPaths(item){
        const style = new Style({
            stroke: new Stroke({color: 'red', width: 2})
        });

        const itemKey = Object.keys(item);

        if(itemKey > 0) {
        let x = [];

        for(let index = 1; index < item[itemKey[0]].length; index++){
            x.push(<Feature
                    key={"tag"+itemKey[0]+"_path_"+index}
                    geometry={new LineString([[ item[itemKey[0]][index - 1].x , item[itemKey[0]][index - 1].y ], [ item[itemKey[0]][index].x , item[itemKey[0]][index].y ] ])}
                    style={style}
                    data={item[itemKey]}
                    projection={"EPSG:4326"}
                />);
        }
        return x;
        } else {
            return null
        }
    }

    render(){

        const map = window.MY_MAP;
        const {tags} = this.state;

        let _tags =  this.renderTagPoints(tags);
        // let _tags =  null;
        let _paths = this.renderTagPaths(tags);
        // let _paths = null;

        return (
            <VectorLayer map={map} {...this.props} >
                {_tags}
                {_paths}
            </VectorLayer>
        );
    }
}

export default SingleTagPathLayer;