import { SITE_MANAGER_ENDPOINTS } from './constants';
import {getUrl, getUrlSiteDesigner} from '../../vss/app/utils';

export const getItemsBySiteIdMapIdDeviceType = async (siteId, mapId, itemType) => {
    const {
        urlTemplate,
        service,
        method,
        getResultData,
    } = SITE_MANAGER_ENDPOINTS[itemType].GET_ALL;
    const url = getUrlSiteDesigner({ urlTemplate: typeof urlTemplate === 'function' ? urlTemplate({ siteId, mapId: mapId }) : urlTemplate });
    const response = await service.instance().request({ url, method });
    return getResultData(response);
};

export const getStagingItemsByMapId = async (siteId, mapId, itemType) => {
    const {
        urlTemplate,
        service,
        method,
        getResultData,
    } = SITE_MANAGER_ENDPOINTS[itemType].GET_ALL_STAGING;
    const url = getUrlSiteDesigner({ urlTemplate: typeof urlTemplate === 'function' ? urlTemplate({ siteId, mapId: mapId }) : urlTemplate });
    const response = await service.instance().request({ url, method });
    return getResultData(response);
};

export const getAutoZonesByMapId = async (siteId, mapId, itemType) => {
    const {
        urlTemplate,
        service,
        method,
        getResultData,
    } = SITE_MANAGER_ENDPOINTS[itemType].GET_AUTO_ZONES;
    const url = getUrlSiteDesigner({ urlTemplate: typeof urlTemplate === 'function' ? urlTemplate({ siteId, mapId: mapId }) : urlTemplate });
    const response = await service.instance().request({ url, method });
    return getResultData(response);
};

export const createItemByItemType = async (itemType, payload) => {
    const {
        urlTemplate,
        service,
        method,
    } = SITE_MANAGER_ENDPOINTS[itemType].CREATE;
    const url = getUrl({ urlTemplate: typeof urlTemplate === 'function' ? urlTemplate({}) : urlTemplate });
    return await service.instance().request({ url, method, data: payload });
};

export const bulkCreateItemByItemType = async (itemType, payload) => {
    const {
        urlTemplate,
        service,
        method,
    } = SITE_MANAGER_ENDPOINTS[itemType].BULK_CREATE;
    const url = getUrl({ urlTemplate: typeof urlTemplate === 'function' ? urlTemplate({}) : urlTemplate });
    return await service.instance().request({ url, method, data: payload });
};

export const updateItemByItemIdItemType = async (itemType, itemId, payload) => {
    const {
        urlTemplate,
        service,
        method,
    } = SITE_MANAGER_ENDPOINTS[itemType].UPDATE;
    const url = getUrl({ urlTemplate: typeof urlTemplate === 'function' ? urlTemplate({ itemId }) : urlTemplate });
    return await service.instance().request({ url, method, data: payload });
};

export const bulkUpdateByItemType = async (itemType, payload) => {
    const {
        urlTemplate,
        service,
        method,
    } = SITE_MANAGER_ENDPOINTS[itemType].BULK_UPDATE;
    const url = getUrl({ urlTemplate: typeof urlTemplate === 'function' ? urlTemplate({}) : urlTemplate });
    return await service.instance().request({ url, method, data: payload });
};

export const deleteItemByItemIdItemType = async (itemType, itemId) => {
    const {
        urlTemplate,
        service,
        method,
    } = SITE_MANAGER_ENDPOINTS[itemType].DELETE;
    const url = getUrl({ urlTemplate: typeof urlTemplate === 'function' ? urlTemplate({ itemId }) : urlTemplate });
    return await service.instance().request({ url, method });
};

export const bulkDeleteItemsByItemIdsItemType = async (itemType, itemIds, props) => {
    const {
        urlTemplate,
        service,
        method,
    } = SITE_MANAGER_ENDPOINTS[itemType].BULK_DELETE;
    const url = getUrl({ urlTemplate: typeof urlTemplate === 'function' ? urlTemplate({ itemIds, props }) : urlTemplate });
    return await service.instance().request({ url, method });
};

export * from './zones';
