import React from 'react';
import Jp from 'jsonpath';

import { getLocalTimestamp } from './LocaleTimestamp';
import ResourceTypesViewProfile from '../views/resourceTypes/ResourceTypesViewProfile';
import ResourceTypesViewAttributes from '../views/resourceTypes/ResourceTypesViewAttributes';
import { REPORT_ID_RESOURCE_TYPES, REPORT_ID_RESOURCES, REPORT_ID_ZONES } from '../constants';
import { getValueOfPath } from './reports';
import ImageDisplayAsIcon from '../views/icons/ImageDisplayAsIcon';
import ButtonFirmware from "../views/deviceManager/firmwares/ButtonFirmware";
import NewValue from "../views/history/NewValue";
import {FieldNameCells} from "../views/resources/FieldNameCell";

const TEXT = 'TEXT';
const DATE_FORMAT_CONFIGURATION = 'DATE';
const SECONDS_FORMAT_CONFIGURATION = 'FORMAT_DWELL';
export const FORMAT_TAGS_CONFIGURATION = 'CUSTOM_TAG';
const DISPLAY_IMAGE_CONFIGURATION = 'DISPLAY_IMAGE_CONFIGURATION';
const FORMAT_BOOLEAN = 'BOOLEAN';
const FORMAT_BOOLEAN_AND_EMPTY = 'BOOLEAN_AND_EMPTY';
const FORMAT_RESOURCE_TYPES_VIEW_PROFILE = 'FORMAT_RESOURCE_TYPES_VIEW_PROFILE';
const FORMAT_RESOURCE_TYPES_VIEW_ATTRIBUTES = 'FORMAT_RESOURCE_TYPES_VIEW_ATTRIBUTES';
const FORMAT_NEW_VALUE_HISTORY = 'FORMAT_NEW_VALUE_HISTORY';
export const TYPE_CUSTOM_CONFIGURATION = 'CUSTOM';
const FORMAT_ZONE_SHAPE = 'FORMAT_ZONE_SHAPE';
const FORMAT_ARRAY = 'FORMAT_ARRAY';
const FORMAT_PURGE = 'PURGE';
const FORMAT_CUSTOM_FIRMWARE = 'FORMAT_CUSTOM_FIRMWARE';
export const TYPE_CUSTOM_ATTRIBUTE_FIELD_NAME = 'TYPE_CUSTOM_ATTRIBUTE_FIELD_NAME';
export const TYPE_SECTION = {
    REPORT:'REPORT',
    DETAILS:'DETAILS',
}
const renderObject = (value, nameProperty, customRenderCell = (value) => {
    return value;
}) => {
    const countObjects = value ? Object.keys(value).length : 0;
    const result = value ? Object.keys(value).map(index =>
        value[index][nameProperty] ?
            <span key={index}>{`${value[index][nameProperty] ? customRenderCell(value[index][nameProperty]) : ''}`}{index < countObjects - 1 ? ', ' : ''}</span> : '',
    ) : '';
    return <div>{result}</div>;
};
const renderSecondsFormat = (zoneDwellSeconds) => {
    if (zoneDwellSeconds) {
        const isNegativeValue = zoneDwellSeconds<0;
        const zoneDwell = Math.abs(zoneDwellSeconds);
        const diffDays = Math.floor(zoneDwell / 86400);
        const diffHrs = Math.floor((zoneDwell % 86400) / 3600);
        const diffMins = Math.floor((zoneDwell % 3600) / 60);
        const diffSeconds = Math.floor(zoneDwell % 60);

        const days = `${diffDays}`.padStart(3, '0');
        const hrs = `${diffHrs}`.padStart(2, '0');
        const mins = `${diffMins}`.padStart(2, '0');
        const seconds = `${diffSeconds}`.padStart(2, '0');
        return `${isNegativeValue?'-':''}${days}d ${hrs}h ${mins}m ${seconds}s`;
    } else {
        return '';
    }
};

const renderDwellFormat = (zoneDwell) => {
    
    if (Array.isArray(zoneDwell)) {
        
        let multipleDwell = zoneDwell.map((dwell) => {
            return renderSecondsFormat(dwell);
        });
        return multipleDwell.join(', ');
        
    } else {
        return renderSecondsFormat(zoneDwell);
    }
    
};

export const parseBoolean = (field) => {
    return field ? 'Yes' : 'No';
    //return typeof field === 'boolean' ? field ? 'Yes' : 'No':'';
};
const parseBooleanAndEmpty = (field) => {
    if (field !== undefined && field !== '') {
        return parseBoolean(field);
    } else return '';
};
export const customCellList = {
    [REPORT_ID_RESOURCES]: {
        'associatedTags': FORMAT_TAGS_CONFIGURATION,
        'icon.iconContent': DISPLAY_IMAGE_CONFIGURATION,
    },
    [REPORT_ID_RESOURCE_TYPES]: {
        'filterProfiles': FORMAT_RESOURCE_TYPES_VIEW_PROFILE,
        'customProperties': FORMAT_RESOURCE_TYPES_VIEW_ATTRIBUTES,
        'icon.iconContent': DISPLAY_IMAGE_CONFIGURATION,
    },
    [REPORT_ID_ZONES]: {
        'shape.geometry': FORMAT_ZONE_SHAPE,
    },
};

const getLocalTimestampCell = (value) => {
    return getLocalTimestamp(new Date(value));
};

const renderZoneShape = (geometry) => {
    let polygon = '';
    if (geometry && geometry.coordinates && geometry.coordinates.length > 0) {
        const coordinatesArray = (geometry.coordinates[0] || []).filter(coordinate => (
            Array.isArray(coordinate) &&
            coordinate[0] !== null && coordinate[0] !== undefined && !isNaN(+coordinate[0]) &&
            coordinate[1] !== null && coordinate[1] !== undefined && !isNaN(+coordinate[1])
        ));
        if (coordinatesArray.length) {
            polygon += '' + coordinatesArray.length - 1 + ';';
            for (let index = 0; index < coordinatesArray.length; index++) {
                const coordinate = coordinatesArray[index];
                //polygon = polygon + coordinate[0] + ';' + coordinate[1] + ';';
                polygon = polygon + (+coordinate[0]).toFixed(2) + ';' + (+coordinate[1]).toFixed(2) + ';';
            }
        }
    }
    return polygon;
};

const renderArray = (arrayValue) => {
    if (Array.isArray(arrayValue))
        return arrayValue.join(',');
    else
        return arrayValue;
};

const wrapperTextOverflow = (value) => {
    return <div style={{ width: '100%', height: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>{value}</div>;
};

const renderDisplayFieldPurge = (value) =>{
    /*switch (value) {
        case 'FromLastUpdate': return 'From Last Update';
        case 'FromDissociation': return 'From Dissociation';
        default: return value;
    }*/
    return value;
}
/*const renderFieldNames = (value,typeSection) =>{
    if(Array.isArray(value)){
        return `${(typeSection===TYPE_SECTION.REPORT?value.slice(0,3):value).join(", ")}${typeSection===TYPE_SECTION.REPORT && value.length>3?', ...':''}`;
    }else{
        return typeof value === "boolean"?''+value:value;
    }
}*/
const customCellByConfiguration = (type, headerKey, row, rowIndex, path,typeSection) => {
    if(headerKey && headerKey.includes("customProperties") && !path){
        path="$."+headerKey;
    }
    let value = [];
    try{
        value = Jp.query(row, path);
    }catch (e) {
        console.log({e})
    }

    if (value.length === 1)
        value = value[0];
    else if (value.length === 0)
        value = '';
    switch (type) {
        case TEXT:
            return wrapperTextOverflow(getValueOfPath(row, path));
        case DATE_FORMAT_CONFIGURATION:
            return wrapperTextOverflow(value ? getLocalTimestampCell(value) : '');
        case SECONDS_FORMAT_CONFIGURATION:
            return renderDwellFormat(value);
        case FORMAT_TAGS_CONFIGURATION:
            return renderObject(value, 'tagId');
        case DISPLAY_IMAGE_CONFIGURATION:
            return (
                <div style={{ textAlign: '-webkit-center' }}>
                    <ImageDisplayAsIcon svgColor={row?.icon?.iconColor} svgBase64={value} alignIcon="center" />
                </div>
            );
        case FORMAT_BOOLEAN:
            return parseBoolean(value);
        case FORMAT_BOOLEAN_AND_EMPTY:
            return parseBooleanAndEmpty(value);
        case FORMAT_RESOURCE_TYPES_VIEW_PROFILE:
            return <ResourceTypesViewProfile rowIndex={rowIndex} />;
        case FORMAT_RESOURCE_TYPES_VIEW_ATTRIBUTES:
            return <ResourceTypesViewAttributes rowIndex={rowIndex} />;
        case FORMAT_NEW_VALUE_HISTORY:
            return <NewValue rowIndex={rowIndex} value={value} typeSection={typeSection}/>;
        case FORMAT_ZONE_SHAPE:
            return renderZoneShape(value);
        case FORMAT_ARRAY:
            return renderArray(value);
        case FORMAT_PURGE:
            return renderDisplayFieldPurge(value);
        case FORMAT_CUSTOM_FIRMWARE:
            return <ButtonFirmware rowIndex={rowIndex} />;
        case TYPE_CUSTOM_ATTRIBUTE_FIELD_NAME:
            //return renderFieldNames(value,typeSection);
            return <FieldNameCells rowIndex={rowIndex} value={value} typeSection={typeSection}/>;
        default: {

            if (value && typeof value === 'string') {
                return value;
            } else {
                if (value && typeof type !== 'undefined')
                    return JSON.stringify(value);
                else if (row && row.hasOwnProperty('customProperties') && row.customProperties.hasOwnProperty(headerKey)) {
                    return row.customProperties[headerKey];
                } else {
                    return '';
                }
            }
        }
    }
};

export const customCellByField = (reportId, headerKey, typeColumn, row, rowIndex, path,typeSection=TYPE_SECTION.REPORT) => {
    if (typeColumn === TYPE_CUSTOM_CONFIGURATION && customCellList?.[reportId]?.[headerKey]) {
        return customCellByConfiguration(customCellList[reportId][headerKey], headerKey, row, rowIndex, path,typeSection);
    } else {
        return customCellByConfiguration(typeColumn, headerKey, row, rowIndex, path,typeSection);
    }
};
