import React from 'react';
import PropTypes from 'prop-types';

import WorldMap from './WorldMap';
import LocalMapView from './LocalMapView';

const styles = {
    container:{
        position:'absolute',
        bottom:'0em',
        left:'0em',
        right:'0em',
        top:'0em'
    },
};


/*
██     ██  ██████  ██████  ██      ██████
██     ██ ██    ██ ██   ██ ██      ██   ██
██  █  ██ ██    ██ ██████  ██      ██   ██
██ ███ ██ ██    ██ ██   ██ ██      ██   ██
 ███ ███   ██████  ██   ██ ███████ ██████
*/

class WorldMapView extends React.Component{
    render(){
        return (
            <div className="vss-map">
                <div style={styles.container}>
                    <WorldMap {...this.props}/>
                </div>
            </div>
        );
    }
}



/*
███████ ██     ██ ██ ████████  ██████ ██   ██
██      ██     ██ ██    ██    ██      ██   ██
███████ ██  █  ██ ██    ██    ██      ███████
     ██ ██ ███ ██ ██    ██    ██      ██   ██
███████  ███ ███  ██    ██     ██████ ██   ██
*/


const MAPMODE_WORLD = 0;
const MAPMODE_LOCAL = 1;

class MapView extends React.Component{
    constructor(props){
        super(props);

        if (props.localMapInfo&&props.localMapInfo.site) {
            this.state = {
                mapMode: MAPMODE_LOCAL,
                site: props.localMapInfo.site
            };
            //props.onSelectSite(props.localMapInfo.site);
        }
        else
            this.state = {
                mapMode: MAPMODE_WORLD,
                site: null
            };

        this.handleSiteSelection = this.handleSiteSelection.bind(this);
    }

    static get propTypes(){
        return {
            actions: PropTypes.object,
            onSelectSite: PropTypes.func,
            worldOnly: PropTypes.bool, // not sure if this should be implied (and other component to manage the local map).
            site: PropTypes.object
        };
    }
    static get defaultProps(){
        return {
            worldOnly: false,
            onSelectSite: ()=>{}
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        const {site, localMapInfo, currentLocalMap}=nextProps;

        if(this.props.site !== site){
            this.handleInternalSiteSelection(site);
            if(!!site) {
                let currentSite = currentLocalMap[nextProps.reportId] && currentLocalMap[nextProps.reportId].site;
                if(currentSite !== site) {
                    let mapsSite = nextProps.maps.filter(map => map.siteId === site._id);

                    let map = null;

                    if(mapsSite && mapsSite.length > 0){
                        if(currentLocalMap[nextProps.reportId] && currentLocalMap[nextProps.reportId].localMapId){
                            // Assign the current map allocated in store for the site.
                            map = mapsSite.filter(m => m._id === currentLocalMap[nextProps.reportId].localMapId)[0] || mapsSite[0];
                        } else {
                            // Assign the first map in site.
                            map = mapsSite[0];
                        }
                    }
                    this.props.actions.changeLocalMap(nextProps.reportId, map, site);
                }
            } else {
                this.props.actions.changeLocalMap(nextProps.reportId, null, null);
            }
        }
        // If the site is defined (in redux store).
        else if(localMapInfo&&localMapInfo.localMapId&&localMapInfo.site)
            this.handleInternalSiteSelection(localMapInfo.site);
        // If the report does not have local map view.
        else if(this.props.localMapInfo!==localMapInfo&&(!localMapInfo||!localMapInfo.site))
            this.handleInternalSiteSelection(null);


    }

    handleSiteSelection(site){
        this.handleInternalSiteSelection(site);
        this.props.onSelectSite(site);
    }

    handleInternalSiteSelection(site){
        if(!this.props.worldOnly){
            this.setState({
                mapMode:MAPMODE_LOCAL,
                site
            });
        }

        if(site === null || site === undefined){
            this.setState({
                mapMode:MAPMODE_WORLD
            });
        }
    }

    render(){
        switch(this.state.mapMode){
            case MAPMODE_WORLD:
                return (
                    <WorldMapView {...this.props} onSelectSite={this.handleSiteSelection}/>
                );
            case MAPMODE_LOCAL:
            default:
                return (
                    <LocalMapView {...this.props} onSelectSite={this.handleSiteSelection} site={this.state.site}/>
                );
        }
    }
}


export default MapView;
