import Input from "../../../vss/app/views/util/Input";

export default function GridDimensionsComponent({ gridMatrix, setGridMatrix }) {

  const handleInputTextChange = (event, index) => {
    const unmutedMatrix = [...gridMatrix];
    unmutedMatrix[index] = +event.target.value || 1;
    setGridMatrix(unmutedMatrix);
  }

  const [rows, columns] = gridMatrix;

  return (
    <>
      <span>
        <p style={{ fontSize: 14 }}>
          Select the dimensions for the grid that will take place on the previously drawn area.
       </p>
      </span>
      <div style={{ display: "flex" }}>
        <Input 
          type="number" 
          label="Rows" 
          value={rows} 
          onChange={(e) => handleInputTextChange(e, 0)}
          min={1}
        />
        <Input 
          type="number" 
          label="Columns" 
          value={columns} 
          onChange={(e) => handleInputTextChange(e, 1)}
          min={1}
        />
      </div>
    </>
  )
}