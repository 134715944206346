import React from 'react';
import Autosuggest from 'react-autosuggest';
import {isAllOption} from '../../../util/report/filter/util';
import {OPTION_ALL_TEXT} from '../../../../constants/Misc';

const getSuggestionValue = suggestion => suggestion;

const renderSuggestion = suggestion => {
    return (<span>
        {suggestion}
    </span>);
};

class FilterDropdown extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            inputValue: null,
            values: [],
            suggestions: [],
            focused: false,
            anchorEl: null
        };

        this.renderInputComponent = this.renderInputComponent.bind(this);
        this.renderSuggestionsContainer = this.renderSuggestionsContainer.bind(this);
        this.renderComboBox = this.renderComboBox.bind(this);
        this.onSuggestionClearRequest = this.onSuggestionClearRequest.bind(this);
        this.onSuggestionFetchRequested = this.onSuggestionFetchRequested.bind(this);
        this.onChangeInputValue = this.onChangeInputValue.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
        this.associateAnchor = this.associateAnchor.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        this.setState({
            inputValue:null,
            values:nextProps.values || this.state.values,
            suggestions: nextProps.values.map(m => m.name)
        })
    }

    onSuggestionClearRequest(){}

    onSuggestionFetchRequested(){
        let suggestions = this.state.values.map(m => m.name);
        this.setState({
            suggestions
        });
    }

    onChangeInputValue(event, {newValue}){
        //this.setState({inputValue: newValue})
    }

    onBlur(event){
        if(this.state.inputValue !== null && event && event.target)
            this.changeValue(event.target.value);

        this.setState({
            inputValue: null,
            focused: false
        })
    }

    onKeyDown(event){
        if(event.key === 'enter'){
            this.changeValue(event.target.value);
            this.setState({inputValue:null});
        }
    }

    onFocus(){
        this.setState({
            focused: true
        });
        this.props.onFocus();
    }

    changeValue(newValue){
        const value = (newValue===OPTION_ALL_TEXT)?"":newValue;
        this.props.onChangeValue(value);
    }

    onSuggestionSelected(event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method}){
        if(method === 'enter')
            this.setState({inputValue:null});

        this.changeValue(suggestionValue);
    }

    renderComboBox(){

        const {inputValue, suggestions} = this.state;
        const {value} = this.props;

        const inputProps = {
            value: (inputValue === null) ? String(value):inputValue,
            onChange: this.onChangeInputValue,
            onBlur: this.onBlur,
            onKeyDown: this.onKeyDown,
            onFocus: this.onFocus
        };

        return (<div className={"filter"}>
            <Autosuggest
                focusInputOnSuggestionClick={false}
                suggestions={suggestions}
                shouldRenderSuggestions={()=>true}
                renderInputComponent={this.renderInputComponent}
                renderSuggestionsContainer={this.renderSuggestionsContainer}
                onSuggestionsFetchRequested={this.onSuggestionFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionClearRequest}
                renderSuggestion={renderSuggestion}
                getSuggestionValue={getSuggestionValue}
                onSuggestionSelected={this.onSuggestionSelected}
                inputProps={inputProps}
                renderSectionTitle={()=>{}}
                getSectionSuggestions={()=>{}}
            />
        </div>);
    }

    renderInputComponent(inputProps){
        const value = (isAllOption(inputProps.value)) ? OPTION_ALL_TEXT : inputProps.value;
        return (
            <div ref={this.associateAnchor} style={{display:'flex', width: this.props.isMobile ? '100%': '98%'}}>
                <input className={"react-autosuggest__input"} {...inputProps} value={value} disabled={this.props.disabledOption} style={{background: this.props.disabledOption ? "#D9DEE0": ""}} />
            </div>
        );
    }

    associateAnchor(ref){
        this.setState({
            anchorEl: ref
        });
    }

    renderSuggestionsContainer({containerProps, children}){
        //const {anchorEl} = this.state;
        //const width = anchorEl && anchorEl.offsetWidth;
        return (
            <div {...containerProps} >
                        {children}
            </div>
        );
    }

    render(){
        return (<div className={"filter-container"}>
            {this.renderComboBox()}
        </div>);
    }
}

export default FilterDropdown;