import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const ViewsIcon = (props) => (
    <SvgIcon {...props}>
        <g transform="scale(0.2),translate(0,5)">
       <path d="M
                   10 15
                a
                   5,5 0 0,1 5,-5
                l
                   35 0
                a
                   20,20 0 0,0 0,20
                l
                   -40 40
                z" />

    <path d="M
                10 75
             l
                20 -20
                30 35
                -45 0
             a
                5,5 0 0,1 -5,-5
             z" />
            <path d="
            M
                        33 52
                     l
                        18.5 -18.5
                        18.5 33.5
                        20 -36.2
                     v
                        54.2
                     a
                        5,5 0 0,1 -5,5
                     l
                        -19 0
                     z

            " />


           <path fillRule="evenodd" d="
           M
                       54.5 30.5
                    l
                       15.5 28.5
                        15.5 -28.5
                    a
                       20,20 0 1,0 -31,0
                    z

                                   M  60,20
                                   a
                                      10,10 0 1,1 20,0
                                  a
                                     10,10 0 1,1 -20,0
                                   z

           " />
         </g>
    </SvgIcon>
);

export default ViewsIcon;
