export const EMPTY_OBJECT = {};
export const EMPTY_ARRAY = [];
export * from './Forms';
export * from './Filters';
export * from './Reports';
export * from './Tags';
export * from './Search';
export * from './Services';


export const globalSettings = { origin: '' };
