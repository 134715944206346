import * as types from '../constants/ActionTypes';

import {displayErrorDialog} from './general';

import {displayErrorFromAxios, getToken, ICService} from './util';

import {ICS_HOST} from '../constants/Misc';

export const loadPackages = (data,applianceId) => ({type:types.LOAD_PACKAGES, data, applianceId});

export const requestPackages = (applianceId) => {
    return (dispatch) => {
        // Actually not convinced we need to dispatch an event... UI doesn't really do much with it.
        dispatch({type:types.REQUEST_TASKS});

        ICService.instance().get("/debugpkgs",{
            params:{
                applianceId
            },
            headers:{
                'Content-Type':'application/json'
            }
        })
        .then(response => {
            dispatch(loadPackages(response.data,applianceId));
        })
        .catch(displayErrorFromAxios.bind(null,dispatch));
    };
};

export const downloadPackage = (packageId) => {
    return (dispatch) => {
        // Actually not convinced we need to dispatch an event... UI doesn't really do much with it.
        dispatch({type:types.REQUEST_TASKS});

        let file = `${ICS_HOST}debugpkgs/${packageId}/file?jwt=${getToken()}`;

        try{
            window.location = file;
        }catch(e){
            dispatch(displayErrorDialog({
                message:e
            }));
        }
    };
};

export const deletePackage = (packageId,callback) => {
    return (dispatch) => {
        //dispatch({type:types...})

        ICService.instance().delete("/debugpkgs/" + packageId)
        .then(callback)
        .catch(displayErrorFromAxios.bind(null,dispatch));
    };
};
