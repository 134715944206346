import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

class ZToggle extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            active:props.on || false
        };
        this.handleTouchTap = this.handleTouchTap.bind(this);
    }

    static get propTypes(){
        return {
            on: PropTypes.bool,
            onText: PropTypes.string,
            offText: PropTypes.string,
            onStyle: PropTypes.object,
            offStyle: PropTypes.object,
            className: PropTypes.string,
            onChange: PropTypes.func
        };
    }

    static get defaultProps(){
        return {
            onChange: ()=>{}
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps){

        // Only if it changed ( we want to be passive otherwise )
        if(nextProps.on !== this.props.on){
            this.setState({
                active: nextProps.on
            });
        }

    }

    handleTouchTap(){
        var _active = !this.state.active;
        this.setState({
            active: _active
        });
        this.props.onChange(_active);
    }

    render(){

        const {active} = this.state;
        const {
            onText,
            offText,
            onStyle,
            offStyle,
            on,     // eslint-disable-line no-unused-vars
            ...other
        } = this.props;

        let classNames = cn("zebra-toggle",this.props.className,{
            "on":(active)
        });


        return (
            <div {...other} className={classNames} onClick={this.handleTouchTap} >
                <div className="on-label" style={onStyle}>
                    <div className="inner-label">{onText}</div>
                </div>
                <div className="off-label" style={offStyle}>
                    <div className="inner-label">{offText}</div>
                </div>
                <div className="indicator">
                </div>
            </div>
        );
    }
}

export default ZToggle;
