

export const RESOURCE_TYPES_COLUMNS = {
    "name": {key:"name",value:"Name",width:155,clickable:false,sortable:true,filters:false,enable:true,order:1},
    "displayName": {key:"displayName",value:"Display Name",width:160,clickable:false,sortable:true,filters:false,enable:true,order:2},
    "description": {key:"description",value:"Description",width:160,clickable:false,sortable:true,filters:false,enable:false,order:3},
    "dataType": {key:"dataType",value:"Data Type",width:110,clickable:false,sortable:true,filters:false,enable:false,order:4},
    "required": {key:"required",value:"Required",width:150,clickable:false,sortable:true,filters:false,enable:false,order:5},
    "parentTypeId": {key:"required",value:"Required",width:150,clickable:false,sortable:true,filters:false,enable:false,order:7},
    "buttonProcess": {key:"required",value:"Required",width:150,clickable:false,sortable:true,filters:false,enable:false,order:8},
    "suspiciousBlink": {key:"required",value:"Required",width:150,clickable:false,sortable:true,filters:false,enable:false,order:9},
    "noBlinksAlert": {key:"required",value:"Required",width:150,clickable:false,sortable:true,filters:false,enable:false,order:10},
    "callType": {key:"required",value:"Required",width:150,clickable:false,sortable:true,filters:false,enable:false,order:11},
    "autoDissociation": {key:"required",value:"Required",width:150,clickable:false,sortable:true,filters:false,enable:false,order:12},
    "updateMode": {key:"required",value:"Required",width:150,clickable:false,sortable:true,filters:false,enable:false,order:13},
    "properties": {key:"required",value:"Required",width:100,clickable:false,sortable:true,filters:false,enable:false,order:14},
};

export const RESOURCE_TYPES_TABLE = "RESOURCE_TYPES_TABLE";

export const TYPE_FIELD_TEXT = 'text';
export const TYPE_FIELD_MULTI_TEXT = 'multiText';
export const TYPE_FIELD_GROUP_PURGE = 'TYPE_FIELD_GROUP_PURGE';
export const TYPE_FIELD_COMBOBOX = 'combobox';
export const TYPE_FIELD_RADIO_GROUP = 'TYPE_FIELD_RADIO_GROUP';
export const TYPE_FIELD_CHECK_BOX = 'TYPE_FIELD_CHECK_BOX';
export const TYPE_FIELD_LOOK_UP_VALUE = 'loopUpValue';
export const TYPE_FIELD_LIST = 'TYPE_FIELD_LIST';
export const TYPE_FIELD_TEXT_ICON = 'TYPE_FIELD_TEXT_ICON';
export const TYPE_FIELD_COLOR_PICKER = 'TYPE_FIELD_COLOR_PICKER';
export const TYPE_FIELD_INPUT_FILE = 'TYPE_FIELD_INPUT_FILE';
export const MODE_DIALOG_RESOURCE_TYPE_ADD = "MODE_DIALOG_RESOURCE_TYPE_ADD";
export const MODE_DIALOG_RESOURCE_TYPE_EDIT = "MODE_DIALOG_RESOURCE_TYPE_EDIT";
export const MODE_DIALOG_RESOURCE = "MODE_DIALOG_RESOURCE";
export const MODE_DIALOG_RESOURCE_TYPE = "MODE_DIALOG_RESOURCE_TYPE";
export const MODE_DIALOG_ICON_RESOURCE_TYPE = "MODE_DIALOG_ICON_RESOURCE_TYPE";
export const CLEAN_DIALOG_ICON_RESOURCE_TYPE = "CLEAN_DIALOG_ICON_RESOURCE_TYPE";
export const UPDATE_RESOURCE_FIELD = "UPDATE_RESOURCE_FIELD";
export const UPDATE_RESOURCE_URL_PARAMS = "UPDATE_RESOURCE_URL_PARAMS";
export const ALLOWED_FILE_TYPES_IN_ICON = 'image/jpeg, image/png, image/svg+xml';
export const WARNING_MESSAGE_FIELD_NAMES = "This change may result existing data being re-indexed, which could take some time depend on the amount of resources associated with this resource type. Do you want to continue?";