import { createSelector } from 'reselect';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import {EMPTY_ARRAY, EMPTY_OBJECT} from '../constants';
import {openDialogZoneGrid, patchReplicateZonesData, patchZonesOnStore, selectZones} from '../actions';

// const selectZoneBuilderState = createSelector(
//     (state) => state.zoneBuilder,
//     (zoneBuilder) => {
//         return zoneBuilder;
//     },
// );

// export const useZoneBuilderState = () => {
//     const { selectedZoneIds, zones, replicateZonesData = EMPTY_OBJECT, interactions } = useSelector(state => selectZoneBuilderState(state), shallowEqual);
//     const selectedZones = useMemo(() => selectedZoneIds.map(id => zones[id]).filter(zone => !!zone), [selectedZoneIds, zones]);
//     return { selectedZoneIds, zones, selectedZones, replicateZonesData, interactions };
// };

export const useZoneBuilderState = () => {
    return useSelector(state => zoneBuilderState(state), shallowEqual);
};
const zoneBuilderState = createSelector(
    (state) => state.zoneBuilder,
    (properties) => {
        const {
            zones = EMPTY_OBJECT,
            zoneForm = EMPTY_OBJECT,
            zoneTypes = EMPTY_ARRAY,
            openZoneDetailsDialog = false,
            formType = null,
            selectedZoneIds = [],
            grid = EMPTY_OBJECT,
            selectedGridIds = EMPTY_ARRAY,
            openDeleteChildrenZones = false,
            zoneSelectedId = null,
            hasChildren = false,
        } = properties || {};

        return {
            zones,
            zoneForm,
            zoneTypes,
            openZoneDetailsDialog,
            formType,
            selectedZoneIds,
            grid,
            selectedGridIds,
            openDeleteChildrenZones,
            zoneSelectedId,
            hasChildren,
        };
    },
);


export const useZoneBuilderDispatch = () => {
    const dispatch = useDispatch();
    return {
        patchReplicateZonesData: useCallback(data => dispatch(patchReplicateZonesData(data)), [dispatch]),
        selectZones: useCallback((zoneIds) => dispatch(selectZones(zoneIds)), [ dispatch ]),
        patchZonesOnStore: useCallback((patchedZones) => dispatch(patchZonesOnStore(patchedZones)), [ dispatch ]),
        openDialogZoneGrid: useCallback((newState) => dispatch(openDialogZoneGrid(newState)), [ dispatch ]),
    };
};