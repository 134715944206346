import {
    ALLOWED_REGEX,
    ANTENNAS_ARRAY_VALIDATION,
    ANTENNAS_CUSTOM_VALIDATION,
    ANTENNAS_PORT_CUSTOM_VALIDATION,
    ARRAY_VALIDATION, CONFIGURATION_XML_FORMAT,
    CUSTOM_RANGE,
    CUSTOM_VALIDATION,
    GPIOLED_CONFIGURATION_REQUIRED,
    MATCH,
    NO_ALLOWED_REGEX,
    RANGE_NUMBER, RANGE_NUMBER_PATH_VALIDATION,
    REQUIRED,
    XML_FORMAT,
    FORM_FIELD_REQUIRED,
    DUPLICATE_FIELD_VALIDATION,
    DUPLICATE_FIELD_VALIDATION_CASE_SENSITIVE
} from '../../constants';

import {
    antennasCustomValidation,
    arrayValidateErrors,
    getCustomRangeValidation,
    gpioledRequiredValidation, rangeValidations,
    removeArrayValidations, validateFieldRequired, validateUniqueNameFiled, xmlConfigurationValidation
} from './deviceValidation';
// import {ValidationType} from "../../constants/SiteManager";
// import {validateFieldsPath} from "./siteManagerValidations";
import {getSafeValueOfPath} from "../../utils";

const getCustomValidation = (fieldValue) => {
    const { field } = fieldValue;
    if (field === 'mode.antennaStopCondition.type') {
        const { type, value, port, signal } = fieldValue;
        if ((type === '' || type == null) && ((!isNaN(value) && value !== '' && value != null) || (!isNaN(port) && port !== '' && port != null) || (signal !== '' && signal != null))) {
            return true;
        }
    } else if (field === 'mode.antennaStopCondition.value') {
        const { type, value } = fieldValue;
        if ((type != null && type !== '' && type !== 'GPI') && (isNaN(value) || value === '' || value == null)) {
            return true;
        }
    } else if (field === 'mode.antennaStopCondition.value.port') {
        const { type, port } = fieldValue;
        if ((type != null && type === 'GPI') && (isNaN(port) || port === '' || port == null)) {
            return true;
        }
    } else if (field === 'mode.antennaStopCondition.value.signal') {
        const { type, signal } = fieldValue;
        if ((type != null && type === 'GPI') && (signal === '' || signal == null)) {
            return true;
        }
    }

    return false;
};

const customRangeValidation = (fieldValue) => {
    const { field } = fieldValue;
    if (field === 'mode.antennaStopCondition.value.port') {
        const { port } = fieldValue;
        if (port !== '' && port != null && (port < 1 || port > 2)) {
            return true;
        }
    }
};

const arrayValidation = (value) => {
    if(value.field === 'mode.transmitPower' || value.field === 'mode.linkProfile') {
        let checkArrayElements = arrayElementsAreNullOrEmpty(value.arrayValue);
        if (value.arrayValue?.length > 0 && !checkArrayElements && !value.arrayValue[value.index]) {
            return true;
        }
    } else if(value.field === 'mode.antennaStopCondition' || value.field === 'mode.query'){
        const arrayElements = value.arrayAntennas?.map((item, index) => value.arrayValue?.[index] || null);
        let test = false;
        if(arrayElements) {
            const checkArrayElements = arrayElements[value.index] && elementsAreEmpty(arrayElements[value.index]);
            test = (!arrayElements[value.index] || Object.keys(arrayElements[value.index]).length === 0 || checkArrayElements);
        }
        const checkArrayObject = arrayElements && !allAreNullOrEmptyObject(arrayElements);
        if ( checkArrayObject && test ) {
            return true;
        }
    }

    return false;
}

const allAreNullOrEmptyObject = (arr) => {
    return arr && arr.every(element => element == null || Object.keys(element).length === 0 || elementsAreEmpty(element)) ;
}

const arrayElementsAreNullOrEmpty = (arr) => {
    return arr && arr.every(element => element == null || element === '') ;
}

const elementsAreEmpty = (object) => {
    return Object.values(object).every(item => {
        const elem = typeof item === 'number' && isNaN(item) ? null : item;
        return elem == null || elem === ''
    })
}

export const getErrorMessageForArrayFields = async (configurationForm, formFieldError, itemType, formKey, validateFields, mainPath) => {
    let formFieldErrorsArray = {...formFieldError};
    const validateFieldsObj = validateFields[itemType];
    const arrayValue = getSafeValueOfPath(configurationForm, mainPath).value;
    for (let key in validateFieldsObj){
        for(let index in arrayValue){
            if(Number(index) === arrayValue.length-1){
                break;
            }
            const path = validateFieldsObj[key].path.replace('index', index);
            const valuePath = getSafeValueOfPath(configurationForm, path).value;
            for(const validation of validateFieldsObj[key].validations){
                const extraData = {configurationForm, formFieldErrorsArray};
                let validationObj = {...validation};
                const pathValidation = validationObj.path.replace('index', index);
                validationObj.path = pathValidation;
                await getErrorMessageOfValidation({
                    validate: validationObj,
                    value: valuePath,
                    defaultValue: '',
                    extraData
                })
                if (extraData.result) {
                    formFieldErrorsArray = extraData.result;
                }
            }
        }
    }
    return formFieldErrorsArray;
}

export const getErrorMessageOfValidation = ({validate, value, defaultValue, extraData = {}}) => {
    switch (validate.type) {
        case REQUIRED:
            if (JSON.stringify(value) === JSON.stringify(defaultValue) || value == null) {
                return validate.errorMessage;
            }
            break;
        case ALLOWED_REGEX:
            if (!new RegExp(validate.regex).test(value)) {
                return validate.errorMessage;
            }
            break;
        case NO_ALLOWED_REGEX:
            if (new RegExp(validate.regex).test(value)) {
                return validate.errorMessage;
            }
            break;
        case RANGE_NUMBER:
            if (value != null && !isNaN(value) && (typeof value) === 'number') {
                if (!((validate.range.hasOwnProperty('min') ? parseInt(value) >= validate.range.min : true))) {
                    return validate.range.errorMessageMinValue;
                }
                if (!((validate.range.hasOwnProperty('max') ? parseInt(value) <= validate.range.max : true))) {
                    return validate.range.errorMessageMaxValue;
                }
            }
            break;
        case XML_FORMAT:
            let domParser = new DOMParser();
            let dom = domParser.parseFromString(value, 'text/xml');
            if (value && value.trim() !== '' && dom.getElementsByTagName('parsererror').length > 0) {
                return validate.errorMessage;
            }
            break;
        case CUSTOM_VALIDATION:
            let error = getCustomValidation(value);
            if (error) {
                return validate.errorMessage;
            }
            break;
        case CUSTOM_RANGE:
            let errorRange = customRangeValidation(value);
            if (errorRange) {
                return validate.range.errorMessage;
            }
            break;
        case MATCH:
            if (validate.fieldValue !== value) {
                return validate.errorMessage;
            }
            break;
        case ARRAY_VALIDATION:
            if(value.arrayValidation) {
                if(arrayValidation(value)){
                    return  validate.errorMessage
                }
            }
            break;
        case ANTENNAS_ARRAY_VALIDATION:
            if (value && Array.isArray(value)) {
                const arrayErrors = arrayValidateErrors(value, extraData.antennasValues, extraData.validateFields[extraData.key].path, validate.errorMessage, extraData.formFieldErrorsArray, validate.field);
                extraData.result = {...arrayErrors}
            } else if(!value){
                const arrayErrors = removeArrayValidations(extraData.formFieldErrorsArray, extraData.validateFields[extraData.key].path);
                extraData.result = {...arrayErrors}
            }
            break;
        case ANTENNAS_CUSTOM_VALIDATION:
            const customErrors = antennasCustomValidation(extraData.antennasValues, validate, extraData.configurationForm, extraData.formFieldErrorsArray);
            extraData.result = {...customErrors}
            break;
        case ANTENNAS_PORT_CUSTOM_VALIDATION:
            const portErrors = getCustomRangeValidation(extraData.antennasValues, validate, extraData.configurationForm, extraData.formFieldErrorsArray);
            extraData.result = {...portErrors};
            break;
        case GPIOLED_CONFIGURATION_REQUIRED:
            const gpioledErrors = gpioledRequiredValidation(validate, extraData.configurationForm, extraData.formFieldErrorsArray);
            extraData.result = {...gpioledErrors};
            break;
        case RANGE_NUMBER_PATH_VALIDATION:
            const rangeErrors = rangeValidations(extraData.formFieldErrorsArray, validate, value);
            extraData.result = {...rangeErrors};
            break;
        case CONFIGURATION_XML_FORMAT:
            const xmlErrors = xmlConfigurationValidation(extraData.formFieldErrorsArray, validate, value);
            extraData.result = {...xmlErrors};
            break;
        case FORM_FIELD_REQUIRED:
            const requiredErrors = validateFieldRequired(extraData.formFieldErrorsArray, validate, value);
            extraData.result = {...requiredErrors};
            break;
        case DUPLICATE_FIELD_VALIDATION:
            extraData.result = { ...validateUniqueNameFiled(extraData.formFieldErrorsArray, validate, value, extraData.specificValuesValidation, false) };
            break;
        case DUPLICATE_FIELD_VALIDATION_CASE_SENSITIVE:
            extraData.result = { ...validateUniqueNameFiled(extraData.formFieldErrorsArray, validate, value, extraData.specificValuesValidation, true) };
            break;
        default:
            break;
    }
    return '';
}
