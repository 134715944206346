import {
    AlertService,
    DirectionService,
    FileService,
    ICService,
    LCSMapService,
    ReportService,
    ResourceService
} from '../actions/util';
import { getFilterWithoutWildcard } from '../actions';
import {
    getCustomFieldsAndResourceType,
    getUrlFromObject,
    parseBoolean,
    parseBooleanResourceType,
    parseDwell,
    parseFilter,
    parseResourceTypeKey,
    processFiltersValues,
    transformDateToISOformat
} from '../utils';
import { getValidTreePathPayload } from '../views/util/report/filter/utils/treeUtils';
import {
    REPORT_ID_ALERT_NOTIFICATION_CONFIGURATION,
    REPORT_ID_CONTACTS,
    REPORT_ID_HARDWARE_DEVICES,
    REPORT_ID_HISTORICAL_REPORTS,
    REPORT_ID_RESOURCE_TYPES,
    REPORT_ID_RESOURCES,
    REPORT_ID_SYSTEM_ALERT_DATA,
    REPORT_ID_SYSTEM_ALERT_HISTORY,
    REPORT_ID_SYSTEM_ALERT_SETTINGS,
    REPORT_ID_TAGS,
    REPORT_ID_WHEREPORTS,
    REPORT_ID_ZONE_GROUPS_CONFIGURATION,
    REPORT_ID_ZONES,
    REPORT_ID_ZONE_EXPIRATION,
    REPORT_ID_DIRECTION_CONFIGURATION,
    REPORT_ID_PATH_CONFIGURATION,
    REPORT_ID_FIRMWARES,
    REPORT_ID_RESOURCE_GROUPING,
    REPORT_ID_TAG_BLINK_HISTORY,
    REPORT_ID_CALL_HISTORY,
    REPORT_ID_RESOURCE_EVENT_HISTORY,
} from './Reports';
import { getTimes, PERIOD } from '../views/util/report/filter/util';
import {booleanToYesNo} from "../utils/tableColumns";

export const ENDPOINTS = (() => {
    return {
        [REPORT_ID_HARDWARE_DEVICES]: {
            GET_ALL: {
                service: ICService,
                method: 'GET',
                urlTemplate: '/devices/list?sort={sort}&page={page}&size={size}&{filters}',
                getResultData: (result) => result.data,
            },
        },
        [REPORT_ID_WHEREPORTS]: {
            GET_ALL: {
                service: ICService,
                method: 'GET',
                urlTemplate: '/devices/list?deviceType=wherePorts&sort={sort}&page={page}&size={size}&{filters}',
                getResultData: (result) => result.data,
            },
        },
        [REPORT_ID_RESOURCE_GROUPING]: {
            GET_ALL: {
                service: ResourceService,
                method: 'GET',
                urlTemplate: '/v2/resources/groups?{filters}&page={page}&size={size}&sort={sort}',
                getResultData: (result) => result.data.content,
            },
        },
        [REPORT_ID_RESOURCES]: {
            GET_ALL: {
                service: ResourceService,
                method: 'GET',
                urlTemplate: '/v2/resources?{filters}&page={page}&size={size}&sort={sort}',
                getResultData: (result) => result.data.content,
                getFiltersQueryParam: (reportFilters) => {
                    const {
                        resourceIds, resourceType, tagSource, IsChild, IsParent, tagIds,
                        zoneDwellMin, zoneDwellMax, zoneGroupDwellMin, zoneGroupDwellMax,blinking,batteryLow,locating,assistingDeviceIds, tree,time
                    } = processFiltersValues(reportFilters);
                
                    const { children: resourceFilters } = reportFilters.resourceType || {};
                
                    const resourceAttributesFilters = resourceFilters ? Object.keys(resourceFilters).reduce((acc, at) => {
                        const valueFilter = resourceFilters[at].value;
                        return (valueFilter||typeof valueFilter==='boolean'||typeof valueFilter==='number') && valueFilter !== '%' && valueFilter !== 'All' ? [...acc, `${at}:${valueFilter}`] : acc;
                    }, []) : [];
                    const zonesParameter =  getValidTreePathPayload(tree);
                    const filtersObject = {
                        resourceIds: resourceIds ? (resourceIds === '%' ? '' : getFilterWithoutWildcard(resourceIds)) : '',
                        type: resourceType && resourceType.length>0?resourceType[0].value:undefined,
                        customProps: resourceAttributesFilters ? resourceAttributesFilters.toString() : '',
                        tagSource,
                        parent: parseBoolean(IsParent),
                        child: parseBoolean(IsChild),
                        tagIds: tagIds ? getFilterWithoutWildcard(tagIds.toString()) : '',
                        zoneDwellMin: parseDwell(zoneDwellMin),
                        zoneDwellMax: parseDwell(zoneDwellMax),
                        zoneGroupDwellMin: parseDwell(zoneGroupDwellMin),
                        zoneGroupDwellMax: parseDwell(zoneGroupDwellMax),
                        zones: zonesParameter,
                        time:time?time:undefined,
                        blinking: parseBoolean(blinking),
                        locating: parseBoolean(locating),
                        batteryLow: parseBoolean(batteryLow),
                        assistingDeviceIds: assistingDeviceIds,
                    };
                    return getUrlFromObject(filtersObject);
                },
            },
        },
        [REPORT_ID_RESOURCE_TYPES]: {
            GET_ALL: {
                service: ResourceService,
                method: 'GET',
                urlTemplate: '/v2/resourcetypes?{filters}&page={page}&size={size}&sort={sort}',
                getResultData: (result) => result.data.content,
                getFiltersQueryParam: (reportFilters) => {
                    const { category, parent } = processFiltersValues(reportFilters);
                    return getUrlFromObject({
                        category: category,
                        [parseResourceTypeKey('parent', parent)]: parseFilter(parseBooleanResourceType(parent))
                    });
                },
            },
        },
        [REPORT_ID_TAGS]: {
            GET_ALL: {
                service: ResourceService,
                method: 'GET',
                urlTemplate: '/v2/tags?{filters}&page={page}&size={size}&sort={sort}',
                getResultData: (result) => result.data.content,
                getFiltersQueryParam: (reportFilters) => {
                    const {
                        batteryLow,
                        blinking,
                        locating,
                        //reader,
                        registered,
                        //resourceIds,
                        resourceType,
                        //sources,
                        tagIds,
                        tree,
                        ...other
                    } = processFiltersValues(reportFilters);
                    const zones = getValidTreePathPayload(tree);
                
                    const filtersObject = {
                        tagIds: getFilterWithoutWildcard(tagIds),
                        registered: parseBoolean(registered),
                        resourceTypes: resourceType && resourceType.length>0?resourceType[0].value:undefined,
                        //resourceIds,
                        batteryLow: parseBoolean(batteryLow),
                        //sources,
                        blinking: parseBoolean(blinking),
                        //reader,
                        locating: parseBoolean(locating),
                        zones,
                        ...other
                    };
                    return getUrlFromObject(filtersObject);
                },
            },
        },
        [REPORT_ID_SYSTEM_ALERT_DATA]: {
            GET_ALL: {
                service: AlertService,
                method: 'GET',
                urlTemplate: '/data?{filters}&page={page}&size={size}&sort={sort}',
                getResultData: (result) => result.data.content,
                getFiltersQueryParam: (reportFilters) => {
                    const filters = processFiltersValues(reportFilters);
                    // SHOW ONLY OPEN ALERTS
                    const defaultFilter = { state: 'open' };
                    return getUrlFromObject({ ...filters, ...defaultFilter });
                },
            },
        },
        [REPORT_ID_SYSTEM_ALERT_HISTORY]: {
            GET_ALL: {
                service: AlertService,
                method: 'GET',
                urlTemplate: '/history?{filters}&page={page}&size={size}&sort={sort}',
                getResultData: (result) => result.data.content,
                getFiltersQueryParam: (reportFilters) => {
                    const filters = processFiltersValues(reportFilters);
                    const today = getTimes(PERIOD.TODAY);
                    filters.from = filters.from ? transformDateToISOformat(filters.from) : transformDateToISOformat(today.from);
                    filters.to = filters.to ? transformDateToISOformat(filters.to) : transformDateToISOformat(today.to);
                    return getUrlFromObject(filters);
                },
            },
        },
        [REPORT_ID_SYSTEM_ALERT_SETTINGS]: {
            GET_ALL: {
                service: AlertService,
                method: 'GET',
                urlTemplate: '/configurations?{filters}&page={page}&size={size}&sort={sort}',
                getResultData: (result) => {
                    let data = result.data.content;
                    data = data.map(alert => {
                        alert.enabled = alert.enabled.toLowerCase() === 'true' ? 'Yes' : 'No';
                        alert.autoClose = alert.autoClose.toLowerCase() === 'true' ? 'Yes' : 'No';
                        alert.recipients = alert.notifications[0]?.recipients.map(recipient => recipient.addrList[0].address).join(', ');
                        return alert;
                    });
                    return data;
                },
            },
        },
        [REPORT_ID_CONTACTS]: {
            GET_ALL: {
                service: AlertService,
                method: 'GET',
                urlTemplate: '/contacts?{filters}&page={page}&size={size}&sort={sort}',
                getResultData: (result) => {
                    let data = result.data.content;
                    data = data.map(contact => {
                        const addressMethods = contact.addresses.map(address => address.method);
                        const addresses = contact.addresses.map(address => address.address);
                        const addressNames = contact.addresses.map(address => address.name);
                        return { name: contact.name, addressMethod: addressMethods.join(','), address: addresses.join(','),addressName: addressNames.join(','), phone: contact.phone, description: contact.description };
                    });
                    return data;
                },
            },
        },
        [REPORT_ID_ZONE_EXPIRATION]: {
            GET_ALL: {
                service: DirectionService,
                method: 'GET',
                urlTemplate: '/zoneexp?{filters}&page={page}&size={size}&sort={sort}',
                getResultData: (result) => {
                    let data = result.data;
                    data = data.map(zoneExpiration => {
                        zoneExpiration.enabled = zoneExpiration.enabled ? 'Yes' : 'No';
                        return zoneExpiration;
                    });
                    return data;
                }
            }
        },
        [REPORT_ID_DIRECTION_CONFIGURATION]: {
            GET_ALL: {
                service: DirectionService,
                method: 'GET',
                urlTemplate: '/directions?{filters}&page={page}&size={size}&sort={sort}',
                getResultData: (result) => {
                    return result.data;
                }
            }
        },
        [REPORT_ID_PATH_CONFIGURATION]: {
            GET_ALL: {
                service: DirectionService,
                method: 'GET',
                urlTemplate: '/directions?{filters}&page={page}&size={size}&sort={sort}',
                getResultData: (result) => {
                    return result.data;
                }
            }
        },
        [REPORT_ID_ZONES]: {
            GET_ALL: {
                service: LCSMapService,
                method: 'GET',
                urlTemplate: '/zones?{filters}&page={page}&size={size}&sort={sort}',
                getResultData: (result) => result.data,
            },
        },
        [REPORT_ID_ZONE_GROUPS_CONFIGURATION]: {
            GET_ALL: {
                service: LCSMapService,
                method: 'GET',
                urlTemplate: '/zonegroups/distinct?{filters}&page={page}&size={size}&sort={sort}', //TODO: Check the sizes {filters}page={page}&size={size}&sort={sort}',
                getResultData: (result) => result.data,
            },
        },
        [REPORT_ID_ALERT_NOTIFICATION_CONFIGURATION]: {
            GET_ALL: {
                service: AlertService,
                method: 'GET',
                urlTemplate: '/notificationConfigurations?{filters}&page={page}&size={size}&sort={sort}',
                getResultData: (result) => {
                    let data = result.data.content;
                    data = data.map(notificationConfigurations => {
                        //const recipients = notificationConfigurations.recipients.map(recipient => recipient.name).join(', ');
                        notificationConfigurations.emails = notificationConfigurations.recipients.map(recipient => recipient.addrList?.map(addr => addr.address)).join(', '); //two level join email
                        notificationConfigurations.siteName = notificationConfigurations.siteName || 'All';           // should be all if empty
                        notificationConfigurations.recurring = booleanToYesNo(notificationConfigurations.recurring); // special case True, False in string
                        return notificationConfigurations;
                    });
                    return data;
                },
            },
        },
        [REPORT_ID_HISTORICAL_REPORTS]: {
            GET_ALL: {
                service: ReportService,
                method: 'GET',
                urlTemplate: ({ runReportId }) => `/run-report/${runReportId}?sort={sort}&limit={size}&{filters}`,
                getResultData: (result) => result.data.results,
            },
        },
        [REPORT_ID_FIRMWARES]: {
            GET_ALL: {
                service: FileService,
                method: 'GET',
                urlTemplate: () => `/files?fileType=firmware&pageNumber={page}&pageSize={size}`,
                getResultData: (result) => result.data.results,
            },
        },
        [REPORT_ID_TAG_BLINK_HISTORY]: {
            GET_ALL: {
                service: ReportService,
                method: 'GET',
                urlTemplate: ({ runReportId }) => `/run-report/${runReportId}?sort={sort}&limit={size}&{filters}`,
                getResultData: (result) => result.data.results,
                getFiltersQueryParam: (reportFilters) => {
                    const {
                        ...filtersAll
                    } = processFiltersValues(reportFilters);
                    const {attributes} = reportFilters||{};
                    const {resourceTypeParent} = attributes;
                    const {resourceAttributesFilters,valueResourceType} = getCustomFieldsAndResourceType(reportFilters,filtersAll,resourceTypeParent);
                    const filtersObject = {
                        customProps: resourceAttributesFilters ? resourceAttributesFilters.toString() : '',
                        ...filtersAll,
                        [resourceTypeParent]: valueResourceType
                    };
                    return getUrlFromObject(filtersObject);
                }
            },
        },
        [REPORT_ID_CALL_HISTORY]: {
            GET_ALL: {
                service: ReportService,
                method: 'GET',
                urlTemplate: ({ runReportId }) => `/run-report/${runReportId}?sort={sort}&limit={size}&{filters}`,
                getResultData: (result) => result.data.results,
                getFiltersQueryParam: (reportFilters) => {
                    const {
                        ...filtersAll
                    } = processFiltersValues(reportFilters);
                    const {attributes} = reportFilters||{};
                    const {resourceTypeParent} = attributes;
                    const {resourceAttributesFilters,valueResourceType} = getCustomFieldsAndResourceType(reportFilters,filtersAll,resourceTypeParent);
                    const filtersObject = {
                        customProps: resourceAttributesFilters ? resourceAttributesFilters.toString() : '',
                        ...filtersAll,
                        [resourceTypeParent]: valueResourceType
                    };
                    return getUrlFromObject(filtersObject);
                }
            },
        },
        [REPORT_ID_RESOURCE_EVENT_HISTORY]: {
            GET_ALL: {
                service: ReportService,
                method: 'GET',
                urlTemplate: ({ runReportId }) => `/run-report/${runReportId}?sort={sort}&limit={size}&{filters}`,
                getResultData: (result) => result.data.results,
                getFiltersQueryParam: (reportFilters) => {
                    const {
                        ...filtersAll
                    } = processFiltersValues(reportFilters);
                    const {attributes} = reportFilters||{};
                    const {resourceTypeParent} = attributes;
                    const {resourceAttributesFilters,valueResourceType} = getCustomFieldsAndResourceType(reportFilters,filtersAll,resourceTypeParent);
                    const filtersObject = {
                        customProps: resourceAttributesFilters ? resourceAttributesFilters.toString() : '',
                        ...filtersAll,
                        [resourceTypeParent]: valueResourceType
                    };
                    return getUrlFromObject(filtersObject);
                }
            },
        },
    };
})();

export const MAX_FILE_SIZE_FIRMWARE = 268435456;
export const MAX_FILE_SIZE_CERTIFICATE = 1048576;
