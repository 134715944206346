import React, {useEffect, useRef} from 'react';
import TileLayer from "ol/layer/Tile";
import OLMap from "ol/Map";
import View from "ol/View";
import {MAP_VIEW_URL, SITE_VIEW_HOST} from "../../constants/Misc";
import {getToken} from "../../actions/util";
import XYZ from "ol/source/XYZ";
import Projection from "ol/proj/Projection";
import {getCenter} from 'ol/extent';
// import {defaults as defaultControls} from 'ol/control';
import MousePosition from "ol/control/MousePosition";
import {toStringXY} from "ol/coordinate";

const MapXYZ = React.forwardRef((props, olMapRef) => {
    const {
        bounds = [0, 0, 1000, 1000],
        mapDeviceId,
        maxZoom,
        onFinishLoadMap,
        onFinishLoadTiles,
        showMouseCoordinates = true,
        coordinateMessage = '',
        updateMapSize = null,
        timestamp = 0
    } = props;

    const olMap = useRef();
    const olMapContainer = useRef();
    const olTileLayer = useRef();
    const defaultZoom = 1;

    const getTileSource = (mapDeviceId, projection) => {
        const urlPrefix = SITE_VIEW_HOST + MAP_VIEW_URL + "/maps/" + mapDeviceId + "/tiles/";
        const tileUrlFunction = (coordinates) => {
            let _z = coordinates[0];
            let _x = coordinates[1];
            // let _y = -coordinates[2] - 1;
            let _y = coordinates[2];

            let url = urlPrefix;
            url += (_z) + "_";
            url += (_x) + "_";
            url += (_y);
            url+= `?timestamp=${timestamp}`;
            url += `&jwt=${getToken()}`;
            return url;
        };

        const tileSource = new XYZ({
            tileUrlFunction: tileUrlFunction,
            tileSize: [256, 256],
            wrapX: false,
            noWrap: true,
            maxZoom: maxZoom,
            projection: projection,
            crossOrigin: 'anonymous',
        });

        if (onFinishLoadTiles != null) {
            let tileRequested = 0;
            let tileReceived = 0;

            const addTileReceived = () => {
                tileReceived++;
                if (tileReceived === tileRequested) {
                    onFinishLoadTiles(mapDeviceId);
                }
            }
            tileSource.on('tileloadstart', () => tileRequested++);
            tileSource.on('tileloadend', addTileReceived);
            tileSource.on('tileloaderror', addTileReceived);
        }

        return tileSource;
    }

    const getProjection = (bounds) => {

        const projection = new Projection({
            code: 'local-map',
            units: 'pixels',
            extent: bounds
        });

        return projection;
    }

    const getView = (bounds, zoom, projection) => {
        let view = new View({
            center: getCenter(bounds),
            zoom: zoom || undefined,
            // resolution: props.resolution || undefined,
            // maxResolution: props.maxResolution || undefined,
            // minResolution: props.minResolution || undefined,
            // extent: props.extent || undefined,
            projection: projection || ("EPSG:3857")

        });

        return view;
    }

    const handleRenderComplete = () => {
        olMap.current.un('rendercomplete', handleRenderComplete);
        if (onFinishLoadMap != null)
            onFinishLoadMap(mapDeviceId);
    }

    const initOpenLayersMap = () => {

        const tileSource = getTileSource();

        const tileLayer = new TileLayer({
            source: tileSource
        });


        // Show mouse coordinates in map.
        const mousePositionControl = new MousePosition({
            coordinateFormat: (coordinate) => {
                return "(" + toStringXY(coordinate, 2) + ")";
            },
            className: 'ol-mouse-position-xy',
            target: document.getElementById('ol-mouse-position'),
            undefinedHTML: '&nbsp;',
        });

        // let controls=[];
        // controls = defaultControls().extend([mousePositionControl]);


        let map = new OLMap({
            layers: [tileLayer],
            controls: [mousePositionControl],
            target: null
        });

        olMap.current = map;
        olTileLayer.current = tileLayer;

        if (olMapRef != null) {
            olMapRef.current = map;
        }

    };

    const updateOpenLayersMap = () => {
        if (!!mapDeviceId && !!bounds) {

            const projection = getProjection(bounds);

            const tileSource = getTileSource(mapDeviceId, projection);

            const view = getView(bounds, defaultZoom, projection);
            olMap.current.on('rendercomplete', handleRenderComplete);
            olTileLayer.current.setSource(tileSource);
            olMap.current.setView(view);

            olMap.current.setTarget(olMapContainer.current);


        } else {

        }
    };

    // Init the map for first time.
    // TODO: check initOpenLayersMap or getTileSource, olMapRe to add as a dependency of the useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(initOpenLayersMap, [/*getTileSource, olMapRef*/]);

    // Update the map on prop change.
    // TODO: check getTileSource & handleRenderComplete functions to add as a dependency of the useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(updateOpenLayersMap, [mapDeviceId, maxZoom, bounds/*, getTileSource, handleRenderComplete*/]);

    // update Map size.
    useEffect(()=>{
        if(olMap!=null&&olMap.current!=null) {
            olMap.current.updateSize();
        }
    }, [updateMapSize]);

    let styleNoMap = {
        background: '#ddd',
        color: '#777',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // position:'absolute',
        top:0,
        left:0,
        zIndex:100,

    };

    return (
        <div ref={olMapContainer} style={{width: "100%", height: '100%'}} id={'ol-map-container'}>
            {(mapDeviceId == null || bounds == null) ?
                <div style={styleNoMap}>
                    <div style={{textAlign: 'center'}}>
                        Invalid map coordinate configuration.
                        <br/><br/>
                        Please reconfigure in Site Manager.
                    </div>
                </div>
                : null}
            <div id={'ol-mouse-position'} className={'ol-mouse-position'+((showMouseCoordinates===false)?' ol-hide':'')}></div>
            {(showMouseCoordinates===false)&&<div className={'ol-mouse-position'}>{coordinateMessage}</div>}
        </div>
    );

});

export default React.memo(MapXYZ, ((prevProps, nextProps) => {
    return (prevProps.mapDeviceId === nextProps.mapDeviceId
        && prevProps.maxZoom === nextProps.maxZoom
        && prevProps.bounds === nextProps.bounds
        && prevProps.updateMapSize === nextProps.updateMapSize
    );
}));

// export default MapXYZ;
