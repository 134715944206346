import { ItemType } from '../../../constants';
import {
    DUPLICATE_FIELD_VALIDATION,
    FORM_FIELD_REQUIRED,
    RANGE_NUMBER_PATH_VALIDATION,
} from './Forms';

export const MAX_INTEGER = 2147483647;
export const MIN_INTEGER = -2147483647;

export const DEVICE_FORM = 'deviceForm';
export const DEVICE_CONFIG_FORM = 'deviceConfigForm';
export const ZONE_FORM_NAME = 'zoneForm';
export const ZONE_BUILDER = 'Zones';
export const ANTENNA_FIELDS = {
    "main_title": {
        label: "Antenna Details"
    },
    "name": {
        "key":"name",
        "label": "Antenna Name",
        "path": "$.name",
        "type": "text",
        "enabled": false,
        "required": true,
        "placeHolder": "Enter Name value",
        "className": "antenna-form-input",
        "validations": [
            {
                'type': 'form_field_required',
                'errorMessage': 'Antenna Name is required.',
                "path": "$.name",
            }
        ]
    },
    "location": {
        "label": "Location",
        "required": true,
        "type": "location",
        "placeHolder": "Coordinate",
        "fields" : {
            "x": {
                "key": "x",
                "label": "X:",
                "path": "$.x",
                "type": "numeric",
                "enabled": true,
                "required": true,
                "placeHolder": "Coordinate",
                "className": "antenna-form-input-num-loc",
                "validations": [
                    {
                        'type': 'form_field_required',
                        'errorMessage': 'X is required.',
                        "path": "$.x",
                    }
                ]
            },
            "y": {
                "key": "y",
                "label": "Y:",
                "path": "$.y",
                "type": "numeric",
                "enabled": true,
                "required": true,
                "placeHolder": "Coordinate",
                "className": "antenna-form-input-num-loc",
                "validations": [
                    {
                        'type': 'form_field_required',
                        'errorMessage': 'Y is required.',
                        "path": "$.y",
                    }
                ]
            },
            "z": {
                "key":"z",
                "label": "Z:",
                "path": "$.z",
                "type": "numeric",
                "enabled": true,
                "required": true,
                "placeHolder": "Coordinate",
                "className": "antenna-form-input-num-loc",
                "validations": [
                    {
                        'type': 'form_field_required',
                        'errorMessage': 'Z is required.',
                        "path": "$.z",
                    }
                ]
            },
            "az": {
                "key":"az",
                "label": "AZ:",
                "path": "$.az",
                "type": "numeric",
                "enabled": false,
                "required": true,
                "placeHolder": "Coordinate",
                "className": "antenna-form-input-num-loc",
                "validations": [
                    {
                        'type': 'form_field_required',
                        'errorMessage': 'AZ is required.',
                        "path": "$.az",
                    }
                ]
            }
        }
    },
    "model": {
        "key":"antenna_model_id",
        "label": "Antenna Model",
        "path": "$.antenna_model_id",
        "enabled": true,
        "required": true,
        "type": "select",
        "defaultValue": {
            "name": "Select a model",
            "value": ""
        },
        "options":[],
        "validations": [
            {
                'type': 'form_field_required',
                'errorMessage': 'Antenna Model is required.',
                "path": "$.antenna_model_id",
            }
        ]
    }
}

export const MEASURING_SYSTEM = {
    "SI": "SI",
    "Imperial": "Imperial"
}

export const OBSTRUCTION_FIELDS = {
    "name": {
        "key":"name",
        "label": "Obstruction Name",
        "path": "$.name",
        "type": "text",
        "enabled": false,
        "required": true,
        "className": "antenna-form-input",
        "maxLength": 100,
        validations: [
            {
                type: FORM_FIELD_REQUIRED,
                errorMessage: 'Obstruction Name is required.',
                path: '$.name',
            },
            {
                type: DUPLICATE_FIELD_VALIDATION,
                errorMessage: 'Obstruction name exists.',
                path: '$.name',
            },
        ]
    },
    /* "type": {
        "key":"type_obstruction",
        "label": "Obstruction Type",
        "path": "$.type",
        "enabled": true,
        "required": true,
        "type": "select",
        "defaultValue": {
            "name": "Select Obstruction Type",
            "value": ""
        },
        "options":[
            {name:"Door",value:"Door"},
            {name:"Fixture",value:"Fixture"},
            {name:"HVAC",value:"HVAC"},
            {name:"Network Cables",value:"Network Cables"},
            {name:"Wall",value:"Wall"},
        ],
        "validations": [
            {
                'type': 'form_field_required',
                'errorMessage': 'Obstruction Type is required.',
                "path": "$.type",
            }
        ]
    }, */
    "coordinates": {
        "type": "obstructionCoordinates",
        "path": "$.coordinates",
        "validations": [
            {
                "type": "array_values_validation"
            }
        ],
        "format": {
            "label": "Coordinate",
            "required": true,
            "fields" : {
                "x": {
                    "key": "x",
                    "label": "X:",
                    "path": "$.coordinates.index.0",
                    "type": "numeric",
                    "enabled": true,
                    "required": true,
                    "placeHolder": "",
                    "className": "antenna-form-input-num-loc",
                    "validations": [
                        {
                            "type": RANGE_NUMBER_PATH_VALIDATION,
                            "range": {
                                'min': MIN_INTEGER,
                                'max': MAX_INTEGER,
                                'errorMessageMinValue': 'Coordinates must be between '+MIN_INTEGER+' and '+MAX_INTEGER,
                                'errorMessageMaxValue': 'Coordinates must be between '+MIN_INTEGER+' and '+MAX_INTEGER
                            },
                            "path": "$.coordinates.index.0"
                        }
                    ]
                },
                "y": {
                    "key": "y",
                    "label": "Y:",
                    "path": "$.coordinates.index.1",
                    "type": "numeric",
                    "enabled": true,
                    "required": true,
                    "placeHolder": "",
                    "className": "antenna-form-input-num-loc",
                    "validations": [
                        {
                            "type": RANGE_NUMBER_PATH_VALIDATION,
                            "range": {
                                'min': MIN_INTEGER,
                                'max': MAX_INTEGER,
                                'errorMessageMinValue': 'Coordinates must be between '+MIN_INTEGER+' and '+MAX_INTEGER,
                                'errorMessageMaxValue': 'Coordinates must be between '+MIN_INTEGER+' and '+MAX_INTEGER
                            },
                            "path": "$.coordinates.index.1"
                        }
                    ]
                }
            }
        }
    }
}

export const ZONE_BUILDER_FIELDS = {
    "name": {
        "key":"name",
        "label": "Zone Name",
        "path": "$.name",
        "type": "text",
        "enabled": false,
        "required": true,
        "className": "antenna-form-input",
        "maxLength": 100,
        validations: [
            {
                type: FORM_FIELD_REQUIRED,
                errorMessage: 'Zone Name is required.',
                path: '$.name',
            },
            {
                type: DUPLICATE_FIELD_VALIDATION,
                errorMessage: 'Zone name exists.',
                path: '$.name',
            },
        ],
    },
    "type": {
        "key":"type_obstruction",
        "label": "Zone Type",
        "path": "$.type",
        "enabled": true,
        "required": true,
        "type": "select",
        "defaultValue": {
            "name": "Select Zone Type",
            "value": ""
        },
        "options":[],
        validations: [
            {
                type: FORM_FIELD_REQUIRED,
                errorMessage: 'Zone Type is required.',
                path: '$.type',
            },
        ],
    },
    "coords": {
        "type": "zoneCoordinates",
        "path": "$.coordinates",
        "validations": [
            {
                "type": "array_values_validation"
            }
        ],
        "format": {
            "label": "Coordinate",
            "required": true,
            "fields" : {
                "x": {
                    "key": "x",
                    "label": "X:",
                    "path": "$.coordinates.index.0",
                    "type": "numeric",
                    "enabled": true,
                    "required": true,
                    "placeHolder": "",
                    "classNameLabel": "antenna-label-location-fields",
                    "className": "antenna-form-input-num-loc",
                    "validations": [
                        {
                            "type": RANGE_NUMBER_PATH_VALIDATION,
                            "range": {
                                'min': MIN_INTEGER,
                                'max': MAX_INTEGER,
                                'errorMessageMinValue': 'Coordinates must be between '+MIN_INTEGER+' and '+MAX_INTEGER,
                                'errorMessageMaxValue': 'Coordinates must be between '+MIN_INTEGER+' and '+MAX_INTEGER
                            },
                            "path": "$.coordinates.index.0",
                        }
                    ]
                },
                "y": {
                    "key": "y",
                    "label": "Y:",
                    "path": "$.coordinates.index.1",
                    "type": "numeric",
                    "enabled": true,
                    "required": true,
                    "placeHolder": "",
                    "classNameLabel": "antenna-label-location-fields",
                    "className": "antenna-form-input-num-loc",
                    "validations": [
                        {
                            "type": RANGE_NUMBER_PATH_VALIDATION,
                            "range": {
                                'min': MIN_INTEGER,
                                'max': MAX_INTEGER,
                                'errorMessageMinValue': 'Coordinates must be between '+MIN_INTEGER+' and '+MAX_INTEGER,
                                'errorMessageMaxValue': 'Coordinates must be between '+MIN_INTEGER+' and '+MAX_INTEGER
                            },
                            "path": "$.coordinates.index.1"
                        }
                    ]
                }
            }
        }
    }
}

export const DATUM_FIELDS = {
    "main_title": {
        label: "Datum Details"
    },
    "name": {
        "key":"name",
        "label": "Name",
        "path": "$.name",
        "type": "text",
        "enabled": false,
        "required": true,
        "placeHolder": "Enter Datum Name",
        "className": "datum-form-input",
        "validations": [
            {
                type: 'form_field_required',
                errorMessage: 'Name is required.',
                path: "$.name",
            },
            {
                type: DUPLICATE_FIELD_VALIDATION,
                errorMessage: 'Datum name exists.',
                path: '$.name',
            },
        ]
    },
    "location": {
        "label": "Location",
        "required": true,
        "type": "location",
        "placeHolder": "Coordinate",
        "path": "$.location",
        "fields" : {
            "x": {
                "key": "x",
                "label": "X:",
                "path": "$.x",
                "type": "numeric",
                "enabled": true,
                "required": true,
                "placeHolder": "Enter x coordinate",
                "className": "datum-form-input-num-loc",
                "validations": [
                    {
                        'type': 'form_field_required',
                        'errorMessage': 'X coordinate is required.',
                        "path": "$.x",
                    }
                ]
            },
            "y": {
                "key": "y",
                "label": "Y:",
                "path": "$.y",
                "type": "numeric",
                "enabled": true,
                "required": true,
                "placeHolder": "Enter y coordinate",
                "className": "datum-form-input-num-loc",
                "validations": [
                    {
                        'type': 'form_field_required',
                        'errorMessage': 'Y is required.',
                        "path": "$.y",
                    }
                ]
            }
        }
    }
}

export const FORM_DATA_SITE_MANAGER_DEVICES = {
    [ItemType.ANTENNA]: {
        formName: DEVICE_FORM,
        fields: ANTENNA_FIELDS
    },
    [ItemType.DEVICE_DATUM]: {
        formName: DEVICE_FORM,
        fields: DATUM_FIELDS
    },
}

export const MEASURE_UNIT = 'Measure System';
export const METRIC_SYSTEM_LABEL = 'Metric (m)';
export const IMPERIAL_SYSTEM_LABEL = 'Imperial System (ft)';
export const MEASURE_UNIT_CHANGE = 'MEASURE UNIT CHANGE';

export const IMPERIAL_MEASURE_SYSTEM = 1;
export const METRIC_MEASURE_SYSTEM = 2;
export const MEASURE_DEFAULT_MEASURE_SYSTEM = IMPERIAL_MEASURE_SYSTEM;
export const MEASURE_UNIT_OPTIONS = [{name: 'Imperial', value: IMPERIAL_MEASURE_SYSTEM}, {name: 'Metric', value: METRIC_MEASURE_SYSTEM}]
