/* jshint esversion: 6 */

import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
//openlayers
//import {Coordinate} from 'ol/coordinate';
import OLOverlay from 'ol/Overlay';

/**
 * Simple wrapper for Overlays to prevent unnecessary re-renders of the DOM.
 */
class OverlayGroup extends React.Component{
    /**
     * @private
     */
    static get propTypes(){
        return {
            active: PropTypes.bool,
            map: PropTypes.any,
            children: PropTypes.any,
            zIndex: PropTypes.number
        };
    }

    /**
     * @private
     */
    static get defaultProps(){
        return {
            active: true,
            zIndex: 1
        };
    }

    /**
     * @private
     */
    render(){

        var children = null;

        if(this.props.active){
            // Make sure the children (overlays) still get the map prop.
            children = React.Children.toArray(this.props.children);
            children = React.Children.map(children,(child) => React.cloneElement(child, {
                map:this.props.map,
                zIndex: this.props.zIndex
            }));
        }


        return (
            <div>
                {children}
            </div>
        );
    }
}

/**
 * A wrapper around an element (typically a label) to position it based on a map coordinate.
 * It is a real DOM element.
 */
class Overlay extends React.Component{

    /**
     * @private
     */
    static get propTypes(){
        return {
            active: PropTypes.any,
            children: PropTypes.any,
            position: PropTypes.any,
            positioning: PropTypes.any,
            offset: PropTypes.any,
            map: PropTypes.any,
            zIndex: PropTypes.number
        };
    }

    static get defaultProps(){
        return {
            zIndex:1
        };
    }

    /**
     * @private
     */
    constructor(props){
        super(props);

        /**
         * @type {Object}
         * @property {node} children Children of the overlay.  (Usually what you actually want to see.)
         * @property {Coordinate} position XY map coordinate over which the overlay will hover.
         * @property {string} positioning Alignment of the overlay. {top | center | bottom}-{left | center | right} (e.g. "center-center")
         */
        this.props = props;

    }

    /**
     * @private
     * Runs after mounting.
     */
    componentDidMount(){

        /**
         * @private
         * Internal DOM element for the overlay.
         */
        this.elm = document.createElement("div");

        var children = (
            <div>
                {this.props.children}
            </div>
        );

        ReactDOM.render(children,this.elm);


        /**
         * @private
         * Internal OpenLayers overlay.
         */
        this.overlay = new OLOverlay({
            position: this.props.position || [0,0],
            positioning: this.props.positioning,
            element: this.elm,
            offset: this.props.offset
        });

        if(this.props.map){
            this.props.map.addOverlay(this.overlay);
        }

        let elm = this.overlay.getElement().parentNode;
        elm.style.zIndex = this.props.zIndex;
    }

    /**
     * @private
     * Runs after rendering.
     */
    componentDidUpdate(pp){
        if(pp.position !== this.props.position){ this.overlay.setPosition(this.props.position); }
        if(pp.children !== this.props.children){
            ReactDOM.unmountComponentAtNode(this.elm);
            var children = (
                <div>
                    {this.props.children}
                </div>
            );

            ReactDOM.render(children,this.elm);
        }
    }

    /**
     * @private
     * Runs before unmounting.
     */
    componentWillUnmount(){
        this.props.map.removeOverlay(this.overlay);
        ReactDOM.unmountComponentAtNode(this.elm);
    }

    /**
     * @private
     * Renders the UI to the Virtual DOM (which updates the actual DOM)
     */
    render(){
        return (
            <div />
        );
    }
}

export default Overlay;
export {OverlayGroup};
