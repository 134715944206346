import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

let FormatColorFill = props => {
    const {fillColor,...other} = props;
    return (
        <SvgIcon {...other}>
          <path d="M16.56 8.94L7.62 0 6.21 1.41l2.38 2.38-5.15 5.15c-.59.59-.59 1.54 0 2.12l5.5 5.5c.29.29.68.44 1.06.44s.77-.15 1.06-.44l5.5-5.5c.59-.58.59-1.53 0-2.12z M5.21 10L10 5.21 14.79 10H5.21z" />
          <path fill={fillColor} d="M19 11.5s-2 2.17-2 3.5c0 1.1.9 2 2 2s2-.9 2-2c0-1.33-2-3.5-2-3.5z" />
          <path fill={fillColor} d="M0 20h24v4H0z" />
        </SvgIcon>
    );
};

FormatColorFill.propTypes = {
    fillColor: PropTypes.any
};



export default FormatColorFill;
