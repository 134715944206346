import {VALIDATION_MESSAGE_REQUIRED, VALIDATION_TYPE_REQUIRED,
    VALIDATION_DUPLICATED_VALUES_IN_ARRAY,VALIDATION_MESSAGE_DUPLICATED_TAGID} from "../../constants/ResourceTypes";

export const VALIDATE_RESOURCE_FIELD = {
    Identity: {
        resourceId: {
            error: false,
            type: VALIDATION_TYPE_REQUIRED,
            message: VALIDATION_MESSAGE_REQUIRED,
            validateForms:[]
        },
        resourceType: {
            error: false,
            type: VALIDATION_TYPE_REQUIRED,
            message: VALIDATION_MESSAGE_REQUIRED,
            validateForms:[]
        },
    },
    Tags: {
        associatedTags: {
            error: false,
            type: VALIDATION_DUPLICATED_VALUES_IN_ARRAY,
            message: VALIDATION_MESSAGE_DUPLICATED_TAGID,
            duplicateIndexes: [],
            validateForms:[]
        }
    },
    Attributes: {
        /*resourceType: {
            error: false,
            type: VALIDATION_TYPE_REQUIRED,
            message: VALIDATION_MESSAGE_REQUIRED,
            validateForms:[]
        },*/
    },
}