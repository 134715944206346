import { OlBase } from '../commons/OlBase';
import PointVector from '../../vss/app/utils/ol/Point';
import {
  area as turfArea,
  booleanPointInPolygon,
  intersect as turfIntersect,
  point as turfPoint,
  polygon as turfPolygon
} from '@turf/turf';
import VectorSource from 'ol/source/Vector';
// import VectorImageLayer from 'ol/layer/Vector';
import VectorImageLayer from 'ol/layer/VectorImage';
import { Feature } from 'ol';
import { Circle, LineString, Polygon } from 'ol/geom';
import { Fill, Stroke, Style } from 'ol/style';

export const olExtender = (...parts) => parts.reduce((sumParts, part) => part(sumParts), OlBase);

export const getOlStoreKey = (featureType) => `${featureType}-store`;

export const isPointInPolygon = (point, polygon, options) => {
  const {
    getPointCoordinates = (point) => point.coordinates,
    getPolygonCoordinates = (polygon) => polygon.coordinates
  } = options || {};
  
  return booleanPointInPolygon(turfPoint(getPointCoordinates(point)), turfPolygon(getPolygonCoordinates(polygon)));
};

// if 'polygonA' is contained in 'polygonB' its return true; in other cases, it returns false.
// options?: { getPolygonKey?: () => number | strting , getPolygonCoordinates?: () => [number, number][] }
export const isPolygonInPolygon = (polygonA, polygonB, options) => {
  const {
    getPolygonKey = (polygon) => polygon.key,
    getPolygonCoordinates = (polygon) => polygon.coordinates
  } = options || {};
  
  if (getPolygonKey(polygonA) === getPolygonKey(polygonB)) {
    return false;
  }
  const coordsPolygonA = getPolygonCoordinates(polygonA);
  const coordsPolygonB = getPolygonCoordinates(polygonB);
  let numberPointsInside = 0;
  
  for (const point of coordsPolygonA[0]) {
    if (isPointInPolygon({ coordinates: point }, { coordinates: coordsPolygonB })) {
      numberPointsInside += 1;
    }
  }
  return numberPointsInside === coordsPolygonA[0].length;
};

// This function returns a polygon that are contained in "polygonSet" and is the last children; in other cases, it returns null.
// options?: { getPolygonKey?: () => number | strting , getPolygonCoordinates?: () => [number, number][] }
export const getLastChildOfPolygonSet = (polygonSet, options) => {
  let polygonChild = polygonSet.length ? polygonSet[0] : null;
  let maxNumberParents = 0;
  for (const polygonA of polygonSet) {
    let numberParents = 0;
    for (const polygonB of polygonSet) {
      if (isPolygonInPolygon(polygonA, polygonB, options)) {
        numberParents += 1;
      }
    }
    if (numberParents > maxNumberParents) {
      maxNumberParents = numberParents;
      polygonChild = polygonA;
    }
  }
  return polygonChild;
};

export const isPolygonIntersectWithPolygon = (polygonA, polygonB, options) => {
  const {
    getPolygonKey = (polygon) => polygon.key,
    getPolygonCoordinates = (polygon) => polygon.coordinates
  } = options || {};
  
  if (getPolygonKey(polygonA) === getPolygonKey(polygonB)) {
    return false;
  }
  
  const polygonATurf = turfPolygon(getPolygonCoordinates(polygonA));
  const polygonBTurf = turfPolygon(getPolygonCoordinates(polygonB));
  
  return !!turfIntersect(polygonATurf, polygonBTurf);
};

// options?: { getPolygonKey?: () => number | strting , getPolygonCoordinates?: () => [number, number][] }
export const isPolygonIntersectWithPolygonSet = (polygon, polygonSet, options) => {
  for (const polygon_ of (polygonSet || [])) {
    if (
      isPolygonIntersectWithPolygon(polygon_, polygon, options) &&
      !isPolygonInPolygon(polygon, polygon_, options) &&
      !isPolygonInPolygon(polygon_, polygon, options)
    ) {
      return true;
    }
  }
  return false;
};

export const isPolygonIntersectOrOverlapWithPolygonSet = (polygon, polygonSet, options) => {
  for (const polygon_ of (polygonSet || [])) {
    if (isPolygonIntersectWithPolygon(polygon_, polygon, options)) {
      return true;
    }
  }
  return false;
};

export const getPointTranslatedInsideOfPolygon = (polygon, pointIndex, distance, options) => {
  const {
    getPolygonCoordinates = (polygon) => polygon.coordinates
  } = options || {};
  const polygonCoordinates = getPolygonCoordinates(polygon);
  
  const previousIndex = pointIndex === 0 ? polygonCoordinates[0].length - 2 : pointIndex - 1;
  const nextIndex = (pointIndex + 1) % (polygonCoordinates[0].length - 1);
  const A = new PointVector(...polygonCoordinates[0][previousIndex]);
  const B = new PointVector(...polygonCoordinates[0][nextIndex]);
  const C = new PointVector(...polygonCoordinates[0][pointIndex]);
  
  const u = A.sub(C).unit().prod(distance);
  const v = B.sub(C).unit().prod(distance);
  
  const angleA = u.angle(v);
  const angleB = Math.PI * 2 - angleA;
  
  const possibleOptions = [];
  const a1 = C.add(u.rotate(angleA / 2));
  const b1 = C.add(v.rotate(-angleA / 2));
  possibleOptions.push([a1, b1]);
  
  const a2 = C.add(u.rotate(-angleA / 2));
  const b2 = C.add(v.rotate(angleA / 2));
  possibleOptions.push([a2, b2]);
  
  const a3 = C.add(u.rotate(angleB / 2));
  const b3 = C.add(v.rotate(-angleB / 2));
  possibleOptions.push([a3, b3]);
  
  const a4 = C.add(u.rotate(-angleB / 2));
  const b4 = C.add(v.rotate(angleB / 2));
  possibleOptions.push([a4, b4]);
  
  const opt = {
    getPointCoordinates: (point) => point,
    getPolygonCoordinates: (polygon) => polygon
  };
  let minDistance = -1;
  let newPoint = null;
  for (const option of possibleOptions) {
    const coordinatesA = option[0].getCoordinates();
    const coordinatesB = option[1].getCoordinates();
    const pointA = new PointVector(...coordinatesA);
    const pointB = new PointVector(...coordinatesB);
    if (isPointInPolygon(coordinatesA, polygonCoordinates, opt)
      && isPointInPolygon(coordinatesB, polygonCoordinates, opt)) {
      const distance = option[0].sub(option[1]).mod2();
      if (minDistance === -1) {
        minDistance = distance;
        newPoint = pointA.middlePoint(pointB);
      } else if (distance < minDistance) {
        minDistance = distance;
        newPoint = pointA.middlePoint(pointB);
      }
    }
  }
  if (!newPoint) {
    return getPointTranslatedInsideOfPolygon(polygon, pointIndex, distance / 2, options);
  }
  
  return {
    coordinates: newPoint.getCoordinates(),
    distance
  };
};

export const getPointTranslatedOutsideOfPolygon = (polygon, pointIndex, distance, options) => {
  const {
    getPolygonCoordinates = (polygon) => polygon.coordinates
  } = options || {};
  const polygonCoordinates = getPolygonCoordinates(polygon);
  const newPoint = new PointVector(...getPointTranslatedInsideOfPolygon(polygon, pointIndex, distance, options).coordinates);
  const point = new PointVector(...polygonCoordinates[0][pointIndex]);
  const u = newPoint.sub(point).ort().ort().unit().prod(distance);
  return {
    coordinates: point.add(u).getCoordinates()
  };
};

export const getPolygonTransformScale = (polygon, distance, options) => {
  const {
    getPolygonKey = (polygon) => polygon.key,
    getPolygonCoordinates = (polygon) => polygon.coordinates
  } = options || {};
  
  const polygonCoordinates = getPolygonCoordinates(polygon);
  const newPolygonCoordinates = structuredClone(getPolygonCoordinates(polygon));
  for (let i = 0; i < polygonCoordinates[0].length; i++) {
    let newPoint;
    if (distance > 0) {
      newPoint = getPointTranslatedOutsideOfPolygon(polygon, i, distance, options).coordinates;
    } else {
      newPoint = getPointTranslatedInsideOfPolygon(polygon, i, -distance, options).coordinates;
    }
    newPolygonCoordinates[0][i] = newPoint;
  }
  return {
    key: getPolygonKey(polygon),
    coordinates: newPolygonCoordinates
  };
};

export const getPolygonArea = (polygon, options) => {
    const {
        getPolygonCoordinates = (polygon) => polygon.coordinates
    } = options || {};
    let area = 0;
    const coordinates = getPolygonCoordinates(polygon)[0];
    for (let i = 1; i < coordinates.length; i++) {
        const j = i - 1;
        const ax = coordinates[i][0];
        const ay = coordinates[i][1];
        const bx = coordinates[j][0];
        const by = coordinates[j][1];
        const crossProduct = ax * by - ay * bx;
        area = area + crossProduct;
    }
    return Math.abs(area / 2);
};

export const getPolygonArea_ = (polygon, options) => { // not working properly...
  const {
    getPolygonCoordinates = (polygon) => polygon.coordinates
  } = options || {};
  
  const polygonTurf = turfPolygon(getPolygonCoordinates(polygon));
  return turfArea(polygonTurf);
};

export const getFirstParentPolygonKey = (polygon, polygonSet, options) => {
  const {
    getPolygonKey = (polygon) => polygon.key
  } = options || {};
  let parentKeyPolygon = null;
  let areaParentPolygon = Number.MAX_VALUE;
  for (const pol of polygonSet) {
    const polArea = getPolygonArea(pol, options);
    if (isPolygonInPolygon(polygon, pol, options) && (polArea < areaParentPolygon)) {
      areaParentPolygon = polArea;
      parentKeyPolygon = getPolygonKey(pol);
    }
  }
  return parentKeyPolygon;
};

export const getParentKeysToPolygonSet = (polygonSet, allPolygonSet, options) => {
  const {
    getPolygonKey = (polygon) => polygon.key,
    getPolygonCoordinates = (polygon) => polygon.coordinates
  } = options || {};
  const map = (polygon) => ({
    key: getPolygonKey(polygon),
    coordinates: getPolygonCoordinates(polygon),
      // area:  turfArea(turfPolygon(getPolygonCoordinates(polygon))),
      // ...polygon,
  });
  // const newAllPolygonSet = allPolygonSet.map(map);
  const result = {};
  polygonSet.map(map).forEach((zone) => {
      // result[zone.key] = getFirstParentPolygonKey(zone, newAllPolygonSet);
      result[zone.key] = null;
  });
  
  return result;
};

export const arePolygonsColliding = (polygonSet, options) => {
  for (const polygon of polygonSet) {
    if (isPolygonIntersectWithPolygonSet(polygon, polygonSet, options)) {
      return true;
    }
  }
  return false;
};

export const arePolygonsCollidingOrOverlapping = (polygonSet, options) => {
  for (const polygon of polygonSet) {
    if (isPolygonIntersectOrOverlapWithPolygonSet(polygon, polygonSet, options)) {
      return true;
    }
  }
  return false;
};

export const getNearPointsBetweenPolygonAndPolygonSet = (polygon, polygonSet, options) => {
  const {
    getPolygonKey = (polygon) => polygon.key,
    getPolygonCoordinates = (polygon) => polygon.coordinates
  } = options || {};
  
  let nearestPoint = null;
  let nearestPointDistance = null;
  
  const featureAKey = getPolygonKey(polygon);
  const coordinatesA = getPolygonCoordinates(polygon);
  for (const featureB of polygonSet) {
    const coordinatesB = getPolygonCoordinates(featureB)[0];
    const featureBKey = getPolygonKey(featureB);
    if (featureAKey !== featureBKey) {
      let nearPoint = null;
      let nearPointDistance = null;
      for (const coordinateA of coordinatesA[0]) {
        const point = new PointVector(...coordinateA);
        let nearPointBySide = null;
        let nearPointDistanceBySide = null;
        for (let i = 1; i < coordinatesB.length; i++) {
          const A = new PointVector(...coordinatesB[i - 1]);
          const B = new PointVector(...coordinatesB[i]);
          const projectedPoint = point.getProjectedPointOnSegment(A, B);
          const projectedFeature = {
            coordinates: projectedPoint.getCoordinates()
          };
          const distance = point.sub(projectedPoint).mod();
          projectedFeature.distance = distance;
          projectedFeature.pivot = point;
          projectedFeature.point = projectedPoint;
          if (nearPointBySide === null) {
            nearPointBySide = projectedFeature;
            nearPointDistanceBySide = distance;
          } else if (distance < nearPointDistanceBySide) {
            nearPointBySide = projectedFeature;
            nearPointDistanceBySide = distance;
          }
        }
        
        if (nearPoint === null) {
          nearPoint = nearPointBySide;
          nearPointDistance = nearPointDistanceBySide;
        } else if (nearPointDistanceBySide < nearPointDistance) {
          nearPoint = nearPointBySide;
          nearPointDistance = nearPointDistanceBySide;
        }
      }
      
      if (nearestPoint === null) {
        nearestPoint = nearPoint;
        nearestPointDistance = nearPointDistance;
      } else if (nearPointDistance < nearestPointDistance) {
        nearestPoint = nearPoint;
        nearestPointDistance = nearPointDistance;
      }
    }
  }
  
  return { nearestPoint, nearestPointDistance };
};

let sourceDraft;
let layerDraft;
const defaultStyle = () => (feature) => {
  if (feature.get('type') === 'circle') {
    return [
      new Style({
        geometry: new Circle(feature.get('coordinates'), feature.get('radius')),
        fill: new Fill({ color: feature.get('fillColor') || 'rgba(255, 255, 255, 0.4)' }),
        stroke: new Stroke({
          color: feature.get('strokeColor'),
          width: feature.get('strokeWidth') || 1
        })
      })
    ];
  } else if (feature.get('type') === 'point') {
    return [
      new Style({
        geometry: new Circle(feature.get('coordinates'), feature.get('radius') || 1),
        fill: new Fill({ color: feature.get('fillColor') })
      })
    ];
  } else if (feature.get('type') === 'lineString') {
    return [
      new Style({
        geometry: new LineString(feature.get('coordinates')),
        stroke: new Stroke({
          color: feature.get('strokeColor'),
          width: feature.get('strokeWidth') || 1
        })
      })
    ];
  }
  return [];
};

export const addDummyFeatures = (mapRef, objects, style = defaultStyle) => {
  if (sourceDraft) {
    sourceDraft.clear();
  }
  if (layerDraft) {
    mapRef.removeLayer(layerDraft);
  }
  sourceDraft = new VectorSource({ useSpatialIndex: false });
  layerDraft = new VectorImageLayer({
    source: sourceDraft,
    style: style(sourceDraft)
  });
  mapRef.addLayer(layerDraft);
  const features = objects.map(object => (new Feature({ ...object, geometry: new Polygon([]) })));
  sourceDraft.addFeatures(features);
};


export const getZoneIds = (response) => {
  return response?.data?.results?.map(zone => zone.id) || [];
}
