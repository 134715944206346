import {WINDOW_RESIZE} from '../constants/ActionTypes';
import {SM, TS} from '../constants/Responsive';
import {MOBILE_DEVICE} from '../constants/Mobile';

const resizeInitialState = {
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
    isMobile: window.innerWidth <= SM,
    isMobileDevice: MOBILE_DEVICE,
    isPortrait:window.innerWidth<window.innerHeight,
    isMidSize: window.innerWidth <= TS,
};

export function resize(state=resizeInitialState, action){
    switch(action.type){
        case WINDOW_RESIZE:
            return {
                windowWidth: action.width,
                windowHeight: action.height,
                isMobile: action.width <= SM,
                isMobileDevice: MOBILE_DEVICE,
                isPortrait: action.width < action.height,
                isMidSize: action.width <= TS,
            }
        default:
            return state;
    }

}
