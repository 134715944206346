import React from 'react';
import Dialog from '../dialog/Dialog';

const Loading = ({ error }) => {
    if (error) {
        return <div>Error!</div>;
    }
    return <Dialog style={{ background: '#4C4C4C', position: 'fixed' }} loader/>;
};

export default Loading;
