import React from "react";
// import {DataEntryTabularField} from "../../../util/DataEntryTabularForm";
import {Delete} from "@material-ui/icons";
import {
    TYPE_FIELD_COMBOBOX, TYPE_FIELD_LIST,
    TYPE_FIELD_LOOK_UP_VALUE, TYPE_FIELD_RADIO_GROUP,
    TYPE_FIELD_TEXT
} from "../../constants/ResourceTypes";
import {VALIDATION_MESSAGE_REGULAR_CHARACTERS_ATTRIBUTE_REQUIRED, VALIDATION_MESSAGE_REQUIRED, VALIDATION_TYPE_REGEXP, VALIDATION_TYPE_REQUIRED} from "../../../../constants/ResourceTypes";
import {useDispatch} from "react-redux";
import {deleteResourceTypeAttribute} from "../../../../actions";
import {useI18n} from "../../../../hooks/i18n";

export const IdBsonType = 'dataType',IdFieldNames='fieldNames',IdLookUpValue = 'lookUpValue',IdName = 'nameTypeAttribute';
const ResourceTypeAttributes = (props) => {

    const {IndexGroup,getFieldTypeAttribute} = props;
    const IdDisplayName = 'displayNameAttribute', IdDescription = 'descriptionAttribute';
    const Searchable = 'searchable';
    const { translate } = useI18n('resourceType');
    const dispatch = useDispatch();

    const getFieldLookUp = () =>{
        return {
            key:IdLookUpValue,
            caption:'Look up values',
            value:[],
            type:TYPE_FIELD_LOOK_UP_VALUE,
            validation:{
                error: false,
                type: VALIDATION_TYPE_REQUIRED,
                message: VALIDATION_MESSAGE_REQUIRED,
                validateForms:[]
            }
        };
    }
    const getFieldList = () =>{
        return {
            key:IdFieldNames,
            caption:'Key',
            value:[],
            type:TYPE_FIELD_LIST,
            validation:{
                error: false,
                type: VALIDATION_TYPE_REQUIRED,
                message: VALIDATION_MESSAGE_REQUIRED,
                validateForms:[]
            }
        };
    }
    const listField = [
        {
            key: IdName,
            caption: 'Name',
            value: '',
            type: TYPE_FIELD_TEXT,
            disabled: true,
            required: true,
            regex: /^\w+$/,
            validation: {
                error: false,
                type: VALIDATION_TYPE_REGEXP,
                regexp: /^\w+$/,
                regFirstCharacter: /^[0-9]$/,
                message: VALIDATION_MESSAGE_REGULAR_CHARACTERS_ATTRIBUTE_REQUIRED,
                validateForms:[]
            }
        },
        {
            key:IdDisplayName,
            caption:'Display Name',
            value:'',
            type:TYPE_FIELD_TEXT,
            validation:{
                error: false,
                type: VALIDATION_TYPE_REQUIRED,
                message: VALIDATION_MESSAGE_REQUIRED,
                validateForms:[]
            }
        },
        {
            key:IdDescription,
            caption:'Description',
            value:'',
            type:TYPE_FIELD_TEXT,
            validation:{
                error: false,
                type: VALIDATION_TYPE_REQUIRED,
                message: VALIDATION_MESSAGE_REQUIRED,
                validateForms:[]
            }
        },
        {
            key:IdBsonType,
            caption:'Data Type',
            value:'',
            type:TYPE_FIELD_COMBOBOX,
            validation:{
                error: false,
                type: VALIDATION_TYPE_REQUIRED,
                message: VALIDATION_MESSAGE_REQUIRED,
                validateForms:[]
            }
        },
        // {
        //     key:IdRequired,
        //     caption:'Required',
        //     value:'',
        //     type:TYPE_FIELD_COMBOBOX,
        //     validation:{
        //         error: false,
        //         type: VALIDATION_TYPE_REQUIRED,
        //         message: VALIDATION_MESSAGE_REQUIRED,
        //         validateForms:[]
        //     }
        // },
        {
            key:Searchable,
            caption:'Searchable',
            value:'',
            type:TYPE_FIELD_COMBOBOX,
            validation:{
                error: false,
                type: VALIDATION_TYPE_REQUIRED,
                message: VALIDATION_MESSAGE_REQUIRED,
                validateForms:[]
            }

        },
        {
            key:'IdRadioOption',
            caption:'Enable lookup:',
            value:'',
            type:TYPE_FIELD_RADIO_GROUP,
            validation:{
                error: false,
                type: "",//VALIDATION_TYPE_REQUIRED,
                message: VALIDATION_MESSAGE_REQUIRED,
                validateForms:[]
            }

        },
        /*{
            key:'pattern',
            caption:'Pattern',
            value:[],
            type:TYPE_FIELD_TEXT,
            validation:{
                error: false,
                type: VALIDATION_TYPE_REQUIRED,
                message: VALIDATION_MESSAGE_REQUIRED,
                validateForms:[]
            }
        },*/
        getFieldLookUp(),
        getFieldList()
    ];
    const getFieldAttribute  = (field) =>{
        /*return (
            <div key={field.key} style={field.key==="lookUpValue"? {flex:"100%",marginTop: "10px"}:{flex:"50%",marginTop: "10px"}} >
                {getFieldTypeAttribute(IndexGroup,field)}
            </div>
        );*/
        return (
            <div key={field.key} style={[IdLookUpValue,IdFieldNames].indexOf(field.key)>=0? {flex:"100%"}:{flex:"50%",marginTop: "10px"}} >
                {getFieldTypeAttribute(IndexGroup,field)}
            </div>
        );
    }
    /*
    const getOption = () =>{
        return enableLookUp?'Lookup':'Flexible';
    }
    const handleChangeRadioButton = (event) =>{
        const value = event.target.value;
        const enabled = value==='Lookup';
        dispatch(updateEnableLookUp(IndexGroup,enabled));
        document.getElementById(`lookUpGroup-${IndexGroup}`).hidden = !enabled;
    }*/
    const renderField  = () => {
        // const fullWidth=1000;
        // const minWidth = 300;
        return (
            // <table className="data-entry-form" style={{width:(fullWidth ? '80%' : 'auto'),tableLayout:'fixed',minWidth:minWidth ? minWidth : 'auto'}}>
            //     <thead />
            //     <tbody >

                <div style={{display: "flex",flexWrap: "wrap"}}>
                {
                    listField.map(field=>{return getFieldAttribute(field);})
                }

                {/*<DataEntryTabularField key={'radio01'} label={''}>
                    <div className="field" key={'radio011'}>
                        <span className="items-header">Enable lookup:</span>
                        <RadioGroup id='operation-operationMode' style={{display: 'flex', flexDirection:'row'}}
                                    value={getOption()} name={'operationMode'} onChange={handleChangeRadioButton}>
                            <FormControlLabel value={"Flexible"} control={<Radio classes={{checked:classes.checked}}
                                                                                 style={{color: '#000', backgroundColor:'transparent'}} size={"small"} />} label="Flexible" />
                            <FormControlLabel value={"Lookup"} control={<Radio classes={{checked:classes.checked}}
                                                                               style={{color: '#000', backgroundColor:'transparent'}} size={"small"} />} label="Lookup" />
                        </RadioGroup>
                    </div>
                </DataEntryTabularField>*/}
            {/*//</tbody>*/}
            {/*// </table>*/}
    </div>
        );
    }
    const deleteFromResourceTypeAttribute = (IndexGroup) =>{
        dispatch(deleteResourceTypeAttribute(IndexGroup));
    }
    const RESOURCE_TYPE_ATTRIBUTE = translate('Resource Type Attribute');
    return (
        <div className="attributes-items">
            <div className="attributes-items-header">
                <span>{`${RESOURCE_TYPE_ATTRIBUTE} ${IndexGroup + 1}`}</span>
                <Delete style={{cursor:'pointer'}} onClick={e => deleteFromResourceTypeAttribute(IndexGroup)}/>
            </div>
            <div className="attributes-items-body">
                {renderField()}
            </div>
        </div>
    );
}
export default ResourceTypeAttributes;
