import { useState, useEffect } from "react";
import { useZoneBuilderState } from "../utils";

import { Form, Field } from "../../util/DataEntryTabularForm";
import StyledDialog, { SaveButton, CancelButton } from "../../util/Dialog";
import { EXIT_ZONE_ID_NAME } from "../Constants";

const ExitZoneDialog = ({ open, mapId, zoneId = "", onClose, onPropertyChange }) => {  
  const zones = useZoneBuilderState(mapId);
  const [selectedZoneId, setSelectedZoneId] = useState("");

  useEffect(() => {
    if (zoneId) setSelectedZoneId(zoneId);
  }, [zoneId]);

  const onChange = e => {
    setSelectedZoneId(e.target.value);
  }

  const onSave = () => {
    const zoneValue = zones && zones.length && !selectedZoneId ? zones[0].data._key : selectedZoneId;
    onPropertyChange(EXIT_ZONE_ID_NAME, zoneValue);
    onClose();
    setTimeout(() => setSelectedZoneId(""), 200); // due to dialog transition
  }

  return (
    <StyledDialog
      open={open}
      title="Exit Zone"
      maxWidth={"xs"}
      onClose={onClose}
      actions={[
        <CancelButton key="cancel-btn-exit-zone" onClick={onClose} />,
        <SaveButton key="save-exit-zone" label="save" onClick={onSave} />
      ]}
    >
      <p style={{ margin: '0 5px 20px' }}>
        Select an exit zone for the selected zone.
      </p>

      <Form fullWidth>
        <Field label="Exit Zone">
          <select value={selectedZoneId} onChange={onChange}>
            {zones.map(({ data }, ind) => (
              <option key={`zone-${data._key}-${ind}`} value={data._key}>{data.name}</option>
            ))}
          </select>
        </Field>
      </Form>
    </StyledDialog>
  );
}

export default ExitZoneDialog;