import React from 'react';

class TimerMinusIcon extends React.Component{

    render() {
        return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	        width="25px" height="25px" className={"timer-svg"} viewBox="0 0 50 50">
            <path d="M5,4.1v13.6l0,0l0,0l9.1,9.1L5,36l0,0l0,0v13.7h27.3V36l0,0l0,0l-9.1-9.1l9.1-9.1l0,0l0,0V4.1H5 M27.8,37.1v8H9.6v-8
	                l9.1-9.1L27.8,37.1 M18.7,25.8l-9.1-9.1v-8h18.2v8L18.7,25.8z"/>
            <g>
	        <path d="M46.5,6H34.8V2.2h11.7V6z"/>
            </g>
        </svg>
        );
    }
};

export default TimerMinusIcon;
