import React, { useCallback, useEffect, useRef } from 'react';
import {formatAsGeoJSON, newDefaultZoneName, refStore, replaceRefConfigZonesMap} from '../../../vss/app/actions';
import MapXYZ from '../../../vss/app/utils/ol/MapXYZ';
import OlConfigZones from '../../../ol/config-zones/OlConfigZones';
import {useZoneBuilderDispatch, useZoneBuilderState} from '../../../vss/app/hooks';
import {useConfigurationZones, useSiteManagerDispatch} from '../../../hooks';
import { DialogType, InteractionType, ItemType, SITE_MANAGER_CONFIGURATION_ZONES_SCOPE } from '../../../constants';
import { getOlStoreKey } from '../../../ol/helpers';
import { getItemsBySiteIdMapIdDeviceType } from '../../../services';
import {removeAllInteractionsOnConfigZonesMapOL} from "../../../store/actions";

export function ConfigZonesMap({
    siteId,
    mapId,
    bounds,
    maxZoom,
    validConfiguration,
    updateMapSize,
    zones,
    autoZones,
    devices,
    obstructions,
    mapCoordinates,
    mapExtent,
    measurementSystem,
}) {
    const mapRef = useRef();
    const { selectZones, openDialogZoneGrid } = useZoneBuilderDispatch();
    const {
        addInteractionOnMap,
        cleanAllInteractionsOnMap,
        bulkDeleteItemsByItemTypeItemIds,
        replaceForm,
        openDialog,
        replaceItems,
    } = useSiteManagerDispatch(SITE_MANAGER_CONFIGURATION_ZONES_SCOPE);
    const { selectedGridIds } = useZoneBuilderState();
    const { saveZoneChanges } = useConfigurationZones(siteId, mapId);
    // const { form } = useSiteManager(SITE_MANAGER_CONFIGURATION_ZONES_SCOPE);


    useEffect(() => {
        if (selectedGridIds != null && selectedGridIds.length !== 0) {
            refStore.configZonesMap?.[getOlStoreKey(ItemType.ZONE)]?.clearSelectedItems();
            refStore.configZonesMap?.[getOlStoreKey(ItemType.ZONE)]?.addSelectedItems(selectedGridIds);
        }
    }, [ selectedGridIds ]);
    const handleOnKeyDown = useCallback((e) => {
        if (e.key === 'Escape') {
            if (refStore.configZonesMap?.[getOlStoreKey(ItemType.ZONE)]?.selectedItemIds.length) {
                refStore.configZonesMap?.[getOlStoreKey(ItemType.ZONE)]?.clearSelectedItems();
            } else {
                cleanAllInteractionsOnMap();
            }
        }

        if (e.ctrlKey && e.key === 'c') {
            if (refStore.configZonesMap?.[getOlStoreKey(ItemType.ZONE)]?.selectedItemIds.length) {
                addInteractionOnMap(InteractionType.COPY);
            }
        }

        if (e.ctrlKey && e.key === 'v') {
            if (refStore.configZonesMap?.[getOlStoreKey(ItemType.ZONE)]?.selectedItemIds.length) {
                addInteractionOnMap(InteractionType.PASTE);
            }
        }

        if (e.key === 'Delete') {
            addInteractionOnMap(InteractionType.REMOVE);
        }

        if (e.ctrlKey && e.key === 'z') {
            addInteractionOnMap(InteractionType.UNDO);
        }
        
        if (e.ctrlKey && e.shiftKey && e.key === 'Z') {
            addInteractionOnMap(InteractionType.REDO);
        }
    }, [ addInteractionOnMap, cleanAllInteractionsOnMap ]);
    
    useEffect(() => {
        const onFinishDrawZone = async ({ zones, zone }) => {
            const newZoneName=newDefaultZoneName({mapId});
            zone.id=newZoneName;
            zone.itemType="";
            zone.childrenZones=[];
            zone.name=newZoneName;
            zone.parentId=null;
            zone.type="standard";
            zone.isNew=true;
            // zone.isDraft=false;
            // zone.notSaved=false;
            refStore.configZonesMap?.initializeDraw();
            await saveZoneChanges({
                zonesBeforeChanges:zones,
                zonesAfterChanges:[...zones,zone],
                takeASnapshot: true,
                event: 'onFinishDrawZone',
                selectCreatedZones: true,
            });


        };
        
        const onSaveZoneChanges = async ({
            zonesBeforeChanges,
            zonesAfterChanges,
            takeASnapshot,
            takeASnapshotOnSuccess,
            event,
            selectCreatedZones,
            noDisplayOverlapError,
            noSaveOnService,
            deleteChildren,
        }) => {
            await saveZoneChanges({
                zonesBeforeChanges,
                zonesAfterChanges,
                takeASnapshot,
                takeASnapshotOnSuccess,
                event,
                selectCreatedZones,
                noDisplayOverlapError,
                noSaveOnService,
                deleteChildren,
            });
        };
        
        const onFinishDrawZoneGrid = (payload) => {
            let polygonSet = [];
            payload.features?.forEach((f, index) => {
                const feature = formatAsGeoJSON(f);
                polygonSet.push({ coords: [ feature.geometry?.coordinates[0] ], id: 'Grid_' + index });
            });
            payload.sourceFeatures?.forEach((f, index) => {
                const feature = formatAsGeoJSON(f);
                polygonSet.push({ coords: [ feature.geometry?.coordinates[0] ], id: 'Feature_' + index });
            });
            const arePolygonsColliding =false;// (sweepLineIntersections(polygonSet, true).length) > 0;
            if (!arePolygonsColliding) {
                openDialogZoneGrid(payload);
            } else {
                openDialog({
                    key: DialogType.ERROR,
                    title: 'Error',
                    content: 'Overlap between zones is not allowed',
                });
                refStore.configZonesMap?.removeDrawGrid();
                refStore.configZonesMap?.initializeDrawZoneGrid();
            }
        };
        
        const onPasteZones = async ({ zonesBeforeChanges, zonesAfterChanges, pastedZoneIds }) => {
            const { success } = await saveZoneChanges({
                zonesBeforeChanges,
                zonesAfterChanges,
                takeASnapshot: true,
                event: 'onPasteZones',
                selectCreatedZones: true,
            });
            if (!success) {
                addInteractionOnMap(InteractionType.TRANSLATE);
                refStore.configZonesMap?.[getOlStoreKey(ItemType.ZONE)]?.clearSelectedItems();
                refStore.configZonesMap?.[getOlStoreKey(ItemType.ZONE)]?.addSelectedItems(pastedZoneIds);
            }
        };
        
        const onSelectItem = ({ selectedItemIds }) => {
           selectZones(selectedItemIds);
        };
        
        const onDeleteZones = async ({ takeASnapshot, zonesToDelete, withCleanSelection }) => {
            const zoneIds = zonesToDelete.map(zone => zone.id);
            await bulkDeleteItemsByItemTypeItemIds({
                siteId, mapId: mapId, itemType: ItemType.ZONE,
                itemIds: zoneIds, props: { deleteChildren: true }, takeSnapshotAfterChanges: takeASnapshot,
            });
            if (withCleanSelection) {
                selectZones([]);
            }
        };
        
        const onEditZone = ({ zone }) => {
            replaceForm({ form: { itemType: ItemType.ZONE, itemId: zone, name: zone.name, coordinates: zone.coords, type: zone.type, color: zone.color } });
            openDialog({ key: DialogType.EDIT_ZONE });
        };
        
        const onDeleteZone = ({ zone }) => {
            let hasChildren = false;
            for (const z of refStore.configZonesMap?.zones || []) {
                if (zone.id === z.parentId) {
                    hasChildren = true;
                }
            }
            refStore.configZonesMap?.[getOlStoreKey(ItemType.ZONE)]?.clearSelectedItems();
            refStore.configZonesMap?.[getOlStoreKey(ItemType.ZONE)]?.addSelectedItems([ zone.id ], { forceMultiSelection: true });
            replaceForm({ form: { hasChildren } });
            openDialog({ key: DialogType.DELETE_ZONE });
        };

        const onDrawRandomZones = async ({ zones }) => {
            const {items:zones_} = await getItemsBySiteIdMapIdDeviceType(siteId, mapId, ItemType.ZONE);
            replaceItems(ItemType.ZONE, [...zones_, ...zones],{mapId,/*siteName*/});
        };

        const onShowMessage = async ({ title,message }) => {
            openDialog({
                key: DialogType.MESSAGE,
                title: title,
                content: message,
            });
        };

        if (mapRef.current) {
            const ref = new OlConfigZones(mapRef.current);
            replaceRefConfigZonesMap(ref);
            ref.subscribe('onFinishDrawZone', onFinishDrawZone);
            ref.subscribe('onSaveZoneChanges', onSaveZoneChanges);
            ref.subscribe('onPasteZones', onPasteZones);
            ref.subscribe('onSelectedItemsChanged', onSelectItem);
            ref.subscribe('onFinishDrawZoneGrid', onFinishDrawZoneGrid);
            ref.subscribe('onDeleteZones', onDeleteZones);
            ref.subscribe('onEditZone', onEditZone);
            ref.subscribe('onDeleteZone', onDeleteZone);
            ref.subscribe('onDrawRandomZones', onDrawRandomZones);
            ref.subscribe('onShowMessage', onShowMessage);
        }
        return () => {
            refStore.configZonesMap?.unsubscribe('onFinishDrawZone', onFinishDrawZone);
            refStore.configZonesMap?.unsubscribe('onSaveZoneChanges', onSaveZoneChanges);
            refStore.configZonesMap?.unsubscribe('onPasteZones', onPasteZones);
            refStore.configZonesMap?.unsubscribe('onSelectedItemsChanged', onSelectItem);
            refStore.configZonesMap?.unsubscribe('onFinishDrawZoneGrid', onFinishDrawZoneGrid);
            refStore.configZonesMap?.unsubscribe('onDeleteZones', onDeleteZones);
            refStore.configZonesMap?.unsubscribe('onEditZone', onEditZone);
            refStore.configZonesMap?.unsubscribe('onDeleteZone', onDeleteZone);
            refStore.configZonesMap?.unsubscribe('onDrawRandomZones', onDrawRandomZones);
            removeAllInteractionsOnConfigZonesMapOL();
            refStore.configZonesMap?.removeLayers();
            replaceRefConfigZonesMap(null);
        };
        // eslint-disable-next-line
    }, [ mapId ]);

    refStore.configZonesMap?.setMapCoordinates(mapCoordinates);
    refStore.configZonesMap?.setMapExtent(mapExtent);
    refStore.configZonesMap?.setMeasurementSystem(measurementSystem);
    
    refStore.configZonesMap?.setZones(zones);
    refStore.configZonesMap?.setAutoZones(autoZones);
    //refStore.configZonesMap?.setObstructions(obstructions);
    //refStore.configZonesMap?.setDevices(devices);

    // refStore.configZonesMap?.setContextMenuByItem({
    //     [ItemType.ZONE]: ({ feature }) => [
    //         { classname: feature.get('isCollided') || feature.get('isDraft') || feature.get('notSaved') ? 'marker-disabled' : '', menuType: MenuType.EDIT, text: 'Edit' },
    //         { classname: feature.get('isCollided') || feature.get('isDraft') || feature.get('notSaved') ? 'marker-disabled' : '', menuType: MenuType.DELETE, text: 'Delete' },
    //     ],
    // });

    return (
        <div className="configuration-map" tabIndex={-1} onKeyDown={handleOnKeyDown}>
            <MapXYZ
                ref={mapRef}
                mapDeviceId={mapId}
                bounds={bounds}
                maxZoom={maxZoom}
                showMouseCoordinates={validConfiguration}
                olMousePositionTargetId="ol-mouse-position-config-zones"
                updateMapSize={updateMapSize}
                timestamp={new Date().getTime()}
                isNotMap={mapId == null}
                isNotCoordinates={!validConfiguration}
            />
        </div>
    );
}
