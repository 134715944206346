import React from 'react';
import ZLoginPage from '../../../../common/app/views/login/ZLoginPage';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actions from '../../actions';

import {withRouter} from 'react-router-dom';
import ErrorDialog from "../util/ErrorDialog";

class Login extends React.Component{

    constructor(props){
        super(props);
        this.state={
            dialogOpen:true,
            loginDisabled: props.userLogin
        };
        this.handleLoginEvent = this.handleLoginEvent.bind(this);
        this.redirectToLandingPage = this.redirectToLandingPage.bind(this);
        this.redirectToResourcesPage = this.redirectToResourcesPage.bind(this);
        this.handleDialog=this.handleDialog.bind(this);
        this.redirectBasedOnDevice = this.redirectBasedOnDevice.bind(this);
    }

    static get propTypes(){
        return {
            history: PropTypes.object,
            login: PropTypes.func.isRequired,
            checkLoginStatus: PropTypes.func.isRequired,
            open:PropTypes.bool,
        };
    }
    static get defaultProps(){
        return {
            open: false
        };
    }
    handleDialog(){
        this.setState({dialogOpen:false})
    }

    componentDidMount(){
        const queryString = new URLSearchParams(window.location.search);
        const adfsEnabled = queryString.get('adfs');
        const kongJWT = queryString.get('kong_jwt');
        const username = queryString.get('username');
        const authError = queryString.get('authError')

        if (adfsEnabled && kongJWT && username) {
            // Remove querystring parameters from url
            window.history.pushState({}, document.title, '/');
            this.props.adfsLogin(kongJWT, username, false).then(this.redirectBasedOnDevice);
            return;
        }

        if (authError) {
            this.props.setDisableAutoRedirect(true);
            this.props.errorDialog('Authentication Error', authError);
            window.history.pushState({}, document.title, '/');
            return;
        }
        this.props.checkLoginType();
        document.getElementById("mainAppId").addEventListener("zLogin",this.handleLoginEvent,false);
        this.props.checkLoginStatus().then(this.redirectBasedOnDevice).catch(()=>{});
    }

    componentWillUnmount(){
        document.getElementById("mainAppId").removeEventListener("zLogin",this.handleLoginEvent);
    }

    redirectToLandingPage(){
        this.props.history.push("/landing");
    }

    redirectToResourcesPage(){
        const {userMobile} = this.props;
        //const resources = userMobile;
        if (userMobile.length < 1){
            this.props.history.push("/landing");
        }else{
            //route for resources
            this.props.history.push(userMobile[1].children[0].routes)
        }
    }

    redirectBasedOnDevice() {
        const {isMobileDevice} = this.props;
        if(isMobileDevice) {
            this.redirectToResourcesPage()
        } else {
            this.redirectToLandingPage();
        }
    }

    handleLoginEvent(e){
        let msg = e.detail.message;
        this.props.login(msg.username,msg.password).then(this.redirectBasedOnDevice);
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        if(nextProps.userLogin !== this.props.userLogin){
            this.setState({
                loginDisabled: nextProps.userLogin,
            })
        }
    }

    render(){

        const {isMobileDevice,isPortrait,windowWidth,windowHeight,userMobile} = this.props;
        const zLoginSizeData = {isMobileDevice,isPortrait,windowWidth,windowHeight,userMobile};
        const {loginDisabled} = this.state;
        return (
            <div>
                <ZLoginPage
                    loginDisabled={loginDisabled}
                    zLoginBGUrl={"assets/images/login-background.jpg"}
                    zLoginSizeData={zLoginSizeData}
                    authSettings={this.props.authSettings}
                    disableAutoRedirect={this.props.disableAutoRedirect}
                />
                {this.props.errorDialogOpen && <ErrorDialog
                    open={this.props.errorDialogOpen}
                    title={this.props.errorDialogTitle}
                    message={this.props.errorDialogMessage}
                    onRequestClose={this.props.closeErrorDialog}
                />}
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        login:actions.login,
        adfsLogin: actions.adfsLogin,
        setDisableAutoRedirect: actions.setDisableAutoRedirect,
        checkLoginType: actions.checkLoginType,
        checkLoginStatus:actions.checkLoginStatus,
        errorDialog: actions.errorDialog,
        closeErrorDialog:actions.closeErrorDialog,

    },dispatch)
});

const mapStateToProps = state =>({
    isMobileDevice:state.resize.isMobileDevice,
    isPortrait:state.resize.isPortrait,
    windowWidth:state.resize.windowWidth,
    windowHeight:state.resize.windowHeight,
    userMobile:state.user.menu,
    errorDialogOpen:state.errorDialog.open,
    errorDialogTitle:state.errorDialog.title,
    errorDialogMessage:state.errorDialog.message,
    userLogin:state.user.userLogin,
    authSettings: state.user.authSettings,
    disableAutoRedirect: state.user.disableAutoRedirect,
});

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Login));
