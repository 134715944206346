import React, {/*useCallback*/} from 'react';

export const InputType = {
    DECIMAL:"DECIMAL",
    INTEGER:"INTEGER",
}

const DEFAULT_VALUE = {
    [InputType.INTEGER]: null,
    [InputType.DECIMAL]: null,
}
const MAX_SAFE_INTEGER = Number.MAX_SAFE_INTEGER;
const MIN_SAFE_INTEGER = Number.MIN_SAFE_INTEGER;
const DECIMAL_MIN_VALUE = -Number.MAX_VALUE-1;
const DECIMAL_MAX_VALUE = Number.MAX_VALUE;


export const InputNumber = ({
                                 typeInput, disabled=false, id, name, value, onChange, validations=[],
                                 positiveOnly=null,
                                 negativeOnly=null,
                                 defaultValue=DEFAULT_VALUE[typeInput],
                                 max=null,
                                 min=null,
                                 className=null,
                                 placeholder,
                                 fixedDecimals = null,
                                 size = null,
                             }) => {
    const specialCharacters = ['-','+'];

    function getIsNumberAndZero (changedValue){
        /*return (changedValue||changedValue===0||changedValue==='0')*/
        return (!isNaN(changedValue) && changedValue !=='' && changedValue!==null && changedValue!==undefined)||changedValue===0||changedValue==='0';
    }
    const getValueTypeInput = (newValue,forceParse=false)=>{
        let changedValue;
        switch (typeInput) {
            case InputType.INTEGER:
                const newValueInteger = getIsNumberAndZero(newValue)? +newValue:'';
                if( (Number.isInteger(newValueInteger) && isIntegerRange(newValueInteger))|| newValueInteger ==='' ) {
                    changedValue = newValueInteger;
                }else{
                    changedValue = getIsNumberAndZero(value)?+value:'';
                }

                break;
            case InputType.DECIMAL:
                changedValue = getIsNumberAndZero(newValue)?newValue:'';
                if( typeof  changedValue !== 'number' ) {
                    changedValue = changedValue?changedValue.replace(/^-0+/, '-0'):changedValue;
                    changedValue = changedValue?changedValue.replace(/^0+/, '0'):changedValue;
                    changedValue = forceParse?(getIsNumberAndZero(changedValue)? Number(changedValue):changedValue):changedValue;
                }
                break;
            default:
                changedValue = defaultValue;
        }
        return changedValue;
    }

    const isIntegerRange = (value) =>{
        return (max?value<=max:value<MAX_SAFE_INTEGER ) && (min?value>=min:value>MIN_SAFE_INTEGER)
    }

    const isDecimalRange = (value) =>{
        return (max?value<=max:value<DECIMAL_MAX_VALUE ) && (min?value>=min:value>DECIMAL_MIN_VALUE)
    }

    const validateValue = (newValue) =>{
        if(size != null){
            const value = String(newValue).replace("-", "");
            if(value.length > size){
                return;
            }
        }
        if( (newValue && !specialCharacters.includes(newValue) && !isNaN(newValue)) || newValue === null) {
            let changedValue = getValueTypeInput(newValue);
            if(changedValue){
                let change = false;
                let changePositiveOnly = false;
                let changeNegativeOnly = false;
                if(positiveOnly && (negativeOnly === null || negativeOnly === false) && changedValue>=0 ){
                    change = true;
                    changePositiveOnly = true;
                }
                if((positiveOnly ===null || positiveOnly===false )&& negativeOnly && changedValue<=0 ){
                    change = true;
                    changeNegativeOnly = true;
                }

                if( typeInput === InputType.INTEGER && isIntegerRange(changedValue)){
                    change = true;
                }
                if( typeInput === InputType.DECIMAL && isDecimalRange(+changedValue)){
                    change = true;
                }
                if(change && ((changeNegativeOnly||changePositiveOnly)||(positiveOnly === null && negativeOnly === null )) ){
                    //const error = getError(changedValue);
                    //if(!error){
                        onChange(changedValue, name);
                    /*}else{
                        console.log({error})
                    }*/
                }
            }else{
                onChange(changedValue, name);
            }
        }else if(specialCharacters.includes(newValue)){//-0
            if( !(positiveOnly && (negativeOnly === null || negativeOnly === false) )){
                onChange(newValue, name);
            }else{
                onChange(defaultValue, name);
            }
        }
    }
    const getValueInput = () =>{
        if( !isNaN(value) || specialCharacters.includes(value)){
            let val =  value===null?'':value;
            let valueStr = val.toString();
            if(fixedDecimals != null && valueStr.indexOf('.') > -1){
                let arrAux = valueStr.split('.');
                let strDec = arrAux[1].slice(0,fixedDecimals);
                arrAux[1] = strDec;
                valueStr = arrAux.join('.');
            }
            return valueStr;
            //return getIsNumberAndZero(value)?value:defaultValue;
        }else{
            return defaultValue||''
        }
    }
    const getInputNumber = () => {
        return <input disabled = {disabled}
                      style = {{textAlign: 'center',padding:'0 0 0 0em', background:disabled? '#dedede':''}}
                      className={className?className:null}
                      placeholder={placeholder}
                      id = {id}
                      autoComplete = "off"
                      value = {getValueInput()}
                      name={name}
                      onChange = {({target}) => {
                          const newValue = target.value.trim() !== '' ? target.value : null;
                          validateValue(newValue)
                      }}
                      /*onKeyDownCapture = {(event)=>{
                          const {key} = event;
                          //console.log({key,value})
                      }}
                      onFocus = {(event)=>{
                          //console.log({onFocus:"onFocus",event})
                      }}*/
                      onBlur = {(event)=>{
                          if(specialCharacters.includes(value)) {//-
                              onChange(defaultValue, name);
                          }else{
                              const valueChange = getIsNumberAndZero(value)?getValueTypeInput(value,true):defaultValue;
                              //console.log({value,valueChange,getIs:getIsNumberAndZero(value),getValue:getValueInput(value,true),defaultValue})
                              onChange(valueChange,name)
                          }
                      }}
        />
    }
    const getInput = () =>{
        switch (typeInput) {
            case InputType.INTEGER:
            case InputType.DECIMAL:
                return getInputNumber()
            default:
                return null
        }
    }
    return getInput();
};
