export const REPORTS = 'reports';
export const REPORT = 'report';

export const MESSAGE_GETTING_DATA = 'Getting the data.';
export const MESSAGE_ERROR_RETRIEVING = 'Error when retrieving data.';
export const MESSAGE_ERROR_UNEXPECTED = 'Error unexpected.';
export const MESSAGE_NO_TAGS_SELECTED = 'No tags selected.';
export const MESSAGE_NO_TAGS_FOR_MAP = 'There is no Tag Blink History for this map in selected date range.';
export const MESSAGE_NO_TAGS_FOR_MAP_DEFAULT = 'There is no Tag Blink History for this map in default date range (last 24 hours).';
export const MESSAGE_BAD_CONFIGURATION = 'Invalid map coordinate configuration.';
export const RESOURCE_GROUPING_REPORT = 'resource-grouping';
export const MESSAGE_MAX_LENGTH_REQUEST = 'Max browser url length was reached';
export const MESSAGE_MAX_URL_LENGTH_REACHED_REQUEST = 'Max browser url length was reached, please un-select some items from multiselect filters';

// REPORT KEYS
export const REPORT_ID_RESOURCE_GROUPING = 'resource-grouping';
export const REPORT_ID_RESOURCES = 'resources';
export const REPORT_ID_TAGS = 'tags';
export const REPORT_ID_CALL_HISTORY = 'call.history';
export const REPORT_ID_RESOURCE_EVENT_HISTORY = 'resource-event.history';
export const REPORT_ID_TAG_BLINK_HISTORY = 'tag-blink.history';

export const REPORT_KEY_SYSTEM_ALERT_DATA = 'reports:alerts:open'; // Required
export const REPORT_ID_SYSTEM_ALERT_DATA = 'alerts.open';
export const REPORT_KEY_SYSTEM_ALERT_HISTORY = 'reports:system-alert:history'; // Required
export const REPORT_ID_SYSTEM_ALERT_HISTORY = 'system-alert.history';

export const REPORT_ID_RESOURCE_TYPES = 'resource-types';
export const REPORT_ID_ZONES = 'zones';
export const REPORT_ID_ZONE_GROUPS_CONFIGURATION = 'zone-groups';
export const REPORT_ID_SYSTEM_ALERT_SETTINGS = 'system-alert';
export const REPORT_ID_CONTACTS = 'contacts.config';
export const REPORT_ID_ALERT_NOTIFICATION_CONFIGURATION = 'alerts.notification-configurations';
export const REPORT_ID_HARDWARE_DEVICES = 'infrastructure.hardware-devices';
export const REPORT_ID_WHEREPORTS = 'infrastructure.whereports';
export const REPORT_ID_HISTORICAL_REPORTS = 'historical';

export const REPORT_ID_ZONE_EXPIRATION = 'zone-expiration';
export const REPORT_ID_PATH_CONFIGURATION = 'path-configuration';
export const REPORT_ID_DIRECTION_CONFIGURATION = 'direction-configuration';
//export const REPORT_ID_FIRMWARES = 'device-manager.firmware';
export const REPORT_ID_FIRMWARES = 'firmware';
export const REPORT_ID_CERTIFICATES = 'device-manager.certificates';

export const REPORT_KEY_SEARCH = 'reports:search';
export const REPORT_ID_SEARCH = 'search';

export const COLUMN_KEYS_TO_PERSIST = ['enabled', 'order','sortable'];
export const FILTER_KEYS_TO_PERSIST = ['enabled', 'order', 'value', 'children','rangeMode','sortConfiguration'];

export const CHANGED_MAP = 'CHANGED_MAP';
export const NO_CHANGED_MAP = 'NO_CHANGED_MAP';

export const REPORT_KEY_DEFAULT = 'reports:___default';
export const REPORTS_WITH_DYNAMIC_FORMS = [REPORT_ID_TAGS,REPORT_ID_ZONE_EXPIRATION,REPORT_ID_DIRECTION_CONFIGURATION,REPORT_ID_PATH_CONFIGURATION,REPORT_ID_SYSTEM_ALERT_SETTINGS];
export const REPORTS_WITH_CUSTOM_ATTRIBUTES = [REPORT_ID_RESOURCES,REPORT_ID_CALL_HISTORY,REPORT_ID_TAG_BLINK_HISTORY,REPORT_ID_RESOURCE_EVENT_HISTORY];
export const REPORTS_WITH_MULTIPLE_ROWS = [REPORT_ID_RESOURCES];
export const REPORTS_WITH_FIELD_NAMES = [REPORT_ID_RESOURCES];

export const REPORTS_WITH_PAGINATION = [REPORT_ID_RESOURCES,REPORT_ID_TAGS]

export const SAVE_REPORT_USE_SELECTED_ROWS_AS_FILTER="USE_SELECTED_ROWS_AS_FILTER";
export const SAVE_REPORT_USE_FILTER="USE_FILTER";
export const SAVE_REPORT_MAX_SELECTED_ROWS_AS_FILTER=100;


export const SAVED_REPORT_FILTER_FROM_SELECTED_ROWS = {
    [REPORT_ID_RESOURCES]: {
        filterId: "resourceIds",   // resourceIds is the filter configured in the json configuration for filters
        columnId:"resourceId"
    },
    [REPORT_ID_TAGS]: {
        filterId: "tagIds",   // resourceIds is the filter configured in the json configuration for filters
        columnId:"id"
    }
}