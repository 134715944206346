import React from 'react';
import PropTypes from 'prop-types';

import {VectorLayer} from '../../../../../../common/app/views/map/Layer';
import Feature from '../../../../../../common/app/views/map/Feature';
//openlayers
import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Polygon from 'ol/geom/Polygon';

/**
 * Displays zones on a map.
 */
class ZoneLayer extends React.Component{

    static get propTypes(){
        return {
            zones: PropTypes.arrayOf(
                PropTypes.shape({
                    shape: PropTypes.array,
                    color: PropTypes.arrayOf(
                        PropTypes.number
                    ),
                    name: PropTypes.node
                })
            )
        };
    }

    static get defaultProps(){
        return {
            zones:[]
        };
    }

    /**
     * @private
     */
    render(){
        let _zones =  this.props.zones.map((v,i)=>{
            if(!(v.shape instanceof Array)){ // dunno
            }
            return (
                <Feature
                    key={"zone"+i}
                    data={{index:i,name:v.name,color:v.color,zone:v.name}}
                    geometry={ new Polygon([v.shape]) }
                    style={new Style({
                        fill: new Fill({color:[v.color[0],v.color[1],v.color[2],v.color[3] || 0.7]}),
                        stroke: new Stroke({color:'#000000'})
                    })}
                />
            );
        });
        return (
            <VectorLayer {...this.props}>
                {_zones}
            </VectorLayer>
        );
    }
}

export default ZoneLayer;
