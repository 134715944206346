import Collection from 'ol/Collection';
import VectorSource from 'ol/source/Vector';
import VectorImageLayer from 'ol/layer/VectorImage';
import { getRandomInt } from '../../helpers';
import { getOlStoreKey } from '../helpers';
import { zoneItemToFeature } from './helpers';
import { ItemType } from '../../constants';

const storeZoneKey = getOlStoreKey(ItemType.ZONE);

export function olCopyPasteZonesFactory(Base) {
    class OlCopyPasteZonesFactory extends Base {
        
        #clipboardZones;
        copyCounts;
        
        //sources and layers
        draftSource;
        draftLayer;
        draftFeatures;
        
        constructor(mapRef) {
            super(mapRef);
            this.#clipboardZones = null;
            this.copyCounts = {};
            
            this.draftFeatures = new Collection();
            this.draftSource = new VectorSource({ useSpatialIndex: false, features: this.draftFeatures });
            this.draftLayer = new VectorImageLayer({ source: this.draftSource });
            this.draftLayer.set('name','draftLayer');
            this.mapRef.addLayer(this.draftLayer);
        }
        
        clearZoneDraftFeatures() {
            this.draftFeatures.clear();
        }
        
        getMaxWidthSelectedZone() {
            this.#clipboardZones = [ ...this[storeZoneKey].selectedItemsFeatureCollection?.getArray() ];
            let maxWidth = 0;
            for (const feature of this.#clipboardZones) {
                const featureExtent = feature.getGeometry().getExtent();
                const featureWidth = featureExtent[2] - featureExtent[0];
                
                if (featureWidth > maxWidth) {
                    maxWidth = featureWidth;
                }
            }
            return maxWidth;
        }
        
        drawReplicateZones(params) {
            this.draftFeatures.clear();
            this.copyCounts = {};
            const { xOffset, yOffset, replicas } = params;
            for (const feature of this.#clipboardZones) {
                const zone = this.getZoneData(feature);
                const maxIndexZone = this.#getMaxIndex(zone);
                // making the replicas of a zone
                let counter = 1;
                while (counter <= replicas) {
                    const indexZoneCopy = maxIndexZone + counter;
                    const zoneCopy = this.#getCopyOfZone(zone, { xOffset, yOffset }, indexZoneCopy);
                    this.draftFeatures.push(zoneItemToFeature.bind(this)(zoneCopy, storeZoneKey));
                    counter += 1;
                }
            }
            // const draftFeaturesCoordinates = this.draftFeatures.getArray()
            //     .map(feature => this.getFeatureCoordinates(feature)[0]);
            let valid = true;
            // for (const draftFeaturesCoordinate of draftFeaturesCoordinates) {
            //     for (const [ x, y ] of draftFeaturesCoordinate) {
            //         if (!onRectangle({ x, y }, this.mapCoordinates)) {
            //             valid = false;
            //         }
            //     }
            // }
            this.copyCounts = {};
            return { valid };
        }
        
        handleReplicateZones() {
            let pastedZoneIds = [];
            for (const zoneFeature of this.draftFeatures.getArray()) {
                const zoneId = zoneFeature.values_?.id;
                pastedZoneIds.push(zoneId);
                zoneFeature.set('isDraft', false);
                zoneFeature.set('isNew', true);
                this.featuresCollection.push(zoneFeature);
            }

            this.observer?.executeEvent('onPasteZones', {
                zonesBeforeChanges: this.zones,
                zonesAfterChanges: this.getFeaturesDataArrayByItemType(ItemType.ZONE),
                pastedZoneIds,
            });
            
            this.#clipboardZones = null;
            this.copyCounts = {};
        }
        
        handleCopyFeatures() {
            this.#clipboardZones = [ ...this[storeZoneKey].selectedItemsFeatureCollection?.getArray() ];
        }
        
        handlePasteFeatures() {
            if (this.#clipboardZones) {
                const pastedZoneIds = [];
                for (const feature of this.#clipboardZones) {
                    const zone = this.getZoneData(feature);
                    const offsetZone = this.#calculateZoneOffset(zone);
                    const maxIndexZone = this.#getMaxIndex(zone);
                    const indexZoneCopy = maxIndexZone + 1;
                    const zoneCopy = this.#getCopyOfZone(zone, offsetZone, indexZoneCopy);
                    zoneCopy.isDraft=false;
                    zoneCopy.isNew=true;
                    this.featuresCollection.push(zoneItemToFeature.bind(this)(zoneCopy, storeZoneKey));
                    pastedZoneIds.push(zoneCopy.id);
                }
                this.observer?.executeEvent('onPasteZones', {
                    zonesBeforeChanges: this.zones,
                    zonesAfterChanges: this.getFeaturesDataArrayByItemType(ItemType.ZONE),
                    pastedZoneIds,
                });
            }
        }
        
        #getCopyOfZone = (zone, offset, indexZone) => {
            const { xOffset, yOffset } = offset;
            const originalName = zone.name;
            if (!this.copyCounts[originalName]) {
                this.copyCounts[originalName] = 0;
            }
            const copyCount = ++this.copyCounts[originalName];
            return {
                ...zone,
                name: `${zone.name}_${indexZone}`,
                // id: `${zone.id}_${indexZone}`,
                id: getRandomInt(),
                isDraft: true,
                childrenZones: undefined,
                parentId: null,
                coords: [ ...zone.coords ].map(coor => ([ coor[0] + xOffset * copyCount, coor[1] + yOffset * copyCount ])),
            };
        };
        
        #getMaxIndex = (zone) => {
            // searching a new pasted index
            const originalName = zone.name;
            const maxIndex = this.zones.reduce((acc, z) => {
                const prefix = `${originalName}_`;
                if (z.name.startsWith(prefix)) {
                    const max = +this.#afterCharacter(z.name, prefix);
                    return max > acc ? max : acc;
                } else {
                    return acc;
                }
            }, 0);
            
            return maxIndex;
        };
        
        #afterCharacter = (string, char) => string.substr(string.indexOf(char) + char.length);
        
        #calculateZoneOffset = zone => {
            // calculating the offset of the zone considering his extent
            const [ mapPoint1X, mapPoint1Y, mapPoint2X, mapPoint2Y ] = zone.extent;
            const offset = Math.max((mapPoint2X - mapPoint1X), (mapPoint2Y - mapPoint1Y)) * 0.05;
            return { xOffset: offset, yOffset: offset };
        };
        
    }
    
    return OlCopyPasteZonesFactory;
}
