import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {requestZones} from '../../../actions';

function getDisplayName(WrappedComponent){
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}


const WrappedComponent = () => {
    class DumbishWrappedComponent extends React.Component{

        static get propTypes(){
            return {
                requestZones:PropTypes.func.isRequired,
                siteId: PropTypes.any
            };
        }

        static get displayName(){
            return `ZoneKnowledgable(${getDisplayName(WrappedComponent)})`;
        }

        getWrappedInstance(){
            return this.refs.wrapped;
        }

        constructor(){
            super();
            this.state = {
                zones:[]
            };
        }

        componentDidMount(){
            this.refreshZones(this.props.siteId);
        }

        componentWillUnmount(){
            this.unmounted = true;
        }

        UNSAFE_componentWillReceiveProps(nextProps){
            if(this.props.siteId !== nextProps.siteId){
                this.refreshZones(nextProps.siteId);
            }
        }

        shouldComponentUpdate(nextProps, nextState){
            return nextProps !== this.props || nextState !== this.state;
        }

        refreshZones(siteId){
            if(siteId === undefined) return;

            this.props.requestZones(siteId)
            .then((zones)=>{
                if(this.unmounted) return; // workaround
                this.setState({
                    zones: this.interpretZonesFromDefinition(zones)
                });
            });
        }

        interpretZonesFromDefinition(zones){
            return zones.map(zone=>({
                name: zone.name,
                color: zone.color && this.hexColorToRGBArray(zone.color),
                shape: zone.shapeTrue && this.interpretShapeString(zone.shapeTrue),
                map: zone.mapId,
                raw: zone,
                abbr: zone.abbr,
                locationTypeId: zone.locationTypeId,
                layerPriority: zone.layerPriority
            }));
        }

        hexColorToRGBArray(hex){
            return [0,2,4].map(i=>parseInt(hex.slice(i,i+2),16));
        }

        interpretShapeString(shapeString){
            let delimited = shapeString.split(';');
            let n = parseInt(delimited[0]);
            let shape = [];
            for(let i = 1; i <= 2*n; i+=2){
                shape.push([ parseFloat(delimited[i]), parseFloat(delimited[i+1]) ]);
            }
            shape.push([].concat(shape[0]));
            return shape;
        }

        render(){
            return (
                <WrappedComponent {...this.props} zones={this.state.zones} ref="wrapped"/>
            );
        }
    }

    return connect(
        null,
        dispatch => ({
            ...bindActionCreators({
                requestZones
            },dispatch)
        })
    )(DumbishWrappedComponent);
};

export default WrappedComponent;
