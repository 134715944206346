import React from 'react';
import PropTypes from 'prop-types';
import {AutoSizer, Grid} from 'react-virtualized';
import cn from 'classnames';

class TableHeader extends React.Component {

    constructor (props) {
        super(props);

        const {columns,filterRenderer} = this.props;

        this.state = {
            columnCount: columns.length + 2 ,
            overscanColumnCount: 2,
            overscanRowCount: 0,
            rowHeight: 56,
            rowCount: (filterRenderer ? 2 : 1),
            selectAllRow:(filterRenderer ? 1 : 0),
        };

        // Public Methods ------------------------------------------------------
        this.forceGridUpdate = this.forceGridUpdate.bind(this);

        // Data Providers ------------------------------------------------------
        this.getColumnWidths = this.getColumnWidths.bind(this);
        this.getWidthOfFirstXColumns = this.getWidthOfFirstXColumns.bind(this);
        this._getDatum = this._getDatum.bind(this);

        // Renderers -----------------------------------------------------------
        this._fixedCellRenderer = this._fixedCellRenderer.bind(this);
        this._scrollableCellRenderer = this._scrollableCellRenderer.bind(this);

        //this._cellRenderer = this._cellRenderer.bind(this);
        this._noContentRenderer = this._noContentRenderer.bind(this);

        // this._renderColumnHeader = this._renderColumnHeader.bind(this);
        // this._renderColumnFilter = this._renderColumnFilter.bind(this);
        // this._renderSelectAll = this._renderSelectAll.bind(this);

        // Handlers ------------------------------------------------------------
        // this.handleColumnHeaderTap = this.handleColumnHeaderTap.bind(this);
    }

    static get propTypes(){
        return {
            columns: PropTypes.array.isRequired,
            scrollLeft: PropTypes.any,
            getColumnWidth: PropTypes.func.isRequired,
            fixedBoxShadow: PropTypes.string,
            fixedColumnCount: PropTypes.number,
            filterRenderer: PropTypes.func,
            theme: PropTypes.object,
            onSelectAll: PropTypes.func,
            sortIndex: PropTypes.number,
            sortDirection: PropTypes.number,
            onHeaderTap: PropTypes.func,
            getRowHeight: PropTypes.func,
            getHeight: PropTypes.func,
            isAllSelected:PropTypes.bool,
            onResizeColumn: PropTypes.func,
            onRequestAutoSize: PropTypes.func,
            sidebarFilterStatus: PropTypes.object,
            cellRenderer:PropTypes.func,
        };
    }

    static get defaultProps(){
        const nop = function(){};
        return {
            fixedColumnCount: 1,
            onSelectAll: nop,
            sortIndex: -1,
            sortDirection: 1,
            onHeaderTap: nop,
            isAllSelected: false,
            onResizeColumn: nop,
            onRequestAutoSize: nop,
            sidebarFilterStatus: {
                leftSidebarEnabled: false,
                leftSidebarCollapsed: false,
                rightSidebarEnabled: false,
                rightSidebarCollapsed: false,
            }
        };
    }

    UNSAFE_componentWillReceiveProps(np){
        const {columns} = np;

        this.setState({
            columnCount: columns.length + 2
        });
    }


    shouldComponentUpdate(np){
        return (this.props.fixedBoxShadow !== np.fixedBoxShadow) ||
                (this.props.columns !== np.columns) ||
                (this.props.scrollLeft !== np.scrollLeft) ||
                (this.props.theme !== np.theme);
    }

    componentDidUpdate(){
        this.Grid.forceUpdate();
    }

    // PUBLIC METHODS ----------------------------------------------------------

    forceGridUpdate(){
        this.Grid.recomputeGridSize();
        this.refs.selectAll.recomputeGridSize();
        this.Grid.forceUpdate();
        this.refs.selectAll.forceUpdate();
    }


    // DATA PROVIDERS ----------------------------------------------------------
    /*
    getColumnWidths({index}){
        if(index === 0) return 75;
        return this.props.getColumnWidth({index:index-1});
    }
    */
    getColumnWidths({index}){
        if(index < 1) return this.props.backgroundRow ? 8 : 75;
        else return this.props.getColumnWidth({index:index-1});
    }


    getWidthOfFirstXColumns(x){
        let s = 0,i;
        for(i = 0; i < x; i++){
            s += this.getColumnWidths({index:i});
        }
        return s;
    }


    _getDatum (index) {
        const { columns } = this.props;

        return columns[index];
    }

    // RENDERERS ---------------------------------------------------------------
    _fixedCellRenderer({columnIndex,rowIndex,key,style}){
        if(columnIndex >= this.state.fixedColumnCount) return;
        return this.props.cellRenderer({columnIndex,rowIndex,key,style});
    }

    _scrollableCellRenderer({columnIndex,rowIndex,key,style}){
        if(columnIndex < this.state.fixedColumnCount) return;
        return this.props.cellRenderer({columnIndex,rowIndex,key,style});
    }

    _noContentRenderer () {
        return (
            <div>
                No Results
            </div>
        );
    }

    isSidebarCollapsed() {
        const sidebarFilterStatus = this.props.sidebarFilterStatus;
        return sidebarFilterStatus
            && sidebarFilterStatus.leftSidebarEnabled
            && sidebarFilterStatus.leftSidebarCollapsed;
    }






    // MAIN RENDER -------------------------------------------------------------

    render () {
        const {
            columnCount,
            overscanColumnCount,
            overscanRowCount,
            rowCount
        } = this.state;

        const {
            fixedBoxShadow,
            scrollLeft,
            fixedColumnCount,
            filterRenderer,
            getRowHeight,
            getHeight,
            // sidebarFilterStatus,
            // _fixedCellRenderer,
            // _scrollableCellRenderer,
        } = this.props;

        const addAutoSizeRef = (ref) => {
            if(ref){
                this.Grid = ref.refs.grid;
            }
        };

        const _getRowHeight = ({index}) =>{
            return (filterRenderer && index)? 56 : 40;
        };

        const _getHeight = ()=>{
            return filterRenderer ? 96 : 40;
        };

        const className = cn("table-header","filled-up",{
            "with-filters":(filterRenderer)
        });

        const height = (getHeight || _getHeight)();

        return (
            <div className={className}>
                <div className="select-all-grid-container">
                    <Grid
                        ref="selectAll"
                        cellRenderer={this._fixedCellRenderer}
                        columnWidth={this.getColumnWidths}
                        columnCount={fixedColumnCount}
                        height={height}
                        noContentRenderer={this._noContentRenderer}
                        overscanColumnCount={overscanColumnCount}
                        overscanRowCount={overscanRowCount}
                        rowHeight={getRowHeight || _getRowHeight}
                        rowCount={rowCount}
                        width={this.getWidthOfFirstXColumns(fixedColumnCount)}
                        className="report-table-header fixed-columns"
                        style={{boxShadow:fixedBoxShadow,overflowX:'hidden', overflowY:'hidden'}}
                    />
                </div>
                <AutoSizer ref={addAutoSizeRef} disableHeight>
                    {({ width }) => (
                        <Grid
                            ref="grid"
                            cellRenderer={this._scrollableCellRenderer}
                            columnWidth={this.getColumnWidths}
                            columnCount={columnCount}
                            height={height}
                            noContentRenderer={this._noContentRenderer}
                            overscanColumnCount={overscanColumnCount}
                            overscanRowCount={overscanRowCount}
                            rowHeight={getRowHeight || _getRowHeight}
                            rowCount={rowCount}
                            scrollLeft={scrollLeft}
                            width={width}
                            className="report-table-header"
                            style={{overflowX:'hidden', overflowY:'hidden', position: 'absolute'}}
                        />
                    )}
                </AutoSizer>
            </div>
        );
    }

}

export default TableHeader;
