import React from 'react';
import * as actions from "../../../actions";
import {connect} from "react-redux";
import {bindActionCreators} from 'redux';
import CloseIcon from '@material-ui/icons/Close';
import NextIcon from '@material-ui/icons/ArrowForward';
import PrevIcon from '@material-ui/icons/ArrowBack';
import {getLocalTimestamp} from "../../../utils/LocaleTimestamp";
import Resizer from './Resizer';
import CustomIconButton from "../../util/button/IconButton";
import {generateComponentId} from "../../../utils/ComponentUtils";


class DetailsSidebar extends React.Component{

    constructor(props){
        super(props);

        // Key for unique identifier in all data.
        this.uidKey='___uid';
        this.indexKey='___index';

        this.previousItem=this.previousItem.bind(this);
        this.nextItem=this.nextItem.bind(this);
        this.close=this.close.bind(this);
    }

    //region Ui events
    previousItem(prevItem){

        if(prevItem!=null)
            this.props.actions.changeSelectedItem(prevItem);
    }

    nextItem(nextItem){

        if(nextItem!=null)
            this.props.actions.changeSelectedItem(nextItem);

    }

    close(){
        this.props.actions.toggleRightSidebar();
        this.props.onClose && this.props.onClose();
    }

    // Find the next and the prev item, null if does not exist.
    prevNextItem(currentItem, selected) {

        let prevNextItems = {};

        if (currentItem != null && selected != null) {
            //const uid = currentItem[this.uidKey];
            const index = currentItem[this.indexKey];

            const uidArray = Object.keys(selected);

            let next = null;
            let prev = null;
            let prevItemUid = null;
            let nextItemUid = null;
            let itemIndexes = [];

            uidArray.forEach((key, i) => {
                const item = selected[key];
                const itemIndex = item[this.indexKey];
                if (itemIndex < index && (prev == null || (index - itemIndex) < (index - prev))) {
                    prev = itemIndex;
                    prevItemUid = item[this.uidKey];
                }
                if (itemIndex > index && (next == null || (index - itemIndex) > (index - next))) {
                    next = itemIndex;
                    nextItemUid = item[this.uidKey];
                }
                itemIndexes.push(item[this.indexKey]);
            });

            prevNextItems.actualPosition = itemIndexes.sort((a, b) => a - b).indexOf(index) + 1;
            prevNextItems.prev = prevItemUid;
            prevNextItems.next = nextItemUid;
        }
        return prevNextItems;
    }
    //endregion

    //region Renders

    // Single item render.
    renderItems(){

        const {selected,detailsItem,columnMeta,columnOrder}=this.props;
        let currentUId=detailsItem;

        // Init values.
        let order = columnOrder || [];
        let info = columnMeta || {};

        // Adds info (display name) to visible columns array.
        let visibleColumns = order.map(col => ({...col, ...info[col.name]}));

        // Remove disabled columns.
        visibleColumns = visibleColumns.filter((v) => v.enabled);

        // Only if selected item exists.
        if(selected!=null&&detailsItem!=null&&Object.keys(selected).length>0&&selected.hasOwnProperty(currentUId)){

            // Get the last selected item.
            const actualItem=selected[currentUId];

            // Add value of columns.
            visibleColumns = visibleColumns.map(col => ({...col, ...{value:actualItem[col.name]}}));

            // Render columns.
            return visibleColumns.map((property, index) => {

                const displayName=property['displayName'];

                let value=property['value'];

                if(property['type']==='DATETIME'&&value)
                    value=getLocalTimestamp(new Date(value));

                return (<div key={index} className={'item'}>
                            <div className={'item-name'}>{displayName}</div>
                            <div  className={'item-value'}>{value}</div>
                        </div>)});
        }

        return "";

    }

    // Main render.
    render(){

        const {selected, detailsItem, animationEnabled}=this.props;
        const countItems=selected!=null&&Object.keys(selected).length;
        const width = this.props.showRightSidebar ? this.props.width : 0;

        let detailsSidebarClassName = 'details-sidebar-container-wrapper';
        if (animationEnabled) {
            detailsSidebarClassName += ' animation';
        }

        let containerResponsive="item-container";
        if(width<300)
            containerResponsive="item-container-responsive";

        // Find next and prev items.
        const prevNextItems=this.prevNextItem(selected[detailsItem],selected);

        return (
            <Resizer
                className={detailsSidebarClassName}
                width={width}
                handleResize={this.props.handleResize}
                resizing={this.props.resizing}
                collapsed={!this.props.showRightSidebar}
                separatorPosition="left"
                identifier={this.props.identifier}
                onTransitionEnd={()=>{this.props.onTransitionEnd()}}
                isMobile={this.props.isMobile}
            >

            <div className={'details-sidebar-container'}>
                <div className={'rounded'}>
                <div className={'toolsSidebar'}>
                    <div id={generateComponentId("details sidebar close", null, "button")} onClick={this.close} className={'closeButton'}><CloseIcon/></div>
                    <div className={'infoNroItems'}>{prevNextItems.actualPosition} of {countItems}</div>
                    <div  className={'previous'}>
                        <CustomIconButton onClick={()=>this.previousItem(prevNextItems.prev)}
                                          icon={<PrevIcon/>}
                                          enabled={!!prevNextItems.prev}
                                          idButton={generateComponentId("details sidebar prev", null, "button")}
                        />
                    </div>
                    <div className={'next'}>
                        <CustomIconButton onClick={()=>this.previousItem(prevNextItems.next)}
                                          icon={<NextIcon/>}
                                          enabled={!!prevNextItems.next}
                                          idButton={generateComponentId("details sidebar next", null, "button")}
                        />
                    </div>
                    <div style={{clear: 'both'}}></div>
                </div>
                <div className={containerResponsive}>
                    {(countItems >= 1)?this.renderItems():<div>Please select a row</div>}
                </div>
                </div>
            </div>
            </Resizer>
        );
    }
    //endregion
}

//region Redux props and actions
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        toggleRightSidebar:actions.toggleRightSidebar,
        changeSelectedItem: actions.changeSelectedItem,
    },dispatch)
});

const mapStateToProps = state =>({

    columnMeta: state.report.columnMeta && state.report.columnMeta[state.report.reportId],
    columnOrder: state.report.columnOrder[state.report.reportId],
    data: state.report.data,
    clickedItem: state.report.clickedItem,
    reportId: state.report.reportId,
    selected:state.report.selected,
    showRightSidebar:state.report.showRightSidebar,
    detailsItem:state.report.detailsItem,
    isMobile:state.resize.isMobile,

});
//endregion

export default connect(mapStateToProps,mapDispatchToProps)(DetailsSidebar);
