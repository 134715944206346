// Sizes are programmed in both javascript and styles
// check common/sass/variables.scss file as well

export const XS = 870; // <=
export const SM = 870; // >=
export const TS = 980; // >=
export const MD = 1024; // >=
export const LG = 1200; // >=


export function getSidebarSizes() {
    const widths = {
        defaultWidth: 0,
        maxWidth: 0,
        minWidth: 0,
    };
    if (window.innerWidth <= XS) {
        widths.defaultWidth = 175;
        widths.maxWidth = 175;
        widths.minWidth = 150;
    } else if (window.innerWidth >= SM && window.innerWidth <= MD) {
        widths.defaultWidth = 250;
        widths.maxWidth = 300;
        widths.minWidth = 200;
    } else if (window.innerWidth >= MD) {
        widths.defaultWidth = 275; //275
        widths.maxWidth = 460;
        widths.minWidth = 160; //210
    }

    return widths;
}
