import Input from "../../../vss/app/views/util/Input";

export default function GridAutonamesComponent({ autoname, setAutoname }) {
  const handleAutonameInputChange = ({ target }) => {
    setAutoname(target.value);
  }

  const getAutoname = label => autoname + label;

  return (
    <div className="grid-autonames">
      <div>
        <p style={{ fontSize: 14 }}>
          Add a label that will be applied on each of the grid zones.
      </p>
        <Input label="Autonames Label" value={autoname} onChange={handleAutonameInputChange} />
      </div>

      <div className="grid-elements">
        <h4>Autonames Preview</h4>
        <p style={{ fontSize: 14, marginTop: 5 }}>
          This is an example of how the autonames would look in a 3x3 grid.
        </p>

        <table>
          <tbody>
            <tr>
              <td>{getAutoname("1_1")}</td>
              <td>{getAutoname("1_2")}</td>
              <td>{getAutoname("1_3")}</td>
            </tr>
            <tr>
              <td>{getAutoname("2_1")}</td>
              <td>{getAutoname("2_2")}</td>
              <td>{getAutoname("2_3")}</td>
            </tr>
            <tr>
              <td>{getAutoname("3_1")}</td>
              <td>{getAutoname("3_2")}</td>
              <td>{getAutoname("3_3")}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}