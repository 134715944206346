const initialState = {
    /*"reports:tags":{
        add:{}
    }*/
}

export const formOnePlatform = (state = initialState, action) => {
    // const newState = {...state};
    switch (action.type) {
        case "FORM_ONE_PLATFORM_LOAD":
            return {
                ...state,
                [action.nameFile]: action.values,
                [action.nameFile]:{...state[action.nameFile], add: action.values}
            };

        case "UPDATE_FIELD_FORM_VALUE":
            let updateFieldValue={...state};
            updateFieldValue[action.reportKey].add[action.IdSection].inputFields[action.IdField].value=action.newValue;
            return {
                ...state,
                // formFieldValues:{...state.formFieldValues,[action.IdField]:action.newValue}
                // add:{...state.tags.add[action.IdSection].inputFields[action.IdField], value:action.newValue}
                // tags:{...state.tags, {'add': }, value:action.newValue}
                ...updateFieldValue
            };
        case "MODE_DIALOG_FORM":
            return {
                ...state,
                statusModalFormAddEdit: action.statusModalFormAddEdit,
                modeDialog:action.modeDialog
                // selectTag:action.selectTag?action.selectTag:null
            };
        case "VIEW_MODAL_FORM_OPTION":
            return {
                ...state,
                statusModalFormAddEdit:action.statusModalFormAddEdit
            };
        case "MODE_DIALOG_ERROR_FORM": {
            return {
                ...state,
                statusModalError: action.statusModalError,
                modeDialog:action.modeDialog,
                error:action.error
            };
        }
        default:
            return state;
    }
}
