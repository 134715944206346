import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import MapOSM from './MapOSM';
import { changeRefMap } from '../../actions';
import FeaturesMapOSM from './FeaturesMapOSM';
import { useReportState, useSitesDispatch, useSitesState } from '../../hooks';

const WorldMapContainer = (props) => {
    const { coordinates = [0, 0], onCLickSite, reportKey, resizeTrigger } = props;
    const dispatch = useDispatch();
    const mapRefOSM = useRef();
    const { bounds, selectedSites } = useReportState(reportKey);
    const { sites, siteManagerSiteHover } = useSitesState();
    const [featuresMapOSM, setFeaturesMapOSM] = useState(null);
    const { changeSiteManagerSiteSelected } = useSitesDispatch();

    const drawPinSites = useCallback(() => {
        if (featuresMapOSM) {
            featuresMapOSM.setSelectedSites(selectedSites);
            featuresMapOSM.removeOldLayer();
            featuresMapOSM.loadPins(sites);
            featuresMapOSM.highlightSelectedSites();
        }
    }, [featuresMapOSM, selectedSites, sites]);

    useEffect(drawPinSites, [drawPinSites]);

    const hoverSite = useCallback(() => {
        if(featuresMapOSM){
            featuresMapOSM.setHoverUID(siteManagerSiteHover);
            featuresMapOSM.hoverSite();
        }
    }, [featuresMapOSM, siteManagerSiteHover]);

    useEffect(hoverSite, [hoverSite]);

    useEffect(() => {
        if (mapRefOSM.current) {
            const updateSiteManagerSiteSelected = (siteId) => {
                changeSiteManagerSiteSelected(siteId);
            };
            setFeaturesMapOSM(new FeaturesMapOSM(mapRefOSM.current, null, updateSiteManagerSiteSelected));
        }
    }, [changeSiteManagerSiteSelected]);
    useEffect(() => {
        if (featuresMapOSM) {
            featuresMapOSM.onClickSite = (siteId, siteName) => {
                onCLickSite?.(siteId, siteName);
            };
        }
    }, [featuresMapOSM, onCLickSite]);

    const handleFinishLoadMapOSM = useCallback(() => {
        drawPinSites();
        dispatch(changeRefMap(mapRefOSM.current));
    }, [drawPinSites, dispatch]);

    // Render self hosted world map.

    useEffect(() => {
        mapRefOSM.current.updateSize();
    }, [resizeTrigger]);

    return (
        <MapOSM
            ref={mapRefOSM}
            coordinates={coordinates}
            onFinishLoadMap={handleFinishLoadMapOSM}
            updateMapSize={bounds.mapContainer}
        />
    );
};

export default React.memo(WorldMapContainer, ((prevProps, nextProps) => {
    return (prevProps.coordinates === nextProps.coordinates &&
        prevProps.onCLickSite === nextProps.onCLickSite &&
        prevProps.reportKey === nextProps.reportKey &&
        prevProps.resizeTrigger === nextProps.resizeTrigger);
}));

// export default WorldLocalMap;
