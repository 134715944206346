import { 
  getPointsAngle, 
  getDiffBetweenTwoPoints, 
  getDotProduct, 
  getVectorDistance, 
  getOrientation, 
  POLYGON_VERTICES,
  SPACE_BETWEEN_ZONES,
  getGridMatrixOrdered } from "./index";

// All of this functionality was based on the C++ code for Zone Builder.
class GridAreaClass {
  constructor(outlineArea, gridMatrix = []) {
    this.outlineArea = outlineArea;
    this.gridMatrix = getGridMatrixOrdered(outlineArea, gridMatrix);
  }

  /**
   * Create outline area from common area with no rotation
   * @param {Array} area 
   */
  static fromArea(area) {
    const [p1, p2, p3] = area;
    const { angle: rotation } = getPointsAngle(p1, p2);

    const A = getDiffBetweenTwoPoints(p1, p2);
    const B = getDiffBetweenTwoPoints(p1, p3);

    const x = getDotProduct(A, B) / getDotProduct(A, A);
    const t = [A[0] * x, A[1] * x];
    const P = [p1[0] + t[0], p1[1] + t[1]];
    const C = getDiffBetweenTwoPoints(P, p3);

    const distance = getVectorDistance(C);
    const orientation = getOrientation(p1, p2, p3);

    const angle = rotation + (2 * Math.PI) / POLYGON_VERTICES;
    const offsetX = orientation * distance * Math.cos(angle);
    const offsetY = orientation * distance * Math.sin(angle);

    const rectCoord3 = [p1[0] + offsetX + A[0], p1[1] + A[1] + offsetY];
    const rectCoord4 = [p1[0] + offsetX, p1[1] + offsetY];

    const gridArea = new GridAreaClass([
      p1,
      p2,
      rectCoord3,
      rectCoord4,
      p1
    ]);

    return gridArea;
  }

  getQuadPolygonMethod() {

    const [pt1, pt2,, pt4] = this.outlineArea;

    let [m_dX_SteepSide, m_dY_SteepSide] = getDiffBetweenTwoPoints(pt1, pt2);
    let [m_dX_OtherSide, m_dY_OtherSide] = getDiffBetweenTwoPoints(pt1, pt4);

    let m_theta_SteepSide = Math.abs(Math.atan2(m_dY_SteepSide, m_dX_SteepSide));
    let m_theta_OtherSide = Math.abs(Math.atan2(m_dY_OtherSide, m_dX_OtherSide));
  
    let m_len_SteepSide = getVectorDistance([m_dX_SteepSide, m_dY_SteepSide]);
    let m_len_OtherSide = getVectorDistance([m_dX_OtherSide, m_dY_OtherSide]);
  
    const swap = m_theta_SteepSide < m_theta_OtherSide;
  
    if (swap) {
      [m_theta_SteepSide, m_theta_OtherSide] = [m_theta_OtherSide, m_theta_SteepSide];
      [m_dX_SteepSide, m_dX_OtherSide] = [m_dX_OtherSide, m_dX_SteepSide];
      [m_dY_SteepSide, m_dY_OtherSide] = [m_dY_OtherSide, m_dY_SteepSide];
      [m_len_SteepSide, m_len_OtherSide] = [m_len_OtherSide, m_len_SteepSide];
    }
  
    const m_SteepSideVertex = swap ? pt4 : pt2;
    const m_OtherSideVertex = swap ? pt2 : pt4;
  
    const m_sign_dXSteepSide = (m_dX_SteepSide < 0) ? -1 : 1;
    const m_sign_dYSteepSide = (m_dY_SteepSide < 0) ? -1 : 1;
    const m_sign_dXOtherSide = (m_dX_OtherSide < 0) ? -1 : 1;
    const m_sign_dYOtherSide = (m_dY_OtherSide < 0) ? -1 : 1;
  
    return {
      m_theta_SteepSide,
      m_theta_OtherSide,
      m_sign_dXSteepSide,
      m_sign_dYSteepSide,
      m_sign_dXOtherSide,
      m_sign_dYOtherSide,
      m_SteepSideVertex,
      m_OtherSideVertex,
      m_len_OtherSide,
      m_len_SteepSide
    };
  }

  getGridIncrementsMethod() {
    const {
      m_theta_OtherSide,
      m_theta_SteepSide,
      m_sign_dXOtherSide,
      m_sign_dXSteepSide,
      m_sign_dYOtherSide,
      m_sign_dYSteepSide,
    } = this.getQuadPolygonMethod();
  
    const [gridWidth, gridHeight] = this.getGridDimensions();
  
    const xIncr_cols = m_sign_dXOtherSide * gridWidth * Math.abs(Math.cos(m_theta_OtherSide));
    const yIncr_cols = m_sign_dYOtherSide * gridWidth * Math.abs(Math.sin(m_theta_OtherSide));
    const xIncr_rows = m_sign_dXSteepSide * gridHeight * Math.abs(Math.cos(m_theta_SteepSide));
    const yIncr_rows = m_sign_dYSteepSide * gridHeight * Math.abs(Math.sin(m_theta_SteepSide));
  
    return {
      xIncr_cols,
      yIncr_cols,
      xIncr_rows,
      yIncr_rows
    }
  }

  makePolygonColumnWise(startPoint) {
    const [x, y] = startPoint;
    const newStartPoint = [x, y-SPACE_BETWEEN_ZONES];
    const { xIncr_cols, yIncr_cols, xIncr_rows, yIncr_rows } = this.getGridIncrementsMethod();
    return [
      newStartPoint,
      [x + xIncr_cols - SPACE_BETWEEN_ZONES, y + yIncr_cols - SPACE_BETWEEN_ZONES],
      [x + xIncr_rows + xIncr_cols - SPACE_BETWEEN_ZONES, y + yIncr_rows + yIncr_cols + SPACE_BETWEEN_ZONES],
      [x + xIncr_rows, y + yIncr_rows + SPACE_BETWEEN_ZONES],
      newStartPoint
    ]
  }

  getGridDimensions() {
    const [numRows, numCols] = this.gridMatrix;
    const { m_len_OtherSide, m_len_SteepSide } = this.getQuadPolygonMethod();
    return [m_len_OtherSide / numCols, m_len_SteepSide / numRows]
  }
}

export default GridAreaClass;