import React from 'react';
import PropTypes from 'prop-types';
import {ListItemIcon, Popover} from '@material-ui/core';
//import Menu from '@material-ui/core/Menu';
import {MenuItem} from '@material-ui/core/es/index';
import AddIcon from '@material-ui/icons/Add';
import ToolbarButton from '../../icons/ToolbarButton';
import ClickAwayListener from "@material-ui/core/es/ClickAwayListener/ClickAwayListener";


// const styles={
//     iconButton:{
//         margin:'0 0.5em',
//         minWidth:'auto',
//         padding:'0 0.5em'
//     },
//
//     iconButtonText:{
//         verticalAlign:'middle',
//         display:'inline-block',
//         fontSize:'0.95em',
//         lineHeight:'24px',
//         marginLeft:'0.1em',
//         marginRight:'0.1em'
//     }
// };


class DataEntryDropDown extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            open: false,
        };

        this.handleTouchTap = this.handleTouchTap.bind(this);
        this.handleRequestClose = this.handleRequestClose.bind(this);
    }

    static get propTypes(){
        return {
            label: PropTypes.string,
            icon: PropTypes.node,
            idButton: PropTypes.string,
            items: PropTypes.arrayOf(
                PropTypes.shape({
                    text: PropTypes.string.isRequired,
                    action: PropTypes.func.isRequired
                })
            )
        };
    }

    static get defaultProps(){
        return {
            label:"Add",
            icon: <AddIcon />,
            idButton: "addBtn",
            items:[]
        };
    }

    handleTouchTap(event){
        // This prevents ghost click.
        event.preventDefault();

        this.setState({
            open: true,
            anchorEl: event.currentTarget,
        });
    }

    handleRequestClose(){
        this.setState({
            open: false,
        });
    }

    renderButton(props){

        const {idButton, type, isMobile, enabled} = props;
        return (
            <ToolbarButton id={idButton} isMobile={isMobile} iconType={type} click={this.handleTouchTap} enabled={enabled}/>
        );
    }


    render(){

        const { label, items, icon, idButton, isMobile, isPortrait, windowWidth,enabled } = this.props;

        let itemIcon = React.cloneElement(icon,{
            style:{margin:'12px'}
        });
        const type = this.props.type;
        let _items = items.map((v,i)=>{
            return (
                <MenuItem
                    key={i}
                    style={{paddingLeft: '0px', minHeight: '36px', height: '36px', fontSize: '0.9em'}}
                    onClick={() => {
                        v.action();
                        this.handleRequestClose();
                    }}
                    //disableTypography
                >
                    <ListItemIcon className={"navbar-menu-icon"}>{itemIcon}</ListItemIcon>
                    {v.text}
                </MenuItem>
            );
        });


        let buttonIcon = React.cloneElement(icon,{
            style:{verticalAlign:'middle'}
        });


        const popover = (
            items.length
        ) ? (
                 <Popover
                     open={this.state.open}
                     anchorEl={this.state.anchorEl}
                     placement={'bottom-start'}
                     anchorOrigin={{
                         vertical: 'bottom',
                         horizontal: 'left',
                     }}
                     transformOrigin={{
                         vertical: 'top',
                         horizontal: 'left',
                     }}
                 >
                     <ClickAwayListener onClickAway={this.handleRequestClose}>
                         <div style={{margin:"8px 0"}}>
                         {_items}
                         </div>
                     </ClickAwayListener>
                 </Popover>

        ) : null;
        return(
            <div>
                {this.renderButton({ label, icon:buttonIcon, idButton, type, isMobile, isPortrait, windowWidth,enabled })}
                {popover}
            </div>
        );
    }
}

export default DataEntryDropDown;
