import React from 'react';
import * as views from '../../../../constants/ViewTypes';
import {BareLocalMap, SmarterTagsPathLayer, ZoneVisibility} from "../LocalMap";
import {SmartSiteMapThumbnailDrawer} from '../../SiteMapThumbnailOverlay';
import {DEFAULT_ID_ATTRIBUTE, MAP_VIEW_URL, SITE_VIEW_HOST} from '../../../../constants/Misc';
import CordialErrorScreen from "../misc/CordialErrorScreen";
import TimeLineControl from './TimeLineControl';
import HistoryPlaybackToolbar from './HistoryPlaybackToolbar';
import TagListComponent from './TagListComponent';
import {getLabelByIdAttribute} from "../../../../utils/View";

//openlayers
import {getCenter} from 'ol/extent';
import {toLonLat} from 'ol/proj';


import {zoomMapByDeltaWithDuration} from '../LocalMapView';
import knowZones from '../../hoc/knowZones';
import BlinkInformation from './BlinkInformation';
import AlertScreen from './AlertScreen';
import supercluster from "supercluster";
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actions from '../../../../actions/index';
import MapPlayback from "./MapPlayback";
import moment from "moment";
import Style from "ol/style/Style";
import Polygon from "ol/geom/Polygon";
import Text from "ol/style/Text";
import VectorLayer from "ol/layer/Vector";
import Fill from "ol/style/Fill";
import Feature from "ol/Feature";
import Stroke from "ol/style/Stroke";
import VectorSource from "ol/source/Vector";

const DEFAULT_BOUNDS = [0,0,100,100];

class BoundsCheckingLocalMap extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            bounds: DEFAULT_BOUNDS,
            mapId: props.mapId
        }
    }

    shouldComponentUpdate(nextProps,nextState){
        return this.props !== nextProps||this.state!==nextState;
    }

    componentDidMount(){
        this.getBounds(this.props.mapId);
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        if(nextProps.mapId !== this.props.mapId){
            this.setState({
                errorMsg: null
            });
            this.getBounds(nextProps.mapId);
        }
    }

    getBounds(mapId){
        if(!mapId){
            this.setState({
                bounds: DEFAULT_BOUNDS
            });
            return;
        }

        this.props.requestMapBounds(mapId)
            .then(bounds=>{
                window._originalBound = bounds;
                if(bounds[2] <= bounds[0] || bounds[3] <= bounds[1]) {
                    this.setState({
                        errorMsg: (
                            <div>
                                Invalid map coordinate configuration.
                                <br/><br/>
                                Please configure with Site Manager.
                            </div>
                        ),
                        bounds: DEFAULT_BOUNDS
                    });
                    this.props.isMapInvalidConf(true);
                } else {
                    this.setState({
                        bounds,
                        mapId,
                        errorMsg: undefined
                    });
                    this.props.isMapInvalidConf(false)
                }
            })
            .catch(err=>{
                this.setState({
                    bounds:DEFAULT_BOUNDS
                });
                this.props.isMapInvalidConf(true);
            });
    }

    getMap(){
        if(this.refs.localMap)
            return this.refs.localMap.getMap();
        else
            return null;
    }

    render(){
        const {children, mapId} = this.props;
        const {bounds, errorMsg} = this.state;

        return  (errorMsg!=null) ? (
            <CordialErrorScreen>
                {errorMsg}
            </CordialErrorScreen>
        ) : (
            <div className={"map-container"}>

                    <BareLocalMap
                        tilePrefix={SITE_VIEW_HOST + MAP_VIEW_URL + "/maps/" + mapId + "/tiles/"}
                        bounds={bounds}
                        {...this.props}

                        disableOLControls
                        ref={"localMap"}
                    >
                        {children}
                    </BareLocalMap>


            </div>
        )
    }
}

export {BoundsCheckingLocalMap};

const NO_TAGS_SELECTED = "No tags selected.";
const NO_TAGS_FOR_MAP = "There is no Tag Blink History for this map in selected date range.";
const BAD_CONFIGURATION = "Invalid map coordinate configuration.";

class WrappedLocalMap extends React.Component{


    constructor(props){
        super(props);
        this.layers = {};
        let map = props.maps && props.maps[0];

        if(props.maps&&props.currentMapId) {
            map = props.maps.filter(item => item._id === props.currentMapId)[0];
        }

        this.state = this.stateFromMap(props, map);
        this.handleSelectMap = this.handleSelectMap.bind(this);
        this.handleMoveEnd = this.handleMoveEnd.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextState){
        if(nextProps.currentMapId !== this.props.currentMapId || nextProps.zones !== this.props.zones){
            this.setState({
                mapId: nextProps.currentMapId,
                ...this.getTagZoneStateOnMap(nextProps, nextProps.currentMapId)
            })
        }

        if(this.props.zoneVisibility !== nextProps.zoneVisibility || this.state.mapId !== nextState.mapId) {
            const active = nextProps.zoneVisibility >= ZoneVisibility.ZONES_ONLY;
            if(this.state.mapId !== nextState.mapId){
                this.removeLayers();
            }
            if (active) {
                const labelActive = nextProps.zoneVisibility === ZoneVisibility.ZONES_AND_LABELS;
                this.drawZones(this.state.zonesOnMap, active);
                this.drawLabels(this.state.zonesOnMap, labelActive);
            } else {
                this.removeLayers();
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        return this.props !== nextProps || this.state !== nextState;
    }

    getMap(){
        return this.refs.localMap.getMap();
    }

    stateFromMap(props,map){
        return {
            mapId: map._id,
            maxZoom: (map.maxZoom - 1) || 0,
            ...this.getTagZoneStateOnMap(props,map._id)
        };
    }

    getTagZoneStateOnMap(props, mapId){
        return{
            tagsOnMap: props.tagsHistory !== null ? this.getTagsOnMap(props.tagsHistory, mapId) : {},
            zonesOnMap: this.getZonesOnMap(props.zones, mapId)
        }
    }

    getTagsOnMap(tags,mapId){
        // return tags.filter(t=> (t.mapId===mapId));
        return tags[mapId];
    }

    getZonesOnMap(zones, mapId){
        return zones.filter(z=>(z.map === mapId));
    }

    handleSelectMap(map){
        this.setState({
            ...this.stateFromMap(this.props,map)
        });
        if(map)
            this.props.changeMap(map);
    }

    handleMoveEnd(e){
        this.setState({selectedTagStatus:false});
    }

    drawZones(zones, active){
        this.map = window.MY_MAP;
        if(active) {
            const elementId = this.state.mapId;
            if (this.layers.hasOwnProperty(elementId)) {
                this.map.removeLayer(this.layers[elementId]);
            }
            let _zones = zones.map((v, i) => {

                const zoneFeature = new Feature({
                    geometry: new Polygon([v.shape]),
                    data: {index: i, name: v.name, color: v.color, zone: v.name}
                });
                return zoneFeature;
            });


            const zoneLayer = new VectorLayer ({
                renderMode: 'image',
                source: new VectorSource ({
                    features: [..._zones]
                }),
                style: function(feature){
                    return new Style({
                        fill: new Fill({
                            color: [feature.get('data').color[0], feature.get('data').color[1], feature.get('data').color[2], feature.get('data').color[3] || 0.5]
                        }),
                        stroke: new Stroke({color: '#000000'})
                    });
                }
            });


            this.layers[elementId+"zone"] = null;
            this.layers[elementId+"zone"] = zoneLayer;

            this.map.addLayer(zoneLayer);
        }
    }

    getLabelStyle(){
        if(this.labelStyle == null) {
            let zoneLabel = {
                text: new Text({
                    font: '16px Calibri,sans-serif',
                    fontWeight: '600',
                    fill: new Fill({color: '#000'}),
                    backgroundFill: new Fill({color: "#fff"}),
                    backgroundStroke: new Stroke({color: "#000", width: 1}),
                    offsetY: 0,
                    overflow: true,
                    padding: [1.5, 5, 1.5, 5]
                })
            };

            this.labelStyle = new Style({
                ...zoneLabel
            });
        }
        return this.labelStyle;
    }

    drawLabels(zones, labelActive){
        this.map = window.MY_MAP;
        if(labelActive){
            const elementId = this.state.mapId;
            if (this.layers.hasOwnProperty(elementId)) {
                this.map.removeLayer(this.layers[elementId+"label"]);
            }
            let zonesLabel = zones.map((v, i) => {
                const label = v.label || v.name;

                const labelFeature = new Feature({
                    geometry: new Polygon([v.shape])
                });
                labelFeature.set("label", label);
                return labelFeature;
            });
            const labelStyle = this.getLabelStyle();

            const labelLayer = new VectorLayer ({
                zIndex: 20,
                renderMode: 'image',
                source: new VectorSource ({
                    features: [...zonesLabel]
                }),
                style: function (feature) {
                    labelStyle.getText().setText(feature.get('label'));
                    return labelStyle;
                }
            });


            this.layers[elementId+"label"] = null;
            this.layers[elementId+"label"] = labelLayer;
            this.map.addLayer(labelLayer);
        }
    }

    removeLayers() {
        this.map = window.MY_MAP;
        for(const key in this.layers)
        {
            const layer=this.layers[key];
            this.map.removeLayer(layer);
        }

    }

    render(){
        const {siteId, hideDrawer, data, tagsQtyByMaps, message, isMobile,...other} = this.props;
        const {tagsOnMap, mapId, maxZoom} = this.state;
        let drawer = null;
        if(!hideDrawer){
            drawer = (
                <SmartSiteMapThumbnailDrawer
                    mapId={mapId}
                    onSelectMap={this.handleSelectMap}
                    siteId={siteId}
                    verticalPosition={true}
                    isPlayback={true}
                    tagsQtyByMaps={tagsQtyByMaps}
                    isMobile={isMobile}
                />
            );
        }

        return (
            <div className={"vss-map"}>
                <BoundsCheckingLocalMap
                    mapId={mapId}
                    ref={"localMap"}
                    {...this.props}
                    onMoveEnd={this.handleMoveEnd}
                    maxZoom={maxZoom}
                >
                    <SmarterTagsPathLayer data={data} {...other} tags={tagsOnMap}/>
                </BoundsCheckingLocalMap>
                {message !== null ? <AlertScreen message={message} /> : null }
                {(this.props.dataRequested && this.props.totalBlinks)&&
                    <BlinkInformation isMaxDefaultSize={this.props.isMaxDefaultSize} totalBlinks={this.props.totalBlinks} />
                }

                <div className={"drawer-container"}>
                    {drawer}
                </div>
            </div>);
    }
}

const KnowledgableWrappedLocalMap = knowZones(WrappedLocalMap);

class HistoryPlaybackMap extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            map: props.maps[0] || props.currentLocalMap,
            showMapDrawer: true,
            bounds: DEFAULT_BOUNDS,
            zoneVisibility: ZoneVisibility.NO_ZONES,
            showTagLabels: false,
            updateCluster: null,
            refinedTags: [],
            refinedZones: [],
            tagsHistory: [],
            maps: [],
            timeArray: [],
            length: 60,
            pos: 0,
            startSelectedDate:moment(),
            // startSelectedDate:moment("2019-07-25T04:00:00Z"),
            endSelectedDate: moment(),
            // endSelectedDate: moment("2019-07-26T04:00:00Z"),
            positionStart:null,
            positionEnd:null,
            minPosition:0,
            maxPosition:60-1,
            selectedElementIds:[],
            requestElementIds:[],
            idAttribute:DEFAULT_ID_ATTRIBUTE,
            startDate:null,
            endDate:null,
            startLimiterDate:null,
            endLimiterDate:null,
            startPointerDate:null,
            endPointerDate:null,
            showTagList: false,
            tagList: {},
            selectedAll: true,
            historyDates:[],
            mapId:null,
            playTimeLine:false,
            tagsQtyByMap: [],
            positionByMaps: {},
            dataRequested: false,
            tagsColorObj: {},
            requested: props.requested,
            showLegend:false,
            eventQuery:'locate',
            mapInvalidConfiguration: false,
        };

        this.zoomIn = this.zoomIn.bind(this);
        this.zoomOut = this.zoomOut.bind(this);
        this.centerMap = this.centerMap.bind(this);
        this.toggleZoneVisibility = this.toggleZoneVisibility.bind(this);
        this.toggleShowTagLabels = this.toggleShowTagLabels.bind(this);
        this.toggleMapDrawer = this.toggleMapDrawer.bind(this);
        this.toggleTagList = this.toggleTagList.bind(this);
        this.toogleBackView = this.toogleBackView.bind(this);
        this.getTagsHistory = this.getTagsHistory.bind(this);
        this.handleChangePosition = this.handleChangePosition.bind(this);
        this.handleChangeMap = this.handleChangeMap.bind(this);
        this.handlePositionStartChange = this.handlePositionStartChange.bind(this);
        this.handlePositionEndChange = this.handlePositionEndChange.bind(this);
        this.handleChangeTimeLine = this.handleChangeTimeLine.bind(this);
        this.handleSelectTag = this.handleSelectTag.bind(this);
        this.handleSelectAllTags = this.handleSelectAllTags.bind(this);
        this.initializeParameters = this.initializeParameters.bind(this);
        this.findLimitDatesByPosition = this.findLimitDatesByPosition.bind(this);
        this.handleSetRangeDate = this.handleSetRangeDate.bind(this);
        this.filterElementsByRange = this.filterElementsByRange.bind(this);
        this.handleCloseTagList = this.handleCloseTagList.bind(this);
        this.handleOnclickOutside = this.handleOnclickOutside.bind(this);
        this.handlePrevTimeLine = this.handlePrevTimeLine.bind(this);
        this.startPlay = this.startPlay.bind(this);
        this.stopPlay = this.stopPlay.bind(this);
        this.handleSetTagsColor = this.handleSetTagsColor.bind(this);
        this.toggleShowLegend = this.toggleShowLegend.bind(this);
        this.getBounds = this.getBounds.bind(this);
        this.handleSetDatePlayback = this.handleSetDatePlayback.bind(this);
        this.handleAddTag = this.handleAddTag.bind(this);
        this.handleRemoveTag = this.handleRemoveTag.bind(this);
        // this.getEventQuery = this.getEventQuery.bind(this);
        this.handleMoveEnd = this.handleMoveEnd.bind(this);
        this.handleBadConfiguration = this.handleBadConfiguration.bind(this);
    }

    UNSAFE_componentWillMount(){

        let stateObject=this.getSelectedElements(this.props.playback, this.props.idAttribute,this.props.reportId);
        let mapId = this.findCurrentLocalMapId(this.props);

        stateObject.mapId=mapId;

        this.setState({
            ...stateObject,
        });

    }

    UNSAFE_componentWillReceiveProps(nextProps){
        const {selectedElementIds,mapId,startSelectedDate,endSelectedDate}=this.state;
        let stateObject={};
        let nextMapId=mapId;
        let currentSelectedTagIds=selectedElementIds;


        if(nextProps.siteId!==this.props.siteId)
        {
            nextMapId=this.findCurrentLocalMapId(nextProps);
            stateObject.mapId=nextMapId;
        }

        if(nextProps.playback!==this.props.playback){
            const idAttribute=nextProps.idAttribute;

            const selectedTags=this.getSelectedElements(nextProps.playback, idAttribute,nextProps.reportId);

            stateObject = {...stateObject, ...selectedTags};

            currentSelectedTagIds=stateObject.selectedElementIds;

            if(startSelectedDate!=null&&endSelectedDate!=null)
                nextProps.requestTagsHistory(stateObject.selectedElementInfo, stateObject.idAttribute,stateObject.eventQuery, moment(startSelectedDate).toISOString(), moment(endSelectedDate).toISOString());

        }

        if((nextProps.tagsData&&
            ((nextProps.tagsData!==this.props.tagsData)
            ||(mapId!=null&&mapId!==nextMapId)
            ||nextProps.maps!==this.props.maps))
            ||nextProps.mapConfiguration!==this.props.mapConfiguration
        )
        {

            const tagsByMap=this.findElementsByMap(nextProps.tagsData,nextMapId);

            const initParams = this.initializeParameters(nextMapId, tagsByMap, currentSelectedTagIds,nextProps.mapConfiguration);

            stateObject = {...stateObject, ...initParams};

            stateObject.blinkObject = nextProps.tagsData;
        }

        if(nextProps.requested !== this.props.requested){
            stateObject.requested = nextProps.requested;
        }

        this.setState({
            length: nextProps.length,
            ...stateObject

        });

    }

    handleTileLoadEnd(){
        console.log("handleTileLoadEnd");
    }
    componentDidMount(){
        this.map = window.MY_MAP;
        this.map.on("moveend", this.handleMoveEnd);
         // this.getTagsHistory(this.state.startSelectedDate,this.state.endSelectedDate);
        // this.map.getSource().on("tileloadend",this.handleTileLoadEnd);
    }
    componentWillUnmount() {
        this.map.un("moveend", this.handleMoveEnd);
        this.props.clearTagsHistory();
        if (this.props.previousView !== views.PLAYBACK_VIEW) {
            this.props.changeView(this.props.previousView, this.props.reportId);
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        return nextProps !== this.props || nextState !== this.state;
    }

    componentDidUpdate(prevProps, prevState){
        if(prevState.blinkObject !== this.state.blinkObject){
            let arrayTagsQty = [];
            if(this.state.blinkObject != null && this.state.blinkObject !== undefined) {
                const maps = this.state.blinkObject.maps;
                for (let key in maps) {
                    let tags = maps[key].elements;
                    for (let keyT in tags) {
                        const arrayAux = tags[keyT].blinks;
                        for(let i= 0; i < arrayAux.length; i++ ){
                            let aux = arrayTagsQty[arrayAux[i].pos] !== undefined && arrayTagsQty[arrayAux[i].pos][key] !== undefined ? arrayTagsQty[arrayAux[i].pos][key] : 0;
                            arrayTagsQty[arrayAux[i].pos] = {...arrayTagsQty[arrayAux[i].pos], [key]: arrayAux[i].groupSize > 0 ? aux + 1 : aux};
                        }
                    }
                }
            }

            this.setState({
                tagsQtyByMap: arrayTagsQty
            })
        }

        if(!!this.state.positionByMaps[this.state.mapId] && prevState.mapId !== this.state.mapId && this.state.pos !== this.state.positionByMaps[this.state.mapId].pos){
            this.setState({
                pos: this.state.positionByMaps[this.state.mapId].pos
            });
        }
    }


    initializeParameters(mapId,elementsByMap,selectedElementIds,mapConfiguration){

        const {pos}=this.state;

        let initParams={};
        const positionObject=this.findLimits(selectedElementIds,elementsByMap);
        const filteredTagsByMap=this.filterElementsByMap(elementsByMap,mapId,positionObject.minPosition,positionObject.maxPosition,selectedElementIds);
        initParams.tagsHistory=filteredTagsByMap;
        initParams.positionStart = positionObject.minPosition;
        initParams.positionEnd = positionObject.maxPosition;
        if (~~pos < ~~positionObject.minPosition)
            initParams.pos = positionObject.minPosition;
        else if (~~pos > ~~positionObject.maxPosition)
            initParams.pos = positionObject.maxPosition;
        else
            initParams.pos = pos;

        const limitDatesByPosition=this.findLimitDatesByPosition(elementsByMap,initParams.pos);

        initParams.limitDatesByPosition=limitDatesByPosition;

        initParams.clusters=this.buildClusters(initParams.tagsHistory,mapConfiguration);

        initParams.blinksPerFrame=this.calculateBlinksPerFrame(initParams.tagsHistory);
        // console.log("initParams.blinksPerFrame",initParams.blinksPerFrame);

        return initParams;
    }

    findElementsByMap(data,mapId){

        if(data!=null&&data.maps){
            return data.maps[mapId];
        }

        return null;
    }

    findCurrentLocalMapId(props){

        const {currentLocalMap,maps,reportId,siteId}=props;
        const currentLocalMapReport=currentLocalMap[reportId];

        let localMapId=null;
        if(currentLocalMapReport){
            const siteMapArray=maps.filter(map=>map.siteId===siteId);
            const localMapArray=siteMapArray.filter(map=>map._id===currentLocalMapReport.localMapId);

            if(localMapArray!=null&&localMapArray.length>0){

                const localMap=localMapArray[0];

                if(localMap!=null&&localMap._id)
                    localMapId=localMap._id;
            }
            else{
                const localMap=siteMapArray[0];

                if(localMap!=null&&localMap._id)
                    localMapId=localMap._id;
            }
        }

        return localMapId;
    }

    getSelectedElements(playbackItemsInfo, idAttribute,reportId){

        let stateObject={};

        if(playbackItemsInfo!=null&&playbackItemsInfo.items!=null) {
            const selected = playbackItemsInfo.items;
            const eventQuery = playbackItemsInfo.eventQuery;


            stateObject.selectedElementIds = [];
            stateObject.selectedElementInfo = {};
            stateObject.requestElementIds = [];
            stateObject.tagList = {};
            stateObject.tagsHistory = {};
            stateObject.idAttribute = idAttribute;

            if (selected && Object.keys(selected).length > 0) {

                Object.keys(selected).forEach(elementId => {

                    if (elementId != null && elementId !== "" && stateObject.selectedElementIds.indexOf(elementId + "") < 0) {
                        stateObject.selectedElementIds.push(elementId + "");
                        stateObject.requestElementIds.push(elementId + "");
                        stateObject.selectedElementInfo[elementId + ""] = selected[elementId];
                    }
                });
                stateObject.selected = selected;
                let tagList = {};
                let selectedItem = null;
                for (let i = 0; i < stateObject.selectedElementIds.length; i++) {
                    selectedItem = stateObject.selected[stateObject.selectedElementIds[i]];
                    tagList[stateObject.selectedElementIds[i]] = {
                        selected: true,
                        displayName: selectedItem[idAttribute],
                        order: selectedItem.order
                    };
                }
                stateObject.tagList = tagList;
            }

            // Find the event for query to ES.
            // stateObject.eventQuery = this.getEventQuery(stateObject.selectedElementInfo);
            stateObject.eventQuery = eventQuery;
        }
        return stateObject;
    }

    findLimitDatesByPosition(elementsByMap){
        const {startSelectedDate,endSelectedDate,minPosition,maxPosition}=this.state;

        const startUserDate=new Date(startSelectedDate).getTime();
        const endUserDate=new Date(endSelectedDate).getTime();

        let limitDatesByPosition={};
        let datesByPosition={};

        if(elementsByMap!=null&&elementsByMap.elements!=null) {
            for (const key in elementsByMap.elements) {

                const blinks = elementsByMap.elements[key].blinks;

                for (const i in blinks) {

                    const blinkInfo=blinks[i];
                    const timeStart = new Date(blinkInfo.timeStart).getTime();
                    const timeEnd = new Date(blinkInfo.timeEnd).getTime();

                    if (!datesByPosition.hasOwnProperty(blinkInfo.pos))
                        datesByPosition[blinkInfo.pos] = [];

                    datesByPosition[blinkInfo.pos].push(timeStart);
                    datesByPosition[blinkInfo.pos].push(timeEnd);
                }
            }

            if (!datesByPosition.hasOwnProperty(minPosition))
                datesByPosition[minPosition] = [];
            datesByPosition[minPosition].push(startUserDate);

            if (!datesByPosition.hasOwnProperty(maxPosition))
                datesByPosition[maxPosition] = [];
            datesByPosition[maxPosition].push(endUserDate);

            for(const key in datesByPosition){
                const datesByPositionArray=datesByPosition[key];
                const minDatePosition=Math.min.apply(null,datesByPositionArray);
                const maxDatePosition=Math.max.apply(null,datesByPositionArray);
                limitDatesByPosition[key]={minDate:minDatePosition,maxDate:maxDatePosition};
            }
        }

        return limitDatesByPosition;
    }

    getLocalTimestampShort(date){
        return date.toLocaleDateString(window.navigator.language,{
            year:"2-digit",
            month:"numeric",
            day:"numeric",
            hour:"numeric",
            minute:"numeric"
        });
    };

    findLimits(selectedElementIds,elementsByMap){

        let positions=[];
        if(elementsByMap!=null&&elementsByMap.elements) {
            for (const key in elementsByMap.elements) {
                if (selectedElementIds.indexOf(key) >= 0) {
                    for (const i in elementsByMap.elements[key].blinks) {
                        const blinkInfo=elementsByMap.elements[key].blinks[i];
                        if (blinkInfo.groupSize > 0)
                            positions.push(blinkInfo.pos);
                    }
                }
            }
        }

        let minPosition=Math.min.apply(null,positions);
        let maxPosition=Math.max.apply(null,positions);

        minPosition=isFinite(minPosition)?minPosition:0;
        maxPosition=isFinite(maxPosition)?maxPosition:0;

        return {minPosition:minPosition,maxPosition:maxPosition};
    }

    filterElementsByMap(elementsByMap, mapId, positionStart, positionEnd,selectedElementIds){
        let filteredBlinks={};
        if(elementsByMap!=null)
            filteredBlinks = this.filterElementsByRange(elementsByMap,positionStart,positionEnd,selectedElementIds);

        return filteredBlinks;
    }

    filterElementsByRange(elementsByMap, positionStart, positionEnd,selectedElementIds){

        if(elementsByMap!=null&&elementsByMap.elements!=null) {
            let filteredTagsByMap={};
            const tags=elementsByMap.elements;
            for(const key in tags){
                const blinkArray=tags[key].blinks;

                if(selectedElementIds.indexOf(key)>=0) {
                    const filteredBlinks = blinkArray.filter((blinkInfo) => 
                        (blinkInfo.groupSize>0&&blinkInfo.pos >= positionStart && blinkInfo.pos <= positionEnd && !(blinkInfo.x === 0 && blinkInfo.y === 0 && blinkInfo.z === 255)) 
                    ? 1 : 0);
                    filteredTagsByMap[key] = Object.assign({},tags[key]);
                    filteredTagsByMap[key].blinks=filteredBlinks;
                }
            }

            return filteredTagsByMap;
        }

        return elementsByMap;
    }

    getMapsForSite(maps,siteId){
        if(siteId !== this.lastSiteID){
            this.lastSiteID = siteId;
            this.lastSiteMaps = maps.filter(m=>m.siteId === siteId);
        }
        return this.lastSiteMaps;
    }

    /**
     * Check if the currentMapId is in the list of maps of site.
     * @param maps Filtered list with maps for current site.
     * @param blinkInfo
     */
    validateCurrentMapId(maps, blinkInfo) {

        let mapId = null;
        if (maps && maps.length > 0) {

            if (blinkInfo != null && Object.keys(blinkInfo).length > 0) {
                const orderedMapId = Object.keys(blinkInfo).sort();
                mapId=~~orderedMapId[0];
            }
            else
                mapId = maps[0]._id;
        }
        return mapId;
    }

    getMap() {
        return this.map;
    }

    zoomIn(){
        let map = this.getMap();
        zoomMapByDeltaWithDuration(map, 1, 250);
    }

    zoomOut(){
        let map = this.getMap();
        zoomMapByDeltaWithDuration(map, -1, 250);
    }

    centerMap(){
        let map = this.getMap();
        map.getView().setCenter(getCenter(window._originalBound));
        map.getView().setZoom(1);
    }

    toggleZoneVisibility(){
        const {NO_ZONES, ZONES_ONLY, ZONES_AND_LABELS} = ZoneVisibility;
        let vis = NO_ZONES;
        switch (this.state.zoneVisibility){
            case NO_ZONES:
                vis = ZONES_ONLY;
                break;
            case ZONES_ONLY:
                vis = ZONES_AND_LABELS;
                break;
            case ZONES_AND_LABELS:
            default:
                vis = NO_ZONES;
                break;
        };

        this.setState({
            zoneVisibility: vis
        });
    }

    toggleShowTagLabels(){
        this.setState({
            showTagLabels: !this.state.showTagLabels
        })
    }

    toggleMapDrawer(){
        this.setState({
            showMapDrawer: !this.state.showMapDrawer
        });
    }

    toggleTagList(){
        this.handleClickButtonTagList();
    }

    toogleBackView(view, reportId){
        this.props.changeView(view, reportId);
    }

    toggleShowLegend(){

        const {showLegend}=this.state;

        this.setState({showLegend:!showLegend});
    }

    getTagsForMaps(data, pos){
        let objMaps = {};
        if(data != null) {
            const maps = data.maps;
            for (let key in maps) {
                let elements = maps[key].elements;
                let arrayTags = [];
                for (let keyT in elements) {
                    let arrT = elements[keyT].blinks.filter(m => {
                        return m.pos === pos && m.groupSize > 0;
                    });
                    arrayTags = arrayTags.concat(arrT);
                }
                objMaps[key] = arrayTags.length;
            }
        }
        return objMaps;
    }

    startPlay() {
        this.setState({playTimeLine: true});
    }

    stopPlay() {
        this.setState({playTimeLine: false});
    }

    getMapgetMap(){
        return this.refs.map.getWrappedInstance().getWrappedInstance().getMap();
    }

    getTagsHistory(startDate, endDate){
        const {selectedElementInfo, idAttribute,eventQuery}=this.state;

        this.props.requestTagsHistory(selectedElementInfo, idAttribute,eventQuery, startDate.toISOString(), endDate.toISOString()).catch(e=>{console.log("error request",e)});

        this.setState({
            startSelectedDate: startDate,
            endSelectedDate: endDate,
            dataRequested: true,
            startDate: startDate,
            endDate: endDate,
        });
    }

    getBounds(){
        const timeLineControlBounds=this.getBoundsInfo("timeLineControl");
        return {timeLineControlBounds}
    }
    getBoundsInfo(refName){
        const refElement = this.refs[refName];

        const bounds=refElement.getBoundingClientRect();

        return bounds;
    }

    getTileParams(){

        const pixelPerUnit = this.map.getView().getResolutionForZoom(0);

        let tileParams={};

        if(this.map!=null) {
            this.map.getLayers().forEach(layer => {
                if (layer.get("siteId")!=null&&layer.get("mapId")!=null) {
                    tileParams = {siteId: layer.get("siteId"), mapId: layer.get("mapId"), pixelPerUnit};
                }
            });
        }

        return tileParams;
    }



    handleChangeMap(map){

        if(map&&map._id) {
            const {tagsData,mapConfiguration} = this.props;
            const {selectedElementIds} = this.state;
            const newMapId = map._id;

            let stateObject={};
            stateObject.mapId=newMapId;
            if(this.state.dataRequested) {
                stateObject.positionByMaps = {...this.state.positionByMaps, [this.state.mapId]: {pos: this.state.pos}};
            }
            let posAux = this.state.positionByMaps[newMapId] !== undefined ? this.state.positionByMaps[newMapId].pos : this.state.pos;
            //this.handleChangePosition(posAux);
            stateObject.pos= posAux;
            let elementsByMap=this.findElementsByMap(tagsData,newMapId);
            const initParams = this.initializeParameters(newMapId, elementsByMap, selectedElementIds,mapConfiguration);

            stateObject={...stateObject,...initParams};

            this.setState(stateObject);
        }
    }


    handleMoveEnd(){

        if(Object.keys(this.props.mapConfiguration).length > 0){
            let currentMapParams=this.getTileParams();

            // Create the index after the map is loaded and render the canvas.
            if(this.state.mapId !== currentMapParams.mapId||this.state.clusters==null||this.state.pixelPerUnit !== currentMapParams.pixelPerUnit){

                const {tagsData,mapConfiguration} = this.props;
                const {selectedElementIds,mapId} = this.state;
                let stateObject={};

                stateObject.extentMap = this.map.getView().calculateExtent((this.map.getSize()));
                stateObject.pixelPerUnit=currentMapParams.pixelPerUnit;

                let elementsByMap=this.findElementsByMap(tagsData,mapId);
                const initParams = this.initializeParameters(mapId, elementsByMap, selectedElementIds,mapConfiguration);
                stateObject={...stateObject,...initParams};
                this.setState(stateObject);

            }

        }

    }

    handleClickButtonTagList(){
        const {showTagList} = this.state;

        if(!showTagList){
            this.handleOpenTagList();
        } else {
            this.handleCloseTagList();
        }
    }

    handleOpenTagList(){
        this.setState({
            showTagList: !this.state.showTagList
        });
        //document.addEventListener('mousedown', this.handleOnclickOutside);
    }

    handleOnclickOutside(e){
        if(this.refs.toolbarContainer && !this.refs.toolbarContainer.contains(e.target))
            this.handleCloseTagList();
    }

    handleCloseTagList(){
        this.setState({
            showTagList: !this.state.showTagList
        });
        //document.removeEventListener('mousedown', this.handleOnclickOutside);
    }

    handleChangePosition(pos,positionRepeatCount,renderLayers){

        if (this.state.positionRepeatCount !== positionRepeatCount
            || this.state.pos !== pos
            || this.state.renderLayers !== renderLayers) {

            this.setState({
                pos: pos,
                positionRepeatCount: positionRepeatCount,
                renderLayers: renderLayers
            });
        }
    }

    handlePositionStartChange(newPosition,positionIndicator){
        const {positionStart,positionEnd,blinkObject,mapId,selectedElementIds}=this.state;
        const {mapConfiguration}=this.props;
        if (newPosition !== positionStart) {

            let blinksByMap = this.findElementsByMap(blinkObject,mapId);

            const filteredTags = this.filterElementsByRange(blinksByMap, newPosition, positionEnd,selectedElementIds);

            // TODO: implement a throttle for this functionality.
            const clusters=this.buildClusters(filteredTags,mapConfiguration);

            this.setState({positionStart: newPosition, tagsHistory: filteredTags, pos: positionIndicator,clusters:clusters});
        }
    }

    handlePositionEndChange(newPosition,positionIndicator){
        const {positionStart,positionEnd,blinkObject,mapId,selectedElementIds}=this.state;
        const {mapConfiguration}=this.props;
        if(newPosition!==positionEnd) {

            let blinksByMap = this.findElementsByMap(blinkObject,mapId);

            const filteredTags = this.filterElementsByRange(blinksByMap, positionStart, newPosition,selectedElementIds);

            // TODO: implement a throttle for this functionality.
            const clusters=this.buildClusters(filteredTags,mapConfiguration);

            this.setState({positionEnd: newPosition, tagsHistory: filteredTags,pos:positionIndicator,clusters:clusters});
        }
    }

    handleChangeTimeLine(positionStartTime,positionEndTime){

        const {selectedElementInfo,idAttribute,historyDates,limitDatesByPosition,startSelectedDate,endSelectedDate,eventQuery}=this.state;

        if(limitDatesByPosition!=null&&limitDatesByPosition[positionStartTime]&&limitDatesByPosition[positionEndTime]) {

            const dateObject = {
                startDate: limitDatesByPosition[positionStartTime].minDate,
                endDate: limitDatesByPosition[positionEndTime].maxDate
            };

            let newHistoryDates=[];
            if(historyDates==null)
                newHistoryDates=[];
            else
                newHistoryDates =historyDates.slice(0);


            newHistoryDates.push({
                startDate: moment(startSelectedDate).toISOString(),
                endDate: moment(endSelectedDate).toISOString()
            });

            this.setState({
                startSelectedDate: moment(dateObject.startDate),
                endSelectedDate: moment(dateObject.endDate),
                historyDates: newHistoryDates,
                positionByMaps: {}
            });

            this.props.requestTagsHistory(selectedElementInfo, idAttribute,eventQuery, moment(dateObject.startDate).toISOString(), moment(dateObject.endDate).toISOString());
        }
    }

    handlePrevTimeLine(positionStartTime, positionEndTime){

        const {selectedElementInfo,idAttribute,historyDates,limitDatesByPosition,eventQuery}=this.state;

        if(limitDatesByPosition!=null) {

            let newHistoryDates=historyDates.slice(0);

            const oldDates=newHistoryDates.pop();

            if(oldDates&&oldDates.startDate&&oldDates.endDate) {
                const startDate = moment(oldDates.startDate);
                const endDate = moment(oldDates.endDate);
                this.setState({
                    startSelectedDate: startDate,
                    endSelectedDate: endDate,
                    historyDates: newHistoryDates,
                    positionByMaps: {}
                });

                this.props.requestTagsHistory(selectedElementInfo, idAttribute,eventQuery, startDate.toISOString(), endDate.toISOString());
            }
        }
    }

    handleSetRangeDate(startDate, endDate){
        this.setState({
            startSelectedDate: startDate,
            endSelectedDate: endDate
        });
    }

    handleSelectTag(tag){
        const {blinkObject,mapId}=this.state;
        const {mapConfiguration}=this.props;
        let taglistAux = Object.assign({},this.state.tagList);
        let selectedElementIds = this.state.selectedElementIds.slice(0);
        if(!taglistAux[tag].selected){
            selectedElementIds.push(tag);
        } else {
            selectedElementIds.splice(selectedElementIds.indexOf(tag), 1)
        }

        taglistAux[tag].selected = !taglistAux[tag].selected;
        let blinksByMap=this.findElementsByMap(blinkObject,mapId);

        const stateObject=this.initializeParameters(mapId,blinksByMap,selectedElementIds,mapConfiguration);

        this.setState({
            tagList: taglistAux,
            // tagsHistory:filteredBlinks,
            selectedElementIds:selectedElementIds,
            ...stateObject
        });
    }

    handleSelectAllTags(){

        const {blinkObject,mapId,selectedAll,tagList}=this.state;
        const {mapConfiguration}=this.props;

        if(Object.keys(tagList).length > 0){

            let newSelectedTagIds = [];
            let newTagList = Object.assign({},tagList);

            // Unselect all.
            if(selectedAll===true){
                Object.keys(newTagList).forEach(key=>newTagList[key].selected=false);
                newSelectedTagIds=[];
            }
            // Select all.
            else {
                newSelectedTagIds=[];
                Object.keys(newTagList).forEach(key=>{
                    newTagList[key].selected=true;
                    newSelectedTagIds.push(key);
                });
            }

            let blinksByMap=this.findElementsByMap(blinkObject,mapId);
            const stateObject=this.initializeParameters(mapId,blinksByMap,newSelectedTagIds,mapConfiguration);

            this.setState({
                tagList: newTagList,
                selectedAll: !selectedAll,
                selectedElementIds:newSelectedTagIds,
                ...stateObject
            });
        }
    }

    handleSetTagsColor(tagColorObj){
        this.setState({
            tagsColorObj: tagColorObj
        });
    }

    handleSetDatePlayback(fromDateRange, toDateRange){
        this.props.setPlaybackDateRange(this.props.reportId, fromDateRange, toDateRange);
    }

    handleAddTag(value) {

        const {addItemsPlaybackView,idAttribute} = this.props;

        let selectedItems={};
        selectedItems[value]={};
        selectedItems[value][idAttribute]=value;

        addItemsPlaybackView(selectedItems);
    }

    handleRemoveTag(value){

        const {removeItemsPlaybackView}=this.props;
        removeItemsPlaybackView([value]);
    }

    // getEventQuery(itemsInfo){
    //
    //     if(itemsInfo!=null&&Object.keys(itemsInfo).length>0) {
    //
    //         // Find if any element has "zone change" event.
    //         const zoneChangeIndex = Object.keys(itemsInfo).some(itemId => {
    //             const currentItemInfo = itemsInfo[itemId];
    //
    //             return (currentItemInfo.event != null
    //                 && currentItemInfo.event.length>0
    //                 && currentItemInfo.event[0].toLowerCase().indexOf("zone change") >= 0);
    //         });
    //
    //         let eventQuery = this.state.eventQuery;
    //         if (zoneChangeIndex === true) {
    //             eventQuery = "zone change";
    //         }
    //
    //         return eventQuery;
    //     }
    //
    //     return this.state.eventQuery;
    // }

    renderToolbar(){
        const {zoneVisibility, showMapDrawer, showTagLabels, showTagList, tagList, tagsColorObj} = this.state;
        const {reportId, previousView, requestFilterValues,idAttribute,columnMeta} = this.props;
        let elementIdLabel = getLabelByIdAttribute(idAttribute);
        let filterId=null;
        if(columnMeta!=null&&columnMeta.hasOwnProperty(reportId)&&columnMeta[reportId].hasOwnProperty(idAttribute)){
            filterId=columnMeta[reportId][idAttribute].filter;
        }


        return (
            <div ref={"toolbarContainer"}>
                <HistoryPlaybackToolbar
                    zoneVisibility={zoneVisibility}
                    showMapDrawer={showMapDrawer}
                    showTagLabels={showTagLabels}
                    zoomIn={this.zoomIn}
                    zoomOut={this.zoomOut}
                    centerMap={this.centerMap}
                    toggleZoneVisibility={this.toggleZoneVisibility}
                    toggleShowTagLabels={this.toggleShowTagLabels}
                    toggleMapDrawer={this.toggleMapDrawer}
                    toggleTagList={this.toggleTagList}
                    toggleBackView={this.toogleBackView}
                    showTagList={showTagList}
                    toggleShowLegend={this.toggleShowLegend}
                    reportId={reportId}
                    previousView={previousView}
                />
                <TagListComponent
                    showTagList={showTagList}
                    tagsColorObj={tagsColorObj}
                    tags={tagList}
                    handleSelectTag={this.handleSelectTag}
                    handleSelectAllTags={this.handleSelectAllTags}
                    handleAddTag={this.handleAddTag}
                    handleRemoveTag={this.handleRemoveTag}
                    requestFilterValues={requestFilterValues}
                    elementIdLabel={elementIdLabel}
                    filterId={filterId}
                />
            </div>
        );
    }

    handleBadConfiguration(mapConfig){
        this.setState({
            mapInvalidConfiguration: mapConfig
        })
    }

    renderContent(){
        const {siteId, maps, resizeTrigger, isMobile, idAttribute} = this.props;
        const {
            zoneVisibility,
            showMapDrawer,
            showTagLabels,
            tagsHistory,
            dataRequested,
            pos,
            positionRepeatCount,
            positionStart,
            positionEnd,
            mapId,
            selectedElementIds,
            requestElementIds,
            selectedElementInfo,
            tagList,
            requested,
            showLegend,
            clusters,
            startDate,
            endDate,
            renderLayers,
            extentMap,
            mapInvalidConfiguration,
        } = this.state;

        let showPlaybackAdvice = !!startDate && !!endDate;
        let currentMapId =mapId;
        let tagListElementsSelected = Object.keys(tagList).some(key => tagList[key].selected);
        let tagsSelected = Object.keys(tagList).length > 0 && tagListElementsSelected;
        let tagsToRequest = requestElementIds.length > 0;
        let tagsResponse = Object.keys(tagsHistory).length > 0;

        let message = null;

        if(showPlaybackAdvice) {
            if(mapInvalidConfiguration){
                message = BAD_CONFIGURATION;
            } else {
                if (!tagsSelected || !tagsToRequest) {
                    message = NO_TAGS_SELECTED;
                } else if (!tagsResponse && dataRequested) {
                    if (requested != null) {
                        message = requested;
                    } else {
                            message = NO_TAGS_FOR_MAP;
                    }
                }
            }
        } else {
            message = "Please select a date range."
        }

        let elementIdLabel = getLabelByIdAttribute(idAttribute);

        if(siteId === null || siteId === undefined){
            return (
                <CordialErrorScreen>
                    Please select a site.
                </CordialErrorScreen>);
        }


        if(selectedElementIds.length > 10){
            return (
                <CordialErrorScreen>
                    You have many elements selected
                </CordialErrorScreen>
            );
        }

        let tagsQtyByMaps = this.state.tagsQtyByMap[pos];
        const mapsForSite = this.getMapsForSite(maps,siteId);
        return (
            <div className={"vss-map"}>
                {(currentMapId!=null)?<MapPlayback
                    resizeTrigger={resizeTrigger}
                    mapId={currentMapId}
                    blinkObject={tagsHistory}
                    currentPosition={pos}
                    positionRepeatCount={positionRepeatCount}
                    positionStart={positionStart}
                    positionEnd={positionEnd}
                    selectedTagIds={selectedElementIds}
                    zoneVisibility={zoneVisibility}
                    hideDrawer={!showMapDrawer}
                    requestTagIds={requestElementIds}
                    startPlay={this.startPlay}
                    stopPlay={this.stopPlay}
                    tagsQtyByMaps={tagsQtyByMaps}
                    selectedTagInfo={selectedElementInfo}
                    showTagLabels={showTagLabels}
                    message={message}
                    handleSetTagsColor={this.handleSetTagsColor}
                    showLegend={showLegend}
                    getBounds={this.getBounds}
                    elementIdLabel={elementIdLabel}
                    clusters={clusters}
                    renderLayers={renderLayers}
                    extentMap={extentMap}
                >
                <KnowledgableWrappedLocalMap
                    maps={mapsForSite}
                    currentMapId={currentMapId}
                    zoneVisibility={zoneVisibility}
                    showTagLabels={showTagLabels}
                    hideDrawer={!showMapDrawer}
                    siteId={siteId}
                    changeMap={this.handleChangeMap}
                    pos={pos}
                    tagsHistory={tagsHistory}
                    tagsQtyByMaps={tagsQtyByMaps}
                    disableDragEvent={true}
                    disableMoveEvent={true}
                    message={message}
                    dataRequested={dataRequested}
                    isMobile={isMobile}
                    isMaxDefaultSize={(this.props.tagsData)?this.props.tagsData.isMaxDefaultSize:false}
                    totalBlinks={(this.props.tagsData)?this.props.tagsData.totalBlinks:0}
                    requestMapBounds={this.props.requestMapBounds}
                    isMapInvalidConf={this.handleBadConfiguration}
                />
                </MapPlayback>:<div className={'playback-map-error'}>No maps for this site</div>}
            </div>
        )
    }

    // Creates clusters for each tag selected (max 10 tags).
    buildClusters(tagsHistory,mapConfiguration) {
        let clusters = {};

        if (tagsHistory != null) {

            for (const key in tagsHistory) {
                clusters[key] = this.createIndex(tagsHistory[key].blinks, mapConfiguration);
            }
        }
        return clusters;
    }

    /**
     * Creates the index of cluster.
     * @param pointsArray
     * @returns {*}
     */
    createIndex(pointsArray,mapConfiguration){

        if(pointsArray!=null&&pointsArray.length>0&&mapConfiguration!=null&&mapConfiguration.hasOwnProperty('mapPoint1X')) {

            // Number of items in the array.
            const count = pointsArray.length;

            const superclusterParams=this.calculateParamsSupercluster(count,mapConfiguration);

            // Create format required for "supercluster".
            let featureCollection = {
                "type": "FeatureCollection",
                "features": []
            };

            // Add features to "featureCollection".
            for (var i = 0; i < count; ++i) {

                const coordinatesLonLat=toLonLat([pointsArray[i].x, pointsArray[i].y])// transform to required coordinates.

                const feature = {
                    "type": "Feature",
                    "properties": {
                        "name": "point_" + i,
                        "index": i,
                        "pos": pointsArray[i].pos,
                        "selected":(pointsArray[i].selected===true)?1:0
                    },
                    "geometry": {
                        "type": "Point",
                        "coordinates": coordinatesLonLat
                    }
                };
                featureCollection.features.push(feature);
            }

            // Creates supercluster instance.
            let index = new supercluster({
                radius:superclusterParams.radius,
                maxZoom: superclusterParams.maxZoom,
                extent:superclusterParams.extent,
                initial: function() { return {selected: []}; },
                map: function(props) { return {selected: props.index}; },
                reduce: function(accumulated, props) {
                    accumulated.selected=accumulated.selected.concat(props.selected);
                }
            });

            // Loads features into "supercluster" instance.
            index.load(featureCollection.features);


            // console.timeEnd("indexTime");
            return index;
        }
        else return null;
    }

    /**
     * Calculates the params for clusterize the points in base of map size and scale.
     * @param count
     * @returns {{radius: number, maxZoom: number, extent: number}}
     */
    calculateParamsSupercluster(count,mapConfiguration){

        const map=this.map;

        let maxZoom=8;

        const pixelPerUnit = map.getView().getResolutionForZoom(0);
        const pixelPerUnitLonLat = toLonLat([pixelPerUnit,0])[0];
        const radioOfClusters=9;

        let params={radius:pixelPerUnitLonLat*radioOfClusters,maxZoom: maxZoom,extent:256};
        
        return params;
    }
    render(){
        const {
            length,
            pos,
            positionStart,
            positionEnd,
            startSelectedDate,
            endSelectedDate,
            limitDatesByPosition,
            playTimeLine,
            historyDates,
            selectedElementIds,
            dataRequested,
            startDate,
            endDate,
            blinksPerFrame,
        } = this.state;

        const {windowWidth, dateRange, reportId} = this.props;

        const defaultLength=(length<=0)?60-1:length-1;

        let showPlaybackAdvice = !(!!startDate && !!endDate);

        return (
            <div className="vss-map">
                {this.renderContent()}

                <div className={"timeline-control-container"} ref={"timeLineControl"}>
                    <TimeLineControl
                        onChangePosition={this.handleChangePosition}
                        length={defaultLength}
                        pos={pos}
                        positionStart={positionStart}
                        positionEnd={positionEnd}
                        getTagHistory={this.getTagsHistory}
                        defaultValue={""}
                        startSelectedDate={startSelectedDate}
                        endSelectedDate={endSelectedDate}
                        onPositionStartChange={this.handlePositionStartChange}
                        onPositionEndChange={this.handlePositionEndChange}
                        onChangeTimeLine={this.handleChangeTimeLine}
                        onPrevTimeLine={this.handlePrevTimeLine}
                        limitDatesByPosition={limitDatesByPosition}
                        setRangeDate={this.handleSetRangeDate}
                        windowWidth={windowWidth}
                        playTimeLine={playTimeLine}
                        historyDates={historyDates}
                        selectedTagIds={selectedElementIds}
                        dataRequested={dataRequested}
                        showPlaybackAdvice={showPlaybackAdvice}
                        blinksPerFrame={blinksPerFrame}
                        setPlaybackDateRange={this.handleSetDatePlayback}
                        dateRange={dateRange}
                        reportId={reportId}
                    />
                </div>
                {this.renderToolbar()}
            </div>
        );
    }

    calculateBlinksPerFrame(blinksByTag){

        let blinksPerFrame={};
        for(const key in blinksByTag){
            const blinkArray=blinksByTag[key];

            let currentBlinksPerFrame={};
            blinkArray.blinks.forEach(blink=>{
                const pos=blink.pos;
                if(!currentBlinksPerFrame.hasOwnProperty(pos)){
                    currentBlinksPerFrame[pos]=0;
                }
                currentBlinksPerFrame[pos]++;

            });

            for (const key in currentBlinksPerFrame){
                if(!blinksPerFrame.hasOwnProperty(key)){
                    blinksPerFrame[key]=currentBlinksPerFrame[key];
                }
                else if(blinksPerFrame[key]<currentBlinksPerFrame[key]) {
                    blinksPerFrame[key]=currentBlinksPerFrame[key];
                }
            }

        }
        return blinksPerFrame;
    }
}

HistoryPlaybackMap.styles = {
    zoomInButton:{
        display:'block',
        height:'32px',
        padding:'8px 12px 0px'
    },
    zoomOutButton:{
        display:'block',
        height:'32px',
        padding:'0px 12px 8px'
    },
    standardButton:{
        display:'block',
        height:'35px',
        padding:'10px 12px'
    },
    spacer:{
        height:'1em',
        width:'48px'
    },
};


const mapStateToProps = state => ({
    mapConfiguration: state.reportMap.mapConfiguration,
    currentLocalMap: state.reportMap.currentLocalMap,
    tagsData: state.reportMap.tagsData,
    reportId:state.report.reportId,
    playback: state.reportMap.playback[state.report.reportId],
    length: state.reportMap.length,
    // selectedElementsInTable: state.reportMap.selectedTagIds,
    // selectedElementInfoInTable: state.reportMap.selectedElementInfo,
    selected: state.report.selected,
    previousView: state.reportMap.previousView,
    currentMapPlayback: state.reportMap.currentMapPlayback,
    windowWidth:state.resize.windowWidth,
    requested: state.reportMap.requested,
    idAttribute: state.reportMap.mapFormat.idAttribute,
    siteId: state.reportMap.currentPlaybackInfo.playbackId,
    dateRange: state.reportMap.dateRange,
    reportIdStore: state.report.reportId,
    columnMeta: state.report.columnMeta,
});

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        requestMapBounds: actions.requestMapBounds,
        getAdjustedBounds: actions.getAdjustedBounds,
        requestTagsHistory: actions.requestTagsHistory,
        changeView:actions.changeView,
        clearTagsHistory:actions.clearTagsHistory,
        setPlaybackDateRange: actions.setPlaybackDateRange,
        requestFilterValues:actions.requestFilterValues,
        addItemsPlaybackView:actions.addItemsPlaybackView,
        removeItemsPlaybackView:actions.removeItemsPlaybackView,
    },dispatch)
});

export default connect(mapStateToProps,mapDispatchToProps)(HistoryPlaybackMap);