import React from "react";
import PropTypes from "prop-types";
import DragLine from "./DragLine";

class TableHeaderDragLine extends React.Component{

    static get propTypes(){
        return {
            onResizeColumn: PropTypes.func,
            onRequestAutoSize: PropTypes.func
        };
    }

    render(){
        return (
            <DragLine
                onResize={this.props.onResizeColumn}
                onDoubleTap={this.props.onRequestAutoSize}
            />
        );
    }
}
export default TableHeaderDragLine;