import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import ActionInfo from '@material-ui/icons/Info';

class BlinkInformation extends React.Component {
    
    render() {
        const { blinksCount, isMaxDefaultSize } = this.props;
        
        const standardButton = {
            display: 'block',
            paddingLeft: '0',
            paddingRight: '0',
            
        };
        // const DISABLED_COLOR = "#aaa";
        // const colorIcon = isMaxDefaultSize?'red':'inherit';
        const style = { color: 'red' };
        
        return (
            
            <div className={'playback-blinks-selected-container'}>
                <div className={'playback-blinks-selected-icon'}>
                    <div style={isMaxDefaultSize ? style : null}>
                        <IconButton className={isMaxDefaultSize ? 'playback-blinks-selected-iconbutton-isMaxDefaultSize' : 'playback-blinks-selected-iconbutton'}
                                    style={standardButton}
                                    disableTouchRipple>
                            <ActionInfo style={{ fontSize: '32' }} />
                        </IconButton>
                        <div className={'playback-blinks-selected'} style={{ height: isMaxDefaultSize ? '79px' : '62px' }}>
                            {!!isMaxDefaultSize && (
                                <div>
                                    <span style={{ fontSize: '14px', fontWeight: 'Bold' }}>Max Blinks Reached</span>
                                </div>
                            )}
                            <div>
                                <span style={{ fontSize: '12px', fontWeight: 'Bold' }}>
                                    Total Blinks:</span><span style={{ fontSize: '14px' }}> {blinksCount?.totalBlinks}
                                </span>
                            </div>
                            <div>
                                <span style={{ fontSize: '12px' }}>
                                    Locate Blinks:</span><span style={{ fontSize: '14px' }}> {blinksCount?.locateBlinks}
                                </span>
                            </div>
                            <div>
                                <span style={{ fontSize: '12px' }}>
                                    No locate Blinks:</span><span style={{ fontSize: '14px' }}> {blinksCount?.noLocateBlinks}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            
            </div>
        );
    }
}

export default BlinkInformation;