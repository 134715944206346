import { useState } from "react";
import StyledDialog from "../../../vss/app/views/util/Dialog";
import { Stepper, Step, StepLabel, Button } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import GridDimensionsComponent from "./GridDimensionsComponent";
import GridAutonamesComponent from "./GridAutonamesComponent";

import "./styles.scss";

const useStylesGrid = makeStyles(() => ({
  root: {
    padding: 0
  }
}));

export default function GridDialog({ grid, onClose, setGridState, onFinish }) {
  const [activeStep, setActiveStep] = useState(0);
  const classes = useStylesGrid();

  const handleStepNavigation = step => {
    if (activeStep + step < 2) {
      setActiveStep(activeStep + step);
    } else {
      onFinish();
      handleOnClose();
    }
  }

  const handleOnClose = () => {
    onClose();
    setTimeout(() => setActiveStep(0), 500); // dialog transition
  }

  const actions = [
    <Button key="action-btn-back" onClick={() => handleStepNavigation(-1)} disabled={activeStep === 0}>Back</Button>,
    <Button key="action-btn-next" onClick={() => handleStepNavigation(1)} style={{ color: '#00779F' }}>
      {activeStep === 1 ? "Finish" : "Next"}
    </Button>
  ]

  return (
    <StyledDialog 
      open={grid.open}
      onClose={handleOnClose}
      actions={actions}
      maxWidth={"xs"}
      title="Draw a Grid of Zones"
      isDraggable
      fullWidth
    >
      <Stepper activeStep={activeStep} className={classes.root}>
        <Step>
          <StepLabel>Select the grid dimensions</StepLabel>
        </Step>
        <Step>
          <StepLabel>Assign grid autonames</StepLabel>
        </Step>
      </Stepper>

      <div>
        {activeStep === 0 ? 
          <GridDimensionsComponent gridMatrix={grid.matrix} setGridMatrix={matrix => setGridState({ matrix })} />
            : 
          <GridAutonamesComponent autoname={grid.autoname} setAutoname={autoname => setGridState({ autoname })} />
        }
      </div>
    </StyledDialog>
  );
}