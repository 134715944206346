import { Draw } from 'ol/interaction';
import { LineString, Polygon } from 'ol/geom';
import { POLYGON_VERTICES } from '../utils';
import GridAreaClass from '../utils/GridAreaClass';
import { Feature } from 'ol';
import { zoneStyle } from './styles';
import VectorSource from 'ol/source/Vector';
import VectorImageLayer from 'ol/layer/VectorImage';
import { getStylingFunction } from '../utils/ZoneGridStyles';
import { getRandomInt } from '../../helpers';
import { getOlStoreKey } from '../helpers';
import { ItemType } from '../../constants';
import { zoneItemToFeature } from './helpers';

export function olZoneGridFactory(Base) {
    class OlZoneGridFactory extends Base {
        drawGrid;
        gridArea;
        gridMatrix;
        gridSet;
        sourceGrid;
        layerGrid;
        featureToDelete;
        
        constructor(mapRef, observer) {
            super(mapRef, observer);
            this.drawGrid = null;
            this.gridArea = null;
            this.gridSet = null;
            this.gridMatrix = [ 3, 3 ];
            this.sourceGrid = null;
            this.layerGrid = null;
            this.featureToDelete = null;
            this.geometryFunction = this.geometryFunction.bind(this);
            this.drawGridInArea = this.drawGridInArea.bind(this);
        }
        
        drawGridInArea(geometry) {
            const area = new GridAreaClass(geometry, this.gridMatrix);
            const [ numRows, numCols ] = area.gridMatrix;
            const increments = area.getGridIncrementsMethod();
            
            const { xIncr_rows, yIncr_rows, xIncr_cols, yIncr_cols } = increments;
            
            const features = [];
            const gridCorner = geometry[0];
            
            for (let i = 0; i < numRows; i++) {
                for (let j = 0; j < numCols; j++) {
                    const cummulatives = [ i * xIncr_rows + j * xIncr_cols, i * yIncr_rows + j * yIncr_cols ];
                    const startPt = [ gridCorner[0] + cummulatives[0], gridCorner[1] + cummulatives[1] ];
                    const coords = area.makePolygonColumnWise(startPt);
                    
                    const feature = new Feature({ geometry: new Polygon([ coords ]) });
                    feature.set('name', `${i + 1}_${j + 1}`); // to get the name further on publish
                    feature.set('color', this.color);
                    // feature.setStyle(getStylingFunction({ defaultColor: this.color, features: this.source.features }));
                    feature.setStyle(zoneStyle(
                        feature,
                        this.color,
                        true,
                        false,
                        this.measurementSystem,
                    ));
                    features.push(feature);
                    this.sourceGrid.addFeature(feature);
                }
            }
            this.gridSet = features;
        }
        
        geometryFunction(coords, geom) {
            if (!geom) {
                geom = new LineString([]);
            } else {
                if (coords.length === POLYGON_VERTICES - 1) {
                    const area = new GridAreaClass.fromArea(coords);
                    this.gridArea = area.outlineArea;
                    geom.setCoordinates(area.outlineArea);
                } else {
                    if (coords.length > POLYGON_VERTICES - 1) {
                        this.drawGridInArea(this.gridArea);
                    }
                    
                    geom.setCoordinates(coords);
                }
            }
            return geom;
        }
        
        initializeDrawZoneGrid() {
            this.gridSet=null;
            this.sourceGrid = new VectorSource();
            const showLabels = false;
            this.layerGrid = new VectorImageLayer({
                source: this.sourceGrid,
                style: (feature) =>
                    zoneStyle(
                        feature,
                        this.color,
                        false,
                        showLabels,
                        this.measurementSystem,
                    ),
            });
            this.layerGrid.set('name','gridLayer');
            this.mapRef.addLayer(this.layerGrid);
            this.drawGrid = new Draw({
                source: this.sourceGrid,
                type: 'LineString',
                style: getStylingFunction({
                    defaultColor: this.color,
                    drawing: true,
                    features: this.source.getFeatures(),
                }),
                maxPoints: 3,
                geometryFunction: this.geometryFunction,
            });
            
            this.mapRef.addInteraction(this.drawGrid);
            
            this.drawGrid.on('drawstart', (event) => {
                const feature = event.feature;
                feature.setId(getRandomInt());
                //feature.on('change', () => this.collisionDetectionEvent([ feature, ...this.featuresCollection?.getArray().filter(feature => this.getFeatureItemType(feature) === ItemType.ZONE) ]));
            });
            
            this.drawGrid.on('drawend', (e) => {
                this.featureToDelete = e.feature;
                const features = this.gridSet;
                this.observer?.executeEvent('onFinishDrawZoneGrid', {
                    features,
                    sourceFeatures: this.featuresCollection.getArray().filter(feature => this.getFeatureItemType(feature) === ItemType.ZONE),
                    open: true,
                });
                this.mapRef.removeInteraction(this.drawGrid);
                this.drawGrid = null;
                //this.collisionDetectionEvent(this.featuresCollection?.getArray().filter(feature => this.getFeatureItemType(feature) === ItemType.ZONE));
            });
            this.drawGrid.on('drawabort', () => {
                //this.collisionDetectionEvent(this.featuresCollection?.getArray().filter(feature => this.getFeatureItemType(feature) === ItemType.ZONE));
            });
        }
        
        setGridMatrix(gridMatrix) {
            this.gridSet?.forEach(feature => this.sourceGrid?.removeFeature(feature));
            this.gridMatrix = gridMatrix;
            if(this.gridArea!=null)
                this.drawGridInArea(this.gridArea);
            const features = this.gridSet;
            this.observer?.executeEvent('onFinishDrawZoneGrid', {
                features,
            });
        }
        
        removeDrawGrid() {

            if (this.gridSet != null) {
                this.gridSet?.forEach(feature => this.sourceGrid?.removeFeature(feature));
            }
            this.mapRef.removeInteraction(this.drawGrid);
            this.drawGrid = null;
        }
        
        isEnabledDrawGrid() {
            return !!this.draw;
        }
        
        finishDrawGrid(zones) {
            this.removeDrawGrid();
            for (const zone of zones) {
                zone.isNew=true;
                this.featuresCollection.push(zoneItemToFeature.bind(this)(zone, getOlStoreKey(ItemType.ZONE)));
            }

            let zonesAfterChange=this.getFeaturesDataArrayByItemType(ItemType.ZONE);
            zonesAfterChange=zonesAfterChange.map(item=>{return {...item,isDraft:false}});

            this.observer?.executeEvent('onSaveZoneChanges', {
                zonesBeforeChanges: this.zones,
                zonesAfterChanges: zonesAfterChange,
                takeASnapshot: true,
                event: 'finishDrawGrid',
                selectCreatedZones: true,
            });
            this.initializeDrawZoneGrid();
        }
    }
    
    return OlZoneGridFactory;
}
