


export const POLYGON_VERTICES = 4;

export const SPACE_BETWEEN_ZONES = 0.01;

/**
 * Checks if the grid is drawn from rtl or ltr
 * @param {Array<number[]>} geometry coordinates from the area drawn
 * @param {Array<number>} gridMatrix matrix dimensions [rows, cols]
 * @returns {Array<number>} reverse the matrix on rtl drawing
 */
export const getGridMatrixOrdered = (geometry, gridMatrix) => {
    const [p1, p2] = geometry;
    if (p1[0] > p2[0]) {
        const [numRows, numCols] = gridMatrix;
        return [numCols, numRows];
    }
    return gridMatrix;
}

/* Math Methods to Draw Rectangle on Certain Angle */
export const getDotProduct = (p1, p2) => p1[0] * p2[0] + p1[1] * p2[1];
export const getCrossProduct = (p1, p2) => p1[0] * p2[1] - p1[1] * p2[0];
export const getVectorDistance = ([dx, dy]) => Math.sqrt(dx * dx + dy * dy);
export const getDiffBetweenTwoPoints = (p1, p2) => [p2[0] - p1[0], p2[1] - p1[1]];

export const getOrientation = (p1, p2, p3) => {
    const long = getDiffBetweenTwoPoints(p1, p2);
    const lang = getDiffBetweenTwoPoints(p1, p3);
    return Math.sign(getCrossProduct(long, lang));
}

export const getPointsAngle = (p1, p2) => {
    const [deltaX, deltaY] = [p2[0] - p1[0], p2[1] - p1[1]];
    const angle = Math.atan2(deltaY, deltaX);
    const degrees = angle * (180 / Math.PI);
    return {
        angle,
        degrees: degrees < 0 ? 360 + degrees : degrees,
    };
}
