import React from 'react';
import PropTypes from 'prop-types';
import supercluster from "supercluster";
//openlayers
import {fromLonLat, toLonLat, transform, transformExtent} from 'ol/proj';
import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import DragBox from 'ol/interaction/DragBox';
import {shiftKeyOnly} from 'ol/events/condition';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import {Image as ImageLayer} from 'ol/layer.js';
import {ImageCanvas as ImageCanvasSource} from 'ol/source.js';


import KDBush from "kdbush";
import Overlay from "ol/Overlay";


/**
 * Displays tags on a map.
 */
class ClusteredTagLayer extends React.Component{

    static get propTypes(){
        return {
            tags: PropTypes.arrayOf(PropTypes.shape({
                raw: PropTypes.shape({
                    ___uid: PropTypes.any.isRequired
                }).isRequired,
                x: PropTypes.number,
                y: PropTypes.number
            })).isRequired,
            showTagLabels:PropTypes.bool,
            reportId: PropTypes.string,
            highlightedItemMap: PropTypes.any,
            updateClusterIndexAction:PropTypes.func,
            siteId:PropTypes.any,
            mapId:PropTypes.any,
        };
    }

    constructor(props){

        super(props);

        this.clusterizePoints=this.clusterizePoints.bind(this);
        this.calculateParamsSupercluster=this.calculateParamsSupercluster.bind(this);
        this.itemStyle=this.itemStyle.bind(this);
        this.handleMoveEnd=this.handleMoveEnd.bind(this);
        this.handleSingleClick=this.handleSingleClick.bind(this);
        // this.handleDragbox=this.handleDragbox.bind(this);
        this.canvasFunction=this.canvasFunction.bind(this);
        this.addCanvasLayer=this.addCanvasLayer.bind(this);
        // this.handleMoveElements=this.handleMoveElements.bind(this);
        // this.handleRenderComplete=this.handleRenderComplete.bind(this);
        this.drawCanvasLayer=this.drawCanvasLayer.bind(this);
        this.checkIndexClick=this.checkIndexClick.bind(this);

        // shift + drag mouse
        this.buildHtmlObject = this.buildHtmlObject.bind(this);
        this.addPopupOverlay = this.addPopupOverlay.bind(this);
        this.calculatePositionPopup = this.calculatePositionPopup.bind(this);
        this.hidePopup = this.hidePopup.bind(this);
        this.updatePopup = this.updatePopup.bind(this);
        this.addDragEvent = this.addDragEvent.bind(this);
        this.selectClickedItems = this.selectClickedItems.bind(this);

        this.starCluster=100;
        this.maxZoom=6;
        this.indexCluster=null;
        this.indexNonCluster=null;
        this.popupOpen=false;

        this.state={
            index:null,
            siteId:null,
            mapId:null,
            pixelPerUnit:null,
            itemSelectedPopUp:null
        };

    }

    componentDidMount() {
        // TODO: Validate the current map is local map.
        this.map=window.MY_MAP;
        this.addCanvasLayer();
        this.addDragEvent();
        this.map.on("moveend",this.handleMoveEnd);
        this.map.on("singleclick",this.handleSingleClick);
        // this.map.on("postcompose",this.handleMoveElements);
        // this.map.on("rendercomplete",this.handleRenderComplete);

        this.addPopupOverlay();
        this.map.on("pointerdown",() => { this.hidePopup() });

    }

    // componentWillReceiveProps(nextProps) {
    //
    //     if (this.props.data !== nextProps.data
    //         || this.props.mapConfiguration !== nextProps.mapConfiguration
    //         ||this.props.highlightedItemMap!==nextProps.highlightedItemMap) {
    //
    //         const tileParams = this.getTileParams();
    //
    //         if(this.props.mapConfiguration !== nextProps.mapConfiguration ||this.props.data !== nextProps.data){
    //             this.index = this.createIndex(nextProps.tags, nextProps.mapConfiguration);
    //
    //             nextProps.updateClusterIndexAction(this.index, nextProps.mapConfiguration);
    //             this.indexCluster=null;
    //             this.indexNonCluster=null;
    //         }
    //
    //         if (this.state.siteId === tileParams.siteId && this.state.mapId === tileParams.mapId) {
    //
    //
    //             console.log("componentWillReceiveProps");
    //             // Draw canvas layer.
    //             this.drawCanvasLayer(this.index,nextProps.tags,nextProps);
    //
    //             // Update shift + drag popup.
    //             this.updatePopup();
    //
    //         }
    //         // else{
    //         //     this.setState({index: null});
    //         // }
    //     }
    //     return true;
    // }

    componentDidUpdate(prevProps,prevState){
        this.map = window.MY_MAP;
        const overlays = this.map.getOverlayById("popupItemSelector");
        if(overlays==null)
        {
            this.addPopupOverlay();
            this.addDragEvent();
            //this.map.getTargetElement().addEventListener("mousedown",this.handleMouseDown);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (
            (this.props.mapConfiguration !== nextProps.mapConfiguration)
            || (this.props.showTagLabels !== nextProps.showTagLabels)
            || (this.props.highlightedItemMap !== nextProps.highlightedItemMap)
            || (this.props.data !== nextProps.data)
            || (this.state.siteId !== nextState.siteId || this.state.mapId !== nextState.mapId)
            || (this.props.selected !== nextProps.selected)) {

            const tileParams = this.getTileParams();
            if ((this.state.siteId === tileParams.siteId && this.state.mapId === tileParams.mapId) 
                || this.props.selected !== nextProps.selected || this.props.highlightedItemMap !== nextProps.highlightedItemMap) {

                this.props.setPlaybackMapId(this.props.localMapInfo.localMapId);
                this.props.setPlaybackInfo(this.props.localMapInfo.site.id, this.props.localMapInfo.site.name);

                // If the data changes or map changes, update the index.
                if (this.props.mapConfiguration !== nextProps.mapConfiguration
                    || this.props.data !== nextProps.data) {
                    this.index = this.createIndex(nextProps.tags, nextProps.mapConfiguration);
                    nextProps.updateClusterIndexAction(this.index, nextProps.mapConfiguration);
                    this.indexCluster = null;
                    this.indexNonCluster = null;
                }

                // Render again if labels or othe special styles are activated by the user.
                if (this.props.showTagLabels !== nextProps.showTagLabels
                    || this.props.highlightedItemMap !== nextProps.highlightedItemMap
                    || this.props.data !== nextProps.data
                    || this.props.selected !== nextProps.selected) {
                    // Draw canvas layer.
                    this.drawCanvasLayer(this.index, nextProps.tags, nextProps);
                    // Update shift + drag popup.
                    this.updatePopup();

                }
            }
            else{
                // this.hidePopup();
            }
            
            return true;
        }

        return false;
    }

    componentWillUnmount() {
        this.map.un("moveend", this.handleMoveEnd);
        this.map.un("singleclick", this.handleSingleClick);
        //this.map.un("postcompose",this.handleMoveElements);
        //this.map.un("rendercomplete",this.handleRenderComplete);
        // this.map.un("pointerdown",this.handleDragbox);
    }

    // handleRenderComplete(){
    //     // console.log("handleRenderComplete");
    // }

    // handleMoveElements(event){
    // }

    handleSingleClick(e){

        let x=e.coordinate[0];
        let y=e.coordinate[1];

        this.checkIndexClick();

        const resolution = this.map.getView().getResolution();
        const foundNonClusters = this.indexNonCluster.within(x, y, 6*resolution);
        const foundClusters = this.indexCluster.within(x, y, 12*resolution);

        const nonClusterItems=foundNonClusters.map(indexItem=>this.indexNonCluster.points[indexItem]);
        const clusterItems=foundClusters.map(indexItem=>this.indexCluster.points[indexItem]);

        if((nonClusterItems!=null&&nonClusterItems.length>0)||(clusterItems!=null&&clusterItems.length>0)) {
            this.selectClickedItems(nonClusterItems, clusterItems);
        }
    }



    handleMoveEnd(){
        if(this.props.mapConfiguration && Object.keys(this.props.mapConfiguration).length > 0){
            let stateParams=this.getTileParams();
            // Create the index after the map is loaded and render the canvas.
           if((this.state.siteId !== stateParams.siteId||this.state.mapId !== stateParams.mapId)||this.index==null||this.state.pixelPerUnit !== stateParams.pixelPerUnit){
               this.index = this.createIndex(this.props.tags, this.props.mapConfiguration);
               this.props.updateClusterIndexAction(this.index, this.props.mapConfiguration);
           }

            this.drawCanvasLayer(this.index,this.props.tags,this.props);
            // Update shift + drag popup.
            this.updatePopup();
            this.setState(stateParams);
        }


        //const newZoom=this.map.getView().getZoom();

        // if(newZoom!=this.currentZoomPopup||this.props.localMapInfo.localMapId!=this.currentMapPopup) {
        //     this.currentZoomPopup=null;
        //     this.currentMapPopup=null;
        //     this.hidePopup();
        // }
    }

    /** added shift + drag to map**/
    addDragEvent() {

        // Override interaction with shift key.
        var dragBox = new DragBox({
            condition: shiftKeyOnly
        });

        this.map.addInteraction(dragBox);
        this.layer=null;

        dragBox.on('boxstart',(e) => {
            this.hidePopup();
        });

        dragBox.on('boxend', (e) => {
            let selectedItems = [];

            // Get the current dragbox coordinates.
            let coordinates = dragBox.getGeometry().getCoordinates();

            let rectangle = new Feature(
                new Polygon(coordinates));

            this.layerSeletedItems = new VectorLayer({
                source: new VectorSource({
                    features: [rectangle]
                }),
                style: new Style({
                    stroke: new Stroke({
                        width: 1,
                        color: [0, 0, 0, 1]
                    }),
                    fill: new Fill({
                        color: [127, 166, 59, 0.3]
                    })
                })
            });

            this.extentSelected = dragBox.getGeometry().getExtent();

            const minX = this.extentSelected[0];
            const minY = this.extentSelected[1];
            const maxX = this.extentSelected[2];
            const maxY = this.extentSelected[3];

            this.checkIndexClick();

            const foundClusters = this.indexCluster.range(minX,minY,maxX,maxY);
            const foundNonClusters = this.indexNonCluster.range(minX,minY,maxX,maxY);


            const nonClusterItems=foundNonClusters.map(indexItem=>this.indexNonCluster.points[indexItem]);
            const clusterItems=foundClusters.map(indexItem=>this.indexCluster.points[indexItem]);

            if((nonClusterItems!=null&&nonClusterItems.length>0)||(clusterItems!=null&&clusterItems.length>0)) {
                selectedItems=selectedItems.concat(nonClusterItems,clusterItems);
            }

            if(selectedItems.length>0) {
                this.map.addLayer(this.layerSeletedItems);

                this.calculatePositionPopup(this.extentSelected);

                this.showPopupItems(selectedItems,true,true);
            }
        });
    }

    checkIndexClick() {

        if (this.indexCluster == null || this.indexNonCluster == null) {

            this.indexCluster = new KDBush(this.clustersData, p => p.x, p => p.y, 64, Float64Array);
            this.indexNonCluster = new KDBush(this.nonClustersData, p => p.x, p => p.y, 64, Float64Array);

        }
    }

    getTileParams(){

        const pixelPerUnit = this.map.getView().getResolutionForZoom(0);

        let tileParams={};

        if(this.map!=null) {
            this.map.getLayers().forEach(layer => {
                if (layer.get("siteId")!=null&&layer.get("mapId")!=null) {
                    tileParams = {siteId: layer.get("siteId"), mapId: layer.get("mapId"), pixelPerUnit};
                }
             });
        }

        return tileParams;
    }

    //region Clusterize

    /**
     * Creates the index of cluster.
     * @param pointsArray
     * @returns {*}
     */
    createIndex(pointsArray,mapConfiguration){

        if(pointsArray!=null&&pointsArray.length>0&&mapConfiguration!=null) {

            // Number of items in the array.
            const count = pointsArray.length;

            const superclusterParams=this.calculateParamsSupercluster(count,mapConfiguration);

            // Create format required for "supercluster".
            let featureCollection = {
                "type": "FeatureCollection",
                "features": []
            };

            // Add features to "featureCollection".
            for (var i = 0; i < count; ++i) {
                const feature = {
                    "type": "Feature",
                    "properties": {
                        "name": "point_" + i,
                        "index": i,
                        "selected":(pointsArray[i].selected===true)?1:0,
                        "___uid":pointsArray[i].raw.___uid,
                        "___index":pointsArray[i].raw.___index,
                        "label":pointsArray[i].label
                    },
                    "geometry": {
                        "type": "Point",
                        "coordinates": transform([pointsArray[i].x, pointsArray[i].y], 'EPSG:3857', 'EPSG:4326')// transform to required coordinates.
                    }
                };
                featureCollection.features.push(feature);
            }

            // Creates supercluster instance.
            let index = new supercluster({
                radius:superclusterParams.radius,
                maxZoom: superclusterParams.maxZoom,
                extent:superclusterParams.extent,
                initial: function() { return {selected: 0,items:[]}; },
                map: function(props) { return {selected: props.selected,items:{___uid:props.___uid,label:props.label,___index:props.___index}}; },
                reduce: function(accumulated, props) {
                    accumulated.selected+=props.selected;
                    accumulated.items=accumulated.items.concat(props.items);
                }
            });

            // Loads features into "supercluster" instance.
            index.load(featureCollection.features);

            return index;
        }
        else return null;
    }

    limitZoom(zoom) {
        return Math.max(this.options.minZoom, Math.min(zoom, this.options.maxZoom + 1));
    }

    /**
     * Return the points (clusterized or not) in the visible area.
     * @param pointsArray
     * @param map
     * @returns {Array}
     */
    clusterizePoints(index,pointsArray,map){

        let clusteredItems=[];

        if(index!=null&&map!=null&&map.getView()!=null) {

            // Reset clusters for click.
            this.indexCluster=null;
            this.indexNonCluster=null;

            // Calculates the limits of current visible area of map.
            const bounds = transformExtent(map.getView().calculateExtent((map.getSize())),'EPSG:3857','EPSG:4326');

            // Gets clustered items.
            let clusterArray = null;
            const zoom=Math.round(map.getView().getZoom());
            clusterArray=index.getClusters(bounds, zoom);

            if(clusterArray!=null&&clusterArray.length>0) {

                clusterArray.forEach(item => {

                    const id=item.properties.cluster_id;
                    let label='';
                    let raw= {___uid:'cluster_'+item.properties.cluster_id};
                    let selected=false;
                    let selectedCount=item.properties.selected;
                    let clusterCount=item.properties.point_count;
                    let map=null;
                    let recent=false;
                    let items=item.properties.items;

                    if(item.properties.cluster!==true)
                    {
                        label=pointsArray[item.properties.index].label;
                        raw= pointsArray[item.properties.index].raw;
                        selected=pointsArray[item.properties.index].selected;
                        map=pointsArray[item.properties.index].map;
                        selectedCount=null;
                        clusterCount=null;
                        recent=pointsArray[item.properties.index].recent

                    }

                    const coord= fromLonLat(item.geometry.coordinates);
                    clusteredItems.push( {
                        id:id,
                        label: label,
                        cluster:item.properties.cluster,
                        point_count: item.properties.point_count,
                        point_count_abbreviated: item.properties.point_count_abbreviated,
                        map: map,
                        raw:raw,
                        selected: selected,
                        x: coord[0],
                        y: coord[1],
                        selectedCount:selectedCount,
                        clusterCount:clusterCount,
                        recent: recent,
                        items:items
                    });
                });

            }
            else {

                pointsArray.forEach(item => {

                    const id = item.raw.___uid;

                    let label = item.label;
                    let raw = item.raw;
                    let selected = item.selected;
                    let map = item.map;
                    let recent = item.recent


                    clusteredItems.push({
                        id: id,
                        label: label,
                        cluster: false,
                        point_count: null,
                        point_count_abbreviated: null,
                        map: map,
                        raw: raw,
                        selected: selected,
                        x: item.x,
                        y: item.y,
                        selectedCount: null,
                        clusterCount: null,
                        recent: recent,
                        items: null
                    });
                });
            }
        }

        return clusteredItems;
    }
    /**
     * Calculates the params for clusterize the points in base of map size and scale.
     * @param count
     * @returns {{radius: number, maxZoom: number, extent: number}}
     */
    calculateParamsSupercluster(count,mapConfiguration){

        const map = window.MY_MAP;

        const pixelPerUnit = map.getView().getResolutionForZoom(0);

        const pixelPerUnitLonLat = toLonLat([pixelPerUnit,0])[0];
        const radioOfClusters=13;
        this.options={};
        this.options.radius=pixelPerUnitLonLat*radioOfClusters;
        this.options.extent=256;

        let params={radius:pixelPerUnitLonLat*radioOfClusters,maxZoom: this.maxZoom,extent:256};

        // If just few items to show don't cluster.
        if(count<=this.starCluster)
            params.maxZoom=-1;

        return params;
    }

    /**
     * Return styles for a current item.
     * @param item
     * @returns {Style}
     */
    itemStyle(item,props){
        let { realTime, reportId,highlightedItemMap,showTagLabels } = props;
        const highlightColor='#00FF2A';
        let strokeWidth=1;
        let label=null;
        let textCluster=null;

        let radius=5;
        let fillColor='#0f0';
        let strokeColor='#0f0';
        if(item.cluster===true){
            radius=12;
            fillColor='#277D9F';
            strokeColor='#277D9F';

            if(item.selectedCount>0){

                const colorIntensity=item.selectedCount/item.clusterCount;
                const hue=((1-colorIntensity)*40).toString(10);

                const hueColor=["hsl(",hue,",100%,50%)"].join("");
                fillColor=hueColor;
                strokeColor=hueColor;
            }


            if(item.id===highlightedItemMap){
                strokeColor=highlightColor;
                radius+=strokeWidth;
                strokeWidth=3;

            }


            if (item.hasOwnProperty('point_count_abbreviated')&&item.point_count_abbreviated!=null)
                textCluster = item.point_count_abbreviated;
        }
        else{
            if(item.selected===true)
                fillColor='red';
            else if(realTime && realTime[reportId] === true && item.recent === true && item.raw!=null && item.raw.locating!=null && item.raw.locating==="yes")
                fillColor='green';
            else if(realTime && realTime[reportId] === true && item.recent === true && item.raw!=null && item.raw.locating!=null && item.raw.locating==="no")
                fillColor='#fda500';
            else
                fillColor='yellow';

            strokeColor='red';

            if(showTagLabels===true)
                label=item.label;

            if(item.raw.___uid===highlightedItemMap){
                strokeColor=highlightColor;
                radius+=strokeWidth;
                strokeWidth=3;

            }
        }

        return {
            radius:radius,
            fillColor:fillColor,
            strokeColor:strokeColor,
            strokeWidth:strokeWidth,
            textArc:textCluster,
            label:label,
        }

    }
    //endregion


    // Main render.
    render(){
        return null;
    }

    // Canvas render functions

    addCanvasLayer() {
        this.canvasLayer = new ImageLayer({
            source: new ImageCanvasSource({
                canvasFunction: this.canvasFunction,
                projection: this.map.getView().getProjection()
                //projection: "EPSG:4326"
            })
        });
        this.canvasLayer.setZIndex(10);
        this.map.addLayer(this.canvasLayer);
    }

    canvasFunction(extent, resolution, pixelRatio, size, projection) {
        this.extent = extent;
        this.pixelRatio = pixelRatio;
        if (this.canvasWidth !== size[0] || this.canvasHeight !== size[1] || this.canvas == null) {
            this.canvasWidth = size[0];
            this.canvasHeight = size[1];

            const canvas = document.createElement('canvas');
            canvas.width = size[0];
            canvas.height = size[1];
            const canvasContext = canvas.getContext('2d');

            this.canvas = canvas;
            this.canvasContext = canvasContext;
        }

        let stateParams=this.getTileParams();

        if((this.state.siteId === stateParams.siteId&&this.state.mapId === stateParams.mapId)&&this.index!=null){
            this.drawCanvasLayer(this.index,this.props.tags,this.props);
        }

        return this.canvas;
    }

    drawCanvasLayer(index,tags,props) {

        const clusteredTags = this.clusterizePoints(index,tags, this.map);

        if (this.map != null&&this.canvasContext!=null) {
            const mapExtent = this.map.getView().calculateExtent(this.map.getSize());
            const canvasOrigin = this.map.getPixelFromCoordinate([this.extent[0], this.extent[3]]);
            const mapOrigin = this.map.getPixelFromCoordinate([mapExtent[0], mapExtent[3]]);
            const delta = [mapOrigin[0] - canvasOrigin[0], mapOrigin[1] - canvasOrigin[1]];

            this.clustersData = [];
            this.nonClustersData = [];
            const data = clusteredTags.map((blinkInfo,index) => {
                const coordinatesPixel = (blinkInfo.x && blinkInfo.y)? this.map.getPixelFromCoordinate([blinkInfo.x, blinkInfo.y]) : [null, null];
                const blinkInfoForIndex={x:blinkInfo.x,y:blinkInfo.y,cluster:null,___uid:null,___index:null,indexInArray:index};
                if (blinkInfo.cluster === true) {
                    blinkInfoForIndex.clusterId=blinkInfo.id;
                    blinkInfoForIndex.cluster=blinkInfo.cluster;
                    blinkInfoForIndex.items=blinkInfo.items;
                    this.clustersData.push(blinkInfoForIndex);
                }
                else {
                    blinkInfoForIndex.___uid=blinkInfo.raw.___uid;
                    blinkInfoForIndex.___index=blinkInfo.raw.___index;
                    blinkInfoForIndex.label=blinkInfo.label;
                    this.nonClustersData.push(blinkInfoForIndex);
                }

                const style = this.itemStyle(blinkInfo,props);
                if(blinkInfo.raw!=null&&blinkInfo.raw.locating!=null&&blinkInfo.raw.locating==="no")
                    style.fillColor="#fda500";
                return {
                    x: coordinatesPixel[0] ? coordinatesPixel[0] * this.pixelRatio + delta[0] * this.pixelRatio : coordinatesPixel[0],
                    y: coordinatesPixel[1] ? coordinatesPixel[1] * this.pixelRatio + delta[1] * this.pixelRatio : coordinatesPixel[1],
                    // x: coordinatesPixel[0],
                    // y: coordinatesPixel[1],
                    radius: style.radius,
                    fillColor: style.fillColor,
                    strokeColor: style.strokeColor,
                    strokeWidth: style.strokeWidth,
                    textArc: style.textArc,
                    label: style.label,
                }
            });

            // console.log("draw canvas");
            this.canvasContext.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
            this.drawArc(this.canvasContext, data);
            this.canvasLayer.changed();
        }
    }

    drawArc(context,data){

        const endAngle=2*Math.PI;
        const font='12px sans-serif,helvetica';

        context.font = font;

        data.forEach(item=> {

            context.fillStyle = item.fillColor;
            context.beginPath();
            context.arc(item.x, item.y, item.radius, 0, endAngle);
            context.closePath();
            context.fill();
            context.lineWidth = item.strokeWidth;
            context.strokeStyle = item.strokeColor;
            context.stroke();
            if(item.textArc!=null) {
                context.fillStyle = "white";
                context.textBaseline = 'middle';
                context.textAlign = "center";
                context.fillText(item.textArc, item.x, item.y);
            }

            if(item.label!=null) {

                // context.fillStyle = "yellow";
                // context.fillRect(item.x, item.y-item.radius-15, context.measureText(item.label).width, 15);
                context.strokeStyle = 'yellow';
                context.lineWidth = 8;
                context.lineJoin="round";
                context.miterLimit=1;
                context.textBaseline = 'bottom';
                context.textAlign = "center";
                context.strokeText(item.label, item.x, item.y-item.radius-4);
                context.fillStyle = "black";
                context.fillText(item.label, item.x, item.y-item.radius-4);
            }
        });
    }

    // popup select tags
    calculatePositionPopup(extentSelected){

        let coordinates=[0,0];
        const extentMap=this.map.getView().calculateExtent((this.map.getSize()));

        // Convert to openlayers coordinates to pixels. (the origin (0,0) of pixels is the left upper corner)
        const selectedLeftBottomPixel=this.map.getPixelFromCoordinate([extentSelected[0],extentSelected[1]]);
        const selectedRightTopPixel=this.map.getPixelFromCoordinate([extentSelected[2],extentSelected[3]]);
        const mapLeftBottomPixel=this.map.getPixelFromCoordinate([extentMap[0],extentMap[1]]);
        const mapRightTopPixel=this.map.getPixelFromCoordinate([extentMap[2],extentMap[3]]);


        const selectedTop=selectedRightTopPixel[1];
        const selectedBottom=selectedLeftBottomPixel[1];
        const selectedLeft=selectedLeftBottomPixel[0];
        const selectedRight=selectedRightTopPixel[0];

        const mapTop=mapRightTopPixel[1];
        const mapBottom=mapLeftBottomPixel[1];
        const mapLeft=mapLeftBottomPixel[0];
        const mapRight=mapRightTopPixel[0];


        const distanceTop=selectedTop-mapTop;
        const distanceRight=mapRight-selectedRight;
        const distanceBottom=mapBottom-selectedBottom;
        const distanceLeft=selectedLeft-mapLeft;

        const maxWidthPopup=150;
        const maxHeightPopup=217// 202+5+10=202 popup max height + 5 arrow height+10 overflow of map;
        //const widthSelect=selectedRight-selectedLeft;

        if(distanceTop>maxHeightPopup){
            coordinates=[((extentSelected[2]-extentSelected[0])/2)+extentSelected[0], extentSelected[3]];
            this.overlay.setPositioning('bottom-center');
            this.overlay.setPosition(coordinates);
            this.popupContainer.className = 'popup-map-multiple-selected-container popup-arrow-bottom-active';
        }
        else if(distanceBottom>maxHeightPopup){
            coordinates=[((extentSelected[2]-extentSelected[0])/2)+extentSelected[0], extentSelected[1]];
            this.overlay.setPositioning('top-center');
            this.overlay.setPosition(coordinates);
            this.popupContainer.className = 'popup-map-multiple-selected-container popup-arrow-top-active';
        }
        else if(distanceRight>maxWidthPopup){
            coordinates=[extentSelected[2],((extentSelected[3]-extentSelected[1])/2)+extentSelected[1]];
            this.overlay.setPositioning('center-left');
            this.overlay.setPosition(coordinates);
            this.popupContainer.className = 'popup-map-multiple-selected-container popup-arrow-right-active';
        }
        else if(distanceLeft>maxWidthPopup){
            coordinates=[extentSelected[0],((extentSelected[3]-extentSelected[1])/2)+extentSelected[1]];
            this.overlay.setPositioning('center-right');
            this.overlay.setPosition(coordinates);
            this.popupContainer.className = 'popup-map-multiple-selected-container popup-arrow-left-active';
        }
        else{
            const coordinatesBottom=this.map.getCoordinateFromPixel([selectedRight,selectedBottom]);
            coordinates=[((extentSelected[2]-extentSelected[0])/2)+extentSelected[0], coordinatesBottom[1]];
            this.overlay.setPositioning('bottom-center');
            this.overlay.setPosition(coordinates);
            this.popupContainer.className = 'popup-map-multiple-selected-container popup-arrow-bottom-active';
        }
    }

    showPopupItems(selectedItems,updateTable,updateZoom){

        if (this.overlay != null && this.overlayContent != null) {
            //const {clusterIndex,data} = this.props;
            // Format html for popup.
            let count =0;
            let htmlObject=null;

            htmlObject = document.createElement('div');
            htmlObject.id = 'popupOl';

            let uniqueIds = [];

            selectedItems.forEach(item => {
                if (item.cluster === true) {
                    const clusterId=item.clusterId;


                    // TODO: Remove catch logic when supercluster library "getLeaves" is fixed.
                    // try {
                    //     items = clusterIndex.getLeaves(item.id, Infinity, 0);
                    // }
                    // catch (e) {
                    //     console.log("this.props",this.props);
                    // }

                    const items=item.items;
                    items.forEach((item, i) => {
                        const idItem = item.___uid;
                        const labelItem = item.label;
                        uniqueIds.push(idItem);

                        htmlObject.appendChild(this.buildHtmlObject("", labelItem, idItem, clusterId, this.state.itemSelectedPopUp === idItem));
                        htmlObject.appendChild(document.createElement('br'));
                        count++;

                    });

                } else {

                    if (item != null && item.hasOwnProperty("label")) {
                        const label = item["label"];
                        const idItem = item.___uid;
                        uniqueIds.push(idItem);

                        htmlObject.appendChild(this.buildHtmlObject("", label, idItem, idItem, this.state.itemSelectedPopUp === idItem));
                        htmlObject.appendChild(document.createElement('br'));
                        count++;
                    }
                }
            });

            // Render popup.
            this.overlayContent.innerHTML="";
            if (count>0) {
                this.overlayContent.appendChild(htmlObject);
                this.popupOpen=true;
            }

            // Keep the current zoom and map.
            if(updateZoom===true)
                this.currentZoomPopup=this.map.getView().getZoom();
            this.currentMapPopup=this.props.localMapInfo.localMapId;

            if(updateTable===true) {
                //select the items of the cluster
                this.props.selectManyRows(uniqueIds, false);
            }
        }
    }

    updatePopup(){
        if(this.popupOpen===true&&this.extentSelected!=null&&this.layerSeletedItems){

            let selectedItems=[];

            this.indexCluster = null;
            this.indexNonCluster = null;

            this.checkIndexClick();

            const minX = this.extentSelected[0];
            const minY = this.extentSelected[1];
            const maxX = this.extentSelected[2];
            const maxY = this.extentSelected[3];


            const foundClusters = this.indexCluster.range(minX,minY,maxX,maxY);
            const foundNonClusters = this.indexNonCluster.range(minX,minY,maxX,maxY);

            const nonClusterItems=foundNonClusters.map(indexItem=>this.indexNonCluster.points[indexItem]);
            const clusterItems=foundClusters.map(indexItem=>this.indexCluster.points[indexItem]);

            if((nonClusterItems!=null&&nonClusterItems.length>0)||(clusterItems!=null&&clusterItems.length>0)) {
                selectedItems=selectedItems.concat(nonClusterItems,clusterItems);
            }

            //hiding pop up if no exists selected items
            if(selectedItems.length>0) {
                this.showPopupItems(selectedItems,false,false);
            }else{
                this.hidePopup();
            }
        }
    }

    hidePopup(event){
        if(event!=null) {
            if (document.getElementById('popupOl') != null && !document.getElementById('popupOl').contains(event.target)) {
                this.overlay.setPosition(undefined);
                event.preventDefault();
            }
        } else{
            this.overlay.setPosition(undefined);
        }

        this.popupOpen=false;

        // Remove highlight of selected item in map.
        this.props.highlightItemMap(null);

        if(this.layerSeletedItems!=null){
            this.map.removeLayer(this.layerSeletedItems);
        }

    }

    addPopupOverlay() {

        const overlays = this.map.getOverlayById("popupItemSelector");

        if (overlays == null) {

            this.popupContainer = document.createElement('div');
            this.popupContainer.className = 'popup-map-multiple-selected-container';
            const arrowBottom = document.createElement('div');
            arrowBottom.className = 'popup-arrow-bottom';
            const arrowTop = document.createElement('div');
            arrowTop.className = 'popup-arrow-top';
            const arrowRight = document.createElement('div');
            arrowRight.className = 'popup-arrow-right';
            const arrowLeft = document.createElement('div');
            arrowLeft.className = 'popup-arrow-left';

            const overlayContainer = document.createElement('div');
            this.popupContainer.appendChild(arrowTop);
            this.popupContainer.appendChild(arrowRight);
            this.popupContainer.appendChild(overlayContainer);
            this.popupContainer.appendChild(arrowLeft);
            this.popupContainer.appendChild(arrowBottom);

            this.overlayContainer = overlayContainer;
            this.overlayContainer.className = 'popup-map-multiple-selected';

            this.overlayCloser = document.createElement('a');
            this.overlayCloser.className = 'ol-popup-closer';
            this.overlayCloser.href = '#';
            this.overlayContainer.appendChild(this.overlayCloser);


            this.overlayCloser.addEventListener('click', (evt)=> {
                this.hidePopup(evt);
            }, false);

            this.overlayContent = document.createElement('div');
            this.overlayContent.className = 'ol-popup-content';
            this.overlayContainer.appendChild(this.overlayContent);

            /**
             * Create an overlay to anchor the popup to the map.
             */
            this.overlay = new Overlay({
                id: 'popupItemSelector',
                element: this.popupContainer,
                autoPan: false,
                autoPanAnimation: {
                    duration: 250
                }
            });


            this.map.addOverlay(this.overlay);
        }
    }

    buildHtmlObject(key, value, elementId, highlightId, highlightText) {

        const containerElement = document.createElement('span');
        const nameElement = document.createElement('b');
        nameElement.innerText = key;
        const contentElement = document.createElement('span');
        contentElement.innerText = value;

        contentElement.className = highlightText ? " maps-popup-selected-element" : "maps-popup-clickable-element";
        contentElement.addEventListener("click", (evt)=>{
            this.setState({itemSelectedPopUp: elementId});
            this.handleSelectTag(elementId,highlightId);
            evt.preventDefault();
        }, false);

        containerElement.appendChild(nameElement);
        containerElement.appendChild(contentElement);

        return containerElement;
    }

    handleSelectTag(elementId,highlightId) {

        const {selectManyRows,openRightSidebar}=this.props;

        selectManyRows([elementId], false);
        openRightSidebar(elementId,highlightId);
    }

    selectClickedItems(items, clusters) {
        let uniqueIds = [];
        let lastObjectSelected = null;

        // Non clustered objects.
        items.forEach(item => {

            uniqueIds.push(item.___uid);

            // Keep last item in the list.
            if (lastObjectSelected == null || item.___index > lastObjectSelected.___index) {
                lastObjectSelected = item;
            }
        });

        // Clustered objects.
        clusters.forEach(cluster => {

            cluster.items.forEach(item => {
                uniqueIds.push(item.___uid);

                // Keep last item in the list.
                if (lastObjectSelected == null || item.___index > lastObjectSelected.___index) {
                    lastObjectSelected = item;
                }
            });
        });

        // Jump and highlight element in table.
        this.props.onItemSelected(lastObjectSelected);

        // Select and unselect clusters/tags.
        this.props.selectCluster(uniqueIds, lastObjectSelected);
    }
}

export default ClusteredTagLayer;
