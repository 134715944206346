import React from 'react';
import RightArrow from '@material-ui/icons/ChevronRight';
import LeftArrow from '@material-ui/icons/ChevronLeft';
import RestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import moment from 'moment';
import FiltersDropDown from '../toolbars/FiltersDropDown';
import {generateComponentId} from '../../../utils/ComponentUtils';
import {FILTER_TYPE, getDefaultValue, isDefaultValue, PERIOD} from '../../util/report/filter/util';
import Resizer from './Resizer';
import ClassedIconButton from "../toolbars/ClassedIconButton";
//import SearchBy from "./SearchBy";
import {historyReports/*, OBJECT_ID, RESOURCE_ID, TAG_ID*/} from "../../../constants/History";
import MultiSelectIcon from '../../icons/MultiSelectIcon';
import { useI18n } from '../../../hooks/i18n';

function NavigationArrow(props) {
    if (!props.collapsed) {
        return <LeftArrow />;
    }
    return <RightArrow />;
}

function Filter(props) {
    const { translate } = useI18n('common.filterTable');
    const {column, content, highlightFilter/*, isEnabled*/} = props;
    //return <div id={column.filter} className={"filter"} style={isEnabled?{pointerEvents:'none', opacity:'0.4'}:{}} >
    return <div id={column.filter} className={"filter"} >
        <span
            className={highlightFilter ? 'highlight' : ''}
            title={highlightFilter ? 'This filter has a value other than default.' : ''}
        >
            {translate(column.displayName)}
        </span>
        {content && content.props.filter && content.props.filter.type === 'multiselect' ? <div
        style={{position: "relative",
            float: "right",
            display: "inline-block"}}
        >
            <div style={{
                position: "absolute",
                width: "18px",
                height: "18px",
                right: "10px"}}><MultiSelectIcon width={18} height={18}/></div>
        </div> : null}
        {content}
    </div>;
}

function HeaderOptions(props) {
    const listChildren = props.children.map((item, key) => <li key={key}>{item}</li>)
    if (!props.collapsed) {
        return <ul>
            {listChildren}
        </ul>;
    }
    return <span />;
}

function Header(props) {
    const { filters, actions, resetHandler, nonDefaultFilters } = props;
    //const NOP = () => {};

        return <div className="sidebar-header">
        <button
            id={generateComponentId("reset_filter", "lsd", "button")}
            title="Toggle Filters"
            className="toggle-button"
            onClick={(e)=>props.onToggleClicked(e)}
        >
            <NavigationArrow collapsed={props.collapsed} />
        </button>

        <HeaderOptions collapsed={props.collapsed}>
            <FiltersDropDown
                filters={filters}
                moveFilter={actions.moveFilter}
                toggleFilter={actions.toggleFilter}
                toggleAllFilters={actions.toggleAllFilters}
            />
            <ClassedIconButton
                id={generateComponentId("reset_filter", "lsd", "button")}
                style={{padding:'0px', backgroundColor: 'transparent'}}
                className="material-button"
                onClick={resetHandler}
                title="Reset Filters"
                disableRipple
            >
                <RestoreIcon />
            </ClassedIconButton>
            {nonDefaultFilters > 0 && <div className="filter-label">
                <span>{nonDefaultFilters} filters</span>
            </div>}
        </HeaderOptions>
    </div>;
}


class TableFilters extends React.Component {
    constructor(props) {
        super(props);

        this.resetHandler = this.resetHandler.bind(this);
        this.scrollHandler = this.scrollHandler.bind(this);
        this.getNonDefaultFiltersCount = this.getNonDefaultFiltersCount.bind(this);
        //this.handleType = this.handleType.bind(this);
        this.state = {
            resourceId: '',
            tagId:'',
            objectId: '',
            isChecked:'',
            reportIndex:''
        }
    }
    /*
    componentDidMount(){
        const reports = historyReports.indexOf(this.props.reportId);
        if (reports >=0){
            this.getFiltersId();
            this.setState({reportIndex:reports})
        }
    }*/

    shouldComponentUpdate(nextProps) {

        return (this.props.filters!==nextProps.filters
            // || this.props.columns !== nextProps.columns // condition replaced with sidebarColumnOrder
            || this.props.sidebarColumnOrder !== nextProps.sidebarColumnOrder
            || this.props.loadingValues !== nextProps.loadingValues
            || this.props.isMobile !== nextProps.isMobile
            || this.props.isMobileDevice !== nextProps.isMobileDevice
            || this.props.reportName !== nextProps.reportName
            || this.props.reportId !== nextProps.reportId
            || this.props.collapsed !== nextProps.collapsed
            || this.props.width !== nextProps.width
            || this.props.resizing !== nextProps.resizing
            || this.props.identifier !== nextProps.identifier
        );

        // return true;
    }
    /*
    componentDidUpdate(prevState){
        const {columns, filters} = this.props;
        const reports = historyReports.indexOf(this.props.reportId);
        if (this.state.reportIndex !== reports) {
            columns.map((col) => Object.assign(col))
                .filter(col => col.filter != null && col.enabled)
                .forEach((column) => {
                    if (filters[column.filter] != null) {
                        if (filters[column.filter].name === OBJECT_ID) {
                            this.setState({objectId: filters[column.filter].id.toString()});
                            if (!filters[column.filter].isEnabled) {
                                this.setState({isChecked: filters[column.filter].id.toString()})
                            }
                        }
                        if (filters[column.filter].name === TAG_ID) {
                            this.setState({tagId: filters[column.filter].id.toString()});
                            if (!filters[column.filter].isEnabled) {
                                this.setState({isChecked: filters[column.filter].id.toString()})
                            }
                        }
                        if (filters[column.filter].name === RESOURCE_ID) {
                            this.setState({objectId: filters[column.filter].id.toString()});
                            if (!filters[column.filter].isEnabled) {
                                this.setState({isChecked: filters[column.filter].id.toString()})
                            }
                        }
                    }
                });
             }
        }*/

    scrollHandler(event) {
        if (document.activeElement && document.activeElement.tagName !== 'BODY'
            && !this.props.isMobile && event.target.className === 'filter-container') {
            // document.activeElement.blur();
        }
    }
    /*
    getFiltersId(){
        const { columns, filters} = this.props;
        const reports = historyReports.indexOf(this.props.reportId);
        columns.map((col) => Object.assign(col))
            .filter(col => col.filter != null && col.enabled)
            .forEach((column) => {
                if (filters[column.filter] != null) {
                    if (filters[column.filter].name === OBJECT_ID) {
                        this.setState({objectId: filters[column.filter].id.toString()});
                        this.setState({isChecked: filters[column.filter].id.toString()});
                        this.props.actions.changeFilterValueHistory(
                            filters[column.filter].id, '', false
                        );
                    }
                    if (filters[column.filter].name === TAG_ID ) {
                        this.setState({tagId: filters[column.filter].id.toString()});
                        if(reports === 1) {
                            this.props.actions.changeFilterValueHistory(
                                filters[column.filter].id, '', false
                            );
                            this.setState({isChecked: filters[column.filter].id.toString()})

                        } else {
                            this.props.actions.changeFilterValueHistory(
                                filters[column.filter].id, '', true
                            );
                        }
                    }
                    if (filters[column.filter].name === RESOURCE_ID) {
                        this.setState({objectId: filters[column.filter].id.toString()});
                        this.props.actions.changeFilterValueHistory(
                            filters[column.filter].id, '', true
                        );
                    }
                }
            });
    }*/
    handleType = (value) => {
        this.setState({isChecked: value});
        if (value === this.state.objectId){
            this.props.actions.changeFilterValueHistory(
                this.state.tagId, '', true
            );
            this.props.actions.changeFilterValueHistory(
                value, '', false
            );
        }
        if (value === this.state.tagId){
            this.props.actions.changeFilterValueHistory(
                this.state.objectId, '', true
            );
            this.props.actions.changeFilterValueHistory(
                value, '', false
            );
        }
    };

    getNonDefaultFiltersCount() {
        const { columns, filters} = this.props;
        let nonDefaultFilters = columns.map((col, index) => Object.assign(col, {originalIndex: index}))
            .filter(col => col.filter != null && col.enabled)
            .reduce((count, column) => {
                let notDefault = false;
                if (filters[column.filter] != null) {
                    notDefault = !isDefaultValue(filters[column.filter].value, filters[column.filter].defaultValue, filters[column.filter].type);
                    if (filters[column.filter].type === 'datetime') {
                        const name = column.name;
                        let isDefaultFrom = false, isDefaultTo = false;

                        Object.keys(filters).forEach(f => {
                            if (filters[f].name === 'from' + name[0].toUpperCase() + name.substr(1)) {
                                isDefaultFrom = isDefaultValue(filters[f].value, filters[f].defaultValue, 'datetimeFrom');
                            }

                            if (filters[f].name === 'to' + name[0].toUpperCase() + name.substr(1)) {
                                isDefaultTo = isDefaultValue(filters[f].value, filters[f].defaultValue, 'datetimeTo');
                            }
                        });
                        notDefault = !isDefaultFrom || !isDefaultTo;
                    }
                }
                return count + (notDefault ? 1 : 0);
            }, 0);
        return nonDefaultFilters;
    }
    getFilters() {
        const { columns, actions, filters, filterRenderer, loadingValues, isMobile, isMobileDevice, reportName,reportId } = this.props;
        const reports = historyReports.indexOf(this.props.reportId);
        let isEnabled = false;
        let displayFilters = columns.map((col, index) => Object.assign(col, {originalIndex: index}))
            .filter(col => col.filter != null && col.enabled)
            .map((column, key) => {
                let highlightFilter = false;
                isEnabled = (reports>=0 && filters[column.filter])? filters[column.filter].isEnabled: isEnabled;
                if (filters[column.filter] != null) {
                    highlightFilter = !isDefaultValue(filters[column.filter].value, filters[column.filter].defaultValue, filters[column.filter].type);

                    if (filters[column.filter].type === 'datetime') {
                        const name = column.name;
                        let isDefaultFrom = false, isDefaultTo = false;

                        Object.keys(filters).forEach(f => {
                            if (filters[f].name === 'from' + name[0].toUpperCase() + name.substr(1)) {
                                isDefaultFrom = isDefaultValue(filters[f].value, filters[f].defaultValue, 'datetimeFrom');
                            }

                            if (filters[f].name === 'to' + name[0].toUpperCase() + name.substr(1)) {
                                isDefaultTo = isDefaultValue(filters[f].value, filters[f].defaultValue, 'datetimeTo');
                            }
                        });
                        highlightFilter = !isDefaultFrom || !isDefaultTo;
                    }
                }
                return <Filter
                    highlightFilter={highlightFilter}
                    key={key}
                    content={filterRenderer({index: column.originalIndex, actions, filters, columns, loadingValues, isMobile, isMobileDevice, reportName,reportId})}
                    column={column}
                    isEnabled={isEnabled}
                    columnId={column.filter}
                />;

            });
        if (displayFilters.length === 0) {
            displayFilters = <span>
                No Filters
            </span>;
        }
        return <div
            onClick={this.handleClick}
            className="filter-container"
            // style={reports>=0?{height: '87%'}:{height:'100%'}}
            onScroll={this.scrollHandler}>
            {displayFilters}
        </div>;
    }
    resetHandler() {
        const { columns, filters,reportId } = this.props;
        //const reports = historyReports.indexOf(reportId);
        let filterArray={datetimeFilters:{}};

        for (let i = 0; i < columns.length; i++) {
            if (columns[i].filter != null) {

                let defaultResetValue = getDefaultValue(filters[columns[i].filter]);

                if (filters[columns[i].filter].type === FILTER_TYPE.DATETIME) {
                    const name = columns[i].name;
                    const from = moment().startOf('day')
                    const to = moment().add(1, 'day').startOf('day');

                    filterArray.datetimeFilters[reportId]={};
                    filterArray.datetimeFilters[reportId][name]={value:PERIOD.TODAY};

                    Object.keys(filters).forEach(f=>{
                        if(filters[f].name === 'from' + name[0].toUpperCase() + name.substr(1)){
                            defaultResetValue = from.toDate().toISOString();
                            // this.props.actions.changeFilterValue(filters[f].id, defaultResetValue);
                            filterArray[filters[f].id]={};
                            filterArray[filters[f].id].value=defaultResetValue;
                            filterArray.datetimeFilters[reportId][name].from=filters[f].id;
                        }

                        if(filters[f].name === 'to' + name[0].toUpperCase()+name.substr(1)){
                            defaultResetValue = to.toDate().toISOString();
                            // this.props.actions.changeFilterValue(filters[f].id, defaultResetValue);
                            filterArray[filters[f].id]={};
                            filterArray[filters[f].id].value=defaultResetValue;
                            filterArray.datetimeFilters[reportId][name].to=filters[f].id;
                        }
                    });
                    continue;
                }
                // this.props.actions.changeFilterValue(columns[i].filter, defaultResetValue);
                filterArray[columns[i].filter]={};
                filterArray[columns[i].filter].value=defaultResetValue;
            }
        }

        this.props.actions.changeBatchFilterValue(filterArray);

        // Reset the current map to world map;
        this.props.resetMap();
        /*if (reports >=0){
            this.getFiltersId();
        }*/
    }
    render() {

        const filters = (!this.props.collapsed && this.props.filterRenderer != null) ? this.getFilters() : '';
        let filtersClassName = 'sidebar-filters ' + (this.props.collapsed ? 'collapsed' : 'expanded');
        if (this.props.animationEnabled) {
            filtersClassName += ' animation';
        }
        //const reports = historyReports.indexOf(this.props.reportId);

        const sidebarFilteredColumns = this.props.columns.filter((col) => col.filter != null);
        const width = !this.props.collapsed ? this.props.width : 0;

        return <Resizer
            className={filtersClassName}
            width={width}
            handleResize={this.props.handleResize}
            resizing={this.props.resizing}
            collapsed={this.props.collapsed}
            separatorPosition="right"
            identifier={this.props.identifier}
            onTransitionEnd={()=>{this.props.onTransitionEnd()}}
            isMobile={this.props.isMobile}
        >
            <Header
                collapsed={this.props.collapsed}
                actions={this.props.actions}
                filters={sidebarFilteredColumns}
                onToggleClicked={this.props.onToggleClicked}
                resetHandler={this.resetHandler}
                handleSaveClick={this.handleSaveClick}
                nonDefaultFilters={this.getNonDefaultFiltersCount()}
            />
            {/*reports>=0?<SearchBy
                collapsed={this.props.collapsed}
                handleType={this.handleType}
                tagId={this.state.tagId}
                objectId={this.state.objectId}
                isChecked={this.state.isChecked}
            />:null*/}
            { filters }
        </Resizer>
    }
}

export default TableFilters;
