import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import {generateComponentId} from "../../../../utils/ComponentUtils";
import CircularProgress from '@material-ui/core/CircularProgress';
import {OPTION_ALL_TEXT, OPTION_ALL_WILDCARD} from "../../../../constants/Misc";
import {getFilterValue, isAllOption, isAllWrite} from "./util";
import FilterMobile from './FilterMobile';
import {I18n} from "react-redux-i18n";


const circularProgress = <CircularProgress style={{ marginTop:-12, color: "#00779f"}} thickness={2} size={16}/>;


const getSuggestionValue = suggestion => suggestion;
const translate = (key) => I18n.t('common.' + key);
// Use your imagination to render suggestions.
const renderSuggestion = suggestion => {
    return (
        <span style={{display:'inline-Block'}}>
            {suggestion}
        </span>
    );
};



class ComboBox extends React.Component {

    static get propTypes(){
        return {
            field: PropTypes.object,
            value: PropTypes.any,
            onError: PropTypes.func.isRequired,
            values: PropTypes.array,
            onFocus: PropTypes.func.isRequired,
            isAllAllowed: PropTypes.bool,
            filter: PropTypes.object,
            onChangeValue:PropTypes.func
        };
    }

    static get defaultProps(){
        return {
            isAllAllowed: false
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            inputValue:null,
            values:[circularProgress],
            suggestions: [],
            focused: false,
            anchorEl: null,
            searches: []
        };

        this.onKeyDown = this.onKeyDown.bind(this);
        this.onChangeInputValue = this.onChangeInputValue.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
        this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
        this.associateAnchor = this.associateAnchor.bind(this);
        this.renderInputComponent = this.renderInputComponent.bind(this);
        this.renderSuggestionsContainer = this.renderSuggestionsContainer.bind(this);
        this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
        //adding methods for multiple sections
        this.renderSectionTitle = this.renderSectionTitle.bind(this);
        this.getSectionSuggestions = this.getSectionSuggestions.bind(this);
    }


    componentDidMount(){
    }

    UNSAFE_componentWillReceiveProps(nextProps){

        this.setState({
            inputValue:null,
            values:nextProps.values || this.state.values,
            searches:nextProps.searches || this.state.searches,
            suggestions: this.getSuggestionsFromValues(nextProps,nextProps.values)
        });

    }

    onFocus(){
        this.props.onFocus();
        if(this.props.isMobileDevice){
            this.props.onRequestDialog();
            this.setState({
                inputValue: null,
                focused: false,
            });
        }else{
            this.setState({
                focused: true
            });
        }
    }

    onBlur(event) {
        if (this.state.inputValue !== null && event && event.target)
            this.changeValue(event.target.value);
        this.setState({
            inputValue: null,
            focused: false,
        });
    }

    onSuggestionSelected(event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }){
        //this hack. Better way to do it to pass this in props itself;
        if(method === "enter"){
            this.setState({inputValue:null});
            // this.props.onChangeValue();
        }
        this.changeValue(suggestionValue);
    }

    getSectionSuggestions(section){
        return section.suggestions;
    }

    renderSectionTitle(section) {
        if(section.suggestions.length > 0){
            return (
                <strong style={{fontSize:'10px'}}>{section.title}</strong>
            );
        }
    }

    onChangeInputValue(event, { newValue }){
        this.setState({inputValue:newValue});
    }

    onKeyDown(event){
        if(event.key === 'Enter')
        {
            this.changeValue(event.target.value);
            this.setState({inputValue:null});
        }
    }

    onSuggestionsFetchRequested({value}){
        const {values} = this.state;
        let suggestions = this.getSuggestionsFromValues(this.props,values);
        this.setState({
            suggestions
        });
    }

    getSuggestionsFromValues(props,values){
        if(props.isAllAllowed===true)
            return values[0] !== OPTION_ALL_TEXT ? [OPTION_ALL_TEXT].concat(values) : [OPTION_ALL_TEXT].concat([circularProgress]);
        else
            return values[0] !== OPTION_ALL_TEXT ? values : [circularProgress];

    }

    onSuggestionsClearRequested(){
        this.setState({ suggestions: [] });
    }

    changeValue(newValue){
        //const value=(newValue===OPTION_ALL_TEXT)?OPTION_ALL_WILDCARD:newValue;
        const value=(isAllWrite(newValue))?OPTION_ALL_WILDCARD:newValue;
        this.props.onChangeValue(value);
    }

    render() {
        const {suggestions,inputValue,searches} = this.state;
        const {value,isMobileDevice} = this.props;
        const inputProps = {
            value: (inputValue===null)?String(value):inputValue,
            onChange: this.onChangeInputValue,
            onBlur: this.onBlur,
            onKeyDown: this.onKeyDown,
            onFocus: this.onFocus,
            readOnly: isMobileDevice
        };

        // Finally, render it!
        // suggestions={[...searches,...suggestions]}
        let searchesfixed = searches.map(function(item) { return item ==='%' ? 'All' : typeof item === "boolean" ?''+item:item });
        let suggestionsFixed = suggestions.map(function(item) { return item ==='%' ? 'All' : typeof item === "boolean"?''+item:item });

        let suggestions_sections = [{'title':translate('Recent Searches'),'suggestions':searchesfixed},
            {'title':translate('Options'),'suggestions':suggestionsFixed}];
        return (
            <Autosuggest
                multiSection={true}
                focusInputOnSuggestionClick={false}
                suggestions={suggestions_sections}
                shouldRenderSuggestions={()=>true}
                renderInputComponent={this.renderInputComponent}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                onSuggestionSelected={this.onSuggestionSelected}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                renderSuggestionsContainer={this.renderSuggestionsContainer}
                inputProps={inputProps}
                renderSectionTitle={this.renderSectionTitle}
                getSectionSuggestions={this.getSectionSuggestions}
            />
        );
    }

    renderInputComponent(inputProps){
        const {filter, isAllAllowed} = this.props;
        let value=(isAllOption(inputProps.value))?OPTION_ALL_TEXT:typeof inputProps.value === 'boolean'?''+inputProps.value:inputProps.value;
        if (value === OPTION_ALL_TEXT && !isAllAllowed) {
            value = '';
        }
        return (
            <div ref={this.associateAnchor} style={{display:'flex', width: '100%'}}>
                <input id={generateComponentId(filter.displayName, null, "text")} {...inputProps} value={value} placeholder="Select or type something"/>
            </div>
        );
    }

    associateAnchor(ref){
        this.setState({
            anchorEl: ref
        });
    }

    renderSuggestionsContainer({containerProps,children}){

        //let anchor = this.state.anchorEl;
        return (
            <div {...containerProps} style={{maxHeight:220}}>
                {children}
            </div>
        );
    }
}
export {ComboBox};


// =============================================================================

class SuggestionsPopover extends React.Component{

    render(){
        const {children} = this.props;

        return (
            <div>{children}</div>
        );
    }

    static propTypes(){
        return {
            children: PropTypes.any,
            anchorEl: PropTypes.any,
            width: PropTypes.number
        };
    }
}

export {SuggestionsPopover};
// -----------------------------------------------------------------------------
/*
███████ ███    ███  █████  ██████  ████████
██      ████  ████ ██   ██ ██   ██    ██
███████ ██ ████ ██ ███████ ██████     ██
     ██ ██  ██  ██ ██   ██ ██   ██    ██
███████ ██      ██ ██   ██ ██   ██    ██

███████ ██ ██   ████████ ███████ ██████
██      ██ ██      ██    ██      ██   ██
█████   ██ ██      ██    █████   ██████
██      ██ ██      ██    ██      ██   ██
██      ██ ███████ ██    ███████ ██   ██
*/




class Filter extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            options:[],
            dialogOpen:false
        };

        this.handleChangeValue = this.handleChangeValue.bind(this);
        this.onRequestDialog = this.onRequestDialog.bind(this);
        this.onRequestClose = this.onRequestClose.bind(this);

    }

    static get propTypes(){
        return {
            isMobile:PropTypes.bool,
            isMobileDevice:PropTypes.bool,
            filter: PropTypes.object,
            actions: PropTypes.shape({
                requestFilterValues: PropTypes.func.isRequired,
                changeFilterValue: PropTypes.func.isRequired
            }).isRequired
        };
    }

    static get defaultProps(){
        return {
            filter:{}
        };
    }

    shouldComponentUpdate(nextProps,nextState){
        return (this.props.filter !== nextProps.filter || this.state.value !== nextState.value || this.state.options !== nextState.options || this.state.dialogOpen !== nextState.dialogOpen);
    }

    UNSAFE_componentWillReceiveProps(nextProps){
    }

    requestFilterValues(){
        const {filter} = this.props;
        if(!filter.hasOwnProperty('searches')){
            filter['searches'] = [];
        }

        this.props.actions.requestFilterValues(filter.id)
            .then(([options])=>{
                this.setState({
                    options
                });
            });
    }

    onRequestDialog(){
        this.setState({dialogOpen:true});
    }

    onRequestClose(){
        this.setState({dialogOpen:false});
    }

    handleChangeValue(value){
        if(value !== ''){
            this.props.actions.changeFilterValue(this.props.filter.id,value);
        }

    }


    render(){

        const {options, dialogOpen} = this.state;
        const {filter,isMobileDevice} = this.props;
        const value=getFilterValue(filter);
        return (

            <div style={{paddingTop:'3px',paddingBottom:'3px',paddingLeft:'0px', paddingRight:'8px'}}>
                <ComboBox
                    value={value}
                    onError={()=>{}}
                    // onChange={this.handleFilterChange}
                    onFocus={()=>{this.requestFilterValues();}}
                    // onBlur={this.handleBlur}
                    values={options}
                    searches={this.props.filter.searches}
                    isAllAllowed={this.props.filter.isAllAllowed}
                    filter={filter}
                    onChangeValue={this.handleChangeValue}
                    isMobileDevice={isMobileDevice}
                    onRequestDialog={this.onRequestDialog}
                />
                {isMobileDevice && <FilterMobile
                    value={value}
                    open={dialogOpen}
                    onRequestClose={this.onRequestClose}
                    values={options}
                    title={filter.displayName}
                    onChangeValue={this.handleChangeValue}
                />}
            </div>
        );
    }
}

export default Filter;