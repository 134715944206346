import { Children, useEffect, useMemo, useState } from 'react';
import { default as Measure } from 'react-measure';
import { DefaultSplitter } from './DefaultSplitter';
import './split.css';

export function Split(props) {
  const {
    horizontal = false,
    initialPrimarySize = '50%',
    minPrimarySize = '0px',
    minSecondarySize = '0px',
    splitterSize = '7px',
    renderSplitter,
    resetOnDoubleClick = false,
    onSplitChanged,
    onMeasuredSizesChanged
  } = props;
  
  const [contentMeasuredDimensions, setContentMeasuredDimensions] = useState({ height: 0, width: 0 });
  const [primaryMeasuredDimensions, setPrimaryMeasuredDimensions] = useState({ height: 0, width: 0 });
  const [splitterMeasuredDimensions, setSplitterMeasuredDimensions] = useState({ height: 0, width: 0 });
  
  const currentContentSize = useMemo(
    () => (horizontal ? contentMeasuredDimensions.height : contentMeasuredDimensions.width),
    [horizontal, contentMeasuredDimensions]
  );
  const currentPrimarySize = useMemo(
    () => (horizontal ? primaryMeasuredDimensions.height : primaryMeasuredDimensions.width),
    [horizontal, primaryMeasuredDimensions]
  );
  const currentSplitterSize = useMemo(
    () => (horizontal ? splitterMeasuredDimensions.height : splitterMeasuredDimensions.width),
    [horizontal, splitterMeasuredDimensions]
  );
  
  const [percent, setPercent] = useState(undefined);
  
  const [clientStart, setClientStart] = useState(0);
  const [primaryStart, setPrimaryStart] = useState(0);
  const [dragging, setDragging] = useState(false);
  
  useEffect(() => {
    if (onSplitChanged) {
      onSplitChanged(percent !== undefined ? `${percent}%` : initialPrimarySize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [percent, initialPrimarySize]);
  
  useEffect(() => {
    if (onMeasuredSizesChanged) {
      onMeasuredSizesChanged({
        primary: currentPrimarySize,
        splitter: currentSplitterSize,
        secondary: currentContentSize - (currentPrimarySize + currentSplitterSize)
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [horizontal, currentContentSize, currentPrimarySize, currentSplitterSize]);
  
  const onMeasureContent = (contentRect) => {
    contentRect.bounds &&
    setContentMeasuredDimensions({ height: contentRect.bounds.height, width: contentRect.bounds.width });
  };
  
  const onMeasurePrimary = (contentRect) => {
    contentRect.bounds &&
    setPrimaryMeasuredDimensions({ height: contentRect.bounds.height, width: contentRect.bounds.width });
  };
  
  const onMeasureSplitter = (contentRect) => {
    contentRect.bounds &&
    setSplitterMeasuredDimensions({ height: contentRect.bounds.height, width: contentRect.bounds.width });
  };
  
  const onSplitPointerDown = (event) => {
    event.currentTarget.setPointerCapture(event.pointerId);
    setClientStart(horizontal ? event.clientY : event.clientX);
    setPrimaryStart(currentPrimarySize);
    setDragging(true);
  };
  
  const onSplitPointerMove = (event) => {
    if (event.currentTarget.hasPointerCapture(event.pointerId)) {
      const position = horizontal ? event.clientY : event.clientX;
      const primarySize = primaryStart + (position - clientStart);
      const newPrimary = Math.max(0, Math.min(primarySize, currentContentSize));
      const newPercent = (newPrimary / currentContentSize) * 100;
      setPercent(newPercent);
    }
  };
  
  const onSplitPointerUp = (event) => {
    event.currentTarget.releasePointerCapture(event.pointerId);
    setDragging(false);
  };
  
  const onSplitDoubleClick = () => {
    resetOnDoubleClick && setPercent(undefined);
  };
  
  const children = Children.toArray(props.children);
  const primaryChild = children.length > 0 ? children[0] : <div />;
  const secondaryChild = children.length > 1 ? children[1] : <div />;
  
  const renderSizes = {
    primary: percent !== undefined ? `${percent}%` : initialPrimarySize,
    minPrimary: minPrimarySize ?? '0px',
    minSecondary: minSecondarySize ?? '0px'
  };
  
  const renderSplitterProps = {
    pixelSize: currentSplitterSize,
    horizontal,
    dragging: dragging
  };
  
  const renderSplitVisual =
    renderSplitter ??
    (() => <DefaultSplitter />);
  
  const rootClassName = horizontal ? 'split-container horizontal' : 'split-container vertical';
  
  const rootStyle = {
    '--react-split-min-primary': renderSizes.minPrimary,
    '--react-split-min-secondary': renderSizes.minSecondary,
    '--react-split-primary': renderSizes.primary,
    '--react-split-splitter': splitterSize
  };
  
  return (
    <Measure bounds onResize={onMeasureContent}>
      {({ measureRef: contentRef }) => (
        <div className="react-split" ref={contentRef}>
          <div className={rootClassName} style={rootStyle}>
            <div className="primary">
              <Measure bounds onResize={onMeasurePrimary}>
                {({ measureRef: primaryRef }) => (
                  <div className="full-content" ref={primaryRef}>
                    {primaryChild}
                  </div>
                )}
              </Measure>
            </div>
            <div
              className="splitter"
              tabIndex={-1}
              onPointerDown={onSplitPointerDown}
              onPointerUp={onSplitPointerUp}
              onPointerMove={onSplitPointerMove}
              onDoubleClick={onSplitDoubleClick}
            >
              <Measure bounds onResize={onMeasureSplitter}>
                {({ measureRef: splitterRef }) => (
                  <div className="full-content" ref={splitterRef}>
                    {renderSplitVisual(renderSplitterProps)}
                  </div>
                )}
              </Measure>
            </div>
            <div className="secondary">
              <div className="full-content">{secondaryChild}</div>
            </div>
          </div>
        </div>
      )}
    </Measure>
  );
};
