/* jshint esversion: 6 */

import React from 'react';
import PropTypes from 'prop-types';

import * as ZMap from './../../../../../common/app/views/map/ZMap';
import LocalMapLayer from './layers/LocalMapLayer';
import ClusteredTagLayer from "./layers/ClusteredTagLayer";
import SingleTagPathLayer from './layers/SingleTagPathLayer';
import ZoneLayer from './layers/ZoneLayer';
import ZoneLabelLayer from './layers/ZoneLabelLayer';
import Projection from 'ol/proj/Projection';
import {getCenter} from 'ol/extent';

const {Map,LayerGroup,PointerCoordinates} = ZMap;

//openlayers



// =============================================================================
export {LocalMapLayer};

// =============================================================================
/*
███████  ██████  ███    ██ ███████     ██       █████  ██    ██ ███████ ██████  ███████
   ███  ██    ██ ████   ██ ██          ██      ██   ██  ██  ██  ██      ██   ██ ██
  ███   ██    ██ ██ ██  ██ █████       ██      ███████   ████   █████   ██████  ███████
 ███    ██    ██ ██  ██ ██ ██          ██      ██   ██    ██    ██      ██   ██      ██
███████  ██████  ██   ████ ███████     ███████ ██   ██    ██    ███████ ██   ██ ███████
*/

export const ZoneVisibility = {
    NO_ZONES:0,
    ZONES_ONLY:1,
    ZONES_AND_LABELS:2
};

export class SmarterZoneLayer extends React.Component{
    static get propTypes(){
        return {
            zones:PropTypes.array,
            zoneVisibility:PropTypes.number
        };
    }

    shouldComponentUpdate(nextProps){
        return (
            this.props.zones !== nextProps.zones
            || this.props.zoneVisibility !== nextProps.zoneVisibility
            || this.props.map !== nextProps.map // eslint-disable-line
        );
    }

    render(){
        return (
            <ZoneLayer
                {...this.props}
                zones={this.props.zones}
                active={this.props.zoneVisibility >= ZoneVisibility.ZONES_ONLY}
            />
        );
    }
}

export class SmarterZoneLabelLayer extends React.Component{
    static get propTypes(){
        return {
            zones: PropTypes.array,
            zoneVisibility: PropTypes.number
        };
    }

    shouldComponentUpdate(nextProps){
        return this.props !== nextProps;
    }

    render(){
        return (
            <ZoneLabelLayer
                {...this.props}
                zones={this.props.zones}
                active={this.props.zoneVisibility === ZoneVisibility.ZONES_AND_LABELS}
            />
        );
    }
}

// =============================================================================
/*
████████  █████   ██████  ███████     ██       █████  ██    ██ ███████ ██████
   ██    ██   ██ ██       ██          ██      ██   ██  ██  ██  ██      ██   ██
   ██    ███████ ██   ███ ███████     ██      ███████   ████   █████   ██████
   ██    ██   ██ ██    ██      ██     ██      ██   ██    ██    ██      ██   ██
   ██    ██   ██  ██████  ███████     ███████ ██   ██    ██    ███████ ██   ██
*/

export class SmarterTagsLayer extends React.Component{

    static get propTypes(){
        return {
            tags: PropTypes.array,
            showTagLabels: PropTypes.bool,
            siteId:PropTypes.any,
            mapId:PropTypes.any,
        };
    }

    static get defaultProps(){
        return {
            tags: [],
            showTagLabels: false
        };
    }

    // constructor(props){
    //     super(props);
    // }

    UNSAFE_componentWillReceiveProps(nextProps){
        if(this.props.tags !== nextProps.tags){
        }
    }

    render(){
        const {
            tags,
            showTagLabels,
            mapConfiguration,
            data,isMobile,
            realTime,
            reportId,
            updateClusterIndexAction,
            highlightedItemMap,
            highlightItemMap,
            siteId,
            mapId,
            selectManyRows,
            openRightSidebar,
            displayErrorDialog,
            selected,
            localMapInfo,
            onItemSelected,
            selectCluster,
            clickedItem,
        } = this.props;

        return (
            <LayerGroup {...this.props}>

                <ClusteredTagLayer
                    tags={tags}
                    data={data}
                    showTagLabels={showTagLabels}
                    mapConfiguration={mapConfiguration}
                    isMobile={isMobile}
                    realTime={realTime}
                    reportId={reportId}
                    updateClusterIndexAction={updateClusterIndexAction}
                    highlightedItemMap={highlightedItemMap}
                    highlightItemMap={highlightItemMap}
                    siteId={siteId}
                    mapId={mapId}
                    selectClickedItems={this.props.selectClickedItems}
                    setPlaybackMapId={this.props.setPlaybackMapId}
                    setPlaybackInfo={this.props.setPlaybackInfo}
                    onItemSelected={onItemSelected}
                    selectCluster={selectCluster}
                    selectManyRows={selectManyRows}
                    openRightSidebar={openRightSidebar}
                    displayErrorDialog={displayErrorDialog}
                    selected={selected}
                    localMapInfo={localMapInfo}
                    clickedItem={clickedItem}
                />

            </LayerGroup>
        );
    }
}

export class SmarterTagsPathLayer extends React.Component{

    static get propTypes(){
        return {
            tags: PropTypes.object,
            showTagLabels: PropTypes.bool
        };
    }

    static get defaultProps(){
        return {
            tags: {},
            showTagLabels: false
        };
    }

    render(){

        const {tags, data, showTagLabels} = this.props;
        return(
            <LayerGroup {...this.props}>
                <SingleTagPathLayer
                    tags={tags}
                    data={data}
                    showTagLabels={showTagLabels}
                />
            </LayerGroup>
        )
    }
}

// =============================================================================
/*
██████   █████  ██████  ███████     ██       ██████   ██████  █████  ██
██   ██ ██   ██ ██   ██ ██          ██      ██    ██ ██      ██   ██ ██
██████  ███████ ██████  █████       ██      ██    ██ ██      ███████ ██
██   ██ ██   ██ ██   ██ ██          ██      ██    ██ ██      ██   ██ ██
██████  ██   ██ ██   ██ ███████     ███████  ██████   ██████ ██   ██ ███████

███    ███  █████  ██████
████  ████ ██   ██ ██   ██
██ ████ ██ ███████ ██████
██  ██  ██ ██   ██ ██
██      ██ ██   ██ ██
*/



export class BareLocalMap extends React.Component{

    static get propTypes(){
        return {
            children: PropTypes.any,
            bounds: PropTypes.array,
            tilePrefix: PropTypes.string,
            onHoverFeature: PropTypes.func,
            onSelectFeature: PropTypes.func,
            maxZoom: PropTypes.number,
            onPointerMove: PropTypes.func,
            onLoadError: PropTypes.func,
            onClick: PropTypes.func
        };
    }

    static get defaultProps(){
        const nop = function(){};
        return {
            onHoverFeature: nop,
            onSelectFeature: nop,
            onPointerMove: nop,
            onLoadError: nop,
            onClick: nop
        };
    }

    constructor(props){
        super(props);
        this.state = {
            projection: this.getProjectionFromBounds(this.props.bounds),
            updateCluster:1,
        };

        this.handlePointerMove = this.handlePointerMove.bind(this);
        //this.handleClick = this.handleClick.bind(this);
        this.handlePress = this.handlePress.bind(this);
        // this.handleMoveEnd = this.handleMoveEnd.bind(this);
    }

    getProjectionFromBounds(bounds){
        return new Projection({
            code:'local-map',
            units:'pixels',
            extent:bounds
        });
    }
    shouldComponentUpdate(nextProps, nextState) {
        if(nextProps!==this.props)
            return true;
        else
            return false;
    }
    UNSAFE_componentWillReceiveProps(nextProps){
        if(this.props.bounds !== nextProps.bounds){
            this.setState({
                projection: this.getProjectionFromBounds(nextProps.bounds)
            });
        }
    }

    getMap(){
        return this.refs.map.getMap();
    }

    render(){

        const {
            children,
            tilePrefix,
            bounds,
            maxZoom,
            onLoadError,
            siteId,
            mapId,
            hideCoordinates
        } = this.props;

        const {
            projection,
            updateCluster,
        } = this.state;

        const mapStyles = {
            height:'100%',
            width:'100%'
        };

        return (
            <Map
                {...this.props}
                style={mapStyles}
                center={getCenter(bounds)}
                projection={projection}
                onPointerMove={this.handlePointerMove}
                //onClick={this.handleClick}
                onPress={this.handlePress}
                onPointerDrag={this.handlePress}
                onMoveEnd={this.props.onMoveEnd}
                ref="map"
                >

                <LocalMapLayer
                    updateCluster={updateCluster}
                    maxZoom={maxZoom}
                    tilePrefix={tilePrefix}
                    projection={projection}
                    onLoadError={onLoadError}
                    siteId={siteId}
                    mapId={mapId}
                />
                {children}

                {!hideCoordinates && <PointerCoordinates />}
            </Map>
        );
    }

    handlePointerMove(event,hoveredFeatures){

        const [x,y] = event.coordinate;

        if(hoveredFeatures.length > 0){
            let data = hoveredFeatures.map(f=>f.get('data'));
            this.props.onHoverFeature(data);
        }else{
            this.props.onHoverFeature([]);
        }

        this.props.onPointerMove(x,y);
    }

    handlePress(event) {
        const [x, y] = event.coordinate;
        this.props.onPointerMove(x, y);
    }
}
