import React, { useEffect } from 'react';
import { HashRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';

import MainPage from './views/MainApp';
import LoginPage from './views/login/Login';
import Loading from './views/loading/Loading';
import { useWindowOnFocus } from './hooks';
import { checkMWEBuildUpdates } from './utils/AutoReload';
import { ROUTES_MIGRATIONS } from './constants/OldRoutesMigrations';

const AsyncMweModule = Loadable({
    loader: () => import(/* webpackChunkName: "mwe.module" */'./modules/MweModule'),
    loading: Loading
});

const AsyncDeviceManagerModule = Loadable({
    loader: () => import(/* webpackChunkName: "deviceManager.module" */'./modules/DeviceManagerModule'),
    loading: Loading
});

const OldRoutesRedirect = () => {
    return (
        <Switch>
            {ROUTES_MIGRATIONS.map((route, index) => {
                return (
                    <Route exact path={route.oldPath} key={index}>
                        <Redirect to={route.newPath} />
                    </Route>
                );
            })}
        </Switch>
    );
}

const WrappedMainPage = (props) => {
    const focus = useWindowOnFocus();
    useEffect(checkMWEBuildUpdates, [focus]);
    return (
        <MainPage {...props}>
            <OldRoutesRedirect />
            <Switch>
                <Route exact path="/">
                    <Redirect to="/login" />
                </Route>
            </Switch>
            <AsyncMweModule />
            <AsyncDeviceManagerModule />
        </MainPage>
    );
}

const Routes = (
    <Router>
        <Switch>
            <Route exact path="/login" component={LoginPage} />
            <Route path="/" component={WrappedMainPage} />
        </Switch>
    </Router>
);
export { Routes };
