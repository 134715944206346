import React, {useEffect, useRef, useState} from 'react';
import {
    useOnePlatformFiltersDispatch, useReportState,
} from '../../../../../hooks';
import {Field, Form} from "../../../../util/DataEntryTabularForm";
import Popper from "@material-ui/core/Popper/Popper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener/ClickAwayListener";
import {getShortDateFromTimestamp} from "../../../../../utils/LocaleTimestamp";
import {PERIOD} from "../../../../util/report/filter/util";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import moment from "moment/moment";
import {getCustomDate} from "../../../../../utils";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";

const COLOR = {
    backgroundColor:'#FFF',
    backgroundColorFocused:'#DDDDDD',
    colorText:'#00779f',
}
const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    tooltip: {
        fontSize: '12px !important',
    },
}));
export const TYPE_RESET_FILTER = {
    RESET_FILTER:'reset',
    SAVED_RESET_FILTER:'savedResetFilter',
    SAVED_RELOAD_FILTER:'savedReloadFilter',
    SUCCESS:'success',
    UPDATE_RANGE_MODE:'updateRangeMode',
    LOAD_REPORT:'loadReport'
}
const TimeFilter = ({ reportKey, filter, filters }) => {

    const { patchTimeFilter } = useOnePlatformFiltersDispatch(reportKey);
    const { isFetchingData } = useReportState(reportKey);
    const { key:keyTimeFilter, value: timeFilterValue, rangeMode:rangeModeFilter, action } = filter;
    const [open,setOpen] = useState(false);
    const [anchorEl,setAnchorEl] = useState(null);
    const buttonStyle = {}
    const button = useRef();
    const classes = useStyles();
    const [dateFilterCustom,setDateFilterCustom] = useState(timeFilterValue?timeFilterValue:getCustomDate());
    const localTime = new Date();
    const timeFormat = 'YYYY-MM-DD[T]HH:mm';
    const maxDate = moment(localTime).format(timeFormat);
    const [rangeModeLocal,setRangeModeLocal] = useState(rangeModeFilter);
    const onRequestClose  = (event) =>{
        if(timeFilterValue === null && rangeModeLocal === PERIOD.CUSTOM){
            handleCustomChangeOnBlur();
        }
        setOpen(false);
    }

    useEffect(()=>{
        if(rangeModeLocal !== rangeModeFilter){
            setRangeModeLocal(rangeModeFilter)
        }
        if(action && [TYPE_RESET_FILTER.RESET_FILTER].includes(action) && isFetchingData === 0){
            patchTimeFilter({ rangeMode:rangeModeFilter,action:action,updateSort:true }, filter.key);
        }else if(!action){
            patchTimeFilter({ rangeMode:rangeModeFilter,action:TYPE_RESET_FILTER.LOAD_REPORT,updateSort:true }, filter.key);
        }
        //eslint-disable-next-line
    },[rangeModeFilter,keyTimeFilter,action,isFetchingData]);

    const width = 100;
    const styles={
        buttonStyle :{
            fontSize:'0.9em',
            width:'calc(100% - 6px)',
            margin:'3px 0px',
            height:'2.222em',
            borderRadius:'4px',
            backgroundColor:'white',
            border:'1px solid #aaa',
            textAlign:'left',
            whiteSpace:'nowrap',
            paddingRight:'1em',
            textOverflow:'ellipsis',
            overflow:'hidden',
            position:'relative',
        }
    };
    const handleTouchTap = (event) =>{
        // This prevents ghost click.
        event.preventDefault();
        setOpen(true);
        setAnchorEl(event.currentTarget)
    }
    const IsValidDate = (date) => {
        return date instanceof Date && !isNaN(date);
    }

    const handleCustomChangeOnBlur = () =>{
        const selectDate = rangeModeLocal === PERIOD.NOW?null:new Date(dateFilterCustom);
        if(IsValidDate(selectDate)|| selectDate===null){
            patchTimeFilter({ rangeMode:rangeModeLocal,value:selectDate,action:TYPE_RESET_FILTER.UPDATE_RANGE_MODE, updateSort:true }, filter.key);

        }else{
            console.log("The data is not a valid date ",{timeFilter: selectDate})
        }
        //onRequestClose();
    }

    const handleRangeChange = (e) =>{
        const rangeMode = e.target.value;
        const oldDate = dateFilterCustom?new Date(dateFilterCustom):null;
        const newDate = rangeMode === PERIOD.CUSTOM ? IsValidDate(oldDate)?oldDate:getCustomDate():null;
        if(IsValidDate(newDate) || newDate === null){
            if(rangeMode === PERIOD.NOW){
                const filterChange = { rangeMode:PERIOD.NOW, value:null, action:TYPE_RESET_FILTER.UPDATE_RANGE_MODE, updateSort:true };
                patchTimeFilter(filterChange, filter.key);
                setOpen(false);
            }else{
                setRangeModeLocal(rangeMode)
            }
        }else{
            console.log("The data is not a valid date ",{timeFilter: newDate,rangeMode})
        }
    }
    const getLabelForFilter = (timeFilterSelect) =>{
        switch (rangeModeLocal) {
            case PERIOD.NOW:
                return `Now`;
            case PERIOD.CUSTOM:
                return `Custom (${getShortDateFromTimestamp(timeFilterSelect)})`;
            default:
                return ``;
        }
    };
    const diffDays = ( date1,date2 ) =>{
        return IsValidDate(date1) && IsValidDate(date2)? moment(date1).diff(moment(date2), 'days'):null;
    }
    return (
        <div className="filter">
            <div>
            <span className={'items-header ' + (rangeModeLocal === PERIOD.CUSTOM ? ' selected' : '')} style={{float:'left'}}>
                {filter.displayName}
            </span>
                {(rangeModeLocal === PERIOD.CUSTOM)?<div data-tip={"The result includes resources moved in last 7 days starting selected time"} data-for={"location-snapshot-time"} data-multiline={true} style={{display:'inline-block',height:'19px',overflow:'hidden'}}>
                    <Tooltip title={"The result includes resources moved in last 7 days starting selected time"} arrow={true} placement="top" classes={{tooltip: classes.tooltip}}>
                        <InfoIcon style={{width:'19px',height:'19px'}}/>
                    </Tooltip>
                </div>:null}
            </div>

            <div>
                <button className="datepicker-carot" style={{...styles.buttonStyle,...buttonStyle}} onClick={handleTouchTap} ref={button}>
                    {getLabelForFilter(dateFilterCustom)}
                </button>
                <Popper open={open}
                        anchorEl={anchorEl}
                        key={"datetimefilter"}
                        className={"filter-popper"}
                        placement={'bottom-start'}
                        style={{maxWidth: 280}}
                >
                    <ClickAwayListener onClickAway={onRequestClose}>
                        <div className={"popper-style"} style={{minWidth:width+'px'}}>
                            <Form fullWidth>
                                <Field label="To">
                                    <select onChange={handleRangeChange} value={rangeModeLocal}>
                                        <option value={PERIOD.NOW}>{`Now`}</option>
                                        <option value={PERIOD.CUSTOM}>{`Custom`}</option>
                                    </select>
                                </Field>
                                {rangeModeLocal !== PERIOD.NOW && <Field label="Time">
                                    <TextField
                                        id="datetime-local"
                                        //label="Time"
                                        type="datetime-local"
                                        value={moment(dateFilterCustom).format(timeFormat)}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            const a = new Date(maxDate);
                                            let b = null;
                                            if(!value){
                                                //When we select clear, value is empty, the default value will be today at 23:59 PM
                                                b = getCustomDate();
                                            }
                                            else{
                                                b = new Date(value);
                                            }
                                            const differenceDays = diffDays(a,b)
                                            if(differenceDays>=0){
                                                setDateFilterCustom(b)
                                            }
                                        } }
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{inputProps: { max:maxDate} }}
                                        classes={{root: classes.root}}
                                        disableunderline={'false'}
                                        disabled={rangeModeLocal && rangeModeLocal===PERIOD.NOW}
                                        onBlur={handleCustomChangeOnBlur}
                                        style={rangeModeLocal===PERIOD.NOW?{backgroundColor:COLOR.backgroundColorFocused,color:'#FFFFFF'}:{}}
                                    />
                                </Field>}
                            </Form>

                        </div>
                    </ClickAwayListener>
                </Popper>
            </div>

        </div>
    );
};

export default React.memo(TimeFilter, ((prevProps, nextProps) => {
    return (prevProps.filter.value === nextProps.filter.value
        && prevProps.filter.rangeMode === nextProps.filter.rangeMode
        && prevProps.filter.action === nextProps.filter.action
    );
}));
