import React from 'react';
import PropTypes from 'prop-types';

import Overlay, {OverlayGroup} from '../../../../../../common/app/views/map/Overlay';
import Label from '../misc/Label';

function getCenter(shape){

    // Max-min centering.
    let xs = shape.map((v)=>v[0]), ys = shape.map((v)=>v[1]);

    let minX = Math.min.apply(null,xs), maxX = Math.max.apply(null,xs);
    let minY = Math.min.apply(null,ys), maxY = Math.max.apply(null,ys);

    return [(minX+maxX) / 2, (minY+maxY) / 2];
}

/**
 * Displays tags on a map.
 */
class ZoneLabelLayer extends React.Component{

    static get propTypes(){
        return {
            zones: PropTypes.arrayOf(
                PropTypes.shape({
                    shape:PropTypes.arrayOf(
                        PropTypes.arrayOf(
                            PropTypes.number
                        )
                    ).isRequired,
                    name: PropTypes.node.isRequired,
                    label: PropTypes.node
                })
            )
        };
    }

    static get defaultProps(){
        return {
            zones: []
        };
    }

    /**
     * @private
     */
    constructor(props){
        super(props);

        this.props = props;
    }

    /**
     * @private
     */
    render(){
        let _zones =  this.props.zones.map((v,i)=>{
            let center = getCenter(v.shape);

            return (
                <Overlay
                    key={"zonelabel"+i}
                    position={center}
                    positioning={"center-center"}
                >
                    <Label>{this.getLabel(v)}</Label>
                </Overlay>
            );
        });
        return (
            <OverlayGroup {...this.props}>
                {_zones}
            </OverlayGroup>
        );
    }

    getLabel(z){
        if(z.label === undefined || z.label === null){
            return z.name;
        }

        return z.label;
    }
}

export default ZoneLabelLayer;
