import { MEASURE_DEFAULT_MEASURE_SYSTEM } from '../../vss/app/constants/SiteDesigner';
import ContextMenu from 'ol-contextmenu';
import Collection from 'ol/Collection';
import VectorSource from 'ol/source/Vector';
import VectorImageLayer from 'ol/layer/VectorImage';
import Draw from "ol/interaction/Draw";

export class OlBase {
    mapRef;
    source;
    layer;
    featuresCollection;
    measurementSystem;
    mapCoordinates;
    mapExtent;
    
    listenerStore = {};
    observer = {
        subscribe: (event, callback) => {
            if (!this.listenerStore[event]) {
                this.listenerStore[event] = [];
            }
            this.listenerStore[event].push(callback);
        },
        unsubscribe: (event, callback) => {
            if (!this.listenerStore[event]) {
                this.listenerStore[event] = [];
            }
            this.listenerStore[event] = this.listenerStore[event].filter(cb => cb !== callback);
        },
        executeEvent: (event, payload) => {
            (this.listenerStore[event] || []).forEach((callback) => {
                callback(payload, this.mapRef);
            });
        },
    };
    
    currentZoom;
    
    tiebreakerFeaturesForSingleSelection(features) {
        return features[0];
    };
    
    #getSingleFeatureOfPixel(pixel) {
        const features = this.mapRef.getFeaturesAtPixel(pixel);
        return this.tiebreakerFeaturesForSingleSelection(features);
    }
    
    constructor(mapRef) {
        // Clean map
        for (const interaction of mapRef?.getInteractions().getArray() || []) {
            if (interaction.get('addedInteraction')) {
                mapRef?.removeInteraction(interaction);
            }
        }
        for (const control of mapRef?.getControls().getArray() || []) {
            if (control.get('addedControl')) {
                mapRef?.removeControl(control);
            }
        }
        for (const layer of mapRef?.getLayers().getArray() || []) {
            if (layer.get('addedLayer')) {
                mapRef?.removeLayer(layer);
            }
        }
        for (const clickListener of mapRef?.listeners_?.click || []) {
            mapRef?.un('click', clickListener);
        }
        this.mapRef = mapRef;
        this.featuresCollection = new Collection([], { unique: true });
        this.source = new VectorSource({ useSpatialIndex: false, features: this.featuresCollection });
        this.layer = new VectorImageLayer({ source: this.source });
        this.layer.set('addedLayer', true);
        this.mapRef.addLayer(this.layer);
        this.measurementSystem = MEASURE_DEFAULT_MEASURE_SYSTEM;
        
        this.mapCoordinates = [ 0, 0, 0, 0 ];
        this.mapExtent = this.mapCoordinates;
        // Context menu
        this.contextMenu = new ContextMenu({ width: 150, defaultItems: false });
        this.contextMenuByItem = {};
        
        let contextMenuItems = [];
        this.contextMenu.on('beforeopen', (event) => {
            const feature = this.#getSingleFeatureOfPixel(event.pixel);
            if (feature) {
                contextMenuItems = this.contextMenuByItem[this.getFeatureItemType(feature)]?.({ feature }) || [];
                if (contextMenuItems.length > 0) {
                    return this.contextMenu.enable();
                }
            }
            this.contextMenu.disable();
        });
        
        this.contextMenu.on('open', async event => {
            const feature = this.#getSingleFeatureOfPixel(event.pixel);
            if (feature) {
                this.contextMenu.clear();
                if (contextMenuItems.length > 0) {
                    contextMenuItems.forEach(menu => {
                        menu.callback = () => {
                            this.observer?.executeEvent('onMenuItemClick', { event, menu, feature });
                        };
                    });
                    this.contextMenu.extend(contextMenuItems);
                }
            }
        });
        this.contextMenu.set('addedControl', true);
        this.contextMenu.set('addedControlType', 'contextMenu');
        this.mapRef.addControl(this.contextMenu);
        
        //
        this.currentZoom = this.mapRef.getView().getZoom();
        this.mapRef.on('moveend', () => {
            const newZoom = this.mapRef.getView().getZoom();
            if (this.currentZoom !== newZoom) {
                this.currentZoom = newZoom;
                this.observer?.executeEvent('zoomUpdated');
            }
        });
    }
    
    updateLayer() {
        this.layer?.changed();
        this.observer?.executeEvent('layerUpdated');
    }
    
    setMeasurementSystem(measurementSystem) {
        if (this.measurementSystem !== measurementSystem) {
            this.measurementSystem = measurementSystem;
            this.observer?.executeEvent('measurementSystemUpdated');
        }
    }
    
    setMapCoordinates(mapCoordinates) {
        if (JSON.stringify(this.mapCoordinates) !== JSON.stringify(mapCoordinates)) {
            this.mapCoordinates = mapCoordinates;
            this.observer?.executeEvent('mapCoordinatesUpdated');
        }
    }
    
    setMapExtent(mapExtent) {
        if (JSON.stringify(this.mapExtent) !== JSON.stringify(mapExtent)) {
            this.mapExtent = mapExtent;
            this.observer?.executeEvent('mapExtentUpdated');
        }
    }
    
    setContextMenuByItem(contextMenuByItem) {
        this.contextMenuByItem = contextMenuByItem;
    }
    
    subscribe(...props) {
        return this.observer.subscribe(...props);
    }
    
    unsubscribe(...props) {
        return this.observer.unsubscribe(...props);
    }

    removeLayers() {

        this.mapRef.getInteractions().getArray()
            .filter(interaction => interaction instanceof Draw)
            .forEach(interaction => {
               this.mapRef.removeInteraction(interaction);

            });
        const removeLayers=["draftLayer","gridLayer"];
        this.mapRef.getLayers().getArray()
            .filter(layer => removeLayers.indexOf(layer.get('name'))>=0)
            .forEach(layer => {
                this.mapRef.removeLayer(layer);
            });
    }
}
