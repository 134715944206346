import React from 'react';
import { fromLonLat } from 'ol/proj';
import Feature from 'ol/Feature';
import { Point } from 'ol/geom';
import VectorSource from 'ol/source/Vector';
import { Fill, Icon, Stroke, Style } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import { Pointer as PointerInteraction } from 'ol/interaction';
import * as ReactDOMServer from 'react-dom/server';

import PinIcon from '../../views/icons/PinIcon';
import PinShadowIcon from '../../views/icons/PinShadowIcon';
import {fitMap} from "./OlUtils";

const getImageSourceFromReactIcon = (icon) => {
    icon = React.cloneElement(icon, { xmlns: 'http://www.w3.org/2000/svg' });
    let svg_string = ReactDOMServer.renderToStaticMarkup(icon);
    
    return 'data:image/svg+xml;charset=utf8,' + encodeURIComponent(svg_string);
};

let PIN_IMAGE = getImageSourceFromReactIcon(<PinIcon height={512} width={512} color={'#269bc5'} borderColor={'#2084a8'} style={{ height: 'auto', width: 'auto' }} />);
let PIN_SHADOW = getImageSourceFromReactIcon(<PinShadowIcon height={512} width={720} color="rgba(0,0,0,0.6)" style={{ height: 'auto', width: 'auto' }} />);

const PIN_STYLE = new Style({
    image: new Icon({
        anchor: [0.5, 1],
        src: PIN_IMAGE,
        imgSize: [512, 512],
        scale: (25.0 / 512)
    })
});

const PIN_STYLE_LARGE = new Style({
    image: new Icon({
        anchor: [0.5, 1],
        src: PIN_IMAGE,
        imgSize: [512, 512],
        scale: (35.0 / 512)
    })
});

const PIN_STYLE_HOVER = new Style({
    image: new Icon({
        anchor: [0.5, (30 / 40)],
        src: PIN_SHADOW,
        imgSize: [720, 512],
        scale: (35.0 / 512)
    })
});

class FeaturesMapOSM {
    
    #onClickSite;
    #handleSingleClick;
    
    constructor(mapRefOSM, hoverUID, updateSiteManagerSiteSelected) {
        this.cursorRef = 'pointer';
        this.featureRef = null;
        this.previousCursorRef = null;
        this.mapRefOSM = mapRefOSM;
        this.sourceRef = null;
        this.vectorLayerRef = null;
        this.hoverUID = hoverUID;
        this.updateSiteManagerSiteSelected = updateSiteManagerSiteSelected;
        this.#handleSingleClick = null;
        this.selectedSites = [];
    }
    
    set onClickSite(onClickSite) {
        this.#onClickSite = onClickSite;
        if (this.#handleSingleClick) {
            this.mapRefOSM.un('singleclick', this.#handleSingleClick);
        }
        this.#handleSingleClick = (event) => {
            if (this.cursorRef) {
                let feature = this.mapRefOSM.forEachFeatureAtPixel(event.pixel, (feature) => feature);
                if (feature) {
                    this.#onClickSite?.(feature.getId(), feature.get('label'));
                }
            }
        };
        this.mapRefOSM.on('singleclick', this.#handleSingleClick);
    }
    
    removeOldLayer = () => {
        this.mapRefOSM.removeLayer(this.vectorLayerRef);
    };
    setHoverUID = (hoverUID) => {
        this.hoverUID = hoverUID;
    };
    setSelectedSites = (siteIdsArray) => {
        this.selectedSites = siteIdsArray;
    }
    loadPins = (sites) => {
        const pins = sites && this.getPins(sites);
        if (!pins || !pins.length) return;
        
        const boundingBox = this.getBoundingBox(pins);
        
        const center = [
            (boundingBox[0] + boundingBox[2]) / 2,
            (boundingBox[1] + boundingBox[3]) / 2
        ];
        
        this.mapRefOSM.getView().setCenter(center);
        
        //this.mapRefOSM.getView().fit(boundingBox, { padding: [170, 50, 30, 150] });
        fitMap(this.mapRefOSM, boundingBox);

        this.loadVectorLayer();
        
        this.drawPins(sites);
    };
    
    getPins = (sites) => {
        // return sites.filter(s=>(s.lat && s.long)).map(getPinFromPoint);
        let siteArray = [];
        for (const key in sites) {
            if (sites[key] && sites[key].lat && sites[key].long) {
                siteArray.push(sites[key]);
            }
        }
        
        return siteArray.map(this.getPinFromPoint);
    };
    
    getPinFromPoint = (point) => {
        let coord = fromLonLat([point.long, point.lat]);
        
        return {
            ...point,
            x: coord[0],
            y: coord[1]
        };
        
    };
    
    getBoundingBox = (pins) => {
        const firstPin = pins[0];
        return pins.reduce((boudingBox, pin) => {
            if (pin.x < boudingBox[0]) boudingBox[0] = pin.x;
            if (pin.x > boudingBox[2]) boudingBox[2] = pin.x;
            if (pin.y < boudingBox[1]) boudingBox[1] = pin.y;
            if (pin.y > boudingBox[3]) boudingBox[3] = pin.y;
            
            return boudingBox;
        }, [firstPin.x, firstPin.y, firstPin.x, firstPin.y]);
    };
    
    drawPins = (sites) => {
        
        if (this.sourceRef) {
            let arrayFeatures = [];
            for (let key in sites) {
                let siteFeature = new Feature({
                    label: sites[key].name,
                    draggable: false,
                    geometry: new Point(fromLonLat([sites[key].long, sites[key].lat]))
                });
                siteFeature.setId(sites[key]._id);
                siteFeature.setStyle(PIN_STYLE);
                arrayFeatures.push(siteFeature);
            }
            this.sourceRef.addFeatures(arrayFeatures);
        }
    };
    
    handleMoveEvent = (event) => {
        if (this.cursorRef) {
            let feature = this.mapRefOSM.forEachFeatureAtPixel(event.pixel, (feature) => feature);
            let element = this.mapRefOSM.getTargetElement();
            let siteID = undefined;
            if (feature) {
                siteID = feature.id_;
                if (element.style.cursor !== this.cursorRef) {
                    this.previousCursorRef = element.style.cursor;
                    element.style.cursor = this.cursorRef;
                    this.featureRef = feature;
                    this.featureRef.setStyle([PIN_STYLE_HOVER, PIN_STYLE_LARGE]);
                    this.updateSiteManagerSiteSelected(siteID);
                }
            } else if (this.previousCursorRef !== undefined && this.featureRef != null) {
                element.style.cursor = this.previousCursorRef;
                this.previousCursorRef = undefined;
                siteID = this.featureRef.id_;
                if (!this.selectedSites.includes(siteID)) {
                    this.featureRef.setStyle(PIN_STYLE);
                }
                this.updateSiteManagerSiteSelected(null);
                this.featureRef = undefined;
            }
            
        }
    };
    
    loadVectorLayer = () => {
        if (this.mapRefOSM) {
            
            const vectorSource = new VectorSource({
                updateWhileInteracting: true,
                style: new Style({
                    fill: new Fill({
                        color: 'rgba(0, 0, 0, 0.3)'
                    }),
                    stroke: new Stroke({
                        width: 3,
                        color: 'rgba(0, 100, 240, 0.8)'
                    })
                })
            });
            
            const vectorLayer = new VectorLayer({
                zIndex: 30,
                source: vectorSource,
                stroke: new Stroke({
                    width: 3,
                    color: [255, 0, 0, 1]
                }),
                fill: new Fill({
                    color: [0, 0, 255, 0.6]
                })
            });
            this.mapRefOSM.addLayer(vectorLayer);
            this.vectorLayerRef = vectorLayer;
            this.sourceRef = vectorSource;
            
            this.mapRefOSM.addInteraction(new PointerInteraction({
                handleMoveEvent: this.handleMoveEvent
            }));
        }
    };
    
    // useEffect(loadPins, [sites]);
    
    hoverSite = () => {
        if(this.sourceRef){
            this.sourceRef.forEachFeature(feature => {
                const stylePin = feature.getId() === this.hoverUID ? [PIN_STYLE_HOVER, PIN_STYLE_LARGE] : PIN_STYLE;
                feature.setStyle(stylePin);
            });
        }
        /*if (this.hoverUID) {
            if (this.sourceRef) {
                this.featureRef = this.sourceRef.getFeatureById(this.hoverUID);
                if(this.featureRef){
                    this.featureRef.setStyle([PIN_STYLE_HOVER, PIN_STYLE_LARGE]);
                }
            }
        } else {
            if (this.featureRef) {
                this.featureRef.setStyle(PIN_STYLE);
                this.featureRef = undefined;
            }
        }*/
    };

    highlightSelectedSites = () => {
        for (const siteId of this.selectedSites) {
            if (this.sourceRef && siteId) {
                const selectedPin = this.sourceRef.getFeatureById(siteId);
                if(selectedPin){
                    selectedPin.setStyle([PIN_STYLE_HOVER, PIN_STYLE_LARGE]);
                }
            }
        }
    }
}

export default FeaturesMapOSM;
