// https://github.com/tomkp/react-split-pane

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Prefixer from 'inline-style-prefixer';
import stylePropType from 'react-style-proptype';
import Handle from '@material-ui/icons/Dehaze';

const USER_AGENT = window.navigator.userAgent;//'Mozilla/5.0 (Windows NT 6.1) AppleWebKit/537.2 (KHTML, like Gecko) Safari/537.2';



//var Color = require('color');

class Resizer extends Component {

    constructor(){
        super();
        this.state={
            hover: false
        };

        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
    }

    handleMouseEnter(){
        this.setState({
            hover:true
        });
    }

    handleMouseLeave(){
        this.setState({
            hover:false
        });
    }

    render() {
        const { split, className, resizerClassName, style, useHandle } = this.props;
        const classes = [resizerClassName, split, className];

        let _style = style; //_transparentBg = Color(style.backgroundColor).alpha(0.5).string();

        const adjustListeners = {
            onMouseDown:(event) => {
                this.props.onMouseDown(event);
            },
            onTouchStart:(event) => {
                event.preventDefault();
                this.props.onTouchStart(event);
            },
            onTouchEnd:(event) => {
                event.preventDefault();
                this.props.onTouchEnd(event);
            }
        };

        const handle = (useHandle) ? (
            <Handle
                className={"handle"}
                style={this.props.prefixer.prefix(this.props.handleStyle) || {}}
                {...adjustListeners}
                />
        ) : null;


        return (
            <span
                className={classes.join(' ')}
                style={this.props.prefixer.prefix(_style) || {}}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                {...(useHandle ? {} : adjustListeners)}
            >
                {handle}
        </span>
        );
    }
}

Resizer.propTypes = {
    onMouseDown: PropTypes.func.isRequired,
    onTouchStart: PropTypes.func.isRequired,
    onTouchEnd: PropTypes.func.isRequired,
    prefixer: PropTypes.instanceOf(Prefixer).isRequired,
    split: PropTypes.oneOf(['vertical', 'horizontal']),
    className: PropTypes.string.isRequired,
    resizerClassName: PropTypes.string.isRequired,
    style: stylePropType,
    handleStyle: stylePropType,
    useHandle: PropTypes.bool
};

Resizer.defaultProps = {
    prefixer: new Prefixer({ userAgent: USER_AGENT }),
    resizerClassName: 'Resizer',
    useHandle: true
};

export default Resizer;
