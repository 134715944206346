import React from 'react';
import { Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    checked: {
        color: '#007CB0 !important',
    },
    rootChecked: {
        color: '#000000', paddingTop: 3, paddingBottom: 3,
    },
});

export const CheckboxInput = ({ properties, onChange, value = false, disabled, additionalStyleChildren = {} }) => {
    const classes = useStyles();
    const { name, label, className } = properties;
    
    return (
        <div className={'form-checkbox-input-container' + (disabled ? ' disabled' : '') + (className ? ' ' + className : '')} style={additionalStyleChildren}>
            <div className="form-checkbox-input-label">
                <span>{label}</span>
            </div>
            <div className="form-checkbox-input">
                <Checkbox
                    name={name}
                    checked={value}
                    style={{ padding: '0px', marginLeft: '-2px' }}
                    classes={{ checked: classes.checked, root: classes.rootChecked }}
                    onChange={e => onChange(e.target.checked)}
                    disabled={disabled}
                />
            </div>
        </div>
    );
};