import {
    LOAD_OPTIONS_WIDGET,
    LOAD_DATA_WIDGET,
    SET_PARAMS_WIDGET
} from "../constants/ActionTypes";

const initialState = {
    data:{},
    options:{},
    params:{},
};

export function dashboards(state=initialState, action) {
    switch (action.type) {
        case LOAD_DATA_WIDGET:
            return {
                ...state,
                data:{...state.data, [action.id]: action.data}
            };
        case LOAD_OPTIONS_WIDGET:
            return {
                ...state,
                options:{...state.options, [action.id]: action.options}
            };
        case SET_PARAMS_WIDGET:
            return {
                ...state,
                params:{...state.params, [action.id]: action.params}
            } 
        default:
            return state;
    }
}