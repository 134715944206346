import {
    displayAllErrorFromAxios,
    FileService,
} from './util';

import axios from 'axios';
import {FIRMWARE_MGMT_CLEAN_STATUS, FIRMWARE_MGMT_UPLOAD_FILE} from '../constants/ActionTypes';
import {ALERT_ERROR, ALERT_LOADING, ALERT_SUCCESS} from "../constants/DeviceManager";
import {displayErrorOnBackgroundProcess} from "./errorDialogOnBackground";

// let source = axios.CancelToken.source();
// const CancelToken = axios.CancelToken;
// let source = CancelToken.source();
const CancelToken = axios.CancelToken;
let cancel;

export const uploadAndSaveFileFirmware = (file, fileName,fileType) => {
    return async  (dispatch) => {
        dispatch({type: FIRMWARE_MGMT_UPLOAD_FILE, message: `Uploading ${fileName} file...`, status:'uploading', typeAlert: ALERT_LOADING, fileName:fileName, file:file.name, fileType:fileType});

            let data = new FormData();
            data.append('file', file);
            data.append('fileType', fileType);
            // data.append('fileName', file.name);
            data.append('fileName', fileName);
            // data.append('fileVersion', 'fileVersion');
            await FileService.instance().post(`files/`, data, {
                    onUploadProgress: (progressEvent) => {
                        if (progressEvent.lengthComputable) {
                            let progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            //update status bar
                            dispatch({
                                type: FIRMWARE_MGMT_UPLOAD_FILE,
                                message: `Uploading ${fileName} file...`,
                                typeAlert: ALERT_LOADING,
                                status:'uploading',
                                progress: progress,
                                fileName:fileName,
                                file:file.name,
                                fileType:fileType
                            });
                        }
                    }, cancelToken: new CancelToken(function executor(c) {
                        // An executor function receives a cancel function as a parameter
                        cancel = c;
                    })
                }
            ).then((response) => {
                        dispatch({type: FIRMWARE_MGMT_UPLOAD_FILE, message: 'Firmware uploaded successfully.', typeAlert: ALERT_SUCCESS, status:'completed',  progress: 100, fileName:fileName, file:file.name, fileType:fileType})
                    }
                ).catch((error) => {
                    if(error.message ===  'Network Error'){
                        dispatch(displayAllErrorFromAxios(error));
                    }
                    if (axios.isCancel(error)) {
                        if(typeof error.message===  'undefined'){
                            error.message='Upload file canceled.'
                        }
                        dispatch({type: FIRMWARE_MGMT_UPLOAD_FILE, message: `Uploading ${fileName} file...`, typeAlert: ALERT_ERROR, status:'cancel', progress: 0, fileName:fileName, file:file.name, fileType:fileType});
                        dispatch(displayErrorOnBackgroundProcess(error.title,error.message,''))
                    }else{
                        const errorOnUploadFile = {}
                        errorOnUploadFile.message = 'Firmware upload failed.'
                        // if (error?.response?.data?.message === 'The upstream server is timing out') {
                        //     errorOnUploadFile.message = 'The maximum size allowed was exceeded.';
                        // }
                        dispatch({type: FIRMWARE_MGMT_UPLOAD_FILE, message: errorOnUploadFile.message, typeAlert: ALERT_ERROR, status:'error',  progress: 0, fileName:fileName, file:file.name, fileType:fileType})
                        if(error.response?.status === 400){
                            dispatch(displayErrorOnBackgroundProcess('Error',error.response.data.status.error,''))
                        }else{
                            dispatch(displayAllErrorFromAxios(error));
                            dispatch(displayErrorOnBackgroundProcess('Error',errorOnUploadFile.message,''))
                        }
                    }
                });
    }
}

export function cancelUploadFileFirmware() {
    return function (dispatch) {
        if (cancel !== undefined) {
            cancel();
        }
        return '';
    };
}

export const deleteFileFirmware = (fileId) => {
    return async dispatch => {
        try {
            await FileService.instance().delete(`files/`+fileId);
            dispatch({type: FIRMWARE_MGMT_UPLOAD_FILE, model: '', message: 'Firmware deleted successfully', typeAlert: ALERT_SUCCESS});
        } catch (error) {
            if(error.response.data){
                console.log(error)
            }
        }
    }
}

export function verifyFields(fields) {
    let error = false;
    let inputFields = {}
    return function (dispatch) {
        Object.keys(fields).forEach(key => {
            if (fields[key] === '') {
                error = true
                inputFields[key]=true
            }
            else{
                inputFields[key]=false
            }
        })
        if (error) {
            inputFields.containsError=error
        }
        return inputFields;
    };
}

export const clearStatus = () => {
    return async dispatch => {
            dispatch({type: FIRMWARE_MGMT_CLEAN_STATUS});
    }
}

//
// export const upgradeFirmware = (model, objectVersions, startDateTime) => {
//     return async (dispatch) => {
//         let data = {
//             operation: UPGRADE_FIRMWARE,
//             args : {version: objectVersions.version}
//         };
//         if (startDateTime) {
//             data.startDateTime = startDateTime.toISOString();
//         }
//
//         let parameters = 'deviceId=' + objectVersions.ids.join('&deviceId=');
//         dispatch({type: FIRMWARE_UPGRADE_STATUS, model: model, status: {message: `Upgrading firmware...`, type: ALERT_LOADING}});
//
//         try {
//             await DeviceManagementService.instance().post(`/devices/operations?${parameters}`, data);
//             dispatch({type: FIRMWARE_UPGRADE_STATUS, model: model, status: {message: 'Operation Upgrade Firmware was submitted', type: ALERT_SUCCESS}});
//
//         } catch (error) {
//             dispatch({type: FIRMWARE_UPGRADE_STATUS, model: model, status: {message: `${JSON.stringify(error.response.data)}`, type: ALERT_ERROR}});
//         }
//     }
// }

export const uploadAndSaveFile = (data, file, fileName, setStatus) => {
    return async  (dispatch,getState) => {
        setStatus({ message: `Uploading ${fileName} file...`, status: 'uploading', typeAlert: ALERT_LOADING, fileName: fileName });
        await FileService.instance().post(`files`, data, {
            onUploadProgress: (progressEvent) => {
                if (progressEvent.lengthComputable) {
                    let progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setStatus({
                        message: `Uploading ${fileName} file...`,
                        typeAlert: ALERT_LOADING,
                        status: 'uploading',
                        progress: progress,
                        fileName: fileName,
                    });
                    }
                }, cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                })
            }
        ).then((response) => {
            setStatus({ message: 'Certificate uploaded successfully.', typeAlert: ALERT_SUCCESS, status: 'completed', progress: 100, fileName: fileName });
        }).catch((error) => {
            if (error.message === 'Network Error') {
                dispatch(displayAllErrorFromAxios(error));
            }
            if (axios.isCancel(error)) {
                if (typeof error.message === 'undefined') {
                    error.message = 'Upload file canceled.';
                }
                setStatus({ message: `Uploading ${fileName} file...`, typeAlert: ALERT_ERROR, status: 'cancel', progress: 0, fileName: fileName });
                // dispatch(displayErrorOnBackgroundProcess(error.title, error.message, ''));
            } else {
                const errorOnUploadFile = {};
                errorOnUploadFile.message = 'Certificate upload failed.';
                // if (error?.response?.data?.message === 'The upstream server is timing out') {
                //     errorOnUploadFile.message = 'The maximum size allowed was exceeded.';
                // }
                setStatus({ message: errorOnUploadFile.message, typeAlert: ALERT_ERROR, status: 'error', progress: 0, fileName: fileName });
                if (error.response?.status === 400) {
                    dispatch(displayErrorOnBackgroundProcess('Error', error.response.data.status.error, ''));
                } else {
                    dispatch(displayAllErrorFromAxios(error));
                    dispatch(displayErrorOnBackgroundProcess('Error', errorOnUploadFile.message, ''));
                }
            }
        });
    }
}