import React, {useState, useEffect} from 'react';
import {useI18n} from '../../hooks';
import StyledDialog, { CancelButton, SaveButton } from '../util/Dialog';
import { Field, Form } from '../util/DataEntryTabularForm';
import ZToggle from './ZToggle';
import { useDispatch, useSelector } from 'react-redux';
import {
    closeDataEntry,
    displayErrorDialog,
    getRefreshRate,
    setRefreshRate,
    zoomFeature,
    setLocaleWithFallback,
    loadLocale, saveMaxResultsByPage
} from '../../actions';
import * as forms from '../../constants/DataEntryForms';
import { SUPPORTED_LOCALES } from '../../constants/i18n';
import ProgressDetails from '../deviceManager/common/ProgressDetails';
import {REPORT_KEY_DEFAULT} from "../../constants";

const UserSettingsDialog = () => {
    const open = useSelector(state => state.dataEntry.open && (state.dataEntry.activeForm === forms.USER_SETTINGS));

    const resize = useSelector(state => state.resize);
    const zoomObject = useSelector(state => state.user?.feature?.zoomObject);
    const currentMaxResultsByPage = useSelector(state => (state.report?.scopes)?state.report?.scopes[REPORT_KEY_DEFAULT]?.maxResultsByPage:null);

    const locale = useSelector(state => state.i18n.locale);
    const [autoRefreshEnabled, setAutoRefreshEnabled] = useState(null);
    const [zoomEnabled, setZoomEnabled] = useState(zoomObject);
    const [langLoading, setLangLoading] = useState(false);
    const [languageChange, setLanguageChange] = useState(null);
    const [maxResultsByPage, setMaxResultsByPage] = useState(currentMaxResultsByPage);
    const [refresh, setRefresh] = useState(5);

    const dispatch = useDispatch();
    const { translate } = useI18n('common');
    const AUTOREFRESH = translate('Auto-Refresh');
    const REFRESH_INT = translate('Refresh Interval (sec)');
    const MAPZOOM_SELECTED_OBJ = translate('Map Zoom is Based on Selected Object(s)');
    const MAX_RESULTS_BY_PAGE = translate('Max results by page');
    const LANGUAGE = translate('Language');
    const YES_LABEL = translate('Yes');
    const NO_LABEL = translate('No');
    const DISABLED_LABEL = translate('Disabled');
    const ENABLED_LABEL = translate('Enabled');
    const USER_SETTINGS = translate('User Settings');
    const languageChangeLocal = languageChange?languageChange:locale;

    const fetchAndUpdateRefreshRate = () => {
        if(open){
            dispatch(getRefreshRate()).then(rr => {
                setAutoRefreshEnabled(true);
                setRefresh(rr);
            }).catch(() => {

            });
        }
        if(zoomObject!==null){
            setZoomEnabled(zoomObject);
        }
    }

    useEffect(()=>{
        if(currentMaxResultsByPage!=null){
            setMaxResultsByPage(currentMaxResultsByPage);
        }
    },[currentMaxResultsByPage,open])

    useEffect(fetchAndUpdateRefreshRate, [open,zoomObject, dispatch]);

    const handleSave = () => {
        const cleanAfterSuccess = () => {
            const errorObject = {
                title:"Success",
                message: "User settings saved.",
                noconsole: true
            };

            dispatch(closeDataEntry());
            dispatch(displayErrorDialog(errorObject));
        }

        dispatch(zoomFeature({ zoomObject: zoomEnabled, zoomReport: false}));
        // Validate max results by page.
        const maxResultsByPageInt=parseInt(maxResultsByPage);

        if (isNaN(maxResultsByPageInt)||maxResultsByPageInt < 1 || maxResultsByPageInt > 10000) {
            dispatch(displayErrorDialog({message: "The range of Maximum Results by Page is between 1 and 10000"}));
            return;
        }
        else {
            dispatch(saveMaxResultsByPage(maxResultsByPage));
        }

        if(languageChange){
            setLangLoading(true);
            dispatch(setLocaleWithFallback(languageChange));
            dispatch(loadLocale(languageChange));
            setLangLoading(false);
            setLanguageChange(null);
        }



        if(!autoRefreshEnabled){
            dispatch(setRefreshRate(null)).then(cleanAfterSuccess);
            return;
        }

        const rr = parseInt(refresh);
        if(rr <= 0){
            dispatch(displayErrorDialog({message:"Interval must be a positive integer"}));
            return;
        }

        if(rr > 0 && rr < 5){
            dispatch(displayErrorDialog({message:"The minimum value of the interval is 5"}));
            return;
        }
        dispatch(setRefreshRate(rr)).then(cleanAfterSuccess);


    }

    const handleClose = () => {
        setLanguageChange(null);
        setZoomEnabled(zoomObject);
        setAutoRefreshEnabled(null);
        setRefresh(5);
        dispatch(closeDataEntry());
    }

    const handleRefreshRateChange = e => {
        setRefresh(e.currentTarget.value);
    }

    const handleChangeSelect = e => {
        setAutoRefreshEnabled(e.currentTarget.value === "true");
        setRefresh(30);
    }

    const handleZoomUserChoice = (arg, e) => {
        setZoomEnabled(arg);
    }

    const handleLanguageChange = e => {
        setLanguageChange(e.currentTarget.value);
    }

    const handleMaxResultsByPageChange=(e)=>{
        const value=parseInt(e.currentTarget.value);
        if(!isNaN(value)) {
           setMaxResultsByPage(value);
        }else if(e.currentTarget.value===''||e.currentTarget.value==='-'){
            setMaxResultsByPage(e.currentTarget.value);
        }
    }

    return (
        <StyledDialog
            open={open}
            onRequestClose={handleClose}
            onClose={handleClose}
            style={{overflowY:'auto'}}
            contentStyle={{maxWidth: 360}}
            title={USER_SETTINGS}
            actions={
                <>
                    <SaveButton id={"usersettings_save_btn"} onClick={handleSave} />
                    <CancelButton id={"usersettings_cancel_btn"} style={{position: 'absolute', left: 8}} onClick={handleClose} />
                </>
            }
            >
            {langLoading && <ProgressDetails width={resize.windowWidth} height={resize.windowHeight - 108} />}
            <Form>
                <Field label={AUTOREFRESH}>
                    <select id={"autorefresh_chk"} value={autoRefreshEnabled} onChange={handleChangeSelect}>
                        <option value={false}>{DISABLED_LABEL}</option>
                        <option value={true}>{ENABLED_LABEL}</option>
                    </select>
                </Field>
                <Field label={REFRESH_INT}>
                    <input id={"refreshintervalNum_txt"} style={{display:autoRefreshEnabled ? "block":"none"}} type={"number"} min="5" value={refresh} onChange={handleRefreshRateChange}/>
                    <input id={"refreshinterval_txt"} style={{display:autoRefreshEnabled ? "none":"block"}} type={"text"} disabled value={"N/A"}/>
                </Field>
                <Field label={MAPZOOM_SELECTED_OBJ}>
                    <ZToggle id={"mapzoom_chk"} onText={YES_LABEL} offText={NO_LABEL} on={zoomEnabled} onChange={handleZoomUserChoice}/>
                </Field>

                <Field label={MAX_RESULTS_BY_PAGE}>
                    <input id={"max_results_by_page"} style={{display:"block"}} type={"text"} onChange={handleMaxResultsByPageChange} value={maxResultsByPage} />
                </Field>

                <Field label={LANGUAGE}>
                    <select id={"locale_select"} value={languageChangeLocal} onChange={handleLanguageChange}>
                        {Object.keys(SUPPORTED_LOCALES).map(key => <option key={key} value={key}>{SUPPORTED_LOCALES[key]}</option>)}
                    </select>
                </Field>
            </Form>
        </StyledDialog>
    );
}

export default UserSettingsDialog;