import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../constants';
import { clearEmptyObjects, jsonPathReverseSetValue } from '../actions/util/transform-data';
import {
    closeAntennaDialog,
    closeConfirmDeleteDialog,
    closeSiteErrorDialog,
    deleteZoneById,
    getCurrentZonesFromStateForMap,
    getSiteDesignerId,
    loadAntennaModelsData,
    loadItemsByType,
    loadMapPhases,
    loadMapPhasesTypes,
    openAntennaFormDialog,
    openConfirmDeleteDialog,
    openEditFormDialog,
    openSiteErrorDialog,
    postMapPhase,
    unloadDeviceForm,
    unloadFormErrors,
    unloadSuccessFul,
    unloadZoneForm,
    updateFieldsForms,
    updateFieldsFormsNoData,
    updateStatusFeature,
    updateZoneFromGeoJSON,
    validateFormSiteManager,
} from '../actions';

const siteDesignerState = createSelector(
    (state) => state.siteDesigner,
    (properties) => {
        const {
            antennas = EMPTY_ARRAY,
            antennaModels = EMPTY_ARRAY,
            antennaForm = EMPTY_OBJECT,
            openFormDialog = false,
            formFieldErrors = EMPTY_OBJECT,
            successSave = false,
            devices = EMPTY_OBJECT,
            zones = EMPTY_OBJECT,
            formType = null,
            deviceForm = EMPTY_OBJECT,
            deviceType = null,
            selectedMapSD = EMPTY_OBJECT,
            openDialogPrevent = false,
            deviceToDelete = EMPTY_OBJECT,
            zoneType = null,
            zoneForm = EMPTY_OBJECT,
            errorDialog = EMPTY_OBJECT,
            mapPhases = EMPTY_ARRAY,
            mapPhasesTypes = EMPTY_ARRAY,
            deviceTypesData = EMPTY_ARRAY,
            componentTypesData = EMPTY_ARRAY,
            deviceConfigForm = EMPTY_OBJECT,
        } = properties || {};
        
        return {
            antennas,
            antennaModels,
            antennaForm,
            openFormDialog,
            formFieldErrors,
            successSave,
            devices,
            formType,
            deviceForm,
            deviceType,
            selectedMapSD,
            openDialogPrevent,
            deviceToDelete,
            zones,
            zoneType,
            zoneForm,
            errorDialog,
            mapPhases,
            mapPhasesTypes,
            deviceTypesData,
            componentTypesData,
            deviceConfigForm,
        };
    },
);

export const useSiteDesignerState = () => {
    return useSelector(state => siteDesignerState(state), shallowEqual);
};

export const useSiteDesignerDispatch = () => {
    const dispatch = useDispatch();
    return {
        getSiteId: useCallback((mapId) => dispatch(getSiteDesignerId(mapId)), [ dispatch ]),
        getAntennaModels: useCallback(() => dispatch(loadAntennaModelsData()), [ dispatch ]),
        openAddDialog: useCallback((payload, formType, deviceType) => dispatch(openAntennaFormDialog(formType, payload, deviceType)), [ dispatch ]),
        openEditDialog: useCallback((payload, formType, deviceType) => dispatch(openEditFormDialog(payload, formType, deviceType)), [ dispatch ]),
        closeDialog: useCallback(() => dispatch(closeAntennaDialog()), [ dispatch ]),
        //changeFields will be deprecated because cannot be updated fields in the same call more than one a time. Use better updateFormFields
        changeFields: useCallback((formField, data, paths, clearEmptyObj = true, detectChanges = true) => {
            let result = { [formField]: { ...data } };
            for (const [ path, value, emptyValues = [ null, undefined ] ] of paths) {
                result = jsonPathReverseSetValue(result, formField, path, value);
                if (clearEmptyObj && emptyValues.some(v => JSON.stringify(v) === JSON.stringify(value))) {
                    result = clearEmptyObjects(result, formField, path, emptyValues);
                }
            }
            dispatch(updateFieldsForms(formField, { ...result[formField] }));
        }, [ dispatch ]),
        updateFormFields: useCallback((formField, paths, clearEmptyObj = true, detectChanges = true) => {
            dispatch(updateFieldsFormsNoData(formField, paths, clearEmptyObj, detectChanges));
        }, [ dispatch ]),
        clearDeviceForm: useCallback(() => dispatch(unloadDeviceForm()), [ dispatch ]),
        // unloadZoneForm
        clearZoneForm: useCallback(() => dispatch(unloadZoneForm()), [ dispatch ]),
        validateForms: useCallback(
            (form, itemType, formKey, specificValuesValidation) => dispatch(validateFormSiteManager(form, itemType, formKey, specificValuesValidation)),
            [ dispatch ],
        ),
        clearFormErrors: useCallback(() => dispatch(unloadFormErrors()), [ dispatch ]),
        clearSuccessful: useCallback(() => dispatch(unloadSuccessFul()), [ dispatch ]),
        closeConfirmDialog: useCallback(() => dispatch(closeConfirmDeleteDialog()), [ dispatch ]),
        openConfirmDialog: useCallback((device) => dispatch(openConfirmDeleteDialog(device)), [ dispatch ]),
        openSiteErrorDialog: useCallback((payload) => dispatch(openSiteErrorDialog(payload)), [ dispatch ]),
        closeSiteErrorDialog: useCallback(() => dispatch(closeSiteErrorDialog()), [ dispatch ]),
        updateStatusFeature: useCallback((feature) => dispatch(updateStatusFeature(feature)), [ dispatch ]),
        getZonesFromZoneBuilder: useCallback((state, mapId) => getCurrentZonesFromStateForMap(state, mapId), []),
        updateZoneBuilder: useCallback((payload, zoneProps) => dispatch(updateZoneFromGeoJSON(payload, zoneProps)), [ dispatch ]),
        deleteZoneBuilder: useCallback((zoneId, childZones) => dispatch(deleteZoneById(zoneId, childZones)), [ dispatch ]),
        getMapPhasesTypes: useCallback(() => dispatch(loadMapPhasesTypes()), [ dispatch ]),
        getMapPhases: useCallback((mapId) => dispatch(loadMapPhases(mapId)), [ dispatch ]),
        saveMapPhase: useCallback((mapId, mapPhaseData) => dispatch(postMapPhase(mapId, mapPhaseData)), [ dispatch ]),
        getItemsByType: useCallback((type) => dispatch(loadItemsByType(type)), [ dispatch ]),
    };
};
