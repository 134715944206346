import React, { useState } from 'react';
import { I18n } from 'react-redux-i18n';
import { refStore } from '../../../vss/app/actions';
import { useSiteManager } from '../../../hooks';
import { SITE_MANAGER_CONFIGURATION_ZONES_SCOPE } from '../../../constants';
import {DialogActions, DialogTitle, IconButton} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/styles";
import Checkbox from "@material-ui/core/Checkbox";

const translate = (key) => I18n.t('common.' + key);

const useStyles = makeStyles({
    paperDialog: {
        overflowY: 'visible',
        overflowX: 'hidden',
        width: 600,
    },
    root: {
        padding: 0,
        '&.Mui-checked': {
            color: '#007CB0',
            backgroundColor: 'transparent',
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
});

export const DialogDeleteChildrenZones = ({ onClose }) => {
    const [ deleteChildZones, setDeleteChildZones ] = useState(false);
    const { form } = useSiteManager(SITE_MANAGER_CONFIGURATION_ZONES_SCOPE);
    const hasChildren = form.hasChildren;
    const classes = useStyles();
    
    const handleDeleteZone = async () => {
        refStore.configZonesMap?.deleteZones(deleteChildZones);
        onClose();
        setDeleteChildZones(false);
    };
    
    const renderCloseButton = () => {
        return (
            <div style={{ position: 'absolute', top: '-0.5em', right: '-0.5em' }}>
                <IconButton
                    aria-label="close"
                    size="small"
                    onClick={onClose}
                    style={{ backgroundColor: '#000', cursor: 'pointer', position: 'relative', zIndex: 100, width: '30px', height: '30px' }}
                >
                    <CloseIcon style={{ color: '#fff' }} />
                </IconButton>
            </div>
        );
    };
    return (
        <Dialog
            open={true}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    onClose();
                }
            }}
            classes={{ paper: classes.paperDialog }}
            className="dialog-zones"
            maxWidth={'md'}
        >
            <DialogTitle style={{ textTransform: 'capitalize' }}>
                Delete zone
            </DialogTitle>
            {renderCloseButton()}
            <DialogContent style={{padding: '8px 24px'}}>
                <span>Do you want to delete zone?</span>
                <br /><br />
                {hasChildren && <div style={{ display: 'flex', justifyContent: 'start' }}>
                    <div
                        style={{
                            marginRight: '13px',
                            lineHeight: '26px',
                            fontStyle: 'italic',
                            fontSize: '0.9em',
                        }}
                    >Delete with child zones
                    </div>
                    <div><Checkbox
                        disableRipple
                        checked={deleteChildZones}
                        classes={{ root: classes.root }}
                        value={deleteChildZones}
                        onChange={() => setDeleteChildZones(!deleteChildZones)}
                    /></div>
                </div>}
            </DialogContent>
            <DialogActions>
                <Button
                    style={{ background: '#fff', color: '#007CB0', padding: '0.5em', textTransform: 'none' }}
                    onClick={onClose}
                    component="span"
                >
                    {translate('Cancel')}
                </Button>
                <Button
                    style={{ background: '#007CB0', color: '#fff', padding: '0.5em', textTransform: 'none' }}
                    onClick={handleDeleteZone} component="span"
                >
                    {translate('Delete')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
