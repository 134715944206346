import Vector from '../../vss/app/utils/ol/Vector';
import { Polygon } from 'ol/geom';
import { getOlStoreKey } from '../helpers';
import { FeatureType } from '../../vss/app/views/sites/designer/constants';
import {ItemType} from '../../constants';

// Requires OlBase class
export function olJoinNeighborZonesFactory(Base) {
    class OlJoinNeighborZonesFactory extends Base {

        joinNeighborZones() {
            const data = {};
            const selectedZones = [];
            this.zones.forEach((zone) => {
                const zoneKey = this.getItemKey(zone);
                if (this[getOlStoreKey(FeatureType.ZONE)].isSelectedItem(zoneKey)) {
                    selectedZones.push(zone);
                    const coordinates = JSON.parse(JSON.stringify(this.getItemCoordinates(zone)));
                    data[zoneKey] = {coordinates, used: new Array(coordinates.length), zoneId: zoneKey};
                }
            });


            if (selectedZones.length >= 2 && selectedZones.length <= 100) {

                let minSideSize = -1;
                for (const zone of Object.values(data)) {
                    for (let i = 0; i < zone.coordinates.length - 1; i++) {
                        const A = new Vector(...zone.coordinates[i]);
                        const B = new Vector(...zone.coordinates[i + 1]);
                        const sideSize = B.sub(A).mod();
                        if (minSideSize === -1) {
                            minSideSize = sideSize;
                        } else if (sideSize < minSideSize) {
                            minSideSize = sideSize;
                        }
                    }
                }
                const minSpace = minSideSize * 0.4;


                // Flag for show a message not enough close.
                let joinedNeighborZones = false;

                const selectedChangedIdZones = new Set();
                for (const zone of Object.values(data)) {
                    for (let pi = 0; pi < zone.coordinates.length - 1; pi++) {
                        const pivot = zone.coordinates[pi];
                        const neighborZones = [];
                        for (const neighbor of Object.values(data)) {
                            if (zone.zoneId !== neighbor.zoneId) {
                                let nearestVertexIndex = -1;
                                let nearestVertexDistance;
                                for (let i = 0; i < neighbor.coordinates.length - 1; i++) {
                                    const vertex = neighbor.coordinates[i];
                                    const A = new Vector(...pivot);
                                    const B = new Vector(...vertex);
                                    const distance = B.sub(A).mod2();
                                    if (!neighbor.used[i] && distance < minSpace * minSpace) {
                                        if (nearestVertexIndex === -1) {
                                            nearestVertexIndex = i;
                                            nearestVertexDistance = distance;
                                        } else {
                                            if (distance < nearestVertexDistance) {
                                                nearestVertexIndex = i;
                                                nearestVertexDistance = distance;
                                            }
                                        }
                                    }
                                }
                                if (nearestVertexIndex !== -1) {
                                    neighbor.used[nearestVertexIndex] = true;
                                    neighborZones.push({zone: neighbor, nearestVertexIndex});
                                    selectedChangedIdZones.add(neighbor.zoneId);
                                }
                            }
                        }

                        if (neighborZones.length) {
                            const neighbors = [
                                {zone, nearestVertexIndex: pi},
                                ...neighborZones
                            ];
                            selectedChangedIdZones.add(zone.zoneId);
                            const centroidX = neighbors.reduce((x, neighbor) => neighbor.zone.coordinates[neighbor.nearestVertexIndex][0] + x, 0) / neighbors.length;
                            const centroidY = neighbors.reduce((y, neighbor) => neighbor.zone.coordinates[neighbor.nearestVertexIndex][1] + y, 0) / neighbors.length;
                            const centroid = new Vector(centroidX, centroidY);
                            neighbors.forEach((neighbor) => {
                                // without margin
                                // neighbor.zone.coordinates[neighbor.nearestVertexIndex] = [centroidX, centroidY];

                                // with margin v1
                                // const neighborPoint = new Vector(...neighbor.zone.coordinates[neighbor.nearestVertexIndex]);
                                // const v = neighborPoint.sub(centroid).unit().prod(0.01);
                                // const newPoint = centroid.add(v);
                                // neighbor.zone.coordinates[neighbor.nearestVertexIndex] = newPoint.coordinates();
                                neighbor.zone.coordinates[neighbor.nearestVertexIndex] = centroid.coordinates();
                            });
                            neighbors.forEach((neighbor) => neighbor.zone.coordinates[neighbor.zone.coordinates.length - 1] = neighbor.zone.coordinates[0]);
                            joinedNeighborZones = true;
                            // For add a gap between the selected zones
                            // with margin v2
                            // fix precision
                            // neighbors.forEach((neighbor) => {
                            //     neighbor.zone.coordinates[neighbor.nearestVertexIndex] = getPointTranslatedInsideOfPolygon({ coordinates: [neighbor.zone.coordinates] }, neighbor.nearestVertexIndex, this.DISTANCE_MINIMUM_GAP).coordinates;
                            // });
                            // neighbors.forEach((neighbor) => neighbor.zone.coordinates[neighbor.zone.coordinates.length - 1] = neighbor.zone.coordinates[0]);
                        }
                    }
                }
                if (joinedNeighborZones === true) {
                    for (const feature of this[getOlStoreKey(FeatureType.ZONE)].selectedItemsFeatureCollection.getArray()) {
                        feature.setGeometry(new Polygon([data[this.getFeatureKey(feature)].coordinates]));
                    }

                    this.observer?.executeEvent('onSaveZoneChanges', {
                        zonesBeforeChanges: this.zones,
                        zonesAfterChanges: this.getFeaturesDataArrayByItemType(ItemType.ZONE),
                        takeASnapshot: true,
                        event: 'joinNeighborZones'
                    });
                } else {
                    this.observer?.executeEvent('onShowMessage', {
                        title: "Join Neighbor Zones",
                        message: "Cannot join selected zones."
                    });
                }
            }
            else if (selectedZones.length > 100) {
                this.observer?.executeEvent('onShowMessage', {
                    title: "Join Neighbor Zones",
                    message: "Too many zones selected (max 100)."
                });
            }
            else if (selectedZones.length < 2) {
                this.observer?.executeEvent('onShowMessage', {
                    title: "Join Neighbor Zones",
                    message: "First select two or more neighbor zones."
                });
            }
        }
    }
    
    return OlJoinNeighborZonesFactory;
}
