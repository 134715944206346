import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const AutoZoneAndLabelIcon = (props) => (
    <SvgIcon {...props}>
        <rect x="0" y="0" width="24" height="24" stroke="black"/>
        <text x="50%" y="50%" fontSize="12" dominantBaseline="middle" textAnchor="middle">AZ</text>
        <rect height="5" style={{fill:'white',stroke:'black'}} width="20" x="2" y="18" />
    </SvgIcon>
);

export default AutoZoneAndLabelIcon;
