import {
    CLOSE_DATAENTRY_FORM,
    DELETE_MULTIPLE_RECORDS,
    LOAD_FORM_FIELDS,
    OPEN_DATAENTRY_FORM
} from '../constants/ActionTypes';

const initialState = {
    open:false,
    activeForm:"",
    initialFormData:{},
    dynamicFormFields:{},
    informationDataDeleted:[]
};

export function dataEntry(state = initialState, action){

    switch(action.type){

        case LOAD_FORM_FIELDS:
            return {
                ...state,
                dynamicFormFields:{
                    ...state.dynamicFormFields,
                    [action.formId]: action.data
                }
            };

        case OPEN_DATAENTRY_FORM:
            return {
                open:true,
                activeForm: action.form,
                initialFormData: action.data,
                informationDataDeleted:[]
            };
        case CLOSE_DATAENTRY_FORM:
            return {
                open:false,
                activeForm: "",
                informationDataDeleted:[]
            };
        case DELETE_MULTIPLE_RECORDS:
            let informationDataDeleted = state.informationDataDeleted;
            return {
                ...state,
                informationDataDeleted: [...informationDataDeleted, action.data]
            };
        default:
            return state;
    }


}
