import {INITIAL_IMPORT_FILTERS, LOAD_IMPORT_HISTORY, LOAD_TOKEN_REPLACEMENTS} from '../constants/ActionTypes';
//import moment from 'moment/moment.js';

//let today = () => moment().startOf('day');
//let tomorrow = () => today().add(1, 'days');

const initialState = {
    importHistoryList: []
};

export function importReport(state = initialState, action){
    switch(action.type) {
        case LOAD_IMPORT_HISTORY:
            return {...state, importHistoryList: action.importHistoryList};
        case INITIAL_IMPORT_FILTERS:
            return {...state, filters: action.filters};
        case LOAD_TOKEN_REPLACEMENTS:
            return {...state, tokenReplacements: action.tokenReplacements}
        default:
            return state;            
    }
}
