import {DISPLAY_NOTIFICATION, UPDATE_NOTIFICATION} from "../constants/ActionTypes";

export const displayNotification = (openNotification,typeNotification, nameNotification,subTitleNotification,messageNotification,showIcon,autoHideDurationNotification=5000,idSetTimeOut) =>{
    return (dispatch) => {
      dispatch({type: DISPLAY_NOTIFICATION,
          openNotification:openNotification,
          typeNotification: typeNotification,
          nameNotification:nameNotification,
          subTitleNotification: subTitleNotification,
          messageNotification: messageNotification,
          showIcon:showIcon,
          autoHideDurationNotification:autoHideDurationNotification,
          idSetTimeOut:idSetTimeOut
          })
  };
};
export const disableNotification = () =>{
    return (dispatch) => {
        dispatch({type: DISPLAY_NOTIFICATION,
            openNotification:false,
            typeNotification: '',
            nameNotification:'',
            subTitleNotification: '',
            messageNotification: '',
            showIcon:'',
            autoHideDurationNotification:0,
            idSetTimeOut:0
        })
    };
};
export const updateIdSetNotification = (idSetTimeOut) =>{
    return (dispatch) => {
        dispatch({type: UPDATE_NOTIFICATION,
            idSetTimeOut:idSetTimeOut,
        })
    };
};

