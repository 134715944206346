import Style from "ol/style/Style";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import CircleStyle from "ol/style/Circle";
import MultiPoint from "ol/geom/MultiPoint";
import Color from "color";
// import {intersectsChildPolygonsGeom} from "../../vss/app/utils/ol/utils";

const GeometryTypes = {
    POLYGON: 'Polygon',
    MULTI_POLYGON: "MultiPolygon",
    POINT: 'Point',
    LINE_STRING: "LineString",
    MULTI_LINE_STRING:"MultiLineString",
    GEOMETRY_COLLECTION: "GeometryCollection"
};


export function getEditingStyles({ strokeColor, fillColor, drawing, withVertices, lineDash }) {
    var styles = {};
    var white = [255, 255, 255, 1]; // useful backdrop
    var width = 3;

    styles[GeometryTypes.POLYGON] = [
        new Style({
            fill: new Fill({
                color: fillColor
            }),
            stroke: (drawing) ? null : new Stroke({
                color: strokeColor,
                width: width,
                lineDash: lineDash,
            })
        }),

    ];
    if (withVertices) {
        styles[GeometryTypes.POLYGON].push(
            new Style({
                image: new CircleStyle({
                    radius: 5,
                    fill: new Fill({
                        color: lineDash ? strokeColor : 'blue',
                    }),
                    // stroke: new Stroke({
                    //     color: strokeColor,
                    //     width
                    // })
                }),
                geometry: function (feature) {
                    // return the coordinates of the first ring of the polygon
                    const coordinates = feature.getGeometry().getCoordinates()[0];
                    return new MultiPoint(coordinates);
                },
            }),
        );
    }
    styles[GeometryTypes.MULTI_POLYGON] = styles[GeometryTypes.POLYGON];

    styles[GeometryTypes.POINT] = [
        new Style({
            image: new CircleStyle({
                radius: width * 2,
                fill: new Fill({
                    color: strokeColor
                }),
                stroke: new Stroke({
                    color: white,
                    width: width / 2
                })
            }),
            zIndex: Infinity
        })
    ];
    styles[GeometryTypes.MULTI_POINT] = styles[GeometryTypes.POINT];


    styles[GeometryTypes.LINE_STRING] = [
        new Style({
            stroke: new Stroke({
                color: white,
                width: width + 2
            })
        }),
        new Style({
            stroke: new Stroke({
                color: strokeColor,
                width: width
            })
        })
    ];
    styles[GeometryTypes.MULTI_LINE_STRING] = styles[GeometryTypes.LINE_STRING];


    styles[GeometryTypes.GEOMETRY_COLLECTION] =
        styles[GeometryTypes.POLYGON].concat(
            styles[GeometryTypes.LINE_STRING],
            styles[GeometryTypes.POINT]
        );

    return styles;
}
class StyleCache {
    constructor(fnProps){
        this.cachedStyles = {};
        this.fnProps = fnProps;
    }

    getStyle({type,selected,color}){
        if(!this.cachedStyles[color]){
            this.cachedStyles[color] = this.getStylesForColor(color,this.fnProps);
        }
        return this.cachedStyles[color][selected][type];
    }

    getStylesForColor(color,o){

        const c = Color(color);

        return [
            getEditingStyles({ // no selected
                ...o,
                fillColor: c.alpha(0.4).rgb().string(),
                strokeColor: c.rgb().string()
            }),
            getEditingStyles({ // selected
                ...o,
                fillColor: c.alpha(0.8).rgb().string(),
                strokeColor:c.rgb().string(),
                withVertices: true,
            }),
            getEditingStyles({ // draft
                ...o,
                fillColor: c.alpha(0.1).rgb().string(),
                strokeColor:c.rgb().string(),
                withVertices: true,
                lineDash: [4,8],
            })
        ];
    }


}

export function getStylingFunction(o={}){
    const cache = new StyleCache(o);

    return (feature)=>{
        const type = feature.getGeometry().getType();
        const selected = isDraft(feature) ? 2 : isSelected(feature) ? 1 : 0;
        // const polygon = {
        //     coords: feature.getGeometry().getCoordinates()[0],
        //     id: feature.values_?.id
        // };
        // const polygonSet = o.features?.map((f) => ({
        //     coords: f.getGeometry().getCoordinates()[0],
        //     id: f.values_?.id
        // }));
        let color = getColor(feature) || o.defaultColor || "#00779F";
        color = feature.get('isCollided') ? '#FF0000' : color;
        // if (feature.getGeometry().getCoordinates()?.[0]?.length >= 4) {
        //     if(intersectsChildPolygonsGeom(polygon, polygonSet)){
        //         color = '#FF0000';
        //     }
        // }
        return cache.getStyle({type,selected,color});
    };
}

function isSelected(feature){
    const data = feature.get('data');
    return !!(data && data.selected);
}

function getColor(feature){
    const data = feature.get('data');
    return (data && data.color);
}

function isDraft(feature){
    const data = feature.get('data');
    return !!(data && data.draft);
}
