import React from 'react';


import {viewResourceTypeDetails} from "../../actions";
import {useDispatch} from "react-redux";
// import {useI18n} from "../../hooks/i18n";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import {
    useOnePlatformTableDispatch,
    useOnePlatformTableState,
    useReportDispatch,
    useReportKey
} from "../../hooks";


const ResourceTypesViewProfile = ({rowIndex}) => {

    const dispatch = useDispatch();
    // const [setModalProfile] = useState(false);
    // const { translate } = useI18n('common.filterTable');
    // const reportKey="resourceTypes";
    const { reportKey } = useReportKey();
    // const { showDetails } = useReportState(reportKey);
    const { patchReportProperties } = useReportDispatch(reportKey);

    const { selectedRows,  data: dataTable } = useOnePlatformTableState(reportKey);
    const { replaceSelectedRows } = useOnePlatformTableDispatch(reportKey);
    const handleShowAttributes = () => {
        if (rowIndex !== undefined) {
            dispatch(viewResourceTypeDetails(rowIndex));
            // dispatch(viewResourceTypeFilters(rowIndex));
            // setModalProfile(true);
            // dispatch(showAttributes(true));

            const row = dataTable[rowIndex];
            const newSelectedRows = { ...selectedRows };
            if (newSelectedRows.hasOwnProperty(row.___uKey)) {
                delete newSelectedRows[row.___uKey];
            } else {
                newSelectedRows[row.___uKey] = row;
            }
            replaceSelectedRows(newSelectedRows)();

            if (Object.keys(newSelectedRows).length>0)
                patchReportProperties({ showDetails: true, customDetails:['customProperties','filters'] });
            else
                patchReportProperties({ showDetails: false, customDetails:['customProperties','filters'] });

            getFocusWithoutScrolling();
        }
    };

    const getFocusWithoutScrolling=()=> {
        try{
            if (document.getElementById("focus-filter") === null) {
                document.getElementById("filterCard").focus();
            } else {
                document.getElementById("focus-filter").focus();
            }
        }catch(e){
        }
    }

    // const closeDialogProfile = () =>{
    //     setModalProfile(false);
    // }

    return (
        <div className={"actionContainer"}>
            {/*<button className={"actionButtonContainer"} style={{backgroundColor:"gray"}} onClick={handleShowAttributes}>{translate('Filters')}</button>*/}
            <div  style={{textAlign:"center", paddingTop: "0.3em"}}>
                <MoreHorizIcon onClick={handleShowAttributes} style={{fill:'#8b8b8f',cursor: "pointer"}} />
            </div>
            {/*{openModalProfile? <DialogProfilesResourceType  closeDialog={closeDialogProfile} open={openModalProfile}  />:null }*/}
        </div>
    )
};

export default ResourceTypesViewProfile;
