import { useDispatch, useSelector } from 'react-redux';
import {loadConfigurationField, maxZonesProcessForm} from '../actions/formsOnePlatform';
import { createSelector } from 'reselect';

const selectFilterValues = createSelector(
    (state, ...keys) => {
        let data = state.formsOnePlatform;
        for (const key of keys) {
            if (key && data[key]) {
                data = data[key];
            } else {
                return data;
            }
        }
        return data;
    },
    (state) => {
        return state;
    },
);

export const useOnePlatformFormsState = (reportKey, formKey, attrKey, key) => {
    return useSelector(state => selectFilterValues(state, reportKey, formKey, attrKey, key), (prevState, nextState) => {
        return JSON.stringify(prevState) === JSON.stringify(nextState);
    });
};

export const useOnePlatformFormsDispatch = (reportKey) => {
    const dispatch = useDispatch();
    return {
        loadConfigurationField: (formMode, fieldKey, params) => () => dispatch(loadConfigurationField(reportKey, formMode, fieldKey, params)),
        maxZonesProcess: () => dispatch(maxZonesProcessForm(reportKey))
    };
};
