import DragBox from 'ol/interaction/DragBox';
import Collection from 'ol/Collection';
import Select from 'ol/interaction/Select';
import Draw from 'ol/interaction/Draw';
import { getOlStoreKey } from '../helpers';

// Requires OlBase class
// Requires getFeatureKey function

export function olSelectionFactory(itemType) {
    return function (Base) {
        
        class OlSelection extends Base {
            constructor(mapRef) {
                super(mapRef);
                
                const storeKey = getOlStoreKey(itemType);
                if (!this[storeKey]) {
                    this[storeKey] = {};
                }
                const store = this[storeKey];
                
                store.singleSelectionEnabled = false;
                store.multiSelectionEnabled = false;
                store.selectedItemIds = [];
                store.selectedItemsFeatureCollection = new Collection();
                store.noSelectedItemsFeatureCollection = new Collection();
                store.dragBoxInteraction = null;
                
                store.selectInteraction = new Select({
                    condition: () => false,
                    features: store.selectedItemsFeatureCollection,
                    style: (feature) => feature.get('featureStyle')(feature),
                });
                store.selectInteraction.set('addedInteraction', true);
                store.selectInteraction.set('addedInteractionType', 'select');
                this.mapRef.addInteraction(store.selectInteraction);
                store.eventClickSelectHandler = (evt) => {
                    if (store.singleSelectionEnabled || store.multiSelectionEnabled) {
                        const features = this.mapRef.getFeaturesAtPixel(evt.pixel)
                            .filter(feature => feature && itemType === this.getFeatureItemType(feature));
                        const feature = this.tiebreakerFeaturesForSingleSelection(features);
                        if (feature) {
                            if (!evt.originalEvent.shiftKey) {
                                store.clearSelectedItems();
                            }
                            if (store.multiSelectionEnabled || store.singleSelectionEnabled) {
                                store.addSelectedItem(this.getFeatureKey(feature));
                            }
                        } else {
                            if (store.selectedItemIds.length) {
                                let isDrawing = false;
                                if(this.mapRef!=null) {
                                    this.mapRef.interactions.forEach(interaction => {
                                        if (interaction instanceof Draw) {
                                            isDrawing=true;
                                        }
                                    });
                                }
                                if(!isDrawing)
                                    store.clearSelectedItems();

                            }
                        }
                    }
                };
                
                this.mapRef.on('click', store.eventClickSelectHandler);
                
                store.enableSingleSelection = () => {
                    store.singleSelectionEnabled = true;
                    store.multiSelectionEnabled = false;
                };
                
                store.enableMultiSelection = () => {
                    store.singleSelectionEnabled = false;
                    store.multiSelectionEnabled = true;
                };
                
                store.disableSingleSelection = () => {
                    store.singleSelectionEnabled = false;
                };
                
                store.disableMultiSelection = () => {
                    store.multiSelectionEnabled = false;
                };
                
                store.disableSelection = () => {
                    store.singleSelectionEnabled = false;
                    store.multiSelectionEnabled = false;
                };
                
                store.isSelectedItem = (itemId) => {
                    return store.selectedItemIds.includes(itemId);
                };
                
                store.clearSelectedItems = (options) => {
                    const { background = false } = options || {};
                    store.selectedItemIds = [];
                    store.selectedItemsFeatureCollection?.clear();
                    store.noSelectedItemsFeatureCollection?.clear();
                    for (const feature of this.featuresCollection.getArray()) {
                        if (this.getFeatureItemType(feature) === itemType) {
                            store.noSelectedItemsFeatureCollection.push(feature);
                        }
                    }
                    this.updateLayer();
                    if (!background) {
                        // console.log("onSelectedItemsChanged")
                        this.observer?.executeEvent('onSelectedItemsChanged', {
                            action: { type: 'clearSelectedItems', featureType: itemType },
                            selectedItemIds: store.selectedItemIds,
                            selectedItemsFeatureCollection: store.selectedItemsFeatureCollection,
                            noSelectedItemsFeatureCollection: store.noSelectedItemsFeatureCollection,
                        });
                    }
                };
                
                store.addSelectedItem = (itemKey, options) => {
                    const { forceMultiSelection = false, background = false } = options || {};
                    if (store.multiSelectionEnabled || store.singleSelectionEnabled || forceMultiSelection) {
                        if (!store.selectedItemIds.includes(itemKey)) {
                            if (store.multiSelectionEnabled || forceMultiSelection) {
                                store.selectedItemIds.push(itemKey);
                                const feature = this.source.getFeaturesCollection()
                                    .getArray()
                                    .find(feature => itemKey === this.getFeatureKey(feature) && itemType === this.getFeatureItemType(feature));
                                
                                if (feature) {
                                    store.selectedItemsFeatureCollection.push(feature);
                                    store.noSelectedItemsFeatureCollection.remove(feature);
                                }
                            } else {
                                const feature = this.source.getFeaturesCollection()
                                    .getArray()
                                    .find(feature => itemKey === this.getFeatureKey(feature) && itemType === this.getFeatureItemType(feature));

                                store.clearSelectedItems();
                                store.selectedItemIds.push(itemKey);
                                
                                if (feature) {
                                    store.selectedItemsFeatureCollection.push(feature);
                                    store.noSelectedItemsFeatureCollection.remove(feature);
                                }
                            }
                            this.updateLayer();
                            if (!background) {
                                // console.log("onSelectedItemsChanged")
                                this.observer?.executeEvent('onSelectedItemsChanged', {
                                    action: { type: 'addSelectedItem', itemKey, options, featureType: itemType },
                                    selectedItemIds: store.selectedItemIds,
                                    selectedItemsFeatureCollection: store.selectedItemsFeatureCollection,
                                    noSelectedItemsFeatureCollection: store.noSelectedItemsFeatureCollection,
                                });
                            }
                            return true;
                        }
                    }
                    return false;
                };
                
                store.addSelectedItems = (itemKeys, options) => {
                    for (const itemKey of itemKeys) {
                        store.addSelectedItem(itemKey, {...options,background:true});
                    }
                    this.observer?.executeEvent('onSelectedItemsChanged', {
                        selectedItemIds: store.selectedItemIds
                    });

                };
                
                store.removeSelectedItem = (itemKey, options) => {
                    const { background = false } = options || {};
                    if (store.multiSelectionEnabled || store.singleSelectionEnabled) {
                        if (store.selectedItemIds.includes(itemKey)) {
                            store.selectedItemIds = store.selectedItemIds.filter(_itemKey => _itemKey !== itemKey);
                            const feature = this.source.getFeaturesCollection()
                                .getArray()
                                .find(feature => itemKey === this.getFeatureKey(feature) && itemType === this.getFeatureItemType(feature));
                            store.selectedItemsFeatureCollection.remove(feature);
                            store.noSelectedItemsFeatureCollection.push(feature);
                            this.updateLayer();
                            
                            if (!background) {
                                // console.log("onSelectedItemsChanged")
                                this.observer?.executeEvent('onSelectedItemsChanged', {
                                    action: { type: 'removeSelectedItem', itemKey, options, featureType: itemType },
                                    selectedItemIds: store.selectedItemIds,
                                    selectedItemsFeatureCollection: store.selectedItemsFeatureCollection,
                                    noSelectedItemsFeatureCollection: store.noSelectedItemsFeatureCollection,
                                });
                            }
                            return true;
                        }
                    }
                    return false;
                };
                
                store.isEnabledDragBoxSelection = () => {
                    return !!store.dragBoxInteraction;
                };
                
                store.initializeDragBoxSelection = () => {
                    store.finalizeDragBoxSelection();
                    store.dragBoxInteraction = new DragBox();
                    
                    this.mapRef.addInteraction(store.dragBoxInteraction);

                    store.dragBoxInteraction.on('boxstart', () => store.clearSelectedItems());
                    
                    store.dragBoxInteraction.on('boxend', () => {
                        const extent = store.dragBoxInteraction.getGeometry().getExtent();
                        const boxFeatures = this.source
                            .getFeaturesInExtent(extent)
                            .filter((feature) => feature.getGeometry().intersectsExtent(extent));
                        store.addSelectedItems(boxFeatures.map(this.getFeatureKey), { forceMultiSelection: true });
                    });
                };
                
                store.finalizeDragBoxSelection = () => {
                    if (store.dragBoxInteraction) {
                        this.mapRef.removeInteraction(store.dragBoxInteraction);
                    }
                    store.dragBoxInteraction = null;
                };
                
                store.updateSelectedItemsFeatureCollection = () => {
                    const selectedItemIdsBackup = store.selectedItemIds;
                    store.clearSelectedItems({ background: true });
                    for (const feature of this.featuresCollection.getArray()) {
                        const featureKey = this.getFeatureKey(feature);
                        const featureItemType = this.getFeatureItemType(feature);
                        if (selectedItemIdsBackup.includes(featureKey) && featureItemType === itemType) {
                            store.selectedItemIds.push(featureKey);
                            store.selectedItemsFeatureCollection.push(feature);
                            store.noSelectedItemsFeatureCollection.remove(feature);
                        }
                    }
                };
            }
        }
        
        return OlSelection;
    };
}
