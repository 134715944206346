import { FORMS_ONE_PLATFORM_REPLACE_FORMS,FIELDS_ONE_PLATFORM_PATCH_FIELD } from '../constants/ActionTypes/formsOnePlatform';
import { ERROR, LOADING, SUCCESS } from '../constants';
import {getDataOfPath, getValueOfPath} from "../utils";
import {getData} from "./filtersOnePlatform";
import {FILTER_TYPE as Field_TYPE} from "../views/util/report/filter/util";
export const replaceForms = (reportKey, forms) => {
    return {
        type: FORMS_ONE_PLATFORM_REPLACE_FORMS,
        reportKey,
        forms,
    }
}

export const formsOnePlatformPatchField = (field, reportKey,formMode, fieldKey) => {
    return {
        type: FIELDS_ONE_PLATFORM_PATCH_FIELD,
        field,
        reportKey,
        formMode,
        fieldKey
    };
};

export const loadConfigurationField = (reportKey,formMode, fieldKey, params) => {
    return async (dispatch, getState) => {
        const fields = getState().formsOnePlatform[reportKey][formMode].fields;
        try {
            const field = fields[fieldKey];
            dispatch(formsOnePlatformPatchField({ fetchingOptions: LOADING, options: [] }, reportKey,formMode, fieldKey));
            const configuration = field.configurationField || {};
            const { url, method, service, optionsPath, optionNamePath, optionValuePath } = configuration;
            let urlField = url;
            let newField = {};
            if (urlField && method) {
                let valuesData = await getData(service, urlField, params, method, dispatch);
                const options = getDataOfPath(valuesData.data, optionsPath);
                newField.options = Object.values(options).map((option) => ({
                    name: '' + getValueOfPath(option, optionNamePath || optionValuePath),
                    //name: '' + getValueOfPath(option, optionNamePath ),
                    value: '' + getValueOfPath(option, optionValuePath),
                })).filter( (field) => field.name !== '' && field.value !== '');

                newField.fetchingOptions = SUCCESS;
                //Added Field to remove duplicate values - should not affect the original Field
                newField.options = newField.options.filter((v, i, a) => a.findIndex(t => (t.value === v.value && t.name === v.name)) === i);
                dispatch(formsOnePlatformPatchField(newField,  reportKey,formMode, fieldKey));
            } else if (field.options) {
                newField.fetchingOptions = SUCCESS;
                if (field.type === Field_TYPE.INEQUALITY) {
                    newField.options = field.options;
                } else {
                    if (field.options[0].value && field.options[0].name) { // options: [{ name: string, value: string }]
                        newField.options = field.options.map(option => ({ name: option.name, value: option.value }));
                    } else {
                        newField.options = field.options.map(option => ({ name: option, value: option }));
                    }
                }
                dispatch(formsOnePlatformPatchField(newField,  reportKey,formMode, fieldKey));
            } else {
                newField.fetchingOptions = ERROR;
                dispatch(formsOnePlatformPatchField(newField,  reportKey,formMode, fieldKey));
            }
        } catch (e) {
            if (fields && fields[reportKey] && fields[reportKey][formMode] && fields[reportKey][formMode].fields
                && fields[reportKey][formMode].fields[fieldKey]
            ) {
                dispatch(formsOnePlatformPatchField({ ...fields[reportKey][formMode].fields[fieldKey], fetchingOptions: ERROR }, reportKey,formMode, fieldKey));
            }
        }
    };
};

export const maxZonesProcessForm = (reportKey) => {
    return async (dispatch) => {
        try {
            const service = "DirectionService";
            const urlField = "directions/max-zones-to-process";
            const params = {};
            const method = "GET";
            let valuesData = await getData(service, urlField, params, method, dispatch);
            const value = getDataOfPath(valuesData.data, "$");
            return value;
        } catch (e) {
            console.log({e})
            return 0;
        }
    }
}
