import { applyMiddleware, compose, createStore } from 'redux';
import { syncTranslationWithStore } from 'react-redux-i18n';
import thunk from 'redux-thunk';
import { throttle } from 'lodash';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import { WINDOW_RESIZE } from './constants/ActionTypes';
import reducer from './reducers';
import { renewServices } from './actions/util';
import { initLocaleWithFallback } from './actions';

export const history = createBrowserHistory();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducer(history), composeEnhancers(applyMiddleware(thunk, routerMiddleware(history))));

// The rest of this page is used for subscribing functions. (Usually for configuration
// or peristing information using cookies, localStorage, etc.)
//
// It may be advisable to put these in a separate 'subscribers' folder.

/*
 ██████  ██████  ██      ██    ██ ███    ███ ███    ██      ██████  ██████  ██████  ███████ ██████
██      ██    ██ ██      ██    ██ ████  ████ ████   ██     ██    ██ ██   ██ ██   ██ ██      ██   ██
██      ██    ██ ██      ██    ██ ██ ████ ██ ██ ██  ██     ██    ██ ██████  ██   ██ █████   ██████
██      ██    ██ ██      ██    ██ ██  ██  ██ ██  ██ ██     ██    ██ ██   ██ ██   ██ ██      ██   ██
 ██████  ██████  ███████  ██████  ██      ██ ██   ████      ██████  ██   ██ ██████  ███████ ██   ██

██████  ███████ ██████  ███████ ██ ███████ ████████
██   ██ ██      ██   ██ ██      ██ ██         ██
██████  █████   ██████  ███████ ██ ███████    ██
██      ██      ██   ██      ██ ██      ██    ██
██      ███████ ██   ██ ███████ ██ ███████    ██
*/

function persistToLocalStorage(key, data) {
    try {
        window.localStorage.setItem(key, JSON.stringify(data));
    } catch (error) {
        console.error({ message: 'can\'t persist', error, data });
    }
}

let oldState;
store.subscribe(() => {
    const state = store.getState();
    
    // (init)
    if (!oldState) {
        oldState = state;
        return;
    }
    
    const username = state.user.username;
    if (oldState?.user?.userGroups !== state?.user?.userGroups) {
        persistToLocalStorage(`mwe-persisted-state/user/${username}`, {userGroups:state?.user?.userGroups||[]});
    }

    if (oldState.report.columnOrder !== state.report.columnOrder) {
        persistToLocalStorage(`mwe-persisted-state/report/column-order/${username}`, state.report.columnOrder);
    }
    
    if (oldState.report.sidebarColumnOrder !== state.report.sidebarColumnOrder) {
        persistToLocalStorage(`mwe-persisted-state/report/sidebar-column-order/${username}`, state.report.sidebarColumnOrder);
    }
    
    if (oldState.filters !== state.filters) {
        persistToLocalStorage(`mwe-persisted-state/filters/${username}`, state.filters);
    }
    
    if (oldState.deviceManager.selectedFilters !== state.deviceManager.selectedFilters) {
        persistToLocalStorage(`mwe-persisted-state/deviceManager/selectedFilters/${username}`, state.deviceManager.selectedFilters);
    }
    if (oldState.deviceManager.columnOrder !== state.deviceManager.columnOrder) {
        persistToLocalStorage(`mwe-persisted-state/deviceManager/columnOrder/${username}`, state.deviceManager.columnOrder);
    }
    if (oldState.reportMap.dateRange !== state.reportMap.dateRange) {
        persistToLocalStorage(`mwe-persisted-state/playback/date-range/${username}`, state.reportMap.dateRange);
    }
    
    if (oldState.reportMap.currentLocalMap !== state.reportMap.currentLocalMap) {
        persistToLocalStorage(`mwe-persisted-state/reportMap/current-local-map/${username}`, state.reportMap.currentLocalMap);
    }
    
    if (oldState.reportMap.scopes !== state.reportMap.scopes) {
        const toPersist = {};
        for (const key in state.reportMap.scopes) {
            toPersist[key] = {
                ...state.reportMap.scopes[key],
                playback: {},
                dialog: {},
            };
        }
        persistToLocalStorage(`mwe-persisted-state/reportMap/scopes/${username}`, toPersist);
    }
    
    if (oldState.i18n.locale !== state.i18n.locale) {
        persistToLocalStorage(`mwe-persisted-state/i18n/locale/${username}`, state.i18n.locale);
    }
    
    if (oldState.filtersOnePlatform !== state.filtersOnePlatform) {
        const persistFilter = {};
        for (const reportKey in state.filtersOnePlatform) {
            const filterReport = {};
            for (const filterId in state.filtersOnePlatform[reportKey]) {
                let filter = { ...state.filtersOnePlatform[reportKey][filterId] };
                if (filter.url !== '') {
                    if (filter.hasOwnProperty('options')) {
                        // delete filter.options;
                    }
                }
                /*if(filter.hasOwnProperty('children')){
                    filter.children = {};
                }*/
                filterReport[filterId] = { ...filter };
            }
            persistFilter[reportKey] = { ...filterReport };
        }
        // delete persistFilter.___filterTemplates;
        persistToLocalStorage(`mwe-persisted-state/filtersOnePlatform/${username}`, persistFilter);
    }
    
    if (oldState.tableOnePlatform !== state.tableOnePlatform) {
        const tableOnePlatform = {};
        for (const reportKey in state.tableOnePlatform) {
            tableOnePlatform[reportKey] = {
                columnsConfig: state.tableOnePlatform[reportKey]?.columnsConfig,
                headerColumns: state.tableOnePlatform[reportKey]?.headerColumns,
                sort: state.tableOnePlatform[reportKey]?.sort,
            };
        }
        persistToLocalStorage(`mwe-persisted-state/tableOnePlatform/${username}`, tableOnePlatform);
    }
    // To persist the reports views:
    if (oldState.report.scopes !== state.report.scopes) {
        persistToLocalStorage(`mwe-persisted-state/report/scopes/${username}`, state.report.scopes || {});
    }
    
    oldState = state;
});
// function loadJSONFromStorage(key){
//     var val = window.localStorage.getItem(key);
//     try{
//         val = JSON.parse(val);
//         return val;
//     }catch(e){
//         return null;
//     }
// }

/*
 █████  ███████ ██    ██ ███    ██  ██████
██   ██ ██       ██  ██  ████   ██ ██
███████ ███████   ████   ██ ██  ██ ██
██   ██      ██    ██    ██  ██ ██ ██
██   ██ ███████    ██    ██   ████  ██████


 ██████  ██████  ███    ██ ███████ ██  ██████
██      ██    ██ ████   ██ ██      ██ ██
██      ██    ██ ██ ██  ██ █████   ██ ██   ███
██      ██    ██ ██  ██ ██ ██      ██ ██    ██
 ██████  ██████  ██   ████ ██      ██  ██████
*/

// Initial State
let USERNAME = window.localStorage.getItem('username');
let JWT_TOKEN = window.localStorage.getItem('jwtToken');

renewServices({ USERNAME, JWT_TOKEN });

store.subscribe(() => {
    var state = store.getState();
    if (state.user.username !== USERNAME || state.user.jwtToken !== JWT_TOKEN) {
        // cache the values
        USERNAME = state.user.username;
        JWT_TOKEN = state.user.jwtToken;
        
        // update the instances.
        renewServices({ USERNAME, JWT_TOKEN });
    }
});

function notifyResize() {
    store.dispatch({ type: WINDOW_RESIZE, width: window.innerWidth, height: window.innerHeight });
}

const RESIZE_THROTTLE_MILLISECONDS = 200;
window.addEventListener('resize', throttle(notifyResize, RESIZE_THROTTLE_MILLISECONDS));

// i18n configs
syncTranslationWithStore(store);
store.dispatch(initLocaleWithFallback());

export default store;
