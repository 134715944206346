import React from 'react';
import MobileMenuItemContainer from './MobileMenuItemContainer';
// import IconButton from 'material-ui/IconButton';
import {IconButton} from '@material-ui/core/es/index';
import ZebraIcon from '../../../icons/ZebraIcon';
import CustomIconButton from "../../button/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
// import FlatButton from 'material-ui/FlatButton';
import FlatButton from '@material-ui/core/Button';

class MobileMenuContainer extends React.Component {

    static get propTypes(){
        return{
            menuList: PropTypes.array,
            openMenu: PropTypes.bool,
            toggle: PropTypes.func,
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            mainMenuSelected: null,
            subMenuActive: null,
        };

        this.clickMainMenu = this.clickMainMenu.bind(this);
        this.clickSubMenu = this.clickSubMenu.bind(this);
    }

    /**
     * Set the SubMenu selected as active and check for updates(new deployments)
     * @param subMenuId
     */
    clickSubMenu(subMenuId){
        this.setState({
            subMenuActive: subMenuId,
        }, () => {
            this.props.onClickSubMenu();
        });
    }

    /**
     * Set the state for open the subMenu
     * @param mainMenuKey
     */
    clickMainMenu(mainMenuKey) {
        this.setState({mainMenuSelected: mainMenuKey});
    }

    /**
     * Generate the elements for menu list on mobile
     * @param list
     * @returns {*}
     */
    createMobileMenu(list) {
        return list.map(function (menuList) {

            // const pathArray = window.location.hash;
            // console.log("PathArray: ", pathArray);

            let selectedMainMenu = false;
            if(this.state.mainMenuSelected !== null) {
                if (menuList.routes === this.state.mainMenuSelected) {
                    selectedMainMenu = true;
                }
            } else {
                for(let i = 0; i < menuList.children.length; i++){
                    const currentPath = window.location.hash;
                    const routeaux = "#" + menuList.children[i].routes;
                    if(currentPath === routeaux){
                        selectedMainMenu = true;
                    }
                }
            }
            return (<MobileMenuItemContainer
                key={menuList.routes}
                menuList={menuList}
                toggle={this.props.toggle}
                clickMainMenu={this.clickMainMenu}
                active={selectedMainMenu}
                index={menuList.routes}
                subMenuActive={this.state.subMenuActive}
                clickSubMenu={this.clickSubMenu}
            />);
        }.bind(this));
    }

    /**
     * Method for generate the sub menu items for the Admin menu option
     * @param zUserMenu
     * @returns {*}
     */
    createUserSubMenuItem (zUserMenu){
        return zUserMenu.map( menuItem => {
            return (<Link to={"/"} key={menuItem.value} onClick={e=> e.preventDefault()} replace>
                        <FlatButton
                        className={"button-submenu"}
                        ref={"button"}
                        label={menuItem.label}
                        style={{textTransform:"capitalize",justifyContent: 'initial'}}
                        onClick={menuItem.onClick}
                        variant="text"
                        >{menuItem.label}</FlatButton>
                    </Link>
            );
        });
    }

    // Main render.
    render() {
        const {menuList, userName} = this.props;
        const Icon = MenuIcon;

        let classButtonLink = "button-link";
        if(this.state.mainMenuSelected === userName){
            classButtonLink += " active";
        }
        return (
            <div>
                <div className={"menuNavNarrow"}>
                    <div className={"header-left-sm"}>
                        <Link to={"/landing"} replace>
                            <IconButton className={"icon-menu"} style={{backgroundColor: 'transparent'}}>
                                <ZebraIcon/>
                            </IconButton>
                        </Link>
                    </div>
                    <div className={"header-mid-sm"}>
                        <span>Zebra Enterprise Motionworks</span>
                    </div>
                    <div className={"header-right-sm"} >
                        <CustomIconButton className={"menu-button"} icon={<Icon style={{color:"white"}}/>} onClick={this.props.toggle}
                                          description={null}/>
                    </div>
                </div>
                {this.props.openMenu ?
                    <div className={'menuNavNarrowLinks'}>
                        {this.createMobileMenu(menuList)}

                        <div className={"link-container"} >
                            <Link to={"/"} onClick={e=> e.preventDefault()} key={userName}>
                                <FlatButton
                                    id={"user-menu-button"}
                                    className={classButtonLink}
                                    ref={"button"}
                                    label={userName}
                                    style={{textTransform:"capitalize",justifyContent: 'initial'}}
                                    onClick={this.clickMainMenu.bind(this, userName)}
                                    variant="text"
                                >{userName}</FlatButton>
                            </Link>
                            { this.state.mainMenuSelected === userName ?
                                <div className={'child-links'}>
                                    {this.createUserSubMenuItem(this.props.userListConfig)}
                                </div> : null
                            }
                        </div>
                    </div> : null
                }
            </div>
        );
    }
}

export default MobileMenuContainer;