// MENU
export const REQUEST_MENU = "request_menu";
export const LOAD_MENU = "load_menu";
export const DISPLAY_SNACKBAR_MESSAGE = "display_snackbar_message";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
export const CHANGE_VIEW = "CHANGE_VIEW";

export const WINDOW_RESIZE = "WINDOW_RESIZE";

export const DISPLAY_ERROR_DIALOG = "DISPLAY_ERROR_DIALOG";
export const CLOSE_ERROR_DIALOG = "CLOSE_ERROR_DIALOG";
export const ERROR_DIALOG="ERROR_DIALOG";

// DATA ENTRY FORMS
export const OPEN_DATAENTRY_FORM = "OPEN_DATAENTRY_FORM";
export const CLOSE_DATAENTRY_FORM = "CLOSE_DATAENTRY_FORM";

// COLUMNS
export const LOAD_COLUMN_ORDER = "LOAD_COLUMN_ORDER";
export const LOAD_FILTERS = "LOAD_FILTERS";
export const REPORT_MOVE_COLUMN = "REPORT_MOVE_COLUMN";
export const REPORT_TOGGLE_COLUMN = "REPORT_TOGGLE_COLUMN";
export const REPORT_TOGGLE_ALL_COLUMNS = "REPORT_TOGGLE_ALL_COLUMNS";
export const UPDATE_COLUMN_WIDTH = "UPDATE_COLUMN_WIDTH";
export const UPDATE_TEMPLATE_COLUMN_WIDTH = "UPDATE_TEMPLATE_COLUMN_WIDTH";
export const UPDATE_COLUMN_WIDTH_AUTO_SIZE = "UPDATE_COLUMN_WIDTH_AUTO_SIZE";

// FILTERS
export const LOAD_SIDEBAR_COLUMN_ORDER = "LOAD_SIDEBAR_COLUMN_ORDER";
export const REPORT_MOVE_FILTER = "REPORT_MOVE_FILTER";
export const REPORT_TOGGLE_FILTER = "REPORT_TOGGLE_FILTER";
export const REPORT_TOGGLE_ALL_FILTERS = "REPORT_TOGGLE_ALL_FILTERS";

// REPORT TABLE
export const REPORT_HOVER_OVER_ROW = "REPORT_HOVER_OVER_ROW";
export const REPORT_CLICK_ON_ROW = "REPORT_CLICK_ON_ROW";
export const REPORT_SELECT_ALL = "REPORT_SELECT_ALL";
export const REPORT_SELECT_MANY = "REPORT_SELECT_MANY";

// REPORT MAP
export const MAP_HOVER_FEATURE = "MAP_HOVER_FEATURE";
export const MAP_SELECT_FEATURE = "MAP_SELECT_FEATURE";
export const SELECT_MAP = "SELECT_MAP";
export const UPDATE_MAP_SIZE = "UPDATE_MAP_SIZE";

// IMPORT
export const LOAD_IMPORT_HISTORY = "LOAD_IMPORT_HISTORY";
export const CHANGE_NORMAL_FILTER_VALUE = "CHANGE_NORMAL_FILTER_VALUE";
export const CHANGE_IMPORTED_DATE_FILTER_VALUE = "CHANGE_IMPORTED_DATE_FILTER_VALUE";
export const RESET_IMPORT_FILTERS = "RESET_IMPORT_FILTERS";
export const UPDATE_NON_DEFAULT_FILTER_COUNTER = "UPDATE_NON_DEFAULT_FILTER_COUNTER";
export const TOGGLE_IMPORT_FILTER = "TOGGLE_IMPORT_FILTER";
export const TOGGLE_ALL_IMPORT_FILTERS = "TOGGLE_ALL_IMPORT_FILTERS";
export const MOVE_IMPORT_FILTER_ITEM = "MOVE_IMPORT_FILTER_ITEM";
export const UPDATE_IMPORT_FILTERS_OPTIONS = "UPDATE_IMPORT_FILTERS_OPTIONS";
export const INITIAL_IMPORT_FILTERS = "INITIAL_IMPORT_FILTERS";
export const LOAD_TOKEN_REPLACEMENTS = "LOAD_TOKEN_REPLACEMENTS";

// USER MANAGEMENT -- TABS
export const USERS_CLICK_TAB = "USERS_CLICK_TAB";
export const SORT_USER_TABLE_COLUMNS = "SORT_USER_TABLE_COLUMNS";

// SITE BUILDER
export const TOGGLE_SITE_SELECTION = "TOGGLE_SITE_SELECTION";
export const TOGGLE_SITEGROUP_SELECTION = "TOGGLE_SITEGROUP_SELECTION";
export const TOGGLE_ALLSITES = "TOGGLE_ALLSITES";
export const MOVE_SITE_TO_SITEGROUP = "MOVE_SITE_TO_SITEGROUP";
export const OPEN_SITE_FORM = "OPEN_SITE_FORM";
export const CLOSE_SITE_FORM = "CLOSE_SITE_FORM";

export const ADD_SITE = "ADD_SITE";
export const ADD_SITE_GROUP = "ADD_SITE_GROUP";
export const UPDATE_SITE = "UPDATE_SITE";
export const UPDATE_SITE_GROUP = "UPDATE_SITE_GROUP";
export const DELETE_SITE = "DELETE_SITE";
export const DELETE_SITE_GROUP = "DELETE_SITE_GROUP";

export const SELECT_FLOOR_MAP = "SELECT_FLOOR_MAP";
export const TOGGLE_MY_SITES = "TOGGLE_MY_SITES";

// REPORTS -- DATA
export const REQUEST_REPORT = "REQUEST_REPORT";
export const REQUEST_REPORT_CONTENT = "REQUEST_REPORT_CONTENT";
export const STOP_FETCHING_DATA = "STOP_FETCHING_DATA";
export const LOAD_REPORT = "LOAD_REPORT";
export const LOAD_REPORT_CONTENT = "LOAD_REPORT_CONTENT";
export const CHANGE_DETAILS_ITEM="CHANGE_DETAILS_ITEM";
export const SITE_SELECTED="SITE_SELECTED";

// REPORT FILTERS
export const REQUEST_FILTER_VALUES = "REQUEST_FILTER_VALUES";
export const LOAD_FILTER_VALUES = "LOAD_FILTER_VALUES";
export const CHANGE_FILTER_VALUE = "CHANGE_FILTER_VALUE";
export const CHANGE_SPECIAL_FILTER_VALUE = "CHANGE_SPECIAL_FILTER_VALUE";
export const CHANGE_BATCH_FILTER_VALUE = "CHANGE_BATCH_FILTER_VALUE";
export const CLEAN_FILTERS = "CLEAN_FILTERS";
export const CHANGE_FILTER_STATE = "CHANGE_FILTER_STATE";
export const UPDATE_ONE_TIME_DEFAULT_FILTER_VALUE = "UPDATE_ONE_TIME_DEFAULT_FILTER_VALUE";

export const REPORT_CHANGE_SORT = "REPORT_CHANGE_SORT";
export const TOGGLE_RIGHT_SIDEBAR = "TOGGLE_RIGHT_SIDEBAR";
export const OPEN_RIGHT_SIDEBAR = "OPEN_RIGHT_SIDEBAR";
export const HIGHLIGHT_ITEM_MAP = "HIGHLIGHT_ITEM_MAP";

// APPLIANCES
export const REGISTER_APPLIANCE = "REGISTER_APPLIANCE";
export const REQUEST_APPLIANCES = "REQUEST_APPLIANCES";
export const LOAD_APPLIANCES = "LOAD_APPLIANCES";
export const TOGGLE_SELECT_APPLIANCE = "TOGGLE_SELECT_APPLIANCE";
export const TOGGLE_SELECT_ALL_APPLIANCES = "TOGGLE_SELECT_ALL_APPLIANCES";
export const UPDATE_SERVER_LOGS_DOWNLOAD_STATUS = "UPDATE_SERVER_LOGS_DOWNLOAD_STATUS";


// sites
export const REQUEST_SITES = "REQUEST_SITES";
export const LOAD_SITES = "LOAD_SITES";
export const REQUEST_SITE_GROUPS = "REQUEST_SITE_GROUPS";
export const LOAD_SITE_GROUPS = "LOAD_SITE_GROUPS";
export const UPLOAD_MAP_STATUS = "UPLOAD_MAP_STATUS";

// tasks
export const REQUEST_TASKS = "REQUEST_TASKS";
export const LOAD_TASKS = "LOAD_TASKS";
export const LOAD_SITE_CONFIGURATION="LOAD_SITE_CONFIGURATION";

// packages
export const REQUEST_PACKAGES = "REQUEST_PACKAGES";
export const LOAD_PACKAGES = "LOAD_PACKAGES";
export const DOWNLOAD_PACKAGE = "DOWNLOAD_PACKAGE";
export const DELETE_PACKAGE = "DELETE_PACKAGE";

// firmware
export const LOAD_FIRMWWARE_VERSIONS = "LOAD_FIRMWWARE_VERSIONS";

// maps
export const LOAD_MAPS = "LOAD_MAPS";
export const REQUEST_MAPS = "REQUEST_MAPS";
export const LOAD_MAP_CONFIG = "LOAD_MAP_CONFIG";
export const CHANGE_LOCAL_MAP = "CHANGE_LOCAL_MAP";
export const LOAD_PLAYBACK_HISTORY = "LOAD_PLAYBACK_HISTORY";
export const LOAD_PLAYBACK_VIEW = "LOAD_PLAYBACK_VIEW";
export const SAVE_CURRENT_VIEW = "SAVE_CURRENT_VIEW";
export const SET_DEFAULT_PLAYBACK_MAP_ID = "SET_DEFAULT_PLAYBACK_MAP_ID";
export const SET_CURRENT_PLAYBACK_SITE_NAME = "SET_CURRENT_PLAYBACK_SITE_NAME";
export const REQUEST_PLAYBACK = "REQUEST_PLAYBACK";
export const UPDATE_CLUSTER_INDEX = "UPDATE_CLUSTER_INDEX";
export const SET_PLAYBACK_DATE = "SET_PLAYBACK_DATE";
export const LOAD_PLAYBACK_DATE = "LOAD_PLAYBACK_DATE";
export const CLEAN_PLAYBACK_DATE = "CLEAN_PLAYBACK_DATE";

// dynamic data entry
export const LOAD_FORM_FIELDS = "LOAD_FORM_FIELDS";
export const DELETE_MULTIPLE_RECORDS = "DELETE_MULTIPLE_RECORDS";
// users + user groups
export const LOAD_USER_GROUPS = "LOAD_USER_GROUPS";
export const LOAD_USERS = "LOAD_USERS";

// resource types
export const LOAD_RESOURCE_TYPES = "LOAD_RESOURCE_TYPES";

export const LOAD_SITES_USERMGMT = "LOAD_SITES_USERMGMT";

export const REQUEST_SAVED_REPORT = "REQUEST_SAVED_REPORT";
export const LOAD_SAVED_REPORT = "LOAD_SAVED_REPORT";

export const RELOAD_REPORT = "RELOAD_REPORT";

export const SAVE_USERNAME = "SAVE_USERNAME";
export const SAVE_JWT_TOKEN = "SAVE_JWT_TOKEN";
export const SET_ACCOUNT_TYPE = "SET_ACCOUNT_TYPE";

export const SET_REFRESH_RATE = "SET_REFRESH_RATE";

export const UPDATE_WEBSOCKET = "UPDATE_WEBSOCKET";

export const WEBSOCKET_STATUS = "WEBSOCKET_STATUS";

export const DISCONNECT_WS = "DISCONNECT_WS";

export const CONNECT_WS = "CONNECT_WS";

export const SIGN_OUT = "SIGN_OUT";

export const WEBSOCKET_STOP_BLINK = "WEBSOCKET_STOP_BLINK";

// users settings

export const ZOOM_FEATURE = "ZOOM_FEATURE";
export const MAX_RESULTS_BY_PAGE = "MAX_RESULTS_BY_PAGE";

export const START_LOADING = "START_LOADING";

// Printer Label

export const LOAD_PRINTER_DEVICES = "LOAD_PRINTER_DEVICES";

export const SET_LOGIN_USER = "SET_LOGIN_USER";

export const SET_AUTH_SETTINGS = "SET_AUTH_SETTINGS";

export const SET_DISABLE_AUTO_REDIRECT = "SET_DISABLE_AUTO_REDIRECT";

// Resource Type Printing

export const RESOURCE_TYPE_PRINT_RFID = "RESOURCE_TYPE_PRINT_RFID";
export const RESOURCE_ID_PRINT_SEQUENCE = "RESOURCE_ID_PRINT_SEQUENCE";
export const TAG_ID_BASE = "TAG_ID_BASE";
export const RANGE_CODE = "RANGE_CODE";




// Zone builder
export const SELECT_ZONE = "SELECT_ZONE";

export const ADD_ZONE = "ADD_ZONE";
export const UPDATE_ZONE = "UPDATE_ZONE";
export const DELETE_ZONE = "DELETE_ZONE";

export const LOAD_ZONES = "LOAD_ZONES";

export const CHANGE_ZONE_PROPERTY = "CHANGE_ZONE_PROPERTY";

export const COPY_ZONE = "COPY_ZONE";
export const PASTE_ZONE = "PASTE_ZONE";

export const SAVE_ZONES = "SAVE_ZONES";
export const PUBLISH_ZONES = "PUBLISH_ZONES";

export const CLEAN_LOCAL_MAP = "CLEAN_LOCAL_MAP";
export const LOAD_LOCAL_MAP = "LOAD_LOCAL_MAP";
export const MODIFY_PLAYBACK_ITEMS = "MODIFY_PLAYBACK_ITEMS";

//set session
export const SET_SESSION = "SET_SESSION";

//Thumbnails Map Drawer
export const TOOGLE_MAP_DRAWER = "TOOGLE_MAP_DRAWER"

// Template form error
export const SET_TEMPLATE_ERROR = "SET_TEMPLATE_ERROR";

//Set User Groups
export const SET_USER_GROUPS="SET_USER_GROUPS";

//Device Manager
export const CHANGE_STEP = "CHANGE_STEP";
export const CHANGE_STEP_BATCH = "CHANGE_STEP_BATCH";
export const APPLY_TEMPLATE = "APPLY_TEMPLATE";
export const SELECT_TEMPLATE = "SELECT_TEMPLATE";
export const SET_FETCHING = "SET_FETCHING";
export const SET_FETCHING_DEVICE = "SET_FETCHING_DEVICE";
export const LOAD_TEMPLATE_LIST = "LOAD_TEMPLATE_LIST";
export const LOAD_FORM_TEMPLATE = "LOAD_FORM_TEMPLATE";
export const UPDATE_VALIDATED_FIELDS = "UPDATE_VALIDATED_FIELDS";
export const CLEAN_FORM_TEMPLATE = "CLEAN_FORM_TEMPLATE";
export const UPDATE_FORM_TEMPLATE = "UPDATE_FORM_TEMPLATE";
export const UPDATE_FORM_TEMPLATE_MAIN = "UPDATE_FORM_TEMPLATE_MAIN";
export const UPDATE_FORM_TEMPLATE_ANTENNA = "UPDATE_FORM_TEMPLATE_ANTENNA";
export const ADD_FORM_TEMPLATE_ANTENNA = "ADD_FORM_TEMPLATE_ANTENNA";
export const DELETE_FORM_TEMPLATE_ANTENNA = "DELETE_FORM_TEMPLATE_ANTENNA";
export const ADD_FORM_TEMPLATE_ANTENNA_BY_PORT = "ADD_FORM_TEMPLATE_ANTENNA_BY_PORT";


export const UPDATE_LIST_TYPE_DEVICES = "UPDATE_LIST_TYPE_DEVICES";
export const CREATE_DEVICES_BATCH = "CREATE_DEVICES_BATCH";
export const CLEAN_IMPORTED_FILES ="CLEAN_IMPORTED_FILES";
export const UPDATE_BATCH_IMPORT_FILES ="UPDATE_BATCH_IMPORT_FILES";
export const DELETE_BATCH_IMPORT_FILE ="DELETE_BATCH_IMPORT_FILE";

export const LOAD_FIRMWARE_VERSIONS_DEVICE = "LOAD_FIRMWARE_VERSIONS_DEVICE";
export const LOAD_MESSAGES_DEVICES_BATCH = "LOAD_MESSAGES_DEVICES_BATCH";
export const CLEAN_MESSAGES_DEVICES_BATCH = "CLEAN_MESSAGES_DEVICES_BATCH";
export const SET_FIRMWARE_ERROR = "SET_FIRMWARE_ERROR";
export const IS_LOADING_FIRMWARE_VERSIONS_DEVICE = "IS_LOADING_FIRMWARE_VERSIONS_DEVICE";
export const CONFIGURATION_VERSION_HISTORY = "CONFIGURATION_VERSION_HISTORY";
//Device management  - device operations
export const UPDATE_DEVICE_OPERATIONS = "UPDATE_DEVICE_OPERATIONS";
export const UPDATE_DEVICE_OPERATIONS_IN_PROGRESS = "UPDATE_DEVICE_OPERATIONS_IN_PROGRESS";
export const CHECK_DEVICE_OPERATION = "CHECK_DEVICE_OPERATION";

export const EDIT_PENDING_OPERATIONS = "EDIT_PENDING_OPERATIONS";

export const CLEAN_DEVICES_BATCH = "CLEAN_DEVICES_BATCH";

export const INIT_ADD_DEVICES = "INIT_ADD_DEVICES";
export const EDIT_DEVICE = "EDIT_DEVICE";
export const UPDATE_DEVICE_ONLY = "UPDATE_DEVICE_ONLY";
export const UPDATE_DEVICE_AND_TEMPLATE = "UPDATE_DEVICE_AND_TEMPLATE";
export const UPDATE_LIST_DEVICES = "UPDATE_LIST_DEVICES";
export const LOAD_DEVICE_LIST = "LOAD_DEVICE_LIST";
export const LOAD_SITE_ZONES = "LOAD_SITE_ZONES";
export const LOAD_FORM_DEVICE = "LOAD_FORM_DEVICE";
export const LOAD_LINKED_DEVICES = "LOAD_LINKED_DEVICES";
export const CLEAN_LINKED_DEVICES = "CLEAN_LINKED_DEVICES";
export const UPDATE_LINKED_SELECTED_ROWS = "UPDATE_LINKED_SELECTED_ROWS";
export const UPDATE_SITE_ZONES = "UPDATE_SITE_ZONES";
export const LOAD_USER_DEFINED_FILE  = "LOAD_USER_DEFINED_FILE";
export const UNLOAD_USER_DEFINED_FILE  = "UNLOAD_USER_DEFINED_FILE";

//Firmware management
export const FIRMWARE_MGMT_UPLOAD_FILE = "FIRMWARE_MGMT_UPLOAD_FILE";
export const FIRMWARE_MGMT_CLEAN_STATUS = "FIRMWARE_MGMT_CLEAN_STATUS";
//Dialog on background process
export const DISPLAY_ERROR_DIALOG_ON_BACKGROUND = 'DISPLAY_ERROR_DIALOG_ON_BACKGROUND';
export const CLOSE_ERROR_DIALOG_ON_BACKGROUND = 'CLOSE_ERROR_DIALOG_ON_BACKGROUND';

// Zebra Data Service
export const LOAD_DEVICES_VIA_ZEBRA_DATA_SERVICE="LOAD_DEVICES_VIA_ZEBRA_DATA_SERVICE";
export const CLOUD_IMPORT_UPDATE_SELECTED_DEVICES = "CLOUD_IMPORT_UPDATE_SELECTED_DEVICES";
export const SET_CLOUD_DEVICES_DATA = "SET_CLOUD_DEVICES_DATA";
export const INIT_STATUS_CLOUD_DEVICES = "INIT_STATUS_CLOUD_DEVICES";
// Maps search

export const FILTER_RESULTS = "FILTER_RESULTS";
export const LIST_SITES = "LIST_SITES";
export const LIST_SITES_GROUPS = "LIST_SITES_GROUPS";
export const RECENT_MAPS = "RECENT_MAPS";
export const LOAD_SUGGESTIONS = "LOAD_SUGGESTIONS";
export const MAP_SELECTED = "MAP_SELECTED";
export const UPDATE_MAP_SELECTED = "UPDATE_MAP_SELECTED";
export const CHANGE_CURRENT_MAP = "CHANGE_CURRENT_MAP";
export const SET_SELECTED_GROUP = "SET_SELECTED_GROUP";
export const LOAD_DEVICES_SITES = "LOAD_DEVICES_SITES";
export const LOAD_MAP_ZONES = "LOAD_MAP_ZONES";
export const UPDATE_MAP_ZONES = "UPDATE_MAP_ZONES";

// Device Details
export const SHOW_DETAILS = "SHOW_DETAILS";
export const SHOW_MAPS = "SHOW_MAPS";
export const ACTION_DEVICE = "ACTION_DEVICE";
export const DEVICE_MANAGER_UPDATE_SELECTED = "DEVICE_MANAGER_UPDATE_SELECTED";
export const LOAD_DEVICE_DETAILS_DATA = "LOAD_DEVICE_DETAILS_DATA";
export const LOAD_TEMPLATE_DETAILS_DATA = "LOAD_TEMPLATE_DETAILS_DATA";
export const SET_FETCHING_DETAILS = "SET_FETCHING_DETAILS";
export const SHOW_MODAL_DETAILS = "SHOW_MODAL_DETAILS";
export const SHOW_MODAL_EDIT_DEVICE = "SHOW_MODAL_EDIT_DEVICE";
//Filters
export const UPDATE_SELECTED_FILTERS = "UPDATE_SELECTED_FILTERS";
export const LOAD_SELECTED_FILTERS = "LOAD_SELECTED_FILTERS";

export const UPDATE_APPLIED_FILTERS = "UPDATE_APPLIED_FILTERS";
// Add Devices
export const LOAD_SITE_MAP = "LOAD_SITE_MAP";
export const LOAD_SCREEN = "LOAD_SCREEN";
export const LOAD_EDITED_DEVICE = "LOAD_EDITED_DEVICE";
export const ADD_ANTENNA_BATCH = "ADD_ANTENNA_BATCH";
export const DELETE_ANTENNA_BATCH = "DELETE_ANTENNA_BATCH";
export const DETECT_SAVE_CHANGES = "DETECT_SAVE_CHANGES";
export const DETECT_SITE_CHANGES = "DETECT_SITE_CHANGES";
//MapView
export const CHANGE_MAP_VIEW = "CHANGE_MAP_VIEW";
//Device Manager Screen
export const LOAD_DEVICE_MANAGER_SCREEN = "LOAD_DEVICE_MANAGER_SCREEN";

//check browser
export const CHECK_BROWSER = "CHECK_BROWSER";

//columns Device Manager
export const DEVICE_MOVE_COLUMN = "DEVICE_MOVE_COLUMN";
export const DEVICE_TOGGLE_COLUMN = "DEVICE_TOGGLE_COLUMN";
export const DEVICE_TOGGLE_ALL_COLUMNS = "DEVICE_TOGGLE_ALL_COLUMNS";
export const LOAD_DEVICE_COLUMN_ORDER = "LOAD_DEVICE_COLUMN_ORDER";

//Open alert
export const OPEN_ALERT = "OPEN_ALERT";

//Sort Columns
export const SORT_COLUMN = "SORT_COLUMN";

//Notification
export const LOAD_DIALOG_NOTIFICATION = "LOAD_DIALOG_NOTIFICATION";
export const DISPLAY_NOTIFICATION = "DISPLAY_NOTIFICATION";
export const CLEAN_NOTIFICATION = "CLOSE_NOTIFICATION";
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";


// DASHBOARDS

export const LOAD_DATA_WIDGET = "LOAD_DATA_WIDGET";
export const LOAD_OPTIONS_WIDGET = "LOAD_OPTIONS_WIDGET";
export const SET_PARAMS_WIDGET = "SET_PARAMS_WIDGET";

//Devices Options
export const LOAD_DEVICE_OPTIONS = "LOAD_DEVICE_OPTIONS";
export const UNLOAD_DEVICE_OPTIONS = "UNLOAD_DEVICE_OPTIONS";
export const CHANGE_DEVICE_OPTIONS = "CHANGE_DEVICE_OPTIONS";
export const UPDATE_DEVICE_OPTIONS = "UPDATE_DEVICE_OPTIONS";

// Allow Update Templates
export const ALLOW_UPDATE_TEMPLATE = "ALLOW_UPDATE_TEMPLATE";

// Set if was added a new template or changed in device
export const SET_ADDED_TEMPLATE = "SET_ADDED_TEMPLATE";
// Check if it's a cloud DMS configuration
export const CONFIGURATION_CLOUD  = "CONFIGURATION_CLOUD";

// Load the snapshot information by site
export const LOAD_SITE_SNAPSHOT_INFORMATION = "LOAD_SITE_SNAPSHOT_INFORMATION";
export const GETTING_SITE_INFO = "GETTING_SITE_INFO";

export const SITE_MANAGER_SITE_SELECTED="SITE_MANAGER_SITE_SELECTED";
export const SITE_MANAGER_SITE_HOVER="SITE_MANAGER_SITE_HOVER";


// Change range of days in DateFilter

export const CHANGE_DATE_RANGE = "CHANGE_DATE_RANGE";

// Map Width and Height modal error
export const MAP_WIDTH_HEIGHT_ERROR = "MAP_WIDTH_HEIGHT_ERROR";
export const LOAD_ZONES_ANTENNAS = "LOAD_ZONES_ANTENNAS";

//Status History
export const LOAD_STATUS_HISTORY = "LOAD_STATUS_HISTORY";
export const LOAD_DEVICE_STATUS = "LOAD_DEVICE_STATUS";
export const CHANGE_HISTORY_DATE_FILTER = "CHANGE_HISTORY_DATE_FILTER";
export const LOAD_FETCH_HISTORY_STATUS = "LOAD_FETCH_HISTORY_STATUS";
export const LOAD_FETCH_DEVICE_STATUS = "LOAD_FETCH_DEVICE_STATUS";
export const DATA_TO_DOWNLOAD = "DATA_TO_DOWNLOAD";
export const CHANGE_LOG_HISTORY_DATE_FILTER = "CHANGE_LOG_HISTORY_DATE_FILTER";
export const LOAD_LOG_HISTORY_DATA = "LOAD_LOG_HISTORY_DATA";
export const SET_LOG_HISTORY_TYPES = "SET_LOG_HISTORY_TYPES";
export const SET_LOG_DOWNLOAD_TYPES = "SET_LOG_DOWNLOAD_TYPES";
export const SET_LOG_TO_DOWNLOAD = "SET_LOG_TO_DOWNLOAD";
export const SET_DEVICES_SELECTED = "SET_DEVICES_SELECTED";
export const SET_DOWNLOAD_ERROR = "SET_DOWNLOAD_ERROR";
export const SET_LOG_CONFIGURATION_ERROR = "SET_LOG_CONFIGURATION_ERROR";
export const SET_LOGS_CONFIGURATION_SUCCESSFULLY = "SET_LOGS_CONFIGURATION_SUCCESSFULLY";
export const LOGS_DELETED_STATUS = "LOGS_DELETED_STATUS";
export const SET_FETCHING_LOGS_SAVE = "SET_FETCHING_LOGS_SAVE";
export const SET_FETCHING_DOWNLOAD_LOGS = "SET_FETCHING_DOWNLOAD_LOGS";
export const SET_FETCHING_LOGS = "SET_FETCHING_LOGS";
export const CHANGE_LOG_LEVEL = "CHANGE_LOG_LEVEL";
export const SET_DOWNLOADED_HISTORY_LOGS = "SET_DOWNLOADED_HISTORY_LOGS";
export const CLEAR_DOWNLOADED_LOGS = "CLEAR_DOWNLOADED_LOGS";
export const SET_DEVICE_LOG_CONFIG = "SET_DEVICE_LOG_CONFIG";
export const SET_ERROR_DEVICES_LIST = "SET_ERROR_DEVICES_LIST";
export const SET_RECORDS_PER_PAGE = "SET_RECORDS_PER_PAGE";

// Details pooling operation
export const UPDATE_DETAILS_DATA_OPERATION = "UPDATE_DETAILS_DATA_OPERATION";

//Loading data devices list
export const LOADING_DEVICES_LIST = "LOADING_DEVICES_LIST"

//Pagination Device Manager

export const SET_PAGINATION_INFO="SET_PAGINATION_INFO";
export const SET_DEVICES_ID="SET_DEVICES_ID";

//Clean Device Manager storage
export const  CLEAN_DEVICE_MANAGER = "CLEAN_DEVICE_MANAGER";

//Resource Types
export const LOAD_CUSTOM_RESOURCE_TYPES_DATA="LOAD_CUSTOM_RESOURCE_TYPES_DATA";
export const LOAD_RESOURCE_TYPES_FILTERS="LOAD_RESOURCE_TYPES_FILTERS";
export const SET_RESOURCE_TYPES_FILTERS="SET_RESOURCE_TYPES_FILTERS";
export const UPDATE_RESOURCE_TYPES_FILTERS_FORM="UPDATE_RESOURCE_TYPES_FILTERS_FORM";
export const SET_DEFAULT_FILTER_VALUES="SET_DEFAULT_FILTER_VALUES";
export const UPDATE_RESOURCE_TYPES_FIELD_ENABLED="UPDATE_RESOURCE_TYPES_FIELD_ENABLED";

//FilterLoaderCon
export const LOAD_RESOURCES_DATA = "LOAD_RESOURCES_DATA";
export const UPDATE_STATIC_RESOURCE_TYPE="UPDATE_STATIC_RESOURCE_TYPE";
export const CLEAN_RESOURCE_TYPES_FORM = "CLEAN_RESOURCE_TYPES_FORM";
export const UPDATE_RESOURCE_TYPES_DATA_FORM = "UPDATE_RESOURCE_TYPES_DATA_FORM";
export const UPDATE_RESOURCE_TYPES_ALL_FIELDS = "UPDATE_RESOURCE_TYPES_ALL_FIELDS";
export const ADD_RESOURCE_TYPES_ATTRIBUTES_FORM = "ADD_RESOURCE_TYPES_ATTRIBUTES_FORM";
export const UPDATE_RESOURCE_TYPES_ATTRIBUTES_FORM = "UPDATE_RESOURCE_TYPES_ATTRIBUTES_FORM";
export const DELETE_RESOURCE_TYPES_ATTRIBUTES_FORM = "DELETE_RESOURCE_TYPES_ATTRIBUTES_FORM";
export const ADD_RESOURCE_TYPES_ATTRIBUTES_LOOK_UP_VALUE_FORM = "ADD_RESOURCE_TYPES_ATTRIBUTES_LOOK_UP_VALUE_FORM";
export const CLEAR_RESOURCE_TYPES_ATTRIBUTES = "CLEAR_RESOURCE_TYPES_ATTRIBUTES";
export const UPDATE_RESOURCE_TYPES_ATTRIBUTES_LOOK_UP_VALUE_OPTION_FORM = "UPDATE_RESOURCE_TYPES_ATTRIBUTES_LOOK_UP_VALUE_OPTION_FORM";
export const UPDATE_RESOURCE_TYPES_ATTRIBUTES_LOOK_UP_VALUE_FORM = "UPDATE_RESOURCE_TYPES_ATTRIBUTES_LOOK_UP_VALUE_FORM";
export const REMOVE_RESOURCE_TYPES_ATTRIBUTES_LOOK_UP_VALUE_FORM = "REMOVE_RESOURCE_TYPES_ATTRIBUTES_LOOK_UP_VALUE_FORM";
export const UPDATE_OPTION_LOOKUP = "UPDATE_OPTION_LOOKUP";
export const UPDATE_OPTION_ICON_UPLOAD = "UPDATE_OPTION_ICON_UPLOAD";
export const RESOURCE_TYPE_SELECTION="RESOURCE_TYPE_SELECTION";
export const RESOURCE_TYPE_FILTER_SELECTION="RESOURCE_TYPE_FILTER_SELECTION";
export const SET_SHOW_ATTRIBUTES="SET_SHOW_ATTRIBUTES";
export const LOAD_DEFAULT_RESOURCE_TYPE_FILTERS="LOAD_DEFAULT_RESOURCE_TYPE_FILTERS";

//Tags
export const TAG_VIEW_MODAL_OPTION = "TAG_VIEW_MODAL_OPTION";
export const UPDATE_TAG_FIELD_VALUE = "UPDATE_TAG_FIELD_VALUE";
export const LOAD_TAGS_DATA="LOAD_TAGS_DATA";
//Sites
export const LOAD_SITES_RESOURCES = "LOAD_SITES_RESOURCES";
export const CHANGE_RESOURCE_FILTER_VALUE = "CHANGE_RESOURCE_FILTER_VALUE";
export const UPDATE_VALIDATED_FIELDS_RESOURCE_TYPES = "UPDATE_VALIDATED_FIELDS_RESOURCE_TYPES";
//Resources
export const RESOURCE_SELECTION = "RESOURCE_SELECTION";
export const UPDATE_VALIDATED_FIELDS_RESOURCE = "UPDATE_VALIDATED_FIELDS_RESOURCE";
export const CLEAN_FIELDS_RESOURCE = "CLEAN_FIELDS_RESOURCE";

// Search screen
export const PROCESSING_SEARCH_QUERY = "PROCESSING_SEARCH_QUERY";
export const LOADING_AUTOCOMPLETION_FIELDS = "LOADING_AUTOCOMPLETION_FIELDS";
export const SET_SEARCH_QUERY_RESPONSE = "SET_SEARCH_QUERY_RESPONSE";
export const SET_AUTOCOMPLETION_FIELDS = "SET_AUTOCOMPLETION_FIELDS";
export const LOADING_SUGGESTIONS_FOR_VALUE = "LOADING_SUGGESTIONS_FOR_VALUE";
export const SET_SUGGESTIONS_FOR_VALUE = "SET_SUGGESTIONS_FOR_VALUE";
export const SAVE_INPUT_QUERY = "SAVE_INPUT_QUERY";
export const SORT_BY_COLUMN = "SORT_BY_COLUMN";

export * from './filtersOnePlatform';
export * from './reportMap';
export * from './sites';
export * from './tableOnePlatform';

export * from './alerts';
export * from './zone-builder';
//Switch published on Edit
export const UPDATE_PUBLISH_SWITCH = "UPDATE_PUBLISH_SWITCH"

export * from './direction';



// Site Designer
export const REPLACE_DEVICES = 'REPLACE_DEVICES';
export const LOAD_ANTENNAS_DATA = "LOAD_ANTENNAS_DATA";
export const LOAD_ANTENNAS_MODEL_DATA = "LOAD_ANTENNAS_MODEL_DATA";
export const OPEN_FORM_ADD_ANTENNAS = "OPEN_FORM_ADD_ANTENNAS";
export const OPEN_FORM_EDIT_DEVICE = "OPEN_FORM_EDIT_DEVICE";
export const UPDATE_ANTENNA_DIALOG_STATE = "UPDATE_ANTENNA_DIALOG_STATE";
export const SITE_DESIGNER_PATCH_PROPERTIES = "SITE_DESIGNER_PATCH_PROPERTIES";
export const UNLOAD_DEVICE_FORM = "UNLOAD_DEVICE_FORM";
export const UPDATE_SITE_DESIGNER_ERROR_FORM = "UPDATE_SITE_DESIGNER_ERROR_FORM";
export const UNLOAD_FORM_ERRORS = "UNLOAD_FORM_ERRORS";
export const UPDATE_FORM_SUCCESS = "UPDATE_FORM_SUCCESS";
export const LOAD_DATUM_DATA = "LOAD_DATUM_DATA";
export const REPLACE_DEVICE_FORM = "REPLACE_DEVICE_FORM";
export const UPDATE_CURRENT_SITE_OBJECT = "UPDATE_CURRENT_SITE_OBJECT";
export const UPDATE_CURRENT_SELECTED_MAP = "UPDATE_CURRENT_SELECTED_MAP";
export const UPDATE_PREVENT_DELETE = "UPDATE_PREVENT_DELETE";
export const REPLACE_ZONES = "REPLACE_ZONES";
export const UPDATE_STATUS_FEATURE = "UPDATE_STATUS_FEATURE";

export const OPEN_FORM_ADD_ZONE = "OPEN_FORM_ADD_ZONE";
export const OPEN_FORM_EDIT_ZONE = "OPEN_FORM_EDIT_ZONE";
export const UNLOAD_ZONE_FORM = "UNLOAD_ZONE_FORM";
export const UPDATE_ERROR_DIALOG_STATE = "UPDATE_ERROR_DIALOG_STATE";

export const LOAD_MAP_PHASES_TYPES = "LOAD_MAP_PHASES_TYPES";
export const LOAD_MAP_PHASES = "LOAD_MAP_PHASES";

export const LOAD_SITE_DESIGNER_DATA = "LOAD_SITE_DESIGNER_DATA";

// For configuration Map(Level)
export const UPDATE_LEVEL_SELECTED = "UPDATE_LEVEL_SELECTED";
export const UPDATE_SITE_SELECTED = "UPDATE_SITE_SELECTED";
export const UPDATE_MEASURE_UNIT = "UPDATE_MEASURE_UNIT";
export const UPDATE_CONFIRM_CHILDREN_ZONES = "UPDATE_CONFIRM_CHILDREN_ZONES";
