import { useCallback } from 'react';
import { getOlStoreKey } from '../ol/helpers';
import { useSiteManagerDispatch } from './siteManager';
import { ItemType, SITE_MANAGER_CONFIGURATION_ZONES_SCOPE, SITE_MANAGER_SCOPE } from '../constants';
import { getZonesTypes} from '../services';
import { useLoaderDispatch } from '../hooks';
import { displayErrorFromAxios } from '../vss/app/actions/util';
import {useDispatch, useSelector} from 'react-redux';
import { loadZoneTypesOnStore, refStore } from '../vss/app/actions';

export const useConfigurationZones = (siteId, mapId) => {
    const {
        takeSnapshot,
        replaceItems,
    } = useSiteManagerDispatch(SITE_MANAGER_CONFIGURATION_ZONES_SCOPE);
    const { addLoaderCounter, subLoaderCounter } = useLoaderDispatch(SITE_MANAGER_SCOPE);
    // const { openSiteErrorDialog } = useSiteDesignerDispatch();
    const dispatch = useDispatch();
    const sites = useSelector(state => state.sites.sites);
    const saveZoneChanges = useCallback(async ({
        zonesBeforeChanges,
        zonesAfterChanges,
        takeASnapshot,
        takeASnapshotOnSuccess,
        event,
        selectCreatedZones,
        noDisplayOverlapError,
        noSaveOnService,
    }) => {

        const { name: siteName = "" } = sites.find(({ _id }) => _id === siteId);
        // console.time("update redux");
        replaceItems(ItemType.ZONE, zonesAfterChanges, {mapId,siteId,siteName});
        // console.timeEnd("update redux");

        if (takeASnapshot) {
            // console.time("takeSnapshot");
            takeSnapshot(ItemType.ZONE, zonesAfterChanges);
            // console.timeEnd("takeSnapshot");
        }
        // console.time("selectCreatedZones");
        if (selectCreatedZones) {
            const newZonesPreviousState = [];
            zonesBeforeChanges.forEach(zone => {
                if (zone.isNew === true) newZonesPreviousState.push(zone.id)
            });
            const newZones = [];
            zonesAfterChanges.forEach(zone => {
                if (zone.isNew === true) newZones.push(zone.id)
            });
            const newZonesIds = newZones.filter((zoneId) => !newZonesPreviousState.includes(zoneId));
            // console.log("newZonesIds", newZonesIds);
            refStore.configZonesMap?.[getOlStoreKey(ItemType.ZONE)]?.clearSelectedItems();
            refStore.configZonesMap?.[getOlStoreKey(ItemType.ZONE)]?.addSelectedItems(newZonesIds, {forceMultiSelection: false});
        }
        // console.timeEnd("selectCreatedZones");

        return {success: true};

        // addLoaderCounter({ message: 'Saving' });


    }, [
        siteId,
        sites,
        mapId,
        takeSnapshot,
        replaceItems,
    ]);
    
    return {
        saveZoneChanges,
        loadZoneTypes: useCallback(async () => {
            addLoaderCounter({ message: 'Loading' });
            try {
                const zonesTypes = await getZonesTypes();
                dispatch(loadZoneTypesOnStore(zonesTypes));
            } catch (error) {
                displayErrorFromAxios(dispatch, error);
            }
            subLoaderCounter();
        }, [ dispatch, addLoaderCounter, subLoaderCounter ]),
    };
};
