import axios from 'axios';

import {
    COLUMN_KEYS_TO_PERSIST,
    EMPTY_OBJECT,
    ENDPOINTS,
    ERROR,
    FILTER_KEYS_TO_PERSIST,
    LOADING,
    MESSAGE_MAX_URL_LENGTH_REACHED_REQUEST,
    NONE,
    REPORT_ID_FIRMWARES,
    REPORT_ID_HISTORICAL_REPORTS,
    REPORT_KEY_DEFAULT,
    REPORT_KEY_SEARCH,
    REPORTS_WITH_DYNAMIC_FORMS,
    REPORTS_WITH_CUSTOM_ATTRIBUTES,
    REPORTS_WITH_MULTIPLE_ROWS,
    SUCCESS,
    SAVE_REPORT_USE_SELECTED_ROWS_AS_FILTER,
    SAVED_REPORT_FILTER_FROM_SELECTED_ROWS,
    EMPTY_ARRAY,
    REPORTS_WITH_PAGINATION,
} from '../constants';
import {
    filtersOnePlatformReloadValues,
    filtersOnePlatformReplaceValues,
    onePlatformReplaceHeaderColumns,
    tableOnePlatformReplaceConfigColumns,
    tableOnePlatformReplaceFocusedRows,
    tableOnePlatformReplaceSelectedRows,
    tableOnePlatformReplaceSort,
    tableOnePlatformUpdateData
} from './tableOnePlatform';
import {
    filtersOnePlatformRecoverAllFiltersAllReports,
    filtersOnePlatformReplaceAllFilters, filtersOnePlatformReplaceFilter
} from './filtersOnePlatform';
import * as types from '../constants/ActionTypes';
import {
    getDataOfPath,
    getDefaultColumnValues, getExtraRows,
    getLookup,
    getReportData,
    getUrl,
    getUrlFromObject,
    maxLengthUrl,
    processFiltersValues,
    processSort
} from '../utils';
import { checkIfHistoryReport, fetchReport } from './main';
import { REPLACE_FILTER_TEMPLATES, REPLACE_REPORT_DATA, /*REPORT_DELETE_REPORT_DATA,*/ REPORT_PATCH_IS_FETCHING_DATA, REPORT_PATCH_PROPERTIES } from '../constants/ActionTypes/report';
import {
    DirectionService,
    displayAllErrorFromAxios,
    displayErrorFromAxios,
    ReportService,
    ResourceService,
    SiteViewService
} from './util';
import { FILTER_TYPE, getTimes, PERIOD } from '../views/util/report/filter/util';
import { getReportKeys } from '../hooks';
import { sortByColumn } from './search';
import { requestMenu } from './menu';
import { replaceForms } from './formsOnePlatform';
import {MAP_VIEW_URL} from "../constants/Misc";
import {loadSites} from "./sites";
import qs from 'qs';
import {
    getConfigurationPagination,
    getNewPaginationInfo,
    getPaginationParameters
} from "../views/onePlatform/LayoutContent/ToolbarLoader/Pagination";

export const updateProcessing = (processing, reportKey, background) => {
    return {
        type: REPORT_PATCH_IS_FETCHING_DATA,
        processing,
        reportKey,
        background,
    };
};

export const loadReport = (reportId, runReport) => {
    return dispatch => {
        const realTimeIsDisabled = checkIfHistoryReport(reportId);
        dispatch({ type: types.REQUEST_REPORT, reportId, realTimeIsDisabled });
        fetchReport(dispatch, reportId, runReport);
    };
};

const replaceFilterTemplates = (data, diff) => {
    return {
        type: REPLACE_FILTER_TEMPLATES,
        data,
        diff,
    };
};

export const initialLoad = () => {
    return async (dispatch, getState) => {
        const { reportKey } = getReportKeys(getState());
        dispatch(patchReportProperties(reportKey, { initialLoadStatus: LOADING }));
        axios.all([axios.get(`/config/filters/_filter-templates.json`), axios.get('/config/reports.json')])
            .then(axios.spread(({ data }, { data: reportsData }) => {
                const filterTemplates = getState().filtersOnePlatform.___filterTemplates;
                const diff = {};
                for (const filterKey in data) {
                    for (const filterPropKey in data[filterKey]) {
                        if (JSON.stringify(data[filterKey][filterPropKey]) !== JSON.stringify(filterTemplates?.[filterKey]?.[filterPropKey])) {
                            if (!diff[filterKey]) {
                                diff[filterKey] = {};
                            }
                            diff[filterKey][filterPropKey] = true;
                        }
                    }
                }
                dispatch(replaceFilterTemplates(data, diff));

                // REPORTS PARAMS
                for (const reportId in reportsData) {
                    // fixedColumns, maxResultsByPage
                    let reportKey = "";
                    if(REPORT_ID_FIRMWARES === reportId){
                        reportKey = [ 'device-manager',...reportId.split('.')].join(':');
                    }else{
                        reportKey = ['reports', ...reportId.split('.')].join(':');
                    }

                    // Get the data from local storage for default configuration(if exists).
                    if(reportKey===REPORT_KEY_DEFAULT){
                        const localStorageObject=getState().report?.scopes[reportKey];
                        if(localStorageObject!=null){
                            let mergeObject=Object.assign(reportsData[reportId],localStorageObject);
                            reportsData[reportId]=mergeObject;
                        }
                    }

                    dispatch(patchReportProperties(reportKey, { ...reportsData[reportId] }));
                }
                dispatch(patchReportProperties(reportKey, { initialLoadStatus: SUCCESS }));
            }))
            .catch(error => {
                dispatch(patchReportProperties(reportKey, { initialLoadStatus: ERROR }));
            });
    };
};

const getSites = () =>{
     return SiteViewService.instance().get(MAP_VIEW_URL + '/sites/', {
        params: {},
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(response => {
        const {data} = response;
        const sites = {}
        data?.forEach(site => {
            const {_id, name} = site;
            sites[_id] = {name};
        });
        return sites;
    }).catch(error => {
        console.error('Error on SiteViewService ' + MAP_VIEW_URL + '/sites/', error);
    });
}
export const loadColumnsAndFilters = () => {
    return async (dispatch, getState) => {
        const { reportKey, reportId } = getReportKeys(getState());
        dispatch(patchReportProperties(reportKey, { configurationsStatus: LOADING }));

        const sites = await getSites();

        axios.all([axios.get(`/config/filters/${reportId}.json`), axios.get(`/config/columns/${reportId}.json`)])
            .then(axios.spread(({ data: filtersConfig }, { data: columnsConfig }) => {
                // TABLE COLUMNS
                const tableHeaderColumns = getState().tableOnePlatform[reportKey]?.headerColumns;
                const tableConfigColumns = getState().tableOnePlatform[reportKey]?.columnsConfig;
                // Restoring extra columns of resources report
                const columns = Object.filter(tableHeaderColumns, headerColumn => headerColumn.isExtra);
                let order = 1;
                const columnsConfigObject = {};
                for (const columnConfig of columnsConfig) {
                    const columnKey = columnConfig.key;
                    columnsConfigObject[columnKey] = columnConfig;
                    const columnRecovered = {};
                    for (const key of COLUMN_KEYS_TO_PERSIST) {
                        if (tableHeaderColumns?.[columnKey]?.hasOwnProperty(key) &&
                            JSON.stringify(tableConfigColumns?.[columnKey]?.[key]) === JSON.stringify(columnConfig?.[key])) {
                            columnRecovered[key] = tableHeaderColumns[columnKey][key];
                        }
                    }
                    const defaultValues = getDefaultColumnValues(columnKey, columnConfig.value || '');
                    columns[columnKey] = {
                        ...defaultValues,
                        order: order++,
                        ...columnConfig,
                        ...columnRecovered,
                    };
                    //MWE-7752 We remember the initial configuration of the sortable property, so as not to call the endpoint again
                    if(columns[columnKey].hasOwnProperty('sortable')){
                        columnsConfigObject[columnKey].sortable = columns[columnKey].sortable;
                    }
                }
                dispatch(onePlatformReplaceHeaderColumns(columns, reportKey, true));
                dispatch(tableOnePlatformReplaceConfigColumns(reportKey, columnsConfigObject));
                // FILTERS
                const filterOnePlatformReport = getState().filtersOnePlatform.___stateRecovered?.[reportKey];
                const filterTemplates = getState().filtersOnePlatform.___filterTemplates;
                const diffFilterTemplates = getState().filtersOnePlatform.___diffFilterTemplates;
                const filtersPreFilledValue = getState().filtersOnePlatform?.[reportKey];
                const filters = {};
                
                const today = getTimes(PERIOD.TODAY);
                order = 1;
                for (const filterKey in filtersConfig) {
                    const defaultParams = {
                        key: filterKey,
                        display: true,
                        enabled: true,
                        withPagination: true,
                        order: order++,
                        type: FILTER_TYPE.SELECT,
                    };
                    const filterTemplate = filterTemplates[filtersConfig[filterKey].filterTemplate || filterKey];
                    if (filterTemplate.url) {
                        defaultParams.method = 'GET';
                    }
                    const filterRecovered = {};
                    for (const key of FILTER_KEYS_TO_PERSIST) {
                        if (filterOnePlatformReport?.[filterKey]?.hasOwnProperty(key) && !diffFilterTemplates?.[filterKey]?.[key]) {
                            filterRecovered[key] = filterOnePlatformReport[filterKey][key];
                        }
                    }
                    
                    const newFilter = {
                        ...defaultParams,
                        ...filterTemplate,
                        ...filtersConfig[filterKey],
                        ...filterRecovered,
                    };
                    
                    if ((newFilter.type === FILTER_TYPE.SELECT || newFilter.type === FILTER_TYPE.MULTISELECT_AUTO_SUGGEST) && (!newFilter.requestMode)) {
                        newFilter.requestMode = NONE;
                        if (newFilter.options && newFilter.options.length > 0) {
                            if (newFilter.options?.[0]?.value && newFilter.options?.[0]?.name) { // options: [{ name: string, value: string }]
                                newFilter.options = newFilter.options.map(option => ({ name: option.name, value: option.value }));
                            } else {
                                newFilter.options = newFilter.options.map(option => ({ name: option, value: option }));
                            }
                        }
                    }
                    
                    if (newFilter.type === FILTER_TYPE.DATETIME_FROM) {
                        newFilter.defaultValue = today.from;
                        if (!newFilter.value) {
                            newFilter.value = today.from;
                        }
                    }
                    if (newFilter.type === FILTER_TYPE.DATETIME_TO) {
                        newFilter.defaultValue = today.to;
                        if (!newFilter.value) {
                            newFilter.value = today.to;
                        }
                    }
                    if (newFilter.type === FILTER_TYPE.SELECT || newFilter.type === FILTER_TYPE.MULTISELECT_AUTO_SUGGEST) {
                        if (!Array.isArray(newFilter.value)) {
                            newFilter.value = [];
                        }
                        if (!Array.isArray(newFilter.recentSearches)) {
                            newFilter.recentSearches = [];
                        }
                    }

                    if (newFilter.type === FILTER_TYPE.TIME) {
                        if (!newFilter.rangeMode) {
                            newFilter.rangeMode = PERIOD.NOW;
                            newFilter.value = null;//getCustomDate();
                            //newFilter.sortConfiguration = getCustomDate();
                        }
                        //console.log({newFilter,filterRecovered})
                    }

                    // fill value of filters filled from other reports.
                    if(filtersPreFilledValue!=null&&filtersPreFilledValue.hasOwnProperty(filterKey)){
                        newFilter.value=filtersPreFilledValue[filterKey].value;
                    }
                    if (newFilter.type === FILTER_TYPE.TREE) {

                        try {
                            const value = newFilter.value || {};
                            const treeSiteName = value.treeSiteName || [];
                            const newSiteName = [];
                            for (const siteIndex in treeSiteName) {
                                const site = treeSiteName[siteIndex];
                                const {id} = site;
                                if (site && id && sites.hasOwnProperty(id)) {
                                    newSiteName.push({...site, 'name': sites[id].name, 'path': sites[id].name})
                                }
                            }
                            if(newFilter.hasOwnProperty('value') && newFilter.value && newFilter.value.hasOwnProperty('treeSiteName')){
                                newFilter.value.treeSiteName = newSiteName;
                            }
                        } catch (e) {
                            console.log("The tree filter structure is not correct: ", e)
                        }
                    }
                    filters[filterKey] = newFilter;
                }
                dispatch(filtersOnePlatformReplaceAllFilters(filters, reportKey));
                dispatch(patchReportProperties(reportKey, { configurationsStatus: SUCCESS }));
                
                // replaceForms
            }))
            .catch(errors => {
                console.error('loadColumnsAndFilters:', errors);
                dispatch(patchReportProperties(reportKey, { configurationsStatus: ERROR }));
            });
        if (REPORTS_WITH_DYNAMIC_FORMS.includes(reportId)) {
            try {
                const { data } = await axios.get(`/config/forms/${reportId}.json`);
                Object.values(data).forEach(form => {
                    Object.entries(form.fields).forEach(([key, value]) => {
                        const defaultValues = {
                            key: key,
                            display: true,
                            enabled: true,
                            type: 'text',
                            initialValue: '',
                            validations: [],
                        };
                        form.fields[key] = {
                            ...defaultValues,
                            ...value,
                        };
                    });
                });
                dispatch(replaceForms(reportKey, data));
            } catch (error) {
                console.warn('Failed to load the forms configuration for ', reportId);
                console.warn(error);
            }
        }
    };
};

export const updateFiltersWithSiteName = () => {
    return async (dispatch, getState) => {

        return SiteViewService.instance().get(MAP_VIEW_URL + '/sites/', {
            params: {},
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                const {data} = response;
                const sites = {}
                data?.forEach(site => {
                    const {_id, name} = site;
                    sites[_id] = {name};
                });

                const UNASIGNED_SITE_ID = "111111111111111111111111";
                const sitesWithoutSiteDefault = data.filter(site => site._id !== UNASIGNED_SITE_ID);
                dispatch(loadSites(sitesWithoutSiteDefault));

                // FILTERS
                const filtersOnePlatform = getState().filtersOnePlatform;
                for (const reportKey in filtersOnePlatform) {
                    if (!(reportKey === '___stateRecovered' || reportKey === '___filterTemplates' || reportKey === '___diffFilterTemplates')) {
                        const filters = filtersOnePlatform[reportKey];
                        for (const filterID in filters) {
                            const filter = filters[filterID];
                            if (filter?.type === FILTER_TYPE.TREE) {
                                try{
                                    const value = filter.value || {};
                                    const treeSiteName = value.treeSiteName || [];

                                    const newSiteName = [];
                                    for (const siteIndex in treeSiteName) {
                                        const site = treeSiteName[siteIndex];
                                        const {id} = site;
                                        if (site && id && sites.hasOwnProperty(id)) {
                                            newSiteName.push({...site, 'name': sites[id].name, 'path': sites[id].name})
                                        }
                                    }
                                    if(filters[filterID].hasOwnProperty('value') && filters[filterID].value.hasOwnProperty('treeSiteName')){
                                        filters[filterID].value.treeSiteName = newSiteName;
                                    }
                                }catch (e) {
                                    console.log("The tree filter structure is not correct: ",e)
                                }
                            }
                        }
                        dispatch(filtersOnePlatformReplaceAllFilters(filters, reportKey))
                    }
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch({type: types.SET_SESSION, isSessionActive: false});
                }
                displayErrorFromAxios.bind(null, dispatch);
            });
    }
}

export const getDataWithFilter = ({ urlTemplate, getResultData, method, service, getFiltersQueryParam = (reportFilters) => getUrlFromObject(processFiltersValues(reportFilters)) }, background) => {
    return async (dispatch, getState) => {
        const { reportKey, runReportId,reportId } = getReportKeys(getState());
        dispatch(updateProcessing(true, reportKey, background));
        const { paginationInfo } = getReportData(getState().report.scopes?.[reportKey], getState().report.scopes?.[REPORT_KEY_DEFAULT]);
        const filtersOnePlatform = getState().filtersOnePlatform;
        //Send pagination
        const columnConfigurationForPagination = getState().report.scopes?.[reportKey].columnConfigurationForPagination;
        const { size, page, extraParams } = getConfigurationPagination(paginationInfo,filtersOnePlatform[reportKey],columnConfigurationForPagination);
        const { pageListConfiguration } = paginationInfo;
        const { sort } = getState().tableOnePlatform[reportKey];
        const reportFilters = getState().filtersOnePlatform[reportKey];
        const urlObject = getUrl({ urlTemplate: typeof urlTemplate === 'function' ? urlTemplate({ runReportId }) : urlTemplate, filters: getFiltersQueryParam(reportFilters), page, size, sort: processSort(sort),extraParams });
        const  {url,params,baseURL} = urlObject;
        const { uKeyFields } = getState().report.scopes?.[reportKey] || {};

        dispatch(patchReportProperties(reportKey, {
            paginationInfo:{...paginationInfo,nextPageAllow:false,prevPageAllow:false}}));
        
        if (url.length > maxLengthUrl()) {
            dispatch(patchReportProperties(reportKey, {
                dialog: {
                    open: true,
                    title: 'Error',
                    content: MESSAGE_MAX_URL_LENGTH_REACHED_REQUEST,
                },
            }));
            dispatch(updateProcessing(false, reportKey));
        } else {
            await service.instance()
                .request({ url:baseURL, method,
                    params,
                    paramsSerializer: (params) => qs.stringify(params, { encode: true }),
                })
                .then((result) => {
                    const dataResult = getResultData(result);
                    let lastValueResult = null;

                    if(Object.keys(extraParams).length>0){
                        const lastRows = dataResult.length>0?dataResult[dataResult.length-1]:{};
                        lastValueResult = lastRows && Object.keys(lastRows).length > 0 ? lastRows[columnConfigurationForPagination.getValueParams]:null;
                    }
                    //Update pagination
                    const {
                        totalElements:totalElementsResult,
                        numberOfElements:numberOfElementsResult,
                        totalPages:totalPagesResult,
                        pageNumber:pageNumberPageable,
                        offset:offsetPageable
                    } = getPaginationParameters(result,reportKey,getState().report.scopes);

                    dispatch(patchReportProperties(reportKey, {
                        paginationInfo:getNewPaginationInfo(result, {
                                totalElementsResult,
                                numberOfElementsResult,
                                pageNumberPageable,
                                offsetPageable,
                                totalPagesResult,
                                paginationInfo,
                                pagePrev:paginationInfo.page,
                                enableSelectPage:paginationInfo.enableSelectPage,
                                page,
                                //Change in pagination
                                lastValueResult,
                                pageListConfiguration,
                                filters:filtersOnePlatform[reportKey],
                                nextPageAllow:!!dataResult.length
                        })}));

                    if(REPORTS_WITH_CUSTOM_ATTRIBUTES.includes(reportId)){
                        let filterResourceType = null;
                        Object.values(reportFilters).forEach(filter=>{
                            if(filter.type === FILTER_TYPE.RESOURCE_TYPE_WITH_DISPLAY_NAME){
                                filterResourceType = filter;
                            }
                        });
                        const { value, children:childrenResourceType } = filterResourceType || {};
                        if(value && Array.isArray(value) && value.length > 0 && REPORTS_WITH_MULTIPLE_ROWS.includes(reportId)){
                            dispatch(tableOnePlatformUpdateData(reportKey, getExtraRows(dataResult, childrenResourceType), uKeyFields));
                        }else{
                            dispatch(tableOnePlatformUpdateData(reportKey, dataResult, uKeyFields));
                        }
                    }else{
                        dispatch(tableOnePlatformUpdateData(reportKey, dataResult, uKeyFields));
                    }


                    // For saved reports select all fields by default if the saved option is using USE_SELECTED_ROWS_AS_FILTER
                    if(SAVED_REPORT_FILTER_FROM_SELECTED_ROWS.hasOwnProperty(reportId)
                        && getState().report.scopes?.[reportKey].optionFilter===SAVE_REPORT_USE_SELECTED_ROWS_AS_FILTER)
                    {
                        const { data = EMPTY_ARRAY } = getState().tableOnePlatform[reportKey];

                        const newSelectedRows = {};
                        data.forEach(row => newSelectedRows[row.___uKey] = row);

                        dispatch(tableOnePlatformReplaceSelectedRows(newSelectedRows, reportKey));
                    }
                })
                .catch(displayErrorFromAxios.bind(null, dispatch))
                .finally(() => dispatch(updateProcessing(false, reportKey)));
        }
    };
};

export const loadData = (reportKey, background) => {
    return (dispatch, getState) => {
        const { reportId } = getReportKeys(getState());
        if (ENDPOINTS.hasOwnProperty(reportId)) {
            dispatch(getDataWithFilter(ENDPOINTS[reportId].GET_ALL, background));
        } else if (reportKey === REPORT_KEY_SEARCH) {
            dispatch(sortByColumn(background));
        } else {
            dispatch(getDataWithFilter(ENDPOINTS[REPORT_ID_HISTORICAL_REPORTS].GET_ALL, background));
        }
    };
};

export const replaceReportData = (reportKey, data, uKeyFields, keyFields) => ({ type: REPLACE_REPORT_DATA, reportKey, data, uKeyFields, keyFields });

export const patchReportProperties = (reportKey, properties) => {
    return {
        type: REPORT_PATCH_PROPERTIES,
        reportKey,
        properties,
    };
};

export const saveSavedReportData = (reportData, history, handleSaveClose) => {
    return (dispatch, getState) => {
        ReportService.instance().post(`/saved-reports`, reportData)
            .then(async ({ data }) => {
                await dispatch(requestMenu(getState().user.username, getState().user.jwtToken));
                handleSaveClose();
                history.push('/saved/' + data.id);
            })
            .catch(errors => {
                dispatch(displayAllErrorFromAxios(errors))
            });
    };
};

export const updateSavedReportData = (reportData, handleSaveClose) => {
    return (dispatch, getState) => {
        const { reportKey,reportId } = getReportKeys(getState());
        //dispatch(patchReportProperties(reportKey, { savedConfigurationsStatus: LOADING,groupsUserReport:[],ownerReport:null }));
        ReportService.instance().put(`/saved-reports/${reportData.id}`, reportData)
            .then(async ({ data }) => {
                dispatch(requestMenu(getState().user.username, getState().user.jwtToken));
                const groupsUserReport = data.groups||[ ];
                const ownerReport = data.owner||null;
                const optionFilter = data.optionFilter||null;



                // replace filter with selected rows.
                if(SAVED_REPORT_FILTER_FROM_SELECTED_ROWS.hasOwnProperty(reportId)
                    && optionFilter===SAVE_REPORT_USE_SELECTED_ROWS_AS_FILTER
                    &&data.filters!=null){

                    const currentFilters = getState().filtersOnePlatform[reportKey];
                    const filterId=SAVED_REPORT_FILTER_FROM_SELECTED_ROWS[reportId].filterId;

                    if(currentFilters!=null
                        &&currentFilters.hasOwnProperty(filterId)
                    ){
                        currentFilters[filterId].value=data.filters[filterId].value;
                        dispatch(filtersOnePlatformReplaceFilter(currentFilters[filterId],filterId,reportKey));
                    }

                }

                dispatch(patchReportProperties(reportKey, {groupsUserReport:groupsUserReport, ownerReport:ownerReport,optionFilter:optionFilter}));
                handleSaveClose();
            })
            .catch(errors => {
                //dispatch(patchReportProperties(reportKey, { savedConfigurationsStatus: ERROR}));
                dispatch(displayAllErrorFromAxios(errors))
            });
    };
};

export const deleteSavedReportData = (reportId, redirect, history,changeReport,handleDeleteClose,forceDelete=false,openForceDeleteDialog) => {
    return (dispatch, getState) => {
        ReportService.instance().delete(`/saved-reports/${reportId}`+(forceDelete?'?force=true':''))
            .then(async () => {
                // history.push('/reports/' + reportId.split(':').join('/'));
                await dispatch(requestMenu(getState().user.username, getState().user.jwtToken));
                // history.push('/reports/' + reportId.split(':').join('/'));
                // console.log({ redirect });
                changeReport();
                handleDeleteClose();
            })
            .catch(errors => {
                const {status,data} = errors?.response;
                if(status && data && status === 422 && data === 'Report published, force delete required.'){
                    handleDeleteClose();
                    openForceDeleteDialog();
                }else{
                    dispatch(displayAllErrorFromAxios(errors))
                }
            });
    };
};

export const loadSavedReportConfiguration = (reportKey) => {
    return (dispatch, getState) => {
        const reportId = reportKey.split(':')[1];

        dispatch(patchReportProperties(reportKey, { savedConfigurationsStatus: LOADING,groupsUserReport:[],ownerReport:null }));
        ReportService.instance().get(`/saved-reports/${reportId}`)
            .then(({ data }) => {
                if (!data) {
                    dispatch(patchReportProperties(reportKey, { savedConfigurationsStatus: ERROR }));
                } else {
                    dispatch(filtersOnePlatformRecoverAllFiltersAllReports({ [reportKey]: data.filters }));
                    dispatch(onePlatformReplaceHeaderColumns(data.headerColumns, reportKey, false));
                    dispatch(tableOnePlatformReplaceSort(data.sort, reportKey));
                    const groupsUserReport = data.groups||[ ];
                    const ownerReport = data.owner||null;
                    const optionFilter = data.optionFilter||null;
                    const properties = { savedConfigurationsStatus: SUCCESS, groupsUserReport:groupsUserReport, ownerReport:ownerReport,optionFilter:optionFilter};
                    const baseReportId = data.baseReportId;
                    if(baseReportId && REPORTS_WITH_PAGINATION.includes(baseReportId) ){
                        const reportKeyParent = ['reports', ...baseReportId.split('.')].join(':')
                        const columnConfigurationForPagination = getState().report.scopes?.[reportKeyParent].columnConfigurationForPagination;
                        if(columnConfigurationForPagination){
                            properties.columnConfigurationForPagination = columnConfigurationForPagination;
                        }
                    }
                    dispatch(patchReportProperties(reportKey, properties));
                }
            })
            .catch(errors => {
                dispatch(patchReportProperties(reportKey, { savedConfigurationsStatus: ERROR }));
                dispatch(displayAllErrorFromAxios(errors));
            });
    };
};

const resetFilterValues = ({savedFilters,filter,sites,filterTemplates,actionFilter}) =>{
    const today = getTimes(PERIOD.TODAY);
    let order = 1;
    const filters = {}
    const timeFilter = {};
    for (const filterKey in filter) {
        const defaultParams = {
            key: filterKey,
            display: true,
            enabled: true,
            withPagination: true,
            order: order++,
            type: FILTER_TYPE.SELECT,
        };

        const filterRecovered = {};
        for (const key of FILTER_KEYS_TO_PERSIST) {
            if (savedFilters?.[filterKey]?.hasOwnProperty(key) ) {
                filterRecovered[key] = savedFilters[filterKey][key];
            }
        }
        const filterTemplate = filterTemplates[filterKey];
        const newFilter = {
            ...defaultParams,
            ...filterTemplate,
            //...filtersConfig[filterKey],
            ...filter[filterKey],
            ...filterRecovered,
        };

        if ((newFilter.type === FILTER_TYPE.SELECT || newFilter.type === FILTER_TYPE.MULTISELECT_AUTO_SUGGEST) && (!newFilter.requestMode)) {
            newFilter.requestMode = NONE;
            if (newFilter.options && newFilter.options.length > 0) {
                if (newFilter.options?.[0]?.value && newFilter.options?.[0]?.name) { // options: [{ name: string, value: string }]
                    newFilter.options = newFilter.options.map(option => ({ name: option.name, value: option.value }));
                } else {
                    newFilter.options = newFilter.options.map(option => ({ name: option, value: option }));
                }
            }
        }

        if (newFilter.type === FILTER_TYPE.DATETIME_FROM) {
            newFilter.defaultValue = today.from;
            if (!newFilter.value) {
                newFilter.value = today.from;
            }
        }
        if (newFilter.type === FILTER_TYPE.DATETIME_TO) {
            newFilter.defaultValue = today.to;
            if (!newFilter.value) {
                newFilter.value = today.to;
            }
        }
        if (newFilter.type === FILTER_TYPE.SELECT || newFilter.type === FILTER_TYPE.MULTISELECT_AUTO_SUGGEST) {
            if (!Array.isArray(newFilter.value)) {
                newFilter.value = [];
            }
            if (!Array.isArray(newFilter.recentSearches)) {
                newFilter.recentSearches = [];
            }
        }

        if (newFilter.type === FILTER_TYPE.TREE) {

            try {
                const value = newFilter.value || {};
                //console.log({valueTree:value})
                const treeSiteName = value.treeSiteName || [];
                const newSiteName = [];
                for (const siteIndex in treeSiteName) {
                    const site = treeSiteName[siteIndex];
                    const {id} = site;
                    if (site && id && sites.hasOwnProperty(id)) {
                        newSiteName.push({...site, 'name': sites[id].name, 'path': sites[id].name})
                    }
                }

                if(newFilter.hasOwnProperty('value') && newFilter.value && newFilter.value.hasOwnProperty('treeSiteName')){
                    newFilter.value.treeSiteName = newSiteName;
                }
                newFilter.forceRefresh = true;
            } catch (e) {
                console.log("The tree filter structure is not correct: ", e)
            }
        }

        if(newFilter.type === FILTER_TYPE.TIME ) {
            timeFilter.keyTimeFilter = newFilter.key;
            timeFilter.rangeMode = newFilter.rangeMode;
            timeFilter.enabledFilters = newFilter.enabledFilters;
            timeFilter.action = actionFilter;
            newFilter.action = actionFilter;
        }
        filters[filterKey] = newFilter;
    }
    return {filters,timeFilter};
}

const resetColumns = ({tableHeaderColumns,recoveredData}) =>{
    const columns = Object.filter(tableHeaderColumns, headerColumn => headerColumn.isExtra);
    const columnsConfigObject = {};
    Object.keys(tableHeaderColumns).forEach(columnKey=>{
        columnsConfigObject[columnKey] = recoveredData[columnKey];
        columns[columnKey] = {
            ...tableHeaderColumns[columnKey],
            ...recoveredData[columnKey],
        };
    })
    return {columns,columnsConfigObject};
}

export const loadFilterColumnsSavedReportConfiguration = (reportKey,restoreFilters,restoreColumns,restoreSort,isSavedReport,actionFilter) => {
    return async (dispatch, getState) => {
        const reportId = reportKey.split(':')[1];
        const sites = await getSites();
        ReportService.instance().get(`/saved-reports/${reportId}`)
            .then(({ data }) => {
                if (!data) {
                    dispatch(patchReportProperties(reportKey, { savedConfigurationsStatus: ERROR }));
                } else {

                    if ( restoreFilters ) {
                        const filter = getState().filtersOnePlatform?.[reportKey];
                        const filterTemplates = getState().filtersOnePlatform.___filterTemplates;
                        const {filters,timeFilter} = resetFilterValues({savedFilters:data.filters,filter,sites,filterTemplates,actionFilter});
                        if(Object.keys(timeFilter).length>0){
                            const {enabledFilters} = timeFilter;
                            let filtersEnable = [];
                            let filtersDisabled = [];
                            if(timeFilter.rangeMode === PERIOD.NOW){
                                filtersEnable = Object.keys(enabledFilters)
                                filtersDisabled = Object.values(enabledFilters)
                            }else{
                                filtersDisabled = Object.keys(enabledFilters)
                                filtersEnable = Object.values(enabledFilters)
                            }
                            filtersEnable.forEach(key =>{
                                if(filters[key]){
                                    filters[key].display = true;
                                }
                            });
                            filtersDisabled.forEach(key =>{
                                if(filters[key]){
                                    filters[key].display = false;
                                }
                            });
                            if(timeFilter.rangeMode === PERIOD.NOW){
                                dispatch(filtersOnePlatformReloadValues(reportKey,timeFilter.keyTimeFilter,isSavedReport,filters,restoreSort,data.sort));
                            }else{
                                dispatch(filtersOnePlatformReplaceValues(reportKey,timeFilter.keyTimeFilter,isSavedReport,filters,restoreSort,data.sort));
                            }
                        }else{
                            dispatch(filtersOnePlatformReplaceAllFilters(filters, reportKey));
                        }
                    }

                    if ( restoreColumns ) {
                        const tableHeaderColumns = getState().tableOnePlatform[reportKey]?.headerColumns;
                        const {columns,columnsConfigObject} = resetColumns ({tableHeaderColumns,recoveredData:data.headerColumns}) ;
                        dispatch(onePlatformReplaceHeaderColumns(columns, reportKey, false));
                        dispatch(tableOnePlatformReplaceConfigColumns(reportKey, columnsConfigObject));
                    }

                    /*if ( restoreSort ) {
                        dispatch(tableOnePlatformReplaceSort(data.sort, reportKey));
                    }*/
                }
            })
            .catch(errors => {
                dispatch(patchReportProperties(reportKey, { savedConfigurationsStatus: ERROR }));
                dispatch(displayAllErrorFromAxios(errors));
            });
    };
};

/*
export const reportDeleteReportData = (reportKey) => {
    return {
        type: REPORT_DELETE_REPORT_DATA,
        reportKey,
    };
};*/

export const cleanReportData = (reportKey, isSavedReport) => {
    return dispatch => {
        if (isSavedReport) {
            //dispatch(reportDeleteReportData(reportKey));
        }
    };
};

export const validateUserPermissions = (reportPath) => {
    return (dispatch, getState) => {
        const menu = getState().user?.menu;
        let menuExists = false;
        const routes = `/${reportPath}`;
        menu.forEach(menuItem => {
            const children = menuItem?.children;
            children?.forEach(subMenu => {
                if (subMenu.path === reportPath || subMenu.routes === routes) {
                    menuExists = true;
                }
            });
        });
        return menuExists;
        /*
        if(!menuExists){
            dispatch(expireSession());
        }*/
    };
};

export const showErrorMessage = ({ reportKey, message, sessionHasExpired = false }) => {
    return (dispatch) => {
        dispatch(patchReportProperties(reportKey, { dialog: { open: true, title: 'Error', content: message, sessionHasExpired: sessionHasExpired } }));
    };
};

export const redirectPageByUserPermission = (reportKey, redirect, history) => {
    return (dispatch) => {
        dispatch(patchReportProperties(reportKey, { dialog: { open: false } }));
        history.push(redirect);
    };
};

export const loadForm = (reportKey, { service, url = '', method = 'GET', values = {}, body, mapResponseData }, refresh = true, focusNewItem) => {
    return async (dispatch, getState) => {
        const { uKeyFields } = getState().report.scopes?.[reportKey] || {};
        const { reportId } = getReportKeys(getState());
        let Service = ResourceService;
        if (service === 'ResourceService') {
            Service = ResourceService;
        } else if (service === 'DirectionService') {
            Service = DirectionService;
        }
        try {
            const data = {};
            for (const keyValue in values) {
                if (body[keyValue]) {
                    data[body[keyValue].key || keyValue] = values[keyValue];
                }
                url = url.split(`{{${keyValue}}}`).join(values[keyValue]);
            }
            const { data: responseData } = await Service.instance().request({ url: url, method: method, data });
            for (const key in mapResponseData) {
                data[key] = getDataOfPath(responseData, mapResponseData[key]);
            }
            const nextAction = async () => {
                if (refresh) {
                    await dispatch(getDataWithFilter(ENDPOINTS[reportId].GET_ALL));
                }
                if (focusNewItem) {
                    const { selectedRows = EMPTY_OBJECT } = getState().tableOnePlatform[reportKey];
                    const ___uKey = getLookup(data, uKeyFields);
                    dispatch(tableOnePlatformReplaceSelectedRows({ ...selectedRows, [___uKey]: { ___uKey } }, reportKey));
                    dispatch(tableOnePlatformReplaceFocusedRows({ [___uKey]: { ___uKey } }, reportKey));
                }
                /*
                if(reportId === REPORT_ID_ZONE_EXPIRATION && method!=='GET'){
                    // save config in file
                    dispatch(saveZoneExpirationConfig());
                }*/
            };
            nextAction();
        } catch (errors) {
            dispatch(displayAllErrorFromAxios(errors));
            return errors;
        }
        return 0;
    };
};

export const updateSelectedSites = (selectedRows) => {
    return (dispatch, getState) => {
        const { reportKey } = getReportKeys(getState());
        const selectedSitesSet = new Set();
        for (const key in selectedRows) {
            const siteId = selectedRows[key]?.site?.siteId || selectedRows[key].siteId;
            selectedSitesSet.add(siteId);
        }
        dispatch(patchReportProperties(reportKey, { selectedSites: [...selectedSitesSet] }));
    };
};


export const viewDetailsJumpToSection = (rowIndex) => {
    return (dispatch, getState) => {
        const { reportKey } = getReportKeys(getState());
        const { data = EMPTY_ARRAY,selectedRows=EMPTY_OBJECT } = getState().tableOnePlatform[reportKey];

        const newSelectedRows = {...selectedRows};
        const clickedRow=data[rowIndex];

        if(selectedRows!=null&&!selectedRows.hasOwnProperty(clickedRow.___uKey)) {
            newSelectedRows[clickedRow.___uKey]=clickedRow;
            dispatch(tableOnePlatformReplaceSelectedRows(newSelectedRows, reportKey));
        }

        dispatch({ type: REPORT_PATCH_PROPERTIES,reportKey:reportKey, properties:{showDetails: true,customDetails:['eventHistoryNewValue']} });

    };
};