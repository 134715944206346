import React from 'react';
import PropTypes from 'prop-types';
import TableBody from './TableBody';
import TableHeader from './TableHeader';

import {ScrollSync} from 'react-virtualized';
import scrollbarSize from 'dom-helpers/scrollbarSize';

import SplitPane from './splitpane/SplitPane';
import {NormalTheme} from './themes/NormalTheme';
import {MAP_REPORT_DEFAULT} from "../../../../vss/app/constants/ViewTypes";
import assign from "object-assign";
import {isDefaultValue} from "../../../../vss/app/views/util/report/filter/util";
import SortDirection from '@material-ui/icons/TrendingFlat';
import TableHeaderDragLine from "./header/TableHeaderDragLine";
import WrappedCheckbox from "./header/WrappedCheckbox";

const sortBy = require('lodash.sortby');

const CHECKBOX_COLUMN_WIDTH = 75;

class TableView extends React.Component{

    constructor(props){
        super();

        this.state = {
            selectedIndexes: {},
            hoverIndex: -1,
            sortedData:props.data,
        };

        //this.table = React.createRef();
        this.datagrid = React.createRef();
        this.headergrid = React.createRef();
        //this.container = React.createRef();

        // data prvoiders
        this._getColumnWidth = this._getColumnWidth.bind(this);
        this._getHeaderColumnWidth = this._getHeaderColumnWidth.bind(this);
        this._getBodyColumnWidth = this._getBodyColumnWidth.bind(this);
        this.isSelected = this.isSelected.bind(this);
        this.isHovered = this.isHovered.bind(this);

        // reference establishment
        this.setRefs = this.setRefs.bind(this);
        this.jumpToRow = this.jumpToRow.bind(this);

        // renderers
        this.renderTableBody = this.renderTableBody.bind(this);

        // handlers
        this.onResize = this.onResize.bind(this);
        this.handleSelectAll = this.handleSelectAll.bind(this);
        this.handleTouchTapRow = this.handleTouchTapRow.bind(this);
        this.handleHoverRow = this.handleHoverRow.bind(this);
        this.handleSortChange = this.handleSortChange.bind(this);

        // Global header render.
        this._cellRenderer = this._cellRenderer.bind(this);
        this._renderColumnHeader = this._renderColumnHeader.bind(this);
        // this._renderColumnFilter = this._renderColumnFilter.bind(this);
        this._renderSelectAll = this._renderSelectAll.bind(this);

        // Handlers ------------------------------------------------------------
        this.handleColumnHeaderTap = this.handleColumnHeaderTap.bind(this);

    }

    static get propTypes(){
        return{
            /* always required */
            columns: PropTypes.array.isRequired,
            data: PropTypes.array.isRequired,
            nFixedColumns: PropTypes.number,

            /* theme */
            theme: PropTypes.object,

            /*hover*/
            onHoverRow: PropTypes.func,
            hoverIndex: PropTypes.number,
            isHovered: PropTypes.func,

            /*selection*/
            onTouchTapRow: PropTypes.func,
            onTouchTapCheckbox: PropTypes.func,
            onSelectAll: PropTypes.func,
            isSelected: PropTypes.func,

            /* split view */
            splitSize: PropTypes.any,
            onSplitResize: PropTypes.func,
            splitComponent: PropTypes.any,

            /* filters */
            filterRenderer: PropTypes.func,

            /* sorting */
            disableSort: PropTypes.bool,
            onSortChange: PropTypes.func,
            sortIndex: PropTypes.number,
            sortDirection: PropTypes.number,

            /* custom renderer */
            bodyCellContentRenderer: PropTypes.func,
            getRowHeight: PropTypes.func,

            /* column sizing */
            getBaseColumnWidth: PropTypes.func,
            disableFillTable: PropTypes.bool,
            onResizeColumn: PropTypes.func,
            onRequestAutoSize: PropTypes.func,

            /* header row sizing */
            headerHeight: PropTypes.number,
            headerWidth: PropTypes.number,
            filterHeight: PropTypes.number,

            /* Sidebars */
            sidebarFilterStatus: PropTypes.object,
            detailsItem:PropTypes.any,
            isSelectedDetails:PropTypes.func,

            // Define if uses position sticky in Custom grid for fixed headers.
            modernBrowser:PropTypes.bool,
        };
    }

    static get defaultProps(){
        const nop = function(){};
        return {
            nFixedColumns: 1,
            onSplitResize: nop,
            theme:NormalTheme,
            splitComponent:(<div>{"Set me with the 'splitComponent' prop in <TableView />"}</div>),
            onSortChange: nop,
            headerHeight:40,
            headerWidth:40,
            filterHeight:56,
            modernBrowser:false,
        };
    }

    onResize(){
        if(this.props.modernBrowser!==true)
            this.HeaderGrid.forceGridUpdate();
        this.DataGrid.forceGridUpdate();
    }

    componentDidMount(){
        window.addEventListener('resize',this.onResize);
    }

    componentWillUnmount(){
        window.removeEventListener('resize',this.onResize);
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        let updates = {};
        if(nextProps.sortIndex !== undefined){
            updates.sortIndex = nextProps.sortIndex;
        }
        if(nextProps.sortDirection !== undefined){
            updates.sortDirection = nextProps.sortDirection;
        }


        let sortedData =nextProps.data;

        // if we have a listener for sort change, then we'll assume sorting is
        // handled at a higher level.
        if(!nextProps.onSortChange && nextProps.sortIndex >= 0){
            sortedData = sortBy(nextProps.data,nextProps.columns[nextProps.sortIndex].name);
            if(nextProps.sortDirection > 0) sortedData = sortedData.reverse();
        }

        const isAllSelected = sortedData.reduce((result,datum,index)=>{
            if(!result) return false;
            return (nextProps.isSelected || this.isSelected)(index,datum);
        },true);

        updates.sortedData=sortedData;
        updates.isAllSelected=isAllSelected;

        this.setState(updates);
    }

    componentDidUpdate(prevProps){

        // Jump to selected item in details sidebar.
        if(this.props.showRightSidebar===true
            &&this.props.detailsItem!=null
            &&this.props.selected!=null
            &&this.props.detailsItem!==prevProps.detailsItem
        )
        {
            if(this.props.clickedItem===prevProps.clickedItem // prevents jump when deselect a item.
                && this.props.selected.hasOwnProperty(this.props.detailsItem)) {
                const indexDetailsItem=this.props.selected[this.props.detailsItem]['___index'];
                this.jumpToRow(indexDetailsItem);
            }
        }

        if(this.props.modernBrowser!==true){
            if(this.HeaderGrid && this.HeaderGrid.hasOwnProperty('forceGridUpdate')){
                this.HeaderGrid.forceGridUpdate();
            }
        }

        this.DataGrid.forceGridUpdate();
    }

    shouldComponentUpdate(nextProps,nextState){
        return (this.props !== nextProps || this.state !== nextState);
    }

    // DATA PROVIDERS ----------------------------------------------------------

    _getBaseColumnWidth(){
        return 150;
    }

    _getBaseTableWidth(getBaseColumnWidth,columns){
        let s = 0,i,l = columns.length;
        for(i=0;i<l;i++){
            s += getBaseColumnWidth({index:i});
        }
        return s;
    }

    _getAdditionalWidth({tableWidth,getBaseColumnWidth,columns}){

        let emptySpace = (tableWidth - (this._getBaseTableWidth(getBaseColumnWidth,columns) + CHECKBOX_COLUMN_WIDTH + scrollbarSize()));

        let additionalWidth =  emptySpace / (columns.length);

        additionalWidth = (additionalWidth > 0) ? Math.floor(additionalWidth) : 0;

        return additionalWidth;
    }

    _getColumnWidth ({ index },remainderColumnHandler) {

        let containerWidth = 0,tableWidth = 0;
        try{
            let {container,table} = this.scrollSync.refs;
            containerWidth = container?.offsetWidth;
            tableWidth = table?.offsetWidth;
        }catch(e){
            // dunno
        }

        const {columns,disableFillTable} = this.props;
        const getBaseColumnWidth = this.props.getBaseColumnWidth || this._getBaseColumnWidth;

        if(index === columns.length){
            return remainderColumnHandler({containerWidth,tableWidth});
        }

        if(disableFillTable){
            return getBaseColumnWidth({index});
        }

        return getBaseColumnWidth({index})+this._getAdditionalWidth({tableWidth,getBaseColumnWidth,columns});
    }

    _getHeaderColumnWidth({index}){
        return this._getColumnWidth(
            {index},
            this._getRemainderHeaderColumnWidth.bind(this)
        );
    }

    _getRemainderHeaderColumnWidth({containerWidth,tableWidth}){
        const _scrollbarSz = scrollbarSize();
        const {columns,disableFillTable} = this.props;
        const getBaseColumnWidth = this.props.getBaseColumnWidth || this._getBaseColumnWidth;

        if(disableFillTable){
            let fullTableWidth = this._getBaseTableWidth(getBaseColumnWidth,columns) + CHECKBOX_COLUMN_WIDTH;
            let usedWidth = (fullTableWidth < tableWidth) ? fullTableWidth : tableWidth;
            let tmp = containerWidth - usedWidth;
            return (tmp > 0) ? tmp + _scrollbarSz : _scrollbarSz;
        }else{
            return containerWidth - (tableWidth - _scrollbarSz);
        }
    }

    _getBodyColumnWidth({index}){
        return this._getColumnWidth(
            {index},
            this._getRemainderBodyColumnWidth.bind(this)
        );
    }

    _getRemainderBodyColumnWidth({tableWidth}){
        const _scrollbarSz = scrollbarSize();
        const {columns,disableFillTable} = this.props;
        const getBaseColumnWidth = this.props.getBaseColumnWidth || this._getBaseColumnWidth;

        if(disableFillTable){
            let fullTableWidth = this._getBaseTableWidth(getBaseColumnWidth,columns) + CHECKBOX_COLUMN_WIDTH;
            let usedWidth = (fullTableWidth < tableWidth) ? fullTableWidth : tableWidth;
            let tmp = tableWidth - usedWidth - _scrollbarSz;
            return (tmp > 0) ? tmp : 0;
        }else{
            return tableWidth - (tableWidth - _scrollbarSz);
        }
    }

    adjustShadow(scrollLeft){
        if(scrollLeft === 0){ return "";}
        if(scrollLeft < 20){
            return "0 0px "+(scrollLeft/2)+"px rgba(0,0,0,0.16), "+
                        "0 0px "+(scrollLeft/2)+"px rgba(0,0,0,0.23)";
        }

        return "0 0px 10px rgba(0,0,0,0.16), 0 0px 10px rgba(0,0,0,0.23)";
    }

    isSelected(index){
        return this.state.selectedIndexes[index] || false;
    }

    isHovered(index){
        return index === ((this.props.hoverIndex === undefined) ? this.state.hoverIndex : this.props.hoverIndex);
    }

    // REFERENCE ESTABLISHMENT -------------------------------------------------

    setRefs(ref){
        if(ref){
            this.scrollSync = ref;
            if(this.props.modernBrowser!==true)
                this.HeaderGrid = this.headergrid.current;
            this.DataGrid = this.datagrid.current;
        }
    }

    jumpToRow(i){
        this.DataGrid.jumpToRow(i);
    }

    // HANDLERS ----------------------------------------------------------------

    handleSelectAll(event,selectAll){
        let i, l = this.props.data.length, o = {};
        for(i=0;i<l;i++){
            o[i] = selectAll;
        }
        this.setState({selectedIndexes:o});
    }

    handleTouchTapRow(index){
        this.setState({
            selectedIndexes:{...this.state.selectedIndexes, [index] : (!this.state.selectedIndexes[index]) }
        });
    }

    handleHoverRow(index){
        this.setState({
            hoverIndex: index
        });
    }

    handleSortChange(index){

        if(this.props.disableSort) return;

        const {columns} = this.props;
        const col = columns[index];

        const same = (this.state.sortIndex === index);
        const direction = (same && (this.state.sortDirection === -1) && 1) || -1;
        this.setState({
            sortIndex: index,
            sortDirection: direction
        });
        this.props.onSortChange(index,direction,col.sortDisable);
    }

    // RENDERERS ---------------------------------------------------------------

    renderTableBody({sortedData, onScroll,scrollTop,scrollLeft}){
        const {
            columns,
            splitSize,
            onSplitResize,
            splitComponent,
            theme,
            nFixedColumns,
            bodyCellContentRenderer,
            getRowHeight,
            // view,
            // isPortrait,
            filters,
            modernBrowser,
            headerHeight,
            backgroundRow,
            reportId,
            maps,
            sites,
        } = this.props;

        const tableBody = (
            <TableBody
                ref={this.datagrid}
                columns={columns}
                data={sortedData}
                theme={theme}
                onScroll={onScroll}
                scrollTop={scrollTop}
                scrollLeft={scrollLeft}
                getColumnWidth={this._getBodyColumnWidth}
                onTouchTapRow={this.props.onTouchTapRow || this.handleTouchTapRow}
                onTouchTapCheckbox={this.props.onTouchTapCheckbox || this.handleTouchTapRow}
                isHovered={this.props.isHovered || this.isHovered}
                onHoverRow={this.props.onHoverRow || this.handleHoverRow}
                fixedBoxShadow={this.adjustShadow(scrollLeft)}
                fixedColumnCount={nFixedColumns}
                isSelected={this.props.isSelected || this.isSelected}
                bodyCellContentRenderer={bodyCellContentRenderer}
                getRowHeight={getRowHeight}
                detailsItem={this.props.detailsItem}
                isSelectedDetails={this.props.isSelectedDetails}
                showRightSidebar={this.props.showRightSidebar}
                modernBrowser={modernBrowser}
                filters={filters}
                headerRender={this._cellRenderer}
                headerHeight={headerHeight}
                backgroundRow={backgroundRow}
                reportId={reportId}
                maps={maps}
                sites={sites}
            />
        );

        const resizerStyle = {
            backgroundColor:theme && theme.table && theme.table.resizer && theme.table.resizer.backgroundColor
        };
        const resizerHandleStyle = {
            color: (theme && theme.table && theme.table.resizerHandle && theme.table.resizerHandle.color) || "inherit"
        };


        let currentView=MAP_REPORT_DEFAULT;

        if(splitSize){
            return (
                <SplitPane
                    defaultSize={splitSize}
                    onChange={onSplitResize}
                    resizerStyle={resizerStyle}
                    split={currentView}
                    resizerHandleStyle={resizerHandleStyle}>
                    <div ref={"table"} className="filled-up">
                        {tableBody}
                    </div>
                    <div>
                        {splitComponent}
                    </div>
                </SplitPane>
            );
        }else{
            return (
                <div ref={"table"} className="filled-up">
                    {tableBody}
                </div>
            );
        }
    }

    // MAIN RENDER -------------------------------------------------------------

    render(){
        const {
            //data,
            columns,
            theme,
            filterRenderer,
            onSelectAll,
            nFixedColumns,
            //onSortChange,
            headerHeight,
            //headerWidth,
            filterHeight,
            filters,
            onResizeColumn,
            onRequestAutoSize,
            sidebarFilterStatus,
            modernBrowser,
        } = this.props;

        const {
            sortIndex,
            sortDirection,
            sortedData,
        } = this.state;

        const getRowHeight = ({index})=>{
            return (index === 0) ? (headerHeight) : (filterHeight);
        };

        const getHeight = ()=>{
            return (filterRenderer ? (headerHeight+filterHeight) : headerHeight);
        };
        // const getWidth = ()=>{
        //     return (headerWidth);
        // };

        return <ScrollSync ref={this.setRefs}>
            {({onScroll,scrollLeft,scrollTop})=>(
                <div ref={"container"} className="report-table filled-up">
                    {(modernBrowser===true)?"":<TableHeader
                        ref={this.headergrid}
                        columns={columns}
                        theme={theme}
                        scrollLeft={scrollLeft}
                        getColumnWidth={this._getHeaderColumnWidth}
                        fixedBoxShadow={this.adjustShadow(scrollLeft)}
                        filterRenderer={filterRenderer}
                        filters={filters}
                        fixedColumnCount={nFixedColumns}
                        onSelectAll={onSelectAll || this.handleSelectAll}
                        sortIndex={sortIndex}
                        sortDirection={sortDirection}
                        // onHeaderTap={this.handleSortChange}
                        getRowHeight={getRowHeight}
                        getHeight={getHeight}
                        // isAllSelected={isAllSelected}
                        onResizeColumn={onResizeColumn}
                        onRequestAutoSize={onRequestAutoSize}
                        sidebarFilterStatus={sidebarFilterStatus}
                        cellRenderer={this._cellRenderer}
                        backgroundRow={this.props.backgroundRow}
                    />}
                    <div className="body-container" style={{top:(modernBrowser===true)?`0px`:getHeight()+'px'}}>
                        {this.renderTableBody({sortedData, onScroll,scrollTop,scrollLeft})}
                    </div>
                </div>
            )
            }
        </ScrollSync>;
    }



    //region Header render.

    _cellRenderer ({ columnIndex, key, rowIndex, style,fixedBoxShadow }) {
        if(columnIndex === 0){
            return this._renderSelectAll({rowIndex,key,style,fixedBoxShadow});
        }
        if (rowIndex === 0) {
            return this._renderColumnHeader({columnIndex:columnIndex-1, key, rowIndex, style});
        } else {
            return;//this._renderColumnFilter({columnIndex:columnIndex-1, key, rowIndex, style});
        }
    }

    _renderSelectAll({rowIndex,key,style,fixedBoxShadow}){
        const {theme,onSelectAll,modernBrowser,backgroundRow} = this.props;
        const {isAllSelected} = this.state;
        let className;

        if(rowIndex === 0){
            className = "column-header-cell";
            style = assign({},style,{
                lineHeight:style.height+'px',
                backgroundColor: theme && theme.table && theme.table.header && theme.table.header.backgroundColor
            });

        }else{
            className = "column-filter-cell";
            style = assign({},style,{
                backgroundColor: theme && theme.table && theme.table.filter && theme.table.filter.backgroundColor
            });
        }


        // Style for shadow in checkbox when horizontal scroll happens.
        let shadowClass="";

        if(modernBrowser===true&&fixedBoxShadow!=null&&fixedBoxShadow!=="")
            shadowClass=" checkbox-cell-shadow-header";

        return (            
            <div style={style} key= {key} className={className+shadowClass}>
                {rowIndex === 0 && backgroundRow===undefined? (
                        <WrappedCheckbox className="report-selectall-checkbox" disableTouchRipple={true} onChange={onSelectAll || this.handleSelectAll} checked={!!isAllSelected}/>)
                    : null}
            </div>
        );
    }


    _renderColumnHeader ({ key, columnIndex, style }) {
        const {columns,theme,sortIndex,sortDirection, onResizeColumn, onRequestAutoSize, filters} = this.props;
        const col = columns[columnIndex];

        const headerStyles = {
            lineHeight:style.height+'px',
            color: theme && theme.table && theme.table.header && theme.table.header.color,
            backgroundColor: theme && theme.table && theme.table.header && theme.table.header.backgroundColor
        };

        const buttonStyles = {
            textAlign: (col && col.centered) ? 'center' : 'left',
            overflow:'hidden'
        };

        style = assign({},style,headerStyles);

        const sortIndicator = (sortIndex === columnIndex&&col.sortDisable!==true) ? (<SortDirection className={(sortDirection  < 0) ? "sort-asc" : "sort-desc"} />) : null;


        let headerClass = 'filled-up';
        let highlightFilter = false;
        let headerTitle = '';

        if (this.filtersEnabled(col, filters)) {
            const filter = filters[col.filter];

            if (filters[col.filter].type === 'datetime') {
                const name = col.name;
                let isDefaultFrom = false, isDefaultTo = false;

                Object.keys(filters).forEach(f=>{
                    if(filters[f].name === 'from' + name[0].toUpperCase() + name.substr(1)){
                        isDefaultFrom = isDefaultValue(filters[f].value,filters[f].defaultValue, 'datetimeFrom');
                    }

                    if(filters[f].name === 'to' + name[0].toUpperCase()+name.substr(1)){
                        isDefaultTo = isDefaultValue(filters[f].value,filters[f].defaultValue, 'datetimeTo');
                    }
                });
                highlightFilter = !isDefaultFrom || !isDefaultTo;
                headerClass += highlightFilter ? ' highlight' : '';
            } else {
                highlightFilter = !isDefaultValue(filter.value,filter.defaultValue, filter.type);
                headerClass += highlightFilter ? ' highlight' : '';
            }

            if (highlightFilter) {
                headerTitle = 'This filter has a value other than default.';
            }
        }

        return (
            <div key={key} style={style} className="column-header-cell">
                <button
                    className={headerClass}
                    data-index={columnIndex}
                    onClick={this.handleColumnHeaderTap}
                    style={buttonStyles}
                    title={headerTitle}
                >
                    {columnIndex !== columns.length && col.caption}
                    {sortIndicator}
                </button>
                <TableHeaderDragLine
                    onResizeColumn={onResizeColumn.bind(null,columnIndex)}
                    onRequestAutoSize={onRequestAutoSize.bind(null,columnIndex)}
                />
            </div>
        );
    }

    filtersEnabled(col, filters) {
        return col && filters && col.filter && filters.hasOwnProperty(col.filter);
    }

    // HANDLERS ----------------------------------------------------------------

    handleColumnHeaderTap(e){
        const columnIndex = parseInt(e.currentTarget.dataset.index);
        this.handleSortChange(columnIndex);
    }
    //endregion

}


export default TableView;
