import React from 'react';
import PropTypes from 'prop-types';

class DataEntryTabularForm extends React.Component{

    static get propTypes(){
        return {
            children: PropTypes.any,
            fullWidth: PropTypes.bool,
            minWidth: PropTypes.string,
        };
    }

    render(){
        const {fullWidth,children, minWidth} = this.props;
        return (
            <table className="data-entry-form" style={{width:(fullWidth ? '100%' : 'auto'),tableLayout:'fixed',minWidth:minWidth ? minWidth : 'auto'}}>
                <thead />
                <tbody>
                    {children}
                </tbody>
            </table>
        );
    }
}

class DataEntryTabularField extends React.Component{
    static get propTypes(){
        return {
            label: PropTypes.string,
            children: PropTypes.any,
            fieldStyle: PropTypes.object,
            required: PropTypes.bool
        };
    }

    render(){

        return (
            <tr>
                <td className="basic-input">
                    <div className={"label"}>
                        {this.props.label}
                        {
                            (this.props.required) ? (
                                <span style={{fontSize:'0.9em',color:'#f55',marginLeft:'0.5em',fontStyle:'italic',letterSpacing:'0.5px'}}>
                                    (required)
                                </span>
                            ):null
                        }
                    </div>
                    <div className={"field"} style={this.props.fieldStyle}>{this.props.children}</div>
                </td>
            </tr>
        );
    }
}

class DataEntryTabularFieldError extends React.Component{
    static get propTypes(){
        return {
            label: PropTypes.string
        };
    }

    render(){
        const {label} = this.props;
        return (
            <tr>
                <td className={"basic-input"} colSpan={2}>
                    <div className={"field"} style={{textAlign:'center',fontSize:'0.9em',color:'#f55',fontStyle:'italic',letterSpacing:'0.5px'}}>
                        {label}
                    </div>
                </td>
            </tr>
        );
    }
}

export {DataEntryTabularForm,DataEntryTabularField,DataEntryTabularFieldError};
export {DataEntryTabularForm as Form, DataEntryTabularField as Field, DataEntryTabularFieldError as ErrorMsg};
