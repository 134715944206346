import { Feature } from 'ol';
import { Polygon } from 'ol/geom';
import { ItemType } from '../../constants';
import { autoZoneStyle } from './styles';

export function autoZoneItemToFeature(item, store) {
    
    const feature = new Feature({
        id: item.id,
        itemType: ItemType.AUTO_ZONE,
        label: item.name,
        geometry: new Polygon([ item.coords ]),
        // others
        name: item.name,
        color: item.color,
    });
    feature.setId(`${ItemType.AUTO_ZONE}-${item.id}`);
    const style = (feature) => {
        if (feature.get('hidden')) {
            return null;
        }
        const selected = this[store].isSelectedItem(this.getFeatureKey(feature));
        return autoZoneStyle(
            feature,
            undefined,
            selected,
            this.showLabels
        );
    };
    feature.setStyle(style);
    feature.set('featureStyle', style);
    
    return feature;
}
