import React from "react";
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import {ClickAwayListener, Grow, IconButton, MenuItem, MenuList, Popper} from '@material-ui/core';
import {SITE_VIEW_HOST} from '../../../constants/Misc';
import ZebraIcon from '../../icons/ZebraIcon';
import ZebraLogoIcon from '../../icons/ZebraLogoIcon';

import {checkMWEBuildUpdates} from '../../../utils/AutoReload';
import MenuNavItem from './MenuNavItem';
import MobileMenuContainer from './mobileMenu/MobileMenuContainer';
import _ from "lodash"
import {generateComponentId} from "../../../utils/ComponentUtils";
import {RESOURCE_ALERT_SETTING} from "../../../actions";
import Divider from "@material-ui/core/Divider";

class ZHorzNav extends React.Component{

    constructor(props, context){
        super(props,context);
        /**
        *
        * Default State
        * @property {boolean} isDocked=false - Define Left Nav bar is docked to the application.
        */
        this.state = {
            isDocked : true,
            leftMenuList: props.zMenuList.filter((v)=>v.placement==="left"),
            rightMenuList: props.zMenuList.filter((v)=>v.placement==="right"),
            submenuOpen: false,
            submenuAnchor: null,
            submenuChildren: [],
            submenuSide:"left",
            hoveredElms:[],
            isOpen: false,
            openMenu: false
        };

        this.handleNavHover = _.debounce(this.handleNavOpen.bind(this),500);
        this.handleClick = this.handleNavOpen.bind(this);
        this.handleRequestClose = this.handleRequestClose.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    UNSAFE_componentWillReceiveProps(np){
        let userName = this.props.zDisplayName || "<username>";
        const userConfigList  = {
            apiCategory: "UserConfig",
            children: np.zMenuJSON,
            name: userName,
            placement: "right",
            routes: "",
            displayIcon: true
        };
        let rightMenuList = np.zMenuList.filter((v)=>v.placement==="right");
        rightMenuList.push(userConfigList);
        this.setState({
            leftMenuList: np.zMenuList.filter((v)=>v.placement==="left"),
            rightMenuList: rightMenuList
        });
    }

    /**
    * Props Types
    * @property {boolean} zLeftPanOpen - * Required property to define is left nav bar is open or close
    * @property {array} zMenuList - * Required Array to get menu list. Please refer to schema for json or array for menu list.
    * @property {object} zSplitContainer - Node object passed to display at left bar bottom. This will split menu and this element automatically
    */

    static get propTypes () {
        return {
            zLeftPanOpen : PropTypes.bool.isRequired,
            zMenuList : PropTypes.array.isRequired,
            zMenuJSON : PropTypes.any,
            zSplitContainer : PropTypes.node,
            zDisplayName: PropTypes.string,
            windowHeight: PropTypes.any
        };
    }

    handleNavOpen(menuList,anchor,active){

        if(!active){
            return;
        }

        let children = menuList.hasOwnProperty("children") ? menuList.children : []; // sub-menus
        this.setState({
            submenuOpen: (children.length !== 0),
            submenuAnchor:anchor,
            submenuChildren:children,
            submenuSide: (menuList.placement === "left" ? "left" : "right"),
            hoveredItem:menuList
        });

    }
    handleRequestClose(){
        let hElms = this.state.hoveredElms.filter((v)=>v!=="submenu");
        this.setState({
            hoveredElms:hElms,
            submenuOpen:!!hElms.length
        });
    }
    handleHoverOverSubMenu(){
        this.setState({
            submenuOpen:true,
            hoveredElms: this.state.hoveredElms.concat(["submenu"])
        });
    }

    toggle(){
        this.setState({
            openMenu: !this.state.openMenu,
        })
    }

    createSubMenuItem (zUserMenu){
        return zUserMenu.map( menuItem => {
            return (
                <MenuItem primaryText={menuItem.label}
                          style={{width:"100%"}}
                          id={generateComponentId(menuItem.label, null, "button")}
                          key={menuItem.value}
                          disabled={menuItem.isDisabled || false}
                          onClick={menuItem.onClick}
                          value={menuItem.value || menuItem.label}>

                </MenuItem>
            );
        });
    }

    /**
    *
    * @returns {ReactElement}
    */
    render () {
        const {isMobile, isMidSize} = this.props;
        let styleNavLink = {};
        const homeLink = "/landing"
        if(!isMobile){
            styleNavLink = {
                display: "none"
            }
        }
        let userName = this.props.zDisplayName || "<username>";

        return (
            <div>
                <div className={"menuNavWide"}>
                    { isMidSize ?
                        <div className="menu-zebra-icon">
                            <Link to={homeLink} replace>
                                <IconButton className={"icon-menu"} style={{backgroundColor: 'transparent'}}>
                                    <ZebraIcon/>
                                </IconButton>
                            </Link>
                        </div>
                        :
                        <div className="menu-logo">
                            <Link to={homeLink} replace>
                                <IconButton className={"icon-logo-menu"}>
                                    <ZebraLogoIcon/>
                                </IconButton>
                            </Link>
                        </div>
                    }
                    <ClickAwayListener onClickAway={this.handleRequestClose}>
                        <div className={"menu-nav-container"}>
                            <div className={"menuNavWideLeft"}>
                                {this.createMenu(this.state.leftMenuList, "left")}
                            </div>
                            <div className={"menuNavWideSeparator"}/>
                            <div className={"menuNavWideRight"}>
                                {this.createMenu(this.state.rightMenuList, "right")}
                            </div>
                        </div>
                    </ClickAwayListener>
                </div>
                {!isMobile ?
                    this.renderSubMenu() : null
                }
                <MobileMenuContainer
                    menuList={this.props.zMenuList}
                    isMobile={isMobile}
                    styleLink={styleNavLink}
                    toggle={this.toggle}
                    openMenu={this.state.openMenu}
                    userName={userName}
                    userListConfig={this.props.zMenuJSON}
                    onClickSubMenu={checkMWEBuildUpdates}
                />
            </div>

        );
    }

    renderSubMenu(){
        const {windowHeight} = this.props;

        const styles = {
            link:{
                textDecoration:'none',
                color:'#5b6d77',
                width:'100%'

            },
            popover:{
                background:'transparent'
            },
            menu:{
                maxHeight: (windowHeight - 60) + 'px'
            },
            options:{
                color:'black',
                paddingLeft:'5px',
                fontSize: '12px',
                fontWeight: 'bold'
            }
        };


        let submenuItems = this.state.submenuChildren.map((v,i)=>{
            const replaceAux = window.location.hash === "#"+v.routes;;
            const baseUrl = SITE_VIEW_HOST;
            if(v.category==='Configuration'&& v.label=== RESOURCE_ALERT_SETTING){
                return(
                    <MenuItem
                        className={"menu-item"}
                        style={styles.link}
                        id={generateComponentId(v.label, null, "button")}
                        key={v.label}
                        onClick={()=>window.open("https:"+baseUrl+"/business-rules/businessRulesManager")}
                        value={v.label}>
                        {v.label}
                    </MenuItem>);
            }
            if(v.type==="dashboard"){
                switch (v.id) {
                    case "2":
                        return(
                            <MenuItem
                                className={"menu-item"}
                                style={styles.link}
                                id={generateComponentId(v.label, null, "button")}
                                key={v.label}
                                onClick={()=>window.open(baseUrl + "/app-grafana")}
                                value={v.label}>
                                {v.text}
                            </MenuItem>);
                    case "3":
                        return(
                            <MenuItem
                                className={"menu-item"}
                                style={styles.link}
                                id={generateComponentId(v.label, null, "button")}
                                key={v.label}
                                onClick={()=>window.open(baseUrl + "/app-prometheus")}
                                value={v.label}>
                                {v.text}
                            </MenuItem>);
                    case "4":
                        return(
                            <div key={"AlertManager"} style={styles.link}>
                                <Divider />
                            <MenuItem
                                className={"menu-item"}
                                id={generateComponentId(v.label, null, "button")}
                                key={v.label}
                                onClick={()=>window.open(baseUrl + "/app-alertmanager")}
                                value={v.label}>
                                {v.text}
                            </MenuItem>
                            </div>);
                    case "5":
                        return(
                            <MenuItem
                                className={"menu-item"}
                                style={styles.link}
                                id={generateComponentId(v.label, null, "button")}
                                key={v.label}
                                onClick={()=>window.open(baseUrl + "/app-kibana/app/kibana#/home")}
                                value={v.label}>
                                {v.text}
                            </MenuItem>);
                    case "6":
                        return(
                            <MenuItem
                                className={"menu-item"}
                                style={styles.link}
                                id={generateComponentId(v.label, null, "button")}
                                key={v.label}
                                onClick={()=>window.open(baseUrl + "/app-kibana/s/logs/app/kibana#/home")}
                                value={v.label}>
                                {v.text}
                            </MenuItem>);
                    default: break;
                }
            }
            if(v.displayUserMenu) {
                return(
                    <MenuItem
                              className={"menu-item"}
                              style={styles.link}
                              id={generateComponentId(v.label, null, "button")}
                              key={v.value}
                              disabled={v.isDisabled || false}
                              onClick={v.onClick}
                              value={v.value || v.label}>
                        {v.label}
                    </MenuItem>
                )
            } else if(v.externalUrl) {
                return(
                    <MenuItem
                        className={"menu-item"}
                        style={styles.link}
                        id={generateComponentId(v.label, null, "button")}
                        key={v.label}
                        onClick={()=>window.open(v.path)}
                        value={v.label}>
                        {v.label}
                    </MenuItem>);
            }
            else {
                return (
                    <Link to={v.routes} style={styles.link} replace={replaceAux} key={i}>
                        <MenuItem
                            id={generateComponentId(v.name, null, "button")}
                            // primaryText={v.name}
                            className={"menu-item"+(v.enabled?"":" menu-item-disabled")}// TODO: temp change menus disabled
                            onClick={checkMWEBuildUpdates}
                        >
                            {v.name} {v.isShared && v.category === 'Saved'?<i style={{textTransform:'none',paddingLeft:'2px'}}>(shared)</i>:null}
                        </MenuItem>
                    </Link>
                )
            }
        });
        if(this.state.submenuSide==='left')
        {
            return (


                <Popper
                    className={"sub-menu-nav"}
                    open={this.state.submenuOpen}
                    anchorEl={this.state.submenuAnchor}
                    placement={"bottom-start"}
                    //onRequestClose={this.handleRequestClose}
                    // useLayerForClickAway={false}
                    style={styles.popover}
                    key={this.state.hoveredItem && this.state.hoveredItem.name}
                >
                            <Grow
                                in={this.state.submenuOpen}
                                style={{ transformOrigin: '0 0 0' }}
                                {...(this.state.submenuOpen ? { timeout: 350 } : {})}
                            >
                                    <MenuList onMouseLeave={this.handleRequestClose} onClick={this.handleRequestClose} style={styles.menu} className={"sub-menu-item"} open={true}>
                                        {submenuItems}
                                    </MenuList>
                            </Grow>


                </Popper>

            );

        }else {
            return (
                <Popper
                    className={"sub-menu-nav"}
                    open={this.state.submenuOpen}
                    anchorEl={this.state.submenuAnchor}
                    placement={"bottom-end"}
                    style={styles.popover}
                    key={this.state.hoveredItem && this.state.hoveredItem.name}
                >
                        <Grow
                            in={this.state.submenuOpen}
                            style={{ transformOrigin: '0 0 0' }}
                            {...(this.state.submenuOpen ? { timeout: 400 } : {})}
                        >
                            <MenuList onMouseLeave={this.handleRequestClose} onClick={this.handleRequestClose} style={styles.menu} className={"sub-menu-item"} open={true}>
                                {submenuItems}
                            </MenuList>
                        </Grow>

                </Popper>
            );

        }

    }


    /**
    * Create Left Menu Items as per props in ZMenuList.
    *
    * @returns {ReactElement}
    */
    createMenu (list,side){

        const styles = {
            button:{
                base:{
                    margin:0,
                    height:'36px',
                    lineHeight:'36px',
                    borderRadius:0,
                    // background:'transparent',
                    // color:'white',
                },
                left:{
                    // borderRight:'2px solid rgba(0,0,0,0.117647)'
                },
                right:{
                    // borderLeft:'2px solid rgba(0,0,0,0.117647)'
                },
                current:{
                    // background:'transparent',
                    // color:'white'
                },
                currentHover:{
                    // background:'#005470'
                },
                label:{
                    textTransform:'none',
                    fontSize:'1em',
                    fontWeight:'normal'
                }
            }
        };

        return list.map(function (menuList,i){
            const children = menuList.children.filter(menuChildren=>!menuChildren.disabled);
            let newMenuList = {...menuList};
            newMenuList.children = children;
            return (
                <MenuNavItem
                    key={i}
                    menuList={ newMenuList }
                    styles={styles}
                    side={side}
                    onHover={this.handleNavHover}
                    onClick={this.handleClick}
                    submenuOpen={this.state.hoveredItem === menuList && this.state.submenuOpen}
                />
            );
        }.bind(this));
    }


    /**
    * This will display element passed though zSplitContainer props at bottom.
    *
    * @returns {ReactElement}
    */
    createSplitContainer (){
        return (
            <div style={{marginBottom:72}}>
                {this.props.zSplitContainer}
            </div>
        );
    }

}

ZHorzNav.contextTypes = {
    //muiTheme: PropTypes.object.isRequired
};

export default ZHorzNav;
