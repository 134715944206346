import { DialogType, InteractionType, ItemType, SITE_MANAGER_CONFIGURATION_ZONES_SCOPE } from '../../../constants';
import { getOlStoreKey } from '../../../ol/helpers';
import {addInteractionOnMap, backSnapshot, forwardSnapshot, removeInteractionOnMap} from './siteManager';
import { refStore } from '../../../vss/app/actions';
import { openDialog } from './dialog';
import { replaceForm } from './form';

const storeZoneKey = getOlStoreKey(ItemType.ZONE);

export const removeInteractionOnConfigZonesMap = (interaction) => {
    const scope = SITE_MANAGER_CONFIGURATION_ZONES_SCOPE;
    return (dispatch) => {
        switch (interaction) {
            case InteractionType.DRAW:
                refStore.configZonesMap?.removeDraw();
                break;
            case InteractionType.AREA_SELECTOR:
                refStore.configZonesMap?.[storeZoneKey]?.finalizeDragBoxSelection();
                break;
            case InteractionType.TRANSLATE:
                refStore.configZonesMap?.[storeZoneKey]?.finalizeTranslate();
                break;
            case InteractionType.MODIFY:
                refStore.configZonesMap?.[storeZoneKey]?.finalizeModify();
                break;
            case InteractionType.GREY_MODE:
                if (refStore.configZonesMap) {
                    refStore.configZonesMap.greyMode = false;
                }
                break;
            case InteractionType.SHOW_HIDE_LABELS:
                refStore.configZonesMap?.setShowLabels(false);
                break;
            case InteractionType.DRAW_ZONE_GRID:
                refStore.configZonesMap?.removeDrawGrid();
                break;
            case InteractionType.TOGGLE_VISIBILITY_AUTO_ZONES:
                const visibleTypes = [...refStore.configZonesMap?.visibleItemTypes];
                if (visibleTypes.indexOf(ItemType.AUTO_ZONE)>=0) {
                    visibleTypes.splice(visibleTypes.indexOf(ItemType.AUTO_ZONE), 1);
                }
                refStore.configZonesMap?.setVisibleItemTypes(visibleTypes);
                break;
            default:
        }
        dispatch(removeInteractionOnMap(scope, interaction));
    };
};


export const removeAllInteractionsOnConfigZonesMap = () => {
    const scope = SITE_MANAGER_CONFIGURATION_ZONES_SCOPE;
    return (dispatch, getState) => {
        const mapInteractions = getState().siteManager[scope]?.mapInteractions || [];
        for (const interaction of mapInteractions) {
            dispatch(removeInteractionOnConfigZonesMap(interaction));
        }
    };
};

export const removeAllInteractionsOnConfigZonesMapOL = () => {

    refStore.configZonesMap?.removeDraw();

    refStore.configZonesMap?.[storeZoneKey]?.finalizeDragBoxSelection();

    refStore.configZonesMap?.[storeZoneKey]?.finalizeTranslate();

    refStore.configZonesMap?.[storeZoneKey]?.finalizeModify();

    if (refStore.configZonesMap) {
        refStore.configZonesMap.greyMode = false;
    }

    refStore.configZonesMap?.setShowLabels(false);

    refStore.configZonesMap?.removeDrawGrid();

    if(refStore.configZonesMap?.visibleItemTypes!=null) {
        const visibleTypes = [...refStore.configZonesMap?.visibleItemTypes];
        if (visibleTypes.indexOf(ItemType.AUTO_ZONE) >= 0) {
            visibleTypes.splice(visibleTypes.indexOf(ItemType.AUTO_ZONE), 1);
        }

        refStore.configZonesMap?.setVisibleItemTypes(visibleTypes);
    }
};

export const addInteractionOnConfigZonesMap = (interaction) => {
    const scope = SITE_MANAGER_CONFIGURATION_ZONES_SCOPE;
    
    return async (dispatch, getState) => {
        const selectedZoneIds = getState().zoneBuilder.selectedZoneIds;
        // const zones = getState().siteManager[SITE_MANAGER_CONFIGURATION_ZONES_SCOPE].items[ItemType.ZONE];
        const oneAtATimeInteractions = [
            InteractionType.DRAW,
            InteractionType.MODIFY,
            InteractionType.AREA_SELECTOR,
            InteractionType.TRANSLATE,
            InteractionType.DRAW_ZONE_GRID,
        ];
        if (oneAtATimeInteractions.includes(interaction)) {
            for (const interaction of oneAtATimeInteractions) {
                dispatch(removeInteractionOnConfigZonesMap(interaction));
            }
        }
        switch (interaction) {
            case InteractionType.DRAW:
                refStore.configZonesMap?.initializeDraw();
                dispatch(addInteractionOnMap(scope, interaction));
                break;
            case InteractionType.MODIFY:
                refStore.configZonesMap?.[storeZoneKey]?.initializeModify();
                dispatch(addInteractionOnMap(scope, interaction));
                break;
            case InteractionType.AREA_SELECTOR:
                refStore.configZonesMap?.[storeZoneKey]?.initializeDragBoxSelection();
                dispatch(addInteractionOnMap(scope, interaction));
                break;
            case InteractionType.TRANSLATE:
                refStore.configZonesMap?.[storeZoneKey]?.initializeTranslate();
                dispatch(addInteractionOnMap(scope, interaction));
                break;
            case InteractionType.SHOW_HIDE_LABELS:
                const showLabels = refStore.configZonesMap?.showLabels;
                refStore.configZonesMap?.setShowLabels(!showLabels);
                dispatch(addInteractionOnMap(scope, interaction));
                break;
            case InteractionType.GREY_MODE:
                refStore.configZonesMap.greyMode = true;
                dispatch(addInteractionOnMap(scope, interaction));
                break;
            case InteractionType.REMOVE:
                if (selectedZoneIds.length) {
                    let hasChildren = false;
                    // selectedZoneIds.forEach(item => {
                    //     Object.keys(zones).forEach(key => {
                    //         if (zones[key].parentId === item) {
                    //             hasChildren = true;
                    //         }
                    //     });
                    // });
                    dispatch(replaceForm(scope, { hasChildren  }))
                    dispatch(openDialog(scope, DialogType.DELETE_ZONE, '', '', ''));
                }
                break;
            case InteractionType.JOIN_NEIGHBOR:
                refStore.configZonesMap?.joinNeighborZones();
                break;
            case InteractionType.FILL_COLOR:
                refStore.configZonesMap?.fillColorZones();
                break;
            case InteractionType.COPY:
                refStore.configZonesMap?.handleCopyFeatures();
                dispatch(addInteractionOnMap(scope, interaction));
                break;
            case InteractionType.PASTE:
                refStore.configZonesMap?.handlePasteFeatures();
                break;
            case InteractionType.UNDO:
                const backSnapshots = getState().siteManager[scope]?.backSnapshots?.[ItemType.ZONE] || [];
                if (backSnapshots.length >= 2) {
                    const currentState = backSnapshots[backSnapshots.length - 1];
                    const backState = backSnapshots[backSnapshots.length - 2];
                    dispatch(backSnapshot(scope, ItemType.ZONE));
                    refStore.configZonesMap?.observer?.executeEvent('onSaveZoneChanges', {
                        zonesBeforeChanges: currentState,
                        zonesAfterChanges: backState,
                        takeASnapshot: false,
                        event: 'undoZones',
                    });
                }
                break;
            case InteractionType.REDO: {
                const backSnapshots = getState().siteManager[scope]?.backSnapshots?.[ItemType.ZONE] || [];
                const forwardSnapshots = getState().siteManager[scope]?.forwardSnapshots?.[ItemType.ZONE] || [];
                if (forwardSnapshots.length) {
                    const currentState = backSnapshots[backSnapshots.length - 1] || [];
                    const forwardState = forwardSnapshots[0];
                    dispatch(forwardSnapshot(scope, ItemType.ZONE));
                    refStore.configZonesMap?.observer?.executeEvent('onSaveZoneChanges', {
                        zonesBeforeChanges: currentState,
                        zonesAfterChanges: forwardState,
                        takeASnapshot: false,
                        event: 'redoZones',
                    });
                }
                break;
            }
            case InteractionType.DRAW_ZONE_GRID:
                refStore.configZonesMap.initializeDrawZoneGrid();
                dispatch(addInteractionOnMap(scope, interaction));
                break;
            case InteractionType.TOGGLE_VISIBILITY_AUTO_ZONES:
                const visibleTypes = [...refStore.configZonesMap?.visibleItemTypes];

                if (visibleTypes.indexOf(ItemType.AUTO_ZONE)===-1) {
                    visibleTypes.push(ItemType.AUTO_ZONE);
                }
                refStore.configZonesMap?.setVisibleItemTypes(visibleTypes);
                dispatch(addInteractionOnMap(scope, interaction));
                break;
            case InteractionType.DRAW_RANDOM_ZONES:
                refStore.configZonesMap.drawRandomZones(5000);
                dispatch(addInteractionOnMap(scope, interaction));
                break;
            default:
        }
    };
};
