import { ADD_LOAD_COUNTER, CLEAR_LOAD_COUNTER, SUB_LOAD_COUNTER } from '../action-types';

export function loader(state = {}, action) {
  
  const currentState = state[action.scope] || {};
  
  switch (action.type) {
    case ADD_LOAD_COUNTER:
      return {
        ...state,
        [action.scope]: {
          loadCounter: (currentState.loadCounter || 0) + 1,
          message: action.message ?? currentState.message ?? null,
          background: action.background ?? currentState.background ?? false,
          fullscreen: action.fullscreen ?? currentState.fullscreen ?? false
        }
      };
    case SUB_LOAD_COUNTER:
      return {
        ...state,
        [action.scope]: {
          loadCounter: Math.max((currentState.loadCounter || 0) - 1, 0),
          message: action.message ?? currentState.message ?? null,
          background: action.background ?? currentState.background ?? false,
          fullscreen: action.fullscreen ?? currentState.fullscreen ?? false
        }
      };
    case CLEAR_LOAD_COUNTER:
      return {
        ...state,
        [action.scope]: { loadCounter: 0, message: null, background: false, fullscreen: false }
      };
    default:
      return state;
  }
}
