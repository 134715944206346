import React from 'react';
import PropTypes from 'prop-types';
import {Popover} from '@material-ui/core';
import SortedChecklist from '../../util/SortedChecklist';
// import ViewColumnIcon from 'material-ui/svg-icons/action/view-column';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';

import ClassedIconButton from './ClassedIconButton';

class ColumnsDropDown extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            anchorEl:null,
            columns:props.columns
        };

        this.handleTouchTap = this.handleTouchTap.bind(this);
        this.handleRequestClose = this.handleRequestClose.bind(this);
        this.handleMoveColumn = this.handleMoveColumn.bind(this);
        this.handleToggleColumn = this.handleToggleColumn.bind(this);
        this.handleToggleAllColumns = this.handleToggleAllColumns.bind(this);
    }

    static get propTypes(){
        return {
            columns: PropTypes.array,
            moveColumn: PropTypes.func.isRequired,
            toggleColumn: PropTypes.func.isRequired,
            toggleAllColumns: PropTypes.func.isRequired
        };
    }

    handleTouchTap(event){
        // This prevents ghost click.
        event.preventDefault();

        this.setState({
            open: true,
            anchorEl: event.currentTarget,
        });
    }

    handleMoveColumn(dragIndex,hoverIndex){
        this.props.moveColumn(dragIndex,hoverIndex);
    }

    handleToggleColumn(index,checked){
        this.props.toggleColumn(index,checked);
    }

    handleToggleAllColumns(event,checked){
        this.props.toggleAllColumns(checked);
    }

    handleRequestClose(){
        this.setState({
            open: false,
        });
    }
    render(){
        const {columns} = this.props;

        return(
            <div>
                <ClassedIconButton
                    id={"columndropdown_btn"}
                    onClick={this.handleTouchTap}>
                    <ViewColumnIcon />
                </ClassedIconButton>
                <Popover open={this.state.open}
                         anchorEl={this.state.anchorEl}
                         anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                         transformOrigin={{horizontal: 'right', vertical: 'top'}}
                         onClose={this.handleRequestClose}
                         transitionDuration={100}
                >
                    <SortedChecklist
                        selectAllText={"Columns"}
                        className={"columns-drop-down"}
                        data={columns}
                        onMoveItem={this.handleMoveColumn}
                        onCheckItem={this.handleToggleColumn}
                        onCheckSelectAll={this.handleToggleAllColumns}
                        keyAttribute={"name"}
                        labelAttribute={"caption"}
                        checkedAttribute={"enabled"}/>
                </Popover>
            </div>


        );
    }
}

export default ColumnsDropDown;
