import PropTypes from "prop-types";
import React from "react";

function unFocus(document, window) {
        try {
            window.getSelection().removeAllRanges();
            // eslint-disable-next-line no-empty
        } catch (e) {}

}

class DragLine extends React.Component{

    static get propTypes(){
        return {
            onResize: PropTypes.func,
            onDoubleTap: PropTypes.func,
            onResizeEnd: PropTypes.func
        };
    }

    static get defaultProps(){
        return {
            onResizeEnd: ()=>{}
        };
    }

    constructor(){
        super();

        this.state = {
            position:0,
            active: false,
            lastTap: 0
        };

        this.onMouseUp = this.onMouseUp.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onTouchMove = this.onTouchMove.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this);
        this.onTouchStart = this.onTouchStart.bind(this);
        this.onTouchEnd = this.onMouseUp;
        this.onClick = this.onClick.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mouseup', this.onMouseUp);
        document.addEventListener('mousemove', this.onMouseMove);
        document.addEventListener('touchmove', this.onTouchMove);
    }

    componentWillUnmount() {
        document.removeEventListener('mouseup', this.onMouseUp);
        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('touchmove', this.onTouchMove);
    }

    onMouseUp(){
        this.setState({
            active: false
        });
        this.props.onResizeEnd();
    }

    onMouseMove(event){
        const eventWithTouches = Object.assign(
            {},
            event,
            { touches: [{ clientX: event.clientX, clientY: event.clientY }] }
        );
        this.onTouchMove(eventWithTouches);
    }

    onTouchMove(event){
        if (this.state.active) {
            unFocus(document, window);
            if (this.handle) {
                const node = this.handle.parentNode;

                if (node.getBoundingClientRect) {
                    const width = node.getBoundingClientRect().width;
                    const current = event.touches[0].clientX;
                    const size = width;
                    const position = this.state.position;
                    const newPosition = (position - current);

                    this.setState({
                        position: current,
                        resized: true,
                    });

                    let newSize = size - newPosition;

                    if(newSize < 50) newSize = 50;

                    this.props.onResize(newSize);
                    this.setState({
                        draggedSize: newSize,
                    });
                }
            }
        }
    }

    onMouseDown(event){
        const eventWithTouches = Object.assign(
            {},
            event,
            { touches: [{ clientX: event.clientX, clientY: event.clientY }] }
        );
        this.onTouchStart(eventWithTouches);
    }

    onTouchStart(event){
        unFocus(document, window);
        const position =  event.touches[0].clientX;
        this.setState({
            active: true,
            position,
        });
    }

    onClick(){
        const timestamp = (new Date()).getTime();

        if(timestamp - this.state.lastTap < 500){
            this.props.onDoubleTap();
        }
        this.setState({
            lastTap: timestamp
        });
    }

    render(){


        return (
            <div
                ref={(ref)=>{ this.handle = ref; }}
                style={{
                    background:'transparent',
                    width:'10px',
                    position: 'absolute',
                    top:0,
                    bottom:0,
                    right:'-5px',
                    textAlign:'center',
                    zIndex:'1',
                    cursor:'col-resize'
                }}
                onMouseDown={this.onMouseDown}
                onTouchStart={this.onTouchStart}
                onTouchEnd={this.onTouchEnd}
                onClick={this.onClick}
            >
                <div style={{
                    width:'1px',
                    height:'100%',
                    margin:'auto'
                }}
                     className={"resizer-line"}
                ></div>
            </div>
        );
    }
}
export const VerticalDragLine = DragLine;
export default DragLine;
