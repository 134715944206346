import React from 'react';

const TooltipInfoMessage = props => {
    const {tooltipContent} = props;

    const tooltipInfo=(tooltipContent)=>{
        const display = tooltipContent.text ? tooltipContent.text : tooltipContent
        const link = typeof tooltipContent === 'object'? (tooltipContent.link? tooltipContent.link: null):null
        return <div className={'tooltip-info'}>
            <div style={{whiteSpace: 'pre-line', lineHeight:'18px'}}>
                {display}
                {<a style={{display: link ? 'visible' : 'hidden'}} href={link} target="_blank" rel="noreferrer">{link}</a>}
            </div>
        </div>
    }
    return (<>
        {tooltipInfo(tooltipContent)}
    </>);
}

export default TooltipInfoMessage;