import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Checkbox } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useI18n, useOutsideClick, useRecalculateHeightWidth, useReportDispatch, useReportKey } from '../../../../../../hooks';
import { useStyles } from '../../../../../deviceManager/common/accordionItems/utils/utils';
import InputBox from './InputBox';
import MultiSelectIcon from '../../../../../icons/MultiSelectIcon';
import { ALL, ALPHABET, LOADING, MESSAGE_MAX_URL_LENGTH_REACHED_REQUEST, MESSAGE_WILDCARD_NOT_ALLOWED } from '../../../../../../constants';

import './BaseFilter.scss';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const BaseFilter = ({
                        properties = {},
                        selectedValues: _initialSelectedValues = [],
                        recentSearches = [],
                        suggestions: initialSuggestion = [],
                        fetchingOptions,
                        onInputChange,
                        validateOnInputChange = (params)=> ({isValidateInputChange:true}),
                        onChangeSelectValues,
                        onOpenOptions,
                        onInit,
                        isValidState = (values, recentSearches, inputValue, properties) => true,
                        enableTitle = true,
                        enabledFilter = true,
                    }) => {
    
    const initialSelectedValues = Array.isArray(_initialSelectedValues) ? _initialSelectedValues : [];
    const { translate } = useI18n('common');
    const boxContainerRef = useRef(null);
    const suggestionsContainerRef = useRef(null);
    const inputContainerRef = useRef(null);
    const inputRef = useRef(null);
    const varRef = useRef({});
    
    const [inputOrder, setInputOrder] = useState(0);
    const [indexFocus, setIndexFocus] = useState(-1);
    const [openSuggestion, setOpenSuggestion] = useState(false);
    const [inputValue, setInputValue] = useState(null);
    const [selectedValues, setSelectedValues] = useState([]);
    const { height, heightContainer } = useRecalculateHeightWidth(boxContainerRef, inputValue, selectedValues);
    const { reportKey } = useReportKey();
    const { patchReportProperties } = useReportDispatch(reportKey);
    useEffect(() => {
        onInit?.();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (openSuggestion) {
            inputRef.current?.focus();
        }
    }, [inputOrder, openSuggestion]);
    const {
        title = '',
        withAll = false,
        multiSelect = false,
        separatorCharacter = ',',
        allowedCharacters = ALPHABET,
        noAllowedCharacters: initialNoAllowedCharacters = '',
        hasValueWildcard = ({ value }) => value && value.includes('*'),
        renderInputBox,
        inputCaption = '',
        getName = option => option.name,
        getValue = option => option.value,
        enableSelectOnLabel = true,
        extraClassInputContainer = '',
        extraClassInputBox = '',
        enableMoreResultsMessage,
        optionsOffset,
        labelOptions= 'Options',
        classInputBox='input-box',
        enableMoreIcon = false,
        styleInput={},
        enableMaxHeight=true,
        configurationButton = {},
        inputDisabled,
        recentSearchesDisabled,
    } = properties;
    const noAllowedCharacters = initialNoAllowedCharacters || (ALPHABET).split('').filter(c => !allowedCharacters.includes(c)).join('');
    const allOption = withAll? { name: translate(ALL), value: ALL }:{name:'',value:''};
    const suggestions = withAll ? [allOption, ...initialSuggestion] : initialSuggestion;
    const classes = useStyles();
    const isValueOnArray = (array = [], value) => !!array.find(option => getValue(option) === value);
    const isNameOnArray = (array = [], value) => !!array.find(option => getName(option) === value);
    
    const endSearch = () => {
        let finalSelectedValues = [...selectedValues];
        if (inputValue) {
            const option = { name: inputValue, value: inputValue };
            if (multiSelect) {
                if (!isNameOnArray(selectedValues, inputValue)) {
                    finalSelectedValues.splice(inputOrder, 0, option);
                    updateSelectedValues(finalSelectedValues);
                    setInputOrder(prevState => prevState + 1);
                }
            } else {
                finalSelectedValues = [option];
                updateSelectedValues([option]);
            }
        }
        changeSelectedValues(finalSelectedValues);
        closeSuggestions();
    };
    
    useOutsideClick(endSearch, inputContainerRef, suggestionsContainerRef);
    
    const updateSelectedValues = useCallback((newValues) => {
        if (multiSelect) {
            setSelectedValues(newValues);
        } else {
            setSelectedValues([...newValues].splice(-1));
        }
    }, [multiSelect]);
    
    const changeSelectedValues = (newValues) => {
        const copyRecentSearches = [...recentSearches];
        const newValuesFixed = newValues;
        const index = recentSearches.findIndex(recentSearch => (
            JSON.stringify(recentSearch) === JSON.stringify(newValuesFixed)),
        );
        if (index !== -1) {
            copyRecentSearches.splice(index, 1);
        }
        const newRecentSearches = [newValuesFixed, ...copyRecentSearches].slice(0, 5);
        if ((JSON.stringify(initialSelectedValues) !== JSON.stringify(newValuesFixed)) || JSON.stringify(recentSearches) !== JSON.stringify(newRecentSearches)) {
            onChangeSelectValues(newValuesFixed, newRecentSearches);
        }
    };
    
    const closeSuggestions = () => {
        updateInputValue(null, false);
        setOpenSuggestion(false);
        inputRef.current?.blur();
    };
    
    const validOnSuggestionSelected = (suggestion) => { // suggestion: { name: string, value: string }
        let newSelectedValues = [];
        varRef.current.downUp = false;
        if (multiSelect) {
            if (isValueOnArray(selectedValues, suggestion.value)) {
                newSelectedValues = selectedValues.filter(value => getValue(value) !== getValue(suggestion));
            } else {
                newSelectedValues = [...selectedValues];
                newSelectedValues.splice(inputOrder, 0, suggestion);
            }
        } else {
            newSelectedValues = [suggestion];
        }
        return isValidState(newSelectedValues, recentSearches, inputValue, properties);
    };
    
    const onSuggestionSelected = (suggestion) => { // suggestion: { name: string, value: string }
        varRef.current.downUp = false;
        if (multiSelect) {
            if (isValueOnArray(selectedValues, suggestion.value)) {
                updateSelectedValues(selectedValues.filter(value => getValue(value) !== getValue(suggestion)));
                if (inputOrder > selectedValues.length) {
                    setInputOrder(selectedValues.length);
                }
            } else {
                const newSelectedValues = [...selectedValues];
                newSelectedValues.splice(inputOrder, 0, suggestion);
                updateSelectedValues(newSelectedValues);
                setInputOrder(prevState => prevState + 1);
            }
        } else {
            updateSelectedValues([suggestion]);
            closeSuggestions();
            changeSelectedValues([suggestion]);
        }
    };
    
    const onAllSelected = () => {
        varRef.current.downUp = false;
        updateSelectedValues([]);
        setInputOrder(0);
        setInputValue(''); // instead of updateInputValue to not trigger  onInputChange
        changeSelectedValues([]);
        closeSuggestions();
    };
    
    const onRecentSearchSelected = (recentSearch) => {
        updateSelectedValues(recentSearch);
        changeSelectedValues(recentSearch);
        closeSuggestions();
        updateInputValue('', false);
    };
    
    useEffect(() => {
        if (JSON.stringify(initialSelectedValues) !== JSON.stringify(selectedValues)) {
            let initial = Array.isArray(initialSelectedValues) ? initialSelectedValues : [];
            updateSelectedValues([...initial]);
            setInputOrder(initial.length);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialSelectedValues, /*selectedValues,*/ separatorCharacter, updateSelectedValues]);
    useEffect(() => {
        if (indexFocus >= 0) {
            let endFocusedItem = 18;
            let startFocusedItem;
            if (indexFocus < recentSearches.length) {
                endFocusedItem += 20 * indexFocus;
                endFocusedItem += 20;
                startFocusedItem = -20;
            } else {
                endFocusedItem += 18 + 24;
                endFocusedItem += 20 * recentSearches.length;
                endFocusedItem += 24 * (indexFocus - recentSearches.length);
                startFocusedItem = -24;
            }
            startFocusedItem += endFocusedItem;
            const endOffset = suggestionsContainerRef.current?.scrollTop + suggestionsContainerRef.current?.offsetHeight;
            if (endFocusedItem > endOffset && suggestionsContainerRef.current) {
                suggestionsContainerRef.current.scrollTop += endFocusedItem - endOffset;
            }
            const startOffset = suggestionsContainerRef.current?.scrollTop;
            if (startFocusedItem < startOffset && suggestionsContainerRef.current) {
                suggestionsContainerRef.current.scrollTop = startFocusedItem;
            }
        }
    }, [indexFocus, recentSearches.length]);
    
    const updateInputValue = (newSuggest, opening) => {
        const {isValidateInputChange,newValue} = validateOnInputChange?.({newSuggest,inputValue,suggestions});
        if (newSuggest !== inputValue && isValidateInputChange) {
            if(isValidateInputChange){
                setInputValue(newSuggest);
            }else{
                setInputValue(newValue);
            }
            if ((typeof newSuggest === 'string' && openSuggestion) || opening) {
                onInputChange?.({ newSuggest, opening });
                if (opening) {
                    onOpenOptions?.();
                }
            }
        }
        if (opening) {
            setOpenSuggestion(true);
        }
    };
    
    const handleOnClickInputContainer = () => {
        if (!openSuggestion) {
            if (multiSelect) {
                updateInputValue('', true);
            } else {
                updateInputValue(selectedValues[0] ? getName(selectedValues[0]) : '', true);
                setSelectedValues([]);
                setInputOrder(0);
            }
            setIndexFocus(-1);
        }
        inputRef.current?.focus();
    };
    
    const renderDefaultInputBox = (props) => {
        return (
            <InputBox {...props} />
        );
    };
    
    const inputProps = {
        openSuggestion,
        selectedValues,
        inputOrder,
        updateInputValue,
        setInputOrder,
        inputValue,
        updateSelectedValues,
        inputRef,
        varRef,
        properties: {
            separatorCharacter,
            noAllowedCharacters,
            multiSelect,
            withAll,
            hasValueWildcard,
            getName,
            isValidState,
            inputDisabled,
        },
        setIndexFocus,
        indexFocus,
        recentSearches,
        suggestions,
        onRecentSearchSelected,
        onSuggestionSelected,
        onAllSelected,
        endSearch,
        allOption,
    };
    const message_more_results = `Showing the first ${optionsOffset} results, you can continue typing to narrow down the results`;
    const getOffset  = () =>{
        return enableMoreResultsMessage?optionsOffset + 1: optionsOffset;
    }
    const styleMoreIcon = {padding:'6px 0px 0px 0px',alignItems:'flex-end'};
    const {enableButton}  = configurationButton;
    const styleSuggestionsContainer= enableMaxHeight? { top: (height - 1) + 'px', maxHeight: extraClassInputBox!=='' ?heightContainer + 'px':''}:{ top: (height - 1) + 'px'};
    return (
        <div className="base-filter">
            <div className="box-container" ref={boxContainerRef}>
                {enableTitle && (
                    <div className="title-container">
                        <div className={'title' + (initialSelectedValues.length !== 0 && enableSelectOnLabel  ? ' selected' : '')}>
                            {title}
                        </div>
                        {enableButton && configurationButton?.renderComponent()}
                        {multiSelect && (
                            <div className="title-icon">
                                <div title={translate('Multiple selection enabled')}><MultiSelectIcon /></div>
                            </div>
                        )}
                    </div>
                )}
                {enabledFilter ? (
                    <div className={`input-container ${extraClassInputContainer}`} onClick={handleOnClickInputContainer} ref={inputContainerRef}>
                        <div
                            className={classInputBox + (multiSelect ? ' multiple-values' : '') + (openSuggestion ? ' opened-suggestion' : '') + ` ${extraClassInputBox}`}>
                            {inputCaption && <div className="prev-label">{inputCaption}</div>}
                            {renderInputBox ? renderInputBox(inputProps, renderDefaultInputBox) : renderDefaultInputBox(inputProps)}
                            { enableMoreIcon && <ExpandMoreIcon style={styleMoreIcon}/>}
                        </div>
                    </div>
                ) : (
                    <div className={`input-container ${extraClassInputContainer}`}>
                        <div
                            className={classInputBox + (multiSelect ? ' multiple-values' : '') + (openSuggestion ? ' opened-suggestion' : '') + ` ${extraClassInputBox}`}>
                            {inputCaption && <div className="prev-label">{inputCaption}</div>}
                            {renderInputBox ? renderInputBox(inputProps, renderDefaultInputBox) : renderDefaultInputBox(inputProps)}
                            {enableMoreIcon && <ExpandMoreIcon style={styleMoreIcon}/>}
                        </div>
                    </div>
                )}
            </div>
            {selectedValues.length > 1 && selectedValues.some(hasValueWildcard) && (
                <div className="alert-message-wildcard">* {MESSAGE_WILDCARD_NOT_ALLOWED}</div>
            )}
            {openSuggestion && enabledFilter && (
                <div className={`suggestions-container ${extraClassInputBox!=='' ? 'suggestions-full-container': ''}`} 
                    style={styleSuggestionsContainer} ref={suggestionsContainerRef}>
                    <div>
                        {!recentSearchesDisabled && (
                            <>
                                {!!recentSearches.length && <label>{translate('Recent Searches')}</label>}
                                <div className="recent-searches">
                                    {recentSearches.map((recentSearch, index) => (
                                        <div
                                            className={'recent-search-item' + (indexFocus === index ? ' focused' : '')}
                                            onClick={() => onRecentSearchSelected(recentSearch)}
                                            onMouseOver={() => setIndexFocus(index)}
                                            key={JSON.stringify(recentSearch) + '___' + index}
                                        >
                                            <div>
                                                {recentSearch.length === 0
                                                    ? allOption.name
                                                    : recentSearch.map(option => getName(option)).join(
                                                        separatorCharacter.charAt(0))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                        <label>{translate(labelOptions)}</label>
                        <div className="suggestions">
                            {enabledFilter && suggestions.slice(0,getOffset()).map((suggestion, index) => {
                                const checked = isValueOnArray(selectedValues, suggestion.value);
                                const className = `suggestion-item${(indexFocus === index + recentSearches.length ? ' focused' : '')}${(false) ? ' disabled' : ''}`;
                                const handleClick = (event) => {
                                    let disabled;
                                    if (withAll && !index) { // All Selected
                                        disabled = !isValidState([], recentSearches, inputValue, properties) && !checked;
                                        if (!disabled) {
                                            onAllSelected();
                                        }
                                    } else {
                                        disabled = !validOnSuggestionSelected(suggestion) && !checked;
                                        if (!disabled) {
                                            onSuggestionSelected(suggestion);
                                        }
                                    }
                                    if (disabled) {
                                        event.preventDefault();
                                        if (!checked) {
                                            patchReportProperties({
                                                dialog: {
                                                    open: true,
                                                    title: 'Error',
                                                    content: MESSAGE_MAX_URL_LENGTH_REACHED_REQUEST,
                                                },
                                            });
                                        }
                                    }
                                };
                                return (
                                    <div
                                        className={className}
                                        onClick={handleClick}
                                        onMouseEnter={() => setIndexFocus(index + recentSearches.length)}
                                        key={JSON.stringify(suggestion) + '___' + index}
                                        style={styleInput}
                                    >
                                        <div>
                                            {multiSelect && suggestion && (withAll ? !!index : true) && (
                                                <Checkbox
                                                    checked={checked}
                                                    style={{ backgroundColor: 'transparent', margin: '0 -0.08em', color: '#007CB0 !important' }}
                                                    classes={{ checked: classes.checked, root: classes.rootChecked }}
                                                />
                                            )}
                                            {getName(suggestion)}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="circular-loader">
                            {fetchingOptions === LOADING && <CircularProgress variant="indeterminate" thickness={2.5} size={18} />}
                            {/*{fetchingOptions !== LOADING && suggestions.length === 0 && 'No data available'}*/}
                        </div>
                        {
                            enableMoreResultsMessage && enabledFilter && suggestions.length - 2 >= optionsOffset
                            && <div className="message-after-search">{message_more_results}</div>
                        }
                    </div>
                </div>
            )}
        </div>
    );
};

export default BaseFilter;
