import {
    CLOSE_ERROR_DIALOG_ON_BACKGROUND,
    DISPLAY_ERROR_DIALOG_ON_BACKGROUND
} from '../constants/ActionTypes';
import {I18n} from "react-redux-i18n";

const initialState = {
    open:false,
    message:"",
    details: "",
    title:""
};
const translate = (key) => I18n.t('common.' + key);
export function errorDialogOnBackground(state = initialState, action){
    switch(action.type){
        case DISPLAY_ERROR_DIALOG_ON_BACKGROUND:
            return {
                open:true,
                title: action.title || translate("Error"),
                message: action.message || translate("Unspecified error"),
                details: action.details,
            };
        case CLOSE_ERROR_DIALOG_ON_BACKGROUND:
            return {
                open:false,
                message:"",
                title: '',
            };
        default:
            return state;
    }


}
