import React from 'react';
import {generateComponentId} from "../../../../utils/ComponentUtils";
import Checkbox from '@material-ui/core/Checkbox';
import LookupDialog from "../../report/filter/LookupDialog";
import ZoomInIcon from "@material-ui/icons/AddBox";
import RemoveIcon from "@material-ui/icons/Clear";

class TagListComponent extends React.Component {

    constructor(props){
        super(props);

        this.state={
            lookupOpen:false
        };

        this.handleAddElement=this.handleAddElement.bind(this);
        this.requestFilterValues=this.requestFilterValues.bind(this);
    }

    shouldComponentUpdate(nextProps,nextState){

        if(this.props.tags!==nextProps.tags
            ||this.props.tagsColorObj!==nextProps.tagsColorObj
            ||this.props.showTagList!==nextProps.showTagList
            ||this.props.showCheckBox!==nextProps.showCheckBox
            ||this.state.lookupOpen!==nextState.lookupOpen
        ){
            return true;
        }
        return false;
    }

    handleAddElement(){
        this.setState({lookupOpen:true});

    }

    requestFilterValues(value) {

        const {filterId}=this.props;
        return this.props.requestFilterValues(filterId, value);
    }

    handleSelectValue(value,optionSelected){
        if (value === '%' || value === 'All') {
            return;
        }
        if(optionSelected===true) {
            const {handleAddTag} = this.props;
            handleAddTag(value);
        }
    }

    handleRemoveValue(value,x){
        const {handleRemoveTag} = this.props;
        handleRemoveTag(value);
    }


    render(){

        const {lookupOpen} = this.state;
        const {showTagList,showCheckBox} = this.props;

        if(showTagList!==true&&lookupOpen!==true){
            return null;
        }

        const {tags, handleSelectTag,handleSelectAllTags, tagsColorObj,elementIdLabel} = this.props;

        const addItemEnabled=(tags!=null&&Object.keys(tags).length<10);

        let checkSelected = [];
        for(let key in tags){
            // checkSelected = checkSelected.concat(Object.values(tags[key]));
            checkSelected = checkSelected.concat(Object.keys(tags).map((e) => {return tags[key][e]}));
        }

        let selectAllChecked = !Object.keys(tags).some(key => !tags[key].selected);
        const sortedTags = Object.keys(tags).sort((a,b) => (tags[a].order > tags[b].order) ? 1 : -1);

        let items = sortedTags.map(key=>{

            let tagColor = (tagsColorObj&&!!tagsColorObj[key]) ? tagsColorObj[key] : "transparent";

            return <div key={"checkbox_" + key} className={"tag-info"}>

                <div className={"tag-info-check"} onClick={() => handleSelectTag(key)}>

                    {(showCheckBox!==false)?<Checkbox className={"tag-info-checkbox"} id={generateComponentId("check_" + key, null, "checkbox")}
                              checked={tags[key].selected}
                              style={{color: tags[key].selected?'#43a047':'inherit'}}
                    />:null}
                </div>
                <span className={"tag-info-text"} onClick={() => handleSelectTag(key)}>{tags[key].displayName?tags[key].displayName:key}</span>
                <div className={"tag-info-color"}
                     onClick={() => handleSelectTag(key)}>
                    <div style={{backgroundColor: tagColor}}></div>
                </div>

                <div className={"tag-list-item-remove"} >
                    <div onClick={() => this.handleRemoveValue(key)}>
                    <RemoveIcon/>
                    </div>
                </div>

            </div>;
        });

        const value="All";
        const loadingValues="";
        let styleReportMap="";
        if(showCheckBox===false){
            styleReportMap=" tag-list-container-report"
        }

        return(
            <div className={"tag-list-container"+styleReportMap} ref={"tagList"}>
                <div className={"tag-list-container-wrapper"}>

                    <div className={"tag-info-container"}>
                        <div className={"tag-info"}>

                            <div className={"tag-info-check"} onClick={handleSelectAllTags}>
                                
                                {(showCheckBox!==false)?<Checkbox className={"tag-info-checkbox"} id={generateComponentId("primarycheck", null, "checkbox")} checked={selectAllChecked} style={{color: selectAllChecked?'#43a047':'inherit'}} />:null}
                            </div>
                            <span className={"tag-info-text tag-info-text-header"} onClick={handleSelectAllTags}>{elementIdLabel}</span>
                        </div>
                        <div className={"tag-info"}>
                            <div className={"tag-info-check tag-info-divider"}></div>
                            <div className={"tag-info-text tag-info-divider"}></div>
                            <div className={"tag-info-color tag-info-divider"}></div>
                            <div className={"tag-list-item-remove tag-info-divider"}></div>
                        </div>
                        {items}
                    </div>


                </div>
                {(showCheckBox!==false)?(addItemEnabled===true)?<div className={"tag-list-add-item"} onClick={this.handleAddElement}><ZoomInIcon /><span> Add a {elementIdLabel}</span>
                                         </div>:<div className={"tag-list-add-item"}>
                                                <span> You reached the limit of items</span>
                                         </div>:null
                }
                <LookupDialog
                    open={!!lookupOpen}
                    onRequestClose={()=>{this.setState({lookupOpen:false});}}
                    onClose={()=>{this.setState({lookupOpen:false});}}
                    queryForValues={(value)=>{
                        return this.requestFilterValues(value);
                    }}
                    onSave={(value,optionSelected)=>{this.handleSelectValue(value,optionSelected);}}
                    value={value}
                    loadingValues={loadingValues}
                />
            </div>
        );
    }
}

export default TagListComponent;