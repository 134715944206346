import { displayErrorDialog, showErrorInLayoutContent/*, expireSession*/} from '../general';
import Promise from 'promise';
import axios from 'axios';
import {
    ACCESS_HOST,
    ACL_HOST,
    ICS_HOST,
    LOC_CONFIG_HOST,
    IMPORT_SERVICE_HOST,
    REPORT_SERVICE_HOST,
    SITE_VIEW_HOST,
    // ZONE_SERVICE_HOST,
    DEVICE_MANAGEMENT_SERVICE_HOST,
    RESOURCE_SERVICE_HOST,
    SEARCH_SERVICE_HOST,
    ALERT_SERVICE_HOST,
    DIRECTION_SERVICE_HOST,
    FILE_SERVICE_HOST
} from '../../constants/Misc';
import { updateProcessing } from '../';

export function displayErrorFromAxios(dispatch,err,{ignore403=false}={}){
    var message = getErrorMessageFromAxios(err);

    // case user do not have permission for some features (some sites , or resource types);
    if (err.response.status === 401 && err.response && err.response.data) {
        dispatch(showErrorInLayoutContent({
            message:message,
            sessionHasExpired: false
        }));
        return Promise.reject(err);
    }
    else if(!ignore403 && sessionHasExpired(err)){
        //dispatch(expireSession());
        dispatch(showErrorInLayoutContent({message:"You do not have permission to view this page.",sessionHasExpired:!ignore403 && sessionHasExpired(err)}));
        return Promise.reject(err);
    }

    if(typeof message === "object"){
        console.log("UNKNOWN SERVER ERROR --", message); // eslint-disable-line
        if(message.error){
            message = message.error;
        }else{
            message = "Unknown Server Error.  See console for details.";
        }
    }

    dispatch(displayErrorDialog({
        message:message
    }));

    dispatch(updateProcessing(false));

    return Promise.reject(err);
}
export const displayAllErrorFromAxios = (err,{ignore403=false}={}) =>{
    return (dispatch) =>{
        let message = getErrorMessageFromAxios(err);
        if(typeof message === "object"){
            console.log("UNKNOWN SERVER ERROR --", message); // eslint-disable-line
            if(message.message){
                message = message.message;
            }else{
                message = "Unknown Server Error.  See console for details.";
            }
        }
        dispatch(displayErrorDialog({message:message}));
    }
}
export function sessionHasExpired(err){
    if(err && err.response){
        switch(err.response.status){
            case 401:
            case 403:
                return true;
            default:
                return false;
        }
    }

    return false;
}

export function handleErrorWithCode(err,code,handler){
    if(err.response.status === code){
        return handler(err);
    }
    return Promise.reject(err);
}

export function getErrorMessageFromAxios(err){
    if(err.response && err.response.data){
        // checking data
        if(err.response.data.status?.error){
            return err.response.data.status.error;
        }else if(err.response.data.message){
            return err.response.data.message;
        }else{
            return err.response.data;
        }
    }else if(err.message){
        return err.message;
    }else{
        return 'Unknown Server Error';
    }
    // return (err.response && err.response.data) || err.message || "Unknown Server Error";
}

/*
███████ ███████ ██████  ██    ██ ██  ██████ ███████ ███████
██      ██      ██   ██ ██    ██ ██ ██      ██      ██
███████ █████   ██████  ██    ██ ██ ██      █████   ███████
     ██ ██      ██   ██  ██  ██  ██ ██      ██           ██
███████ ███████ ██   ██   ████   ██  ██████ ███████ ███████
*/


const reportServiceInstance = (username,token)=>{
    return axios.create({
        baseURL: REPORT_SERVICE_HOST,
        timeout:300000, // 5 minutes in milliseconds
        headers:{
            'X-Consumer-Username':username || "",
            'Authorization':'Bearer '+token,
            'Cache-Control':'no-cache, no-store, must-revalidate',
            'Pragma':'no-cache',
            'Expires': 0
        }
    });
};

const siteViewServiceInstance = (username,token)=>{
    return axios.create({
        baseURL: SITE_VIEW_HOST,
        timeout:300000,
        headers:{
            'X-Consumer-Username':username || "",
            'Authorization':'Bearer '+token,
            'Cache-Control':'no-cache, no-store, must-revalidate',
            'Pragma':'no-cache',
            'Expires': 0
        }
    });
};

const locationConfigServiceInstance = (username,token)=>{
    return axios.create({
        baseURL: LOC_CONFIG_HOST,
        timeout:300000,
        headers:{
            'X-Consumer-Username':username || "",
            'Authorization':'Bearer '+token,
            'Cache-Control':'no-cache, no-store, must-revalidate',
            'Pragma':'no-cache',
            'Expires': 0
        }
    });
};

function createStandardAxiosConnection(baseUrl,{ timeout, headers={} }={}){
    return (token)=>{
        const config = {
            baseURL: baseUrl,
            headers:{
                'Authorization':'Bearer '+token,
                /*'Cache-Control':'no-cache, no-store, must-revalidate',
                'Pragma':'no-cache',*/
                /*'Expires': 0,*/
                ...headers
            }
        };

        if(timeout){
            config.timeout = timeout;
        }
        return axios.create(config);
    };
}

function createStandardAxiosConnectionNoCache(baseUrl,{ timeout, headers={} }={}){
    return (token)=>{
        const config = {
            baseURL: baseUrl,
            headers:{
                'Authorization':'Bearer '+token,
                'Cache-Control':'no-cache, no-store, must-revalidate',
                'Pragma':'no-cache',
                'Expires': 0,
                ...headers
            }
        };

        if(timeout){
            config.timeout = timeout;
        }
        return axios.create(config);
    };
}

const authServiceInstance = createStandardAxiosConnection(ACCESS_HOST);
const icsInstance = createStandardAxiosConnection(ICS_HOST,{timeout: 60000});
const accessControlServiceInstance = createStandardAxiosConnection(ACL_HOST);
const importServiceInstance = createStandardAxiosConnection(IMPORT_SERVICE_HOST);
const deviceManagementServiceInstance = createStandardAxiosConnectionNoCache(DEVICE_MANAGEMENT_SERVICE_HOST, {timeout: 60000});
const resourceServiceInstance = createStandardAxiosConnectionNoCache(RESOURCE_SERVICE_HOST);
const searchServiceInstance = createStandardAxiosConnection(SEARCH_SERVICE_HOST);
const alertServiceInstance = createStandardAxiosConnection(ALERT_SERVICE_HOST);
const directionServiceInstance = createStandardAxiosConnection(DIRECTION_SERVICE_HOST);
const fileServiceInstance = createStandardAxiosConnection(FILE_SERVICE_HOST);

const lcsMapInstance = createStandardAxiosConnection(LOC_CONFIG_HOST,{
    // timeout:180000 // 3 minutes
    timeout:  45 * 60000 // 3 minutes
});


let REPORT_SERVICE = reportServiceInstance(),
    AUTH_SERVICE = authServiceInstance(),
    ICS = icsInstance(),
    LCS_MAP = lcsMapInstance(),
    ACL_SERVICE = accessControlServiceInstance(),
    LOC_CONFIG_SERVICE = locationConfigServiceInstance(),
    IMPORT_SERVICE=importServiceInstance(),
    SITE_VIEW_SERVICE = siteViewServiceInstance(),
    DEVICE_MANAGEMENT_SERVICE = deviceManagementServiceInstance(),
    RESOURCE_SERVICE = resourceServiceInstance(),
    SEARCH_SERVICE = searchServiceInstance(),
    ALERT_SERVICE = alertServiceInstance(),
    DIRECTION_SERVICE = directionServiceInstance(),
    FILE_SERVICE = fileServiceInstance();

const ReportService = {
    instance:()=>{
        return REPORT_SERVICE;
    },
    renew:(username,token)=>{
        REPORT_SERVICE = reportServiceInstance(username,token);
    }
};

const AuthService = {
    instance:()=>{
        return AUTH_SERVICE;
    },
    renew:(token)=>{
        AUTH_SERVICE = authServiceInstance(token);
    }
};

const ICService = {
    instance:()=>{
        return ICS;
    },
    renew:(token)=>{
        ICS = icsInstance(token);
    }
};

const AccessControlService = {
    instance:()=>{
        return ACL_SERVICE;
    },
    renew:(token)=>{
        ACL_SERVICE = accessControlServiceInstance(token);
    }
};

const LCSMapService = {
    instance: ()=>{
        return LCS_MAP;
    },
    renew:(token)=>{
        LCS_MAP = lcsMapInstance(token);
    }
};

const LocationConfigService = {
    instance:()=>{
        return LOC_CONFIG_SERVICE;
    },
    renew:(username, token)=>{
        LOC_CONFIG_SERVICE = locationConfigServiceInstance(username, token);
    }
};

const ImportService = {
    instance:()=>{
        return IMPORT_SERVICE;
    },
    renew:(token)=>{
        IMPORT_SERVICE = importServiceInstance(token);
    }
};

const SiteViewService = {
    instance:()=>{
        return SITE_VIEW_SERVICE;
    },
    renew:(username,token)=>{
        SITE_VIEW_SERVICE = siteViewServiceInstance(username,token);
    }
};

const DeviceManagementService = {
    instance:()=>{
        return DEVICE_MANAGEMENT_SERVICE;
    },
    renew:(token)=>{
        DEVICE_MANAGEMENT_SERVICE = deviceManagementServiceInstance(token);
    }
};

const ResourceService = {
    instance:()=>{
        return RESOURCE_SERVICE;
    },
    renew:(token)=>{
        RESOURCE_SERVICE = resourceServiceInstance(token);
    }
};

const SearchService = {
    instance:()=>{
        return SEARCH_SERVICE;
    },
    renew:(token)=>{
        SEARCH_SERVICE = searchServiceInstance(token);
    }
};

const AlertService = {
    instance: () => {
        return ALERT_SERVICE;
    },
    renew: token => {
        ALERT_SERVICE = alertServiceInstance(token);
    }
}

const DirectionService = {
    instance: () => {
        return DIRECTION_SERVICE;
    },
    renew: token => {
        DIRECTION_SERVICE = directionServiceInstance(token);
    }
}

const FileService = {
    instance: () => {
        return FILE_SERVICE;
    },
    renew: token => {
        FILE_SERVICE = fileServiceInstance(token);
    }
}

let TOKEN = null;

const getToken = ()=>{
    return TOKEN;
};


function renewServices({USERNAME,JWT_TOKEN}){
    TOKEN = JWT_TOKEN;
    ReportService.renew(USERNAME,JWT_TOKEN);
    AuthService.renew(JWT_TOKEN);
    ICService.renew(JWT_TOKEN);
    LCSMapService.renew(JWT_TOKEN);
    LocationConfigService.renew(USERNAME,JWT_TOKEN);
    AccessControlService.renew(JWT_TOKEN);
    //ZoneService.renew(JWT_TOKEN);
    ImportService.renew(JWT_TOKEN);
    SiteViewService.renew(USERNAME,JWT_TOKEN);
    DeviceManagementService.renew(JWT_TOKEN);
    ResourceService.renew(JWT_TOKEN);
    SearchService.renew(JWT_TOKEN);
    AlertService.renew(JWT_TOKEN);
    DirectionService.renew(JWT_TOKEN);
    FileService.renew(JWT_TOKEN);
}

export {
    reportServiceInstance, // used for requesting the menu... (ReportService isn't updated in time.)
    ReportService,
    AuthService,
    ICService,
    AccessControlService,
    LCSMapService,
    LocationConfigService,
    ImportService,
    SiteViewService,
    DeviceManagementService,
    ResourceService,
    SearchService,
    AlertService,
    DirectionService,
    FileService,
    renewServices,
    getToken
};
