import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/Icon';
import Maximizer from './Maximizer';
import { DialogActions, DialogTitle, Paper } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {DialogContent} from '@material-ui/core/es';
import Fade from "@material-ui/core/Fade/Fade";
import { I18n } from 'react-redux-i18n';
import Draggable from 'react-draggable';
//import { connect } from 'react-redux';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} timeout={100}/>;
});

const DraggableComponent = props => {
    return (
        <Draggable handle="#dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    )
}

class StyledDialog extends React.Component{

    static get propTypes(){
        return {
            children: PropTypes.node,
            fullHeight: PropTypes.bool,
            title: PropTypes.any,
            actions: PropTypes.any,
            bodyStyle: PropTypes.object,
            contentStyle: PropTypes.object,
            disableTinyX: PropTypes.bool,
            className: PropTypes.string,
            fullWidth: PropTypes.bool,
            onClose: PropTypes.func,
            headerStyles: PropTypes.object,
            isDraggable: PropTypes.bool, // make the dialog draggable
            hideBackdrop: PropTypes.bool,
        };
    }


    static get defaultProps() {
        return {
            fullWidth: false,
            headerStyles: {}
        };
    }

    renderCloseButton(){
        return (
            <div style={{position:'absolute',top:'1em',right:'1em',color:'#777'}}>
                <IconButton aria-label="close"
                    onClick={this.props.onClose}
                    style={{cursor:'pointer',position:'relative',zIndex:'100'}}
                    >
                    <CloseIcon />
                </IconButton>
            </div>
        );
    }

    render(){

        const { fullHeight,
                disableTinyX,
                bodyStyle,
                title,
                children, 
                actions, 
                contentStyle,
                open,
                onClose,
                style,
                className,
                isDraggable,
                fullWidth,
                headerStyles,
                hideBackdrop,
        } = this.props;
        
        let maxWidth = false;
        if (this.props.maxWidth || this.props.maxWidth === false) maxWidth = this.props.maxWidth;
        const content = (fullHeight) ? (
            <Maximizer style={{padding:'0 24px 24px',boxSizing:'border-box', overflowY: 'auto',maxWidth:'600px', ...bodyStyle}} hasTitle={!!title} hasActions={!!React.Children.count(actions)}>
                {children}
            </Maximizer>
        ) : children;

        const PaperComponent = isDraggable ? DraggableComponent : Paper;
        const hideBackdropProps = {
            disableEnforceFocus: true,
            BackdropProps:{ style: {backgroundColor: 'transparent'}}
        }
        return (
            <Dialog
                open={open}
                onClose={onClose}
                maxWidth={maxWidth}
                TransitionComponent={Transition}
                style={{ ...style, ...(hideBackdrop ? { pointerEvents: 'none' } : {}) }}
                PaperProps={{ style: { overflowY: 'visible', pointerEvents: 'auto', } }}
                PaperComponent={PaperComponent}
                className={className}
                fullWidth={fullWidth}
                {...(hideBackdrop ? hideBackdropProps: {})}
            >
                {disableTinyX ? null : this.renderCloseButton()}
                <DialogTitle 
                    id="dialog-title"
                    style={{ 
                        paddingRight: 50, 
                        cursor: isDraggable ? "move" : "default",
                        ...headerStyles
                    }}
                >
                    <div style={{ fontWeight: '300', fontSize: '0.9em' }}>
                        {title}
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div style={{ color: '#0009', minHeight: 40, ...contentStyle }}>
                        {content}
                    </div>
                </DialogContent>
                {actions && <DialogActions className='dialog-buttons'>
                    {actions}
                </DialogActions>}
            </Dialog>
        );
    }
}

export default StyledDialog;



class DefaultSaveButton extends React.Component{
    static get propTypes(){
        return {
            disabled: PropTypes.bool
        };
    }

    render(){
        const {disabled} = this.props;
        const translate = (key) => I18n.t('common.' + key);
        return (
            <Button
                label={translate('Save')}
                style={{
                    color: (disabled) ? "#aaa" : '#00779F'
                }}
                {...this.props}
            >{translate(this.props.label?this.props.label:'Save')}</Button>
        );
    }
}


class DefaultCancelButton extends React.Component{
    render(){
        const translate = (key) => I18n.t('common.' + key);
        return (
            <Button label={translate('Cancel')} style={{float:'left'}} {...this.props}>{translate('Cancel')}</Button>
        );
    }
}
/*
const SaveButton = connect(state => ({ i18n: state.i18n }))(DefaultSaveButton);
const CancelButton = connect(state => ({ i18n: state.i18n }))(DefaultCancelButton);
*/
export {
  /*SaveButton,
  CancelButton*/
    DefaultSaveButton as SaveButton,
    DefaultCancelButton as CancelButton
};
