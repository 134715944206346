import React from 'react';
import { useSiteManager, useSiteManagerDispatch } from '../../hooks';
import { getSafeValueOfPath } from '../../vss/app/utils';
import { InputNumber, InputType, SelectInput, TextInput } from '../../vss/app/views/onePlatform/LayoutContent/FormLoader/FieldsForms/Inputs';
import InputNumberValidated from "../../vss/app/views/onePlatform/LayoutContent/FormLoader/FieldsForms/Inputs/InputNumberValidated";

export const FormFields = ({ scope, fields, formError }) => {
    const { updateFieldsForm } = useSiteManagerDispatch(scope);
    const handleChangeField = (path, value, emptyValues) => updateFieldsForm({ paths: [ [ path, value, emptyValues ] ] });
    const { form } = useSiteManager(scope);
    const getValueField = (path) => getSafeValueOfPath(form, path).value;
    
    const handleChangeFieldCoordinatesZones = async (path, value, emptyValues) => {
        const indexSplited = path.split('.');
        updateFieldsForm({ paths: [ [ path, value, emptyValues ] ] });
        if (indexSplited[2] === '0') {
            indexSplited[2] = form?.coordinates.length - 1;
            const newPath = indexSplited.join('.');
            updateFieldsForm({ paths: [ [ newPath, value, emptyValues ] ] });
        }
    };
    
    const handleRenderLocation = (fields) => {
        const fieldsLocation = Object.keys(fields).map((item, index) => {
            const key = fields[item].key || fields[item].path + index;
            const pathField = fields[item].path.replace('index', index);
            return (
                    <InputNumberValidated
                        key={key}
                        label={fields[item].label}
                        typeInput={InputType.DECIMAL}
                        placeholder={fields[item].placeHolder}
                        className="location-fields-container"
                        classNameLabel={fields[item].classNameLabel || ''}
                        classNameInput={fields[item].className || ''}
                        disabled={fields[item].disabled}
                        name={fields[item].name}
                        onChange={(value) => handleChangeFieldCoordinatesZones(fields[item].path, value, [ NaN, null, undefined ])}
                        value={getValueField(fields[item].path)}
                        fixedDecimals={2}
                        errorEl={"container"}
                        errorPlacement={"bottom"}
                        forceDisplayError={!!formError?.[pathField] && formError?.[pathField] !== '' }
                        forceMessageError={formError?.[pathField]}
                    />
            );
        });
        return (
            <div className="antenna-location-container">
                {fieldsLocation}
            </div>
        );
    };
    
    const handleRenderCoordinates = fields => {
        return fields?.map((coor, index) => <div key={index} style={{ padding: '3px 10px 0 3px' }}>
            <label className="antenna-location-label">{coor.label} *</label>
            {handleRenderLocation(coor.fields)}
        </div>);
    };
    
    return (
        <div style={{ width: '100%' }}>
            <div style={{ margin: 'auto 10px' }}>
                {
                    Object.keys(fields)?.map(item => {
                        const key = fields[item].key;
                        switch (fields[item].type) {
                            case 'text':
                                return (
                                    <div key={key + '_div'}>
                                        <TextInput
                                            key={key}
                                            properties={{
                                                name: fields[item].label,
                                                validations: fields[item].validations,
                                                label: fields[item].label,
                                                placeholder: fields[item].placeHolder
                                            }}
                                            className={fields[item].className || ''}
                                            classNameInput={fields[item].className || ''}
                                            disabled={fields[item].disabled}
                                            onChange={(value) => handleChangeField(fields[item].path, value, [ '', null, undefined ])}
                                            value={getValueField(fields[item].path)}
                                            forceDisplayError={formError && !!formError[fields[item].path] && formError[fields[item].path] !== ''}
                                            forceMessageError={formError?.[fields[item]?.path]}
                                        />
                                        {/*<span key={key + '_span'} className="antenna-fields-error">{formError && formError[fields[item].path]}</span>*/}
                                    </div>
                                );
                            case 'number':
                                return (
                                    <InputNumber
                                        key={key}
                                        placeholder={fields[item].placeHolder}
                                        className={fields[item].className || ''}
                                        disabled={fields[item].disabled}
                                        onChange={(value) => handleChangeField(fields[item].path, value, [ NaN, null, undefined ])}
                                        value={getValueField(fields[item].path)}
                                    />
                                );
                            case 'select':
                                return (
                                    <div key={key + '_div'}>
                                        <SelectInput
                                            key={key}
                                            className={fields[item].className || ''}
                                            disabled={fields[item].disabled}
                                            options={fields[item].options}
                                            properties={{
                                                name: fields[item].label,
                                                label: fields[item].label,
                                                validations: fields[item].validations,
                                            }}
                                            defaultOption={fields[item].defaultValue}
                                            onChange={(option) => handleChangeField(fields[item].path, option.value, [ '', null, undefined ])}
                                            value={getValueField(fields[item].path) || ''}
                                            forceDisplayError={!!formError?.[fields[item].path]}
                                            forceMessageError={formError?.[fields[item]?.path]}
                                        />
                                        {/*<span key={key + '_span'} className="antenna-fields-error">{formError && formError[fields[item].path]}</span>*/}
                                    </div>
                                );
                            case 'location':
                                return (
                                    <div key={fields[item].label} style={{ padding: '3px 10px 0 3px' }}>
                                        <label className="antenna-location-label">{fields[item].label} *</label>
                                        {handleRenderLocation(fields[item].fields)}
                                        <span key={key + '_span'} className="antenna-fields-error">{formError && formError[fields[item].path]}</span>
                                    </div>
                                );
                            case 'zoneCoordinates':
                            case 'obstructionCoordinates':
                                return (
                                    <div key={key + '_div'}>
                                        {handleRenderCoordinates(fields[item].fields)}
                                    </div>
                                );
                            default:
                                return null;
                        }
                    })
                }
            </div>
        </div>
    );
};
