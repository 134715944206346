import { TAG_VIEW_MODAL_OPTION, UPDATE_TAG_FIELD_VALUE } from '../constants/ActionTypes';
import {displayAllErrorFromAxios, displayErrorFromAxios, getErrorMessageFromAxios, ResourceService} from './util';
import { MODE_DIALOG_TAG } from '../views/tagView/constants/TagsConstants';
import {showDialogErrorForm, showDialogForm, viewModalFormAddEdit} from './formOnePlatform';
import { getReportKeys } from '../hooks';
import { ENDPOINTS, REPORT_ID_TAGS } from '../constants';
import { getDataWithFilter } from './reports';
import { tableOnePlatformReplaceSelectedRows } from './tableOnePlatform';

export const updateStaticValuesAction = () => {
    return async (dispatch, getState) => {
    };
};

export const validateTagAddEdit = () => {
    return async (dispatch, getState) => {
        const { reportKey } = getReportKeys(getState());
        const inputFields2 = getState().formOnePlatform[reportKey].add;
        const inputFieldsEdit = {};
        Object.keys(inputFields2).map(section =>
            section !== 'config' ?
                (
                    Object.keys(inputFields2[section].inputFields).map(itemValue =>
                        inputFieldsEdit[inputFields2[section].inputFields[itemValue].id] = parseBooleanValue(inputFields2[section].inputFields[itemValue].value),
                    )
                )
                : '',
        );
        
        const payloadTag = inputFieldsEdit;
        const modeDialog = getState().formOnePlatform.modeDialog;
        let modeSaveURL = JSON.parse(JSON.stringify(getState().tags.tagsURL.add));
        
        if (modeDialog === 'MENU_EDIT') {
            modeSaveURL = JSON.parse(JSON.stringify(getState().tags.tagsURL.edit));
            modeSaveURL.url = modeSaveURL.url.replace('{source}', payloadTag.source);
            modeSaveURL.url = modeSaveURL.url.replace('{id}', payloadTag.id);
        }
        
        // console.log(modeSaveURL)
        await ResourceService.instance().request(
            {
                url: modeSaveURL.url,
                method: modeSaveURL.method,
                data: payloadTag,
            },
        ).then(() => {
                // console.log("result: ",result);
                // dispatch(viewModalTagAddEdit(false));
                dispatch(viewModalFormAddEdit(false));
                // dispatch(getListTagsWithFilter());
                dispatch(getDataWithFilter(ENDPOINTS[REPORT_ID_TAGS].GET_ALL));
                
            },
        ).catch(displayErrorFromAxios.bind(null, dispatch));
        
    };
};

// export const validateTagDelete = (selectedRow,disassociateResource) =>{
//     return async (dispatch,getState) => {
//
//         console.log("deleteAction",selectedRow)
//         if(disassociateResource){
//             /**
//              *Make tree calls to delete the relation between resource and tag
//              * 1.- get the associatedTags, and siteId
//              * 2.- send patch resource with teh new associatedTags to remove the relation
//              * 3.- delete de tag
//              */
//             // get from resource  los associateTags y de ahí eliminar el tag que debemos elimnar
//             // /mwe/v2/resources/{type}/{id}
//                 //type= Resource Type
//                 //id = ResourceId
//                 // associatedTags: [
//                 // {
//                 //   tagId
//                 //   source
//                 // ]
//             const resourceAPI = getState().resources.resourcesURL.oneResource;
//             let resourceGetURL = resourceAPI.url;
//             resourceGetURL = resourceGetURL.replace('{type}',selectedRow.resourceType);
//             resourceGetURL = resourceGetURL.replace('{id}',selectedRow.resourceId);
//
//             resourceGetURL =resourceGetURL+"?fields=associatedTags,siteId";
//             console.log("resourceAPI",resourceAPI);
//             const result0 = await ResourceService.instance().request(
//                 {
//                     url:resourceGetURL,
//                     method:resourceAPI.method,
//                 }
//                 ).catch(displayErrorFromAxios.bind(null,dispatch));
//
//             const newAssociatedTags=result0.data.associatedTags.filter(function(el) { return el.tagId != selectedRow.id; });
//             // console.log(selectedRow);
//             console.log("result0",result0);
//             const siteId=result0.data.site.id;
//             console.log("newAssociatedTags",newAssociatedTags);
//             console.log("siteId",siteId);
//
//             const data={};
//             data.siteId=siteId;
//             data.associatedTags=newAssociatedTags;
//             console.log("data",data);
//             /// we make the resource update
//             const resourceUpdateAPI = getState().resources.resourcesURL.update;
//             let resourceURL = resourceUpdateAPI.url;
//             resourceURL = resourceURL.replace('{resourceType}',  selectedRow.resourceType);
//             resourceURL = resourceURL.replace('{resourceId}',selectedRow.resourceId);
//             console.log("resourceUpdateAPI",resourceURL);
//             const resultUpdate= await ResourceService.instance().request(
//                 {
//                     url:resourceURL,
//                     method:resourceUpdateAPI.method,
//                     data:data
//                 }
//             ).catch(displayErrorFromAxios.bind(null,dispatch));
//
//             console.log("resultUpdate",resultUpdate);
//
//             const tagURL = getState().tags.tagsURL.delete;
//             let urlTag=tagURL.url;
//             urlTag = urlTag.replace('{source}',selectedRow.source);
//             urlTag = urlTag.replace('{id}',selectedRow.id);
//             console.log("tagURLDELETE",tagURL)
//             const result = await ResourceService.instance().request(
//                 {
//                     url:urlTag,
//                     method:tagURL.method
//                     // data: payloadTag
//                 }
//             ).catch(displayErrorFromAxios.bind(null,dispatch));
//
//
//         }else{
//             /**
//              *Verify if the tag has a resource associate and display message
//              */
//             if(selectedRow.resourceId){
//                 console.log("Has a Resource associated")
//                 displayErrorFromAxios.bind("Has a Resource associated",dispatch)
//             }else{
//                 const deleteTag = getState().tags.tagsURL.delete;
//                 let urlTag=deleteTag.url;
//                 urlTag = urlTag.replace('{source}',selectedRow.source);
//                 urlTag = urlTag.replace('{id}',selectedRow.id);
//
//                 console.log("deleteTag",deleteTag)
//                 const result = await ResourceService.instance().request(
//                     {
//                         url:urlTag,
//                         method:deleteTag.method
//                         // data: payloadTag
//                     }
//                 ).catch(displayErrorFromAxios.bind(null,dispatch));
//
//                 console.log("result2: ",result);
//             }
//
//
//         }
//
//
//
//         // dispatch(viewModalTagAddEdit(false));
//         // dispatch(viewModalFormAddEdit(false));
//         dispatch(getTagsList());
//     }
// }

export const validateTagMultipleDelete = (selectRows) => {
    return async (dispatch, getState) => {
        const deleteTag = getState().tags.tagsURL.deleteMultipleTag;
        dispatch(deleteMultipleTag(selectRows, deleteTag.url, deleteTag.method));
    };
};
export const validateTagForceMultipleDelete = (selectRows) => {
    return async (dispatch, getState) => {
        const deleteTag = getState().tags.tagsURL.deleteMultipleTag;
        dispatch(deleteMultipleTag(selectRows, deleteTag.urlForceDelete, deleteTag.method));
    };
};

export const deleteMultipleTag = (selectRows, url, method) => {
    return async (dispatch, getState) => {
        const { reportKey } = getReportKeys(getState());
        const tags = Object.keys(selectRows).map(key => {
            return { id: selectRows[key].id, source: selectRows[key].source };
        });
        await ResourceService.instance().request(
            {
                url: url,
                method: method,
                data: tags,
            },
        ).then(result => {
            dispatch(showDialogForm(false, ''));
            dispatch(tableOnePlatformReplaceSelectedRows({}, reportKey));
            dispatch(getDataWithFilter(ENDPOINTS[REPORT_ID_TAGS].GET_ALL));
        }).catch(error => {
            dispatch(showDialogForm(false,''));
            try{
                const msgError = getErrorMessageFromAxios(error);
                const json = JSON.parse(msgError);
                dispatch(showDialogErrorForm(true,"MODAL_ERROR_DELETE",json));
                dispatch(getDataWithFilter(ENDPOINTS[REPORT_ID_TAGS].GET_ALL));
            }catch (e) {
                dispatch(displayAllErrorFromAxios(error));
            }
        });
    };
};
export const validateTagDelete = (selectedRow) => {
    return async (dispatch, getState) => {
        const deleteTag = getState().tags.tagsURL.deleteOneTag;
        try{
            await deleteOneTag(selectedRow, deleteTag.url, deleteTag.method,dispatch);
        }catch (e) {
            console.log(e)
        }
    };
};

export const validateTagDeleteForce = (selectedRow) => {
    return async (dispatch, getState) => {
        const deleteTag = getState().tags.tagsURL.deleteOneTag;
        try{
            await deleteOneTag(selectedRow, deleteTag.urlForceDelete, deleteTag.method,dispatch);
        }catch (e) {
            console.log(e)
        }
    };
};
export const deleteOneTag = async (selectedRow, urlParam, method,dispatch) => {
    let urlTag = urlParam;
    urlTag = urlTag.replace('{source}', selectedRow.source);
    urlTag = urlTag.replace('{id}', selectedRow.id);

    await ResourceService.instance().request(
        {
            url: urlTag,
            method: method,
        },
    ).then(
        result => {
            // dispatch(getListTagsWithFilter());
            dispatch(getDataWithFilter(ENDPOINTS[REPORT_ID_TAGS].GET_ALL));
        },
    ).catch(displayErrorFromAxios.bind(null, dispatch));
    //dispatch(getDataWithFilter(ENDPOINTS[REPORT_ID_TAGS].GET_ALL));
};

export const viewModalTagAddEdit = (status) => {
    return (dispatch) => {
        dispatch({ type: TAG_VIEW_MODAL_OPTION, statusModalTagAddEdit: status });
    };
};

export const updateTagFieldValue = (IdField, value, index) => {
    return (dispatch) => {
        dispatch({ type: UPDATE_TAG_FIELD_VALUE, IdField: IdField, value: value, index: index });
    };
};

const parseBooleanValue = (value) => {
    switch (value) {
        case 'Yes':
            return true;
        case 'No':
            return false;
        default:
            return value;
    }
};
/*
const parseBoolean = (filter) => {
    switch (filter) {
        case 'Yes':
            return true;
        case 'No':
            return false;
        default:
            return '';
    }
};
/*
export const getTagsList = () => {
    return async (dispatch, getState) => {
        const listUrl = getState().tags.tagsURL.list;
        let tagsUrl = listUrl.url;
        tagsUrl = tagsUrl.replace('{filters}&',"");
        tagsUrl = tagsUrl.replace('{page}', PAGE);
        tagsUrl = tagsUrl.replace('{size}', SIZE);
        let sort = '';
        tagsUrl = tagsUrl.replace('{sort}',sort);

        const result = await ResourceService.instance().request(
            {
                url:tagsUrl,
                method:listUrl.method
            }
        ).catch(displayErrorFromAxios.bind(null,dispatch));

        dispatch({type: LOAD_TAGS_DATA, tagList: result.data?result.data.content:[]});
    }
}*/

export const showDialog = (status, modeDialog) => ({ type: MODE_DIALOG_TAG, statusModalTagAddEdit: status, modeDialog: modeDialog, selectTag: {} });
