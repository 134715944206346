import { createSelector } from 'reselect';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { EMPTY_OBJECT } from '../constants';
import {loadMenu, requestUserGroups} from '../actions';
const USER_GROUP_ADMINISTRATOR = "MWEAdministrator";
const selectUserState = createSelector(
    state => state.user,
    userState => {
        const { menu, autoRefreshInterval = 30, feature = EMPTY_OBJECT, username, userGroups=[] } = userState || {};
        return { menu, autoRefreshInterval, feature, username, userGroups,isAdministrator:(userGroups||[]).indexOf(USER_GROUP_ADMINISTRATOR)>=0 };
    },
);

export const useUserState = () => {
    return useSelector(state => selectUserState(state), shallowEqual);
};

export const useUserDispatch = () => {
    const dispatch = useDispatch();
    return {
        loadMenu: (menu, permissions) => dispatch(loadMenu(menu, permissions)),
        getUserGroups: () => dispatch(requestUserGroups()),
    };
};