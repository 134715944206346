import {TYPE_FIELD_COMBOBOX, TYPE_FIELD_TEXT} from "../views/resourceTypes/constants/ResourceTypes";
const TYPE_DATA_STRING = "text";
// const TYPE_DATA_NUMERIC = "number";
const getField = (key,typeField,typeData,caption,disabled) =>{
    return {
        key:key,
        typeField:typeField,
        typeData:typeData,
        caption:caption,
        disabled: disabled!==undefined?disabled:false,
        value:""
    }
}
export const TAGS_TYPE_FIELD = [
    getField('id',TYPE_FIELD_TEXT,TYPE_DATA_STRING,'Id'),
    // getField('resourceId',TYPE_FIELD_COMBOBOX,null,'Resource Id'),
    // getField('siteId',TYPE_FIELD_COMBOBOX,null,'Site Id'),
    getField('source',TYPE_FIELD_COMBOBOX,null,'Source')
    // getField('label',TYPE_FIELD_TEXT,TYPE_DATA_STRING,'Label'),
    // getField('mode',TYPE_FIELD_COMBOBOX,null,'Mode'),
    // getField('rssi',TYPE_FIELD_TEXT,TYPE_DATA_NUMERIC,'Rssi'),
    // getField('reader',TYPE_FIELD_TEXT,TYPE_DATA_NUMERIC,'Reader'),
    // getField('status',TYPE_FIELD_TEXT,TYPE_DATA_NUMERIC,'Status'),
    // getField('temperature',TYPE_FIELD_TEXT,TYPE_DATA_STRING,'Temperature'),
    // // getField('receiver',TYPE_FIELD_TEXT,TYPE_DATA_STRING,'Receiver'),
    // getField('region',TYPE_FIELD_TEXT,TYPE_DATA_NUMERIC,'Region'),
    // getField('barSequence',TYPE_FIELD_TEXT,TYPE_DATA_NUMERIC,'Bar Sequence'),
    // getField('sequence',TYPE_FIELD_TEXT,TYPE_DATA_NUMERIC,'Sequence'),
    // getField('format',TYPE_FIELD_TEXT,TYPE_DATA_NUMERIC,'format'),
    // getField('frameworkSequence',TYPE_FIELD_TEXT,TYPE_DATA_STRING,'Framework Sequence'),
    // getField('controlWord',TYPE_FIELD_TEXT,TYPE_DATA_STRING,'Control Word'),
    // getField('indexedData',TYPE_FIELD_TEXT,TYPE_DATA_STRING,'Indexed Data'),
    // getField('packetType',TYPE_FIELD_TEXT,TYPE_DATA_NUMERIC,'Packet Type'),
    // getField('payload',TYPE_FIELD_TEXT,TYPE_DATA_STRING,'Payload'),
    // getField('stationId',TYPE_FIELD_TEXT,TYPE_DATA_STRING,'Station Id'),
    // getField('longMessage',TYPE_FIELD_TEXT,TYPE_DATA_STRING,'long Message'),
    // getField('longMessageTime',TYPE_FIELD_TEXT,TYPE_DATA_STRING,'long Message Time'),
    // getField('gpsInfo',TYPE_FIELD_TEXT,TYPE_DATA_STRING,'GPS Info'),
    // getField('epcInfo',TYPE_FIELD_TEXT,TYPE_DATA_STRING,'Epc Info'),
    // getField('dartInfo',TYPE_FIELD_TEXT,TYPE_DATA_STRING,'Dart Info'),
];
export const VALIDATE_TAGS_FIELD = {

}
