import { TREE_STRUCTURE_ID } from './constants';
import { OPTION_ALL_WILDCARD } from '../../../../../constants/Misc';
import { getValueOfPath } from '../../../../../utils';

/* $TREE HELPERS  */

/**
 * Gets the tree levels of the selected node in an array format.
 * @param {string} _id Nested levels in format of the tree library. E.g. treePos-0-0-1-1
 * @param {boolean} countFirst check if should it contain the 'All' (first) node level.
 * @returns {Array<string>} Array of the nest levels from the tree, e.g. [0, 1, 1]
 */
export const getChildrenNestLevels = (_id, countFirst) => {
  const substrScope = countFirst ? 1 : 3;
  const substrLength = TREE_STRUCTURE_ID.length + substrScope; // 3 to remove the first nest
  const childrenNest = _id.substring(substrLength); // 1st to check if loaded
  return childrenNest.split("-");
}

/**
 * It loops the current tree position and orders its params based on the treeStructure.
 * @param {Array} nestLevels Current tree position based on children (e.g. ["0", "0", "1"])
 * @param {Object} treeData TreeFilter current data.
 * @param {Object} treeStructure The structure for the multiple tree filters return response.
 * @param {Object} treeStructure The structure for the return tree filter response.
 * @returns {Object} Structured response based on the treeStructure param.
 */
export const structureTreeForDispatch = (nestLevels, treeData, treeStructure) => {
  let nestNode = { ...treeData }, path = "", prevId;
  const zonesObjKeys = Object.keys(treeStructure);

  nestLevels.forEach((level, index) => {
    const key = zonesObjKeys[index];
    prevId = nestNode.value;
    nestNode = nestNode.children[level];
    
    const arr = [...treeStructure[key]];
    path = getTreeNodePath(path, nestNode.label);
    if (arr.findIndex(val => val.id === nestNode.value) === -1) {
      arr.push({
        path,
        id: nestNode.value,
        name: nestNode.label,
        hasChildren: nestLevels.length - 1 > index,
        parentId: prevId,
      });
      treeStructure[key] = arr;
    }
  });
}

/**
 * Concatenates and builds the path for requesting with a separating symbol.
 * @param {string} path previously defined path
 * @param {string} label new path label to be added to the path
 * @returns {string} the new defined path
 */
export const getTreeNodePath = (path, label) => path ? path.concat(`\f${label}`) : label;

/**
 * Dispatcher to structure the tree in a specific format.
 * @param {Object} filters filtersOnePlatform filters list
 * @param {Object} tree the defined tree structure (sites, map, groups and zones)
 * @param {Object} structuredTree tree structured to see the tree path for further requests
 * @param {Object} extraProps extra properties to be injected to the tree filter sections
 */
export const setOnePlatformTreeFilter = (filters, tree, structuredTree, extraProps = {}) => ({
  ...filters,
  tree: { ...filters.tree, value: structuredTree, ...extraProps},
  treeSiteName: { ...filters.treeSiteName, value: tree.treeSiteName,extraFilter:true },
  treeMapName: { ...filters.treeMapName, value: tree.treeMapName,extraFilter:true },
  treeZoneGroup: { ...filters.treeZoneGroup, value: tree.treeZoneGroup,extraFilter:true },
  treeZone: { ...filters.treeZone, value: tree.treeZone,extraFilter:true }
});

export const generateOnePlatformTreeFilter = (structuredTree, filters) => {
  const tree = {};
  Object.entries(structuredTree).forEach(([key, value]) => {
    tree[key] = value.map(node => node.name);
  });
  return setOnePlatformTreeFilter(filters, tree, structuredTree);
}

export const selectSite = (siteId, siteName, structuredTree) => {
  const site = structuredTree.treeSiteName.find(site => site.id === siteId);
  if (!site) {
    const path = getTreeNodePath('', siteName);
      structuredTree.treeSiteName = [
        ...structuredTree.treeSiteName,
        {
          path,
          id: siteId,
          name: siteName,
          hasChildren: false,
          parentId: OPTION_ALL_WILDCARD,
        },
      ];
  } else {
    if (site.hasChildren) {
      const toClean = [siteId];
      const nextToClean = [];
      for (const key of ['treeMapName', 'treeZoneGroup', 'treeZone']) {
        const newNodes = [];
        for (const node of structuredTree[key] || []) {
          if (toClean.includes(node.parentId)) {
            if (node.hasChildren) {
              nextToClean.push(node.id);
              node.hasChildren = false;
            }
          } else {
              newNodes.push(node);
          }
        }
        structuredTree[key] = newNodes;
        toClean.length = 0;
        toClean.push(...nextToClean);
        nextToClean.length = 0;
      }
      site.hasChildren = false;
    }
  }
}

/**
 * Creates a path for the selected node array.
 * @param {Array} treeArr tree node array (sites, maps, groups or zones)
 * @param {string} path previously defined path
 */
const constructTreePath = (treeArr, path) => {
  const treePath = treeArr.reduce((acc, cur) => {
      const zonePath = getValueOfPath(cur, path);
      return !cur.hasChildren ? acc.concat(zonePath, ",") : acc;
  }, "");

  return treePath ? treePath.slice(0, -1) : treePath;
}

/**
 * Get the tree path to make the payload
 * @param {Object} tree selected tree object
 * @param {string} path defined path to be applied
 */
export const getTreePathPayload = (tree = {}, path) => {
  return Object.values(tree).reduce((acc, cur) => {
      const treePath = cur?.length ? constructTreePath(cur, path) : "";
      return treePath ? acc.concat(treePath, ",") : acc;
  }, "").slice(0, -1);
}

// Construct the paths to siteName\fmapName\fgroupName\fzoneName
export const getValidTreePathPayload = (tree) => {
  if (tree && tree.treeSiteName && tree.treeSiteName.length) {
    return getTreePathPayload(tree, '$..path'); // FILTER_NAME.json path
  }
  return '';
};


// Gauss implementation
const getValuesTree = (list, key) => {
  return list.map(item => {
      return item[key];
  });
};

// Victor implementation for querying the zones
const getQueryZone = (maps, zoneGroups, zones) => {
  const query = [];
  const DELIMITER = "\f";
  maps && maps.forEach(map => {
      const zoneGroupByMap = zoneGroups.filter(zoneGroup => zoneGroup.parentId === map.id);
      if (zoneGroupByMap && zoneGroupByMap.length > 0) {
          zoneGroupByMap.forEach(zoneGroup => {
              const zoneByZoneGroup = zones.filter(zone => zone.parentId === zoneGroup.id);
              if (zoneByZoneGroup && zoneByZoneGroup.length > 0) {
                  zoneByZoneGroup.forEach(zone => {
                      query.push(map.id + DELIMITER + zoneGroup.name + DELIMITER + zone.name);
                  });
              } else {
                  query.push(map.id + DELIMITER + zoneGroup.name);
              }
          });
      }
  });

  return query.toString();
};

// get tree properties for tags-action.js and resources.js
export const filterTreeForReports = ({
  treeSiteName = [],
  treeMapName = [],
  treeZoneGroup = [],
  treeZone = []
}) => {

  const siteIds = getValuesTree(treeSiteName, 'id').toString();
  // If the site has no map children, get the site children from property
  let auxMapIds = [];
  if (treeSiteName.length && treeMapName.length) {
    auxMapIds = treeSiteName.reduce((acc, site) => {
      if (!site.hasChildren && site.childrenIds.length) {
        return [...acc, site.childrenIds];
      }
      return acc;
    }, []);
  }

  const mapIds = getValuesTree(treeMapName, 'id');
  const reqMapIds = mapIds.concat(auxMapIds).toString();
  const zones = getQueryZone(treeMapName, treeZoneGroup, treeZone);

  return {
    siteIds,
    mapIds: reqMapIds,
    zones
  }
}
export const getPaths = (treeDataA) =>{
  const {treeSiteName,treeMapName,treeZoneGroup,treeZone} = treeDataA;
  const paths = [];
  for (let zone of treeZone) {
    for (let group of treeZoneGroup) {
      if (zone?.parentId === group?.id) {
        for (let map of treeMapName) {
          if (group?.parentId === map?.id) {
            for (let site of treeSiteName) {
              if ( map?.parentId === site.id) {
                paths.push(site.name+"/"+map.id+"/"+zone.name);
              }
            }
          }
        }
      }
    }
  }
  return paths;
}

export const getStructuredTreeUtil = (data) =>{
  if(typeof data === 'string' && data.length>0){
    //eslint-disable-next-line
    return JSON.parse(data.replaceAll('\'', '\"'));
  }else{
    return data;
  }
}
export const formatTree  = (treeData) =>{
  const treeSiteName = [];
  const treeMapName = [];
  const treeZoneGroup = [];
  const treeZone  = treeData?.treeZone || [];

  if (treeData?.treeSiteName !== undefined) {
    for (let site of treeData?.treeSiteName) {
      for (let map of treeData?.treeMapName) {
        let mapId = undefined;
        if(!isNaN(map.id)){
          mapId = parseInt(map.id);
        }
        if (map?.parentId === site.id) {
          for (let group of treeData?.treeZoneGroup) {
            if(group.parentId === map.id){
              Object.keys(treeZone).forEach(zoneIndex=>{
                if (treeZone[zoneIndex]?.parentId === group?.id ) {
                  const newPath = site.name+'\f'+map.name+'\f'+group.name+'\f'+treeZone[zoneIndex].name;
                  treeZone[zoneIndex].path = newPath;
                }
              });
              treeZoneGroup.push({ id: group.id, name: group.name, parentId:mapId, hasChildren: true , expanded: true  });
            }
          }
          treeMapName.push({ id:mapId, name:map?.name, parentId:site.id, hasChildren: true, expanded: true })
        }
      }
      treeSiteName.push({ id:site.id,name:site.name, hasChildren: true, expanded: true });
    }
  }
  return { treeSiteName, treeMapName, treeZoneGroup, treeZone };
}

export const getNewPath = (item) =>{
  const path = item.path;
  const nodes = path.split("\f");
  if( nodes.length === 4 ) {
    return nodes[0]+"/"+nodes[1]+"/"+nodes[3];
  } else {
    return item.name;
  }
}
export const formatTreeField = (data) =>{
  if(typeof data === 'string' && data.length>=0 ){
    try{
      //eslint-disable-next-line
      const dataParse = data.replaceAll('\'', '\"');
      const structuredTree = dataParse !==''? JSON.parse(dataParse):{};
      const newTree = formatTree(structuredTree);
      return newTree;
    }catch (e) {
      console.log({e})
    }
  }else{
   return data;
  }
}

export const removeAllChecked = (data,currentNode) =>{
  try{
    for(let siteIndex in data.children){
      for(let mapIndex in data.children[siteIndex].children){
        for(let groupIndex in data.children[siteIndex].children[mapIndex].children){
          for(let zoneIndex in data.children[siteIndex].children[mapIndex].children[groupIndex].children){
            if(data.children[siteIndex].children[mapIndex].children[groupIndex].children[zoneIndex].checked
                && data.children[siteIndex].children[mapIndex].children[groupIndex].children[zoneIndex].value !== currentNode.value
            ){
              data.children[siteIndex].children[mapIndex].children[groupIndex].children[zoneIndex].checked = false;
            }
          }
        }
      }
    }
  }catch (e) {
    console.log({e})
  }

}