//toolbarbutton
import React from 'react';

import AddIcon from '@material-ui/icons/Add';
import UpdateIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import RunIcon from '@material-ui/icons/Update';
import Info from '@material-ui/icons/Info';
import ExportIcon from '../icons/ExportIcon';
import ImportIcon from '../icons/ImportIcon';
import CustomIconButton from "../util/button/IconButton";
import RefreshIcon from '@material-ui/icons/Refresh';
import SettingsIcon from '@material-ui/icons/Settings';
import PrintIcon from '@material-ui/icons/Print';

class ToolbarButton extends React.Component{



    render(){

        const {isMobile, iconType, id, enabled} = this.props;

        let Icon = AddIcon;
        let description = "Add";

        switch (iconType){
            case "ADD" :
                         Icon = AddIcon;
                         description = "Add";
                         break;
            case "UPDATE":
                        Icon = UpdateIcon;
                        description = "Update";
                        break;
            case "IMPORT":
                        Icon = ImportIcon;
                        description = "Import";
                        break;
            case "DELETE":
                        Icon = DeleteIcon;
                        description = "Delete";
                        break;
            case "EXPORT":
                        Icon = ExportIcon;
                        description = "Export";
                        break;
            case "RUN": Icon = RunIcon;
                        description = "Run Report";
                        break;
            case "DETAIL": Icon = Info;
                        description = "Details";
                        break;
            case "REFRESH": Icon = RefreshIcon;
                        description = "Refresh";
                        break;
            case "MORE": Icon = SettingsIcon;
                        description = "More";
                        break;
            case "PRINT": Icon = PrintIcon;
                        description = "Print RFID";
                        break;
            default:
                description = "Add";

        }

        let className = " button-container material-button";



        return(<CustomIconButton
                onClick={this.props.click}
                icon={<Icon viewBox={"0 2 24 24"}/>}
                description={(!isMobile)?description:null}
                className={className}
                idButton = {id}
                enabled={enabled}
                />);
    }
}

export default ToolbarButton;