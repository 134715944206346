import Handle from '@material-ui/icons/Dehaze';
import './defaultSplitter.scss';

export const DefaultSplitter = () => {
  return (
    <div className="default-splitter">
      <div className="line">
        <Handle />
      </div>
    </div>
  );
};
