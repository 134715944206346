import React, {useState} from "react";

import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import DialogFirmware from "./DialogFirmware";
import {useOnePlatformTableState, useReportDispatch, useReportKey} from "../../../hooks";

import {useDispatch} from "react-redux";
import {deleteFileFirmware} from "../../../actions/firmware-manager";
import { REPORT_ID_CERTIFICATES } from '../../../constants';

const ButtonFirmware = ({rowIndex}) => {
    const { reportKey, reportId } = useReportKey();
    const { data: dataTable } = useOnePlatformTableState(reportKey);
    const dispatch = useDispatch();
    const { loadData} = useReportDispatch(reportKey);

    const [openAlert, setOpenAlert] = useState(false);
    const row = dataTable[rowIndex];

    function handleConfirmDialog() {
        setOpenAlert(true);
        return undefined;
    }

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };
    const handleDeleteFirmware = async () => {
        await dispatch(deleteFileFirmware(row.fileId));
        loadData()
        setOpenAlert(false);
    };

    return(
        <div>
            <div style={{display:'flex',justifyContent:'center'}} >
                <IconButton size="small" onClick={() => handleConfirmDialog()} ><DeleteIcon /></IconButton>
            </div>
            <DialogFirmware
                row={row}
                openModal={openAlert}
                onClose={handleCloseAlert}
                onDelete={handleDeleteFirmware}
                isCertificate={reportId === REPORT_ID_CERTIFICATES}
            />
        </div>
    )

}

export default ButtonFirmware;