import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { createSelector } from 'reselect';

import { changeSiteManagerSiteSelected, loadMapZones, loadSiteZones, requestMaps, requestSites } from '../actions';

const EMPTY_ARRAY = [];

const selectSitesState = createSelector(
    (state) => state.sites,
    (sitesState) => {
        const { maps = EMPTY_ARRAY, sites = EMPTY_ARRAY, siteManagerSiteHover, zones } = sitesState || {};
        return { sites, maps, siteManagerSiteHover, zones };
    }
);

export const useSitesState = () => {
    return useSelector(state => selectSitesState(state), shallowEqual);
};

export const useSitesDispatch = () => {
    const dispatch = useDispatch();
    return {
        requestSites: useCallback(() => {
            dispatch(requestSites());
        }, [ dispatch ]), // return something
        requestMaps: useCallback(() => dispatch(requestMaps()), [ dispatch ]),
        changeSiteManagerSiteSelected: useCallback((siteId) => dispatch(changeSiteManagerSiteSelected(siteId)), [ dispatch ]),
        loadSiteZones: useCallback((siteId) => dispatch(loadSiteZones(siteId)), [ dispatch ]),
        loadMapZones: useCallback((mapId) => dispatch(loadMapZones(mapId)), [ dispatch ])
    };
};
