import React from "react";
import PropTypes from "prop-types";
import ReactTooltip from 'react-tooltip';

// Simple button render with icon.
class CustomIconButton extends React.PureComponent{
    // constructor(props){
    //     super(props);
    // }

    static get propTypes(){
        return{
            onClick: PropTypes.func.isRequired,
            icon: PropTypes.any.isRequired,
            description: PropTypes.string,
            enabled:PropTypes.bool,
            className:PropTypes.string,
            id:PropTypes.string,
        };
    }

    static get defaultProps(){
        return {
            enabled: true,
        };
    }

    render(){

        const {idButton, icon,description,enabled,className, showPlaybackAdvice}=this.props;

        return showPlaybackAdvice ?
            <div className="show-playback-advice">
                <button id={idButton} className={className} onClick={this.props.onClick} disabled={!enabled}
                        data-tip={"Please select a date range."} data-for={"messageDate"}>
                    {icon} {(!description) ? "" : <span className={"button-content"}>{description}</span>}
                </button>
                <ReactTooltip className={"playback-tooltip"} place={"top"} id={"messageDate"} effect={"solid"}/>
            </div>
            :
            <button id={idButton} className={className} onClick={this.props.onClick} disabled={!enabled} >
                {icon} {(!description) ? "" : <span className={"button-content"}>{description}</span>}
            </button>;
    }

}

export default CustomIconButton;
