import React from 'react';
import PropTypes from 'prop-types';
import assign from 'object-assign';

const styles = {
    defaultLabel:{
        border:'solid 1px black',
        backgroundColor:'white',
        padding:'0 5px',
        fontSize:'0.9em',
        position:'relative',
        left:'-5px'
    }
};

class Label extends React.Component{
    static get propTypes(){
        return{
            style:PropTypes.object,
            children:PropTypes.any
        };
    }

    render(){

        let _styles = assign({},styles.defaultLabel,this.props.style);

        return (
            <div style={_styles}>
                {this.props.children}
            </div>
        );
    }
}

export default Label;
