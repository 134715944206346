import {
    VALIDATION_TYPE_CUSTOM,
    VALIDATION_TYPE_REGEXP,
    VALIDATION_TYPE_REQUIRED,
    VALIDATION_JSON_FORMAT,
    VALIDATION_ANTENNAS_REQUIRED,
    VALIDATION_TYPE_CUSTOM_PATH, CONFIGURATION_VALIDATION_OBJECT
} from "../../constants/DeviceManager";
import Jp from "jsonpath";
import {getSafeValueOfPath} from "../../utils";

export const validateDevice=(templateForm,templateFormLoaded,needsValidationFields)=>{
    let error=false;
    let antennasWithIssues = [];
    let firstSectionWithError = null;
    Object.keys(needsValidationFields).forEach(sectionKey=>{
        const section=needsValidationFields[sectionKey];
        Object.keys(section).forEach(field=>{
            let validationInfo=section[field];
            if(validationInfo.validateForms!=null&&validationInfo.validateForms.indexOf(templateFormLoaded)>=0) {

                let fieldValue =null;

                if (validationInfo.path === true) {
                    try {
                        fieldValue = Jp.query(templateForm, field);
                    } catch (e) {
                        console.log(e);
                    }
                } else {
                    const pathToField = field.split('.');
                    fieldValue = pathToField.reduce((reducedObject, currentKey) => reducedObject[currentKey], templateForm);
                }

                switch(validationInfo.type){
                    case VALIDATION_TYPE_CUSTOM:
                        validationInfo.error = validateCustom(field, fieldValue, validationInfo, templateForm);
                        break;
                    case VALIDATION_TYPE_CUSTOM_PATH:
                        validationInfo = validateCustomPath(field, fieldValue, validationInfo, templateForm);
                        break;
                    case VALIDATION_TYPE_REQUIRED:
                        validationInfo.error = validateRequired(fieldValue);
                        break;
                    case VALIDATION_TYPE_REGEXP:
                        validationInfo.error = validateRegexp(fieldValue, validationInfo.regexp);
                        break;
                    case VALIDATION_JSON_FORMAT:
                        validationInfo.error = validateJsonString(fieldValue, templateForm);
                        break;
                    case VALIDATION_ANTENNAS_REQUIRED:
                        antennasWithIssues = checkAntennas(fieldValue);
                        validationInfo.error  = antennasWithIssues.length > 0;
                        break;
                    default:
                        break;
                }

                if(validationInfo.error === true){
                    error = true;

                    // putting the first section with error validation
                    if(!firstSectionWithError){
                        firstSectionWithError = sectionKey;
                    }
                }
            }
        });
    });

    let customNeedsValidationFields = {...needsValidationFields};
    customNeedsValidationFields.rfidAntennas.rfidAntennas.issues = antennasWithIssues;
    return {error,needsValidationFields: customNeedsValidationFields, focusedSection: firstSectionWithError};

}

const checkAntennas = rfidAntennas => {
    let titlesArray = rfidAntennas.map(item => item.title);
    let findDuplicatesOrEmptyNames = titlesArray.filter((item, index) => item === "" || titlesArray.indexOf(item) !== index);
    let antennasWithIssues = rfidAntennas.reduce((acc, item, index) => findDuplicatesOrEmptyNames.indexOf(item.title) > -1? [...acc, index]: acc, []);
    return antennasWithIssues;
}

const validateAntennasTransmitPower=(rfidAntennas)=>{

    const antennasWithTransmitPowerIssues=[];
    rfidAntennas.forEach((antenna,index)=>{
        const transmitPower=antenna.transmitPower;
        if(transmitPower!=null&&transmitPower<10){
            antennasWithTransmitPowerIssues.push(index);
        }
    });
    return antennasWithTransmitPowerIssues;
}

const validateJsonString = (str, templateForm) => {
    if(str === undefined || str === null || typeof(str) === 'object' || templateForm.operationMode !== 'advanced'){
        return false;
    }

    try {
        JSON.parse(str);
    } catch (e) {
        return true;
    }
    return false;
}

function validateRequired(fieldValue){
    let error=false;
    if(fieldValue==null||fieldValue===''){
        error=true;
    }
    return error;


}
function validateRegexp(fieldValue,regexp){
    let error=false;

        error=true;
// console.log("error",error);
    return error;
}
function validateCustom(field,fieldValue,validationInfo,templateForm){
    let error=false;
    if(field==='minRssi'&&templateForm.operationMode==='inventory'){
        if(fieldValue!=null&&fieldValue!==''&&(isNaN(fieldValue)||fieldValue>=0)){
            error=true;
        }
    } else if(field==='tagFilter.value'){

        if ((fieldValue == null || fieldValue === '')&&
            ((templateForm.tagFilter.match != null && templateForm.tagFilter.match !== '')
            || (templateForm.tagFilter.operation != null && templateForm.tagFilter.operation !== ''))) {
            error=true;
        }
    } else if(field==='tagFilter.match'){
        if((fieldValue==null || fieldValue==='') && (templateForm.tagFilter.value!=='' && templateForm.tagFilter.value != null)){
            error=true;
        }
    } else if(field==='tagFilter.operation'){
        if((fieldValue==null || fieldValue==='') &&
            templateForm.tagFilter.value!=='' && templateForm.tagFilter.value!= null &&
            templateForm.tagFilter.match!=='' && templateForm.tagFilter.match!= null){
            error=true;
        }
    }else if(field==='tagFilter.length'){
        if((fieldValue==null || fieldValue==='') &&
            templateForm.tagFilter.value!=='' && templateForm.tagFilter.value!= null && templateForm.tagFilter.value?.length % 2 !== 0 ){
            error=true;
        }
    }else if(field==='inventoryInterval.value'&&templateForm.operationMode==='inventory'){

        if ((fieldValue == null ||fieldValue === ''|| isNaN(fieldValue) || fieldValue <= 0) &&
            (templateForm.inventoryInterval.unit != null && templateForm.inventoryInterval.unit !== '')) {
            error=true;
        }
    } else if(field==='inventoryInterval.unit'&&templateForm.operationMode==='inventory'){
        if((fieldValue==null || fieldValue==='') && parseFloat(templateForm.inventoryInterval.value) > 0){
            error=true;
        }
    } else if(field==='portalSettings.startTrigger.port'&&templateForm.operationMode==='portal'){
        if((fieldValue==null ||fieldValue === ''|| isNaN(fieldValue) || fieldValue<=0)&&
            (templateForm.portalSettings.startTrigger.signal != null && templateForm.portalSettings.startTrigger.signal !== '')){
            error=true;
        }
    } else if(field==='portalSettings.startTrigger.signal'&&templateForm.operationMode==='portal'){
        if((fieldValue==null || fieldValue==='') && parseInt(templateForm.portalSettings.startTrigger.port) > 0){
            error=true;
        }
    }

    return error;
}

function validateCustomPath(field, fieldValue, validationInfo, templateForm) {

    if (field === '$.rfidAntennas[*]') {
        const antennasTransmitPowerIssues = validateAntennasTransmitPower(fieldValue);
        validationInfo.error = antennasTransmitPowerIssues.length > 0;
        validationInfo.transmitPowerIssues = antennasTransmitPowerIssues;
    }

    return validationInfo;
}


export const antennasCustomValidation = ( antennas, validation, configurationForm, formFieldErrors) => {
    let errorValidations = {...formFieldErrors}
    const {value: stopConditionValue} = getSafeValueOfPath(configurationForm, validation.stopConditionPath);
    if (validation.field === 'mode.antennaStopCondition.type') {
        if(stopConditionValue && Array.isArray(stopConditionValue)) {
            antennas.forEach((item,index) => {
                if(stopConditionValue[index]){
                    const path = validation.dynamicPath.replace('{index}', index);
                    if ((stopConditionValue[index].type === '' || stopConditionValue[index].type == null) &&
                        ((!isNaN(stopConditionValue[index].value) && stopConditionValue[index].value !== '' && stopConditionValue[index].value != null) ||
                            (!isNaN(stopConditionValue[index].port) && stopConditionValue[index].port !== '' && stopConditionValue[index].port != null) ||
                            (stopConditionValue[index].signal !== '' && stopConditionValue[index].signal != null))) {
                        errorValidations[path] = validation.errorMessage;
                    } else if(errorValidations[path]){
                        errorValidations[path] = '';
                    }
                }
            })
        } else if(stopConditionValue) {
            if ((stopConditionValue.type === '' || stopConditionValue.type == null) &&
                ((!isNaN(stopConditionValue.value) && stopConditionValue.value !== '' && stopConditionValue.value != null) ||
                    (!isNaN(stopConditionValue.port) && stopConditionValue.port !== '' && stopConditionValue.port != null) ||
                    (stopConditionValue.signal !== '' && stopConditionValue.signal != null))) {
                errorValidations[validation.path] = validation.errorMessage;
            } else if(errorValidations[validation.path]){
                errorValidations[validation.path] = '';
            }
        }
    } else if (validation.field === 'mode.antennaStopCondition.value') {
        if(stopConditionValue && Array.isArray(stopConditionValue)) {
            antennas.forEach((item,index) => {
                if(stopConditionValue[index]){
                    const path = validation.dynamicPath.replace('{index}', index);
                    if ((stopConditionValue[index].type != null && stopConditionValue[index].type !== '' && stopConditionValue[index].type !== 'GPI') &&
                        (isNaN(stopConditionValue[index].value) || stopConditionValue[index].value === '' || stopConditionValue[index].value == null)) {
                        errorValidations[path] = validation.errorMessage;
                    } else if(errorValidations[path]){
                        errorValidations[path] = '';
                    }
                }
            })
        } else if(stopConditionValue) {
            if ((stopConditionValue.type != null && stopConditionValue.type !== '' && stopConditionValue.type !== 'GPI') &&
                (isNaN(stopConditionValue.value) || stopConditionValue.value === '' || stopConditionValue.value == null)) {
                errorValidations[validation.path] = validation.errorMessage;
            } else if(errorValidations[validation.path]){
                errorValidations[validation.path] = '';
            }
        }
    } else if (validation.field === 'mode.antennaStopCondition.value.port') {
        if(stopConditionValue && Array.isArray(stopConditionValue)) {
            antennas.forEach((item,index) => {
                if(stopConditionValue[index]) {
                    const path = validation.dynamicPath.replace('{index}', index);
                    if ((stopConditionValue[index].type != null && stopConditionValue[index].type === 'GPI') &&
                        (isNaN(stopConditionValue[index].value?.port) || stopConditionValue[index].value?.port === '' || stopConditionValue[index].value?.port == null)) {
                        errorValidations[path] = validation.errorMessage;
                    } else if(errorValidations[path]){
                        errorValidations[path] = '';
                    }
                }
            });
        } else if(stopConditionValue) {
            if ((stopConditionValue.type != null && stopConditionValue.type === 'GPI') &&
                (isNaN(stopConditionValue.value?.port) || stopConditionValue.value?.port === '' || stopConditionValue.value?.port == null)) {
                errorValidations[validation.path] = validation.errorMessage;
            } else if(errorValidations[validation.path]){
                errorValidations[validation.path] = '';
            }
        }
    } else if (validation.field === 'mode.antennaStopCondition.value.signal') {
        if(stopConditionValue && Array.isArray(stopConditionValue)) {
            antennas.forEach((item,index) => {
                if (stopConditionValue[index]) {
                    const path = validation.dynamicPath.replace('{index}', index);
                    if ((stopConditionValue[index].type != null && stopConditionValue[index].type === 'GPI') &&
                        (stopConditionValue[index].value?.signal === '' || stopConditionValue[index].value?.signal == null)) {
                        errorValidations[path] = validation.errorMessage;
                    } else if(errorValidations[path]){
                        errorValidations[path] = '';
                    }
                }
            });
        } else if(stopConditionValue) {
            if ((stopConditionValue.type != null && stopConditionValue.type === 'GPI') &&
                (stopConditionValue.value?.signal === '' || stopConditionValue.value?.signal == null)) {
                errorValidations[validation.path] = validation.errorMessage;
            } else if(errorValidations[validation.path]){
                errorValidations[validation.path] = '';
            }
        }
    }

    return errorValidations;
};

const arrayElementsAreNullOrEmpty = (arr) => {
    return arr && arr.every(element => element == null || element === '') ;
}

const allAreNullOrEmptyObject = (arr) => {
    return arr && arr.every(element => element == null || Object.keys(element).length === 0 || elementsAreEmpty(element)) ;
}

const elementsAreEmpty = (object) => {
    return Object.values(object).every(item => {
        const elem = typeof item === 'number' && isNaN(item) ? null : item;
        return elem == null || elem === ''
    })
}

export const arrayValidateErrors = (arrayValues, antennasValues, path, errorMessage, formFieldErrors, field) => {
    let errors = {...formFieldErrors};
    if(field === 'mode.transmitPower') {
        if (!arrayElementsAreNullOrEmpty(arrayValues)) {
            antennasValues.forEach((item, index) => {
                const pathElement = path + '[' + index + ']';
                if ((!arrayValues[index] || arrayValues?.[index] + '' === '')) {
                    errors[pathElement] = errorMessage;
                } else if (errors[pathElement]) {
                    errors[pathElement] = ''
                }
            })
        } else {
            antennasValues.forEach((item, index) => {
                const pathElement = path + '[' + index + ']';
                errors[pathElement] = '';
            })
        }
    } else if(field === 'mode.antennaStopCondition' || field === 'mode.query') {
        if(!allAreNullOrEmptyObject(arrayValues)){
            antennasValues.forEach((item, index) => {
                const pathElement = path + '[' + index + ']';
                if ((!arrayValues[index] || elementsAreEmpty(arrayValues[index]))) {
                    errors[pathElement] = errorMessage;
                } else if (errors[pathElement]) {
                    errors[pathElement] = ''
                }
            })
        } else {
            antennasValues.forEach((item, index) => {
                const pathElement = path + '[' + index + ']';
                errors[pathElement] = '';
            })
        }
    }
    return errors;
}

export const getCustomRangeValidation = (antennas, validation, configurationForm, formFieldErrors) => {
    let errorValidations = {...formFieldErrors}
    if (validation.field === 'mode.antennaStopCondition.value.port') {
        const {value: stopConditionValue} = getSafeValueOfPath(configurationForm, validation.stopConditionPath);
        if(stopConditionValue && Array.isArray(stopConditionValue)) {
            antennas.forEach((item,index) => {
                if(stopConditionValue[index]) {
                    const path = validation.dynamicPath.replace('{index}', index);
                    if (stopConditionValue[index].value?.port !== '' && stopConditionValue[index].value?.port != null &&
                        (stopConditionValue[index].value?.port <  validation.range.min || stopConditionValue[index].value?.port >  validation.range.max)) {
                        errorValidations[path] = validation.range.errorMessage;
                    } else if(errorValidations[path]){
                        errorValidations[path] = '';
                    }
                }
            })
        }else if(stopConditionValue){
            if (stopConditionValue.port !== '' && stopConditionValue.port != null && (stopConditionValue.port < validation.range.min || stopConditionValue.port > validation.range.max)) {
                errorValidations[validation.path] = validation.range.errorMessage;
            } else if(errorValidations[validation.path]){
                errorValidations[validation.path] = '';
            }
        }
    }
    return errorValidations;
};

export const gpioledRequiredValidation = (validations, configurationForm, formFieldsError) => {
    let gpoFields = CONFIGURATION_VALIDATION_OBJECT.GPIOLED_FIELDS;
    let errors = {...formFieldsError};
    gpoFields.forEach(item => {
        const typePath = validations.typeDynamicPath.replace('{gpioled_condition}', item);
        const {value: typeValue} = getSafeValueOfPath(configurationForm[validations.field],typePath);
        const pathCondition = validations.dynamicPath.replace('{gpioled_condition}', item);
        const {value: valueCondition} = getSafeValueOfPath(configurationForm[validations.field], pathCondition);
        if(typeValue && (valueCondition == null || valueCondition+'' === '') && (typeValue === validations.typeValue || validations.typeValue === 'ANY')){
            errors[pathCondition] = validations.errorMessage;
        } else if(errors[pathCondition]){
            errors[pathCondition] = '';
        }
    });
    return errors;
}

export const rangeValidations = (formFieldErrors, validate, value) => {
    let errors = {...formFieldErrors};
    if (value != null && !isNaN(value) && (typeof value) === 'number') {
        if (!((validate.range.hasOwnProperty('min') ? parseInt(value) >= validate.range.min : true))) {
            errors[validate.path] = validate.range.errorMessageMinValue;
        } else if (!((validate.range.hasOwnProperty('max') ? parseInt(value) <= validate.range.max : true))) {
            errors[validate.path] = validate.range.errorMessageMaxValue;
        } else if ( errors[validate.path] ) {
            errors[validate.path] = '';
        }
    }
    return errors;
}

export const validateFieldRequired = (formFieldErrors, validate, value) => {
    let error= {...formFieldErrors};
    if(value==null||value===''){
        error[validate.path] = validate.errorMessage;
    } else {
        error[validate.path] = '';
    }
    return error;
}

export const xmlConfigurationValidation = (formFieldErrors, validate, value) => {
    let errors = {...formFieldErrors};
    let domParser = new DOMParser();
    let dom = domParser.parseFromString(value, 'text/xml');
    if (value && value.trim() !== '' && dom.getElementsByTagName('parsererror').length > 0) {
        errors[validate.path] = validate.errorMessage;
    }
    return errors;
}

export const validateUniqueNameFiled = (formFieldErrors, validate, value, values, caseSensitive) => {
    const error = { ...formFieldErrors };
    if (values?.some(item => (caseSensitive ? (item.name + '') : (item.name + '').toLowerCase()) === (caseSensitive ? (value + '') : (value + '').toLowerCase()))) {
        error[validate.path] = validate.errorMessage;
    } else {
        error[validate.path] = '';
    }
    return error;
};

export const removeArrayValidations = (formFieldErrors, key) => {
    let errors = {...formFieldErrors}
    Object.keys(errors).forEach(item => {
        if(item.indexOf(key) > -1){
            errors[item] = ''
        }
    })
    return errors;
}