import { getReportKeys } from '../hooks';

export const loadInputFile = (reportKey, reportId) => {
    return (dispatch) => {
        fetch(`../config/forms/${reportId}.json`).then(function(response){
        // await fetch(`../config/forms/${FileConfigName}.json`).then(function(response){
            return response.json();
        })
            .then(function(myJson) {
                dispatch({type: "FORM_ONE_PLATFORM_LOAD", values: myJson, nameFile: reportKey});
            });
    }

}

export const updateFormFieldValue=(IdSection,IdField,newValue)=>{
    return async (dispatch,getState) =>{
        const { reportKey } = getReportKeys(getState());
        dispatch({type: "UPDATE_FIELD_FORM_VALUE", reportKey:reportKey, IdSection:IdSection, IdField:IdField,newValue:newValue});
        }
}

export const modeUpdateFormField = () =>{
    return async (dispatch, getState) => {
        const { reportKey } = getReportKeys(getState());
        const selectedRow = getState().tableOnePlatform[reportKey].selectedRows;

        const index=Object.keys(selectedRow)[0];
        console.log({ reportKey, form: getState().formOnePlatform[reportKey] });
        let sectionField = getState().formOnePlatform[reportKey]?.add || {};

        Object.keys(sectionField).map(item =>
            {if(item!=='config'){
                Object.keys(sectionField[item].inputFields).forEach(field=>
                    {
                        dispatch(updateFormFieldValue(item,field,selectedRow[index][field]))
                    }
                )
              }
              return item;
            }
        )

    }
}

export const showDialogForm = (status,modeDialog) => ({type: "MODE_DIALOG_FORM", statusModalFormAddEdit: status,modeDialog:modeDialog});
export const showDialogErrorForm = (statusModalError,modeDialog,error) => ({type: "MODE_DIALOG_ERROR_FORM", statusModalError: statusModalError,modeDialog:modeDialog,error:error});

export const viewModalFormAddEdit = (status) =>{
    return (dispatch) => {
        dispatch({type:"VIEW_MODAL_FORM_OPTION",statusModalFormAddEdit:status})
    }
}
