import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

import StyledDialog from '../../util/Dialog';

import {DataEntryTabularField, DataEntryTabularForm} from '../../util/DataEntryTabularForm';

import ComboBox from './ComboBox';
import {generateComponentId} from "../../../utils/ComponentUtils";
import LinearProgress from '@material-ui/core/LinearProgress';

import IconButton from '@material-ui/core/Icon';
import NavigationExpandMore from '@material-ui/icons/ExpandMore';
import NavigationExpandLess from '@material-ui/icons/ExpandLess';
import {withStyles} from '@material-ui/core/styles';

class DynamicSelectInput extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            values: [],
            value: props.defaultValue
        };
        this.value = props.defaultValue;

        this.handleChange = this.handleChange.bind(this);
    }

    static get propTypes(){
        return {
            actions: PropTypes.object.isRequired,
            field: PropTypes.object.isRequired,
            onChange: PropTypes.func,
            onError: PropTypes.func.isRequired,
            defaultValue: PropTypes.any,
            otherValues: PropTypes.object
        };
    }

    static get defaultProps(){
        return {
            onChange: ()=>{}
        };
    }

    requestValues(props){
        props.actions.requestFieldValues(props.field.id,props.otherValues)
          .then(res=>{
              if(res.indexOf(this.state.value) === -1){ // current value not an option.
                  props.onChange(props.field.name,res[0]);
                  this.setState({
                      values:res,
                      value: res[0]
                  });

              }else{
                  this.setState({
                      values:res
                  });
              }
          })
          .catch(props.onError);
    }

    componentDidMount(){
        this.requestValues(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        for(var key in nextProps.otherValues){
            if(nextProps.otherValues[key] !== this.props.otherValues[key]){
                this.requestValues(nextProps);
                break;
            }
        }
        if(this.props.defaultValue !== nextProps.defaultValue){
            this.setState({
                value: nextProps.defaultValue
            });
        }
    }

    UNSAFE_componentWillUpdate(nextProps,nextState){
        this.value = nextState.value;
    }

    handleChange(e){
        let value = e.target.value;
        this.setState({
            value
        });
        this.props.onChange(this.props.field.name,value);
    }

    render(){
        const {value,values} = this.state;

        const options = values.map( (v,i) => (
          <option key={i}>{v}</option>
        ));

        return (
          <select id={this.props.id} onChange={this.handleChange} value={value}>
              {options}
          </select>
        );
    }
}


const ColoredLinearProgress = withStyles({
    colorPrimary:{
        backgroundColor: 'gray'
    },
    barColorPrimary: {
        backgroundColor: '#4b95af'
    }
})(LinearProgress);

class DynamicDataEntryDialog extends React.Component {

    constructor(){
        super();

        this.state = {
            tempValues: {},
            recordsDelete: null,
            showDetails: true,
            completedProgress: 0,
            anchorEl: null
        };

        this.generateRecordDelete = this.generateRecordDelete.bind(this);
        this.handleShowInformation = this.handleShowInformation.bind(this);
        this.showDeleteMultipleRecords = this.showDeleteMultipleRecords.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleError = this.handleError.bind(this);
        this.handleTempChange = this.handleTempChange.bind(this);
        this.generatingRecordDeleting = this.generatingRecordDeleting.bind(this);
        this.fieldRefs = {};
        this.associateAnchor = this.associateAnchor.bind(this);
    }

    static get propTypes(){
        return {
            open: PropTypes.bool,
            actions: PropTypes.object.isRequired,
            data: PropTypes.shape({
                meta: PropTypes.shape({
                    name: PropTypes.string.isRequired,
                    id: PropTypes.string.isRequired
                }),
                fields: PropTypes.object,
                reportRows: PropTypes.array
            })
        };
    }

    static get defaultProps(){
        return {
            open: false,
            data:{
                fields:{},
                reportRows:[]
            }
        };
    }

    extractFormId(props){
        return props.data && props.data.meta && props.data.meta.id;
    }

    requestFormFields(id){
        if(id) this.props.actions.requestFormFields(id);
    }

    generatingRecordDeleting(){
        const fields = this.props.data.fields && this.props.data.fields[this.extractFormId(this.props)];
        const data = this.props.data.reportRows;
        //conditional for deleting multiple records
        const conditionalDeleting = this.props.data.meta.type === 'DELETE' && this.props.data.reportRows.length > 1;
        let recordDeleting=null;
        if(data && conditionalDeleting){
            recordDeleting = [];
            data.forEach(datum =>
                {
                    let object = {};
                    fields.forEach(field =>
                        {
                            var defaultValue = field.defaultValue;
                            object[field.name] = datum[defaultValue];
                        }
                    )
                    recordDeleting.push(object);
                }
            )
        }
        return recordDeleting;
    }

    componentDidMount(){
        this.requestFormFields( this.extractFormId(this.props) );
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        var id = this.extractFormId(nextProps);
        if(id && id !== this.extractFormId(this.props)){
            this.requestFormFields(nextProps.data.meta.id);
        }

        if(nextProps.data.meta){
            const fields = nextProps.data.fields && nextProps.data.fields[this.extractFormId(nextProps)];
            const recordsDelete = this.generateRecordDelete(nextProps.data, id, fields);
            this.setState({recordsDelete});
        }
    }

    generateRecordDelete(propdata, id, fields){
        const data = propdata.reportRows;
        //conditional for Deleting Multiple records
        const conditionalDeleting= propdata.meta.type === 'DELETE' && propdata.reportRows.length > 1;
        let recordDeleting= null;

        if(data && fields && conditionalDeleting){
            recordDeleting= [];
            data.forEach(datum =>
                {
                    let object = {};
                    fields.forEach(field =>
                        {
                            var defaultValue = field.defaultValue;
                            object[field.name] = datum[defaultValue];
                        }
                    )
                    recordDeleting.push(object);
                }
            )
        }
        return recordDeleting;
    }

    handleFormSubmit (e) {
        if(e.keyCode === 13){
            this.handleSave()
        }
    }

    handleClose ()  {
        this.setState({completedProgress: 0, recordsDelete: null});
        this.props.actions.closeDataEntry();

    }

    handleError(err){
        throw err;
    }

    handleTempChange(name,value){
        this.setState({
            tempValues:{
                ...this.state.tempValues,
                [name]:value
            }
        });
    }

    getFieldValues(){
        var obj = {}, i, val;
        for(i in this.fieldRefs){
            val = this.fieldRefs[i].value;
            if(val){
                obj[i] = val.trim();
            }
        }
        return obj;
    }

    clear(){
        var obj = {}, i;

        const fields = this.props.data.fields && this.props.data.fields[this.extractFormId(this.props)];

        let fieldLookupByName = {};
        fields.forEach(field=>{
            fieldLookupByName[field.name] = field;
        });

        for(i in this.fieldRefs){
            switch(fieldLookupByName[i].displayType){
                case "MEMOBOX":
                case "EDIT":
                case "COLORBOX":
                case "COMBOBOX":
                    this.fieldRefs[i].value = fieldLookupByName[i].defaultValue;
                    break;
                default:
                    break;
            }
        }

        this.setState({
            somethingToTriggerARefresh: (new Date()).getTime()
        });

        return obj;
    }

    handleSave(){
        const {recordsDelete} = this.state;
        if(recordsDelete){
            this.props.actions.deleteArrayDataEntry(this.extractFormId(this.props),recordsDelete);
        }else{

            const values = this.getFieldValues();
            let _then = this.handleClose;
            if(this.refs.another && this.refs.another.checked){
                _then = ()=>{
                    this.clear();
                };
            }
            this.props.actions.runDataEntry(
            this.extractFormId(this.props),
            values
            ).then(_then);
        }
    }

    autoRef(id,ref){
        this.fieldRefs[id] = ref;
    }

    renderField(field){
        var defaultValue = field.defaultValue || "";
        if(field.defaultSource === "REPORT_ROW" && this.props.data.reportRows[0]){
            defaultValue = this.props.data.reportRows[0][defaultValue] || "";
        }

        var textareaStyles={
            width:'100%',
            boxSizing:'border-box',
            lineHeight:'2em',
            fontSize:'0.9em',
            resize:'none'
        };

        var innerInput = null;
        switch(field.displayType){
            case "NO_DISPLAY":
            case "COLORBOX_READONLY":
            case "ICON_WITHREPOSITORY": // disabling these types of fields for now...
                return (
                  <tr key={field.id}>
                      <td >
                          <input id={generateComponentId(field.name, null, "text")} type="hidden" ref={this.autoRef.bind(this,field.name)} value={defaultValue}/>
                      </td>
                  </tr>
                );

            case "DISPLAY":
                innerInput = (
                  <div>
                      <input id={generateComponentId(field.name, null, "text")} type="hidden" ref={this.autoRef.bind(this,field.name)} value={defaultValue}/>
                      {defaultValue}
                  </div>
                );
                break;

            case "MEMOBOX":
                innerInput =  (
                  <textarea id={generateComponentId(field.name, null, "text")} ref={this.autoRef.bind(this,field.name)} style={textareaStyles} defaultValue={defaultValue}/>
                );
                break;

            case "MEMOBOX_NOEDIT":
                innerInput =  (
                  <textarea id={generateComponentId(field.name, null, "text")} ref={this.autoRef.bind(this,field.name)} style={textareaStyles} defaultValue={defaultValue} disabled/>
                );
                break;

            case "COMBOBOX":
                innerInput = (
                  <ComboBox
                    id={generateComponentId(field.name, null, "dropdown")}
                    ref={this.autoRef.bind(this,field.name)}
                    field={field}
                    defaultValue={defaultValue}
                    actions={this.props.actions}
                    onError={this.handleError}
                    onChange={this.handleTempChange}
                    otherValues={this.state.tempValues}
                  />
                );
                break;

            case "COMBOBOX_NOEDIT":
                innerInput = (
                  <DynamicSelectInput
                    id={generateComponentId(field.name, null, "dropdown")}
                    ref={this.autoRef.bind(this,field.name)}
                    actions={this.props.actions}
                    field={field}
                    defaultValue={defaultValue}
                    onError={this.handleError}
                    onChange={this.handleTempChange}
                    otherValues={this.state.tempValues}
                  />
                );
                break;

            case "COLORBOX":
            case "EDIT":
            default:
                innerInput =  (
                  <input id={generateComponentId(field.name, null, "text")} type={"text"} ref={this.autoRef.bind(this,field.name)} defaultValue={defaultValue}/>
                );
                break;
        }

        return (
          <DataEntryTabularField key={field.id} label={field.caption}>
              {innerInput}
          </DataEntryTabularField>
        );
    }

    handleShowInformation(){
        this.setState({showDetails: !this.state.showDetails})
    }

    showDeleteMultipleRecords(){
        const {recordsDelete} = this.state;
        const {informationDataDeleted} = this.props;
        const numberRecords = recordsDelete.length;
            let message = `You have selected ${numberRecords} records. `;
            let messageInformation = (informationDataDeleted.length > 0)? `${informationDataDeleted.length} of ${recordsDelete.length} `:'';

            let numberCorrectResults = informationDataDeleted.filter(record => record.status === 200).length;
            let numberNoCorrectResults = informationDataDeleted.filter(record => record.status !== 200).length;

            let messagenoCorrectResults = informationDataDeleted.filter(record => record.status !== 200).map(filtered => filtered.message);
            //remove duplicate messages
            messagenoCorrectResults = messagenoCorrectResults.filter((item,i,ar)=> ar.indexOf(item) === i);

            let noCorrectResults = messagenoCorrectResults.map(item =>
                ({number: informationDataDeleted.filter(record => record.message === item).length , message: item})
            );
            let labelState = (noCorrectResults.length > 0)? 'Failed':'Completed';

            return (
                <div style={{minWidth: '350px'}}>
                    {informationDataDeleted.length === 0? <>
                    {message}
                    Are you sure you want to delete the selected records?
                    </>:                    
                    <div>
                        <div style={{marginTop:'3px', marginBottom:'3px'}} >
                            <div style={{fontSize: '14px',marginBottom:'2px'}}> {(recordsDelete.length > informationDataDeleted.length) ?'Processing':labelState}</div>
                            <ColoredLinearProgress variant="determinate" style={{height:'8px'}} value={(informationDataDeleted.length/numberRecords)*100} min={0} max={recordsDelete.length} />
                            <div> <span style={{float:'right', fontSize: '14px'}}>{messageInformation}</span>  </div>
                        </div>
                        <div style={{fontSize: '14px',marginTop:'12px',marginBottom:'5px'}}>
                            <span style={{marginRight:'5px', display: 'inline-block', verticalAlign: 'middle'}} >More Information</span>
                            <div style={{display: 'inline-block', verticalAlign: 'middle'}}>
                                <IconButton onClick={this.handleShowInformation} fontSize={"small"}  style={{color: '#2C3335', backgroundColor: 'transparent'}} >
                                    {(this.state.showDetails)?<NavigationExpandLess fontSize='inherit' />: <NavigationExpandMore fontSize='inherit' />}
                                </IconButton>
                            </div>
                        </div>
                        {(this.state.showDetails) &&
                        <div style={{backgroundColor:'#f0f0f0',padding:'1px'}}>
                            <ul style={{fontSize: '14px',  listStyleType: 'none', padding: '0px', marginLeft:'5px',marginRight:'4px'}} >
                                <li>
                                    <strong>Deleted: </strong> {numberCorrectResults} records
                                </li>
                                <li>
                                    <strong>Not Deleted: </strong> {numberNoCorrectResults} records
                                    <br />
                                    <strong style={{paddingLeft:'12px'}} >Errors :</strong>
                                    <ul style={{fontSize: '14px', padding: '0px', marginLeft:'30px'}}>
                                        {noCorrectResults.map((item, index) =>
                                            <li key={index} >{item.number} records: {item.message} </li>
                                        )}
                                    </ul>
                                </li>
                            </ul>
                        </div>}
                    </div>}
                </div>
            );
    }

    renderForm(fields){
        this.fieldRefs = {};
        const {recordsDelete} = this.state;
        if(recordsDelete){
            return(this.showDeleteMultipleRecords());
        }else{
            return (
                <DataEntryTabularForm fullWidth>
                    {fields.map(this.renderField.bind(this))}
                </DataEntryTabularForm>
              );
        }
    }

    associateAnchor(ref){
        this.setState({
            anchorEl: ref
        });
    }

    render() {
        const {
            actions, // eslint-disable-line
            informationDataDeleted,
            ...other
        } = this.props;

        const {recordsDelete} = this.state;
        const meta = this.props.data && this.props.data.meta;
        const fields = this.props.data.fields && this.props.data.fields[this.extractFormId(this.props)];
        if(!meta || !fields) return null;
        const SAVE_LABELS = {
            "DELETE" : "Delete"
        };

        //let anchor = this.state.anchorEl;
        const applyDiv = meta.type === "ADD" ? (
          <div style={{display:'inline-block', fontSize:'0.8em',verticalAlign:'middle',paddingRight:'1em',letterSpacing:'-0.5px'}}>
              <input type={"checkbox"} style={{verticalAlign:'middle'}} ref="another" id="add_another"/>
              <label style={{verticalAlign:'middle',userSelect:'none'}} htmlFor="add_another"> ADD ANOTHER </label>
          </div>
        ) : null;

        const mainButtons = (
          <div>
              {applyDiv}
              {(informationDataDeleted.length > 0)?
                <Button id={"closeButton"} onClick={this.handleClose}>Close</Button>:  
                <Button id={"saveButton"} style={{color:'#00779F'}}
                onClick={this.handleSave}>{SAVE_LABELS[ meta.type] || "Save"}</Button>
              } 
                <Button 
                    id={"cancelButton"} 
                    label="Cancel" 
                    style={{display:(recordsDelete && informationDataDeleted.length > 0)?'none':null, position: 'absolute', left: 8}} 
                    onClick={this.handleClose}>Cancel</Button>
          </div>

        );
        return (
          <StyledDialog
            title={meta.name}
            /*modal={false}*/
            open={this.props.open}
            onClose={this.handleClose}
            onRequestClose={this.handleClose}
            bodyStyle={{overflowY:'auto'}}
            actions={mainButtons}
            {...other}
          >
            <form onKeyDown={this.handleFormSubmit} style={{maxWidth: 350}}>
                {this.renderForm(fields)}
            </form>
          </StyledDialog>
        );
    }
}

export default DynamicDataEntryDialog;