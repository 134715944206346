import {
    CHANGE_BATCH_FILTER_VALUE,
    CHANGE_FILTER_VALUE,
    //CHANGE_SEARCHES,
    CHANGE_SPECIAL_FILTER_VALUE,
    CLEAN_FILTERS,
    LOAD_FILTER_VALUES,
    LOAD_FILTERS,
    LOAD_REPORT,
    LOAD_SAVED_REPORT,
    START_LOADING,
    CHANGE_FILTER_STATE,
    CHANGE_DATE_RANGE, UPDATE_ONE_TIME_DEFAULT_FILTER_VALUE
} from '../constants/ActionTypes';
import moment from 'moment/moment.js';
import {arrayToObject} from '../utils/Misc';
// import {WILDCARD_ALL} from '../constants/Misc';
let today = () => moment().startOf('day');
let tomorrow = () => today().add(1, 'days');


const importState = {
    importReport:{
        id:"importReport",
        defaultValue: '%',
        displayName:'Report',
        isAllAllowed: true,
        name:'report',
        type:'normal',
        value: '%',
        filterSelector: 'reportName',
        sort: 'asc'
    },
    importAction:{
        id:"importAction",
        defaultValue: '%',
        displayName:'Action',
        isAllAllowed: true,
        name:'action',
        type:'normal',
        value: '%',
        filterSelector: 'formName',
        sort: 'asc'
    },
    importImportedBy:{
        id:"importImportedBy",
        defaultValue: '%',
        displayName:'Imported by',
        isAllAllowed: true,
        name:'importedBy',
        type:'normal',
        value: '%',
        filterSelector: 'user'
    },
    importFromDate:{
        id:"importFromDate",
        type: "datetime",
        name: "fromImportDate",
        displayName:'Imported From',
        isAllAllowed: false,
        defaultValue: today(),
        value: today(),
        filterSelector: 'fromValue'
    },
    importToDate:{
        id:"importToDate",
        type: "datetime",
        name: "toImportDate",
        displayName:'Imported To',
        isAllAllowed: false,
        defaultValue: tomorrow(),
        value: tomorrow(),
        filterSelector: 'toValue'
    }
}

const initialState = {
    ...importState,
    loadingValues: false,
    datetimeFilters:{},
    oneTimeDefaultFilterValue: {},
};

function filter(state,action){

    switch(action.type){
        case LOAD_FILTER_VALUES:
            return {
                    ...state,
                options: action.values,
                value: state.value,
            };

        case CHANGE_FILTER_VALUE:
            if (state.type === 'normal' || state.type === 'inequality' || state.type === 'lookup'){
                let searches = state.searches ? state.searches.filter(item => item !== action.value) : [];
                return {
                    ...state,
                    value: action.value,
                    searches: [action.value,...searches.slice(0,4)]
                };
            }else if (state.type === 'multiselect'){
                // CONDITION FOR SAVED REPORTS
                if(isJsonString(action.value) && JSON.parse(action.value).values){
                    let valuesArray = JSON.parse(action.value).values;
                    let searches = state.searches ? state.searches.filter(item => item !== valuesArray.toString()): [];
                    return {
                        ...state,
                        value: action.value,
                        searches: [valuesArray.toString(), ...searches.slice(0,4)]
                    }
                }
                const values = Array.isArray(action.value) ? action.value.map(item => '"'+item+'"') : action.value;
                const valueFilter=Array.isArray(values) ? `{"values": [${values.toString()}]}` : values;
                let searches = state.searches ? state.searches.filter(item => item !== action.value.toString()) : [];
                return {
                    ...state,
                    value: valueFilter,
                    searches: Array.isArray(action.value) ? [...new Set([action.value.toString().replace(/\\/g, "").replace(/'/g, ""),...searches.slice(0,4)] ) ]: [action.value,...searches.slice(0,4)]
                };
            }else{
                return {
                    ...state,
                    value: action.value
                };
            }
        case CHANGE_FILTER_STATE:
            return {
                ...state,
                isEnabled: action.isEnabled
            };
        default:
            return null;
    }
}

function synthesizeFilterDefinitions(filters, state){

    let filterNameMap = arrayToObject(filters,"name");
    let filterIdMap = arrayToObject(filters,"id");

    filters.forEach(filter => {
        let constraints = filter.constraints;
        constraints.forEach(constraint => {
            var master = filterNameMap[ constraint ];
            if(master){
                master.dependents = master.dependents || {};
                master.dependents[filter.id] = filter.name;
            }
        });
    });
    for(let f in filterIdMap){
        filterIdMap[f] = {
            ...state[f],
            ...filterIdMap[f],
        };
    }
    return filterIdMap;
}

function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function filters(state = initialState,action){
    switch(action.type){
        case LOAD_REPORT:
            return {
                ...state,
                ...synthesizeFilterDefinitions( action.reportDefinition.filters, state )
            };

        case LOAD_SAVED_REPORT:
            return Object.keys(action.report.filterValues || {}).reduce((state,filterId)=>{
                state[filterId] = filter( state[filterId], { type:CHANGE_FILTER_VALUE, value:action.report.filterValues[filterId] } );
                return state;
            },{
                ...state,
            });

        case LOAD_FILTER_VALUES:
        case CHANGE_FILTER_STATE:
        case CHANGE_FILTER_VALUE:
            return {
                ...state,
                loadingValues: false,
                [action.filterId]: filter(state[action.filterId],action)
            };
        case CHANGE_SPECIAL_FILTER_VALUE:

            const newDatetimeFilters={...state.datetimeFilters,...action.datetimeFilters};
            return {
                ...state,
                datetimeFilters:newDatetimeFilters,

            };
        case UPDATE_ONE_TIME_DEFAULT_FILTER_VALUE:
            return {
                ...state,
                oneTimeDefaultFilterValue:action.oneTimeDefaultFilterValue,

            };
        case CLEAN_FILTERS:
            return{
                ...initialState
            }
        case CHANGE_BATCH_FILTER_VALUE:

            return {
                ...state,
                ...action.filters,
            };
        // Set filters to store from "browser local storage".
        case LOAD_FILTERS:
            return {
                ...initialState,
                ...action.filters
            };
        case START_LOADING:
            return{
                ...state,
                loadingValues: true
            };
        case CHANGE_DATE_RANGE:
            return{
                ...state,
                daysRange: action.daysQty
            }
        default:
            return state;

    }
}
