export const TABLE_ONE_PLATFORM_REPLACE_COLUMNS = 'TABLE_ONE_PLATFORM_REPLACE_COLUMNS';
export const TABLE_ONE_PLATFORM_REPLACE_SORT = 'TABLE_ONE_PLATFORM_REPLACE_SORT';
export const TABLE_ONE_PLATFORM_REPLACE_SELECTED_ROWS = 'TABLE_ONE_PLATFORM_REPLACE_SELECTED_ROWS';
export const TABLE_ONE_PLATFORM_REPLACE_FOCUSED_ROWS = 'TABLE_ONE_PLATFORM_REPLACE_FOCUSED_ROWS';
export const TABLE_ONE_PLATFORM_REPLACE_HOVERED_ROWS = 'TABLE_ONE_PLATFORM_REPLACE_HOVERED_ROWS';
export const TABLE_ONE_PLATFORM_UPDATE_WIDTH_COLUMN = 'TABLE_ONE_PLATFORM_UPDATE_WIDTH_COLUMN';
export const TABLE_UPDATE_DATA = 'TABLE_UPDATE_DATA';
export const TABLE_ONE_PLATFORM_ALL_REPLACE_COLUMNS = 'TABLE_ONE_PLATFORM_ALL_REPLACE_COLUMNS';
export const TABLE_ONE_PLATFORM_REPLACE_CONFIG_COLUMNS = 'TABLE_ONE_PLATFORM_REPLACE_CONFIG_COLUMNS';
export const TABLE_ONE_PLATFORM_REPLACE_HEADERS_COLUMNS_SORT_FILTERS_FILTER = 'TABLE_ONE_PLATFORM_REPLACE_HEADERS_COLUMNS_SORT_FILTERS_FILTER';
