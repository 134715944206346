import { useSelector } from 'react-redux';
import { Paper, Toolbar } from '@material-ui/core';
import ZBToolbarTool from '../../vss/app/views/util/toolbar/ToolbarTool';
import ZoomInIcon from '@material-ui/icons/AddBox';
import CenterIcon from '@material-ui/icons/CenterFocusStrong';
import ZoomOutIcon from '@material-ui/icons/IndeterminateCheckBox';
import { I18n } from 'react-redux-i18n';
import { ZOOM_INCREMENT } from '../../vss/app/constants/SiteManager';
import { centerMap, zoomMapByDeltaWithDuration } from '../../vss/app/utils/ol/OlUtils';

const translate = (key) => I18n.t('common.' + key);

export function PositionToolbar({ configRef }) {
    
    const onDisplaySites = useSelector(state => state.sites.layout.sitesPanel);
    
    const validMap = () => !!configRef?.mapRef && !!(configRef?.mapRef.getView()) && !!configRef?.mapExtent;
    
    const handleZoomIn = () => validMap() && zoomMapByDeltaWithDuration(
        configRef?.mapRef,
        configRef?.mapExtent,
        ZOOM_INCREMENT,
    );
    
    const handleZoomOut = () => validMap() && zoomMapByDeltaWithDuration(
        configRef?.mapRef,
        configRef?.mapExtent,
        -ZOOM_INCREMENT,
    );
    
    const handleCenterMap = () => validMap() && centerMap(configRef?.mapRef, configRef?.mapExtent);
    
    const PositionMapTool = props => (
        <ZBToolbarTool
            disabled={!validMap()}
            isVertical
            toolTipOnRight={!onDisplaySites}
            {...props}
        />
    );
    
    return (
        <Paper className="position-toolbar-container" elevation={0}>
            <Toolbar className="position-toolbar" disableGutters>
                <PositionMapTool
                    title={translate('Zoom In')}
                    icon={<ZoomInIcon />}
                    onClick={handleZoomIn}
                />

                <PositionMapTool
                    title={translate('Zoom Out')}
                    icon={<ZoomOutIcon />}
                    onClick={handleZoomOut}
                />

                <PositionMapTool
                    title={translate('Fit Map to Screen')}
                    icon={<CenterIcon />}
                    onClick={handleCenterMap}
                />
            </Toolbar>
        </Paper>
    );
}
