import {
    TABLE_ONE_PLATFORM_ALL_REPLACE_COLUMNS,
    TABLE_ONE_PLATFORM_REPLACE_COLUMNS,
    TABLE_ONE_PLATFORM_REPLACE_CONFIG_COLUMNS,
    TABLE_ONE_PLATFORM_REPLACE_FOCUSED_ROWS, TABLE_ONE_PLATFORM_REPLACE_HEADERS_COLUMNS_SORT_FILTERS_FILTER,
    TABLE_ONE_PLATFORM_REPLACE_HOVERED_ROWS,
    TABLE_ONE_PLATFORM_REPLACE_SELECTED_ROWS,
    TABLE_ONE_PLATFORM_REPLACE_SORT,
    TABLE_ONE_PLATFORM_UPDATE_WIDTH_COLUMN,
    TABLE_UPDATE_DATA,
} from '../constants/ActionTypes';
import { getLookup } from '../utils';

const SIZE = 10000;
const PAGE = 0;

export const tableOnePlatform = (state = {}, action) => {
    const newState = { ...state };
    switch (action.type) {
        case TABLE_ONE_PLATFORM_REPLACE_COLUMNS:
            newState[action.page] = {
                ...newState[action.page],
                headerColumns: action.headerColumns,
                maxRowSize: SIZE,
                pageSelect: PAGE,
            };
            return newState;
        case TABLE_ONE_PLATFORM_ALL_REPLACE_COLUMNS:
            Object.keys(action.tableOnePlatform).forEach(reportKey => {
                newState[reportKey] = { ...action.tableOnePlatform[reportKey] };
            });
            return newState;
        case TABLE_ONE_PLATFORM_REPLACE_SORT:
            newState[action.page] = {
                ...newState[action.page],
                sort: [...action.sort],
            };
            return newState;
        case TABLE_ONE_PLATFORM_REPLACE_SELECTED_ROWS:
            newState[action.page] = {
                ...newState[action.page],
                selectedRows: { ...action.selectedRows },
            };
            return newState;
        case TABLE_ONE_PLATFORM_REPLACE_FOCUSED_ROWS:
            newState[action.page] = {
                ...newState[action.page],
                focusedRows: { ...action.focusedRows },
            };
            return newState;
        case TABLE_ONE_PLATFORM_REPLACE_HOVERED_ROWS:
            newState[action.page] = {
                ...newState[action.page],
                hoveredRows: { ...action.hoveredRows },
            };
            return newState;
        case TABLE_ONE_PLATFORM_UPDATE_WIDTH_COLUMN:
            const headerColumns = [...state[action.page].headerColumns];
            headerColumns[action.columnIndex].width = action.width;
            state[action.page].headerColumns = headerColumns;
            return state;
        case TABLE_UPDATE_DATA:
            if (!action.uKeyFields) {
                console.error('No unique key field configured');
            }
            const newSelectedRows = {};
            const newData = action.data.map((row, index) => {
                let ___uKey = index;
                if (action.uKeyFields) {
                    ___uKey = getLookup(row, action.uKeyFields);
                }
                const tableRow = {
                    ...row,
                    ___index: index,
                    ___uKey,
                };
                if(row.hasOwnProperty('groupRowIndex')){
                    tableRow.groupUKey = ___uKey;
                }
                //  Revalidating selected rows
                if (newState[action.reportKey]?.selectedRows?.[___uKey]) {
                    newSelectedRows[___uKey] = tableRow;
                }
                return tableRow;
            });
            newState[action.reportKey] = {
                ...newState[action.reportKey],
                data: newData,
                selectedRows: newSelectedRows,
            };
            return newState;
        case TABLE_ONE_PLATFORM_REPLACE_CONFIG_COLUMNS:
            newState[action.reportKey].columnsConfig = action.columnsConfig;
            return newState;
        case TABLE_ONE_PLATFORM_REPLACE_HEADERS_COLUMNS_SORT_FILTERS_FILTER:
            newState[action.reportKey].headerColumns = action.headerColumns;
            if(action.updateSort){
                newState[action.reportKey] = {
                    ...newState[action.reportKey],
                    sort: [...action.sort],
                };
            }

            return newState;
        default:
            return state;
    }
};
