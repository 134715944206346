import openSocket from 'socket.io-client';
import {STREAM_SERVICE_HOST, STREAM_SERVICE_PATH} from '../../../constants/Misc'

let socket;

function registerEvents(cb, updateStatusCallback) {
  socket.on('disconnect', function(){
    updateStatusCallback(false);
  });
  socket.on('reconnect', function(){
    updateStatusCallback(true);
  });
  socket.on('blinks', data => cb(null, data));
  socket.emit('subscribeToBlinks');
}

function subscribeToWS(cb, updateStatusCallback) {
  if(!socket) {
      let JWT_TOKEN = window.localStorage.getItem("jwtToken");
      socket = openSocket(STREAM_SERVICE_HOST, {
        path: STREAM_SERVICE_PATH,
        query: {
          jwt: JWT_TOKEN
        }
      });
  }
  registerEvents(cb, updateStatusCallback)
  socket.on('reconnect', function () {
    registerEvents(cb, updateStatusCallback)
  })
}

function unsubscribeToWS() {
  if(socket) {
    socket.emit('unsubscribeToBlinks');
    socket.removeAllListeners();
    socket.close();
    socket = null;
  }
}

function subscribeToCloseWindow(){
    window.onbeforeunload = function() {
        unsubscribeToWS();
    };
}

function unsubscribeToCloseWindow(){
    window.onbeforeunload = null;
}



export { subscribeToWS, unsubscribeToWS, subscribeToCloseWindow ,unsubscribeToCloseWindow };