/* jshint esversion: 6 */

import React from 'react';
import PropTypes from 'prop-types';
//openlayers
import Select from 'ol/interaction/Select';
import {click, pointerMove} from 'ol/events/condition'


/**
 * Simple select interaction component.  When included, it will call its onSelect
 * function when features are hovered over in the map.
 */
class Interaction extends React.Component{

    /**
     * @private
     */
    constructor(props){
        super(props);

        /**
         * @type {Object}
         * @property {function} onSelect Fires when features are selected.  Should accept an array of features.
         */
        this.props = props;
    }

    /**
     * @private
     */
    static get propTypes(){
        return {
            map: PropTypes.any, // passed in by <Map />
            onSelect: PropTypes.func,
            onFeatureHover: PropTypes.func,
            onFeatureClick: PropTypes.func
        };
    }

    /**
     * @private
     * Runs after mounting.
     */
    componentDidMount(){
        if(!this.props.map) return;

        this.interactions = {

        };

        // Feature -- Hover
        if(this.props.onFeatureHover){
            let i = this.interactions.featurehover = new Select({
                condition: pointerMove
            });

            this.props.map.addInteraction(i);

            i.on('select',function(e){
                this.props.onFeatureHover(e.selected);
            }.bind(this));
        }

        // Feature -- Click
        if(this.props.onFeatureClick){
            let i = this.interactions.featureclick = new Select({
                condition: click
            });

            this.props.map.addInteraction(i);

            i.on('select',function(e){
                this.props.onFeatureClick(e.selected);
            }.bind(this));
        }


    }

    /**
     * @private
     * Renders the UI to the Virtual DOM (which updates the actual DOM)
     */
    render(){
        return (<div />);
    }
}

export default Interaction;
