import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Button, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import StyledDialog, { CancelButton } from '../../../util/Dialog';
import { useZoneBuilderState } from '../../../../hooks';
import { refStore } from '../../../../actions';
import { ItemType, SITE_MANAGER_CONFIGURATION_ZONES_SCOPE } from '../../../../../../constants';
import { useSiteManager } from '../../../../../../hooks';

const translate = (key) => I18n.t('common.' + key);
const MAX_LIMIT_NEW_ZONES = 1000;
const useStyles = makeStyles({
    checked: {
        color: '#007CB0 !important'
    },
    tooltip: {
        color: '#000000',
        backgroundColor: '#E6EE31',
        fontSize: '14px'
    },
    arrow: {
        color: '#E6EE31'
    },
    popper: {
        zIndex: 0
    },
    paperDialog: {
        overflowY: 'visible'
    }
});

const DialogReplicateZones = props => {
    const classes = useStyles();
    const { onClose, open } = props;
    const { selectedZoneIds } = useZoneBuilderState();
    const { mapPoint1X, mapPoint2X, mapPoint1Y, mapPoint2Y } = useSelector(state => state.reportMap.mapConfiguration) || {};
    const relativeOffset = Math.max((mapPoint2X - mapPoint1X), (mapPoint2Y - mapPoint1Y)) * 0.03;
    const initialState = useMemo(() => {
        const maxWidthFeature = refStore.configZonesMap.getMaxWidthSelectedZone();
        return { xOffset: maxWidthFeature, yOffset: relativeOffset, replicas: 1 };
    }, [relativeOffset]);
    const { items } = useSiteManager(SITE_MANAGER_CONFIGURATION_ZONES_SCOPE);
    const [payload, setPayload] = useState({ ...initialState });
    const [step, setStep] = useState(1);
    const [errorMessage, setErrorMessage] = useState('');
    
    const zones = items[ItemType.ZONE] || [];
    
    useEffect(() => {
        if (step > 1) {
            if (payload.replicas * selectedZoneIds.length <= MAX_LIMIT_NEW_ZONES) {
                const { valid } = refStore.configZonesMap.drawReplicateZones(payload);
                if (valid) {
                    setErrorMessage('');
                } else {
                    setErrorMessage('Some replicated zones will be outside of the map boundary');
                }
            }
        } else {
            setErrorMessage('');
            refStore.configZonesMap.clearZoneDraftFeatures();
        }
    }, [step, payload, selectedZoneIds.length]);
    
    const changeParam = event => {
        const { name, value } = event.target;
        if (name === 'replicas') {
            setPayload(oldState => ({ ...oldState, [name]: Math.max(+value, 0) }));
        } else {
            setPayload(oldState => ({ ...oldState, [name]: +value }));
        }
    };
    
    const closeDialog = (event, reason) => {
        if (reason && reason === 'backdropClick') {
            return;
        }
        onClose();
        refStore.configZonesMap.clearZoneDraftFeatures();
    };
    
    const actionButtons = () => {
        const { replicas } = payload;
        return (
            <div style={{ width: '100%' }}>
                <Button label={translate('Previous')} style={{ width: 'auto' }} onClick={() => setStep(step - 1)}
                        disabled={step === 1}>
                    {'< ' + translate('Previous')}
                </Button>
                <Button label={translate('Next')} onClick={() => setStep(step + 1)}
                        disabled={step >= 3 || !selectedZoneIds.length}>
                    {translate('Next') + ' >'}
                </Button>
                <CancelButton style={{ float: 'right' }} onClick={closeDialog} />
                <Button label={translate('Finish')} style={{ float: 'right' }}
                        disabled={step < 3 || !replicas || replicas < 1 || replicas * selectedZoneIds.length > MAX_LIMIT_NEW_ZONES}
                        onClick={() => {
                            refStore.configZonesMap.handleReplicateZones(payload);
                            closeDialog();
                        }}
                >
                    {translate('Finish')}
                </Button>
            </div>
        );
    };
    
    const renderContentDialog = () => {
        const { replicas, xOffset, yOffset } = payload;
        const selectedZones = zones.filter(zone => selectedZoneIds?.includes(zone.id)).map(zone => zone.name);
        
        if (step === 1) {
            return (
                <div className="content">
                    <div className="description">
                        1. Select the zone you wish to replicate by clicking on it, in the map.
                    </div>
                    <div className="data-content names">
                        Selected zone{selectedZoneIds?.length > 1 ? 's' : ''}: <div
                        className="name-zones">{selectedZones?.join(', ')}</div>
                    </div>
                </div>
            );
        } else if (step === 2) {
            return (
                <div className="content">
                    <div className="description">
                        2. The offsets to be used in replication can be set by moving the selected polygon to the next desired
                        location, or by entering in the offsets below.
                    </div>
                    <div className="data-content">
                        <div className="offset">
                            X offset: <input
                            type="number"
                            name="xOffset"
                            value={xOffset}
                            onChange={changeParam}
                        />
                        </div>
                        <div className="offset">
                            Y offset: <input
                            type="number"
                            name="yOffset"
                            value={yOffset}
                            onChange={changeParam}
                        />
                        </div>
                    </div>
                    <div style={{ height: '1em', color: 'red' }}>
                        {errorMessage}
                    </div>
                </div>
            );
        } else if (step === 3) {
            return (
                <div className="content">
                    <div className="description">
                        3. Specify the number of zones to be replicated. Each subsequent zone will be placed at the specified
                        offset from the previous zone.
                    </div>
                    <div className="data-content">
                        Number of zones to replicate:
                        <Tooltip
                            open={replicas * selectedZoneIds.length > MAX_LIMIT_NEW_ZONES}
                            arrow={true}
                            title={`Max replicate zones number (${MAX_LIMIT_NEW_ZONES}) was reached`}
                            placement={'top'}
                            classes={{ tooltip: classes.tooltip, arrow: classes.arrow, popper: classes.popper }}
                            PopperProps={{ disablePortal: true }}
                        >
                            <input
                                type="number"
                                name="replicas"
                                value={replicas}
                                onChange={changeParam}
                            />
                        </Tooltip>
                    </div>
                    <div style={{ height: '1em', color: 'red' }}>
                        {errorMessage}
                    </div>
                </div>
            );
        } else {
            return null;
        }
    };
    
    return (
        <StyledDialog
            title="Replicate a zone"
            open={open}
            onClose={closeDialog}
            contentStyle={{ width: 450 }}
            maxWidth={'md'}
            className="replicate-zones-dialog"
            actions={actionButtons()}
            isDraggable
            hideBackdrop
        >
            {renderContentDialog()}
        </StyledDialog>
    );
};

export default DialogReplicateZones;
