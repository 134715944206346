import {
    SELECT_ZONE,
    SELECT_ZONES,
    CLICK_ZONE_PAGE_SECTION,
    ADD_ZONE,
    UPDATE_ZONE,
    DELETE_ZONE,
    CHANGE_ZONE_PROPERTY,
    COPY_ZONE,
    LOAD_ZONES,
    PUBLISH_ZONES,
    SAVE_ZONES,
    UPDATE_ZONE_PROPERTY_SCHEMA,
    LOAD_PUBLISHED_ZONES_FOR_MAP,
    CHANGE_FILTER,
    // UNDO,
    // REDO,
    UPDATE_ZONE_GROUPS,
    TOGGLE_MAP_INTERACTION,
    TOGGLE_MULTI_INTERACTION,
    ADD_MULTI_ZONES,
    CHANGE_ACTIVE_TAB,
    SELECT_ZONE_GROUP,
    PATCH_REPLICATE_ZONES_DATA,
    REPLACE_ZONES,
    UPDATE_ZONES,
    OPEN_FORM_ZONE_GRID,
    LOAD_STAGING_ZONES_FOR_MAP, CLEAR_DIRTY,
} from '../constants/ActionTypes/zone-builder';
import Zone from '../utils/ZoneModel';
import { SITE_MANAGER_TABS } from '../views/sites/SitesMapPanelInfrastucture';
import {getShape, ZONE_LOCAL_DATA} from '../actions';
import {DEFAULT_GRID_MATRIX_VALUE} from "../views/sites/designer/constants";
import {REPLACE_ITEMS} from "../../../store/action-types";
import {LOAD_ZONES_FOR_MAP} from "../constants/ActionTypes";
const initialState = {
    selectedZoneIds: [],
    selectedZoneGroup: "",
    interactions: {}, // map interactions that don't overlap
    multinteractions: {}, // interactions that might overlap (2 or more at the same time)
    section: null,
    zones: {},
    zonesPrev: {},
    clipboardZone:null,

    zoneBackSnapshots: [],
    zoneForwardSnapshots: [],

    published: false,
    dirty: false,

    publishedZonesByMap:{},

    propertiesSchema: {},

    activeFilter:{},

    zoneGroups: [],
    activeTab: SITE_MANAGER_TABS.MAP,
    
    replicateZonesData: {},
    grid: {
        anchorEl: null,
        matrix: DEFAULT_GRID_MATRIX_VALUE,
        open: false,
        features: [],
        autoname: ""
    },
};

function zone(state,action){
    //let _state = {...state};
    //delete _state.geojson;
    switch(action.type){

        case ADD_ZONE:
            return action.zone;

        case UPDATE_ZONE: console.log("UPDATE_ZONE")
            return new Zone({
                ...(state?.data||{}),
                ...action.updates
            });
        default:
            return state;
    }
}

function createZoneHashMap(zones){
    return zones.reduce((o,z)=>{
        o[z.data._key] = z;
        return o;
    },{});
}

function firstSelectedZoneId(state){
    return state.selectedZoneIds && state.selectedZoneIds[0];
}

export function selectSelectedZones(state) {
    const zones = state.zones;
    const selectedIdZones = state.selectedZoneIds;
    const selectedZones = [];
    selectedIdZones.forEach(selectedIdZone => {
        selectedZones.push(zones[selectedIdZone]);
    });
    return selectedZones;
}

function castWithSchema(zones,schema){
    let adjZones = {...zones};

    for(let _key in zones){
        let zone = zones[_key];
        let properties = {...zone.data.properties};
        for(let p in schema){

            if(properties[p]) continue;

            if(schema[p].type === "DROPDOWN"){
                properties[p] = schema[p].values[0];
                continue;
            }

            properties[p] = "";
        }
        adjZones[_key] = new Zone({
            ...zone.data,
            properties
        });
    }

    return adjZones;
}

function updateNameZones(zones) {
    ZONE_LOCAL_DATA.NAME_ZONES = new Set();
    Object.values(zones).forEach(zone => {
        if (zone?.data?.name) {
            ZONE_LOCAL_DATA.NAME_ZONES.add(zone?.data?.name);
        }
    });
}

export function zoneBuilder(state=initialState,action){

    // let _state;
    let zones;
    switch(action.type){

        case LOAD_ZONES:
            zones = createZoneHashMap(action.zones);
            updateNameZones(zones);
            return {
                ...state,
                zones,
                dirty: action.dirty ? action.dirty : false,
                published: typeof action.published === "boolean" ? action.published : state.published
            };

        case CHANGE_ZONE_PROPERTY:
            zones = {
                ...state.zones,
                [firstSelectedZoneId(state)]: {
                    ...state.zones[state.zoneId],
                    [action.key]: action.value,
                },
            };
            updateNameZones(zones);
            return {
                ...state,
                // zoneBackSnapshots: (state.zoneBackSnapshots.concat([state.zones])),
                // zoneForwardSnapshots:[],
                zones,
                dirty: true,
                published: false
            };
        case CLICK_ZONE_PAGE_SECTION:
            return {
                ...state,
                section: action.section
            }
        case SELECT_ZONE:
            return {
                ...state,
                selectedZoneIds: action.zoneId ? [ action.zoneId ] : []
            };

        case SELECT_ZONES:
            return {
                ...state,
                selectedZoneIds: action.zoneIds.filter(id=>!!id)
            };

        case SELECT_ZONE_GROUP:
            return {
                ...state,
                selectedZoneGroup: action.groupName
            }

        case TOGGLE_MAP_INTERACTION: 
            return {
                ...state,
                interactions: {
                    [action.interaction]: !!!state.interactions[action.interaction]
                }
            }

        case TOGGLE_MULTI_INTERACTION:
            return {
                ...state,
                multinteractions: {
                    ...state.multinteractions,
                    [action.interaction]: !!!state.multinteractions[action.interaction]
                }
            }

        case ADD_ZONE:
            zones = {
                ...state.zones,
                [action.zone.data._key]: zone(undefined, action),
            };
            updateNameZones(zones);
            return {
                ...state,
                // zoneBackSnapshots:(state.zoneBackSnapshots.concat([state.zones])),
                // zoneForwardSnapshots:[],
                zones,
                selectedZoneIds: (action.zone.data._key) ? [ action.zone.data._key ] : [],
                dirty: true,
                published: false
            };

        case ADD_MULTI_ZONES:
            zones = {
                ...state.zones,
                ...action.zones,
            };
            updateNameZones(zones);
            return {
                ...state,
                // zoneBackSnapshots:(state.zoneBackSnapshots.concat([state.zones])),
                // zoneForwardSnapshots:[],
                zones,
                selectedZoneIds: action.selectedZoneIds,
                dirty: true,
                published: false
            }

        case UPDATE_ZONE: console.log("UPDATE_ZONE")
            zones = {
                ...state.zones,
                [action.zoneId]: zone(state.zones[action.zoneId]||{}, action),
            };
            updateNameZones(zones);
            return {
                ...state,
                // zoneBackSnapshots:(state.zoneBackSnapshots.concat([state.zones])),
                // zoneForwardSnapshots:[],
                zones,
                dirty: true,
                published: false
            };
    
        case UPDATE_ZONES:
            const newZones = {};
            for (const payload of action.zones) {
                newZones[payload.zoneId] = new Zone({
                    ...state.zones[payload.zoneId].data,
                    ...payload.updates,
                });
            }
            zones = {
                ...state.zones,
                ...newZones,
            };
            updateNameZones(zones);
            return {
                ...state,
                // zoneBackSnapshots:(state.zoneBackSnapshots.concat([state.zones])),
                // zoneForwardSnapshots:[],
                zones,
                dirty: true,
                published: false
            };
        case REPLACE_ITEMS:
            const newUpdateZones = {};
            for (const item of action.items) {
                const id = item.id;
                const {mapId,siteName=""} = action.zoneProperties;
                const zoneObj = {
                    ...(state.zones[id]?.data||{}),
                    ...item,
                    shape:getShape(item.coords),
                    zoneId:id,
                    _id:id,
                    siteName
                };

                //console.log({mapId,siteName,action})
                if(mapId){
                    zoneObj.mapId = mapId;
                }
                if(!item.isDeleted){
                    newUpdateZones[id] = new Zone(zoneObj);
                }
            }
            zones = {
                //...state.zones,
                ...newUpdateZones,
            };
            updateNameZones(zones);
            return {
                ...state,
                // zoneBackSnapshots:(state.zoneBackSnapshots.concat([state.zones])),
                // zoneForwardSnapshots:[],
                zones,
                dirty: true,
                published: false
            };
        case DELETE_ZONE:
            return {
                ...state,
                // zoneBackSnapshots:(state.zoneBackSnapshots.concat([state.zones])),
                // zoneForwardSnapshots:[],
                zones: (
                    (o)=>{
                        let ret = {...o};
                        delete ret[action.zoneId];
                        return ret;
                    }
                )(state.zones),
                dirty: true,
                published: false
            };

        case COPY_ZONE:
            return {
                ...state,
                clipboardZones: selectSelectedZones(state)
            };

        case SAVE_ZONES:
            return {
                ...state,
                published: false,
                dirty: false
            };
        case CLEAR_DIRTY:
            return {
                ...state,
                dirty: false
            };

        case PUBLISH_ZONES:
            return {
                ...state,
                published: true,
                dirty: false,
                zoneBackSnapshots: [],
                zoneForwardSnapshots: []
            };

        case UPDATE_ZONE_PROPERTY_SCHEMA:
            return {
                ...state,
                propertiesSchema: action.schema,
                zones: castWithSchema(state.zones,action.schema)
            };

        case LOAD_PUBLISHED_ZONES_FOR_MAP:
            zones = action.loadAsStaged ? createZoneHashMap(action.publishedZones.map(z=>new Zone(z))) : state.zones;
            updateNameZones(zones);
            return {
                ...state,
                publishedZonesByMap:{
                    //...state.publishedZonesByMap,
                    [action.mapId]:action.publishedZones
                },
                zones,
                zonesPrev:zones,
                published: (action.published!=null)?action.published:(action.loadAsStaged ? true : state.published),
                dirty: false
            };
        case LOAD_STAGING_ZONES_FOR_MAP:
            zones = action.loadAsStaged ? createZoneHashMap(Object.values(action.zones).map(z=>new Zone(z))) : state.zones;
            updateNameZones(zones);
            return {
                ...state,
                publishedZonesByMap:{
                    //...state.publishedZonesByMap,
                    [action.mapId]:action.publishedZones
                },
                zones:zones,
                zonesPrev:zones,
                published: (action.published!=null)?action.published:(action.loadAsStaged ? true : state.published),
                dirty: false
            };
        case LOAD_ZONES_FOR_MAP:
            zones = action.loadAsStaged ? createZoneHashMap(action.zones.map(z=>new Zone(z))) : state.zones;
            updateNameZones(zones);
            console.log("LOAD_ZONES: ",{action})
            return {
                ...state,
                publishedZonesByMap:{
                    //...state.publishedZonesByMap,
                    [action.mapId]:action.publishedZones
                },
                zones,
                zonesPrev:zones,
                published: action.published,
                dirty: false
            };
        case CHANGE_FILTER:
            return {
                ...state,
                activeFilter: action.filter
            };

        // case UNDO:
        //     if(state.zoneBackSnapshots.length > 0){
        //         zones = state.zoneBackSnapshots[state.zoneBackSnapshots.length - 1];
        //         updateNameZones(zones);
        //         _state = {
        //             ...state,
        //             zones,
        //             zoneBackSnapshots: state.zoneBackSnapshots.slice(0,state.zoneBackSnapshots.length - 1),
        //             zoneForwardSnapshots: state.zoneForwardSnapshots.concat([state.zones])
        //         };
        //         //console.log(state,_state);
        //         return _state;
        //     }
        //     return state;
        //
        // case REDO:
        //     if(state.zoneForwardSnapshots.length > 0){
        //         zones = state.zoneForwardSnapshots[state.zoneForwardSnapshots.length - 1];
        //         updateNameZones(zones);
        //         _state =  {
        //             ...state,
        //             zones,
        //             zoneForwardSnapshots: state.zoneForwardSnapshots.slice(0,state.zoneForwardSnapshots.length - 1),
        //             zoneBackSnapshots: state.zoneBackSnapshots.concat([state.zones])
        //         };
        //         //console.log(state,_state);
        //         return _state;
        //     }
        //     return state;
        case UPDATE_ZONE_GROUPS: 
            return { ...state, zoneGroups: action.zoneGroups };
        case CHANGE_ACTIVE_TAB:
            return { ...state, activeTab: action.tab };
        case PATCH_REPLICATE_ZONES_DATA:
            return { ...state, replicateZonesData: { ...state.replicateZonesData, ...action.data } };
        case REPLACE_ZONES:
            zones = action.zones;
            updateNameZones(zones);
            return { ...state, zones };
        case OPEN_FORM_ZONE_GRID:
            return {
                ...state,
                grid: action.newState != null ? {
                    ...state.grid,
                    ...action.newState
                } : initialState.grid
            }
        default:
            return state;
    }

}
