import { EMPTY_ARRAY, EMPTY_OBJECT, NONE, REPORT } from '../constants';
import Jp from 'jsonpath';
import {v4 as uuidv4} from 'uuid';
import {TYPE_DATA} from "../actions";

export const getReportKey = (reportId) => {
    return REPORT + ':' + reportId;
};

export const getReportRunUrl = (runReportId) => {
    return `/run-report/${runReportId}`;
};

export const getValueOfPath = (data, path = '$') => {
    try {
        if (path === '$') {
            return data;
        }
        return Jp.query(data, path).join();
    } catch (e) {
        console.error(e);
        return null;
    }
};

export const getDataOfPath = (data, path = '$') => {
    if (path === '$') { // it is not necessary that data is an object
        return data;
    }
    try { // data should be an object
        return Jp.value(data, path);
    } catch (e) {
        console.error(e);
        return null;
    }
};

export const getLookup = (datum, fields, separator = '|') => {
    if(fields.length === 0){
        return uuidv4();
    }
    return fields.map(f => getValueOfPath(datum, f)).join(separator);
};

export const processSort = (sort) => {
    if (sort && sort.length) {
        const orderSort = sort[0].direction === -1 ? '-' : '';
        const columnSort = sort[0].keySort;
        return orderSort + columnSort;
    }
    return '';
};

export const parseBoolean = (filter) => {
    switch (filter) {
        case 'Yes':
            return true;
        case 'No':
            return false;
        default:
            return '';
    }
};
export const parseDwell = (date) => {
    return (date && date !== '%' ? date : '');
};

export const parseResourceTypeKey = (key, value) => {
    if (key === 'parent' && value === 'Child') {
        return 'child';
    } else {
        return key;
    }
};

export const parseBooleanResourceType = (data) => {
    switch (data) {
        case 'Yes':
            return 'true';
        case 'No':
            return 'false';
        case 'Parent':
            return 'true';
        case 'Child':
            return 'true';
        default:
            return data;
    }
};

export const parseFilter = (valueFilter) => {
    return valueFilter && valueFilter !== '%' ? valueFilter : '';
};

export const getUrlFromObject = (object, withEmptyParams) => {
    const urlArray = [];
    const filterParams = {};
    for (const key in object) {
        if (key !== 'rangeModetimestamp') {
            if (object.hasOwnProperty(key) && (object[key] || withEmptyParams || typeof object[key] === 'boolean')) {
                const valueFilter = typeof object[key] === 'string' ? object[key] : Object.prototype.toString.call(object[key]) === '[object Date]' ? object[key].toISOString() : JSON.stringify(object[key]);
                urlArray.push(`${key}=${valueFilter}`);
                filterParams[key] = valueFilter;
            }
        }
    }
    const urlFilter =  urlArray.join('&');
    return {urlFilter,filterParams};
};

export const getUrlSiteDesigner = ({ urlTemplate, filters, page, size, sort }) => {
    let url = urlTemplate;
    if(filters != null) url = url.replace('{filters}', encodeURI(filters));
    if(page != null) url = url.replace('{page}', page);
    if(size != null) url = url.replace('{size}', size);
    if(sort != null) url = url.replace('{sort}', encodeURI(sort));
    return url;
};

export const getUrl = ({ urlTemplate, filters={}, page, size, sort,extraParams = {} }) => {
    let url = urlTemplate;
    const {urlFilter,filterParams} = filters;
    const params = {};
    const baseURL = urlTemplate.substring(0,urlTemplate.indexOf('?'));

    if(extraParams && Object.keys(extraParams).length > 0){
        Object.assign(filterParams,extraParams);
    }
    if(urlTemplate.indexOf('{filters}')){
        Object.assign(params,filterParams);
    }
    if(urlTemplate.indexOf('page') >= 0){
        Object.assign(params,{page});
    }
    if(urlTemplate.indexOf('size') >= 0){
        if(urlTemplate.indexOf('limit')>=0){
            Object.assign(params,{limit:size});
        }else{
            Object.assign(params,{size});
        }
    }
    if(urlTemplate.indexOf('sort') >= 0){
        Object.assign(params,{sort});
    }

    url = url.replace('{filters}', encodeURI(urlFilter));
    url = url.replace('{page}', page);
    url = url.replace('{size}', size);
    url = url.replace('{sort}', encodeURI(sort));

    return {url,params,baseURL};
};

export const getDefaultColumnValues = (columnKey, titleLabel) => {
    // Calc the min length
    const maxLength = (titleLabel + ' __ _').length; // ' __ _', sort arrow and check => ' _↑  ✓'
    let minWidth = maxLength;
    const titleValues = (titleLabel + ' __ _').split(' ');
    titleValues.reverse();
    let secondLine = 0;
    let firstLine = maxLength;
    for (const titleValue of titleValues) {
        firstLine -= titleValue.length + 1;
        secondLine += titleValue.length + 1;
        minWidth = Math.min(Math.max(firstLine, secondLine), minWidth);
    }
    return {
        key: columnKey,
        displayOnDetail: true,
        display: true,
        enabled: true,
        sortable: true,
        width: 200,
        type: 'TEXT',
        value: titleLabel,
        minWidth: minWidth * 9, // 9 ~ width of a letter
        path: '$.' + columnKey,
        filters: [columnKey],
        clickable: true,
    };
};

export const maxLengthUrl = () => {
    const FIREFOX = 7300;
    const GOOGLE_CHROME = 7200; // 7241;
    const { userAgent } = navigator;
    
    if (userAgent.includes('Firefox/')) { // Firefox
        return FIREFOX;
    } else if (userAgent.includes('Edg/')) {
        // Edge (Chromium)
    } else if (userAgent.includes('Chrome/')) { // Chrome
        return GOOGLE_CHROME;
    } else if (userAgent.includes('Safari/')) {
        // Safari
    }
    return 2000;
};

export const getReportData = (reportProperties, reportGeneric) => {
    const {
        showDetails = false,
        mainView,
        showFilters = true,
        uKeyFields = EMPTY_ARRAY,
        labelFields = EMPTY_ARRAY,
        idColumnFields = EMPTY_ARRAY,
        counterRefresh = 1,
        customDetails,
        bounds = EMPTY_OBJECT,
        isFetchingData = 0,
        background = false,
        configurationsStatus = NONE,
        mapXField = EMPTY_OBJECT,
        mapYField = EMPTY_OBJECT,
        mapIdColumnField = EMPTY_OBJECT,
        mapLabelField = EMPTY_OBJECT,
        mapIdField = EMPTY_OBJECT,
        dialog = EMPTY_OBJECT,
        //pageSelect = 0,
        //maxRowSize = process.env.REACT_APP_MAX_ROWS || 10000,
        maxResultsByPage,
        savedConfigurationsStatus = NONE,
        initialLoadStatus = NONE,
        fixedColumns = 0,
        selectedSites = EMPTY_ARRAY,
        form,
        detailsDisplay = true, // TRI
        exportDisplay = true, // TRI
        saveDisplay = true,
        backToPage = EMPTY_OBJECT,

        groupsUserReport = [],
        ownerReport = null,
        optionFilter=null,
        paginationInfo = {
            nextPageAllow: false,
            prevPageAllow: false,
            offset: 0,
            maxResultsByPage: maxResultsByPage,//process.env.REACT_APP_MAX_ROWS || 10000,
            page:1,
            totalPages:1,
            totalRecords: 0,
            totalPerPage: 0,
            quantityRanges: [],
            sort: null,
            enableSelectPage:false,
            pageListConfiguration:{}
        },
        savingFormData=false
    } = { ...reportGeneric, ...reportProperties };
    paginationInfo.maxResultsByPage=reportGeneric.maxResultsByPage;// always get generic info (latest configured) override configuration happens after this.

    return {
        showDetails,
        mainView,
        showFilters,
        uKeyFields,
        labelFields,
        idColumnFields,
        counterRefresh,
        customDetails,
        bounds,
        isFetchingData,
        background,
        configurationsStatus,
        mapXField,
        mapYField,
        mapIdColumnField,
        mapLabelField,
        mapIdField,
        dialog,
        //pageSelect,
        //maxRowSize,
        maxResultsByPage,
        savedConfigurationsStatus,
        initialLoadStatus,
        fixedColumns,
        selectedSites,
        form,
        detailsDisplay, // TRI
        exportDisplay, // TRI
        saveDisplay,
        backToPage,

        groupsUserReport,
        ownerReport,
        optionFilter,

        paginationInfo,
        savingFormData
    };
};

export const getPermissionsOfRoute = (menu, route) => {
    const permissions = {
        canAdd: false,
        canEdit: false,
        canList: false,
        canDelete: false,
    };
    menu?.forEach?.(menu => {
        menu?.children?.forEach?.(child => {
            if (child?.routes === route) {
                permissions.canAdd = !!child?.canAdd;
                permissions.canEdit = !!child?.canEdit;
                permissions.canList = !!child?.canList;
                permissions.canDelete = !!child?.canDelete;
            }
        });
    });
    return permissions;
};
export const getCustomFieldsAndResourceType = (reportFilters,filtersAll,resourceTypeParent) =>{
    const { children: resourceFilters } = reportFilters?.[resourceTypeParent] || {};
    const resourceType = filtersAll[resourceTypeParent]||{};
    const resourceAttributesFilters = resourceFilters ? Object.keys(resourceFilters).reduce((acc, at) => {
        const valueFilter = resourceFilters[at].value;
        if(filtersAll.hasOwnProperty(at)){
            delete filtersAll[at]
        }
        return (valueFilter||typeof valueFilter==='boolean'||typeof valueFilter==='number') && valueFilter !== '%' && valueFilter !== 'All' ? [...acc, `${at}:${valueFilter}`] : acc;
    }, []) : [];
    const valueResourceType = resourceType && resourceType.length>0?resourceType[0].value:undefined;
    return  {resourceAttributesFilters,valueResourceType};
}
export const getExtraRows = (content, childrenResourceType) =>{
    const filterCustomProperties = Object.values(childrenResourceType).filter(item => item.dataType ===TYPE_DATA.LIST );
    const filterFieldName = {id:null,name:null,value:null};

    filterCustomProperties.forEach(filter=>{
        const {value, listValues,id,name} = filter;
        if(value && (listValues||[]).indexOf(value)>=0){
            filterFieldName.id = id;
            filterFieldName.name = name;
            filterFieldName.value = value;
        }
    });

    const result = [];
    content.forEach(row=>{
        const { customProperties }=row;
        let maxRows = 0;
        Object.keys(customProperties).forEach(nameAttribute=>{
            if(nameAttribute && Array.isArray(customProperties[nameAttribute]) && maxRows<customProperties[nameAttribute].length){
                maxRows = customProperties[nameAttribute].length;
            }
        });
        for(let index = 0;index<maxRows;index++){
            let newRow = {...row};
            let newCustomProperties = {};

            Object.keys(customProperties).forEach(nameAttribute=>{
                if( Array.isArray(customProperties[nameAttribute])){
                    const temp =index<customProperties[nameAttribute].length?customProperties[nameAttribute][index]:"";
                    newCustomProperties = {...newCustomProperties,...temp};
                }else{
                    newCustomProperties[nameAttribute]=customProperties[nameAttribute];
                }
            });
            newRow.customProperties = newCustomProperties;
            newRow.groupRowIndex = index;
            newRow.groupUKey = row.id;

            if( filterFieldName.name && filterFieldName.value  ){
                if(newRow.customProperties[filterFieldName.name] === filterFieldName.value){
                    result.push(newRow);
                }
            }else{
                result.push(newRow);
            }
        }
        if(maxRows === 0){
            row.groupRowIndex = 0;
            row.groupUKey = row.id;
            result.push(row);
        }
    });
    return result;
}

export const getCustomDate = () =>{
    const newDate = new Date();
    newDate.setHours(23);
    newDate.setMinutes(59);
    newDate.setSeconds(59);
    return newDate;
}


export const getSafeValueOfPath = (data, path) => {
    let value;
    let success = false;
    if (path && data !== undefined) {
        if (path === '$') { // it is not necessary that data is an object
            value = data;
            success = true;
        } else {
            try { // data should be an object
                value = Jp.value(data, path);
                success = true;
            } catch (e) {
                console.warn(e);
            }
        }
    }
    return { success, value };
};