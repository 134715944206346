import React from 'react';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import {DialogActions, DialogTitle} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import {useDispatch, useSelector} from "react-redux";
import {closeErrorOnBackgroundProcess} from "../../actions/errorDialogOnBackground";


const ErrorDialogOnBackground = (props) => {
    const open = useSelector(state => state.errorDialogOnBackground.open);
    const content = useSelector(state => state.errorDialogOnBackground);
    const dispatch = useDispatch();
    const handleCloseAlert = () => {
        dispatch(closeErrorOnBackgroundProcess())
    };

    const actionButtons = (
        <div className='dialog-buttons'>
            <Button className={'alert-close-button'} onClick={handleCloseAlert} style={{left: 8}}>OK</Button>
        </div>
    );

    return (
        <Dialog
            fullWidth
            open={open}
            maxWidth={'xs'}
            classes={{}}
            PaperProps={{style: {overflowY: 'visible'}}}
        >
            <DialogTitle style={{paddingRight: 50}}>
                <div style={{fontWeight:'300',fontSize:'0.9em'}}>
                    {content?.title}
                </div>
            </DialogTitle>
            <DialogContent>
                <div style={{color: '#0009', minHeight: 40, height: 'auto', width:"auto"}}>
                    {content?.message}
                </div>
            </DialogContent>
            <DialogActions className='dialog-buttons'>
                {actionButtons}
            </DialogActions>
        </Dialog>
    )
};

export default ErrorDialogOnBackground;