import * as types from '../constants/ActionTypes';

const initialState = {
    rfidData: [],
    resourcePrintSeq: [],
    tagIdBase: [],
    rangeCode: [],
};

export function resourceTypePrint(state = initialState, action){
    switch (action.type){
        case types.RESOURCE_TYPE_PRINT_RFID:

            return {
                ...state,
                rfidData: action.data
            };

        case types.RESOURCE_ID_PRINT_SEQUENCE:
            return {
                ...state,
                resourcePrintSeq: action.data
            };

        case types.TAG_ID_BASE:
            return {
                ...state,
                tagIdBase: action.data
            };

        case types.RANGE_CODE:
            return {
                ...state,
                rangeCode: action.data
            };
        default:
            return state;
    }
}
