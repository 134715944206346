/*
const {
    STANDARD_GREY, MOTIONWORKS_BLUE, STANDARD_ORANGE, STANDARD_RED, DARK_GREY
} = require('../../constants/Colors');

const RUNNING_COLOR = MOTIONWORKS_BLUE;
const ACTIVATING_COLOR = STANDARD_GREY;
const STOPPED_COLOR = DARK_GREY;
const OFFLINE_COLOR = STANDARD_ORANGE;
const FAILED_COLOR = STANDARD_RED;*/

module.exports = {
    type:"TABLE",
    reportLink:"/appliances", // system alerts report
    dataProvider:{
        service:"report-appliance-status",
        url:"/appliances/status"
    },
    title:"ZLA Status",
    columns:[{
        caption:"Status",
        key:"status"
    },{
        caption:"# of Appliances",
        key:"count",
        align:'center'
    }],
    rowProperties:{
        textColor:{
            attribute:"color",
            default:"inherit"
        }
    },
    lastLineTotal: true,
    data:null
    /*
    data: {
        type: 'pie',
        data: {
            labelAttribute:"status",
            datasets: [{
                //data: [4, 1, 1],
                dataAttribute:"count",
                backgroundColor:[
                    RUNNING_COLOR,
                    ACTIVATING_COLOR,
                    STOPPED_COLOR,
                    OFFLINE_COLOR,
                    FAILED_COLOR
                ]
            }],

            // These labels appear in the legend and in the tooltips when hovering different arcs
            labels: [
                'Running',
                'Activating',
                'Stopped',
                'Offline',
                'Failed'
            ]
        },
        options: {
            legend:{
                position:'right'
            }
        }
    }*/
};
