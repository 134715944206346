import React from 'react';
import PropTypes from 'prop-types';
import ReactToolTip from 'react-tooltip';

import { IconButton, makeStyles } from '@material-ui/core';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const TOGGLED = {
  background: "#ddd",
  transition: "background .2s"
}

const useStyles = makeStyles(() => ({
  iconButton: {
    color: "#212529",
    padding: 8,
    margin: "0 5px"
  },
  vertIconButton: {
    color: "#212529",
    padding: 8
  },
  dropdownIcon: {
    position: "absolute",
    bottom: -3,
    right: -5,
    color: "#3e3e3e",
    transform: "rotate(-45deg)"
  }
}));  

const getToolStyles = (isToggle, isInteraction) => {
  if (typeof isToggle === "boolean") {
    return !isToggle ? { color: "#aaa" } : (isInteraction ? TOGGLED : {});
  }
  return {};
}

const ToolbarTool = ({ 
  ref, title, icon, onClick, isToggle, disabled, isDropdown, isVertical, isInteraction, toolTipOnRight
}) => {
  const classes = useStyles();
  const btnStyles = getToolStyles(isToggle, isInteraction);
  
  return (
    <>
      <IconButton
        ref={ref}
        onClick={onClick}
        disabled={disabled}
        data-tip={title}
        data-for={title}
        className={isVertical ? classes.vertIconButton : classes.iconButton}
        style={btnStyles}
      >
        {icon}
        {isDropdown ? <ArrowDropDownIcon className={classes.dropdownIcon} /> : null}
      </IconButton>

      <ReactToolTip
        id={title}
        place={isVertical ? "left" : (toolTipOnRight? "right":"bottom" ) }
        effect="solid"
      />
    </>
  )
}

ToolbarTool.propTypes = {
  ref: PropTypes.shape({ current: PropTypes.any }),
  title: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  isToggle: PropTypes.bool,
  disabled: PropTypes.bool,
  isDropdown: PropTypes.bool,
  isVertical: PropTypes.bool,
  isInteraction: PropTypes.bool
}

export default ToolbarTool;
