import React from 'react';


const CoordinatesIcon = ({width=512, height=512}) => {
    return(
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
             width={width} height={height} viewBox="0 0 512.000000 512.000000"
             preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
               fill="#000000" stroke="none">
                <path d="M0 4640 l0 -480 480 0 480 0 0 160 0 160 160 0 160 0 0 160 0 160
-160 0 -160 0 0 160 0 160 -480 0 -480 0 0 -480z m640 0 l0 -160 -160 0 -160
0 0 160 0 160 160 0 160 0 0 -160z"/>
                <path d="M4160 4640 l0 -480 160 0 160 0 0 -160 0 -160 160 0 160 0 0 160 0
160 160 0 160 0 0 480 0 480 -480 0 -480 0 0 -480z m640 0 l0 -160 -160 0
-160 0 0 160 0 160 160 0 160 0 0 -160z"/>
                <path d="M1600 4640 l0 -160 160 0 160 0 0 160 0 160 -160 0 -160 0 0 -160z"/>
                <path d="M2240 4640 l0 -160 160 0 160 0 0 160 0 160 -160 0 -160 0 0 -160z"/>
                <path d="M2880 4640 l0 -160 160 0 160 0 0 160 0 160 -160 0 -160 0 0 -160z"/>
                <path d="M3520 4640 l0 -160 160 0 160 0 0 160 0 160 -160 0 -160 0 0 -160z"/>
                <path d="M320 3680 l0 -160 160 0 160 0 0 160 0 160 -160 0 -160 0 0 -160z"/>
                <path d="M4480 3360 l0 -160 160 0 160 0 0 160 0 160 -160 0 -160 0 0 -160z"/>
                <path d="M320 3040 l0 -160 160 0 160 0 0 160 0 160 -160 0 -160 0 0 -160z"/>
                <path d="M4480 2720 l0 -160 160 0 160 0 0 160 0 160 -160 0 -160 0 0 -160z"/>
                <path d="M320 2400 l0 -160 160 0 160 0 0 160 0 160 -160 0 -160 0 0 -160z"/>
                <path d="M4480 2080 l0 -160 160 0 160 0 0 160 0 160 -160 0 -160 0 0 -160z"/>
                <path d="M320 1760 l0 -160 160 0 160 0 0 160 0 160 -160 0 -160 0 0 -160z"/>
                <path d="M4480 1440 l0 -160 160 0 160 0 0 160 0 160 -160 0 -160 0 0 -160z"/>
                <path d="M320 1120 l0 -160 -160 0 -160 0 0 -480 0 -480 480 0 480 0 0 480 0
480 -160 0 -160 0 0 160 0 160 -160 0 -160 0 0 -160z m320 -640 l0 -160 -160
0 -160 0 0 160 0 160 160 0 160 0 0 -160z"/>
                <path d="M4160 800 l0 -160 -160 0 -160 0 0 -160 0 -160 160 0 160 0 0 -160 0
-160 480 0 480 0 0 480 0 480 -480 0 -480 0 0 -160z m640 -320 l0 -160 -160 0
-160 0 0 160 0 160 160 0 160 0 0 -160z"/>
                <path d="M1280 480 l0 -160 160 0 160 0 0 160 0 160 -160 0 -160 0 0 -160z"/>
                <path d="M1920 480 l0 -160 160 0 160 0 0 160 0 160 -160 0 -160 0 0 -160z"/>
                <path d="M2560 480 l0 -160 160 0 160 0 0 160 0 160 -160 0 -160 0 0 -160z"/>
                <path d="M3200 480 l0 -160 160 0 160 0 0 160 0 160 -160 0 -160 0 0 -160z"/>
            </g>
        </svg>
    )
};

export default CoordinatesIcon;