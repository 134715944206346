import {DeviceManagementService, SiteViewService} from "./util";
import {
    CLOUD_IMPORT_UPDATE_SELECTED_DEVICES,
    LOAD_DEVICES_VIA_ZEBRA_DATA_SERVICE,
    SET_CLOUD_DEVICES_DATA, INIT_STATUS_CLOUD_DEVICES,
    SET_FETCHING
} from "../constants/ActionTypes";
import {ALERT_SUCCESS, ALERT_ERROR, ALERT_RECEIVER, RFID_MODELS, RFID_READER} from "../constants/DeviceManager";
import {displayNotification} from './notification';
import {createDevicesBatch} from './device-manager';
import {MAP_VIEW_URL} from '../constants/Misc';

export const getSiteName = async (siteId) => {
    const sitesResponse = await SiteViewService.instance().get(MAP_VIEW_URL + '/sites/');
    const sites = sitesResponse.data;
    for (let index = 0; index < sites.length; index++) {
        const site = sites[index];
        if (site._id === siteId) {
            return site.name;
        }
    }
};

export const loadDevicesViaZebraDataService = () => {
    return async (dispatch) => {
        dispatch({type: SET_FETCHING, isFetching: true});
        try {
            const response = await DeviceManagementService.instance().get("/devices/savanna_devices");
            if (response !== null) {
                const cloudDevices = response.data;

                let deviceModelAndType = {};
                if(cloudDevices!=null) {
                    cloudDevices.forEach(device => {
                        if (RFID_MODELS.hasOwnProperty(device.substring(0, 6))) {
                            deviceModelAndType[device] = {
                                model: RFID_MODELS[device.substring(0, 6)].label,
                                type: RFID_READER
                            };
                        } else if (RFID_MODELS.hasOwnProperty(device.substring(0, 7))) {
                            deviceModelAndType[device] = {
                                model: RFID_MODELS[device.substring(0, 7)].label,
                                type: RFID_READER
                            };
                        } else {
                            deviceModelAndType[device] = {model: "", type: ""};
                        }
                    });
                }

                // const cloudDevices = [...response.data, 'FX9600EF7B9D_1','FX9600EF7B9D_2','FX9600EF7B9D_3','FX9600EF7B9D_4','FX9600EF7B9D_5','FX9600EF7B9D_6','FX9600EF7B9D_7','FX9600EF7B9D_8']
                const selectedRows = cloudDevices.reduce((acc, item) => ({...acc, [item]: item}), {});
                dispatch(selectAllCloudDevice(selectedRows));
                dispatch({type: LOAD_DEVICES_VIA_ZEBRA_DATA_SERVICE, cloudDevices: cloudDevices,deviceModelAndType:deviceModelAndType});
            }
        } catch (e) {
            console.log(e);
            // TODO remove this:
            // cloudDevices.push({
            //     "assetClass": "reader",
            //     "id": "FX7500F26671",
            //     "lastModified": "2021-02-17T20:59:22Z",
            //     "lastModifiedBy": "pdouglas"
            // })
        }
        dispatch({type: SET_FETCHING, isFetching: false});
    }
};


export const selectRowCloudDevice = (rowIndex) => {
    return (dispatch, getState) => {
        const currentSelectedRows = getState().zebraDataService.selectedRows;
        const device = getState().zebraDataService.cloudDevices[rowIndex];
        const newState = Object.assign({}, currentSelectedRows);
        newState[device] = device;
        dispatch({type: CLOUD_IMPORT_UPDATE_SELECTED_DEVICES, selectedRows: newState});
    }
};

export const unSelectRowClodDevice = (rowIndex) => {
    return (dispatch, getState) => {
        const state = getState();
        const device = state.zebraDataService.cloudDevices[rowIndex];
        const currentSelectedRows = state.zebraDataService.selectedRows;
        const newState = Object.assign({}, currentSelectedRows);
        delete newState[device];
        dispatch({type: CLOUD_IMPORT_UPDATE_SELECTED_DEVICES, selectedRows: newState});
    }
};

export const selectAllCloudDevice = (allRows) => ({type: CLOUD_IMPORT_UPDATE_SELECTED_DEVICES, selectedRows: allRows});

export const initAddCloudDevices = () => {
    return dispatch => {
        dispatch({type: CLOUD_IMPORT_UPDATE_SELECTED_DEVICES, selectedRows: {}});
        dispatch({type: LOAD_DEVICES_VIA_ZEBRA_DATA_SERVICE, cloudDevices: []});
    }
}

export const cleanDeviceCloudStatus = () => ({type: INIT_STATUS_CLOUD_DEVICES});
export const commitSelectedCloudDevices = (listDevices) => {
    return async (dispatch, getState) => {
        // let cloudDevices=getState().zebraDataService.cloudDevices;
        const selectedDevices = getState().zebraDataService.selectedRows;
        let devicesBatch = [];
        dispatch(displayNotification(true, ALERT_RECEIVER, `New devices: `, 'saving...', '', true, 5000));
        let countFailed = 0;
        let errors = [];

        for(var i = 0; i < listDevices.length; i++){
            const device = listDevices[i];
            let {_id, deviceIdStr, template, status, operation_status, ...deviceObject} = device;
            if(deviceObject.siteId && deviceObject.siteId !== ""){
                const siteName = await getSiteName(deviceObject.siteId);
                deviceObject.siteName = siteName;
            }

            try {
                let response = await DeviceManagementService.instance().post(`/devices`, deviceObject);
                let getResponse = await DeviceManagementService.instance().get(`/devices/${response.data._id}`);

                dispatch({
                    type: SET_CLOUD_DEVICES_DATA,
                    deviceId: deviceObject.ipAddress,
                    stateSaved: {status: 'SUCCESS', text: ''}
                });
                devicesBatch.push(getResponse.data);
            } catch (e) {
                if (e.response.data.errors) {
                    let errorMessage = `${e.response.data.errors[0].title}: ${e.response.data.errors[0].detail}`;
                    errors.push(errorMessage);
                    dispatch({
                        type: SET_CLOUD_DEVICES_DATA,
                        deviceId: deviceObject.ipAddress,
                        stateSaved: {status: 'FAILED', text: errorMessage}
                    });
                }
                countFailed++;
            }   
        }
        
        if (countFailed === 0) {
            dispatch(displayNotification(true, ALERT_SUCCESS, `New devices: `, `${Object.keys(selectedDevices).length} device(s) were saved successfully`, '', true, 5000));
            dispatch(createDevicesBatch(devicesBatch));
        } else {
            let detailsErrorMessage = `${errors.join('\n')} have errors`;
            dispatch(displayNotification(true, ALERT_ERROR, `Issues for import: `, `${countFailed} of ${Object.keys(selectedDevices).length} devices could not be saved`, detailsErrorMessage, true));
        }
    }
};