const ParameterAsyncConfigurations = require('./ParameterAsyncConfigurations');

module.exports = {
    type:'TABLE',
    title:"Resource Movement by Site",
    pinType:'count',
    data:null,
    dataProvider:{
        service:"report",
        url:"/dashboards/widgets/12/data"
    },
    parameters:[
        {
            type:"enum",
            name:"type",
            label:"Resource Type",
            async:ParameterAsyncConfigurations.RESOURCE_TYPE
        },
        {
            name:"fromTime",
            type:"from-to",
            label:"Time Range",
            fromName:"fromTime",
            toName:"toTime",
            required:true
        },
    ],
    columns:[
        {
            key:"site_name",
            caption:"Site",
            align:'left'
        },{
            key:"incnt",
            caption:"In",
            align:'center'
        },{
            key:"outcnt",
            caption:"Out",
            align:'center'
        }
    ],
    //reportLink:"/reports/81DCCA86-6D91-4E05-8B27-D85A334840DB", // zone history report
    reportLink:"/reports/B9AF8F31-453D-4847-B48C-99523CC36776", // event history report
};
