
export const DEFAULT_ZONE_PRIORITY = 16000; // assigned by zebra
export const DEFAULT_EXIT_ZONE_ID = "";
export const DEFAULT_GROUP_NAME = "Default Group";
export const DEFAULT_ZONE_ORIENTATION = "";

export default class Zone {
    constructor(zoneObj){
        this.data = {
            _id: zoneObj._id || undefined,
            _key: zoneObj._key || zoneObj._id || generateObjectId(),
            mapId: zoneObj.mapId,
            siteId: zoneObj.siteId,
            siteName: zoneObj.siteName,
            mapName: zoneObj.mapName,
            name: zoneObj.name,
            description: zoneObj.description || "",
            tenantName: zoneObj.tenantName,
            type: zoneObj.type || "standard",
            color: zoneObj.color || "#CCCCCC",
            shape: zoneObj.shape,
            action: zoneObj.action || null,
            properties: zoneObj.properties,
            priority: zoneObj.priority || DEFAULT_ZONE_PRIORITY,
            groupName: zoneObj.groupName || `${zoneObj.siteName} ${DEFAULT_GROUP_NAME}`,
            exitZoneId: zoneObj.exitZoneId || DEFAULT_EXIT_ZONE_ID,
            orientationAngle: (zoneObj.orientationAngle!=null?zoneObj.orientationAngle:DEFAULT_ZONE_ORIENTATION),
            //whereportInclusion: zoneObj.whereportInclusion || "",
            //resourceOrientation: zoneObj.resourceOrientation || 0
        };
    }

    isOnMap(mapId){
        return this.data.mapId === mapId;
    }

    toJSON(){
        const d = this.data;
        const jsonObj = {
            mapId: d.mapId,
            siteId: d.siteId,
            siteName: d.siteName,
            mapName: d.mapName,
            name: d.name,
            description: d.description,
            tenantName: d.tenantName,
            type: d.type,
            color: d.color,
            shape: d.shape,
            action: d.action,
            properties: d.properties,
            priority: d.priority,
            exitZoneId: d.exitZoneId,
            groupName: d.groupName,
            orientationAngle: (d.orientationAngle===''?null:d.orientationAngle),
            //whereportInclusion: d.whereportInclusion,
            //resourceOrientation: d.resourceOrientation
        };

        if(d._id){
            jsonObj._id = d._id;
        }

        return jsonObj;
    }
}


const ZONES_GENERATED = {};
export function generateObjectId(){
    let _id = generateSimpleObjectId();
    while(_id in ZONES_GENERATED){
        _id = generateSimpleObjectId();
    }

    ZONES_GENERATED[_id] = true;
    return _id;
}

function generateSimpleObjectId(){
    let s = "";
    const HEX_ARRAY = "0123456789abcdef".split("");
    for(let i = 0; i < 24; i++){
        s += HEX_ARRAY[randInt(HEX_ARRAY.length-1)];
    }
    return s;
}

function randInt(max){
    return Math.floor(Math.random()*(max+1));
}
