import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const ZebraLogoIcon = (props) => (
    <SvgIcon viewBox={"200 0 1 130"} {...props}>
        <g>
            <polygon points="83.6,65.6 75.2,57 75.2,81 83.6,81 	"/>
            <path d="M49.7,39.8h20.7L62,31.5H51c7-0.7,12.8-6.6,12.8-13.9c0-7.7-6.2-14.1-14.1-14.1v27.9L26.4,8.4
		c-1.3,0.7-2.4,1.3-3.5,2c-1.3,0.9-2.4,1.8-3.7,2.6l22,22v48.2l16.9,16.9V88l-8.6-8.6L49.7,39.8z"/>
            <polygon points="58.1,39.8 58.1,81 66.7,81 66.7,48.4 	"/>
            <polygon points="87.3,57 78.8,48.4 66.7,48.4 75.2,57 	"/>
            <path d="M32.6,75V38.5L13,18.9c-1.8,2.2-3.5,4.6-4.8,7.3L24,42v12.1L4.8,35C4,38.3,3.5,41.8,3.3,45.5
		C3.5,45.5,31.7,74.1,32.6,75"/>
            <polygon points="3.7,57.6 3.7,69.7 58.1,124.1 58.1,112.2 	"/>
            <path d="M100.8,70.4h-8.6v10.8h-8.6c0,7.7,6.4,14.1,14.1,14.1c7.7,0,14.1-6.4,14.1-14.1L100.8,70.4z"/>
            <polygon points="170.5,81.2 163.7,93.3 123.9,93.3 149.6,43.8 126.7,43.8 133.1,31.7 172.9,31.7 147.2,81.2 	"/>
            <polygon points="193.2,56.3 213.4,56.3 213.4,68.4 193.2,68.4 193.2,81.2 213.4,81.2 213.4,93.3 178,93.3 178,31.7
		213.4,31.7 213.4,43.8 193.2,43.8 	"/>
            <path d="M235.2,82.7h5.5c6.4,0,9-2.2,9-7c0-2.9-1.1-5.1-3.3-6.4c-1.8-0.9-3.1-1.1-7.7-1.1h-3.5V82.7z M235.2,57.2h3.7
		c6.8,0,9.7-2.2,9.7-7.3c0-5.1-2.9-7-9.9-7h-3.5V57.2z M241.3,31.7c7.9,0,12.3,0.9,15.8,2.9c4.2,2.6,6.8,7.9,6.8,13.9
		c0,4.4-1.3,7.9-4.2,10.8c-1.8,1.8-3.1,2.6-6.4,3.5c3.7,0.9,5.5,1.8,7.5,3.5c2.9,2.6,4.2,6.2,4.2,10.8c0,5.7-2.4,10.8-6.4,13.4
		c-3.1,2-6.8,2.9-13,2.9h-25.3V31.5L241.3,31.7z"/>
            <path d="M291.1,60.9c6.2,0,9.9-3.1,9.9-8.6c0-5.1-3.5-8.6-8.8-8.6h-5.7v17.2H291.1z M286.2,93.3h-15V31.5h20
		c8.1,0,14.1,1.5,18.3,4.6c4.2,3.3,6.6,8.8,6.6,15.6c0,9.5-4,15.6-11.9,18.7l13.6,22.7h-18l-13.4-24L286.2,93.3z"/>
            <path d="M341.2,69.3l6.2-20l6.2,20H341.2z M357.3,31.7h-19.1l-20.5,61.8h15.8l3.7-12.1h20l3.7,12.1h16.3L357.3,31.7z"
            />
        </g>
    </SvgIcon>
);

export default ZebraLogoIcon;