import { ItemType } from '../../constants';
import { SITE_MANAGER_ENDPOINTS } from './constants';
import { getUrl } from '../../vss/app/utils/index';
import { toPayloadZone } from '../../helpers';

export const createZones = async (siteId, mapId, zones) => {
    const payload = zones.map(toPayloadZone(siteId, mapId));
    const { urlTemplate, service, method } = SITE_MANAGER_ENDPOINTS[ItemType.ZONE].CREATE;
    const url = getUrl({ urlTemplate: typeof urlTemplate === 'function' ? urlTemplate() : urlTemplate });
    return await service.instance().request({ url, method, data: payload });
};

export const updateZones = async (siteId, mapId, zones) => {
    const payload = zones.map(toPayloadZone(siteId, mapId));
    const { urlTemplate, service, method } = SITE_MANAGER_ENDPOINTS[ItemType.ZONE].UPDATE;
    const url = getUrl({ urlTemplate: typeof urlTemplate === 'function' ? urlTemplate() : urlTemplate });
    return await service.instance().request({ url, method, data: payload });
};

export const getZonesTypes = async () => {
    const { urlTemplate, service, method, getResultData } = SITE_MANAGER_ENDPOINTS[ItemType.ZONE].GET_ZONE_TYPES;
    const url = getUrl({ urlTemplate: typeof urlTemplate === 'function' ? urlTemplate() : urlTemplate });
    const response = await service.instance().request({ url, method });
    return getResultData(response);
};
