import {
    ANTENNA_FIELDS,
    DATUM_FIELDS,
    OBSTRUCTION_FIELDS,
    ZONE_BUILDER_FIELDS
} from "../../constants/SiteDesigner";
import {getSafeValueOfPath} from "../../utils";
import {getErrorMessageForArrayFields, getErrorMessageOfValidation} from "./validations";
import { ItemType } from '../../../../constants';
import {ARRAY_VALUE_VALIDATION} from "../../constants";

const VALIDATE_FIELDS = {
    ANTENNA: {
        ...ANTENNA_FIELDS,
        ...ANTENNA_FIELDS.location.fields
    },
    [ItemType.DEVICE_DATUM]: { ...DATUM_FIELDS, ...DATUM_FIELDS.location.fields },
    [ItemType.OBSTRUCTION]: { ...OBSTRUCTION_FIELDS },
    [ItemType.ZONE]: {...ZONE_BUILDER_FIELDS},
    "zoneCoordinates": {...ZONE_BUILDER_FIELDS.coords.format.fields},
    "obstructionCoordinates": {...OBSTRUCTION_FIELDS.coordinates.format.fields}
}

export const validateFieldsPath = async (configurationForm, formFieldErrors, itemType, formKey, specificValuesValidation = []) => {
    let formFieldErrorsArray = {...formFieldErrors};
    const validateFields = VALIDATE_FIELDS[itemType];
    for (let key in validateFields) {
        if (validateFields[key]) {
            for (const validation of validateFields[key].validations || []) {
                if(validation.type === ARRAY_VALUE_VALIDATION ){
                    formFieldErrorsArray = await getErrorMessageForArrayFields(configurationForm, formFieldErrorsArray, validateFields[key].type, formKey, VALIDATE_FIELDS, validateFields[key].path )
                    console.log("FormFieldErrorsArray:", formFieldErrorsArray)
                } else {
                    const extraData = {key, configurationForm, validateFields, formFieldErrorsArray, formKey, specificValuesValidation};
                    await getErrorMessageOfValidation({
                        validate: validation,
                        value: getSafeValueOfPath(extraData.configurationForm, extraData.validateFields[extraData.key].path).value,
                        defaultValue: '',
                        extraData,
                    });
                    if (extraData.result) {
                        formFieldErrorsArray = extraData.result;
                    }
                    //TODO formFieldErrorsArrays currently is an object but should be replaced by array instead,because when a field has more than one validation, always is rewrite with the lasted validation
                    if(extraData.result?.[extraData.validateFields[extraData.key].path]!==''){
                        break;
                    }
                }
            }
        }
    }
    return formFieldErrorsArray;
}
