const {
    STANDARD_RED, STANDARD_GREEN,
    MOTIONWORKS_BLUE, STANDARD_ORANGE
} = require('../../constants/Colors');

const BLINKING_COLOR = MOTIONWORKS_BLUE;
const NOT_BLINKING_COLOR = STANDARD_ORANGE;
const GOOD_BATTERY_COLOR = STANDARD_GREEN;// MOTIONWORKS_BLUE;
const LOW_BATTERY_COLOR = STANDARD_RED;//STANDARD_ORANGE;


const innerData = {
    type: 'bar',
    data: {
        labelAttribute:"technology",
        datasets: [{
            label: 'Blinking',
            stack:"0",
            dataAttribute:"blinking",
            backgroundColor: BLINKING_COLOR,
            borderColor: BLINKING_COLOR,
            borderWidth: 1
        },{
            label: 'Not Blinking',
            stack:"0",
            dataAttribute:"notblinking",
            backgroundColor: NOT_BLINKING_COLOR,
            borderColor: NOT_BLINKING_COLOR,
            borderWidth: 1
        },{
            label: 'Good Battery',
            stack:"1",
            dataAttribute:"goodbattery",
            backgroundColor: GOOD_BATTERY_COLOR,
            borderColor: GOOD_BATTERY_COLOR,
            borderWidth: 1
        },{
            label: 'Low Battery',
            stack:"1",
            dataAttribute:"lowbattery",
            backgroundColor: LOW_BATTERY_COLOR,
            borderColor: LOW_BATTERY_COLOR,
            borderWidth: 1
        }]
    },
    options: {
        scales: {
            xAxes:[{
                stacked:true
            }],
            yAxes: [{
                stacked: true,
                ticks: {
                    beginAtZero:true,
                    callback: function(value) {
                        if (Math.floor(value) === value) {
                            return value;
                        }
                    }
                }
            }]
        },
        legend:{
            display:true,
            position:'bottom'
        }
    }
};


module.exports = {
    type:"GRAPH",
    data:innerData,
    title:"Tag Health",
    dataProvider:{
        service:"report",
        url:"/dashboards/widgets/8/data"
    },
    reportLink:"/reports/2D50E6C5-F1C7-4544-A91F-B1DA38045411"
};
