import React, { useRef } from 'react';
import Paper from '@material-ui/core/Paper';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import createTheme from '@material-ui/core/es/styles/createTheme';
import IconButton from '@material-ui/core/Icon';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';

import { useOutsideClick } from '../../hooks';

const outerTheme = createTheme({
    palette: {
        primary: {
            main: '#00779F',
        },
    },
    shadows: Array(25).fill('none'),
});

const ColorLinearProgress = withStyles({
    barColorPrimary: {
        backgroundColor: '#00779f',
    },
})(LinearProgress);

const Dialog = ({ style, message = 'Getting Data From the Server...', loader, onClose, medium, content, title }) => {
    const refPaper = useRef();
    useOutsideClick(onClose, refPaper);
    return (
        <div style={{
            position: 'absolute',
            top: 0,
            right: 0,
            height: '100%',
            width: '100%',
            background: 'rgba(0,0,0,0.5)',
            zIndex: '100000',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            ...style,
        }}>
            <Paper style={{ display: 'inline-block', position: 'relative', width: medium ? '410px' : '' }} ref={refPaper}>
                {(onClose || title) && (
                    <div className="dialog-title" style={{ padding: '2em' }}>
                        <div style={{ fontSize: '18px', lineHeight: '1.6', fontWeight: '300' }}>{title}</div>
                        {onClose && (
                            <div style={{ position: 'absolute', top: '16px', right: '14px', color: '#777' }}>
                                <IconButton aria-label="close" onClick={onClose} style={{ cursor: 'pointer', position: 'relative', zIndex: '100' }}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        )}
                    </div>
                )}
                <div style={{ padding: `${onClose || title ? '0' : '2em'} 2em ${onClose ? '0' : '2em'} 2em` }}>
                    {content ? content : message}
                    {loader && (
                        <ThemeProvider theme={outerTheme}>
                            <ColorLinearProgress style={{ background: 'rgba(0,0,0,0.25)', marginTop: '1em' }} />
                        </ThemeProvider>
                    )}
                </div>
                {onClose && (
                    <div style={{ padding: '2em', display: 'flex', justifyContent: 'flex-end' }}>
                        <Button label="OK" onClick={onClose}>OK</Button>
                    </div>
                )}
            </Paper>
        </div>
    );
};

export default Dialog;
