export const REQUIRED = 'required';
export const ALLOWED_REGEX = 'allowedRegex';
export const NO_ALLOWED_REGEX = 'noAllowedRegex';
export const MAX_ZONES = 'maxZones';
export const RANGE_NUMBER = 'rangeNumber'
export const FORM_MODE = {
    ADD: "add",
    EDIT: "edit",
    DELETE: "delete"
};

export const DUPLICATE_FIELD_VALIDATION = 'duplicate_field_validation';
export const FORM_FIELD_REQUIRED = 'form_field_required';
export const RANGE_NUMBER_PATH_VALIDATION = 'rangeNumberPathValidation';
export const ARRAY_VALUE_VALIDATION = 'array_values_validation';
export const ANTENNAS_ARRAY_VALIDATION = 'antennas_array_values_required';
export const ANTENNAS_CUSTOM_VALIDATION = 'antennas_custom';
export const ANTENNAS_PORT_CUSTOM_VALIDATION = 'antennas_port_custom_range';
export const ARRAY_VALIDATION = 'array_values_required';
export const XML_FORMAT = 'xml';
export const CONFIGURATION_XML_FORMAT = 'configuration_xml';
export const CUSTOM_VALIDATION = 'custom';
export const CUSTOM_RANGE = 'customRange';
export const GPIOLED_CONFIGURATION_REQUIRED = 'gpioled_configurations_required';
export const MATCH = 'match';
export const DUPLICATE_FIELD_VALIDATION_CASE_SENSITIVE = 'duplicate_field_validation_case_sensitive';