import { useState } from 'react';
import PropTypes from 'prop-types';

import { Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';

import ToolbarTool from './ToolbarTool';

const DEFAULT_ANCHOR_ORIGIN = { horizontal: 'left', vertical: 'bottom' };
const DEFAULT_TRANSFORM_ORIGIN = { horizontal: 'left', vertical: 'top' };

export default function ToolbarGroup({ disabled, items, icon, title }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClicked = callback => {
    callback();
    setAnchorEl(null);
  }

  return (
    <>
      <ToolbarTool
        icon={icon}
        title={title}
        disabled={disabled}
        onClick={(event) => setAnchorEl(event.currentTarget)}
        isDropdown
      />

      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={DEFAULT_ANCHOR_ORIGIN}
        transformOrigin={DEFAULT_TRANSFORM_ORIGIN}
      >
        {items.map((item, ind) => (
          <MenuItem 
            key={`${item.title}-${ind}`} 
            onClick={() => handleMenuClicked(item.actions)}
            style={{ display: "flex", padding: "0 10px" }}
          >
            <ListItemIcon style={{ minWidth: 36 }}>{item.icon}</ListItemIcon>
            <ListItemText>{item.title}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

ToolbarGroup.propTypes = {
  disabled: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.exact({
    icon: PropTypes.element,
    title: PropTypes.string,
    actions: PropTypes.func
  })).isRequired,
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired
}