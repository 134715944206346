import {
    LOAD_MENU,
    LOAD_SAVED_REPORT,
    SAVE_JWT_TOKEN,
    SAVE_USERNAME,
    SET_ACCOUNT_TYPE,
    SET_AUTH_SETTINGS,
    SET_DISABLE_AUTO_REDIRECT,
    SET_LOGIN_USER,
    SET_REFRESH_RATE,
    SIGN_OUT,
    ZOOM_FEATURE,
    SET_USER_GROUPS,
    CHECK_BROWSER,
    SORT_USER_TABLE_COLUMNS, MAX_RESULTS_BY_PAGE
} from '../constants/ActionTypes';

let USERNAME = window.localStorage.getItem("username");
let TOKEN = window.localStorage.getItem("jwtToken");

const initialState = {
    menu:[],
    permissions:{},
    displayName:USERNAME,
    username:USERNAME,
    jwtToken: TOKEN,
    isLocal: false,
    autoRefreshInterval: null,
    feature: {
        zoomObject: true,
        zoomReport: false
    },
    userLogin: false,
    savedReports: {}, // may need its own reducer.
    authSettings: {},
    disableAutoRedirect: false,
    userGroups:[],
    modernBrowser: false,
    selectedUserRows: {},
    sortDirection: -1,
    maxResultsByPage:null
};

export function user(state = initialState, action){
    switch(action.type){
        case LOAD_MENU:
            return {
                ...state,
                menu: action.menu,
                permissions: action.permissions
            };
        case LOAD_SAVED_REPORT:
            return {
                ...state,
                savedReports:{
                    ...state.savedReports,
                    [action.report.id]: action.report
                }
            };
        case SAVE_USERNAME:
            return {
                ...state,
                username: action.username,
                displayName: action.username
            };
        case SAVE_JWT_TOKEN:
            return {
                ...state,
                jwtToken: action.token
            };
        case SET_ACCOUNT_TYPE:
            return {
                ...state,
                isLocal: action.isLocal
            };

        case SET_REFRESH_RATE:
            return {
                ...state,
                autoRefreshInterval: action.refreshRate
            };

        case SIGN_OUT:
            return {
                ...state,
                username: null,
                displayName: null,
                jwtToken: null
            };

        case ZOOM_FEATURE:
            return {
              ...state,
                feature: action.feature
            };
        case MAX_RESULTS_BY_PAGE:
            return {
              ...state,
              maxResultsByPage: action.maxResultsByPage
            };

        case SET_LOGIN_USER:
            return {
                ...state,
                userLogin: action.userLogin
            }

        case SET_AUTH_SETTINGS:
            return {
                ...state,
                authSettings: action.authSettings
            }

        case SET_DISABLE_AUTO_REDIRECT:
            return {
                ...state,
                disableAutoRedirect: action.disableAutoRedirect,
            };
        case SET_USER_GROUPS:
            return {
                ...state,
                userGroups: action.userGroups
            };
        case CHECK_BROWSER:
            return {
                ...state,
                modernBrowser: action.modernBrowser
            }
        case SORT_USER_TABLE_COLUMNS:
            return {
                ...state,
                sortDirection: action.sortDirection,
                sortIndex: action.sortIndex
            };
        default:
            return state;
    }
}
