const userGroups = [
        {
            caption:"Group",
            name:"groupName",
            sort:0,
            filter:true,
            enabled:true
        },{
            caption:"Description",
            name:"description",
            sort:0,
            filter:true,
            enabled:true
        },{
            caption:"Role",
            name:"role",
            sort:0,
            filter:true,
            enabled:true
        },{
            caption:"Access Location",
            name:"sites",
            sort:0,
            filter:true,
            enabled:true
        },{
            caption:"Resource Type",
            name:"resourceTypes",
            sort:0,
            filter:true,
            enabled:true
        },{
            caption:"Resources",
            name:"resources",
            sort:0,
            filter:true,
            enabled:true,
            centered:true
        },{
            caption:"Tags",
            name:"tags",
            sort:0,
            filter:true,
            enabled:true,
            centered:true
        },{
            caption:"Alerts",
            name:"alerts",
            sort:0,
            filter:true,
            enabled:true,
            centered:true
        },{
            caption:"Infrastructure",
            name:"infrastructure",
            sort:0,
            filter:true,
            enabled:true,
            centered:true
        },{
            caption:"User Mgmt",
            name:"userManagement",
            sort:0,
            filter:true,
            enabled:true,
            centered:true
        },{
            caption:"Configuration",
            name:"configuration",
            sort:0,
            filter:true,
            enabled:true,
            centered:true
        },{
            caption:"Dashboard",
            name:"dashboard",
            sort:0,
            filter:true,
            enabled:true,
            centered:true
        }
];
export default userGroups;
