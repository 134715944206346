import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { useCallback } from 'react';
import { addLoaderCounter, clearLoaderCounter, subLoaderCounter } from '../store/actions';

const selectLoaderState = createSelector(
  (state, scope) => state.loader[scope],
  (loaderState) => {
    const { loadCounter = 0, message = null, background = false, fullscreen = false } = loaderState || {};
    return { loadCounter, message, background, fullscreen };
  }
);

export const useLoader = (scope) => {
  return useSelector(state => selectLoaderState(state, scope), shallowEqual);
};

export const useLoaderDispatch = (scope) => {
  const dispatch = useDispatch();
  return {
    addLoaderCounter: useCallback((props = {}) => dispatch(addLoaderCounter({ ...props, scope })), [dispatch, scope]),
    subLoaderCounter: useCallback((props = {}) => dispatch(subLoaderCounter({ ...props, scope })), [dispatch, scope]),
    clearLoaderCounter: useCallback(() => dispatch(clearLoaderCounter({ scope })), [dispatch, scope])
  };
};
