import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const MapViewIcon = (props) => (
    <SvgIcon viewBox={"0 -5 100 110"} {...props}>
        <g id="mapa" transform="translate(0 -6.231)">
            <g id="Group_10" data-name="Group 10" transform="translate(0 6.231)">
                <g id="Group_9" data-name="Group 9" transform="translate(0 0)">
                    <path id="Path_104" data-name="Path 104"
                          d="M347.275,58.6l5.248-3.551a32.27,32.27,0,0,0-3.849-4.652l-.707-.688-4.351,4.6.574.558A25.644,25.644,0,0,1,347.275,58.6Z"
                          transform="translate(-275.518 -41.089)"/>
                    <path id="Path_105" data-name="Path 105"
                          d="M376.713,115.695l6.268-.951a31.629,31.629,0,0,0-1.811-6.8l-5.907,2.305A25.538,25.538,0,0,1,376.713,115.695Z"
                          transform="translate(-300.894 -87.787)"/>
                    <path id="Path_106" data-name="Path 106"
                          d="M193.689,87.849a15.4,15.4,0,1,0,15.4,15.4v0A15.417,15.417,0,0,0,193.689,87.849Zm0,24.454v.006a9.059,9.059,0,1,0,0-.006Z"
                          transform="translate(-142.955 -71.674)"/>
                    <path id="Path_107" data-name="Path 107"
                          d="M77.62,54.7a31.632,31.632,0,0,0,4.756-14.475l-6.342-.453a25.316,25.316,0,0,1-7.366,16.108L50.734,73.816,32.8,55.882A25.367,25.367,0,0,1,63.6,16.065l3.222-5.454A31.725,31.725,0,0,0,22.8,52.971L0,57.543V105.23l34.88-6.976L66.589,104.6l34.88-6.976V49.93ZM31.709,92.42,6.342,97.493V62.737l20.411-4.09c.5.583,1.008,1.167,1.56,1.719l3.4,3.4Zm31.709,5.073L38.051,92.42V70.1L50.734,82.787,63.418,70.1ZM95.127,92.42,69.76,97.493V63.761l1.268-1.268,24.1-4.826Z"
                          transform="translate(0 -6.231)"/>
                </g>
            </g>
        </g>
    </SvgIcon>
);

export default MapViewIcon;