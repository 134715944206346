import React, { useCallback, useMemo } from 'react';
import { useI18n, useReportKey } from '../../../../hooks';
import BaseFilter from '../FilterLoader/Filters/BaseFilter/BaseFilter';
import { isValueAll } from '../../../../utils';
import { NONE, ON_INIT, REQUIRED } from '../../../../constants';
import { useOnePlatformFormsState } from '../../../../hooks/onePlatformForms';
import {useHandleError} from "../FormLoader/useHandleError";

export const SelectInputToolbar = ({
                                    properties: {
                                        name,
                                        label,
                                        idSelect = name,
                                        className,
                                        validations = [],
                                        errorPlacement = 'right',
                                        showErrorOnBlur = false,
                                        inputDisabled = false
                                    },
                                    onError,
                                    value,
                                    options = [],
                                    onChange,
                                    disabled,
                                    forceDisplayError,
                                    multiselect, isAllAllowed,
                                    field,
                                    formMode,
                                    //filter
                                    additionalStyleChildren = {},
                                }) => {

    const selectedValue = useMemo(() => ([{ name: value, value }]), [value]);
    const { translate } = useI18n('common.filterTable');
    const { reportKey } = useReportKey();
    const {  fetchingOptions = NONE } = useOnePlatformFormsState(reportKey, formMode, 'fields', field.key);
    const fieldConfiguration = field?.configurationField;
    const enableAutoSuggest = fieldConfiguration?.hasOwnProperty('enableAutoSuggest')?fieldConfiguration.enableAutoSuggest:true;

    const getError = useCallback((value) => {
        for (const validate of validations || []) {
            switch (validate.type) {
                case REQUIRED:
                    if (!(value && value.length > 0)) {
                        return validate.errorMessage;
                    }
                    break;
                default:
            }
        }
        return '';
    }, [validations]);

    const {
        errorMessage,
        displayError,
        //handleOnBlur,
    } = useHandleError({ getError, value, onError, forceDisplayError, showErrorOnBlur });

    return (
        <div className={'form-select-input-container-toolbar' + (className ? ' ' + className : '') + (disabled ? ' disabled' : '') + (displayError && errorMessage ? ' display-error' : ' no-display-error')} style={additionalStyleChildren}>

            <div className="form-select-input-toolbar" style={{padding:'0px'}}>
                <BaseFilter
                    properties={{
                        title: translate(label),
                        withAll: isAllAllowed,
                        multiSelect: multiselect,
                        separatorCharacter: fieldConfiguration?.separatorCharacter || ',',
                        noAllowedCharacters: fieldConfiguration?.noAllowedCharacters || '',
                        isValueAll,
                        enableSelectOnLabel: false,
                        extraClassInputBox:(options||[]).length>9999?'input-toolbar-open-options':'',
                        classInputBox:'input-toolbar ',//+(options||[]).length>9999?'open-options':'',
                        extraClassInputContainer:'input-toolbar-container',
                        labelOptions:'Pages',
                        enableMoreIcon:true,
                        styleInput:{color:'#000000'},
                        enableMaxHeight:false,
                        inputDisabled:inputDisabled,
                        allowedCharacters:"0123456789",
                    }}
                    //renderInputBox={(inputProps,renderDefaultInputBox)=>renderDefaultInputBox}
                    selectedValues={selectedValue || []}
                    recentSearches={fieldConfiguration?.recentSearches}
                    suggestions={options}
                    fetchingOptions={fetchingOptions}
                    onInit={() => {
                        if (fieldConfiguration?.requestMode === ON_INIT) {
                            //let queryParams = {};
                            if (fieldConfiguration?.withPagination) {
                                //queryParams = { size: fieldConfiguration?.optionsOffset || 100, page: 0 };
                            }
                            //loadConfigurationField(formMode, field.key, queryParams)();
                        }
                    }}
                    onInputChange={({ newSuggest, opening }) => {
                        if (!opening) {

                            const query = newSuggest || '';
                            const newKeyQuery = field?.configurationField?.keyFilterQueryParams || field.key;
                            const queryParams = { size: fieldConfiguration?.optionsOffset || 100, page: 0 };
                            if (query) {
                                queryParams[newKeyQuery] = query + '*';
                            }
                            if(enableAutoSuggest){
                                //loadConfigurationField(formMode, field.key, queryParams)();
                            }
                        }
                    }}
                    onChangeSelectValues={(values, recentSearches) => {
                        onChange((values || []).map(value => value.value).join(','));
                    }}
                    enableTitle={false}
                    enabledFilter={!disabled}
                    onOpenOptions={() => {
                        const query = '';
                        const newKeyQuery = fieldConfiguration?.configurationField?.keyFilterQueryParams || field.key;
                        const queryParams = { size: fieldConfiguration?.optionsOffset || 100, page: 0 };
                        if (query && enableAutoSuggest) {
                            queryParams[newKeyQuery] = query + '*';
                        }
                        //loadConfigurationField(formMode, field.key, queryParams)();
                    }}
                    validateOnInputChange={(params)=>{
                        const {newSuggest,suggestions} = params;
                        const defaultPage = 1;
                        const optionSelect = newSuggest && (suggestions||[]).find(({name})=> name===newSuggest);
                        const result = (!newSuggest)|| optionSelect;
                        return {isValidateInputChange:result,newValue:result?newSuggest?newSuggest:defaultPage:defaultPage};
                    }}
                />
            </div>
        </div>
    );
};