import React from 'react';
import Button from "@material-ui/core/Button";
import WarningIcon from '@material-ui/icons/Warning';
// import LinkedDevicesPanel from '../accordionItems/LinkedDevicesPanel';
import Dialog from '@material-ui/core/Dialog';
import {DialogActions, DialogTitle} from "@material-ui/core";
import {DialogContent} from '@material-ui/core/es';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/Icon';

const MESSAGE_DIALOG = "Are you sure to delete this firmware?";
const MESSAGE_CERTIFICATE_DIALOG = "Are you sure to delete this certificate?";
const MESSAGE_CONTENT = "The following firmware will be removed";
const MESSAGE_CERTIFICATE_CONTENT = "The following certificate will be removed";

const DialogFirmware = (props) => {

    const actionButtons = (
        <div className='dialog-buttons'>
            <Button className={'alert-confirm-button'} onClick={()=>props.onDelete(props.row)} style={{left: 8}}>Delete</Button>
            <Button className={'alert-close-button'} onClick={props.onClose} style={{left: 8}}>Close</Button>
        </div>
    );

    // const contentData = () => {
    //     return (<div>
    //         <div style={{display: 'flex', margin: '10px 5px', color: '#2891bc', fontStyle: 'italic'}}><InfoIcon/>{MESSAGE_CONTENT}</div>
    //         <LinkedDevicesPanel showWarning={false} checkColumn={false} />
    //     </div>)
    // };

    const renderCloseButton = () => {
        return (
            <div style={{position:'absolute',top:'1em',right:'1em',color:'#777'}}>
                <IconButton aria-label="close"
                            onClick={props.onClose}
                            style={{cursor:'pointer',position:'relative',zIndex:'100'}}
                >
                    <CloseIcon />
                </IconButton>
            </div>
        );
    };


    return (
        <Dialog
            open={props.openModal}
            onClose={props.onClose}
            maxWidth={'xs'}
            PaperProps={{style: {overflowY: 'visible'}}}
        >
            {renderCloseButton()}
            <DialogTitle style={{paddingRight: 50}}>
                <div style={{fontWeight:'300',fontSize:'0.9em'}}>
                    {props.isCertificate ? MESSAGE_CERTIFICATE_DIALOG : MESSAGE_DIALOG}
                </div>
            </DialogTitle>
            <DialogContent>
                <div style={{color: '#0009', minHeight: 40, height: 'auto', width:"auto"}}>
                    {/*{(linkedDevices && linkedDevices.length > 0) ? contentData() : MESSAGE_DIALOG}*/}
                    {props.isCertificate && (
                        <div style={{ display: 'flex', gap: '10px', alignItems: 'center', marginBottom: '20px' }}>
                            <WarningIcon />
                            Some devices could be using this certificate. In that case, those devices will need to be updated.
                        </div>
                    )}
                    {props.isCertificate ? MESSAGE_CERTIFICATE_CONTENT : MESSAGE_CONTENT}
                    <div style={{paddingTop:'0.5em'}}>
                        <label style={{fontWeight:'bold'}}>{props.row.fileName}</label>
                    </div>
                </div>
            </DialogContent>
            <DialogActions className='dialog-buttons'>
                {actionButtons}
            </DialogActions>
        </Dialog>
    )
};

export default DialogFirmware;