export const GET_SYSTEM_ALERT_DATA = "GET_SYSTEM_ALERT_DATA";
export const GET_SYSTEM_ALERT_SETTINGS_DATA = 'GET_SYSTEM_ALERT_SETTINGS_DATA';
export const GET_NOTIFICATION_BY_ALERT_NAME = 'GET_NOTIFICATION_BY_ALERT_NAME';
export const CHANGE_ALERT_MESSAGE_MODAL_STATUS = 'CHANGE_ALERT_MESSAGE_MODAL_STATUS';
export const UPDATE_EMAIL_NOTIFICATION_MODAL_STATUS = 'UPDATE_EMAIL_NOTIFICATION_MODAL_STATUS';
export const UPDATE_EMAIL_NOTIFICATION_DATA = 'UPDATE_EMAIL_NOTIFICATION_DATA';
export const SET_SELECTED_ALERT_DEFINITION = 'SET_SELECTED_ALERT_DEFINITION';
export const GET_ALERT_RECIPIENTS = 'GET_ALERT_RECIPIENTS';
export const OPEN_DIALOG_CONTACT = 'OPEN_DIALOG_CONTACT';
export const CONTACT_DIALOG_MODE = 'CONTACT_DIALOG_MODE';
export const SET_CONTACT = 'SET_CONTACT';
export const SITE_NOTIFICATION = 'SITE_NOTIFICATION';
export const RECIPIENTS_LIST = 'RECIPIENTS_LIST';
export const LOADING_SITE_NOTIFICATION = 'LOADING_SITE_NOTIFICATION';