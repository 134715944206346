import { createRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import {refStore} from '../../../actions';
import {
  IconButton,
  ListItem, ListItemIcon, ListItemText
} from '@material-ui/core';

import LayersIcon from '@material-ui/icons/LayersRounded';
import StreetviewIcon from '@material-ui/icons/Streetview';
import EditIcon from '@material-ui/icons/CreateRounded';
import DeleteIcon from '@material-ui/icons/DeleteRounded';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { AutoSizer } from 'react-virtualized';
import { VariableSizeList } from 'react-window';
import {DialogType, ItemType, SITE_MANAGER_CONFIGURATION_ZONES_SCOPE} from "../../../../../constants";
import {getOlStoreKey} from "../../../../../ol/helpers";
import {useSiteManagerDispatch} from "../../../../../hooks";
import {makeStyles} from "@material-ui/core/styles";


const GROUP_TYPE = 'groupType';
const ITEM_TYPE = 'itemType';

const useStyles = makeStyles((theme) => ({
  listIcon: {
    minWidth: 35
  },
  zoneList: {
    paddingLeft: theme.spacing(2),
    "&.selected": {
      backgroundColor: "rgba(0,119,159,0.2)",
    }
  },
  zoneGroup: {
    "&.selected": {
      backgroundColor: "rgba(0,119,159,0.3)",
    }
  },
}),{index:1});

const getItemSize = (width, text) => {
  const lineHeight = 35;
  let maxCharPerLine = 10;

  if (width <= 150) {
    maxCharPerLine = 6;
  } else if (width <= 200) {
    maxCharPerLine = 10;
  } else if (width <= 250) {
    maxCharPerLine = 17;
  } else if (width <= 300) {
    maxCharPerLine = 24;
  } else if (width <= 350) {
    maxCharPerLine = 30;
  } else if (width <= 400) {
    maxCharPerLine = 40;
  } else if (width <= 450) {
    maxCharPerLine = 50;
  } else {
    maxCharPerLine = 60;
  }
  const numLines = Math.ceil(text.length / maxCharPerLine);
  return numLines * lineHeight;
}

const ZBPanelZones = ({ zones, allGroups, onZonesClicked,onZoneGroupEdit,isSelectedMultipleZoneGroups }) => {

  const classes = useStyles();
  const selectedZoneIds = useSelector(state => state.zoneBuilder.selectedZoneIds);
  const { replaceForm, openDialog } = useSiteManagerDispatch(SITE_MANAGER_CONFIGURATION_ZONES_SCOPE);
  const listRef = createRef();
  const [collapsedGroups, setCollapsedGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);

  useEffect(() => {
    handleResize();
    // eslint-disable-next-line
  }, [zones]);

  const handleExpandedGroups = (e, groupName) => {
    e.stopPropagation();
    if (collapsedGroups!=null&&collapsedGroups.includes(groupName)) {
      setCollapsedGroups((prev) => ([...prev.filter(item => item !== groupName)]));
    } else {
      setCollapsedGroups((prev) => ([...prev, groupName]));
    }
  };

  const groupIsCollapsed = (groupName) => {
    if (collapsedGroups!=null&&collapsedGroups.includes(groupName)) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    if (selectedZoneIds.length) {
      const id = selectedZoneIds[0]; // scroll to first zone selected
      const zone = zones.find(zone => zone.data.id === id);
      if (listRef.current!=null&&zone!=null&&!collapsedGroups.includes(zone.data.groupName)) {
        const currentZones = listRef.current.props.itemData;
        const index = currentZones.findIndex((i) => i.id === zone.data.id);
        listRef.current?.scrollToItem(index);
      }
    }
    // eslint-disable-next-line
  }, [selectedZoneIds]);

  const onGroupNameEdit = (e,item) => {
    e.stopPropagation();
    onZoneGroupEdit(item.groupName);
  }

  const onZoneDelete = (e, zone) => {
    e.stopPropagation();
    refStore.configZonesMap?.[getOlStoreKey(ItemType.ZONE)]?.clearSelectedItems();
    refStore.configZonesMap?.[getOlStoreKey(ItemType.ZONE)]?.addSelectedItems([ zone.id ], { forceMultiSelection: true });
    replaceForm({ form: { hasChildren: false } });
    openDialog({ key: DialogType.DELETE_ZONE });
  }

  const getZoneGroupBackground = (groupName) => {
    let backgroundColor = 'inherit';
    const allZonesSelected = zones.filter((zone) =>
      selectedZoneIds.includes(zone.data.id)
    );
    const selectedGroups = allZonesSelected.map((zone) => zone.data.groupName);
    const isSelectedMultipleZoneGroups =
      [...new Set(selectedGroups)].length > 1;

    if (isSelectedMultipleZoneGroups) {
      backgroundColor = 'inherit';
    } else {
      const groupItems = zones.filter(
        (zone) => zone.data.groupName === groupName
      );
      const allZoneGroup =
        groupItems.length === selectedZoneIds.length &&
        groupItems.every((item) => selectedZoneIds.includes(item.data.id));
      if (allZoneGroup) {
        backgroundColor = 'rgba(0,119,159,0.4)';
      } else {
        const someZones = groupItems.some((zone) =>
          selectedZoneIds?.includes(zone.data?.id)
        );
        if (someZones && !allZoneGroup) {
          backgroundColor = 'rgba(0,119,159,0.15)';
        }
      }
    }

    return backgroundColor;
  };

  const onGroupSelect = (groupName) => {

    if(selectedGroup!=null&&selectedGroup===groupName){
      refStore.configZonesMap?.[getOlStoreKey(ItemType.ZONE)]?.clearSelectedItems();
      setSelectedGroup(null);
    }
    else {
      console.time("select group");
      const selectedZonesOfGroup = [];
      console.time("foreach");
      zones.forEach(zone => {
        if (zone.data.groupName === groupName)
          selectedZonesOfGroup.push(zone.data.id);
      });
      console.timeEnd("foreach");
      refStore.configZonesMap?.[getOlStoreKey(ItemType.ZONE)]?.clearSelectedItems();
      refStore.configZonesMap?.[getOlStoreKey(ItemType.ZONE)]?.addSelectedItems(selectedZonesOfGroup);
      setSelectedGroup(groupName);
      console.timeEnd("select group");
    }
  }

  const renderRow = (props) => {
    const {index, style, data} = props;
    const item = data[index];
    switch (item.renderType) {
      case GROUP_TYPE:
        return (
            <ListItem
                button
                key={index}
                style={{...style, backgroundColor: getZoneGroupBackground(item.groupName)}}
                onClick={() => onGroupSelect(item.groupName)}
            >
              <ListItemIcon className={classes.listIcon}>
                <LayersIcon style={{ color: '#1a1a1a' }} />
              </ListItemIcon>
              <ListItemText primary={item.groupName} />
              <IconButton size={'small'} onClick={(e) => onGroupNameEdit(e, item)}>
                <EditIcon />
              </IconButton>
              <IconButton size='small' onClick={(e) => handleExpandedGroups(e, item.groupName)}>
              {groupIsCollapsed(item.groupName) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
            </ListItem>
        );
      case ITEM_TYPE:
        return (
            <ListItem
                button
                key={index}
                style={style}
                id={item.id}
                onClick={() => onZonesClicked(item.id)}
                className={classnames(classes.zoneList, {
                  selected: selectedZoneIds?.find((zoneId) => zoneId === item.id),
                })}
            >
              <ListItemIcon className={classes.listIcon}>
                <StreetviewIcon style={{ color: item.color }} />
              </ListItemIcon>
              <ListItemText primary={item.name} />
              <IconButton
                  size='small'
                  onClick={(e) => onZoneDelete(e, item)}
              >
                <DeleteIcon />
              </IconButton>
            </ListItem>
        );
      default:
        return null;
    }
  }

  const handleResize = () => {
    if (listRef?.current) {
      listRef.current.resetAfterIndex(0);
    }
  }

  const renderContent = () => {
    const groupedZones = getContentToRender();
    if (groupedZones) {
      return (
            <AutoSizer onResize={handleResize}>
              {({ width, height }) => (
                  width && <VariableSizeList
                      height={height}
                      itemCount={groupedZones.length}
                      itemSize={(index) => getItemSize(width, groupedZones[index].text)}
                      width={width}
                      itemData={groupedZones}
                      ref={listRef}
                  >
                    {renderRow}
                  </VariableSizeList>
              )}
            </AutoSizer>
      );
    }
    return null;
  };

  const getGroupedZones = () => {
    return zones.reduce((result, obj) => {
      const groupName = obj.data.groupName;
      if (!result[groupName]) {
        result[groupName] = [];
      }
      result[groupName].push(obj);
      return result;
    }, {});
  };

  const getContentToRender = () => {
    if (zones.length) {
      const groupedZones = getGroupedZones();
      const result = [];
      Object.entries(groupedZones).forEach(([groupName, zones]) => {
        result.push({groupName: groupName, renderType: GROUP_TYPE, text: groupName });
        if (!groupIsCollapsed(groupName)) {
          result.push(...zones.map(zone => ({...zone.data, renderType: ITEM_TYPE, text: zone.data.name })));
        }
      });
      return result;
    }
    return null;
  }

  return (
      <div style={{width: '100%', height: '100%', padding: '1px'}}>
        {renderContent()}
      </div>
  );
}

export default ZBPanelZones;