


import React from 'react';
import PropTypes from 'prop-types';


/**
 * A common Page template that every page in Zebra Application inherited from. This provide common look and feel
 * as well as sub-header functionality. Sub header contain breadcrumbs and another UI components related to this page.
 *
 * @todo We have to implements how to handle bread crumb items.
 *
 * @example
 *
 * import ZPage from "<common_page>";
 *
 * // your page in application looks like below.
 * // This will render with subheader i.e it will split your MyPage with two container that contain subheader and main content.
 * // If you don't want subheader make zShowSubH eader props to false
 *
 *
 *
 * class MyPage extends React.Component {
 *
 *  render () {
 *      <ZPage  zBreadCrumbs=["Subpage","Sub-Subpage"]
 *              zShowSubHeader={true}
 *              zSubHeaderHeight={200}>
 *          <div>
 *              <some-components/>
 *          </div>
 *      </ZPage>
 *  }
 * }
 *
 *
 */

class ZPage extends React.Component{


    static get propTypes(){
        return {
            children: PropTypes.node
        };
    }

    render () {
        return (
            <div ref="zPageRef" className="zPage" style={{height:'100%'}}>
                <div className="zPageContent">
                    {this.props.children}
                </div>
            </div>
        );
    }

}
export default ZPage;
