import * as types from '../constants/ActionTypes';
import Promise from 'promise';
import { unsubscribeToWS} from './util/websocket/api';
import {showErrorMessage} from "./reports";
import {getReportKeys} from "../hooks";
// -----------------------------------------------------------------------------
// snackbar

export const displayMessage = text => ({ type:types.DISPLAY_SNACKBAR_MESSAGE, text});

export const closeSnackbar = () =>({type:types.CLOSE_SNACKBAR});

// -----------------------------------------------------------------------------
// error dialog
export const displayErrorDialog = (err) => {
    return (dispatch, getState)=>{
        let state = getState();
        if(state.user.jwtToken != null) {
            const {title, message, noconsole, details} = err;

            dispatch({type: types.DISPLAY_ERROR_DIALOG, title, message, details});

            if (!noconsole) {
                console.error(err); // eslint-disable-line
            }
        }
    };
};
export const closeErrorDialog = () => ({type:types.CLOSE_ERROR_DIALOG});


// -----------------------------------------------------------------------------
// dataEntry
export const openDataEntry = (form,data) =>({type:types.OPEN_DATAENTRY_FORM, form, data});
export const closeDataEntry = () => ({type:types.CLOSE_DATAENTRY_FORM});

// -----------------------------------------------------------------------------

let cleanUpCallbacks = [];

export const addCleanUpCallback = (cb) => {
    return ()=>{
        cleanUpCallbacks.push(cb);    
    };
};

export const signOut = () => {
    return dispatch => {

        dispatch({type:types.SIGN_OUT});

        window.localStorage.setItem("jwtToken",null);
        window.localStorage.setItem("username",null);

        cleanUpCallbacks.forEach(cb=>cb());
        unsubscribeToWS();
        return Promise.resolve();
    };
};

export const expireSession = ()=>{
    return (dispatch,getState) => {

        if(!getState().user.username){
            return; // already informed logged out
        }
        dispatch(signOut());
        unsubscribeToWS();
        window.alert("Your token has expired.");
        window.location = "/#/login";
    };
};

export  const showErrorInLayoutContent = ({message,sessionHasExpired=false}) => {
    return (dispatch,getState) =>{
        const { reportKey } = getReportKeys(getState());
        dispatch(showErrorMessage({reportKey,message,sessionHasExpired}));
    }
}