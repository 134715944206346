import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import { ZONE_BUILDER_FIELDS } from '../../../vss/app/constants/SiteDesigner';
import { I18n } from 'react-redux-i18n';
import { useSelector } from 'react-redux';
import { useSiteDesignerDispatch, useSiteDesignerState, useZoneBuilderState } from '../../../vss/app/hooks';
import { refStore } from '../../../vss/app/actions';
import { DialogType, ItemType, SITE_MANAGER_CONFIGURATION_ZONES_SCOPE } from '../../../constants';
import { useSiteManager, useSiteManagerDispatch } from '../../../hooks';
import { FormFields } from '../../../components/form';
import { getCoordinatesFields } from '../../../helpers';
import {makeStyles} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const translate = (key) => I18n.t('common.' + key);

const useStyles = makeStyles({
    paperDialog: {
        overflowY: 'visible',
    },
});

export const DialogZoneDetails = ({ onClose }) => {
    
    const { items, form, dialog } = useSiteManager(SITE_MANAGER_CONFIGURATION_ZONES_SCOPE);
    const [ formFields, setFormFields ] = useState({});
    const { formFieldErrors, selectedMapSD } = useSiteDesignerState();
    const { zoneTypes } = useZoneBuilderState();
    const { clearFormErrors, clearSuccessful, validateForms } = useSiteDesignerDispatch();
    const { updateItemByItemTypeItemId, bulkCreateItemByItemType } = useSiteManagerDispatch(SITE_MANAGER_CONFIGURATION_ZONES_SCOPE);
    const mapId = useSelector(state => state.reportMap.mapId);
    const siteId = selectedMapSD.siteId;
    const classes = useStyles();
    
    const handleInitDialog = () => {
        return () => {
            clearFormErrors();
            clearSuccessful();
        };
    };
    
    useEffect(handleInitDialog, [ clearFormErrors, clearSuccessful ]);
    
    const handleUpdateZoneFields = () => {
        const coordinateFormat = { ...ZONE_BUILDER_FIELDS.coords.format };
        setFormFields({
            name: ZONE_BUILDER_FIELDS.name,
            type: { ...ZONE_BUILDER_FIELDS.type, options: zoneTypes.map(item => item.name) },
            coords: { ...ZONE_BUILDER_FIELDS.coords, fields: getCoordinatesFields(form?.coordinates?.length || 0, coordinateFormat) },
        });
    };
    useEffect(handleUpdateZoneFields, [ form, zoneTypes ]);
    
    const handleSaveZone = async () => {
        const { success } = await validateForms(form, ItemType.ZONE, dialog.key, items?.[ItemType.ZONE]?.filter(zone => zone.id !== form.itemId));
        if (success) {
            const name = form.name;
            const type = form.type;
            const color = form.color;
            const shapeCoords = form.coordinates.map((coord, index) => ({
                sequence: index + 1,
                x: coord[0],
                y: coord[1],
            }));
            const zonePayload = {
                mapId,
                name,
                color,
                shape: shapeCoords,
                type: type,
                parentId: null,
                childrenZones: [],
            };
            switch (dialog.key) {
                case DialogType.ADD_ZONE: {
                    const { success } = await bulkCreateItemByItemType({
                        siteId,
                        mapId: mapId,
                        itemType: ItemType.ZONE,
                        payload: [ zonePayload ],
                        takeSnapshotAfterChanges: false,
                        withoutReloadingItems: true,
                    });
                    if (success) {
                        onClose();
                        refStore.configZonesMap?.initializeDraw();
                        refStore.configZonesMap?.observer?.executeEvent('onSaveZoneChanges', {
                            zonesBeforeChanges: refStore.configZonesMap?.getFeaturesDataArrayByItemType(ItemType.ZONE),
                            zonesAfterChanges: refStore.configZonesMap?.getFeaturesDataArrayByItemType(ItemType.ZONE),
                            takeASnapshot: true,
                            event: 'saveZone',
                        });
                    }
                    break;
                }
                case DialogType.EDIT_ZONE:
                    const { success } = await updateItemByItemTypeItemId({
                        siteId,
                        mapId: mapId,
                        itemType: ItemType.ZONE,
                        itemId: form.itemId,
                        payload: zonePayload,
                    });
                    if (success) {
                        onClose();
                    }
                    break;
                default:
                    break;
            }
        }
    };
    
    const handleClose = () => {
        switch (dialog.key) {
            case DialogType.ADD_ZONE:
                refStore.configZonesMap?.removeDraw();
                refStore.configZonesMap?.initializeDraw();
                break;
            case DialogType.EDIT_ZONE:
            default:
        }
        onClose();
    };
    
    const renderCloseButton = () => {
        return (
            <div style={{ position: 'absolute', top: '-0.5em', right: '-0.5em' }}>
                <IconButton
                    aria-label="close"
                    size="small"
                    onClick={handleClose}
                    style={{ cursor: 'pointer', position: 'relative', zIndex: '100', backgroundColor: '#000000', width: '30px', height: '30px' }}
                >
                    <CloseIcon style={{ color: '#fff' }} />
                </IconButton>
            </div>
        );
    };
    
    return (
        <Dialog
            open={true}
            fullWidth={true}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    handleClose();
                }
            }}
            className="dialog-zones"
            classes={{ paper: classes.paperDialog }}
        >
            <DialogTitle style={{ backgroundColor: '#000000', color: '#fff' }}>Zone Details</DialogTitle>
            {renderCloseButton()}
            <DialogContent style={{padding: '8px 24px'}}>
                <FormFields
                    scope={SITE_MANAGER_CONFIGURATION_ZONES_SCOPE}
                    fields={formFields}
                    formError={formFieldErrors}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    style={{ background: '#fff', color: '#007CB0', padding: '0.5em', textTransform: 'none' }}
                    onClick={handleClose} component="span"
                >
                    {translate('Cancel')}
                </Button>
                <Button
                    style={{
                        background: '#007CB0',
                        color: '#fff',
                        padding: '0.5em',
                        width: '200px',
                        textTransform: 'none',
                    }}
                    onClick={handleSaveZone} component="span"
                >
                    {translate('Save')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
