import { CHANGE_RESOURCE_FILTER_VALUE, CLEAN_FIELDS_RESOURCE, LOAD_SITES_RESOURCES, RESOURCE_SELECTION, UPDATE_VALIDATED_FIELDS_RESOURCE } from '../constants/ActionTypes';
import {displayAllErrorFromAxios, displayErrorFromAxios, ResourceService} from './util';
import {UPDATE_RESOURCE_FIELD, UPDATE_RESOURCE_URL_PARAMS} from '../views/resourceTypes/constants/ResourceTypes';
import {
    VALIDATION_TYPE_REQUIRED,
    VALIDATION_DUPLICATED_VALUES_IN_ARRAY,
    VALIDATION_TYPE_JSON
} from '../constants/ResourceTypes';
import { EMPTY_OBJECT, ENDPOINTS, REPORT_ID_RESOURCES} from '../constants';
import { getReportKeys } from '../hooks';
import { tableOnePlatformReplaceFocusedRows, tableOnePlatformReplaceSelectedRows } from './tableOnePlatform';
import { showDialogForm } from './formOnePlatform';
import { getDataWithFilter } from './reports';
import isEqual from 'lodash/isEqual';
import { getLookup } from '../utils';
import {getJsonArray, isValidDataType, TYPE_DATA} from "./resource-types";
import {IdBsonType} from "../views/resourceTypes/toolbar/accordionItemsResourceType/ResourceTypeAttributes";

export const getFilterWithoutWildcard = (valueIds) => {
    const listIds = valueIds.split(',');
    if (listIds.length > 1) {
        return listIds.filter(value => !value.includes('*')).toString();
    } else {
        return valueIds;
    }
};

export const getSitesList = () => {
    return async (dispatch, getState) => {
        const resourceAPI = getState().resources.resourcesURL.listSites;
        const result = await ResourceService.instance().request(
            {
                url: resourceAPI.url,
                method: resourceAPI.method,
            },
        ).catch(displayErrorFromAxios.bind(null, dispatch));
        dispatch({ type: LOAD_SITES_RESOURCES, sitesData: result.data });
    };
};

export const initFormsResource = () => {
    return (dispatch, getState) => {
        const needsValidationFieldsResource = getState().resources.needsValidationFieldsResource;
        Object.keys(needsValidationFieldsResource).forEach(sectionKey => {
            const section = needsValidationFieldsResource[sectionKey];
            section && Object.keys(section).forEach(field => {
                const validationInfo = section[field];
                validationInfo.error = false;
            });
        });
        dispatch({ type: CLEAN_FIELDS_RESOURCE, needsValidationFieldsResource: needsValidationFieldsResource });
    };
};

export const validateFieldsResource = (resourceObject, modeDialog, resourceObjectInit) => {
    return async (dispatch, getState) => {
        const validateResults = validateFormResource(resourceObject, getState().resources.needsValidationFieldsResource);
        const successful = !validateResults.error;
        dispatch({ type: UPDATE_VALIDATED_FIELDS_RESOURCE, needsValidationFieldsResource: {...validateResults.needsValidationFieldsResource}, statusModalResourceAddEdit: successful, selectResource: resourceObject });
        if (successful === true) {
            switch (modeDialog) {
                case 'MENU_ADD':
                    dispatch(addNewResource(resourceObject, modeDialog));
                    break;
                case 'MENU_EDIT':
                    dispatch(editResource(resourceObject, resourceObjectInit));
                    break;
                default:
                    break;
            }
        }
        return successful;
    };
};

export const validateFormResource = (resourceObject, needsValidationFieldsResource) => {
    const { customProperties } = resourceObject;
    let error = false;
    Object.keys(needsValidationFieldsResource).forEach(sectionKey => {
        const section = needsValidationFieldsResource[sectionKey];
        if (sectionKey !== 'Attributes') {
            Object.keys(section).forEach(field => {
                const validationInfo = section[field];
                if (validationInfo.type === VALIDATION_TYPE_REQUIRED) {
                    validationInfo.error = validateRequired(resourceObject[field]);
                }
                else if(validationInfo.type === VALIDATION_DUPLICATED_VALUES_IN_ARRAY){
                    const duplicateIndexes = hasDupsTagObjects(resourceObject[field]);
                    validationInfo.duplicateIndexes = duplicateIndexes;
                    validationInfo.error = duplicateIndexes.length > 0;
                    validationInfo.idError = Date.now();

                    // Validate error in tag or source.
                    const errorsTagOrSource = validateTagAndSource(resourceObject[field]);
                    if(errorsTagOrSource!=null){
                        validationInfo.error=true;
                        validationInfo.errorObject=errorsTagOrSource;
                    }

                }

                if (validationInfo.error === true) {
                    error = true;
                }
            });
        }
    });

    needsValidationFieldsResource['Attributes'] = {};
    if (customProperties && Object.keys(customProperties).length > 0) {
        Object.keys(customProperties).forEach(field => {
            let validationInfo = { error: false, message: 'Mandatory field' };
            if (customProperties[field].required === true) {
                validationInfo.error = validateRequired(customProperties[field].value);
            }
            else if (customProperties[field][IdBsonType] === TYPE_DATA.LIST) {// for validate valid JSON in custom properties, defined if is this bsontype always should be a JSON
                validationInfo = validateJsonString(customProperties[field].value);
                if (validationInfo.error === true) {
                    error = validationInfo.error;
                }
            }
            if (validationInfo.error === true) {
                error = true;
            }

            needsValidationFieldsResource['Attributes'][field] = validationInfo;
        });
    }
    return { error, needsValidationFieldsResource };
};
export const clearValidationFieldResource = (IdSection,key) =>{
    return async (dispatch, getState) => {
        const needsValidationFieldsResource = getState().resources.needsValidationFieldsResource;
        if(needsValidationFieldsResource[IdSection][key] && needsValidationFieldsResource[IdSection][key].hasOwnProperty('error')){
            needsValidationFieldsResource[IdSection][key].error = false;
        }
    }
}
const validateRequired = (fieldValue) => {
    let error = false;
    if (fieldValue === undefined || fieldValue == null || fieldValue === '' || (typeof fieldValue === 'object' && fieldValue.length === 0)) {
        error = true;
    }
    return error;
};

const validateJsonString = (fieldValue) => {
    let validationInfo = { error: true, message: VALIDATION_TYPE_JSON };
    if(fieldValue && typeof fieldValue === 'string')
    {
        try {
            let object=JSON.parse(fieldValue);
            if (object && typeof object === "object") {
                validationInfo.error = false;
            }
            else{
                validationInfo.error = true;
            }

        } catch (e) {
            validationInfo.error = true;
        }

    }else if(fieldValue === null || fieldValue === ''){
        validationInfo.error = false;
    }
    return validationInfo;
};

export const hasDupsTagObjects = (array = []) => {
    return array.map(item => item.tagId + item.tagSource).reduce((acc, el, i, arr) => {
        if(arr.indexOf(el) !== arr.lastIndexOf(el)){
            return [...acc, i];
        }else{
            return acc;
        }
    }, []);
}

export const validateTagAndSource=(tagObject)=>{
    let error=null;

    if(tagObject!=null){
        tagObject.forEach((tagItem,index)=>{
            if((tagItem.tagId==null||tagItem.tagId==="")||(tagItem.tagSource==null||tagItem.tagSource==="")){
                if(error==null){
                    error={};
                }
                error[index]="Tag Id and Source are required"; // Not used message, component is adding the message, we leave in this way for future improvement.
            }
        });
    }
    return error;
};

const getPayload = (resourceObjectParams) => {
    
    const { resourceId, /*listTag*/associatedTags:listTag, customProperties, resourceType, siteId, zoneId, zoneName, zoneGroup, mapId/*, mapName*/, description } = resourceObjectParams;
    const listProperties = customProperties ? Object.keys(customProperties).reduce((acc, at) => isValidDataType(customProperties[at].value,TYPE_DATA.DEFAULT).result ? { ...acc, [at]: customProperties[at][IdBsonType] === TYPE_DATA.LIST? getJsonArray(customProperties[at].value).value: customProperties[at].value } : { ...acc, [at]: null }, {}) : {};
    customProperties && Object.keys(customProperties).forEach(keyName=>{
        const {isChanged} = customProperties[keyName];
        if(!isChanged && listProperties.hasOwnProperty(keyName)){
            delete listProperties[keyName];
        }
    });
    const listTags = listTag ? (Object.keys(listTag).map((index) => {
        return { tagId: listTag[index].tagId, source: listTag[index].tagSource };
    })).filter((value, index, self) => value.tagId !== '' && value.source !== '') : [];
    let zone = {};
    /*if (zoneId || zoneName || zoneGroup || mapId) {

        zone = {
            mapId: mapId ? mapId : undefined,
            siteId: siteId ? siteId : undefined,
            id: zoneId ? zoneId : undefined,
            name: zoneName ? zoneName : undefined,
            zoneGroup: zoneGroup ? zoneGroup : undefined
        };
    }*/
    if (mapId) {
        zone.mapId = mapId;
    }
    
    if (siteId) {
        zone.siteId = siteId;
    }
    
    if (zoneId) {
        zone.id = zoneId;
    }
    
    if (zoneName) {
        zone.name = zoneName;
    }/*else{
        zone.name = "On-Site";
    }*/
    
    if (zoneGroup) {
        zone.groupName = zoneGroup;
    }/*else{
        zone.groupName = "Default";
    }*/
    
    let location = {};
    if (mapId) {
        location = {
            x: 0,
            y: 0,
            z: 0,
            mapId: mapId,
        };
    }
    let resource = {
        resourceId: resourceId,
        resourceType: resourceType,
        associatedTags: listTags,
        description: description,
    };
    if(listProperties && Object.keys(listProperties).length>0){
        resource.customProperties = listProperties;
    }
    if (siteId) {
        resource.siteId = siteId;
    }
    
    if (location && Object.keys(location).length > 0 && !zone.hasOwnProperty('id')) {
        resource.location = location;
        resource.locateType = 'MANUAL';
    }
    if (Object.keys(zone).length > 0 && (zone.hasOwnProperty('mapId')
        /*&& zone.hasOwnProperty('id')
        && zone.hasOwnProperty('name')*/
        && zone.hasOwnProperty('groupName')
    )) {
        resource.zone = zone;
    }
    
    return resource;
};

const getZoneFormat = (resourceOld) => {
    let resourceData = {}
    resourceData.mapId = resourceOld?.location?.properties?.mapId ? resourceOld?.location?.properties?.mapId : resourceOld.mapId;
    resourceData.mapName = resourceOld?.location?.properties?.mapName ? resourceOld?.location?.properties?.mapName : resourceOld.mapName;
    resourceData.siteId = resourceOld?.site?.siteId;
    resourceData.siteName = resourceOld?.site?.siteName;
    resourceData.zoneGroup = resourceOld?.zones?.[0]?.zoneGroup;
    resourceData.zoneGroupId = undefined;
    resourceData.zoneId = resourceOld?.zones?.[0]?.zoneId;
    resourceData.zoneName = resourceOld?.zones?.[0]?.zoneName;
    const newResource = getPayload(resourceData);
    resourceOld.zone = newResource?.zone;
    return resourceOld;
}
export const addNewResource = (resourceObjectParams, modeDialog) => {
    return async (dispatch, getState) => {
        const { reportKey } = getReportKeys(getState());
        const { uKeyFields } = getState().report.scopes?.[reportKey] || {};
        const resourceAPI = getState().resources.resourcesURL.add;
        let resourceURL = resourceAPI.url;
        const { resourceType } = resourceObjectParams;
        resourceURL = resourceURL.replace('{resourceType}', resourceType);
        const resource = getPayload(resourceObjectParams);
        try {
            const { data } = await ResourceService.instance().request({ url: resourceURL, method: resourceAPI.method, data: resource });
            const { selectedRows = EMPTY_OBJECT } = getState().tableOnePlatform[reportKey];
            const ___uKey = getLookup({ id: data }, uKeyFields);
            dispatch(tableOnePlatformReplaceSelectedRows({ ...selectedRows, [___uKey]: { ___uKey } }, reportKey));
            dispatch(tableOnePlatformReplaceFocusedRows({ [___uKey]: { ___uKey } }, reportKey));
            dispatch(getDataWithFilter(ENDPOINTS[REPORT_ID_RESOURCES].GET_ALL, false));
            dispatch(showDialogForm(false, ''));
        } catch (error) {
            dispatch(displayAllErrorFromAxios(error));
        }
    };
};

export const changeFilterValue = (filterId, filterKey, value) => {
    return (dispatch, getState) => {
        const { reportKey } = getReportKeys(getState());
        const currentFilter = getState().resources.resourceFilters[filterId];
        let filterObject = { ...currentFilter, [filterKey]: value };
        if (filterId === 'resourceType' && filterKey === 'value' && value !== '') {
            const resourceTypesData = getState().tableOnePlatform[reportKey].data;
            const resourceTypeSelected = resourceTypesData.find(rt => rt.name === value);
            const childrenValues = resourceTypeSelected.customProperties.reduce((acc, field) => {
                acc[field.name] = {
                    // displayName: field.displayName? field.displayName: field.name,
                    displayName: field.name,
                    value: '',
                    type: field.lookups ? 'SELECT' : 'INPUT',
                    options: field.lookups,
                };
                return acc;
            }, {});
            filterObject.children = childrenValues;
        }
        
        if (filterId === 'resourceType' && filterKey === 'value' && value === '') {
            filterObject.children = {};
        }
        dispatch({ type: CHANGE_RESOURCE_FILTER_VALUE, filterId, filterObject });
    };
};

const getResource_Id = (resource) => {
    //return `${resource.resourceId}-${resource.resourceType}`
    return `${resource.id}`;
};
export const unSelectResourceRow = (rowIndex) => {
    return (dispatch, getState) => {
        const resource = getState().resources.resourcesData[rowIndex];
        const currentSelectedRows = getState().resources.selectedRows;
        const newState = Object.assign({}, currentSelectedRows);
        delete newState[getResource_Id(resource)];
        dispatch({ type: RESOURCE_SELECTION, selectedRows: newState });
    };
};

export const selectResourceRow = (rowIndex) => {
    return (dispatch, getState) => {
        const currentSelectedRows = getState().resources.selectedRows;
        const resource = getState().resources.resourcesData[rowIndex];
        const newState = Object.assign({}, currentSelectedRows);
        newState[getResource_Id(resource)] = resource;
        
        dispatch({ type: RESOURCE_SELECTION, selectedRows: newState, selectedResourceId: getState().resources.selectedResourceId || '' });
    };
};

export const resourceSelectAll = (allRows) => ({ type: RESOURCE_SELECTION, selectedRows: allRows });

export const modeUpdateResource = (resource, modeDialog) => {
    return async (dispatch, getState) => {
        const resourceAPI = getState().resources.resourcesURL.oneResource;
        const { resourceId, resourceType } = resource;
        let resourceURL = resourceAPI.url;
        resourceURL = resourceURL.replace('{type}', resourceType);
        resourceURL = resourceURL.replace('{id}', resourceId);
        await ResourceService.instance().request(
            {
                url: resourceURL,
                method: resourceAPI.method,
            },
        ).then(result => {
            let resourceSelect = { ...result.data };
            resourceSelect.resourceId = resourceId;
            resourceSelect.resourceType = resourceType;
            const listTags = resourceSelect.associatedTags ? (Object.keys(resourceSelect.associatedTags).map((index) => {
                return { tagId: resourceSelect.associatedTags[index].tagId, source: resourceSelect.associatedTags[index].tagSource };
            })) : [];
            resourceSelect.associatedTags = listTags;
            dispatch({ type: UPDATE_RESOURCE_FIELD, urlParams:{resourceId:resourceId,resourceType:resourceType}, statusModalResourceAddEdit: true, selectResource: resourceSelect, modeDialog: modeDialog });
        }).catch(displayErrorFromAxios.bind(null, dispatch));
    };
};
//export const showDialogResource = (status, modeDialog) => ({ type: MODE_DIALOG_RESOURCE, statusModalResourceAddEdit: status, modeDialog: modeDialog, selectResource: {} });
export const editResource = (resourceObjectParams, resourceObjectInit) => {
    return async (dispatch, getState) => {
        const resourceAPI = getState().resources.resourcesURL.update;
        //const modeDialog = getState().resources.modeDialog;
        let resource = getPayload(resourceObjectParams);
        // let resourcePrev = getPayload(resourceObjectInit);
        let resourceOld = JSON.parse(JSON.stringify(getState().resources.selectResourcePrev));
        let resourcePrev = getZoneFormat(resourceOld);
        const { resourceIdPrev, resourceTypePrev } = resourceObjectParams;

        delete resource['siteId'];
        for (const key in resource) {
            if (JSON.stringify(resource[key]) === JSON.stringify(resourcePrev[key]) || isEqual(resource[key], resourcePrev[key]) || ((resource[key] === '' || Object.keys(resource[key]).length === 0) && typeof resourcePrev[key] === 'undefined')) {
                delete resource[key];
            }
        }

        let resourceURL = resourceAPI.url;
        resourceURL = resourceURL.replace('{resourceType}', resourceTypePrev);
        resourceURL = resourceURL.replace('{resourceId}', resourceIdPrev);
        if (Object.keys(resource).length > 0) {
            await ResourceService.instance().request(
                {
                    url: resourceURL,
                    method: resourceAPI.method,
                    data: resource,
                },
            ).then(() => {
                // dispatch(getListResourcesWithFilter());
                dispatch({ type: UPDATE_RESOURCE_FIELD, selectResource: null,urlParams:null });
                dispatch(getDataWithFilter(ENDPOINTS[REPORT_ID_RESOURCES].GET_ALL));
                dispatch(showDialogForm(false, ''));
            }).catch(error => {
                const {selectResourcePrev} = getState().resources;
                dispatch({ type: UPDATE_RESOURCE_URL_PARAMS, urlParams:{resourceId:selectResourcePrev.resourceId,resourceType:selectResourcePrev.resourceType}});
                dispatch(displayAllErrorFromAxios(error));
            });
        }else{
            dispatch({ type: UPDATE_RESOURCE_FIELD, selectResource: null,urlParams:null });
            dispatch(showDialogForm(false, ''));
        }
    };
};
export const deleteMultipleResource = (selectRows) => {
    return async (dispatch, getState) => {
        const resources = Object.keys(selectRows).map(key => {
            //return { id: selectRows[key].resourceId, type: selectRows[key].resourceType };
            return { resourceId: selectRows[key].resourceId, resourceType: selectRows[key].resourceType };
        });
        
        const { reportKey } = getReportKeys(getState());
        const resourceAPI = getState().resources.resourcesURL.deleteMultipleResource;
        const resourceURL = resourceAPI.url;
        await ResourceService.instance().request(
            {
                url: resourceURL,
                method: resourceAPI.method,
                data: resources,
            },
        ).then(result => {
            dispatch(showDialogForm(false, ''));
            // dispatch(getListResourcesWithFilter());
            dispatch(getDataWithFilter(ENDPOINTS[REPORT_ID_RESOURCES].GET_ALL));
            dispatch(tableOnePlatformReplaceSelectedRows({}, reportKey));
        }).catch(displayErrorFromAxios.bind(null, dispatch));
    };
};
export const deleteResource = (resourceObjectParams) => {
    return async (dispatch, getState) => {
        const resourceAPI = getState().resources.resourcesURL.deleteOneResource;
        const { resourceId, resourceType } = resourceObjectParams;
        const { reportKey } = getReportKeys(getState());
        let resourceURL = resourceAPI.url;
        resourceURL = resourceURL.replace('{resourceType}', resourceType);
        resourceURL = resourceURL.replace('{resourceId}', resourceId);
        try {
            await ResourceService.instance().request(
                {
                    url: resourceURL,
                    method: resourceAPI.method,
                },
            ).catch(displayErrorFromAxios.bind(null, dispatch));
            dispatch(getDataWithFilter(ENDPOINTS[REPORT_ID_RESOURCES].GET_ALL));
            dispatch(tableOnePlatformReplaceSelectedRows({}, reportKey));
        } catch {
            displayErrorFromAxios.bind(null, dispatch);
        }
    };
};
