import {displayErrorFromAxios, ICService} from './util';

import {requestAppliances} from './appliance-appliances';
import {createTask} from './appliance-tasks';

import Promise from 'promise';

export const getFilterProfiles = ()=>{
    return (dispatch) => {
        return ICService.instance().get(`/appliances/messageFilterProfiles`)
        .then(res=>res.data)
        .catch(displayErrorFromAxios.bind(null,dispatch));
    };
};

export const updateMultipleApplianceFilterProfiles = (uuids,filterProfile)=>{
    return (dispatch) => {
        return Promise.all(
            [uuids.map(
                id=>dispatch(updateApplianceFilterProfile(id,filterProfile))
            )]
        );
    };
};

export const updateApplianceFilterProfile = (uuid,filterProfile)=> {
    return (dispatch) => {

        // Send the actual task...
        return dispatch(
            createTask(
                uuid,
                "SetMessageFilters",
                {
                    messageFilters:filterProfile
                },
                undefined,
                function(){}   // nop
             )
         ).then(()=>{
             dispatch(requestAppliances());
         });

    };
};
