const NormalTheme = {
    table:{
        header:{
            color:'#777',
            backgroundColor:'white'
        },
        filter:{
            backgroundColor:'#00779F'
        },
        body:{
            rowSeparatorColor: '#ddd',
            backgroundColor:'white',
            hover:{
                borderColor:'#00779F',
                backgroundColor:'#F0F8FA'
            }
        },
        resizer:{
            backgroundColor:'#00779F'
        },
        resizerHandle:{
            color:'white'
        }
    }
};

const NoFilterTheme = {
    table:{
        header:{
            color:'white',
            backgroundColor:'#00779F'
        },
        body:{
            rowSeparatorColor: '#ddd',
            backgroundColor:'white',
            hover:{
                borderColor:'#00779F',
                backgroundColor:'#F0F8FA'
            }
        },
        resizer:{
            backgroundColor:'#00779F'
        },
        resizerHandle:{
            color:'white'
        }
    }
};
export {NormalTheme,NoFilterTheme};
