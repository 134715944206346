import React from 'react';
import { useDispatch } from 'react-redux';
import {viewDetailsJumpToSection} from '../../actions';
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import {useReportKey} from "../../hooks";
import {REPORTS_WITH_FIELD_NAMES} from "../../constants";
import {TYPE_SECTION} from "../../utils/CustomCellByConfiguration";


export const FieldNameCells = ({ rowIndex, value, typeSection}) => {
    //console.log({rowIndex,value,typeSection})
    const dispatch = useDispatch();
    const { reportId } = useReportKey();
    const handleShowAttributes = () => {
        if (rowIndex !== undefined) {
            dispatch(viewDetailsJumpToSection(rowIndex));
        }
    };
    if(REPORTS_WITH_FIELD_NAMES.includes(reportId)){
        if(Array.isArray(value)){
            return `${(typeSection===TYPE_SECTION.REPORT?value.slice(0,3):value).join(", ")}${typeSection===TYPE_SECTION.REPORT && value.length>3?', ...':''}`;
        }else{
            return typeof value === "boolean"?''+value:value;
        }
    }else{
        if (typeof value !== 'object') {
            return '';
        } else if (value != null&&typeof value === 'object' && Array.isArray(value)) {
            if (typeSection === 'REPORT') {
                return (
                    <div className={'actionContainer'}>
                        <div style={{textAlign: "center", paddingTop: "0.3em"}}>
                            <MoreHorizIcon onClick={handleShowAttributes} style={{fill: '#819dc9', cursor: "pointer"}}/>
                        </div>
                    </div>
                );
            } else if ('DETAILS') {
                const htmlSubValues = [];
                for (const [keyArrayAttr, objectValueArrayAttr] of Object.entries(value)) {
                    for (const [key, objectValue] of Object.entries(objectValueArrayAttr)) {
                        const newKey = rowIndex+'custom_cell_'+keyArrayAttr+'_'+key;
                        //htmlValues.push(<span style={{paddingLeft: '0px'}} key={newKey} id={newKey}>{key}: {objectValue + ''}</span>);
                        //htmlValues.push(<br/>);
                        htmlSubValues.push(<div key={newKey}>{key}: {objectValue + ''}</div>);
                    }
                }
                return <div key={rowIndex+'_custom_cell'}>{htmlSubValues}</div>
            }
        }else {return null;}
    }
}