import React, { useEffect, useRef, useState } from 'react';
import StyledDialog, { CancelButton, SaveButton } from '../../util/Dialog';

import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { modifyZone, patchReportProperties } from '../../../actions';
import { useOutsideClick, useReportKey } from '../../../hooks';
import { isValidPolygon } from '../utils/polygon';
import { Tooltip } from '@material-ui/core';

/* Move this method to global helpers */
const roundNumberToDecimals = (number, decimal) => {
    const round = decimal * 10;
    return Math.round(number * round) / round;
};

const getCoordsIntoDecimals = ([x, y]) => ([
    roundNumberToDecimals(x, 2),
    roundNumberToDecimals(y, 2),
]);

const CellInput = ({ value, onChange }) => {
    return (
        <td>
            <input value={value} onChange={onChange} type="number" />
        </td>
    );
};

const ButtonAction = ({ onClick, disabled, label, placementTooltip, titleTooltip }) => {
    const [showError, setShowError] = useState(false);
    const buttonRef = useRef();
    useOutsideClick(() => {
        setShowError(false);
    }, buttonRef);
    return (
        <Tooltip
            open={showError}
            arrow={true}
            title={titleTooltip}
            placement={placementTooltip}
            PopperProps={{ disablePortal: true }}
        >
            <button
                onClick={() => {
                    if (disabled) {
                        setShowError(true);
                    } else {
                        onClick();
                    }
                }}
                ref={buttonRef}
                className={disabled ? 'disabled' : ''}
            >
                {label}
            </button>
        </Tooltip>
    );
};
const ShapesDialog = ({ open, onClose, zoneId }) => {
    const zone = useSelector(state => state.zoneBuilder.zones[zoneId]);
    const dispatch = useDispatch();
    const [shape, _setShape] = useState([]);
    const [rowSelected, setRowSelected] = useState(-1);
    const { reportKey } = useReportKey();
    
    useEffect(() => {
        setRowSelected(-1);
    }, [open]);
    useEffect(() => {
        if (rowSelected >= shape.length - 1) {
            setRowSelected(-1);
        }
    }, [rowSelected, shape.length]);
    useEffect(() => {
        const isZoneShape = zone?.data?.shape?.geometry?.coordinates?.[0]?.length;
        if (isZoneShape) {
            const shapeArr = JSON.parse(JSON.stringify(zone?.data?.shape?.geometry?.coordinates?.[0]));
            _setShape(shapeArr);
        }
    }, [zone?.data?.shape?.geometry?.coordinates, zoneId, open]);
    
    const setShape = (shape) => {
        if (shape.length) {
            shape[shape.length - 1] = [...shape[0]];
        }
        _setShape(shape);
    }
    
    const onShapeChange = (index, axis, value) => {
        const unmutedShape = [...shape];
        unmutedShape[index][axis] = value;
        setShape(unmutedShape);
    };
    
    const onAddPoint = (index) => {
        const unmutedShape = [...shape];
        unmutedShape.splice(index + 1, 0, [(shape[index][0] + shape[index + 1][0]) / 2, (shape[index][1] + shape[index + 1][1]) / 2]);
        setShape(unmutedShape);
    };
    const handleOnSave = () => {
        const isValid = isValidPolygon(shape);
        const newShape = zone?.data?.shape;
        if (isValid && newShape?.geometry?.coordinates) {
            newShape.geometry.coordinates[0] = shape;
            dispatch(modifyZone(zoneId, newShape));
            onClose();
        } else {
            dispatch(patchReportProperties(reportKey, { dialog: { open: true, content: 'Polygons with intersecting sides are not allowed' } }));
        }
    };
    
    const onDeletePoint = (index) => {
        const unmutedShape = [...shape];
        unmutedShape.splice(index, 1);
        setShape(unmutedShape);
    };
    
    return (
        <StyledDialog
            open={open}
            onClose={onClose}
            title="Zone Shape Coordinates"
            maxWidth={'xs'}
            actions={
                <>
                    <CancelButton /*style={{ position: 'absolute', left: 8, bottom: 8 }}*/ onClick={onClose} />
                    <SaveButton onClick={handleOnSave} /*label="Close"*/ />
                </>
            }
            isDraggable
            fullWidth
        >
            <div className="shapes-dialog">
                <div className="table-coordinates">
                    <table className="shapes-dialog-table">
                        <thead>
                        <tr>
                            <th>No</th>
                            <th>X</th>
                            <th>Y</th>
                        </tr>
                        </thead>
                        <tbody>
                        {shape.slice(0, -1).map(getCoordsIntoDecimals).map(([xCoord, yCoord], index) => (
                            <tr key={`shape-coords-${index}`} onClick={() => setRowSelected(index)} className={rowSelected === index ? 'row-selected' : ''}>
                                <td>{index + 1}</td>
                                {rowSelected === index ? (<>
                                    <CellInput value={xCoord + ''} onChange={({ target }) => onShapeChange(index, 0, +target.value)} />
                                    <CellInput value={yCoord + ''} onChange={({ target }) => onShapeChange(index, 1, +target.value)} />
                                </>) : (<>
                                    <td>{xCoord}</td>
                                    <td>{yCoord}</td>
                                </>)}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                <div className="buttons">
                    <ButtonAction
                        titleTooltip="You need to select a row"
                        disabled={rowSelected === shape.length - 1 || rowSelected === -1}
                        onClick={() => onAddPoint(rowSelected)}
                        label="Insert"
                        placementTooltip="top"
                    />
                    <ButtonAction
                        titleTooltip={shape.length <= 4 ? 'Minimum vertices reached' : 'You need to select a row'}
                        disabled={shape.length <= 4 || rowSelected === -1}
                        onClick={() => onDeletePoint(rowSelected)}
                        label="Delete"
                        placementTooltip="bottom"
                    />
                </div>
            </div>
        </StyledDialog>
    );
};

export default ShapesDialog;