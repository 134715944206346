import * as types from '../constants/ActionTypes';
import {displayErrorFromAxios, ReportService} from "./util";
import {reloadReport} from "./main";

export const updateResourceTypePrint = (arrayObj) => {
    return dispatch =>{
        ReportService.instance().post('/resourceTypePrint/update',
                arrayObj
            )
            .then(() => {
                dispatch(reloadReport({}));
            }).catch(displayErrorFromAxios.bind(null,dispatch));
    }
};

export const getRfidPrintingData = () => {
    return dispatch => {
        ReportService.instance().get('/resourceTypePrint/rfidPrinting')
          .then(res => {
              dispatch({type: types.RESOURCE_TYPE_PRINT_RFID, data: res.data});
          }).catch(displayErrorFromAxios.bind(null,dispatch));
    }
};

export const getResourceIdPrintSeq = (item) => {
    return dispatch => {
        ReportService.instance().get('/resourceTypePrint/resourceIdPrintSeq/'+item)
            .then(res => {
                dispatch({type: types.RESOURCE_ID_PRINT_SEQUENCE, data: res.data})
            }).catch(displayErrorFromAxios.bind(null,dispatch));
    }
};

export const getTagIdBase = (item) => {
    return dispatch => {
        ReportService.instance().get('/resourceTypePrint/tagIdBase/'+item)
            .then(res => {
                dispatch({type: types.TAG_ID_BASE, data: res.data})
            }).catch(displayErrorFromAxios.bind(null,dispatch));
    }
};

export const getRangeCode = (item) => {
    return dispatch => {
        ReportService.instance().get('/resourceTypePrint/rangeCode/'+item)
            .then(res => {
                dispatch({type: types.RANGE_CODE, data: res.data})
            }).catch(displayErrorFromAxios.bind(null,dispatch));
    }
}