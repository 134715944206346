import {
    FIRMWARE_MGMT_CLEAN_STATUS,
    FIRMWARE_MGMT_UPLOAD_FILE
} from "../constants/ActionTypes";

const initialState = {
    firmwareUploadFileStatus: {}
}

export function firmwareManager(state = initialState, action) {
    switch (action.type) {
        case FIRMWARE_MGMT_UPLOAD_FILE:
            return {
                ...state,
                firmwareUploadFileStatus: {...state.firmwareUploadFileStatus,
                    message: action.message, typeAlert: action.typeAlert, status:action.status,  progress:action.progress, fileName:action.fileName, file:action.file, fileType:action.fileType
                }
            }
        case FIRMWARE_MGMT_CLEAN_STATUS:
            return {
                ...state,
                firmwareUploadFileStatus: {}
            }

        default:
            return state;
    }

}