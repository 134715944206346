import React from "react";
import PropTypes from 'prop-types';
import HorzNav from './util/menuNavigation/ZHorizontalMenu'; //'./../../../common/app/views/ZLeftNav';
import Home from "./home/Home";
import Snackbar from '@material-ui/core/Snackbar';

import ErrorDialog from './util/ErrorDialog';

import * as forms from '../constants/DataEntryForms';
import UserSettingsDialog from './util/UserSettingsDialog';
import ChangePasswordDialog from './util/ChangePasswordDialog';


import {checkMWEBuildUpdates} from '../utils/AutoReload';
import {getSpecialLoginAuthType} from '../../../common/app/utils/auth'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actions from '../actions';
import {withRouter} from 'react-router-dom';
import Notification from "./deviceManager/Notification";
import ErrorDialogOnBackground from "./util/ErrorDialogOnBackground";

/**
 *
 * Main Page
 * @type {*|Function}
 */
class MainPage extends React.Component{
    constructor(){
        super();

        this.state = {
            isLeftPaneOpen : false,
            username : "",
            menuArray : [],
            loginVerifed : false
        };

        this.handleRequestClose = this.handleRequestClose.bind(this);
        this.handleOpenUserSettingsForm = this.handleOpenUserSettingsForm.bind(this);
        this.handleOpenChangePasswordForm = this.handleOpenChangePasswordForm.bind(this);
        this.handleSignOut = this.handleSignOut.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.props.actions.checkLoginType();
        this.props.actions.checkLoginStatus()
            .then(([username,token])=>{
                this.props.actions.requestMenu(username,token);
                this.setState({
                    loginVerifed:true
                });
                checkMWEBuildUpdates();
            })
            .catch(()=>{
                this.props.history.push('/login');
            });

        const {history} = this.props;
        this.unsubscribeFromHistory = history.listen(this.props.actions.changeLocation);
    }

    componentWillUnmount(){
        if(this.unsubscribeFromHistory) this.unsubscribeFromHistory();
    }

    handleOpenUserSettingsForm(){
        this.props.actions.openDataEntry(forms.USER_SETTINGS,{});
    }

    handleOpenChangePasswordForm(){
        this.props.actions.openDataEntry(forms.CHANGE_PASSWORD,{});
    }

    handleSignOut(){
        // Disable auto-redirect only when a special login is detected
        if (getSpecialLoginAuthType(this.props.authSettings)) {
            this.props.actions.setDisableAutoRedirect(true);
        }

        this.props.actions.signOut()
            .then(()=>{
                this.props.history.push("/login");
            });
        this.props.actions.cleanFilters();
        this.props.actions.cleanPlaybackDate();
        this.props.actions.cleanLocalMap();
        this.props.actions.cleanDeviceManager();

    }


    render() {
        const {isMobile, windowHeight, isMidSize} = this.props;
        const open = this.props.isOpenNotification;
        let menu = (this.props.userIsLocal) ? [
            {
                label: "Change Password",
                name: "change_password",
                value:"change_password",
                displayUserMenu: true,
                routes: "",
                onClick: this.handleOpenChangePasswordForm
            }
        ] : [];

        menu.push(
            {
                "label" : "User Settings",
                "name"  : "user_settings",
                "value" : "user_settings",
                "displayUserMenu": true,
                "routes": "",
                "onClick" : this.handleOpenUserSettingsForm
            },
            {
                "label" : "Sign Out",
                "name"  : "signout",
                "value" : "signout",
                "displayUserMenu": true,
                "routes" : "",
                "isDisabled" : false,
                "onClick" : this.handleSignOut
            }
        );
        return (
            <div style={{height:'100%',width:'100%'}} >
                <HorzNav zLeftPanOpen={this.state.isLeftPaneOpen}
                         zMenuList={this.props.menuList}
                         zMenuJSON={menu}
                         zDisplayName={this.props.displayName}
                         isMobile={isMobile}
                         windowHeight={windowHeight}
                         isMidSize={isMidSize}
                />
                <section  className={this.state.isLeftPaneOpen === true ? "section marginLeft" : "section" }>
                    {this.state.loginVerifed ? this.getChildrenPages() : null}
                </section>
                <Snackbar
                    open={this.props.snackbarOpen}
                    message={this.props.snackbarMessage}
                    autoHideDuration={4000}
                    onRequestClose={this.handleRequestClose}
                />
                <ErrorDialog
                    open={this.props.errorDialogOpen}
                    title={this.props.errorDialogTitle}
                    message={this.props.errorDialogMessage}
                    details={this.props.errorDialogDetails}
                    onRequestClose={this.props.actions.closeErrorDialog}
                />
                <UserSettingsDialog />
                <ErrorDialogOnBackground />
                <ChangePasswordDialog />
                {
                    open?<Notification
                        open={this.props.isOpenNotification}
                        type={this.props.typeNotification}
                        name={this.props.nameNotification}
                        subTitle={this.props.subTitleNotification}
                        message={this.props.messageNotification}
                        autoHideDuration={this.props.autoHideDurationNotification}
                        iconAlert={this.props.showIcon}
                    />: null
                }

            </div>
        );
    }

    handleRequestClose(){
        this.props.actions.closeSnackbar();
    }

    getChildrenPages() {
        return React.Children.map((this.props.children ? this.props.children : <Home/>),
            (child)=>React.cloneElement(child,{actions:this.props.actions})
        );
    }

    static get propTypes (){
        return{
            children: PropTypes.any,
            actions: PropTypes.object,
            menuList: PropTypes.array,
            snackbarOpen: PropTypes.bool,
            snackbarMessage: PropTypes.string,
            displayName: PropTypes.string,
            errorDialogOpen: PropTypes.bool,
            errorDialogTitle: PropTypes.string,
            errorDialogMessage: PropTypes.any, // can be a node...
            errorDialogDetails: PropTypes.any,
            history: PropTypes.object,
            userIsLocal: PropTypes.bool,
            windowHeight: PropTypes.any,
            isMidSize: PropTypes.bool,

        };
    }

    static get defaultProps(){
        return {
            snackbarOpen: false,
            snackbarMessage: ""
        };
    }
}

const mapStateToProps = state => {
    // console.log('state', state);
    return {
        menuList: state.user.menu,
        displayName: state.user.displayName,
        snackbarOpen: state.snackbar.open,
        snackbarMessage: state.snackbar.message,
        errorDialogOpen: state.errorDialog.open,
        errorDialogTitle: state.errorDialog.title,
        errorDialogMessage: state.errorDialog.message,
        errorDialogDetails: state.errorDialog.details,
        userIsLocal: state.user.isLocal,
        isMobile: state.resize.isMobile,
        windowHeight: state.resize.windowHeight,
        isMidSize: state.resize.isMidSize,

        isOpenNotification: state.notification.openNotification,
        typeNotification: state.notification.typeNotification,
        nameNotification: state.notification.nameNotification,
        subTitleNotification: state.notification.subTitleNotification,
        messageNotification: state.notification.messageNotification,
        showIcon: state.notification.showIcon,
        autoHideDurationNotification: state.notification.autoHideDurationNotification,
        idSetTimeOut: state.notification.idSetTimeOut,
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions,dispatch)
});

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(MainPage));
