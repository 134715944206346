import { combineReducers } from 'redux';
import { i18nReducer } from 'react-redux-i18n';
import { connectRouter } from 'connected-react-router';

import { user } from './user';
import { snackbar } from './snackbar';
import { view } from './view';
import { resize } from './resize';
import { dataEntry } from './dataEntry';
import { report } from './report';
import { sites } from './sites';
import { filters } from './filters';
import { errorDialog } from './errorDialog';
import { appliances } from './appliances';
import { userMgmt } from './userMgmt';
import { reportMap } from './reportMap';
import { zoneBuilder } from './zoneBuilder';
import { importReport } from './importReport';
import { resourceTypePrint } from './resourceTypePrint';
import { deviceManager } from './deviceManager';
import { notification } from './notification';
import { dashboards } from './dashboards';
import { zebraDataService } from './zebraDataService';
import { resources } from './resources';
import { resourceTypes } from './resourceTypes';
import { tags } from './tags';
import { filtersOnePlatform } from './filtersOnePlatform';
import { tableOnePlatform } from './tableOnePlatform';
import { formOnePlatform } from './formOnePlatform';
import { formsOnePlatform } from './formsOnePlatform';
import { search } from './search';
import alerts from './alerts';
import direction from './direction';
import {firmwareManager} from "./firmwareManager";
import {errorDialogOnBackground} from "./errorDialogOnBackground";
import { loader, siteManager } from '../../../store/reducers';
import {siteDesigner} from "./siteDesigner";
const rootReducer = history => combineReducers({
    router: connectRouter(history),
    notification,
    user,
    snackbar,
    view,
    resize,
    dataEntry,
    report,
    reportMap,
    sites,
    filters,
    errorDialog,
    appliances,
    userMgmt,
    zoneBuilder,
    importReport,
    resourceTypePrint,
    deviceManager,
    zebraDataService,
    i18n: i18nReducer,
    dashboards,
    resources,
    resourceTypes,
    tags,
    search,
    filtersOnePlatform,
    tableOnePlatform,
    formsOnePlatform,
    formOnePlatform,
    alerts,
    direction,
    firmwareManager,
    errorDialogOnBackground,
    loader,
    siteManager,
    siteDesigner,
});

export default rootReducer;
