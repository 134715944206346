import React from "react";
import CloseIcon from '@material-ui/icons/Close';
import { Dialog, DialogActions, DialogTitle, DialogContent, IconButton } from "@material-ui/core";

const DialogMessage = (props) => {
    const {open, onClose, title, content, actions} = props;

    const renderCloseButton = () => {
        return (
            <div style={{position:'absolute',top:'0.5em',right:'0.5em'}}>
                <IconButton aria-label="close"
                    onClick={onClose}
                    style={{cursor:'pointer',position:'relative',zIndex:'100', color: '#000', padding: '12px'}}
                    >
                    <CloseIcon />
                </IconButton>
            </div>
        );
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth={true}
            >
                {renderCloseButton()}
                <DialogTitle style={{paddingRight: 50,}}>{title}</DialogTitle>
                <DialogContent style={{padding: '8px 24px', minHeight: 60, display: 'flex', alignItems: 'center', textAlign: 'justify'}}>
                    <div>{content}</div>
                </DialogContent>
                <DialogActions style={{padding: '8px'}}>
                    {actions}
                </DialogActions>
        </Dialog>
    )
}

export default DialogMessage;