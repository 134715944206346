import React from 'react';
import PropTypes from 'prop-types';
import Promise from 'promise';

import StyledDialog, {CancelButton, SaveButton} from '../../util/Dialog';

import {DataEntryTabularField, DataEntryTabularForm} from '../../util/DataEntryTabularForm';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actions from '../../../actions';
import * as forms from '../../../constants/DataEntryForms';

class SaveZonesDialog extends React.Component {


    static get propTypes(){
        return {
            open: PropTypes.bool,
            onSave: PropTypes.func.isRequired,
            onClose: PropTypes.func.isRequired,
            data: PropTypes.object
        };
    }

    static get defaultProps(){
        return {
            open: false
        };
    }

    constructor(){
        super();

        this.handleSave = this.handleSave.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    render() {

        const {
            actions, // eslint-disable-line
            ...other
        } = this.props;

        return (
            <StyledDialog
                title="Save Changes to Staging"
                open={this.props.open}
                onClose={this.handleClose}
                maxWidth={"xs"}
                actions={
                    <>
                        <CancelButton onClick={this.handleClose} />
                        <SaveButton onClick={this.handleSave} />
                    </>
                }
                {...other}
                >
                {this.renderForm()}
            </StyledDialog>
        );
    }

    handleSave(){
        Promise.resolve(this.props.onSave())
        .then(()=>{
            return this.props.onClose();
        });
    }

    handleClose ()  {
        this.props.onClose();
    }

    renderForm(){

        return (
            <DataEntryTabularForm fullWidth>

                <DataEntryTabularField label={""}>
                    Would you like to save the changes to staging before publishing to be used in operations?
                </DataEntryTabularField>

            </DataEntryTabularForm>
        );
    }
}

export default connect(
    state => ({
        open: state.dataEntry.open && state.dataEntry.activeForm === forms.ZONE_BUILDER_SAVE_ZONES
    }),

    dispatch => ({
        ...bindActionCreators({
            onSave: actions.saveCurrentMapZonesToStaging,
            onClose: actions.closeDataEntry
        },dispatch)
    })
)(SaveZonesDialog);
