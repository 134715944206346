const ID_LABELS = {
    'objectId': 'Resource Id',
    'tagId': 'Tag Id'
};


export const getLabelByIdAttribute=(idAttribute)=>{

    let elementIdLabel = idAttribute;

    if (ID_LABELS.hasOwnProperty(idAttribute)) {
        elementIdLabel = ID_LABELS[idAttribute];
    }

    return elementIdLabel;
}