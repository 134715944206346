export const PATCH_FORM = 'PATCH_FORM';
export const REPLACE_FORM = 'REPLACE_FORM';
export const REPLACE_ITEMS = 'REPLACE_ITEMS';
export const UPDATE_ZONES_MAP='UPDATE_ZONES_MAP';
export const ADD_INTERACTION_ON_MAP = 'ADD_INTERACTION_ON_MAP';
export const REMOVE_INTERACTION_ON_MAP = 'REMOVE_INTERACTION_ON_MAP';
export const TAKE_SNAPSHOT = 'TAKE_SNAPSHOT';
export const BACK_SNAPSHOT = 'BACK_SNAPSHOT';
export const FORWARD_SNAPSHOT = 'FORWARD_SNAPSHOT';
export const CLEAN_SNAPSHOTS = 'CLEAN_SNAPSHOTS';
export const OPEN_DIALOG = 'OPEN_DIALOG';
export const CLOSE_DIALOG = 'CLOSE_DIALOG';
