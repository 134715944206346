import {
    FIELDS_ONE_PLATFORM_PATCH_FIELD,
    FORMS_ONE_PLATFORM_REPLACE_FORMS
} from '../constants/ActionTypes/formsOnePlatform';

export function formsOnePlatform(state = {}, action) {
    switch (action.type) {
        case FORMS_ONE_PLATFORM_REPLACE_FORMS:
            return {
                ...state,
                [action.reportKey]: action.forms,
            };
        case FIELDS_ONE_PLATFORM_PATCH_FIELD:

            if (!state[action.reportKey]) {
                state[action.reportKey] = {};
            }

            if (!state[action.reportKey][action.formMode]) {
                state[action.reportKey][action.formMode] = {};
            }

            if (!state[action.reportKey][action.formMode].fields) {
                state[action.reportKey][action.formMode].fields = {};
            }

            if (!state[action.reportKey][action.formMode].fields[action.fieldKey]) {
                state[action.reportKey][action.formMode].fields[action.fieldKey] = {};
            }

            const newField = { ...state[action.reportKey][action.formMode].fields[action.fieldKey] };
            for (const propertyKey of Object.keys(action.field)) {
                newField[propertyKey] = action.field[propertyKey];
            }

            let newState = {...state[action.reportKey]};
            newState[action.formMode].fields[action.fieldKey] = newField;
            return {
                ...state,
                [action.reportKey]: {
                    ...newState
                },
            };
        default:
            return state;
    }
}
