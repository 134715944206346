// Copied from official example you can personalize in implementation component.

import PointerInteraction from "ol/interaction/Pointer";
import { LOWER_LEFT_POINT, UPPER_RIGHT_POINT} from "../../../constants/SiteManager";

export const CustomDrag = /*@__PURE__*/(function (PointerInteraction) {
    function Drag() {
        PointerInteraction.call(this, {
            handleDownEvent: handleDownEvent,
            handleDragEvent: handleDragEvent,
            handleMoveEvent: handleMoveEvent,
            handleUpEvent: handleUpEvent,
        });


        this.lowerLeftFeature=null;
        this.upperRightFeature=null;

        // Observer for change position of drag points.
        this.observerDrag=null;



        /**
         * @type {import("../src/ol/coordinate.js").Coordinate}
         * @private
         */
        this.coordinate_ = null;

        /**
         * @type {string|undefined}
         * @private
         */
        this.cursor_ = 'pointer';

        /**
         * @type {Feature}
         * @private
         */
        this.feature_ = null;

        /**
         * @type {string|undefined}
         * @private
         */
        this.previousCursor_ = undefined;


        // Subscribe event on drag
        this.subscribeDrag = (observerFunction) => {
            this.observerDrag = observerFunction;
        }
        this.onDragUserPoint=()=>{
            if(this.observerDrag!=null) {
                this.observerDrag(this.lowerLeftFeature.getGeometry().getFlatCoordinates(),this.upperRightFeature.getGeometry().getFlatCoordinates());
            }
        }
    }

    if ( PointerInteraction ) Drag.__proto__ = PointerInteraction;
    Drag.prototype = Object.create( PointerInteraction && PointerInteraction.prototype );
    Drag.prototype.constructor = Drag;

    return Drag;
}(PointerInteraction));

/**
 * @param {import("../src/ol/MapBrowserEvent.js").default} evt Map browser event.
 * @return {boolean} `true` to start the drag sequence.
 */
function handleDownEvent(evt) {
    var map = evt.map;

    var feature = map.forEachFeatureAtPixel(evt.pixel, function (feature) {
        return feature;
    });

    if (feature) {
        this.coordinate_ = evt.coordinate;
        this.feature_ = feature;
    }

    return !!feature;
}

/**
 * @param {import("../src/ol/MapBrowserEvent.js").default} evt Map browser event.
 */
function handleDragEvent(evt) {
    var deltaX = evt.coordinate[0] - this.coordinate_[0];
    var deltaY = evt.coordinate[1] - this.coordinate_[1];

    // Gets current coordinates of lower left and upper right points.
    const lowerLeftCoordinates=this.lowerLeftFeature.getGeometry().getFlatCoordinates();
    const upperRightCoordinates=this.upperRightFeature.getGeometry().getFlatCoordinates();

    const featureName=this.feature_.get('name');

    // Validates the max x and y position of points.
    if(featureName===LOWER_LEFT_POINT){
        if(evt.coordinate[0]>=upperRightCoordinates[0]) {
            deltaX = 0;
        }
        if(evt.coordinate[1]>=upperRightCoordinates[1]) {
            deltaY = 0;
        }
    }
    else if(featureName===UPPER_RIGHT_POINT){
        if(evt.coordinate[0]<=lowerLeftCoordinates[0]) {
            deltaX = 0;
        }
        if(evt.coordinate[1]<=lowerLeftCoordinates[1]) {
            deltaY = 0;
        }
    }

    var geometry = this.feature_.getGeometry();
    geometry.translate(deltaX, deltaY);
    this.coordinate_[0] = evt.coordinate[0];
    this.coordinate_[1] = evt.coordinate[1];

    this.onDragUserPoint();
}

/**
 * @param {import("../src/ol/MapBrowserEvent.js").default} evt Event.
 */
function handleMoveEvent(evt) {
    if (this.cursor_) {
        var map = evt.map;
        var feature = map.forEachFeatureAtPixel(evt.pixel, function (feature) {
            return feature;
        });
        var element = evt.map.getTargetElement();
        if (feature) {
            if (element.style.cursor !== this.cursor_) {
                this.previousCursor_ = element.style.cursor;
                element.style.cursor = this.cursor_;
            }
        } else if (this.previousCursor_ !== undefined) {
            element.style.cursor = this.previousCursor_;
            this.previousCursor_ = undefined;
        }
    }
}

/**
 * @return {boolean} `false` to stop the drag sequence.
 */
function handleUpEvent() {
    this.coordinate_ = null;
    this.feature_ = null;
    return false;
}
