//import SvgIcon from '@material-ui/core/SvgIcon';
import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';
import PropTypes from 'prop-types';

const PinIcon = (props) => {

    const {color,borderColor,...other} = props;


    return (
        <SvgIcon {...other} >
            <g transform="translate(3,0) scale(0.17)" >
                <path
                    d="m 99.498376,52.719861 c 0,-26.489798 -21.269663,-47.963099 -47.510063,-47.963099 -26.238532,0 -47.5100649,21.473301 -47.5100649,47.963099 0,11.64676 4.1162875,22.325641 10.9565339,30.632173 L 52.029401,134.73062 89.159097,82.57451 c 1.333498,-1.684793 2.554937,-3.460503 3.656847,-5.327128 l 0.375397,-0.543604 -0.06163,-0.30368 c 4.04345,-7.059274 6.368668,-14.939958 6.368668,-23.680237"
                    style={{
                        fill:color,
                        stroke:borderColor,
                        strokeWidth:8.5
                    }}
                />
                <path
                    d="m 75.349999,50.560706 c 0,12.829284 -10.671175,23.237706 -23.834696,23.237706 -13.165397,0 -23.834697,-10.408422 -23.834697,-23.237706 0,-12.832955 10.671175,-23.237706 23.834697,-23.237706 13.166332,-9.18e-4 23.834696,10.403834 23.834696,23.237706"
                    style={{
                        fill:'white',
                        stroke:borderColor,
                        strokeWidth:8.5
                    }}
                />
            </g>
        </SvgIcon>
    );

};

PinIcon.propTypes = function(){
    return {
        color: PropTypes.string,
        borderColor: PropTypes.string
    };
};




export default PinIcon;
