import Promise from 'promise';

import { DEFAULT_MAP, LOCAL_MAP, WORLD_MAP } from '../utils/ol/olConstants';
import * as types from '../constants/ActionTypes';
import { REPORT_MAP_PATCH_PROPERTIES_MAP, REPORT_MAP_REPLACE_ALL_SITE_LOCAL_MAP, SELECT_MAP, SITE_MANAGER_SITE_SELECTED } from '../constants/ActionTypes';
import { getMapCoordinates } from './util/maps';
import { displayErrorDialog } from './general';
import {
    MESSAGE_ERROR_RETRIEVING,
    MESSAGE_ERROR_UNEXPECTED,
    MESSAGE_GETTING_DATA,
    MESSAGE_NO_TAGS_FOR_MAP,
    MESSAGE_NO_TAGS_FOR_MAP_DEFAULT,
    REPORT_ID_RESOURCES,
    REPORT_ID_TAGS
} from '../constants';
import { ReportService } from './util';
import { subscribeToCloseWindow, subscribeToWS, unsubscribeToCloseWindow, unsubscribeToWS } from './util/websocket/api';
import { tableOnePlatformUpdateData } from './tableOnePlatform';
import { getReportKeys } from '../hooks';

export const refStore = {};

export const replaceRefOlMap = (ref) => {
    refStore.olMap = ref;
};

export const replaceRefLocalMap = (ref) => {
    refStore.localMap = ref;
};

export const changeRefMap = (ref) => {
    return (dispatch) => {
        refStore.olMap = ref;
    };
};

export const replaceReportMapAllSiteLocalMap = (scopes) => {
    return {
        type: REPORT_MAP_REPLACE_ALL_SITE_LOCAL_MAP,
        scopes,
    };
};

export const patchReportMapPropertiesMap = (reportKey, properties) => {
    return {
        type: REPORT_MAP_PATCH_PROPERTIES_MAP,
        reportKey,
        properties,
    };
};

export const changeSelectMap = (mapType, mapId, mapConfiguration, validConfiguration, bounds, maxZoom, currentMap) => {
    return {
        type: SELECT_MAP,
        mapType,
        mapId,
        mapConfiguration,
        validConfiguration,
        bounds,
        maxZoom,
        currentMap,
    };
};

export const changeSiteManagerSiteSelected = (siteId) => ({ type: SITE_MANAGER_SITE_SELECTED, siteManagerSiteSelected: siteId });
export const changeOlMap = (mapId, defaultBounds = false) => {
    return async (dispatch, getState) => {
        const state = getState();
        const sites = state.sites.sites;
        const maps = state.sites.maps;
        let currentMap = DEFAULT_MAP;
        let mapType = WORLD_MAP;
        let mapConfiguration = null;
        
        if (mapId != null) {
            mapType = LOCAL_MAP;
            mapConfiguration = await getMapCoordinates(mapId);
            let mapName = '';
            let siteId = '';
            let siteName = '';
            maps.forEach(item => {
                if (item._id === mapId) {
                    mapName = item.description;
                    siteId = item.siteId;
                }
            });
            
            if (siteId && siteId !== '') {
                sites.forEach(item => {
                    if (item._id === siteId) {
                        siteName = item.name;
                    }
                });
            }
            
            currentMap = `${siteName} - ${mapName}`;
        }
        
        let validConfiguration = true;
        let bounds = null;
        let maxZoom = 0;
        
        let coordinates = null;
        if (mapConfiguration != null) {
            if (mapConfiguration.extent == null) {
                validConfiguration = false;
                // Default bounds is used for show a map without configuration.
                if (defaultBounds) {
                    coordinates = {};
                    coordinates.extent = [0, 0, 256, 256];
                }
            } else {
                coordinates = mapConfiguration;
            }
            
            if (mapConfiguration.maxZoom != null) {
                maxZoom = mapConfiguration.maxZoom - 1; // Tiles starts in 0.
            }
            
            if (coordinates != null && coordinates.extent) {
                bounds = coordinates.extent;
            }
        }
        dispatch(changeSelectMap(mapType, mapId, coordinates, validConfiguration, bounds, maxZoom, currentMap));
    };
};

export const updateLocalMap = (reportKey, mapId, defaultBounds = false) => {
    return async (dispatch, getState) => {
        
        const state = getState();
        const map = state.sites.maps.find(map => map._id === mapId);
        
        if (map) {
            const site = state.sites.sites.find(site => site._id === map.siteId);
            let configuration = await getMapCoordinates(mapId);
            let validConfiguration = true;
            let coordinates = null;
            if (configuration) {
                if (configuration.extent == null) {
                    validConfiguration = false;
                    // Default bounds is used for show a map without configuration.
                    if (defaultBounds) {
                        coordinates = {};
                        coordinates.extent = [0, 0, 256, 256];
                    }
                } else {
                    coordinates = configuration;
                }
            }
            dispatch(patchReportMapPropertiesMap(reportKey, {
                configuration: coordinates,
                validConfiguration,
                currentMap: `${site?.name || ''} - ${map.description || ''}`,
                mapId,
                siteId: map.siteId,
                //bounds: configuration.extent
                // maxZoom: configuration.maxZoom - 1  // Tiles starts in 0.
            }));
        } else {
            dispatch(patchReportMapPropertiesMap(reportKey, {
                mapType: WORLD_MAP,
                mapId: null,
                configuration: {},
                validConfiguration: false,
                currentMap: null,
            }));
        }
    };
};

export const selectSiteAndChangeLocalMap = (reportKey, siteId) => {
    return async (dispatch, getState) => {
        const map = getState().sites.maps.find(map => map.siteId === siteId);
        if (map) {
            dispatch(updateLocalMap(reportKey, map._id));
        }
    };
};

export const loadTagsHistory = (reportKey, resources, idAttribute, indexQuery, startDate, endDate,userDefinedDateRange) => {
    return dispatch => {
        if(resources && Object.keys(resources).length > 0){
            const itemsIds = [];
            const itemsEvent = {};
            Object.keys(resources).forEach(itemId => {
                itemsIds.push(resources[itemId].key);
                itemsEvent[resources[itemId].key] = indexQuery;
            });
            
            let data = {
                itemsId: itemsIds,
                itemsEvent,
                idColumn: idAttribute,
                startDate: startDate,
                endDate: endDate,
                limitClustering: 100000,
                numberOfSlots: 60,
            };
            dispatch(patchReportMapPropertiesMap(reportKey, { dialog: { open: true, message: MESSAGE_GETTING_DATA, loader: true } }));

            return ReportService.instance().post('/playback/', data)
                .then(response => {
                        const dialog = { loader: false };
                        if (Object.keys(response.data).length) {
                            dialog.open = false;
                        } else {
                            dialog.open = true;
                            if(userDefinedDateRange!==true)
                                dialog.message = MESSAGE_NO_TAGS_FOR_MAP_DEFAULT;
                            else
                                dialog.message = MESSAGE_NO_TAGS_FOR_MAP;
                        }
                        const blinksCount = { byMap: {}, totalBlinks: 0, noLocateBlinks: 0 };
                        if (response.data != null && response.data.maps != null) {
                        Object.entries(response.data?.maps).forEach(([mapId, mapData]) => {
                            const validBlinks = Object.values(mapData?.elements || {}).map(e => e.blinks).flat()?.filter(b => b.groupSize > 0);
                            const noLocateBlinks = validBlinks.filter(blink => blink.x === 0 && blink.y === 0 && blink.z === 255);
                            blinksCount.byMap[mapId] = {
                                totalBlinks: validBlinks.length,
                                noLocateBlinks: noLocateBlinks.length,
                            };
                            blinksCount.totalBlinks += validBlinks.length;
                            blinksCount.noLocateBlinks += noLocateBlinks.length;
                        });
                        }
                        blinksCount.locateBlinks = blinksCount.totalBlinks - blinksCount.noLocateBlinks;
                        dispatch(patchReportMapPropertiesMap(reportKey, {
                            dialog,
                            playback: {
                                data: response.data,
                                blinksCount,
                            },
                        }));
                    },
                )
                .catch(err => {
                    console.error(err);
                    return Promise.reject(err);
                })
                .catch(err => {
                    let details = (((err || {}).response || {}).data || {}).message || MESSAGE_ERROR_UNEXPECTED;
                    if (details === MESSAGE_ERROR_UNEXPECTED) {
                        details = (err || {}).message || MESSAGE_ERROR_UNEXPECTED;
                    }
                    dispatch(displayErrorDialog({
                        message: MESSAGE_ERROR_RETRIEVING,
                        details: details,
                    }));
                    dispatch(patchReportMapPropertiesMap(reportKey, { dialog: { open: true, message: MESSAGE_ERROR_RETRIEVING, loader: false } }));
                });
        }else{

            // no tags selected then open the list of resources/tags
            dispatch(patchReportMapPropertiesMap(reportKey,{
                showTagList: true
            }));
            //dispatch(displayErrorDialog({message: MESSAGE_NO_TAGS_SELECTED}))
        }
    };
};

export const connectRealTimeWS = () => {
    return (dispatch, getState) => {
        
        subscribeToCloseWindow();
        const { reportKey, reportId } = getReportKeys(getState());
        
        subscribeToWS(
            (err, data) => {
                const rowsData = getState().tableOnePlatform[reportKey].data;
                if (reportId === REPORT_ID_RESOURCES/* || reportId === RESOURCE_HISTORY_REPORT*/ || reportId === REPORT_ID_TAGS /* || reportId === TAG_HISTORY_REPORT*/) {
                    const blinks = data[reportId === REPORT_ID_RESOURCES ? 'resourceMap' : 'tagMap'];
                    const realTimeStamp = new Date().getTime();
                    if (Object.keys(blinks).length) {
                        let changed = false;
                        const newRowsData = rowsData.map(row => {
                            const newBlink = blinks[row[reportId === REPORT_ID_RESOURCES ? 'resourceId' : 'id']?.toLowerCase()];
                            if (newBlink) {
                                changed = true;
                                if (newBlink.locating === 'no') {
                                    return {
                                        ...row,
                                        realTimeStamp,
                                        noLocateBlink: true,
                                        location: {
                                            ...row.location,
                                        },
                                    };
                                }
                                return {
                                    ...row,
                                    realTimeStamp,
                                    noLocateBlink: false,
                                    location: {
                                        ...row.location,
                                        geometry: {
                                            ...row.location?.geometry,
                                            coordinates: [newBlink.x, newBlink.y, row.location?.geometry?.coordinates[2] || 0],
                                        },
                                        properties: {
                                            mapId: newBlink.mapId,
                                            mapName: newBlink.mapName,
                                        },
                                    },
                                };
                            }
                            return row;
                        });
                        if (changed) {
                            const { uKeyFields } = getState().report.scopes?.[reportKey] || {};
                            dispatch(tableOnePlatformUpdateData(reportKey, newRowsData, uKeyFields));
                        }
                    }
                    dispatch(patchReportMapPropertiesMap(reportKey, { realTimeStamp }));
                }
            },
            (status) => {
                dispatch({ type: types.WEBSOCKET_STATUS, status: status });
            },
        );
    };
};

export const disconnectRealTimeWS = () => {
    return (dispatch, getState) => {
        unsubscribeToWS();
        unsubscribeToCloseWindow();
        const { reportKey } = getReportKeys(getState());
        const rowsData = getState().tableOnePlatform[reportKey]?.data;
        if (Array.isArray(rowsData)) {
            const newRowsData = rowsData?.map(row => ({ ...row, noLocateBlink: false }));
            const { uKeyFields } = getState().report.scopes?.[reportKey] || {};
            dispatch(tableOnePlatformUpdateData(reportKey, newRowsData, uKeyFields));
        }
        dispatch(patchReportMapPropertiesMap(reportKey, { realTimeStamp: 0 }));
    };
};



// site designer
export const replaceRefSiteDesignerMap = (ref) => {
    refStore.siteDesigner = ref;
};

export const replaceRefConfigZonesMap = (ref) => {
    refStore.configZonesMap = ref;
};

export const replaceRefConfigObstructionsMap = (ref) => {
    refStore.configObstructionsMap = ref;
};

export const replaceRefConfigDevicesMap = (ref) => {
    refStore.configDevicesMap = ref;
};