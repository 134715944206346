import React from 'react';
import Button from '@material-ui/core/Button';
import StyledDialog from '../../util/Dialog'

class ModalPrint extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            objDevices: {},
            objSites: {},
            copies: '',
            anchorEl: null
        };

        this.handlePrintData = this.handlePrintData.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleAllowPositiveInt = this.handleAllowPositiveInt.bind(this);
        this.renderForm = this.renderForm.bind(this);
        this.associateAnchor = this.associateAnchor.bind(this);
    }

    associateAnchor(ref){
        this.setState({
            anchorEl: ref
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        if(this.props.printerDevices !== nextProps.printerDevices){
            let objDevices = {};
            nextProps.printerDevices.map(m =>
                objDevices[m.id] = m
            );
            this.setState({
                objDevices: objDevices
            });
        }

        let objSites = {};
        nextProps.sites.map(s =>
            objSites[s.name] = s
        );
        this.setState({
            objSites: objSites
        });
    }

    handlePrintData(){
        const objSite = this.state.objSites[this.refs.siteSelect.value];
        const objPrinter = this.state.objDevices[this.refs.printDevice.value];
        const numCopies = this.refs.numCopies.value;
        // const numLabels = this.refs.numLabels.value;
        let print = true;

        if( !objSite ){
            this.props.actions.displayErrorDialog({
                title: "Error",
                message: "Please select a site.",
                noconsole: true,
            });
            print = false;
        }

        if( !objPrinter ){
            this.props.actions.displayErrorDialog({
                title: "Error",
                message: "Please select a printer.",
                noconsole: true,
            });
            print = false;
        }

        if( !numCopies ){
            this.props.actions.displayErrorDialog({
                title: "Error",
                message: "Please type a number of copies.",
                noconsole: true,
            });
            print = false;
        }

        if(numCopies === "0"){
            this.props.actions.displayErrorDialog({
                title: "Error",
                message: "Please type a number of copies greater than 0.",
                noconsole: true,
            });
            print = false;
        }

        /*if( !numLabels ){
            this.props.actions.displayErrorDialog({
                title: "Error",
                message: "Please type a number of Sequential Labels.",
                noconsole: true,
            });
            print = false;
        }*/

        if(print) {
            let keys = Object.keys(this.props.selected);

            let arrayData = [];
            keys.forEach(key => {
                const resourceObj = this.props.selected[key];

                const objPrint = {
                    "resourceId": resourceObj.objectId,
                    "resourceType": resourceObj.objectType,
                    "printerName": objPrinter.name,
                    "userName": this.props.userName,
                    "numberOfCopies": numCopies,
                    "numberOfSequentialLabels": "1",
                    "mode": !!resourceObj.tagId ? "PRINTGIVEN" : "PRINTNEW",
                    "tagId": !!resourceObj.tagId ? resourceObj.tagId : null,
                    "siteId": objSite.id,
                    "additionalData": []
                };

                arrayData.push(objPrint);
            });
            this.props.actions.sendDataToPrint(arrayData);
            this.props.onClose();
        }
    }

    handleSelectChange(e){
        const siteName = e.target.value;
        this.props.onChange(siteName);
    }

    handleAllowPositiveInt(event){
        if (event.which === 45 || event.which === 43 || event.which === 46){
            event.preventDefault();
        }
    }

    handlePositiveInt(event){
        if (event.length <= 8) this.setState({copies: event});
    }

    renderForm(){
        const {printerDevices, sites, selected} = this.props;
        let options = printerDevices.map(m => {
            return <option key={m.id} value={m.id} >{m.name}</option>;
        });

        let sitesOptions = sites.map(s => {
            return <option key={s.id} value={s.name}>{s.name}</option>
        });

        let staticFields = [];

        const keys = Object.keys(selected);       
        keys.forEach((key) => {
            const resourceObj = this.props.selected[key];
            const element = {
                "resourceId": resourceObj.objectId,
                "resourceType": resourceObj.objectType,
                "tagId": !!resourceObj.tagId ? resourceObj.tagId : null
            }
            staticFields.push(element);
        });
        let fullWidth = true;
        return (
        <div className={"modal-printer-container"} style={{maxWidth: 360}}>
            <div className={"elements-container"}>
                <table className="data-entry-form" style={{width:(fullWidth ? '100%' : 'auto'),tableLayout:'fixed'}}>
                    <thead />
                    <tbody>
                        <tr style={{display: (keys.length===1?'block':'none')}}>
                            <td className="basic-input">
                                <div className={"label"}>
                                    Resource ID
                                </div>
                                <div className={"field"}>
                                    {staticFields[0].resourceId}
                                </div>
                            </td>
                        </tr>
                        <tr style={{display: (keys.length===1?'block':'none')}}>
                            <td className="basic-input">
                                <div className={"label"}>
                                    Resource Type
                                </div>
                                <div className={"field"}>
                                    {staticFields[0].resourceType}
                                </div>
                            </td>
                        </tr>
                        <tr style={{display: (keys.length===1?'block':'none')}}>
                            <td className="basic-input">
                                <div className={"label"}>
                                    Tag ID
                                </div>
                                <div className={"field"}>
                                    {staticFields[0].tagId}
                                </div>
                            </td>
                        </tr>
                        <tr style={{display: (keys.length>1?'block':'none')}}>
                            <td className="basic-input">
                                <div className={"label"}>
                                    Number of Selected Items
                                </div>
                                <div className={"field"}>
                                    {keys.length}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="basic-input">
                                <div className={"label"}>
                                    Select Site
                                </div>
                                <div className={"field"}>
                                    <select ref={"siteSelect"} onChange={this.handleSelectChange}>
                                        <option>Select...</option>
                                        {sitesOptions}
                                    </select>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="basic-input">
                                <div className={"label"}>
                                    Select Printer
                                </div>
                                <div className={"field"}>
                                    <select ref={"printDevice"}>
                                        <option>Select...</option>
                                        {options}
                                    </select>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="basic-input">
                                <div className={"label"}>
                                    Number of copies
                                </div>
                                <div className={"field"}>
                                    <input 
                                        ref={"numCopies"} 
                                        type={"number"} 
                                        min={"1"} 
                                        max={"99999999"}
                                        value={this.state.copies}
                                        onKeyPress={this.handleAllowPositiveInt}
                                        onChange={(event) => this.handlePositiveInt(event.target.value)} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>   
            </div>
        </div>
        );
    }

    render(){
        let anchor = this.state.anchorEl;

        const modalPrintButtons = (
            <div className='dialog-buttons'>
                <Button onClick={this.props.onClose} style={{position: 'absolute', left: 8}}>Cancel</Button>
                <Button style={{color:'#00779f'}} onClick={this.handlePrintData}>Print</Button>
            </div>
        );

        let mobileHeight = `${this.props.windowHeight - 240}px`;        
        if(anchor && (anchor.offsetHeight < this.props.windowHeight -240)){
            mobileHeight = `${anchor.offsetHeight}px`;
        }

        return (<StyledDialog
            title={'Update Resource by RFID Label Printing'}
             modal={true}
             open={this.props.openModal}
             onClose={this.props.onClose}
             maxWidth={false}
             contentStyle={{height: 'auto'}}
             actions={modalPrintButtons}
             bodyStyle={{overflowY:'auto',minHeight: (this.props.isMobile)?mobileHeight:'inherit',height:'100%'}}
         >
             <form ref={this.associateAnchor}>
                {this.renderForm()}
             </form>
            
        </StyledDialog>)        
    }
}

export default ModalPrint;
