import { colorHexToRgb } from './utils';

export const LOCAL_MAP = 'LOCAL_MAP';
export const WORLD_MAP = 'WORLD_MAP';

export const DEFAULT_MAP = 'World';

export const ZOOM = 11;

export const SVG_BASE_64 = 'svgBase64';
export const BASE_64 = 'base64';
export const SQUARE = 'square';
export const DEFAULT_RESOURCE = 'DEFAULT_RESOURCE';
export const POINT = 'POINT';

export const HIGHLIGHT_COLOR = '#00FF2A';
export const BLINK_COLOR = '#000000';

export const BASE_COLOR_ARRAY = [
    '#00BDFF',
    '#00A25D',
    '#FF0000',
    '#7600FF',
    '#0000FF',
    '#F4DC00',
    '#F800FF',
    '#FF7F00',
    '#00EAA2',
    '#7EC600'
];

export const LINE_COLOR_ARRAY = BASE_COLOR_ARRAY.map(hexColor => colorHexToRgb(hexColor, 0.7));
export const ARROW_COLOR_ARRAY = BASE_COLOR_ARRAY.map(hexColor => colorHexToRgb(hexColor, 0.5));

export const MIN_CLUSTER_SIZE = 2;
export const MIN_SIZE_TO_CLUSTER = 101;
export const POINT_SIZE = 10;
export const ICON_SIZE = 16;
export const ICON_PLAYBACK_SIZE = 18;
export const DISTANCE = 25;
export const DEFAULT_ZONE_COLOR = "#7ab636"
