import {getLength} from "ol/sphere";
import {IMPERIAL_MEASURE_SYSTEM, METRIC_MEASURE_SYSTEM} from "../../constants/SiteDesigner";
import {polygon as turfPolygon, intersect as turfIntersect, point as turfPoint, booleanPointInPolygon, area as turfArea} from "@turf/turf"

export const colorHexToRgb = (hexColor, opacity) => {
    const bigint = parseInt(hexColor.substring(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
};

export const getExtensionBase64 = (base64) => {
    switch (base64.charAt(0)) {
        case '/':
            return 'image/jpeg';
        case 'i':
            return 'image/png';
        case 'P':
            return 'image/svg+xml';
        default:
    }
    return '';
};



export const measureDistanceImperial = (line) => getTextDistance(getLength(line))

export const getTextDistance = (distance, unitMeasure) => {
    let intPart = Math.floor(distance);
    let decimalPart = distance - Math.floor(distance);
    let textDistance = '';
    switch (unitMeasure) {
        case IMPERIAL_MEASURE_SYSTEM:
            decimalPart = Math.floor(decimalPart * 12);
            textDistance = decimalPart===0? `${intPart} ft`:`${intPart} ft ${decimalPart} in`;
            break;
        case METRIC_MEASURE_SYSTEM:
            decimalPart = distance - Math.floor(distance);
            decimalPart = Math.floor(decimalPart * 100);
            // textDistance = `${intPart} m ${decimalPart} cm`;
            textDistance = `${intPart}.${decimalPart} m`;
            break;
        default:
            decimalPart = Math.floor(decimalPart * 12);
            textDistance = `${intPart} ft ${decimalPart} in`;
            break;
    }
    return textDistance;
}

export const toFeetConversion = (distance, measurementSystem) => {
    let distanceConversion = distance;
    if (measurementSystem === METRIC_MEASURE_SYSTEM) {
        distanceConversion = distanceConversion * 3.281;
    }
    return distanceConversion;
}

// This function returns an array of polygon ids that are contained in "polygonSet" and intersect "polygon"; in other cases, it returns null.
export const intersectsPolygonGeom = (polygon, polygonSet) => {
    const polygonCoord = polygon.coords;
    const polygonTurf = turfPolygon([polygonCoord]);
    const intersectedPolygons = [];
    try {
        for (const pol of polygonSet) {
            const polCoord = pol.coords;
            const polTurf = turfPolygon([polCoord]);
            if (turfIntersect(polTurf, polygonTurf) && (polygon.id !== pol.id)) {
                intersectedPolygons.push(pol);
            }
        }
    } catch (e) {
        console.log("Some obstruction has the First and Last position are not equivalent.")
    }
    return intersectedPolygons.length ? intersectedPolygons : null;
}

const booleanPolygonsIntersect = (polygonA, polygonB) => {
    if (polygonA?.id === polygonB?.id) {
        return false;
    }
    const coordsPolygonA = polygonA.coords;
    const coordsPolygonB = polygonB.coords;
    const polygonATurf = turfPolygon([coordsPolygonA]);
    const polygonBTurf = turfPolygon([coordsPolygonB]);
    if (turfIntersect(polygonATurf, polygonBTurf)) {
        return true;
    }
    return false;
};

// This function returns a boolean value that checks if 'polygonA' is contained in 'polygonB' return True; in other cases, it return False.
const booleanPolygonInPolygon = (polygonA, polygonB) => {
    if (polygonA?.id === polygonB?.id) {
        return false;
    }
    const coordsPolygonA = polygonA.coords;
    const coordsPolygonB = polygonB.coords;
    const polygonTurf = turfPolygon([coordsPolygonB]);
    let numberPointsInside = 0;
    for (const point of coordsPolygonA) {
        const pointTurf = turfPoint(point);
        if (booleanPointInPolygon(pointTurf, polygonTurf)) {
            numberPointsInside += 1;
        }
    }
    if (numberPointsInside === coordsPolygonA.length) {
        return true;
    }
    return false;
};

// This function returns an array of polygon ids that are contained in "polygonSet," are brothers, and intersect "polygon"; in other cases, it returns null.
// Two polygons are brothers if they have the same parent.
export const intersectsChildPolygonsGeom = (polygon, polygonSet) => {
    const childPolygons = [];
    if (polygonSet) {
        for (const polygon_ of polygonSet) {
            if (
                booleanPolygonsIntersect(polygon_, polygon) &&
                !booleanPolygonInPolygon(polygon, polygon_) &&
                !booleanPolygonInPolygon(polygon_, polygon)
            ) {
                childPolygons.push(polygon_);
            }
        }
    }
    return childPolygons.length ? childPolygons : null;
};

// This function returns an array of polygon ids that are contained in "polygonSet" and are children of "polygon"; in other cases, it returns null.
export const getChildPolygons = (polygon, polygonSet) => {
    const intersectedPolygons = intersectsPolygonGeom(polygon, polygonSet);
    const childPolygons = [];
    if (intersectedPolygons) {
        for (const intersectedPolygon of intersectedPolygons) {
            if (booleanPolygonInPolygon(intersectedPolygon, polygon)) {
                childPolygons.push({id: intersectedPolygon.id, coords: intersectedPolygon.coords});
            }
        }
    }
    return childPolygons.length ? childPolygons : null;
};

// This function returns a  polygon that are contained in "polygonSet" and is the last children; in other cases, it returns null.
export const getLastChildPolygonSet = (polygonSet) => {
    let polygonChild = polygonSet.length ? polygonSet[0] : null;
    let maxNumberParents = 0;
    for (const polygonA of polygonSet) {
        let numberParents = 0;
        for (const polygonB of polygonSet) {
            if (booleanPolygonInPolygon(polygonA, polygonB)) {
                numberParents += 1;
            }
        }
        if (numberParents > maxNumberParents) {
            maxNumberParents = numberParents;
            polygonChild = polygonA;
        }
    }
    return polygonChild;
};

export const polygonArea = (polygon) => {
    const polygonTurf = turfPolygon([polygon.coords]);
    return turfArea(polygonTurf);
}

export const getChildsById = (polygonId, polygonSet) => {
    let childrenZones = [];
    const polygon = polygonSet.find((polygon_ => (polygon_.id === polygonId)));
    if (polygon) {
        childrenZones = getChildPolygons(polygon, polygonSet);
    }
    return childrenZones;
};

export const getFirstParentPolygonId = (polygon, polygonSet) => {
    let parentIdPolygon = null;
    let areaParentPolygon = Number.MAX_VALUE;
    for (const pol of polygonSet) {
        const polArea = polygonArea(pol);
        if (booleanPolygonInPolygon(polygon, pol) && (polArea < areaParentPolygon)) {
            areaParentPolygon = polArea;
            parentIdPolygon = pol.id;
        }
    }
    return parentIdPolygon;
};

export const getFirstParentAndChildsPolygonId = (polygon, polygonSet) => {
    let parentPolygon = {id: null};
    let areaParentPolygon = Number.MAX_VALUE;
    const childSet = [];
    for (const pol of polygonSet) {
        const polArea = polygonArea(pol);
        if (booleanPolygonInPolygon(polygon, pol) && (polArea < areaParentPolygon)) {
            areaParentPolygon = polArea;
            parentPolygon = pol;
        }
        if (booleanPolygonInPolygon(pol, polygon)) {
            childSet.push(pol);
        }
    }
    const childPolygons = [];
    for (const childA of childSet) {
        let numberParents = 0;
        for (const childB of childSet) {
            if (childA.id === childB.id) {
                continue;
            }
            if (booleanPolygonInPolygon(childA, childB)) {
                numberParents += 1;
            }
        }
        if (numberParents === 0) {
            childPolygons.push(childA);
        }
    }
    return {
        parent: parentPolygon,
        childs: childPolygons,
    };
};
