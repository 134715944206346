import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {PERIOD} from "../../util/report/filter/util";
import {changeFilterValueRange} from "../../../actions";


const NextPrevButtons = (props) => {
    const reportId = useSelector(state => state.report.reportId);
    const filters = useSelector(state => state.filters);
    const dispatch = useDispatch();
    const NEXT = 1;
    const PREV = 0;
    const handleGetNextRange = () => {
        eventsDateTimer(NEXT);
    };

    const handleGetPrevRange = () => {
        eventsDateTimer(PREV);
    };
    const eventsDateTimer = (TYPE) => {
        const dateTimeFilters = filters.datetimeFilters[reportId];
        const {nameFilter,fromFilter,toFilter} = props;
        const fromId = fromFilter.id;
        const toId = toFilter.id;

        let valueFromTo = PERIOD.TODAY;
        if( dateTimeFilters && dateTimeFilters[nameFilter]){
            const blinkTime = dateTimeFilters[nameFilter];
            valueFromTo = blinkTime.value;
        }else{
            props.onChangeDatePredefinedTimeLast(nameFilter,valueFromTo,fromId,toId);
        }
        const valueFrom = filters[fromId].value;
        const valueTo = filters[toId].value;
        const newDateTimeFrom = valueFrom?new Date(valueFrom):new Date();
        const newDateTimeTo = valueTo?new Date(valueTo):new Date();
        const differenceInTime = newDateTimeTo.getTime() - newDateTimeFrom.getTime();
        let differenceInDays = (differenceInTime / (1000 * 3600 * 24));
        differenceInDays = differenceInDays<1?1:valueFrom===PERIOD.TODAY?differenceInDays-1:differenceInDays;
        switch (valueFromTo) {
            case PERIOD.TODAY:
            case PERIOD.YESTERDAY:
            case PERIOD.LAST_WEEK:
            case PERIOD.LAST_MONTH:
            case PERIOD.CUSTOM:
                if (TYPE === NEXT) {
                    newDateTimeFrom.setDate(newDateTimeFrom.getDate() + differenceInDays);
                    newDateTimeTo.setDate(newDateTimeTo.getDate() + differenceInDays);
                } else {
                    newDateTimeFrom.setDate(newDateTimeFrom.getDate() - differenceInDays);
                    newDateTimeTo.setDate(newDateTimeTo.getDate() - differenceInDays);
                }
                break;
            default:
                break;
        }

        dispatch(changeFilterValueRange(fromId, newDateTimeFrom.toISOString(),toId, newDateTimeTo.toISOString()));

    };
    const styleNextPrev = {
        fontSize:'0.9em',
        width:'calc(100% - 6px)',
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between',
    }
    const styleButton ={
        width:'45%',
        backgroundColor: '#9E9E9E',
        color: '#fff',
        //margin:'3px 0px',
        height:'22px',
        border:'0',
    };

    return(
        <div style={styleNextPrev}>
            <button onClick={handleGetPrevRange} style={styleButton}  >{`<< Prev`}</button>
            <button onClick={handleGetNextRange} style={styleButton}  >{`Next >>`}</button>
        </div>
    )
};

export default NextPrevButtons;