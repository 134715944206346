import PropTypes from "prop-types";
import React from "react";
import {Checkbox} from "@material-ui/core";

class WrappedCheckbox extends React.Component{

    static get propTypes(){
        return {
            checked: PropTypes.bool
        };
    }

    componentDidMount(){
        this.rerenderFlag = true;
    }

    shouldComponentUpdate(np){
        return (this.props.checked !== np.checked);
    }
    render(){
        return (
            <Checkbox className="report-checkbox" style={{padding:'8px',backgroundColor:'transparent'}} disableTouchRipple={true} {...this.props} />
        );
    }
}
export default WrappedCheckbox;