import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { createSelector } from 'reselect';

import { connectRealTimeWS, disconnectRealTimeWS, loadTagsHistory, patchReportMapPropertiesMap, selectSiteAndChangeLocalMap, updateLocalMap } from '../actions';
import { ZoneVisibility } from '../views/util/map/LocalMap';
import { EMPTY_OBJECT } from '../constants';
import { useReportKey } from './report';
import {DISTANCE, MIN_CLUSTER_SIZE, MIN_SIZE_TO_CLUSTER} from "../utils/ol/olConstants";

const selectReportKeyMapState = createSelector(
    (state, reportKey) => state.reportMap.scopes?.[reportKey],
    mapProperties => {
        const {
            mapId = null,
            siteId = null,
            showMapThumbnails,
            showTagLegend = false,
            playback = EMPTY_OBJECT,
            showTagList = false,
            bounds = EMPTY_OBJECT,
            showLabels = false,
            configuration = EMPTY_OBJECT,
            validConfiguration = false,
            zoneVisibility = ZoneVisibility.NO_ZONES,
            autoZoneVisibility = ZoneVisibility.NO_ZONES,
            realTimeEnabled = false,
            dialog = { open: false, message: '', loader: false },
            focusMapSelectedRows = 0,
            realTimeStamp = 0,
            showNoLocateLabels = false,
            showLegend = false,
            showConfigureClustering = false,
            distanceCluster = DISTANCE,
            minClusterSize = MIN_CLUSTER_SIZE,
            minSizeToCluster = MIN_SIZE_TO_CLUSTER
        } = mapProperties || {};
        return { mapId, siteId, showMapThumbnails, showTagLegend, playback, showTagList, bounds, showLabels, zoneVisibility, autoZoneVisibility, configuration, validConfiguration, realTimeEnabled, dialog, focusMapSelectedRows, realTimeStamp, showNoLocateLabels, showLegend, showConfigureClustering, distanceCluster, minClusterSize, minSizeToCluster };
    }
);

export const useReportMapState = () => {
    const { reportKey } = useReportKey();
    return useSelector(state => selectReportKeyMapState(state, reportKey), shallowEqual);
};

export const useReportMapDispatch = (reportKey) => {
    const dispatch = useDispatch();
    return {
        selectSiteAndChangeLocalMap: useCallback((siteId) => dispatch(selectSiteAndChangeLocalMap(reportKey, siteId)), [dispatch, reportKey]),
        patchReportMapPropertiesMap: useCallback((properties) => dispatch(patchReportMapPropertiesMap(reportKey, properties)), [dispatch, reportKey]),
        updateLocalMap: useCallback((mapId, defaultBounds) => dispatch(updateLocalMap(reportKey, mapId, defaultBounds)), [dispatch, reportKey]),
        loadTagsHistory: useCallback((resources, idAttribute, indexQuery, startDate, endDate,userDefinedDateRange) => dispatch(loadTagsHistory(reportKey, resources, idAttribute, indexQuery, startDate, endDate,userDefinedDateRange)), [dispatch, reportKey]),
        connectRealTimeWS: useCallback(() => {
            dispatch(connectRealTimeWS());
        }, [dispatch]),
        disconnectRealTimeWS: useCallback(() => {
            dispatch(disconnectRealTimeWS());
        }, [dispatch]),
    };
};
