import * as types from '../constants/ActionTypes/zone-builder';
import { displayErrorFromAxios, handleErrorWithCode, LocationConfigService } from './util';
import Zone, {generateObjectId} from '../utils/ZoneModel';
import { requestSites } from './sites';
import { requestMaps } from './site-maps';
import { filterZones } from '../views/zone-builder/utils';
import {
    LOAD_ANTENNAS_MODEL_DATA,
    PATCH_REPLICATE_ZONES_DATA,
    REPLACE_ZONES
} from '../constants/ActionTypes';
import { patchReportProperties } from './reports';
import { isValidPolygon } from '../views/zone-builder/utils/polygon';
import { getReportKeys } from '../hooks';
import Vector from '../utils/ol/Vector';
import { selectSelectedZones } from '../reducers/zoneBuilder';
//import {getListCustomProperties} from "../views/zone-builder/utils/ImportZoneFromVSS";
import equal from 'deep-equal';
import GeoJSON from "ol/format/GeoJSON";
import ZoneBuilderModel from "../utils/ZoneBuilderModel";
import {ItemType, SITE_MANAGER_CONFIGURATION_ZONES_SCOPE, SITE_MANAGER_SCOPE} from "../../../constants";
import {getItemsBySiteIdMapIdDeviceType} from "../../../services/site-manager";
import {replaceItems, takeSnapshot} from "../../../store/actions/site-manager";
import {zonesArrayMapper} from "../../../helpers";
// import {LOAD_ZONES_FOR_MAP} from "../constants/ActionTypes/zone-builder";
import {addLoaderCounter, clearLoaderCounter} from "../../../store/actions";
import {closeDataEntry} from "./general";
import {refStore} from "./report-map";
import {UPDATE_ZONES_MAP} from "../../../store/action-types";
import {UPDATE_ZONES} from "../constants/ActionTypes/zone-builder";
/*
███████ ████████  █████   ██████  ██ ███    ██  ██████
██         ██    ██   ██ ██       ██ ████   ██ ██
███████    ██    ███████ ██   ███ ██ ██ ██  ██ ██   ███
     ██    ██    ██   ██ ██    ██ ██ ██  ██ ██ ██    ██
███████    ██    ██   ██  ██████  ██ ██   ████  ██████
*/

const formatStagingZones = ({ zones, zonesWithoutId }) => {
    const staged = Object.keys(zones || {}).map(i => zones[i]).concat(zonesWithoutId || []);
    return staged.filter(z=>z.shape).map(z=>new Zone(z));
}

export const getLatestZones = mapId => {
    return dispatch => LocationConfigService.instance().get(`/staging/${mapId}`)
        .then(resp => resp.data)
        .then(formatStagingZones)
        .catch(err => {
            if (err.response.status === 404) {
                return LocationConfigService.instance().get(`/zones?mapId=${mapId || 0}`)
                    .then(resp => resp.data)
            }
            throw err;
        })
        .catch(displayErrorFromAxios.bind(null, dispatch));
}

export const requestZonesForMap = (mapid) => {
    return (dispatch) => {
        return LocationConfigService.instance().get(`/staging/${mapid}`)
        .then(d=>d.data)
        .then(stage=>{
            const zones = formatStagingZones(stage);
            dispatch( loadZones(zones, { published: false }));
        })
        .catch(err=>{
            if(err?.response?.status === 404){
                return dispatch(requestCurrentlyPublishedZonesForMap(mapid,true));
            }
            throw err;
        })
        .catch(displayErrorFromAxios.bind(null,dispatch));
    };
};

export const loadZones = (zones, extraProps = {}) => ({
    type: types.LOAD_ZONES,
    zones,
    ...extraProps
});

export const saveToStaging = (mapId)=>{
    return (dispatch,getState)=>{

        mapId = mapId || 0;

        const {zones,zonesWithoutId} = getCurrentZonesForStaging(getState(),mapId);

        const data = {
            _id:mapId,
            zones: zones,
            zonesWithoutId:zonesWithoutId
        };
        if( (zones && Object.keys(zones)?.length>0) || (zonesWithoutId && zonesWithoutId?.length>0)){
            return LocationConfigService.instance().put(`/staging/${mapId}`,data)
                .then(()=>{
                    dispatch({type:types.SAVE_ZONES});
                })
                .catch(displayErrorFromAxios.bind(null,dispatch));
        }
    };
};

export function getCurrentZonesForStaging(state,mapId){
    const allZones = state.zoneBuilder.zones;
    const allZonesInMap = state.siteManager[SITE_MANAGER_CONFIGURATION_ZONES_SCOPE]?.items?.[ItemType.ZONE]||[];

    const zonesInMapById={};
    for (const zoneInMap of allZonesInMap) {
        zonesInMapById[zoneInMap.id] = zoneInMap;
    }


    const zones={};
    const zonesWithoutId=[];

    for(const zoneId in allZones) {        

        const isValidZone = filterZones(allZones[zoneId], mapId, false);
        const zone=new Zone(allZones[zoneId].data);


        if (isValidZone&&zonesInMapById[zoneId].isNew!==true&&zonesInMapById[zoneId].isDeleted!==true) {
            zones[zoneId]=zone;
        }
        else if(isValidZone&&zonesInMapById[zoneId].isNew===true){
            delete zone.data._id;
            zonesWithoutId.push(zone);
        }

    }
    return {zones,zonesWithoutId};
}


// const getPublishMapZones = (state,mapId) =>{
//     const siteId = state.reportMap.mapConfiguration?.siteId;
//     const mapName = state.reportMap.mapConfiguration?.description;
//     const siteSelect = state.sites.sites.filter(site=>site._id===siteId);
//     let siteName = "";
//     if(siteSelect.length>0){
//         siteName = siteSelect[0].name;
//     }
//     const {items} = state.siteManager?.[SITE_MANAGER_CONFIGURATION_ZONES_SCOPE];
//     const allZones = items[ItemType.ZONE] || [];
//     const zones = [];
//     allZones.forEach(zone=>{
//         const {isDraft } = zone;
//         const zoneSelect = {
//             ...zone,
//             mapId,
//             mapName,
//             siteId,
//             siteName,
//         }
//         if(!isDraft){
//             zoneSelect._id  = zone.id;
//         }
//         zones.push(zoneSelect)
//     })
//     return zones;
// }
export const getShape = (coords) =>{
    return {
        type:"Feature",
        geometry:{
            type:"Polygon",
            coordinates:[coords]
        }
    }
}
export function getCurrentZonesFromStateForMap(state,mapId){
    const allZones = state.zoneBuilder.zones;
    return Object.keys(allZones).reduce((zones,id)=>{
        const isValidZone = filterZones(allZones[id], mapId, true);
        if(isValidZone){
            zones[id] = allZones[id];
        }
        return zones;
    },{});
}

export const saveCurrentMapZonesToStaging = () => {
    return (dispatch,getState)=>{
        const state = getState();
        return dispatch(saveToStaging(state.sites.selectedFloorMap));
    };
};

/*
██████  ██    ██ ██████  ██      ██ ███████ ██   ██ ██ ███    ██  ██████
██   ██ ██    ██ ██   ██ ██      ██ ██      ██   ██ ██ ████   ██ ██
██████  ██    ██ ██████  ██      ██ ███████ ███████ ██ ██ ██  ██ ██   ███
██      ██    ██ ██   ██ ██      ██      ██ ██   ██ ██ ██  ██ ██ ██    ██
██       ██████  ██████  ███████ ██ ███████ ██   ██ ██ ██   ████  ██████
*/

const BANNED_ZONE_NAME = "On-Site";
const BANNED_ZONE_ERROR = `Cannot create a zone with name nor group "${BANNED_ZONE_NAME}". Please review your changes and try again.`;

const checkIfZoneIsValid = ({ data }) => data.name !== BANNED_ZONE_NAME && data.groupName !== BANNED_ZONE_NAME;
const equalsObject = (zone,zonePrev) =>{
    return equal(zone,zonePrev);
}
const compareZones=(currentZone,previousZone)=>{
    let equals=false;

    if(currentZone.action===previousZone.action
    &&currentZone.color===previousZone.color
    &&currentZone.description===previousZone.description
    &&currentZone.exitZoneId===previousZone.exitZoneId
    &&currentZone.groupName===previousZone.groupName
    &&currentZone.mapId===previousZone.mapId
    &&currentZone.mapName===previousZone.mapName
    &&(currentZone.orientationAngle===previousZone.orientationAngle||(currentZone.orientationAngle===""&&previousZone.orientationAngle==null))
    &&currentZone.priority===previousZone.priority
    &&equalsObject(currentZone.properties,previousZone.properties)
    &&equalsObject(currentZone.shape,previousZone.shape)
    &&currentZone.siteId===previousZone.siteId
    &&currentZone.siteName===previousZone.siteName
    &&currentZone.type===previousZone.type
    ){
        equals=true;
    }


    // console.log("currentZone.action===previousZone.action",currentZone.action===previousZone.action);
    // console.log("currentZone.color===previousZone.color",currentZone.color===previousZone.color);
    // console.log("currentZone.description===previousZone.description",currentZone.description===previousZone.description);
    // console.log("currentZone.exitZoneId===previousZone.exitZoneId",currentZone.exitZoneId===previousZone.exitZoneId);
    // console.log("currentZone.groupName===previousZone.groupName",currentZone.groupName===previousZone.groupName);
    // console.log("currentZone.mapId===previousZone.mapId",currentZone.mapId===previousZone.mapId);
    // console.log("currentZone.mapName===previousZone.mapName",currentZone.mapName===previousZone.mapName);
    // console.log("(currentZone.orientationAngle===previousZone.orientationAngle||(currentZone.orientationAngle===\"\"&&previousZone.orientationAngle==null))",(currentZone.orientationAngle===previousZone.orientationAngle||(currentZone.orientationAngle===""&&previousZone.orientationAngle==null)));
    // console.log("currentZone.priority===previousZone.priority",currentZone.priority===previousZone.priority);
    // console.log("equalsObject(currentZone.properties,previousZone.properties)",equalsObject(currentZone.properties,previousZone.properties));
    // console.log("equalsObject(currentZone.shape,previousZone.shape)",equalsObject(currentZone.shape,previousZone.shape));
    // console.log("currentZone.siteId===previousZone.siteId",currentZone.siteId===previousZone.siteId);
    // console.log("currentZone.siteName===previousZone.siteName",currentZone.siteName===previousZone.siteName);
    // console.log("currentZone.type===previousZone.type",currentZone.type===previousZone.type);
    // console.log("equals",equals);

    return equals;
}

export const publishCurrentMapZones = () => {
    return async (dispatch, getState) => {
        dispatch(addLoaderCounter({message: 'Saving', background: false, scope: SITE_MANAGER_SCOPE, fullscreen: true}));
        dispatch(closeDataEntry());
        const state = getState();
        const currentMapId = state.sites.selectedFloorMap;
        const {items} = state.siteManager?.[SITE_MANAGER_CONFIGURATION_ZONES_SCOPE];
        const currentZones = items[ItemType.ZONE] || [];
        const currentZonesById={};
        const currentZonesProperties = getCurrentZonesFromStateForMap(state, currentMapId);

        // const zonesPrev = state.zoneBuilder.zonesPrev;
        // const publishedMapZones = state.zoneBuilder.publishedZonesByMap[currentMapId];
        // const publishedZoneIds = publishedMapZones && publishedMapZones?.reduce((lookup, zone) => {
        //     lookup[zone._id] = true;
        //     return lookup;
        // }, {});

        // Current Site and map data
        const mapId = currentMapId;
        const mapName = state.reportMap.mapConfiguration?.description;
        const siteId = state.reportMap.mapConfiguration?.siteId;
        const {name: siteName = ""} = (siteId && state.sites.sites.find(({_id}) => _id === siteId))||{};







        // Gets publish zones for compare with actual zones.
        let publishedZones=null;
        try {
            publishedZones = await getItemsBySiteIdMapIdDeviceType(siteId, mapId, ItemType.ZONE);
        } catch (error) {
            // console.log(error);
            dispatch(clearLoaderCounter({scope: SITE_MANAGER_SCOPE}));
            displayErrorFromAxios(dispatch, error);
        }

        let addedZones = [], updatedZones = [], zonesDeleted=[];
        try {
            for (const zone of currentZones) {

                currentZonesById[zone.id] = zone;
                if (zone.isNew === true&&zone.isDeleted!==true) {
                    currentZonesProperties[zone.id].data = {
                        ...currentZonesProperties[zone.id].data,
                        mapId,
                        mapName,
                        siteId,
                        siteName
                    };
                    const isZoneValid = checkIfZoneIsValid(currentZonesProperties[zone.id]);
                    if (!isZoneValid) {
                        throw Error(BANNED_ZONE_ERROR);
                    }

                    // Remove temporal fields for add.
                    delete currentZonesProperties[zone.id].data._key;
                    delete currentZonesProperties[zone.id].data._id;
                    addedZones.push(currentZonesProperties[zone.id]);
                }
            }

            // Updated zones.
            if (publishedZones != null && publishedZones.zoneProperties != null) {
                for (const publishedZone of publishedZones.zoneProperties) {
                    // if the published zone is still in the current zones and is not marked as deleted.
                    if (currentZonesById.hasOwnProperty(publishedZone._id) && currentZonesById[publishedZone._id].isDeleted !== true) {
                        currentZonesProperties[publishedZone._id].data = {
                            ...currentZonesProperties[publishedZone._id].data,
                            mapId,
                            mapName,
                            siteId,
                            siteName
                        };
                        const isZoneValid = checkIfZoneIsValid(currentZonesProperties[publishedZone._id]);
                        if (!isZoneValid) {
                            throw Error(BANNED_ZONE_ERROR);
                        }
                        if (!compareZones(currentZonesProperties[publishedZone._id].data, publishedZone)) {
                            updatedZones.push(currentZonesProperties[publishedZone._id]);
                        }
                    } else if(publishedZone.priority!==-1){
                        zonesDeleted.push(publishedZone._id);
                    }
                }
            }
        }catch (e){console.log(e)}

        //     const {added, edited} = Object.keys(currentZonesProperties).reduce((o, zoneId) => {
        //
        //         if (currentZonesProperties[zoneId].data?.mapId == null)
        //             currentZonesProperties[zoneId].data.mapId = mapId;
        //         if (currentZonesProperties[zoneId].data?.mapName == null)
        //             currentZonesProperties[zoneId].data.mapName = mapName;
        //         if (currentZonesProperties[zoneId].data?.siteId == null)
        //             currentZonesProperties[zoneId].data.siteId = siteId;
        //         if (currentZonesProperties[zoneId].data?.siteName == null)
        //             currentZonesProperties[zoneId].data.siteName = siteName;
        //
        //         const isZoneValid = checkIfZoneIsValid(currentZonesProperties[zoneId]);
        //         if (!isZoneValid) {
        //             throw Error(BANNED_ZONE_ERROR);
        //         }
        //         if (publishedZoneIds && (zoneId in publishedZoneIds)) {
        //           if (zonesPrev[zoneId] && !equalsZones(currentZonesProperties[zoneId], zonesPrev[zoneId])) {
        //                 o.edited.push(currentZonesProperties[zoneId]);
        //             }
        //         } else {
        //             o.added.push(currentZonesProperties[zoneId]);
        //         }
        //         return o;
        //     }, {
        //         added: [],
        //         edited: []
        //     });
        //
        //     addedZones = added;
        //     updatedZones = edited;
        // } catch (e) {
        //     console.log(e);
        //     dispatch(clearLoaderCounter({scope: SITE_MANAGER_SCOPE}))
        //     return dispatch({
        //         type: ERROR_DIALOG,
        //         title: "Error when publishing",
        //         message: e.message
        //     });
        // }

        // shape validation to avoid the "On-Site" zone, also validating the name just in case
        /*const deletedZones = publishedMapZones.filter(({  _id, shape, name }) => {
            return _id && (shape || name !== BANNED_ZONE_NAME) && !(_id in currentMapZones);
        });*/

        const zonesAdded = addedZones.map(z => z.toJSON());
        const zonesUpdated = updatedZones.map(z => z.toJSON());
        //const zonesDeleted = deletedZones.map(z => z._id);
        //const zonesPrevIds = Object.keys(zonesPrev);
        //const zonesDeleted = currentZones.filter(zone => !(zonesPrevIds.indexOf(zone.id)));
        // allZones.filter(item=>item.isDeleted && !item.isNew).map(({id})=>id);
        // only send values if they have data
        const data = {
            ...(zonesAdded.length ? {zonesAdded} : {}),
            ...(zonesUpdated.length ? {zonesUpdated} : {}),
            ...(zonesDeleted.length ? {zonesDeleted} : {})
        };


        try {

            let successPublishZones=false;

            if (zonesAdded.length > 0 || zonesUpdated.length > 0 || zonesDeleted.length > 0) {

                await LocationConfigService.instance().put(`/zones/?batch=true&mapId=${currentMapId}`, data);
                await deleteStageForMap(currentMapId);
                successPublishZones = true;

            } else {
                await deleteStageForMap(currentMapId);
                successPublishZones = true;
            }

            if (successPublishZones === true) {

                const {
                    items,
                    zoneProperties,
                    autoZones
                } = await getItemsBySiteIdMapIdDeviceType(siteId, mapId, ItemType.ZONE)
                dispatch(replaceItems(SITE_MANAGER_CONFIGURATION_ZONES_SCOPE, ItemType.ZONE, items, {
                    mapId,
                    siteId
                }));
                dispatch(replaceItems(SITE_MANAGER_CONFIGURATION_ZONES_SCOPE, ItemType.AUTO_ZONE, autoZones, {
                    mapId,
                    siteId
                }));
                dispatch(loadPublishedZonesForMap(mapId, zoneProperties, true));
                dispatch(takeSnapshot(SITE_MANAGER_CONFIGURATION_ZONES_SCOPE, ItemType.ZONE, items));
                dispatch(clearLoaderCounter({scope: SITE_MANAGER_SCOPE}))


            }
        } catch (error) {
            dispatch(clearLoaderCounter({scope: SITE_MANAGER_SCOPE}));
            displayErrorFromAxios(dispatch, error);
        }
    };
};

const deleteStageForMap = async (mapId) => {
    await LocationConfigService.instance().delete(`/staging/${mapId}`);

};


export const undoZoneAction = ()=>({type:types.UNDO});
export const redoZoneAction = ()=>({type:types.REDO});

export const loadPublishedZonesForMap = (mapId,publishedZones,loadAsStaged,published=null) => ({
    type:types.LOAD_PUBLISHED_ZONES_FOR_MAP,
    mapId,
    publishedZones,
    loadAsStaged,
    published
});

export const loadStagingZonesForMap = (mapId,zones,publishedZones,loadAsStaged,published=null) => ({
    type:types.LOAD_STAGING_ZONES_FOR_MAP,
    mapId,
    zones,
    publishedZones,
    loadAsStaged,
    published
});
export const loadZonesForMap = (mapId,zones,loadAsStaged,published,publishedZones) => ({
    type:types.LOAD_ZONES_FOR_MAP,
    mapId,
    zones,
    loadAsStaged,
    published,
    publishedZones
});

export const requestCurrentlyPublishedZonesForMap = (mapId,loadAsStaged) => {
    return dispatch => {
        return LocationConfigService.instance().get(`/zones?mapId=${mapId || 0}`)
        .then((response)=>{
            return dispatch( loadPublishedZonesForMap(mapId,response.data,loadAsStaged));
        })
        .catch(displayErrorFromAxios.bind(null,dispatch));
    };
};

/*
██    ██ ██
██    ██ ██
██    ██ ██
██    ██ ██
 ██████  ██
*/

export const clickItemSection = (section) => ({type: types.CLICK_ZONE_PAGE_SECTION, section});
export const selectZone = (zoneId)=>({type:types.SELECT_ZONE, zoneId});
export const selectZones = (zoneIds)=>({
    type: types.SELECT_ZONES,
    zoneIds
});


export const selectZoneGroupZones = groupName => {
    return (dispatch, getState) => {
        const state = getState();
        const zones = Object.keys(state.zoneBuilder.zones).map(zid=>state.zoneBuilder.zones[zid]);
        const groupZones = zones.filter(zone => zone.data.groupName === groupName);
        const zoneIds = groupZones.map(zone => zone.data._key);
        dispatch(selectZones(zoneIds));
    }
};



export const changeActiveFilter = (filter)=>({type:types.CHANGE_FILTER, filter});

export const toggleMapInteraction = interaction => ({
    type: types.TOGGLE_MAP_INTERACTION,
    interaction
});

export const toggleMultiInteraction = interaction => ({
    type: types.TOGGLE_MULTI_INTERACTION,
    interaction
});

export const copyZone = () => ({ type: types.COPY_ZONE });
export const clearDirty = () => ({ type: types.CLEAR_DIRTY });

export const ZONE_LOCAL_DATA = { MAX_ZONE_INDEX: {}, NAME_ZONES: new Set() };

export const newDefaultZoneName = ({ mapId }) => {

    const prefix = `ZONE_${mapId}`;
    if (ZONE_LOCAL_DATA.MAX_ZONE_INDEX[mapId] === undefined) {
        let maxIndex = 0;
        for (const name of ZONE_LOCAL_DATA.NAME_ZONES) {
            if (name) {
                const words = name.split(' ');
                const index = +words[1];
                if (words.length === 2 && words[0] === prefix && words[1] && !Number.isNaN(index)) {
                    maxIndex = Math.max(index, maxIndex);
                }
            }
        }
        ZONE_LOCAL_DATA.MAX_ZONE_INDEX[mapId] = maxIndex;
    }
    let name = '';
    do {
        ZONE_LOCAL_DATA.MAX_ZONE_INDEX[mapId]++;
        name = `${prefix} ${ZONE_LOCAL_DATA.MAX_ZONE_INDEX[mapId]}`;
    } while(ZONE_LOCAL_DATA.NAME_ZONES.has(name));
    return name;
}

export const pasteZone = () => {
    return (dispatch, getState) => {
        const state = getState();
        const { mapPoint1X, mapPoint2X, mapPoint1Y, mapPoint2Y } = getState().reportMap.mapConfiguration || {};
        const relativeOffset = Math.max((mapPoint2X - mapPoint1X), (mapPoint2Y - mapPoint1Y)) * 0.03;
        const zones = {};
        for (const clipboardZone of state.zoneBuilder.clipboardZones || []) {
            
            if (!clipboardZone) return;
    
            const zoneCopy = getCopyOfZone(clipboardZone.data, relativeOffset);
            const zoneName = newDefaultZoneName({ mapId: state.reportMap.mapId, nameList: Object.values(state.zoneBuilder.zones).map(zone => zone?.data?.name) });
            const zone = new Zone({
                ...zoneCopy,
                name: zoneName,
                _key: undefined,
                _id: undefined,
            });
            zones[zone.data._key] = zone;
        }
        dispatch({
            type: types.ADD_MULTI_ZONES,
            selectedZoneIds: Object.keys(zones),
            zones,
        });
    };
};

const copyCounts = {};
function getCopyOfZone(zone, offset) {
    const originalName = zone.name;
    if (!copyCounts[originalName]) copyCounts[originalName] = 0;
    const copyCount = ++copyCounts[originalName];
    
    return {
        color: zone.color,
        mapId: zone.mapId,
        mapName:zone.mapName,
        siteId:zone.siteId,
        siteName:zone.siteName,
        name: `${zone.name} (${copyCount})`,
        groupName: zone.groupName,
        shape: getGeoJSONGeometryWithOffset(zone.shape, offset * copyCount, offset * copyCount),
    };
}

function getGeoJSONGeometryWithOffset(shape,offsetX,offsetY){
    return {
        ...shape,
        geometry:{
            ...shape.geometry,
            coordinates:shape.geometry.coordinates.map(c => {
                return c.map( ([x,y]) => ([ x + offsetX, y + offsetY ]) );
            })
        }
    };
}

const getNewZoneName = ({ gridName, zoneName, namesList = [], ind, mapId }) => {
    if (gridName) return zoneName + gridName;
    
    return newDefaultZoneName({ mapId });
}

export const addZoneFromGeoJSON = (geojson, zoneProps) => {
    return (dispatch, getState) => {
        const state = getState();
        const { reportKey } = getReportKeys(state);
        // get the selected map information
        const siteId = state.reportMap.mapConfiguration?.siteId;
        const mapName = state.reportMap.mapConfiguration?.description;
        
        const { name: siteName = "" } = state.sites.sites.find(({ _id }) => _id === siteId);
        const { color, mapId, zoneName = "", namesList = [], groupName } = zoneProps;

        const zones = geojson.reduce((acc, feature, ind) => {
            const { properties, ...shape } = feature;
            const gridName = properties?.gridName;
            const name = getNewZoneName({ gridName, zoneName, namesList, ind, mapId });
            const zone = new Zone({
                name,
                shape,
                color,
                mapName,
                siteName,
                groupName,
                mapId: mapId,
                siteId: siteId,
            });

            return { ...acc, [zone.data._key]: zone };
        }, {});
    
        let areValid = true;
        Object.values(zones).forEach(zone => {
            const shape = zone.data.shape.geometry.coordinates[0];
            const isValid = isValidPolygon(shape);
            if (!isValid) {
                areValid = false;
            }
        });
        if (areValid) {
            dispatch({
                type: types.ADD_MULTI_ZONES,
                selectedZoneIds: Object.keys(zones),
                mapId,
                zones,
            });
        } else {
            dispatch(patchReportProperties(reportKey, { dialog: { open: true, content: 'Some polygons have intersecting sides, new zones will not be drawn' } }));
        }
    };
};

export const addImportZoneJSON = (Zones) =>{
    return (dispatch, getState) => {
        const state = getState();
        // get the selected map information
        const siteId = state.reportMap.mapConfiguration?.siteId;
        const mapId = state.reportMap.mapConfiguration?._id;
        const siteSelect = state.sites.sites.filter(site=>site._id===siteId);
        const publishedMapZones = state.zoneBuilder.publishedZonesByMap[mapId];
        let siteName = "";
        if(siteSelect.length>0){
            siteName = siteSelect[0].name;
        }
        const newZones = Zones.map(zone=>({...zone,isNew:true,mapId:mapId,itemType:ItemType.ZONE,_id:zone._id||generateObjectId(),siteName:siteName}))
        const prevZones = Object.values(state.zoneBuilder.zones||{}).map(({data})=>data)||[];
        const {items:newItems,zoneProperties} = zonesArrayMapper([...prevZones,...newZones]);
        dispatch(replaceItems(SITE_MANAGER_CONFIGURATION_ZONES_SCOPE,ItemType.ZONE,newItems,{mapId,siteId,siteName}));
        dispatch(loadZonesForMap(mapId,zoneProperties,true,false,publishedMapZones));
    };
};

export const syncLocalZones = (mapId) => {
    return async (dispatch, getState) => {
        // TODO: Ways to sync up with published zones, like a merge state
        const state = getState();
        const localZones = state.zoneBuilder.zones;
        const latestZones = await dispatch(getLatestZones(mapId));

        Object.entries(localZones).forEach(([zoneId, zoneValue]) => {
            const selectedZone = latestZones.find(({ data }) => data._id === zoneId);
            if (selectedZone) {
                if (JSON.stringify(selectedZone) === JSON.stringify(zoneValue)) {
                    console.log(zoneId + " is the same!!!");
                } else {
                    console.log(zoneId + " varies");
                }
            }
            console.log(selectedZone);
        });

        console.log({ localZones, latestZones });
    }
}

export const deleteZone = (zone) => {
    return (dispatch) => {
        const zoneId = zone.data._key;
        dispatch({type:types.DELETE_ZONE, zoneId});
    };
};

export const modifyZone = (zoneId,geojson) => {
    return (dispatch, getState) => {
        const { reportKey } = getReportKeys(getState());
        const isValid = isValidPolygon(geojson?.geometry?.coordinates?.[0]);
        if (isValid) {

            const items = [...getState().siteManager[SITE_MANAGER_CONFIGURATION_ZONES_SCOPE].items[ItemType.ZONE]];
            const siteId =  getState().siteDesigner.selectedMapSD.siteId
            const mapId = getState().siteDesigner.selectedMapSD._id
            const zoneProperties={mapId:mapId,siteId:siteId}
            const zoneObject= items.find(itm=>itm.id===zoneId )
            zoneObject.coords=geojson?.geometry?.coordinates?.[0];
            dispatch(replaceItems(SITE_MANAGER_CONFIGURATION_ZONES_SCOPE,ItemType.ZONE,items,zoneProperties));
            refStore.configZonesMap?.setZones(items);

        } else {
            dispatch(patchReportProperties(reportKey, { dialog: { open: true, content: 'Polygons with intersecting sides are not allowed' } }));
        }
    };
};

const syncActiveZoneWithChanges = (changes) =>{
    return (dispatch,getState) => {

        const state = getState().zoneBuilder;
        const selectedZoneIds=state.selectedZoneIds||[];
        const siteManager = getState().siteManager;
        // for (const zoneId of state.selectedZoneIds) {
        const items = ((siteManager[SITE_MANAGER_CONFIGURATION_ZONES_SCOPE]||{}).items||{})[ItemType.ZONE]||[];
        const newItems =[];
        const zonesToModify = [];
        items.forEach(item => {
            if (selectedZoneIds.indexOf(item.id) >= 0) {
                newItems.push({...item, ...changes});
                zonesToModify.push({
                    zoneId: item.id,
                    updates: changes
                });
            } else {
                newItems.push(item);
            }
        });

        // Update Map properties.
        dispatch(({
            type: UPDATE_ZONES_MAP,
            scope:SITE_MANAGER_CONFIGURATION_ZONES_SCOPE,
            itemType:ItemType.ZONE,
            items:newItems
        }));

        // Update zone properties.
        dispatch(({
            type: UPDATE_ZONES,
            zones: zonesToModify
        }));
    };
};

export const changeZoneProperty = (key,value)=>{
    return (dispatch) => {

        return dispatch(syncActiveZoneWithChanges({
            [key]:value
        }));
    };
};

export const changeZoneGroup = (prevGroupName, newGroupName) => {
    return (dispatch, getState) => {
        const {zones} = getState().zoneBuilder;
        const zonesToModify = [];
        for (const zoneKey in zones) {
            const zone = zones[zoneKey];
            if (zone.data.groupName === prevGroupName) {
                zonesToModify.push({
                    zoneId: zone.data._id,
                    updates: {
                        groupName: newGroupName
                    }
                });
            }
        }
        dispatch(({
            type: types.UPDATE_ZONES,
            zones: zonesToModify
        }));

    };
};

export const selectZoneGroup = groupName => {
    return (dispatch, getState) => {
        const state = getState();
        const zones = Object.keys(state.zoneBuilder.zones).map(zid=>state.zoneBuilder.zones[zid]);
        const groupZones = zones.filter(zone => zone.data.groupName === groupName);
        const zoneIds = groupZones.map(zone => zone.data._key);
        dispatch(selectZones(zoneIds));
    }
};

export const unSelectZoneGroup = () => {
    return dispatch => dispatch(selectZones([]));
}


export const updateZonePropertiesSchema = (schema) => {
    return (dispatch) => {

        return LocationConfigService.instance().post('/schemas/zones',schema)
        .then(d=>d.data)
        .then(() => {
            return dispatch({
                type:types.UPDATE_ZONE_PROPERTY_SCHEMA,
                schema
            });
        })
        .catch(displayErrorFromAxios.bind(null,dispatch));
    };
};

export const requestZonePropertiesSchema = ()=>{
    return (dispatch) => {
        return LocationConfigService.instance().get('/schemas/zones')
        .then(d=>d.data)
        .then(doc=>{

            const schema = doc.schema;

            return dispatch({
                type:types.UPDATE_ZONE_PROPERTY_SCHEMA,
                schema
            });
        })
        .catch( err => handleErrorWithCode(err,404,()=>{
            return dispatch({
                type:types.UPDATE_ZONE_PROPERTY_SCHEMA,
                schema:{}
            });
        }))
        .catch(displayErrorFromAxios.bind(null,dispatch));
    };
};

export const getZoneGroups = () => {
    return dispatch => {
        LocationConfigService.instance().get("/zonegroups")
            .then(({ data: zoneGroups }) => dispatch({ type: types.UPDATE_ZONE_GROUPS, zoneGroups }))
            .catch(displayErrorFromAxios.bind(null,dispatch));
        }
}

export const getSiteZoneGroups = siteId => {
    return dispatch => {
        LocationConfigService.instance().get(`/zonegroups?siteId=${siteId}`)
            .then(({ data: zoneGroups }) => dispatch({ type: types.UPDATE_ZONE_GROUPS, zoneGroups }))
            .catch(displayErrorFromAxios.bind(null,dispatch));
    }
}

export const editZoneGroupName = (prevName, groupName, mapId) => {
    return (dispatch, getState) => {
        const state = getState();
        const zones = Object.values(state.zoneBuilder.zones).map((zoneVal) => {
            if (zoneVal.data.groupName === prevName && zoneVal.data.mapId === mapId) {
                const data = { ...zoneVal.data, groupName };
                return new Zone(data);
            }
            return zoneVal;
        });
        dispatch(loadZones(zones, { dirty: true }));
    }
}

export const changeActiveTab = tab => ({ type: types.CHANGE_ACTIVE_TAB, tab });

export const joinNeighborZones = () => {
    return (dispatch, getState) => {
        const { reportKey } = getReportKeys(getState());
        
        const zones = getState().zoneBuilder.zones;
        // const extent = getState().reportMap.mapConfiguration.extent;
        // const minSpace = Math.max((extent[2] - extent[0]), (extent[3] - extent[1])) * 0.05;
        const { mapPoint1X, mapPoint2X, mapPoint1Y, mapPoint2Y } = getState().reportMap.mapConfiguration || {};
        const minSpace = Math.max((mapPoint2X - mapPoint1X), (mapPoint2Y - mapPoint1Y)) * 0.01;
        //const minSpace = 10; // Absolute
        const selectedIdZones = getState().zoneBuilder.selectedZoneIds;
        const selectedZones = [];
        const data = {};
        Object.entries(zones).forEach(([key, zone]) => {
            if (selectedIdZones.includes(key)) {
                selectedZones.push(zone);
                const coordinates = JSON.parse(JSON.stringify(zone.data.shape.geometry.coordinates[0]));
                data[key] = { coordinates, used: new Array(coordinates.length), zoneId: key };
            }
        });

        if(selectedZones.length < 2){
            dispatch(patchReportProperties(reportKey, { dialog: { open: true, content: 'Select two or more neighbor zones first.' } }));
        }

        const selectedChangedIdZones = new Set();
        for (const zone of Object.values(data)) {
            for (let pi = 0; pi < zone.coordinates.length - 1; pi++) {
                const pivot = zone.coordinates[pi];
                const neighborZones = [];
                for (const neighbor of Object.values(data)) {
                    if (zone.zoneId !== neighbor.zoneId) {
                        let nearestVertexIndex = -1;
                        let nearestVertexDistance;
                        for (let i = 0; i < neighbor.coordinates.length - 1; i++) {
                            const vertex = neighbor.coordinates[i];
                            const A = new Vector(...pivot);
                            const B = new Vector(...vertex);
                            const distance = B.sub(A).mod2();
                            if (!neighbor.used[i] && distance < minSpace * minSpace) {
                                if (nearestVertexIndex === -1) {
                                    nearestVertexIndex = i;
                                    nearestVertexDistance = distance;
                                } else {
                                    if (distance < nearestVertexDistance) {
                                        nearestVertexIndex = i;
                                        nearestVertexDistance = distance;
                                    }
                                }
                            }
                        }
                        if (nearestVertexIndex !== -1) {
                            neighbor.used[nearestVertexIndex] = true;
                            neighborZones.push({ zone: neighbor, nearestVertexIndex });
                            selectedChangedIdZones.add(neighbor.zoneId);
                        }
                    }
                }
                if (neighborZones.length) {
                    const neighbors = [{zone, nearestVertexIndex: pi}, ...neighborZones];
                    selectedChangedIdZones.add(zone.zoneId);
                    const centroidX = neighbors.reduce((x, neighbor) => neighbor.zone.coordinates[neighbor.nearestVertexIndex][0] + x, 0) / neighbors.length;
                    const centroidY = neighbors.reduce((y, neighbor) => neighbor.zone.coordinates[neighbor.nearestVertexIndex][1] + y, 0) / neighbors.length;
                    neighbors.forEach((neighbor) => neighbor.zone.coordinates[neighbor.nearestVertexIndex][0] = centroidX);
                    neighbors.forEach((neighbor) => neighbor.zone.coordinates[neighbor.nearestVertexIndex][1] = centroidY);
                    neighbors.forEach((neighbor) => neighbor.zone.coordinates[neighbor.zone.coordinates.length -1] = neighbor.zone.coordinates[0]);
                }
            }
        }
        let areValid = true;
        selectedZones.forEach(zone => {
            const zoneId = zone.data._key;
            if (selectedChangedIdZones.has(zoneId)) {
                const shape = data[zoneId].coordinates;
                const isValid = isValidPolygon(shape);
                if (!isValid) {
                    areValid = false;
                }
            }
        });
        if (areValid) {
            const zones = [];
            selectedZones.forEach(zone => {
                const zoneId = zone.data._key;
                if (selectedChangedIdZones.has(zoneId)) {
                    const shape = data[zoneId].coordinates;
                    const newShape = JSON.parse(JSON.stringify(zone?.data?.shape));
                    if (newShape?.geometry?.coordinates) {
                        newShape.geometry.coordinates[0] = shape;
                        zones.push({
                            zoneId,
                            updates: {
                                shape: newShape,
                            },
                        });
                    }
                }
            });
            dispatch(({
                type: types.UPDATE_ZONES,
                zones,
            }));
        } else {
            dispatch(patchReportProperties(reportKey, { dialog: { open: true, content: 'Some polygons have intersecting sides, the join of neighboring zones will not be applied' } }));
        }
    };
};

export const translateSelectedZones = ({ xOffset, yOffset }) => {
    return (dispatch, getState) => {
        const state = getState();
        const zones = [];
        for (const selectedZone of selectSelectedZones(state.zoneBuilder)) {
            zones.push({
                zoneId: selectedZone.data._key,
                updates: {
                    shape: getGeoJSONGeometryWithOffset(selectedZone.data.shape, xOffset, yOffset),
                },
            });
        }
        dispatch(({
            type: types.UPDATE_ZONES,
            zones,
        }));
    };
};

export const updateDraftZones = (zoneIds) => {
    return {
        type: PATCH_REPLICATE_ZONES_DATA,
        data: {
            draftZoneIds: zoneIds,
        },
    };
};

export const patchReplicateZonesData = (data) => {
    return {
        type: PATCH_REPLICATE_ZONES_DATA,
        data,
    };
};

export const replicateZones = ({ replicas, xOffset, yOffset, draft }) => {
    return (dispatch, getState) => {
        const state = getState();
        const zones = {};
        const mapId = state.sites.selectedFloorMap;
        const zoneIds = [];
        // let count = 0;
        for (let indexReplica = 1; indexReplica <= replicas; indexReplica++) {
            for (const selectedZone of selectSelectedZones(state.zoneBuilder)) {
                const name = newDefaultZoneName({ mapId });
                const groupName = selectedZone.data.groupName;
                // count++;
                const zone = new Zone({
                    color: selectedZone.data.color,
                    mapId: selectedZone.data.mapId,
                    mapName:selectedZone.data.mapName,
                    siteId:selectedZone.data.siteId,
                    siteName:selectedZone.data.siteName,
                    name,
                    groupName,
                    shape: getGeoJSONGeometryWithOffset(selectedZone.data.shape, xOffset * indexReplica, yOffset * indexReplica),
                    _key: undefined,
                    _id: undefined,
                });
                zones[zone.data._key] = zone;
                zoneIds.push(zone.data._key);
            }
        }
        if (draft) {
            dispatch(updateDraftZones(zoneIds));
        }
        dispatch({
            type: types.ADD_MULTI_ZONES,
            selectedZoneIds: state.zoneBuilder.selectedZoneIds,
            zones,
        });
    };
};

export const replaceZones = (zones) => {
    return {
        type: REPLACE_ZONES,
        zones,
    };
};

export const cleanDraftZones = () => {
    return (dispatch, getState) => {
        const state = getState();
        if ((state.zoneBuilder.replicateZonesData?.draftZoneIds || []).length) {
            const zones = { ...state.zoneBuilder.zones };
            (state.zoneBuilder.replicateZonesData?.draftZoneIds || []).forEach(draftZoneId => {
                delete zones[draftZoneId];
            });
            dispatch(replaceZones(zones));
            dispatch(updateDraftZones([]));
        }
    };
};

/*
██ ███    ██ ██ ████████ ██  █████  ██      ██ ███████ ███████
██ ████   ██ ██    ██    ██ ██   ██ ██      ██    ███  ██
██ ██ ██  ██ ██    ██    ██ ███████ ██      ██   ███   █████
██ ██  ██ ██ ██    ██    ██ ██   ██ ██      ██  ███    ██
██ ██   ████ ██    ██    ██ ██   ██ ███████ ██ ███████ ███████
*/


export const initialize = ()=>{
    return dispatch => {
        dispatch(requestSites());
        dispatch(requestMaps());
        dispatch(requestZonePropertiesSchema());
    };
};



// site designer
export const updateZoneFromGeoJSON = (geojson, zoneProps) => {
    return (dispatch) => {
        const zoneId = zoneProps.id;
        dispatch(({
            type:types.UPDATE_ZONE,
            zoneId,
            updates:{
                shape: geojson,
            }
        }));
    };
};


export const deleteZoneById = (zoneId, childrenZones=[]) => {
    return (dispatch) => {
        dispatch({type:types.DELETE_ZONE, zoneId});
        for(const zone in childrenZones){
            const childZoneId = childrenZones[zone].id;
            dispatch({type: types.DELETE_ZONE, zoneId: childZoneId});
        }
    };
};

export const loadAntennaModelsData = () => {
    return async (dispatch) => {
        try {
            const response = await LocationConfigService.instance().get(`/antennaModels`);
            if (response.status === 200) {
                dispatch({ type: LOAD_ANTENNAS_MODEL_DATA, antennaModels: response.data.results });
            }
        } catch (e) {
            displayErrorFromAxios.bind(null, dispatch);
        }
    };
};

export const formatAsGeoJSON = (feature) => {
    return  (new GeoJSON()).writeFeatureObject(feature,{});
}

export const getZonesByGeoJson = (geoJson, zoneProps, mapId) => {
    const zones = geoJson.map((feature, index) => {
        const {properties, ...shape} = feature;
        const {zoneName = "", namesList = []} = zoneProps;
        const newShape = shape?.geometry?.coordinates[0].map((coord, index) => ({sequence: index + 1, x: coord[0], y: coord[1]}));
        const gridName = properties?.name;
        const color = properties?.color;
        const name = getNewZoneName({ gridName, zoneName, namesList, index, mapId });
        const zone = new ZoneBuilderModel({
            name,
            shape: newShape,
            color,
            mapId
        })

        return zone.toJSON();
    })

    return zones;
}


export const loadZoneTypesOnStore = (zoneTypes) => ({
    type: types.LOAD_ZONE_TYPES,
    zoneTypes
});


export const patchZonesOnStore = patchedZones => ({ type: types.PATCH_ZONES_ON_CONFIG, patchedZones});

export const openDialogZoneGrid = (newState) => ({
    type: types.OPEN_FORM_ZONE_GRID,
    newState
});